/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { IpAddrConfigItem, RepeatedMessageItem } from 'ajs/modules/data-model/factories';
import { IAviDataGridConfig } from 'ng/shared/components';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './dns-resolver-nameservers-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   DNS Resolvers Name Servers grid component.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'dns-resolver-nameservers-grid',
    templateUrl: './dns-resolver-nameservers-grid.component.html',
})
export class DnsResolverNameserversGridComponent implements AfterViewInit {
    /**
     * Name Server repeated IpAddr message item.
     */
    @Input()
    public nameServerIps: RepeatedMessageItem<IpAddrConfigItem>;

    /**
     * Template ref for Name Server IP grid row.
     */
    @ViewChild('nameServerIpsTemplateRef')
    public nameServerIpsTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Name Server IPs grid config.
     */
    public nameServerIpsGridConfig: IAviDataGridConfig;

    /**
     * This will be added as prefix for each input row.
     */
    public readonly nameServerIpsRowPrefix = 'nameserver_ip_';

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.nameServerIpsGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleAddress),
                id: 'nameserver_address',
                templateRef: this.nameServerIpsTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (nameServerIps: IpAddrConfigItem[]) => {
                    nameServerIps.forEach((nameServerIp: IpAddrConfigItem) => {
                        this.deleteNameServerIp(nameServerIp);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (nameServerIp: IpAddrConfigItem) => this.deleteNameServerIp(nameServerIp),
            }],
        };
    }

    /**
     * Handler for Name Server IP add operation.
     */
    public addNameServerIp(): void {
        this.nameServerIps.add();
    }

    /**
     * Handler for Name Server IP delete operation.
     */
    private deleteNameServerIp(nameServerIp: IpAddrConfigItem): void {
        this.nameServerIps.removeByMessageItem(nameServerIp);
    }
}
