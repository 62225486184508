/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @alias configFieldInputValidation */
function configFieldInputValidation(schemaService) {
    /**
     * Sets min, max, step and special values attributes to the element if field type is integer.
     */
    function setAttributes(scope, elem, attr) {
        const { objectType, fieldName } = attr;

        const attributes = schemaService.getFieldInputAttributes(objectType, fieldName);

        _.each(attributes, (value, key) => {
            attr.$set(key, value);
        });
    }

    return {
        restrict: 'A',
        compile: () => {
            return {
                pre: setAttributes,
            };
        },
    };
}

configFieldInputValidation.$inject = [
        'schemaService',
];

/**
 *  @ngdoc directive
 *  @name configFieldInputValidation
 *  @restrict A
 *  @desc
 *      This directive sets min, max, and step attributes to the input field.
 *      sets step = 1 when, field type is 'int' or 'uint'.
 *      sets min & max for integer fields when there are no special values or
 *          they can be merged with the regular range
 *
 *      Note: This directive does not set min, max attributes if special_values &
 *            field range are not continuous.
 *
 *  @example
 *  <input
 *     type="number"
 *     config-field-input-validation
 *     object-type="objectType"
 *     field-name="fieldName"
 *     ng-model="config.fieldName">
 */
angular.module('avi/component-kit')
    .directive('configFieldInputValidation', configFieldInputValidation);
