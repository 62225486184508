/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { GenericHdrMatchBaseConfigItem } from
    'ajs/modules/match/factories/generic-hdr-match-base.config-item';

import {
    HdrMatchOperation,
    ICookieMatch,
    IHdrMatch,
    IHostHdrMatch,
} from 'generated-types';

import {
    CookieMatch,
    HdrMatch,
} from 'object-types';

import { SchemaService } from 'ajs/modules/core/services/schema-service';
import * as l10n from './generic-hdr-match.l10n';
import './generic-hdr-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/***
 * List of match objectTypes which contains name input field.
 * More items can be added to this list in case we want to extend the support
 * to add more header match types with name input field.
 */
const OBJECT_TYPES_WITH_NAME_FIELD = [HdrMatch, CookieMatch];

/***
 * List of operations for which value should not be configurable.
 */
const preventConfigureValueSet = new Set([
    HdrMatchOperation.HDR_EXISTS,
    HdrMatchOperation.HDR_DOES_NOT_EXIST,
]);

/**
 * @description
 *     Generic Header Match component for the header match message.
 *     Enables reusing same component for different header match types.
 *     Currently it supports HdrMatch, HostHdrMatch and CookieMatch.
 * @author Abhinesh Gour, alextsg
 */
@Component({
    selector: 'generic-hdr-match',
    templateUrl: './generic-hdr-match.component.html',
})

export class GenericHdrMatchComponent implements OnInit {
    /**
     * HdrMatch message item instance.
     */
    @Input()
    public editable: GenericHdrMatchBaseConfigItem<IHdrMatch | IHostHdrMatch | ICookieMatch>;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * Match ObjectType passed to this match component for
     * conditional rendering fields in the template.
     * Enables reusing same component for different match types.
     * Currently the Value can be either of HdrMatch, HostHdrMatch or CookieMatch.
     */
    @Input()
    public matchObjectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    public readonly l10nKeys = l10nKeys;

    /**
     * Name of the match field whose value is to be matched.
     */
    public matchObjectNameField = 'hdr';

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Determine the field names based on the matchObjectType.
     */
    public ngOnInit(): void {
        this.setMatchObjectNameField();
    }

    /**
     * Returns true if the value input field should be shown.
     */
    public showValueField(): boolean {
        return this.editable.matchCriteria && !this.preventConfigureValue();
    }

    /**
     * Returns true if matchObjectType is CookieMatch. False othwerwise.
     * CookieMatch expects data in value field as string instead of string array.
     */
    public isCookieMatchObjectType(): boolean {
        return this.matchObjectType === CookieMatch;
    }

    /**
     * Called when the match criteria changes.
     */
    public handleCriteriaChange(): void {
        if (this.preventConfigureValue()) {
            this.editable.handleCriteriaChange();
        }
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }

    /**
     * Show name input field for supported matchObjects.
     */
    public showNameField(): boolean {
        return OBJECT_TYPES_WITH_NAME_FIELD.includes(this.matchObjectType);
    }

    /**
     * True if the value field should not be configurable.
     */
    private preventConfigureValue(): boolean {
        return preventConfigureValueSet.has(this.editable.matchCriteria);
    }

    /**
     * Sets matchObjectNameField as required by the matchObjectType.
     * Refer schema.json for more details.
     */
    private setMatchObjectNameField(): void {
        if (this.isCookieMatchObjectType()) {
            // Field `name` is for Name of the Cookie. Refer ICookieMatch for details.
            this.matchObjectNameField = 'name';
        }
    }
}
