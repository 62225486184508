/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { Cloud } from 'object-types';
import { CloudType } from 'generated-types';
import { ICloud } from '../../cloud.types';
import './cloud-modal.component.less';
import * as l10n from './cloud-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Set of Cloud types having IPAM/DNS section.
 */
const cloudsWithIpamDns = new Set([
    CloudType.CLOUD_NSXT,
    CloudType.CLOUD_NONE,
    CloudType.CLOUD_LINUXSERVER,
]);

/**
 * Set of Cloud types having Service Engine Section.
 */
const cloudsWithoutServiceEngine = new Set([
    CloudType.CLOUD_NSXT,
]);

/**
 * Set of Cloud types with Autoscale Polling Interval field.
 */
const cloudsWithAutoscalePollingInterval = new Set([
    CloudType.CLOUD_AZURE,
    CloudType.CLOUD_LINUXSERVER,
]);

/**
 * Set of Clout types with Tags section.
 */
const cloudsWithTags = new Set([
    CloudType.CLOUD_AZURE,
    CloudType.CLOUD_GCP,
]);

/**
 * @description Component for the Cloud modal.
 * @author alextsg
 */
@Component({
    selector: 'cloud-modal',
    templateUrl: './cloud-modal.component.html',
})
export class CloudModalComponent implements OnInit {
    /**
     * Cloud Item.
     */
    @Input() public editable: ICloud;

    /**
     * Layout for clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Cloud type dropdown options.
     */
    public cloudTypeOptions: IAviDropdownOption[] = [];

    /**
     * License tier dropdown options.
     */
    public licenseTierOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * True if the Cloud Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * Holds the object type to be used in template.
     */
    public readonly objectType = Cloud;

    /**
     * Flag to show IPAM/DNS section.
     */
    public showIpamDns = false;

    /**
     * Flag to show Service Engine section.
     * This section is not applicable for NSXT Cloud.
     * For all the clouds having Service Engine Group section, it'll be displayed only in edit mode.
     */
    public showServiceEngine = false;

    /**
     * Flag to show sections specific to No Orchestrator cloud.
     */
    public isNoOrchestratorCloud = false;

    /**
     * Flag to show sections specific to Linux Server cloud.
     */
    public isLinuxServerCloud = false;

    /**
     * Flag to show sections specific to Azure Cloud.
     */
    public isAzureCloud = false;

    /**
     * Flag to check if Azure cloud credentials are connected.
     */
    public isAzureCloudConnected = false;

    /**
     * Flag to show Autoscale Polling Interval field.
     */
    public showAutoScalePollingInterval = false;

    /**
     * Flag to show Tags section.
     */
    public showTags = false;

    public constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(SchemaService) schemaService: SchemaService,
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        const cloudTypes = schemaService.getEnumValues('CloudType');
        const licenseTiers = schemaService.getEnumValues('LicenseTierType');

        this.cloudTypeOptions = createOptionsFromEnumProps(cloudTypes);
        this.licenseTierOptions = createOptionsFromEnumProps(licenseTiers);

        this.isEditing = Boolean(itemId);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.isNoOrchestratorCloud = this.editable.isVtype(CloudType.CLOUD_NONE);
        this.isLinuxServerCloud = this.editable.isVtype(CloudType.CLOUD_LINUXSERVER);
        this.isAzureCloud = this.editable.isVtype(CloudType.CLOUD_AZURE);

        this.showIpamDns = cloudsWithIpamDns.has(this.editable.getVtype());

        this.showServiceEngine = !cloudsWithoutServiceEngine.has(this.editable.getVtype());

        this.showAutoScalePollingInterval = cloudsWithAutoscalePollingInterval.has(
            this.editable.getVtype(),
        );

        this.showTags = cloudsWithTags.has(this.editable.getVtype());

        if (this.isEditing) {
            this.setIsAzureCloudConnected();
        }
    }

    /**
     * Adds an Item as a child of Cloud to the MacroStack.
     */
    public addCloudChildToMacroStack(item: Item): void {
        this.editable.addChildToMacroStack(item);
    }

    /**
     * Deletes an Item from the MacroStack.
     */
    public deleteCloudChildFromMacroStack(item: Item): void {
        this.editable.deleteChildFromMacroStack(item);
    }

    /**
     * Sets Clouds Credentials Connected Status.
     */
    public setIsAzureCloudConnected(): void {
        this.isAzureCloudConnected = true;
    }

    /**
     * Returns if Azure Configuration modal needs to be disabled.
     */
    public isAzureFormInvalid(): boolean {
        const { config } = this.editable;

        const { azure_configuration: azureConfiguration } = config;

        return azureConfiguration && !azureConfiguration.config.subscription_id;
    }
}
