/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    BackupConfiguration,
    SystemConfig,
} from 'ajs/modules/system';

import * as l10n from './welcome-system-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * Welcome modal -> System settings panel component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'welcome-system-settings',
    templateUrl: './welcome-system-settings.component.html',
})
export class WelcomeSystemSettingsComponent {
    /**
     */
    @Input()
    public backupConfig: BackupConfiguration;

    /**
     * SystemConfig Item instance.
     */
    @Input()
    public systemConfig: SystemConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
