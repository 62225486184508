/**
 * @module TenancyModeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';
import { TenantSettingsService } from 'ajs/modules/accounts';

const $injector = '$injector';

export const tenantSettingsServiceProvider = {
    deps: [$injector],
    provide: TenantSettingsService,
    useFactory(injector: Injector): TenantSettingsService {
        return injector.get('TenantSettingsService');
    },
};

