/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'host-servers-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleHostIpAddress = `${prefix}.hostIpAddressHeader`;
export const columnTitleServiceEngineGroup = `${prefix}.serviceEngineGroupHeader`;
export const columnTitleCores = `${prefix}.coresHeader`;
export const columnTitleMemory = `${prefix}.memoryHeader`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;

export const ENGLISH = {
    [columnTitleHostIpAddress]: 'Host IP Address',
    [columnTitleServiceEngineGroup]: 'Service Engine Group',
    [columnTitleCores]: 'Cores',
    [columnTitleMemory]: 'Memory (GB)',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
    [addButtonLabel]: 'Add',
};
