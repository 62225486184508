/** @module PoolModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    Pool,
    PoolCollection,
    poolCreateController,
    PoolGroup,
    PoolGroupCollection,
    PoolGroupDeploymentPolicy,
    PoolGroupDeploymentPolicyCollection,
    POOL_COLLECTION_TOKEN,
    POOL_GROUP_COLLECTION_TOKEN,
    POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN,
    POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN,
    POOL_GROUP_ITEM_TOKEN,
    POOL_ITEM_TOKEN,
} from '.';

const poolModule = angular.module('avi/pool');

const controllers = [
    {
        controller: poolCreateController,
        name: 'PoolCreateController',
    },
];

const factories = [
    {
        factory: Pool,
        name: POOL_ITEM_TOKEN,
    },
    {
        factory: PoolCollection,
        name: POOL_COLLECTION_TOKEN,
    },
    {
        factory: PoolGroup,
        name: POOL_GROUP_ITEM_TOKEN,
    },
    {
        factory: PoolGroupCollection,
        name: POOL_GROUP_COLLECTION_TOKEN,
    },
    {
        factory: PoolGroupDeploymentPolicyCollection,
        name: POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN,
    },
    {
        factory: PoolGroupDeploymentPolicy,
        name: POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(poolModule, 'factory', name, factory);
});

controllers.forEach(({ name, controller }) => poolModule.controller(name, controller));
