/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { ErrorPageBodyModalComponent } from 'ng/modules/error-page';
import { ERROR_PAGE_BODY_ITEM_TOKEN } from '.';

/**
 * Ajs dependency token for ErrorPageBody collection.
 */
export const ERROR_PAGE_BODY_COLLECTION_TOKEN = 'ErrorPageBodyCollection';

export type TErrorPageBodyCollection = typeof ErrorPageBodyCollection;

/**
 * @description ErrorPageBody collection class.
 *
 * @author Satish Pednekar
 */
export class ErrorPageBodyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'errorpagebody',
            windowElement: ErrorPageBodyModalComponent,
            permissionName: AviPermissionResource.PERMISSION_ERRORPAGEBODY,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ERROR_PAGE_BODY_ITEM_TOKEN);
    }
}

ErrorPageBodyCollection.ajsDependencies = [
    ERROR_PAGE_BODY_ITEM_TOKEN,
];
