/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'pool';

export const poolGroupDeploymentPolicyModalBreadcrumbTitle =
    `${prefix}.poolGroupDeploymentPolicyModalBreadcrumbTitle`;

export const ENGLISH = {
    [poolGroupDeploymentPolicyModalBreadcrumbTitle]: 'Pool Group Deployment Policy',
};
