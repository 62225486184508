/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'error-page';
const componentName = 'error-page-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const editErrorPageProfileHeader = `${prefix}.editErrorPageProfileHeader`;
export const newErrorPageProfileHeader = `${prefix}.newErrorPageProfileHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const errorPagesHeader = `${prefix}.errorPagesHeader`;
export const generalLabel = `${prefix}.generalLabel`;

export const ENGLISH = {
    [editErrorPageProfileHeader]: 'Edit Error Page Profile: {0}',
    [newErrorPageProfileHeader]: 'New Error Page Profile: {0}',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [errorPagesHeader]: 'Error Pages',
    [generalLabel]: 'General',
};
