/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Ajs dependency token for Cluster item.
 */
export const CLUSTER_ITEM_TOKEN = 'Cluster';

/**
 * Ajs dependency token for ClusterNodeConfigItem.
 */
export const CLUSTER_NODE_CONFIG_ITEM_TOKEN = 'ClusterNodeConfigItem';
