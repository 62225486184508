/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/scatter-plot.less';
import {
    svg as d3Svg,
    selectAll as d3SelectAll,
} from 'd3';

const d3 = {
    svg: d3Svg,
    selectAll: d3SelectAll,
};

/**
 * @ngdoc directive
 * @name scatterPlot
 * @restrict A
 * @param {Metric} metrics
 */
angular.module('aviApp').directive('scatterPlot', ['$filter', 'ScatterPlotter',
function($filter, ScatterPlotter) {
    const healthScoreClassFilter = $filter('healthScoreClass');

    function scatterPlotLink(scope, elem, attr) {
        const data = {
            metrics: {
                xMetricTitle: scope.metrics.getTitle(),
                yMetricTitle: 'Health',
            },
            xMax() {
                return scope.metrics.maxConns;
            },
            yMax() {
                return scope.metrics.maxHS;
            },
        };

        function makeBrush() {
            function handleBrush() {
                // get brush selection
                const extent = scope.brush.extent();

                const x0 = extent[0][0];
                const y0 = extent[0][1];
                const x1 = extent[1][0];
                const y1 = extent[1][1];

                const { x, y } = scope.chart;

                // range goes backwards for y
                const ydiff = y.invert(0) - y.invert(len);
                const xdiff = x.invert(len);

                // grabbing out all the selected nodes
                const currentlySelected = [];

                scope.dots.each(d => {
                    const betweenX = d.x + xdiff >= x0 && d.x - xdiff <= x1;
                    const betweenY = d.y + ydiff >= y0 && d.y - ydiff <= y1;

                    d.selected = betweenX && betweenY;

                    if (d.selected) {
                        currentlySelected.push(d.title);
                    }
                });

                scope.dots.classed('selected', function(d) {
                    return d.selected;
                });

                scope.$emit('scatterPlotCurrentlySelected', currentlySelected);
            }

            const boxSize = scope.chart.len;
            const len = boxSize / 2;

            scope.$on('clear-scatter-plot-brush', function() {
                scope.brush.clear();
                d3.selectAll('.brush').call(scope.brush);
                handleBrush();
            });

            // add brush
            scope.brush = d3.svg.brush()
                .x(scope.chart.x)
                .y(scope.chart.y);

            scope.brush.on('brushend', function() {
                scope.$apply(handleBrush);
            });

            scope.chart.g.append('g')
                .attr('class', 'brush')
                .call(scope.brush);
        }

        function repaint() {
            elem.find('svg, h2').remove();

            const { metrics } = scope;

            if (metrics && metrics.hasSeries()) {
                scope.chart = new ScatterPlotter(elem, data, { small: attr.small === 'true' });

                // generating the points from the data
                scope.points = _.map(
                    _.filter(metrics.series, series => series.hasData()),
                    series => {
                        const hsValue = series.getHealthScoreValue() || 0;

                        return {
                            x: series.getValue() || 0,
                            y: hsValue,
                            title: series.objId,
                            className: healthScoreClassFilter(hsValue, series.getOperStatus()),
                        };
                    },
                );

                scope.dots = scope.chart.plot(scope.points);

                if (attr.small !== 'true') {
                    makeBrush();

                    // if it's big enough for a brush, it's big enough for a title
                    const title = $('<h2 class="graph-title graph-overlay"/>');

                    title.text('Servers')
                        .css({
                            left: '50px',
                            top: '5px',
                        });
                    elem.append(title);
                }
            }
        }

        scope.$watch('metrics.lastUpdate', repaint);

        scope.$on('repaint', repaint);
    }

    return {
        restrict: 'A',
        scope: {
            metrics: '<',
        },
        template: '<div ng-hide="metrics.getMainSeries().hasData()" avi-loader></div>',
        link: scatterPlotLink,
    };
}]);
