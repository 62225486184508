/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'ipam';

export const customIpamDnsProfileModalBreadcrumbTitle =
    `${prefix}.customIpamDnsProfileModalBreadcrumbTitle`;

export const ENGLISH = {
    [customIpamDnsProfileModalBreadcrumbTitle]: 'Custom IPAM/DNS Profile',
};
