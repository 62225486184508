/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem, RepeatedMessageItem } from 'ajs/modules/data-model/factories';

import { Tier1LogicalRouterInfoConfigItem } from '.';

interface INsxtTier1SegmentManualModeConfig {
    tier1_lrs: RepeatedMessageItem<Tier1LogicalRouterInfoConfigItem>;
}

export class NsxtTier1SegmentManualModeConfigItem
    extends MessageItem<INsxtTier1SegmentManualModeConfig> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NsxtTier1SegmentManualMode',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Removes all configured Tier1 LRs.
     */
    public removeTier1LRs(): void {
        this.config.tier1_lrs.removeAll();
    }
}
