/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './boolean-label.filter.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IBooleanLabelPairs {
    enabled: string[];
    allowed: string[];
    switch: string[];
    yes: string[];
    active: string[];
}

export function booleanLabelFilter(
    l10nService: L10nService,
) {
    /**
     * @desc
     *
     *   Translates boolean values into text labels. Default is "True" and "False".
     *
     * @author
     *   Akul Aggarwal, Alex Malitsky
     */
    return function booleanLabelFilter(boolVal: boolean, type: string, lowerCase: boolean): string {
        let output: string;

        l10nService.registerSourceBundles(dictionary);

        const pairs: IBooleanLabelPairs = {
            enabled: [
                l10nService.getMessage(l10nKeys.enabledLabel),
                l10nService.getMessage(l10nKeys.notEnabledLabel),
            ],
            allowed: [
                l10nService.getMessage(l10nKeys.allowedLabel),
                l10nService.getMessage(l10nKeys.disallowedLabel),
            ],
            switch: [
                l10nService.getMessage(l10nKeys.disableLabel),
                l10nService.getMessage(l10nKeys.enableLabel),
            ],
            yes: [
                l10nService.getMessage(l10nKeys.yesLabel),
                l10nService.getMessage(l10nKeys.noLabel),
            ],
            active: [
                l10nService.getMessage(l10nKeys.activeLabel),
                l10nService.getMessage(l10nKeys.suspendedLabel),
            ],
        };

        if (type && type in pairs) {
            const pair = pairs[type];

            output = boolVal ? pair[0] : pair[1];
        } else {
            output = boolVal ?
                l10nService.getMessage(l10nKeys.trueLabel) :
                l10nService.getMessage(l10nKeys.falseLabel);
        }

        if (lowerCase) {
            output = output.toLowerCase();
        }

        return output;
    };
}

booleanLabelFilter.$inject = [
    'l10nService',
];
