/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { GSLB_GEODB_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/gslb/gslb.tokens';
import * as l10n from './geo-profile-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  geoProfilePage
 * @description  Page for Geo Profiles containing a Collection Grid of Geo Profiles.
 */
class GeoProfilePageController {
    constructor(GslbGeoDbProfileCollection, CRUDGridConfig, l10nService) {
        this._collection = new GslbGeoDbProfileCollection();
        l10nService.registerSourceBundles(dictionary);

        const gridConfig = {
            collection: this._collection,
            id: 'geo-profile-list-page',
            fields: [{
                name: 'data.config.name',
                sortBy: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
            }, {
                name: 'entries',
                title: l10nService.getMessage(l10nKeys.columnTitleEntries),
                transform(row) {
                    const entries = row.data.config.entries.config;
                    const entryDescriptions = entries.map(entry => {
                        const { priority, file } = entry.config;
                        const { filename } = file.config;
                        const entryDescription = `${filename}(${priority})`;

                        return entryDescription;
                    }).join();

                    return entryDescriptions;
                },
            }],
            layout: {
                disabledCreateButtonLabel:
                    l10nService.getMessage(l10nKeys.disabledCreateButtonLabel),
            },
        };

        this.gridConfig = CRUDGridConfig(gridConfig);
    }
}

GeoProfilePageController.$inject = [
        GSLB_GEODB_PROFILE_COLLECTION_TOKEN,
        'CRUDGridConfig',
        'l10nService',
];

angular.module('aviApp').component('geoProfilePage', {
    controller: GeoProfilePageController,
    templateUrl: 'src/components/pages/infrastructure/gslb/geo-profile-page/' +
            'geo-profile-page.html',
});
