/**
 * Module containing update related components.
 * @preferred
 * @module UpdateModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Inject } from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { InitialDataService } from 'ajs/modules/core/services/initial-data';
import * as l10n from './update-card-about.l10n';

import './update-card-about.component.less';

const {
    ENGLISH: dictionary,
    ...l10nKeys
} = l10n;

/**
 * @description Version-info card used in update page, current controller version.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'update-card-about',
    templateUrl: './update-card-about.component.html',
})
export class UpdateCardAboutComponent {
    public controllerBuild: number;
    public controllerPatch: string;
    public controllerBuildDate: string;
    public controllerVersion: string;

    public readonly l10nKeys = l10nKeys;

    public constructor(
        @Inject(InitialDataService) private initialDataService: InitialDataService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Fetches the Controller's version details from initialData
     * and assigns to relevant class properties
     */
    public ngOnInit(): void {
        const {
            controllerBuild,
            controllerPatch,
            controllerBuildDate,
            controllerVersion,
        } = this.initialDataService;

        this.controllerBuild = controllerBuild;
        this.controllerPatch = controllerPatch;
        this.controllerBuildDate = controllerBuildDate;
        this.controllerVersion = controllerVersion;
    }
}
