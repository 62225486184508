/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import './expander-button.less';

/**
 * @ngdoc component
 * @name  expander-button
 * @description Component for an expander button.
 * @memberOf module:avi/shared
 * @param iconClassName - Name of the icon class for the expander button.
 * @param onClick - Click handler.
 * @author alextsg
 */
@Component({
    selector: 'expander-button',
    templateUrl: './expander-button.component.html',
})
export class ExpanderButtonComponent {
    @Input() public iconClassName: string;
    @Output() public onClick ? = new EventEmitter();

    /**
     * Click handler for clicking the button.
     */
    public handleClick($event: MouseEvent): void {
        $event.stopPropagation();
        this.onClick.emit();
    }
}
