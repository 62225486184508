/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name MetricsDimensionDurationCollDataSource
 * @description
 *
 *     Params differ a bit from MetricsDimensionListCollDataSource. Data processing is the same.
 *
 */
angular.module('avi/vs/security').factory('MetricsDimensionDurationCollDataSource', [
'MetricsDimensionListCollDataSource',
function(MetricsDimensionListCollDataSource) {
    class MetricsDimensionDurationCollDataSource extends MetricsDimensionListCollDataSource {
        /** @override */
        getRequestParams_(requestType) {
            const
                params = super.getRequestParams_(requestType),
                { owner_: collection } = this;

            params['dimensions'] = collection.dimension_;

            return params;
        }
    }

    angular.extend(MetricsDimensionDurationCollDataSource.prototype, {
        defaultFields_: [
            {
                id: 'duration',
                preserved: true,
                subscribers: ['__mandatory_field'],
            },
        ],
        defaultParams_: {
            metric_id: 'dos.sum_attack_duration',
            dimension_aggregation: 'sum',
            pad_missing_data: false,
        },
    });

    return MetricsDimensionDurationCollDataSource;
}]);
