/**
 * @module WafModule
 */
/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { WafCrsCollection } from 'ajs/modules/waf';
import { EventCollection } from 'ajs/js/services/collections/EventCollection';
import {
    ICRSDetails,
    IEventLog,
} from 'generated-types';

/**
 * @description
 *     Exposes function to fetch last CRS update from the events API and latest Waf CRS
 *     from waf API. This helps in determining if the WAF is using latest CRS or not.
 * @author Amit Dubey
 */
export class LatestWafCrsVersionService {
    private eventCollection: EventCollection;
    private wafCrsCollection: WafCrsCollection;

    constructor(
        WafCollection: typeof WafCrsCollection,
        EventsCollection: typeof EventCollection,
    ) {
        this.eventCollection = new EventsCollection({
            params: {
                filter: [
                    'eq(obj_type,ALBSERVICES)',
                    'eq(event_id,CRS_UPDATE)',
                ],
                duration: 31536000, // One year -> 365*24*60*60 seconds
                orderby: '-report_timestamp',
            },
            isStatic: true,
            limit: 1,
        });

        this.wafCrsCollection = new WafCollection({
            params: {
                fields: 'name,release_date,version,tenant_ref',
            },
            isStatic: true,
            sortBy: '-version',
            limit: 1,
        });
    }

    /**
     * Return the latest CRS available and the latest waf crs in use.
     */
    public getLatestVersions(): Promise<ICRSDetails[]> {
        return Promise.all([
            this.getCrsFromPortal(),
            this.getCrsFromController(),
        ]);
    }

    /**
     * Return the latest CRS available on the customer portal
     */
    private async getCrsFromPortal(): Promise<ICRSDetails> {
        await this.eventCollection.load();

        if (!this.eventCollection.getNumberOfItems()) {
            return;
        }

        const crsUpdateEvent: IEventLog[] = this.eventCollection.itemList.map(
            (item: Item) => item.getConfig(),
        );

        const [latestPortalCrsInfo]: ICRSDetails[] = crsUpdateEvent[0]
            .event_details
            .crs_update_details
            .crs_info;

        return latestPortalCrsInfo;
    }

    /**
     * Return the latest CRS available on the controller
     */
    private async getCrsFromController(): Promise<ICRSDetails> {
        await this.wafCrsCollection.load();

        if (!this.wafCrsCollection.getNumberOfItems()) {
            return;
        }

        const [controllerCrs]: ICRSDetails[] = this.wafCrsCollection.itemList.map(
            (item: Item) => item.getConfig(),
        );

        const latestControllerCrsInfo: ICRSDetails = {
            name: controllerCrs.name,
            release_date: controllerCrs.release_date,
            version: controllerCrs.version,
        };

        return latestControllerCrsInfo;
    }
}

LatestWafCrsVersionService.$inject = [
    'WafCrsCollection',
    'EventCollection',
];
