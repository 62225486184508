/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './application-profile-modal.less';
import * as l10n from './application-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const compressionProfileTypes = {
    AUTO_COMPRESSION: 'AUTO_COMPRESSION',
    CUSTOM_COMPRESSION: 'CUSTOM_COMPRESSION',
};

const APPLICATION_PROFILES_WITH_APP_SERVICE_TYPE_SUPPORT = new Set([
    'APPLICATION_PROFILE_TYPE_HTTP',
    'APPLICATION_PROFILE_TYPE_L4',
    'APPLICATION_PROFILE_TYPE_SSL',
]);

/**
 * @constructor
 * @memberOf module:avi/profiles/application
 * @mixes module:avi/profiles/application.applicationProfileModalComponentBindings
 * @see {@link module:avi/profiles/application.applicationProfileModalComponent}
 */
class ApplicationProfileModalController {
    constructor(
        $window,
        $element,
        StringGroupCollection,
        ApplicationProfile,
        schemaService,
        Regex,
        l10nService,
        systemInfoService,
        licenseBasedFeaturesService,
    ) {
        this.$element_ = $element;
        this.$window_ = $window;
        this.stringGroupCollection = new StringGroupCollection();
        this.ApplicationProfile_ = ApplicationProfile;

        /**
         * @protected
         */
        this.systemInfoService_ = systemInfoService;

        const supportedApplicationProfileTypesMap =
            licenseBasedFeaturesService.getSupportedAppProfileTypesMap();

        this.applicationProfileTypeEnumValues =
            schemaService
                .getEnumValues('ApplicationProfileType')
                .filter(({ value }) => supportedApplicationProfileTypesMap.get(value));

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * @type {Regex}
         */
        this.regex = Regex;

        /**
         * SSL everewhere enable flag
         * @type {boolean}
         */
        this.sslEverywhereEnabled = false;

        /**
         * List of compression profile types.
         */
        this.compressionProfileTypes = compressionProfileTypes;
    }

    /**
     * @override
     */
    $onInit() {
        this.tab = 0;

        this.handleHttpsEverywhereChange();
        this.resetSubObjects();
    }

    /**
     * Handles the toggling Secure Http checkboxes.
     * All associated checkboxes except includeSubdomains are checked/unchecked
     * based on value of SSL Everywhere checkbox. SSL Everywhere is made checked/unchecked
     * programmatically based on values of associated checkboxes.
     * @param {boolean} [value] - sslEverywhereEnabled flag value.
     */
    handleHttpsEverywhereChange(value) {
        const { http_profile: httpProfile } = this.editable.getConfig();

        if (!httpProfile) {
            throw new Error('Http Profile doesn\'t exist.');
        }

        if (value !== undefined) {
            httpProfile.http_to_https = value;
            httpProfile.server_side_redirect_to_https = value;
            httpProfile.secure_cookie_enabled = value;
            httpProfile.hsts_enabled = value;
            httpProfile.httponly_enabled = value;
            httpProfile.x_forwarded_proto_enabled = value;
        } else {
            this.sslEverywhereEnabled = this.ApplicationProfile_
                .getHttpProfileSslEverywhereEnabledValue(httpProfile);
        }
    }

    /**
     * Resets compressionFilter.
     */
    resetSubObjects() {
        this.editMode = {
            compressionFilter: null,
        };
    }

    /**
     * Resets some properties to default values or removes them completely.
     * ngClick handler for the buttons of Profile type selection.
     * @param {AppProfileType} type
     */
    setType(type) {
        const currentType = this.editable.getType();

        if (currentType !== type) {
            this.editable.setType(type);
        }
    }

    /**
     * Goes to the specific tab for the given index in the wizard.
     * @param {number} tabIndex
     */
    gotoTab(tabIndex) {
        if (!this.confirmDiscardSubobject()) {
            return;
        }

        this.resetSubObjects();

        // Clear errors if exist
        this.editable.errors = null;
        this.tab = tabIndex;
    }

    /**
     * Confirms with user before discarding the compression filter changes.
     * @returns {boolean}
     */
    confirmDiscardSubobject() {
        if (this.editMode && this.editMode.compressionFilter) {
            return this.$window_.confirm(this.l10nService
                .getMessage(this.l10nKeys.discardCompressionFilterChangesPrompt));
        }

        return true;
    }

    /**
     * Handles compression type change.
     */
    compressionTypeChange() {
        if (!this.confirmDiscardSubobject()) {
            this.editable.updateCompressionProfileType('CUSTOM_COMPRESSION');

            return;
        }

        this.resetSubObjects();

        // Clear errors if exist
        this.editable.errors = null;
    }

    /**
     * Toggles selective caching checkbox.
     */
    toggleSelectiveCaching() {
        const { cache_config: cacheConfig } = this.editable.getConfig().http_profile;

        if (cacheConfig.selectiveCaching) {
            this.editable.addCacheConfigPathMatch();
        } else {
            this.editable.deleteCacheConfigPathMatch();
        }
    }

    /**
     * Checks if type is Custom Compression
     */
    isTypeCustomCompression() {
        return this.editable.getHttpCompressionProfile().type ===
            this.compressionProfileTypes.CUSTOM_COMPRESSION;
    }

    /**
     * Returns true if defaultLicenseTier is
     * ENTERPRISE OR ENTERPRISE_WITH_CLOUD_SERVICES License Tier.
     */
    get isEnterpriseOrEnterpriseWithCloudServicesTier() {
        return this.systemInfoService_.isEnterpriseOrEnterpriseWithCloudServicesTier;
    }

    /**
     * If true, AppServiceType dropdown will be shown to the user.
     * @returns {boolean}
     */
    get showAppServiceType() {
        const type = this.editable.getType();

        return APPLICATION_PROFILES_WITH_APP_SERVICE_TYPE_SUPPORT.has(type);
    }

    /**
     * If true, preserve clientIP field will be shown to the user.
     * @returns {boolean}
     */
    get showPreserveClientIpField() {
        return !this.editable.isType('APPLICATION_PROFILE_TYPE_HTTP') &&
            !this.editable.isType('APPLICATION_PROFILE_TYPE_SIP');
    }

    /**
     * @override
     */
    $onDestroy() {
        this.stringGroupCollection.destroy();
    }
}

ApplicationProfileModalController.modalPortalClassName = 'avi-modal';

ApplicationProfileModalController.$inject = [
    '$window',
    '$element',
    'StringGroupCollection',
    'ApplicationProfile',
    'schemaService',
    'Regex',
    'l10nService',
    'systemInfoService',
    'licenseBasedFeaturesService',
];

/**
 * @name applicationProfileModalComponent
 * @property {module:avi/profiles/application.ApplicationProfileModalController} controller
 * @property {module:avi/profiles/application.applicationProfileModalComponentBindings} bindings
 * @memberOf module:avi/profiles/application
 * @description Component for Application Profile create/edit modal.
 * @author Alex Malitsky, chitra
 */
angular.module('avi/profiles/application').component('applicationProfileModal', {
    /**
     * @mixin applicationProfileModalComponentBindings
     * @memberOf module:avi/profiles/application
     * @property {ApplicationProfile} editable - ApplicationProfile item.
     * @property {Function} closeModal - method to be called when config modal gets closed.
     */
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    controller: ApplicationProfileModalController,
    templateUrl: 'src/components/templates/profiles/application/' +
        'application-profile-modal/application-profile-modal.html',
});
