/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { range } from 'underscore';

/**
 * Parses the range input received.
 * Works with inputs like "300-304"
 * Returns an array of numbers like [300, 301, 302, 303, 304].
 */
export function parseRangeToNumbers(inputString: string): number[] {
    const [start, end] = inputString.split('-');

    // Stop parameter passed to range method is exclusive. So, had to do +end + 1.
    return range(+start, +end + 1, 1);
}

/**
 * Parses multiple ',' seperated inputs to individual numbers.
 * Works with values like "300,301,302,303,304"
 * Returns array of numbers like [300, 301, 302, 303, 304].
 */
export function parseStringsToNumbers(inputString: string): number[] {
    return inputString.split(',').map(
        (value: string) => +value,
    );
}

/**
 * Checks if the input string received is a series of consecutive numbers.
 * If numbers are consecutive, converts them into a range.
 * Converts an input like "300, 301, 302, 303" to "300-303".
 */
export function parseNumbersToRange(inputString: string): string {
    let isRange = true;

    const sortedInputValues = inputString.split(',').sort(
        (firstInput: string, otherInput: string) => +firstInput - +otherInput,
    );

    const min = +sortedInputValues[0];

    const max = +sortedInputValues[sortedInputValues.length - 1];

    if (max - min + 1 !== sortedInputValues.length) {
        return inputString;
    }

    for (let i = 0; i < sortedInputValues.length - 1; i++) {
        if (+sortedInputValues[i + 1] - +sortedInputValues[i] !== 1) {
            isRange = false;
            break;
        }
    }

    if (isRange) {
        return `${min}-${max}`;
    } else {
        return inputString;
    }
}

/**
 * Take start and end numbers, return the numbers within the (closing) range.
 */
export function parseStartEndToNumbers(start: number, end: number): number[] {
    const rangeString = `${start}-${end}`;

    return parseRangeToNumbers(rangeString);
}

/**
 * Works with inputs like: [[1,2,3],5] or [1,3,5,6,7] or [[1,2,3],5,7,[8,9,10]].
 * Flattens the input array and converts array element to range [[1,2,3]] -> ["1-3"]
 */
export function flattenArray(
    inputArray: Array<number | number[]>,
): string[] {
    const flattenedArray = [];

    for (const key of inputArray) {
        if (!Array.isArray(key)) {
            flattenedArray.push(key.toString());
        } else {
            const startingRangeValue = key[0];
            const endRangeValue = key[key.length - 1];

            flattenedArray.push(`${startingRangeValue}-${endRangeValue}`);
        }
    }

    return flattenedArray;
}
