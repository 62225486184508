/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import {
    MyAccountModalComponent,
    MyAccountModalService,
    MyAccountPreviewComponent,
    TenantModalComponent,
    UserAccountPasswordStrengthDirective,
    UserProfileModalComponent,
} from '.';

import {
    systemConfigServiceProvider,
    userProfileCollectionProvider,
} from './ajs-upgraded-providers';

const accountComponents = [
    MyAccountModalComponent,
    MyAccountPreviewComponent,
    TenantModalComponent,
    UserProfileModalComponent,
];

const accountDirectives = [
    UserAccountPasswordStrengthDirective,
];

/**
 * @description
 *      Angular Module for Accounts related components.
 * @author Suraj Kumar, Kondiparthi Shanmukha Sarath
 */
@NgModule({
    declarations: [
        ...accountComponents,
        ...accountDirectives,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
    ],
    providers: [
        MyAccountModalService,
        systemConfigServiceProvider,
        userProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AccountsModule {}
