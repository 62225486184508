/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'certificate-expander';
const prefix = `${moduleName}.${componentName}`;

export const revocationReasonLabel = `${prefix}.revocationReasonLabel`;
export const ocspResponseFromResponderUrlLabel = `${prefix}.ocspResponseFromResponderUrlLabel`;
export const nextUpdateLabel = `${prefix}.nextUpdateLabel`;
export const certStatusLabel = `${prefix}.certStatusLabel`;
export const revocationTimeLabel = `${prefix}.revocationTimeLabel`;
export const thisUpdateLabel = `${prefix}.thisUpdateLabel`;

export const ENGLISH = {
    [revocationReasonLabel]: 'Revocation Reason',
    [ocspResponseFromResponderUrlLabel]: 'OCSP Response from Responder URL',
    [nextUpdateLabel]: 'Next Update',
    [certStatusLabel]: 'Cert Status',
    [revocationTimeLabel]: 'Revocation Time',
    [thisUpdateLabel]: 'This Update',
};
