/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './waf-crs-list.l10n';
import './waf-crs-list.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @memberOf module:aviApp
 * @typedef {Object} ICrsUpdate
 * @property {string} name
 * @property {string} url
 * @property {string} release_date
 * @property {string} version
 */

/**
 * @ngdoc component
 * @name wafCrsList
 * @description WAF CRS page for uploading CRS rules.
 */
class WafCrsListController {
    constructor(
        WafCrsCollection,
        latestWafCrsVersionService,
        l10nService,
    ) {
        this.WafCrsCollection_ = WafCrsCollection;

        /**
         * @protected
         */
        this.latestWafCrsVersionService_ = latestWafCrsVersionService;

        /**
         * @type {ICrsUpdate|null}
         */
        this.newCrsInfo = null;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Creates the gridConfig and creates an instance of WafCrsCollection.
     */
    $onInit() {
        this.wafCrsCollection = new this.WafCrsCollection_({
            params: {
                fields: 'name,release_date,description,tenant_ref',
            },
        });

        const { objectName } = this.wafCrsCollection;
        const { l10nService_: l10nService } = this;

        this.gridConfig = {
            id: `${objectName}-list-page`,
            collection: this.wafCrsCollection,
            fields: [
                {
                    name: 'name',
                    title: l10nService.getMessage(l10nKeys.columnTitleName),
                    template: '{{::row.getName()}}',
                },
                {
                    name: 'date',
                    title: l10nService.getMessage(l10nKeys.columnTitleReleaseDate),
                    template: '{{::row.getConfig().release_date}}',
                },
            ],
            expandedContainerTemplate: '<pre>{{::row.getConfig().description}}</pre>',
            permission: 'PERMISSION_WAFCRS',
            multipleactions: [{
                title: l10nService.getMessage(l10nKeys.actionTitleDelete),
                do: rows => {
                    this.wafCrsCollection.dropItems(rows);

                    return true;
                },
            }],
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.actionTitleDelete),
                class: 'icon-trash',
                do: row => {
                    this.wafCrsCollection.dropItems(row);

                    return true;
                },
            }],
        };

        this.latestWafCrsVersionService_.getLatestVersions().then(this.handleCrsUpdate_);
    }

    /**
     * Method to populate newCrsInfo object if a latest portal CRS
     * does not match latest controller CRS
     * @param {ICRSDetails[]} crsUpdateData - Array containing latest portal Crs
     *    and latest controller CRS
     * @protected
     */
    handleCrsUpdate_ = ([latestPortalCrsInfo, latestControllerCrsInfo]) => {
        this.newCrsInfo = null;

        if (latestPortalCrsInfo) {
            const portalCrsReleaseDate = new Date(latestPortalCrsInfo.release_date);
            const controllerCrsReleaseDate = new Date(latestControllerCrsInfo.release_date);

            if (portalCrsReleaseDate > controllerCrsReleaseDate) {
                this.newCrsInfo = latestPortalCrsInfo;
            }
        }
    }

    /**
     * Called when a user has selected a file from a local machine.
     * @param {string} file - JSON stringified file contents.
     */
    handleFileLoad(file) {
        const promise = this.wafCrsCollection.uploadWafCrs(file);

        promise.then(() => {
            this.wafCrsCollection.load();
            this.latestWafCrsVersionService_.getLatestVersions().then(this.handleCrsUpdate_);
        });

        return promise;
    }

    /**
     * Click handler for new CRS update popup close button.
     */
    closeCrsAlert() {
        this.newCrsInfo = null;
    }

    /**
     * Destroys the WafCrsCollection instance, cancelling any pending requests.
     */
    $onDestroy() {
        this.wafCrsCollection.destroy();
    }
}

WafCrsListController.$inject = [
    'WafCrsCollection',
    'latestWafCrsVersionService',
    'l10nService',
];

angular.module('aviApp').component('wafCrsList', {
    controller: WafCrsListController,
    templateUrl: 'src/components/pages/templates/waf/waf-crs-list/waf-crs-list.html',
});
