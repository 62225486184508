/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    isEmpty,
    isUndefined,
} from 'underscore';

import { IIpAddr } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './vip-public-ip-config.l10n';
import './vip-public-ip-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description VIP -> Public IP (Floating IP) Configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vip-public-ip-config',
    templateUrl: './vip-public-ip-config.component.html',
})
export class VipPublicIpConfigComponent implements OnInit {
    /**
     * UUID of the cloud on which this VIP is being configured.
     */
    @Input()
    public cloudId: string;

    /**
     * Support for auto allocate floating IP. Default is true.
     * If false, radio button for auto allocate will not be shown.
     */
    @Input()
    public supportAutoAllocateFloatingIp = true;

    /**
     * Binding for vip#auto_allocate_floating_ip field.
     */
    @Input()
    public autoAllocateFloatingIp: boolean | undefined;

    /**
     * Support for static value in floating IP. Default is true.
     * If false, radio button for static will not be shown.
     */
    @Input()
    public supportStaticFloatingIp = true;

    /**
     * Binding for vip#floating_ip_address (IPv4) field.
     */
    @Input()
    public floatingIpAddress: IIpAddr | undefined;

    /**
     * Subnet UUID for the floating ip address allocation.
     */
    @Input()
    public floatingIpSubnetId: string | undefined;

    /**
     * Subnet UUID for the floating ipv6 address allocation.
     */
    @Input()
    public floatingIp6SubnetId: string | undefined;

    /**
     * NetworkRef of VIP.
     */
    @Input()
    public networkRef?: string;

    /**
     * If true, Subnet selection will be displayed for floating ip(public ip).
     */
    @Input()
    public allowSubnetSelection = false;

    /**
     * Change emitter for vip#auto_allocate_floating_ip field (for two way binding).
     */
    @Output()
    public autoAllocateFloatingIpChange = new EventEmitter<boolean | undefined>();

    /**
     * Change emitter for vip#floating_ip_address field (for two way binding).
     */
    @Output()
    public floatingIpAddressChange = new EventEmitter<IIpAddr | undefined>();

    /**
     * Change emitter for vip#floating_subnet_uuid field (for two way binding).
     */
    @Output()
    public floatingIpSubnetIdChange = new EventEmitter<string | undefined>();

    /**
     * Change emitter for vip#floating_subnet6_uuid field (for two way binding).
     */
    @Output()
    public floatingIp6SubnetIdChange = new EventEmitter<string | undefined>();

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectType of VIPConfigItem.
     */
    public readonly objectType = 'Vip';

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * If autoAllocateFloatingIp is false && floatingIpAddress is empty/undefined,
     * then selection should be `None` (ie autoAllocateFloatingIp = undefined).
     * @override
     */
    public ngOnInit(): void {
        if (!this.autoAllocateFloatingIp && isEmpty(this.floatingIpAddress)) {
            this.autoAllocateFloatingIp = undefined;
        }
    }

    /**
     * If true, floating ip address field will be shown.
     * If user selects `None`, autoAllocateFloatingIp will be undefined (no autoAllocate/no static)
     * in that case, we dont have to show the input.
     */
    public get showFloatingIpInput(): boolean {
        return !isUndefined(this.autoAllocateFloatingIp);
    }

    /**
     * Fires on public ip - allocation type change.
     */
    public ipAllocationTypeChange(): void {
        // if `autoAllocateFloatingIp` is true, IP will be allocated automatically.
        // if `autoAllocateFloatingIp` is undefined, public_ip is not configured.
        // so for the above cases, we set `floatingIpAddress` to undefined.
        if (this.autoAllocateFloatingIp || isUndefined(this.autoAllocateFloatingIp)) {
            this.floatingIpAddress = undefined;
        }

        this.autoAllocateFloatingIpChange.emit(this.autoAllocateFloatingIp);
        this.onFloatingIpChange();
    }

    /**
     * Fires on public (floating) ip address change.
     */
    public onFloatingIpChange(): void {
        this.floatingIpAddressChange.emit(this.floatingIpAddress);
    }

    /**
     * Fires floating ip subnet uuid change.
     */
    public onFloatingIpSubnetIdChange(): void {
        this.floatingIpSubnetIdChange.emit(this.floatingIpSubnetId);
    }

    /**
     * Fires floating ip subnet6 uuid change.
     */
    public onFloatingIpSubnet6IdChange(): void {
        this.floatingIp6SubnetIdChange.emit(this.floatingIp6SubnetId);
    }

    /**
     * If true, FIP Subnet select component will be rendered.
     */
    public get showFipSubnetSelection(): boolean {
        return this.allowSubnetSelection && Boolean(this.autoAllocateFloatingIp);
    }
}
