/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'host-hdr-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const hostHdrLabel = `${prefix}.hostHdrLabel`;

export const ENGLISH = {
    [hostHdrLabel]: 'Host Header',
};
