/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AutoScalePolicyModalComponent } from 'ng/modules/auto-scale';
import { AUTO_SCALE_POLICY_ITEM_TOKEN } from './auto-scale-policy.item.factory';

/**
 * Ajs dependency token for AutoScalePolicy collection.
 */
export const AUTO_SCALE_POLICY_COLLECTION_TOKEN = 'AutoScalePolicyCollection';

/**
 * @description AutoScale policy collection
 *
 * @author Ram Pal, Nisar Nadaf
 */
export class AutoScalePolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'serverautoscalepolicy',
            windowElement: AutoScalePolicyModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(AUTO_SCALE_POLICY_ITEM_TOKEN);
    }
}

AutoScalePolicyCollection.ajsDependencies = [
    AUTO_SCALE_POLICY_ITEM_TOKEN,
];
