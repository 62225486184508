/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ssl-profile-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleAcceptedVersions = `${prefix}.columnTitleAcceptedVersions`;
export const applicationProfileButtonLabel = `${prefix}.applicationProfileButtonLabel`;
export const systemProfileButtonLabel = `${prefix}.systemProfileButtonLabel`;
export const editActionTitle = `${prefix}.editActionTitle`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleAcceptedVersions]: 'Accepted Versions',
    [applicationProfileButtonLabel]: 'Application Profile',
    [systemProfileButtonLabel]: 'System Profile',
    [editActionTitle]: 'Edit',
};
