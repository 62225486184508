/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHostHdrMatch } from 'generated-types';
import { HostHdrMatch } from 'object-types';
import { GenericHdrMatchBaseConfigItem } from './generic-hdr-match-base.config-item';

/**
 * @description  HostHdrMatch ConfigItem class.
 * @author Akul Aggarwal, Abhinesh Gour
 */
export class HostHdrMatchConfigItem extends GenericHdrMatchBaseConfigItem<IHostHdrMatch> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HostHdrMatch,
            ...args,
        };

        super(extendedArgs);
    }
}
