/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { WafMode } from 'generated-types';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-mode.l10n';
import './waf-mode.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component to display the WAF mode setting. Can either be set directly on the rule or be
 *     inherited from the parent.
 * @author alextsg
 */
@Component({
    selector: 'waf-mode',
    templateUrl: './waf-mode.component.html',
})
export class WafModeComponent {
    /**
     * Configured mode setting on the parent WAF Policy.
     */
    @Input()
    public parentMode: WafMode;

    /**
     * Configured mode setting on the WAF rule. A setter is used here as the mode can change during
     * configuration.
     */
    @Input('mode')
    private set setMode(mode: WafMode | undefined) {
        if (mode) {
            this.wafModeSettingLabel = this.modeLabelMap.get(mode);
        } else {
            this.wafModeSettingLabel = this.l10nService.getMessage(
                l10nKeys.useParentModeValueLabel,
                [this.modeLabelMap.get(this.parentMode)],
            );
        }
    }

    /**
     * Label for the mode setting.
     */
    @Input()
    public label: string;

    /**
     * String representation of the configured mode.
     */
    public wafModeSettingLabel: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Map of the WAF mode enum to the string to display.
     */
    private modeLabelMap = new Map<WafMode, string>();

    constructor(
        private l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const detectionModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            WafMode.WAF_MODE_DETECTION_ONLY,
        ).label;

        const enforcementModeEnumValue = schemaService.getEnumValue(
            'WafMode',
            WafMode.WAF_MODE_ENFORCEMENT,
        ).label;

        this.modeLabelMap.set(WafMode.WAF_MODE_DETECTION_ONLY, detectionModeEnumValue);
        this.modeLabelMap.set(WafMode.WAF_MODE_ENFORCEMENT, enforcementModeEnumValue);
    }
}
