/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { TemplateRef } from '@angular/core';

export type IAviDataGridRow = any;
export type TStringRow = Record<'value', string>;
export type TAviDataGridRowComparator = (row1: IAviDataGridRow, row2: IAviDataGridRow) => number;

/**
 * A field column is shown when marked with true in the hash, hidden otherwise.
 * Keys of the map are field IDs.
 */
export type TAviDataGridColumnPresenceMap = Record<string, boolean>;

/**
 * Visibility for a field to decide the hide/show behavior of the corresponding column.
 */
export enum AviDataGridFieldVisibility {
    // always shown, can't be hidden
    MANDATORY = 'mandatory',
    // initially hidden
    OPTIONAL = 'optional',
    // initially shown
    // if no visibility is set for a field, it'll be treated as 'default' and is initially shown
    DEFAULT = 'default',
}

export interface IAviDataGridLayout {
    showFooter?: boolean;
    hideCheckboxes?: boolean;
    placeholderMessage?: string;
    withReordering?: boolean;
    hideSearch?: boolean;
    searchInputPlaceholder?: string;
    hidePagination?: boolean;
}

export interface IAviDataGridConfigField {
    label: string;
    id: string;
    // sortBy string: property name | sortBy function: comparator
    sortBy?: string | TAviDataGridRowComparator;
    visibility?: AviDataGridFieldVisibility;
    templateRef?: TemplateRef<HTMLElement>;
    transform?(row: IAviDataGridRow): string;
}

export interface IAviDataGridMultipleaction {
    label: string;
    disabled?(rows: IAviDataGridRow[]): boolean;
    onClick(rows: IAviDataGridRow[]): void;
}

export interface IAviDataGridSingleaction {
    label: string;
    shape: string;
    onClick(row: IAviDataGridRow): void;
    disabled?(row: IAviDataGridRow): boolean;
}

export interface IAviDataGridConfig {
    fields: IAviDataGridConfigField[];
    layout?: IAviDataGridLayout;
    multipleactions?: IAviDataGridMultipleaction[];
    singleactions?: IAviDataGridSingleaction[];

    /**
     * Content shown under a row when the row's expand button is clicked.
     */
    expandedContentTemplateRef?: TemplateRef<HTMLElement>;
    getRowId(index: number, row: IAviDataGridRow): string | number;
    rowSelectionDisabled?(row: IAviDataGridRow): boolean;
}

export interface IRowReorder {
    row: IAviDataGridRow;
    currentIndex: number;
    previousIndex: number;
}
