/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'static-route-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitlePrefix = `${prefix}.columnTitlePrefix`;
export const columnTitleNextHop = `${prefix}.columnTitleNextHop`;
export const defaultGatewayLabel = `${prefix}.defaultGatewayLabel`;
export const editBtnTooltip = `${prefix}.editBtnTooltip`;
export const deleteBtnTooltip = `${prefix}.deleteBtnTooltip`;
export const vrfContextHeader = `${prefix}.vrfContextHeader`;

export const ENGLISH = {
    [columnTitlePrefix]: 'Prefix',
    [columnTitleNextHop]: 'Next Hop',
    [defaultGatewayLabel]: 'Default Gateway',
    [editBtnTooltip]: 'Edit',
    [deleteBtnTooltip]: 'Delete',
    [vrfContextHeader]: 'VRF Context: {0}',
};
