/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'service-engine-list';
const prefix = `${moduleName}.${componentName}`;

export const rebootBtnLabel = `${prefix}.rebootBtnLabel`;
export const rebootConfirmMessage = `${prefix}.rebootConfirmMessage`;
export const enableBtnLabel = `${prefix}.enableBtnLabel`;
export const disableBtnLabel = `${prefix}.disableBtnLabel`;
export const disabelForVsPlacementBtnLabel = `${prefix}.disabelForVsPlacementBtnLabel`;
export const deleteBtnLabel = `${prefix}.deleteBtnLabel`;
export const columnTitleEnableState = `${prefix}.columnTitleEnableState`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleHealth = `${prefix}.columnTitleHealth`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const columnTitleUptime = `${prefix}.columnTitleUptime`;
export const columnTitleSeGroup = `${prefix}.columnTitleSeGroup`;
export const columnTitleManagementIp = `${prefix}.columnTitleManagementIp`;
export const columnTitleVirtualServices = `${prefix}.columnTitleVirtualServices`;
export const columnTitleAvailabilityZone = `${prefix}.columnTitleAvailabilityZone`;
export const columnTitleHost = `${prefix}.columnTitleHost`;
export const columnTitleAlerts = `${prefix}.columnTitleAlerts`;
export const columnTitleVersion = `${prefix}.columnTitleVersion`;
export const tryForceDeleteConfirmMessage = `${prefix}.tryForceDeleteConfirmMessage`;
export const naLabel = `${prefix}.naLabel`;
export const virtualServicesHeader = `${prefix}.virtualServicesHeader`;
export const emptyListMessage = `${prefix}.emptyListMessage`;

export const ENGLISH = {
    [rebootBtnLabel]: 'Reboot',
    [rebootConfirmMessage]: 'Reboot selected Service Engine(s)?',
    [enableBtnLabel]: 'Enable',
    [disableBtnLabel]: 'Disable',
    [disabelForVsPlacementBtnLabel]: 'Disable for VS Placement',
    [deleteBtnLabel]: 'delete',
    [columnTitleEnableState]: 'Enable State',
    [columnTitleName]: 'Name',
    [columnTitleHealth]: 'Health',
    [columnTitleStatus]: 'Status',
    [columnTitleUptime]: 'Uptime',
    [columnTitleSeGroup]: 'Service Engine Group',
    [columnTitleManagementIp]: 'Management IP',
    [columnTitleVirtualServices]: '# Virtual Services',
    [columnTitleAvailabilityZone]: 'Availability Zone',
    [columnTitleHost]: 'Host',
    [columnTitleAlerts]: 'Alerts',
    [columnTitleVersion]: 'Version',
    [tryForceDeleteConfirmMessage]: '{0}. Try force delete?',
    [naLabel]: 'N/A',
    [virtualServicesHeader]: 'Virtual Services',
    [emptyListMessage]: 'None',
};
