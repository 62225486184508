/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function gridCtrlRowsSortingMixin(
        naturalSort,
        classMixin,
        propLookup,
) {
    /**
     * Returns function to be used for rows sorting based on certain properties comparison.
     * @param {string} propPath
     * @returns {Function} To be passed into Array.prototype.sort.
     * @inner
     */
    function getDefaultSortFunc(propPath) {
        return function defaultStringSort(objA, objB) {
            const
                a = propLookup(propPath, objA),
                b = propLookup(propPath, objB);

            return naturalSort(a, b);
        };
    }

    /**
     * For reverse order we negate any sorting function results.
     * @param {Function} sortingFunction
     * @returns {Function} - Function to be passed into Array.prototype.sort.
     * @inner
     */
    function negateSortFunctionResults(sortingFunction) {
        return (a, b) => -1 * sortingFunction(a, b);
    }

    const mixin = {
        /**
         * Keeps current sorting setting - can be either Function or string. There is no way
         * to switch sorting off if it had been set in the past.
         * @type {undefined|string|Function}
         * @public
         */
        currentSortFieldName: '',

        /**
         * Asc/desc ordering switch. Also used in template.
         * @type {boolean}
         * @public
         */
        reverseOrdering: false,

        /**
         * Sets sorting properties and sorts filteredRows.
         * @param {string} fieldName - String to find a certain row property,
         *     sorting function or undefined to reinstate the currently applied sorting.
         * @param {boolean=} reverseOrder - When passed reversedOrder will be set accordingly.
         */
        setSorting(fieldName, reverseOrder) {
            if (this.reorderingAvailable()) {
                console.error('Sorting can\'t be used if rows reordering is available');
            }

            this.setSorting_(fieldName, reverseOrder);
            this.sortRows_();
        },

        /**
         * Sets sorting properties to the values passed. Doesn't sort anything.
         * @param {string} fieldName
         * @param {boolean} reverseOrder
         * @protected
         */
        setSorting_(fieldName, reverseOrder) {
            if (fieldName && angular.isString(fieldName)) {
                if (fieldName) {
                    if (this.currentSortFieldName === fieldName) {
                        this.reverseOrdering = !this.reverseOrdering;
                    } else {
                        this.currentSortFieldName = fieldName;
                        this.reverseOrdering = false;
                    }
                }

                //when explicitly passed will use it
                if (!angular.isUndefined(reverseOrder)) {
                    this.reverseOrdering = !!reverseOrder;
                }
            }
        },

        /**
         * Sorts filteredRows according to the currentSortFieldName and reverseOrdering
         * properties.
         * @protected
         */
        sortRows_() {
            const { currentSortFieldName: fieldName } = this;

            if (!fieldName) {
                return;
            }

            const { sortBy } = _.findWhere(this.config.fields, { name: fieldName });

            let sortFunc;

            if (angular.isFunction(sortBy)) {
                sortFunc = sortBy;
            } else if (angular.isString(sortBy)) {
                //support for nested properties like "smth.bar.foo"
                sortFunc = getDefaultSortFunc(sortBy);
            } else {
                console.error('sortBy is not set on the field config');

                return;
            }

            if (this.reverseOrdering) { //for reverse order same but negated function is used
                sortFunc = negateSortFunctionResults(sortFunc);
            }

            this.filteredRows.sort(sortFunc);
        },
    };

    return GridCtrl => classMixin(GridCtrl, angular.copy(mixin));
}

gridCtrlRowsSortingMixin.$inject = [
    'naturalSort',
    'classMixin',
    'propLookup',
];

/**
 * @ngdoc service
 * @name gridCtrlRowsSortingMixin
 * @type {Function}
 * @mixin
 * @desc
 *
 *     Grid table rows sorting methods and properties.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid')
    .factory('gridCtrlRowsSortingMixin', gridCtrlRowsSortingMixin);
