/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'vrf-context-modal';
const prefix = `${moduleName}.${componentName}`;

export const editVrfContextLabel = `${prefix}.editVrfContextLabel`;
export const newVrfContextLabel = `${prefix}.newVrfContextLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const bfdParametersHeader = `${prefix}.bfdParametersHeader`;
export const detectionMultiplierInputLabel = `${prefix}.detectionMultiplierInputLabel`;
export const minimumTransmitIntervalInputLabel = `${prefix}.minimumTransmitIntervalInputLabel`;
export const minimumReceiveIntervalInputLabel = `${prefix}.minimumReceiveIntervalInputLabel`;
export const msInnerText = `${prefix}.msInnerText`;

export const ENGLISH = {
    [editVrfContextLabel]: 'Edit VRF Context: {0}',
    [newVrfContextLabel]: 'New VRF Context: {0}',
    [nameInputLabel]: 'Name',
    [bfdParametersHeader]: 'BFD Parameters',
    [detectionMultiplierInputLabel]: 'Detection Multiplier',
    [minimumTransmitIntervalInputLabel]: 'Minimum Transmit Interval',
    [minimumReceiveIntervalInputLabel]: 'Minimum Receive Interval',
    [msInnerText]: 'ms',
};
