/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'tcp-fast-path';
const prefix = `${moduleName}.${componentName}`;

export const sessionIdleTimeoutInputLabel = `${prefix}.sessionIdleTimeoutInputLabel`;
export const secondsHelperText = `${prefix}.secondsHelperText`;
export const idleTimeoutInputPlaceholder = `${prefix}.idleTimeoutInputPlaceholder`;

export const ENGLISH = {
    [sessionIdleTimeoutInputLabel]: 'Session Idle Timeout',
    [secondsHelperText]: 'Seconds',
    [idleTimeoutInputPlaceholder]: 'Enter Session Idle Timeout',
};
