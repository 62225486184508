/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './ServerAutoScaleListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name ServerAutoScaleListController
 * @description Controller for Server AutoScale page.
 **/
angular.module('aviApp').controller('ServerAutoScaleListController', [
'$scope',
'CRUDGridConfig',
'AutoScalePolicyCollection',
'Regex',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    AutoScalePolicyCollection,
    Regex,
    l10nService,
) {
    this.Regex = Regex;

    this.collection = new AutoScalePolicyCollection();

    this.gridConfig = new CRUDGridConfig();

    this.gridConfig.collection = this.collection;

    /**
    * Get keys from source bundles for template usage.
    */
    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const { objectName } = this.gridConfig.collection;

    this.gridConfig.id = `${objectName}-list-page`;

    this.gridConfig.props = { l10nKeys };

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }, {
        name: 'data.config.min_size',
        title: l10nService.getMessage(l10nKeys.columnTitleMinSize),
        template:
            '{{ row.getConfig().min_size || (config.props.l10nKeys.emptyDataLabel | vtranslate) }}',
    }, {
        name: 'data.config.max_size',
        title: l10nService.getMessage(l10nKeys.columnTitleMaxSize),
        template:
            '{{ row.getConfig().max_size || (config.props.l10nKeys.emptyDataLabel | vtranslate) }}',
    }, {
        name: 'data.config.intelligent_autoscale',
        title: l10nService.getMessage(l10nKeys.columnTitleIntelligentAutoScale),
        template: '{{ row.getConfig().intelligent_autoscale ? ' +
            'config.props.l10nKeys.enabledDataLabel : ' +
            'config.props.l10nKeys.disabledDataLabel | vtranslate}}',
    }];
}]);
