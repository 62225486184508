/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

export const WAF_PROFILE_ITEM_TOKEN = 'WafProfileItem';
export const WAF_PROFILE_COLLECTION_TOKEN = 'WafCollectionItem';
export const WAF_DATA_FILE_CONFIG_ITEM_TOKEN = 'WafDataFileConfigItem';
