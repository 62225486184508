/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SsoPolicyModule
 */

import { Injector } from '@angular/core';

const $injector = '$injector';

export const authProfileCollectionProvider = {
    deps: [$injector],
    provide: 'AuthProfileCollection',
    useFactory(injector: Injector): any {
        return injector.get('AuthProfileCollection');
    },
};
