/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'webhook';
const componentName = 'webhook-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const generalHeader = `${prefix}.generalHeader`;
export const nameFieldLabel = `${prefix}.nameFieldLabel`;
export const nameFieldPlaceholder = `${prefix}.nameFieldPlaceholder`;
export const callbackUrlFieldLabel = `${prefix}.callbackUrlFieldLabel`;
export const callbackUrlFieldPlaceholder = `${prefix}.callbackUrlFieldPlaceholder`;
export const verificationTokenFieldLabel = `${prefix}.verificationTokenFieldLabel`;
export const verificationTokenFieldPlaceholder = `${prefix}.verificationTokenFieldPlaceholder`;
export const descriptionFieldLabel = `${prefix}.descriptionFieldLabel`;
export const descriptionFieldPlaceholder = `${prefix}.descriptionFieldPlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Webhook: {0}',
    [modalTitleNew]: 'New Webhook: {0}',
    [generalHeader]: 'General',
    [nameFieldLabel]: 'Name',
    [nameFieldPlaceholder]: 'Enter Name',
    [callbackUrlFieldLabel]: 'Callback URL',
    [callbackUrlFieldPlaceholder]: 'Enter URL',
    [verificationTokenFieldLabel]: 'Verification Token',
    [verificationTokenFieldPlaceholder]: 'Enter Token',
    [descriptionFieldLabel]: 'Description',
    [descriptionFieldPlaceholder]: 'Enter Description',
};
