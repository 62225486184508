/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IManagementNetworkConfig, NsxtTransportZoneType } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { Tier1LogicalRouterInfoConfigItem } from '.';

type TManagementNetworkConfigConfigPartial = Omit<IManagementNetworkConfig, 'overlay_segment'>;

interface IManagementNetworkConfigConfig extends TManagementNetworkConfigConfigPartial {
    overlay_segment?: Tier1LogicalRouterInfoConfigItem;
}

export const MANAGEMENT_NETWORK_CONFIG_CONFIG_ITEM_TOKEN = 'ManagementNetworkConfigConfigItem';

export class ManagementNetworkConfigConfigItem extends MessageItem<IManagementNetworkConfigConfig> {
    /** @constructor */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'ManagementNetworkConfig',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for data transport zone type overlay or vlan.
     * Return undefined if not configured.
     */
    public get transportZoneType(): NsxtTransportZoneType | undefined {
        return this.config.tz_type;
    }

    /**
     * Setter for transport zone type.
     */
    public set transportZoneType(type: NsxtTransportZoneType) {
        this.config.tz_type = type;
    }

    /**
     * Getter for the name of transport zone path for avi service engines.
     * Return undefined if not configured.
     */
    public get transportZone(): string | undefined {
        return this.config.transport_zone;
    }

    /**
     * Setter for transport zone.
     */
    public set transportZone(transportZone: string) {
        this.config.transport_zone = transportZone;
    }

    /**
     * Get the VLAN segment path.
     * Defined only when transport zone type is VLAN.
     */
    public get vlanSegment(): string | undefined {
        return this.config.vlan_segment;
    }

    /**
     * Set the VLAN segment path.
     * Valid only when transport zone type is VLAN.
     */
    public set vlanSegment(vlanSegment: string) {
        if (!this.isTransportZoneType(NsxtTransportZoneType.VLAN)) {
            throw new Error('vlan_segment is configurable only for VLAN transport zone type.');
        }

        this.config.vlan_segment = vlanSegment;
    }

    /**
     * Get the Tier1LogicalRouterInfo segment object.
     * Defined only when transport zone type is OVERLAY.
     */
    public get overlaySegment(): Tier1LogicalRouterInfoConfigItem | undefined {
        return this.config.overlay_segment;
    }

    /**
     * Check current transport zone type against the given one.
     */
    public isTransportZoneType(type: NsxtTransportZoneType): boolean {
        return this.transportZoneType === type;
    }

    /**
     * Remove the configured transport zone and related data.
     */
    public clearTransportZoneData(): void {
        this.config.transport_zone = undefined;
        this.config.tz_type = undefined;

        this.onTransportZoneChange();
    }

    /**
     * Set or remove fields when transport zone changes.
     */
    public onTransportZoneChange(): void {
        this.resetFieldsByTranportZoneType();
    }

    /**
     * Reset fields that are mutually exclusive based on transport zone type.
     */
    private resetFieldsByTranportZoneType(): void {
        const { tz_type: tzType } = this.config;

        switch (tzType) {
            case NsxtTransportZoneType.OVERLAY:
                if (this.overlaySegment) {
                    this.overlaySegment.clearFields();
                } else {
                    this.safeSetNewChildByField('overlay_segment');
                }

                delete this.config.vlan_segment;
                break;

            case NsxtTransportZoneType.VLAN:
                this.config.vlan_segment = '';
                delete this.config.overlay_segment;
                break;

            default:
                delete this.config.vlan_segment;
                delete this.config.overlay_segment;
        }
    }
}
