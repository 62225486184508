/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-checkpoints-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const timestampLabel = `${prefix}.timestampLabel`;
export const nameLabel = `${prefix}.nameLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Checkpoint: {0}',
    [modalTitleNew]: 'New Checkpoint: {0}',
    [timestampLabel]: 'Timestamp',
    [nameLabel]: 'Name',
    [descriptionLabel]: 'Description',
};
