/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'alert-config-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleAlertAction = `${prefix}.columnTitleAlertAction`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleAlertGroup = `${prefix}.columnTitleAlertGroup`;
export const columnTitleEnabled = `${prefix}.columnTitleEnabled`;
export const columnTitleObject = `${prefix}.columnTitleObject`;
export const notSeparator = `${prefix}.notSeparator`;
export const allInstancesObjectName = `${prefix}.allInstancesObjectName`;
export const emptySearchAlertsConfigMessage = `${prefix}.emptySearchAlertsConfigMessage`;
export const emptyAlertConfigMessage = `${prefix}.emptyAlertConfigMessage`;
export const trueText = `${prefix}.trueText`;
export const falseText = `${prefix}.falseText`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleAlertAction]: 'Alert Action',
    [emptyDataLabel]: '-None-',
    [columnTitleType]: 'Type',
    [columnTitleAlertGroup]: 'Alert Group',
    [columnTitleEnabled]: 'Enabled',
    [columnTitleObject]: 'Object',
    [notSeparator]: ', not ',
    [allInstancesObjectName]: 'All Instances',
    [emptySearchAlertsConfigMessage]: 'No Alert Configs match your search criteria.',
    [emptyAlertConfigMessage]: 'No Alert Configs found.',
    [trueText]: 'True',
    [falseText]: 'False',
};
