/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { WelcomeService } from '../../services';

/**
 * @description
 *
 *     Container component for welcome modal.
 *
 *     This component has empty template.
 *     but its used a ui-router route and is responsible for opening WelcomeModal.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'welcome-modal-container',
    template: '',
})
export class WelcomeModalContainerComponent implements OnInit {
    /**
     * Subscription to the welcomeModal complete subject.
     */
    private welcomeFlowCompleteSubscription: Subscription;

    constructor(private readonly welcomeService: WelcomeService) {}

    /** @override */
    public ngOnInit(): void {
        this.subscribeToWelcomeFlowCompleteEvent();
        this.openWelcomeModal();
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.welcomeService.isWelcomeModalOpen()) {
            this.welcomeService.closeModal();
        }

        this.welcomeFlowCompleteSubscription.unsubscribe();
    }

    /**
     * Opens welcome modal and subscribes to welcomeflow complete event.
     */
    private async openWelcomeModal(): Promise<void> {
        await this.welcomeService.openWelcomeModal();
    }

    /**
     * Subscribes to welcomeflow complete event.
     */
    private subscribeToWelcomeFlowCompleteEvent(): void {
        this.welcomeFlowCompleteSubscription =
            this.welcomeService.welcomeCompleteSubject$.subscribe(this.onWelcomeComplete);
    }

    /**
     * Callback for welcome flow complete event.
     * Closes welcome modal and navigates the user to next state.
     */
    private onWelcomeComplete = (openCloudModal: boolean): void => {
        try {
            this.welcomeService.closeModal();

            if (openCloudModal) {
                this.welcomeService.navigateToCloudList();
            } else {
                this.welcomeService.navigateToAppDefaultState();
            }
        } catch (e) {
            console.error(e);
        }
    };
}
