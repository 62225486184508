/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { HSMSafenetLunaServer } from 'object-types';
import { IHSMSafenetLunaServer } from 'generated-types';
import { HsmGroupSafenetLunaServerModalComponent } from 'ng/modules/security';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './hsm-group.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description HSM Safenet Luna Server Message Item.
 * @author vgohil
 */
export class HSMSafenetLunaServerConfigItem extends
    withFullModalMixin(MessageItem)<IHSMSafenetLunaServer> {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * L10nService instance for translation.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: HSMSafenetLunaServer,
            windowElement: HsmGroupSafenetLunaServerModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.safenetLunaServerModalBreadcrumbTitle);
    }
}
