/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ip-reputation-type-match-controller';
const prefix = `${moduleName}.${componentName}`;

export const andText = `${prefix}.andText`;
export const ipReputationLabel = `${prefix}.ipReputationLabel`;
export const isRadioLabel = `${prefix}.isRadioLabel`;
export const isNotRadioLabel = `${prefix}.isNotRadioLabel`;
export const ipReputationTypeInputLabel = `${prefix}.ipReputationTypeInputLabel`;
export const ipReputationTypeInputPlaceholder = `${prefix}.ipReputationTypeInputPlaceholder`;
export const removeMatchTitle = `${prefix}.removeMatchTitle`;

export const ENGLISH = {
    [andText]: 'and',
    [ipReputationLabel]: 'IP Reputation',
    [isRadioLabel]: 'Is',
    [isNotRadioLabel]: 'Is not',
    [ipReputationTypeInputLabel]: 'IP Reputation Type',
    [ipReputationTypeInputPlaceholder]: 'Select IP Reputation Type',
    [removeMatchTitle]: 'Remove Match',
};
