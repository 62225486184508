/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import './expander-action-confirmation.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './expander-action-confirmation.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @ngdoc component
 * @name ExpanderActionConfirmationComponent
 * @memberOf module:avi/shared
 * @param message - Message to be shown.
 * @param onCancel - Callback for clicking Cancel.
 * @description Component for displaying a message and Cancel/Confirmation buttons. An example
 *     use-case would be verifying that the user would like to delete something before proceeding.
 * @example
 *     <expander-action-confirmation
 *         message="Are you sure you want to delete {{ group.getName() }}?"
 *         (onCancel)="cancelDelete()"
 *     >
 *         <button
 *             confirmation-button
 *             class="btn btn-danger"
 *             (click)="handleDelete()"
 *         >
 *             Delete
 *         </button>
 *     </expander-action-confirmation>
 * @author alextsg
 */
@Component({
    selector: 'expander-action-confirmation',
    templateUrl: './expander-action-confirmation.component.html',
})

export class ExpanderActionConfirmationComponent {
    @Input() public message: string;
    @Output() public onCancel = new EventEmitter();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for clicking Cancel.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }
}
