/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'string-group-or-custom-value';
const prefix = `${moduleName}.${componentName}`;

export const selectFromAvailableLabel = `${prefix}.selectFromAvailableLabel`;
export const enterCustomValueLabel = `${prefix}.enterCustomValueLabel`;
export const selectStringGroupPlaceholder = `${prefix}.selectStringGroupPlaceholder`;

export const ENGLISH = {
    [selectFromAvailableLabel]: 'Select From Available',
    [enterCustomValueLabel]: 'Enter Custom Value',
    [selectStringGroupPlaceholder]: 'Select String Group',
};
