/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const infobloxSubnetConfigItemFactory = MessageItem => {
    /**
     * @constructor
     * @memberOf module:avi/ipam
     * @extends MessageItem
     * @desc InfobloxSubnet ConfigItem class.
     * @author alextsg
     */
    class InfobloxSubnetConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'InfobloxSubnet',
                ...args,
            };

            super(extendedArgs);
        }

        /**
         * @override
         * @protected
         */
        requiredFields() { //eslint-disable-line class-methods-use-this
            return [
                'subnet',
                'subnet6',
            ];
        }

        /**
         * @override
         */
        canFlatten() {
            const { subnet, subnet6 } = this.config;

            return subnet.canFlatten() || subnet6.canFlatten();
        }
    }

    return InfobloxSubnetConfigItem;
};

infobloxSubnetConfigItemFactory.$inject = [
    'MessageItem',
];

angular
    .module('avi/ipam')
    .factory('InfobloxSubnetConfigItem', infobloxSubnetConfigItemFactory);
