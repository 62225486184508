/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './avi-alert.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to display alert message..
 * @author Aravindh Nagarajan, Alex Malitsky, Suraj Kumar
 */
@Component({
    selector: 'avi-alert',
    templateUrl: './avi-alert.component.html',
})
export class AviAlertComponent {
    /**
     * Warning message(s) to be displayed.
     */
    @Input()
    public readonly messages: string | string[];

    /**
     * To be fired on modal close.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Function binding to close modal.
     */
    @Output()
    public closeModal = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Dismisses alert modal.
     */
    public dismiss(): void {
        this.onClose.emit();
        this.closeModal.emit();
    }

    /**
     * Returns true to render the list of alerts.
     */
    public get hasList(): boolean {
        return Array.isArray(this.messages);
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackbyIndex(index: number): number {
        return index;
    }
}
