/**
 * @module PersistenceProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { PersistenceProfileModalComponent } from 'ng/modules/persistence-profile';
import { AviPermissionResource } from 'generated-types';
import { PERSISTENCE_PROFILE_ITEM_TOKEN } from './persistence-profile.item.factory';

/**
 * Ajs dependency token for Persistence profile collection.
 */
export const PERSISTENCE_PROFILE_COLLECTION_TOKEN = 'PersistenceProfileCollection';

/**
 * @description Persistence profile collection.
 * @author Rohit Gaikwad
 */
export class PersistenceProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'applicationpersistenceprofile',
            objectType: 'ApplicationPersistenceProfile',
            permissionName: AviPermissionResource.PERMISSION_APPLICATIONPERSISTENCEPROFILE,
            windowElement: PersistenceProfileModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(PERSISTENCE_PROFILE_ITEM_TOKEN);
    }
}

PersistenceProfileCollection.ajsDependencies = [
    PERSISTENCE_PROFILE_ITEM_TOKEN,
];
