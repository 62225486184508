/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Injector } from '@angular/core';

import {
    DataScriptSet,
    DataScriptSetCollection,
    TDataScriptSet,
    TDataScriptSetCollection,
} from 'ajs/modules/scripts';

import {
    PoolCollection,
    PoolGroupCollection,
} from 'ajs/modules/pool';

import {
    CertificateCollection,
    PKIProfileCollection,
    SSLProfileCollection,
} from 'ajs/modules/security';

import { IPReputationDBCollection } from 'ajs/modules/ip-reputation-db';

const $injector = '$injector';

export const dataScriptSetItemProvider = {
    deps: [$injector],
    provide: DataScriptSet,
    useFactory(injector: Injector): TDataScriptSet {
        return injector.get('DataScriptSet');
    },
};

export const dataScriptSetCollectionProvider = {
    deps: [$injector],
    provide: DataScriptSetCollection,
    useFactory(injector: Injector): TDataScriptSetCollection {
        return injector.get('DataScriptSetCollection');
    },
};

export const ipAddrGroupCollectionProvider = {
    deps: [$injector],
    provide: 'IpAddrGroupCollection',
    useFactory(injector: Injector): any {
        return injector.get('IpAddrGroupCollection');
    },
};

export const stringGroupCollectionProvider = {
    deps: [$injector],
    provide: 'StringGroupCollection',
    useFactory(injector: Injector): any {
        return injector.get('StringGroupCollection');
    },
};

export const protocolParserCollectionProvider = {
    deps: [$injector],
    provide: 'ProtocolParserCollection',
    useFactory(injector: Injector): any {
        return injector.get('ProtocolParserCollection');
    },
};

export const ipReputationDBCollectionProvider = {
    deps: [$injector],
    provide: IPReputationDBCollection,
    useFactory(injector: Injector): typeof IPReputationDBCollection {
        return injector.get('IPReputationDBCollection');
    },
};

export const poolCollectionProvider = {
    deps: [$injector],
    provide: PoolCollection,
    useFactory(injector: Injector): typeof PoolCollection {
        return injector.get('PoolCollection');
    },
};

export const poolGroupCollectionProvider = {
    deps: [$injector],
    provide: PoolGroupCollection,
    useFactory(injector: Injector): typeof PoolGroupCollection {
        return injector.get('PoolGroupCollection');
    },
};

export const sslProfileCollectionProvider = {
    deps: [$injector],
    provide: SSLProfileCollection,
    useFactory(injector: Injector): typeof SSLProfileCollection {
        return injector.get('SSLProfileCollection');
    },
};

export const certificateCollectionProvider = {
    deps: [$injector],
    provide: CertificateCollection,
    useFactory(injector: Injector): typeof CertificateCollection {
        return injector.get('CertificateCollection');
    },
};

export const pkiProfileCollectionProvider = {
    deps: [$injector],
    provide: PKIProfileCollection,
    useFactory(injector: Injector): typeof PKIProfileCollection {
        return injector.get('PKIProfileCollection');
    },
};
