/**
 * @module groupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AviPermissionResource,
    IKeyValue,
    IStringGroup,
} from 'generated-types';

import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { StringGroupModalComponent } from 'ng/modules/groups/components';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../../groups.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TStringGroupPartial = Omit<IStringGroup, 'kv'>;

export interface IStringGroupConfig extends TStringGroupPartial {
    kv: RepeatedMessageItem<MessageItem<IKeyValue>>;
}

interface IStringGroupData {
    config: IStringGroupConfig;
}

/**
 * @description String Group Item.
 * @author Suraj Kumar
 */
export class StringGroupItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];
    /**
     * StringGroupItem.data.
     */
    public data: IStringGroupData;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'stringgroup',
            objectType: 'StringGroup',
            windowElement: StringGroupModalComponent,
            permissionName: AviPermissionResource.PERMISSION_STRINGGROUP,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Updates config list with the passed list.
     */
    public updateConfigList(list: IKeyValue[]): void {
        if (!list) {
            return;
        }

        this.getConfig().kv.updateConfig(list);
    }

    /**
     * Discards all entries in list.
     */
    public discardConfigList(): void {
        this.getConfig().kv.removeAll();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.stringGroupModalBreadcrumbTitle);
    }
}
