/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'vs-security-ddos-top-list-grid-cell-rate';
const prefix = `${moduleName}.${componentName}`;

export const emptyDataLabel = `${prefix}.emptyDataLabel`;

export const ENGLISH = {
    [emptyDataLabel]: 'N/A',
};
