/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { reduce } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { IPolicyMatchInfo } from '../../policies.types';

import { PolicyMatchOrActionWrapperConfigItem }
    from './policy-match-or-action-wrapper.extendable-config-item.factory';
import { SchemaService } from '../../../core/services/schema-service/schema.service';
import * as l10n from './policy-match.config-item.factory.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IMatchFieldProps {
    label: string;
    value: string;
    description: string;
}

type TFieldToDescriptionHash = Record<string, string>;

type TFieldToObjectTypeNameHash = Record<string, string>;

export const POLICY_MATCH_CONFIG_ITEM_TOKEN = 'PolicyMatchConfigItem';

/**
 * @alias PolicyMatchConfigItem
 * @description
 *
 *     Abstract Policy Match ConfigItem class. Used for protobuf messages that contain match
 *     wrapper messages from match.proto or policies, ex. MatchTarget that contains IpAddrMatch and
 *     PortMatch. Use case would be to pass a match instance extending this class to the
 *     matchConfig component or a policy rule expander component, which work with the methods and
 *     properties defined here.
 *
 * @author alextsg, Zhiqian Liu
 */
export abstract class PolicyMatchConfigItem<T = any>
    extends PolicyMatchOrActionWrapperConfigItem<T> {
    /**
     * Hash of field names to their descriptions.
     */
    private fieldsToDescriptionsHash: TFieldToDescriptionHash;

    /**
     * Hash of message field names to their object types
     */
    private childFieldsToObjectTypesHash: TFieldToObjectTypeNameHash;

    private fieldNamesToLabelsHash: {};

    private objectTypesToLabelsHash: {};

    /**
     * Getter for supported match field names.
     */
    public get supportedMatches(): string[] {
        return this.fields;
    }

    /**
     * Return the number of matches configured.
     */
    public get matchCount(): number {
        return this.entryCount;
    }

    private l10nService: L10nService;

    constructor(args: {} = {}) {
        super(args);

        this.fieldsToDescriptionsHash = this.getFieldsToDescriptionsHash();
        this.childFieldsToObjectTypesHash = this.getChildFieldsToObjectTypesHash();

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);

        this.fieldNamesToLabelsHash = {
            source_ip: this.l10nService.getMessage(l10nKeys.sourceIpAddressLabel),
            destination_ip: this.l10nService.getMessage(l10nKeys.destinationIpAddressLabel),
            source_port: this.l10nService.getMessage(l10nKeys.sourcePortLabel),
            destination_port: this.l10nService.getMessage(l10nKeys.destinationPortLabel),
            protocol: this.l10nService.getMessage(l10nKeys.protocolLabel),
            port: this.l10nService.getMessage(l10nKeys.servicePortsLabel),
        };

        this.objectTypesToLabelsHash = {
            IpAddrMatch: this.l10nService.getMessage(l10nKeys.ipAddrMatchLabel),
            PortMatch: this.l10nService.getMessage(l10nKeys.portMatchLabel),
            ProtocolMatch: this.l10nService.getMessage(l10nKeys.protocolMatchLabel),
            MethodMatch: this.l10nService.getMessage(l10nKeys.methodMatchLabel),
            HTTPVersionMatch: this.l10nService.getMessage(l10nKeys.httpVersionMatchLabel),
            PathMatch: this.l10nService.getMessage(l10nKeys.pathMatchLabel),
            QueryMatch: this.l10nService.getMessage(l10nKeys.queryMatchLabel),
            HdrMatch: this.l10nService.getMessage(l10nKeys.headerMatchLabel),
            CookieMatch: this.l10nService.getMessage(l10nKeys.cookieMatchLabel),
            HostHdrMatch: this.l10nService.getMessage(l10nKeys.hostHeaderMatchLabel),
            L4RulePortMatch: this.l10nService.getMessage(l10nKeys.l4RulePortMatchLabel),
            L4RuleProtocolMatch: this.l10nService.getMessage(l10nKeys.l4RuleProtocolMatchLabel),
            IPReputationTypeMatch: this.l10nService.getMessage(l10nKeys.ipReputationMatchLabel),
        };
    }

    /**
     * Return true if the match exists in this.config. We check for a RepeatedMessageItem
     * because of the HdrMatch message which is a RepeatedMessageItem.
     * Defined as an arrow function because member function "this" is not preserved when being used
     * as a callback in Underscore or Array methods.
     * @param matchFieldName - Name of the match field name.
     */
    public hasMatchByField = (matchFieldName: string): boolean => {
        return this.hasEntryByField(matchFieldName);
    };

    /**
     * Add an empty match ConfigItem.
     * @param matchFieldName - Name of the field of the match to add.
     */
    public addMatch(matchFieldName: string): void {
        this.addEntry(matchFieldName);
    }

    /**
     * Remove the match from the configuration.
     * @param matchFieldName - Name of the field of the match to remove.
     */
    public removeMatch(matchFieldName: string): void {
        this.removeEntry(matchFieldName);
    }

    /**
     * Return a label to be displayed for a match field.
     * Lookup of label will be done by field_name or object_type.
     * field_name is prioritized first.
     * If label is not configured for a field_name, label for object_type will be considered.
     * @param field - Match field name.
     */
    public getMatchLabelByField(field: string): string {
        if (field in this.fieldNamesToLabelsHash) {
            return this.fieldNamesToLabelsHash[field];
        }

        const objectType = this.childFieldsToObjectTypesHash[field];

        return this.getMatchLabelByObjectType(objectType);
    }

    /**
     * Return the label for an object type.
     * @param objectType - Message object type.
     */
    public getMatchLabelByObjectType(objectType: string): string {
        return this.objectTypesToLabelsHash[objectType];
    }

    /**
     * Return a list of values, labels, and descriptions for a list of supported match fields.
     */
    public mapMatchFieldProps(matches: string[] = []): IMatchFieldProps[] {
        return matches.map(this.getMatchFieldProps);
    }

    /**
     * Get list of configured matches with label/value pairs.
     */
    public getConfiguredMatchInfoList(): IPolicyMatchInfo[] {
        const configuredMatches = this.configuredMatchFields;

        return configuredMatches.map(matchFieldName => {
            return {
                matchLabel: this.getMatchLabel(matchFieldName),
                matchValue: this.getMatchValue(matchFieldName),
            };
        });
    }

    /**
     * Return value for a match field.
     * @param field Any one of the supportedMatches.
     */
    public getMatchValue(field: string): string {
        const {
            config: matchConfig,
        } = this;

        return matchConfig[field].matchValue;
    }

    /**
     * Return label for a match field.
     * @param field Any one of the supportedMatches.
     */
    public getMatchLabel(field: string): string {
        const {
            config: matchConfig,
        } = this;

        return matchConfig[field].matchLabel;
    }

    /** @override */
    public canFlatten(): boolean {
        return this.hasAnyEntry();
    }

    /**
     * Pick only match fields that are configured in the rule.
     */
    public get configuredMatchFields(): string[] {
        return this.supportedMatches.filter(
            (field: string) => this.hasMatchByField(field),
        ) || [];
    }

    /**
     * Return a value, label, and description for a match field name.
     */
    protected getMatchFieldProps = (matchFieldName: string): IMatchFieldProps => {
        const label = this.getMatchLabelByField(matchFieldName);
        const description = this.fieldsToDescriptionsHash[matchFieldName];

        return {
            value: matchFieldName,
            label,
            description,
        };
    };

    /**
     * Return a hash of field names to their descriptions.
     */
    private getFieldsToDescriptionsHash(): TFieldToDescriptionHash {
        const schemaService: SchemaService = this.getAjsDependency_('schemaService');

        return this.fields.reduce((acc, fieldName) => {
            acc[fieldName] = schemaService.getFieldDescription(this.objectType, fieldName);

            return acc;
        }, {});
    }

    /**
     * Return a hash of message field names to their object types.
     */
    private getChildFieldsToObjectTypesHash(): TFieldToObjectTypeNameHash {
        return reduce(this.messageMap, (acc, messageMapProps, field) => {
            acc[field] = messageMapProps.objectType;

            return acc;
        }, {});
    }
}

PolicyMatchConfigItem.ajsDependencies = [
    'schemaService',
    'l10nService',
];
