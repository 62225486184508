/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createDropdownOption, createOptionsFromEnumProps } from 'ng/shared/utils';

const dropDownUtilsFactory = schemaService => {
    /**
     * Can be either object or simple type value.
     * @typedef {Object} DropDownOption
     * @property {string} label
     * @property {*} value
     * @property {string} tooltip
     */

    /**
     * Creates DropDownOption from HTML element.
     * @param {HTMLElement} elem
     * @returns {DropDownOption}
     * @public
     */
    function createOptionFromHtml(elem) {
        const $elem = $(elem);
        const value = $elem.val().trim();
        const label = $elem.text().trim();
        const tooltip = $elem.attr('tooltip');

        return createDropdownOption(value, label, tooltip);
    }

    /**
     * Returns dropdown options given an enum name.
     * @param {string} enumName - Name of an enum.
     * @returns {DropDownOption[]}
     */
    function getEnumDropdownOptions(enumName) {
        return _.compose(createOptionsFromEnumProps, schemaService.getEnumValues)(enumName);
    }

    return {
        createOption: createDropdownOption,
        createOptionFromHtml,
        createOptionsFromEnumProps,
        getEnumDropdownOptions,
    };
};

dropDownUtilsFactory.$inject = [
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  dropDownUtils
 * @author alogashov, alextsg
 * @desc Factory containing helpful methods associated with the Dropdown directive.
 */
angular.module('avi/component-kit').factory('dropDownUtils', dropDownUtilsFactory);
