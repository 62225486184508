/**
 * Module containing form components.
 * @preferred
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrTextareaModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { NotificationModule } from 'ng/modules/notification/notification.module';

import {
    AviEnumDropdownComponent,
    AviFieldsetComponent,
    AviRepeatedKeyValuesGridComponent,
    AviRepeatedNumbersComponent,
    AviRepeatedStringsGridComponent,
    BackupPassphraseComponent,
    CloudVrfContextComponent,
    CloudVrfContextDialogComponent,
    CredentialsVerificationComponent,
    CredentialsVerificationDialogComponent,
    EnumRadioButtonsComponent,
    ItemFullModalComponent,
    StringGroupOrCustomValueComponent,
} from './components';

import {
    ConfigFieldInputValidationDirective,
    InvalidateFormDirective,
    ParseIpAddrPrefixMessageItemDirective,
    ParseIpDirective,
    ParseIpListDirective,
    PasswordInputDirective,
    PermissionCheckDirective,
    ProvideParentFormDirective,
    RegexPatternDirective,
    TrueFalseValueDirective,
    ValidatePortDirective,
} from './directives';

import {
    stringGroupCollectionProvider,
} from './ajs-upgraded-providers';

const aviFormsComponents = [
    AviEnumDropdownComponent,
    AviFieldsetComponent,
    AviRepeatedKeyValuesGridComponent,
    AviRepeatedNumbersComponent,
    AviRepeatedStringsGridComponent,
    BackupPassphraseComponent,
    CloudVrfContextComponent,
    CloudVrfContextDialogComponent,
    CredentialsVerificationComponent,
    CredentialsVerificationDialogComponent,
    EnumRadioButtonsComponent,
    ItemFullModalComponent,
    StringGroupOrCustomValueComponent,
];

const aviFormsDirectives = [
    ConfigFieldInputValidationDirective,
    InvalidateFormDirective,
    ParseIpAddrPrefixMessageItemDirective,
    ParseIpDirective,
    ParseIpListDirective,
    PasswordInputDirective,
    PermissionCheckDirective,
    ProvideParentFormDirective,
    RegexPatternDirective,
    ValidatePortDirective,
    TrueFalseValueDirective,
];

@NgModule({
    declarations: [
        ...aviFormsComponents,
        ...aviFormsDirectives,
    ],
    exports: [
        FormsModule,
        ClrFormsModule,
        ClrInputModule,
        ClrRadioModule,
        ClrTextareaModule,
        ClrCheckboxModule,
        AviEnumDropdownComponent,
        AviFieldsetComponent,
        AviRepeatedKeyValuesGridComponent,
        AviRepeatedNumbersComponent,
        AviRepeatedStringsGridComponent,
        BackupPassphraseComponent,
        CloudVrfContextComponent,
        CloudVrfContextDialogComponent,
        CredentialsVerificationComponent,
        CredentialsVerificationDialogComponent,
        ConfigFieldInputValidationDirective,
        EnumRadioButtonsComponent,
        ItemFullModalComponent,
        InvalidateFormDirective,
        ParseIpAddrPrefixMessageItemDirective,
        ParseIpDirective,
        ParseIpListDirective,
        PasswordInputDirective,
        PermissionCheckDirective,
        ProvideParentFormDirective,
        RegexPatternDirective,
        ValidatePortDirective,
        StringGroupOrCustomValueComponent,
        TrueFalseValueDirective,
    ],
    imports: [
        ClrInputModule,
        CommonModule,
        FormsModule,
        SharedModule,
        NotificationModule,
        ClrRadioModule,
    ],
    providers: [
        stringGroupCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AviFormsModule {}
