/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import './match-wrapper.component.less';

/**
 * @description
 *     Wrapper component around individual match components. Basically renders the
 *     AviFieldsetComponent and its header.
 * @author alextsg
 */
@Component({
    selector: 'match-wrapper',
    templateUrl: './match-wrapper.component.html',
})
export class MatchWrapperComponent {
    /**
     * Label to be used as the header text.
     */
    @Input()
    public label: string;

    /**
     * Sub label to be used for header text.
     * Example - For query match we need to have header - Query (Contains).
     * (Contains) is the sub label here.
     */
    @Input()
    public subLabel?: string;

    /**
     * objectType of the match. Used for the tooltip icon.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName of the match. Used for the tooltip icon.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Called to remove a match.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
