/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'string-group-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleNumberOfStrings = `${prefix}.columnTitleNumberOfStrings`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const mapLabel = `${prefix}.mapLabel`;
export const stringLabel = `${prefix}.stringLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [columnTitleNumberOfStrings]: 'Number of Strings',
    [actionBtnEdit]: 'Edit',
    [mapLabel]: 'map',
    [stringLabel]: 'string',
};
