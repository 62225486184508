/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'header';
const componentName = 'user-card';
const prefix = `${moduleName}.${componentName}`;

export const myAccountButtonLabel = `${prefix}.myAccountButtonLabel`;
export const lastLoginLabel = `${prefix}.lastLoginLabel`;

export const ENGLISH = {
    [myAccountButtonLabel]: 'My Account',
    [lastLoginLabel]: 'Last Login {0}',
};
