/**
 * @module ClusterModule
 */

/***************************************************************************
* ========================================================================
* Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
* ========================================================================
*/
import {
    Component,
    Type,
} from '@angular/core';

import { IClusterNode } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ClusterNode } from 'object-types';
import { ClusterNodeModalComponent }
    from 'ng/modules/cluster/components/cluster-node-modal/cluster-node-modal.component';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';

type TClusterNodePartial = Omit<IClusterNode, 'ip' | 'public_ip_or_name'>;

interface IClusterNodeConfig extends TClusterNodePartial {
    ip?: IpAddrConfigItem,
    public_ip_or_name?: IpAddrConfigItem
}

/**
 * @description
 *      Cluster Node Message Item class for setting up nodes.
 * @author Ratan Kumar
 */
export class ClusterNodeConfigItem extends withFullModalMixin(MessageItem)<IClusterNodeConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: ClusterNode,
            windowElement: ClusterNodeModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter to get publicIp config.
     */
    public get publicIp(): IpAddrConfigItem {
        return this.config.public_ip_or_name;
    }

    /**
     * Getter to get Ip config.
     */
    public get ip(): IpAddrConfigItem {
        return this.config.ip;
    }

    /**
     * Method will check if name is empty then assign IP Addr as name.
     */
    public setName(): void {
        if (!this.config.name) {
            this.config.name = this.ip.address;
        }
    }

    /**
     * Method will load required Field on add or edit action.
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'ip',
            'public_ip_or_name',
        ];
    }

    /**
     * Getter function for the config to override the message default.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<any> {
        return {
            name: '',
        };
    }
}
