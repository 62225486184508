/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'gcp-vip-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;

export const ENGLISH = {
    [sectionTitleGeneral]: 'General',
};
