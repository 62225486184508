/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  LegacyPolicyConfigItem
 * @description
 *     LegacyPolicyConfigItem, abstraction class for policies and profiles.
 *     Will be deprecated soon after policy refactoring is done.
 *     For the new class please refer to PolicyExtendableConfigItem.
 */
const legacyPolicyConfigItemFactory = (
    ConfigItem,
    Item,
    LegacyPolicyRuleConfigItem,
    AviModal,
    $q,
    naturalSort,
) => {
    class LegacyPolicyConfigItem extends ConfigItem {
        constructor(args = {}) {
            super(args);

            this.ruleClass_ = args.ruleClass || this.ruleClass_;
            this.rulesPropertyName_ = args.rulePropertyName || this.rulesPropertyName_;

            this.transformAfterLoad_();
        }

        /**
         * Return function to be used for rules sorting based on certain properties comparison.
         * @param {string} propName
         * @returns {Function} To be passed into Array.prototype.sort.
         * @static
         */
        static getSortFunc(propName) {
            return (ruleA, ruleB) => {
                const a = ruleA[propName];
                const b = ruleB[propName];

                return naturalSort(a, b);
            };
        }

        /**
         * Getter function to return a reference to config#rule.
         * @return {LegacyPolicyRuleConfigItem[]} config#rule
         */
        get rules() {
            return this.getConfig()[this.rulesPropertyName_];
        }

        /**
         * Gets the name of the LegacyPolicyConfigItem object.
         * @return {string|undefined} name
         */
        getName() {
            return this.getConfig()['name'];
        }

        /**
         * Sets the name of the LegacyPolicyConfigItem object.
         * @param {string} name - Name to set.
         */
        setName(name) {
            const config = this.getConfig();

            config.name = name;
        }

        /**
         * Enables a set of rules.
         * @param {LegacyPolicyRuleConfigItem[]} rules
         */
        enable(rules) {
            rules.forEach(rule => rule.setEnable(true));
        }

        /**
         * Disables a set of rules.
         * @param {LegacyPolicyRuleConfigItem[]} rules
         */
        disable(rules) {
            rules.forEach(rule => rule.setEnable(false));
        }

        /**
         * Deletes a set of rules.
         * @param {LegacyPolicyRuleConfigItem[]|LegacyPolicyRuleConfigItem} rules
         */
        delete(rulesToDelete) {
            rulesToDelete = Array.isArray(rulesToDelete) ? rulesToDelete : [rulesToDelete];
            this.getConfig()[this.rulesPropertyName_] = this.rules.filter(
                rule => rulesToDelete.indexOf(rule) === -1,
            );
        }

        /**
         * Returns true if the indicies of the rules are valid for re-ordering.
         * @param {number} index1 - Index of rule within rules, not the property.
         * @param {number} index2 - Index of rule within rules, not the property.
         */
        indiciesAreValid(index1, index2) {
            const max = this.rules.length - 1;
            const min = 0;

            return index1 !== index2 &&
                Math.max(index1, index2) <= max && Math.min(index1, index2) >= min;
        }

        /**
         * Moves rule to a new index.
         * @param {LegacyPolicyRuleConfigItem} rule
         * @param {Object} toData - Contains position and index properties.
         * @param {string} toData.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} toData.index - New index to be moved to.
         */
        moveRule(rule, toData) {
            const oldIndex = this.rules.indexOf(rule);
            let newIndex = _.findIndex(this.rules, rule => rule.getIndex() === toData.index);

            if (toData.position === 'below') {
                newIndex++;
            }

            if (oldIndex < newIndex) {
                newIndex--;
            }

            this.moveToIndex(oldIndex, newIndex);
        }

        /**
         * Moves rule to a new index. All rules in-between need to have their indices shifted.
         * @param {number} oldIndex - Index of the original position of the rule.
         * @param {number} newIndex - Index of the new position.
         */
        moveToIndex(oldIndex, newIndex) {
            if (!this.indiciesAreValid(oldIndex, newIndex)) {
                return;
            }

            /**
             * newIndex moves towards the direction of oldIndex
             */
            const increment = oldIndex < newIndex ? -1 : 1;

            while (oldIndex !== newIndex) {
                this.swapRule(oldIndex, newIndex);
                newIndex += increment;
            }
        }

        /**
         * Handler for drag-and-drop event.
         * @param {number} oldIndex - Index of the original position of the rule.
         * @param {number} newIndex - Index of the new position.
         */
        handleDragAndDropChange(oldIndex, newIndex) {
            this.moveToIndex(oldIndex, newIndex);
        }

        /**
         * Given two indices of rules, swaps positions in the rules array along with the index
         * property in the rule.
         * @param {number} oldIndex
         * @param {number} newIndex
         */
        swapRule(oldIndex, newIndex) {
            const
                oldRule = this.rules[oldIndex],
                newRule = this.rules[newIndex];

            this.rules[oldIndex] = newRule;
            this.rules[newIndex] = oldRule;

            /**
             * Actual 'index' property of the rule.
             */
            const
                oldIndexValue = oldRule.getIndex(),
                newIndexValue = newRule.getIndex();

            oldRule.setIndex(newIndexValue);
            newRule.setIndex(oldIndexValue);
        }

        /**
         * Returns true if rules have been configured in this policy/profile.
         */
        hasRules() {
            return Array.isArray(this.rules) && this.rules.length > 0;
        }

        /**
         * Creates a new policy rule.
         * @param {Object=} toData - Contains position and index properties.
         * @param {string} toData.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} toData.index - New index to be moved to.
         */
        createRule(toData) {
            let createData = toData;

            if (_.isEmpty(toData)) {
                const rows = this.rules;
                const index = Array.isArray(rows) ?
                    rows.length && rows[rows.length - 1].index || 0 :
                    0;

                createData = {
                    index,
                    position: 'below',
                };
            }

            const RuleClass = this.ruleClass_;
            const rule = new RuleClass({
                data: {
                    config: {
                        name: `Rule ${this.rules.length + 1}`,
                    },
                },
            });

            this.editRule(rule, createData);
        }

        /**
         * Opens the policy rule modal to configure a rule.
         * @param {LegacyPolicyRuleConfigItem} rule
         * @param {Object} toData - Contains position and index properties.
         */
        editRule(rule, toData) {
            // edit copy to avoid state preservation when if the rule is not submitted for being
            // added to the rule list
            const ruleCopy = angular.copy(rule);

            ruleCopy.beforeEdit();

            AviModal.open(this.windowElement_, {
                rule: ruleCopy,
                onSubmit: ['rule', rule => this.saveRule(rule, toData)],
            });
        }

        /**
         * Duplicates an existing rule and allow for editing.
         * @param {LegacyPolicyRuleConfigItem} rule
         * @param {Object} toData - Contains position and index properties.
         * @param {string} toData.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} toData.index - New index to be moved to.
         */
        duplicateRule(rule, toData) {
            const duplicate = angular.copy(rule);
            const name = duplicate.getName();

            duplicate.setName(`${name} - duplicated`);
            duplicate.setIndex();
            this.editRule(duplicate, toData);
        }

        /**
         * Returns true if any rules have duplicate names.
         * @param {LegacyPolicyRuleConfigItem} ruleToCheck
         * @return {boolean}
         */
        hasDuplicateName_(ruleToCheck) {
            return _.any(this.rules, rule => {
                return rule.getName() === ruleToCheck.getName() &&
                    rule.getIndex() !== ruleToCheck.getIndex();
            });
        }

        /**
         * Saves the configured rule. If editing, replaces the old rule with the new one. If
         * creating, pushes the new rule to the end of the list. If a toPosition is specified,
         * moves the rule after it's been added to the list. Returns a promise so that if the
         * rule is invalid, the promise can be rejected and the user can make changes.
         * @param {LegacyPolicyRuleConfigItem} rule
         * @param {Object} toData - Contains position and index properties.
         * @param {string} toData.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} toData.index - New index to be moved to.
         * @return {ng.$q.promise}
         */
        saveRule(newRule, toPosition) {
            const deferred = $q.defer();

            if (this.hasDuplicateName_(newRule)) {
                deferred.reject(`Rule name (${newRule.getName()}) already in use.`);
            } else {
                const oldIndex = _.findIndex(this.rules, rule => {
                    return rule.getIndex() === newRule.getIndex();
                });

                /**
                 * Index exists, so rule is being edited.
                 */
                if (oldIndex !== -1) {
                    this.rules[oldIndex] = newRule;
                /**
                 * Creating new rule.
                 */
                } else {
                    const maxIndexRule = _.max(this.rules, rule => rule.getIndex());
                    const newIndex = !_.isEmpty(maxIndexRule) ? maxIndexRule.getIndex() + 1 : 1;

                    newRule.setIndex(newIndex);
                    this.rules.push(newRule);
                }

                /**
                 * If position is specified, move rule.
                 */
                if (angular.isObject(toPosition)) {
                    this.moveRule(newRule, toPosition);
                }

                deferred.resolve();
            }

            return deferred.promise;
        }

        /**
         * Sort rules by a particular field/property name.
         * @param {string} propName
         * @protected
         */
        sortRulesBy_(propName) {
            const { [this.rulesPropertyName_]: rules } = this.getConfig();
            const sortFunc = LegacyPolicyConfigItem.getSortFunc(propName);

            rules.sort(sortFunc);
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig()),
                rules = config[this.rulesPropertyName_],
                rulesToSave = Item.filterRepeatedInstances(rules) || [];

            config[this.rulesPropertyName_] = rulesToSave;

            return config;
        }

        transformAfterLoad_() {
            const
                config = this.getConfig(),
                RuleClass = this.ruleClass_,
                rulesProperty = this.rulesPropertyName_;

            if (!(rulesProperty in config)) {
                config[rulesProperty] = [];
            }

            config[rulesProperty] = config[rulesProperty].map(config => {
                return config instanceof RuleClass ?
                    config :
                    new RuleClass({ data: { config } });
            });
        }
    }

    angular.extend(LegacyPolicyConfigItem.prototype, {
        windowElement_: '',
        ruleClass_: LegacyPolicyRuleConfigItem,
        rulesPropertyName_: 'rules',
    });

    return LegacyPolicyConfigItem;
};

legacyPolicyConfigItemFactory.$inject = [
    'ConfigItem',
    'Item',
    'LegacyPolicyRuleConfigItem',
    'AviModal',
    '$q',
    'naturalSort',
];

angular.module('aviApp').factory('LegacyPolicyConfigItem', legacyPolicyConfigItemFactory);
