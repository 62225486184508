/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ErrorPageModule
 */

import { Injector } from '@angular/core';

import {
    ErrorPageBodyCollection,
    ERROR_PAGE_BODY_COLLECTION_TOKEN,
    TErrorPageBodyCollection,
} from 'ajs/modules/error-page';

const $injector = '$injector';

export const errorPageBodyCollectionProvider = {
    deps: [$injector],
    provide: ErrorPageBodyCollection,
    useFactory(injector: Injector): TErrorPageBodyCollection {
        return injector.get(ERROR_PAGE_BODY_COLLECTION_TOKEN);
    },
};
