/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './item-alert-level-icon.less';

/**
 * @ngdoc component
 * @name itemAlertLevelIcon
 * @param {string} level - high, low, medium.
 * @description
 *
 *     Circle colored by Item's alert severity.
 */
const componentName = 'item-alert-level-icon';

const template = `<div class = "${componentName}__icon"
        ng-class = "'${componentName}__icon_level_' + $ctrl.level"></div>`;

class ItemAlertLevelIconController {
    constructor($element) {
        this.$elem_ = $element;
    }

    $onInit() {
        this.$elem_.addClass(componentName);
    }
}

ItemAlertLevelIconController.$inject = ['$element'];

angular.module('aviApp').component('itemAlertLevelIcon', {
    controller: ItemAlertLevelIconController,
    bindings: {
        level: '<',
    },
    template,
});
