/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name disabledTooltip
 * @description
 *     Used to display a tooltip to the user when an input element is disabled to explain why it is
 *     such. Adds a "title" attribute to the element, or to the parent if the parent element is a
 *     <label> in the case of checkboxes and radio buttons.
 * @example
 *     <label>
 *         <radio
 *             ng-model="ngModel"
 *             ng-disabled="disabledCondition"
 *             disabled-tooltip="Disabled when 'disabledCondition' is true">
 *         </radio>
 *     </label>
 */
const disabledTooltip = () => {
    const disabledTooltipLink = (scope, elm, attr) => {
        let element = elm;

        /**
         * If the disabled element is wrapped in <label> tags, assume the element is a radio
         * button or a checkbox, in which case we want to append the "title" attribute to
         * <label>.
         */
        if (elm.parent()[0].tagName.toLowerCase() === 'label') {
            element = elm.parent();
        }

        /**
         * Keep a copy of the previous "title", so that we can display it again when going from
         * disabled back to enabled.
         */
        const backupTitle = element.attr('title');

        const setTooltip = () => {
            if (attr.disabled) {
                element.attr('title', attr.disabledTooltip);
            } else if (backupTitle) {
                element.attr('title', backupTitle);
            } else {
                element.removeAttr('title');
            }
        };

        setTooltip();

        attr.$observe('disabled', (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }

            setTooltip();
        });
    };

    return {
        restrict: 'A',
        link: disabledTooltipLink,
    };
};

angular.module('aviApp').directive('disabledTooltip', disabledTooltip);
