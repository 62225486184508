/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { CertificateManagementModalComponent } from 'ng/modules/security';

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    AviPermissionResource,
    ICertificateManagementProfile,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { CustomParamsConfigItem } from './custom-params.config-item.factory';
import * as l10n from './certificate-management.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCertificateManagementProfilePartial = Omit<ICertificateManagementProfile, 'script_params'>;

interface ICertificateManagementProfileConfig extends TCertificateManagementProfilePartial {
    script_params: RepeatedMessageItem<CustomParamsConfigItem>
}

interface ICertificateManagementProfileData {
    config: ICertificateManagementProfileConfig;
}

/**
 * Constant for Script Params field.
 */
const SCRIPT_PARAMS_FIELD = 'script_params';

/**
 * Ajs dependency token for CertificateManagement Item.
 */
export const CERTIFICATE_MANAGEMENT_ITEM_TOKEN = 'CertificateManagement';

/**
 * @description Certificate Management Item.
 * @author Ram Pal, Rajawant Prajapati
 */
export class CertificateManagement extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * CertificateManagement data.
     */
    public data: ICertificateManagementProfileData;

    /**
     * When enable, Add Script Params option is visible.
     */
    public enableScriptParams: boolean;

    /**
     * l10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'certificatemanagementprofile',
            objectType: 'CertificateManagementProfile',
            windowElement: CertificateManagementModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CERTIFICATEMANAGEMENTPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Used to find if item has script params.
     */
    public get hasScriptParams(): boolean {
        return !this.scriptParams.isEmpty();
    }

    /**
     * Used to get name of control script.
     */
    public get controlScriptName(): string {
        const { run_script_ref: runScriptRef } = this.getConfig();

        return this.stringService.name(runScriptRef);
    }

    /**
     * Getter for script_params as RepeatedMessageItem.
     */
    public get scriptParams(): RepeatedMessageItem<CustomParamsConfigItem> {
        const { script_params: scriptParams } = this.getConfig();

        return scriptParams;
    }

    /**
     * Method to add Script Param Message Item.
     */
    public addScriptParam(): void {
        this.addChildMessageItem({
            field: SCRIPT_PARAMS_FIELD,
        });
    }

    /**
     * Method to edit Script Param Message Item.
     */
    public editScriptParam(scriptParam: CustomParamsConfigItem): void {
        this.editChildMessageItem({
            field: SCRIPT_PARAMS_FIELD,
            messageItem: scriptParam,
            modalBindings: {
                editMode: true,
            },
        });
    }

    /**
     * Method to remove Script Param Message Item.
     */
    public removeScriptParam(scriptParam: CustomParamsConfigItem): void {
        this.scriptParams.removeByMessageItem(scriptParam);
    }

    /** @override */
    public dataToSave(): ICertificateManagementProfile {
        const config = super.dataToSave();

        if (!this.enableScriptParams) {
            delete config.script_params;
        }

        return config;
    }

    /** @override */
    protected beforeEdit(): void {
        if (this.hasScriptParams) {
            this.enableScriptParams = true;
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.certificateManagementModalBreadcrumbTitle);
    }
}
