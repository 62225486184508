/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { withFullModalMixin } from 'ajs/js/utilities/mixins';

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { ErrorPageProfileModalComponent } from 'ng/modules/error-page';

import {
    AviPermissionResource,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { ErrorPageConfigItem } from './error-page.config-item.factory';

import * as l10n from '../error-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const ERROR_PAGES = 'error_pages';

/**
 * Ajs dependency token for ErrorPageProfile ObjectTypeItem.
 */
export const ERROR_PAGE_PROFILE_ITEM_TOKEN = 'ErrorPageProfile';

/**
 * @description Error page profile ObjectTypeItem class.
 *
 * @author Ratan Kumar
 */
export class ErrorPageProfile extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'errorpageprofile',
            objectType: 'ErrorPageProfile',
            windowElement: ErrorPageProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_ERRORPAGEPROFILE,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * To get errorPages from error page profile config.
     */
    public get errorPageList(): RepeatedMessageItem<ErrorPageConfigItem> {
        const { error_pages: errorPages } = this.getConfig();

        return errorPages;
    }

    /**
     * Method to add new error page item.
     */
    public addErrorPage(): void {
        this.addChildMessageItem({
            field: ERROR_PAGES,
        });
    }

    /**
     * Method to edit error pages.
     */
    public updateErrorPage(errorPage: ErrorPageConfigItem): void {
        this.editChildMessageItem({
            field: ERROR_PAGES,
            messageItem: errorPage,
        });
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.errorPageProfileModalBreadcrumbTitle);
    }
}
