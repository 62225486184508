/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { DatePipe } from '@angular/common';

import { MyAccount } from 'ajs/modules/core/services/my-account';

export const aviMediumDateFormat = 'MMM d, y h:mm a';

/**
 * @description Service for transforming given date.
 * @author Akul Aggarwal
 */
@Pipe({
    name: 'aviDate',
})
export class AviDatePipe extends DatePipe implements PipeTransform {
    constructor(private readonly myAccount: MyAccount) {
        super('en-US');
    }

    public transform(
        input: Date | string | number,
        format?: string,
        timezone?: string,
        locale?: string
    ): string | null;
    public transform(
        input: null | undefined,
        format?: string,
        timezone?: string,
        locale?: string,
    ): null;
    public transform(input: string): string {
        const timezone = this.myAccount.useUtc ? '+0000' : '';

        return super.transform(input, aviMediumDateFormat, timezone);
    }
}
