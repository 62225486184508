/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-placement-network-grid';
const prefix = `${moduleName}.${componentName}`;

export const placementNetworkHeader = `${prefix}.placementNetworkHeader`;
export const columnTitleNetwork = `${prefix}.columnTitleNetwork`;
export const columnTitleSubnet = `${prefix}.columnTitleSubnet`;
export const columnTitleSubnet6 = `${prefix}.columnTitleSubnet6`;
export const removeActionLabel = `${prefix}.removeActionLabel`;
export const editActionLabel = `${prefix}.editActionLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;

export const ENGLISH = {
    [placementNetworkHeader]: 'Placement Network ({0})',
    [columnTitleNetwork]: 'Network',
    [columnTitleSubnet]: 'IPv4 Subnet',
    [columnTitleSubnet6]: 'IPv6 Subnet',
    [removeActionLabel]: 'Remove',
    [editActionLabel]: 'Edit',
    [addButtonLabel]: 'Add',
};
