/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { copy } from 'angular';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';

import {
    IBackupConfiguration,
    IScheduler,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { SchedulerModalComponent } from 'ng/modules/system';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import * as l10n from '../system.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface ISchedulerMacroConfig {
    model_name: string;
    data: IExtendedSchedulerConfig;
}

interface IExtendedSchedulerConfig extends IScheduler {
    backup_config_ref_data: IBackupConfiguration;
}

/**
 * @description Scheduler Item class for setting up Backup Scheduler.
 * @author Ratan Kumar
 */
export class Scheduler extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public getConfig: () => IExtendedSchedulerConfig;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'scheduler',
            objectType: 'Scheduler',
            windowElement: SchedulerModalComponent,
            params: {
                include_name: true,
                join: 'backupconfiguration:backup_config_ref',
            },
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public dataToSave(): ISchedulerMacroConfig {
        const config = copy(this.getConfig());

        return {
            model_name: 'scheduler',
            data: config,
        };
    }

    /**
     * @override
     */
    public urlToSave(): string {
        return '/api/macro';
    }

    /**
     * Getter for backup_passphrase
     */
    public get backupPassphrase(): string {
        return this.backupConfigRefData?.backup_passphrase;
    }

    /**
     * Setter for backup_passphrase
     */
    public set backupPassphrase(passphrase: string) {
        this.backupConfigRefData.backup_passphrase = passphrase;
    }

    /**
     * Getter for backup_config_ref_data
     */
    public get backupConfigRefData(): IBackupConfiguration {
        return this.getConfig().backup_config_ref_data;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.schedulerModalBreadcrumbTitle);
    }
}
