/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'geo-db-mapping-grid';
const prefix = `${moduleName}.${componentName}`;

export const geoDbMappingAddButtonLabel = `${prefix}.geoDbMappingAddButtonLabel`;
export const geoDbMappingColTitleName = `${prefix}.geoDbMappingColTitleName`;
export const geoDbMappingColTitleElements = `${prefix}.geoDbMappingColTitleElements`;
export const geoDbMappingRemoveButtonLabel = `${prefix}.geoDbMappingRemoveButtonLabel`;
export const geoDbMappingEditButtonLabel = `${prefix}.geoDbMappingEditButtonLabel`;

export const ENGLISH = {
    [geoDbMappingAddButtonLabel]: 'Add',
    [geoDbMappingColTitleName]: 'Name',
    [geoDbMappingColTitleElements]: 'Elements',
    [geoDbMappingRemoveButtonLabel]: 'Remove',
    [geoDbMappingEditButtonLabel]: 'Edit',
};
