/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'se-usage-directory-path';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const autoSelectPathRadioLabel = `${prefix}.autoSelectPathRadioLabel`;
export const singlePathForAllLogsRadioLabel = `${prefix}.singlePathForAllLogsRadioLabel`;
export const separatePathRadioLabel = `${prefix}.separatePathRadioLabel`;
export const systemLogPathLabel = `${prefix}.systemLogPathLabel`;
export const pathPlaceholder = `${prefix}.pathPlaceholder`;
export const sizelabel = `${prefix}.sizeLabel`;
export const sizeHelperText = `${prefix}.sizeHelperText`;
export const sizePlaceholder = `${prefix}.sizePlaceholder`;
export const pathForClientLogsLabel = `${prefix}.pathForClientLogsLabel`;

export const ENGLISH = {
    [sectionTitle]: 'Directory Path for Service Engine Usage',
    [autoSelectPathRadioLabel]: 'Auto-select path',
    [singlePathForAllLogsRadioLabel]: 'Single path for all logs',
    [separatePathRadioLabel]: 'Separate paths for System & Client logs',
    [systemLogPathLabel]: 'Path for System Logs',
    [pathPlaceholder]: 'Enter Path',
    [sizelabel]: 'Size',
    [sizeHelperText]: 'Gigabytes',
    [sizePlaceholder]: 'Enter Size',
    [pathForClientLogsLabel]: 'Path for Client Logs',
};
