/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'match-dropdown-controller';
const prefix = `${moduleName}.${componentName}`;

export const addNewMatchInputPlaceholder = `${prefix}.addNewMatchInputPlaceholder`;

export const ENGLISH = {
    [addNewMatchInputPlaceholder]: 'Add New Match',
};
