/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    any,
    every,
    findIndex,
    isEmpty,
    max,
} from 'underscore';
import { IWafRuleGroup } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { WafRuleGroupModalComponent } from 'ng/modules/waf';
import { withFullModalMixin } from 'ajs/utils/mixins';
import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { WafRuleConfigItem } from './waf-rule.config-item.factory';
import { WafExcludeListEntryConfigItem } from './waf-exclude-list-entry.config-item.factory';
import {
    POST_CRS_GROUPS_FIELD,
    PRE_CRS_GROUPS_FIELD,
} from './waf-policy.factory';
import * as l10n from '../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafRuleGroupConfigPartial = Omit<IWafRuleGroup, 'rules' | 'exclude_list'>;

interface IWafRuleGroupConfig extends TWafRuleGroupConfigPartial {
    rules?: RepeatedMessageItem<WafRuleConfigItem>;
    exclude_list?: RepeatedMessageItem<WafExcludeListEntryConfigItem>;
}

export const WAF_RULE_GROUP_CONFIG_ITEM_TOKEN = 'WafRuleGroupConfigItem';

export class WafRuleGroupConfigItem extends
    withFullModalMixin(
        withEditChildMessageItemMixin<IWafRuleGroupConfig, typeof MessageItem>(MessageItem),
    )<IWafRuleGroupConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafRuleGroup',
            windowElement: WafRuleGroupModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns true if this group is enabled.
     */
    public isEnabled(): boolean {
        return this.config.enable;
    }

    /**
     * Returns the index of this group.
     */
    public getIndex(): number {
        return this.config.index;
    }

    /**
     * Returns the name of this group.
     */
    public getName(): string {
        return this.config.name;
    }

    /**
     * Sets the index for this group.
     */
    public setIndex(index: number): void {
        this.config.index = index;
    }

    /**
     * Returns true if the group contains rules.
     */
    public hasRules(): boolean {
        return !this.config.rules.isEmpty();
    }

    /**
     * Returns true if all rules in this group are enabled.
     */
    public hasAllRulesEnabled(): boolean {
        return every(this.config.rules.config, rule => rule.isEnabled());
    }

    /**
     * Returns true if any rule in this group is enabled.
     */
    public hasAnyRuleEnabled(): boolean {
        return any(this.config.rules.config, rule => rule.isEnabled());
    }

    /**
     * Sets the enable flag of this group.
     */
    public setEnabledState(enabled = false): void {
        this.config.enable = enabled;
    }

    /**
     * Sets the enable flag of all rules in this group.
     */
    public setAllRulesEnabledState(enabled = false): void {
        this.config.rules.config.forEach((rule: WafRuleConfigItem) => {
            rule.setEnabledState(enabled);
        });
    }

    /**
     * Replaces existing rule with new one.
     */
    public replaceRule(newRule: WafRuleConfigItem): void {
        const { rules } = this.config;
        const newRuleIndex = newRule.getIndex();
        const index = findIndex(rules.config, rule => rule.getIndex() === newRuleIndex);

        if (index > -1) {
            rules.config.splice(index, 1, newRule);
        } else {
            throw new Error('Rule to be replaced not found.');
        }
    }

    /**
     * Adds a new rule to the group. Assigns an index based on the current max index of the
     * rules in this group.
     * @param ruleToCreateAbove - If defined, create new rule above this rule.
     */
    public addRule(ruleToCreateAbove?: WafRuleConfigItem): void {
        const { rules } = this.config;
        const maxIndexRule = max(rules.config, rule => rule.getIndex()) as WafRuleConfigItem;
        const newIndex = !isEmpty(maxIndexRule) ? maxIndexRule.getIndex() + 1 : 0;
        const newRule = this.createChildByField_('rules', { index: newIndex }, true);

        rules.add(newRule);

        if (ruleToCreateAbove instanceof WafRuleConfigItem) {
            const targetIndex = ruleToCreateAbove.getIndex();
            const targetArrayIndex = this.getArrayIndexFromRuleIndex(targetIndex);
            const currentArrayIndex = rules.config.length - 1;

            this.moveRule(currentArrayIndex, targetArrayIndex);
        }
    }

    /**
     * Removes a rule from config.rules.
     */
    public removeRule(rule: WafRuleConfigItem): void {
        const { rules } = this.config;
        const index = rules.config.indexOf(rule);

        if (index > -1) {
            rules.remove(index);
        }
    }

    /**
     * Returns the array position index from the rule.index property.
     * @param ruleIndex - Index from rule.index.
     */
    public getArrayIndexFromRuleIndex(ruleIndex: number): number {
        return findIndex(this.config.rules.config, rule => rule.getIndex() === ruleIndex);
    }

    /**
     * Moves rule to a new index. All rules in-between need to have their indices shifted.
     * @param oldIndex - Index of the original position of the rule.
     * @param newIndex - Index of the new position.
     */
    public moveRule(oldIndex: number, newIndex: number): void {
        let newIndexCounter = newIndex;
        /**
         * newIndex moves towards the direction of oldIndex
         */
        const increment = oldIndex < newIndex ? -1 : 1;

        while (oldIndex !== newIndexCounter) {
            this.swapRule(oldIndex, newIndexCounter);
            newIndexCounter += increment;
        }
    }

    /**
     * Given two indices of rules, swaps positions in the rules array along with the index
     * property in the rule.
     * @param oldIndex
     * @param newIndex
     */
    public swapRule(oldIndex: number, newIndex: number): void {
        const { rules } = this.config;

        const oldRule = rules.at(oldIndex);
        const newRule = rules.at(newIndex);

        rules.config[oldIndex] = newRule;
        rules.config[newIndex] = oldRule;

        /**
         * Actual 'index' property of the rule.
         */
        const oldIndexValue = oldRule.getIndex();
        const newIndexValue = newRule.getIndex();

        oldRule.setIndex(newIndexValue);
        newRule.setIndex(oldIndexValue);
    }

    /**
     * Adds a WafExcludeListEntryConfigItem MessageItem to config.exclude_list.
     */
    public addExcludeListEntry(exception: any): void {
        this.config.exclude_list.add(exception);
    }

    /**
     * Creates a new location and opens the modal to edit it.
     */
    public addExcludeListEntryMessageItem(modalBindings?: Record<string, any>): void {
        this.addChildMessageItem({
            field: 'exclude_list',
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.addExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Edits a WafPSMLocation config item.
     */
    public editExcludeListEntryMessageItem(
        entry: WafExcludeListEntryConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        this.editChildMessageItem({
            field: 'exclude_list',
            messageItem: entry,
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.editExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Removes a WafExcludeListEntry message item from the exclude list.
     */
    public removeExcludeListEntry(entry: WafExcludeListEntryConfigItem): void {
        this.excludeList.removeByMessageItem(entry);
    }

    /**
     * Returns true if entries exist on config.exclude_list.
     */
    public hasExcludeListEntries(): boolean {
        const { exclude_list: excludeList } = this.config;

        return excludeList && !excludeList.isEmpty();
    }

    /**
     * Returns true if any rules within the group contain exceptions.
     */
    public hasRulesWithExcludeListEntries(): boolean {
        return any(this.config.rules.config, rule => rule.hasExcludeListEntries());
    }

    /**
     * Returns true if any entry has an invalid configuration.
     */
    public hasInvalidExcludeListEntries(): boolean {
        return any(this.config.exclude_list.config, entry => !entry.isValid());
    }

    /**
     * Returns true if the group contains the exception.
     * @param exception - Exception containing subnet, path, and match element.
     */
    public hasMatchingException(exception: any): boolean {
        const { exclude_list: excludeList } = this.getConfig();

        return any(excludeList.config, entry => entry.hasMatchingException(exception));
    }

    /**
     * Returns a hash of all rule IDs to rules within the group. Used for lookup.
     */
    public getRuleIdHash(): Record<string, WafRuleConfigItem> {
        const { rules } = this.config;

        if (!rules || rules.isEmpty()) {
            return {};
        }

        return rules.config.reduce((
            hash: Record<string, WafRuleConfigItem>,
            rule: WafRuleConfigItem,
        ) => {
            hash[rule.getId()] = rule;

            return hash;
        }, {} as any as Record<string, WafRuleConfigItem>);
    }

    /**
     * Returns the fieldName of the group.
     */
    public getFieldName(): string {
        return this.fieldName_;
    }

    /**
     * Adds a new rule. Only applies to Pre-CRS and Post-CRS as CRS rules are not allowed to be
     * added.
     */
    public addWafRule(
        ruleToCreateAbove?: WafRuleConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        const field = this.getFieldName();

        if (field !== PRE_CRS_GROUPS_FIELD && field !== POST_CRS_GROUPS_FIELD) {
            return;
        }

        const { rules: repeatedRules } = this.config;
        const modalHeaderKey = field === PRE_CRS_GROUPS_FIELD ?
            l10nKeys.createPreCrsRuleModalHeader :
            l10nKeys.createPostCrsRuleModalHeader;

        let setRulePosition;

        if (ruleToCreateAbove instanceof WafRuleConfigItem) {
            const targetIndex = ruleToCreateAbove.getIndex();
            const targetArrayIndex = this.getArrayIndexFromRuleIndex(targetIndex);

            setRulePosition = () => {
                const currentArrayIndex = repeatedRules.count - 1;

                this.moveRule(currentArrayIndex, targetArrayIndex);
            };
        }

        this.addChildMessageItem({
            field: 'rules',
            modalBindings: {
                modalHeaderKey,
                setRulePosition,
                ...modalBindings,
            },
        });
    }

    /**
     * Edits an existing rule. Only applies to Pre-CRS and Post-CRS as CRS rules are not allowed to
     * be added.
     */
    public editWafRule(wafRule: WafRuleConfigItem, modalBindings?: Record<string, any>): void {
        const field = this.getFieldName();

        if (field !== PRE_CRS_GROUPS_FIELD && field !== POST_CRS_GROUPS_FIELD) {
            return;
        }

        const modalHeaderKey = field === PRE_CRS_GROUPS_FIELD ?
            l10nKeys.editPreCrsRuleModalHeader :
            l10nKeys.editPostCrsRuleModalHeader;

        this.editChildMessageItem({
            field: 'rules',
            messageItem: wafRule,
            modalBindings: {
                modalHeaderKey,
                ...modalBindings,
            },
        });
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafGroupBreadcrumbTitle);
    }

    /**
     * Returns the excludeList repeatedMessageItem.
     */
    public get excludeList(): RepeatedMessageItem<WafExcludeListEntryConfigItem> {
        return this.config.exclude_list;
    }

    /**
     * Returns the WafRuleConfigItem repeatedMessageItem.
     */
    public get rules(): RepeatedMessageItem<WafRuleConfigItem> {
        return this.config.rules;
    }
}
