/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { Vip } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/shared/components';
import { SchemaService } from 'ajs/modules/core/services';
import { RepeatedMessageItem } from 'ajs/modules/data-model/factories';
import { VipPlacementNetworkConfigItem } from 'ajs/modules/vs';
import * as l10n from './vip-placement-network-grid.l10n';

import './vip-placement-network-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for VIP Placement Network.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vip-placement-network-grid',
    templateUrl: './vip-placement-network-grid.component.html',
})
export class VipPlacementNetworkGridComponent implements OnInit {
    /**
     * VipPlacementNetworkConfigItem's RepeatedMessageItem instance.
     */
    @Input()
    public placementNetworks: RepeatedMessageItem<VipPlacementNetworkConfigItem>;

    /**
     * EventEmitter for Add placement network.
     */
    @Output()
    public onAddPlacementNetwork = new EventEmitter<void>();

    /**
     * EventEmitter for Edit placement network.
     */
    @Output()
    public onEditPlacementNetwork = new EventEmitter<VipPlacementNetworkConfigItem>();

    /**
     * EventEmitter for Delete placement network.
     */
    @Output()
    public onDeletePlacementNetwork = new EventEmitter<VipPlacementNetworkConfigItem>();

    /**
     * VipPlacementNetwork Grid config.
     */
    public placementNetworksGridConfig: IAviDataGridConfig;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Vip ObjectType.
     */
    public readonly objectType = Vip;

    /**
     * Maximum number of placementNetworks that can be added to each VIP.
     */
    private readonly maxPlacementNetworks: number;

    constructor(
        private readonly l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.maxPlacementNetworks =
            schemaService.getFieldMaxElements(this.objectType, 'placement_networks');
    }

    /** @override */
    public ngOnInit(): void {
        const NO_DATA = '-';

        this.placementNetworksGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: this.l10nService.getMessage(l10nKeys.columnTitleNetwork),
                id: 'network_ref',
                transform: (networkConfigItem: VipPlacementNetworkConfigItem) => {
                    return networkConfigItem.network;
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.columnTitleSubnet),
                id: 'subnet',
                transform(network: VipPlacementNetworkConfigItem) {
                    const { subnet } = network.config;

                    return subnet && subnet.isValid() ? subnet.toString() : NO_DATA;
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.columnTitleSubnet6),
                id: 'subnet6',
                transform(network: VipPlacementNetworkConfigItem) {
                    const { subnet6 } = network.config;

                    return subnet6 && subnet6.isValid() ? subnet6.toString() : NO_DATA;
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.editActionLabel),
                shape: 'pencil',
                onClick: (network: VipPlacementNetworkConfigItem) => {
                    this.editPlacementNetwork(network);
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'trash',
                onClick: (network: VipPlacementNetworkConfigItem) => {
                    this.deletePlacementNetwork(network);
                },
            }],
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                onClick: (placementNetworks: VipPlacementNetworkConfigItem[]) => {
                    placementNetworks.forEach((network: VipPlacementNetworkConfigItem) => {
                        this.deletePlacementNetwork(network);
                    });
                },
            }],
        };
    }

    /**
     * Fires on Add placement Network.
     */
    public addPlacementNetwork(): void {
        this.onAddPlacementNetwork.emit();
    }

    /**
     * Fires on Edit placement Network.
     */
    public editPlacementNetwork(placementNetwork: VipPlacementNetworkConfigItem): void {
        this.onEditPlacementNetwork.emit(placementNetwork);
    }

    /**
     * Fires on Delete placement Network.
     */
    public deletePlacementNetwork(placementNetwork: VipPlacementNetworkConfigItem): void {
        this.onDeletePlacementNetwork.emit(placementNetwork);
    }

    /**
     * Returns true if Add placement network button can be disabled for a vip.
     */
    public disableAddPlacementNetworkButton(): boolean {
        return this.placementNetworks.count >= this.maxPlacementNetworks;
    }
}
