/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../../less/pages/application/virtualservice-config.less';
import * as l10n from './VirtualServiceListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('VirtualServiceListController', [
'$scope',
'CRUDGridConfig',
'$state',
'VirtualServiceCollection',
'$templateCache',
'Auth',
'AviModal',
'$q',
'aviAlertService',
'getGridMetricFieldConfig',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    $state,
    VirtualServiceCollection,
    $templateCache,
    Auth,
    AviModal,
    $q,
    aviAlertService,
    getGridMetricFieldConfig,
    l10nService,
) {
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const gridMetricFields = [{
        require: 'l7_client.avg_complete_responses',
        title: 'RPS',
        fullTitle: l10nService.getMessage(l10nKeys.requestPerSecColumnTitle),
        visibility: 'd',
    }, {
        require: 'l4_client.avg_complete_conns',
        title: 'CPS',
        fullTitle: l10nService.getMessage(l10nKeys.connectionsPerSecColumnTitle),
        visibility: 'd',
    }, {
        require: 'l4_client.max_open_conns',
        visibility: 'd',
    }, {
        require: 'l4_client.avg_bandwidth',
        visibility: 'd',
    },
        'l4_client.avg_total_rtt',
        'l4_server.avg_total_rtt',
        'l4_client.avg_errored_connections',
        'l4_client.avg_rx_pkts',
        'l4_client.avg_tx_pkts',
        'l4_client.avg_policy_drops',
        'l4_client.avg_dos_attacks',
        'l7_client.avg_error_responses',
        'l7_client.pct_response_errors',
    ];

    const gridConfig = {};

    gridConfig.layout = {
        includeTimeframeSelector: true,
        includeMetricsValueSelector: true,
    };

    gridConfig.collection = new VirtualServiceCollection({
        dataFields: [
            'config',
            'faults',
        ],
        bind: {
            // After new item has been created throw user to analytics page
            collItemCreate: ({ id }) => {
                $state.go('^.virtualservice-detail.analytics', { vsId: id });
            },
        },
    });

    gridConfig.createActions = [
        {
            label: l10nService.getMessage(l10nKeys.btnLabelBasicSetup),
            onClick: () => gridConfig.collection.create('app-vs-create-basic'),
        }, {
            label: l10nService.getMessage(l10nKeys.btnLabelAdvancedSetup),
            onClick: () => gridConfig.collection.create(),
        },
    ];

    const gridFields = [
        {
            require: 'config',
            name: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            template: require('./vs-grid-name-column.partial.html'),
            sortBy: 'name',
            visibility: 'm',
        }, {
            // Style based on health name, don't remove
            name: 'health',
            require: 'health,runtime',
            title: l10nService.getMessage(l10nKeys.columnTitleHealth),
            template: '<avi-healthscore item = "row" stop-async-on-hide="true"></avi-healthscore>',
            visibility: 'm',
        }, {
            require: 'config',
            name: 'address',
            title: l10nService.getMessage(l10nKeys.columnTitleAddress),
            template: '<vs-address-grid-cell vs="row" />',
            visibility: 'd',
        }, {
            require: 'config',
            name: 'fqdn',
            title: l10nService.getMessage(l10nKeys.columnTitleAppDomainName),
            template: require('./vs-grid-fqdn-column.partial.html'),
            visibility: 'd',
        }, {
            require: 'config',
            name: 'services',
            title: l10nService.getMessage(l10nKeys.columnTitleServicePorts),
            template: '{{ config.getServicesString(row.data.config.services) }}',
            visibility: 'd',
        }, {
            require: 'config',
            name: 'pools',
            title: l10nService.getMessage(l10nKeys.columnTitlePools),
            template: require('./vs-grid-pools-column.partial.html'),
            visibility: 'd',
        }, {
            require: 'config',
            name: 'poolgroup',
            title: l10nService.getMessage(l10nKeys.columnTitlePoolGroup),
            template: '{{ row.data.poolgroups | repeatedRefs }}',
        }, {
            require: 'config',
            name: 'se_group',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceEngineGroup),
            template: '{{row.data.config.se_group_ref.name()}}',
        }, {
            require: 'runtime',
            name: 'service_engines',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceEngines),
            template: '<virtualservice-grid-service-engine row="row" />',
        }, {
            require: 'config',
            name: 'cloud',
            title: l10nService.getMessage(l10nKeys.columnTitleCloud),
            template: '{{ row.getCloudRef().name() }}',
            sortBy: 'cloud_ref',
        }, {
            require: 'config',
            name: 'vh_type',
            title: l10nService.getMessage(l10nKeys.columnTitleVirtualHosting),
            template: `<vs-list-virtual-hosting-cell
                            vs-type="row.getType()"
                            vh-type="row.getVHType()"
                        ></vs-list-virtual-hosting-cell>`,
        },
        {
            require: 'config',
            name: 'vrf_context',
            title: l10nService.getMessage(l10nKeys.columnTitleVrfContext),
            template: '{{ row.getVRFContextRef.name() }}',
        }, {
            require: 'runtime',
            name: 'service_engine_count',
            title: l10nService.getMessage(l10nKeys.columnTitleTotalServiceEngines),
            visibility: 'd',
            transform(vs) {
                const runtimeData = vs.getRuntimeData();

                if (runtimeData && Array.isArray(runtimeData.vip_summary)) {
                    return runtimeData.vip_summary.reduce((acc, vip) => {
                        if (Array.isArray(vip.service_engine)) {
                            acc += vip.service_engine.length;
                        }

                        return acc;
                    }, 0);
                }

                return 0;
            },
        }, {
            require: 'config',
            name: 'vrf_context',
            title: l10nService.getMessage(l10nKeys.columnTitleVrfContext),
            template: `{{ row.getVRFContextRef().name() ||
                '${l10nService.getMessage(l10nKeys.emptyDataLabel)}' }}`,
            visibility: 'optional',
        },
    ];

    //let's add metric series fields
    gridFields.push(
        ...gridMetricFields.map(seriesName => getGridMetricFieldConfig(seriesName)),
    );

    // TODO: Alerts column will not show if PERMISSION_ALERT changes from NO_ACCESS or
    // WRITE_ACCESS to READ_ACCESS unless page is reloaded.
    if (Auth.isPrivilegeAllowed('PERMISSION_ALERT')) {
        gridFields.push({
            require: 'alert',
            name: 'alerts',
            title: l10nService.getMessage(l10nKeys.columnTitleAlerts),
            template: '<item-alert-bell item="row"></item-alert-bell>',
        });
    }

    gridConfig.fields = gridFields;

    gridConfig.props = { l10nKeys };

    const { objectName } = gridConfig.collection;

    gridConfig.id = `${objectName}-list-page`;

    function isVSDisabled(vs) {
        return !vs.data.config.enabled;
    }

    function toggleMultiple(selected, enable) {
        const promises = [];

        selected.forEach(function(vs) {
            if (vs.data.config.enabled !== enable) {
                promises.push(vs.setEnabledState(enable));
            }
        });

        return $q.all(promises)
            .then(function() {
                return gridConfig.collection.load();
            }).catch(function(rsp) {
                aviAlertService.throw(rsp.data);
            });
    }

    gridConfig.multipleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnDelete),
        disabled: rows => _.every(rows, row => !row.isDroppable()),
        do(rows) {
            AviModal.open(
                'virtualservice-delete', {
                    rows,
                    collection: this.config.collection,
                }, 'avi-modal modal3',
            );

            return false;
        },
    }, {
        title: l10nService.getMessage(l10nKeys.actionBtnEnable),
        disabled(selected) {
            return !_.some(selected, function(vs) {
                return isVSDisabled(vs);
            });
        },
        do(selected) {
            toggleMultiple(selected, true);

            return true;
        },
    }, {
        title: l10nService.getMessage(l10nKeys.actionBtnDisable),
        disabled(selected) {
            return !_.some(selected, function(vs) {
                return !isVSDisabled(vs);
            });
        },
        do(selected) {
            toggleMultiple(selected, false);

            return true;
        },
    }];

    /**
     * Gets string of VS services to be displayed in grid.
     * @param  {Object} services - VS list of services.
     * @return {String} Comma-separated list of VS services.
     */
    gridConfig.getServicesString = function(services = []) {
        return services.map(service => {
            let result = service.port;

            if (service.port_range_end !== service.port) {
                result += `-${service.port_range_end}`;
            }

            if (service.enable_ssl) {
                result += ' (SSL)';
            }

            return result;
        }).join(', ');
    };

    $scope.gridConfig = CRUDGridConfig(gridConfig);

    $scope.$on('$destroy', () => {
        gridConfig.collection.destroy();
    });
}]);
