/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './error-page-profile.l10n';

import './error-page-profile.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name errorPageProfile
 * @description Error Page Profile list page.
 */
class ErrorPageProfileController {
    /**
     * Returns all status codes from specified page.
     * @param {ErrorPage} page
     * @return {string[]}
     */
    static getStatusCodesFromPage(page) {
        return page.getErrorPageStatusCodes();
    }

    constructor(
        ErrorPageProfileCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.ErrorPageProfileCollection = ErrorPageProfileCollection;
        this.CRUDGridConfig = CRUDGridConfig;
        /** @protected */
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        this.gridConfig = new this.CRUDGridConfig();
        this.gridConfig.collection = new this.ErrorPageProfileCollection();
        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }, {
            name: 'data.config.error_pages',
            title: l10nService.getMessage(l10nKeys.columnTitleErrorPages),
            transform: row => {
                const { error_pages: pages } = row.getConfig();

                if (!pages.isEmpty()) {
                    const statusCodes = pages.config.map(page => {
                        return ErrorPageProfileController.getStatusCodesFromPage(page).join(', ');
                    });

                    const totalPagesLabel = l10nService.getMessage(
                        l10nKeys.totalPagesLabel, [pages.count],
                    );

                    const statusCodesLabel = l10nService.getMessage(
                        l10nKeys.statusCodesLabel, [statusCodes.join(', ')],
                    );

                    return `
                            <div>${totalPagesLabel}</div>
                            <div class="error-page-profile-list__status-codes">
                                ${statusCodesLabel}
                            </div>`;
                }

                return '';
            },
        }];

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ErrorPageProfileController.$inject = [
        'ErrorPageProfileCollection',
        'CRUDGridConfig',
        'l10nService',
];

angular.module('aviApp').component('errorPageProfileList', {
    controller: ErrorPageProfileController,
    template:
            `<div class="panel templatesList error-page-profile-list">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});
