/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';

import {
    IcapProfile,
    IcapProfileCollection,
} from 'ajs/modules/icap-profile';

import {
    SchemaService,
    StringService,
    TEnumValueLabelsHash,
} from 'ajs/modules/core/services';

import template from './icap-profile-list-page.component.html';
import * as l10n from './icap-profile-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type IcapProfileCollectionType = typeof IcapProfileCollection;

/**
 * @description
 *
 *   ICAP Profiles list-page component.
 *
 * @author Aravindh Nagarajan
 */
class IcapProfileListPageController {
    /**
     * Icap-profile collection-grid config.
     */
    public gridConfig: ICollectionGridConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of failAction enum values to their labels
     */
    private failActionEnumValueLabelHash: TEnumValueLabelsHash;

    /**
     * Hash of icapVendor enum values to their labels
     */
    private icapVendorEnumValueLabelHash: TEnumValueLabelsHash;

    constructor(
        private IcapProfileCollection: IcapProfileCollectionType,
        private schemaService: SchemaService,
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        this.failActionEnumValueLabelHash =
            this.schemaService.getEnumValueLabelsHash('ICAPFailAction');

        this.icapVendorEnumValueLabelHash =
            this.schemaService.getEnumValueLabelsHash('ICAPVendor');

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.initIcapProfileGrid();
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }

    /**
     * Initializes ICAP-Profile collection-grid.
     */
    private initIcapProfileGrid(): void {
        this.gridConfig = createCrudCollectionGridConfig();

        const { gridConfig, l10nService } = this;

        gridConfig.id = 'icap-profile-list-page';

        gridConfig.collection = new this.IcapProfileCollection({ isStatic: false });

        gridConfig.fields = [{
            name: 'getConfig().name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }, {
            name: 'getConfig().service_uri',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceUri),
        }, {
            name: 'pool_group_ref',
            title: l10nService.getMessage(l10nKeys.columnTitlePoolGroup),
            transform: (row: IcapProfile) => {
                const { pool_group_ref: poolGroupRef } = row.getConfig();

                return this.stringService.name(poolGroupRef);
            },
        }, {
            name: 'fail_action',
            title: l10nService.getMessage(l10nKeys.columnTitleFailAction),
            transform: (row: IcapProfile) => {
                const { fail_action: failAction } = row.getConfig();

                return this.failActionEnumValueLabelHash[failAction];
            },
        }, {
            name: 'vendor',
            title: l10nService.getMessage(l10nKeys.columnTitleVendor),
            transform: (row: IcapProfile) => {
                const { vendor } = row.getConfig();

                return this.icapVendorEnumValueLabelHash[vendor];
            },
        }];

        gridConfig.singleactions.push({
            title: l10nService.getMessage(l10nKeys.actionBtnDelete),
            class: 'icon-trash',
            do: (item: IcapProfile) => item.drop(),
        });
    }
}

IcapProfileListPageController.$inject = [
    'IcapProfileCollection',
    'schemaService',
    'stringService',
    'l10nService',
];

export const icapProfileListPageOptions = {
    controller: IcapProfileListPageController,
    template,
};
