/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IAzureUserPassCredentials } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

export class AzureUserPassCredentialsConfigItem extends MessageItem<IAzureUserPassCredentials> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'AzureUserPassCredentials',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.username);
    }

    /**
     * Deletes configured fields.
     */
    public clearFields(): void {
        delete this.config.username;
        delete this.config.password;
        delete this.config.tenant_name;
    }
}
