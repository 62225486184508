/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './VirtualServiceDnsRecordsController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Controller for Virtual Service -> DNS Records page.
 */
class Controller {
    constructor($scope, DNSQueryCollection, MetricDataCollection, l10nService) {
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.virtualservice = $scope.VirtualService;

        this.fqdnListCollection = new DNSQueryCollection({
            params: this.getFqdnListCollectionParams(),
        });

        this.totalQueriesCollection = new MetricDataCollection({
            params: this.getTotalQueriesCollectionParams(),
        });

        this.busy = true;

        this.virtualservice.load(['config'])
            .then(() => {
                this.virtualservice.addLoad(['health', 'alert']);

                this.fqdnListGridConfig = {
                    collection: this.fqdnListCollection,
                    fields: [{
                        name: 'data.name',
                        title: 'FQDN',
                    }, {
                        name: 'udp',
                        title: l10nService.getMessage(l10nKeys.columnTitleUdpQueries),
                        template:
                                '{{ config.options.getValue(' +
                                'row.data.metrics[\'dns_client.avg_udp_queries\'], 2) }}',
                    }, {
                        name: 'errors',
                        title: l10nService.getMessage(l10nKeys.columnTitleErrors),
                        template:
                                '{{ config.options.getValue(' +
                                'row.data.metrics[\'dns_client.avg_errored_queries\']) }}',
                    }, {
                        name: 'data.source',
                        title: l10nService.getMessage(l10nKeys.columnTitleSource),
                    }, {
                        name: 'data.service',
                        title: l10nService.getMessage(l10nKeys.columnTitleService),
                    }, {
                        name: 'data.tenant',
                        title: l10nService.getMessage(l10nKeys.columnTitleTenant),
                    }],
                    layout: {
                        hideSearch: true,
                    },
                    options: {
                        getValue: this.getValue,
                    },
                };

                this.totalQueriesGridConfig = {
                    collection: this.totalQueriesCollection,
                    fields: [{
                        name: 'udp',
                        title: l10nService.getMessage(l10nKeys.columnTitleUdpQueries),
                        template:
                                '{{ config.options.getValue(' +
                                'row.data.metrics[\'dns_client.avg_complete_queries\'], 2) }}',
                    }, {
                        name: 'errors',
                        title: l10nService.getMessage(l10nKeys.columnTitleErrors),
                        template:
                                '{{ config.options.getValue(' +
                                'row.data.metrics[\'dns_client.avg_errored_queries\']) }}',
                    }],
                    layout: {
                        hideSearch: true,
                        hideTableNavigation: true,
                        hideTenantColumn: true,
                    },
                    options: {
                        getValue: this.getValue,
                    },
                };
            }).finally(() => {
                this.busy = false;
            });

        $scope.$on('$destroy', () => {
            this.fqdnListCollection.destroy();
            this.totalQueriesCollection.destroy();
        });
    }

    /**
     * Returns the params object used for this.totalQueriesCollection.
     * @return {Object}
     */
    getTotalQueriesCollectionParams() {
        return {
            uuid: this.virtualservice.id,
            dimension_limit: 1000,
            metric_id: [
                    'dns_client.avg_complete_queries',
                    'dns_client.avg_errored_queries',
            ].join(),
        };
    }

    /**
     * Returns the params object used for this.fqdnListCollection.
     * @return {Object}
     */
    getFqdnListCollectionParams() {
        return {
            uuid: this.virtualservice.id,
            metric_id: [
                    'dns_client.avg_udp_queries',
                    'dns_client.avg_errored_queries',
            ].join(),
            obj_id: '*',
        };
    }

    /**
     * Returns the value for the metric.
     * @param  {Object} metric   - Metric data.
     * @param  {number} decimals - Number of decimal places for rounding.
     * @return {number}
     */
    getValue(metric, decimals = 0) {
        return Math.num2abbrStr(metric.data[0].value, decimals);
    }
}

Controller.$inject = [
    '$scope',
    'DNSQueryCollection',
    'MetricDataCollection',
    'l10nService',
];

angular.module('aviApp').controller('VirtualServiceDnsRecordsController', Controller);
