/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './dsr-profile-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const DSR_TYPE_L2 = 'DSR_TYPE_L2';
const ENCAP_IPINIP = 'ENCAP_IPINIP';

class DsrProfileSettingsController {
    constructor(
        schemaService,
        dropDownUtils,
        l10nService,
    ) {
        this.dropDownUtils_ = dropDownUtils;
        this.schemaService_ = schemaService;
        this.DSR_TYPE_L2 = DSR_TYPE_L2;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const dsrTypeEnums = this.schemaService_.getEnumValues('DsrType');
        const dsrEncapTypeEnums = this.schemaService_.getEnumValues('EncapType');
        const { dsr_profile: dsrProfile } = this.profileConfig;

        this.dsrTypes = dsrTypeEnums.map(this.createDropdownOption_);
        this.dsrEncapTypes = dsrEncapTypeEnums.map(this.createDropdownOption_);
        this.profileConfig.useDSR = !!dsrProfile;
    }

    /**
     * Callback method to create dropdown options.
     * @param {string} value - value of the dropdown.
     * @param {string} label - label for the dropddown.
     * @param {string} description - description of dropdown.
     * @protected
     * @return {DropDownOption}
     */
    createDropdownOption_ = ({ value, label, description }) => {
        return this.dropDownUtils_.createOption(
            value,
            label,
            description,
        );
    }

    /**
     * Update dropdown data if profile type is changed.
     * @param {Object} profileType selected profile type.
     */
    $onChanges({ profileType }) {
        if (!profileType.isFirstChange() &&
            profileType.currentValue !== profileType.previousValue) {
            this.profileConfig.useDSR = false;
            this.onDsrToggle();
        }
    }

    /**
     * Set DSR encapsulation to undefined for DSR type L2,
     * else set it to ENCAP_IPINIP.
     */
    onDsrTypeSelect() {
        const { dsr_profile: dsrProfile } = this.profileConfig;

        dsrProfile.dsr_encap_type =
            dsrProfile.dsr_type === DSR_TYPE_L2 ?
                undefined :
                ENCAP_IPINIP;
    }
}

DsrProfileSettingsController.$inject = [
    'schemaService',
    'dropDownUtils',
    'l10nService',
];

/**
 * @ngdoc component
 * @name dsrProfileSettings
 * @param {Object} profileConfig - Network profile data.
 * @param {NetProfileType} profileType -  Network profile type.
 * @param {boolean} editMode - EditMode for the already created newtwork profile.
 * @param {Function} onDsrToggle -  Handle check and uncheck of use DSR checkbox.
 * @author Ashish Verma
 * @description
 *      Form component for DSR settings of Network Profile.
 *      Only for TCP and UDP network profile types
 */
angular.module('aviApp').component('drsProfileSettings', {
    bindings: {
        profileConfig: '<',
        profileType: '<',
        editMode: '<',
        onDsrToggle: '&',
    },
    controller: DsrProfileSettingsController,
    templateUrl: 'src/components/templates/profiles/network/dsr-profile-settings.html',
});
