/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { infraCloudStateFeatureKey } from './infra-cloud-store.selectors';
import { infraCloudStateReducer } from './infra-cloud-store.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(infraCloudStateFeatureKey, infraCloudStateReducer),
    ],
    declarations: [],
})
export class IInfraCloudStateModule {}
