/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('innerText', function() {
    return {
        restrict: 'A',
        link: function link(scope, elm, attr) {
            const text = $(`<span class="add-on">${attr.innerText}</span>`);

            elm.wrap('<div class="inner-text"/>').after(text);

            if (elm[0].style.width) {
                elm.parent()[0].style.width = elm[0].style.width;
                elm[0].style.width = '';
            }

            if (elm.hasClass('pull-left')) {
                elm.parent().addClass('pull-left');
                elm.removeClass('pull-left');
            }

            if (elm.hasClass('pull-right')) {
                elm.parent().addClass('pull-right');
                elm.removeClass('pull-right');
            }

            const unWatch = scope.$watch(function() {
                return attr.innerText;
            }, function(newValue, oldValue) {
                if (oldValue !== newValue) {
                    text.text(newValue);
                }
            });

            scope.$on('$destroy', function() {
                unWatch();
            });
        },
    };
});
