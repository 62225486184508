/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'email-smtp-page-card';
const prefix = `${moduleName}.${componentName}`;

export const emailSmtpSourceCardTitle = `${prefix}.emailSmtpSourceCardTitle`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const smtpSourceLabel = `${prefix}.smtpSourceLabel`;
export const fromAddressLabel = `${prefix}.fromAddressLabel`;
export const smtpServerLabel = `${prefix}.smtpServerLabel`;
export const userNameLabel = `${prefix}.userNameLabel`;
export const sendTestEmailBtnLabel = `${prefix}.sendTestEmailBtnLabel`;
export const localHostLabel = `${prefix}.localHostLabel`;
export const anonymousSmtpServerLabel = `${prefix}.anonymousSmtpServerLabel`;
export const portLabel = `${prefix}.portLabel`;

export const ENGLISH = {
    [emailSmtpSourceCardTitle]: 'email smtp source',
    [emptyDataLabel]: 'N/A',
    [smtpSourceLabel]: 'SMTP Source',
    [fromAddressLabel]: 'From Address',
    [smtpServerLabel]: 'SMTP Server',
    [portLabel]: 'Port',
    [userNameLabel]: 'Username',
    [sendTestEmailBtnLabel]: 'Send Test Email',
    [anonymousSmtpServerLabel]: 'Anonymous SMTP Server',
    [localHostLabel]: 'Local Host',
};
