/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { InitialDataService } from 'ajs/modules/core/services/initial-data';
import { AviDatePipe } from 'ng/modules/core/pipes';
import './app-about-modal.component.less';
import * as l10n from './app-about-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *   Modal Component for displaying current application version info.
 *   [version, build, build date, patch etc]
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'app-about-modal',
    templateUrl: './app-about-modal.component.html',
})
export class AppAboutModalComponent implements OnInit {
    /**
     * Called on modal close.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Controller build number.
     */
    public controllerBuild: number;

    /**
     * Controller version.
     */
    public controllerVersion: string;

    /**
     * Controller patch.
     */
    public controllerPatch: string;

    /**
     * Controller build date.
     */
    public controllerBuildDate: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly aviDatePipe: AviDatePipe,
        private readonly initialDataService: InitialDataService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const {
            controllerBuild,
            controllerPatch,
            controllerBuildDate,
            controllerVersion,
        } = this.initialDataService;

        this.controllerBuild = controllerBuild;
        this.controllerPatch = controllerPatch;
        this.controllerVersion = controllerVersion;
        this.controllerBuildDate = this.aviDatePipe.transform(controllerBuildDate);
    }

    /**
     * Handler for Close button click.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }
}
