/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb-geodb-profile';
const componentName = 'geo-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const sectionTitleEntries = `${prefix}.sectionTitleEntries`;
export const atleastOneGeoDbWarning = `${prefix}.atleastOneGeoDbWarning`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Geo Profile: {0}',
    [modalHeaderNew]: 'New Geo Profile: {0}',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [sectionTitleGeneral]: 'General',
    [sectionTitleEntries]: 'Entries',
    [atleastOneGeoDbWarning]: 'Atleast one GeoDB entry needs to be configured to continue.',
};
