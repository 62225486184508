/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { BgpRoutingOptionsConfigItem } from 'ajs/modules/vrf-context';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './bgp-routing-options-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * BGP RoutingOptions modal component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'bgp-routing-options-modal',
    templateUrl: './bgp-routing-options-modal.component.html',
})
export class BgpRoutingOptionsModalComponent implements OnInit {
    /**
     * BgpRoutingOptionsConfigItem instance.
     */
    @Input()
    public editable: BgpRoutingOptionsConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit: EventEmitter<void> = new EventEmitter<void>();

    /**
     * BgpRoutingOptions object type.
     */
    public objectType: string;

    /**
     * Layout for BGP RoutingOptions modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Returns true if `learn_routes` or `advertise_default_route` is true.
     * Used to disable `learn_only_default_route` and `advertise_learned_routes` checkboxes.
     */
    public get learnRoutesOrAdvertiseDefaultRoute(): boolean {
        const {
            learnRoutes,
            advertiseDefaultRoute,
        } = this.editable;

        return learnRoutes || advertiseDefaultRoute;
    }

    /**
     * Returns true if `learn_only_default_route` or `advertise_learned_routes` is true.
     * Used to disable `learn_routes` and `advertise_default_route` checkboxes.
     */
    public get learnOnlyDefaultRouteOrAdvertiseLearnedRoutes(): boolean {
        const {
            learnOnlyDefaultRoute,
            advertiseLearnedRoutes,
        } = this.editable;

        return learnOnlyDefaultRoute || advertiseLearnedRoutes;
    }
}
