/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { L10nService } from '@vmw/ngx-vip';

import { every } from 'underscore';

import { ConnectedPosition } from '@angular/cdk/overlay';
import { TOP_LEFT_ALIGNED_CONNECTED_POSITION } from 'ng/shared/components/avi-dropdown-button';

import {
    AviDataGridFieldVisibility,
    IAviDataGridConfigField,
    TAviDataGridColumnPresenceMap,
} from '../../avi-data-grid/avi-data-grid.types';

import * as l10n from './avi-data-grid-column-selection.component.l10n';

import './avi-data-grid-column-selection.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the pop-up column selection panel for the avi-data-grid family.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-data-grid-column-selection',
    templateUrl: './avi-data-grid-column-selection.component.html',
})
export class AviDataGridColumnSelectionComponent {
    /**
     * Fields to hide/show.
     */
    @Input()
    public fields: IAviDataGridConfigField[];

    /**
     * Map to decide which field columns to show and which to hide.
     */
    @Input()
    public showColumnHash: TAviDataGridColumnPresenceMap = {};

    @Output()
    public showColumnHashChange = new EventEmitter<TAviDataGridColumnPresenceMap>();

    /**
     * Positions of the column selection panel relative to the hide/show button. Contains only one
     * entry of the primary postion.
     */
    public viewColumnPanelPositions: [ConnectedPosition] = [TOP_LEFT_ALIGNED_CONNECTED_POSITION];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Subject to control the hide/show column selection panel close button.
     */
    private columnSelectionCloseControlSubject = new Subject<boolean>();

    /**
     * Indicate whether all field columns are present.
     */
    public get allFieldColumnsShown(): boolean {
        return every(this.showColumnHash, shown => shown === true);
    }

    /**
     * Return an observable to allow hide/show column panel to close itself through subscription.
     */
    public get columnSelectionCloseButtonControl$(): Observable<boolean> {
        return this.columnSelectionCloseControlSubject.asObservable();
    }

    public constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Check if a field is mandatory and can't be hidden.
     */
    public isMandatoryField(field: IAviDataGridConfigField): boolean {
        return field.visibility === AviDataGridFieldVisibility.MANDATORY;
    }

    /**
     * Show all field columns by resetting the column presence hash entries to all true.
     */
    public showAllFieldColumns(): void {
        Object.keys(this.showColumnHash)
            .forEach(id => this.showColumnHash[id] = true);
    }

    /**
     * Close the column hide/show panel.
     */
    public closeColumnSelectionPanel(): void {
        this.columnSelectionCloseControlSubject.next(false);
    }
}
