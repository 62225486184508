/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosTableGridName
 * @param {MetricsDimension} row - MetricsDimension item.
 * @param {function} onSeriesSelect - Function called when name of the filter is clicked.
 * @param {function} disableSelect - Determines if the name of the filter can be clicked.
 * @param {boolean} showTooltip - Determines if the tooltip should be shown, true when the filter is
 *     an attack.
 * @description
 *     Displays the name of the MetricsDimension item, along with a tooltip if applicable. Calls the
 *     onSeriesSelect function when clicked.
 */
class VsSecurityDdosTopListGridCellNameController {
    constructor($templateCache, schemaService) {
        this.schemaService_ = schemaService;
        this.attackDescriptionTooltipTemplate = require(
            './vs-security-ddos-top-list-grid-cells-tooltip.partial.html',
        );
    }

    $onInit() {
        this.disabled = false;

        if (this.showTooltip) {
            const { description } = this.schemaService_
                .getEnumValue('AttackType', this.row.getName());

            this.description = description;
        }
    }

    /**
     * Selects filter when the name is clicked.
     */
    selectSeries() {
        this.onSeriesSelect({
            filter: this.row.getName(),
        });
    }

    /**
     * Returns true if clicking name is disabled.
     * @return {boolean}
     */
    isDisabled() {
        return this.disableSelect({
            filter: this.row.getName(),
        });
    }
}

VsSecurityDdosTopListGridCellNameController.$inject = [
    '$templateCache',
    'schemaService',
];

angular.module('avi/vs/security')
    .component('vsSecurityDdosTopListGridCellName', {
        bindings: {
            row: '<',
            onSeriesSelect: '&',
            disableSelect: '&',
            showTooltip: '<',
        },
        controller: VsSecurityDdosTopListGridCellNameController,
        templateUrl: 'src/components/applications/virtualservice/vs-security-tab/' +
                'vs-security-ddos/vs-security-ddos-top-lists/' +
                'vs-security-ddos-top-list-grid-cells/' +
                'vs-security-ddos-top-list-grid-cell-name.html',
    });
