/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *
 *   BGP Routing Options Message Item.
 *
 * @author Aravindh Nagarajan
 */
import { Component, Type } from '@angular/core';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IBgpRoutingOptions } from 'generated-types';
import { BgpRoutingOptions } from 'object-types';
import { BgpRoutingOptionsModalComponent } from 'ng/modules/vrf-context';
import { IItemParams, withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../vrf-context.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for BgpRoutingOptionsConfigItem.
 */
export const BGP_ROUTING_OPTIONS_CONFIG_ITEM_TOKEN = 'BgpRoutingOptionsConfigItem';

export class BgpRoutingOptionsConfigItem extends
    withFullModalMixin(MessageItem)<IBgpRoutingOptions> {
    public static ajsDependencies = ['l10nService'];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: BgpRoutingOptions,
            windowElement: BgpRoutingOptionsModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for routing option label.
     */
    public get label(): string {
        return this.config.label ?? '';
    }

    /**
     * Returns true if learning is enabled.
     */
    public isLearningEnabled(): boolean {
        const {
            learnRoutes,
            learnOnlyDefaultRoute,
        } = this;

        return learnRoutes || learnOnlyDefaultRoute;
    }

    /**
     * Returns true if advertising is enabled.
     */
    public isAdvertisingEnabled(): boolean {
        const {
            advertiseLearnedRoutes,
            advertiseDefaultRoute,
        } = this;

        return advertiseLearnedRoutes || advertiseDefaultRoute;
    }

    /**
     * Getter for this.editable.config.learn_only_default_route.
     */
    public get learnOnlyDefaultRoute(): boolean {
        const {
            config: {
                learn_only_default_route: learnOnlyDefaultRoute,
            },
        } = this;

        return learnOnlyDefaultRoute;
    }

    /**
     * Getter for this.editable.config.advertise_learned_routes
     */
    public get advertiseLearnedRoutes(): boolean {
        const {
            config: {
                advertise_learned_routes: advertiseLearnedRoutes,
            },
        } = this;

        return advertiseLearnedRoutes;
    }

    /**
     * Getter for this.editable.config.learn_routes.
     */
    public get learnRoutes(): boolean {
        const {
            config: {
                learn_routes: learnRoutes,
            },
        } = this;

        return learnRoutes;
    }

    /**
     * Getter for this.editable.config.advertise_default_route.
     */
    public get advertiseDefaultRoute(): boolean {
        const {
            config: {
                advertise_default_route: advertiseDefaultRoute,
            },
        } = this;

        return advertiseDefaultRoute;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.routingOptionsLabel);
    }

    /**
     * Returns the string to be displayed as the breadcrumb description.
     */
    protected getModalBreadcrumbDescription(params: IItemParams): string {
        const editable = params.editable as BgpRoutingOptionsConfigItem;

        return editable.config.label;
    }
}
