/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { ErrorPageBody } from 'ajs/modules/error-page';

import * as l10n from './error-page-body-modal.l10n';
import './error-page-body-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Error Page Body full-modal component.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'error-page-body-modal',
    templateUrl: './error-page-body-modal.component.html',
})
export class ErrorPageBodyModalComponent implements OnInit {
    /**
     * ErrorPageBody ObjectTypeItem instance.
     */
    @Input()
    public editable: ErrorPageBody;

    /**
     * ErrorPageBody object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Error Page Body modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
