/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

//TODO modify to support SSL string parts
angular.module('aviApp').directive('logSelection', ['$timeout', function($timeout) {
    function link(scope, elm) {
        const delay = 50; //for throttling mouseover events

        const onMouseMove = _.throttle(function(e) {
            let passedHovered = false;

            //document.getSelection().removeAllRanges();
            elm.children().each(function(key, child) {
                if (passedHovered) {
                    $(child).removeClass('log-filter-selected');
                } else {
                    $(child).addClass('log-filter-selected');
                }

                if (!passedHovered && e.target === child) {
                    passedHovered = true;
                }
            });
        }, delay);

        let value,
            fragment,
            i;

        scope.parts = [];
        value = scope.value;

        const parts = _.filter(value.split(/[^a-zA-Z0-9]/g), function(p) {
            return p.length;
        });

        for (i = 0; i < parts.length; i++) {
            fragment = value.substr(0, value.indexOf(parts[i]) + parts[i].length);
            scope.parts.push(fragment);
            value = value.slice(fragment.length);
        }

        if (value.length) {
            scope.parts.push(value);
        }

        elm
            .on('mousemove', function(e) {
                //don't prevent from normal selection
                if (e.buttons === 1) {
                    return;
                }

                onMouseMove(e);
            })
            .on('click', () => {
                const selectedEl = elm.find('>span.log-filter-selected');
                const textContent = selectedEl.text();
                let text = textContent.replace(/\r?\n|\r/g, '');

                text = JSON.stringify(text);

                const wholeSelected = selectedEl.length === elm.find('>span').length;
                const mod = wholeSelected ? '' : '^';
                const str = `${scope.key}${mod}=${text}`;

                scope.onUpdate({ str });
                scope.$apply();
            })
            .on('mouseleave', function(e) {
                //don't hide normal selection
                if (e.buttons === 1) {
                    return;
                }

                $timeout(function() {
                    elm.children().removeClass('log-filter-selected');
                }, delay * 1.1);
            });
    }

    return {
        restrict: 'A',
        scope: {
            key: '@',
            value: '@',
            onUpdate: '&',
        },
        link,
        template: '<span class="sel-log-info-client-ip-part"' +
            'ng-repeat="part in parts track by $index">{{part}}</span>',
    };
}]);
