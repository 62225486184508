/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'gslb';

export const gslbPoolMemberUnavailableOperStatus = `${prefix}.gslbPoolMemberUnavailableOperStatus`;
export const federationCheckpointModalBreadcrumbTitle = `${prefix}.federationCheckpointModalBreadcrumbTitle`;
export const geoProfileModalBreadcrumbTitle = `${prefix}.geoProfileModalBreadcrumbTitle`;

export const ENGLISH = {
    [gslbPoolMemberUnavailableOperStatus]: 'This site is not monitoring the GSLB service pool ' +
        'member',
    [federationCheckpointModalBreadcrumbTitle]: 'Federation Checkpoint',
    [geoProfileModalBreadcrumbTitle]: 'Geo Profile',
};
