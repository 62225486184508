/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './tri-checkbox.less';

/**
 * @ngdoc component
 * @name triCheckbox
 * @param {number} state - Expression evaluating to a number that indicates the state of the
 *     checkbox. 0 for unchecked, 1 for checked. and 2 for indeterminate.
 * @param {Function} onClick - Function to be called when the checkbox or label is clicked.
 * @param {string} label - String to be shown as the label for the checkbox.
 * @description
 *     3-state checkbox component, useful for when a single checkbox is representative of a set of
 *     checkboxes.
 */
class TriCheckboxController {
    /**
     * Handles clicking the checkbox or label.
     * @param {angular.$event} $event
     */
    handleClick($event) {
        $event.stopPropagation();
        this.onClick();
    }
}

angular.module('aviApp').component('triCheckbox', {
    controller: TriCheckboxController,
    bindings: {
        state: '<',
        onClick: '&',
        label: '@',
    },
    templateUrl: 'src/components/common/tri-checkbox/tri-checkbox.html',
});
