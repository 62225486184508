/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    ALERT_EMAIL_CONFIG_COLLECTION_TOKEN,
    ALERT_SYSLOG_CONFIG_COLLECTION_TOKEN,
    SNMP_TRAP_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/alert/alert.tokens';

import {
    AlertEmailConfigCollection,
} from 'ajs/modules/alert/factories/alert-email-config/alert-email-config.collection.factory';

import {
    SnmpTrapProfileCollection,
} from 'ajs/modules/alert/factories/snmp-trap-profile/snmp-trap-profile.collection.factory';

import {
    AlertSyslogConfigCollection,
} from 'ajs/modules/alert/factories/alert-syslog-config/alert-syslog-config.collection.factory';

import {
    ControlScriptCollection,
    CONTROL_SCRIPT_COLLECTION_TOKEN,
} from 'ajs/modules/scripts/factories/control-script/control-script.collection.factory';

type TAlertEmailConfigCollection = typeof AlertEmailConfigCollection;
type TSnmpTrapProfileCollection = typeof SnmpTrapProfileCollection;
type TControlScriptCollection = typeof ControlScriptCollection;
type TAlertSyslogConfigCollection = typeof AlertSyslogConfigCollection;

const $injector = '$injector';

export const alertEmailConfigCollectionProvider = {
    deps: [$injector],
    provide: AlertEmailConfigCollection,
    useFactory(injector: Injector): TAlertEmailConfigCollection {
        return injector.get(ALERT_EMAIL_CONFIG_COLLECTION_TOKEN);
    },
};

export const snmpTrapProfileCollectionProvider = {
    deps: [$injector],
    provide: SnmpTrapProfileCollection,
    useFactory(injector: Injector): TSnmpTrapProfileCollection {
        return injector.get(SNMP_TRAP_PROFILE_COLLECTION_TOKEN);
    },
};

export const alertSyslogConfigCollectionProvider = {
    deps: [$injector],
    provide: AlertSyslogConfigCollection,
    useFactory(injector: Injector): TAlertSyslogConfigCollection {
        return injector.get(ALERT_SYSLOG_CONFIG_COLLECTION_TOKEN);
    },
};

export const controlScriptCollectionProvider = {
    deps: [$injector],
    provide: ControlScriptCollection,
    useFactory(injector: Injector): TControlScriptCollection {
        return injector.get(CONTROL_SCRIPT_COLLECTION_TOKEN);
    },
};
