/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'dns-service-controller';
const prefix = `${moduleName}.${componentName}`;

export const discardConfirmMessage = `${prefix}.discardConfirmMessage`;
export const dnsVirtualServicesLabel = `${prefix}.dnsVirtualServicesLabel`;
export const virtualServiceInputLabel = `${prefix}.virtualServiceInputLabel`;
export const virtualServiceInputPlaceholder = `${prefix}.virtualServiceInputPlaceholder`;
export const createVirtualServiceBtnLabel = `${prefix}.createVirtualServiceBtnLabel`;
export const removeConfigurationTitle = `${prefix}.removeConfigurationTitle`;
export const addVirtualServiceBtnLabel = `${prefix}.addVirtualServiceBtnLabel`;

export const ENGLISH = {
    [discardConfirmMessage]: 'Are you sure you want to discard?',
    [dnsVirtualServicesLabel]: 'DNS Virtual Services',
    [virtualServiceInputLabel]: 'Virtual Service',
    [virtualServiceInputPlaceholder]: 'Select Virtual Service',
    [createVirtualServiceBtnLabel]: 'Create Virtual Service',
    [removeConfigurationTitle]: 'Remove Configuration',
    [addVirtualServiceBtnLabel]: '+ Add Virtual Service',
};
