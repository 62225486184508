/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './http-status-match.less';
import * as l10n from './http-status-match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class HTTPStatusMatchController {
    constructor(
        $element,
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        $element.addClass('http-status-match');
    }
}

HTTPStatusMatchController.$inject = [
    '$element',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @requires httpStatusMatch
 * @param {HTTPStatusMatch} match - HTTPStatusMatch instance.
 * @param {string} hideMatchCriteria - If not empty, will hide "IS" and "IS NOT" selector.
 * @description
 *      Component for configuring HTTPStatusMatch instance.
 */
angular.module('aviApp').directive('httpStatusMatch', function() {
    return {
        restrict: 'E',
        controller: HTTPStatusMatchController,
        controllerAs: '$ctrl',
        scope: {
            match: '=',
            hideMatchCriteria: '@?',
        },
        templateUrl: 'src/components/common/http-status-match/http-status-match.html',
    };
});
