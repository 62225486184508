/**
 * @module ServiceEngineGroup
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IUpgradeStatusEssential,
    withUpgradeStatusMixin,
} from 'ajs/modules/upgrade';
import { IUpgradeStatusInfo } from 'generated-types';
import { SEGroup as SEGroupOriginal } from './se-group.item';

interface ISegInventoryData {
    upgradestatus: IUpgradeStatusInfo;
}

/**
 * @desc
 *
 *      Item for SEGroup.
 *      Integration of the original SEGroup class and UpgradeStatusMixin.
 *
 * @author Zhiqian Liu
 */
export class SEGroupBase extends SEGroupOriginal implements IUpgradeStatusEssential {
    public getInventoryData: () => ISegInventoryData;

    /**
     * @override
     */
    public getUpgradeStatusConfig(): IUpgradeStatusInfo {
        return this.getInventoryData()?.upgradestatus || {};
    }
}

export const SEGroup = withUpgradeStatusMixin(SEGroupBase);

// Export instance type ITSEGroup for type annotation use only due to the fact that SEGroup class
// can't be used that way since it's a generated intermidiate class and it works as a value.
export type ITSEGroup = InstanceType<typeof SEGroup>;
