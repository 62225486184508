/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { SSO_POLICY_COLLECTION_TOKEN } from 'ajs/modules/sso-policy/sso-policy.tokens';
import * as l10n from './sso-policy-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'sso-policy-list';

class SSOPolicyListController {
    constructor(
        $element,
        SSOPolicyCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.$element_ = $element;
        this.SSOPolicyCollection_ = SSOPolicyCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.SSOPolicyCollection_(),
            fields: [{
                name: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                template: '{{row.getName()}}',
                sortBy: 'name',
            },
            {
                name: 'default_auth_profile',
                title: l10nService.getMessage(l10nKeys.columnTitleAuthProfile),
                template: '{{row.getDefaultAuthProfileRef() | name}}',
            }],
            permission: 'PERMISSION_SSOPOLICY',
        };

        const { objectName } = gridConfigOptions.collection;

        gridConfigOptions.id = `${objectName}-list-page`;

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

SSOPolicyListController.$inject = [
    '$element',
    SSO_POLICY_COLLECTION_TOKEN,
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @ngdoc component
 * @name SSOPolicyList
 * @author Aravindh Nagarajan
 * @description SSO Policy list page with options to create/delete/edit a policy.
 */
angular.module('aviApp').component('ssoPolicyList', {
    controller: SSOPolicyListController,
    template:
        `<div class="templatesList">
            <collection-grid config="$ctrl.gridConfig"></collection-grid>
        </div>`,
});
