/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name HSGraphCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     DataSource to get lists of nodes and edges for the HealthScore Graph.
 */
angular.module('aviApp').factory('HSGraphDataSource', [
'DataSource', 'Timeframe', 'HSGraphMetrics',
function(DataSource, Timeframe, HSGraphMetrics) {
    return class HSGraphDataSource extends DataSource {
        constructor(args) {
            angular.extend(args, {
                defaultFields: [
                    {
                        id: 'config',
                        preserved: true,
                        subscribers: ['__default_field'],
                    },
                ],
                defaultParams: {
                    hstree: true,
                },
            });

            super(args);

            this.onTimeframeChange_ = this.onTimeframeChange_.bind(this);
            Timeframe.on('change', this.onTimeframeChange_);

            this.updateInterval_ = 15;
            this.protectedUpdateInterval_ = true;

            this.metricSeriesPropList_ = HSGraphMetrics.seriesPropList;

            this.setStepAndLimitParams_();
        }

        /** @override */
        getRequestParams_() {
            const owner = this.owner_;
            const item = owner.getRootItem();
            const request = super.getRequestParams_();
            const seriesStart = owner.metrics.getSeriesStart();

            if (!angular.isUndefined(seriesStart)) {
                request.start = seriesStart;
            }

            request['objectName_'] = [
                'analytics',
                'healthscore',
                item.objectName,
                item.id,
            ];

            return request;
        }

        /**
         * Calculates the diff between two lists - present and received Ids.
         * @param {string[]} oldIds
         * @param {string[]} newIds
         * @returns {{
         *     toBeRemoved: string[],
         *     toBeUpdated: string[],
         *     toBeUpdatedHash: {Object},
         *     toBeAdded: string[],
         *     toBeAddedHash: {Object}
         * }} Lists and hashes of modified Items.
         * @protected
         * @static
         */
        static calcMergeDiff_(oldIds, newIds) {
            const
                toBeUpdated = _.intersection(oldIds, newIds),
                toBeRemoved = _.difference(oldIds, newIds),
                toBeAdded = _.difference(newIds, oldIds);

            return {
                toBeRemoved,
                toBeUpdated,
                toBeUpdatedHash: toBeUpdated.reduce(HSGraphDataSource.reduceIdListToHash_, {}),
                toBeAdded,
                toBeAddedHash: toBeAdded.reduce(HSGraphDataSource.reduceIdListToHash_, {}),
            };
        }

        /**
         * Function to be passed into Array.prototype.reduce to transform an array of ids into
         * a hash.
         * @param {Object} hash
         * @param {Item.id} id
         * @returns {Object}
         * @protected
         * @static
         */
        static reduceIdListToHash_(hash, id) {
            hash[id] = true;

            return hash;
        }

        /**
         * For graph rendering it is important to know how graph's data has been changed. This
         * method figures it out based on the two diffs between list of nodes we had and
         * received. And same for edges.
         * @param {Object} nodeDiff
         * @param {Object} edgeDiff
         * @returns {number} - 1 if list of nodes has been changed, 2 if list of edges got
         *     changed, 3 otherwise.
         * @protected
         * @static
         */
        static getUpdateType_(nodeDiff, edgeDiff) {
            let type = 4;

            if (nodeDiff.toBeRemoved.length || nodeDiff.toBeAdded.length) {
                type = 1;
            } else if (edgeDiff.toBeRemoved.length || edgeDiff.toBeAdded.length) {
                type = 2;
            } else { //TODO figure out whether we've got anything new at all
                type = 3;
            }

            return type;
        }

        /**
         * After getting lists of nodes and edges we need to split them into three buckets:
         * updated, added and removed and process accordingly.
         * @override
         **/
        processResponse_({ data }, requestParams) {
            const
                { activenode } = requestParams,
                { owner_: owner } = this,
                { nodes, edges } = data,
                oldNodeIds = Object.keys(owner.nodeById),
                oldEdgeIds = Object.keys(owner.edgeById),
                newNodeIds = nodes.map(owner.getNodeIdFromData.bind(owner)),
                newEdgeIds = edges.map(owner.getEdgeIdFromData.bind(owner)),
                nodeDiff = HSGraphDataSource.calcMergeDiff_(oldNodeIds, newNodeIds),
                edgeDiff = HSGraphDataSource.calcMergeDiff_(oldEdgeIds, newEdgeIds);

            nodeDiff.toBeRemoved.forEach(owner.removeNode.bind(owner));

            nodes.forEach(nodeData => {
                const id = owner.getNodeIdFromData(nodeData);

                if (id in nodeDiff.toBeUpdatedHash) {
                    owner.updateNode(nodeData);
                } else if (id in nodeDiff.toBeAddedHash) {
                    owner.appendNode(nodeData);
                }
            });

            edgeDiff.toBeRemoved.forEach(owner.removeEdge.bind(owner));

            edges.forEach(edgeData => {
                const id = owner.getEdgeIdFromData(edgeData);

                if (id in edgeDiff.toBeUpdatedHash) {
                    owner.updateEdge(edgeData);
                } else if (id in edgeDiff.toBeAddedHash) {
                    owner.appendEdge(edgeData);
                }
            });

            const updateType = HSGraphDataSource.getUpdateType_(nodeDiff, edgeDiff);

            //if clicked node is gone
            if (updateType === 1 && activenode && !(activenode in owner.nodeById)) {
                this.setActiveNode();
            }

            this.processMetricsResponse_(data);

            this.trigger('DataSourceLoad DataSourceAfterLoad', updateType);
        }

        /**
         * Filters reponse to series properties and generates an event.
         * @param {Object} response - Whole API response object.
         * @protected
         */
        processMetricsResponse_(response) {
            const { owner_: owner } = this;
            const fields = this.metricSeriesPropList_.concat('dominant_contributors');

            owner.metricsData_ = _.pick(response, fields);
            owner.trigger('metricsDataUpdate', owner.metricsData_);
        }

        /**
         * Set's the activednode property and triggers appropriate event.
         * @param nodeId
         * @public
         */
        setActiveNode(nodeId) {
            const hasChanged = this.getParams('activenode') !== nodeId;

            if (hasChanged) {
                this.setParams({ activenode: nodeId });
                this.trigger('activeNodeChanged', nodeId);
            }

            return hasChanged;
        }

        /**
         * Since this is a health score API and we provide step & limit params to it there is a
         * need to listen to Timeframe change event and update those accordingly.
         * @protected
         */
        setStepAndLimitParams_() {
            const { step, limit } = Timeframe.selected();

            this.params_['step'] = step;
            this.params_['limit'] = limit;
        }

        /**
         * Timeframe change event listener.
         * @protected
         */
        onTimeframeChange_() {
            this.setStepAndLimitParams_();
            this.setParams({ activenode: undefined });
            this.load();
        }

        /** @override */
        destroy(force) {
            const gotDestroyed = super.destroy(force);

            if (gotDestroyed) {
                Timeframe.unbind('change', this.onTimeframeChange_);
            }

            return gotDestroyed;
        }
    };
}]);
