/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'sip-profile';
const prefix = `${moduleName}.${componentName}`;

export const sipProfileHeader = `${prefix}.sipProfileHeader`;
export const sipLogDepthInputLabel = `${prefix}.sipLogDepthInputLabel`;
export const excludeSipStatusCodesFromErrorClassificationInputLabel = `${prefix}.excludeSipStatusCodesFromErrorClassificationInputLabel`;

export const ENGLISH = {
    [sipProfileHeader]: 'SIP Profile',
    [sipLogDepthInputLabel]: 'SIP log depth',
    [excludeSipStatusCodesFromErrorClassificationInputLabel]: 'Exclude SIP Status codes from Error Classification',
};
