/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './waf-psm-match-element-config.less';
import * as l10n from './waf-psm-match-element-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias waf/wafPsmMatchElementConfig
 * @private
 */
class WafPsmMatchElementConfigComponent {
    constructor(
        dropDownUtils,
        l10nService,
    ) {
        this.wafVariableOptions = dropDownUtils.getEnumDropdownOptions('WafVariable');

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Adds a match element for configuration.
     */
    addMatchElement() {
        this.rule.addMatchElement();
    }

    /**
     * Removes a match element.
     * @param {number} index - Index of the match element to remove.
     */
    removeMatchElement(index) {
        const { match_elements: matchElements } = this.rule.config;

        matchElements.remove(index);
    }
}

WafPsmMatchElementConfigComponent.$inject = [
    'dropDownUtils',
    'l10nService',
];

/**
 * @ngdoc component
 * @name wafPsmMatchElementConfig
 * @module waf/wafPsmMatchElementConfig
 * @desc Component for editing a WAF PSM location argument rule's match elements.
 * @param {WafPolicyPSMRule} rule
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/waf').component('wafPsmMatchElementConfig', {
    bindings: {
        rule: '<',
        preventEdit: '<',
    },
    controller: WafPsmMatchElementConfigComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-rule-modal/' +
        'waf-psm-match-element-config/waf-psm-match-element-config.component.html',
});
