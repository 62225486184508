/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { SSLCertificateConfigItem } from 'ajs/modules/security';
import * as l10n from './pki-certificate-authority-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for PKI Certificate Authority(CA) modal.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'pki-certificate-authority-modal',
    templateUrl: './pki-certificate-authority-modal.component.html',
})
export class PkiCertificateAuthorityModalComponent implements OnInit {
    /**
     * SSLCertificateConfigItem instance.
     */
    @Input()
    public editable: SSLCertificateConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<SSLCertificateConfigItem>();

    /**
     * SSLCertificate object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Content of the selected Certificate Authority(CA) file.
     */
    public certificateAuthorityFileContent: string;

    /**
     * Layout for PKI Certificate Authority modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Handles Certificate Authority(CA) file upload.
     */
    public onCertificateAuthorityFileUpload(): void {
        this.editable.updateCertificateAuthorityData(this.certificateAuthorityFileContent);
    }

    /**
     * Handles file selection change event.
     * Clears previous CA data if selected file is changed or removed.
     */
    public handleFileSelectChange(): void {
        this.editable.clearCertificateAuthorityData();
    }
}
