/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name parseSubnet
 * @restrict A
 * @description Parses and formats input subnet string. Subnet string gets parsed to object with
 * ip_addr and mask properties, and subnet object is formatted into subnet string.
 */
angular.module('aviApp').directive('parseSubnet', ['getSubnetObject', 'getSubnetString',
function(getSubnetObject, getSubnetString) {
    function link(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parseSubnetAddr);
        ngModel.$formatters.push(formatSubnetAddr);
    }

    /**
     * Parses subnet string into subnet object with ip_addr and mask properties.
     * @param  {string} val - subnet string.
     * @return {Object|null} subnet object {ip_addr:{addr:'1.2.3.4', type:'V4'}, mask:0}.
     */
    function parseSubnetAddr(val) {
        let output;

        if (typeof val === 'string' && val.split('/').length === 2) {
            output = getSubnetObject(val);
        } else if (val === '') {
            output = '';
        }

        return output;
    }

    /**
     * Formats subnet object into subnet string.
     * @param  {Object} val - subnet object {ip_addr:{addr:'1.2.3.4', type:'V4'}, mask:0}.
     * @return {string} subnet string.
     */
    function formatSubnetAddr(val) {
        let output = '';

        if (val && val.ip_addr && !_.isUndefined(val.mask)) {
            output = getSubnetString(val);
        }

        return output;
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);
