/**
 * Module containing WAF related components and services.
 * @preferred
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { VIPModule } from '@vmw/ngx-vip';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { MatchModule } from 'ng/modules/match/match.module';
import {
    ClrCheckboxModule,
    ClrInputModule,
    ClrRadioModule,
    ClrSpinnerModule,
    ClrTextareaModule,
} from '@clr/angular';
import { DialogModule } from 'ng/modules/dialog/dialog.module';

import {
    WafApplicationRulesListComponent,
    WafApplicationRulesListHeaderComponent,
    WafCrsExpanderOpenerComponent,
    WafCrsRuleComponent,
    WafCrsRuleGroupComponent,
    WafDataFileGrid,
    WafDataFileModalComponent,
    WafExcludeListComponent,
    WafExcludeListEntryComponent,
    WafExcludeListEntryModalComponent,
    WafLearningLinkComponent,
    WafModeComponent,
    WafPolicyAllowlistRuleComponent,
    WafPolicyAllowlistRuleExpanderContentComponent,
    WafPolicyAllowlistRuleExpanderContentService,
    WafPolicyAllowlistRuleModalComponent,
    WafPolicyAllowlistRulesListComponent,
    WafPolicyApplicationRulesComponent,
    WafPolicyConfirmChangeCrsVersionComponent,
    WafPolicyConfirmChangeCrsVersionCounterComponent,
    WafPolicyPsmGroupComponent,
    WafPolicyPsmGroupExpanderContentComponent,
    WafPolicyPsmGroupsConfigComponent,
    WafPolicyPsmGroupsListComponent,
    WafPolicyPsmLocationComponent,
    WafPolicyPsmLocationExpanderContentComponent,
    WafPolicyPsmLocationsConfigComponent,
    WafPolicyPsmLocationsListComponent,
    WafPolicyPsmRuleComponent,
    WafPolicyPsmRuleExpanderContentComponent,
    WafPolicyPsmRulesConfigComponent,
    WafPolicyPsmRulesListComponent,
    WafProfileContentTypeMappingGrid,
    WafProfileModalComponent,
    WafRuleGroupModalComponent,
    WafRuleModalComponent,
    WafUserOverridesBadgeComponent,
} from '.';
import {
    wafApplicationSignatureProviderCollectionProvider,
    wafApplicationSignatureProviderProvider,
} from '../../ajs-upgraded-providers';

import {
    wafProfileCollectionProvider,
} from './ajs-upgraded-providers';

const wafComponents = [
    WafApplicationRulesListComponent,
    WafApplicationRulesListHeaderComponent,
    WafCrsExpanderOpenerComponent,
    WafCrsRuleComponent,
    WafCrsRuleGroupComponent,
    WafExcludeListComponent,
    WafExcludeListEntryComponent,
    WafExcludeListEntryModalComponent,
    WafLearningLinkComponent,
    WafModeComponent,
    WafPolicyAllowlistRuleExpanderContentComponent,
    WafPolicyAllowlistRuleComponent,
    WafPolicyAllowlistRuleModalComponent,
    WafPolicyAllowlistRulesListComponent,
    WafPolicyApplicationRulesComponent,
    WafPolicyConfirmChangeCrsVersionComponent,
    WafPolicyConfirmChangeCrsVersionCounterComponent,
    WafPolicyPsmLocationsConfigComponent,
    WafPolicyPsmLocationsListComponent,
    WafPolicyPsmLocationComponent,
    WafPolicyPsmLocationExpanderContentComponent,
    WafPolicyPsmRulesConfigComponent,
    WafPolicyPsmRulesListComponent,
    WafPolicyPsmRuleComponent,
    WafPolicyPsmRuleExpanderContentComponent,
    WafPolicyPsmGroupsConfigComponent,
    WafPolicyPsmGroupsListComponent,
    WafPolicyPsmGroupComponent,
    WafPolicyPsmGroupExpanderContentComponent,
    WafProfileModalComponent,
    WafRuleGroupModalComponent,
    WafRuleModalComponent,
    WafUserOverridesBadgeComponent,
    WafProfileContentTypeMappingGrid,
    WafDataFileGrid,
    WafDataFileModalComponent,
];

@NgModule({
    declarations: [
        ...wafComponents,
    ],
    exports: [
        WafCrsExpanderOpenerComponent,
        WafLearningLinkComponent,
        WafPolicyApplicationRulesComponent,
        WafPolicyPsmGroupsConfigComponent,
        WafPolicyPsmLocationsConfigComponent,
        WafPolicyPsmRulesConfigComponent,
        WafPolicyAllowlistRulesListComponent,
        WafRuleGroupModalComponent,
        WafRuleModalComponent,
        WafExcludeListEntryModalComponent,
        WafUserOverridesBadgeComponent,
        WafPolicyAllowlistRuleModalComponent,
    ],
    imports: [
        ClrInputModule,
        ClrSpinnerModule,
        ClrTextareaModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        SharedModule,
        DialogModule,
        ClrCheckboxModule,
        ClrRadioModule,
        AviFormsModule,
        MatchModule,
        VIPModule,
    ],
    providers: [
        wafApplicationSignatureProviderProvider,
        wafApplicationSignatureProviderCollectionProvider,
        WafPolicyAllowlistRuleExpanderContentService,
        wafProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class WafModule { }
