/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * @ngdoc service
 * @name TenantService
 * @module services/TenantService
 * @desc Service used to manage tenant context.
 * @author alextsg
 */
@Injectable()
export class TenantService {
    private subject = new Subject<string>();

    /**
     * Returns an observable to allow subscriptions to tenant context changes. The observer will
     * receive the tenantRef on changes.
     */
    public get currentTenantRef$(): Observable<string> {
        return this.subject.asObservable();
    }

    /**
     * Called when the tenant context has changed.
     */
    public changeContext(tenantRef: string): void {
        this.subject.next(tenantRef);
    }
}
