/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function identityDataTransportFactory($q, Base) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.Base
     * @author Ashish Verma
     * @desc
     *     This is a mock of DataTransport - it returns a request object as a backend response.
     *     Needed to follow the pattern when we don't have a backend API for some kind of data.
     *
     *     For now used by Pool Servers - to get the list of them from the Pool `config` data.
     */
    class IdentityDataTransport extends Base {
        /**
         * Gets a request object and returns it back wrapped in a promise doing some basic
         * transformations (when applicable). The only exposed method.
         * @param {*=} params
         * @returns {ng.$q.promise}
         * @abstract
         */
        load(params) {
            return $q.when(
                this.processResponse_(this.getRequestObject_(params)),
            );
        }

        /**
         * Transforms request parameters object into specific for particular DataTransport format.
         * @param {*=} params
         * @returns {*}
         * @protected
         * @abstract
         */
        getRequestObject_(params) {
            return params;
        }
    }

    /**
     * Makes very basic and general transformations over the backend response before passing it
     * further.
     * @param {*} resp
     * @returns {*}
     * @protected
     * @abstract
     */
    IdentityDataTransport.prototype.processResponse_ = angular.identity;

    /**
     * Destroys the transport by cancelling all pending (if any) requests.
     * @public
     * @abstract
     */
    IdentityDataTransport.prototype.destroy = angular.noop;

    return IdentityDataTransport;
}

identityDataTransportFactory.$inject = [
    '$q',
    'Base',
];

angular.module('avi/dataModel').factory(
    'IdentityDataTransport',
    identityDataTransportFactory,
);
