/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'header';
const componentName = 'app-about-modal';
const prefix = `${moduleName}.${componentName}`;

export const aboutModalTitle = `${prefix}.aboutModalTitle`;
export const versionLabel = `${prefix}.versionLabel`;
export const buildLabel = `${prefix}.buildLabel`;
export const patchLabel = `${prefix}.patchLabel`;
export const dateLabel = `${prefix}.dateLabel`;

export const ENGLISH = {
    [aboutModalTitle]: 'About VMware NSX ALB (Avi)',
    [versionLabel]: 'Version',
    [buildLabel]: 'Build',
    [patchLabel]: 'Patch',
    [dateLabel]: 'Date',
};
