/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'file-upload';
const prefix = `${moduleName}.${componentName}`;

export const uploadBtnLabel = `${prefix}.uploadBtnLabel`;
export const chooseFileInputPlaceholder = `${prefix}.chooseFileInputPlaceholder`;
export const fileAttachedLabel = `${prefix}.fileAttachedLabel`;

export const ENGLISH = {
    [uploadBtnLabel]: 'Upload File',
    [chooseFileInputPlaceholder]: 'choose file',
    [fileAttachedLabel]: 'file attached',
};
