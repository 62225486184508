/**
 * @module avi/clarity
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    angleIcon,
    atomIcon,
    bellIcon,
    betaIcon,
    bubbleExclamationIcon,
    CdsIcon,
    certificateIcon,
    chatBubbleIcon,
    checkboxListIcon,
    checkCircleIcon,
    circleArrowIcon,
    circleIcon,
    ClarityIcons,
    cloudIcon,
    cloudScaleIcon,
    cloudTrafficIcon,
    codeIcon,
    cogIcon,
    dashboardIcon,
    displayIcon,
    downloadIcon,
    dragHandleIcon,
    ellipsisVerticalIcon,
    employeeIcon,
    envelopeIcon,
    eventIcon,
    exclamationTriangleIcon,
    fileGroupIcon,
    fileSettingsIcon,
    firewallIcon,
    helpIcon,
    helpInfoIcon,
    idBadgeIcon,
    infoCircleIcon,
    keyIcon,
    listIcon,
    lockIcon,
    mapMarkerIcon,
    minusCircleIcon,
    networkGlobeIcon,
    networkSwitchIcon,
    pencilIcon,
    plusCircleIcon,
    rackServerIcon,
    resourcePoolIcon,
    searchIcon,
    shareIcon,
    shieldCheckIcon,
    shieldIcon,
    sliderIcon,
    stepForward2Icon,
    tasksIcon,
    timesCircleIcon,
    timesIcon,
    trashIcon,
    userIcon,
    viewColumnsIcon,
    vmBugIcon,
    vmwAppIcon,
    windowCloseIcon,
} from '@cds/core/icon';

const iconList = [
    angleIcon,
    atomIcon,
    bellIcon,
    betaIcon,
    bubbleExclamationIcon,
    certificateIcon,
    chatBubbleIcon,
    checkboxListIcon,
    checkCircleIcon,
    circleArrowIcon,
    circleIcon,
    cloudIcon,
    cloudScaleIcon,
    cloudTrafficIcon,
    codeIcon,
    cogIcon,
    dashboardIcon,
    displayIcon,
    downloadIcon,
    dragHandleIcon,
    ellipsisVerticalIcon,
    employeeIcon,
    envelopeIcon,
    eventIcon,
    exclamationTriangleIcon,
    fileGroupIcon,
    fileSettingsIcon,
    firewallIcon,
    helpIcon,
    helpInfoIcon,
    idBadgeIcon,
    infoCircleIcon,
    keyIcon,
    listIcon,
    lockIcon,
    mapMarkerIcon,
    minusCircleIcon,
    networkGlobeIcon,
    networkSwitchIcon,
    pencilIcon,
    plusCircleIcon,
    rackServerIcon,
    resourcePoolIcon,
    searchIcon,
    shareIcon,
    shieldCheckIcon,
    shieldIcon,
    sliderIcon,
    stepForward2Icon,
    tasksIcon,
    timesCircleIcon,
    timesIcon,
    trashIcon,
    userIcon,
    viewColumnsIcon,
    vmBugIcon,
    vmwAppIcon,
    windowCloseIcon,
];

ClarityIcons.addIcons(...iconList);

// Some clr-angular (clr-stepper, ...) components use clr-icon internally,
// Since we no longer use clr-icon package,
// creating a backward compatible clr-icon alias.

const directions = new Set(['up', 'down', 'left', 'right']);

// Some clr-angular components will add clr-icons with extra params in the shape attribute, like
// <clr-icon shape="angle left"></clr-icon> instead of
// <clr-icon shape="angle" direction="left"></clr-icon>. This is incompatible with
// customElements.define('clr-icon', LegacyIcon) as it expects separate attributes. In order to fix
// this, we override the shape getter and setter to split up the attribuets.
class LegacyIcon extends CdsIcon {
    public get shape(): string {
        return super.shape;
    }

    public set shape(val: string) {
        const [shape, ...shapeProps] = val.split(' ');

        if (!shapeProps.length) {
            super.shape = val;
        } else {
            this.setAttribute('shape', shape);

            const direction = shapeProps.find(prop => directions.has(prop));

            if (direction) {
                this.setAttribute('direction', direction);
            }
        }
    }
}

customElements.define('clr-icon', LegacyIcon);
