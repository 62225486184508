/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ACTIVE_USER_PROFILE_SERVICE_TOKEN } from 'ajs/modules/core/services/active-user-profile';
import './tenant-selector.less';
import * as l10n from './tenant-selector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'tenant-selector';

/**
 * @class
 * @constructor
 * @memberOf module:avi/navigation
 * @see {@link  module:avi/navigation.tenantSelectorComponent}
 */
class TenantSelectorController {
    constructor(
        $scope,
        $state,
        Auth,
        dropDownUtils,
        naturalSort,
        l10nService,
        stateManagerService,
        activeUserProfileService,
    ) {
        this.$scope_ = $scope;
        this.$state_ = $state;
        this.auth_ = Auth;
        this.dropDownUtils_ = dropDownUtils;
        this.naturalSort_ = naturalSort;
        this.l10nService_ = l10nService;
        this.stateManagerService_ = stateManagerService;

        /**
         * @protected
         */
        this.activeUserProfileService_ = activeUserProfileService;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Tenant Selector dropdown options.
         * @type {DropDownOption[]}
         */
        this.tenantOptions = [];

        /**
         * Tenant WatchCollection deregister function.
         * @type {Function|null}
         * @protected
         */
        this.unregisterTenantWatch_ = null;
    }

    /** @override */
    $onInit() {
        // Sets current tenant ref as dropdown model.
        this.selectedTenantRef = this.auth_.getCurrentTenantRef();

        this.unregisterTenantWatch_ = this.$scope_.$watchCollection(() => {
            return this.activeUserProfileService_.getTenants();
        }, () => {
            this.setTenantDropdownOptions_();
        });
    }

    /**
     * Sets tenant dropdown option list.
     * @protected
     */
    setTenantDropdownOptions_() {
        const {
            naturalSort_: naturalSort,
            l10nService_: l10nService,
            activeUserProfileService_: activeUserProfileService,
            dropDownUtils_: dropDownUtils,
        } = this;

        const tenants = activeUserProfileService.getTenants();

        const tenantOptions = tenants
            .map(({ url, name }) => dropDownUtils.createOption(url, name))
            .sort(({ label: labelA }, { label: labelB }) => naturalSort(labelA, labelB));

        if (tenantOptions.length && activeUserProfileService.isSuperUser()) {
            tenantOptions
                .unshift(dropDownUtils.createOption('*',
                    l10nService.getMessage(l10nKeys.allTenantsLabel)));
        }

        this.tenantOptions = tenantOptions;
    }

    /**
     * Fired when tenant is changed.
     * Sets the selected tenant in Auth context.
     */
    onTenantChange() {
        const {
            $state_: $state,
            selectedTenantRef,
            stateManagerService_: stateManagerService,
            auth_: auth,
        } = this;

        const tenantName = selectedTenantRef.name() || selectedTenantRef;
        const {
            current: currentState,
            params: currentStateParams,
        } = $state;

        const oldTenantName = auth.getTenantName();

        if (tenantName === oldTenantName) {
            return;
        }

        // with newly selected tenant
        const updatedStateParams = Object.assign(
            {},
            currentStateParams,
            { tenantName },
        );

        auth.setTenant(tenantName)
            .then(() => {
                if (stateManagerService.isAllowedState(
                    currentState,
                    updatedStateParams,
                    oldTenantName,
                )) {
                    return $state.go(currentState, updatedStateParams);
                } else {
                    const target =
                        stateManagerService.getClosestAllowedState(
                            currentState,
                            updatedStateParams,
                            oldTenantName,
                        );

                    return $state.go(target, { tenantName });
                }
            })
            .catch(() => {
                auth.logout(true, false);
            });
    }

    /** @override */
    $onDestroy() {
        if (this.unregisterTenantWatch_) {
            this.unregisterTenantWatch_();
        }
    }
}

TenantSelectorController.$inject = [
    '$scope',
    '$state',
    'Auth',
    'dropDownUtils',
    'naturalSort',
    'l10nService',
    'stateManagerService',
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
];

/**
 * @name tenantSelectorComponent
 * @memberOf module:avi/navigation
 * @property {module:avi/navigation.TenantSelectorController} controller
 * @desc
 *      Dropdown to switch between tenants.
 *      Found in the header.
 * @author Aravindh Nagarajan
 */
angular.module('avi/navigation').component('tenantSelector', {
    controller: TenantSelectorController,
    templateUrl: `src/components/avi-header/${componentName}/${componentName}.html`,
});
