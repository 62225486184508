/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import {
    FullModalService,
    IFullModalLayout,
} from 'ng/modules/core';

import { L10nService } from '@vmw/ngx-vip';
import { MyAccount } from 'ajs/modules/core/services/my-account';
import { MyAccountModalComponent, MyAccountPreviewComponent } from '../components';
import * as l10n from './my-account-modal.service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Service to open/close the my account modal.
 *
 * @author Nisar Nadaf
 */
@Injectable()
export class MyAccountModalService {
    constructor(
        private readonly fullModalService: FullModalService,
        private readonly myAccount: MyAccount,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method to open my account modal.
     */
    public openModal(): void {
        this.fullModalService.addModal(this.getMyAccountModalProps());
    }

    /**
     * Method to close my account modal.
     */
    public closeModal(): void {
        this.fullModalService.removeModalByComponent(MyAccountModalComponent as Type<Component>);
    }

    /**
     * Method to check if my account modal is open.
     */
    public isOpen(): boolean {
        return this.fullModalService.isOpen(MyAccountModalComponent as Type<Component>);
    }

    /**
     * Method to get account name for full modal description.
     */
    public getDescription(): string {
        const { accountData } = this.myAccount;

        return accountData ? accountData.full_name : '';
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getMyAccountModalProps(): IFullModalLayout {
        return {
            component: MyAccountModalComponent as Type<Component>,
            previewComponent: MyAccountPreviewComponent as Type<Component>,
            getName: () => this.l10nService.getMessage(l10nKeys.myAccountLabel),
            getDescription: () => this.getDescription(),
        };
    }
}
