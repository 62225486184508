/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import {
    VRFContext,
    VRF_CONTEXT_COLL_DATASOURCE_TOKEN,
    VRF_CONTEXT_TOKEN,
} from '.';

/**
 * Ajs dependency token for VRFContextCollection.
 */
export const VRF_CONTEXT_COLLECTION_TOKEN = 'VRFContextCollection';

/**
 * @description
 *
 *   VRF Context Collection.
 *
 * @author Aravindh Nagarajan
 */
export class VRFContextCollection extends Collection {
    constructor(args = {}) {
        const allDataSources = {
            list: {
                source: VRF_CONTEXT_COLL_DATASOURCE_TOKEN,
                transformer: 'ListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        };

        const extendedArgs = {
            objectName: 'vrfcontext',
            windowElement: 'vrf-context-modal',
            allDataSources,
            defaultDataSources: 'list',
            restrictCreateOnEssentialLicense: true,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(VRF_CONTEXT_TOKEN);
    }

    /**
     * Returns true if any custom VRF Contexts exist, as in there exists a VRF Context that is
     * neither the default 'management' or 'global' VRF Contexts.
     */
    public hasCustomVRFContext(): boolean {
        const { items: vrfContexts } = this;

        const defaultVrfContextNames = [
            'management',
            'global',
        ];

        return vrfContexts.some((vrfContext: VRFContext) => {
            const name = vrfContext.getName();

            return !defaultVrfContextNames.includes(name);
        });
    }
}

VRFContextCollection.ajsDependencies = [
    VRF_CONTEXT_TOKEN,
];
