/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './user-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'user-list';

/**
 * @constructor
 * @memberOf module:avi/accounts
 * @see {@link module:avi/accounts.userListComponent userListComponent}
 */
class UserListController {
    constructor(
        $element,
        $q,
        $sce,
        Auth,
        aviAlertService,
        CRUDGridConfig,
        UserCollection,
        User,
        initialDataService,
        l10nService,
    ) {
        /**
         * @type {angular.$element}
         * @protected
         */
        this.$element_ = $element;

        /**
         * @type {angular.$q}
         * @protected
         */
        this.$q_ = $q;

        /**
         * @protected
         */
        this.$sce_ = $sce;

        /**
         * @type {module:avi/core.Auth}
         * @protected
         */
        this.auth_ = Auth;

        /**
         * @type {AviAlertService}
         * @protected
         */
        this.aviAlertService_ = aviAlertService;

        /**
         * @type {CRUDGridConfig}
         * @protected
         */
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
         * @type {module:avi/accounts.UserCollection}
         * @protected
         */
        this.UserCollection_ = UserCollection;

        /**
         * @type {module:avi/accounts.User}
         * @protected
         */
        this.User_ = User;

        this.l10nService_ = l10nService;

        /**
         * Time difference in seconds between client and controller clocks.
         * @type {number}
         * @protected
         */
        this.controllerTimeDifference_ = initialDataService.controllerTimeDifference;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.$element_.addClass(componentName);

        const { l10nService_: l10nService } = this;

        const gridConfigOptions = {
            collection: new this.UserCollection_(),
            permission: 'PERMISSION_USER',
            fields: [
                {
                    name: 'username',
                    title: l10nService.getMessage(l10nKeys.columnTitileUsername),
                    sortBy: 'username',
                    template: '{{row.getConfig().username}}',
                }, {
                    name: 'is_superuser',
                    title: l10nService.getMessage(l10nKeys.columnTitileSuperUser),
                    sortBy: 'is_superuser',
                    template:
                        '{{row.getConfig().is_superuser | booleanLabel: "yes"}}',
                }, {
                    name: 'status',
                    title: l10nService.getMessage(l10nKeys.columnTitileStatus),
                    sortBy: 'is_active',
                    template:
                        '{{row.getConfig().is_active | booleanLabel: "active"}}',
                }, {
                    name: 'full_name',
                    title: l10nService.getMessage(l10nKeys.columnTitileFullName),
                    sortBy: 'full_name',
                    template: '{{row.getConfig().full_name}}',
                }, {
                    name: 'email',
                    title: l10nService.getMessage(l10nKeys.columnTitileEmail),
                    sortBy: 'email',
                    template: '{{row.getConfig().email}}',
                }, {
                    name: 'tenant',
                    title: l10nService.getMessage(l10nKeys.columnTitileTenant),
                    transform: row => {
                        const { access: accessList } = row.getConfig();

                        if (Array.isArray(accessList)) {
                            const tenantAccessSummary =
                                accessList.map(this.User_.getTenantAccessSummary).join(', ');

                            return this.$sce_
                                .trustAsHtml(
                                    `<span title="${tenantAccessSummary}">
                                        ${tenantAccessSummary}
                                    </span>`,
                                );
                        }

                        return '';
                    },
                    sortBy: 'default_tenant_ref__name',
                }, {
                    name: 'last_signed_in',
                    title: l10nService.getMessage(l10nKeys.columnTitileLastSignedIn),
                    transform: row => this.getLastLoginStatus(row),
                },
            ],
        };

        const { objectName } = gridConfigOptions.collection;

        gridConfigOptions.id = `${objectName}-list-page`;

        gridConfigOptions.multipleactions = [
            {
                title: l10nService.getMessage(l10nKeys.gridMultiActionDeleteLabel),
                disabled(users) {
                    return !_.find(users, user => user.isDroppable());
                },
                do: rows => {
                    const promises = _.map(rows, row => row.drop());

                    this.$q_.all(promises)
                        .finally(() => {
                            this.gridConfig.collection.load();
                        });

                    return true;
                },
            }, {
                title: l10nService.getMessage(l10nKeys.gridMultiActionActivateLabel),
                className: 'sel-enable-user icon-activate',
                disabled(users) {
                    return !_.any(users,
                        user => user.isEditable() && !user.getConfig().is_active);
                },
                do: users => {
                    _.each(users, user => {
                        if (user.isEditable() &&
                            !user.getConfig().is_active) {
                            user.getConfig().is_active = true;

                            user.save().catch(response => {
                                this.aviAlertService_.throw(response.data);
                                user.getConfig().is_active = false;
                            });
                        }
                    });

                    return true;
                },
            }, {
                title: l10nService.getMessage(l10nKeys.gridMultiActionSuspendLabel),
                className: 'sel-disable-user icon-suspend',
                disabled(users) {
                    return !_.any(users,
                        user => user.isEditable() && user.getConfig().is_active);
                },
                do: users => {
                    _.each(users, user => {
                        if (user.isEditable() && user.getConfig().is_active) {
                            user.getConfig().is_active = false;

                            user.save().catch(response => {
                                this.aviAlertService_.throw(response.data);
                                user.getConfig().is_active = true;
                            });
                        }
                    });

                    return true;
                },
            },
        ];

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    /**
     * Returns last login status of a user in a readable format.
     * @param {module:avi/accounts.User} user
     * @returns {string}
     */
    getLastLoginStatus(user) {
        const config = user.getConfig();
        const { activity = {} } = config;
        const { l10nService_: l10nService } = this;
        const { controllerTimeDifference_: timeDifference } = this;

        if (activity.last_login_timestamp) {
            const {
                logged_in: isLoggedIn,
                last_login_timestamp: lastLoginTimestamp,
                last_login_ip: lastLoginIp,
            } = activity;

            let lastLoginStatus = l10nService.getMessage(
                isLoggedIn ? l10nKeys.onlineSincePrefix : l10nKeys.lastLoginPrefix,
            );

            lastLoginStatus += moment
                .utc(lastLoginTimestamp)
                .add(timeDifference, 'seconds')
                .fromNow();

            if (lastLoginIp) {
                lastLoginStatus += ` from ${lastLoginIp}`;
            }

            return lastLoginStatus;
        }

        return 'Never logged-in';
    }

    /** @override */
    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

UserListController.$inject = [
    '$element',
    '$q',
    '$sce',
    'Auth',
    'aviAlertService',
    'CRUDGridConfig',
    'UserCollection',
    'User',
    'initialDataService',
    'l10nService',
];

/**
 * @name userListComponent
 * @memberOf module:avi/accounts
 * @property {module:avi/accounts.UserListController} UserListController
 * @description User list page with options to create/delete/edit a policy.
 * @author Aravindh Nagarajan
 */
angular.module('avi/accounts').component('userList', {
    controller: UserListController,
    templateUrl:
        'src/components/pages/administration/accounts/user/user-list.html',
});
