/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'cloud-type-selector';
const prefix = `${moduleName}.${componentName}`;

export const header = `${prefix}.header`;
export const noOrchestratorLabel = `${prefix}.noOrchestratorLabel`;
export const orchestratorLabel = `${prefix}.orchestratorLabel`;

export const ENGLISH = {
    [header]: 'Cloud Infrastructure Type',
    [noOrchestratorLabel]: 'No Orchestrator',
    [orchestratorLabel]: 'Orchestrator',
};
