/** @module SecurityModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    copy,
    IHttpResponse,
    IPromise,
} from 'angular';

import { Component, Type } from '@angular/core';
import {
    isEmpty,
    isUndefined,
    pluck,
} from 'underscore';

import {
    AviPermissionResource,
    CertificateStatus,
    ICertificateAuthority,
    ICustomParams,
    IOCSPConfig,
    IOCSPResponseInfo,
    ISSLCertificate,
    ISSLCertificateDescription,
    ISSLKeyAndCertificate,
    SSLCertificateType,
    SSLKeyAlgorithm,
} from 'generated-types';

import {
    MessageItem,
    ObjectTypeItem,
} from 'ajs/modules/data-model/factories';

import { TWindowElement } from 'ajs/modules/data-model/mixins/with-edit.mixin';

import { IItemParams, withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';

import {
    SslCertificateCreateApplicationModalComponent,
    SslCertificateCreateRootModalComponent,
} from 'ng/modules/security';

import { TStringRow } from 'ng/shared/components';

import {
    CertificateManagement,
    CERTIFICATE_MANAGEMENT_ITEM_TOKEN,
    SSLCertificateConfigItem,
} from '..';

import * as l10n from '../../security.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCertificateManagement = typeof CertificateManagement;

type TSSLKeyAndCertificatePartial =
    Omit<ISSLKeyAndCertificate, 'certificate' | 'ocsp_response_info'>;

interface ISSLKeyAndCertificateConfig extends TSSLKeyAndCertificatePartial {
    certificate?: SSLCertificateConfigItem,
    ocsp_response_info?: MessageItem<IOCSPResponseInfo>
}

/**
 * Represent Certificate item data
 */
interface ICertificateData {
    config: ISSLKeyAndCertificateConfig;
}

/**
 * Types of Certificate creation
 */
export enum CertificateCreateType {
    SELF_SIGNED = 'self-signed',
    CA_SIGNED = 'ca-signed',
    IMPORT = 'import',
}

/**
 * Ajs dependency token for Certificate.
 */
export const CERTIFICATE_ITEM_TOKEN = 'Certificate';

/**
 * @description Certificate item
 * @author Nisar Nadaf, Zhiqian Liu
 */
export class Certificate extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        CERTIFICATE_MANAGEMENT_ITEM_TOKEN,
        'l10nService',
    ];

    /**
     * Certificate.data
     */
    public data: ICertificateData;

    /**
     * Object wrapper list of subject_alt_names strings.
     */
    private subjectAltNameWrappers: TStringRow[];

    /**
     * Object wrapper list of OCSP responderUrlLists strings.
     */
    private ocspResponderUrlListWrappers: TStringRow[];

    /**
     * Certificate management Item class
     */
    private readonly CertificateManagement: TCertificateManagement;

    /**
     * l10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'sslkeyandcertificate',
            objectType: 'SSLKeyAndCertificate',
            windowElement: SslCertificateCreateApplicationModalComponent,
            permissionName: AviPermissionResource.PERMISSION_SSLKEYANDCERTIFICATE,
            restrictEditOnEssentialLicense: false,
            params: {
                include_internal: true,
                include_name: true,
            },
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);

        this.CertificateManagement = this.getAjsDependency_(CERTIFICATE_MANAGEMENT_ITEM_TOKEN);
    }

    /**
     * Returns true if the type of the Certificate is CSR.
     */
    public isCertificateSigningRequest(): boolean {
        const { certificate } = this.getConfig();

        return !isUndefined(certificate.config.certificate_signing_request);
    }

    /**
     * Called when user selects between Self-signed, CSR, and Import types. Sets properties
     * on data.config.
     */
    public changeCertCreateType(type: CertificateCreateType): void {
        const config = this.getConfig();
        const { certificate } = config;

        certificate.config.self_signed = type === CertificateCreateType.SELF_SIGNED;

        if (type === CertificateCreateType.IMPORT) {
            certificate.config.subject.updateConfig();
            delete certificate.config.self_signed;
        } else {
            delete certificate.config.certificate;
            delete config.key;

            if (type === CertificateCreateType.CA_SIGNED) {
                delete certificate.config.days_until_expire;
            }
        }
    }

    /**
     * Returns certificate create type.
     */
    public get certificateCreateType(): CertificateCreateType {
        const { url } = this.getConfig();

        if (isUndefined(url)) {
            return CertificateCreateType.SELF_SIGNED;
        } else {
            return this.isCertificateSigningRequest() &&
                CertificateCreateType.CA_SIGNED || CertificateCreateType.IMPORT;
        }
    }

    /**
     * Returns certificate object, if it exists.
     */
    public get certificate(): SSLCertificateConfigItem | null {
        const { certificate } = this.getConfig();

        return certificate || null;
    }

    /**
     * Returns subject object from certificate, if it exists.
     */
    public get subject(): MessageItem<ISSLCertificateDescription> | null {
        const { certificate } = this.getConfig();
        const { subject } = certificate || {};

        return subject || null;
    }

    /**
     * Getter for subject_alt_names wrapper objects.
     */
    public get subjectAltNames(): TStringRow[] {
        return this.subjectAltNameWrappers || [];
    }

    /**
     * Getter for OCSP responder_url_lists wrapper objects.
     */
    public get ocspResponderUrlLists(): TStringRow[] {
        return this.ocspResponderUrlListWrappers || [];
    }

    /**
     * Clears certificate information.
     */
    public clearCertificate(): void {
        const { certificate } = this.getConfig();

        delete certificate.config.certificate;
    }

    /**
     * Function to add new SAN.
     */
    public addSubjectAltName(): void {
        this.subjectAltNameWrappers.push({
            value: '',
        });
    }

    /**
     * Function to delete Subject Alt Names.
     */
    public deleteSubjectAltNames(namesToDelete: TStringRow[]): void {
        namesToDelete.forEach(nameObject => {
            const index = this.subjectAltNameWrappers.indexOf(nameObject);

            this.subjectAltNameWrappers.splice(index, 1);
        });
    }

    /**
     * Function to add new OCSP responderUrlList.
     */
    public addOcspResponderUrlList(): void {
        this.ocspResponderUrlListWrappers.push({
            value: '',
        });
    }

    /**
     * Function to delete OCSP responderUrlLists.
     */
    public deleteOcspResponderUrlLists(urlListsToDelete: TStringRow[]): void {
        urlListsToDelete.forEach(urlListObject => {
            const index = this.ocspResponderUrlListWrappers.indexOf(urlListObject);

            this.ocspResponderUrlListWrappers.splice(index, 1);
        });
    }

    /**
     * Get missing cert issuer name. If no cert is missing return empty string.
     */
    public getMissingCertIssuerName(): string {
        const { ca_certs: caCerts } = this.getConfig();
        let certName = '';

        if (caCerts && !this.isSelfSigned) {
            const cert = caCerts.config.find(
                (caCert: MessageItem<ICertificateAuthority>) => !caCert.config.ca_ref,
            );

            certName = cert ? cert.config.name : '';
        }

        return certName;
    }

    /**
     * Returns ocsp_config object, if it exists.
     */
    public get ocspConfig(): MessageItem<IOCSPConfig> | null {
        const {
            ocsp_config: ocspConfig,
            enable_ocsp_stapling: enableOcspStapling,
        } = this.getConfig();

        if (enableOcspStapling && !ocspConfig) {
            throw new Error('config.ocsp_config does not exist');
        }

        return ocspConfig || null;
    }

    /**
     * Returns expiry_status property for the certificate.
     */
    public get expiryStatus() : string | undefined {
        const { certificate } = this.getConfig();

        return certificate?.config.expiry_status;
    }

    /**
     * Returns if certificate is self signed
     */
    public get isSelfSigned() : boolean {
        const { certificate } = this.getConfig();

        return Boolean(certificate?.config.self_signed);
    }

    /**
     * Returns ocsp_error_status property for the certificate.
     */
    public get ocspErrorStatus(): string | undefined {
        const { ocsp_error_status: ocspErrorStatus } = this.getConfig();

        return ocspErrorStatus;
    }

    /**
     * Returns enable_ocsp_stapling property for the certificate.
     */
    public get enableOcspStapling(): boolean | undefined {
        const { enable_ocsp_stapling: enableOcspStapling } = this.getConfig();

        return enableOcspStapling;
    }

    /**
     * Sends certificate validate request
     */
    public validateCertificate(): IPromise<void> {
        const config = this.getConfig();
        const {
            certificate,
            certificate_base64: certificateBase64,
            key,
            key_base64: keyBase64,
            key_passphrase: keyPassphrase,
        } = config;

        this.busy = true;
        this.errors = null;

        const url = '/api/sslkeyandcertificate/validate';
        const payload = {
            certificate: certificate?.config.certificate,
            certificate_base64: certificateBase64,
            key,
            key_base64: keyBase64,
            key_passphrase: keyPassphrase,
        };

        return this.request(
            'POST',
            url,
            payload,
        ).then(({ data } : IHttpResponse<ISSLKeyAndCertificate>) => {
            if (data.certificate) {
                this.setValidatedCertificate(data.certificate);
            }

            if (config.type === SSLCertificateType.SSL_CERTIFICATE_TYPE_CA) {
                this.setValidatedKey(data.certificate);
            } else {
                this.setValidatedKey(data);
            }
        })
            .catch(({ data }: IHttpResponse<Error>) => {
                this.errors = data;

                return Promise.reject();
            })
            .finally(() => this.busy = false);
    }

    /**
     * Getter for ocsp response info
     */
    public get ocspResponseInfo(): MessageItem<IOCSPResponseInfo> | null {
        const { ocsp_response_info: ocspResponseInfo } = this.getConfig();

        return ocspResponseInfo || null;
    }

    /**
     * To check if Ocsp is applicable to a certficate.
     */
    public get isOcspApplicable(): boolean {
        const { ocsp_config: ocspConfig } = this.getConfig();

        return Boolean(ocspConfig);
    }

    /**
     * To check if certificate OCSP status is revoked.
     */
    public get isRevoked(): boolean {
        if (!this.isOcspApplicable) {
            throw new Error('OCSP not applicable for this certificate');
        }

        return this.ocspResponseInfo?.config
            .cert_status === CertificateStatus.OCSP_CERTSTATUS_REVOKED;
    }

    /**
     * If a Certificate Management Profile is selected, load it to check for dynamic_params.
     */
    public loadCertificateManagementProfile(): void {
        const config = this.getConfig();

        config.dynamic_params.removeAll();

        if (!config.certificate_management_profile_ref) {
            return;
        }

        const certManagement = new this.CertificateManagement({
            id: this.stringService.slug(config.certificate_management_profile_ref),
        });

        this.busy = true;

        certManagement.load()
            .then(() => {
                const { script_params: scriptParams } = certManagement.getConfig();

                if (scriptParams.isEmpty()) {
                    return;
                }

                const filteredParams =
                    scriptParams.config.filter(
                        (param: MessageItem<ICustomParams>) => param.config.is_dynamic,
                    );

                config.dynamic_params.config.push(...filteredParams);
            })
            .finally(() => {
                this.busy = false;
                certManagement.destroy();
            });
    }

    /**
     * @override
     */
    public dataToSave(): ISSLKeyAndCertificate {
        const config = super.dataToSave();

        if (config.certificate) {
            config.key_params = copy(config.certificate.key_params);
            delete config.certificate.key_params;

            const { subjectAltNameWrappers } = this;

            // subjectAltNameWrappers has been initiated with original non-empty subject_alt_names
            // and then has been modified to empty list or initiated as an empty list in beforeEdit
            if (isEmpty(this.subjectAltNameWrappers)) {
                delete config.certificate.subject_alt_names;
            } else {
                config.certificate.subject_alt_names = pluck(subjectAltNameWrappers, 'value');
            }
        }

        const { ocsp_config: ocspConfig } = config;

        if (ocspConfig) {
            const { ocspResponderUrlListWrappers } = this;

            // ocspResponderUrlListWrappers has been initiated with original non-empty OCSP
            // responder_url_lists and then has been modified to empty list or initiated as an empty
            // list in beforeEdit
            if (isEmpty(this.ocspResponderUrlListWrappers)) {
                delete ocspConfig.responder_url_lists;
            } else {
                ocspConfig.responder_url_lists = ocspResponderUrlListWrappers.map(
                    ({ value }) => value,
                );
            }
        }

        if (!config.ocsp_responder_url_list_from_certs?.length) {
            delete config.ocsp_responder_url_list_from_certs;
        }

        const { key_params: keyParams } = config;

        if (keyParams) {
            const { algorithm } = keyParams;

            switch (algorithm) {
                case SSLKeyAlgorithm.SSL_KEY_ALGORITHM_RSA:
                    delete keyParams.ec_params;
                    break;
                case SSLKeyAlgorithm.SSL_KEY_ALGORITHM_EC:
                    delete keyParams.rsa_params;
                    break;
            }
        }

        return config;
    }

    /**
     * @override
     */
    public isEditable(): boolean {
        const config = this.getConfig();

        return (config.type === SSLCertificateType.SSL_CERTIFICATE_TYPE_CA ||
            this.isSelfSigned === false) && super.isEditable();
    }

    /**
     * @override
     * Based on the certificate type, Open CA/Root certificate modal or
     * Application/controller modal.
     */
    public openModal(windowElement: TWindowElement, params: IItemParams): Promise<void> {
        const { type } = this.getConfig();

        if (type === SSLCertificateType.SSL_CERTIFICATE_TYPE_CA) {
            return Promise.resolve(
                super.openModal(
                    SslCertificateCreateRootModalComponent as Type<Component>, params,
                ),
            );
        }

        return Promise.resolve(
            super.openModal(
                SslCertificateCreateApplicationModalComponent as Type<Component>, params,
            ),
        );
    }

    /**
     * Initialize an OCSP Config Message Item if none is found.
     */
    public setOcspConfig(): void {
        this.safeSetNewChildByField('ocsp_config');
        this.ocspResponderUrlListWrappers = [];
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.sslTlsCertificateModalBreadcrumbTitle);
    }

    /**
     * Sets config.certificate.certificate, config.key, and config.key_passphrase to
     * undefined, but leaves config.certificate.subject as is. This allows showing the user
     * the previous certificate and lets him import a new certificate and key.
     * If config.certificate is set to undefined, the previous certificate data is lost.
     * If nothing is set to undefined, then the user could possibly replace the old key with
     * a new key without replacing the old certificate, causing an error.
     * @override
     */
    protected beforeEdit(): void {
        const config = this.getConfig();

        const { certificate } = config;

        if (!isUndefined(certificate)) {
            if (!certificate.config.certificate) {
                config.certificate_base64 = true;
                config.key_base64 = true;
            }

            config.certificate.config.key_params = copy(config.key_params);

            if (!('certificate_signing_request' in certificate)) {
                delete certificate.config.not_after;
            }

            const { subject_alt_names: subjectAltNames } = this.certificate.config;

            if (subjectAltNames) {
                this.subjectAltNameWrappers = subjectAltNames.map(name => {
                    return {
                        value: name,
                    };
                });
            } else {
                this.subjectAltNameWrappers = [];
            }
        }

        const { ocspConfig } = this;

        if (ocspConfig) {
            const { responder_url_lists: resUrlLists } = ocspConfig.config;

            if (resUrlLists) {
                this.ocspResponderUrlListWrappers = resUrlLists.map(urlList => {
                    return {
                        value: urlList,
                    };
                });
            } else {
                this.ocspResponderUrlListWrappers = [];
            }
        }

        if (!config.ocsp_responder_url_list_from_certs) {
            config.ocsp_responder_url_list_from_certs = [];
        }
    }

    /**
     * Called after validating a certificate and replaces previous key values with validated
     * values.
     */
    private setValidatedKey(data: ISSLKeyAndCertificate | ISSLCertificate): void {
        const config = this.getConfig();

        config.key_base64 = false;

        if ('key' in data) {
            config.key = data.key;
        }

        config.key_params.updateConfig(data.key_params);

        // Certificate.key_params are used to display imported imformation data.
        if (!config.certificate.config.key_params) {
            config.certificate.config.key_params = config.key_params;
        }
    }

    /**
     * Called after validating a certificate and replaces previous certificate values with
     * validated values.
     */
    private setValidatedCertificate(certificate: ISSLCertificate): void {
        const config = this.getConfig();
        const {
            certificate: cert,
            subject,
            key_params: keyParams,
            not_after: notAfter,
        } = certificate;

        config.certificate_base64 = false;

        config.certificate.updateConfig({
            certificate: cert,
            subject,
            key_params: keyParams,
            not_after: notAfter,
        });
    }
}
