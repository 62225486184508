/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './SSLProfileListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('SSLProfileListController', [
    '$scope',
    'SSLProfileCollection',
    '$templateCache',
    'CRUDGridConfig',
    'l10nService',
function(
    $scope,
    SSLProfileCollection,
    $templateCache,
    CRUDGridConfig,
    l10nService,
) {
    l10nService.registerSourceBundles(dictionary);

    const gridConfig = {
        collection: new SSLProfileCollection(),
    };

    const { objectName } = gridConfig.collection;

    gridConfig.id = `${objectName}-list-page`;

    gridConfig.fields = [
        {
            name: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            template: '<span class="sel-name">{{ row.getName() }}</span>',
            sortBy: 'name',
        }, {
            name: 'vers',
            title: l10nService.getMessage(l10nKeys.columnTitleAcceptedVersions),
            template: '<span class="sel-versions">{{ row.getAcceptedVersionsString() }}</span>',
        },
    ];

    gridConfig.createActions = [{
        label: l10nService.getMessage(l10nKeys.applicationProfileButtonLabel),
        onClick() {
            gridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_PROFILE_TYPE_APPLICATION',
            });

            gridConfig.collection.create();
        },
    }, {
        label: l10nService.getMessage(l10nKeys.systemProfileButtonLabel),
        onClick() {
            gridConfig.collection.setDefaultItemConfigProps({
                type: 'SSL_PROFILE_TYPE_SYSTEM',
            });

            gridConfig.collection.create();
        },
    }];

    gridConfig.layout = {
        hideEditColumns: true,
    };

    gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.editActionTitle),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    $scope.gridConfig = CRUDGridConfig(gridConfig);
}]);
