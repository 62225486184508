/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'dns-info-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleApplicationDomain = `${prefix}.columnTitleApplicationDomain`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const removeActionLabel = `${prefix}.removeActionLabel`;
export const editActionLabel = `${prefix}.editActionLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const dnsHeader = `${prefix}.dnsHeader`;

export const ENGLISH = {
    [columnTitleApplicationDomain]: 'Application Domain',
    [columnTitleType]: 'Type',
    [removeActionLabel]: 'Remove',
    [editActionLabel]: 'Edit',
    [addButtonLabel]: 'Add',
    [dnsHeader]: 'DNS ({0})',
};
