/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'custom-tags';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const customTagsLabel = `${prefix}.customTagsLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleKey = `${prefix}.columnTitleKey`;
export const columnKeyPlaceholder = `${prefix}.columnKeyPlaceholder`;
export const columnTitleValue = `${prefix}.columnTitleValue`;
export const columnValuePlaceholder = `${prefix}.columnValuePlaceholder`;
export const removeActionLabel = `${prefix}.removeActionLabel`;

export const ENGLISH = {
    [sectionTitle]: 'Tags',
    [customTagsLabel]: 'Key & Value(s) ({0})',
    [addButtonLabel]: 'Add',
    [columnTitleKey]: 'Key',
    [columnKeyPlaceholder]: 'Enter Key',
    [columnTitleValue]: 'Value',
    [columnValuePlaceholder]: 'Enter Value',
    [removeActionLabel]: 'Remove',
};
