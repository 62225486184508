/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/scatter-plot-card.less';

import * as l10n from './ScatterPlotCard.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name scatterPlotCard
 */
angular.module('aviApp').directive('scatterPlotCard', function() {
    class ScatterPlotCardDirective {
        constructor(
            l10nService,
        ) {
            this.l10nKeys = l10nKeys;
            l10nService.registerSourceBundles(dictionary);
        }
    }

    ScatterPlotCardDirective.$inject = [
        'l10nService',
    ];

    return {
        restrict: 'E',
        template:
            `<h3
                ng-bind-html="$ctrl.l10nKeys.serverPlural | vtranslate :
                true : item.getConfig().servers.length"
             >
            </h3>
            <div scatter-plot small="true" item="item"
                metrics="item.async.collMetricsHash.pools_servers_hs_scatter"></div>`,
        controller: ScatterPlotCardDirective,
        controllerAs: '$ctrl',
    };
});
