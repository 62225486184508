/**
 * @module WebhookModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { WebhookModalComponent } from '../../../angular/modules/webhook/components';
import { withFullModalMixin } from '../../utilities/mixins';

interface IWebhookConfig {
    name?: string;
    callback_url?: string;
    verification_token?: string;
    description?: string;
}

interface IWebhookData {
    config: IWebhookConfig;
}

export class Webhook extends withFullModalMixin(ObjectTypeItem) {
    public data: IWebhookData;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'webhook',
            objectType: 'Webhook',
            permissionName: 'PERMISSION_POOL',
            windowElement: WebhookModalComponent,
        };

        super(extendedArgs);
    }
}
