/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import { Component, Input } from '@angular/core';

/**
 * @description Component to display an error message in the config modal.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config-errors',
    templateUrl: './full-modal-config-errors.component.html',
})
export class FullModalConfigErrorsComponent {
    /**
     * Error message to be displayed.
     */
    @Input() public errorMessage = '';
}
