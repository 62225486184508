/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { SSO_POLICY_COLLECTION_TOKEN } from 'ajs/modules/sso-policy/sso-policy.tokens';
import * as l10n from './saml-policy-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.samlPolicySettingsBindings
 * @see {@link module:avi/vs.samlPolicySettingsComponent samlPolicySettingsComponent}
 */
class SamlPolicySettingsController {
    constructor(
        CertificateCollection,
        SSOPolicyCollection,
        l10nService,
    ) {
        /**
         * @type {module:avi/app.CertificateCollection}
         */
        this.certificateCollection = new CertificateCollection({
            params: {
                type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
            },
        });

        /**
         * @type {SSOPolicyCollection}
         */
        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: 'SSO_TYPE_SAML',
            },
            defaults: {
                type: 'SSO_TYPE_SAML',
            },
        });

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onDestroy() {
        [
            this.certificateCollection,
            this.ssoPolicyCollection,
        ].forEach(collection => collection.destroy());
    }
}

SamlPolicySettingsController.$inject = [
    'CertificateCollection',
    SSO_POLICY_COLLECTION_TOKEN,
    'l10nService',
];

/**
 * @name samlPolicySettingsComponent
 * @memberOf module:avi/vs
 * @description
 *      VS Access Policy -> SAML Settings.
 *      Only applicable for HTTP app profile type.
 * @param {module:avi/vs.samlPolicySettingsBindings} bindings
 * @param {module:avi/vs.SamlPolicySettingsController} controller
 * @author Aravindh Nagarajan, Alex Malitsky
 */
angular.module('avi/vs').component('samlPolicySettings', {
    /**
     * @mixin samlPolicySettingsBindings
     * @memberOf module:avi/vs
     * @property {Object} samlSpConfig VirtualService#data#config#saml_sp_config
     * @property {string} ssoPolicyRef VirtualService#data#config#sso_policy_ref
     */
    bindings: {
        samlSpConfig: '=',
        ssoPolicyRef: '=',
    },
    controller: SamlPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'saml-policy-settings/saml-policy-settings.component.html',
});
