/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'policy-grid-menu-tooltip-controller';
const prefix = `${moduleName}.${componentName}`;

export const editLabel = `${prefix}.editLabel`;
export const moveToLabel = `${prefix}.moveToLabel`;
export const duplicateRuleLabel = `${prefix}.duplicateRuleLabel`;

export const ENGLISH = {
    [editLabel]: 'Edit',
    [moveToLabel]: 'Move to',
    [duplicateRuleLabel]: 'Duplicate rule',
};
