/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description Component that is used to group fields together in a modal.
 * @author alextsg
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import './avi-fieldset.component.less';

@Component({
    selector: 'avi-fieldset',
    templateUrl: './avi-fieldset.component.html',
})
export class AviFieldsetComponent implements OnInit {
    /**
     * True to hide the content.
     */
    @Input()
    public hideContent = false;

    /**
     * Emitted when the remove icon is clicked.
     */
    @Output()
    public onRemove = new EventEmitter<void>();

    /**
     * If true, shows the remove icon.
     */
    public showRemoveIcon = false;

    /** @override */
    public ngOnInit(): void {
        this.showRemoveIcon = this.onRemove.observers.length > 0;
    }

    /**
     * Called when the remove icon is clicked.
     */
    public handleRemove(): void {
        this.onRemove.emit();
    }
}
