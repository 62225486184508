/**
 * @module PersistenceProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { MessageItem, ObjectTypeItem } from 'ajs/modules/data-model/factories';
import { PersistenceProfileModalComponent } from 'ng/modules/persistence-profile';

import {
    AviPermissionResource,
    IAppCookiePersistenceProfile,
    IApplicationPersistenceProfile,
    IHdrPersistenceProfile,
    IHttpCookiePersistenceProfile,
    IIPPersistenceProfile,
    PersistenceProfileType,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { PersistenceProfileTypeMap } from '../constants';
import * as l10n from './persistence-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TPKIProfilePartial = Omit<IApplicationPersistenceProfile,
'app_cookie_persistence_profile' | 'hdr_persistence_profile' | 'ip_persistence_profile'
| 'http_cookie_persistence_profile'>;

interface IApplicationPersistenceProfileConfig extends TPKIProfilePartial {
    app_cookie_persistence_profile: MessageItem<IAppCookiePersistenceProfile>;
    hdr_persistence_profile: MessageItem<IHdrPersistenceProfile>;
    ip_persistence_profile: MessageItem<IIPPersistenceProfile>;
    http_cookie_persistence_profile: MessageItem<IHttpCookiePersistenceProfile>;
}

interface IPersistenceProfileData {
    config: IApplicationPersistenceProfileConfig;
}

/**
 * Ajs dependency token for Persistence profile objectTypeItem.
 */
export const PERSISTENCE_PROFILE_ITEM_TOKEN = 'PersistenceProfile';

/**
 * @description Persistence profile objectTypeItem.
 * @author Rohit Gaikwad
 */
export class PersistenceProfile extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Types of Persisitence profiles.
     */
    public static typeToConfigPropNameHash = PersistenceProfileTypeMap;

    public data: IPersistenceProfileData;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'applicationpersistenceprofile',
            windowElement: PersistenceProfileModalComponent,
            objectType: 'ApplicationPersistenceProfile',
            permissionName: AviPermissionResource.PERMISSION_APPLICATIONPERSISTENCEPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns profile type as enum value.
     */
    public getType(): PersistenceProfileType {
        return this.getConfig().persistence_type;
    }

    /** @override */
    public dataToSave(): IApplicationPersistenceProfile {
        const config = super.dataToSave();
        const actualType = this.getType();
        const actualPropName = PersistenceProfile.typeToConfigPropNameHash.get(actualType);

        PersistenceProfile.typeToConfigPropNameHash
            .forEach((propName: string) => {
                if (propName !== actualPropName) {
                    delete config[propName];
                }
            });

        this.setIsFederatedFlag();

        return config;
    }

    /**
     * Event handler for type change event.
     */
    public onTypeChange(): void {
        const persistenceProfileType = this.getType();
        const config = this.getConfig();

        PersistenceProfile.typeToConfigPropNameHash
            .forEach((propName: string) => {
                if (
                    propName !==
                        PersistenceProfile.typeToConfigPropNameHash.get(persistenceProfileType)
                ) {
                    delete config[propName];
                }
            });

        switch (persistenceProfileType) {
            case PersistenceProfileType.PERSISTENCE_TYPE_APP_COOKIE:
                this.safeSetNewChildByField('app_cookie_persistence_profile');
                break;

            case PersistenceProfileType.PERSISTENCE_TYPE_CLIENT_IP_ADDRESS:
                this.safeSetNewChildByField('ip_persistence_profile');
                break;

            case PersistenceProfileType.PERSISTENCE_TYPE_CUSTOM_HTTP_HEADER:
                this.safeSetNewChildByField('hdr_persistence_profile');
                break;

            case PersistenceProfileType.PERSISTENCE_TYPE_HTTP_COOKIE:
            case PersistenceProfileType.PERSISTENCE_TYPE_GSLB_SITE:
                this.safeSetNewChildByField('http_cookie_persistence_profile');
                break;
        }

        this.setIsFederatedFlag();
    }

    /**
     * Returns the IP Persistence Profile object.
     */
    public get ipPersistenceProfile(): MessageItem<IIPPersistenceProfile> {
        const { config } = this;

        return config.ip_persistence_profile;
    }

    /**
     * Returns the Hdr Persistence Profile object.
     */
    public get hdrPersistenceProfile(): MessageItem<IHdrPersistenceProfile> {
        const { config } = this;

        return config.hdr_persistence_profile;
    }

    /**
     * Returns the Http Cookie Persistence Profile object.
     */
    public get httpCookiePersistenceProfile(): MessageItem<IHttpCookiePersistenceProfile> {
        const { config } = this;

        return config.http_cookie_persistence_profile;
    }

    /**
     * Returns the App Cookie Persistence Profile object.
     */
    public get appCookiePersistenceProfile(): MessageItem<IAppCookiePersistenceProfile> {
        const { config } = this;

        return config.app_cookie_persistence_profile;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.persistenceProfileBreadcrumbTitle);
    }

    /**
     * Resets 'is_federated' field to false for all types but GSLB.
     */
    private setIsFederatedFlag(): void {
        this.getConfig().is_federated =
            this.getType() === PersistenceProfileType.PERSISTENCE_TYPE_GSLB_SITE;
    }
}
