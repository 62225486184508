/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { VipConfigItem } from 'ajs/modules/vs';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './openstack-vip-config.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description VIP Config Component for OpenStack CloudType.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'openstack-vip-config',
    templateUrl: './openstack-vip-config.component.html',
})
export class OpenStackVipConfigComponent {
    /**
     * VipConfigItem Instance.
     */
    @Input()
    public editable: VipConfigItem;

    /**
     * UUID of the cloud on which this VIP is being configured.
     */
    @Input()
    public cloudId: string;

    /**
     * UUID of the VRFContext on which this VIP is being configured.
     */
    @Input()
    public vrfContextId: string;

    /**
     * VsVip#ipam_selector label string.
     * Set by backend, UI configuration is not available yet.
     */
    @Input()
    public ipamSelectorLabels: string;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
