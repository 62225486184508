/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/components/dos-rate-limiter.less';
import * as l10n from './dosRateLimiter.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name dosRateLimiter
 * @restrict E
 * @param {ApplicationProfile} applicationProfile - ApplicationProfile item.
 * @description Form Directive for the Profile Modal. Sets the DoS Rate Limiters and their
 * properties. So we are watching type property here to filter our
 * dictionaries in appropriate way. Each limiter type can be selected only once, so we keep
 * tracking present in object config types too.
 */
angular.module('aviApp').directive('dosRateLimiter', [
'dosRateLimiterService',
'dropDownUtils',
'l10nService',
function(
    dosRateLimiterService,
    dropDownUtils,
    l10nService,
) {
    l10nService.registerSourceBundles(dictionary);

    /**
     * Creates blank RateProfile object.
     * @returns {Object}
     */
    const createRateProfile = () => {
        return {
            action: {
                type: 'RL_ACTION_NONE',
            },
            rate_limiter: {
                count: 1000000000,
                period: 1,
            },
        };
    };

    const link = function(scope) {
        scope.l10nKeys = l10nKeys;

        /**
         * @type {dosRateLimiterService}
         */
        scope.limiters = dosRateLimiterService;

        /**
         * Add limiter dropdown's options. Will be filtered out by selected ones.
         * @type {Array.<LimiterType>}
         */
        scope.limiterTypes = [];//filtered by profile type (layer)

        /**
         * Action dropdown's options. Will be filtered out by selected ones.
         * @type {Array.<LimiterActionType>}
         */
        scope.limiterActions = [];//filtered by profile type (layer)

        /**
         * Interactive array of the selected limiter types. ngRepeated.
         * @type {Array.<string>}
         */
        scope.limiterKeys = [];//array of activated limiter keys

        /**
         * ngClick handler for the Dropdown with limiter types.
         * @param {Object} value - Selected limiter dropdown action.
         */
        scope.addLimiter = function({ value }) {
            const config = scope.applicationProfile.getConfig();

            if (value === 'http_header_rate_limits') {
                const { rl_profile: rlProf } = config.dos_rl_profile;

                if (!angular.isArray(rlProf.http_header_rate_limits)) {
                    rlProf.http_header_rate_limits = [];
                }

                const prof = createRateProfile();

                prof._http_rl_field = 'http_cookie';
                rlProf.http_header_rate_limits.push(prof);
            } else {
                config.dos_rl_profile.rl_profile[value] = createRateProfile();
                scope.limiterKeys.push(value);
            }
        };

        /**
         * ngClick handler for the remove button at the limiter settings row.
         * @param {number} index - Index of limiter to be removed inside the $scope.limiterKeys.
         * @param {string} limiter - Type or the range limiter to be removed.
         */
        scope.removeLimiter = function(index, limiter) {
            delete scope.applicationProfile.getConfig().dos_rl_profile.rl_profile[limiter];
            scope.limiterKeys.splice(index, 1);
        };

        /**
         * Removes item from http_header_rate_limits array.
         * @param {number=} index
         */
        scope.removeHttpHeaderLimiter = function(index = 0) {
            const key = 'http_header_rate_limits';
            const config = scope.applicationProfile.getConfig();

            config.dos_rl_profile.rl_profile[key].splice(index, 1);
        };

        /**
         * Returns rate-limiter dropdown actions based on rate profile name.
         * @param {string} profName
         * @returns {Object[]}
         */
        scope.getActions = function(profName) {
            return profName === 'client_ip_connections_rate_limit' ?
                dosRateLimiterService.l4Actions : scope.limiterActions;
        };

        scope.$watch('applicationProfile.getType()', (newValue, oldValue) => {
            let intType;
            const config = scope.applicationProfile.getConfig();

            if (newValue !== oldValue) {
                if (!config.dos_rl_profile) {
                    config.dos_rl_profile = {};
                }

                config.dos_rl_profile.rl_profile = {
                    client_ip_connections_rate_limit: createRateProfile(),
                };
            }

            scope.limiterKeys.length = 0;

            switch (newValue) {
                case 'APPLICATION_PROFILE_TYPE_HTTP':
                    intType = 10;
                    break;
                case 'APPLICATION_PROFILE_TYPE_L4':
                case 'APPLICATION_PROFILE_TYPE_SSL':
                case 'APPLICATION_PROFILE_TYPE_DNS':
                    intType = 1;
            }

            if (intType) {
                scope.limiterTypes = _.filter(dosRateLimiterService.types, ({ type }) => {
                    return type === intType || type === 11;
                });
                scope.limiterActions = _.filter(dosRateLimiterService.actions, ({ type }) => {
                    return type === intType || type === 11;
                });

                scope.limiterTypes.forEach(({ id }) => {
                    if (id !== 'http_header_rate_limits') {
                        if (config) {
                            const { dos_rl_profile: dosProf } = config;

                            if (dosProf) {
                                const { rl_profile: rlProf } = dosProf;

                                if (rlProf[id]) {
                                    scope.limiterKeys.push(id);
                                }
                            }
                        }
                    }
                });
            } else {
                scope.limiterTypes.length = 0;
                scope.limiterActions.length = 0;
            }
        });
    };

    return {
        restrict: 'E',
        scope: {
            applicationProfile: '<',
        },
        link,
        templateUrl: 'src/views/profile/dos-rate-limiter.html',
    };
}]);
