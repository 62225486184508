/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gslb-non-avi-modal';
const prefix = `${moduleName}.${componentName}`;

export const saveBtnLabel = `${prefix}.saveBtnLabel`;
export const advancedSettingsHeader = `${prefix}.advancedSettingsHeader`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const newGslb3rdPartySiteHeader = `${prefix}.newGslb3rdPartySiteHeader`;
export const EditGslb3rdPartySiteHeader = `${prefix}.EditGslb3rdPartySiteHeader`;

export const ENGLISH = {
    [saveBtnLabel]: 'Save',
    [advancedSettingsHeader]: 'Advanced Settings',
    [enabledLabel]: 'Enabled',
    [nameInputLabel]: 'Name',
    [newGslb3rdPartySiteHeader]: 'New GSLB Third-party Site: {0}',
    [EditGslb3rdPartySiteHeader]: 'Edit GSLB Third-party Site: {0}',
};
