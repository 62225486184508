/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('ServerHealthScoreMonitorListCollDataSource', [
'ListCollDataSource',
function(ListCollDataSource) {
    class ServerHealthScoreMonitorListCollDataSource extends ListCollDataSource {
        /** @override */
        getRequestParams_(params) {
            const { owner_: owner } = this;

            // We want to get all_se or primary_only from params.
            // primary_only is used when corresponding VS.runtime.one_plus_one_ha is true or
            // serviceenginegroup?referred_by=virtualservice:vsId&
            // ha_mode=HA_MODE_LEGACY_ACTIVE_STANDBY
            // returns at least one result. Otherwise use default all_se.
            return angular.extend(
                super.getRequestParams_(params),
                {
                    objectName_: [
                        'pool',
                        owner.poolId,
                        'runtime',
                        'server',
                    ],
                    server_ip: owner.serverIp,
                    server_port: owner.serverPort,
                },
            );
        }
    }

    angular.extend(ServerHealthScoreMonitorListCollDataSource.prototype, {
        defaultParams_: {
            all_se: true,
        },
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
    });

    return ServerHealthScoreMonitorListCollDataSource;
}]);
