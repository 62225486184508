/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { NgModel } from '@angular/forms';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { debounce } from 'underscore';
import { LinuxServerHostConfigItem } from 'ajs/modules/cloud';
import { SEGroupCollection } from 'ajs/modules/service-engine-group';
import { StringService } from 'ajs/modules/core/services';

import {
    HostAttributeKeys,
    HostAttributeValues,
} from 'ajs/modules/cloud/factories/linux-server-host.config-item.factory';

import {
    AviDropdownButtonPosition,
    IAviDropdownButtonAction,
} from 'ng/shared/components';

import {
    TaskType,
    VerifyCloudConnectorUserHostModalService,
} from 'ng/modules/cloud/services/verify-cloud-connector-user-host-modal.service';

import {
    HostAttributes,
    LinuxServerHost,
} from 'object-types';

import * as l10n from './host-servers-modal.l10n';
import './host-servers.component.less';

type TSeGroupCollection = typeof SEGroupCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Modal component to configure Host Servers.
 *
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'host-servers-modal',
    templateUrl: './host-servers-modal.component.html',
})
export class HostServersModalComponent implements AfterViewInit, OnInit, OnDestroy {
    /**
     * LinuxServerHostConfigItem messageItem instance.
     */
    @Input()
    public editable: LinuxServerHostConfigItem;

    /**
     * Holds the flag for edit mode.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Holds the cloud id to be used for fetching Service Engines.
     */
    @Input()
    public cloudId: string;

    /**
     * Holds user ref. Used to get cloud connector user details.
     */
    @Input()
    public userRef = '';

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Template Ref for Host IP input.
     */
    @ViewChild('hostIpInputField')
    public hostIpInputField: NgModel;

    /**
     * Object Type used in template.
     */
    public readonly objectType = LinuxServerHost;

    /**
     * Host attribute object type used for Host attribute fields.
     */
    public readonly hostAttributeObjectType = HostAttributes;

    /**
     * Holds the boolean value for Enable DPDK Checkbox.
     */
    public isDpdkEnabled = false;

    /**
     * Holds the value for All and Custom radio button for Cores for Service Engines.
     */
    public coresForServiceEngines: string;

    /**
     * Holds the custom value for number of cores entered by user.
     */
    public numberofCoresForServiceEngines: string;

    /**
     * Holds the value for All and Custom radio button for memory for Service Engines.
     */
    public memoryForServiceEngines: string;

    /**
     * Holds the custom value for memory amount for Service Engines.
     */
    public memoryAmountForServiceEngines: string;

    /**
     * Holds the boolean value for Enable Inband Management Checkbox.
     */
    public isSeInbandManagementEnabled = false;

    /**
     * Holds values of HostAttributeValues enum to use in tempalte.
     */
    public readonly hostAttributeValues = HostAttributeValues;

    /**
     * Layout for DNS Resolver modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * SEGroupCollection instance.
     */
    public readonly seGroupCollection: SEGroupCollection;

    /**
     * Actions for Host IP input field dropdown menu.
     */
    public hostIpInputDropdownActions: IAviDropdownButtonAction[] = [];

    /**
     * Position of the actions menu tooltip.
     */
    public actionPosition = AviDropdownButtonPosition.TOP_RIGHT;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(SEGroupCollection)
        SEGroupCollection: TSeGroupCollection,
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly verifyCloudConnectorUserHostModalService
        : VerifyCloudConnectorUserHostModalService,
    ) {
        this.seGroupCollection = new SEGroupCollection({
            isStatic: true,
        });

        l10nService.registerSourceBundles(dictionary);

        this.setDropdownActions = debounce(this.setDropdownActions, 250);
    }

    /** @override */
    public ngOnInit(): void {
        this.seGroupCollection.setParams({
            'cloud_ref.uuid': this.cloudId,
        });

        const { host_attr: hostAttribute } = this.editable.getConfig();
        const hostAttributeConfig = hostAttribute.getConfig();

        for (const hostAttr of hostAttributeConfig) {
            const hostAttributeKey = hostAttr.config.attr_key;
            const hostAttributeValue = hostAttr.config.attr_val;

            switch (hostAttributeKey) {
                case HostAttributeKeys.DPDK:
                    if (hostAttributeValue === HostAttributeValues.Yes) {
                        this.isDpdkEnabled = true;
                    }

                    break;
                case HostAttributeKeys.SE_INBAND_MGMT:
                    if (hostAttributeValue === HostAttributeValues.True) {
                        this.isSeInbandManagementEnabled = true;
                    }

                    break;
                case HostAttributeKeys.CPU:
                    if (hostAttributeValue === HostAttributeValues.All) {
                        this.coresForServiceEngines = hostAttributeValue;
                    } else {
                        this.coresForServiceEngines = HostAttributeValues.Custom;
                        this.numberofCoresForServiceEngines = hostAttributeValue;
                    }

                    break;
                case HostAttributeKeys.MEMORY:
                    if (hostAttributeValue === HostAttributeValues.All) {
                        this.memoryForServiceEngines = hostAttributeValue;
                    } else {
                        this.memoryForServiceEngines = HostAttributeValues.Custom;
                        this.memoryAmountForServiceEngines = hostAttributeValue;
                    }
            }
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.setDropdownActions();
    }

    /**
     * Function to set Verify Host and Clean UP dropdown menu actions.
     * Dropdown menu actions are shown only when Host IP address has a valid value.
     * Dropdown menu actions are not shown if Host IP value is not set or is invalid.
     */
    public setDropdownActions(): void {
        this.hostIpInputDropdownActions = [];

        const { config: { host_ip: hostIp } } = this.editable;
        const { address } = hostIp;
        const { valid } = this.hostIpInputField;

        if (address && valid) {
            const userUuid = this.stringService.slug(this.userRef);

            this.hostIpInputDropdownActions = [
                {
                    label: this.l10nService.getMessage(l10nKeys.verifyHostMenuLabel),
                    onClick: () => {
                        this.verifyCloudConnectorUserHostModalService
                            .openModal(TaskType.VERIFY, userUuid, hostIp);
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.cleanUpMenuLabel),
                    onClick: () => this.verifyCloudConnectorUserHostModalService
                        .openModal(TaskType.CLEANUP, userUuid, hostIp),
                },
            ];
        }
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Handles the value change of Enable Dpdk checkbox.
     */
    public handleDpdkChange(): void {
        this.editable.setDpdk(this.isDpdkEnabled);
    }

    /**
     * Handles the value change of Enable Inband Management checkbox.
     */
    public handleInbandManagementChange(): void {
        this.editable.setSeInbandManagement(this.isSeInbandManagementEnabled);
    }

    /**
     * Fires on change of radio button and when user enters any input in case Custom is selected.
     * Converts ui fields to format accepted at back end.
     */
    public handleCoresForServiceEnginesChange(): void {
        this.editable.setCoresForServiceEngines(
            this.coresForServiceEngines,
            this.numberofCoresForServiceEngines,
        );
    }

    /**
     * Fires on change of radio button and when user enters any input in case Custom is selected.
     * Converts ui fields to format accepted at back end.
     */
    public handleMemoryForServiceEnginesChange(): void {
        this.editable.setMemoryForServiceEngines(
            this.memoryForServiceEngines,
            this.memoryAmountForServiceEngines,
        );
    }

    /**
     * @override
     * Destroys SEGroupCollection instance.
     */
    public ngOnDestroy(): void {
        this.seGroupCollection.destroy();
    }
}
