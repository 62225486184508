/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { HTTPVersionMatchConfigItem } from 'ajs/modules/match';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import { compose } from 'underscore';
import * as l10n from './http-version-match.l10n';
import './http-version-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const httpVersionPrefix = 'HTTP/';

/**
 * @description
 * Match component for the HTTP Versions.
 * @author Abhinesh Gour
 */
@Component({
    selector: 'http-version-match',
    templateUrl: './http-version-match.component.html',
})
export class HttpVersionMatchComponent {
    /**
     * HTTP Versions match config item instance.
     */
    @Input()
    public editable: HTTPVersionMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object Type to be used in template for tooltip.
     */
    public readonly httpVersionTypeMatchObjectType = 'HTTPVersionMatch';

    /**
     * HTTPVersion type dropdown options.
     */
    public readonly httpVersionTypeOptions: IAviDropdownOption[] = [];

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        const HTTPVersionTypes = schemaService.getEnumValues('HTTPVersion');

        this.httpVersionTypeOptions = compose(
            this.prefixOptionLabel,
            createOptionsFromEnumProps,
        )(HTTPVersionTypes);

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }

    /**
     * createOptionsFromEnumProps when called with HTTPVersionTypes returns label as 0.9, 1.0, etc
     * but we need to show these option labels as HTTP/0.9, HTTP/1.0
     * this function returns the label with "HTTP/" prefix.
     */
    private prefixOptionLabel = (options: IAviDropdownOption[]): IAviDropdownOption[] => {
        return options.map(option => {
            option.label = httpVersionPrefix + option.label;

            return option;
        });
    };
}
