/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    copy,
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    findIndex,
    isNumber,
    isObject,
    isString,
    isUndefined,
    pick,
    pluck,
    reject,
} from 'underscore';

import { Item } from 'ajs/modules/data-model/factories/item.factory';
import {
    GslbGeoLocationSource,
    IGslb,
    IGslbGeoLocation,
    IGslbSite,
    IGslbSiteDnsVs,
    IGslbThirdPartySite,
    IpAddrType,
    IReplicationPolicy,
    SiteMemberType,
} from 'generated-types';

import {
    SystemInfoService,
} from 'ajs/modules/core/services/system-info';

import { AviAlertService } from 'ng/modules/core';
import { GSLBVSCollection } from './gslb-vs.collection.factory';
import { Base, IBaseRequestPromise } from '../../data-model/factories/base.factory';

type TGslbVsCollectionType = typeof GSLBVSCollection;

enum SiteConfigFieldName {
    AVI_SITES = 'sites',
    NON_AVI_SITES = 'third_party_sites',
}

interface IDnsVsSite {
    clusterId: string;
    name: string;
    dnsVSs: IGslbSiteDnsVs[];
}

/**
 * @description
 *
 *     Global Service Load Balancer Item. Has a list of GslbSites, one of which is local,
 *     remaining are remote. One GslbSite is considered the owner and can't be dropped.
 *
 * @author Alex Malitsky, Ram Pal
 */
export class GSLB extends Item<IGslb> {
    public static sitesConfigPropName = 'sites';
    public static nonAviSitesConfigPropName = 'third_party_sites';
    public readonly clientGroupIps: object[];
    private readonly GSLBVSCollection: TGslbVsCollectionType;
    private readonly systemInfo: SystemInfoService;

    constructor(args = {}) {
        super(args);

        /**
         * List of IPGroups, used for edit only. Objects of IpAddr, IpAddrRange or
         * IpAddrPrefix types.
         */
        this.clientGroupIps = [];
        this.GSLBVSCollection = this.getAjsDependency_('GSLBVSCollection');
        this.systemInfo = this.getAjsDependency_('systemInfoService');
    }

    /**
     * Gets a local Site configuration with ips, port and cluster_uuid.
     */
    public static getLocalSiteConfig(): Promise<IGslbSite> {
        const systemInfo = this.getAjsDependency_('systemInfoService');

        return Promise.resolve(systemInfo.isReady() || systemInfo.load())
            .then(() => {
                const {
                    cluster: clusterConfig,
                    systemconfiguration: systemConfig,
                } = systemInfo.data;

                return {
                    cluster_uuid: clusterConfig.uuid,
                    ip_addresses: pluck(clusterConfig.nodes, 'ip'),
                    port: systemConfig.portal_configuration.port,
                    member_type: SiteMemberType.GSLB_ACTIVE_MEMBER,
                };
            },
            err => {
                const aviAlertService: AviAlertService =
                    this.getAjsDependency_('aviAlertService');

                aviAlertService.throw(err.data);

                return Promise.reject(err);
            });
    }

    /**
     * Makes an API call to verify whether this GslbSite is configured to participate in GSLB
     * configuration.
     */
    private static verifySite(gslbSite: IGslbSite): IBaseRequestPromise<void> {
        const payload = pick(gslbSite, ['username', 'password', 'ip_addresses', 'port']);
        const AjsBase: typeof Base = this.getAjsDependency_('Base');

        return new AjsBase().request('post', '/api/gslbsiteops/verify', payload);
    }

    /** @override */
    public beforeEdit(): void {
        const config: IGslb = this.getConfig();

        if (!('dns_configs' in config)) {
            config.dns_configs = [];
        }

        if (!config.dns_configs.length) {
            config.dns_configs.push({ domain_name: '' });
        }

        config.sites.forEach((site: IGslbSite) => {
            if (!('ip_addresses' in site)) {
                site.ip_addresses = [];
            }

            if (!site.ip_addresses.length) {
                site.ip_addresses.push({
                    addr: '',
                    type: IpAddrType.V4,
                });
            }

            if (!('dns_vses' in site)) {
                site.dns_vses = [];
            }

            if (!site.dns_vses.length) {
                site.dns_vses.push(
                    this.getDefaultGslbSiteDNSVSConfig(),
                );
            }

            const gslbLocationAfterLoad = this.getAjsDependency_('gslbLocationAfterLoad');

            gslbLocationAfterLoad(site.location);
        });

        const { clientGroupIps } = this;

        clientGroupIps.length = 0;

        if ('client_ip_addr_group' in config) {
            const { client_ip_addr_group: group } = config;

            ['addrs', 'ranges', 'prefixes'].forEach(propName => {
                if (propName in group) {
                    clientGroupIps.push(...group[propName]);
                }
            });
        }
    }

    /** @override */
    public dataToSave(): IGslb {
        const config: IGslb = copy(this.getConfig());

        config.dns_configs = reject(config.dns_configs,
            config => !config.domain_name);

        if (!config.dns_configs.length) {
            config.dns_configs = undefined;
        }

        const filterLocation = (location: IGslbGeoLocation): void => {
            if (location && location.source !==
                GslbGeoLocationSource.GSLB_LOCATION_SRC_USER_CONFIGURED) {
                delete location.location;
            }
        };

        config.sites.forEach((site: IGslbSite) => {
            site.ip_addresses = reject(site.ip_addresses, ipAddr => !ipAddr.addr);

            if (!site.ip_addresses.length) {
                site.ip_addresses = undefined;
            }

            if (site.member_type === SiteMemberType.GSLB_ACTIVE_MEMBER &&
                Array.isArray(site.dns_vses)
            ) {
                site.dns_vses = site.dns_vses
                    .filter(({ dns_vs_uuid: vsId }) => vsId);

                // strip off names from VS uuids
                site.dns_vses.forEach((dnsVS: IGslbSiteDnsVs) =>
                    dnsVS.dns_vs_uuid = this.stringService.slug(dnsVS.dns_vs_uuid));
            } else {
                site.dns_vses = undefined;
            }

            filterLocation(site.location);
        });

        if (Array.isArray(config.third_party_sites)) {
            config.third_party_sites.forEach(site => filterLocation(site.location));
        }

        const { clientGroupIps } = this;

        if (clientGroupIps.length) {
            if (!('client_ip_addr_group' in config)) {
                config.client_ip_addr_group = {};
            }

            const { client_ip_addr_group: clientGroup } = config;

            clientGroup.addrs = [];
            clientGroup.ranges = [];
            clientGroup.prefixes = [];

            clientGroupIps.forEach((ip: any) => {
                if (isObject(ip)) {
                    if ('addr' in ip) {
                        clientGroup.addrs.push(ip);
                    } else if ('mask' in ip) {
                        clientGroup.prefixes.push(ip);
                    } else if ('begin' in ip && 'end' in ip) {
                        clientGroup.ranges.push(ip);
                    }
                }
            });
        } else {
            delete config.client_ip_addr_group;
        }

        return config;
    }

    /** @override */
    public transformAfterLoad(): void {
        this.clientGroupIps.length = 0;
    }

    /**
     * Checks whether passed controller is marked as `owner` of GSLB configuration.
     */
    public isLeaderSite(gslbSite: IGslbSite): boolean | undefined {
        const id = isString(gslbSite) && gslbSite ||
            isObject(gslbSite) && gslbSite.cluster_uuid;
        let res;

        if (this.data) {
            res = !!id && this.getConfig().leader_cluster_uuid === id;
        }

        return res;
    }

    /**
     * Returns a name of GslbSite which is the leader of this GSLB configuration.
     */
    public getLeaderSiteName(): string | undefined {
        const config = this.getConfig();

        if (!config) {
            return;
        }

        const { name } = this.getAviSiteByIndex(
            this.getSiteIndex(SiteConfigFieldName.AVI_SITES, config.leader_cluster_uuid),
        );

        return name;
    }

    /**
     * Returns a default GslbSite configuration.
     */
    public getDefaultSiteConfig(type: SiteConfigFieldName): IGslbSite {
        let config: IGslbSite;
        const defaultValues = this.getAjsDependency_('defaultValues');

        switch (type) {
            case SiteConfigFieldName.NON_AVI_SITES:
                config = defaultValues.getDefaultItemConfigByType('gslbthirdpartysite');
                break;

            default:
                [config] = this.getDefaultConfig_()?.sites;
        }

        return copy(config);
    }

    /**
     * Returns default configuration of GslbSite DNS VS object.
     */
    public getDefaultGslbSiteDNSVSConfig(): IGslbSiteDnsVs {
        return {
            dns_vs_uuid: '',
            domain_names: [],
        };
    }

    /**
     * Returns GslbSite of a passed index if present in GSLB config.
     */
    public getAviSiteByIndex(index: number): IGslbSite | undefined {
        return this.getSiteByIndex(SiteConfigFieldName.AVI_SITES, index);
    }

    /**
     * Returns nonAviGslbSite of a passed index if is present in GSLB config.
     */
    public getNonAviSiteByIndex(index: number): IGslbThirdPartySite | undefined {
        return this.getSiteByIndex(SiteConfigFieldName.NON_AVI_SITES, index);
    }

    /**
     * Returns a fist DNS domain name.
     */
    public getDefaultDNSDomainName(): string {
        const domains = this.getDNSDomainNames();

        return domains.length ? domains[0] : '';
    }

    /**
     * Returns a list of domain names provided by this GSLB item.
     */
    public getDNSDomainNames(woDot = false): string[] {
        const { dns_configs: dnsConfigs } = this.getConfig();
        let domains: string[] = [];

        if (Array.isArray(dnsConfigs)) {
            domains = pluck(dnsConfigs, 'domain_name');

            if (!woDot) {
                domains = domains.map((domainName: string) => `.${domainName}`);
            }
        }

        return domains.sort();
    }

    /**
     * Wrapper over addSite for regular GSLB sites.
     */
    public addAviSite(): void {
        this.addSite(SiteConfigFieldName.AVI_SITES);
    }

    /**
     * Wrapper over addSite for non avi sites.
     */
    public addNonAviSite(): void {
        this.addSite(SiteConfigFieldName.NON_AVI_SITES);
    }

    /**
     * Opens a modal to edit properties of GslbSite belonging to GSLB. Wrapper over editSite.
     */
    public editAviSite(site: string | number | IGslbSite | IGslbThirdPartySite): IPromise<void> {
        return this.editSite(SiteConfigFieldName.AVI_SITES, site);
    }

    /**
     * Opens a modal to edit properties of GslbThirdPartySite belonging to GSLB.
     * Wrapper over editSite.
     */
    public editNonAviSite(site: string | number | IGslbSite | IGslbThirdPartySite): IPromise<void> {
        return this.editSite(SiteConfigFieldName.NON_AVI_SITES, site);
    }

    /**
     * Wrapper over toggleSiteEnabledFlag_ for regular GSLB sites.
     */
    public toggleAviSiteEnabledFlag(
        sites: IGslbSite | IGslbSite[],
        flagValue?: boolean,
    ): IPromise<IHttpResponse<IGslb>> {
        return this.toggleSiteEnabledFlag(SiteConfigFieldName.AVI_SITES, sites, flagValue);
    }

    /**
     * Wrapper over toggleSiteEnabledFlag for non-avi GSLB sites.
     */
    public toggleNonAviSiteEnabledFlag(
        sites: IGslbSite | IGslbSite[],
        flagValue?: boolean,
    ): IPromise<IHttpResponse<IGslb>> {
        return this.toggleSiteEnabledFlag(SiteConfigFieldName.NON_AVI_SITES, sites, flagValue);
    }

    /**
     * Wrapper over dropSites for regular sites.
     */
    public dropAviSites(sites: string | IGslbSite | IGslbSite[]): IPromise<IHttpResponse<IGslb>> {
        return this.dropSites(SiteConfigFieldName.AVI_SITES, sites);
    }

    /**
     * Wrapper over dropSites for non avi sites.
     */
    public dropNonAviSites(
        sites: string | IGslbSite | IGslbSite[],
    ): IPromise<IHttpResponse<IGslb>> {
        return this.dropSites(SiteConfigFieldName.NON_AVI_SITES, sites);
    }

    /**
     * When editing list of DNS VSes of a GslbSite we need to load their names from the
     * corresponding GslbSites and append them to bare uuids we have in config. When
     * siteIndex is not passed we will load all the names for all GslbSites.
     */
    public getSiteDNSVSNames(siteIndex: number): Promise<any[]> {
        const errMsg = 'Item not ready (isDestroyed_) or wrong siteIndex was passed';
        const promises: Array<Promise<any>> = [];

        let sites;
        let site;

        if (this.data && (isUndefined(siteIndex) ||
            // eslint-disable-next-line no-cond-assign
            (site = this.getAviSiteByIndex(siteIndex)))) {
            if (site) {
                sites = [site];
            } else {
                sites = this.getConfig().sites;
            }

            this.busy = true;

            sites.forEach((site: IGslbSite) => {
                const vsIdToDNSVSList = {};
                const vsUuids: string[] = [];

                if (Array.isArray(site.dns_vses)) {
                    site.dns_vses.forEach(dnsVS => {
                        const { dns_vs_uuid: vsId } = dnsVS;

                        if (!(vsId in vsIdToDNSVSList)) {
                            vsIdToDNSVSList[vsId] = [];
                            vsUuids.push(vsId);
                        }

                        vsIdToDNSVSList[vsId].push(dnsVS);
                    });
                }

                let promise: Promise<any>;

                if (vsUuids && vsUuids.length) {
                    const vsCollection = new this.GSLBVSCollection({
                        gslbSiteId: site.cluster_uuid,
                        gslbTenant: this.getTenantId(),
                        limit: 1000,
                        params: {
                            'uuid.in': vsUuids.join(),
                            fields: 'name, tenant_ref',
                            headers_: { 'X-Avi-Internal-All-Tenants': true },
                        },
                    });

                    promise = vsCollection.load().then(() => {
                        if (!this.isDestroyed()) {
                            // add names to VS uuids in-place
                            vsUuids.forEach((vsUuid: string) => {
                                const vsItem = vsCollection.getItemById(vsUuid);

                                if (vsItem) {
                                    const vsName = vsItem.getName();

                                    vsIdToDNSVSList[vsUuid].forEach((dnsVS: IGslbSiteDnsVs) =>
                                        dnsVS.dns_vs_uuid += `#${vsName}`);
                                }
                            });
                        } else {
                            this.devLoggerService.warn(errMsg);

                            return Promise.reject(new Error(errMsg));
                        }
                    }).finally(() => {
                        vsCollection.destroy();
                    });
                } else {
                    promise = Promise.resolve(
                        'No VSes are present in config - have nothing to load',
                    );
                }

                promises.push(promise);
            });

            if (promises.length) {
                Promise.all(promises)
                    .finally(() => this.busy = false);
            } else {
                this.busy = false;
            }
        } else {
            this.devLoggerService.warn(errMsg);
            promises.push(Promise.reject(new Error(errMsg)));
        }

        return Promise.all(promises);
    }

    /**
     * Makes use of private static method to verify whether Site configuration is ready to be
     * used for GSLB. Also checks credentials, gets and sets GslbSite#cluster_uuid and
     * saves/submits an object.
     */
    public verifySiteAndSave(siteIndex: number, submitOnSuccess: boolean): Promise<void> {
        const errMsg = 'Item not be ready (might be UI destroyed) or faulty API response';
        let promise: any;
        let site: IGslbSite;

        // eslint-disable-next-line no-cond-assign
        if (this.data && (site = this.getAviSiteByIndex(siteIndex))) {
            this.busy = true;

            promise = GSLB.verifySite.call(this, site)
                .then((rsp: any) => {
                    if (this.data && rsp.data) {
                        const oldClusterUuid = site.cluster_uuid;

                        site.cluster_uuid = rsp.data.rx_uuid;

                        // if cluster_uuid has changed we remove old DNS VSs
                        if (oldClusterUuid !== site.cluster_uuid) {
                            site.dns_vses.length = 0;
                        }

                        return rsp;
                    } else {
                        this.devLoggerService.warn(errMsg);

                        return Promise.reject(errMsg);
                    }
                }, (err: any) => {
                    this.errors = err.data;

                    return Promise.reject(errMsg);
                })
                .finally(() => {
                    this.busy = false;
                });

            promise = promise.then((rsp: any) => {
                if (this.data && rsp.data) {
                    return submitOnSuccess ? this.submit() : this.save();
                } else {
                    this.devLoggerService.warn(errMsg);

                    return Promise.reject(errMsg);
                }
            });
        } else {
            promise = Promise
                .reject(new Error('Data is not ready or Site of a passed index wasn\'t found.'));
        }

        return promise;
    }

    /**
     * Returns a hash of cluster_id as a key and value keeping an array of DNS VS ids
     * configured on that particular GslbSite. Source of data for GSLBServiceFQDNCollection.
     */
    public getDNSVSSites(): IDnsVsSite {
        const res: IDnsVsSite = {} as any;
        const { sites } = this.getConfig();

        sites.forEach(({ cluster_uuid: clusterId, dns_vses: dnsVSes, name }: IGslbSite) => {
            if (dnsVSes && dnsVSes.length) {
                res[clusterId] = {
                    clusterId,
                    name,
                    dnsVSs: pluck(dnsVSes, 'dns_vs_uuid').concat(),
                };
            }
        });

        return res;
    }

    /**
     * Returns the list of GslbSite ids.
     */
    public getSites(): string[] {
        const { sites } = this.getConfig();

        return sites.map(({ cluster_uuid: clusterUuid, name }) => `${clusterUuid}#${name}`);
    }

    /**
     * Returns the list of non-Avi site ids.
     */
    public getNonAviSites(): string[] {
        const config = this.getConfig();
        const sites = config[SiteConfigFieldName.NON_AVI_SITES] || [];

        return sites
            .map(({ cluster_uuid: clusterUuid, name }: IGslbSite) => `${clusterUuid}#${name}`);
    }

    /**
     * Returns the list of both Avi and non-Avi site ids.
     */
    public getAllSites(): string[] {
        return [...this.getSites(), ...this.getNonAviSites()];
    }

    /**
     * Returns replication policy object.
     */
    public get replicationPolicyConfig(): IReplicationPolicy {
        const { replication_policy: replicationPolicy } = this.getConfig();

        if (!replicationPolicy) {
            throw new Error('replication_policy obj does not exist on site config.');
        }

        return replicationPolicy;
    }

    /**
     * Returns human-readable value of current replication_mode.
     */
    public get replicationPolicyModeEnumerated(): string {
        return this.stringService
            .enumeration(this.replicationPolicyConfig.replication_mode, 'REPLICATION_MODE_');
    }

    /**
     * Returns the active replication checkpoint id, if exists.
     */
    public get activeCheckpointId(): string {
        const { checkpoint_ref: activeCheckpointUrl } = this.replicationPolicyConfig;

        return activeCheckpointUrl ? this.stringService.slug(activeCheckpointUrl) : '';
    }

    /**
     * Fires api call to attempt to mark new checkpoint as active one.
     */
    public setActiveReplicationCheckpoint(id: string): IPromise<IHttpResponse<IGslb>> {
        return this.patch({
            add: {
                replication_policy: { checkpoint_ref: id },
            },
        });
    }

    /**
     * Deletes object from clientGroupIps at index specified.
     */
    public deleteClientGroupIp(index: number): void {
        this.clientGroupIps.splice(index, 1);
    }

    /**
     * Adds empty placeholder object to clientGroupIps array.
     */
    public addClientGroupIp(): void {
        this.clientGroupIps.push(undefined);
    }

    /**
     * Getter for enable_config_by_members field.
     * If true, we can edit GSLB followers.
     */
    public get enableConfigByMembers(): boolean {
        const { enable_config_by_members: enableConfigByMembers } = this.getConfig();

        return Boolean(enableConfigByMembers);
    }

    /** @override */
    public isEditable(): boolean {
        return (!this.systemInfo.haveGSLBConfig() || this.systemInfo.localSiteIsGSLBLeader) &&
            super.isEditable();
    }

    /** @override */
    public isProtected(): boolean {
        return !this.systemInfo.localSiteIsGSLBLeader || super.isProtected();
    }

    /**
     * Since we use different modals for regular gslbSites and non avi ones we need to
     * dismiss the right one. In case on non avi Gslb site create/edit boolean argument will
     * be passed.
     */
    public dismiss(nonAviSiteModal: boolean): void {
        // dismiss doesn't support passing modal id unfortunately, let's redefine it for a while
        const { windowElement } = this;

        if (nonAviSiteModal) {
            this.windowElement = this.getSiteEditModalId(SiteConfigFieldName.NON_AVI_SITES);
        }

        super.dismiss(true);

        this.windowElement = windowElement;
    }

    /**
     * Non avi Gslb sites use different edit modal component, this component returns such id.
     */
    private getSiteEditModalId(type: SiteConfigFieldName): string | undefined {
        switch (type) {
            case SiteConfigFieldName.NON_AVI_SITES:
                return 'gslb-non-avi-modal';
        }
    }

    /**
     * Drops any GslbSite which is not an `owner` or any NonAviGslbSite from GSLBConfig.
     */
    private dropSites(
        siteConfigFieldName: SiteConfigFieldName,
        sites: string | string[] | IGslbSite | IGslbSite[],
    ): IPromise<IHttpResponse<IGslb>> {
        const config = this.getConfig();
        const { leader_cluster_uuid: leaderClusterId } = config;
        const list = config[siteConfigFieldName];
        const sitesToDrop: Array<IGslbSite | string> = [];

        let promise;

        if (!Array.isArray(sites)) {
            sites = [sites] as IGslbSite[] | string[];
        }

        sites.forEach((site: IGslbSite | string) => {
            const index = this.getSiteIndex(siteConfigFieldName, site);

            // eslint-disable-next-line no-cond-assign
            if (index !== -1 && (site = list[index]) &&
                site.cluster_uuid !== leaderClusterId) {
                sitesToDrop.push(site);
            }
        });

        if (sitesToDrop.length) {
            const payload = {};

            payload[siteConfigFieldName] = sitesToDrop;
            promise = this.patch({ delete: payload });
        }

        return promise || Promise.reject(new Error('No sites to drop'));
    }

    /**
     * Adds a GslbSite or GslbThirdPartySite with default configuration to GslbConfig and opens
     * a modal to set it's properties. In case of modal dismiss event removes dummy item from
     * GSLB. Need to switch off Item#loadOnEdit before opening modal, otherwise dummy item
     * will be dropped since backend configuration doesn't have it yet.
     */
    private addSite(siteConfigFieldName: SiteConfigFieldName): void {
        const windowElement = this.getSiteEditModalId(siteConfigFieldName);
        const defaultConfig = this.getDefaultSiteConfig(siteConfigFieldName);
        const { loadOnEdit: prevLoadOnEdit } = this;
        const config = this.getConfig();

        config[siteConfigFieldName] = config[siteConfigFieldName] || [];

        const newIndex = config[siteConfigFieldName].push(defaultConfig) - 1;

        this.loadOnEdit = false;

        this.edit(windowElement, { siteIndex: newIndex })
            .catch(() => config[siteConfigFieldName].pop())
            .finally(() => this.loadOnEdit = prevLoadOnEdit);
    }

    /**
     * Figures out which modal to open and opens it in a usual manner.
     */
    private editSite(
        siteConfigFieldName: SiteConfigFieldName,
        site: string | number | IGslbSite | IGslbThirdPartySite,
    ): ng.IPromise<void> {
        const windowElement = this.getSiteEditModalId(siteConfigFieldName);
        const index = this.getSiteIndex(siteConfigFieldName, site);

        let promise;

        if (index !== -1) {
            promise = this.edit(windowElement, { siteIndex: index });
        }

        return promise ||
            Promise.reject(new Error('Passed GslbSite was not found in Item\'s config data'));
    }

    /**
     * Returns a GslbSite index in GslbConfig.sites or GslbThirdPartySite index in
     * GslbConfig.third_party_sites.
     */
    private getSiteIndex(
        siteConfigFieldName: SiteConfigFieldName,
        site: string | number | IGslbSite | IGslbThirdPartySite,
    ): number {
        const sites = this.getConfig()[siteConfigFieldName];

        let index = -1;
        let siteId: string;

        if (isNumber(site) && site in sites) {
            index = site;
        // eslint-disable-next-line no-cond-assign
        } else if (site && isString(site) && (siteId = site) ||
            // eslint-disable-next-line no-cond-assign
            isObject(site) && (siteId = site.cluster_uuid)) {
            index = findIndex(sites, gslbSite => gslbSite.cluster_uuid === siteId);
        }

        return index;
    }

    /**
     * Actual lookup is made here.
     */
    private getSiteByIndex(
        siteConfigFieldName: SiteConfigFieldName,
        index: number,
    ): IGslbSite | IGslbThirdPartySite | undefined {
        const list = this.getConfig()[siteConfigFieldName];

        if (Array.isArray(list)) {
            return list[index];
        }
    }

    /**
     * Enables/Disables GslbSite or GslbThirdPartySite of GSLB.
     */
    private toggleSiteEnabledFlag(
        siteConfigFieldName: SiteConfigFieldName,
        sites: IGslbSite | IGslbSite[],
        flagValue?: boolean,
    ): IPromise<IHttpResponse<IGslb>> {
        const haveValueToSet = !isUndefined(flagValue);
        const sitesToUpdate: IGslbThirdPartySite[] = [];
        const list = this.getConfig()[siteConfigFieldName];

        let promise;

        if (!Array.isArray(sites)) {
            sites = [sites];
        }

        sites.forEach((site: IGslbSite | string) => {
            const index = this.getSiteIndex(siteConfigFieldName, site);

            if (index !== -1) {
                const siteToUpdate = copy(list[index]);

                siteToUpdate.enabled = haveValueToSet ? !!flagValue : !siteToUpdate.enabled;
                sitesToUpdate.push(siteToUpdate);
            } else {
                console.error('Passed GslbSite was not found in Item\'s config data');
            }
        });

        if (sitesToUpdate.length) {
            const payload = {};

            payload[siteConfigFieldName] = sitesToUpdate;
            promise = this.patch({ add: payload });
        }

        return promise || Promise.reject(new Error('No sites to update'));
    }
}

Object.assign(GSLB.prototype, {
    objectName: 'gslb',
    windowElement: 'gslb-edit',
});

GSLB.ajsDependencies = [
    'Base',
    'defaultValues',
    'aviAlertService',
    'GSLBVSCollection',
    'systemInfoService',
    'gslbLocationAfterLoad',
    'stringService',
    'devLoggerService',
];
