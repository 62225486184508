/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'licensing';
const componentName = 'license-tier-select-card';
const prefix = `${moduleName}.${componentName}`;

export const tierHeader = `${prefix}.tierHeader`;
export const numberOfLicenses = `${prefix}.numberOfLicenses`;

export const ENGLISH = {
    [tierHeader]: '{0} Tier',
    [numberOfLicenses]: '{0} Service Core Licenses',
};
