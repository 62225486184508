/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-psm-groups-config';
const prefix = `${moduleName}.${componentName}`;

export const addPsmGroupLabel = `${prefix}.addPsmGroupLabel`;
export const createLabel = `${prefix}.createLabel`;
export const wafPolicyLabel = `${prefix}.wafPolicyLabel`;

export const ENGLISH = {
    [addPsmGroupLabel]: 'Add Positive Security Group',
    [createLabel]: 'Create',
    [wafPolicyLabel]: 'WAF Policy: {0}',
};
