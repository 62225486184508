/**
 * @module LoginModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    ViewChild,
} from '@angular/core';

import {
    StateParams,
    StateService,
} from '@uirouter/core';

import { NgForm } from '@angular/forms';
import { Auth } from 'ajs/modules/core/services';
import { L10nService } from '@vmw/ngx-vip';
import './password-change.component.less';

import * as l10n from './password-change.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Constant for login string.
 */
const LOGIN_STATE = 'login';

/**
 * @desc
 *      Password Reset Component
 *          - contains new password and confirm password fields.
 *          - triggers password change call to server.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'password-change',
    templateUrl: './password-change.component.html',
})
export class PasswordChangeComponent {
    @ViewChild('passwordResetForm', { static: true })
    public passwordResetFrom: NgForm;

    /**
     * Model value for new password field.
     */
    public newPassword = '';

    /**
     * Model value for confirm password field.
     */
    public confirmPassword = '';

    /**
     * Holds the error message if any.
     */
    public errorMessage = '';

    /**
     * Flag to check if password was changed successfully.
     */
    public passwordChanged = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Flag to check if api call is in progress.
     */
    public isBusy = false;

    constructor(
        private readonly authService: Auth,
        private readonly l10nService: L10nService,
        private readonly $state: StateService,
        @Inject('$stateParams')
        private readonly $stateParams: StateParams,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Invokes the Auth service method to change the password.
     */
    public async changePassword(): Promise<void> {
        const {
            authService,
            confirmPassword,
            l10nKeys,
            l10nService,
            newPassword,
            $stateParams,
        } = this;

        const {
            token,
            username,
        } = $stateParams;

        try {
            if (this.isBusy) {
                return;
            }

            if (newPassword !== confirmPassword) {
                this.errorMessage = l10nService.getMessage(l10nKeys.passwordsUnmatchedErrorMessage);

                return;
            }

            this.isBusy = true;
            this.errorMessage = '';

            await authService.passwordChange(token, username, newPassword);

            this.passwordChanged = true;

            this.redirectToLogin();
        } catch (error) {
            this.errorMessage = error.data.error;
        } finally {
            this.isBusy = false;
        }
    }

    /**
     * Returns if Password Change button needs to be disabled.
     */
    public get isPasswordChangeDisabled(): boolean {
        const {
            passwordChanged,
            passwordResetFrom,
        } = this;

        return passwordResetFrom.invalid || passwordChanged;
    }

    /**
     * Redirects to login page after password changed successfully.
     */
    private redirectToLogin(): void {
        setTimeout(() => {
            this.$state.go(LOGIN_STATE);
        }, 3000);
    }
}
