/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'update-card-about';
const prefix = `${moduleName}.${componentName}`;

export const versionLabel = `${prefix}.versionLabel`;
export const buildLabel = `${prefix}.buildLabel`;
export const patchLabel = `${prefix}.patchLabel`;
export const dateLabel = `${prefix}.dateLabel`;

export const ENGLISH = {
    [versionLabel]: 'VERSION',
    [buildLabel]: 'BUILD',
    [patchLabel]: 'PATCH',
    [dateLabel]: 'DATE',
};
