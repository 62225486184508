/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import './match-display-wrapper.component.less';

/**
 * Simple view wrapper component to be used to display a match.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'match-display-wrapper',
    templateUrl: './match-display-wrapper.component.html',
})
export class MatchDisplayWrapperComponent {
    /**
     * Type of match.
     */
    @Input()
    public readonly matchLabel: string;

    /**
     * Optional parameter of the match, put in parenthesis.
     */
    @Input()
    public readonly matchCriteria?: string;

    /**
     * Needed to show/hide border. Only needed if it's possible to have match without values.
     */
    @Input()
    public readonly matchValuesExist?: boolean = true;

    /**
     * For matches which have names.
     */
    @Input()
    public readonly subheader?: string;

    /**
     * For matches which have name (subheader).
     * if matches have criteria, it will be displayed along with name.
     */
    public get matchTitle(): string {
        return this.matchCriteria ? `${this.subheader} (${this.matchCriteria})` : this.subheader;
    }
}
