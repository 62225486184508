/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import {
    each,
    isObject,
    isUndefined,
    reduce,
} from 'underscore';
import { IMessageMapProps } from '../services/message-map';
import { UpdatableItem } from './updatable-item.factory';
import { MessageBase } from './message-base.factory';
import { RepeatedMessageItem } from './repeated-message-item.factory';
import { IEditableChildren, MessageItem } from './message-item.factory';
import { withMessageMapMixin } from '../mixins';
import { IItemData } from './item.factory';

export type TItemConfig = Record<string, any>;

type TMessageItemsHash = Record<string, MessageItem | RepeatedMessageItem<MessageItem>>;

type TMessageItemTreeConfig = Record<string, MessageItem | RepeatedMessageItem<MessageItem> | any>;

interface IObjectTypeItemArgs {
    /**
     * objectType is the name used for the object in Schema.js, whereas objectName is used
     * for the API. If the objectType is set, then the Item should go through the ConfigItem
     * flow and its config will be set to contain ConfigItem instances rather than flat object
     * data.
     */
    objectType?: string;

    /**
     * Data object containing the item config.
     */
    data?: IItemData<any>;

    /**
     * List of whitelisted fields. Used when converting an Item to an ObjectTypeItem but only
     * specified fields should be converted into MessageItems.
     */
    whitelistedFields?: string[];
}

/**
 * @description
 *     An item to be used with MessageItems. If your item is compatible with the MessageItem
 *     architecture, you should extend ObjectTypeItem rather than Item and define an objectType.
 * @author alextsg
 */
export class ObjectTypeItem extends withMessageMapMixin(UpdatableItem)
    implements IEditableChildren {
    /**
     * The message name in the protobuf.
     */
    protected objectType: string;

    /**
     * MessageBase constructor function. Used to call createMessageMap static method.
     */
    private MessageBase: typeof MessageBase = this.getAjsDependency_('MessageBase');

    /** */
    constructor(args: IObjectTypeItemArgs = {}) {
        super(args);

        const { objectType, data } = args;

        this.objectType = objectType || this.objectType;

        if (!this.objectType) {
            throw new Error(`objectType not found for ${this.objectName}`);
        }

        if (data && data.config) {
            this.updateConfigItems(data.config);
        }
    }

    /**
     * Returns the Item's config data.
     */
    public get config(): TItemConfig {
        return this.getConfig();
    }

    /** @override */
    public updateItemData(newData: IItemData<any>): boolean {
        if (isObject(newData)) {
            const { data } = this;

            each(newData, (val, key) => {
                if (key === 'config') {
                    this.updateConfigItems(val);
                } else {
                    data[key] = val;
                }
            });
        }

        return true;
    }

    /** @override */
    public getDataCopy(): IItemData<any> {
        const flattenedConfig = this.flattenConfig();
        const itemData = {
            ...this.data,
            config: flattenedConfig,
        };

        return angular.copy(itemData);
    }

    /**
     * Created as a public wrapper around createChildByField_ for consistency with MessageItem,
     * which also has createChildByField as a public method.
     * Given a field name of a config, create a MessageItem corresponding to that field.
     */
    public createChildByField(
        ...args: [string, TItemConfig?, boolean?, boolean?, object?]
    ): MessageItem | RepeatedMessageItem<MessageItem> {
        return this.createChildByField_(...args);
    }

    /**
     * Returns plain object config data from each child MessageItem.
     */
    public flattenConfig(): TItemConfig {
        const config = this.getConfig();
        const flattenedConfigItems = reduce(
            this.messageMap,
            (acc, messageMapProps, field) => {
                const value = config[field];

                if (!isUndefined(value)) {
                    acc[field] = value.flattenConfig();
                }

                return acc;
            },
            {},
        );

        const flattenedConfig = {
            ...config,
            ...flattenedConfigItems,
        };

        return angular.copy(flattenedConfig);
    }

    /**
     * Calls getDataToSave on each child MessageItem.
     */
    public dataToSave(): TItemConfig {
        return angular.copy(
            this.childConfigItemMap(this.getConfigCopy(), 'getDataToSave'),
        );
    }

    /**
     * Getter for objectType.
     */
    public get messageType(): string {
        return this.objectType;
    }

    /** @override */
    public destroy(): boolean {
        const gotDestroyed = super.destroy();

        if (gotDestroyed) {
            this.configMessageItemsCall('destroy');
        }

        return gotDestroyed;
    }

    /**
     * List of fields that should always be present in the config. If that field's value is
     * undefined, it will be set to the default value in this.modifyConfigDataAfterLoad.
     */
    protected requiredFields(): string[] {
        return [];
    }

    /**
     * Given a field name of a config, create a MessageItem corresponding to that field.
     * @param fieldName - name of the property in the config.
     * @param childConfig - Config data object to be set in the child MessageItem.
     * @param skipRepeated - True if RepeatedMessageItem should not be created, and the
     *     ConfigItemClass should be created directly.
     * @param optionalArgs - Optional arguments to pass to the child constructor.
     */
    // eslint-disable-next-line no-underscore-dangle
    protected createChildByField_(
        fieldName: string,
        childConfig?: TItemConfig,
        skipRepeated = false,
        skipTransformation = false,
        optionalArgs = {},
    ): MessageItem | RepeatedMessageItem<MessageItem> {
        const { ConfigItemClass, objectType, isRepeated } = this.messageMap[fieldName];
        const InjectedRepeatedMessageItem = this.getAjsDependency_('RepeatedMessageItem');

        const args = {
            objectType,
            fieldName,
            config: childConfig,
            ...optionalArgs,
        };

        if (isRepeated && !skipRepeated) {
            const { config, ...messageItemArgs } = args;

            return new InjectedRepeatedMessageItem({
                messageItemArgs: { ...messageItemArgs },
                MessageItemConstructor: ConfigItemClass,
                config,
            });
        }

        return new ConfigItemClass(args);
    }

    /**
     * Creates a new RepeatedMessageItem or MessageItem instance and sets it as a property on
     * the config.
     * @param fieldName - Property of a MessageItem to set on the config.
     */
    protected setNewChildByField(
        fieldName: string,
        ...args: [TItemConfig?, boolean?, boolean?, object?]
    ): void {
        this.data.config[fieldName] = this.createChildByField_(fieldName, ...args);
    }

    /**
     * Only creates a new RepeatedMessageItem or MessageItem instance and sets it as a property on
     * the config if it does not already exist.
     * @param fieldName - Property of a MessageItem to set on the config.
     */
    protected safeSetNewChildByField(
        fieldName: string,
        ...args: [TItemConfig?, boolean?, boolean?, object?]
    ): void {
        if (!(this.data.config[fieldName] instanceof this.MessageBase)) {
            this.setNewChildByField(fieldName, ...args);
        }
    }

    /**
     * Updates the current config with new config data, and calls lifecycle hooks used to modify
     * data after loading.
     * @param newConfig - New config data.
     */
    private updateConfigItems(newConfig: object): void {
        this.data.config = this.data.config || {};

        const configItems = reduce(
            this.messageMap,
            this.getSetConfigDataReducer(newConfig),
            {},
        );

        this.data.config = {
            ...newConfig,
            ...configItems,
        };
    }

    /**
     * Calls a method on each MessageItem in the config and returns the resulting object as a new
     * config object.
     * @param config
     * @param methodName - Name of the method to call.
     */
    private childConfigItemMap(
        config: TMessageItemTreeConfig,
        methodName: string,
    ): TMessageItemTreeConfig {
        // If config is null or undefined, just return it back.
        if (!config) {
            return config;
        }

        const configItemsHash = reduce(
            this.messageMap,
            (acc, messageMapProps, field) => {
                if (!isUndefined(config[field])) {
                    acc[field] = config[field][methodName]();
                }

                return acc;
            },
            {},
        );

        return {
            ...config,
            ...configItemsHash,
        };
    }

    /**
     * Call a method on every child MessageItem in config.
     */
    private configMessageItemsCall(methodName: string): void {
        this.childConfigItemMap(this.getConfig(), methodName);
    }

    /**
     * Returns a clone of each child MessageItem.
     */
    private getConfigCopy(): TMessageItemTreeConfig {
        return this.childConfigItemMap(this.getConfig(), 'clone');
    }

    /**
     * Reducer used when updating or creating new MessageItems.
     * @param newConfig - New config object to update the current config with.
     */
    private getSetConfigDataReducer(newConfig: TItemConfig) {
        return (
            configItems: TMessageItemsHash,
            messageMapProps: IMessageMapProps,
            field: string,
        ) => {
            const { isRepeated } = messageMapProps;
            const newValue = newConfig[field];

            if (isUndefined(newValue) && !isRepeated && !this.requiredFields().includes(field)) {
                return configItems;
            }

            if (this.getConfig()[field] instanceof this.MessageBase) {
                const configItem = this.getConfig()[field];

                configItem.updateConfig(newValue);
                configItems[field] = configItem;

                return configItems;
            }

            configItems[field] = this.createChildByField_(field, newValue);

            return configItems;
        };
    }
}

ObjectTypeItem.ajsDependencies = [
    'MessageBase',
    'RepeatedMessageItem',
];
