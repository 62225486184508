/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'cloud-vrf-context-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setCloudVrfContextHeader = `${prefix}.setCloudVrfContextHeader`;
export const cloudSelectLabel = `${prefix}.cloudSelectLabel`;
export const cloudSelectPlaceholder = `${prefix}.cloudSelectPlaceholder`;
export const vrfContextSelectLabel = `${prefix}.vrfContextSelectLabel`;
export const vrfContextSelectPlaceholder = `${prefix}.vrfContextSelectPlaceholder`;
export const alertModalMessage = `${prefix}.alertModalMessage`;
export const submitButtonLabel = `${prefix}.submitButtonLabel`;

export const ENGLISH = {
    [setCloudVrfContextHeader]: 'Set Cloud & VRF',
    [cloudSelectLabel]: 'Cloud',
    [cloudSelectPlaceholder]: 'Select Cloud',
    [vrfContextSelectLabel]: 'VRF Context',
    [vrfContextSelectPlaceholder]: 'Select VRF Context',
    [alertModalMessage]: 'Once set Cloud and VRF Context cannot be changed',
    [submitButtonLabel]: 'Set',
};
