/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cluster';
const componentName = 'cluster-node-grid';
const prefix = `${moduleName}.${componentName}`;

export const addLabel = `${prefix}.addLabel`;
export const editLabel = `${prefix}.editLabel`;
export const removeLabel = `${prefix}.removeLabel`;
export const nameLabel = `${prefix}.nameInputLabel`;
export const ipLabel = `${prefix}.ipLabel`;
export const publicIpOrNameLabel = `${prefix}.publicIpOrNameLabel`;
export const indexLabel = `${prefix}.indexLabel`;

export const ENGLISH = {
    [addLabel]: 'Add',
    [editLabel]: 'Edit',
    [removeLabel]: 'Remove',
    [nameLabel]: 'Name',
    [ipLabel]: 'Cluster IP',
    [publicIpOrNameLabel]: 'Public IP Or Host Name',
    [indexLabel]: 'Index',
};
