/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './AlertGridConfig.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * This factory is for sharing a single code base for creating alert grid configs
 */
angular.module('aviApp').factory('alertGridConfig', [
'$filter', 'AlertConfig', 'eventGridConfig', '$templateCache', 'Auth', 'l10nService',
function($filter, AlertConfig, eventGridConfig, $templateCache, Auth, l10nService) {
    l10nService.registerSourceBundles(dictionary);

    /**
     * Returns regular grid config for metrics info within alert info drawer.
     * @returns {Grid#config}
     * @inner
     */
    function metricsGridConfigGenerator() {
        return {
            fields: [{
                title: l10nService.getMessage(l10nKeys.columnTitleMetric),
                name: 'metric_id',
                sortBy: 'metric_id',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleValue),
                name: 'value',
                sortBy: 'value',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleReportTime),
                name: 'report_timestamp',
                sortBy: 'report_timestamp',
                template: '{{row.report_timestamp | noYearTime}}',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleEndTime),
                name: 'end_timestamp',
                template: '{{row.end_timestamp | noYearTime}}',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleStep),
                name: 'step',
                template: '{{row.step + "s"}}',
            }],
            rowId(row) {
                return ['metric_id', 'value', 'report_timestamp', 'end_timestamp', 'step'].reduce(
                    function(base, propertyName) {
                        return `${base + row[propertyName]}-`;
                    }, '',
                ).slice(0, -1);
            },
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
            },
        };
    }

    return function(collection, options) {
        if (!collection) {
            console.error('alertGridConfig requires a collection');
        }

        const defaultConfig = {
            props: {
                l10nKeys,
            },
            fields: [{
                title: l10nService.getMessage(l10nKeys.columnTitleTimestamp),
                name: 'timestamp', //className
                template: '{{::row.getConfig().timestamp | noYearTime}}',
                sortBy: 'timestamp',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleResourceName),
                name: 'obj_name',
                transform: row => row.getObjName(),
                sortBy: 'obj_ref',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleLevel),
                name: 'level',
                template:
                    '<item-alert-level-icon level="row.level"></item-alert-level-icon>' +
                    '<span>{{ ::row.level }}</span>',
                sortBy: 'level',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleSummary),
                name: 'data.config.summary',
            }],
            defaultSorting: 'timestamp',
            multipleactions: [{
                title: l10nService.getMessage(l10nKeys.actionTitleDelete),
                do(rows) {
                    this.config.collection.dropItems(_.pluck(rows, 'id'));
                },
            }],
            collection,
            executeBeforeContainerExpand(row) {
                this.eventsGridConfig = undefined;
                this.metricsGridConfig = undefined;

                if ('events' in row.data.config) {
                    this.eventsGridConfig = this.config.getEventsGridConfig();
                    this.eventsGridConfig.id = 'events-grid-list-page';
                }

                if ('metric_info' in row.data.config) {
                    this.metricsGridConfig = this.config.getMetricsGridConfig();
                    this.metricsGridConfig.id = 'metrics-grid-list-page';
                }
            },
            expandedContainerTemplate: require('./alert-config-event.partial.html'),
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.actionTitleExpand),
                dontCloseExpander: true,
                do(row, index, event) {
                    this.config.onRowClick(row, event);
                },
            }],
            actions: {
                configAlert(ref) {
                    new AlertConfig({ id: ref.slug() }).edit();
                },
            },
            checkboxDisable(row) {
                return row.data.config.type_tmp === 'discovery' ||
                    !Auth.isPrivilegeAllowed('PERMISSION_ALERT', 'WRITE_ACCESS');
            },
            layout: {
                hideEditColumns: true,
            },
            getEventsGridConfig: eventGridConfig,
            getMetricsGridConfig: metricsGridConfigGenerator,
        };

        return angular.extend(defaultConfig, options);
    };
}]);
