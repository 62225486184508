/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { CRLConfigItem } from 'ajs/modules/security';
import { L10nService } from '@vmw/ngx-vip';
import './pki-certificate-revocation-list-modal.component.less';
import * as l10n from './pki-certificate-revocation-list-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Enum for Certificate Revocation List modifications methods.
 * CRL can be added by server url or crl file upload.
 */
enum CrlAdditionMethodType {
    SERVER_URL = 'SERVER_URL',
    FILE_UPLOAD = 'FILE_UPLOAD',
}

/**
 * @description Component for PKI Certificate Revocation List(CRL) modal.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'pki-certificate-revocation-list-modal',
    templateUrl: './pki-certificate-revocation-list-modal.component.html',
})
export class PkiCertificateRevocationListModalComponent implements OnInit {
    /**
     * CRLConfigItem instance.
     */
    @Input()
    public editable: CRLConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<CRLConfigItem>();

    /**
     * CRL object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Content of selected Certificate Revocation List(CRL) file.
     */
    public crlFileContent: string;

    /**
     * Layout for PKI Certificate Revocation List modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Certificate Revocation list method types.
     */
    public readonly CrlAdditionMethodType = CrlAdditionMethodType;

    /**
     * Add CRL by SERVER_URL is the default options for user.
     */
    public crlAdditionMethod: CrlAdditionMethodType = this.CrlAdditionMethodType.SERVER_URL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Function to fetch CRL data from server.
     */
    public onCrlServerLookup(url: string): void {
        this.editable.fetchCrlData(url);
    }

    /**
     * Handles Certificate Revocation List(CRL) file upload.
     */
    public onCrlFileUpload(): void {
        this.editable.updateCrlData(this.crlFileContent);
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Handles file selection change event.
     * Clears previous CRL data if selected file is changed or removed.
     */
    public handleFileSelectChange(): void {
        this.editable.clearCrlData();
    }
}
