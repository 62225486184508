/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    copy,
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    StringService,
    SystemInfoService,
} from 'ajs/modules/core/services';

import { UpdatableItem } from 'ajs/modules/data-model/factories/updatable-item.factory';

import {
    IGslbPool,
    IGslbPoolMember,
    IGslbService,
    IIpAddr,
    IpAddrType,
} from 'generated-types';

import {
    any,
    compact,
    each,
    isEmpty,
    isNumber,
    isObject,
    isUndefined,
    keys,
    map,
    pluck,
    reject,
    uniq,
} from 'underscore';

import { GSLB } from './gslb.item.factory';
import { GSLBServiceCollection } from './gslb-service.collection.factory';

interface IGslbServiceArgs {
    gslb?: GSLB;
}

interface IGslbServiceData {
    config: any;
}

interface ISplittedDomainName {
    appDomainName: string;
    subdomain: string;
}

interface IExtendedGslbPoolMember extends IGslbPoolMember {
    priority_: any;
}

interface IExtendedGslbPool extends IGslbPool {
    id: number;
    config: IGslbPool;
}

/**
 * @description
 *
 *     Item as of protobuf GslbService message. Needs a reference to GSLB it belongs to.
 *
 * @author Alex Malitsky, Zhiqian Liu
 */
export class GSLBService extends UpdatableItem {
    public getConfig: () => any;
    public getDefaultConfig_: () => IGslbService;
    public loadMetrics: (fields: string[]) => any;
    public readonly gslb?: GSLB;
    public data: IGslbServiceData;
    protected collection: GSLBServiceCollection;
    protected opener: GSLBService;
    private readonly systemInfo: SystemInfoService;

    constructor(args: IGslbServiceArgs = {}) {
        super(args);

        if ('gslb' in args) {
            this.gslb = args.gslb;
        }

        this.systemInfo = this.getAjsDependency_('systemInfoService');
    }

    /**
     * Since a GSLB pool member doesn't have a unique identifier field, we locate one with its
     * parent pool name and its IP address.
     * This method is made static since it's also used by GSLB pool member data transformer to build
     * data from API response.
     */
    public static getPoolMember(
        config: IGslbService,
        poolName: string,
        ip: IIpAddr,
    ): IGslbPoolMember | undefined {
        const { groups: gslbPools } = config;
        const targetPool = gslbPools.find(({ name }: IGslbPool) => name === poolName);

        if (!targetPool) {
            return undefined;
        }

        const isIPEqual = ({ addr: addr1 }: IIpAddr, { addr: addr2 }: IIpAddr): boolean => {
            return addr1 === addr2;
        };

        return targetPool.members.find(
            ({ ip: memberIp }: IGslbPoolMember) => isIPEqual(memberIp, ip),
        );
    }

    /**
     * Concatenates subdomain and domain name into one string.
     */
    private static domainNameBeforeSave({ appDomainName, subdomain }: ISplittedDomainName): string {
        const fqdnJoin = this.getAjsDependency_('fqdnJoin');

        return fqdnJoin(appDomainName, subdomain);
    }

    /** @override */
    public isEditable(): boolean {
        const gslb = this.getGSLB();
        const isGslbLoaded = gslb && gslb.hasConfig();

        if (super.isEditable() && isGslbLoaded) {
            // Edit is allowed for leaders by default
            // Incase of followers, edit is allowed if `enableConfigByMembers` prop is true.
            return this.systemInfo.localSiteIsGSLBLeader || gslb.enableConfigByMembers;
        }

        return false;
    }

    /**
     * Check the write permission of PERMISSION_GSLBSERVICE to modify `enabled` field of a pool
     * member.
     */
    public isPoolMemberEnableWritable(): boolean {
        // this.isEdiable() is an override with check on GSLB item existence and in turns it checks
        // on W/R permission of GSLB, so isEditable() from Item class is used
        return super.isEditable();
    }

    /** @override */
    public isProtected(): boolean {
        return !this.systemInfo.localSiteIsGSLBLeader || super.isProtected();
    }

    /** @override */
    public loadRequest(fields: string[]): IPromise<any> {
        return Promise.all([
            this.loadConfig(),
            this.loadMetrics(fields),
        ]);
    }

    /**
     * Get method for GSLB instance this GSLBService belongs to.
     * //TODO mb it is better to pass gslb ref into GslbService item on create/edit/append
     */
    public getGSLB(): GSLB {
        if ('gslb' in this) {
            return this.gslb;
        } else if (this.collection) {
            return this.collection.gslb;
        } else if (this.opener && 'gslb' in this.opener) {
            return this.opener.gslb;
        }
    }

    /**
     * Checks whether Item is in enabled (default) state.
     */
    public isEnabled(): boolean {
        const { enabled } = this.getConfig();

        return Boolean(enabled);
    }

    /**
     * Makes a PATCH request to set enabled state to the passed value.
     */
    public setEnabledState(enabled: boolean): IPromise<IHttpResponse<void>> {
        return this.patch({ replace: { enabled } });
    }

    /** @override */
    public beforeEdit(): void {
        const config = this.getConfig();

        ['domain_names', 'health_monitor_refs', 'groups']
            .forEach((fieldName: string) => {
                if (!(fieldName in config)) {
                    config[fieldName] = [];
                }
            });

        const { groups } = config;
        const gslbLocationAfterLoad = this.getAjsDependency_('gslbLocationAfterLoad');

        if (groups) {
            groups.forEach(({ members }: IGslbPool) => {
                if (members) {
                    members.forEach(({ location }: IGslbPoolMember) => {
                        gslbLocationAfterLoad(location);
                    });
                }
            });
        } else {
            config.groups = [];
        }

        if (!config.domain_names.length) {
            const gslb = this.getGSLB();

            config.domain_names.push(
                this.domainNameBeforeEdit(gslb ? gslb.getDefaultDNSDomainName() : ''),
            );
        } else {
            config.domain_names = config.domain_names
                .map((domainName: string) => this.domainNameBeforeEdit(domainName));
        }
    }

    /** @override */
    public dataToSave(): IGslbService {
        const config = copy(this.getConfig());

        ['health_monitor_refs', 'groups'].forEach((fieldName: string) => {
            config[fieldName] = compact(config[fieldName]); // removes falsy values

            if (!config[fieldName].length) {
                config[fieldName] = undefined;
            }
        });

        config.domain_names = reject(config.domain_names,
            domainName => !domainName.appDomainName);

        if (!config.domain_names.length) {
            config.domain_names = undefined;
        } else {
            config.domain_names = config.domain_names
                .map((domainName: ISplittedDomainName) => GSLBService
                    .domainNameBeforeSave.call(this, domainName));
        }

        if (config.groups) {
            config.groups.forEach((group: IGslbPool) => {
                group.members.forEach((member: IGslbPoolMember) => {
                    const stringService: StringService = this.getAjsDependency_('stringService');

                    if (member.vs_uuid) {
                        member.vs_uuid = stringService.slug(member.vs_uuid);
                    }

                    ['isSetByIp', 'priority_'].forEach(fieldName => {
                        member[fieldName] = undefined;
                    });
                });
            });
        }

        return config;
    }

    /**
     * Returns default GslbPoolConfig and sequential array index within GslbServiceConfig#groups
     * to be saved to.
     */
    public createPool(newGslbPoolConfig: IGslbPool): IExtendedGslbPool {
        const config = this.getConfig();
        const defGslbPoolConfig = this.getDefaultPoolConfig();

        if (config) {
            return {
                id: config.groups.length,
                config: Object.assign(
                    defGslbPoolConfig,
                    {
                        members: [this.getDefaultPoolMemberConfig()],
                        priority: undefined,
                    },
                    newGslbPoolConfig,
                ),
            };
        }
    }

    /**
     * Returns copied GslbPoolConfig of a passed index (or found by config itself) and
     * index value. Kinda Item#beforeEdit method for GslbPool.
     */
    public editPool(poolConfig: IGslbPool): IExtendedGslbPool {
        const index = this.getPoolIndex(poolConfig);

        if (this.data && !isUndefined(index)) {
            poolConfig = copy(this.data.config.groups[index]);

            poolConfig.members.forEach((member: any) => {
                member.isSetByIp = !('vs_uuid' in member);

                if (!('ip' in member)) {
                    member.ip = {
                        addr: '',
                        type: IpAddrType.V4,
                    };
                }
            });

            return {
                id: index,
                config: poolConfig,
            };
        }
    }

    /**
     * Puts a GslbPool into a passed position of GslbServiceConfig#groups array. Kinda
     * Item#dataToSave method for GslbPool.
     */
    public appendPool(poolConfig: IGslbPool, index: number): void {
        if (this.data) {
            const { groups } = this.getConfig();

            if (isObject(poolConfig) && isNumber(index) &&
                index >= 0 && index <= groups.length) {
                groups[index] = poolConfig;
            }
        }
    }

    /**
     * Removes a passed (by config itself or array index) GslbPoolConfig from GslbServiceConfig.
     */
    public dropPool(poolConfig: IGslbPool): void {
        if (this.data) {
            const { groups } = this.getConfig();
            let index;

            if (isNumber(poolConfig) && poolConfig >= 0 && poolConfig < groups.length) {
                index = poolConfig;
            } else {
                index = groups.indexOf(poolConfig);
            }

            if (index !== -1) {
                groups.splice(index, 1);
            }
        }
    }

    /**
     * Checks whether a passed GslbPoolConfig confirms to the existent GslbServiceConfig
     * and it's requirements - basically we verify that name and priority properties are unique
     * among all GslbPools within the GslbServiceConfig.
     */
    public checkEditablePool(poolConfig: IGslbPool, indexToExclude: number): boolean {
        let isValid = false;

        if (this.data) {
            const { name } = poolConfig;

            if (name) {
                const checkIndex = isNumber(indexToExclude) &&
                indexToExclude >= 0 && indexToExclude < this.getConfig().groups.length;

                // looking for the pool with same name and different index
                isValid = poolConfig.members.length &&
                    !any(this.getConfig().groups, (poolConfig, index) => {
                        return poolConfig.name === name &&
                            (!checkIndex || indexToExclude !== index);
                    });
            }
        }

        return isValid;
    }

    /**
     * Set the 'enabled' field of a GSLB pool member with a new value.
     */
    public setPoolMemberEnableState(
        poolName: string,
        poolMemberIp: IIpAddr,
        newState: boolean,
    ): void {
        const poolMember = GSLBService.getPoolMember(this.getConfig(), poolName, poolMemberIp);

        if (poolMember) {
            poolMember.enabled = newState;
        }
    }

    /**
     * Checks one faked active-standby GslbPoolConfig which will be translated into many pools
     * with a single member each. Used by basic create and `active-standby` only.
     */
    public checkEditableActiveStandbyPool(poolConfig: IGslbPool): boolean {
        let isValid = false;

        if (this.data) {
            isValid = !this.getConfig().groups.length && poolConfig.members.length &&
                uniq(pluck(poolConfig.members, 'priority_')).length ===
                poolConfig.members.length;
        }

        return isValid;
    }

    /**
     * For basic create `active-standby` only from one GslbPoolConfig with many members we make
     * corresponding number of pools with only one member each. Made so to be able to reuse
     */
    public appendActiveStandbyPool(poolConfig: IGslbPool): void {
        poolConfig.members.forEach((member: IExtendedGslbPoolMember, index: number) => {
            const newPool = this.createPool({
                name: `${poolConfig.name}-${index + 1}`,
                members: [member],
                /* eslint no-underscore-dangle: 0 */
                priority: member.priority_,
            });

            this.appendPool(newPool.config, newPool.id);
        });
    }

    /**
     * Returns the default GslbPoolMemberConfig configuration.
     */
    public getDefaultPoolMemberConfig() : IGslbPoolMember {
        const { members } = this.getDefaultPoolConfig();
        const memberDefaultConfig = members && members[0];

        return Object.assign(memberDefaultConfig, {
            ip: {
                addr: '',
                type: 'V4',
            },
            isSetByIp: false,
        });
    }

    /**
     * Since object model keeps {GslbPoolMember#vs_uuid} not as ref but bare uuid we need to go over
     * all GslbPools, pick GslbSites and vsIds used and load their names and ips.
     */
    public getPoolMemberVsData(): Promise<Record<string, any>> {
        let promise;

        if (this.data) {
            // clusterId: {names: {vsId1: name, vsId2: name}, ips: {vsId1: [ipAddr]},
            // collection: vsCollection}
            const hash: Record<string, any> = {};
            const config = this.getConfig();
            const GSLBVSCollection = this.getAjsDependency_('GSLBVSCollection');

            this.busy = true;

            config.groups.forEach((group: IGslbPool) => {
                group.members.forEach((member: IGslbPoolMember) => {
                    const { cluster_uuid: clusterId } = member;
                    const vsId = member.vs_uuid;

                    if (clusterId && vsId) {
                        if (!(clusterId in hash)) {
                            hash[clusterId] = {
                                names: {},
                                ips: {},
                            };
                        }

                        // name placeholder
                        hash[clusterId].names[this.stringService.slug(vsId)] = '';
                    }
                });
            });

            each(hash, (data: any, gslbSiteId: string) => {
                if (!isEmpty(data.names)) {
                    data.collection = new GSLBVSCollection({
                        gslbSiteId,
                        limit: 1000,
                        params: {
                            'uuid.in': keys(data.names).join(),
                            fields: ['vsvip_ref', 'vh_parent_vs_ref', 'type'].join(),
                            join: 'vsvip_ref',
                        },
                    });
                }
            });

            promise = Promise.all(
                map(hash, (data: any) => data.collection && data.collection.load()),
            ).then(() => {
                // put names and ips in place
                each(hash, (data: any, clusterId: string) => {
                    each(data.names, (emptyName: string, vsId: string) => {
                        const vs = data.collection.getItemById(vsId);

                        if (vs) {
                            data.names[vsId] = vs.getName();

                            // Handle vs potentially being a childVS
                            if (vs.isVHChild()) {
                                const headerParam = {
                                    headers_: { 'X-Avi-Internal-GSLB': clusterId },
                                };

                                vs.addParams(headerParam);
                                vs.getVHParentIPs('allIPs')
                                    .then((parentIPs: string[]) => data.ips[vsId] = parentIPs)
                                    .catch(console.error);
                            } else {
                                data.ips[vsId] = vs.getIPAddresses('allIPs');
                            }
                        }
                    });
                });

                return hash;
            }).finally(() => {
                this.busy = false;
                each(
                    hash,
                    (data: any) => data.collection && data.collection.destroy(),
                );
            });
        } else {
            promise = Promise.reject(new Error('Config is not ready'));
        }

        return promise;
    }

    /**
     * Returns an array of domain names used by this GSLBService.
     */
    public getDomainNames() : string[] {
        const { domain_names: domainNames } = this.getConfig();

        return domainNames.concat();
    }

    /**
     * Parses full domain name into an object expected by edit modal. Full domain names consists
     * of subdomain as well as top most level domain name supported by GSLB.
     */
    public domainNameBeforeEdit(fullDomainName: string): ISplittedDomainName {
        let appDomainName = '';
        let subdomain = '';

        if (fullDomainName) {
            [appDomainName, subdomain] = this.splitDomainNameField(fullDomainName);
        }

        return {
            appDomainName,
            subdomain,
        };
    }

    /**
     * Returns default configuration of the nested GslbPool.
     */
    protected getDefaultPoolConfig(): IGslbPool {
        const { groups } = this.getDefaultConfig_();

        return groups && groups[0] || {};
    }

    /**
     * Returns a position of a passed GslbPoolConfig in GslbServiceConfig#groups array.
     */
    private getPoolIndex(poolConfig: IGslbPool): number | undefined {
        const index = this.getConfig().groups.indexOf(poolConfig);

        return index !== -1 ? index : undefined;
    }

    /**
     * Since we present domain in forms as two inputs we need a way to split them on load.
     */
    private splitDomainNameField(fullDomainName: string): string[] {
        const fqdnSplit = this.getAjsDependency_('fqdnSplit');
        const gslb = this.getGSLB();

        return fqdnSplit(fullDomainName, gslb && gslb.getDNSDomainNames());
    }
}

Object.assign(GSLBService.prototype, {
    objectName: 'gslbservice',
    windowElement: 'app-gslb-service-edit',
});

GSLBService.ajsDependencies = [
    'GSLBVSCollection',
    'systemInfoService',
    'fqdnSplit',
    'fqdnJoin',
    'gslbLocationAfterLoad',
    'stringService',
];
