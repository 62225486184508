/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './waf-exceptions-modal.less';
import * as l10n from './waf-exceptions-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafExceptionsModalComponent {
    constructor(
        getSubnetObject,
        getSubnetString,
        Regex,
        l10nService,
    ) {
        this.getSubnetObject = getSubnetObject;
        this.getSubnetString = getSubnetString;
        this.Regex = Regex;

        this.exclusions = [];
        this.subnet = '';
        this.uriPath = '';
        this.wafGroup = null;
        this.wafRule = null;
        this.wafLogRules = null;
        this.excludeList = [];
        this.errors = null;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const {
            wafGroup,
            wafRule,
            wafLogRules,
            uriPath,
            subnet = '',
            exclusions,
            overrideExcludeList,
        } = this;

        if (overrideExcludeList) {
            this.excludeList = overrideExcludeList;
        } else {
            const groupConfig = wafGroup.getConfig();

            this.excludeList = groupConfig.exclude_list;

            if (wafRule) {
                const ruleConfig = wafRule.getConfig();

                this.excludeList = ruleConfig.exclude_list;
            }
        }

        // prevent duplicated inputs
        const dupHash = {};

        if (Array.isArray(wafLogRules)) {
            wafLogRules.forEach(rule => {
                if (Array.isArray(rule.matches)) {
                    rule.matches.forEach(match => {
                        const matchElement = match.match_element;

                        if (!match.is_internal &&
                                !this.hasExclusion(matchElement, uriPath, subnet)) {
                            const hash = matchElement + uriPath + subnet;

                            if (!(hash in dupHash)) {
                                dupHash[hash] = 0;
                                exclusions.push({
                                    client_subnet: this.getSubnetObject(subnet),
                                    uri_path: uriPath,
                                    match_element: matchElement,
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    /**
     * Checks if any WAF Group has similar exclusion params.
     * @param {string} matchElement
     * @param {string} uriPath
     * @param {string} clientSubnet
     * @returns {boolean}
     */
    hasExclusion(matchElement, uriPath, clientSubnet) {
        if (Array.isArray(this.excludeList)) {
            let excl;
            let cfg;

            for (let i = 0; i < this.excludeList.length; i++) {
                excl = this.excludeList[i];
                cfg = excl.getConfig();

                if (cfg.match_element === matchElement &&
                        cfg.uri_path === uriPath &&
                        this.getSubnetString(cfg.client_subnet) === clientSubnet) {
                    return true;
                }
            }
        }

        return false;
    }

    removeExclusion(index = 0) {
        this.exclusions.splice(index, 1);
    }

    submit() {
        if (this.exclusions.length > 0 && !this.overrideExcludeList) {
            const configItem = this.wafRule || this.wafGroup;

            this.exclusions.forEach(e => configItem.addExcludeListEntry(e));
        }

        this.errors = null;

        this.saveWafPolicy({
            wafGroup: this.wafGroup,
            wafRule: this.wafRule,
            exclusions: this.exclusions,
        })
            .then(() => this.closeModal())
            .catch(rsp => this.errors = rsp.data);
    }

    cancel() {
        this.onCancel();
        this.closeModal();
    }
}

WafExceptionsModalComponent.$inject = [
    'getSubnetObject',
    'getSubnetString',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name wafExceptionsModal
 * @param {WafRuleGroupConfig} wafGroup
 * @param {WafRuleConfig} wafRule
 * @param {Array} wafLogRules - WafRuleLog rule_logs
 * @param {string} subnet - Exception subnet.
 * @param {string} uriPath - Exception uriPath.
 * @param {RepeatedMessageItem} overrideExcludeList - Exclude list repeatedMessageItem belonging to
 *     CRS overrides.
 * @param {Function} saveWafPolicy - Called to save exceptions on the WafPolicy
 * @param {Function} closeModal - Call to close the modal.
 * @param {Function} onCancel - Called when close button is clicked.
 * @param {Function} onSubmit - Called when user clicks the submit button.
 * @description
 *     Modal component for managing WAF Group Exceptions in VS Log.
 */
angular.module('avi/waf').component('wafExceptionsModal', {
    controller: WafExceptionsModalComponent,
    bindings: {
        wafGroup: '<',
        wafRule: '<?',
        wafLogRules: '<',
        subnet: '<',
        uriPath: '<',
        overrideExcludeList: '<',
        saveWafPolicy: '&',
        closeModal: '&',
        onCancel: '&',
        onSubmit: '&',
    },
    templateUrl: 'src/components/modals/waf-exceptions-modal/waf-exceptions-modal.component.html',
});
