/**
 * Module for NAT Policy related items and components
 * @module NatModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isNaN, isUndefined } from 'underscore';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { NatRuleConfigItem } from '.';

interface INatPolicyConfig {
    rules: RepeatedMessageItem<NatRuleConfigItem>;
}

export class NatPolicy extends ObjectTypeItem {
    // overriding getConfig()'s return type
    public getConfig: () => INatPolicyConfig;

    constructor(args: {}) {
        const extendedArgs = {
            objectType: 'NatPolicy',
            permissionName: 'PERMISSION_NATPOLICY',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns a new NatRule ConfigItem instance.
     */
    public createNewRule(config: {} = null): MessageItem {
        return this.createChildByField_('rules', config, true) as MessageItem;
    }

    /**
     * Adds a rule to the list of rules. If the rule has an index, it means that an existing
     * rule was modified so the existing rule should be replaced. If not, add the new rule to
     * the end of the list.
     */
    public addRule(rule: NatRuleConfigItem): void {
        const { rules } = this.getConfig();
        const indexField = rule.getIndex();

        // New rule doesn't have an index.
        if (isUndefined(indexField)) {
            const maxIndex = rules.getMaxIndex();
            const newIndex = isNaN(maxIndex) ? 0 : maxIndex + 1;

            rule.setIndex(newIndex);
            rules.add(rule);
        } else {
            const arrayIndex = rules.getArrayIndexWithIndexField(indexField as any as number);

            rules.config[arrayIndex] = rule;
        }
    }

    /**
     * Getter for the number of rules in policy.
     */
    public get rulesCount(): number {
        return this.getConfig().rules.count;
    }
}

Object.assign(NatPolicy.prototype, {
    objectName: 'natpolicy',
    windowElement: 'nat-policy-modal',
});
