/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import { GslbGeoDbProfileCollection } from 'ajs/modules/gslb';
import { GSLB_GEODB_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/gslb/gslb.tokens';

const $injector = '$injector';

export const gslbGeoDbProfileCollectionProvider = {
    deps: [$injector],
    provide: GslbGeoDbProfileCollection,
    useFactory(injector: Injector): typeof GslbGeoDbProfileCollection {
        return injector.get(GSLB_GEODB_PROFILE_COLLECTION_TOKEN);
    },
};
