/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './NetworkProfileListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Network Profile List Controller function.
 */
function NetworkProfileListController(
    $scope,
    CRUDGridConfig,
    NetworkProfileCollection,
    l10nService,
    schemaService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    const networkProfileCollection = new NetworkProfileCollection();

    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    $scope.gridConfig = new CRUDGridConfig();

    $scope.collection = networkProfileCollection;

    $scope.gridConfig.collection = $scope.collection;

    $scope.gridConfig.fields = [
        {
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            name: 'name',
            template: '<span class="sel-name"> {{row.getConfig().name}} </span>',
            sortBy: 'name',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleType),
            name: 'type',
            template: `
            <span class="sel-name">
                {{row.getProfileTypeLabel(row.getConfig().profile.config.type)}}
            </span>`,
        }, {
            name: 'auto',
            title: l10nService.getMessage(l10nKeys.columnTitleAutoLearn),
            template: `
            <span class="sel-automatic"
                ng-if="row.getConfig().profile.config.type=='PROTOCOL_TYPE_TCP_PROXY'
                && row.getConfig().profile.config.tcp_proxy_profile.config.automatic">
                ${l10nService.getMessage(l10nKeys.yesLabel)}
            </span>`,
        },
    ];

    const { objectName } = $scope.gridConfig.collection;

    $scope.gridConfig.id = `${objectName}-list-page`;

    $scope.gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnEdit),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];

    const networkProfileEnumValues = schemaService.getEnumValues('ProtocolType');

    $scope.gridConfig.createActions = networkProfileEnumValues.map(({ label, value }) => ({
        label,
        onClick: () => {
            networkProfileCollection.setDefaultItemConfigProps({
                profile: {
                    type: value,
                },
            });

            networkProfileCollection.create();
        },
    }));

    $scope.$on('$destroy', () => $scope.collection.destroy());
}

NetworkProfileListController.$inject = [
    '$scope',
    'CRUDGridConfig',
    'NetworkProfileCollection',
    'l10nService',
    'schemaService',
];

/**
 * @ngdoc controller
 * @name  NetworkProfileListController
 * @description Controller for Network profile list.
 */
angular.module('aviApp').controller('NetworkProfileListController', NetworkProfileListController);

