/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import { IHttpResponse } from 'angular';

import {
    Component,
    Inject,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import {
    each,
    intersection,
    some,
    sortBy,
} from 'underscore';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import { anyIPIPSubnetIPv4RangeList } from 'ng/utils/regex.utils';
import { AviContinueConfirmationComponent } from 'ng/modules/dialog';
import { DialogService } from 'ng/modules/core';
import { IpAddrGroup } from 'object-types';

import {
    IpAddrGroupItem,
    IServer,
} from 'ajs/modules/groups';

import {
    ICountryCode,
    IGeoIpDbApiResult,
} from '../..';

import { IpAddrGroupModalService } from '../../services';

import './ip-addr-group-modal.component.less';

import * as l10n from './ip-addr-group-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum IpGroupCreateType {
    IP = 'ip',
    COUNTRY_CODE = 'country_code',
}

/**
 * ID for IP address Group change confirmation dialog.
 */
const IP_ADDR_GROUP_TYPE_CHANGE_DIALOG_ID = 'ip-addr-group-type-change-confirmation';

/**
 * @description Component for IP Address Group create/edit modal.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ip-addr-group-modal',
    templateUrl: './ip-addr-group-modal.component.html',
    providers: [
        IpAddrGroupModalService,
    ],
})
export class IpAddrGroupModalComponent implements OnInit {
    /**
     * IpAddrGroup ObjectTypeItem.
     */
    @Input()
    public editable: IpAddrGroupItem;

    /**
     * IpAddrGroup object type.
     */
    public readonly objectType = IpAddrGroup;

    /**
     * Layout for IP Group modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Stores all IP addresses in modal's grid in single array format.
     */
    public ipAddresses: IServer[] = [];

    /**
     * Dropdown options for type field (values from custom enum- IpGroupCreateType).
     */
    public groupTypeOptions: IAviDropdownOption[] = [];

    /**
     * Dropdown options for selecting Country Code.
     */
    public countryCodeOptions: IAviDropdownOption[] = [];

    /**
     * Stores IP group creation type.
     * User can select between IP Address and Country Code to create IP group.
     */
    public selectedGroupType: IpGroupCreateType;

    /**
     * Custom enum IpGroupCreateType to access in template.
     */
    public readonly IpGroupCreateType = IpGroupCreateType;

    /**
     * Stores content of file uploaded.
     */
    public ipListFile: string;

    /**
     * Count of valid IPs uploaded through file.
     */
    public uploadedIpCount = 0;

    /**
     * Count of duplicate IPs uploaded through file.
     */
    public duplicateIpCount = 0;

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    /**
     * Separate from editable.errors, this holds errors other than those on the Item.
     */
    private modalErrors: string;

    public constructor(
        private readonly l10nService: L10nService,
        private readonly ipAddrGroupModalService: IpAddrGroupModalService,
        private readonly dialogService: DialogService,
        @Inject('RangeParser')
        private rangeParser: any,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.groupTypeOptions = [
            createDropdownOption(IpGroupCreateType.IP,
                l10nService.getMessage(l10nKeys.ipGroupCreateTypeIpLabel)),
            createDropdownOption(IpGroupCreateType.COUNTRY_CODE,
                l10nService.getMessage(l10nKeys.ipGroupCreateTypeCountryCodeLabel)),
        ];
    }

    /** @override */
    public ngOnInit(): void {
        this.resetType();
        this.populateCountryCodes();

        this.ipAddresses = this.editable.addresses;
    }

    /**
     * Check busy state, decided by editable busy state and component busy state.
     */
    public isBusy(): boolean {
        return this.editable.isBusy() || this.busy;
    }

    /**
     * Decides which type (item or modal) error to show.
     */
    public get errors(): IpAddrGroupItem['errors'] | string {
        return this.modalErrors || this.editable.errors;
    }

    /**
     * Called when file is uploaded.
     * Extracts valid IP addresses from file.
     */
    public importFile(): void {
        let containsValidIps = false;

        this.modalErrors = null;
        this.uploadedIpCount = 0;
        this.duplicateIpCount = 0;

        if (!this.ipListFile || this.ipListFile === '') {
            return;
        }

        each(this.ipListFile.split('\n'), addr => {
            addr = addr.trim();

            if (addr && addr.match(anyIPIPSubnetIPv4RangeList)) {
                this.formatAndAddIpAddr(addr);
                containsValidIps = true;
            }
        });

        if (!containsValidIps) {
            this.modalErrors =
                this.l10nService.getMessage(l10nKeys.fileHasNoValidIpAddressesErrorMessage);
        }
    }

    /**
     * Called on form submit.
     * Checks if country code present. If yes, clears the IP addresses from item,
     * else remove country code and translates the string representations of each
     * address type into message items to be added.
     */
    public updateConfig(): void {
        const { country_codes: countryCodes } = this.editable.getConfig();

        this.editable.removeIpAddresses();

        if (!countryCodes) {
            this.editable.removeCountryCodes();

            each(this.ipAddresses, (server: IServer) => {
                if (!server.addr) {
                    return;
                }

                server.addr.forEach((ip: string) => {
                    const rangeOrIp = this.rangeParser.ipRange2Json(ip.trim());

                    if (!rangeOrIp) {
                        return; // Not recognized piece
                    }

                    if (rangeOrIp.begin) { // assume this is range
                        this.editable.config.ranges.add(rangeOrIp);
                    } else if (rangeOrIp.mask) { // assume prefix
                        this.editable.config.prefixes.add(rangeOrIp);
                    } else { // otherwise ip
                        this.editable.config.addrs.add(rangeOrIp);
                    }
                });
            });
        }
    }

    /**
     * Fires on form submit.
     * Update values from modal to item object and then submit the form.
     */
    public submit(): void {
        this.updateConfig();
        this.editable.submit();
    }

    /**
     * Called to add an address entry in grid.
     */
    public addIpAddr(): void {
        this.ipAddresses.push({ addr: [] });
    }

    /**
     * Called to remove an address entry in grid.
     */
    public onRemoveIps(ipList: IServer[]): void {
        ipList.forEach((item: IServer) => {
            const index = this.ipAddresses.findIndex(ipAddr => {
                // if clear button clicked then value come as undefined.
                if (!item.addr) {
                    return !ipAddr.addr;
                }

                return ipAddr.addr.join(',') === item.addr.join(',');
            });

            this.ipAddresses.splice(index, 1);
        });
    }

    /**
     * Called on group type change. Check if values present in modal and show confirmation.
     */
    public onGroupTypeChange(): void {
        if (this.editable.config.country_codes || this.ipAddresses.length) {
            this.ipAddrGroupTypeChangeConfirmation();
        }
    }

    /**
     * Called when user confirm to reset the data after changing group type.
     */
    private clearData(): void {
        this.ipListFile = '';
        this.ipAddresses = [];
        this.editable.removeCountryCodes();
    }

    /**
     * Function to add unique IP address string to modals ip address list.
     */
    private formatAndAddIpAddr(addr: string): void {
        if (this.isAddressPresent(addr)) {
            this.duplicateIpCount++;
        } else {
            this.ipAddresses.push({
                addr: addr.split(','),
            });

            this.uploadedIpCount++;
        }
    }

    /**
     * Loads Country code dropdown using service call.
     */
    private populateCountryCodes(): void {
        this.busy = true;
        this.modalErrors = null;
        this.ipAddrGroupModalService.loadCountryCodes()
            .then(({ data }: IHttpResponse<IGeoIpDbApiResult>) => {
                this.setCountryCodeDropdownOptions(sortBy(data.country, 'name'));
            }).catch(({ data }) => {
                this.modalErrors = data;
            }).finally(() => this.busy = false);
    }

    /**
     * Returns true if address already exists in the list of server addresses.
     */
    private isAddressPresent(address: string): boolean {
        return some(this.ipAddresses, server => {
            return intersection(address.split(','), server.addr).length > 0;
        });
    }

    /**
     * Populate country code dropdown options.
     */
    private setCountryCodeDropdownOptions(data: ICountryCode[]): void {
        data.forEach(country => {
            this.countryCodeOptions.push(createDropdownOption(
                country.code,
                `${country.name} (${country.code})`,
            ));
        });
    }

    /**
     * Reset the selected dropdown option for 'type'.
     */
    private resetType(): void {
        if (this.editable.config.country_codes) {
            this.selectedGroupType = IpGroupCreateType.COUNTRY_CODE;
        } else {
            this.selectedGroupType = IpGroupCreateType.IP;
        }
    }

    /**
     * Displays stringGroupTypeChangeConfirmation dialog.
     */
    private ipAddrGroupTypeChangeConfirmation(): void {
        return this.dialogService.add({
            id: IP_ADDR_GROUP_TYPE_CHANGE_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                warning: this.l10nService.getMessage(l10nKeys.discardedCurrentValuesConfirmation),
                customHeader: this.l10nService.getMessage(l10nKeys.resetToDefaultValuesHeader),
                onConfirm: () => {
                    this.dialogService.remove(IP_ADDR_GROUP_TYPE_CHANGE_DIALOG_ID);
                    this.clearData();
                },
                onClose: () => {
                    this.dialogService.remove(IP_ADDR_GROUP_TYPE_CHANGE_DIALOG_ID);
                    this.resetType();
                },
            },
        });
    }
}
