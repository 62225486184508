/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'backup-passphrase';
const prefix = `${moduleName}.${componentName}`;

export const passphraseLabel = `${prefix}.passphraseLabel`;
export const passphrasePlaceholder = `${prefix}.passphrasePlaceholder`;
export const confirmPassphraseLabel = `${prefix}.confirmPassphraseLabel`;
export const confirmPassphrasePlaceholder = `${prefix}.confirmPassphrasePlaceholder`;

export const ENGLISH = {
    [passphraseLabel]: 'Passphrase',
    [passphrasePlaceholder]: 'Enter Passphrase',
    [confirmPassphraseLabel]: 'Confirm Passphrase',
    [confirmPassphrasePlaceholder]: 'Enter Confirm Passphrase',
};
