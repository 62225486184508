/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'ipam-dns-configuration';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const ipamProfileSelectLabel = `${prefix}.ipamProfileSelectLabel`;
export const ipamProfileSelectPlaceholder = `${prefix}.ipamProfileSelectPlaceholder`;
export const dnsProfileSelectLabel = `${prefix}.dnsProfileSelectLabel`;
export const dnsProfileSelectPlaceholder = `${prefix}.dnsProfileSelectPlaceholder`;
export const stateBasedDnsRegistrationCheckboxLabel = `${prefix}.stateBasedDnsRegistrationCheckboxLabel`;
export const dnsResolverLabel = `${prefix}.dnsResolverLabel`;

export const ENGLISH = {
    [sectionTitle]: 'IPAM/DNS',
    [ipamProfileSelectLabel]: 'IPAM Profile',
    [ipamProfileSelectPlaceholder]: 'Select IPAM Profile',
    [dnsProfileSelectLabel]: 'DNS Profile',
    [dnsProfileSelectPlaceholder]: 'Select DNS Profile',
    [stateBasedDnsRegistrationCheckboxLabel]: 'Enable State Based DNS Registration',
    [dnsResolverLabel]: 'DNS Resolvers ({0})',
};
