/**
 * @module UpdateModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrCheckboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrInputModule,
    ClrSpinnerModule,
    ClrTextareaModule,
} from '@clr/angular';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { VIPModule } from '@vmw/ngx-vip';
import { CoreModule } from 'ng/modules/core/core.module';

import { DialogModule } from '../dialog/dialog.module';

import {
    ComplianceModeConfirmationComponent,
    RecentUpdatesListComponent,
    SystemUpdateAboutCardComponent,
    UpdateCardAboutComponent,
    UpdateNodeStatusCardComponent,
    UpgradeTranscriptModalComponent,
} from './components';

const updateComponents = [
    ComplianceModeConfirmationComponent,
    RecentUpdatesListComponent,
    SystemUpdateAboutCardComponent,
    UpdateCardAboutComponent,
    UpdateNodeStatusCardComponent,
    UpgradeTranscriptModalComponent,
];

/**
 * Module containing all upgrade stuff (angular only).
 */
@NgModule({
    declarations: [
        ...updateComponents,
    ],
    exports: [
        RecentUpdatesListComponent,
        UpdateCardAboutComponent,
        UpgradeTranscriptModalComponent,
    ],
    imports: [
        AviFormsModule,
        ClrCheckboxModule,
        ClrDatagridModule,
        ClrDropdownModule,
        ClrInputModule,
        ClrSpinnerModule,
        ClrTextareaModule,
        CommonModule,
        CoreModule,
        FormsModule,
        DialogModule,
        SharedModule,
        VIPModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class UpdateModule { }
