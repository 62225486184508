/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'static-route-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const newStaticRouteHeader = `${prefix}.newStaticRouteHeader`;
export const editStaticRouteHeader = `${prefix}.editStaticRouteHeader`;
export const gatewaySubnetInputLabel = `${prefix}.gatewaySubnetInputLabel`;
export const nextHopInputLabel = `${prefix}.nextHopInputLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [newStaticRouteHeader]: 'New Static Route',
    [editStaticRouteHeader]: 'Edit Static Route: {0}',
    [gatewaySubnetInputLabel]: 'Gateway Subnet',
    [nextHopInputLabel]: 'Next Hop',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
};
