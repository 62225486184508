/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import './avi-dropdown-option.component.less';

const RIGHT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: 10,
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
};

const LEFT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: -10,
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
};

const tooltipPositionsPriority: ConnectedPosition[] = [
    RIGHT_CONNECTED_POSITION,
    LEFT_CONNECTED_POSITION,
];

const caretClassNameMap = new Map([
    [RIGHT_CONNECTED_POSITION, 'avi-dropdown-option__tooltip--right'],
    [LEFT_CONNECTED_POSITION, 'avi-dropdown-option__tooltip--left'],
]);

/**
 * @description Component for a dropdown option.
 * @author alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-dropdown-option',
    templateUrl: './avi-dropdown-option.component.html',
})
export class AviDropdownOptionComponent {
    /**
     * @param label - Text to be displayed in the option.
     */
    @Input() public label: string;

    /**
     * @param tooltipText - Text to be displayed in the tooltip.
     */
    @Input() public tooltipText?: string;

    public tooltipPositionsPriority: ConnectedPosition[] = tooltipPositionsPriority;
    public caretClassName = caretClassNameMap.get(tooltipPositionsPriority[0]);

    /**
     * Handler for the position change of the tooltip content. Used to reposition the caret.
     */
    public handlePositionChange(connectedPosition: ConnectedPosition): void {
        this.caretClassName = caretClassNameMap.get(connectedPosition);
    }
}
