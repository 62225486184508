/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'server-info-controller';
const prefix = `${moduleName}.${componentName}`;

export const serverHeader = `${prefix}.serverHeader`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const portLabel = `${prefix}.portLabel`;
export const ratioLabel = `${prefix}.ratioLabel`;
export const statusLabel = `${prefix}.statusLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const disabledLabel = `${prefix}.disabledLabel`;
export const uptimeLabel = `${prefix}.uptimeLabel`;
export const downtimeLabel = `${prefix}.downtimeLabel`;

export const ENGLISH = {
    [serverHeader]: 'Server: <span class="sel-subnav-name">{0}</span>',
    [ipAddressLabel]: 'IP Address',
    [portLabel]: 'Port',
    [ratioLabel]: 'Ratio',
    [statusLabel]: 'Status',
    [enabledLabel]: 'Enabled',
    [disabledLabel]: 'Deactivated',
    [uptimeLabel]: 'Uptime',
    [downtimeLabel]: 'Downtime',
};

