/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { CloudType } from 'generated-types';
import { StringService } from 'ajs/modules/core/services/string-service';
import { IAviDataGridConfig } from 'ng/shared/components';
import { BgpPeerConfigItem } from 'ajs/modules/vrf-context';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './bgp-peer-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// Set of cloud types that supports placement_network.
const CLOUDS_WITH_PLACEMENT_NETWORK_SUPPORT = new Set([
    CloudType.CLOUD_VCENTER,
    CloudType.CLOUD_NSXT,
]);

/**
 * @desc BGP Peer Grid component - Used in BGP Profile.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'bgp-peer-grid',
    templateUrl: './bgp-peer-grid.component.html',
})
export class BgpPeerGridComponent {
    /**
     * BgpPeerConfigItem RepeatedMessageItem instance.
     */
    @Input()
    public peers: RepeatedMessageItem<BgpPeerConfigItem>;

    /**
     * Selected Cloud's Type.
     */
    @Input()
    public cloudType: CloudType;

    /**
     * Fires on new Peer addition.
     */
    @Output()
    public onAddPeer: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on edit of a Peer.
     */
    @Output()
    public onEditPeer: EventEmitter<BgpPeerConfigItem> = new EventEmitter();

    /**
     * Fires on delete/deletAll of Peer.
     */
    @Output()
    public onDeletePeer: EventEmitter<BgpPeerConfigItem> = new EventEmitter();

    /**
     * BGP Peer Grid config.
     */
    public bgpPeerGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { l10nService, l10nKeys } = this;

        this.bgpPeerGridConfig = {
            getRowId(index: number, peer: BgpPeerConfigItem): string {
                return peer.repeatedId;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleIpv4Peer),
                id: 'peer_ip',
                transform(peer: BgpPeerConfigItem) {
                    const { peer_ip: peerIpAddrConfigItem } = peer.config;

                    return peerIpAddrConfigItem?.config?.addr ?? '';
                },
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleIpv6Peer),
                id: 'peer_ip6',
                transform(peer: BgpPeerConfigItem) {
                    const { peer_ip6: peerIp6AddrConfigItem } = peer.config;

                    return peerIp6AddrConfigItem?.config?.addr ?? '';
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (peers: BgpPeerConfigItem[]) => {
                    peers.forEach((peer: BgpPeerConfigItem) => this.deleteBgpPeer(peer));
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (peer: BgpPeerConfigItem) => this.editBgpPeer(peer),
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (peer: BgpPeerConfigItem) => this.deleteBgpPeer(peer),
            }],
        };

        if (this.showPlacementNetworkColumn) {
            this.bgpPeerGridConfig.fields.push({
                label: l10nService.getMessage(l10nKeys.columnTitlePlacementNetwork),
                id: 'network_uuid',
                transform: (peer: BgpPeerConfigItem) => {
                    return peer.config.network_ref ?
                        this.stringService.name(peer.config.network_ref) : '';
                },
            });
        }
    }

    /**
     * Handler for BGP-Peer Add button.
     */
    public addBgpPeer(): void {
        this.onAddPeer.emit();
    }

    /**
     * Handler for BGP-Peer delete.
     */
    private deleteBgpPeer(peer: BgpPeerConfigItem): void {
        this.onDeletePeer.emit(peer);
    }

    /**
     * Handler for BGP-Peer edit.
     */
    private editBgpPeer(peer: BgpPeerConfigItem): void {
        this.onEditPeer.emit(peer);
    }

    /**
     * Returns true if Placement Network can be set on the BGP Peer.
     */
    private get showPlacementNetworkColumn(): boolean {
        return CLOUDS_WITH_PLACEMENT_NETWORK_SUPPORT.has(this.cloudType);
    }
}
