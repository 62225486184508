/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'email-controller';
const prefix = `${moduleName}.${componentName}`;

export const smtpTitle = `${prefix}.smtpTitle`;
export const smtpTextLabel = `${prefix}.smtpTextLabel`;
export const emailTitle = `${prefix}.emailTitle`;
export const emailTextLabel = `${prefix}.emailTextLabel`;
export const snmpTitle = `${prefix}.snmpTitle`;
export const snmpTextLabel = `${prefix}.snmpTextLabel`;
export const syslogTitle = `${prefix}.syslogTitle`;
export const syslogTextLabel = `${prefix}.syslogTextLabel`;
export const toInputLabel = `${prefix}.toInputLabel`;
export const ccInputLabel = `${prefix}.ccInputLabel`;
export const subjectInputLabel = `${prefix}.subjectInputLabel`;
export const submitButtonLabel = `${prefix}.submitButtonLabel`;

export const ENGLISH = {
    [smtpTitle]: 'Validate Email SMTP Settings',
    [smtpTextLabel]: 'Body',
    [emailTitle]: 'Validate Email Settings',
    [emailTextLabel]: 'Body',
    [snmpTitle]: 'Validate SNMP Trap Settings',
    [snmpTextLabel]: 'Trap Description',
    [syslogTitle]: 'Validate Syslog Settings',
    [syslogTextLabel]: 'Message',
    [toInputLabel]: 'To',
    [ccInputLabel]: 'Cc',
    [subjectInputLabel]: 'Subject',
    [submitButtonLabel]: 'Submit',
};
