/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'subject-alt-names-grid';
const prefix = `${moduleName}.${componentName}`;

export const subjectAlternateNameInputLabel = `${prefix}.subjectAlternateNameInputLabel`;
export const subjectAlternateNameInputPlaceholder = `${prefix}.subjectAlternateNameInputPlaceholder`;
export const actionTitleRemove = `${prefix}.actionTitleRemove`;
export const columnTitleName = `${prefix}.columnTitleName`;

export const ENGLISH = {
    [subjectAlternateNameInputLabel]: 'Add',
    [subjectAlternateNameInputPlaceholder]: 'Enter Subject Alternate Name',
    [actionTitleRemove]: 'Remove',
    [columnTitleName]: 'Name',
};
