/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name HSGraphDataTransformer
 * @author Alex Malitsky
 * @description
 *
 *     Data Transport for Health Score Graph API.
 */
angular.module('aviApp').factory('HSGraphDataTransformer', [
'RevisedDataTransformer', 'HSGraphMetrics',
function(RevisedDataTransformer, HSGraphMetrics) {
    return class HSGraphDataTransformer extends RevisedDataTransformer {
        constructor(...args) {
            super(...args);

            this.metricSeriesPropList_ = HSGraphMetrics.seriesPropList;
        }

        /** @override */
        getRequestConfig(requestParams) {
            const params = super.getRequestConfig(requestParams);

            if ('fields_' in params && angular.isArray(params['fields_'])) {
                params['include'] = params['fields_'].join(',');
            }

            delete params['fields_'];

            return params;
        }

        /**
         * Wraps Item's data into 'config' property. For consistency with other Items.
         * @param {Item.data} config
         * @returns {{config: Item.data}}
         * @static
         * @private
         */
        static dataWrapper_(config) {
            return { config };
        }

        /**
         * Puts obj_id into name and name into the title to be consistent with regular series.
         * @param {Object} header
         * @static
         * @private
         */
        static seriesTransform_({ header }) {
            const { obj_id: seriesId, name } = header;

            header.title = name;
            header.name = seriesId;
            delete header['obj_id'];
        }

        /** @override */
        processResponse(resp) {
            const
                { owner_: owner } = this,
                { data } = resp,
                { nodes, edges } = data;

            if (angular.isArray(nodes) && angular.isArray(edges)) {
                const
                    nodeIdByIndexHash = {},
                    nodeConfigByIndexHash = {};

                let rootNodeId = '';

                data.nodes = nodes.map(nodeData => {
                    const { index } = nodeData;

                    if (!rootNodeId && nodeData['parentid'] === null) {
                        rootNodeId = nodeData['nodeid'];
                    }

                    nodeConfigByIndexHash[index] = nodeData;
                    nodeData = HSGraphDataTransformer.dataWrapper_(nodeData);
                    nodeIdByIndexHash[index] = owner.getNodeIdFromData(nodeData);

                    return nodeData;
                });

                //source and target come as numeric indexes, let's switch em into node ids
                //also we want to mark contributors and first level children (scores)
                data.edges = edges.map(edgeData => {
                    const { source: sourceIndex, target: targetIndex, contributor } = edgeData;

                    edgeData['source'] = nodeIdByIndexHash[sourceIndex];

                    const sourceId = nodeIdByIndexHash[sourceIndex];

                    edgeData['target'] = nodeIdByIndexHash[targetIndex];

                    if (contributor) {
                        nodeConfigByIndexHash[targetIndex].contributor = true;
                    }

                    if (sourceId === rootNodeId) {
                        nodeConfigByIndexHash[targetIndex].isRootChild = true;
                    }

                    return HSGraphDataTransformer.dataWrapper_(edgeData);
                });

                _.each(this.metricSeriesPropList_, key => {
                    //no need to keep empty sets
                    if (key in data) {
                        if (_.isEmpty(data[key])) {
                            delete data[key];
                        } else if (!('header' in data[key])) {
                            //flatten hashes of metrics into lists
                            data[key] = _.values(data[key]);
                        }
                    }

                    //move header.name to header.title and set name to obj_id
                    if (angular.isArray(data[key])) {
                        data[key].forEach(HSGraphDataTransformer.seriesTransform_);
                    } else if (data[key]) {
                        HSGraphDataTransformer.seriesTransform_(data[key]);
                    }
                });
            }

            return resp;
        }
    };
}]);
