/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { CloudType } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/shared/components';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { VipConfigItem } from 'ajs/modules/vs';
import * as l10n from './vip-grid.l10n';
import './vip-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Set of cloudTypes with AllocationType selection.
 */
const cloudsWithAllocationTypeField = new Set([
    CloudType.CLOUD_AWS,
    CloudType.CLOUD_AZURE,
]);

/**
 * Set of cloudTypes with Public IP support.
 */
const cloudsWithFloatingIpField = new Set([
    CloudType.CLOUD_AWS,
    CloudType.CLOUD_AZURE,
    CloudType.CLOUD_OPENSTACK,
]);

/**
 * Following cloudTypes supports IPV6.
 */
const cloudsWithIpv6Field = new Set([
    CloudType.CLOUD_NONE,
    CloudType.CLOUD_OPENSTACK,
    CloudType.CLOUD_NSXT,
]);

/**
 * @description Vip grid component.
 *
 * @author Aravindh Nagarajan.
 */
@Component({
    selector: 'vip-grid',
    templateUrl: './vip-grid.component.html',
})
export class VipGridComponent implements AfterViewInit, OnChanges {
    /**
     * VipConfigItem RepeatedMessageItem instance.
     */
    @Input()
    public vips: RepeatedMessageItem<VipConfigItem>;

    /**
     * If True, only one VIP can be added.
     */
    @Input()
    public hasMultiVip = false;

    /**
     * True if cloud instance loaded in hostComponent.
     */
    @Input()
    public cloudLoaded = false;

    /**
     * Type of cloud.
     */
    @Input()
    public cloudType: CloudType;

    /**
     * Indicates whether cloud has IPAM Provider profile.
     * If true, PrivateIpType column will be displayed.
     */
    @Input()
    public hasIpamProviderProfile = false;

    /**
     * Fires on add vip.
     */
    @Output()
    public onAddVip = new EventEmitter<void>();

    /**
     * Fires on edit vip.
     */
    @Output()
    public onEditVip = new EventEmitter<VipConfigItem>();

    /**
     * Fires on delete vip.
     */
    @Output()
    public onDeleteVip = new EventEmitter<VipConfigItem>();

    /**
     * Template ref for enabled checkbox row.
     */
    @ViewChild('enabledColumnTemplateRef')
    public enabledColumnTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Vip grid config.
     */
    public vipGridConfig: IAviDataGridConfig;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnChanges({ cloudLoaded }: SimpleChanges): void {
        if (cloudLoaded && !cloudLoaded.firstChange && this.cloudLoaded) {
            this.setVipGridColumns();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.vipGridConfig = {
            getRowId(index: number, vip: VipConfigItem): number {
                return index;
            },
            fields: [],
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                onClick: (vips: VipConfigItem[]) => {
                    vips.forEach((vip: VipConfigItem) => this.deleteVip(vip));
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.editActionLabel),
                shape: 'pencil',
                onClick: (vip: VipConfigItem) => this.editVip(vip),
            }, {
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'trash',
                onClick: (vip: VipConfigItem) => this.deleteVip(vip),
            }],
        };
    }

    /**
     * Fires add Vip event.
     */
    public addVip(): void {
        this.onAddVip.emit();
    }

    /**
     * If it returns true, `Add Vip` button will be displayed.
     */
    public disableAddVipButton(): boolean {
        return !this.hasMultiVip && this.vips.count === 1;
    }

    /**
     * Fires edit Vip event.
     */
    private editVip(vip: VipConfigItem): void {
        this.onEditVip.emit(vip);
    }

    /**
     * Fires delete Vip event.
     */
    private deleteVip(vip: VipConfigItem): void {
        this.onDeleteVip.emit(vip);
    }

    /**
     * Sets columns based on cloudType.
     */
    private setVipGridColumns(): void {
        const NO_DATA = '-';

        const {
            cloudType,
            hasIpamProviderProfile,
            vipGridConfig,
            l10nService,
        } = this;

        vipGridConfig.fields = [
            {
                label: l10nService.getMessage(l10nKeys.columnTitleEnabled),
                id: 'enabled',
                templateRef: this.enabledColumnTemplateRef,
            },
            {
                label: l10nService.getMessage(l10nKeys.columnTitleVipId),
                id: 'vip_id',
                transform({ id }: VipConfigItem) {
                    return id;
                },
            },
            ...hasIpamProviderProfile || cloudsWithAllocationTypeField.has(cloudType) ? [{
                label: l10nService.getMessage(l10nKeys.columnTitlePrivateIpType),
                id: 'auto_allocate_ip',
                transform: ({ isAutoAllocateIpEnabled }: VipConfigItem) => {
                    const privateIpTypeLabel = isAutoAllocateIpEnabled ?
                        l10nKeys.autoAllocateLabel : l10nKeys.staticLabel;

                    return l10nService.getMessage(privateIpTypeLabel);
                },
            }] : [],
            {
                label: l10nService.getMessage(l10nKeys.columnTitleIpAddress),
                id: 'ip_address',
                transform(vip: VipConfigItem) {
                    return vip.getVipAddress() || NO_DATA;
                },
            },
            ...cloudsWithIpv6Field.has(cloudType) ? [{
                label: l10nService.getMessage(l10nKeys.columnTitleIpv6Address),
                id: 'ip6_address',
                transform(vip: VipConfigItem) {
                    return vip.getIP6VipAddress() || NO_DATA;
                },
            }] : [],
            ...cloudsWithFloatingIpField.has(cloudType) ? [{
                label: l10nService.getMessage(l10nKeys.columnTitlePublicIp),
                id: 'floating_ip',
                transform(vip: VipConfigItem) {
                    return vip.config.floating_ip?.addr || NO_DATA;
                },
            }] : [],
        ];
    }
}
