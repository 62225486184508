/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name AuthProfile
 */
angular.module('aviApp').factory('AuthProfile', [
'Item', 'defaultValues',
function(Item, defaultValues) {
    const TYPE_LDAP = 'AUTH_PROFILE_LDAP';
    const TYPE_TACACS = 'AUTH_PROFILE_TACACS_PLUS';
    const TYPE_SAML = 'AUTH_PROFILE_SAML';
    const TYPE_PING = 'AUTH_PROFILE_PINGACCESS';
    const TYPE_JWT = 'AUTH_PROFILE_JWT';
    const TYPE_OAUTH = 'AUTH_PROFILE_OAUTH';

    const AUTH_SAML_APP_VS = 'AUTH_SAML_APP_VS';
    const AUTH_SAML_CLUSTER_VIP = 'AUTH_SAML_CLUSTER_VIP';

    const objectName = 'authprofile';

    class AuthProfile extends Item {
        /**
         * Sets type and default sub-config values when user switches auth profile types.
         * Available on creation only.
         * @param {string} type - AuthProfileType enum
         */
        setType(type) {
            const config = this.getConfig();

            if (this.id || config.type === type) {
                return;
            }

            config.type = type;

            this.syncSubObjectsWithType_(true);
            this.beforeEdit();
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();
            const { type } = config;

            if (!type) {
                config.type = TYPE_LDAP;
            }

            //default settings have all types, let's drop redundant
            this.syncSubObjectsWithType_();

            switch (config.type) {
                case TYPE_TACACS: {
                    const { tacacs_plus: tacacsPlus } = config;

                    if (!('authorization_attrs' in tacacsPlus)) {
                        tacacsPlus.authorization_attrs = [{}];
                    }

                    break;
                }

                case TYPE_SAML: {
                    // SAML doesn't have default settings
                    if (!('saml' in config)) {
                        config.saml = {};
                    }

                    const { saml } = config;

                    if (!('sp' in saml)) {
                        saml.sp = {};
                    }

                    const { sp: samlSp } = saml;

                    if (!('saml_entity_type' in samlSp)) {
                        samlSp.saml_entity_type = AUTH_SAML_CLUSTER_VIP;
                    }

                    break;
                }

                case TYPE_PING: {
                    if (!('pa_agent_ref' in config)) {
                        config.pa_agent_ref = '';
                    }

                    break;
                }
            }
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            /**
             * Need to remove oauth_profile from config
             * which was added as part of default values.
             */
            if (config.type !== TYPE_OAUTH) {
                delete config.oauth_profile;
            }

            switch (config.type) {
                case TYPE_LDAP: {
                    const { ldap } = config;
                    const { http: { require_user_groups: requireUserGroups } = {} } = config;

                    //due to checkbox directive bug
                    ldap.security_mode = ldap.security_mode || 'AUTH_LDAP_SECURE_NONE';

                    if (requireUserGroups) {
                        config.http.require_user_groups = _.compact(requireUserGroups);
                    }

                    break;
                }
            }

            return config;
        }

        /**
         * Sets SAML entity type and updates SAML sub-configuration fields.
         * Available on creation only.
         * @param {string} type
         */
        setSamlEntityType(type) {
            const { saml } = this.getConfig();

            // creation only
            if (this.id || type === saml.sp.saml_entity_type) {
                return;
            }

            // nothing but type is needed for this one
            if (type === AUTH_SAML_APP_VS) {
                saml.sp = {
                    saml_entity_type: AUTH_SAML_APP_VS,
                };

                return;
            }

            const { sp } = saml;

            sp.saml_entity_type = type;
            sp.fqdn = undefined;
        }

        /**
         * Returns profile type.
         * @return {string} - AuthProfileType protobuf enum. Returns empty string if not set.
         */
        getType() {
            const config = this.getConfig();

            return config?.type || '';
        }

        /**
         * Synchronizes auth profile type with corresponding configuration sub objects.
         * @param {boolean=} resetToDefault - If true is passed will reset corresponding subobject
         *     to default settings.
         * @protected
         */
        syncSubObjectsWithType_(resetToDefault = false) {
            const config = this.getConfig();

            switch (config.type) {
                case TYPE_LDAP:
                    config.tacacs_plus = undefined;
                    config.saml = undefined;
                    config.pa_agent_ref = undefined;
                    config.jwt_profile_ref = undefined;

                    if (resetToDefault) {
                        config.ldap = defaultValues.getDefaultItemConfig(`${objectName}.ldap`);
                        config.http = defaultValues.getDefaultItemConfig(`${objectName}.http`);
                    }

                    break;

                case TYPE_TACACS:
                    config.ldap = undefined;
                    config.saml = undefined;
                    config.http = undefined;
                    config.pa_agent_ref = undefined;
                    config.jwt_profile_ref = undefined;

                    if (resetToDefault) {
                        config.tacacs_plus = defaultValues.getDefaultItemConfig(
                            `${objectName}.tacacs_plus`,
                        );
                    }

                    break;

                case TYPE_SAML:
                    config.tacacs_plus = undefined;
                    config.ldap = undefined;
                    config.http = undefined;
                    config.pa_agent_ref = undefined;
                    config.jwt_profile_ref = undefined;

                    if (resetToDefault) {
                        config.saml =
                            defaultValues.getDefaultItemConfig(`${objectName}.saml`) || {};
                    }

                    break;

                case TYPE_PING:
                    config.tacacs_plus = undefined;
                    config.ldap = undefined;
                    config.http = undefined;
                    config.saml = undefined;
                    config.jwt_profile_ref = undefined;

                    if (resetToDefault) {
                        config.pa_agent_ref = '';
                    }

                    break;

                case TYPE_JWT:
                    config.tacacs_plus = undefined;
                    config.ldap = undefined;
                    config.http = undefined;
                    config.saml = undefined;
                    config.pa_agent_ref = undefined;

                    if (resetToDefault) {
                        config.jwt_profile_ref = '';
                    }

                    break;
            }
        }
    }

    AuthProfile.prototype.objectName = objectName;
    AuthProfile.prototype.windowElement = 'prof-auth-profile-create';

    return AuthProfile;
}]);
