/**
 * @module RbacModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { L10nService } from '@vmw/ngx-vip';
import { IRoleFilterMatchLabel } from 'generated-types';
import { debounce } from 'underscore';
import './role-filter-match-label.component.less';
import * as l10n from './role-filter-match-label.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * Wrapper component to update RBAC config items with a plain RBAC label object list.
 * And generate a plain RBAC object list from RBAC config items.
 *
 * @author Abhinesh Gour
 */
@Component({
    selector: 'role-filter-match-label',
    templateUrl: './role-filter-match-label.component.html',
})
export class RoleFilterMatchLabel implements OnInit {
    /**
     * Hosting component's objectType, which will be used to show tooltip.
     */
    @Input()
    public objectType: string;

    /**
     * Hosting component's fieldName, which will be used to show tooltip.
     */
    @Input()
    public fieldName: string;

    /**
     * Hosting component's RepeatedMessageItem instance config.
     */
    @Input()
    public markers: RepeatedMessageItem<MessageItem<IRoleFilterMatchLabel>>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * RBAC label objects to be manipulated by the component.
     */
    public rbacLabels: IRoleFilterMatchLabel[] = [];

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
        this.updateRbacConfig = debounce(this.updateRbacConfig, 500);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        // initiate rbacLabels with plain objects flattened from message items
        // flattenConfig() generates a plain RBAC object list from RBAC config items.
        this.rbacLabels = this.markers.flattenConfig();
    }

    /**
     * Change handler method
     */
    public handleUpdate(): void {
        this.updateRbacConfig();
    }

    /**
     * Update RBAC config items with a plain RBAC label object list.
     */
    private updateRbacConfig = (): void => {
        this.markers.updateConfig(this.rbacLabels);
    };
}
