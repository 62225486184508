/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Inject,
    Injectable,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { StateService } from '@uirouter/core';

import {
    Observable,
    Subject,
} from 'rxjs';

import {
    ALBServicesRegistrationStatus,
    IALBServicesStatus,
    ISaasLicensingStatus,
    LicenseTierType,
} from 'generated-types';

import { LicensingService } from 'ajs/modules/licensing';
import { CportalService } from 'ajs/modules/cportal/services/cportal.service';
import { portalStatus } from 'ajs/modules/cportal/constants';
import { AppLevelAlertsService } from '../app-level-alerts.service';

import * as l10n from './central-license-alerts.service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const PULSE_REGISTRATION_ALERT_ID = 'pulse-registration-alert';
const LICENSE_ALERTS_LOCAL_STORAGE_KEY = 'license-alerts';

/**
 * Enterprise with cloud services license subscription failed alert id.
 */
export const LICENSE_SUBSCRIPTION_FAILED_ALERT_ID = 'license-subscription-fail-alert';

/**
 * @desc Service to fetch app level alerts related to central licensing.
 *
 * @author Nisar Nadaf
 */
@Injectable()
export class CentralLicenseAlertsService {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Holds controller registration status.
     */
    public controllerRegistrationStatus: ALBServicesRegistrationStatus;

    /**
     * List of alerts closed/dismissed by user.
     */
    private removedAlerts: string[] = [];

    /**
     * Holds enterprise with cloud services license(SaaS) tier subscription status.
     */
    private saasSubscriptionStatus: ISaasLicensingStatus;

    /**
     * Used to track changes in enterprise with cloud services license(SaaS) tier
     * subscription status from reconcile api.
     */
    private readonly licenseSubscriptionStatusSubject = new Subject<void>();

    constructor(
        private readonly appLevelAlertsService: AppLevelAlertsService,
        private readonly cportalService: CportalService,
        private readonly l10nService: L10nService,
        private readonly licensingService: LicensingService,
        @Inject('$state')
        private readonly $state: StateService,
        @Inject('systemInfoService')
        private readonly systemInfoService: any,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Render app level alerts based on response of api calls.
     * Currently fetching controller registration status.
     */
    public loadAlerts(): void {
        this.setRemovedAlerts();
        this.cportalService.loadRegistrationStatus()
            .then(() => {
                const albserviceStatus = this.cportalService.getRegistrationStatusDetails();

                this.refreshPulseRegistrationAlert(albserviceStatus);

                // Set controller registration and method call to set
                // SaaS status and show license subscription failed app level alert.
                const { registration_status: registrationStatus } = albserviceStatus;

                this.controllerRegistrationStatus = registrationStatus;

                this.setSaasStatusAndRefreshLicenseFailAlert();
            });
    }

    /**
     * Setter for license subscription status.
     */
    public set saasStatus(saasStatus: ISaasLicensingStatus) {
        this.saasSubscriptionStatus = saasStatus;
    }

    /**
     * Getter for license subscription status.
     */
    public get saasStatus(): ISaasLicensingStatus {
        return this.saasSubscriptionStatus;
    }

    /**
     * Returns an observable to allow subscriptions to license subscription status change.
     */
    public get licenseSubscriptionStatus$(): Observable<void> {
        return this.licenseSubscriptionStatusSubject.asObservable();
    }

    /**
     * Method to show/hide app level alert and retry button when
     * enterprise with cloud services license tier subscription failed.
     */
    public refreshLicenseSubscriptionFailAlert(): void {
        const { enabled, message } = this.saasStatus;

        if (this.isEnterpriseWithCloudServicesTier && this.isControllerRegistered && !enabled) {
            this.appLevelAlertsService.add({
                id: LICENSE_SUBSCRIPTION_FAILED_ALERT_ID,
                componentProps: {
                    message,
                    status: 'danger',
                    closable: false,
                    actionLabel: this.l10nService.getMessage(
                        l10nKeys.retryNowButtonText,
                    ),
                    onClick: () => this.refreshLicenseStatus(),
                },
            });
        } else {
            this.removeAlert(LICENSE_SUBSCRIPTION_FAILED_ALERT_ID);
        }
    }

    /**
     * Render/Remove app level alert based on pulse registration status.
     * Once alert is closed/dismissed by user then it will not re-render.
     */
    public refreshPulseRegistrationAlert(albserviceStatus: IALBServicesStatus): void {
        const { registration_status: registrationStatus } = albserviceStatus;
        const { ALBSERVICES_DEREGISTERED } = portalStatus;
        const { defaultLicenseTier } = this.systemInfoService;

        // If alert is previously closed by user then don't render it again.
        if (this.removedAlerts.includes(PULSE_REGISTRATION_ALERT_ID)) {
            return;
        }

        // Default license tier is ENTERPRISE_WITH_CLOUD_SERVICES and Controller is not registered
        if (defaultLicenseTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES &&
            registrationStatus === ALBSERVICES_DEREGISTERED
        ) {
            this.appLevelAlertsService.add({
                id: PULSE_REGISTRATION_ALERT_ID,
                componentProps: {
                    message: this.l10nService.getMessage(
                        l10nKeys.controllerNotRegisteredMessage,
                    ),
                    actionLabel: this.l10nService.getMessage(
                        l10nKeys.registerControllerButtonText,
                    ),
                    onClick: () => this.redirectToCloudServices(),
                    onClose: () => this.dismissAlert(PULSE_REGISTRATION_ALERT_ID),
                },
            });
        } else {
            this.removeAlert(PULSE_REGISTRATION_ALERT_ID);
        }
    }

    /**
     * Clears removedAlerts list from localStorage.
     */
    public clearAlertsFromLocalStorage(): void {
        localStorage.removeItem(LICENSE_ALERTS_LOCAL_STORAGE_KEY);
    }

    /**
     * Loads SaaS status and call method to show license subscription failed app level alert.
     */
    public async setSaasStatusAndRefreshLicenseFailAlert(): Promise<void> {
        try {
            const { saas_status: saasStatus } = await this.licensingService.loadLicenseStatus();

            this.saasStatus = saasStatus;

            this.refreshLicenseSubscriptionFailAlert();
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Sets removedList data if value present in localStorage.
     */
    private setRemovedAlerts(): void {
        const removedList = localStorage.getItem(LICENSE_ALERTS_LOCAL_STORAGE_KEY);

        this.removedAlerts = [];

        if (removedList) {
            this.removedAlerts = removedList.split(',');
        }
    }

    /**
     * Redirects to the Cloud services settings page.
     */
    private redirectToCloudServices(): void {
        this.$state.go('authenticated.administration.settings.cloud-services');
    }

    /**
     * Dismiss app level alert by given alert ID and store id in removedAlerts.
     */
    private dismissAlert(alertId: string): void {
        this.removeAlert(alertId);
        this.removedAlerts.push(alertId);
        localStorage.setItem(LICENSE_ALERTS_LOCAL_STORAGE_KEY, this.removedAlerts.join(','));
    }

    /**
     * Remove app level alert if present.
     */
    private removeAlert(alertId: string): void {
        if (this.appLevelAlertsService.has(alertId)) {
            this.appLevelAlertsService.remove(alertId);
        }
    }

    /**
     * Returns true if controller is registered.
     */
    private get isControllerRegistered(): boolean {
        const { ALBSERVICES_REGISTERED } = portalStatus;

        return this.controllerRegistrationStatus === ALBSERVICES_REGISTERED;
    }

    /**
     * Returns true for ENTERPRISE_WITH_CLOUD_SERVICES License tier.
     */
    private get isEnterpriseWithCloudServicesTier(): boolean {
        const { defaultLicenseTier } = this.systemInfoService;

        return defaultLicenseTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES;
    }

    /**
     * Call Reconcile api to refresh ENTERPRISE_WITH_CLOUD_SERVICES license subscription status.
     */
    private async refreshLicenseStatus(): Promise<void> {
        try {
            const { saas_status: saasStatus } = await this.licensingService.refreshLicenseStatus();
            const { enabled } = saasStatus;

            if (enabled) {
                this.licenseSubscriptionStatusSubject.next();

                this.removeAlert(LICENSE_SUBSCRIPTION_FAILED_ALERT_ID);
            }
        } catch (error) {
            console.error(error);
        }
    }
}
