/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * Ajs dependency token for NetworkProfile collection.
 */
export const NETWORK_PROFILE_COLLECTION_TOKEN = 'NetworkProfileCollection';

/**
 * Ajs dependency token for NetworkProfile item.
 */
export const NETWORK_PROFILE_ITEM_TOKEN = 'NetworkProfile';

/**
 * Ajs dependency token for TCP fast path profile config message item.
 */
export const TCP_FAST_PATH_CONFIG_ITEM_TOKEN = 'TCPFastPathProfileConfigItem';

/**
 * Ajs dependency token for UDP fast path profile config message item.
 */
export const UDP_FAST_PATH_CONFIG_ITEM_TOKEN = 'UDPFastPathProfileConfigItem';

/**
 * Ajs dependency token for network profile union config message item.
 */
export const NETWORK_PROFILE_UNION_CONFIG_ITEM_TOKEN = 'NetworkProfileUnionConfigItem';
