/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'tenant-selector';
const prefix = `${moduleName}.${componentName}`;

export const allTenantsLabel = `${prefix}.allTenantsLabel`;

export const ENGLISH = {
    [allTenantsLabel]: 'All Tenants',
};
