/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Sarthak Kapoor
 */

/**
 * Dependency token for LinuxServerHost Message Item.
 */
export const LINUX_SERVER_HOST_CONFIG_ITEM_TOKEN = 'LinuxServerHostConfigItem';

/**
 * Dependency token for LinuxServerConfiguration Message Item.
 */
export const LINUX_SERVER_CONFIGURATION_CONFIG_ITEM_TOKEN = 'LinuxServerConfigurationConfigItem';

/**
 * Dependency token for AzureConfiguration Message Item.
 */
export const AZURE_CONFIGURATION_CONFIG_ITEM_TOKEN = 'AzureConfigurationConfigItem';

/**
 * Dependency token for AzureNetworkInfor Message Item.
 */
export const AZURE_NETWORK_INFO_CONFIG_ITEM_TOKEN = 'AzureNetworkInfoConfigItem';
