/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleNew = `${prefix}.modalTitleNew`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const actionSectionTitle = `${prefix}.actionSectionTitle`;
export const matchSectionTitle = `${prefix}.matchSectionTitle`;
export const nameLabel = `${prefix}.nameLabel`;
export const enterNameLabel = `${prefix}.enterNameLabel`;
export const enableRuleLabel = `${prefix}.enableRuleLabel`;
export const clientIpLabel = `${prefix}.clientIpLabel`;
export const protocolLabel = `${prefix}.protocolLabel`;
export const servicePortsLabel = `${prefix}.servicePortsLabel`;

export const ENGLISH = {
    [modalTitleNew]: 'New L4 Rule: {0}',
    [modalTitleEdit]: 'Edit L4 Rule: {0}',
    [generalSectionTitle]: 'General',
    [actionSectionTitle]: 'Action',
    [matchSectionTitle]: 'Match',
    [nameLabel]: 'Name',
    [enterNameLabel]: 'Enter Name',
    [enableRuleLabel]: 'Enable Rule',
    [clientIpLabel]: 'Client IP',
    [protocolLabel]: 'Protocol',
    [servicePortsLabel]: 'Service Ports',
};
