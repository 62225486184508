/**
 * @module PersistenceProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { TPbEnumValueKey } from 'ajs/modules/core/services/schema-service/schema.types';
import { PersistenceProfile } from 'ajs/modules/persistence-profile';

import {
    PersistenceProfileType,
    PersistentServerHMDownRecovery,
} from 'generated-types';

import * as l10n from './persistence-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for persistence profile modal.
 * @author Rohit Gaikwad
 */
@Component({
    selector: 'persistence-profile-modal',
    templateUrl: './persistence-profile-modal.component.html',
})
export class PersistenceProfileModalComponent implements OnInit {
    /**
     * PersistenceProfile ObjectTypeItem.
     */
    @Input()
    public editable: PersistenceProfile;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * PersistenceProfile object type.
     */
    public objectType: string;

    /**
     * Layout for PersistenceProfile modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Hidden option values.
     */
    public hiddenTypes: TPbEnumValueKey[] = [];

    /**
     * Hash of Persistence Profile Types enum
     */
    public readonly persistenceProfileTypeHash = {
        PERSISTENCE_TYPE_GSLB_SITE: PersistenceProfileType.PERSISTENCE_TYPE_GSLB_SITE,
        PERSISTENCE_TYPE_HTTP_COOKIE: PersistenceProfileType.PERSISTENCE_TYPE_HTTP_COOKIE,
        PERSISTENCE_TYPE_APP_COOKIE: PersistenceProfileType.PERSISTENCE_TYPE_APP_COOKIE,
        PERSISTENCE_TYPE_CLIENT_IP_ADDRESS:
            PersistenceProfileType.PERSISTENCE_TYPE_CLIENT_IP_ADDRESS,
        PERSISTENCE_TYPE_CUSTOM_HTTP_HEADER:
            PersistenceProfileType.PERSISTENCE_TYPE_CUSTOM_HTTP_HEADER,
        PERSISTENCE_TYPE_TLS:
            PersistenceProfileType.PERSISTENCE_TYPE_TLS,
    };

    /**
     * Hash of Persistence Server HMDown Recovery enum
     */
    public readonly persistentServerHMDownRecoveryHash = {
        HM_DOWN_PICK_NEW_SERVER: PersistentServerHMDownRecovery.HM_DOWN_PICK_NEW_SERVER,
        HM_DOWN_CONTINUE_PERSISTENT_SERVER:
            PersistentServerHMDownRecovery.HM_DOWN_CONTINUE_PERSISTENT_SERVER,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { editable } = this;
        const persistenceProfileType = editable.getType();

        this.objectType = editable.messageType;
        editable.onTypeChange();

        // for create case drop non-GSLB types from the list except gslb type.
        if (!editable.id && persistenceProfileType ===
            PersistenceProfileType.PERSISTENCE_TYPE_GSLB_SITE) {
            this.hiddenTypes = Object.keys(this.persistenceProfileTypeHash).filter(type => {
                return type !== persistenceProfileType;
            });
        }
    }
}
