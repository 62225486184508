/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import { Inject, Injectable } from '@angular/core';

import {
    SchemaService,
    TEnumValueLabelsHash,
} from 'ajs/modules/core//services/schema-service';

/**
 * @ngdoc service
 * @name WafPolicyAllowlistRuleExpanderContentService
 * @module services/WafPolicyAllowlistRuleExpanderContentService
 * @desc Service used to display action labels for Waf Policy Allowlist actions.
 * @author alextsg
 */
@Injectable()
export class WafPolicyAllowlistRuleExpanderContentService {
    public actionValueLabelHash: TEnumValueLabelsHash;

    constructor(@Inject(SchemaService) private schemaService: SchemaService) {
        const enumType = 'WafPolicyAllowlistAction';

        this.actionValueLabelHash = this.schemaService.getEnumValueLabelsHash(enumType);
    }

    /**
     * Returns a list of text to display based on a list of action enums.
     * @param actions - List of action enums from WafPolicyAllowlistAction.
     * @returns Comma-separated string of text to display from the enums.
     */
    public getActionLabels(actions: string[] = []): string {
        return actions.map(this.getActionLabel).join(', ');
    }

    /**
     * Returns text to display based on an action enum.
     * @param value - Action enum from WafPolicyAllowlistAction.
     * @returns Text to display from the enum.
     */
    private getActionLabel = (value: string): string => {
        return this.actionValueLabelHash[value];
    };
}
