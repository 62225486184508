/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './HealthMonitorListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name HealthMonitorListController
 * @description
 *
 *     Regular list view controller.
 *
 */
angular.module('aviApp').controller('HealthMonitorListController', [
'$scope',
'CRUDGridConfig',
'HealthMonitorCollection',
'loadedSystemInfoService',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    HealthMonitorCollection,
    systemInfo,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const collection = new HealthMonitorCollection({
        params: {
            is_federated: undefined, // need to get both types here
        },
    });

    const fields = [
        {
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }, {
            name: 'type',
            title: l10nService.getMessage(l10nKeys.columnTitleType),
            template:
                '<span class="sel-type">{{row.getConfig().type | enum: "HEALTH_MONITOR_"}}</span>',
        }, {
            name: 'send',
            title: l10nService.getMessage(l10nKeys.columnTitleSendInterval),
            template:
                `<span class="sel-send-interval">
                    {{ config.props.l10nKeys.secInnerText |
                        vtranslate : false : row.getConfig().send_interval }}
                </span>`,
        }, {
            name: 'recv',
            title: l10nService.getMessage(l10nKeys.columnTitleReceiveTimeout),
            template:
                `<span class="sel-receive-timeout">
                    {{ config.props.l10nKeys.secInnerText  |
                        vtranslate : false : row.getConfig().receive_timeout }}
                </span>`,
        }, {
            name: 'data.config.successful_checks',
            title: l10nService.getMessage(l10nKeys.columnTitleSuccessfulChecks),
        }, {
            name: 'data.config.failed_checks',
            title: l10nService.getMessage(l10nKeys.columnTitleFailedChecks),
        },
    ];

    if (systemInfo.haveGSLBConfig()) {
        fields.splice(2, 0, {
            name: 'is_federated',
            title: l10nService.getMessage(l10nKeys.columnTitleIsFederated),
            template: '{{ row.getConfig().is_federated | booleanLabel:"yes" }}',
        });
    }

    const { objectName } = collection;

    const gridConfig = {
        id: `${objectName}-list-page`,
        props: {
            l10nKeys,
        },
        collection,
        fields,
        singleactions: [{
            title: l10nService.getMessage(l10nKeys.actionBtnEdit),
            class: 'icon-pencil-4',
            hidden: row => !row.isEditable() && !row.isClonable(),
            do: row => row.isEditable() && row.edit() || collection.clone(row),
        }],
    };

    $scope.healthMonitorsGridConfig = CRUDGridConfig(gridConfig);

    $scope.$on('$destroy', () => collection.destroy());
}]);
