/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { SSO_POLICY_COLLECTION_TOKEN } from 'ajs/modules/sso-policy/sso-policy.tokens';
import * as l10n from './ping-access-policy-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.pingAccessPolicySettingsBindings
 * @see {@link module:avi/vs.pingAccessPolicySettingsComponent pingAccessPolicySettingsComponent}
 */
class PingAccessPolicySettingsController {
    constructor(SSOPolicyCollection, l10nService) {
        /** @type {SSOPolicyCollection} */
        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: 'SSO_TYPE_PINGACCESS',
            },
            defaults: {
                type: 'SSO_TYPE_PINGACCESS',
            },
        });

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onDestroy() {
        this.ssoPolicyCollection.destroy();
    }
}

PingAccessPolicySettingsController.$inject = [
    SSO_POLICY_COLLECTION_TOKEN,
    'l10nService',
];

/**
 * @name pingAccessPolicySettingsComponent
 * @memberOf module:avi/vs
 * @description
 *      VS Access Policy -> PingAccess Settings.
 *      Only applicable for HTTP app profile type.
 * @param {module:avi/vs.pingAccessPolicySettingsBindings} bindings
 * @param {module:avi/vs.PingAccessPolicySettingsController} controller
 * @author Aravindh Nagarajan, Alex Malitsky
 */
angular.module('avi/vs').component('pingAccessPolicySettings', {
    /**
     * @mixin pingAccessPolicySettingsBindings
     * @memberOf module:avi/vs
     * @property {string} ssoPolicyRef VirtualService#data#config#sso_policy_ref
     */
    bindings: {
        ssoPolicyRef: '=',
    },
    controller: PingAccessPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'ping-access-policy-settings/ping-access-policy-settings.component.html',
});
