/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * It contains constants related to cases. We have severity and type
 * which we currently support in cases creation.
 */

const BUG = 'Bug';
const CONFIGURATION_HELP = 'Configuration Help';
const FEATURE_REQUEST = 'Feature Request';
const ISSUE = 'Issue';
const QUESTION = 'Question';

const APIC = 'APIC';
const AWS = 'AWS';
const AZURE = 'Azure';
const CSP = 'CSP';
const GCP = 'GCP';
const KUBERNETES = 'Kubernetes';
const LINUX_SERVER_CLOUD = 'Linux Server Cloud';
const OPENSTACK = 'OpenStack';
const VMWARE = 'VMware';

// Portal modes for case.
export enum PORTAL_MODES {
    MYVMWARE = 'MYVMWARE',
    SYSTEST = 'SYSTEST',
    SALESFORCE = 'SALESFORCE',
}

export const ASSIGNEE = 'assignee';
export const REPORTER = 'reporter';
export const AFFECTED_VERSION = 'affectedVersions';
export const TARGET_VERSION = 'targetFixVersions';
export const USERS = 'users';
export const VERSION = 'version';

export const systemTagTypes = [
    ASSIGNEE,
    REPORTER,
    AFFECTED_VERSION,
    TARGET_VERSION,
];

export const systemTypeLabels = {
    [AFFECTED_VERSION]: 'Affected Version',
    [TARGET_VERSION]: 'Target Version',
    [ASSIGNEE]: 'Assignee',
    [REPORTER]: 'Reporter',
    bugType: 'Bug Type',
    issueType: 'Issue Type',
    systestAssessment: 'System Test Assessment',
    testFailureType: 'Test Failure Type',
};

export const typeHash = {
    BUG,
    CONFIGURATION_HELP,
    FEATURE_REQUEST,
    ISSUE,
    QUESTION,
};

export enum severityHash {
    SEVERITY_1 = 'Severity 1',
    SEVERITY_2 = 'Severity 2',
    SEVERITY_3 = 'Severity 3',
    SEVERITY_4 = 'Severity 4',
    SEVERITY_5 = 'Severity 5',
}

export const environmentHash = {
    APIC,
    AWS,
    AZURE,
    CSP,
    GCP,
    KUBERNETES,
    LINUX_SERVER_CLOUD,
    OPENSTACK,
    VMWARE,
};

export const enum CaseStatus {
    CLOSED = 'closed',
    ACTIVE = 'active',
}
