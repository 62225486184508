/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ssl-certificate-grid-status-column';
const prefix = `${moduleName}.${componentName}`;

export const certificateStatusTooltipLabel = `${prefix}.certificateStatusTooltipLabel`;
export const certificateExpiringOnTooltipLabel = `${prefix}.certificateExpiringOnTooltipLabel`;

export const ENGLISH = {
    [certificateStatusTooltipLabel]: 'Status',
    [certificateExpiringOnTooltipLabel]: '(Expiring on<span class="status-expiring ml5">{0}</span>)',
};
