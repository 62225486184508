/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-tls-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeSelectPlaceholder = `${prefix}.typeSelectPlaceholder`;
export const acceptedVersionsLabel = `${prefix}.acceptedVersionsLabel`;
export const acceptedVersionsPlaceholder = `${prefix}.acceptedVersionsPlaceholder`;
export const selectListRadioButtonLabel = `${prefix}.selectListRadioButtonLabel`;
export const enterStringRadioButtonLabel = `${prefix}.enterStringRadioButtonLabel`;
export const sendCloseNotifyCheckboxLabel = `${prefix}.sendCloseNotifyCheckboxLabel`;
export const preferClientCipherOrderingCheckboxLabel = `${prefix}.preferClientCipherOrderingCheckboxLabel`;
export const enableEarlyDataCheckboxLabel = `${prefix}.enableEarlyDataCheckboxLabel`;
export const enableSslSessionReuseCheckboxLabel = `${prefix}.enableSslSessionReuseCheckboxLabel`;
export const sslSessionExpirationInputLabel = `${prefix}.sslSessionExpirationInputLabel`;
export const sslSessionExpirationInputPlaceholder = `${prefix}.sslSessionExpirationInputPlaceholder`;
export const acceptedCiphersInputLabel = `${prefix}.acceptedCiphersInputLabel`;
export const acceptedCiphersInputPlaceholder = `${prefix}.acceptedCiphersInputPlaceholder`;
export const ciphersuitesInputLabel = `${prefix}.ciphersuitesInputLabel`;
export const ciphersuitesInputPlaceholder = `${prefix}.ciphersuitesInputPlaceholder`;
export const secondsHelperText = `${prefix}.secondsHelperText`;
export const sectionTitleCiphers = `${prefix}.sectionTitleCiphers`;
export const sectionTitleRbac = `${prefix}.sectionTitleRbac`;
export const tabTitleRbac = `${prefix}.tabTitleRbac`;
export const changingCiphersMessage = `${prefix}.changingCiphersMessage`;
export const resetToDefaultValuesHeader = `${prefix}.resetToDefaultValuesHeader`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit SSL/TLS Profile: {0}',
    [modalTitleNew]: 'New SSL/TLS Profile: {0}',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [typeInputLabel]: 'Type',
    [typeSelectPlaceholder]: 'Select Type',
    [acceptedVersionsLabel]: 'Accepted Versions',
    [acceptedVersionsPlaceholder]: 'Select Version',
    [sendCloseNotifyCheckboxLabel]: 'Send \'Close Notify\' Alert',
    [preferClientCipherOrderingCheckboxLabel]: 'Prefer Client Cipher Ordering',
    [enableEarlyDataCheckboxLabel]: 'Enable Early Data',
    [enableSslSessionReuseCheckboxLabel]: 'Enable SSL Session Reuse',
    [sslSessionExpirationInputLabel]: 'SSL Session Expiration',
    [sslSessionExpirationInputPlaceholder]: 'Enter SSL Session Expiration',
    [selectListRadioButtonLabel]: 'Select From List',
    [enterStringRadioButtonLabel]: 'Enter String',
    [acceptedCiphersInputLabel]: 'Accepted Ciphers',
    [acceptedCiphersInputPlaceholder]: 'Enter Accepted Ciphers',
    [ciphersuitesInputLabel]: 'Ciphersuites (TLSv1.3)',
    [ciphersuitesInputPlaceholder]: 'Enter Ciphersuites',
    [secondsHelperText]: 'Seconds',
    [sectionTitleCiphers]: 'Ciphers',
    [sectionTitleRbac]: 'Role-Based Access Control (RBAC)',
    [tabTitleRbac]: 'RBAC',
    [changingCiphersMessage]: 'Changing cipher type will reset settings to default values.',
    [resetToDefaultValuesHeader]: 'Reset to Default Values',
};
