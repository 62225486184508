/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { ProtocolParserModalComponent } from 'ng/modules/scripts';
import { PROTOCOL_PARSER_ITEM_TOKEN } from './protocol-parser.item.factory';

/**
 * Ajs dependency token for ProtocolParser Collection.
 */
export const PROTOCOL_PARSER_COLLECTION_TOKEN = 'ProtocolParserCollection';

/**
 * @description  Collection of ProtocolParser items.
 *
 * @author Aravindh Nagarajan, Rajawant Prajapati
 */

export class ProtocolParserCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'protocolparser',
            windowElement: ProtocolParserModalComponent,
            permissionName: AviPermissionResource.PERMISSION_PROTOCOLPARSER,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(PROTOCOL_PARSER_ITEM_TOKEN);
    }
}

ProtocolParserCollection.ajsDependencies = [
    PROTOCOL_PARSER_ITEM_TOKEN,
];
