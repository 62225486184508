/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'case-preview';
const prefix = `${moduleName}.${componentName}`;

export const casePreviewHeader = `${prefix}.casePreviewHeader`;
export const caseStatusLabel = `${prefix}.caseStatusLabel`;
export const caseCreatedDateLabel = `${prefix}.caseCreatedDateLabel`;

export const ENGLISH = {
    [casePreviewHeader]: 'Support Information',
    [caseStatusLabel]: 'Status',
    [caseCreatedDateLabel]: 'Date Created',
};
