/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'case-attachments-grid';
const prefix = `${moduleName}.${componentName}`;

export const attachmentGridHeaderLabel = `${prefix}.attachmentGridHeaderLabel`;
export const attachmentNameColumnLabel = `${prefix}.attachmentNameColumnLabel`;
export const nameColumnLabel = `${prefix}.nameColumnLabel`;
export const statusColumnLabel = `${prefix}.statusColumnLabel`;
export const uploadSuccessLabel = `${prefix}.uploadSuccessLabel`;
export const uploadFailedLabel = `${prefix}.uploadFailedLabel`;
export const uploadInProgressLabel = `${prefix}.uploadInProgressLabel`;

export const ENGLISH = {
    [attachmentGridHeaderLabel]: 'Attached Files ({0})',
    [attachmentNameColumnLabel]: 'File Name',
    [nameColumnLabel]: 'Name',
    [statusColumnLabel]: 'Status',
    [uploadSuccessLabel]: 'Upload Successful',
    [uploadFailedLabel]: 'Upload Failed',
    [uploadInProgressLabel]: 'Uploading',
};
