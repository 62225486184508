/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-server-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const requestsPerSecondFullTitle = `${prefix}.requestsPerSecondFullTitle`;
export const columnTitleServerName = `${prefix}.columnTitleServerName`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const columnTitleDescription = `${prefix}.columnTitleDescription`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const columnTitleHealth = `${prefix}.columnTitleHealth`;
export const columnTitlePort = `${prefix}.columnTitlePort`;
export const columnTitleRatio = `${prefix}.columnTitleRatio`;
export const actionTitleEnable = `${prefix}.actionTitleEnable`;
export const actionTitleDisable = `${prefix}.actionTitleDisable`;
export const actionTitleDelete = `${prefix}.actionTitleDelete`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const disabledLabel = `${prefix}.disabledLabel`;
export const gridDefaultPortLabel = `${prefix}.gridDefaultPortLabel`;
export const blastPortLabel = `${prefix}.blastPortLabel`;
export const pcoipPortLabel = `${prefix}.pcoipPortLabel`;
export const tunnelPortLabel = `${prefix}.tunnelPortLabel`;

export const ENGLISH = {
    [requestsPerSecondFullTitle]: 'Requests per second',
    [columnTitleServerName]: 'Server Name',
    [columnTitleIpAddress]: 'IP Address',
    [columnTitleDescription]: 'Description',
    [columnTitleStatus]: 'Status',
    [columnTitleHealth]: 'Health',
    [columnTitlePort]: 'Port',
    [columnTitleRatio]: 'Ratio',
    [actionTitleEnable]: 'Enable',
    [actionTitleDisable]: 'Disable',
    [actionTitleDelete]: 'Delete',
    [enabledLabel]: 'Enabled',
    [disabledLabel]: 'Disabled',
    [gridDefaultPortLabel]: 'Default Port',
    [blastPortLabel]: 'Blast',
    [pcoipPortLabel]: 'PCoIP',
    [tunnelPortLabel]: 'Tunnel',
};
