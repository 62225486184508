/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'ip-addr-match';
const prefix = `${moduleName}.${componentName}`;

export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const addItemButtonLabel = `${prefix}.addItemButtonLabel`;

export const ENGLISH = {
    [ipAddressLabel]: 'IP Address',
    [addItemButtonLabel]: 'Add Item',
};
