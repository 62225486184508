/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'login';
const componentName = 'reset-password';
const prefix = `${moduleName}.${componentName}`;

export const forgotPassword = `${prefix}.forgotPassword`;
export const enterEmailAddress = `${prefix}.enterEmailAddress`;
export const signIn = `${prefix}.signIn`;
export const sendInstructions = `${prefix}.sendInstructions`;

export const ENGLISH = {
    [forgotPassword]: 'Forgot your password?',
    [enterEmailAddress]: `Enter the email address on file for your account and
        we'll send you instructions on how to change your password.`,
    [signIn]: 'Sign in',
    [sendInstructions]: 'Send Recovery Instructions',
};
