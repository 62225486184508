/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import { Injector } from '@angular/core';
import {
    WafProfileCollection,
} from 'ajs/modules/waf/factories/waf-profile/waf-profile.collection.factory';

import { WAF_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/waf/waf.tokens';

const $injector = '$injector';

export const wafProfileCollectionProvider = {
    deps: [$injector],
    provide: WafProfileCollection,
    useFactory(injector: Injector): typeof WafProfileCollection {
        return injector.get(WAF_PROFILE_COLLECTION_TOKEN);
    },
};
