/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @name msToStringChunks
 * @type {Function}
 * @param {number|string} timeInMs - Either number of ms or string like '3,000,005'.
 * @param {boolean=} omitRem - If true is passed will return only two most significant values.
 * @description
 *
 *     Converts number of ms to human readable string like "1h 25min 4s 709ms" represented in
 *     chunks (objects) with "value" and "dimension" properties.
 */
const stringRegex = /,/g;

const ranges = [{
    value: 60 * 60 * 1000,
    dimension: 'h',
}, {
    value: 60 * 1000,
    dimension: 'm',
}, {
    value: 1000,
    dimension: 's',
}, {
    value: 1,
    dimension: 'ms',
}];

function msToStringChunks(timeInMs, omitRem = false) {
    // not sure if string argument form is still used
    if (angular.isString(timeInMs)) {
        if (timeInMs.indexOf(',') !== -1) {
            timeInMs = timeInMs.replace(stringRegex, '');
        }

        timeInMs = parseFloat(timeInMs);
    }

    timeInMs = Math.round(timeInMs);

    const chunks = [];

    // special case for zero
    if (_.isNaN(timeInMs)) {
        chunks.push({
            value: 'N/A',
            dimension: '',
        });
    } else if (!timeInMs) {
        chunks.push({
            value: 0,
            dimension: 'ms',
        });
    } else {
        let i = 0;

        while (i < ranges.length && (!omitRem || chunks.length < 2)) {
            const {
                value: range,
                dimension,
            } = ranges[i];

            i++;

            if (timeInMs >= range) {
                chunks.push({
                    value: Math.floor(timeInMs / range),
                    dimension,
                });

                timeInMs %= range;
                // doesn't skip zero values once first match is found
            } else if (chunks.length) {
                chunks.push({
                    value: 0,
                    dimension,
                });
            }
        }
    }

    return chunks;
}

//used by unit tests only
msToStringChunks.chunksToString = function(chunks) {
    return chunks.reduce(
        (acc, { value, dimension }) => `${acc}${value}${dimension}`,
        '',
    );
};

angular.module('aviApp')
    .constant('msToStringChunks', msToStringChunks);
