/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';

import {
    BackupConfiguration,
    SystemConfig,
} from 'ajs/modules/system';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { MacroStackFactory } from 'ajs/modules/data-model/factories/macro-stack.factory';
import { FaultService } from 'ajs/modules/notifications';
import { InitialDataService } from 'ajs/modules/core/services/initial-data';

import './welcome-modal.component.less';
import * as l10n from './welcome-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TMacroStackFactory = typeof MacroStackFactory;

/**
 * @desc Welcome modal component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'welcome-modal',
    templateUrl: './welcome-modal.component.html',
})
export class WelcomeModalComponent {
    /**
     * BackupConfig item instance.
     */
    @Input()
    public backupConfig: BackupConfiguration;

    /**
     * SystemConfig Item instance.
     */
    @Input()
    public systemConfig: SystemConfig;

    /**
     * Fires on modal close.
     */
    @Output()
    public onWelcomeModalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Fires on modal dismiss.
     */
    @Output()
    public onWelcomeModalDismiss: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Errors to be shown on top of the modal.
     */
    public errors = '';

    /**
     * Modal layout value.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Will be set to true if all steps of the panels are completed.
     * Used to enable submit button.
     */
    public welcomeFormComplete = false;

    /**
     * If true, we open cloud modal right after save.
     */
    public openCloudModal = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * MacroStackFactory instance.
     */
    private macroStackFactory: MacroStackFactory;

    /**
     * Modal busy state (submit/load).
     * True if the spinner should be shown in the footer,
     * disabling the action buttons.
     */
    private busy = false;

    public constructor(
        private readonly initialDataService: InitialDataService,
        private readonly faultService: FaultService,
        l10nService: L10nService,
        @Inject(MacroStackFactory)
        MacroStackFactory: TMacroStackFactory,
    ) {
        this.macroStackFactory = new MacroStackFactory();

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when the user clicks the Submit button.
     * Saves welcome configurations.
     */
    public async submit(): Promise<void> {
        this.busy = true;
        this.errors = '';

        this.macroStackFactory.add(this.backupConfig);
        this.macroStackFactory.add(this.systemConfig);

        try {
            await this.macroStackFactory.submit();
            await this.completeWelcomeSetup();

            this.unsetIgnoreControllerFaults();
            this.faultService.refreshControllerFaultsNotification();
            this.closeModal();
        } catch (errors) {
            this.macroStackFactory.remove(this.backupConfig);
            this.macroStackFactory.remove(this.systemConfig);

            this.errors = this.macroStackFactory.errors || errors.data;
        }

        this.busy = false;
    }

    /**
     * Fires on modal dismiss.
     */
    public dismiss(): void {
        this.onWelcomeModalDismiss.emit();
    }

    /**
     * Method to check whether modal is busy.
     */
    public isBusy(): boolean {
        return this.busy;
    }

    /**
     * Fires when last step button is clicked.
     */
    public completeWelcomeFlowSteps(): void {
        this.welcomeFormComplete = true;
    }

    /**
     * Method to close modal.
     */
    private closeModal(): void {
        this.onWelcomeModalClose.emit(this.openCloudModal);
    }

    /**
     * Marks welcomeFlow as completed, initiate auth.initData get request.
     */
    private async completeWelcomeSetup(): Promise<void> {
        await this.systemConfig.setWelcomeWorkflowComplete();
        await this.initialDataService.loadData(true);
    }

    /**
     * Sets ignoreControllerFaults property as false to start
     * showing notifications related to controller faults.
     */
    private unsetIgnoreControllerFaults(): void {
        this.faultService.setIgnoreControllerFaults(false);
    }
}
