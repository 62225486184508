/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
    THttpWrapper,
} from 'ajs/modules/core/factories';

import { DevLoggerService } from 'ng/modules/core';

const OS_KEYSTONE_AUTH_URL =
    '/api/cloud?vtype=CLOUD_OPENSTACK&' +
        'openstack_configuration.use_keystone_auth=true&fields=url&page_size=1';

/**
 * Ajs Dependency token for openstackKeystoneService.
 */
export const OPENSTACK_KEYSTONE_SERVICE_TOKEN = 'openstackKeystoneService';

/**
 * @description Service to load keystone auth info.
 *
 * @author Aravindh Nagarajan
 */
export class OpenstackKeystoneService {
    /**
     * If true, keystone auth is enabled.
     */
    private isKeystoneAuthEnabled = false;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
        HttpWrapper: THttpWrapper,
        private readonly devLoggerService: DevLoggerService,
    ) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Get OpenStack keystone auth flag.
     */
    public loadKeystoneAuthInfo(): ng.IPromise<void> {
        this.isKeystoneAuthEnabled = false;

        const requestConfig = {
            url: OS_KEYSTONE_AUTH_URL,
            method: HttpMethod.GET,
            requestId: 'keystone-auth-request',
        };

        return this.httpWrapper.request(requestConfig)
            .then(({ data }: any) => {
                this.isKeystoneAuthEnabled = data.count > 0;
            })
            .catch(error => {
                this.devLoggerService.error(error);
            });
    }

    /**
     * Getter for isKeystoneAuthEnabled.
     */
    public get keystoneAuthEnabled(): boolean {
        return this.isKeystoneAuthEnabled;
    }
}

OpenstackKeystoneService.$inject = [
    HTTP_WRAPPER_TOKEN,
    'devLoggerService',
];
