/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vs-vip-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const tabTitleRbac = `${prefix}.tabTitleRbac`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const cloudLabel = `${prefix}.cloudLabel`;
export const vrfContextLabel = `${prefix}.vrfContextLabel`;
export const rbacHeader = `${prefix}.rbacHeader`;
export const tier1LogicalRouterSelectLabel = `${prefix}.tier1LogicalRouterInputLabel`;
export const tier1LogicalRouterSelectPlaceholder = `${prefix}.tier1LogicalRouterInputPlaceholder`;
export const bgpPeerLabelSelectLabel = `${prefix}.bgpPeerLabelSelectLabel`;
export const bgpPeerLabelSelectPlaceholder = `${prefix}.bgpPeerLabelSelectPlaceholder`;
export const useStandardAlbCheckboxLabel = `${prefix}.useStandardAlbCheckboxLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit VS VIP: {0}',
    [modalTitleNew]: 'Create VS VIP: {0}',
    [sectionTitleGeneral]: 'General',
    [tabTitleRbac]: 'RBAC',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [cloudLabel]: 'Cloud',
    [vrfContextLabel]: 'VRF Context',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
    [tier1LogicalRouterSelectLabel]: 'Tier1 Logical Router',
    [tier1LogicalRouterSelectPlaceholder]: 'Select Tier1 Logical Router',
    [bgpPeerLabelSelectLabel]: 'BGP Peer Labels',
    [bgpPeerLabelSelectPlaceholder]: 'Select BGP Peer Labels',
    [useStandardAlbCheckboxLabel]: 'Use Standard ALB',
};
