/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-tls-certificate-ocsp';
const prefix = `${moduleName}.${componentName}`;

export const ocspStaplingCheckboxLabel = `${prefix}.ocspStaplingCheckboxLabel`;
export const ocspResponderUrlFromCertificatesListLabel = `${prefix}.ocspResponderUrlFromCertificatesListLabel`;
export const frequencyIntervalInputLabel = `${prefix}.frequencyIntervalInputLabel`;
export const frequencyIntervalInputPlaceholder = `${prefix}.frequencyIntervalInputPlaceholder`;
export const responseTimeoutInputLabel = `${prefix}.responseTimeoutInputLabel`;
export const responseTimeoutInputPlaceholder = `${prefix}.responseTimeoutInputPlaceholder`;
export const failJobIntervalInputLabel = `${prefix}.failJobIntervalInputLabel`;
export const failJobIntervalInputPlaceholder = `${prefix}.failJobIntervalInputPlaceholder`;
export const maxTriesInputLabel = `${prefix}.maxTriesInputLabel`;
export const maxTriesInputPlaceholder = `${prefix}.maxTriesInputPlaceholder`;
export const ocspResponderUrlListLabel = `${prefix}.ocspResponderUrlListLabel`;
export const responderUrlActionInputLabel = `${prefix}.responderUrlActionInputLabel`;
export const columnTitleUrl = `${prefix}.columnTitleUrl`;
export const urlActionFailoverOptionLabel = `${prefix}.urlActionFailoverOptionLabel`;
export const urlActionOverrideOptionLabel = `${prefix}.urlActionOverrideOptionLabel`;
export const secondsInnerText = `${prefix}.secondsInnerText`;

export const ENGLISH = {
    [ocspStaplingCheckboxLabel]: 'Enable OCSP Stapling',
    [ocspResponderUrlFromCertificatesListLabel]: 'OCSP Responder URL from Certificates List ({0})',
    [frequencyIntervalInputLabel]: 'Frequency Interval',
    [frequencyIntervalInputPlaceholder]: 'Enter Frequency Interval',
    [responseTimeoutInputLabel]: 'Response Timeout',
    [responseTimeoutInputPlaceholder]: 'Enter Response Timeout',
    [failJobIntervalInputLabel]: 'Fail Job Interval',
    [failJobIntervalInputPlaceholder]: 'Enter Fail Job Interval',
    [maxTriesInputLabel]: 'Max Tries',
    [maxTriesInputPlaceholder]: 'Enter Max Tries',
    [ocspResponderUrlListLabel]: 'OCSP Responder URL List ({0})',
    [responderUrlActionInputLabel]: 'Responder URL Action',
    [columnTitleUrl]: 'URL',
    [urlActionFailoverOptionLabel]: 'Failover',
    [urlActionOverrideOptionLabel]: 'Override',
    [secondsInnerText]: 'Seconds',
};
