/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { VCenterCredentialsConfigItem } from 'ajs/modules/cloud-connector-user';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './vcenter-credentials.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the VCenterCredentials configuration.
 * @author alextsg
 */
@Component({
    selector: 'vcenter-credentials',
    templateUrl: './vcenter-credentials.component.html',
})
export class VCenterCredentialsComponent {
    /**
     * VCenterCredentialsConfigItem Config Item.
     */
    @Input() public editable: VCenterCredentialsConfigItem;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
}
