/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'tech-support-data-transformer';
const prefix = `${moduleName}.${componentName}`;

export const completeSuccessfullyLabel = `${prefix}.completeSuccessfullyLabel`;
export const completeErrorLabel = `${prefix}.completeErrorLabel`;

export const ENGLISH = {
    [completeSuccessfullyLabel]: 'Completed successfully',
    [completeErrorLabel]: 'Completed with errors',
};
