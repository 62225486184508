/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name DnsPassThroughMetric
 * @description
 *
 *     One series only. Created to set tiles configuration.
 */
angular.module('aviApp').factory('DnsPassThroughMetric', [
'CollMetric', function(CollMetric) {
    /**
     * @class
     * @extends CollMetric
     **/
    return class DnsPassThroughMetric extends CollMetric {
        /** @override */
        getTimingTilesConfig() {
            return {
                header: 'UDP Passthrough',
                tiles: [
                    {
                        id: 'aviIcon',
                        icon: 'lb',
                        iconTitle: 'LB',
                    }, {
                        id: 'avi',
                        title: 'Total Time',
                        series: this.series[0],
                    }, {
                        id: 'server',
                        icon: 'server',
                        iconTitle: 'Server',
                    },
                ],
            };
        }
    };
}]);
