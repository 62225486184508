/**
 * @module IpamModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    CustomIpamDnsCollection,
    CustomIpamDnsProfile,
    IPAMProfileCollection,
} from '.';

const ipamModule = angular.module('avi/ipam');

const factories = [
    {
        dependency: IPAMProfileCollection,
        name: 'IPAMProfileCollection',
    },
    {
        dependency: CustomIpamDnsProfile,
        name: 'CustomIpamDnsProfile',
    },
    {
        dependency: CustomIpamDnsCollection,
        name: 'CustomIpamDnsCollection',
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(ipamModule, 'factory', name, dependency);
});
