/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} TechSupportApiConfig
 * @description config object returned by tech support status api
 * @property {TechSupportApiStatus} status
 * @property {string} modified
 * @property {string} size - in bytes
 * @property {string} url - to download or find file
 * @private
 */

function techSupportCollectionFactory(FileServiceCollection, TechSupport) {
    /**
     * @alias module:services/TechSupportCollection
     * @extends FileServiceCollection
     * @private
     */
    class TechSupportCollection extends FileServiceCollection {
        constructor(args = {}) {
            const allDataSources = {
                list: {
                    source: 'ListCollDataSource',
                    transformer: 'TechSupportDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config'],
                },
            };

            const extendedArgs = Object.assign(
                {
                    isStatic: true,
                    allDataSources,
                    params: {
                        uri: 'controller://tech_support',
                    },
                },
                args,
            );

            FileServiceCollection.prototype.itemClass_ = TechSupport;

            super(extendedArgs);
        }
    }

    return TechSupportCollection;
}

techSupportCollectionFactory.$inject = [
    'FileServiceCollection',
    'TechSupport',
];

/**
 * @ngdoc services
 * @name TechSupportCollection
 * @module services/TechSupportCollection
 * @author Akul Aggarwal
 * @description
 *
 *     TechSupportCollection extends @FileServiceCollection, uses Tech Support item.
 *
 */
angular.module('aviApp').factory('TechSupportCollection', techSupportCollectionFactory);
