/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-placement-subnet';
const prefix = `${moduleName}.${componentName}`;

export const ipv4SubnetDropdownLabel = `${prefix}.ipv4SubnetDropdownLabel`;
export const ipv4SubnetDropdownPlaceholder = `${prefix}.ipv4SubnetDropdownPlaceholder`;
export const ipv6SubnetDropdownLabel = `${prefix}.ipv6SubnetDropdownLabel`;
export const ipv6SubnetDropdownPlaceholder = `${prefix}.ipv6SubnetDropdownPlaceholder`;

export const ENGLISH = {
    [ipv4SubnetDropdownLabel]: 'IPv4 Subnet',
    [ipv4SubnetDropdownPlaceholder]: 'Select IPv4 Subnet',
    [ipv6SubnetDropdownLabel]: 'IPv6 Subnet',
    [ipv6SubnetDropdownPlaceholder]: 'Select IPv6 Subnet',
};
