/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'cportal-info-preview';
const prefix = `${moduleName}.${componentName}`;

export const portalUrlLabel = `${prefix}.portalUrlLabel`;

export const ENGLISH = {
    [portalUrlLabel]: 'Cloud Services Portal URL',
};
