/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { SslTlsProfileModalComponent } from 'ng/modules/security';
import { SSL_PROFILE_ITEM_TOKEN } from './ssl-profile.item.factory';

/**
 * Ajs dependency token for SSLProfileCollection.
 */
export const SSL_PROFILE_COLLECTION_TOKEN = 'SSLProfileCollection';

/**
 * @desc Collection of SSLProfile Items.
 *
 * @author Ram Pal, Aravindh Nagarajan
 */
export class SSLProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'sslprofile',
            windowElement: SslTlsProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_SSLPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(SSL_PROFILE_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            tags: [],
        };
    }
}

SSLProfileCollection.ajsDependencies = [
    SSL_PROFILE_ITEM_TOKEN,
];
