/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'system';

export const emailConfigurationModalBreadcrumbTitle =
    `${prefix}.emailConfigurationModalBreadcrumbTitle`;
export const schedulerModalBreadcrumbTitle = `${prefix}.schedulerModalBreadcrumbTitle`;

export const ENGLISH = {
    [emailConfigurationModalBreadcrumbTitle]: 'Email/SMTP',
    [schedulerModalBreadcrumbTitle]: 'Configuration Backup',
};
