/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './auth-profile-verify-saml.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name authProfileVerifySaml
 * @param {AuthProfile} config
 * Auth Profile modal popup triggered by a singleaction. Shows details for the SAML config.
 */
class AuthProfileVerifySamlController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }
}

AuthProfileVerifySamlController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('authProfileVerifySaml', {
    controller: AuthProfileVerifySamlController,
    bindings: {
        config: '<',
        closeModal: '&',
    },
    templateUrl: 'src/components/pages/templates/profiles/auth-profile-page/' +
        'auth-profile-verify-saml/auth-profile-verify-saml.html',
});
