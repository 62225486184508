/**
 * Groups module
 * @module groupsModule
 * @preferred
 */
/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    IpAddrGroupCollection,
    IpAddrGroupItem,
    StringGroupCollection,
    StringGroupItem,
} from '.';

import {
    IP_ADDRESS_GROUP_COLLECTION_TOKEN,
    IP_ADDRESS_GROUP_ITEM_TOKEN,
    STRING_GROUP_COLLECTION_TOKEN,
    STRING_GROUP_ITEM_TOKEN,
} from './groups.tokens';

const groupsModule = angular.module('avi/groups');

const factories = [
    {
        factory: StringGroupItem,
        name: STRING_GROUP_ITEM_TOKEN,
    },
    {
        factory: StringGroupCollection,
        name: STRING_GROUP_COLLECTION_TOKEN,
    },
    {
        factory: IpAddrGroupCollection,
        name: IP_ADDRESS_GROUP_COLLECTION_TOKEN,
    },
    {
        factory: IpAddrGroupItem,
        name: IP_ADDRESS_GROUP_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(groupsModule, 'factory', name, factory);
});
