/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gslb-service-edit-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitlePriority = `${prefix}.columnTitlePriority`;
export const columnTitleAlgorithm = `${prefix}.columnTitleAlgorithm`;
export const columnTitleDescription = `${prefix}.columnTitleDescription`;
export const actionTitleEdit = `${prefix}.actionTitleEdit`;
export const actionTitleDelete = `${prefix}.actionTitleDelete`;
export const editGslbServiceTitle = `${prefix}.editGslbServiceTitle`;
export const newGslbServiceTitle = `${prefix}.newGslbServiceTitle`;
export const newGslbPoolTitle = `${prefix}.newGslbPoolTitle`;
export const editGslbPoolTitle = `${prefix}.editGslbPoolTitle`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const applicationNameTooltipLabel = `${prefix}.applicationNameTooltipLabel`;
export const applicationNameInputLabel = `${prefix}.applicationNameInputLabel`;
export const subdomainInputLabel = `${prefix}.subdomainInputLabel`;
export const removeDomainNameTitle = `${prefix}.removeDomainNameTitle`;
export const addApplicationNameLinkLabel = `${prefix}.addApplicationNameLinkLabel`;
export const healthMonitorLabel = `${prefix}.healthMonitorLabel`;
export const healthMonitorScopeLabel = `${prefix}.healthMonitorScopeLabel`;
export const controllerHealthStatusLabel = `${prefix}.controllerHealthStatusLabel`;
export const selectGroupTypeLabel = `${prefix}.selectGroupTypeLabel`;
export const activeActiveLabel = `${prefix}.activeActiveLabel`;
export const activeStandbyLabel = `${prefix}.activeStandbyLabel`;
export const groupsLoadBalancingAlgorithmLabel = `${prefix}.groupsLoadBalancingAlgorithmLabel`;
export const selectInputPlaceholder = `${prefix}.selectInputPlaceholder`;
export const sitePersistenceCheckboxLabel = `${prefix}.sitePersistenceCheckboxLabel`;
export const siteCookieApplicationLabel = `${prefix}.siteCookieApplicationLabel`;
export const minimumServersLabel = `${prefix}.minimumServersLabel`;
export const minimumServersInputPlaceholder = `${prefix}.minimumServersInputPlaceholder`;
export const gslbPoolPlural = `${prefix}.gslbPoolPlural`;
export const addPoolLinkLabel = `${prefix}.addPoolLinkLabel`;
export const numberOfIpsLabel = `${prefix}.numberOfIpsLabel`;
export const downResponseLabel = `${prefix}.downResponseLabel`;
export const ttlServedByDnsServiceLabel = `${prefix}.ttlServedByDnsServiceLabel`;
export const defaultDnsServiceInputPlaceholder = `${prefix}.defaultDnsServiceInputPlaceholder`;
export const secInnerText = `${prefix}.secInnerText`;
export const resolveCnameCheckboxLabel = `${prefix}.resolveCnameCheckboxLabel`;
export const poolHeader = `${prefix}.poolHeader`;
export const poolMembersLoadBalancingAlgorithmLabel = `${prefix}.poolMembersLoadBalancingAlgorithmLabel`;
export const poolMembersFallbackLoadBalancingAlgorithmLabel = `${prefix}.poolMembersFallbackLoadBalancingAlgorithmLabel`;
export const poolMembersFallbackMessage = `${prefix}.poolMembersFallbackMessage`;
export const poolMembersLoadMessage = `${prefix}.poolMembersLoadMessage`;
export const minHealthMonitorsLabel = `${prefix}.minHealthMonitorsLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const doneBtnLabel = `${prefix}.doneBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitlePriority]: 'Priority',
    [columnTitleAlgorithm]: 'Algorithm',
    [columnTitleDescription]: 'Description',
    [actionTitleEdit]: 'Edit',
    [actionTitleDelete]: 'Delete',
    [editGslbServiceTitle]: 'Edit GSLB Service',
    [newGslbServiceTitle]: 'New GSLB Service',
    [newGslbPoolTitle]: 'New GSLB Pool',
    [editGslbPoolTitle]: 'Edit GSLB Pool',
    [nameInputLabel]: 'Name',
    [applicationNameTooltipLabel]: 'Application name of the fully qualified domain name of the GSLB service.',
    [applicationNameInputLabel]: 'Application Name',
    [subdomainInputLabel]: 'Subdomain',
    [removeDomainNameTitle]: 'Remove Domain Name',
    [addApplicationNameLinkLabel]: 'Add Application Name',
    [healthMonitorLabel]: 'Health Monitor',
    [healthMonitorScopeLabel]: 'Health Monitor Scope',
    [controllerHealthStatusLabel]: 'Controller Health Status',
    [selectGroupTypeLabel]: 'Select Group Type',
    [activeActiveLabel]: 'Active Active',
    [activeStandbyLabel]: 'Active Standby',
    [groupsLoadBalancingAlgorithmLabel]: 'Groups Load Balancing Algorithm',
    [selectInputPlaceholder]: 'Select',
    [sitePersistenceCheckboxLabel]: 'Site Persistence',
    [siteCookieApplicationLabel]: 'Site Cookie Application Persistence Profile',
    [minimumServersLabel]: 'Minimum number of Servers',
    [minimumServersInputPlaceholder]: 'Min number of servers',
    [gslbPoolPlural]: '{0, plural, one{GSLB pool} other{GSLB pools}}',
    [addPoolLinkLabel]: 'Add Pool',
    [numberOfIpsLabel]: 'Number of IPs returned by DNS server',
    [downResponseLabel]: 'Down Response',
    [ttlServedByDnsServiceLabel]: 'TTL served by DNS Service',
    [defaultDnsServiceInputPlaceholder]: 'Default from DNS Service',
    [secInnerText]: 'Sec',
    [resolveCnameCheckboxLabel]: 'Resolve CNAME',
    [poolHeader]: 'Pool',
    [poolMembersLoadBalancingAlgorithmLabel]: 'Pool Members Load Balancing Algorithm',
    [poolMembersFallbackLoadBalancingAlgorithmLabel]: 'Pool Members Fallback Load Balancing Algorithm',
    [poolMembersFallbackMessage]: 'Pool Members Fallback Load Balancing Algorithm Mask',
    [poolMembersLoadMessage]: 'Pool Members Load Balancing Algorithm Mask',
    [minHealthMonitorsLabel]: 'Min. Health Monitors to consider server \'up\'',
    [descriptionLabel]: 'Description',
    [doneBtnLabel]: 'Done',
    [saveBtnLabel]: 'Save',
};
