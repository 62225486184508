/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import classnames from 'classnames';
import * as l10n from './waf-rule-group.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Map of classnames to be set based on the enabled state.
 */
const wafGroupEnabledStateClassNamesMap = new Map([
    [0, 'waf-rule-group__enabled-setting--disabled'],
    [1, 'waf-rule-group__enabled-setting--enabled'],
    [2, 'waf-rule-group__enabled-setting--mixed'],
]);

class WafRuleGroupController {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        this.l10nService = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.expanded = false;
    }

    /**
     * Adds a rule to the group.
     * @param {WafRuleConfig=} rule
     */
    handleAddRule(rule) {
        this.wafGroup.addWafRule(rule, {
            parentMode: this.mode,
            modeDelegationAllowed: this.modeDelegationAllowed,
        });
    }

    /**
     * Called to remove a group.
     */
    handleRemoveGroup() {
        this.onRemoveGroup();
    }

    /**
     * Handler for clicking the expander button.
     */
    handleExpand() {
        this.expanded = !this.expanded;
    }

    /**
     * Returns the enabled state of the group as a number. 0 for disabled, 1 for eenabled, and 2 for
     * mixed, as in some rules are enabled while some are disabled.
     * @returns {number}
     */
    get wafGroupEnabledState() {
        const wafGroupEnabled = this.wafGroup.isEnabled();

        if (!this.wafGroup.hasRules()) {
            return wafGroupEnabled ? 1 : 0;
        }

        if (wafGroupEnabled && this.wafGroup.hasAllRulesEnabled()) {
            return 1;
        }

        if (wafGroupEnabled && this.wafGroup.hasAnyRuleEnabled()) {
            return 2;
        }

        return 0;
    }

    /**
     * Returns the text label to be displayed based on the enabled status.
     * @returns {string}
     */
    get wafGroupEnabledSettingLabel() {
        const labelMap = new Map([
            [0, this.l10nKeys.deactivatedLabel],
            [1, this.l10nKeys.enabledLabel],
            [2, this.l10nKeys.mixedEnabledLabel],
        ]);

        const wafGroupEnabledStateKey = labelMap.get(this.wafGroupEnabledState);

        return this.l10nService.getMessage(wafGroupEnabledStateKey);
    }

    /**
     * Returns the class name to be set on the text label of the enabled status.
     * @returns {string}
     */
    get wafGroupEnabledStateClassName() {
        return classnames(
            'waf-rule-group__enabled-setting',
            wafGroupEnabledStateClassNamesMap.get(this.wafGroupEnabledState),
        );
    }

    /**
     * Moves rule to a new index. All rules in-between need to have their indices shifted.
     * @param {number} newIndex - Index of the new position.
     * @param {object} rule - Contains a ruleIndex property, which is the index of the rule to move.
     */
    handleDragAndDrop(newIndex, { ruleIndex }) {
        const oldIndex = this.wafGroup.getArrayIndexFromRuleIndex(ruleIndex);

        if (oldIndex < newIndex) {
            newIndex--;
        }

        this.wafGroup.moveRule(oldIndex, newIndex);

        return true;
    }

    /**
     * Returns a string to be used as the title for the filter icon.
     * @return {string}
     */
    getFilterIconTitle() {
        const outputs = [];

        if (this.wafGroup.hasExcludeListEntries()) {
            outputs.push('exceptions');
        }

        if (this.wafGroup.hasRulesWithExcludeListEntries()) {
            outputs.push('rules with exceptions');
        }

        return `This group has ${outputs.join(' and ')} configured.`;
    }

    /**
     * Called when editing a group.
     */
    handleEditGroup() {
        this.onEditGroup();
    }

    /**
     * Called when editing a rule.
     * @param {WafRule} rule - WAF Rule messageItem to be edited.
     */
    handleEditRule(rule) {
        this.wafGroup.editWafRule(rule, {
            parentMode: this.mode,
            modeDelegationAllowed: this.modeDelegationAllowed,
        });
    }
}

WafRuleGroupController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  wafRuleGroup
 * @description Component displaying information about a Waf Policy group.
 * @param {WafRuleGroupConfig} config - WafRuleGroupConfig config item.
 * @param {Function} onRemoveGroup - Called to remove a group.
 * @param {boolean} modeDelegationAllowed - If true, allows for overriding the parent mode.
 * @param {string} mode - Parent mode.
 * @param {Function} onEditGroup - Called to edit a group.
 * @param {WafPolicy} wafPolicy - WafPolicy instance.
 * @author alextsg
 */
angular.module('aviApp').component('wafRuleGroup', {
    controller: WafRuleGroupController,
    bindings: {
        wafGroup: '<config',
        onRemoveGroup: '&',
        modeDelegationAllowed: '<',
        mode: '@',
        onEditGroup: '&',
        wafPolicy: '<',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule-group/waf-rule-group.html',
});
