/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IItemArgs,
    IListResponse,
    Item,
} from 'ajs/modules/data-model/factories';

import {
    INetworkSubnet,
    ISubnetList,
} from 'generated-types';

interface IExtendedNetworkSubnet extends INetworkSubnet {
    nameWithSubnets?: string;
}

interface ISubnetListNetworkArgs extends IItemArgs<IExtendedNetworkSubnet> {
    cloudId?: string;
    fip_capable?: boolean;
}

/**
 * Ajs dependency token for SubnetListNetwork item.
 */
export const SUBNET_LIST_NETWORK_TOKEN = 'SubnetListNetwork';

/**
 * @description
 *
 *     Network Item for api/networksubnetlist which returns both configured
 *     and discovered networks.
 *
 *     Cloud id param is required for it to work properly.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export class SubnetListNetwork extends Item<IExtendedNetworkSubnet> {
    public static ajsDependencies = [
        'SubnetListNetworkDataTransformer',
    ];

    /**
     * Cloud Id of NetworkSubnet.
     */
    private readonly cloudId: string;

    /**
     * If true, subnet is capable of hosting public IP (Floating IP).
     */
    private readonly fipCapable: boolean;

    constructor(args = {} as unknown as ISubnetListNetworkArgs) {
        super({
            objectName: 'networksubnetlist',
            ...args,
        });

        this.cloudId = args.cloudId;
        this.fipCapable = args.fip_capable;
    }

    /** @override */
    public getName(): string {
        return this.getConfig().nameWithSubnets || super.getName();
    }

    /** @override */
    public isEditable(): boolean {
        return false;
    }

    /**
     * Returns a list of configured subnets for this Network.
     */
    public getSubnets(): ISubnetList[] {
        const config = this.getConfig();

        if ('subnet' in config) {
            return config.subnet.concat();
        }

        return [];
    }

    /**
     * Loads item through the list API with uuid=uuid as a filter.
     * Regular single record loading syntax is not provided by networksubnetlist API. :(
     * @override
     */
    protected loadConfig(): ng.IPromise<ng.IHttpResponse<INetworkSubnet>> {
        if (!this.cloudId) {
            const errMsg = `Can't load ${this.objectName} ${this.id} wo cloudId set`;

            console.error(errMsg);

            return Promise.reject(errMsg);
        }

        const headers = this.getLoadHeaders_();
        const params = this.getLoadParams();
        const paramStr = params.join('&');

        const {
            id,
            cloudId,
            fipCapable,
            objectName,
        } = this;

        let url = `/api/${objectName}?uuid=${id}&cloud_uuid=${cloudId}`;

        if (fipCapable) {
            url += `&fip_capable=${fipCapable}`;
        }

        if (paramStr) {
            url += `&${paramStr}`;
        }

        this.cancelRequests('config');

        return this.request(
            'get',
            url,
            undefined,
            headers,
            'config',
        )
            .then(this.onConfigLoad_.bind(this));
    }

    /** @override */
    protected onConfigLoad_(// eslint-disable-line no-underscore-dangle
        rsp: ng.IHttpResponse<IListResponse<INetworkSubnet>>,
    ): ng.IHttpResponse<IListResponse<INetworkSubnet>> {
        const { results: [config] } = rsp.data;

        if (config) {
            this.updateItemData({ config });
        }

        return rsp;
    }

    /** @override */
    protected transformAfterLoad(): void {
        const config = this.getConfig() || {};

        const SubnetListNetworkDataTransformer =
            this.getAjsDependency_('SubnetListNetworkDataTransformer');

        SubnetListNetworkDataTransformer.networkTransform(config);
    }
}
