/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtual-service-dns-records-controller';
const prefix = `${moduleName}.${componentName}`;

export const totalValuesHeader = `${prefix}.totalValuesHeader`;
export const columnTitleUdpQueries = `${prefix}.columnTitleUdpQueries`;
export const columnTitleErrors = `${prefix}.columnTitleErrors`;
export const columnTitleSource = `${prefix}.columnTitleSource`;
export const columnTitleService = `${prefix}.columnTitleService`;
export const columnTitleTenant = `${prefix}.columnTitleTenant`;

export const ENGLISH = {
    [totalValuesHeader]: 'Total Values',
    [columnTitleUdpQueries]: 'UDP Queries (/sec)',
    [columnTitleErrors]: 'Errors (/sec)',
    [columnTitleSource]: 'Source',
    [columnTitleService]: 'Service',
    [columnTitleTenant]: 'Tenant',
};
