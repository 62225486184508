/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { WAF_POLICY_ALLOWLIST_ACTION } from '../../waf-logs.service';
import '../../waf-log-entry.less';
import '../../waf-log-match-element.less';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.wafAllowlistLogBindings
 * @see {@link  module:avi/logs.wafAllowlistLogComponent}
 */
class WafAllowlistLogController {
    constructor(wafLogsService) {
        this.wafLogsService_ = wafLogsService;
    }

    /**
     * Returns a list of text to display based on a list of action enums.
     * @returns {string} - List of text to display from the action enums.
     */
    getActionLabels() {
        return this.wafLogsService_.getActionLabels(WAF_POLICY_ALLOWLIST_ACTION, this.log.actions);
    }
}

WafAllowlistLogController.$inject = [
    'wafLogsService',
];

/**
 * @name wafAllowlistLogComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.WafAllowlistLogController} controller
 * @property {module:avi/logs.wafAllowlistLogBindings} bindings
 * @description Component for a WAF Allowlist Log.
 * @author alextsg
 */
angular.module('avi/logs').component('wafAllowlistLog', {
    /**
     * @mixin wafAllowlistLogBindings
     * @memberOf module:avi/logs
     * @property {Object} log - WAF Allowlist Log object.
     * @property {boolean} expanded - Boolean to expand/collapse all.
     */
    bindings: {
        log: '<',
        expanded: '<',
    },
    controller: WafAllowlistLogController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'waf-allowlist-logs-list/waf-allowlist-log/waf-allowlist-log.component.html',
});
