/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'cloud-vrf-context';
const prefix = `${moduleName}.${componentName}`;

export const setCloudVrfContextHeader = `${prefix}.setCloudVrfContextHeader`;
export const cloudLabel = `${prefix}.cloudLabel`;
export const vrfContextLabel = `${prefix}.vrfContextLabel`;
export const notSetLabel = `${prefix}.notSetLabel`;
export const credentialsButtonLabel = `${prefix}.credentialsButtonLabel`;

export const ENGLISH = {
    [setCloudVrfContextHeader]: 'Set Cloud & VRF',
    [cloudLabel]: 'Cloud',
    [vrfContextLabel]: 'VRF Context',
    [notSetLabel]: 'Not Set',
    [credentialsButtonLabel]: 'Set Cloud & VRF',
};
