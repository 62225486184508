/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { StringMatch } from 'object-types';
import { isEmpty } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { StringMatchConfigItem }
    from 'ajs/modules/match/factories/string-match.config-item.factory';
import { IStringGroupRefsOrMatchStringsConfig } from '../../match.types';
import * as l10n from './string-match-config.l10n';
import './string-match-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      StringMatch Configuration component.
 *      (cannot be used as individual match ie doesnt have match wrapper).
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'string-match-config',
    templateUrl: './string-match-config.component.html',
})
export class StringMatchConfigComponent implements OnInit {
    /**
     * StringMatchConfigItem instance.
     */
    @Input()
    public editable: StringMatchConfigItem;

    /**
     * Unique Index to generate ID.
     */
    @Input()
    public id: number | string;

    /**
     * Label for string-group-or-custom-value element.
     */
    @Input()
    public stringLabel: string;

    /**
     * List of stringGroup and customValue configurations.
     */
    public stringGroupRefsOrCustomValuesConfigs: IStringGroupRefsOrMatchStringsConfig[] = [];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object types used in template.
     */
    public readonly objectType = StringMatch;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.stringLabel = l10nService.getMessage(l10nKeys.stringLabel);
    }

    /**
     * @override
     * Populates the list of stringGroupRefsOrCustomValuesConfigs with the configured refs and match
     * strings.
     */
    public ngOnInit(): void {
        const {
            string_group_refs: stringGroupRefs,
            match_str: matchStrings,
        } = this.editable.config;

        if (!isEmpty(stringGroupRefs)) {
            this.addItem({
                ...this.getDefaultConfig(),
                stringGroupRefs: [...stringGroupRefs],
            });
        }

        if (!isEmpty(matchStrings)) {
            this.addItem({
                ...this.getDefaultConfig(),
                matchStrings: [...matchStrings],
            });
        }

        if (isEmpty(stringGroupRefs) && isEmpty(matchStrings)) {
            this.addItem();
        }
    }

    /**
     * Called to add an Item.
     */
    public addItem(config: IStringGroupRefsOrMatchStringsConfig = this.getDefaultConfig()): void {
        this.stringGroupRefsOrCustomValuesConfigs.push(config);
    }

    /**
     * Called to remove an item.
     */
    public removeItem(index: number): void {
        this.stringGroupRefsOrCustomValuesConfigs.splice(index, 1);

        this.handleStringsChange();
    }

    /**
     * Called when a string or groupRef has changed.
     * We need to take all the configured strings and groupRefs add them to the match message item.
     */
    public handleStringsChange(): void {
        this.editable.clearStringGroupRefsAndMatchStrings();

        const allStringGroupRefs: string[] = [];
        const allMatchStrings: string[] = [];

        this.stringGroupRefsOrCustomValuesConfigs.forEach(config => {
            const { stringGroupRefs = [], matchStrings = [] } = config;

            allStringGroupRefs.push(...stringGroupRefs);
            allMatchStrings.push(...matchStrings);
        });

        this.editable.addStringGroupRefs(allStringGroupRefs);
        this.editable.addMatchStrings(allMatchStrings);
    }

    /**
     * TrackBy function for stringGroupRefs / customValues entries.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Returns an empty config object.
     */
    private getDefaultConfig(): IStringGroupRefsOrMatchStringsConfig {
        return {
            stringGroupRefs: [],
            matchStrings: [],
        };
    }
}
