/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRuleTransportProtocolMatchConfig
 * @description DnsPolicyRuleTransportProtocolMatch MatchConfigItem class.
 */
const dnsPolicyRuleTransportProtocolMatchController = (MatchConfigItem, schemaService) => {
    class DnsPolicyRuleTransportProtocolMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'IS_IN';
            config.protocol = config.protocol || 'DNS_OVER_UDP';
        }

        /** @override */
        toString() {
            const
                config = this.getConfig(),
                { protocol, match_criteria: matchCriteria } = config;

            if (protocol) {
                const value = schemaService.getEnumValue('MatchOperation', matchCriteria).label;
                const modifiedValue = value.replace(/ in/g, '');

                return `${modifiedValue.toLowerCase()} ${protocol.enumeration()}`;
            } else {
                return config.protocols;
            }
        }
    }

    angular.extend(DnsPolicyRuleTransportProtocolMatchConfig.prototype, {
        type: 'DnsTransportProtocolMatch',
        name: 'Transport Protocol',
        defaultPath: 'dnsrule.match.protocol',
    });

    return DnsPolicyRuleTransportProtocolMatchConfig;
};

dnsPolicyRuleTransportProtocolMatchController.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleTransportProtocolMatchConfig', dnsPolicyRuleTransportProtocolMatchController,
);
