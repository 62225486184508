/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Used when a function needs to be called on a change in ng-model and the ng-model value needs to
 * be validated.
 * @ngdoc directive
 * @name  changeAndValidate
 * @restrict A
 * @example <input type="text" change-and-validate="onChange()"/>
 */
angular.module('aviApp').directive('changeAndValidate', function() {
    return {
        scope: {
            changeAndValidate: '&',
        },
        require: 'ngModel',
        restrict: 'A',
        link(scope, elm, attr, ngModelCtrl) {
            ngModelCtrl.$validators.changeAndValidate = function(ngModelValue) {
                if (!ngModelValue) {
                    return true;
                }

                return scope.changeAndValidate({ newValue: ngModelValue });
            };
        },
    };
});
