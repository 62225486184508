/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    VRFContext,
    VRFContextCollection,
} from 'ajs/modules/vrf-context/factories';

import {
    InfraCloudState,
    INFRA_CLOUD_STATE_SERVICE_TOKEN,
    vrfContextCollectionChangeEvent,
} from 'ajs/modules/vrf-context/services';

import {
    Auth,
    AUTH_SERVICE_TOKEN,
    AviConfirmService,
} from 'ajs/modules/core/services';

import { L10nService } from '@vmw/ngx-vip';
import template from './bgp-profile-list.component.html';
import * as l10n from './bgp-profile-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for BGP profile List page.
 *
 * @author Aravindh Nagarajan
 */
class BgpProfileListController {
    /**
     * VRFContextCollection instance.
     */
    public vrfContextCollection: VRFContextCollection;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Flag to control loading state of VRFContext list.
     */
    public isLoading = false;

    constructor(
        private readonly infraCloudState: InfraCloudState,
        private readonly auth: Auth,
        private readonly aviConfirmService: AviConfirmService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.handleVrfCollectionChange();

        this.infraCloudState.on(vrfContextCollectionChangeEvent, this.handleVrfCollectionChange);
    }

    /**
     * Checks if BGP can be modified.
     */
    public isInTenant(vrfContext: VRFContext): boolean {
        const currentTenant = this.auth.getTenantName();

        return vrfContext.isInTenant(currentTenant);
    }

    /**
     * Handler for 'vrfContextCollectionChange' event. Reloads the VRF Context lists.
     */
    public handleVrfCollectionChange = (): void => {
        this.vrfContextCollection = this.infraCloudState.getVrfContextCollection();
        this.loadVRFCollection();
    };

    /**
     * @override
     */
    public $onDestroy(): void {
        const { infraCloudState } = this;

        infraCloudState.unbind(
            vrfContextCollectionChangeEvent,
            this.handleVrfCollectionChange,
        );
    }

    /**
     * Returns the l10nKey to be displayed when custom VRF Contexts exist based on the vrfContext's
     * bgpProfile isDisabled status.
     */
    public getCustomBgpPeeringLabelKey(vrfContext: VRFContext): string {
        return vrfContext.bgpProfile.isDisabled ?
            this.l10nKeys.bgpPeeringDisabledWithVrfLabel :
            this.l10nKeys.bgpPeeringWithVrfLabel;
    }

    /**
     * Opens aviConfirmationDialog, if user confirms, removes bgp_profile from vrf_context.
     */
    public onBgpProfileDeactivate(vrfContext: VRFContext): void {
        this.isLoading = true;

        this.aviConfirmService.prompt('deactivate-bgp-peering-confirmation', {
            vrfContextName: vrfContext.getName(),
        })
            .then(async() => {
                await vrfContext.deactivateBgpProfile();

                this.loadVRFCollection();
            })
            .finally(() => this.isLoading = false);
    }

    /**
     * Since collection is carried over all tabs of Routing section we want to make sure it
     * loads what we need it to load for the component. Also kicks off page rendering.
     */
    private loadVRFCollection = async(): Promise<void> => {
        this.vrfContextCollection.updateViewportSize(200, true, true);

        await this.vrfContextCollection.load(undefined, true);
    };
}

BgpProfileListController.$inject = [
    INFRA_CLOUD_STATE_SERVICE_TOKEN,
    AUTH_SERVICE_TOKEN,
    'AviConfirmService',
    'l10nService',
];

/**
 * Ajs name of the component.
 */
export const BGP_PROFILE_LIST_COMPONENT_TOKEN = 'bgpProfileList';

export const bgpProfileListComponentOptions = {
    controller: BgpProfileListController,
    template,
};
