/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { isString } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { ClrDatagridComparatorInterface, ClrDatagridStateInterface } from '@clr/angular';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import {
    AviDataGridFieldVisibility,
    IAviDataGridConfig,
    IAviDataGridConfigField,
    IAviDataGridLayout,
    IAviDataGridMultipleaction,
    IAviDataGridRow,
    IAviDataGridSingleaction,
    TAviDataGridColumnPresenceMap,
} from '../avi-data-grid/avi-data-grid.types';
import './avi-data-grid-base.component.less';
import * as l10n from './avi-data-grid-base.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const defaultLayout: IAviDataGridLayout = {
    showFooter: false,
};

/**
 * Calls either an action on a list of rows (multipleaction) or a single row (singleaction). We use
 * a try-catch here because if we were to think about AviDataGrid as a component from a third-party
 * library, we wouldn't want to rely on action.onClick being error-free and have the component break
 * down if it fails.
 */
const callAction = (
    action: IAviDataGridSingleaction | IAviDataGridMultipleaction,
    rows: IAviDataGridRow | IAviDataGridRow[],
): void => {
    try {
        action.onClick(rows);
    } catch (error) {
        throw new Error(`action failed: ${error}`);
    }
};

/**
 * @description
 *
 *     Grid component, created as a wrapper around Clarity's Datagrid component.
 *
 *     Should only be utilized for extension purposes and not be exposed for any direct use.
 *     This component serves as a base to provide essential functionalities to build grid components
 *     with more specific purpose such as avi-data-grid and avi-collection-data-grid.
 *
 *     When using custom templates for cells, we need to use templateRefs as creating a template
 *     from a string is not supported in Angular. This means that the parent component needs to
 *     create a templateRef variable, then pass it into config.fields in ngAfterViewInit. The outlet
 *     context contains the row and the index.
 *
 *     Please refer to avi-data-grid.stories.ts for an example.
 *
 *     Note: Clarity's datagrid has an issue with displaying columns when rows are empty. If you try
 *     to update fields while rows is an empty array, those fields will not get displayed. As a
 *     workaround, we use the afterViewInit flag to render the clarity datagrid, to allow the parent
 *     component to update its fields in its own ngAfterViewInit hook.
 *
 * @author alextsg, Aravindh Nagarajan, Zhiqian Liu
 */
@Component({
    selector: 'avi-data-grid-base',
    templateUrl: './avi-data-grid-base.component.html',
})
export class AviDataGridBaseComponent implements OnInit, AfterViewInit, OnChanges, DoCheck {
    /**
     * Grid configuration object containing getRowId, multipleactions, etc.
     */
    @Input()
    public config: IAviDataGridConfig;

    /**
     * Data to be displayed by the grid for the current page.
     */
    @Input()
    public rows: IAviDataGridRow[] = [];

    /**
     * Total count of data to be displayed.
     */
    @Input()
    public rowsTotal: number;

    /**
     * Max number of items displayed at one time for a grid page with default size 10.
     */
    @Input()
    public pageSize ? = 10;

    /**
     * Indicate whether the row data is being loaded.
     */
    @Input()
    public isLoading ? = false;

    /**
     * Fires on row selection change.
     */
    @Output()
    public onSelectionChange = new EventEmitter<IAviDataGridRow[]>();

    /**
     * Fires on Row order change.
     */
    @Output()
    public onRowOrderChange = new EventEmitter<CdkDragDrop<IAviDataGridRow>>();

    /**
     * Parent callback to update row data through API calls.
     * Called when states of the data grid changes (bound by ClrDatagridStateInterface.)
     * i.e., pagination, sorting or filtering changes will trigger the call.
     */
    @Output()
    public onDataGridStateChange = new EventEmitter<ClrDatagridStateInterface>();

    /**
     * Fires on search is confirmed by 'Enter' or emptied by the clear icon.
     */
    @Output()
    public onSearch = new EventEmitter<string>();

    @ViewChild('searchInput')
    private searchInputRef: ElementRef;

    /**
     * Cache config.fields for implementing a custom change detection.
     */
    public fields: IAviDataGridConfigField[] = [];

    /**
     * List of selected rows.
     */
    public selected: IAviDataGridRow[] | undefined;

    /**
     * Boolean set to true after the ngAfterViewInit hook has been called, used as a workaround to
     * fix Clarity datagrid issues.
     */
    public afterViewInit = false;

    /**
     * If true, displays the singleactions column.
     */
    public hasSingleactions = false;

    /**
     * Width of the singleactions column in pixels.
     */
    public singleactionsWidth = 0;

    /**
     * If true, shows the column containing the expander icon.
     */
    public showExpander = false;

    /**
     * Whether search is included.
     */
    public hasSearch = false;

    /**
     * Token used for search.
     */
    public searchValue: string;

    /**
     * If true, show the search bar, otherwise show search icon only.
     */
    public showSearchBar = false;

    /**
     * Map managed by avi-data-grid-column-selection component to toggle true/false for a field ID,
     * which shows/hides a corresponding field column.
     */
    public showColumnHash: TAviDataGridColumnPresenceMap = {};

    /**
     * Width in pixels to be used for icon cells.
     */
    public readonly iconWidth = 40;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Map for keeping track of which rows have been expanded, based on the rowId.
     */
    private readonly expandedRows = new Map<string | number, boolean>();

    public constructor(private l10nService: L10nService) {
        this.l10nService = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.selected = this.getDefaultSelected();
        this.hasSearch = this.onSearch.observers.length > 0;
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.setDisplayProps();

        this.afterViewInit = true;
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { config } = changes;

        if (config) {
            if (config.firstChange) {
                return;
            }

            this.setDisplayProps();
        }
    }

    /**
     * @override
     * Used to add a custom change detection for this.config.fields. This detection is needed
     * because Angular is not able to detect any changes of fields since it's not directly bound to
     * the template.
     */
    public ngDoCheck(): void {
        if (this.config?.fields !== this.fields) {
            this.fields = this.config?.fields || [];

            this.setShowColumnHash();
        }
    }

    /**
     * Getter for fields that are not hidden by column selection.
     */
    public get filteredFields(): IAviDataGridConfigField[] {
        return this.fields.filter(({ id }) => this.showColumnHash[id]);
    }

    /**
     * Returns a list of multipleactions - actions to be performed on selected data.
     */
    public get multipleactions(): IAviDataGridMultipleaction[] {
        return this.config?.multipleactions || [];
    }

    /**
     * Returns either the configured layout or the default layout.
     */
    public get layout(): IAviDataGridLayout {
        return this.config?.layout || { ...defaultLayout };
    }

    /**
     * Getter for the placeholder of search input with a default value.
     */
    public get searchInputPlaceholder(): string {
        return this.layout.searchInputPlaceholder ||
            this.l10nService.getMessage(l10nKeys.defaultSearchInputPlaceholder);
    }

    /**
     * Decide if selection of a row should be disabled due to constraints.
     */
    public isRowSelectable(row: IAviDataGridRow): boolean {
        const { config } = this;

        return config.rowSelectionDisabled ? !config.rowSelectionDisabled(row) : true;
    }

    /**
     * Calls the action and clears selected rows.
     */
    public callMultipleaction(action: IAviDataGridMultipleaction): void {
        callAction(action, this.selected);
        this.selected = this.getDefaultSelected();
    }

    /**
     * When the grid state changes with latest info, update the stored data grid state and call to
     * update data accordingly.
     */
    public refresh(dataGridState: ClrDatagridStateInterface): void {
        this.onDataGridStateChange.emit(dataGridState);
    }

    /**
     * Calls a singleaction on a row.
     */
    public callSingleaction(action: IAviDataGridSingleaction, row: IAviDataGridRow): void {
        callAction(action, row);
        this.selected = this.getDefaultSelected();
    }

    /**
     * trackBy function for each field.
     */
    public trackByFieldId(index: number, field: IAviDataGridConfigField): string {
        return field?.id || '';
    }

    /**
     * trackBy function for row data.
     */
    public trackByRowId = (index: number, row: IAviDataGridRow): string | number => {
        return this.config?.getRowId(index, row);
    };

    /**
     * Called to get the value of disabled callback function on sinlge-actions, if present.
     * Else do not disable actions.
     */
    public isSingleActionDisabled(action: IAviDataGridSingleaction, row: IAviDataGridRow): boolean {
        if (action.disabled) {
            return action.disabled(row);
        } else {
            return false;
        }
    }

    /**
     * Called to get the value of disabled callback function on multi-actions, if present.
     * Else do not disable actions.
     */
    public isMultipleActionDisabled(action: IAviDataGridMultipleaction): boolean {
        if (action.disabled) {
            return action.disabled(this.selected);
        } else {
            return false;
        }
    }

    /**
     * trackBy function for actions.
     */
    public trackByActionLabel(index: number, action: IAviDataGridMultipleaction): string {
        return action?.label || '';
    }

    /**
     * Returns a placeholder message to be displayed if there are no items to display.
     */
    public get placeholderMessage(): string {
        const { l10nService } = this;

        return this.layout.placeholderMessage ||
            l10nService.getMessage(l10nKeys.defaultEmptyListPlaceholderMessage);
    }

    /**
     * Hides Pagination for Grid when hidePagination is set "true" or
     * withReordering is set "true".
     */
    public get hidePagination(): boolean {
        const { hidePagination, withReordering } = this.config.layout || {};

        return Boolean(hidePagination) || Boolean(withReordering);
    }

    /**
     * Sets the expanded state on the expandedRows Map.
     */
    public toggleExpanded(rowIndex: number, row: IAviDataGridRow): void {
        const expanded = this.isExpanded(rowIndex, row);

        this.expandedRows.set(this.config.getRowId(rowIndex, row), !expanded);
    }

    /**
     * Retrieves the expanded state from the expandedRows Map.
     */
    public isExpanded(rowIndex: number, row: IAviDataGridRow): boolean {
        return Boolean(this.expandedRows.get(this.config.getRowId(rowIndex, row)));
    }

    /**
     * Emits selected rows on selectionChange event.
     */
    public selectionChanged(selectedRows: IAviDataGridRow[]): void {
        // for two-way binding of this.selected.
        this.selected = selectedRows;

        this.onSelectionChange.emit(this.selected);
    }

    /**
     * Emits onRowOrderChange event to parent with CdkDragDrop event.
     */
    public emitRowOrderChange(event: CdkDragDrop<IAviDataGridRow>): void {
        this.onRowOrderChange.emit(event);
    }

    /*
     * Generate a comparator for sorting by a grid column field to conform with the type required by
     * [clrDgSortBy].
     * Designed so that both "property name string" and "function" sortings are available for
     * regular data grids as long as an implementation is added in a data-grid component based on
     * the DataGridState bubbled up from data-grid-base, while only "string" sorting is available
     * for a collection-data-grid since the implementation shouldn't contain a function comparator
     * interface according to its server-driven nature.
     */
    // eslint-disable-next-line class-methods-use-this
    public getSortingComparator(
        configField: IAviDataGridConfigField,
    ): string | ClrDatagridComparatorInterface<IAviDataGridRow> | undefined {
        const { sortBy } = configField;

        if (sortBy) {
            if (isString(sortBy)) {
                return sortBy;
            } else {
                return {
                    compare(a: IAviDataGridRow, b: IAviDataGridRow) {
                        return sortBy(a, b);
                    },
                };
            }
        }
    }

    /**
     * Search by string value. Called when search is confirmed or cleared.
     */
    public search(): void {
        this.onSearch.emit(this.searchValue);
    }

    /**
     * Expand the search section with a search bar and a clear icon.
     */
    public expandSearchBar(): void {
        this.showSearchBar = true;

        setTimeout(() => {
            this.searchInputRef.nativeElement.focus();
        });
    }

    /**
     * Clear the search input field.
     */
    public clearSearch(): void {
        this.searchValue = undefined;
        this.showSearchBar = false;

        this.search();
    }

    /**
     * Returns the default value to use for the selected property, which is used by clr-datagrid.
     * If undefined, the checkboxes will not be rendered.
     */
    private getDefaultSelected(): IAviDataGridRow[] | undefined {
        return this.layout.hideCheckboxes ? undefined : [];
    }

    /**
     * Sets datagrid display properties based on the config. Called in initialization as well as on
     * config changes.
     */
    private setDisplayProps(): void {
        if (!this.config) {
            return;
        }

        const { singleactions = [], expandedContentTemplateRef } = this.config;
        const { length: singleactionsLength } = singleactions;

        this.singleactionsWidth = singleactionsLength * this.iconWidth;
        this.hasSingleactions = Boolean(singleactionsLength);
        this.showExpander = Boolean(expandedContentTemplateRef);

        this.setShowColumnHash();
    }

    /**
     * Initially show fields with visibility of MANDATORY, DEFAULT or no visibility set, and hide
     * fields with visibility of OPTIONAL.
     */
    private setShowColumnHash(): void {
        this.fields.forEach(({ id, visibility }) => {
            if (visibility === AviDataGridFieldVisibility.OPTIONAL) {
                this.showColumnHash[id] = false;
            } else {
                this.showColumnHash[id] = true;
            }
        });
    }
}
