/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'custom-ipam-dns-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const scriptInputLabel = `${prefix}.scriptInputLabel`;
export const scriptHeaderWithCount = `$${prefix}.scriptHeaderWithCount`;
export const addButtonLabel = `${prefix}.addButtonLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Custom IPAM/DNS Profile: {0}',
    [modalTitleNew]: 'New Custom IPAM/DNS Profile: {0}',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Enter Name',
    [scriptInputLabel]: 'Script',
    [scriptHeaderWithCount]: 'Script Params ({0})',
    [addButtonLabel]: 'Add',
};
