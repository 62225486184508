/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-detection-result-match-grid';
const prefix = `${moduleName}.${componentName}`;

export const classificationsLabelWithCount = `${prefix}.classificationLabelWithCount`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeActionLabel = `${prefix}.removeActionLabel`;
export const classificationLabel = `${prefix}.classificationLabel`;
export const valueLabel = `${prefix}.valueLabel`;
export const classificationPlaceHolder = `${prefix}.classificationPlaceHolder`;
export const valuePlaceHolder = `${prefix}.valuePlaceHolder`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [classificationsLabelWithCount]: 'Classifications ({0})',
    [removeActionLabel]: 'Remove',
    [classificationLabel]: 'Classification',
    [valueLabel]: 'Value',
    [classificationPlaceHolder]: 'Select Classification',
    [valuePlaceHolder]: 'Enter Value',
};
