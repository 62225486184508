/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-rule';
const prefix = `${moduleName}.${componentName}`;

export const ruleWithCustomModeAndExceptionsConfiguredMessage = `${prefix}.ruleWithCustomModeAndExceptionsConfiguredMessage`;
export const ruleWithExceptionsConfiguredMessage = `${prefix}.ruleWithExceptionsConfiguredMessage`;
export const ruleWithCustomModeConfiguredMessage = `${prefix}.ruleWithCustomModeConfiguredMessage`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const showRuleButtonLabel = `${prefix}.showRuleButtonLabel`;
export const hideRuleButtonLabel = `${prefix}.hideRuleButtonLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const deactivatedLabel = `${prefix}.deactivatedLabel`;
export const statusLabel = `${prefix}.statusLabel`;
export const ruleModeLabel = `${prefix}.ruleModeLabel`;
export const ruleLabel = `${prefix}.ruleLabel`;

export const ENGLISH = {
    [ruleWithCustomModeAndExceptionsConfiguredMessage]: 'This rule has a custom mode and exceptions configured.',
    [ruleWithExceptionsConfiguredMessage]: 'This rule has exceptions configured.',
    [ruleWithCustomModeConfiguredMessage]: 'This rule has a custom mode configured.',
    [emptyDataLabel]: 'N/A',
    [showRuleButtonLabel]: 'Show Rule',
    [hideRuleButtonLabel]: 'Hide Rule',
    [enabledLabel]: 'Enabled',
    [deactivatedLabel]: 'Deactivated',
    [statusLabel]: 'Status',
    [ruleModeLabel]: 'Rule Mode',
    [ruleLabel]: 'Rule',
};
