/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import {
    ActiveUserProfileService,
    Auth,
} from 'ajs/modules/core/services';
import './user-menu.component.less';

/**
 * @desc Component for user menu component.
 * @author alextsg
 */
@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
})
export class UserMenuComponent implements OnInit {
    /**
     * Current user's activity. Uses the "any" type as the TS type has not yet been created.
     */
    public userActivity: any = null;

    /**
     * Used to load the userActivity. Also passed to the UserCardMenu component.
     */
    public readonly username: string;

    constructor(
        private readonly authService: Auth,
        @Inject('User')
        private readonly User: any,
        activeUserProfileService: ActiveUserProfileService,
    ) {
        this.username = activeUserProfileService.username;
    }

    /** @override */
    public ngOnInit(): void {
        // Load useractivity only when current user has 'PERMISSION_USER' access.
        if (this.authService.isAllowed('user')) {
            this.setUserActivity();
        }
    }

    /**
     * Sets the userActivity property.
     */
    private async setUserActivity(): Promise<void> {
        try {
            this.userActivity = await this.User.loadUserActivity(this.username);
        } catch (e) {
            // empty catch block
        }
    }
}
