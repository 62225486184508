/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';
import {
    FederationCheckpoint,
    FederationCheckpointCollection,
} from 'ajs/modules/gslb';
import { AviConfirmService as AviConfirmServiceClass } from 'ajs/modules/core/services';
import { naturalSort } from 'ajs/modules/core/constants';
import { IFederationCheckpoint } from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './gslb-checkpoints-page.l10n';

import './gslb-checkpoints-page.component.less';

import template from './gslb-checkpoints-page.component.html';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TFederationCheckpointCollection = typeof FederationCheckpointCollection;
type TFederationCheckpoint = typeof FederationCheckpoint;
type TNaturalSort = typeof naturalSort;

class GslbCheckpointsPageController {
    public gridConfig: ICollectionGridConfig;

    public l10nKeys: {};

    /** Creation date/time of currently active checkpoint. */
    private activeCheckpointCreationTime = '';

    private gslb: any;

    /** @constructor */
    constructor(
        private AviConfirmService: AviConfirmServiceClass,
        private FederationCheckpoint: TFederationCheckpoint,
        private FederationCheckpointCollection: TFederationCheckpointCollection,
        private naturalSort: TNaturalSort,
        public l10nService: L10nService,
    ) {
        this.l10nKeys = l10nKeys;
        this.l10nService = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.createCheckpointsGrid();
        this.setActiveCheckpointCreationTime();
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
        this.gslb.destroy();
    }

    /** Sets var activeCheckpointCreationTime with creation date/time of active checkpoint. */
    private setActiveCheckpointCreationTime(): void {
        const { activeCheckpointId } = this.gslb;

        if (!activeCheckpointId) {
            this.activeCheckpointCreationTime = '';

            return;
        }

        const activeCheckpoint = new this.FederationCheckpoint({
            id: activeCheckpointId,
        });

        activeCheckpoint.load()
            .then(() => {
                this.activeCheckpointCreationTime = activeCheckpoint.date;
            }).finally(() => {
                activeCheckpoint.destroy();
            });
    }

    /** Creates gridConfig needed for collectionGrid in view. */
    private createCheckpointsGrid(): void {
        this.gridConfig = createCrudCollectionGridConfig();

        const { gridConfig, l10nService } = this;

        gridConfig.collection = new this.FederationCheckpointCollection();

        const dateTemplate =
            `<div class="gslb-checkpoints-page__grid-column-timestamp">
                 <i
                     class="icon-star gslb-checkpoints-page__icon-star"
                     ng-class="{ 'gslb-checkpoints-page__icon-star--invisible': !row.isActive }"
                 ></i>
                 <span>{{ row.date | aviDate }}</span>
             </div>`;

        gridConfig.fields = [{
            name: 'data.config.date',
            title: l10nService.getMessage(l10nKeys.columnTitleTimestamp),
            sortBy: 'date',
            template: dateTemplate,
        }, {
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
        }];

        gridConfig.singleactions.push({
            class: 'icon-trash',
            do: (row: FederationCheckpoint) => row.drop(),
            title: l10nService.getMessage(l10nKeys.actionTitleDelete),
        }, {
            class: 'icon-star',
            do: (row: FederationCheckpoint) => {
                const { uuid: id } = row.getConfig();
                const name = row.getName();

                this.confirmMakeCheckpointActive(name, id);
            },
            hidden: (row: FederationCheckpoint) => {
                if (!this.activeCheckpointCreationTime) {
                    return false;
                }

                return !this.isLessRecentThanActiveCheckpoint(row.date);
            },
            title: l10nService.getMessage(l10nKeys.actionTitleSetToActive),
        });
    }

    /**
     * Returns true if checkpoint is more recent than active.
     * @param checkpointTime - time/date created of checkpoint to be compared
     */
    private isLessRecentThanActiveCheckpoint(
        checkpointTime: IFederationCheckpoint['date'],
    ): boolean {
        return this.naturalSort(this.activeCheckpointCreationTime, checkpointTime) < 0;
    }

    /**
     * Provides confirmation dialog if user tries to make active more recent checkpoint.
     * @param name - name of checkpoint to make active
     * @param id - id of checkpoint to make active
     */
    private confirmMakeCheckpointActive(
        name: IFederationCheckpoint['name'],
        id: IFederationCheckpoint['uuid'],
    ): void {
        const { l10nService } = this;

        const message = l10nService.getMessage(l10nKeys.confirmMessage, [name]);

        this.AviConfirmService.confirm(message)
            .then(() => {
                this.updateGslbCheckpointRef(id);
            });
    }

    /**
     * Updates gslb item to mark checkpoint as active.
     * @param id - id of checkpoint to make active
     */
    private updateGslbCheckpointRef(id: IFederationCheckpoint['uuid']): void {
        this.gslb.setActiveReplicationCheckpoint(id).then(() => {
            // Upon successful patch, gslbSite is updated, but cp table has no idea, thus:
            this.updateCheckpointsTable();
            this.setActiveCheckpointCreationTime();
        });
    }

    /** Forces update of checkpoint table. */
    private updateCheckpointsTable(): void {
        this.gridConfig.collection.load();
    }
}

GslbCheckpointsPageController.$inject = [
    'AviConfirmService',
    'FederationCheckpoint',
    'FederationCheckpointCollection',
    'naturalSort',
    'l10nService',
];

export const gslbCheckpointsPageOptions = {
    bindings: {
        gslb: '<resolveGslb',
    },
    controller: GslbCheckpointsPageController,
    template,
};
