/**
 * @module VsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IVip } from 'generated-types';
import {
    InventoryDataTransformer,
} from 'ajs/modules/data-model/factories/inventory-data-transformer.factory';
import { OperStateToTypeMap } from '../vs.constants';
import { IExtendedVipRuntimeDetail } from '../vs.types';

/**
 * @desc
 *
 *   DataTransformer for the VsVipCollection. Currently runtime object contains vip_id and
 *   other runtime properties such as oper_status etc. Here we populate runtime object with
 *   ip_address from vip object for the matching oper_statuses.
 *
 * @author Ram Pal
 */
export class VsVipInventoryDataTransformer extends InventoryDataTransformer {
    public responseListOffsetControl_: (resp: any, request: any) => any;

    /** @override */
    public processResponse(resp: any, request: any): any {
        resp = this.responseListOffsetControl_(resp, request);

        const { data: { results } } = resp;

        if (Array.isArray(results)) {
            results.forEach((vsVip: Record<string, any>) => {
                const { config: { vip: vips }, runtime: vipsRuntime } = vsVip;

                const ipAddressByIdMap = new Map();
                const ip6AddressByIdMap = new Map();

                vips.forEach(({
                    vip_id: vipId,
                    ip_address: ipAddress,
                    ip6_address: ip6Address,
                }: IVip) => {
                    if (ipAddress) {
                        ipAddressByIdMap.set(vipId, ipAddress);
                    }

                    if (ip6Address) {
                        ip6AddressByIdMap.set(vipId, ip6Address);
                    }
                });

                vipsRuntime.forEach((vipRuntimeData: IExtendedVipRuntimeDetail) => {
                    const { vip_id: vipId, oper_status: { state } } = vipRuntimeData;

                    vipRuntimeData.ip_address = ipAddressByIdMap.get(vipId);
                    vipRuntimeData.ip6_address = ip6AddressByIdMap.get(vipId);
                    vipRuntimeData.operStateType = OperStateToTypeMap.get(state);
                });
            });
        }

        return resp;
    }
}
