/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @description Alert Metric collection item.
 */
angular.module('aviApp').factory('AlertMetricsItem', ['Item',
function(Item) {
    class AlertMetricsItem extends Item {
        /** @override */
        isEditable() {
            return false;
        }
    }

    AlertMetricsItem.prototype.objectName = 'analytics/metric_id';

    return AlertMetricsItem;
}]);
