/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *
 *   BGP Peer Message Item.
 *
 * @author Aravindh Nagarajan
 */

import {
    IpAddrConfigItem,
    IpAddrPrefixConfigItem,
    MessageItem,
} from 'ajs/modules/data-model/factories';

import { BgpPeerModalComponent } from 'ng/modules/vrf-context';
import { IItemParams, withFullModalMixin } from 'ajs/js/utilities/mixins';
import { IBgpPeer } from 'generated-types';
import { BgpPeer } from 'object-types';
import { Component, Type } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { BgpTypes } from './bgp-profile.config-item.factory';
import * as l10n from '../vrf-context.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IBgpPeerConfig extends IBgpPeer {
    peer_ip: IpAddrConfigItem;
    subnet: IpAddrPrefixConfigItem;
    peer_ip6: IpAddrConfigItem;
    subnet6: IpAddrPrefixConfigItem;
}

interface IBgpPeerParams extends IItemParams {
    bgpProfileType: BgpTypes;
}

/**
 * Ajs dependency token for BgpPeerConfigItem.
 */
export const BGP_PEER_CONFIG_ITEM_TOKEN = 'BgpPeerConfigItem';

export class BgpPeerConfigItem extends
    withFullModalMixin(MessageItem)<IBgpPeerConfig> {
    public static ajsDependencies = ['l10nService'];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: BgpPeer,
            windowElement: BgpPeerModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'peer_ip',
            'subnet',
            'peer_ip6',
            'subnet6',
        ];
    }

    /** @override */
    protected getModalBreadcrumbTitle(params: IItemParams): string {
        const { bgpProfileType } = params as IBgpPeerParams;

        return this.l10nService.getMessage(l10nKeys.bgpPeerLabel, [bgpProfileType]);
    }
}
