/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'dns';

export const dnsModalBreadcrumbTitle = `${prefix}.dnsModalBreadcrumbTitle`;

export const ENGLISH = {
    [dnsModalBreadcrumbTitle]: 'DNS',
};
