/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    ErrorPageBodyCollection,
    ErrorPageConfigItem,
    TErrorPageBodyCollection,
} from 'ajs/modules/error-page';

import * as l10n from './error-page-profile-list-modal.l10n';
import './error-page-profile-list-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * ErrorPageProfileList modal component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'error-page-profile-list-modal',
    templateUrl: './error-page-profile-list-modal.component.html',
})
export class ErrorPageProfileListModalComponent implements OnInit {
    /**
     * ErrorPageConfigItem instance.
     */
    @Input()
    public editable: ErrorPageConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ErrorPageProfileList object type.
     */
    public objectType: string;

    /**
     * Layout for ErrorPageProfileList modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * collection of Error Page Body.
     */
    public errorPageBodyCollection: ErrorPageBodyCollection;

    /**
     * statusCodesAndRanges will hold codes and ranges flatten array of type string.
     */
    public statusCodesAndRanges: string[];

    constructor(
        l10nService: L10nService,
        @Inject(ErrorPageBodyCollection)
        ErrorPageBodyCollection: TErrorPageBodyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.errorPageBodyCollection = new ErrorPageBodyCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.statusCodesAndRanges = this.editable.getErrorPageStatusCodes();
    }

    /**
     * Handler to set status codes and ranges.
     */
    public handleStatusCodesAndRangesChange(): void {
        this.editable.setStatusCodesAndRanges(this.statusCodesAndRanges);
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
