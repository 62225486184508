/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function subnetListNetworkDataTransformerFactory(
    ListDataTransformer,
    getSubnetString,
) {
    /**
     * @constructor
     * @memberOf module:avi/network
     * @extends module:avi/dataModel.ListDataTransformer
     * @desc
     *
     *     Appending list of subnets to the network name.
     *     Used by {@link module:avi/network.SubnetListNetworkCollection
     *     SubnetListNetworkCollection}.
     */
    class SubnetListNetworkDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            resp = super.processResponse(resp, request);

            const { data } = resp;

            if (data && 'results' in data && Array.isArray(data.results)) {
                data.results.forEach(
                    ({ config }) => SubnetListNetworkDataTransformer.networkTransform(config),
                );
            }

            return resp;
        }

        /**
         * SubnetListNetwork transformer function.
         * @param {Object} network
         */
        static networkTransform(network) {
            network['nameWithSubnets'] = network['name'];

            const { subnet: subnets } = network;

            if (Array.isArray(subnets) && subnets.length) {
                const subnetIds = subnets.map(({ prefix }) => getSubnetString(prefix));

                network['nameWithSubnets'] += ` - ${subnetIds.join(', ')}`;
            }
        }
    }

    return SubnetListNetworkDataTransformer;
}

subnetListNetworkDataTransformerFactory.$inject = [
    'ListDataTransformer',
    'getSubnetString',
];

angular.module('avi/network')
    .factory('SubnetListNetworkDataTransformer', subnetListNetworkDataTransformerFactory);
