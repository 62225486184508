/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    AviPermissionResource,
    HSMType,
    ICluster,
    IHardwareSecurityModuleGroup,
    IServiceEngine,
} from 'generated-types';

import { HardwareSecurityModuleGroup } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { HSMGroupModalComponent } from 'ng/modules/security';
import { L10nService } from '@vmw/ngx-vip';
import { HardwareSecurityModuleConfigItem } from './hardware-security-module.config-item.factory';
import * as l10n from './hsm-group.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IServiceEngineConfig {
    count: number;
    results: IServiceEngine[];
}

type THardwareSecurityModuleGroupPartial = Omit<IHardwareSecurityModuleGroup, 'hsm'>;

interface IHardwareSecurityModuleGroupConfig extends THardwareSecurityModuleGroupPartial {
    hsm?: HardwareSecurityModuleConfigItem;
}

interface IHardwareSecurityModuleGroupData {
    config: IHardwareSecurityModuleGroupConfig;
}

/**
 * Constants used in the class.
 */
const SE_MGMT_NETWORK_URL = '/api/serviceengine?search=(is_mgmt,true)&fields=' +
 'mgmt_vnic.vnic_networks.ip.ip_addr.addr,mgmt_vnics.is_mgmt';

const SE_DEDICATED_NETWORK_URL = '/api/serviceengine?search=(is_hsm,true)&' +
 'fields=data_vnics.vnic_networks.ip.ip_addr.addr,data_vnics.is_hsm';

const CLUSTER_URL = '/api/cluster';

/**
 * @description HSM Group Item.
 * @author vgohil
 */
export class HSMGroup extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IHardwareSecurityModuleGroupData;

    /**
     * L10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'hardwaresecuritymodulegroup',
            windowElement: HSMGroupModalComponent,
            objectType: HardwareSecurityModuleGroup,
            permissionName: AviPermissionResource.PERMISSION_HARDWARESECURITYMODULEGROUP,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Combines responses into the list of IP addresses.
     */
    protected static transformNodesResponse([cluster, se]:
    [IHttpResponse<ICluster>, IHttpResponse<IServiceEngineConfig>]): string[] {
        const { nodes } = cluster.data;
        const ips = nodes.map(node => node.ip.addr);
        const { url: seApiUrl } = se.config;
        const { results } = se.data;

        if (seApiUrl.includes('is_hsm')) {
            results.forEach(seItem => {
                const dataVnics = seItem.data_vnics;

                if (dataVnics) {
                    dataVnics.forEach(vnicItem => {
                        if (vnicItem.is_hsm) {
                            ips.push(vnicItem.vnic_networks[0].ip.ip_addr.addr);
                        }
                    });
                }
            });
        } else {
            results.forEach(seItem => {
                const vnicNetworks = seItem.mgmt_vnic?.vnic_networks;

                if (vnicNetworks) {
                    vnicNetworks.forEach(vnicItem => ips.push(vnicItem.ip.ip_addr.addr));
                }
            });
        }

        return ips;
    }

    /**
     * Returns HSM type.
     */
    public getType(): string {
        const { hsm } = this.getConfig();

        if (!hsm) {
            return;
        }

        return hsm.config.type;
    }

    /**
     * Tweaks config properties based on the current type.
     */
    public onTypeChange(): void {
        const { hsm } = this.getConfig();
        const type = this.getType();
        const propsToDrop = [];

        switch (type) {
            case HSMType.HSM_TYPE_SAFENET_LUNA:
                hsm.addSafenetLuna();

                propsToDrop.push(
                    'nethsm',
                    'rfs',
                    'cloudhsm',
                );

                break;

            case HSMType.HSM_TYPE_AWS_CLOUDHSM:
                hsm.addCloudHsm();

                propsToDrop.push(
                    'nethsm',
                    'rfs',
                    'sluna',
                );

                break;
        }

        propsToDrop.forEach(propName => delete hsm.config[propName]);
    }

    /**
     * Populates SAFENET LUNA nodes list with loaded default values.
     */
    public populateSLunaNodesList(): IPromise<string[] | void> {
        if (this.getType() === HSMType.HSM_TYPE_SAFENET_LUNA) {
            return this.getSLunaNodes()
                .then(this.transformSLunaNodesList.bind(this))
                .catch(() => this.transformSLunaNodesList([]));
        }

        return this.$q.reject('Wrong HSM type');
    }

    /** @override */
    public dataToSave(): IHardwareSecurityModuleGroupConfig {
        const config = super.dataToSave();

        return config;
    }

    /**
     * Replaces SAFENET LUNA nodes list with passed IPs.
     */
    protected transformSLunaNodesList(ipList: string[]): void {
        const nodeInfoList = ipList.map(clientIp => ({ client_ip: clientIp }));
        const { safenetLuna } = this.getConfig().hsm;

        safenetLuna.config.node_info.updateConfig(nodeInfoList);
    }

    /**
     * Makes request to get a list of nodes available for SAFENET Luna HSM type.
     */
    protected getSLunaNodes(): IPromise<string[]> {
        const { hsm } = this.getConfig();
        const apis = [
            CLUSTER_URL,
        ];

        const useDedicatedNetwork = Boolean(hsm?.safenetLuna?.useDedicatedNetwork);

        apis.push(
            useDedicatedNetwork ? SE_DEDICATED_NETWORK_URL : SE_MGMT_NETWORK_URL,
        );

        this.busy = true;
        this.errors = null;

        const requests =
            apis.map(api => this.request('GET', api, null, undefined, 'getLunaNodes'));

        return this.$q.all(requests)
            .then(HSMGroup.transformNodesResponse)
            .catch((rsp: IHttpResponse<Error>) => this.$q.reject(this.errors = rsp.data))
            .finally(() => this.busy = false);
    }

    /** @override */
    protected beforeEdit(): void {
        this.onTypeChange();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.hsmGroupModalBreadcrumbTitle);
    }
}
