/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name getRuntimeDataFingerprint
 * @description
 *
 *     Method returns a hash of the passed runtimeData with can be used in a $watch to figure
 *     out that it got changed.
 */
//TODO replace with an Item event `runtimeDataUpdated`
angular.module('aviApp').factory('getRuntimeDataFingerprint', function() {
    /**
     * @returns {string|undefined} - Meaningful runtime values joined with comma.
     */
    return runtimeData => {
        if (angular.isObject(runtimeData)) {
            const result = [];
            const { health_score: healthScore, oper_status: operStatus } = runtimeData;

            if (healthScore) {
                ['resources_penalty',
                    'health_score',
                    'anomaly_penalty',
                    'performance_score',
                    'security_penalty',
                ].forEach(filedName => result.push(healthScore[filedName]));
            }

            if (operStatus) {
                result.push(operStatus['state']);

                const { reason } = operStatus;

                if (angular.isArray(reason)) {
                    Array.prototype.push.apply(result, reason);
                } else {
                    result.push(reason);
                }
            }

            return result.join();
        }
    };
});
