/**
 * Module containing Cloud components.
 * @preferred
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrTextareaModule,
} from '@clr/angular';

import { SharedModule } from '../../shared/shared.module';
import { AviFormsModule } from '../avi-forms/avi-forms.module';

import {
    AzureConfigurationComponent,
    AzureDnsComponent,
    AzureEditCredentialsDialogComponent,
    AzureLocationNetworkComponent,
    CloudModalComponent,
    ConvertCloudTypeDialogComponent,
    CustomTagsComponent,
    DataNetworkConfigComponent,
    DnsResolverGridComponent,
    DnsResolverModalComponent,
    DnsResolverNameserversGridComponent,
    HostServersGridComponent,
    HostServersModalComponent,
    IpamDnsConfigurationComponent,
    LicenseSelectorDirective,
    LinuxServerConfigurationComponent,
    ManagementNetworkConfigComponent,
    NoOrchestratorConfigurationComponent,
    NsxtConfigurationComponent,
    NsxtEditCredentialsDialogComponent,
    ServiceEngineConfigurationComponent,
    SeUsageDirectoryPathComponent,
    VcenterServerEditCredentialsDialogComponent,
    VCenterServerModalComponent,
    VCenterServersListComponent,
    VerifyCloudConnectorUserHostModalComponent,
    VerifyCloudConnectorUserHostModalService,
} from '.';

import { DialogModule } from '../dialog/dialog.module';

import {
    licenseBasedFeaturesServiceProvider,
    seGroupCollectionProvider,
} from './ajs-upgraded-providers';

const cloudComponents = [
    AzureConfigurationComponent,
    AzureDnsComponent,
    AzureEditCredentialsDialogComponent,
    AzureLocationNetworkComponent,
    CloudModalComponent,
    ConvertCloudTypeDialogComponent,
    CustomTagsComponent,
    DataNetworkConfigComponent,
    DnsResolverGridComponent,
    DnsResolverModalComponent,
    DnsResolverNameserversGridComponent,
    HostServersGridComponent,
    HostServersModalComponent,
    IpamDnsConfigurationComponent,
    LinuxServerConfigurationComponent,
    ManagementNetworkConfigComponent,
    NoOrchestratorConfigurationComponent,
    NsxtConfigurationComponent,
    NsxtEditCredentialsDialogComponent,
    ServiceEngineConfigurationComponent,
    SeUsageDirectoryPathComponent,
    VcenterServerEditCredentialsDialogComponent,
    VCenterServerModalComponent,
    VCenterServersListComponent,
    VerifyCloudConnectorUserHostModalComponent,
];

const cloudDirectives = [
    LicenseSelectorDirective,
];

@NgModule({
    declarations: [
        ...cloudComponents,
        ...cloudDirectives,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ClrInputModule,
        ClrRadioModule,
        ClrTextareaModule,
        ClrFormsModule,
        ClrCheckboxModule,
        DialogModule,
        AviFormsModule,
    ],
    providers: [
        licenseBasedFeaturesServiceProvider,
        seGroupCollectionProvider,
        VerifyCloudConnectorUserHostModalService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CloudModule {}
