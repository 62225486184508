/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ipam-dns-infoblox-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const infloboxProfileConfigurationHeader = `${prefix}.infloboxProfileConfigurationHeader`;
export const subnetListFetchedSuccessMessage = `${prefix}.subnetListFetchedSuccessMessage`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const networkViewInputLabel = `${prefix}.networkViewInputLabel`;
export const dnsViewInputLabel = `${prefix}.dnsViewInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const settingsHeader = `${prefix}.settingsHeader`;
export const wapiVersionInputLabel = `${prefix}.wapiVersionInputLabel`;
export const usableSubnetsHeader = `${prefix}.usableSubnetsHeader`;
export const extensibleAttributesHeader = `${prefix}.extensibleAttributesHeader`;
export const addExtensibleAttributeButtonLabel = `${prefix}.addExtensibleAttributeButtonLabel`;

export const ENGLISH = {
    [infloboxProfileConfigurationHeader]: 'Infoblox Profile Configuration',
    [subnetListFetchedSuccessMessage]: 'Subnetwork list successfully fetched.',
    [ipAddressInputLabel]: 'IP Address or FQDN',
    [networkViewInputLabel]: 'Network View',
    [dnsViewInputLabel]: 'DNS View',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [settingsHeader]: 'Settings',
    [wapiVersionInputLabel]: 'WAPI Version',
    [usableSubnetsHeader]: 'Usable Subnets',
    [extensibleAttributesHeader]: 'Extensible Attributes',
    [addExtensibleAttributeButtonLabel]: 'Add Extensible Attribute',
};
