/**
 * Module containing VS-VIP related components.
 * @preferred
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'ng/modules/core/core.module';

import { AviFormsModule } from '../avi-forms/avi-forms.module';
import { RbacModule } from '../rbac/rbac.module';

import {
    ipamProfileItemProvider,
    subnetListNetworkItemProvider,
    vrfContextItemProvider,
} from './ajs-upgraded-providers';

import {
    AwsVipConfigComponent,
    AzureVipConfigComponent,
    DnsInfoGridComponent,
    DnsInfoModalComponent,
    EnableVipComponent,
    FipAutoAllocateSubnetSelection,
    GcpVipConfigComponent,
    GenericVipConfigComponent,
    NsxtVipConfigComponent,
    OpenStackVipConfigComponent,
    VcenterVipConfigComponent,
    VipGridComponent,
    VipModalComponent,
    VipPlacementNetworkGridComponent,
    VipPlacementNetworkModalComponent,
    VipPlacementSubnetComponent,
    VipPrivateIpConfigComponent,
    VipPublicIpConfigComponent,
    VsVipFqdnComponent,
    VsVipModalComponent,
    VsVipModalPreviewComponent,
} from '.';

const vsVipComponents = [
    AwsVipConfigComponent,
    AzureVipConfigComponent,
    DnsInfoGridComponent,
    DnsInfoModalComponent,
    EnableVipComponent,
    FipAutoAllocateSubnetSelection,
    GcpVipConfigComponent,
    GenericVipConfigComponent,
    NsxtVipConfigComponent,
    OpenStackVipConfigComponent,
    VcenterVipConfigComponent,
    VipGridComponent,
    VipModalComponent,
    VipPlacementNetworkGridComponent,
    VipPlacementNetworkModalComponent,
    VipPlacementSubnetComponent,
    VipPrivateIpConfigComponent,
    VipPublicIpConfigComponent,
    VsVipFqdnComponent,
    VsVipModalComponent,
    VsVipModalPreviewComponent,
];

/**
 * @description Module containing VS-VIP related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...vsVipComponents,
    ],
    imports: [
        AviFormsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        ClrSpinnerModule,
        CoreModule,
        CommonModule,
        FormsModule,
        RbacModule,
        SharedModule,
    ],
    providers: [
        subnetListNetworkItemProvider,
        ipamProfileItemProvider,
        vrfContextItemProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class VsVipModule {}
