/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './HealthMonitorCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name HealthMonitorCreateController
 */
angular.module('aviApp').controller('HealthMonitorCreateController', [
'$scope',
'SSLProfileCollection',
'PKIProfileCollection',
'CertificateCollection',
'schemaService',
'l10nService',
function(
    $scope,
    SSLProfileCollection,
    PKIProfileCollection,
    CertificateCollection,
    schemaService,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.init = function() {
        /* to show code text view onOpen */
        $scope.ui = {
            externalCodeAsText: 'true',
            showSslAttributes: $scope.editable.hasSslAttributes(),
        };
    };

    $scope.sslProfileCollection = new SSLProfileCollection();
    $scope.pkiProfileCollection = new PKIProfileCollection();
    $scope.certificateCollection = new CertificateCollection();
    $scope.sipMonitorTransportOptions = schemaService.getEnumValues('SipMonTransport');
    $scope.sipRequestCodeOptions = schemaService.getEnumValues('SipRequestCode');
    $scope.healthMonitorDNSRcodes = schemaService.getEnumValues('HealthMonitorDNSRcode');
    $scope.healthMonitorDNSQueryTypes = schemaService.getEnumValues('HealthMonitorDNSQueryType');

    const dnsRecordTypes = schemaService.getEnumValues('DnsRecordType');

    /**
     * Filters and returns 'A' and 'AAAA' types of DNS records.
     * @type {string[]}
     */
    $scope.healthMonitorDnsRecordTypes = _.filter(dnsRecordTypes, dnsRecord => {
        return dnsRecord.value === 'DNS_RECORD_A' || dnsRecord.value === 'DNS_RECORD_AAAA';
    });

    /**
     * Changes existing DNS type value to selected DNS type value .
     * @param {string} typeName - DNS type name
     * @param {string} value - DNS type value
     */
    $scope.changeDNSTypeValue = (typeName, value) => {
        $scope.editable.getConfig().dns_monitor[typeName] = value;
    };

    /**
     * Handles checkbox select and de-select for HTTPS monitor SSL attributes.
     */
    $scope.handleSslAttributesChange = function() {
        $scope.editable.clearSslAttributes();
    };

    /**
     * Called when TCP Half-Open checkbox is selected. Removes properties that cannot be
     * configured when enabled.
     */
    $scope.onSelectTCPHalfOpen = function() {
        const { tcp_monitor: tcp } = $scope.editable.getConfig();

        if (tcp && tcp['tcp_half_open']) {
            delete tcp['maintenance_response'];
            delete tcp['tcp_request'];
            delete tcp['tcp_response'];
        }
    };
}]);
