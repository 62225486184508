/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    IIPNetworkSubnet,
    IpAddressVersions,
} from 'generated-types';

import {
    IVipConfig,
    VipConfigItem,
} from 'ajs/modules/vs';

import {
    SubnetListNetwork,
    SubnetListNetworkCollection,
} from 'ajs/modules/network';

import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'ajs/modules/core/services/string-service';
import * as l10n from './vip-private-ip-config.l10n';
import './vip-private-ip-config.component.less';

// Local copy of IpAddressVersions enum.
enum IpAddressVersionsEnum {
    V4_ONLY = IpAddressVersions.V4_ONLY,
    V6_ONLY = IpAddressVersions.V6_ONLY,
    V4_V6 = IpAddressVersions.V4_V6,
}

type TSubnetListNetwork = typeof SubnetListNetwork;
type TSubnetListNetworkCollection = typeof SubnetListNetworkCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *      VIP -> Private IP Configuration component.
 *      !! TODO: Take care of networkConfig/ipamNetworkConfig issue URGENT
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vip-private-ip-config',
    templateUrl: './vip-private-ip-config.component.html',
})
export class VipPrivateIpConfigComponent implements OnInit, OnDestroy {
    /**
     * VIP ConfigItem instance.
     */
    @Input()
    public editable: VipConfigItem;

    /**
     * UUID of the cloud on which this VIP is being configured.
     */
    @Input()
    public cloudId: string;

    /**
     * UUID of the VRFContext on which this VIP is being configured.
     * It will be falsy value for some of the cloud types (eg Nsx-t).
     */
    @Input()
    public vrfContextId?: string;

    /**
     * Indicates whether cloud has IPAM Provider profile.
     * If true, autoIpAllocation option will be available to user.
     */
    @Input()
    public hasIpamProviderProfile = false;

    /**
     * True if cloud supports IPV4 PrefixLength configuration.
     */
    @Input()
    public supportPrefixLength = false;

    /**
     * If true, IP Allocation Radiobuttons will be displayed irrespective of
     * any conditions (ex AWS Cloud).
     */
    @Input()
    public alwaysShowAllocationSelection = false;

    /**
     * If true, NetworkSelection dropdown will be displayed irrespective of
     * IP allocation type (ex AWS Cloud).
     */
    @Input()
    public alwaysAllowNetworkSelection = false;

    /**
     * Support for IPV6. Default is true.
     * If true, fields related to ipv6 will not be shown.
     */
    @Input()
    public supportIpv6 = true;

    /**
     * VsVip#ipam_selector label string.
     * Set by backend, UI configuration is not available yet.
     */
    @Input()
    public ipamSelectorLabels: string;

    /**
     * Model for `Enable Prefix Length` checkbox.
     */
    public prefixLengthEnabled = false;

    /**
     * ObjectType of VIPConfigItem.
     */
    public objectType: string;

    /**
     * SubnetListNetworkCollection instance.
     * Used in placement network collection-dropdown.
     */
    public subnetListNetworkCollection: SubnetListNetworkCollection;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * IpAddressVersions Enum.
     */
    public readonly IpAddressVersionsEnum = IpAddressVersionsEnum;

    /**
     * Flag to indicate component's busy state.
     *
     * If vipConfig has networkRef, we need to load the corresponding networkInstance
     * to get the fullRef before passing it to vip-placement-subnet component.
     *
     * If true, spinner will be displayed in place of vip-placement-subnet.
     */
    public busy = false;

    constructor(
        @Inject(SubnetListNetworkCollection)
        private readonly SubnetListNetworkCollection: TSubnetListNetworkCollection,
        @Inject(SubnetListNetwork)
        private readonly SubnetListNetwork: TSubnetListNetwork,
        private readonly stringService: StringService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        const networkLoadParams: Record<string, string | boolean> = {
            cloud_uuid: this.cloudId,
            auto_allocate_only: true,
            preferred_nw_type: this.editable.config.auto_allocate_ip_type,
        };

        if (this.vrfContextId) {
            networkLoadParams.vrf_context_uuid = this.vrfContextId;
        }

        if (this.ipamSelectorLabels) {
            networkLoadParams.labels = this.ipamSelectorLabels;
        }

        this.subnetListNetworkCollection = new this.SubnetListNetworkCollection({
            params: networkLoadParams,
        });

        this.prefixLengthEnabled = !this.editable.prefixLengthMatchesDefault();

        if (this.networkConfig.network_ref) {
            this.setFormattedNetworkRef();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.subnetListNetworkCollection.destroy();
    }

    /**
     * Fires on IP Allocation network change.
     */
    public onNetworkChange(selected?: string): void {
        this.editable.clearVipSubnet();
        this.editable.clearVipAddress();

        if (selected) {
            // Sets subnet_uuid fields when the network is selected.
            const { network_ref: networkRef } = this.networkConfig;
            const subnetListNetworkId = this.stringService.slug(networkRef);
            const subnetListNetworkItem = this.subnetListNetworkCollection
                .getItemById(subnetListNetworkId);

            const subnets = subnetListNetworkItem.getSubnets();

            if (subnets.length) {
                const { prefix, uuid } = subnets[0];

                this.editable.setVipSubnet(prefix, uuid, this.hasIpamProviderProfile);
            }
        }
    }

    /**
     * Fires on IP Allocation type change.
     */
    public ipAllocationTypeChange(): void {
        this.editable.clearVipNetwork();
        this.onNetworkChange();
    }

    /* Handles prefix length checkbox change. If checkbox is unchecked, prefix_legth
    * value is restored to the default value.
    */
    public handlePrefixLengthChange(): void {
        if (!this.prefixLengthEnabled) {
            this.editable.restoreDefaultPrefixLength();
        }
    }

    /**
     * Fires on IP Protocol change.
     * Sets filter on subnetListNetworkCollection based on protocol type.
     */
    public onIpProtocolTypeChange(): void {
        this.subnetListNetworkCollection.setParams({
            preferred_nw_type: this.editable.config.auto_allocate_ip_type,
        });

        this.ipAllocationTypeChange();
    }

    /**
     * Clears ipv4 vip address on ipv4 subnet change.
     */
    public onIpv4SubnetChange(): void {
        this.editable.clearVipIpv4Address();
    }

    /**
     * Clears ipv6 vip address on ipv6 subnet change.
     */
    public onIpv6SubnetChange(): void {
        this.editable.clearVipIpv6Address();
    }

    /**
     * Returns usable network config object.
     */
    public get networkConfig(): IIPNetworkSubnet | IVipConfig {
        if (this.hasIpamProviderProfile) {
            return this.editable.ipamNetworkConfig;
        }

        return this.editable.config;
    }

    /**
     * Returns true if static IPv4 field is mandatory.
     */
    public get isIpv4Required(): boolean {
        return !this.supportIpv6 || !this.editable.config.ip6_address.addr;
    }

    /**
     * Returns true if static IPv6 field is mandatory.
     */
    public get isIpv6Required(): boolean {
        return !this.editable.config.ip_address.addr;
    }

    /*
     * VipConfig#network_ref (received from backend) is slightly different than
     * subnetListNetwork collection refs.
     * So we load the network and get the full ref.
     */
    private async setFormattedNetworkRef(): Promise<void> {
        this.busy = true;

        const networkInstance = new this.SubnetListNetwork({
            cloudId: this.cloudId,
            id: this.stringService.slug(this.networkConfig.network_ref),
            params: {
                auto_allocate_only: true,
            },
        });

        await networkInstance.load();

        const networkRef = networkInstance.getRef();

        if (networkRef) {
            this.networkConfig.network_ref = networkRef;
        }

        this.busy = false;
    }
}
