/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'hm-select';
const prefix = `${moduleName}.${componentName}`;

export const healthMonitorProxyInputLabel = `${prefix}.healthMonitorProxyInputLabel`;
export const removeHealthMonitorProxyButtonTitle = `${prefix}.removeHealthMonitorProxyButtonTitle`;
export const HealthMonitorProxyLink = `${prefix}.HealthMonitorProxyLink`;

export const ENGLISH = {
    [healthMonitorProxyInputLabel]: 'Health Monitor Proxy',
    [removeHealthMonitorProxyButtonTitle]: 'Remove Health Monitor Proxy',
    [HealthMonitorProxyLink]: '+ Health Monitor Proxy',
};
