/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { IAviDataGridConfig, TStringRow } from 'ng/shared/components';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './subject-alt-names-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   Subject alt name(SAN) grid component.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'subject-alt-names-grid',
    templateUrl: './subject-alt-names-grid.component.html',
})
export class SubjectAltNamesGridComponent implements AfterViewInit {
    /**
     * List of subject alternate names.
     */
    @Input()
    public subjectAltNames: TStringRow[];

    /**
     * Disables add/delete row.
     */
    @Input()
    public disabled = false;

    /**
     * Fires on new subject alt name add.
     */
    @Output()
    public onAddSubjectAltName = new EventEmitter<void>();

    /**
     * Fires on delete of name.
     */
    @Output()
    public onDeleteSubjectAltNames = new EventEmitter<TStringRow[]>();

    /**
     * Template ref for subject alt name grid row.
     */
    @ViewChild('subjectAltNameTemplateRef')
    public subjectAltNameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Responder subject alt names grid config.
     */
    public subjectAltNamesGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        this.subjectAltNamesGridConfig = {
            fields: [{
                id: 'subject-alt-names',
                label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                templateRef: this.subjectAltNameTemplateRef,
            }],
            getRowId(index: number): number {
                return index;
            },
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.actionTitleRemove),
                onClick: (urls: TStringRow[]) => {
                    this.onDeleteSubjectAltNames.emit(urls);
                },
                disabled: () => this.disabled,
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.actionTitleRemove),
                shape: 'trash',
                onClick: (url: TStringRow): void => {
                    this.onDeleteSubjectAltNames.emit([url]);
                },
                disabled: () => this.disabled,
            }],
        };
    }
}
