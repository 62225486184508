/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import 'ajs/less/modal/infra-network-edit.less';
import * as l10n from './NetworkCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @name networkCreateController
 * @memberOf module:avi/network
 * @desc Network create/edit modal controller.
 * @author Alex Malitsky, Aravindh Nagarajan
 */
function networkCreateController(
    $scope,
    VRFContextCollection,
    infraCloudState,
    Regex,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    //For AviModal
    $scope.$parent.modalScope = $scope;

    /**
     * @type {Regex}
     */
    $scope.Regex = Regex;

    /**
     * @type {VRFContextCollection}
     */
    $scope.vrfCollection = new VRFContextCollection();

    /**
     * True if controller has custom VRF contexts.
     * @type {boolean}
     */
    $scope.hasCustomVrfContext = false;

    /**
     * Init function to be called when modal opens.
     */
    $scope.init = () => {
        const cloud = infraCloudState.getCloud();

        if (!cloud) {
            throw new Error(l10nService
                .getMessage(l10nKeys.cloudRequiredToCreateNetworkErrorMessage));
        }

        const { id: cloudId } = cloud;

        $scope.vrfCollection.setParams({
            'cloud_ref.uuid': cloudId,
        });

        $scope.vrfCollection.load()
            .then(() => {
                $scope.hasCustomVrfContext = $scope.vrfCollection.hasCustomVRFContext();
            });
    };

    $scope.$on('$destroy', () => {
        $scope.vrfCollection.destroy();
    });
}

networkCreateController.$inject = [
    '$scope',
    'VRFContextCollection',
    'infraCloudState',
    'Regex',
    'l10nService',
];

angular.module('avi/network').controller('NetworkCreateController', networkCreateController);
