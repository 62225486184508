/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import { BotMappingRuleConfigItem } from 'ajs/modules/bot-detection-policy';
import { ClrFormLayout } from '@clr/angular';
import { IAviDropdownOption } from 'ng/shared/components';
import { BotMappingRuleMatchTarget } from 'object-types';
import {
    BotClassMatcherComponent,
    BotTypeMatcherComponent,
    HdrMatchComponent,
    HostHdrMatchComponent,
    HttpMethodMatchComponent,
    IMatchOption,
    IpAddrMatchComponent,
    PathOrQueryMatchComponent,
} from 'ng/modules/match';
import * as l10n from './bot-mapping-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Bot Mapping Rule modal, for Matches and Action
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-mapping-rule-modal',
    templateUrl: './bot-mapping-rule-modal.component.html',
})
export class BotMappingRuleModalComponent implements OnInit {
    @Input()
    public editable: BotMappingRuleConfigItem;

    /**
     * Informs if current item is new or already exists.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Called when the user wants to save the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Called when the user wants to cancel editing the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * Options for classification (action) dropdown
     */
    public botClassificationTypeOptions: IAviDropdownOption[];

    /**
     * Options for component_matcher dropdown.
     */
    public componentMatcherOptions: IAviDropdownOption[];

    /**
     * Options list for all possible bot-allow-rule options
     */
    public matchOptions: IMatchOption[];

    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Is 'BotMappingRule'.
     */
    public objectType: string;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.setBotMappingMatches();
        this.setBotRuleDropdownOptions();
    }

    /**
     * Variable modal header, depends on creating new, or editing.
     */
    public get modalHeader(): string {
        return this.l10nService.getMessage(
            this.isEditing ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleCreate,
            [this.editable.getName()],
        );
    }

    /**
     * Triggered upon user attempting to save settings.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Triggered upon user exiting modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Configures options for match-adder component.
     */
    private setBotMappingMatches(): void {
        this.matchOptions = [
            {
                component: BotClassMatcherComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.clientClassLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'class_matcher',
            },
            {
                component: BotTypeMatcherComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.botTypeMatcherLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'type_matcher',
            },
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.stringMatcherLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'identifier_matcher',
            },
            {
                component: IpAddrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.clientIpAddressLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'client_ip',
            },
            {
                component: HttpMethodMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.httpMethodLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'method',
            },
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.pathMatcherLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'path',
            },
            {
                component: HdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.hdrMatcherLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'hdrs',
            },
            {
                component: HostHdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.hostHeaderMatcherLabel),
                objectType: BotMappingRuleMatchTarget,
                fieldName: 'host_hdr',
            },
        ];
    }

    /**
     * Sets options for dropdowns' enums for template.
     */
    private setBotRuleDropdownOptions(): void {
        const botClassificationTypes = this.schemaService.getEnumValues('BotClassificationTypes');
        const componentMatcherTypes = this.schemaService.getEnumValues('BotDecisionComponent');

        this.botClassificationTypeOptions = createOptionsFromEnumProps(botClassificationTypes);
        this.componentMatcherOptions = createOptionsFromEnumProps(componentMatcherTypes);
    }
}
