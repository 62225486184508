/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isEmpty } from 'underscore';
import { IAppLearningConfidenceOverride } from 'generated-types';
import { DefaultValues, SchemaService } from 'ajs/modules/core/services';

/**
 * Enums for AppLearningConfidenceLabel.
 */
export enum CONFIDENCE_LEVELS {
    CONFIDENCE_LOW = 'CONFIDENCE_LOW',
    CONFIDENCE_PROBABLE = 'CONFIDENCE_PROBABLE',
    CONFIDENCE_HIGH = 'CONFIDENCE_HIGH',
    CONFIDENCE_VERY_HIGH = 'CONFIDENCE_VERY_HIGH',
}

const confidenceEnums = [
    CONFIDENCE_LEVELS.CONFIDENCE_LOW,
    CONFIDENCE_LEVELS.CONFIDENCE_PROBABLE,
    CONFIDENCE_LEVELS.CONFIDENCE_HIGH,
    CONFIDENCE_LEVELS.CONFIDENCE_VERY_HIGH,
];

const confidenceEnumsToLabelsHash = {
    [CONFIDENCE_LEVELS.CONFIDENCE_LOW]: 'Low',
    [CONFIDENCE_LEVELS.CONFIDENCE_PROBABLE]: 'Probable',
    [CONFIDENCE_LEVELS.CONFIDENCE_HIGH]: 'High',
    [CONFIDENCE_LEVELS.CONFIDENCE_VERY_HIGH]: 'Very High',
};

/**
 * Fields corresponding to AppLearningConfidenceLabel enums in WafPolicy.
 */
const CONFIDENCE_LOW_FIELD = 'confid_low_value';
const CONFIDENCE_PROBABLE_FIELD = 'confid_probable_value';
const CONFIDENCE_HIGH_FIELD = 'confid_high_value';
const CONFIDENCE_VERY_HIGH_FIELD = 'confid_very_high_value';

const confidenceEnumsToFieldsHash = {
    [CONFIDENCE_LEVELS.CONFIDENCE_LOW]: CONFIDENCE_LOW_FIELD,
    [CONFIDENCE_LEVELS.CONFIDENCE_PROBABLE]: CONFIDENCE_PROBABLE_FIELD,
    [CONFIDENCE_LEVELS.CONFIDENCE_HIGH]: CONFIDENCE_HIGH_FIELD,
    [CONFIDENCE_LEVELS.CONFIDENCE_VERY_HIGH]: CONFIDENCE_VERY_HIGH_FIELD,
};

/**
 * Returns a percentage value based on a number.
 */
const valueToPercentage = (value: number): number => value / 100;

/**
 * Used to calculate the slider spacing by percentage.
 */
const sliderInterval = Math.round(100 / (confidenceEnums.length - 1));

export type TMinConfidenceLabelsHash = { [enumValue in CONFIDENCE_LEVELS]?: string };

export type TMinConfidenceSliderLabelHash = { [sliderValue: number]: string };

/**
 * @name wafApplicationLearningService
 * @memberOf module:avi/waf
 * @description Service to help with configuring WAF Application Learning fields.
 * @author alextsg
 */
export class WafApplicationLearningService {
    constructor(private schemaService: SchemaService, private defaultValues: DefaultValues) {}

    /**
     * Returns a hash of labels based on configured confidence percentages. The labels contain
     * either 'None' or something like 'Low (70-90%)', where the '(70-90%)' part is taken from
     * previously configured values in the 'confidence_override' object.
     */
    public getMinConfidenceLabelsHash(
        overrideConfig: IAppLearningConfidenceOverride,
    ): TMinConfidenceLabelsHash {
        overrideConfig = !isEmpty(overrideConfig) ?
            overrideConfig :
            this.defaultValues.getDefaultItemConfigByType('applearningconfidenceoverride');

        return confidenceEnums.reduce((acc, enumValue, index) => {
            const label = confidenceEnumsToLabelsHash[enumValue];
            const field = confidenceEnumsToFieldsHash[enumValue];
            const nextEnum = confidenceEnums[index + 1];
            const nextField = confidenceEnumsToFieldsHash[nextEnum];
            const minPercentage = valueToPercentage(overrideConfig[field]);
            const maxPercentage = enumValue === CONFIDENCE_LEVELS.CONFIDENCE_VERY_HIGH ?
                100 :
                valueToPercentage(overrideConfig[nextField]);

            acc[enumValue] = `${label} (${minPercentage}-${maxPercentage}%)`;

            return acc;
        }, {});
    }

    /**
     * Returns a hash of slider values to labels.
     */
    public getMinConfidenceSliderLabelHash(): TMinConfidenceSliderLabelHash {
        return confidenceEnums.reduce((acc, enumValue, index) => {
            acc[index * sliderInterval] = confidenceEnumsToLabelsHash[enumValue];

            return acc;
        }, {});
    }

    /**
     * Returns the slider value based on a configured enum.
     */
    public getSliderValueByMinConfidenceEnum(enumValue: CONFIDENCE_LEVELS): number {
        const index = confidenceEnums.indexOf(enumValue);

        return index * sliderInterval;
    }

    /**
     * Returns the 'min_confidence' enum value to set based on the slider value.
     */
    public getMinConfidenceEnumBySliderValue(sliderValue: number): string {
        const index = sliderValue / sliderInterval;

        return confidenceEnums[index];
    }

    /**
     * Returns the range of allowed values for the 'sampling_percent' field.
     */
    public getLearningSamplingPercentRange(): [number, number] {
        return this.schemaService.getFieldRangeAsTuple('AppLearningParams', 'sampling_percent');
    }
}

WafApplicationLearningService.$inject = [
    'schemaService',
    'defaultValues',
];
