/** @module SecurityModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import template from './certificate-management-expander.component.html';
import * as l10n from './certificate-management-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class CertificateManagementExpanderController {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}

CertificateManagementExpanderController.$inject = [
    'l10nService',
];

export const certificateManagementExpanderOptions = {
    bindings: {
        certificateManagement: '<',
    },
    controller: CertificateManagementExpanderController,
    template,
};
