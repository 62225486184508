/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-repeated-key-values-grid';
const prefix = `${moduleName}.${componentName}`;

export const header = `${prefix}.header`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const keyFieldPlaceholder = `${prefix}.keyFieldPlaceholder`;
export const valueFieldPlaceholder = `${prefix}.valueFieldPlaceholder`;
export const keyDuplicationErrorMsg = `${prefix}.keyDuplicationErrorMsg`;
export const duplicateValuesErrorMsg = `${prefix}.duplicateValuesErrorMsg`;
export const keyColumnLabel = `${prefix}.keyColumnLabel`;
export const valueColumnLabel = `${prefix}.valueColumnLabel`;
export const removeButtonLabel = `${prefix}.removeBtnLabel`;

export const ENGLISH = {
    [header]: 'Key & Value',
    [addButtonLabel]: 'Add',
    [keyFieldPlaceholder]: 'Enter Key',
    [valueFieldPlaceholder]: 'Enter Value',
    [keyDuplicationErrorMsg]: 'System does not support duplicate keys. Please update or delete.',
    [duplicateValuesErrorMsg]: 'Duplicate keys: {0}.',
    [keyColumnLabel]: 'Key',
    [valueColumnLabel]: 'Value',
    [removeButtonLabel]: 'Remove',
};
