/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name objectToHtmlList
 *
 * @param obj {Object} - Object to be rendered.
 * @param diffObj {Object=} - Result of DeepDiff.diff.
 * @param type {string=} - Whether rendered Object is 'new' or 'old' to use an appropriate
 *     highlighting classes.
 * @param comparedObj {Object=} - Object to be compared with.
 * @returns {string} HTML string to be put into the template.
 *
 * @author Alex Malitsky
 * @desc
 *     Parses an object into HTML list with appropriate highlighting classes got through
 *     diffObj (when additional parameters are passed).
 */
angular.module('aviApp').factory('objectToHtmlList', [
'httpHeaderNameDuplicatePostfix', 'ipAddrToInt',
function(duplicatesPostfix, ipAddrToInt) {
    /**
     * Since we are using comparison feature mostly for objects we can not have properties
     * with the same names within one object. But this is the case for HTTP headers. To
     * make it work we need to append a postfix to the duplicated names for the sake of data
     * processing and hide it from user on layout rendering.
     * This is classical workaround to be removed.
     * @param {string} str
     * @returns {string}
     * @inner
     * TODO rebuild
     */
    function removeNamePostfix(str) {
        str = str.toString();

        const pos = str.lastIndexOf(duplicatesPostfix);

        if (pos !== -1) {
            str = str.slice(0, pos);
        }

        return str;
    }

    function getClassName(item, type) {
        let className = '';
        let { kind } = item;

        // have to show differently when set of properties were replaced by null and vice versa
        if (type === 'right' && kind === 'E' &&
            angular.isObject(item.right) !== angular.isObject(item.left)) {
            kind = 'N';
        }

        switch (kind) {
            case 'E':
                className = 'edited';
                break;

            case 'N':
                className = 'new';
                break;

            case 'D':
                className = 'deleted';
                break;
        }

        // need this to take space of non-existent properties (deleted or added on other side)
        if (className === 'deleted' && type === 'right' ||
            className === 'new' && type === 'left') {
            className = 'empty';
        }

        return className;
    }

    function objectToHtmlList(obj, diffObj, type, comparedObj) {
        let keys = Object.keys(obj);
        let str = '<ul>';

        if (comparedObj) {
            const rightKeys = Object.keys(comparedObj);

            // need to replace because order matters for arrays with equal length
            keys = type === 'right' ? _.union(keys, rightKeys) : _.union(rightKeys, keys);

            if (angular.isObject(obj)) {
                const serverSample = _.sample(obj);

                if (angular.isObject(serverSample) && 'ip' in serverSample &&
                    angular.isObject(serverSample['ip']) && 'addr' in serverSample['ip']
                ) {
                    keys.sort((a, b) => {
                        const
                            aColonPos = a.indexOf(':'),
                            bColonPos = b.indexOf(':');

                        if (aColonPos !== -1) {
                            a = a.slice(0, aColonPos);
                        }

                        if (bColonPos !== -1) {
                            b = b.slice(0, bColonPos);
                        }

                        return ipAddrToInt(a) - ipAddrToInt(b);
                    });
                }
            }
        }

        _.each(keys, param => {
            let rowClassName = 'nodiff';
            let val = obj[param];

            //to take place for easier comparison with neighbour (before / after) view
            if (angular.isUndefined(val)) {
                val = comparedObj[param];
            }

            //resource data for event 'new configuration'
            if (val === null || !angular.isObject(val) && param !== 'resource_data') {
                if (diffObj && diffObj[param]) {
                    rowClassName = getClassName(diffObj[param], type);
                }

                str +=
                    `<li class="${rowClassName}">` +
                        `<span class="key">${removeNamePostfix(param)}</span>: ` +
                    `<span class="value">${_.escape(val)}</span>`;

                //have to preserve the max value length for properties to line up
                if (rowClassName === 'edited') {
                    const oppositeValue = diffObj[param][type === 'left' ? 'right' : 'left'];
                    const valueLengthDiff = oppositeValue.length - param.length;

                    if (valueLengthDiff > 0) {
                        str +=
                            '<span class="placeholder">' +
                                `${_.escape(oppositeValue.slice(param.length))}` +
                            '</span>';
                    }
                }

                str += '</li>';
            } else {
                if (param === 'resource_data') {
                    val = JSON.parse(val);
                }

                str += '<li class="groupName';

                if (diffObj && diffObj[param]) {
                    str += ` ${getClassName(diffObj[param], type)}`;
                } else {
                    str += ' nodiff';
                }

                str += `"><span>${removeNamePostfix(param)}</span>`;

                str += objectToHtmlList(
                    val,
                    diffObj && diffObj[param],
                    type,
                    obj[param] && comparedObj && comparedObj[param],
                );

                str += '</li>';
            }
        });

        return `${str}</ul>`;
    }

    return objectToHtmlList;
}]);
