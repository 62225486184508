/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { Auth, SystemInfoService } from 'ajs/modules/core/services';
import { isObject } from 'underscore';
import { GSLB } from './gslb.item.factory';

interface IGslbServiceArgs {
    gslb?: GSLB;
}

const allDataSources = {
    list: {
        source: 'InventoryCollDataSource',
        transformer: 'InventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config', 'runtime', 'health'],
    },
};

/**
 * @description
 *
 *     Collection which is supposed to have at most one item. Used to load it or create a new
 *     one using default configuration.
 *
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBServiceCollection extends Collection {
    public readonly gslb: GSLB;
    private readonly auth: Auth;
    private readonly systemInfo: SystemInfoService;

    constructor(args: IGslbServiceArgs = {}) {
        super(args);

        // expect loaded GSLB item here
        if (isObject(args.gslb)) {
            this.gslb = args.gslb;
        }

        this.serverDefaultsOverride_ = {
            groups: undefined,
            health_monitor_refs: [],
            domain_names: [],
        };

        this.itemClass_ = this.getAjsDependency_('GSLBService');
        this.systemInfo = this.getAjsDependency_('systemInfoService');
        this.auth = this.getAjsDependency_('Auth');
    }

    /** @override */
    public isCreatable(): boolean {
        return this.systemInfo.localSiteIsGSLBLeader &&
            this.auth.isAllowed('gslb') &&
            super.isCreatable();
    }
}

Object.assign(GSLBServiceCollection.prototype, {
    objectName_: 'gslbservice-inventory',
    allDataSources_: allDataSources,
    windowElement_: 'app-gslb-service-edit',
    isStatic_: false,
});

GSLBServiceCollection.ajsDependencies = [
    'GSLBService',
    'systemInfoService',
    'Auth',
];
