/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'user-modal-default-tenant-settings';
const prefix = `${moduleName}.${componentName}`;

export const defaultTenantInputPlaceholder = `${prefix}.defaultTenantInputPlaceholder`;
export const selectDefaultTenantInputPlaceholder = `${prefix}.selectDefaultTenantInputPlaceholder`;

export const ENGLISH = {
    [defaultTenantInputPlaceholder]: 'Select',
    [selectDefaultTenantInputPlaceholder]: 'Select Default Tenant',
};
