/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import { BgpProfileConfigItem } from 'ajs/modules/vrf-context';

import template from './bgp-profile-details.component.html';
import './bgp-profile-details.component.less';
import * as l10n from './bgp-profile-details.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for BGP profile details view.
 *
 * @author Aravindh Nagarajan
 */
class BgpProfileDetailsController {
    public readonly bgpProfile: BgpProfileConfigItem;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}

BgpProfileDetailsController.$inject = [
    'l10nService',
];

/**
 * Ajs name of the component.
 */
export const BGP_PROFILE_DETAILS_COMPONENT_TOKEN = 'bgpProfileDetails';

export const bgpProfileDetailsComponentOptions = {
    bindings: {
        bgpProfile: '<',
    },
    controller: BgpProfileDetailsController,
    template,
};
