/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';
import { L10nService } from '@vmw/ngx-vip';
import { StateService } from '@uirouter/core';
import { Cloud } from 'ajs/js/services/items/Cloud';
import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';
import { IVIMgrIPSubnetRuntime } from 'generated-types';
import { ConfiguredNetwork, ConfiguredNetworkCollection } from '../../factories';
import template from './network-list-page.component.html';

import * as l10n from './network-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TConfiguredNetwork = typeof ConfiguredNetwork;
type TConfiguredNetworkCollection = typeof ConfiguredNetworkCollection;

/**
 * @desc Component for Network List page.
 */
class NetworkListPageController {
    public gridConfig: ICollectionGridConfig;

    constructor(
        private readonly ConfiguredNetwork: TConfiguredNetwork,
        private readonly ConfiguredNetworkCollection: TConfiguredNetworkCollection,
        private readonly infraCloudState: any,
        private readonly $state: StateService,
        private readonly l10nService: L10nService,
    ) {
        this.ConfiguredNetwork = ConfiguredNetwork;
        this.ConfiguredNetworkCollection = ConfiguredNetworkCollection;
        this.infraCloudState = infraCloudState;
        this.$state = $state;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        const cloud: Cloud = this.infraCloudState.getCloud();
        const { id: slug } = cloud;
        const vtype = cloud.getVtype();

        this.gridConfig = createCrudCollectionGridConfig();

        this.gridConfig.collection = new this.ConfiguredNetworkCollection({
            params: {
                'cloud_ref.uuid': slug,
            },
            defaults: {
                cloud_ref: cloud.getRef(),
            },
            bind: {
                collItemConfigUpdate: () => this.gridConfig.collection.load(),
            },
            cloudType: vtype,
        });

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;

        this.gridConfig.fields = [{
            require: 'config, runtime',
            name: 'data.config.name',
            title: this.l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'discSubnets',
            require: 'discovery',
            title: this.l10nService.getMessage(l10nKeys.columnTitleDiscoveredSubnets),
            transform: (row: ConfiguredNetwork) => {
                const { exclude_discovered_subnets: excludeDiscoveredSubnets } =
                    row.getConfig();

                if (excludeDiscoveredSubnets) {
                    return this.l10nService.getMessage(l10nKeys.excludedLabel);
                }

                const { discovery } = row.data;

                if (discovery && discovery.ip_subnet && discovery.ip_subnet.length) {
                    return discovery.ip_subnet
                        .map((s: IVIMgrIPSubnetRuntime) => getSubnetString(s.prefix))
                        .join(', ');
                } else {
                    return this.l10nService.getMessage(l10nKeys.noneLabel);
                }
            },
            visibility: 'm',
        }, {
            name: 'confSubnets',
            title: this.l10nService.getMessage(l10nKeys.columnTitleConfiguredSubnets),
            transform: (row: ConfiguredNetwork) => {
                const { configured_subnets: configuredSubnets } = row.getConfig();

                if (!configuredSubnets?.length) {
                    return this.l10nService.getMessage(l10nKeys.noneLabel);
                }

                const [subnet, ...remainingSubnets] = configuredSubnets;

                const formattedSubnet =
                    this.ConfiguredNetwork.formatSubnetRuntimeData(subnet);

                const { length: remainingSubnetCount } = remainingSubnets;

                return remainingSubnets.length ?
                    this.l10nService.getMessage(
                        l10nKeys.configuredSubnetsValueText,
                        [formattedSubnet, remainingSubnetCount],
                    ) :
                    formattedSubnet;
            },
            visibility: 'm',
        }, {
            name: 'staticPools',
            title: this.l10nService.getMessage(l10nKeys.columnTitleStaticIpPools),
            transform: (row: ConfiguredNetwork) => {
                const { configured_subnets: configuredSubnets = [] } = row.getConfig();

                const staticIpPoolsCount = configuredSubnets.reduce((staticPoolCount, subnet) => {
                    const { static_ip_ranges: staticIpRanges = [] } = subnet;

                    return staticPoolCount + staticIpRanges.length;
                }, 0);

                return String(staticIpPoolsCount);
            },
            visibility: 'm',
        }, {
            name: 'vrf',
            title: 'VRF Context',
            template: '{{ row.getConfig().vrf_context_ref.name() }}',
            visibility: 'optional',
        }];

        if (!cloud.allowNetworkCreate()) {
            delete this.gridConfig.multipleactions;
        }

        // Modify singleactions to take $scope.Cloud as a param.
        this.gridConfig.singleactions = [{
            title: 'Edit',
            class: 'icon-pencil-4',
            hidden: row => !row.isEditable(),
            do: row => {
                row.edit(null, {
                    Cloud: cloud,
                });
            },
        }];

        this.gridConfig.expandedContainerTemplate = `
            <network-list-expander [network]="row"></network-list-expander>
        `;

        this.gridConfig.expanderDisabled = (row: ConfiguredNetwork) => {
            const { configuredSubnets } = row;

            return !configuredSubnets.length;
        };

        this.infraCloudState.on('cloudChange', this.handleCloudChange);
    }

    /**
     * Handler for the 'cloudChange' event. Sets the Network collection based on the cloud and
     * loads it.
     */
    public handleCloudChange = (): void => {
        this.$state.reload(this.$state.$current.name);
    };

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
        this.infraCloudState.unbind('cloudChange', this.handleCloudChange);
    }
}

NetworkListPageController.$inject = [
    'ConfiguredNetwork',
    'ConfiguredNetworkCollection',
    'infraCloudState',
    '$state',
    'l10nService',
];

export const networkListPageOptions = {
    controller: NetworkListPageController,
    template,
};
