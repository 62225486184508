/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './ipam-dns-profiles-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class IpamDnsProfilesPageController {
    constructor(
        CRUDGridConfig,
        IPAMProfileCollection,
        IPAMProfile,
        l10nService,
    ) {
        this.CRUDGridConfig_ = CRUDGridConfig;
        this.IPAMProfileCollection_ = IPAMProfileCollection;
        this.IPAMProfile_ = IPAMProfile;

        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        const { l10nService_: l10nService } = this;
        const ipamProfileCollection = new this.IPAMProfileCollection_();
        const { objectName } = ipamProfileCollection;
        const gridConfigOptions = {
            id: `${objectName}-list-page`,
            collection: ipamProfileCollection,
            fields: [
                {
                    name: 'data.config.name',
                    sortBy: 'name',
                    title: l10nService.getMessage(l10nKeys.columnTitleName),
                }, {
                    name: 'data.config.type',
                    title: l10nService.getMessage(l10nKeys.columnTitleType),
                    template: '{{row.data.config.type | enumText: "IpamDnsType"}}',
                },
            ],
            expandedContainerTemplate: '<ipam-dns-profiles-expander ipam-profile="row"/>',
            expanderDisabled: ipamProfile => {
                const { type } = ipamProfile;
                const {
                    IPAMDNS_TYPE_AWS,
                    IPAMDNS_TYPE_AWS_DNS,
                    IPAMDNS_TYPE_AZURE_DNS,
                    IPAMDNS_TYPE_OCI,
                    IPAMDNS_TYPE_OPENSTACK,
                } = this.IPAMProfile_.typeHash;

                return type === IPAMDNS_TYPE_AWS ||
                    type === IPAMDNS_TYPE_AWS_DNS ||
                    type === IPAMDNS_TYPE_AZURE_DNS ||
                    type === IPAMDNS_TYPE_OCI ||
                    type === IPAMDNS_TYPE_OPENSTACK;
            },
            createActions: [{
                label: l10nService.getMessage(l10nKeys.btnLabelIpamProfile),
                onClick: () => {
                    ipamProfileCollection.setDefaultItemConfigProps({
                        type: 'IPAMDNS_TYPE_INTERNAL',
                    });

                    ipamProfileCollection.create();
                },
            }, {
                label: l10nService.getMessage(l10nKeys.btnLabelDnsProfile),
                onClick: () => {
                    ipamProfileCollection.setDefaultItemConfigProps({
                        type: 'IPAMDNS_TYPE_INTERNAL_DNS',
                    });

                    ipamProfileCollection.create();
                },
            }],
        };

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

IpamDnsProfilesPageController.$inject = [
        'CRUDGridConfig',
        'IPAMProfileCollection',
        'IPAMProfile',
        'l10nService',
];

/**
 * @ngdoc component
 * @name ipamDnsProfilesPage
 * @author alextg, Chitra
 * @description Component for the IPAM/DNS Profiles page.
 */
angular.module('aviApp').component('ipamDnsProfilesPage', {
    controller: IpamDnsProfilesPageController,
    template:
            `<div class="avi-main">
                <div class="panel templatesList">
                    <collection-grid config="$ctrl.gridConfig"></collection-grid>
                </div>
            </div>`,
});
