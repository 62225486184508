/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'unit-info';
const prefix = `${moduleName}.${componentName}`;

export const serviceEngineHeader = `${prefix}.serviceEngineHeader`;
export const managementIpHeader = `${prefix}.managementIpHeader`;
export const uptimeHeader = `${prefix}.uptimeHeader`;
export const downtimeHeader = `${prefix}.downtimeHeader`;
export const managementInterfaceHeader = `${prefix}.managementInterfaceHeader`;
export const managementNetworkHeader = `${prefix}.managementNetworkHeader`;
export const serviceEngineGroupHeader = `${prefix}.serviceEngineGroupHeader`;
export const physicalHostHeader = `${prefix}.physicalHostHeader`;
export const systemMemoryHeader = `${prefix}.systemMemoryHeader`;
export const resourceUsageMemoryLabel = `${prefix}.resourceUsageMemoryLabel`;
export const diskUsageHeader = `${prefix}.diskUsageHeader`;
export const resourceUsageDiskLabel = `${prefix}.resourceUsageDiskLabel`;
export const numberOfCpusHeader = `${prefix}.numberOfCpusHeader`;
export const socketsHeader = `${prefix}.socketsHeader`;
export const inuseInterfaceListLabel = `${prefix}.inuseInterfaceListLabel`;
export const virtualNetworkInterfacesLabel = `${prefix}.virtualNetworkInterfacesLabel`;
export const columnTitleInterface = `${prefix}.columnTitleInterface`;
export const columnTitleNetworkName = `${prefix}.columnTitleNetworkName`;
export const columnTitleMacAddress = `${prefix}.columnTitleMacAddress`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const vlanInterfacesLabel = `${prefix}.vlanInterfacesLabel`;

export const ENGLISH = {
    [serviceEngineHeader]: 'Service Engine: <span class="sel-subnav-name navbar-unit-name se">{0}</span>',
    [managementIpHeader]: 'Management IP',
    [uptimeHeader]: 'Uptime',
    [downtimeHeader]: 'Downtime',
    [managementInterfaceHeader]: 'Management Interface',
    [managementNetworkHeader]: 'Management Network',
    [serviceEngineGroupHeader]: 'Service Engine Group',
    [physicalHostHeader]: 'Physical Host',
    [systemMemoryHeader]: 'System Memory',
    [resourceUsageMemoryLabel]: '{0}MB / {1}MB',
    [diskUsageHeader]: 'Disk Usage',
    [resourceUsageDiskLabel]: '{0}GB / {1}GB',
    [numberOfCpusHeader]: 'Number of CPUs',
    [socketsHeader]: 'Sockets',
    [inuseInterfaceListLabel]: 'In-use Interface List',
    [virtualNetworkInterfacesLabel]: 'Virtual Network Interfaces',
    [columnTitleInterface]: 'Interface',
    [columnTitleNetworkName]: 'Network Name',
    [columnTitleMacAddress]: 'Mac Address',
    [columnTitleIpAddress]: 'IP Address',
    [vlanInterfacesLabel]: 'VLAN Interfaces',
};
