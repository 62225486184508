/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './dropdown-menu.component.less';

/**
 * @constructor
 * @memberOf module:avi/component-kit
 * @mixes module:avi/component-kit.dropdownMenuComponentBindings
 */
class DropdownMenuComponentController {
    constructor($scope, $element, $compile, PopoverFactory) {
        this.$scope_ = $scope;
        this.$element_ = $element;
        this.$compile_ = $compile;
        this.PopoverFactory_ = PopoverFactory;

        /**
         * @type {HTMLElement|null}
         * @protected
         */
        this.button_ = null;

        /**
         * @type {PopoverFactory|null}
         */
        this.dropdownListPopover = null;
    }

    /** @override */
    $onInit() {
        this.$element_.addClass('.dropdown-menu');

        this.button_ = this.$element_.find('.dropdown-menu__button');

        this.setPopoverInstance_();
    }

    /**
     * Compile popover template and set an instance of PopoverFactory.
     * @protected
     */
    setPopoverInstance_() {
        const popoverTemplate =
            `<dropdown-list
                options="$ctrl.options"
                on-select="$ctrl.onSelect({ value })"
             ></dropdown-list>`;

        const $popoverContent = $(popoverTemplate);

        if (this.optionComponentName) {
            $popoverContent.attr('option-component-name', this.optionComponentName);
        }

        this.dropdownListPopover = new this.PopoverFactory_({
            repositioning: true,
            removeAfterHide: true,
            html: this.$compile_($popoverContent)(this.$scope_),
            className: this.optionListClassName || '',
            hide: {
                outClick: true,
                innerClick: true,
                timeout: true,
                onEscape: true,
                onWinResize: true,
            },
        });
    }

    /**
     * Open popover which holds options.
     */
    openDropdownListPopover() {
        this.dropdownListPopover.show(this.button_);
    }

    /** @override */
    $onDestroy() {
        this.dropdownListPopover.remove();
    }
}

DropdownMenuComponentController.$inject = [
    '$scope',
    '$element',
    '$compile',
    'popoverFactory',
];

/**
 * Option object for Dropdown Menu.
 * This object can have more properties than listed below.
 * @typedef {Object}
 * @memberOf module:avi/component-kit
 * @name DropdownMenuOption
 * @property {string} label - Label/content of the option to show in the list.
 * @property {any} value - Value of the option; serves as a unique ID.
 */

/**
 * @mixin dropdownMenuComponentBindings
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.DropdownMenuOption[]} options - Options to select on.
 * @property {Function} onSelect - Function to be called with selected option value.
 * @property {boolean=} [hideCaret=false] - Flag deciding whether to hide dropdown caret at the
 *     rightmost of the button.
 * @property {string=} optionComponentName - The custom component name(in camel case) used for
 *     options. Default component will be used if this is not present.
 * @property {string=} optionListClassName - Custom style class name for the popover(list).
 */

/**
 * @mixin dropdownMenuComponentTransclusions
 * @memberOf module:avi/component-kit
 * @property {HTMLElement} buttonLabel - Label of the dropdown button.
 * @property {HTMLElement=} leadingIcon - Icon placed in the leftmost of the dropdown button.
 */

/**
 * @name DropdownMenuComponent
 * @memberOf module:avi/component-kit
 * @property {module:avi/component-kit.dropdownMenuComponentBindings} bindings
 * @property {module:avi/component-kit.DropdownMenuComponentController} controller
 * @property {module:avi/component-kit.dropdownMenuComponentTransclusions} transcludes
 * @description
 *
 *     Component providing a menu button and a dropdown for options.
 *
 * @see {@link module:avi/component-kit.DropdownListComponent}
 * @see {@link module:avi/component-kit.DropdownListOptionComponent}
 *
 * @example
 *
 *  <dropdown-menu
 *      options="$ctrl.rollbackOptions"
 *      on-select="$ctrl.onRollbackOptionClick(id)"
 *      option-component-name="dropdownMenuRollbackOption"
 *      option-list-class-name="update-page__rollback-dropdown-menu-list"
 *  >
 *      <dropdown-menu.leading-icon>
 *          <i class="sl-icon-reload"></i>
 *      </dropdown-menu.leading-icon>
 *
 *      <dropdown-menu.button-label>
 *          Rollback
 *      </dropdown-menu.button-label>
 *  </dropdown-menu>
 *
 * @author Zhiqian Liu
 */
angular.module('avi/component-kit').component('dropdownMenu', {
    bindings: {
        options: '<',
        onSelect: '&',
        hideCaret: '<?',
        optionComponentName: '@?',
        optionListClassName: '@?',
    },
    transclude: {
        buttonLabel: 'dropdownMenuButtonLabel',
        leadingIcon: '?dropdownMenuLeadingIcon',
    },
    controller: DropdownMenuComponentController,
    templateUrl: 'src/components/common/dropdown-menu/dropdown-menu.component.html',
});
