/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Inject, Injectable } from '@angular/core';
import { HttpMethod, HttpWrapper } from 'ajs/modules/core/factories/http-wrapper';
import { IHttpPromise } from 'angular';

type THttpWrapper = typeof HttpWrapper;

const GEO_IP_DB_DATA_URL = 'api/geoipdb';

/**
 * Represents Country Code object which is part of response
 * from geo IP DB api call.
 */
export interface ICountryCode {
    code: string;
    name: string;
}

/**
 * Represents response of geo IP DB api call.
 */
export interface IGeoIpDbApiResult {
    country: ICountryCode[];
}

/**
 * @description Service to fetch data in modal.
 *
 * @author Nisar Nadaf
 */
@Injectable()
export class IpAddrGroupModalService {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Returns the country codes.
     */
    public loadCountryCodes(): IHttpPromise<IGeoIpDbApiResult> {
        const requestConfig = {
            url: GEO_IP_DB_DATA_URL,
            method: HttpMethod.GET,
        };

        return this.httpWrapper.request(requestConfig);
    }
}
