/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * AVI Accounts related stuff (User/Role/User profile..)
 * @module avi/accounts
 */

import {
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
} from 'ajs/modules/core/services';

/**
 * @typedef {Object} UserActivity
 * @memberOf module:avi/accounts
 * @property {number} concurrent_sessions
 * @property {number} failed_login_attempts
 * @property {string} last_login_ip
 * @property {string} last_login_timestamp
 * @property {string} last_password_update
 * @property {boolean} logged_in
 * @property {string} name
 * @property {string} url
 * @property {string} uuid
 */

/**
 * @typedef {Object} TenantAccess
 * @memberOf module:avi/accounts
 * @property {string} tenant_ref
 * @property {string?} role_ref
 * @property {boolean?} all_tenants
 */

/**
 * @typedef {Object} CompressedTenantAccess
 * @memberOf module:avi/accounts
 * @property {string} tenant_ref
 * @property {string[]?} roleRefs
 * @see {@link module:avi/accounts.User.compressIndividualTenantsList_}
 */

const userFactory = (
    $http,
    Item,
    Auth,
    passwordGenerator,
    openstackKeystoneService,
    activeUserProfileService,
) => {
    /**
     * @constructor
     * @memberOf module:avi/accounts
     * @extends module:avi/dataModel.Item
     * @desc
     *  User item.
     * @see {@link module:avi/accounts.UserCollection}
     * @author Aravindh Nagarajan
     */
    class User extends Item {
        constructor(args) {
            super(args);

            if (!this.opener && this.id) {
                // Adding onSave event listener to notify Auth service on successful save.
                this.bind('itemSaveSuccess', this.onUserSave_);
            }
        }

        /** @override */
        transformAfterLoad() {
            this.decodeUIProperty_();
        }

        /** @override */
        beforeEdit() {
            const userConfig = this.getConfig();

            if (!userConfig.access) {
                userConfig.access = [];
            }

            // Creates two seperate lists (individual tenants and all tenants) from config.access
            // To display tenant-settings in User create/edit modal.
            // original accessList is not polluted so it can be accessed for other purposes.
            const [
                allTenantsAccessList,
                individualTenantAccessList,
            ] = _.partition(userConfig.access, access => access.all_tenants);

            /**
             * UI Only property to show all tenants role selection.
             * List of role_refs selected for all_tenants
             * @type {string[]}
             */
            userConfig.allTenantRoles = _.chain(allTenantsAccessList)
                .pluck('role_ref')
                .uniq()
                .value();

            const compressedIndividualTenantsList =
                User.compressIndividualTenantsList_(individualTenantAccessList);

            /**
             * UI Only property that contains only Individual Tenant access configuration.
             * @type {module:avi/accounts.CompressedTenantAccess[]}
             */
            userConfig.individualTenantAccessList = compressedIndividualTenantsList;

            if (!userConfig.access.length) {
                // Add an empty set of tenant-role map,
                // for new user with empty access list.
                this.addTenantRoleMap();
            }
        }

        /** @override */
        transformDataAfterSave(rsp) {
            this.decodeUIProperty_(rsp.data);

            return rsp.data;
        }

        /**
         * Parses UI Property and sets to config data.
         * @param {object|undefined} data
         * @protected
         */
        decodeUIProperty_(data) {
            const config = data || this.getConfig();
            let uiProperty = config.ui_property;
            const displayValues = ['avg', 'max', 'sum', 'current'];

            if (uiProperty && typeof uiProperty === 'string') {
                config.ui_property = JSON.parse(uiProperty);
                uiProperty = config.ui_property;

                if (!_.contains(displayValues, uiProperty.valuesToDisplay)) {
                    uiProperty.valuesToDisplay = 'avg';
                }
            } else if (uiProperty === undefined || uiProperty === '') {
                // Defaults
                config.ui_property = {
                    useUTCTime: false,
                    defaultTimeframe: '6h',
                    valuesToDisplay: 'avg',
                };
            }
        }

        /**
         * Adds new row to tenant and role access.
         */
        addTenantRoleMap() {
            const access = this.getIndividualTenantAccessList();
            const mapTemplate = {
                tenant_ref: undefined,
                role_ref: undefined,
            };

            access.push(mapTemplate);
        }

        /**
         * Loads user's activity.
         * @param {string} username
         * @return {ng.$q.promise} - To be resolved with UserActivity
         * @static
         */
        static loadUserActivity(username) {
            const url = `/api/useractivity/?name=${username}`;

            return $http.get(url).then(({ data }) => {
                const { results } = data;
                const [activity] = results;

                return activity;
            });
        }

        /**
         * Returns the name of the user.
         * Full name if its available or username.
         * @return {string}
         * @override
         */
        getName() {
            const config = this.getConfig();

            return config.full_name || config.username;
        }

        /**
         * Removes a row from tenant-role mapping.
         * @param {number} index
         */
        removeTenantRoleMap(index) {
            const accessList = this.getIndividualTenantAccessList();

            accessList.splice(index, 1);
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            // `allTenantRoles` and `individualTenantAccessList` will be set in `beforeEdit()`
            // to display Tenant-selection in User create/edit modal.
            // But some cases (like Activate/Suspend of user from Grid) skips `beforeEdit`
            // and directly call `dataToSave()`, So to avoid exceptions
            // do the following only when `beforeEdit` is called before `dataToSave`.
            if ('allTenantRoles' in config) {
                const {
                    individualTenantAccessList,
                    allTenantRoles,
                } = config;

                const expandedIndividualTenantsList =
                    individualTenantAccessList.reduce((accessList, access) => {
                        accessList.push(...User.expandIndividualTenantsAccess_(access));

                        return accessList;
                    }, []);

                const accessList = [
                    ...expandedIndividualTenantsList,
                ];

                allTenantRoles.forEach(roleRef => {
                    const allTenantsAccess = {
                        all_tenants: true,
                        role_ref: roleRef,
                    };

                    accessList.push(allTenantsAccess);
                });

                config.access = accessList;

                delete config.allTenantRoles;
                delete config.individualTenantAccessList;
            }

            // Delete default_tenant_ref when no Individual Tenants and Role are configured.
            if (!config.access?.length) {
                delete config.default_tenant_ref;
            }

            // Serialize ui property before saving
            if (config.ui_property) {
                config.ui_property = JSON.stringify(config.ui_property);
            }

            if (!config.password) {
                delete config.password;
            }

            delete config.confirm_password;

            return config;
        }

        /**
         * Generates random string to be used as a password.
         * @param {number=} length - Length of the password string. Defaults to 10.
         */
        generatePassword(length) {
            const config = this.getConfig();

            config.password = passwordGenerator.generate(length || 10);
            config.require_password_confirmation = false;
        }

        /**
         * Special case for user object, all users are editable if there is permission.
         * @return {boolean}
         */
        isEditable() {
            const config = this.getConfig();

            // Enforce to disable editing remote users if keystone auth is enabled
            if (openstackKeystoneService.keystoneAuthEnabled &&
                !config.local && config.default_tenant_ref.slug() !== 'admin') {
                return false;
            }

            return super.isEditable.call(this);
        }

        /** @override */
        isDroppable() {
            if (this.isProtected()) {
                return false;
            }

            return this.isAllowed();
        }

        /**
         * Returns access list from config.
         * @return {module:avi/accounts.TenantAccess[]|null}
         */
        getAccessList() {
            return this.getConfig().access || null;
        }

        /**
         * Returns single tenant access list from config.
         * Available only on edit mode, It is set in `beforeEdit()`.
         * @see {@link module:avi/accounts.User#beforeEdit}
         * @returns {module:avi/accounts.CompressedTenantAccess[]}
         */
        getIndividualTenantAccessList() {
            return this.getConfig().individualTenantAccessList || [];
        }

        /**
         * Returns true, if user has all_tenants roles.
         * Available only on edit mode, It is set in `beforeEdit()`..
         * @see {@link module:avi/accounts.User#beforeEdit}
         * @returns {boolean}
         */
        hasAllTenants() {
            return !!this.getConfig().allTenantRoles?.length;
        }

        /**
         * Getter for username
         * @return {string}
         */
        get username() {
            return this.getConfig().username || '';
        }

        /**
         * Fires on successful user save.
         * If current user is being edited,
         * Control goes to AuthService to update Tenant List.
         * @protected
         */
        onUserSave_ = () => {
            if (this.username === activeUserProfileService.username) {
                Auth.onCurrentUserChange();
            }
        };

        /**
         * Creates new access objects for each roleRefs in
         * an access Object.
         * @param {module:avi/accounts.CompressedTenantAccess} access
         * @returns {module:avi/accounts.TenantAccess[]}
         * @protected
         */
        static expandIndividualTenantsAccess_(access) {
            const {
                roleRefs = [],
                tenant_ref: tenantRef,
            } = access;

            return roleRefs.map(roleRef => {
                return {
                    tenant_ref: tenantRef,
                    role_ref: roleRef,
                    all_tenants: false,
                };
            });
        }

        /**
         * Combines and removes duplicates in individual access list.
         * @param {module:avi/accounts.TenantAccess[]} accessList
         * @returns {module:avi/accounts.CompressedTenantAccess[]}
         * @protected
         */
        static compressIndividualTenantsList_(accessList) {
            const tenantAccessHash = accessList.reduce((tenantsHash, access) => {
                const { tenant_ref: tenantRef } = access;

                let tenantAccessObj;

                // If tenant_ref is already added, get the corresponding object from the list
                // Else create a new access object for the tenant_ref and update the hash.
                if (tenantRef in tenantsHash) {
                    tenantAccessObj = tenantsHash[tenantRef];
                } else {
                    tenantAccessObj = {
                        tenant_ref: tenantRef,
                        roleRefs: [],
                    };

                    tenantsHash[tenantRef] = tenantAccessObj;
                }

                const { roleRefs } = tenantAccessObj;
                const { role_ref: roleRef } = access;

                if (roleRef) {
                    roleRefs.push(roleRef);
                }

                return tenantsHash;
            }, {});

            return Object.values(tenantAccessHash);
        }

        /**
         * Returns a string containing tenant name and its corresponding
         * role.
         * @param {module:avi/accounts.TenantAccess} access - TenantAccess object
         * @returns {string}
         */
        static getTenantAccessSummary(access) {
            const {
                role_ref: roleRef,
                tenant_ref: tenantRef = '',
                all_tenants: allTenants = false,
            } = access;

            const tenantName = allTenants ? 'All Tenants' : tenantRef.name();

            const roleName = roleRef ? roleRef.name() : '';

            return `${tenantName} (${roleName})`;
        }
    }

    Object.assign(User.prototype, {
        objectName: 'user',
        windowElement: 'user-modal',
    });

    return User;
};

userFactory.$inject = [
    '$http',
    'Item',
    'Auth',
    'passwordGenerator',
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
];

angular.module('avi/accounts').factory('User', userFactory);
