/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { AviModalType } from 'ajs/modules/core/services/avi-modal';

/**
 * @ngdoc service
 * @name activateAviModalJQueryPlugin
 * @description
 *
 *     Factory to be called once with jQuery instance passed to register
 *     a jQuery plugin which shows or hides Modal windows while stacking them.
 *
 *     @function external:"jQuery.fn".aviModal.
 *     @param {string} action - Hide or show.
 *     @param {string} [modalType='AVI_MODAL'] - Type of modal to open. Default is avi-modal.
 *
 */
angular.module('aviApp').factory('activateAviModalJQueryPlugin', function() {
    return function($) {
        let container,
            backdrop,
            backdropTransparent;

        // please see @deepestModalZIndex in z-index.less
        const MAX_AVI_MODAL_COUNT = 20;

        // stack for avi-modal
        const aviModalStack = [];
        const aviModalZIndexOffset = 10000; // please see @modalZIndex in z-index.less

        // stack for avi-alert and avi-confirm
        const aviConfirmStack = [];
        // z-index of avi-alert/avi-confirm should surpass possible max avi-modal index
        // and 10199 (full-modal z-index, see: @deepestModalZIndex in z-index.less)
        const aviConfirmZIndexOffset = aviModalZIndexOffset + 10 * (MAX_AVI_MODAL_COUNT + 1);

        function initialize() {
            if (!container) {
                container = $('<div class="modal-scrollable" style="z-index: 1099;"></div>');

                backdrop = $('<div class="modal-backdrop animated fadeIn"' +
                    'style="z-index: 1098;"/>');

                backdropTransparent =
                    $('<div class="modal-backdrop" style="z-index: 1099; opacity:0"></div>');

                $(document.body).append([container, backdrop, backdropTransparent]);
            }
        }

        function updateBackdropZIndex(backdropZIndex) {
            backdrop.css('z-index', backdropZIndex);
            backdropTransparent.css('z-index', backdropZIndex + 1);
        }

        function hideBackdrop() {
            container.hide()
                .children()
                .removeClass('first');

            backdrop.hide();
            backdropTransparent.hide();
        }

        function showBackDropWithZIndex(backdropZIndex) {
            container.show();
            backdrop.show();
            backdropTransparent.show();

            updateBackdropZIndex(backdropZIndex);
        }

        /**
         * Based on actions and modal types, open or hide a modal.
         * Alert/Confirm modal is guaranteed to be opened with higher z-index of regular avi-modals.
         * @function external:"jQuery.fn".aviModal.
         * @param {string} action - Hide or show.
         * @param {string} [modalType='AVI_MODAL'] - Type of modal to open. Default is avi-modal.
         */
        $.fn.aviModal = function(action, modalType = AviModalType.AVI_MODAL) {
            let winStack = aviModalStack;
            let zIndexOffset = aviModalZIndexOffset;

            if (modalType === AviModalType.AVI_ALERT_OR_CONFIRM) {
                winStack = aviConfirmStack;
                zIndexOffset = aviConfirmZIndexOffset;
            }

            initialize();

            let backdropZIndex;

            if (action === 'hide') {
                $(this)
                    .hide()
                    .trigger('hidden');

                // Clear the form to look pristine
                $(this).find('.changed')
                    .removeClass('changed');

                winStack.pop();

                // update z-index of the backdrop to make it stick with the top layer window
                backdropZIndex = zIndexOffset + 10 * winStack.length - 1;

                if (!aviConfirmStack.length && !aviModalStack.length) {
                    // when both stacks are empty, hide the backdrop
                    hideBackdrop();
                } else if (!aviConfirmStack.length && aviModalStack.length) {
                    // when avi-confirm stack is empty but avi-modal stack is not empty,
                    // z-index of the backdrop is decided by avi-modal stack
                    backdropZIndex = aviModalZIndexOffset + 10 * aviModalStack.length - 1;
                }

                updateBackdropZIndex(backdropZIndex);
            } else {
                const win = $(this);

                //skip if we already have this modal opened (ex: fast double click)
                if (winStack.indexOf(win) == -1) {
                    win.show().addClass('fadeInDown').trigger('focus');

                    setTimeout(function() {
                        win.removeClass('fadeInDown');
                    }, 500);

                    if (!winStack.length) {
                        win.addClass('first');
                    }

                    winStack.push(win);

                    win.css({
                        'z-index': zIndexOffset + 10 * winStack.length + 1,
                        top: `${4 + winStack.length}%`,
                        left: `${6.5 + winStack.length}%`,
                    });

                    backdropZIndex = zIndexOffset + 10 * winStack.length - 1;

                    // Put window in a stack for further manipulations
                    showBackDropWithZIndex(backdropZIndex);
                }
            }
        };

        // Catch escape click for the modal with highest z-index.
        // Alert/Confirmation modal takes precedence here.
        $(window).on('keyup', function(event) {
            const winStack = aviConfirmStack.length === 0 ? aviModalStack : aviConfirmStack;

            if (event.keyCode === 27 && winStack.length) {
                // If there are confirm buttons then instead of just closing the window
                // need to trigger click
                const closeButtons = $(winStack[winStack.length - 1])
                    .find('button.close-button, button.close');

                if (closeButtons.length) {
                    $(closeButtons[0]).trigger('click');
                } else {
                    console.warn('jQuery aviModal plugin: can\'t close modal since close button' +
                        ' wasn\'t found');
                }
            }
        });
    };
});
