/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import './custom-tags-config.less';

/**
 * @description Component for adding custom tags to AWS, GCP cloud and SEGroup modals.
 * @author Chitra Flarid, Sarthak Kapoor
 */
class CustomTagsConfigController {
    /**
     * Flag to check if customTags are an instance of RepeatedMessageItem.
     * For SEGroup, custom Tags will be a normal array while for clouds,
     * it'll be a RepeatedMessageItem.
     * Can be removed once SEGroup is migrated to new design.
     */
    isRepeatedMessageItem = false;

    /** @override */
    $onInit() {
        if (this.customTags instanceof RepeatedMessageItem) {
            this.isRepeatedMessageItem = true;
        }
    }

    /**
     * Adds Custom Tag object to custom_tags array. Only applicable for AWS/Azure Cloud or SE group.
     */
    addCustomTag() {
        if (!this.isRepeatedMessageItem) {
            this.customTags.push({});
        } else {
            this.customTags.add();
        }
    }

    /**
     * Removes Custom Tag object from custom_tags array. If custom_tags is empty as a result, set it
     * to undefined.
     * @param {number} index - Index of Custom Tag object to be removed.
     */
    removeCustomTag(index) {
        if (!this.isRepeatedMessageItem && Array.isArray(this.customTags)) {
            this.customTags.splice(index, 1);
        } else {
            this.customTags.remove(index);
        }
    }
}

/**
 * @ngdoc component
 * @name customTagsConfig
 * @param {Object[]}
 * @description
 *     Component for configuring custom tags for Azure/AWS Cloud or SEGroup instance.
 */
angular.module('aviApp').component('customTagsConfig', {
    bindings: {
        customTags: '<',
    },
    controller: CustomTagsConfigController,
    templateUrl: 'src/components/infrastructure/custom-tags-config/custom-tags-config.html',
});
