/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Injectable,
    Type,
} from '@angular/core';

import {
    FullModalService,
    IFullModalLayout,
} from 'ng/modules/core/services/full-modal';

import {
    BackupConfiguration,
    SystemConfig,
} from 'ajs/modules/system/factories';

import {
    DefaultValues,
    StringService,
} from 'ajs/modules/core/services';

import {
    Observable,
    Subject,
} from 'rxjs';

import { StateService } from '@uirouter/core';
import { appStates } from 'ajs/js/constants/app-config/app-state.constants';
import { L10nService } from '@vmw/ngx-vip';
import { WelcomeModalComponent } from '../components/welcome-modal/welcome-modal.component';
import * as l10n from './welcome.service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSystemConfig = typeof SystemConfig;
type TBackupConfiguration = typeof BackupConfiguration;

const { DEFAULT_STATE } = appStates;

/**
 * Service to setup welcome modal system/backup configuration and open/close the modal.
 *
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
@Injectable()
export class WelcomeService {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SystemConfig Item instance.
     */
    public systemConfig: SystemConfig = null;

    /**
     * BackupConfiguration Item instance.
     */
    public backupConfig: BackupConfiguration = null;

    /**
     * Subject to control welcome modal close.
     */
    private readonly welcomeCompleteSubject = new Subject<boolean>();

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly defaultValues: DefaultValues,
        private readonly stringService: StringService,
        private readonly $state: StateService,
        @Inject(SystemConfig)
        private readonly SystemConfig: TSystemConfig,
        @Inject(BackupConfiguration)
        private readonly BackupConfiguration: TBackupConfiguration,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns an observable to allow subscriptions to welcome modal close.
     */
    public get welcomeCompleteSubject$(): Observable<boolean> {
        return this.welcomeCompleteSubject.asObservable();
    }

    /**
     * Method to open welcome modal.
     */
    public async openWelcomeModal(): Promise<void> {
        await this.getDataModel();

        this.fullModalService.addModal(this.getWelcomeModalProps());
    }

    /**
     * Method to close welcome modal.
     */
    public closeModal(): void {
        this.fullModalService.removeModalByComponent(WelcomeModalComponent as Type<Component>);
        this.reset();
    }

    /**
     * Returns true if welcome modal is still open.
     */
    public isWelcomeModalOpen(): boolean {
        return this.fullModalService.isOpen(WelcomeModalComponent as Type<Component>);
    }

    /**
     * Navigates to DEFAULT_STATE.
     */
    public navigateToAppDefaultState(): void {
        this.$state.go(DEFAULT_STATE);
    }

    /**
     * Navigates to cloud-list page.
     */
    public navigateToCloudList(): void {
        this.$state.go('authenticated.infrastructure.cloud-list');
    }

    /**
     * Initialize and load systemConfig instance.
     */
    private async initSystemConfig(): Promise<void> {
        const systemConfig = new this.SystemConfig();

        await systemConfig.load();

        this.systemConfig = systemConfig;
    }

    /**
     * Prepares welcome modal data.
     */
    private async getDataModel(): Promise<void> {
        await Promise.all([
            this.initSystemConfig(),
            this.initBackupConfig(),
        ]);
    }

    /**
     * Init backup configurations.
     */
    private async initBackupConfig(): Promise<void> {
        const initialBackupConfigId = this.stringService.slug(
            // defaultValues is guaranteed to be loaded before the service is ready by injection
            // resolving from ui-router, which is configured in app-config.js
            this.defaultValues.getSystemObjectRefByName(
                'backupconfiguration',
                // this is the default name of the initial Backup Configuration object
                'Backup-Configuration',
            ),
        );

        this.backupConfig = new this.BackupConfiguration({
            id: initialBackupConfigId,
        });

        // load data so that it has uuid and _last_modified to unblock saving
        await this.backupConfig.load();
    }
    /**
     * Returns props to be passed to the FullModal.
     */
    private getWelcomeModalProps(): IFullModalLayout {
        const { l10nService } = this;

        return {
            component: WelcomeModalComponent as Type<Component>,
            componentProps: {
                systemConfig: this.systemConfig,
                backupConfig: this.backupConfig,
                onWelcomeModalClose: this.onWelcomeModalClose,
                onWelcomeModalDismiss: this.onWelcomeModalDismiss,
            },
            getName: () => l10nService.getMessage(l10nKeys.welcomeLabel),
            getDescription: () => '',
        };
    }

    /**
     * Callback for welcome modal close.
     *
     * @param openCloudModal True, if user wants to open cloud modal after completion.
     */
    private onWelcomeModalClose = (openCloudModal: boolean): void => {
        this.welcomeCompleteSubject.next(openCloudModal);
        this.welcomeCompleteSubject.complete();
    };

    /**
     * Callback for welcome modal dismiss.
     *
     * Just closes the modal.
     * Will not complete welcomeCompleteSubject.
     */
    private onWelcomeModalDismiss = (): void => {
        this.welcomeCompleteSubject.next();
    };

    /**
     * Destroy and reset item and collection instances.
     */
    private reset(): void {
        this.backupConfig.destroy();
        this.systemConfig.destroy();

        this.backupConfig = null;
        this.systemConfig = null;
    }
}
