/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './ordered-grid.less';
import * as l10n from './ordered-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name orderedGrid
 * @description
 *     Grid component for displaying a set of data. Order is a priority in this component, so
 *     sorting is not an option. Methods and fields are defined in the "config", and data comes from
 *     the "rows" binding. Ideally, the "rows" come from a collection class, and the methods in the
 *     config that interact with the data just call methods on that collection. This component does
 *     not interact with the collection itself however, and is only concerned with displaying data
 *     and calling methods on the "config".
 *
 *     Methods defined on "config.actions":
 *         handleCreate - If defined, displays a "Create" button for creating new "rows".
 *         handleDragAndDropChange - If defined, allows drag-and-drop on rows.
 *
 * @example
 *     scope.config = new PolicyGridConfig({
 *         collection: scope.collectionClass,
 *         getRowId: row => scope.collectionClass.getRowId(row),
 *         fields: [{
 *             title: 'Index',
 *             name: 'index',
 *             template: '<span>{{ row.index }}</span>'
 *         }, {
 *             title: 'Enable',
 *             name: 'enable',
 *             template: `<checkbox ng-model="row.enable"></checkbox>`
 *         }, {
 *             title: 'Name',
 *             name: 'name',
 *             template: '<b>{{ row.name }}</b>'
 *         }],
 *         actions: {
 *             handleCreate: () => scope.addRule(),
 *             handleDragAndDropChange: (oldIndex, newIndex) => {
 *                 scope.collectionClass.handleDragAndDropChange(oldIndex, newIndex);
 *             }
 *         },
 *         singleactions: [{
 *             title: 'Move rule',
 *             template: require('../grid.tooltip.partial.html')
 *         }, {
 *             title: 'Menu',
 *             template: require('../grid-menu.tooltip.partial.html'),
 *             edit: rule => scope.editRule(rule),
 *             create: index => scope.addRule(index),
 *             remove: rule => scope.removeRule(rule)
 *         }]
 *     });
 *
 */
class OrderedGridController {
    constructor($element, l10nService) {
        this._$element = $element;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Hash containing filters and filter functions. When determining which rows to display
         * in the grid, each function in this hash is called on the row, and the row is shown if
         * every function in the hash returns true for that row. Similar to the $$validators
         * hash. Search is stored as a value in this hash as well since it behaves in the same
         * way.
         */
        this._filters = {};

        /**
         * Hash containing the rows that are expanded, with the key being the rowId.
         */
        this._expanded = {};

        this.filterRow = this.filterRow.bind(this);
    }

    $onInit() {
        /**
         * ngModel property for the Search input. Gets updated by ordered-grid-controls
         * component.
         */
        this.searchTerm = '';

        /**
         * Allow column widths to be set only once.
         */
        this.setColumnWidths = _.once(this.setColumnWidths);

        /**
         * If 'search' is one of the actions configured, add it to this._filters.
         */
        if (this.config.actions && this.config.actions.search) {
            this._filters['search'] = this.config.actions.search;
        }
    }

    /**
     * Returns the unique identifier for the row, defined on the config.
     * @param {Object} row - Row object.
     */
    getRowId(row) {
        return this.config.getRowId(row);
    }

    /**
     * ClassName generator.
     * @param {string} name - field#name
     * @return {string}
     * @public
     */
    getClassFromName(name) {
        return name ? name.replace(/\./g, '-') : '';
    }

    /**
     * A filter function used to filter rows based on the filters in this._filters. The row is
     * displayed if every function in the hash returns true for the row.
     * @param {Object} row - Row object.
     * @return {boolean} - True if row should be shown.
     */
    filterRow(row) {
        const { _filters: filters, searchTerm } = this;

        return !this._hasFilters() || _.every(filters, filter => filter(row, searchTerm));
    }

    /**
     * Handler passed to ordered-grid-controls to update this.searchTerm.
     */
    handleSearch(searchTerm) {
        this.searchTerm = searchTerm;
    }

    /**
     * Calls singleaction (row button clicked).
     * @param {Object} row - Row object.
     * @param {Object} action - The action object (defined in config).
     * @param {number} index - Id of row in a grid.
     * @param {Object} event - AngularJS $event object.
     */
    doSingleaction(row, action, index, event) {
        event.stopPropagation();
        action.do.call(this.config, row, index, event);
    }

    /**
     * Returns true if the singleaction should be hidden.
     * @param {Object} row - Row object.
     * @param {Object} action - The action object.
     * @return {boolean}
     */
    hideSingleaction(row, action) {
        let hidden = false;

        if (angular.isObject(action.layout)) {
            const { layout } = action;

            if (layout.hiddenOnFilter && this._hasFilters()) {
                hidden = true;
            }
        }

        if (angular.isFunction(action.hidden) && action.hidden.call(this.config, row)) {
            hidden = true;
        } else if (action.hidden) {
            hidden = true;
        }

        return hidden;
    }

    /**
     * Returns true if reordering rows should be disallowed. True if a row has been selected or
     * if rows have been filtered.
     * @return {boolean}
     */
    disallowReordering() {
        return this._hasFilters() && !this.filteredOnlyByEmptySearch();
    }

    /**
     * Returns true if this._filters contains only the search filter and no search is being
     * performed.
     * @return {boolean}
     */
    filteredOnlyByEmptySearch() {
        return _.size(this._filters) === 1 && 'search' in this._filters &&
                _.isEmpty(this.searchTerm);
    }

    /**
     * Returns true if rows are being filtered, either by filters or search.
     * @return {boolean}
     */
    _hasFilters() {
        return !_.isEmpty(this._filters);
    }

    /**
     *
     * Returns true if the clickable div between rows should be displayed.
     * @return {boolean}
     */
    showClickBetween() {
        return !angular.isUndefined(this.config.actions.createAt);
    }

    /**
     * Click handler for clicking the div between rows. Calls actions.createAt.
     * @param {angular.$event} $event - Angular $event object.
     * @param {string} position - Either 'above' or 'below' the target rule.
     * @param {Object} rule - Target rule to create above or below.
     */
    handleClickBetween($event, position, rule) {
        $event.stopPropagation();

        let targetRule = rule;

        if (_.isEmpty(rule)) {
            targetRule = this.rows[this.rows.length - 1];
        }

        this.config.actions.createAt(targetRule, position);
    }

    /**
     * Returns true if a row is expanded.
     * @param {Object} row - Target rule.
     * @return {boolean}
     */
    isExpanded(row) {
        return Boolean(this._expanded[this.getRowId(row)]);
    }

    /**
     * Handler for clicking the expand button.
     * @param {Object} row - Target rule.
     */
    handleExpand(row) {
        this._expanded[this.getRowId(row)] = !this._expanded[this.getRowId(row)];
    }

    /**
     * Methods related to grid resizing. Used by orderedGridResizer and orderedGridExpander child
     * components.
     */

    /**
     * Gets the total width of a field element including padding. Expected DOM structure:
     * <th class="header-table-cell grid-field-0">
     *     <div class="field">
     *         <span class="field-title"></span>
     *     </div>
     * </th>
     * Returns the width of span.field-title and the padding of div.field.
     * @param {HTMLElement} element - DOM node element.
     * @return {number}
     */
    static getTotalThWidth(element) {
        const field = $(element).find('.ordered-grid__cell--header__title');
        const padding = field.innerWidth() - field.width();
        const fieldTitle = field.find('span');

        return fieldTitle.length ? fieldTitle.width() + padding : field.innerWidth();
    }

    /**
     * Returns orderedGrid child elements matching the selector.
     * @param {string} selector - Selector expression to match elements against.
     * @return {JQuery}
     */
    _getChildElement(selector) {
        return this._$element.find(selector);
    }

    /**
     * Returns the 'th' element matching the selector class.
     * @param {string} selector - Selector expression to match elements against.
     * @return {JQuery}
     */
    _getColumnHeader(selector) {
        return this._getChildElement(`.ordered-grid__cell--header${selector}`);
    }

    /**
     * Returns the total width of the header element matching the selector.
     * @param {string} selector - Selector expression to match elements against.
     * @return {number}
     */
    getColumnHeaderWidth(selector) {
        return this._getColumnHeader(selector).innerWidth();
    }

    /**
     * Returns the left offset of the header element matching the selector.
     * @param {string} selector - Selector expression to match elements against.
     * @return {number}
     */
    getColumnHeaderLeftOffset(selector) {
        return this._getColumnHeader(selector).offset().left;
    }

    /**
     * Returns the smallest width a column can be minimized, equal to the width of the field
     * title string along with its padding.
     * @param {string} selector - Selector expression to match elements against.
     * @return {number}
     */
    getMinimumHeaderWidth(selector) {
        const header = this._getColumnHeader(selector);

        return OrderedGridController.getTotalThWidth(header);
    }

    /**
     * Sets each th element width to the existing width to prevent unwanted automatic width
     * changes during dragging.
     */
    setColumnWidths() {
        const { classNamePrefix } = OrderedGridController;
        const theadSelector = '.ordered-grid__header';
        const tableWidth = this._getChildElement(theadSelector).width();

        /**
         * Set widths for drag-and-drop handle and singleactions.
         */
        OrderedGridController.uniqueFieldSelectors
            .forEach(selector => this._setRelativeWidth(selector, tableWidth));

        /**
         * Set widths for grid-fields.
         */
        this._getChildElement('.ordered-grid__cell--title').each(index => {
            const selector = `.${classNamePrefix + index}`;

            this._setRelativeWidth(selector, tableWidth);
        });
    }

    /**
     * Given a class name as a selector, sets those elements' current default widths as a
     * percentage.
     * @param {string} selector - Selector for columns to set widths on.
     * @param {number} totalWidth - Total width of the table.
     */
    _setRelativeWidth(selector, totalWidth) {
        const selectorElement = this._getChildElement(selector);
        const selectorElementWidth = selectorElement.innerWidth() / totalWidth * 100;

        selectorElement.css('flex', `0 0 ${selectorElementWidth}%`);
    }

    /**
     * Sets the width of elements using a selector.
     * @param {string} selector - Selector for the elements to set widths on.
     * @param {number} percentage - Width in percentage.
     */
    setWidth(selector, percentage) {
        const elements = this._getChildElement(selector);

        elements.css('flex', `0 0 ${percentage}%`);
    }

    /**
     * Returns the total width of the table as a number in pixels.
     * @return {number}
     */
    getTableWidth() {
        return this._$element.find('.ordered-grid').width() || 0;
    }

    /**
     * Returns the maxX value, or the maximum x-coordinate value that the element can be
     * dragged.
     * @param {string} selector - Class selector used to get the 'th' element.
     * @return {number}
     */
    getMaxX(selector) {
        const { headerClassName } = OrderedGridController;
        const thElement = this._getChildElement(`.${headerClassName}${selector}`);
        const tableWidth = this.getTableWidth();
        const max = (this._$element.offset().left || 0) + tableWidth;
        let rightMin = 0;

        const nextTh = thElement.next(`.${headerClassName}`);

        rightMin += OrderedGridController.getTotalThWidth(nextTh);

        nextTh
            .nextAll(`.${headerClassName}`)
            .each((index, element) => rightMin += $(element).innerWidth());

        /**
         * Add a small buffer of 5px for max to prevent overlapping from rounding errors.
         */
        return max - rightMin - 5;
    }
}

OrderedGridController.$inject = [
    '$element',
    'l10nService',
];

OrderedGridController.classNamePrefix = 'ordered-grid__cell--';

const { classNamePrefix } = OrderedGridController;

OrderedGridController.headerClassName = `${classNamePrefix}header`;
OrderedGridController.uniqueFieldSelectors = [
    `.${classNamePrefix}drag-and-drop`,
    `.${classNamePrefix}rowactions`,
];

angular.module('aviApp').component('orderedGrid', {
    bindings: {
        config: '<',
        rows: '<',
    },
    controller: OrderedGridController,
    templateUrl: 'src/components/common/ordered-grid/ordered-grid.html',
});
