/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'security';
const componentName = 'jwt-server-profile-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [actionBtnDelete]: 'Delete',
};
