/** @module GeoDBModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import template from './geo-db-federated-grid-cell.component.html';
import './geo-db-federated-grid-cell.component.less';

export const GEO_DB_FEDERATED_GRID_CELL_TOKEN = 'geoDbFederatedGridCell';

/**
 * @author Rachit Aggarwal
 */
class GeoDbFederatedGridCellController {
    /**
     * boolean value whether Geo DB is federated.
     */
    public isFederated: boolean;
}

export const geoDbFederatedGridCellOptions = {
    bindings: {
        isFederated: '<',
    },
    controller: GeoDbFederatedGridCellController,
    template,
};
