/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function staticRouteFactory(Item) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Item
     * @author Ram Pal
     * @desc StaticRoute item
     */
    class StaticRoute extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.config && data.config.route_id || '';
        }

        /**
         * Called on ng-change of VRF Context collection dropdown. Sets the
         * VRF Context collection so we know where to put the Static Route.
         * @param  {Boolean} onClear - If called by onclear, set collection to 'global'.
         */
        loadVRFContextCollection(onClear) {
            const self = this;

            // Default collection is with VRFContext === 'global'. When dropdown value is changed or
            // cleared, we load the new collection.
            this.collection.vrf = onClear ? 'global' : this.data.config.vrfContext.name();

            this.busy = true;
            this.collection.load().finally(function() {
                self.busy = false;
            });
        }

        dataToSave() {
            const data = angular.copy(this.data.config);

            if (!data.route_id) {
                data.route_id = this.collection.getNewIndex();
                this.data.config.route_id = data.route_id; // update the item also
            }

            return data;
        }

        saveRequest() {
            if (!this.collection) {
                return;
            }

            return this.collection.save(this.dataToSave());
        }

        drop() {
            if (!this.collection) {
                return;
            }

            return this.collection.drop(this.data.config.route_id);
        }

        /**
         * Called from getNewIndex method in Collection.
         * @returns {string} route id for individual item.
         */
        get routeId() {
            const { route_id: routeId } = this.getConfig();

            return routeId;
        }
    }

    Object.assign(StaticRoute.prototype, {
        objectName: 'vrfcontext',
        windowElement: 'infra-staticroute-create',
        loadOnEdit: false,
        restrictEditOnEssentialLicense_: false,
    });

    return StaticRoute;
}

staticRouteFactory.$inject = [
    'Item',
];

angular.module('avi/app')
    .factory('StaticRoute', staticRouteFactory);
