/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { AzureConfigurationConfigItem } from 'ajs/modules/cloud';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { L10nService } from '@vmw/ngx-vip';

import {
    AzureConfiguration,
    AzureNetworkInfo,
} from 'object-types';

import { IAzureNetworkInfo } from 'generated-types';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';

import * as l10n from './azure-location-netowrk.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const AvailabilityZones = [
    '1',
    '2',
    '3',
];

/**
 * @description Component for Location & Network Section in Azure Configuration Cloud.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'azure-location-network',
    templateUrl: './azure-location-network.component.html',
})
export class AzureLocationNetworkComponent implements OnInit {
    /**
     * AzureNetworkInfo ConfigItem.
     */
    @Input()
    public editable: RepeatedMessageItem<MessageItem<IAzureNetworkInfo>>;

    /**
     *  AzureConfiguration ConfigItem used for setting location and resource group.
     */
    @Input()
    public azureConfiguration: AzureConfigurationConfigItem;

    /**
     * If Subscription Id and Azure credentials are set and connected.
     */
    @Input()
    public credentialsConnected: boolean;

    /**
     * Location dropdown options passed from AzureConfiguration component.
     */
    @Input()
    public locationDropdownOptions: IAviDropdownOption[];

    /**
     * Resource Group dropdown options passed from AzureConfiguration component.
     */
    @Input()
    public resourceGroupDropdownOptions: IAviDropdownOption[];

    /**
     * Flag to check if cloud is in edit mode.
     */
    @Input()
    public isEditing: boolean;

    /**
     * VNet dropdown options.
     */
    public vNetDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Service Engine Network drodpown options.
     */
    public seNetworkDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Subnet for Management Interface dropdown options.
     */
    public subnetMiDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Service Engine Disk Encryption dropdown options.
     */
    public seDiskEcryptionDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Flag to check if Use Dedicated Management Interface checkbox is selected.
     */
    public useDedicatedManagementInterface = false;

    /**
     * Object Type to be used in template.
     */
    public readonly objectType = AzureNetworkInfo;

    /**
     * Azure Configuration object type to be used in template.
     */
    public readonly azureConfigObjectType = AzureConfiguration;

    /**
     * Holds enum values to be passed to avi-enum-dropdown.
     */
    public availabilityZones: IAviDropdownOption[];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Formats values received from back end to dropdown display values.
     */
    private static formatDropdownDisplayValues(firstValue: string, secondValue: string): string {
        return `${firstValue} - ${secondValue}`;
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isEditing) {
            const {
                management_network_id: managementNetworkId,
            } = this.networkInfoConfig.config;

            this.setVNetDropdownOptions();
            this.setSeNetworkDropdownOptions();
            this.setSeDiskEncryptionDropdownOptions();

            this.useDedicatedManagementInterface = Boolean(managementNetworkId);
        }

        this.availabilityZones = AvailabilityZones.map(
            (availabilityZone: string) => createDropdownOption(availabilityZone, availabilityZone),
        );
    }

    /**
     * Returns NetowrkInfo Config object.
     */
    public get networkInfoConfig(): MessageItem<IAzureNetworkInfo> {
        return this.editable.at(0);
    }

    /**
     * Returns location property on Azure Configuration Object.
     */
    public get location(): string | undefined {
        return this.azureConfiguration.config.location;
    }

    /**
     * Returns helper text for VNet dropdown.
     */
    public get vNetHelperText(): string {
        const {
            l10nService,
            l10nKeys,
            location,
        } = this;

        return !location ? l10nService.getMessage(l10nKeys.vNetHelperText) : '';
    }

    /**
     * Returns helper text for Service Engine Network dropdown.
     */
    public get seNetworkHelperText(): string {
        const {
            l10nService,
            l10nKeys,
            networkInfoConfig,
        } = this;

        return !networkInfoConfig.config.virtual_network_id ? l10nService.getMessage(
            l10nKeys.serviceEngineHelperText,
        ) : '';
    }

    /**
     * Returns helper text for Subnet for Management Interface dropdown.
     */
    public get subnetHelperText(): string {
        const {
            l10nService,
            l10nKeys,
            networkInfoConfig,
        } = this;

        return !networkInfoConfig.config.virtual_network_id ? l10nService.getMessage(
            l10nKeys.subnetHelperText,
        ) : '';
    }

    /**
     * Returns helper text for Service Engine Disk Encryption dropdown.
     */
    public get seDiskEncryptionHelperText(): string {
        const {
            l10nService,
            l10nKeys,
            location,
        } = this;

        return !location ? l10nService.getMessage(l10nKeys.seDiskEncryptionHelperText) : '';
    }

    /**
     * Triggered when Location dropdown value is changed.
     */
    public handleLocationDropdownChange(): void {
        this.resetVnetDropdownOptions();
        this.resetSeDiskEcryptionDropdownOptions();
        this.setVNetDropdownOptions();
        this.setSeDiskEncryptionDropdownOptions();
        this.azureConfiguration.removeLocationBasedFields();
    }

    /**
     * Triggered when VNet drodown value is changed.
     */
    public handleVnetDropdownChange(): void {
        this.resetSeNetworkDropdownOptions();
        this.resetSubnetMiDropdownOptions();
        this.setSeNetworkDropdownOptions();
    }

    /**
     * Triggered when Use Dedicated Management Interface Checkbox is selected/unselected.
     */
    public handleUseDedicatedManagementInterfaceChange(): void {
        this.azureConfiguration.setManagementNetworkId(this.useDedicatedManagementInterface);
    }

    /**
     * Disable VNet dropdown if location is not set or azureconfiguration Config Item is busy.
     */
    public disableVnet(): boolean {
        return !this.location || this.azureConfiguration.busy;
    }

    /**
     * Disable Service Engine Network or Subnet For Management Interface Dropdown if,
     * VNet ID is not set or azureconfiguration Config Item is busy.
     */
    public disableSeNetworkOrSubnetManagementInterface(): boolean {
        return this.disableVnet() || !this.networkInfoConfig.config.virtual_network_id;
    }

    /**
     * Handles Use Azure's Enhanced Feature HA checkbox changes.
     */
    public handleAzureEnhancedHaChange(): void {
        const { use_enhanced_ha: useEnhancedHa } = this.azureConfiguration.config;

        if (!useEnhancedHa) {
            this.azureConfiguration.removeAvailabilityZones();
        }
    }

    /**
     * Resets VNet dropdown options on change of Location.
     */
    private resetVnetDropdownOptions(): void {
        this.vNetDropdownOptions = [];
    }

    /**
     * Fetches list of Virtual Networks and sets the dropdown options for VNet.
     */
    private async setVNetDropdownOptions(): Promise<void> {
        const vNets = await this.azureConfiguration.fetchVirtualNetworks() || [];

        this.vNetDropdownOptions = vNets.map(
            ({ cidr, id, name }) => {
                const nameWithCidr = AzureLocationNetworkComponent.formatDropdownDisplayValues(
                    name,
                    cidr.join(', '),
                );

                return createDropdownOption(id, nameWithCidr);
            },
        );
    }

    /**
     * Resets Service Engine Network dropdown options when VNet dropdown value is changed.
     */
    private resetSeNetworkDropdownOptions(): void {
        this.seNetworkDropdownOptions = [];
    }

    /**
     * Fetches list of Service Engine Networks and sets the dropdown options for SE Networks.
     * Sets the dropdown options for Subnet for Management Interface.
     */
    private async setSeNetworkDropdownOptions(): Promise<void> {
        const subNets = await this.azureConfiguration.fetchSubNets() || [];

        this.seNetworkDropdownOptions = subNets.map(
            ({ cidr, id, name }) => {
                const nameWithCidr = AzureLocationNetworkComponent.formatDropdownDisplayValues(
                    name,
                    cidr,
                );

                return createDropdownOption(id, nameWithCidr);
            },
        );

        this.setSubnetMiDropdownOptions();
    }

    /**
     * Resets dropdown options for Subnet for Management Interface.
     */
    private resetSubnetMiDropdownOptions(): void {
        this.subnetMiDropdownOptions = [];
    }

    /**
     * Sets the dropdown options for Subnet for Management Interface.
     */
    private setSubnetMiDropdownOptions(): void {
        this.subnetMiDropdownOptions = [...this.seNetworkDropdownOptions] || [];
    }

    /**
     * Resets Service Engine Disk Encryption dropdown options,
     * when Location dropdown value is changed.
     */
    private resetSeDiskEcryptionDropdownOptions(): void {
        this.seDiskEcryptionDropdownOptions = [];
    }

    /**
     * Fetches list of disk encryption sets and sets the dropdown options for SE Disk Encryption.
     */
    private async setSeDiskEncryptionDropdownOptions(): Promise<void> {
        const diskEncryptionSets = await this.azureConfiguration.fetchDiskEcnryptionSets() || [];

        this.seDiskEcryptionDropdownOptions = diskEncryptionSets.map(
            ({ id, name, resource_group: resourceGroup }) => {
                const formattedName = AzureLocationNetworkComponent.formatDropdownDisplayValues(
                    name,
                    resourceGroup,
                );

                return createDropdownOption(id, formattedName);
            },
        );
    }
}
