/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'tcp-proxy';
const prefix = `${moduleName}.${componentName}`;

export const autoLearnInputLabel = `${prefix}.autoLearnInputLabel`;
export const customInputLabel = `${prefix}.customInputLabel`;
export const naglesAlgorithmInputLabel = `${prefix}.naglesAlgorithmInputLabel`;
export const timeoutHeader = `${prefix}.timeoutHeader`;
export const idleConnectionInputLabel = `${prefix}.idleConnectionInputLabel`;
export const keepAliveRadioOption = `${prefix}.keepAliveRadioOption`;
export const ageOutIdleConnectonsRadioOption = `${prefix}.ageOutIdleConnectonsRadioOption`;
export const secondsHelperText = `${prefix}.secondsHelperText`;
export const millisecondsHelperText = `${prefix}.millisecondsHelperText`;
export const idleDurationInputPlaceholder = `${prefix}.idleDurationInputPlaceholder`;
export const idleDurationInputLabel = `${prefix}.idleDurationInputLabel`;
export const ignoreTimeWaitInputLabel = `${prefix}.ignoreTimeWaitInputLabel`;
export const ignoreTimeWaitDurationInputPlaceholder = `${prefix}.ignoreTimeWaitDurationInputPlaceholder`;
export const ignoreTimeWaitDurationInputLabel = `${prefix}.ignoreTimeWaitDurationInputLabel`;
export const retransmissionBehaviorHeader = `${prefix}.retransmissionBehaviorHeader`;
export const maxRetransmissionInputLabel = `${prefix}.maxRetransmissionInputLabel`;
export const maxSynRetransmissionInputLabel = `${prefix}.maxSynRetransmissionInputLabel`;
export const maxCountInputPlaceholder = `${prefix}.maxCountInputPlaceholder`;
export const bufferManagementHeader = `${prefix}.bufferManagementHeader`;
export const receiveWindowInputLabel = `${prefix}.receiveWindowInputLabel`;
export const kilobytesHelperText = `${prefix}.kilobytesHelperText`;
export const maxSegmentSizeHeader = `${prefix}.maxSegmentSizeHeader`;
export const useInterfaceMtuInputLabel = `${prefix}.useInterfaceMtuInputLabel`;
export const bytesHelperText = `${prefix}.bytesHelperText`;
export const maxSegmentInputPlaceholder = `${prefix}.maxSegmentInputPlaceholder`;
export const maxSegmentInputLabel = `${prefix}.maxSegmentInputLabel`;
export const qosTrafficEngineeringHeader = `${prefix}.qosTrafficEngineeringHeader`;
export const passThroughInputLabel = `${prefix}.passThroughInputLabel`;
export const dscpValueInputLabel = `${prefix}.dscpValueInputLabel`;
export const dscpInputPlaceholder = `${prefix}.dscpInputPlaceholder`;

export const ENGLISH = {
    [autoLearnInputLabel]: 'Auto Learn',
    [customInputLabel]: 'Custom',
    [naglesAlgorithmInputLabel]: 'Enable Nagles Algorithm',
    [timeoutHeader]: 'Timeout',
    [idleConnectionInputLabel]: 'Idle Connections',
    [keepAliveRadioOption]: 'TCP keepalive',
    [ageOutIdleConnectonsRadioOption]: 'Age Out Idle Connections',
    [secondsHelperText]: 'Seconds',
    [millisecondsHelperText]: 'Milliseconds',
    [kilobytesHelperText]: 'Kilobytes',
    [bytesHelperText]: 'Bytes',
    [idleDurationInputPlaceholder]: 'Enter Idle Duration',
    [idleDurationInputLabel]: 'Idle Duration',
    [ignoreTimeWaitInputLabel]: 'Ignore Time Wait',
    [ignoreTimeWaitDurationInputLabel]: 'TCP Time Wait Duration',
    [ignoreTimeWaitDurationInputPlaceholder]: 'Enter Time Wait Duration',
    [retransmissionBehaviorHeader]: 'Retransmission Behavior',
    [maxRetransmissionInputLabel]: 'Maximum Retransmissions',
    [maxSynRetransmissionInputLabel]: 'Maximum SYN Retransmissions',
    [maxCountInputPlaceholder]: 'Maximum #',
    [bufferManagementHeader]: 'Buffer Management',
    [receiveWindowInputLabel]: 'Receive Window',
    [maxSegmentSizeHeader]: 'TCP Max Segment Size',
    [useInterfaceMtuInputLabel]: 'Use Network Interface MTU for Size',
    [maxSegmentInputPlaceholder]: 'Enter Max Segment Size',
    [maxSegmentInputLabel]: 'Max Segment Size',
    [qosTrafficEngineeringHeader]: 'QOS & Traffic Engineering',
    [passThroughInputLabel]: 'Enable DSCP Pass Through',
    [dscpValueInputLabel]: 'DSCP Value',
    [dscpInputPlaceholder]: 'Enter DSCP Value',
};
