/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './policy-rule-summary.component.less';
import {
    Component, Input,
} from '@angular/core';

import {
    IPolicyActionInfo,
    IPolicyMatchInfo,
    IPolicyRuleConfig,
    PolicyRuleExtendableConfigItem,
} from 'ajs/modules/policies';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './policy-rule-summary.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

@Component({
    selector: 'policy-rule-summary',
    templateUrl: './policy-rule-summary.component.html',
})
export class PolicyRuleSummaryComponent {
    /**
     * PolicyRuleExtendableConfigItem instance.
     */
    @Input()
    public rule: PolicyRuleExtendableConfigItem<IPolicyRuleConfig>;

    /**
     * L10n keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Function for match info trackBy.
     */
    public trackByMatchLabel(index: number, matchInfo: IPolicyMatchInfo): string {
        return matchInfo.matchLabel;
    }

    /**
     * Function for action info trackBy.
     */
    public trackByActionLabel(index: number, actionInfo: IPolicyActionInfo): string {
        return actionInfo.actionLabel;
    }
}
