/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name PolicyGridService
 * @description Service used to get the string value from matches and actions from a policy rule.
 */
class PolicyGridService {
    constructor(RangeParser, getSubnetString, $filter, schemaService) {
        const matchOperationLabelHash = schemaService.getEnumValueLabelsHash('MatchOperation');
        const hdrMatchOperationLabelHash = schemaService
            .getEnumValueLabelsHash('HdrMatchOperation');

        this.matches = {
            client_ip: {
                name: 'Client IP',
                stringify(m) {
                    const value = matchOperationLabelHash[m.match_criteria];
                    const val = [];

                    if (m.addrs) {
                        _.each(m.addrs, item => val.push(item.addr));
                    }

                    if (m.ranges) {
                        _.each(m.ranges, item => {
                            val.push(`${item.begin.addr}-${item.end.addr}`);
                        });
                    }

                    if (m.prefixes) {
                        _.each(m.prefixes, item => val.push(getSubnetString(item)));
                    }

                    if (m.group_refs) {
                        _.each(m.group_refs, item => {
                            val.push(`group ${item.name() || item.slug()}`);
                        });
                    }

                    return `${value.toLowerCase()} (${val.join(', ')})`;
                },
            },
            client_ip_address: {
                name: 'DNS Client IP',
                stringify(m) {
                    const { client_ip: clientIpMatch } = m;

                    return clientIpMatch ?
                        matches['client_ip'].stringify(clientIpMatch.getConfig()) : '';
                },
            },
            vs_port: {
                name: 'Service Port',
                stringify(m) {
                    let value = matchOperationLabelHash[m.match_criteria];

                    value = value.replace(/ in/g, '');

                    return `${value.toLowerCase()} ${m.ports.join(', ')}`;
                },
            },
            protocol: {
                name: 'Protocol Type',
                stringify(m) {
                    if (m.protocol) {
                        let value = matchOperationLabelHash[m.match_criteria];

                        value = value.replace(/ in/g, '');

                        return `${value.toLowerCase()} ${m.protocol.enumeration()}`;
                    } else {
                        return m.protocols;
                    }
                },
            },
            method: {
                name: 'HTTP Method',
                stringify(m) {
                    const value = matchOperationLabelHash[m.match_criteria];
                    const methods = m.methods.map(item => item.replace(/^HTTP_METHOD_/g, ''))
                        .join(', ');

                    return `${value.toLowerCase()} (${methods})`;
                },
            },
            version: {
                name: 'HTTP Version',
                stringify(m) {
                    return _.map(m.versions, function(item) {
                        const { label } = schemaService.getEnumValue('HTTPVersion', item);

                        return label;
                    }).join(', ');
                },
            },
            path: {
                name: 'Path',
                stringify(m) {
                    const val = [];

                    Array.prototype.push.apply(val, m.match_str);

                    _.each(m.string_group_refs, function(item) {
                        val.push(`group ${item.name()}` || item.slug());
                    });

                    const { label } =
                        schemaService.getEnumValue('StringOperation', m.match_criteria);

                    return `${label.toLowerCase()} (${val.join(', ')})`;
                },
            },
            query: {
                name: 'Query',
                stringify(m) {
                    const val = [];

                    _.each(m.match_str, str => val.push(`"${str}"`));

                    _.each(m.string_group_refs, function(item) {
                        val.push(`group ${item.name()}` || item.slug());
                    });

                    return `contains ${val.join(' or ')}`;
                },
            },
            hdrs: {
                name: 'Headers',
                stringify(hdrs) {
                    return _.map(hdrs, function(hdr) {
                        const schema = hdrMatchOperationLabelHash[hdr.match_criteria];
                        const value = schema || hdr.match_criteria;
                        const contains = hdr.match_criteria === 'HDR_CONTAINS' ?
                            ` '${hdr.value.join('\' or \'')}'` : '';

                        return `'${hdr.hdr}' ${value.toLowerCase()}${contains}`;
                    }).join(', ');
                },
            },
            cookie: {
                name: 'Cookie',
                stringify(m) {
                    const schema = hdrMatchOperationLabelHash[m.match_criteria];
                    const value = (schema || m.match_criteria).toLowerCase();

                    return `${m.name} ${value} ${m.value || ''}`;
                },
            },
            host_hdr: {
                name: 'Host Header',
                stringify(m) {
                    const value = hdrMatchOperationLabelHash[m.match_criteria];

                    return `${value.toLowerCase()} '${m.value.join('\' or \'')}'`;
                },
            },
            loc_hdr: {
                name: 'Location Header',
                stringify(m) {
                    const value = hdrMatchOperationLabelHash[m.match_criteria];

                    return `${value.toLowerCase()} '${m.value.join('\' or \'')}'`;
                },
            },
            status: {
                name: 'HTTP Status',
                stringify(m) {
                    let ranges = [];

                    if (Array.isArray(m.ranges)) {
                        ranges = m.ranges.map(range => `${range.begin}-${range.end}`);
                    }

                    const statuses = m.status_codes.concat(ranges);
                    const value = matchOperationLabelHash[m.match_criteria];

                    return `${value.toLowerCase()} (${statuses.join(', ')})`;
                },
            },
            rsp_hdrs: {
                name: 'Response Header',
                stringify(hdrs) {
                    return _.map(hdrs, function(hdr) {
                        const schema = hdrMatchOperationLabelHash[hdr.match_criteria];
                        const value = schema || hdr.match_criteria;
                        const contains = hdr.match_criteria !== 'HDR_DOES_NOT_EXIST' &&
                                hdr.match_criteria !== 'HDR_EXISTS' ?
                            ` '${hdr.value.join('\' or \'')}'` : '';

                        return `'${hdr.hdr}' ${value.toLowerCase()}${contains}`;
                    }).join(', ');
                },
            },
            microservice: {
                name: 'MicroService',
                stringify(m) {
                    const schema = matchOperationLabelHash[m.match_criteria];
                    const value = schema || m.match_criteria;

                    return `${value.replace(' in', '').toLowerCase()} '${m.group_ref.name()}'`;
                },
            },
            geo_location: {
                name: 'Geographical Location',
                stringify(m) {
                    const schema = matchOperationLabelHash[m.match_criteria];
                    const value = schema || m.match_criteria;
                    const lowercase = value.replace(' in', '').toLowerCase();
                    let {
                        geolocation_name: name,
                        geolocation_tag: tag,
                    } = m;

                    name = name || 'N/A';
                    tag = tag || 'N/A';

                    return `${lowercase} Name: ${name}; Tag: ${tag}`;
                },
            },
            ip_reputation_type: {
                name: 'IP Reputation',
                stringify(match) {
                    const {
                        match_operation: matchOperation,
                        reputation_types: reputationTypes,
                    } = match;

                    const ipReputationTypeLabelsHash =
                        schemaService.getEnumValueLabelsHash('IPReputationType');

                    const ipReputationTypeLabels =
                        reputationTypes
                            .map(reputationType => ipReputationTypeLabelsHash[reputationType])
                            .join(', ');

                    const matchOperationLabel = matchOperationLabelHash[matchOperation];

                    return `${matchOperationLabel.toLowerCase()} '${ipReputationTypeLabels}'`;
                },
            },
            bot_detection_result: {
                name: 'Bot Management',
                stringify(match) {
                    const {
                        match_operation: matchOperation,
                        classifications,
                    } = match;

                    const classificationsTypeLabelsHash =
                        schemaService.getEnumValueLabelsHash('BotClassificationTypes');

                    const classificationsTypeLabels =
                        classifications.map(
                            classification => classificationsTypeLabelsHash[classification.type],
                        ).join(', ');

                    const matchOperationLabel = matchOperationLabelHash[matchOperation];

                    return `${matchOperationLabel.toLowerCase()} '${classificationsTypeLabels}'`;
                },
            },
            source_ip: {
                name: 'Source IP',
                stringify(m) {
                    const value = matchOperationLabelHash[m.match_criteria];
                    const val = [];

                    if (m.addrs) {
                        _.each(m.addrs, item => val.push(item.addr));
                    }

                    if (m.ranges) {
                        _.each(m.ranges, item => {
                            val.push(`${item.begin.addr}-${item.end.addr}`);
                        });
                    }

                    if (m.prefixes) {
                        _.each(m.prefixes, item => val.push(getSubnetString(item)));
                    }

                    if (m.group_refs) {
                        _.each(m.group_refs, item => {
                            val.push(`group ${item.name() || item.slug()}`);
                        });
                    }

                    return `${value.toLowerCase()} (${val.join(', ')})`;
                },
            },
        };

        const { matches } = this;

        this.actions = {
            redirect_action: {
                name: 'Redirect',
                stringify(a) {
                    // Stringify tokens
                    let host = '<ExistingHost>';
                    let path = '<ExistingPath>';

                    if (a.host && a.host.tokens.length) {
                        host = a.host.tokens.map(item => RangeParser.token2str(item)).join('.');
                    }

                    if (a.path && a.path.tokens.length) {
                        path = a.path.tokens.map(item => RangeParser.token2str(item)).join('/');
                    }

                    const protocol = a.protocol.toLowerCase();
                    const port = 'port' in a ? `:${a.port}` : '';
                    const query = a.keep_query ? '<KeepQueryString>' : '<RemoveQueryString>';

                    return `${protocol}://${host}${port}/${path}?${query}`;
                },
            },
            hdr_action: {
                name: 'Modify Header',
                stringify(action) {
                    let output = '';

                    _.each(action, function(hdr) {
                        output += `${hdr.action.enumeration('HTTP_')}: `;

                        const { value } = hdr.hdr;
                        const hdrVar = value && value.var ?
                            value.var.enumeration('HTTP_POLICY_VAR_') : 'Custom Value';
                        const hdrVal = value && value.val ? ` "${value.val}"` : '';

                        switch (hdr.action) {
                            case 'HTTP_REPLACE_HDR':
                                output += `replace "${hdr.hdr.name}" with ${hdrVar}${hdrVal}`;
                                break;
                            case 'HTTP_ADD_HDR':
                                output += `add "${hdr.hdr.name}" = ${hdrVar}${hdrVal}`;
                                break;
                            case 'HTTP_REMOVE_HDR':
                                output += `remove "${hdr.hdr.name}"`;
                                break;
                            default:
                                output += hdr.cookie ? `"${hdr.cookie.name}"` : '';

                                if (hdr.action !== 'HTTP_REMOVE_COOKIE') {
                                    output += ` = "${hdr.cookie.value}"`;
                                }
                        }

                        output += '; ';
                    });

                    return output;
                },
            },
            rewrite_url_action: {
                name: 'Rewrite URL',
                stringify(a) {
                    // Stringify tokens
                    let host = '<ExistingHostHeader>';
                    let path = '<ExistingPath>';

                    if (a.host_hdr && a.host_hdr.tokens.length) {
                        const { tokens } = a.host_hdr;

                        host = tokens.map(item => RangeParser.token2str(item)).join('.');
                    }

                    if (a.path && a.path.tokens && a.path.tokens.length) {
                        path = a.path.tokens.map(item => RangeParser.token2str(item)).join('/');
                    }

                    const query = a.query.keep_query ?
                        '<KeepQueryString>' : '<RemoveQueryString>';

                    return `Host Header: ${host}; Path: /${path}?${query}`;
                },
            },
            switching_action: {
                name: 'Content Switch',
                stringify(action) {
                    let output = '';

                    switch (action.action) {
                        case 'HTTP_SWITCHING_SELECT_POOL':
                            output += `Pool: ${action.pool_ref.name()}`;

                            if (action.server && action.server.ip && action.server.ip.addr) {
                                output += `, Server: ${action.server.ip.addr}`;

                                if (action.server.port) {
                                    output += `:${action.server.port}`;
                                }
                            }

                            break;
                        case 'HTTP_SWITCHING_SELECT_POOLGROUP':
                            output += `Pool Group: ${action.pool_group_ref.name()}`;
                            break;
                        case 'HTTP_SWITCHING_SELECT_LOCAL': {
                            const statusCode = action.status_code.replace(
                                'HTTP_LOCAL_RESPONSE_STATUS_CODE_', '',
                            );

                            const fileType = action.file ?
                                `, File: ${action.file.content_type}` :
                                '';

                            output += `Status Code: ${statusCode}${fileType}`;
                            break;
                        }
                    }

                    return output;
                },
            },
            loc_hdr_action: {
                name: 'Rewrite Location Header',
                stringify(action) {
                    let host = '<ExistingHost>';
                    let path = '<ExistingPath>';

                    if (action.host && action.host.tokens.length) {
                        const { tokens } = action.host;

                        host = tokens.map(item => RangeParser.token2str(item)).join('.');
                    }

                    if (action.path && action.path.tokens.length) {
                        const { tokens } = action.path;

                        path = tokens.map(item => RangeParser.token2str(item)).join('/');
                    }

                    const protocol = action.protocol.toLowerCase();
                    const port = 'port' in action ? `:${action.port}` : '';
                    const query = action.keep_query ?
                        '<KeepQueryString>' : '<RemoveQueryString>';

                    return `${protocol}://${host}${port}/${path}?${query}`;
                },
            },
            /**
             * Used in HTTP Security Policy, where the structure is different from HTTP Request
             * and Response Policies.
             */
            action: {
                name: row => {
                    let name = '';

                    if (angular.isObject(row.action)) {
                        name = schemaService
                            .getEnumValue('HTTPSecurityActionType', row.action.action).label;
                    } else {
                        name = row.action.enumeration('NETWORK_SECURITY_POLICY_ACTION_TYPE_');
                    }

                    return name;
                },
                stringify(action, row) {
                    return action.action ?
                        this.getHttpSecurityActionString(action) :
                        this.getNetworkSecurityActionString(row);
                },
                /**
                 * Used for HTTP Security Policies, where actions are nested under rule#action.
                 * Returns a string of the action configuration to be displayed.
                 * @param {Object} action - Policy action object.
                 * @return {string}
                 */
                getHttpSecurityActionString(action) {
                    const outputs = [];

                    switch (action.action) {
                        case 'HTTP_SECURITY_ACTION_SEND_RESPONSE': {
                            const statusCodeEnum = 'HTTP_LOCAL_RESPONSE_STATUS_CODE_';
                            const statusCode = action.status_code.enumeration(statusCodeEnum);

                            outputs.push(`Status Code: ${statusCode}`);

                            if (action.file && action.file.content_type) {
                                outputs.push(`File: ${action.file.content_type}`);
                            }

                            break;
                        }

                        case 'HTTP_SECURITY_ACTION_REDIRECT_TO_HTTPS':
                            outputs.push(`Port: ${action.https_port}`);
                            break;

                        case 'HTTP_SECURITY_ACTION_RATE_LIMIT':
                            if (action.rate_profile && action.rate_profile.action &&
                                    action.rate_profile.action.type) {
                                const { type } = action.rate_profile.action;

                                outputs.push(
                                    `Rate Limiter Action: ${type.enumeration('RL_ACTION_')}`,
                                );
                            }

                            break;
                    }

                    return outputs.join('; ');
                },
                /**
                 * Used for Network Security Policies, where rule#action is a string. Returns
                 * a string of the action configuration to be displayed.
                 * @param {Object} row - Policy rule object.
                 */
                getNetworkSecurityActionString(row) {
                    const outputs = [];

                    if (row.action === 'NETWORK_SECURITY_POLICY_ACTION_TYPE_RATE_LIMIT' &&
                            angular.isObject(row.rl_param)) {
                        const param = row.rl_param;

                        if (param.burst_size) {
                            outputs.push(`Burst Size: ${param.burst_size}`);
                        }

                        if (param.max_rate) {
                            outputs.push(`Maximum Rate: ${param.max_rate}`);
                        }
                    }

                    return outputs.join('; ');
                },
            },
        };

        this.getMatchName = this.getMatchName.bind(this);
        this.getMatchString = this.getMatchString.bind(this);
        this.getActionName = this.getActionName.bind(this);
        this.getActionString = this.getActionString.bind(this);
    }

    /**
     * Returns the name to use for the match property.
     * @param {string} property - Match property used for lookup.
     * @return {string}
     */
    getMatchName(property) {
        return this.matches[property].name;
    }

    /**
     * Returns a string representation of the match configuration.
     * @param {string} property - Match property used for lookup.
     * @param {Object} matchConfig - Data of the match.
     * @return {string}
     */
    getMatchString(property, matchConfig) {
        return this.matches[property].stringify(matchConfig);
    }

    /**
     * Returns the name to use for the action property.
     * @param {string} property - Action property used for lookup.
     * @param {Object} row - Row data object.
     * @return {string}
     */
    getActionName(property, row) {
        const { name } = this.actions[property];

        return angular.isFunction(name) ? name(row) : name;
    }

    /**
     * Returns a string representation of the action configuration.
     * @param {string} property - Action property used for lookup.
     * @param {Object} action - Action object containing the config.
     * @param {Object} row - Row data object
     * @return {string}
     */
    getActionString(property, action, row) {
        return this.actions[property].stringify(action, row);
    }
}

PolicyGridService.$inject = [
    'RangeParser',
    'getSubnetString',
    '$filter',
    'schemaService',
];

angular.module('aviApp').service('PolicyGridService', PolicyGridService);
