/**
 * Module containing portal related components.
 * @module CportalModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    ClrFormsModule,
    ClrInputModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { CoreModule } from 'ng/modules/core/core.module';

import { DialogModule } from '../dialog/dialog.module';

import {
    AddAttachmentsGridComponent,
    AttachmentsSelectionDialogComponent,
    CaseAttachmentsGridComponent,
    CaseCommentsListComponent,
    CaseCustomTagsGridComponent,
    CaseModalComponent,
    CasePreviewComponent,
    CPortalInfoModalComponent,
    CPortalInfoPreviewComponent,
    CPortalPageComponent,
    SplitProxyConfigurationComponent,
} from '.';

const caseComponents = [
    AddAttachmentsGridComponent,
    AttachmentsSelectionDialogComponent,
    CaseAttachmentsGridComponent,
    CaseCustomTagsGridComponent,
    CaseCommentsListComponent,
    CaseModalComponent,
    CasePreviewComponent,
    CPortalPageComponent,
    CPortalInfoModalComponent,
    CPortalInfoPreviewComponent,
    CPortalPageComponent,
    SplitProxyConfigurationComponent,
];

@NgModule({
    declarations: [
        ...caseComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        SharedModule,
        AviFormsModule,
        CoreModule,
        ClrFormsModule,
        ClrInputModule,
        ClrSpinnerModule,
        DialogModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class CportalModule {}
