/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IPortRange } from 'generated-types';

export const PORT_RANGE_CONFIG_ITEM_TOKEN = 'PortRangeConfigItem';
export class PortRangeConfigItem extends MessageItem<IPortRange> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'PortRange',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Get string representation of the config data.
     */
    public toString(): string {
        const { start, end } = this.config;

        return `${start}-${end}`;
    }
}
