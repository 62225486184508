/**
 * Module containing update related components.
 * @preferred
 * @module UpdateModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { UpgradeNodeType, UpgradeStateGroup } from 'ng/modules/update/upgrade.types';
import { ITUpgradeNode, UpgradeNodeCollection } from 'ajs/modules/upgrade';
import './recent-updates-list.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './recent-updates-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Panel card for update pages.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'recent-updates-list',
    templateUrl: './recent-updates-list.component.html',
})
export class RecentUpdatesListComponent implements OnInit, OnDestroy {
    /**
     * Types of nodes to ask API for.
     * i.e. controller or seg
     */
    @Input() public nodeType: UpgradeNodeType;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Instance of UpgradeNodeCollection.
     */
    private upgradeNodeCollection: UpgradeNodeCollection;

    /**
     * Filtered list to be used for displaying recent nodes.
     */
    public get nodeList(): ITUpgradeNode[] {
        return this.upgradeNodeCollection.itemList as ITUpgradeNode[];
    }

    /**
     * Getter for empty list place holder. Decided by what's expected to be displayed in the list.
     */
    public get emptyListPlaceHolder(): string {
        const {
            emptyListPlaceholderController,
            emptyListPlaceholderSeg,
        } = this.l10nKeys;

        if (this.isControllerUpdateList()) {
            return emptyListPlaceholderController;
        }

        return emptyListPlaceholderSeg;
    }

    public constructor(
        @Inject('UpgradeNodeCollection')
        private UpgradeNodeCollectionFactory: typeof UpgradeNodeCollection,
        l10nService: L10nService,
    ) {
        this.upgradeNodeCollection = new UpgradeNodeCollectionFactory();
        l10nService.registerSourceBundles(dictionary);
    }

    public ngOnInit(): void {
        this.upgradeNodeCollection.setParams({
            node_type: this.nodeType,
            state: `${UpgradeStateGroup.GROUP_COMPLETED},${UpgradeStateGroup.GROUP_ERROR}`,
        });

        this.upgradeNodeCollection.load();
    }

    public ngOnDestroy(): void {
        this.upgradeNodeCollection.destroy();
    }

    /**
     * Used by repeat (*ngFor) to track by unique identifier.
     */
    public trackNodeList(i: number, node: ITUpgradeNode): string {
        return node.id;
    }

    /**
     * Decide if this list is used by system update page to show only controller list.
     */
    private isControllerUpdateList(): boolean {
        return this.nodeType === UpgradeNodeType.CONTROLLER;
    }
}
