/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IL4RulePortMatch, MatchOperation } from 'generated-types';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { PortMatchConfigItem } from 'ajs/modules/match';
import { IPolicyMatchInfo } from 'ajs/modules/policies/policies.types';
import { PortRangeConfigItem } from 'ajs/modules/policies/factories/config-items';

interface IL4RulePortMatchConfig
    extends Omit<IL4RulePortMatch, 'match_criteria' | 'port_ranges'>
{
    match_criteria?: MatchOperation;
    port_ranges?: RepeatedMessageItem<PortRangeConfigItem>;
}

/**
 * @desc L4RulePortMatch MessageItem class.
 * @author Zhiqian Liu
 */
export class L4RulePortMatchConfigItem extends PortMatchConfigItem implements IPolicyMatchInfo {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'L4RulePortMatch',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IL4RulePortMatchConfig> {
        return {
            match_criteria: MatchOperation.IS_IN,
        };
    }

    /**
     * Label of match. Commonly used as part of expander info.
     */
    public get matchLabel(): string {
        return 'Service Port';
    }

    /**
     * Value of match to represent the data. Commonly used as part of expander info.
     */
    public get matchValue(): string {
        const {
            ports,
            port_ranges: portRanges,
        } = this.config;

        const hasPorts = ports?.length > 0;
        const hasPortRanges = !portRanges?.isEmpty();

        let portsValue = '';

        if (hasPorts) {
            portsValue = ports.join(', ');
        }

        let portRangesValue = '';

        if (hasPortRanges) {
            const portRangeList = portRanges.config.map(
                (portRangeConfigItem: any) => portRangeConfigItem.toString(),
            );

            portRangesValue = portRangeList.join(', ');
        }

        return [portsValue, portRangesValue].join(', ');
    }

    /**
     * Parse flattened array of ports to stand-alone port list and port range data.
     */
    public updateDataWithPortList(portList: number[]): void {
        this.config.ports = [];
        this.config.port_ranges.removeAll();

        const { ports, port_ranges: portRanges } = this.config;
        const sortedPorts = portList.slice()
            .sort((a, b) => a - b); // to avoid alphabetic sort

        let readingRange = false;
        let start: number;

        // handle up to the second to the last port number in the list
        for (let i = 0; i < sortedPorts.length - 1; i++) {
            const curr = sortedPorts[i];
            const next = sortedPorts[i + 1];

            // next is a consecutive number of current
            if (curr + 1 === next) {
                if (!readingRange) {
                    start = curr;
                    readingRange = true;
                }
            // next is not a consecutive number of current and reading range
            } else if (readingRange) {
                const end = curr;

                readingRange = false;

                portRanges.add({
                    start,
                    end,
                });
            // next is not a consecutive number of current and not reading range
            // meaning current is a stand-alone number
            } else {
                ports.push(curr);
            }
        }

        // handle the last port number in the list
        const last = sortedPorts[sortedPorts.length - 1];

        if (last) {
            if (readingRange) {
                const end = last;

                portRanges.add({
                    start,
                    end,
                });
            } else {
                ports.push(last);
            }
        }
    }

    /** @override */
    public toString(): string {
        return `${this.matchCriteriaLabel} ${this.matchValue}`;
    }

    /** @override */
    protected modifyConfigDataBeforeSave(): void {
        const { ports } = this.config;

        if (ports && ports.length === 0) {
            delete this.config.ports;
        }
    }
}
