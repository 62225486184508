/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function tenantUsersCollDataSourceFactory(CollDataSource) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.CollDataSource
     * @author Ashish Verma
     * @desc CollDataSource of {@link module:avi/app.TenantUsersCollection
     *        TenantUsersCollection}.
     */
    class TenantUsersCollDataSource extends CollDataSource {
        /** @override */
        getRequestParams_() {
            return {
                objectName_: 'user',
                includeName_: true,
                page_size: 200,
            };
        }

        /** @override */
        isInactive() {
            return !this.owner_.getNumberOfItems();
        }

        /** @override */
        processResponse_(resp) {
            const
                allTenantsKey = '__any_tenant|', //should be in sync with TenantUsersDataTransformer
                respHash = {};

            //translates array back to hash
            resp.data.results.forEach(function(tenant) {
                respHash[tenant.tenantId] = tenant;
            });

            _.each(this.owner_.itemById, function(tenant, tenantId) {
                if ('users' in tenant.data && Array.isArray(tenant.data.users)) {
                    tenant.data.users.length = 0;
                } else {
                    tenant.data.users = [];
                }

                if (tenantId in respHash) {
                    Array.prototype.push.apply(tenant.data.users, respHash[tenantId].users);
                }

                if (allTenantsKey in respHash) {
                    Array.prototype.push.apply(tenant.data.users, respHash[allTenantsKey].users);
                }
            }, this.owner_);

            return resp;
        }
    }

    return TenantUsersCollDataSource;
}

tenantUsersCollDataSourceFactory.$inject = [
    'CollDataSource',
];

angular.module('avi/app')
    .factory(
        'TenantUsersCollDataSource',
        tenantUsersCollDataSourceFactory,
    );
