/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { VsVip } from 'ajs/modules/vs';
import { Auth, StringService } from 'ajs/modules/core/services';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import * as l10n from './vs-vip-modal-preview.l10n';
import './vs-vip-modal-preview.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description VsVip modal preview component - displays Tenant and LastModified information.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vs-vip-modal-preview',
    templateUrl: './vs-vip-modal-preview.component.html',
})
export class VsVipModalPreviewComponent implements OnInit {
    /**
     * VsVip ObjectTypeItem instance.
     */
    @Input()
    public editable: VsVip;

    /**
     * True if VsvIp item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * Tenant name.
     */
    public tenantName: string;

    /**
     * Last modified timestamp of the item.
     */
    public lastModified: number;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly authService: Auth,
        private readonly stringService: StringService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
        l10nService: L10nService,
    ) {
        this.isEditing = Boolean(itemId);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const tenantRef = this.editable.getTenantRef() || this.authService.getCurrentTenantRef();

        this.tenantName = this.stringService.name(tenantRef);

        if (this.isEditing) {
            // eslint-disable-next-line no-underscore-dangle
            this.lastModified = Math.floor(this.editable.config._last_modified / 1000);
        }
    }
}
