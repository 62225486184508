/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterContentInit,
    Component,
    ContentChild,
    Input,
    OnDestroy,
} from '@angular/core';
import {
    NgControl,
    ValidationErrors,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import classnames from 'classnames';
import './avi-input-container.component.less';

/**
 * @description Component to wrap a label (or avi-label-with-tooltip) and an input element, used to
 *     replace Clarity's clr-input-container component.
 *
 * @author alextsg
 */
@Component({
    selector: 'avi-input-container',
    templateUrl: './avi-input-container.component.html',
})
export class AviInputContainerComponent implements AfterContentInit, OnDestroy {
    /**
     * Sets the noMarginTop property.
     */
    @Input('noMarginTop')
    private set setNoMarginTop(noMarginTop: boolean | '') {
        this.noMarginTop = noMarginTop === '' || noMarginTop;
    }

    /**
     * Optional Helper-text.
     * To be displayed below input field.
     */
    @Input()
    public helperText ?= '';

    /**
     * Optional Error text.
     * To be displayed when ngModel becomes invalid.
     */
    @Input()
    public errorText ?= '';

    /**
     * Transcluded ngControl instance.
     */
    @ContentChild(NgControl, { static: true })
    public ngControl: NgControl;

    /**
     * If true, removes the margin-top from the top-level div
     */
    public noMarginTop = false;

    /**
     * Hash of validation errors, coming from the ngControl's errors object. Updated on validation
     * status changes.
     */
    public validationErrors: ValidationErrors;

    /**
     * ngControl statusChanges subscription. Reference stored to unsubscribe when the component is
     * destroyed.
     */
    private statusChangesSubscription: Subscription;

    /** @override */
    public ngAfterContentInit(): void {
        if (this.ngControl) {
            this.statusChangesSubscription = this.ngControl.statusChanges.subscribe(() => {
                this.validationErrors = this.ngControl.control.errors || {};
            });
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.statusChangesSubscription) {
            this.statusChangesSubscription.unsubscribe();
        }
    }

    /**
     * Returns the classes to be set on the top level div.
     */
    public get topLevelClassNames(): string {
        return classnames(
            'avi-input-container',
            'clr-wrapper',
            this.noMarginTop && 'avi-input-container--no-margin-top',
        );
    }

    /**
     * Returns true if the ngControl is invalid.
     */
    public get invalid(): boolean {
        if (!this.ngControl) {
            return false;
        }

        const { control } = this.ngControl;
        const { dirty, invalid, touched } = control;

        return (touched || dirty) && invalid;
    }
}
