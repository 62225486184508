/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'vrf-context';

export const bgpPeerLabel = `${prefix}.bgpPeerLabel`;
export const bgpProfileLabel = `${prefix}.bgpProfileLabel`;
export const routingOptionsLabel = `${prefix}.routingOptionsLabel`;

export const ENGLISH = {
    [bgpPeerLabel]: 'Peer ({0})',
    [bgpProfileLabel]: 'BGP Profile',
    [routingOptionsLabel]: 'Routing Options',
};
