/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'webhook-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleCallbackUrl = `${prefix}.columnTitleCallbackUrl`;
export const columnTitleVerificationToken = `${prefix}.columnTitleVerificationToken`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleCallbackUrl]: 'Callback URL',
    [columnTitleVerificationToken]: 'Verification Token',
};
