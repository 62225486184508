/**
 * @module avi/dataModel
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { TPbMessageName } from 'ajs/modules/core/services/schema-service';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { filterMessageMap, IMessageMap } from '../services/message-map';
import { Constructor } from '../../../declarations/globals.d';

/**
 * @description
 *     Mixin to extend a BaseClass with methods and properties related to a messageMap.
 * @author alextsg
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withMessageMapMixin<T extends Constructor<AjsDependency>>(BaseClass: T) {
    return class MessageMapItem extends BaseClass {
        public static ajsDependencies = [
            'messageMapService',
        ];

        protected readonly objectType: TPbMessageName;

        /**
         * MessageMap containing the field names and MessageItems of those field names.
         */
        private readonly completeMessageMap: IMessageMap;

        /**
         * MessageMap with whiteListed fields.
         */
        private readonly whitelistedMessageMap: IMessageMap;

        /**
         * List of whitelisted fields. Used when converting an Item to an ObjectTypeItem but only
         * specified fields should be converted into MessageItems.
         */
        private readonly whitelistedFields: string[] = [];

        /**
         * @override
         */
        constructor(...args: any[]) {
            super(...args);

            const [{ whitelistedFields = [], objectType }] = args;

            this.objectType = objectType || this.objectType;

            if (!this.objectType) {
                throw new Error('withMessageMapMixin requires an objectType to be set.');
            }

            this.whitelistedFields = whitelistedFields || this.whitelistedFields;

            const messageMapService = this.getAjsDependency_('messageMapService');

            this.completeMessageMap = messageMapService.createMessageMap(this.objectType);
            this.whitelistedMessageMap = filterMessageMap(
                this.completeMessageMap,
                this.whitelistedFields,
            );
        }

        /**
         * Returns either the completeMessageMap or the whitelistedMessageMap.
         */
        public get messageMap(): IMessageMap {
            const {
                completeMessageMap,
                whitelistedFields,
                whitelistedMessageMap,
            } = this;

            return whitelistedFields.length ? whitelistedMessageMap : completeMessageMap;
        }
    };
}
