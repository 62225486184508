/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'bgp-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const warningHeaderText = `${prefix}.warningHeaderText`;
export const switchingTypeMessage = `${prefix}.switchingTypeMessage`;
export const confirmationText = `${prefix}.confirmationText`;
export const confirmButtonText = `${prefix}.confirmButtonText`;
export const bgpProfileHeader = `${prefix}.bgpProfileHeader`;
export const generalHeader = `${prefix}.generalHeader`;
export const bgpAutonomousSystemInputLabel = `${prefix}.bgpAutonomousSystemInputLabel`;
export const bgpAutonomousSystemInputPlaceholderLabel = `${prefix}.bgpAutonomousSystemInputPlaceholderLabel`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const keepaliveIntervalInputLabel = `${prefix}.keepaliveIntervalInputLabel`;
export const holdTimeInputLabel = `${prefix}.holdTimeInputLabel`;
export const routingHeader = `${prefix}.routingHeader`;
export const peersTabLabel = `${prefix}.peersTabLabel`;
export const peersHeader = `${prefix}.peersHeader`;
export const communityTabLabel = `${prefix}.communityTabLabel`;
export const communityHeader = `${prefix}.communityHeader`;
export const sendCommunityAttributesCheckboxLabel = `${prefix}.sendCommunityAttributesCheckboxLabel`;
export const localPreferenceLabel = `${prefix}.localPreferenceLabel`;
export const localPreferencePlaceholder = `${prefix}.localPreferencePlaceholder`;
export const numberAsPathPrependLabel = `${prefix}.numberAsPathPrependLabel`;
export const numberAsPathPrependPlaceholder = `${prefix}.numberAsPathPrependPlaceholder`;
export const secondsInnerText = `${prefix}.secondsInnerText`;
export const communityStringsHeader = `${prefix}.communityStringsHeader`;
export const communityStringsColumnLabel = `${prefix}.communityStringsColumnLabel`;
export const communityStringsInputPlaceholder = `${prefix}.communityStringsInputPlaceholder`;

export const ENGLISH = {
    [warningHeaderText]: 'Warning',
    [switchingTypeMessage]: 'Switching type will wipe out stored peers',
    [confirmationText]: 'Are you sure you want to continue?',
    [confirmButtonText]: 'Yes, continue',
    [bgpProfileHeader]: 'BGP Profile: {0}',
    [generalHeader]: 'General',
    [bgpAutonomousSystemInputLabel]: 'BGP Autonomous System (AS) ID',
    [bgpAutonomousSystemInputPlaceholderLabel]: 'Enter BGP Autonomous System (AS) ID',
    [typeInputLabel]: 'Type',
    [keepaliveIntervalInputLabel]: 'Keepalive Interval',
    [holdTimeInputLabel]: 'Hold Time',
    [routingHeader]: 'Routing Options',
    [peersTabLabel]: 'Peers',
    [peersHeader]: 'Peers',
    [communityTabLabel]: 'Community',
    [communityHeader]: 'Community',
    [sendCommunityAttributesCheckboxLabel]: 'Send Community Attributes to All Peers',
    [localPreferenceLabel]: 'Local Preference',
    [localPreferencePlaceholder]: 'Enter Local Preference',
    [numberAsPathPrependLabel]: 'Number of AS-Path Prepend',
    [numberAsPathPrependPlaceholder]: 'Enter Number of AS-Path Prepend',
    [secondsInnerText]: 'Seconds',
    [communityStringsHeader]: 'Community Strings ({0})',
    [communityStringsColumnLabel]: 'String',
    [communityStringsInputPlaceholder]: 'Enter String',
};
