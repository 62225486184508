/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AviPermissionResource,
    Privilege,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { IGridRow } from 'ajs/components/common/grid/grid-data-model';
import * as l10n from './role.service.l10n';

import {
    IBasicRoleNode,
    IPrivilege,
    IRoleNode,
    Permission,
} from './role.types';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Role service. Handles most interactions between, Role, RoleCollection and RoleController.
 */
export class RoleService extends AjsDependency {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Defines the Role structure.
     */
    private roleCategories: IBasicRoleNode[];

    /**
     * Maps permission name to it's type in {@link roleCategories}.
     */
    private permissionNodeMap: Record<string, IRoleNode> = {};

    private l10nService: L10nService;

    constructor() {
        super();

        this.roleCategories = [
            {
                name: l10nKeys.applicationLabel,
                permission: Permission.APPLICATION,
                children: [
                    {
                        name: l10nKeys.virtualServiceLabel,
                        permission: AviPermissionResource.PERMISSION_VIRTUALSERVICE,
                    },
                    {
                        name: l10nKeys.poolLabel,
                        permission: AviPermissionResource.PERMISSION_POOL,
                    },
                    {
                        name: l10nKeys.poolGroupLabel,
                        permission: AviPermissionResource.PERMISSION_POOLGROUP,
                    },
                    {
                        name: l10nKeys.httpPolicySetLabel,
                        permission: AviPermissionResource.PERMISSION_HTTPPOLICYSET,
                    },
                    {
                        name: l10nKeys.networkSecurityPolicyLabel,
                        permission: AviPermissionResource.PERMISSION_NETWORKSECURITYPOLICY,
                    },
                    {
                        name: l10nKeys.autoScaleLabel,
                        permission: AviPermissionResource.PERMISSION_AUTOSCALE,
                    },
                    {
                        name: l10nKeys.dnsPolicyLabel,
                        permission: AviPermissionResource.PERMISSION_DNSPOLICY,
                    },
                ],
            },
            {
                name: l10nKeys.profilesLabel,
                permission: Permission.PROFILE,
                children: [
                    {
                        name: l10nKeys.tcpUdpProfileLabel,
                        permission: AviPermissionResource.PERMISSION_NETWORKPROFILE,
                    },
                    {
                        name: l10nKeys.applicationProfileLabel,
                        permission: AviPermissionResource.PERMISSION_APPLICATIONPROFILE,
                    },
                    {
                        name: l10nKeys.persistenceProfileLabel,
                        permission: AviPermissionResource.PERMISSION_APPLICATIONPERSISTENCEPROFILE,
                    },
                    {
                        name: l10nKeys.healthMonitorLabel,
                        permission: AviPermissionResource.PERMISSION_HEALTHMONITOR,
                    },
                    {
                        name: l10nKeys.analyticsProfileLabel,
                        permission: AviPermissionResource.PERMISSION_ANALYTICSPROFILE,
                    },
                    {
                        name: l10nKeys.ipamDnsProfileLabel,
                        permission: AviPermissionResource.PERMISSION_IPAMDNSPROVIDERPROFILE,
                    },
                    {
                        name: l10nKeys.customIpamDnsProfileLabel,
                        permission: AviPermissionResource.PERMISSION_CUSTOMIPAMDNSPROFILE,
                    },
                    {
                        name: l10nKeys.trafficCloneLabel,
                        permission: AviPermissionResource.PERMISSION_TRAFFICCLONEPROFILE,
                    },
                    {
                        name: l10nKeys.icapProfileLabel,
                        permission: AviPermissionResource.PERMISSION_ICAP,
                    },
                ],
            },
            {
                name: l10nKeys.groupScriptLabel,
                permission: Permission.GROUPS,
                children: [
                    {
                        name: l10nKeys.ipAddressGroupLabel,
                        permission: AviPermissionResource.PERMISSION_IPADDRGROUP,
                    },
                    {
                        name: l10nKeys.stringGroupLabel,
                        permission: AviPermissionResource.PERMISSION_STRINGGROUP,
                    },
                    {
                        name: l10nKeys.dataScriptsLabel,
                        permission: AviPermissionResource.PERMISSION_VSDATASCRIPTSET,
                    },
                    {
                        name: l10nKeys.protocolParserScriptsLabel,
                        permission: AviPermissionResource.PERMISSION_PROTOCOLPARSER,
                    },
                ],
            },
            {
                name: l10nKeys.securityLabel,
                permission: Permission.SECURITY,
                children: [
                    {
                        name: l10nKeys.sslTlsProfileLabel,
                        permission: AviPermissionResource.PERMISSION_SSLPROFILE,
                    },
                    {
                        name: l10nKeys.authenticationProfileLabel,
                        permission: AviPermissionResource.PERMISSION_AUTHPROFILE,
                    },
                    {
                        name: l10nKeys.pingAccessAgentLabel,
                        permission: AviPermissionResource.PERMISSION_PINGACCESSAGENT,
                    },
                    {
                        name: l10nKeys.pkiProfileLabel,
                        permission: AviPermissionResource.PERMISSION_PKIPROFILE,
                    },
                    {
                        name: l10nKeys.sslTlsCertificatesLabel,
                        permission: AviPermissionResource.PERMISSION_SSLKEYANDCERTIFICATE,
                    },
                    {
                        name: l10nKeys.certificateManagementProfileLabel,
                        permission: AviPermissionResource.PERMISSION_CERTIFICATEMANAGEMENTPROFILE,
                    },
                    {
                        name: l10nKeys.hardwareSecurityModuleGroupLabel,
                        permission: AviPermissionResource.PERMISSION_HARDWARESECURITYMODULEGROUP,
                    },
                    {
                        name: l10nKeys.ssoPolicyLabel,
                        permission: AviPermissionResource.PERMISSION_SSOPOLICY,
                    },
                ],
            },
            {
                name: l10nKeys.policyLabel,
                permission: Permission.POLICIES,
                children: [
                    {
                        name: l10nKeys.natPolicyLabel,
                        permission: AviPermissionResource.PERMISSION_NATPOLICY,
                    },
                    {
                        name: l10nKeys.l4PolicyLabel,
                        permission: AviPermissionResource.PERMISSION_L4POLICYSET,
                    },
                ],
            },
            {
                name: l10nKeys.wafLabel,
                permission: Permission.WAF,
                children: [
                    {
                        name: l10nKeys.wafProfileLabel,
                        permission: AviPermissionResource.PERMISSION_WAFPROFILE,
                    },
                    {
                        name: l10nKeys.wafPolicyLabel,
                        permission: AviPermissionResource.PERMISSION_WAFPOLICY,
                    },
                    {
                        name: l10nKeys.positiveSecurityLabel,
                        permission: AviPermissionResource.PERMISSION_WAFPOLICYPSMGROUP,
                    },
                    {
                        name: l10nKeys.wafCrsLabel,
                        permission: AviPermissionResource.PERMISSION_WAFCRS,
                    },
                ],
            },
            {
                name: l10nKeys.errorPageLabel,
                permission: Permission.WAF,
                children: [
                    {
                        name: l10nKeys.errorPageProfileLabel,
                        permission: AviPermissionResource.PERMISSION_ERRORPAGEPROFILE,
                    },
                    {
                        name: l10nKeys.errorPageBodyLabel,
                        permission: AviPermissionResource.PERMISSION_ERRORPAGEBODY,
                    },
                ],
            },
            {
                name: l10nKeys.operationsLabel,
                permission: Permission.OPERATIONS,
                children: [
                    {
                        name: l10nKeys.alertConfigLabel,
                        permission: AviPermissionResource.PERMISSION_ALERTCONFIG,
                    },
                    {
                        name: l10nKeys.alertLabel,
                        permission: AviPermissionResource.PERMISSION_ALERT,
                    },
                    {
                        name: l10nKeys.alertActionLabel,
                        permission: AviPermissionResource.PERMISSION_ACTIONGROUPCONFIG,
                    },
                    {
                        name: l10nKeys.syslogLabel,
                        permission: AviPermissionResource.PERMISSION_ALERTSYSLOGCONFIG,
                    },
                    {
                        name: l10nKeys.emailLabel,
                        permission: AviPermissionResource.PERMISSION_ALERTEMAILCONFIG,
                    },
                    {
                        name: l10nKeys.snmpTrapsLabel,
                        permission: AviPermissionResource.PERMISSION_SNMPTRAPPROFILE,
                    },
                    {
                        name: l10nKeys.trafficCaptureLabel,
                        permission: AviPermissionResource.PERMISSION_TRAFFIC_CAPTURE,
                    },
                ],
            },
            {
                name: l10nKeys.infrastructureLabel,
                permission: Permission.INFRASTRUCTURE,
                children: [
                    {
                        name: l10nKeys.cloudLabel,
                        permission: AviPermissionResource.PERMISSION_CLOUD,
                    },
                    {
                        name: l10nKeys.serviceEngineLabel,
                        permission: AviPermissionResource.PERMISSION_SERVICEENGINE,
                    },
                    {
                        name: l10nKeys.serviceEngineGroupLabel,
                        permission: AviPermissionResource.PERMISSION_SERVICEENGINEGROUP,
                    }, {
                        name: l10nKeys.networkLabel,
                        permission: AviPermissionResource.PERMISSION_NETWORK,
                    },
                    {
                        name: l10nKeys.vrfContextLabel,
                        permission: AviPermissionResource.PERMISSION_VRFCONTEXT,
                    },
                    {
                        name: l10nKeys.userCredentialsLabel,
                        permission: AviPermissionResource.PERMISSION_USER_CREDENTIAL,
                    },
                ],
            },
            {
                name: l10nKeys.administrationLabel,
                permission: Permission.ADMINISTRATION,
                children: [
                    {
                        name: l10nKeys.systemSettingsLabel,
                        permission: AviPermissionResource.PERMISSION_SYSTEMCONFIGURATION,
                    },
                    {
                        name: l10nKeys.controllerLabel,
                        permission: AviPermissionResource.PERMISSION_CONTROLLER,
                    },
                    {
                        name: l10nKeys.rebootLabel,
                        permission: AviPermissionResource.PERMISSION_REBOOT,
                    },
                    {
                        name: l10nKeys.upgradeLabel,
                        permission: AviPermissionResource.PERMISSION_UPGRADE,
                    },
                    {
                        name: l10nKeys.troubleshootingLabel,
                        permission: AviPermissionResource.PERMISSION_TECHSUPPORT,
                    },
                    {
                        name: l10nKeys.internalLabel,
                        permission: AviPermissionResource.PERMISSION_INTERNAL,
                    },
                    {
                        name: l10nKeys.controllerSiteLabel,
                        permission: AviPermissionResource.PERMISSION_CONTROLLERSITE,
                    },
                    {
                        name: l10nKeys.softwareLabel,
                        permission: AviPermissionResource.PERMISSION_IMAGE,
                    },
                ],
            },
            {
                name: l10nKeys.accountsLabel,
                permission: Permission.ACCOUNT,
                children: [
                    {
                        name: l10nKeys.usersLabel,
                        permission: AviPermissionResource.PERMISSION_USER,
                    },
                    {
                        name: l10nKeys.rolesLabel,
                        permission: AviPermissionResource.PERMISSION_ROLE,
                    },
                    {
                        name: l10nKeys.tenantLabel,
                        permission: AviPermissionResource.PERMISSION_TENANT,
                    },
                ],
            },
            {
                name: l10nKeys.gslbLabel,
                permission: Permission.GSLB,
                children: [
                    {
                        name: l10nKeys.gslbConfigurationLabel,
                        permission: AviPermissionResource.PERMISSION_GSLB,
                    },
                    {
                        name: l10nKeys.gslbServicesLabel,
                        permission: AviPermissionResource.PERMISSION_GSLBSERVICE,
                    },
                    {
                        name: l10nKeys.gslbGeoProfileLabel,
                        permission: AviPermissionResource.PERMISSION_GSLBGEODBPROFILE,
                    },
                ],
            },
        ];
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns roles category structure.
     */
    public getRoleCategories(): IBasicRoleNode[] {
        return this.roleCategories;
    }

    /**
     * Returns current permission node map which can be modified by multiple services.
     */
    public get currentPermissionNodeMap(): Record<string, IRoleNode> {
        return this.permissionNodeMap;
    }

    /**
     * Processes {@link IBasicRoleNode} array and returns array containing {@link IRoleNode} items.
     * Modifies {@link permissionNodeMap} by mapping privilege type to created permission node.
     */
    public processRoles(
        source: IBasicRoleNode[],
        privilegeTypeMap: Record<string, string>,
        parentNode: IRoleNode,
    ): IRoleNode[] {
        const target: IRoleNode[] = [];
        const { l10nService } = this;

        for (const item of source) {
            const category = item;
            const privilegeNode: IRoleNode = {
                name: l10nService.getMessage(category.name),
                permission: category.permission,
                type: privilegeTypeMap[category.permission] || Privilege.NO_ACCESS,
                assorted: false,
                children: [] as IRoleNode[],
                parentNode,
                canWrite: true,
                canRead: true,
                hint: '',
            };

            this.permissionNodeMap[category.permission] = privilegeNode;
            target.push(privilegeNode);

            if (category.children && category.children.length) {
                privilegeNode.children = this.processRoles(
                    category.children, privilegeTypeMap, privilegeNode,
                );
                this.setRoleNodeType(privilegeNode);
            }
        }

        return target;
    }

    /**
     * Sets specified RoleNode's type based on its children properties.
     */
    public setRoleNodeType(roleNode: IRoleNode): void {
        if (roleNode && roleNode.children || !roleNode.children.length) {
            let allSelected = true;
            let hasRead = false;
            let hasWrite = false;
            let hasNoAccess = false;
            let [{ type }] = roleNode.children;

            for (const child of roleNode.children) {
                if (!child.type || child.type !== type) {
                    allSelected = false;
                }

                if (!hasNoAccess && child.type === Privilege.NO_ACCESS) {
                    hasNoAccess = true;
                }

                if (!hasRead && child.type === Privilege.READ_ACCESS) {
                    hasRead = true;
                }

                if (!hasWrite && child.type === Privilege.WRITE_ACCESS) {
                    hasWrite = true;
                }

                type = child.type;
            }

            roleNode.assorted = this.isAssorted(hasRead, hasWrite, hasNoAccess);

            if (allSelected) {
                roleNode.type = type;
            } else {
                roleNode.type = '';
            }
        }
    }

    /**
     * Maps privilege resource to its type.
     */
    public createPrivilegeHashMap(data: IPrivilege[]): Record<string, string> {
        const hashMap = {};

        if (!data || !data.length) {
            return hashMap;
        }

        for (const item of data) {
            hashMap[item.resource] = item.type;
        }

        return hashMap;
    }

    /**
     * Converts role nodes back to server defined privilege objects.
     */
    public flattenRoles(roles: IRoleNode[]): Record<string, object> {
        const privileges = {};

        for (const role of roles) {
            const parent = role;
            const { children } = parent;

            children.forEach((child: IRoleNode) => {
                const {
                    permission: resource,
                    type,
                } = child;

                if (resource && type) {
                    privileges[resource] = {
                        resource,
                        type,
                    };
                }
            });
        }

        return privileges;
    }

    /**
     * Returns roles row transform function for Roles table assuming each parent node in
     * {@link roleCategories} corresponds to each Role table column.
     */
    public getRowTransformFunctionBasedOnColumnIndex(
        columnIndex: number,
    ): (row: IGridRow) => string {
        const { l10nService } = this;

        return (row: IGridRow) => {
            const privileges = row.getPrivileges();
            const { children } = privileges[columnIndex];

            if (!children) {
                return '';
            }

            let hasRead = false;
            let hasWrite = false;
            let hasNoAccess = false;

            for (const child of children) {
                if (!hasRead && child.type === Privilege.READ_ACCESS) {
                    hasRead = true;
                }

                if (!hasWrite && child.type === Privilege.WRITE_ACCESS) {
                    hasWrite = true;
                }

                if (!hasNoAccess && child.type === Privilege.NO_ACCESS) {
                    hasNoAccess = true;
                }

                if (hasRead && hasWrite && hasNoAccess) {
                    break;
                }
            }

            if (this.isAssorted(hasRead, hasWrite, hasNoAccess)) {
                return l10nService.getMessage(l10nKeys.assortedLabel);
            } else if (hasNoAccess) {
                return l10nService.getMessage(l10nKeys.noAccessLabel);
            } else if (hasRead) {
                return l10nService.getMessage(l10nKeys.readLabel);
            } else if (hasWrite) {
                return l10nService.getMessage(l10nKeys.writeLabel);
            }

            return '';
        };
    }

    /**
     * Checks if passed arguments are assorted i.e. more than 1 is of the same value.
     */
    private isAssorted(hasRead: boolean, hasWrite: boolean, hasNoAccess: boolean): boolean {
        return hasRead && hasWrite || hasRead && hasNoAccess || hasWrite && hasNoAccess;
    }
}
