/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'request-policy';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameDuplicatedMessage = `${prefix}.ruleNameDuplicatedMessage`;
export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const clientIpMatchLabel = `${prefix}.clientIpMatchLabel`;
export const servicePortMatchLabel = `${prefix}.servicePortMatchLabel`;
export const protocolTypeMatchLabel = `${prefix}.protocolTypeMatchLabel`;
export const httpMethodMatchLabel = `${prefix}.httpMethodMatchLabel`;
export const httpVersionMatchLabel = `${prefix}.httpVersionMatchLabel`;
export const pathMatchLabel = `${prefix}.pathMatchLabel`;
export const queryMatchLabel = `${prefix}.queryMatchLabel`;
export const headersMatchLabel = `${prefix}.headersMatchLabel`;
export const cookieMatchLabel = `${prefix}.cookieMatchLabel`;
export const hostHeaderMatchLabel = `${prefix}.hostHeaderMatchLabel`;
export const ipReputationMatchLabel = `${prefix}.ipReputationMatchLabel`;
export const redirectActionLabel = `${prefix}.redirectActionLabel`;
export const modifyHeaderActionLabel = `${prefix}.modifyHeaderActionLabel`;
export const rewriteUrlActionLabel = `${prefix}.rewriteUrlActionLabel`;
export const contentSwitchActionLabel = `${prefix}.contentSwitchActionLabel`;
export const createRuleActionLabel = `${prefix}.createRuleActionLabel`;
export const deleteRuleActionLabel = `${prefix}.deleteRuleActionLabel`;
export const menuActionLabel = `${prefix}.menuActionLabel`;
export const ipReputationDbHeader = `${prefix}.ipReputationDbHeader`;
export const ipReputationDbInputPlaceholder = `${prefix}.ipReputationDbInputPlaceholder`;
export const addHttpRequestRuleHeader = `${prefix}.addHttpRequestRuleHeader`;
export const matchingRulesHeader = `${prefix}.matchingRulesHeader`;
export const actionHeader = `${prefix}.actionHeader`;
export const sourceIpMatchLabel = `${prefix}.sourceIpMatchLabel`;

export const ENGLISH = {
    [ruleNameDuplicatedMessage]: '{0} - duplicated',
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [clientIpMatchLabel]: 'Client IP',
    [servicePortMatchLabel]: 'Service Port',
    [protocolTypeMatchLabel]: 'Protocol Type',
    [httpMethodMatchLabel]: 'HTTP Method',
    [httpVersionMatchLabel]: 'HTTP Version',
    [pathMatchLabel]: 'Path',
    [queryMatchLabel]: 'Query',
    [headersMatchLabel]: 'Headers',
    [cookieMatchLabel]: 'Cookie',
    [hostHeaderMatchLabel]: 'Host Header',
    [ipReputationMatchLabel]: 'IP Reputation',
    [redirectActionLabel]: 'Redirect',
    [modifyHeaderActionLabel]: 'Modify Header',
    [rewriteUrlActionLabel]: 'Rewrite URL',
    [contentSwitchActionLabel]: 'Content Switch',
    [createRuleActionLabel]: 'Create rule',
    [deleteRuleActionLabel]: 'Delete',
    [menuActionLabel]: 'Menu',
    [ipReputationDbHeader]: 'IP Reputation DB',
    [ipReputationDbInputPlaceholder]: 'Select IP Reputation DB',
    [addHttpRequestRuleHeader]: 'Add HTTP Request Rule',
    [matchingRulesHeader]: 'Matching Rules',
    [actionHeader]: 'Action',
    [sourceIpMatchLabel]: 'Source IP',
};
