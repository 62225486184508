/** @module PoolModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { POOL_ITEM_TOKEN } from './pool.item.factory';

const poolInventoryDataSources = {
    inventory: {
        source: 'InventoryCollDataSource',
        transformer: 'InventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: [
            'config',
            'health',
            'runtime',
            'alert',
        ],
    },
    'collection-metrics': {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'l4_server.avg_bandwidth',
            'l4_server.max_open_conns',
            'l4_server.avg_total_rtt',
            'l7_server.avg_complete_responses',
            'l7_server.avg_error_responses',
            'l7_server.pct_response_errors',
        ],
        dependsOn: 'config',
    },
};

/**
 * Ajs dependency token for PoolCollection.
 */
export const POOL_COLLECTION_TOKEN = 'PoolCollection';

/**
 * @description Pool collection
 * @author Alex Malitsky, Zhiqian Liu, Nisar Nadaf
 */
export class PoolCollection extends Collection {
    constructor(args = {} as any) {
        const { params = {}, ...restArgs } = args;
        const extendedArgs = {
            objectName: 'pool',
            windowElement: 'app-pool-create',
            isStatic: false,
            searchFields: [
                'name',
                'addr',
            ],
            ...restArgs,
            params: {
                includeName_: true,
                join: 'application_persistence_profile_ref',
                ...params,
            },
        };

        const { objectName } = extendedArgs;

        // if the object name is pool-inventory, load by the inventory API
        // otherwise use default data source settings to load by the list (pool) API
        if (objectName === 'pool-inventory') {
            extendedArgs.allDataSources = poolInventoryDataSources;
            extendedArgs.defaultDataSources = 'inventory';
        }

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(POOL_ITEM_TOKEN);
        this.serverDefaultsOverride_ = {
            certificate: undefined,
            servers: [],
            networks: [],
            autoscale_policy: undefined,
            x509_cert: [],
            max_conn_rate_per_server: undefined,
        };
    }
}

PoolCollection.ajsDependencies = [
    POOL_ITEM_TOKEN,
];
