/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { Scheduler } from 'ajs/modules/system';
import { CloudConnectorUserCollection } from 'ajs/modules/cloud-connector-user';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './scheduler-modal.l10n';
import './scheduler-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloudConnectorUserCollection = typeof CloudConnectorUserCollection;

enum ObjectType {
    SCHEDULER = 'Scheduler',
    BACKUP_CONFIGURATION = 'BackupConfiguration',
}

/**
 * @description Modal component for scheduler create.
 * @author Ratan Kumar
 */
@Component({
    selector: 'scheduler-modal',
    templateUrl: './scheduler-modal.component.html',
})
export class SchedulerModalComponent implements OnInit, OnDestroy {
    /**
     * Scheduler ObjectTypeItem instance.
     */
    @Input()
    public editable: Scheduler;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Property holding Scheduler objectType.
     */
    public readonly objectTypeEnum = ObjectType;

    /**
     * Scheduler frequency-unit dropdown options.
     */
    public schedulerFrequencyUnitOptions: IAviDropdownOption[];

    /**
     * CloudConnectorUserCollection instance.
     */
    public sshUserCollection: CloudConnectorUserCollection;

    /**
     * Layout for scheduler create modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Copy of the password sent by the backend to initialize both password fields.
     */
    public initialPassphrase: string;

    constructor(
        private readonly l10nService: L10nService,
        @Inject('CloudConnectorUserCollection')
        CloudConnectorUserCollection: TCloudConnectorUserCollection,
        @Inject(SchemaService)
        schemaService: SchemaService,
    ) {
        const frequencyTypes = schemaService.getEnumValues('SchedulerFrequencyUnit');

        this.schedulerFrequencyUnitOptions = createOptionsFromEnumProps(frequencyTypes);

        this.sshUserCollection = new CloudConnectorUserCollection();
        l10nService.registerSourceBundles(dictionary);
    }

    public ngOnInit(): void {
        this.initialPassphrase = this.editable.backupPassphrase;
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sshUserCollection.destroy();
    }
}
