/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'host-hdr-match';
const prefix = `${moduleName}.${componentName}`;

export const criteriaInputLabel = `${prefix}.criteriaInputLabel`;
export const criteriaInputPlaceholder = `${prefix}.criteriaInputPlaceholder`;
export const valueInputLabel = `${prefix}.valueInputLabel`;

export const ENGLISH = {
    [criteriaInputLabel]: 'Criteria',
    [criteriaInputPlaceholder]: 'Select Criteria',
    [valueInputLabel]: 'Value',
};
