/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'icap-nsx-defender-logs';
const prefix = `${moduleName}.${componentName}`;

export const nsxDefenderHeaderLabel = `${prefix}.nsxDefenderHeaderLabel`;
export const scoreLabel = `${prefix}.scoreLabel`;
export const taskIdLabel = `${prefix}.taskIdLabel`;
export const detailAnalysisLabel = `${prefix}.detailAnalysisLabel`;

export const ENGLISH = {
    [nsxDefenderHeaderLabel]: 'NSX Defender',
    [scoreLabel]: 'Score',
    [taskIdLabel]: 'Task ID',
    [detailAnalysisLabel]: 'Detail Analysis',
};
