/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-public-ip-config';
const prefix = `${moduleName}.${componentName}`;

export const publicIpLabel = `${prefix}.publicIpLabel`;
export const autoAllocateRadioButtonLabel = `${prefix}.autoAllocateRadioButtonLabel`;
export const staticRadioButtonLabel = `${prefix}.staticRadioButtonLabel`;
export const noneRadioButtonLabel = `${prefix}.noneRadioButtonLabel`;
export const ipv4AddressInputLabel = `${prefix}.ipv4AddressInputLabel`;
export const ipv4AddressInputPlaceholder = `${prefix}.ipv4AddressInputPlaceholder`;

export const ENGLISH = {
    [autoAllocateRadioButtonLabel]: 'Auto-Allocate',
    [staticRadioButtonLabel]: 'Static',
    [publicIpLabel]: 'Public IP',
    [noneRadioButtonLabel]: 'None',
    [ipv4AddressInputLabel]: 'IPv4 Address',
    [ipv4AddressInputPlaceholder]: 'Enter IPv4 Address',
};
