/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine-group';
const componentName = 'segroup-nsxt-placement-scope-config';
const prefix = `${moduleName}.${componentName}`;

export const vcenterLabel = `${prefix}.vcenterLabel`;
export const removeVcenterHint = `${prefix}.removeVcenterHint`;
export const vcenterFieldLabel = `${prefix}.vcenterFieldLabel`;
export const vcenterFieldPlaceholder = `${prefix}.vcenterFieldPlaceholder`;
export const serviceEngineFolderFieldLabel = `${prefix}.serviceEngineFolderFieldLabel`;
export const serviceEngineFolderFieldPlaceholder = `${prefix}.serviceEngineFolderFieldPlaceholder`;
export const hostClusterScopeFieldLabel = `${prefix}.hostClusterScopeFieldLabel`;
export const anyRadioOptionLabel = `${prefix}.anyRadioOptionLabel`;
export const hostRadioOptionLabel = `${prefix}.hostRadioOptionLabel`;
export const sharedRadioOptionLabel = `${prefix}.sharedRadioOptionLabel`;
export const clusterRadioOptionLabel = `${prefix}.clusterRadioOptionLabel`;
export const hostFieldLabel = `${prefix}.hostFieldLabel`;
export const clusterFieldLabel = `${prefix}.clusterFieldLabel`;
export const includeRadioOptionLabel = `${prefix}.includeRadioOptionLabel`;
export const excludeRadioOptionLabel = `${prefix}.excludeRadioOptionLabel`;
export const selectHostsFieldPlaceholder = `${prefix}.selectHostsFieldPlaceholder`;
export const selectClustersFieldPlaceholder = `${prefix}.selectClustersFieldPlaceholder`;
export const dataScopeFieldLabel = `${prefix}.dataScopeFieldLabel`;
export const dataStoreFieldLabel = `${prefix}.dataStoreFieldLabel`;
export const selectDataStoresFieldPlaceholder = `${prefix}.selectDataStoresFieldPlaceholder`;
export const vcenterServerNotAvailable = `${prefix}.vcenterServerNotAvailable`;
export const vSphereHaHeader = `${prefix}.vSphereHaHeader`;
export const clusterInputLabel = `${prefix}.clusterInputLabel`;
export const clusterInputPlaceholder = `${prefix}.clusterInputPlaceholder`;
export const vmGroupInputLabel = `${prefix}.vmGroupInputLabel`;
export const vmGroupInputPlaceholder = `${prefix}.vmGroupInputPlaceholder`;
export const removeClusterHaBtnLabel = `${prefix}.removeClusterHaBtnLabel`;
export const addClusterHaLinkLabel = `${prefix}.addClusterHaLinkLabel`;

export const ENGLISH = {
    [vcenterLabel]: 'vCenter',
    [removeVcenterHint]: 'Remove vCenter',
    [vcenterFieldLabel]: 'vCenter',
    [vcenterFieldPlaceholder]: 'Select vCenter Server',
    [serviceEngineFolderFieldLabel]: 'Service Engine Folder',
    [serviceEngineFolderFieldPlaceholder]: 'Select a Folder',
    [hostClusterScopeFieldLabel]: 'Host/Cluster Scope Service Engine within',
    [anyRadioOptionLabel]: 'Any',
    [hostRadioOptionLabel]: 'Host',
    [sharedRadioOptionLabel]: 'Shared',
    [clusterRadioOptionLabel]: 'Cluster',
    [hostFieldLabel]: 'Host',
    [clusterFieldLabel]: 'Cluster',
    [includeRadioOptionLabel]: 'Include',
    [excludeRadioOptionLabel]: 'Exclude',
    [selectHostsFieldPlaceholder]: 'Select Hosts',
    [selectClustersFieldPlaceholder]: 'Select Clusters',
    [dataScopeFieldLabel]: 'Data Store Scope for Service Engine Virtual Machine',
    [dataStoreFieldLabel]: 'Data Store',
    [selectDataStoresFieldPlaceholder]: 'Select Data Stores',
    [vcenterServerNotAvailable]: 'N/A',
    [vSphereHaHeader]: 'vSphere HA',
    [clusterInputLabel]: 'Cluster',
    [clusterInputPlaceholder]: 'Select Cluster',
    [vmGroupInputLabel]: 'VM Group',
    [vmGroupInputPlaceholder]: 'Select VM Group',
    [removeClusterHaBtnLabel]: 'Remove Cluster HA Config',
    [addClusterHaLinkLabel]: 'Add Cluster HA Config',
};
