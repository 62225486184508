/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} ListDataTransportRequestParams
 * @property {string|string[]} objectName_ - These values will generate a URL path.
 * @property {boolean} includeName_ - Special property without value for the query string.
 * @property {{string:string}} headers_ - Hash of HTTP headers. Optional.
 * @property {string|string[]} * - Any other properties will be concatenated by
 * ampersand to generate a query URL part. If property's value has an array type it will
 * generate a key-value pair for each of the values with the same key name. I.e.
 * filter=val1&filter=val2.
 */

/**
 * List results are returned by the backend in a such form. DataTransformers are usually working
 * with and preserving this data structure.
 * @typedef {Object} ListDataTransportResponse
 * @property {Object[]} results - Array of objects returned by backend.
 * @property {number} count - Number of Items which backend has. In general it is not equal to
 * results length as we usually ask for specific page with explicitly set page size.
 */
function listDataTransportFactory(DataTransport, moment) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.DataTransport
     * @author Ashish Verma
     * @desc
     *     Generic DataTransport for GET requests through API URLs looking like
     *     /api/smth1/smth2?include_name&param1=val&param2=val.
     *
     *     Basically we have two lists of values used here to build up a URL - first one to be
     *     concatenated by forward slashes (values only, order matters) creating a `path` part and
     *     other one (`query` part of the URL) to be concatenated by ampersand (keys and values,
     *     orderdoesn't matter). Between them we use a separator '/?'.
     *
     *     'include_name' parameter is special in a sense that it doesn't need a value but should be
     *     included (when set) to the query URL part.
     *
     *     Payload is not used.
     * DataTransport of {@link module:avi/dataModel.ListDataTransport ListDataTransport}.
     */
    class ListDataTransport extends DataTransport {
        /** @override */
        getRequestUrl_(params) {
            let url;

            if (params['objectName_']) {
                url = `${this.getRequestUrlPath_(params)}/`;
            } else {
                throw new Error('Can\'t build up inventory API URL without an objectName');
            }

            return url;
        }

        /**
         * Generates a `path` part of URL.
         * @param {ListDataTransportRequestParams} params
         * @returns {string} `path` part of API URL.
         * @protected
         */
        getRequestUrlPath_(params) {
            let base = this.apiUrlPrefix_;

            if (typeof params['objectName_'] === 'string') {
                base += params['objectName_'];
            } else if (Array.isArray(params['objectName_'])) {
                base += params['objectName_'].join('/');
            }

            return base;
        }

        /**
         * Generates QueryParam object for Request. Special values will be excluded.
         * @param {ListDataTransportRequestParams} params
         * @returns {Object} Query params for request
         * @protected
         * @override
         */
        getQueryParams_(params) {
            params = angular.copy(params);

            if (params && typeof params === 'object') {
                if (params['includeName_']) {
                    params['include_name'] = params['includeName_'];
                }

                delete params['offset_'];
                delete params['limit_'];

                delete params['objectName_'];
                delete params['includeName_'];
                delete params['headers_'];

                //unix timestamp or moment object to ISO string
                ['start', 'end'].forEach(propName => {
                    if (propName in params) {
                        const value = params[propName];

                        if (angular.isNumber(value) || moment.isMoment(value)) {
                            params[propName] = moment.utc(value)
                                .toISOString();
                        }
                    }
                });
            }

            return params;
        }

        /**
         * Returns HTTP headers hash if corresponding parameters are set.
         * @param {ListDataTransportRequestParams} params
         * @override
         */
        getRequestHeaders_(params) {
            return typeof params['headers_'] === 'object' ?
                angular.copy(params['headers_']) : undefined;
        }
    }

    return ListDataTransport;
}

listDataTransportFactory.$inject = [
    'DataTransport',
    'moment',
];

angular.module('avi/dataModel').factory(
    'ListDataTransport',
    listDataTransportFactory,
);
