/**
 * Module containing CloudConnectorUser components.
 * @preferred
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrTextareaModule,
} from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    GcpCredentialsComponent,
    NsxtCredentialsComponent,
    UserCredentialsModalComponent,
    VCenterCredentialsComponent,
} from '.';

const cloudConnectorUserComponents = [
    GcpCredentialsComponent,
    NsxtCredentialsComponent,
    UserCredentialsModalComponent,
    VCenterCredentialsComponent,
];

@NgModule({
    declarations: [
        ...cloudConnectorUserComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        FormsModule,
        SharedModule,
        ClrInputModule,
        ClrRadioModule,
        ClrTextareaModule,
        ClrFormsModule,
    ],
})
export class CloudConnectorUserModule {}
