/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} OpenStackRoleMapping
 * @property {string} os_role
 * @property {string} avi_role
 */

/**
 * Keystone controller constructor.
 * @param {Scope} $scope
 * @param {Function} RoleCollection
 * @constructor
 */
class KeystoneRoleMappingController {
    constructor($scope, RoleCollection) {
        /**
         * OpenStack Cloud instance.
         * @type {Cloud|null}
         */
        this.cloud = $scope.editable;

        let rolesConfig;

        if ('isRoleCreatable' in $scope) {
            rolesConfig = {
                isCreatable: $scope.isRoleCreatable,
            };
        }

        this.roleCollection = new RoleCollection(rolesConfig);

        // Override method scope with current instance.
        this.handleOpenStackLogin = this.handleOpenStackLogin.bind(this);

        const removeCloudSetWatcher = $scope.$on('openStackLogin', this.handleOpenStackLogin);

        $scope.$on('$destroy', () => {
            removeCloudSetWatcher();
        });
    }

    /**
     * Handles "openStackLogin" event.
     * @param {Object} event
     * @param {Cloud} cloud - OpenStack cloud instance.
     */
    handleOpenStackLogin(event, cloud) {
        this.cloud = cloud;
    }

    /**
     * Removes keystone role map.
     * @param {OpenStackRoleMapping} roleMap
     */
    removeRoleMap(roleMap) {
        const cfg = this.cloud.getCloudConfig();

        if (cfg.role_mapping) {
            const i = cfg.role_mapping.indexOf(roleMap);

            if (i > -1) {
                cfg.role_mapping.splice(i, 1);
            }
        }
    }

    /**
     * Adds keystone role map to role_mappings array.
     * @param {string} osRole - Openstack role.
     * @param {string} aviRole - Avi role.
     * @return {OpenStackRoleMapping}
     */
    addRoleMap(osRole, aviRole) {
        const cfg = this.cloud.getCloudConfig();

        cfg.role_mapping = cfg.role_mapping || [];

        const roleMap = {
            os_role: osRole || '',
            avi_role: aviRole || '',
        };

        cfg.role_mapping.push(roleMap);

        return roleMap;
    }
}

KeystoneRoleMappingController.$inject = ['$scope', 'RoleCollection'];

/**
 * @ngdoc controller
 * @name KeystoneRoleMappingController
 * @description
 *     Controller for Keystone role mapping template.
 */
angular.module('aviApp').controller('KeystoneRoleMappingController', KeystoneRoleMappingController);
