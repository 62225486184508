/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'sso-policy-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleAuthProfile = `${prefix}.columnTitleAuthProfile`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleAuthProfile]: 'Auth Profile',
};
