/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function fileServiceCollectionFactory(Collection, FileService) {
    /**
     * @alias module:services/FileServiceCollection
     * @extends Collection
     * @private
     */
    class FileServiceCollection extends Collection {}

    /** @override */
    FileServiceCollection.prototype.objectName_ = 'fileservice';

    /** @override */
    FileServiceCollection.prototype.itemClass_ = FileService;

    return FileServiceCollection;
}

fileServiceCollectionFactory.$inject = [
    'Collection',
    'FileService',
];

/**
 * @ngdoc services
 * @name FileServiceCollection
 * @module services/FileServiceCollection
 * @author Akul Aggarwal
 * @description
 *
 *     Collection for File Service items. This is an abstract class, to be extended
 *     as needed wherever /fileservice/ api is needed.
 *
 */
angular.module('aviApp').factory('FileServiceCollection', fileServiceCollectionFactory);
