/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'bot-type-matcher';
const prefix = `${moduleName}.${componentName}`;

export const clientTypesLabel = `${prefix}.clientTypesLabel`;
export const selectClientTypesPlaceholder = `${prefix}.selectClassTypeLabel`;

export const ENGLISH = {
    [clientTypesLabel]: 'Client Types',
    [selectClientTypesPlaceholder]: 'Select Client Types',
};
