/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cluster-node';
const componentName = 'cluster-node-modal';
const prefix = `${moduleName}.${componentName}`;

export const editClusterNodeHeader = `${prefix}.editClusterNodeHeader`;
export const newClusterNodeHeader = `${prefix}.newClusterNodeHeader`;
export const generalLabel = `${prefix}.generalLabel`;
export const ipLabel = `${prefix}.ipLabel`;
export const ipInputPlaceholder = `${prefix}.ipInputPlaceholder`;
export const nameLabel = `${prefix}.nameLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const passwordLabel = `${prefix}.passwordLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const publicIpLabel = `${prefix}.publicIpLabel`;
export const publicIpOrHostNameInputPlaceholder = `${prefix}.publicIpOrHostNameInputPlaceholder`;
export const duplicateIpErrorMessage = `${prefix}.duplicateIpErrorMessage`;
export const duplicateNameErrorMessage = `${prefix}.duplicateNameErrorMessage`;

export const ENGLISH = {
    [editClusterNodeHeader]: 'Edit Cluster Node: {0}',
    [newClusterNodeHeader]: 'Add Cluster Node: {0}',
    [generalLabel]: 'General',
    [ipLabel]: 'IP Address',
    [ipInputPlaceholder]: 'Enter IP Address',
    [nameLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [passwordLabel]: 'Password',
    [passwordInputPlaceholder]: 'Enter Password',
    [publicIpLabel]: 'Public IP or Host Name',
    [publicIpOrHostNameInputPlaceholder]: 'Enter Public IP Or Host Name',
    [duplicateIpErrorMessage]: 'Duplicate IP Not Allowed',
    [duplicateNameErrorMessage]: 'Duplicate Name Not Allowed',
};
