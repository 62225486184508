/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './vs-security-ddos-top-list-grid-cell-rate.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosTableGridRate
 * @param {MetricsDimension} row - MetricsDimension item.
 * @param {VirtualService.data} metricData - Virtual Service data object.
 * @description
 *     Displays the rate of MetricsDimension item. If the Virtual Service metric data is available,
 *     display the rate based off the metric. Otherwise, display the rate calculated from the
 *     item data, as it loads faster.
 */
class VsSecurityDdosTopListGridCellRateController {
    constructor(myAccount, GraphSync, l10nService) {
        this.myAccount_ = myAccount;
        this.GraphSync_ = GraphSync;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the rate of the MetricsDimension item.
     * @returns {string}
     * @public
     */
    getRate() {
        const { l10nService_: l10nService } = this;

        const
            { row } = this,
            mouseOverTheChart = this.GraphSync_.mouseIsOnGraph(),
            valuesToDisplay = mouseOverTheChart ? 'exact' :
                this.myAccount_.getValuesDisplayType(),
            series = row.getSeriesBySeriesId('dos.avg_attack_count');

        if (valuesToDisplay === 'sum' || !series.hasData()) {
            return l10nService.getMessage(l10nKeys.emptyDataLabel);
        }

        const value = series.getValue(
            valuesToDisplay,
            this.GraphSync_.mouseOnGraph,
        );

        return angular.isNumber(value) ?
            value.toFixed(2) : l10nService.getMessage(l10nKeys.emptyDataLabel);
    }
}

VsSecurityDdosTopListGridCellRateController.$inject = [
    'myAccount',
    'GraphSync',
    'l10nService',
];

angular.module('avi/vs/security')
    .component('vsSecurityDdosTopListGridCellRate', {
        bindings: {
            row: '<',
            metricData: '<',
        },
        controller: VsSecurityDdosTopListGridCellRateController,
        template: '<span>{{ $ctrl.getRate() }}</span>',
    });
