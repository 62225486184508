/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'auth-profile-jwt-settings';
const prefix = `${moduleName}.${componentName}`;

export const jwtServerProfileLabel = `${prefix}.jwtServerProfileLabel`;
export const jwtTitleHeader = `${prefix}.jwtTitleHeader`;

export const ENGLISH = {
    [jwtServerProfileLabel]: 'JWT Server Profile',
    [jwtTitleHeader]: 'JSON Web Token (JWT)',
};
