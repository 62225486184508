/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-export-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitle = `${prefix}.modalTitle`;
export const configurationHeader = `${prefix}.configurationHeader`;
export const sslKeyInputLabel = `${prefix}.sslKeyInputLabel`;
export const copyToClipboardButtonLabel = `${prefix}.copyToClipboardButtonLabel`;
export const sslCertificateInputLabel = `${prefix}.sslCertificateInputLabel`;
export const submitBtnLabel = `${prefix}.submitBtnLabel`;

export const ENGLISH = {
    [modalTitle]: 'Export Certificate: {0}',
    [configurationHeader]: 'Configuration',
    [sslKeyInputLabel]: 'Key',
    [copyToClipboardButtonLabel]: 'Copy to clipboard',
    [sslCertificateInputLabel]: 'Certificate',
    [submitBtnLabel]: 'Done',
};
