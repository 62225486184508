/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    NgControl,
} from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { isUndefined } from 'underscore';
import classnames from 'classnames';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { L10nService } from '@vmw/ngx-vip';
import {
    DropdownModelSingleValue,
    DropdownModelValue,
    IAviDropdownOption,
} from './avi-dropdown.types';
import {
    containsDropdownOptionByValue,
    getDisplayLabel,
    getOptionsHeight,
    getValue,
} from './avi-dropdown.utils';
import { MAX_OPTIONS_LIST_HEIGHT, OPTION_HEIGHT } from './avi-dropdown.constants';
import { normalizeValue } from '../../utils';
import './avi-dropdown.component.less';
import * as l10n from './avi-dropdown.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const optionsPositionsPriority: ConnectedPosition[] = [
    {
        offsetY: 3,
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
    },
    {
        offsetY: -3,
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
    },
];

/**
 * @description Dropdown component.
 * @author alextsg, Zhiqian Liu
 */
@Component({
    selector: 'avi-dropdown',
    templateUrl: './avi-dropdown.component.html',
})
export class AviDropdownComponent implements ControlValueAccessor, OnInit, OnChanges {
    /**
     * Placeholder shown when no option(s) have been selected.
     */
    @Input() public placeholder = '';

    /**
     * Options to be shown for selection.
     */
    @Input() public options: IAviDropdownOption[] = [];

    /**
     * If true, allows user to select more than one option.
     */
    @Input() public multiple = false;

    /**
     * If true, hides the search input.
     */
    @Input() public hideSearch = false;

    /**
     * Maximum number of selectable options, only applicable for multiple selection dropdown.
     */
    @Input() public maxSelectableOptions = Infinity;

    /**
     * required - True if the dropdown is a required input.
     */
    @Input('required') private set setRequired(required: boolean | '') {
        this.required = required === '' || required;
    }

    /**
     * True to show a spinner in the dropdown options.
     */
    @Input() public busy ? = false;

    /**
     * List of values that should be hidden from selection.
     */
    @Input() public hiddenValues?: DropdownModelSingleValue[] = [];

    /**
     * Text to display underneath the field. Typically used for letting the user know that a certain
     * field must be populated first before configuring this field.
     */
    @Input() public helperText?: string;

    /**
     * Flag to show a value as readonly. Differs from disabled in that the input field will be
     * displayed similarly to a label.
     */
    @Input() public readonly ? = false;

    /**
     * Setter for the hybrid mode disabled attribute.
     * Reason to have:
     * 'disabled' is a built-in attribute bound to NgControl and this.setDisabledState is a callback
     * called by ControlValueAccessor when the value of 'disabled' changes.
     * This callback doesn't work for downgraded ajs directive since Angular NgControl is not
     * present for ajs which uses its own ngModel that no built-in 'disabled' attribute is involved.
     * Therefore, this.setDisabledState is called manually for hybrid mode whenever the value of
     * 'disable' attr is changed.
     */
    @Input('disabled') private set setAjsDisabled(disabled: boolean | string) {
        const isDisabled = disabled === '' || disabled;

        if (!this.hasNgControl) {
            this.setDisabledState(Boolean(isDisabled));
        }
    }

    /**
     * Called when a user types into the search input field. Used if custom behavior should be used
     * for searching through a list of options (HTTP request for example) rather than a simple
     * filtering of the options.
     */
    @Output() public onSearch = new EventEmitter<string>();

    /**
     * Called when the user has scrolled to the end of the options list.
     */
    @Output() public onScrollEnd = new EventEmitter<number>();

    /**
     * Called when the list of options has been opened.
     */
    @Output() public optionsOpenedChange = new EventEmitter<boolean>();

    @ViewChild('aviDropdown') private elementRef: ElementRef;

    /**
     * Reference to the div containing an optional transcluded [aviDropdown_selectedValuesContainer]
     * element.
     */
    @ViewChild('selectedValuesContainerContent') private selectedValuesContainer: ElementRef;

    public optionsPositionsPriority: ConnectedPosition[] = optionsPositionsPriority;
    public searchTerm = '';
    public required = false;
    public disabled = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    private modelValue: DropdownModelValue;
    private dropdownOptionsControlSubject = new Subject<boolean>();

    /**
     * Used to keep track of options that have been selected. Updated when this.value has been
     * changed and when the list of options has changed.
     */
    private selectedOptions: IAviDropdownOption[] = [];

    /**
     * Hash to keep track of hidden values for lookup.
     */
    private hiddenValuesHash: { [value: string]: true } = {};

    /**
     * Used to track changes in the number of options. This is used by AviTooltipDirective to update
     * the position if the position is no longer valid after the size of the options list has
     * changed.
     */
    private optionsLengthSubject = new Subject<void>();

    /**
     * Flag to indicate whether Angular NgControl is involed.
     * True when this component is used in pure Angular env.
     * False when this component is used in hybrid mode.
     */
    private hasNgControl = false;

    constructor(
    // get access to the instance of FormControl
    @Optional() @Self() @Inject(NgControl) ngControl: NgControl,
    l10nService: L10nService,
    ) {
        if (ngControl) {
            // manually register this component as valueAccessor to make ControlValueAccessor work
            ngControl.valueAccessor = this;
            this.hasNgControl = true;
        }

        l10nService.registerSourceBundles(dictionary);

        this.placeholder = l10nService.getMessage(l10nKeys.selectPlaceholder);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.hiddenValues.length) {
            this.setHiddenValuesHash();
        }
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { options, hiddenValues } = changes;

        if (options) {
            const { firstChange, previousValue, currentValue } = options;

            if (firstChange) {
                return;
            }

            if (previousValue.length !== currentValue.length) {
                this.optionsLengthSubject.next();
            }

            this.setSelectedDropdownOptions();
        }

        if (hiddenValues) {
            this.setHiddenValuesHash();
        }
    }

    /**
     * Returns true if the [aviDropdown_selectedValuesContainer] transcluded element exists.
     */
    public get hasTranscludedSelectedValuesContainer(): boolean {
        return this.selectedValuesContainer &&
            this.selectedValuesContainer.nativeElement.children.length > 0;
    }

    /**
     * Returns the width of the dropdown, used to set the max width of the dropdown options overlay.
     */
    public get elementWidth(): number {
        return this.elementRef ? this.elementRef.nativeElement.offsetWidth : 0;
    }

    /**
     * Returns the height of the list of options.
     */
    public get optionsHeight(): number {
        return getOptionsHeight(this.options.length, OPTION_HEIGHT, MAX_OPTIONS_LIST_HEIGHT);
    }

    /**
     * Returns an observable to inform subscriptions of a change in the number of options.
     */
    public get optionsLength$(): Observable<void> {
        return this.optionsLengthSubject.asObservable();
    }

    /**
     * Returns an observable to allow subscriptions to show and hide dropdown options.
     */
    public get dropdownOptionsControl$(): Observable<boolean> {
        return this.dropdownOptionsControlSubject.asObservable();
    }

    /**
     * Returns the list of dropdown options to show. If onSearch is not passed then we simply filter
     * options based on the searchTerm.
     */
    public get availableDropdownOptions(): IAviDropdownOption[] {
        if (this.onSearch.observers.length > 0) {
            return this.options;
        }

        return this.options.filter(option => {
            return this.isFiltered(option) && !this.isHiddenValue(option);
        });
    }

    /**
     * Returns a list of selected dropdown options.
     */
    public get selectedDropdownOptions(): IAviDropdownOption[] {
        return this.selectedOptions;
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): DropdownModelValue {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue. If multiple is set to true, then the modelValue takes the form of
     * an array. Otherwise it's just a string.
     */
    public set value(val: DropdownModelValue) {
        if (this.modelValue !== val) {
            const normalizedValue = normalizeValue(val);

            this.modelValue = normalizedValue;
            this.onChange(normalizedValue);
            this.setSelectedDropdownOptions();
        }

        this.onTouched();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: DropdownModelValue) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: DropdownModelValue): void {
        this.modelValue = normalizeValue(value);
        this.setSelectedDropdownOptions();
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Sets the disabled state.
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /*************************************************************************/

    /**
     * Called when a dropdown option has been selected. Adds the value to the modelValue array if
     * the dropdown is for multiple selection, otherwise replaces the current model value. We check
     * for instanceof Array so that TypeScript knows this.value is of type
     * DropdownModelSingleValue[] instead of type DropdownModelSingleValue.
     */
    public handleSelect(dropdownOption: IAviDropdownOption): void {
        if (containsDropdownOptionByValue(dropdownOption, this.selectedDropdownOptions)) {
            this.hideDropdownOptions();

            return;
        }

        const dropdownValue = getValue(dropdownOption);

        if (this.value instanceof Array) {
            this.value = [...this.value, dropdownValue];
        } else if (this.multiple) {
            this.value = [dropdownValue];
        } else {
            this.value = dropdownValue;
        }

        this.hideDropdownOptions();
    }

    /**
     * Click handler for clicking on a selected option. Applicable only to multiple selection. We
     * check for instanceof Array so that TypeScript knows this.value is of type
     * DropdownModelSingleValue[] instead of type DropdownModelSingleValue.
     */
    public handleRemoveValue(valueToRemove: DropdownModelValue): void {
        if (!(this.value instanceof Array)) {
            return;
        }

        this.value = this.value.filter(value => value !== valueToRemove);
    }

    /**
     * Called when user types into the search field. Emits the onSearch event if the search should
     * be handled by the parent.
     */
    public handleSearch(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.onSearch.emit(searchTerm);
    }

    /**
     * Called when the Clear All button has been clicked. Clears the model value.
     */
    public handleClearAll($event: MouseEvent): void {
        $event.stopPropagation();

        this.value = undefined;
    }

    /**
     * Returns true if the currently selected number of options is greater than or equal to the
     * maximum number of allowed options. We check for instanceof Array so that TypeScript knows
     * this.value is of type DropdownModelSingleValue[] instead of type DropdownModelSingleValue.
     */
    public reachedMaxSelected(): boolean {
        return this.multiple &&
            this.value instanceof Array &&
            this.value.length >= this.maxSelectableOptions;
    }

    /**
     * Event emitter for the dropdown options scroll end event. Typically used in
     * AviCollectionDropdown to load new options as the user gets to the end for an infinite scroll
     * effect.
     */
    public handleScrollEnd(total: number): void {
        this.onScrollEnd.emit(total);
    }

    /**
     * Event emitter for the dropdown options opening and closing. Typically used in
     * AviCollectionDropdown to load new options when the user opens the options.
     */
    public handleOpenedChange(opened: boolean): void {
        this.optionsOpenedChange.emit(opened);
    }

    /**
     * Returns a string of classes to apply to the container element.
     */
    public getContainerClassNames(): string {
        const containerClassName = 'avi-dropdown__container';

        return classnames(
            containerClassName,
            this.reachedMaxSelected() && `${containerClassName}--unclickable`,
            this.readonly && `${containerClassName}--readonly`,
        );
    }

    /**
     * Sets this.hiddenValuesHash to contain values that should be hidden from selection.
     */
    private setHiddenValuesHash(): void {
        this.hiddenValuesHash = this.hiddenValues.reduce((hash, value) => ({
            ...hash,
            [value]: true,
        }), {});
    }

    /**
     * Sets a list of selected dropdown options.
     */
    private setSelectedDropdownOptions(): void {
        if (isUndefined(this.value)) {
            this.selectedOptions = [];

            return;
        }

        const selectedValues = this.multiple ?
            this.value as DropdownModelSingleValue[] :
            [this.value];

        const selectedOptions = selectedValues
            .map(value => {
                const valueFromOptions = this.options.find(option => getValue(option) === value);

                return valueFromOptions ||
                    createDropdownOption(value as unknown as DropdownModelSingleValue);
            });

        // If selectedOptions have been found, set this.selectedOptions to those options. However,
        // it's possible that the value is populated while the options are not available (requires
        // an HTTP request for example), so we resort to showing the value itself in those cases.
        if (selectedOptions.length) {
            this.selectedOptions = selectedOptions;
        } else {
            this.selectedOptions = selectedValues.map((value: string) => {
                return createDropdownOption(value);
            });
        }
    }

    /**
     * Called when the dropdownOptions should be hidden.
     */
    private hideDropdownOptions(): void {
        this.dropdownOptionsControlSubject.next(false);
    }

    /**
     * Returns true if the label of a dropdown option includes what the user has searched for.
     */
    private isFiltered = (dropdownOption: IAviDropdownOption): boolean => {
        const label = getDisplayLabel(dropdownOption);

        return label.toLowerCase().includes(this.searchTerm.toLowerCase());
    };

    /**
     * Returns true if the dropdownOption should be hidden from selection.
     */
    private isHiddenValue = (dropdownOption: IAviDropdownOption): boolean => {
        const value = getValue(dropdownOption);

        return value in this.hiddenValuesHash;
    };

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: DropdownModelValue): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
