/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

export enum AviModalType {
    AVI_MODAL,
    AVI_ALERT_OR_CONFIRM,
}
