/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'tenancy-mode';
const componentName = 'tenancy-mode-page';
const prefix = `${moduleName}.${componentName}`;

export const tenantModePageTitle = `${prefix}.tenantModePageTitle`;
export const editBtnLabel = `${prefix}.editBtnLabel`;

export const ENGLISH = {
    [tenantModePageTitle]: 'Tenancy Mode',
    [editBtnLabel]: 'Edit',
};
