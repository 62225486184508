/**
 * @module LoginModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';

import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations';

import { L10nService } from '@vmw/ngx-vip';
import { Auth } from 'ajs/modules/core/services/auth';
import { StateService, TransitionPromise } from '@uirouter/core';
import './login-page.component.less';

import * as l10n from './login-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Login-page component
 *          - Manages login and password-reset flow
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'login-page',
    templateUrl: './login-page.component.html',
    animations: [
        trigger(
            'fadeIn',
            [
                transition(':enter', [
                    style(
                        {
                            opacity: 0,
                        },
                    ),
                    animate(
                        '500ms',
                        style(
                            {
                                opacity: 1,
                            },
                        ),
                    ),
                ]),
            ],
        ),
    ],
})
export class LoginPageComponent {
    /**
     * If true, PasswordReset component will be rendered.
     */
    public requirePasswordReset = false;

    /**
     * If true, Recovery email success alert-message will be displayed.
     */
    public showEmailSentSuccessMessage = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Recovery email success notification message.
     */
    public recoveryInstructionSentMessage = '';

    public constructor(
        private readonly l10nService: L10nService,
        private readonly authService: Auth,
        private readonly $state: StateService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.recoveryInstructionSentMessage =
            this.l10nService.getMessage(l10nKeys.recoveryInstructionSent);
    }

    /**
     *  Shows PasswordReset flow.
     */
    public showResetPassword(): void {
        this.requirePasswordReset = true;
    }

    /**
     * Switches to login screen and displays recovery success message.
     */
    public onRecoverySuccess(): void {
        this.showLoginScreen();

        this.showEmailSentSuccessMessage = true;

        setTimeout(() => {
            this.showEmailSentSuccessMessage = false;
        }, 3000);
    }

    /**
     * Displays login screen.
     */
    public showLoginScreen(): void {
        this.requirePasswordReset = false;
    }

    /**
     * Fires on login success.
     */
    public onSuccess(): void {
        const target = this.authService.getAfterLoginTargetState();

        this.$state.go(target.name(), target.params())
            .catch(() => this.handleTargetStateTransitionFailure())
            .catch(err => {
                console.error(err);
            });
    }

    /**
     * Callback of login-after state transition failure.
     *
     * Clears last active state from localStorage and tries a last
     * attempt to route to target state.
     */
    private handleTargetStateTransitionFailure(): TransitionPromise {
        this.authService.removeLastActiveState();

        const target = this.authService.getAfterLoginTargetState();

        return this.$state.go(
            target.name(),
            target.params(),
        );
    }
}
