/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @type function
 * @description
 *     Returns a string representation of server information in the format
 *     <ip>:<port> (<hostname>)
 */
const serverStringBuilder = function(server) {
    let output = server.ip.addr;

    if (!angular.isUndefined(server.port)) {
        output += `:${server.port}`;
    }

    if (!angular.isUndefined(server.hostname)) {
        output += ` (${server.hostname})`;
    }

    return output;
};

angular.module('aviApp').constant('serverStringBuilder', serverStringBuilder);
