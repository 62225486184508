/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name timeframeSelector
 * @restrict E
 * @description
 * Updates timeframe service value when needed and syncs up to its state.
 */
import * as l10n from './TimeframeSelector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').directive('timeframeSelector', [
'Timeframe', 'myAccount', 'dropDownUtils', 'l10nService',
function(Timeframe, myAcccount, dropDownUtils, l10nService) {
    function link(scope) {
        const timeFrameIsAvail = tf => !tf.hiddenInLayout;

        l10nService.registerSourceBundles(dictionary);

        scope.l10nKeys = l10nKeys;

        function tfChangeHandler(reason) {
            if (reason !== tfChangeReason) {
                scope.selectedTimeframe = Timeframe.selected().key;
            }
        }

        function tfGroupChangeHandler() {
            // filter timeframe options based on isAvailable and then sort based on index
            const options = _.sortBy(
                _.filter(
                    _.values(Timeframe.options),
                    timeFrameIsAvail,
                ),
                'index',
            );

            // mapping timeframe options to create dropdown options
            scope.options = options.map(
                ({ label, key }) => dropDownUtils.createOption(key, l10nService.getMessage(label)),
            );
        }

        const tfChangeReason = 'from timeframeSelector';

        tfChangeHandler();
        tfGroupChangeHandler();

        scope.onChange = function({ value }) {
            Timeframe.set(value, tfChangeReason);
            myAcccount.saveDefaultTimeframe(value);
        };

        Timeframe.on('change', tfChangeHandler);
        Timeframe.on('afterGroupChange', tfGroupChangeHandler);

        scope.$on('$destroy', function() {
            Timeframe.unbind('change', tfChangeHandler);
            Timeframe.unbind('afterGroupChange', tfGroupChangeHandler);
        });
    }

    return {
        restrict: 'E',
        scope: true,
        link,
        template: require('../../views/components/timeframe-selector.partial.html'),
    };
}]);
