/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { IAviDataGridConfig } from 'ng/shared/components';
import { DnsResolverConfigItem } from 'ajs/modules/cloud';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './dns-resolver-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   DNS Resolver grid component.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'dns-resolver-grid',
    templateUrl: './dns-resolver-grid.component.html',
})
export class DnsResolverGridComponent implements OnInit {
    /**
     * DNS Resolver repeated message item.
     */
    @Input()
    public dnsResolvers: RepeatedMessageItem<DnsResolverConfigItem>;

    /**
     * Fires on add DNS Resolver.
     */
    @Output()
    public onAddDnsResolver = new EventEmitter<void>();

    /**
     * Fires on edit DNS Resolver.
     */
    @Output()
    public onEditDnsResolver = new EventEmitter<DnsResolverConfigItem>();

    /**
     * DNS Resolver grid config.
     */
    public dnsResolverGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        const { l10nService, l10nKeys } = this;

        this.dnsResolverGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleName),
                id: 'name',
                transform(dnsResolver: DnsResolverConfigItem) {
                    return dnsResolver.getName() || '-';
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (dnsResolverList: DnsResolverConfigItem[]) => {
                    dnsResolverList.forEach((dnsResolver: DnsResolverConfigItem) => {
                        this.deleteDnsResolver(dnsResolver);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (dnsResolver: DnsResolverConfigItem) => {
                    this.editDnsResolver(dnsResolver);
                },
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (dnsResolver: DnsResolverConfigItem) => {
                    return this.deleteDnsResolver(dnsResolver);
                },
            }],
        };
    }

    /**
     * Handler for DNS Resolver add operation.
     */
    public addDnsResolver(): void {
        this.onAddDnsResolver.emit();
    }

    /**
     * Handler for DNS Resolver edit operation.
     */
    private editDnsResolver(dnsResolver: DnsResolverConfigItem): void {
        this.onEditDnsResolver.emit(dnsResolver);
    }

    /**
     * Handler for DNS Resolver delete operation.
     */
    private deleteDnsResolver(dnsResolver: DnsResolverConfigItem): void {
        this.dnsResolvers.removeByMessageItem(dnsResolver);
    }
}
