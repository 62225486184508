/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { AviPermissionResource } from 'generated-types';
import { withFullModalMixin } from 'ajs/utils/mixins/with-full-modal.mixin';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withEditChildMessageItemMixin } from
    'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { L10nService } from '@vmw/ngx-vip';
import { BotIpReputationTypeMappingModalComponent } from 'ng/modules/bot-detection-policy';
import { IpReputationTypeMappingConfigItem } from 'ajs/modules/bot-detection-policy';
import * as l10n from './bot-ip-reputation-type-mapping.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @author Akul Aggarwal
 */
export class BotIpReputationTypeMappingItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'botipreputationtypemapping',
            objectType: 'BotIPReputationTypeMapping',
            permissionName: AviPermissionResource.PERMISSION_BOTDETECTIONPOLICY,
            windowElement: BotIpReputationTypeMappingModalComponent,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Used to allow user to create new existing ip_reputation_type_mapping.
     */
    public createIpReputationMappingConfigItem(): void {
        this.addChildMessageItem({
            field: 'ip_reputation_mappings',
            modalBindings: {
                isEditing: false,
            },
        });
    }

    /**
     * Used to allow user to edit existing ip_reputation_type_mapping.
     */
    public editIpReputationMappingConfigItem(
        messageItem: IpReputationTypeMappingConfigItem,
    ): void {
        this.editChildMessageItem({
            field: 'ip_reputation_mappings',
            messageItem,
            modalBindings: {
                isEditing: true,
            },
        });
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.ipReputationMappingTitle);
    }
}
