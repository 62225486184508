/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-allowlist-rule';
const prefix = `${moduleName}.${componentName}`;

export const samplingValue = `${prefix}.samplingValue`;

export const ENGLISH = {
    [samplingValue]: 'Sampling: {0}%',
};
