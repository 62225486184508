/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './pool-group-deployment-policy-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name poolGroupDeploymentPolicyPage
 * @description Component for Pool Group Deployment Policy page.
 */
class PoolGroupDeploymentPolicyPageController {
    constructor(
        CRUDGridConfig,
        PoolGroupDeploymentPolicyCollection,
        l10nService,
    ) {
        this.collection = new PoolGroupDeploymentPolicyCollection();
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        const { objectName } = this.collection;

        const config = {
            id: `${objectName}-list-page`,
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
            }, {
                name: 'data.config.target_test_traffic_ratio',
                title: l10nService.getMessage(l10nKeys.columnTitleTargetTrafficRatio),
            }, {
                name: 'data.config.evaluation_duration',
                title: l10nService.getMessage(l10nKeys.columnTitleDuration),
            }, {
                name: 'data.config.test_traffic_ratio_rampup',
                title: l10nService.getMessage(l10nKeys.columnTitleRamupRatio),
            }, {
                name: 'data.config.rules',
                title: l10nService.getMessage(l10nKeys.columnTitleRules),
                template: '{{ row.getRules().config.length || 0 }}',
            }],
            layout: {
                hideEditColumns: true,
            },
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

PoolGroupDeploymentPolicyPageController.$inject = [
        'CRUDGridConfig',
        'PoolGroupDeploymentPolicyCollection',
        'l10nService',
];

angular.module('aviApp').component('poolGroupDeploymentPolicyPage', {
    controller: PoolGroupDeploymentPolicyPageController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});
