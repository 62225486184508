/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'bgp-routing-options-modal';
const prefix = `${moduleName}.${componentName}`;

export const routingOptionsHeader = `${prefix}.routingOptionsHeader`;
export const configLabelInputLabel = `${prefix}.configLabelInputLabel`;
export const configLabelInputPlaceholder = `${prefix}.configLabelInputPlaceholder`;
export const learnRoutesCheckboxLabel = `${prefix}.learnRoutesCheckboxLabel`;
export const advertiseDefaultRoutesCheckboxLabel = `${prefix}.advertiseDefaultRoutesCheckboxLabel`;
export const learnOnlyDefaultRoutesCheckboxLabel = `${prefix}.learnOnlyDefaultRoutesCheckboxLabel`;
export const advertiseRoutesCheckboxLabel = `${prefix}.advertiseRoutesCheckboxLabel`;
export const maxLearnedRoutesInputLabel = `${prefix}.maxLearnedRoutesInputLabel`;
export const maxLearnedRoutesInputPlaceholder = `${prefix}.maxLearnedRoutesInputPlaceholder`;

export const ENGLISH = {
    [routingOptionsHeader]: 'Routing Options: {0}',
    [configLabelInputLabel]: 'Label',
    [configLabelInputPlaceholder]: 'Enter Label',
    [learnRoutesCheckboxLabel]: 'Learn Routes',
    [advertiseDefaultRoutesCheckboxLabel]: 'Advertise Only Default Routes',
    [learnOnlyDefaultRoutesCheckboxLabel]: 'Learn Only Default Routes',
    [advertiseRoutesCheckboxLabel]: 'Advertise Routes',
    [maxLearnedRoutesInputLabel]: 'Max Learned Routes',
    [maxLearnedRoutesInputPlaceholder]: 'Enter Max Learned Routes',
};
