/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author vgohil
 */

/**
 * Ajs dependency token for LicenseBasedFeatures service.
 */
export const LICENSE_BASED_FEATURES_SERVICE_TOKEN = 'licenseBasedFeaturesService';
