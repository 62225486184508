/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'tenancy-mode';
const componentName = 'tenant-mode-page-card';
const prefix = `${moduleName}.${componentName}`;

export const tenancyCardHeader = `${prefix}.tenancyCardHeader`;
export const ipRouteDomainLabel = `${prefix}.ipRouteDomainLabel`;
export const serviceEngineManagedWithinLabel = `${prefix}.serviceEngineManagedWithinLabel`;
export const tenantServiceEngineAccessLabel = `${prefix}.tenantServiceEngineAccessLabel`;

export const emptyDataLabel = `${prefix}.emptyDataLabel`;

export const ENGLISH = {
    [tenancyCardHeader]: 'Tenancy',
    [ipRouteDomainLabel]: 'IP Route Domain',
    [serviceEngineManagedWithinLabel]: 'Service Engine Managed Within',
    [tenantServiceEngineAccessLabel]: 'Tenant Service Engine Access',

    [emptyDataLabel]: 'N/A',
};
