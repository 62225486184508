/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    SchemaService,
    StringService,
    TEnumValueHash,
} from 'ajs/modules/core/services';

import { SSLScore } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { Subscription } from 'rxjs';
import { SSLRatingConfigItem } from 'ajs/modules/security';
import { HealthScoreClassNamePipe } from 'ng/shared/pipes/health-score-class-name.pipe';
import { ISSLRatingFetchData, SslProfileModalService } from '../../../services';
import * as l10n from './ssl-rating-preview.l10n';

import './ssl-rating-preview.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum SSLRatingType {
    SECURITY_SCORE,
    PERFORMANCE_RATING,
    COMPATIBILITY_RATING,
}

/**
 * Prefix for SSL Rating value classnames.
 */
const SSL_RATING_CLASS_NAME_PREFIX = 'ssl-rating-preview__rating--';

/**
 * Common prefix of SSLScore enum values.
 */
const sslScorePrefix = 'SSL_SCORE_';

/**
 * @description
 *      Preview component for SSLProfile Modal - displays SSL Rating of the SSL Profile
 *      which is being edited/created.
 *
 *      Component will receive SSLRating from SSLModalService subscription
 *      whenever some SSLProfile properties (ciphers, versions) are changed.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ssl-rating-preview',
    templateUrl: './ssl-rating-preview.component.html',
    providers: [
        HealthScoreClassNamePipe,
    ],
})
export class SslRatingPreviewComponent implements OnInit, OnDestroy {
    /**
     * SSLRatingConfigItem instance.
     * Used to display metrics (score, performance, compatibility).
     */
    public sslRating: SSLRatingConfigItem = null;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Loading indicator.
     * Show loader till we receive SSL rating from modal service.
     */
    public isLoading = true;

    /**
     * SSLScore Enum hash.
     * Used to get labels for performance and compatibility.
     */
    public readonly sslScoreEnumHash: TEnumValueHash;

    /**
     * Types of SSLRating we display
     * (securityScore/performance/compatibility).
     */
    public readonly SSLRatingType = SSLRatingType;

    /**
     * Subscription to keep track of SSLProfile update event.
     */
    private sslProfileUpdateSubscription: Subscription;

    /**
     * Map of possible SSL rating (performance/compatibility_values to
     * their classnames.
     */
    private readonly sslScoreClassNameMap = new Map<SSLScore, string>();

    constructor(
        private readonly sslProfileModalService: SslProfileModalService,
        private readonly stringService: StringService,
        schemaService: SchemaService,
        private readonly healthScoreClassName: HealthScoreClassNamePipe,
        l10nService: L10nService,
    ) {
        this.sslScoreEnumHash = schemaService.getEnumValuesHash('SSLScore');

        l10nService.registerSourceBundles(dictionary);

        const sslScoreEnumValues = Object.keys(this.sslScoreEnumHash);

        this.setSslScoreClassNameMap(sslScoreEnumValues as SSLScore[]);
    }

    /** @override */
    public ngOnInit(): void {
        this.sslProfileUpdateSubscription =
            this.sslProfileModalService.sslProfileUpdate$.subscribe(this.setLatestSslRating);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslProfileUpdateSubscription.unsubscribe();

        this.sslRating = null;
    }

    /**
     * Getter for SSLRating-SecurityScore
     */
    public get securityScore(): number | undefined {
        const { securityScore } = this.sslRating;

        return securityScore && Math.round(securityScore);
    }

    /**
     * Getter for SSLRating-performance rating.
     */
    public get performanceRating(): string | undefined {
        const { performanceRating } = this.sslRating;

        return performanceRating && this.sslScoreEnumHash[performanceRating].label;
    }

    /**
     * Getter for SSLRating-compatibility rating.
     */
    public get compatibilityRating(): string | undefined {
        const { compatibilityRating } = this.sslRating;

        return compatibilityRating && this.sslScoreEnumHash[compatibilityRating].label;
    }

    /**
     * Returns classname based on type.
     */
    public getSslRatingClassName(type: SSLRatingType): string {
        switch (type) {
            case SSLRatingType.SECURITY_SCORE: {
                const { securityScore } = this;

                return SSL_RATING_CLASS_NAME_PREFIX +
                    this.healthScoreClassName.transform(securityScore);
            }

            case SSLRatingType.PERFORMANCE_RATING: {
                const { performanceRating } = this.sslRating;

                return this.sslScoreClassNameMap.get(performanceRating);
            }

            case SSLRatingType.COMPATIBILITY_RATING: {
                const { compatibilityRating } = this.sslRating;

                return this.sslScoreClassNameMap.get(compatibilityRating);
            }

            default: {
                return '';
            }
        }
    }

    /**
     * Handler for SSLProfile change event.
     * On change of SSLProfile, we receive the latest SSLRating.
     */
    private setLatestSslRating = (sslRatingFetchData: ISSLRatingFetchData): void => {
        const { loading, sslRatingConfigItem } = sslRatingFetchData;

        this.isLoading = loading;

        this.sslRating = sslRatingConfigItem;
    };

    /**
     * Generates a map of sslScoreEnumValue and its corresponding classname.
     */
    private setSslScoreClassNameMap(sslScoreEnumValues: SSLScore[]): void {
        sslScoreEnumValues.forEach((sslScoreEnumValue: SSLScore) => {
            const classNameSuffix =
            this.stringService.enumeration(sslScoreEnumValue, sslScorePrefix)
                .split(' ').join('-').toLowerCase();

            this.sslScoreClassNameMap.set(
                sslScoreEnumValue,
                `${SSL_RATING_CLASS_NAME_PREFIX}${classNameSuffix}`,
            );
        });
    }
}
