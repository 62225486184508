/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'vcenter-servers-list';
const prefix = `${moduleName}.${componentName}`;

export const vCenterServersHeader = `${prefix}.vCenterServersHeader`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const actionTitleRemove = `${prefix}.actionTitleRemove`;
export const actionTitleEdit = `${prefix}.actionTitleEdit`;
export const configureTransportZoneWarning = `${prefix}.configureTransportZoneWarning`;

export const ENGLISH = {
    [vCenterServersHeader]: 'vCenter Servers ({0})',
    [addButtonLabel]: 'Add',
    [columnTitleName]: 'Name',
    [actionTitleRemove]: 'Remove',
    [actionTitleEdit]: 'Edit',
    [configureTransportZoneWarning]: 'Configure Transport Zone to add vCenter Servers',
};
