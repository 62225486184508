/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './gradient-loader.component.less';

import {
    Component, ElementRef, Renderer2,
} from '@angular/core';

/**
 * @description
 *      Gradient Loader used in cell-sparkline directive.
 *      Need to set height & width of the loader from the consumer component.
 * @example
 *      <gradient-loader
 *          ng-if="some-condition"
 *          class="class-name"
 *      ></gradient-loader>
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'gradient-loader',
    template: '<ng-content></ng-content>',
})
export class GradientLoaderComponent {
    public constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    public ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'gradient-loader');
    }
}
