/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Directive } from '@angular/core';
import { isUndefined } from 'underscore';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { userAccountPasswordStrengthValidator } from '../../validators';

/**
 * @description Directive for applying password strength validator to input field.
 *     Validates field if it satisfy atleast three of the following
 *     criteria (contains uppercase, lowercase, numeric or special characters).
 *
 * @example
 *      <input
 *          type="text"
 *          [(ngModel)]="value"
 *          userAccountPasswordStrength
 *          name="value"
 *       />
 *
 * @author Nisar Nadaf
 */
@Directive({
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: UserAccountPasswordStrengthDirective,
    }],
    selector: `[userAccountPasswordStrength][formControlName],
        [userAccountPasswordStrength][formControl],[userAccountPasswordStrength][ngModel]`,
})
export class UserAccountPasswordStrengthDirective implements Validator {
    /**
     * @override
     * Check the password strength if the value is defined, since we don't want to
     * invalidate a form with empty input fields that are optional.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return !isUndefined(control.value) ? userAccountPasswordStrengthValidator()(control) : null;
    }
}
