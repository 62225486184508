/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'rbac-module';
const componentName = 'role-filter-match-label';
const prefix = `${moduleName}.${componentName}`;

export const keyAndValuesHeader = `${prefix}.keyAndValuesHeader`;

export const ENGLISH = {
    [keyAndValuesHeader]: 'Key & Values ({0})',
};
