/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-group-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleNumberOfPools = `${prefix}.columnTitleNumberOfPools`;
export const columnTitleVirtualServices = `${prefix}.columnTitleVirtualServices`;
export const columnTitleCloud = `${prefix}.columnTitleCloud`;
export const createPoolGroupButtonLabel = `${prefix}.createPoolGroupButtonLabel`;
export const emptyPoolGroupSearchMatchLabel = `${prefix}.emptyPoolGroupSearchMatchLabel`;
export const emptyPoolGroupSearchLabel = `${prefix}.emptyPoolGroupSearchLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleNumberOfPools]: 'Number of Pools',
    [columnTitleVirtualServices]: 'Virtual Services',
    [columnTitleCloud]: 'Cloud',
    [createPoolGroupButtonLabel]: 'Create Pool Group',
    [emptyPoolGroupSearchMatchLabel]: 'No Pool Groups match your search criteria.',
    [emptyPoolGroupSearchLabel]: 'No Pool Groups found.',
};
