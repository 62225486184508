/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';
import {
    JWTServerProfile,
    JWTServerProfileCollection,
    jwtServerProfileCollectionToken,
} from 'ajs/modules/security/factories';
import template from './jwt-server-profile-list-page.component.html';
import * as l10n from './jwt-server-profile-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type JWTServerProfileCollectionType = typeof JWTServerProfileCollection;

export const jwtServerProfileListPageToken = 'jwtServerProfileListPage';

/**
 * @description
 *
 *   JWT Server Profiles list age component.
 *
 * @author Rachit Aggarwal.
 */
class JWTServerProfileListPageController {
    /**
     * JWT server profile collection-grid config.
     */
    public gridConfig: ICollectionGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly JWTServerProfileCollection: JWTServerProfileCollectionType,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.initJWTServerProfileGrid();
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }

    /**
     * Initializes JWT Server Profile collection-grid.
     */
    private initJWTServerProfileGrid(): void {
        this.gridConfig = createCrudCollectionGridConfig();

        const { gridConfig, l10nService } = this;

        gridConfig.id = 'jwt-server-profile-list-page';

        gridConfig.collection = new this.JWTServerProfileCollection({ isStatic: false });

        gridConfig.fields = [{
            name: 'getConfig().name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }];

        gridConfig.singleactions.push({
            title: l10nService.getMessage(l10nKeys.actionBtnDelete),
            class: 'icon-trash',
            do: (item: JWTServerProfile) => item.drop(),
        });
    }
}

JWTServerProfileListPageController.$inject = [
    jwtServerProfileCollectionToken,
    'l10nService',
];

export const jwtServerProfileListPageOptions = {
    controller: JWTServerProfileListPageController,
    template,
};
