/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import { TransitionService } from '@uirouter/core';

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

/**
 * @description Component shows horizontal progress bar during routing transitions.
 * @author Suraj Kumar
 */
@Component({
    selector: 'transition-progress-bar',
    templateUrl: './transition-progress-bar.component.html',
})
export class TransitionProgressBarComponent implements OnInit, OnDestroy {
    /**
     * Default value of percentage.
     */
    public percentage = 0;

    /**
     * By default transition progress bar will be hidden.
     */
    public showProgressbar = false;

    /**
     * Default value of property transitionFinished.
     */
    public transitionFinished = false;

    /**
     * Initially transitionId will be set as 0.
     */
    private transitionId = 0;

    /**
     * Default value of fast transition duration.
     */
    private fastTransitionDuration = 200;

    /**
     * Default value of start time before transition.
     */
    private startTime: Date;

    /**
     * Max duration before calling reset progress bar in transition finish and
     * it should match the transition duration set in css.
     */
    private resetDuration = 500;

    /**
     * Timeout id returned by setTimeout. NaN by default.
     */
    private startTimeout = NaN;

    /**
     * Function for unregistering TransitionService onStart. null by default.
     */
    private unregisterTransitionStart: any = null;

    /**
     * Function for unregistering TransitionService onFinish. null by default.
     */
    private unregisterTransitionFinish: any = null;

    constructor(
        @Inject('$transitions')
        private readonly $transitions: TransitionService,
    ) {}

    /**
     * Register transitions events.
     * @override
     */
    public ngOnInit(): void {
        this.unregisterTransitionStart = this.$transitions.onStart({},
            this.transitionStartHandler);

        this.unregisterTransitionFinish = this.$transitions.onFinish({},
            this.transitionFinishHandler);
    }

    /**
     * Transitions events gets unregistered.
     * @override
     */
    public ngOnDestroy(): void {
        this.unregisterTransitionStart();
        this.unregisterTransitionFinish();
    }

    /**
     * Handler is called when transition starts.
     */
    private transitionStartHandler = (): void => {
        this.startTime = new Date();
        this.transitionId++;

        const { transitionId } = this;

        this.percentage = 0;

        // Till transitionFinished is set as true, transition-duration in css will remain as 4s.
        this.transitionFinished = false;
        this.showProgressbar = true;

        // Timeout is used here to avoid percentage increase for fast transition
        this.startTimeout = +setTimeout(() => {
            if (this.transitionId !== transitionId) {
                return;
            }

            this.percentage = 90;
        }, this.fastTransitionDuration);
    };

    /**
     * Handler is called when transition finishes.
     */
    private transitionFinishHandler = (): void => {
        if (this.isFastTransition()) {
            clearTimeout(this.startTimeout);
            this.resetProgressBar();

            return;
        }

        const { transitionId } = this;

        // When transitionFinished is set as true, transition-duration in css
        // will be set to 500ms from 4s.
        this.transitionFinished = true;

        // Timeout is used here so that css transition-duration should apply before this.
        setTimeout(() => {
            if (this.transitionId !== transitionId) {
                return;
            }

            this.percentage = 100;

            // This timeout will make sure that percentage is set to 100 before reset gets called.
            setTimeout(() => {
                if (this.transitionId !== transitionId) {
                    return;
                }

                this.resetProgressBar();
            }, this.resetDuration);
        });
    };

    /**
     * Checks if transition is shorter than this.fastTransitionDuration.
     */
    private isFastTransition(): boolean {
        const transDelay: number = new Date().getTime() - this.startTime.getTime();

        return transDelay < this.fastTransitionDuration;
    }

    /**
     * Reset progress bar values.
     */
    private resetProgressBar(): void {
        this.percentage = 0;
        this.showProgressbar = false;
        this.transitionFinished = false;
        this.startTime = null;
    }
}
