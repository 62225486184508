/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { UploadType } from 'ng/shared/components';

import * as l10n from './ssl-certificate-signing-request.l10n';

import './ssl-certificate-signing-request.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export interface ISigningRequestData {
    base64: boolean,
    certificate: string,
}

/**
 * @description Allows user to upload/copy-paste a certificate used in the CSR workflow.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ssl-certificate-signing-request',
    templateUrl: './ssl-certificate-signing-request.component.html',
})
export class SslCertificateSigningRequestComponent {
    /**
     * Certificate signing request data.
     */
    @Input()
    public signingRequest: string;

    /**
     * Fires when certificate data changed.
     */
    @Output()
    public onCertificateDataChange = new EventEmitter<ISigningRequestData>();

    /**
     * Stores if certificate is binary file or not.
     */
    public base64 = true;

    /**
     * Stores certificate data uploaded from file-upload-textarea component.
     */
    public certificate: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SSLCertificate objectType.
     */
    public readonly objectType = 'SSLCertificate';

    constructor(
        @Inject('clipboardService')
        private readonly clipboardService: any,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Function to copy signingRequest data to clipboard.
     */
    public copyToClipboard(target: EventTarget): void {
        this.clipboardService.copy(this.signingRequest, target);
    }

    /**
     * Sets base64 flag based on uploadType.
     */
    public setCertificateBase64(uploadType: UploadType): void {
        this.base64 = uploadType === UploadType.FILE;
    }

    /**
     * Calls onCertificateDataChange to update certificate data in a parent component.
     */
    public handleCertificateChange(): void {
        this.onCertificateDataChange.emit({
            base64: this.base64,
            certificate: this.certificate,
        });
    }
}

