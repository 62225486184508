/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { ClrFormLayout } from '@clr/angular';
import { FederationCheckpoint } from 'ajs/modules/gslb';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './gslb-checkpoints-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

@Component({
    selector: 'gslb-checkpoints-modal',
    templateUrl: './gslb-checkpoints-modal.component.html',
})
export class GslbCheckpointsModalComponent {
    @Input() public editable: FederationCheckpoint;
    public objectType = 'FederationCheckpoint';
    public verticalLayout = ClrFormLayout.VERTICAL;
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
