/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} PoolFailActionConfig
 * @property {string|undefined} type - FailActionEnum enum value. If undefined object would be
 *   removed upon save.
 **/

/** @alias poolFailActionService **/
function poolFailActionService(
    defaultValues,
    generateURL,
    parseURL,
) {
    /**
     * Does certain transformations before edit modal opens up.
     * @param {PoolFailActionConfig=} config
     * @return {PoolFailActionConfig} - Returns empty object if empty object or object wo type
     *     is passed.
     * @public
     */
    function beforeEdit(config = {}) {
        const { type } = config;

        // failAction is an optional field, hence for UI having type unset is OK
        // will get dropped by dataToSave
        if (!type) {
            return config;
        }

        if (type === 'FAIL_ACTION_HTTP_REDIRECT') {
            const { redirect } = config;

            //protocol is ignored since it is used separately
            const redirectCopy = Object.assign(
                {},
                redirect,
                { protocol: undefined },
            );

            redirect.url = generateURL(redirectCopy);

            delete redirect.host;
            delete redirect.path;
            delete redirect.query;
        }

        // default failAction config has all the sub-objects set
        syncSubObjectsWithType_(config);

        return config;
    }

    /**
     * Sets type as passed and calls syncSubObjectsWithType_ to update internal
     * configuration accordingly.
     * @param {PoolFailActionConfig} config
     * @param {PoolFailActionConfig#type|undefined} type
     * @public
     */
    function onTypeChange(config, type) {
        if (type === config.type) {
            return;
        }

        config.type = type;
        syncSubObjectsWithType_(config, true);
    }

    /**
     * Does certain transformations before configuration is passed to the backend
     * as a payload for the update request.
     * @param {PoolFailActionConfig=} config
     * @return {PoolFailActionConfig|undefined} - Undefined if type property is not set.
     * @public
     */
    function dataToSave(config) {
        if (!config || !config.type) {
            return;
        }

        if (config.type === 'FAIL_ACTION_HTTP_REDIRECT') {
            const { redirect } = config;

            if (redirect.url) {
                const urlObject = parseURL(redirect.url);

                delete redirect.url;

                redirect.host = urlObject.host; // required field
                redirect.path = urlObject.path; // will be "/" if not provided
                redirect.query = urlObject.query || undefined; // don't want empty string here
            }
        }

        return config;
    }

    /**
     * Removes and resets certain sub-objects of FailAction configuration based on
     * FailAction type.
     * @param {PoolFailActionConfig} config
     * @param {boolean=} resetToDefault - If true, then will reset corresponding sub-object
     *     configuration to default.
     * @protected
     */
    function syncSubObjectsWithType_(config, resetToDefault = false) {
        switch (config.type) {
            case 'FAIL_ACTION_HTTP_REDIRECT':
                if (resetToDefault) {
                    config['redirect'] =
                        defaultValues.getDefaultItemConfig('pool.fail_action.redirect');
                    config['redirect'].url = '';
                }

                delete config['local_rsp'];
                break;

            case 'FAIL_ACTION_HTTP_LOCAL_RSP':
                if (resetToDefault) {
                    config['local_rsp'] =
                        defaultValues.getDefaultItemConfig('pool.fail_action.local_rsp');
                }

                delete config['redirect'];
                break;

            case 'FAIL_ACTION_CLOSE_CONN':
            default:
                delete config['redirect'];
                delete config['local_rsp'];
                break;
        }
    }

    return {
        onTypeChange,
        beforeEdit,
        dataToSave,
    };
}

poolFailActionService.$inject = [
    'defaultValues',
    'generateURL',
    'parseURL',
];

/**
 * @ngdoc service
 * @name poolFailActionService
 * @author Alex Malitsky
 * @desc
 *
 *   Set of methods to work with FailAction protobuf message type which is used
 *   by {@link Pool} and {@link PoolGroup}.
 *   To be replaced with corresponding configItem.
 */
angular.module('aviApp').factory('poolFailActionService', poolFailActionService);
