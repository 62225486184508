/**
 * @module RbacModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { isUndefined } from 'underscore';
import { IRoleFilterMatchLabel } from 'generated-types';
import { rbacKeyUniquenessValidator } from '../../validators';

@Directive({
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: RbacKeyUniquenessValidationDirective,
    }],
    selector: '[rbacKeyUniquenessValidation][index][rbacList][formControlName],' +
        '[rbacKeyUniquenessValidation][index][rbacList][formControl],' +
        '[rbacKeyUniquenessValidation][index][rbacList][ngModel]',
})
export class RbacKeyUniquenessValidationDirective implements Validator {
    /**
     * RBAC list to be checked for key duplications.
     */
    @Input('rbacList')
    public rbacEntryList: IRoleFilterMatchLabel[];

    /**
     * Current index of the input within the list.
     */
    @Input('index')
    public currentIndex: number;

    /**
     * @override
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return !isUndefined(control.value) ?
            rbacKeyUniquenessValidator(this.rbacEntryList, this.currentIndex)(control) :
            null;
    }
}
