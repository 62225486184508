/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { each, has } from 'underscore';

import { CloudStatusComponent } from 'ng/modules/notification';

/**
 * @type {string}
 * @inner
 */
const cloudStatusNotificationId = 'cloud-status-notification';

/**
 * Cloud services constructor.
 * @param {AviModal} AviModal
 * @param {angular.$http} $http
 * @param {angular.$q} $q
 * @param {Object} aviAlertService
 * @param {Object} schemaService
 * @param {Object} notificationService
 * @constructor
 */
function CloudService(
        AviModal,
        $http,
        $q,
        aviAlertService,
        schemaService,
        notificationService,
) {
    this.notificationService_ = notificationService;

    /**
     * @type {AviModal}
     */
    this.AviModal = AviModal;

    /**
     * @type {angular.$http}
     */
    this.$http = $http;

    /**
     * @type {angular.$q}
     */
    this.$q = $q;

    /**
     * @type {Object}
     */
    this.aviAlertService = aviAlertService;

    /**
     * @type {?angular.Promise}
     */
    this.httpTimeout = null;

    /**
     * @type {Cloud[]}
     */
    this.cloudItems = [];

    /**
     * Set of uuid of the clouds whose notification message is dismissed by user.
     * @type {Set<string>}
     */
    this.dismissedCloudIDs = new Set();

    /**
     * Uuid of the cloud whose status notification is currently being displayed.
     * @type {string}
     */
    this.currentStatusID = '';

    /**
     * Processed cloud status object from diag API.
     * @type {Object}
     */
    this.cloudStatusDetails = {};

    /**
     * Flag for when /api/vimgrvcenterruntime/vcenter/diag API is active.
     * @type {boolean}
     */
    this.loadingDiag = false;

    /**
     * @type {Object}
     * @protected
     */
    this.schemaService_ = schemaService;
}

/**
 * Checks each cloud and displays cloud-status notification
 * if status is CLOUD_STATE_PLACEMENT_READY.
 * @param {Cloud[]} clouds - Cloud Items array.
 */
CloudService.prototype.displayCloudDataStatus = function(clouds) {
    this.cloudItems = clouds;

    each(clouds, cloud => {
        const { data: cloudData } = cloud;
        const { status } = cloudData;

        if (status) {
            const { state } = status;
            const { description } = this.schemaService_.getEnumValue('CloudState', state);

            status.text = description;

            if (state === 'CLOUD_STATE_PLACEMENT_READY' && status.reason) {
                this.displayCloudStatus(cloudData);
            }
        }
    });
};

/**
 * Displays cloud status notification for cloud data object.
 * @param {Object} cloudData - Cloud#data.
 */
CloudService.prototype.displayCloudStatus = function(cloudData) {
    const { uuid: cloudId } = cloudData.config;

    // Return if cloud is already dismissed or we have a notification on display.
    if (this.dismissedCloudIDs.has(cloudId) || this.currentStatusID) {
        return;
    }

    this.currentStatusID = cloudId;

    const {
        config: {
            name: cloudName,
        },
        status: {
            reason: cloudStatus,
        },
    } = cloudData;

    this.notificationService_.add({
        id: cloudStatusNotificationId,
        component: CloudStatusComponent,
        componentProps: {
            cloudId,
            cloudName,
            status: cloudStatus,
            onClose: this.dismissCloudStatusNotification.bind(this),
            onDisplayStatusDetail: this.openCloudStatusDetails.bind(this),
        },
    });
};

/**
 * Returns CSS selector for cloud status icon.
 * @param {string} state - Cloud state enum
 * @returns {string} CSS class name for status icon
 * @static
 */
CloudService.getStatusIconClass = function(state) {
    const STATUS = 'status-';

    switch (state) {
        case 'CLOUD_STATE_IN_PROGRESS':
        case 'CLOUD_STATE_DELETING':
            return `${STATUS}in-progress`;

        case 'CLOUD_STATE_UNKNOWN':
            return `${STATUS}unknown`;

        case 'CLOUD_STATE_PLACEMENT_READY':
            return `${STATUS}ready`;

        case 'CLOUD_STATE_FAILED':
            return `${STATUS}failed`;
    }

    return '';
};

/**
 * Hides cloud status popup notification and displays next message if applicable.
 */
CloudService.prototype.dismissCloudStatusNotification = function() {
    // push current cloud Id to dismissed clouds list.
    if (this.currentStatusID) {
        this.dismissedCloudIDs.add(this.currentStatusID);

        this.currentStatusID = '';
    }

    this.removeCloudStatusNotification();
    // Move on to next cloud
    this.displayCloudDataStatus(this.cloudItems);
};

/**
 * Removes cloud-status notification from UI.
 */
CloudService.prototype.removeCloudStatusNotification = function() {
    const { notificationService_: notificationService } = this;

    if (notificationService.has(cloudStatusNotificationId)) {
        notificationService.remove(cloudStatusNotificationId);
    }
};

/**
 * Opens modal window with additional cloud status details for {@link currentStatusID}.
 * @param {string=} cloudId - Optional cloud id to display status for.
 */
CloudService.prototype.openCloudStatusDetails = function(cloudId) {
    cloudId = cloudId || this.currentStatusID;

    if (cloudId) {
        if (this.httpTimeout) {
            this.httpTimeout.resolve();
        }

        this.AviModal.open('infra-cloud-status-details');
        this.cloudStatusDetails = {};
        this.httpTimeout = this.$q.defer();
        this.loadingDiag = true;
        this.$http.post('/api/vimgrvcenterruntime/vcenter/diag', {
            cloud_uuid: cloudId,
        }, { timeout: this.httpTimeout.promise }).then(({ data }) => {
            const { resource } = data;

            if (!resource) {
                return;
            }

            const { vcenter_inventory_diag_rsp: vCenter } = resource;
            const { networks } = vCenter;
            const cloudStatusDetails = {
                hostErrorCount: vCenter.errored_hosts || 0,
            };

            if (networks) {
                cloudStatusDetails.overlappingSubnets = networks.overlapping_subnets;
                cloudStatusDetails.managementNetwork = networks.mgmt_nw_diag;
            }

            if (vCenter.hosts) {
                cloudStatusDetails.hosts = vCenter.hosts;

                const { schemaService_: schemaService } = this;
                const objectType = 'VcenterHostDiag';

                cloudStatusDetails.hosts.forEach(function(host) {
                    if (!has(host, 'powerstate_up')) {
                        host.powerstate_up = schemaService.getFieldDefaultValue(
                            objectType,
                            'powerstate_up',
                        );
                    }

                    if (!has(host, 'maintenance_mode')) {
                        host.maintenance_mode = schemaService.getFieldDefaultValue(
                            objectType,
                            'maintenance_mode',
                        );
                    }

                    if (!has(host, 'connection_state')) {
                        host.connection_state = schemaService.getFieldDefaultValue(
                            objectType,
                            'connection_state',
                        );
                    }

                    if (!has(host, 'cntlr_accessible')) {
                        host.cntlr_accessible = schemaService.getFieldDefaultValue(
                            objectType,
                            'cntlr_accessible',
                        );
                    }

                    if (!has(host, 'quarantined')) {
                        host.quarantined = schemaService.getFieldDefaultValue(
                            objectType,
                            'quarantined',
                        );
                    }
                });
            }

            let hasNetworkErrors = false;

            if (networks && cloudStatusDetails.managementNetwork &&
                    cloudStatusDetails.managementNetwork.is_static) {
                const mgmNtwk = cloudStatusDetails.managementNetwork;

                hasNetworkErrors = mgmNtwk.static_ip_pool_exhausted ||
                        mgmNtwk.static_no_ip_pool || mgmNtwk.static_subnet_not_present;
            }

            cloudStatusDetails.hasNetworkErrors = hasNetworkErrors;

            const hasOverlappingSubnets = cloudStatusDetails.overlappingSubnets &&
                    cloudStatusDetails.overlappingSubnets.length > 0;

            if (vCenter.errored_hosts > 0 || hasOverlappingSubnets || hasNetworkErrors) {
                this.cloudStatusDetails = cloudStatusDetails;
            } else {
                this.aviAlertService.throw('No details to display');
            }
        }).catch(({ data }) => {
            if (data) {
                this.aviAlertService.throw(data.error);
            }
        }).finally(() => this.loadingDiag = false);
    }
};

/**
 * Hides cloud status details modal windows.
 */
CloudService.prototype.closeCloudStatusDetails = function() {
    this.AviModal.destroy('infra-cloud-status-details');

    if (this.httpTimeout) {
        this.httpTimeout.resolve();
    }
};

CloudService.$inject = [
    'AviModal',
    '$http',
    '$q',
    'aviAlertService',
    'schemaService',
    'notificationService',
];

/**
 * @ngdoc service
 * @name cloudService
 * @description
 *     Handles interactions between cloud related controllers and directives.
 */
angular.module('avi/cloud').service('cloudService', CloudService);

export { CloudService };
