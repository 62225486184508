/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './auth-profile-ping-access-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'auth-profile-ping-access-settings';

class AuthProfilePingAccessSettingsController {
    constructor(
        PingAccessAgentProfileCollection,
        l10nService,
    ) {
        this.PingAccessAgentProfileCollection_ = PingAccessAgentProfileCollection;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.pingAccessProfileCollection = new this.PingAccessAgentProfileCollection_();
    }

    /** @override */
    $onDestroy() {
        this.pingAccessProfileCollection.destroy();
    }
}

AuthProfilePingAccessSettingsController.$inject = [
    'PingAccessAgentProfileCollection',
    'l10nService',
];

/**
 * @ngdoc component
 * @name authProfilePingAccessSettings
 * @author Chitra Flarid
 * @param {string} paAgentRef
 * @desc
 *     Configuration form for PingAccessAgent Settings object (pa_agent_uuid).
 */
angular.module('aviApp').component('authProfilePingAccessSettings', {
    bindings: {
        paAgentRef: '=',
    },
    controller: AuthProfilePingAccessSettingsController,
    templateUrl: `src/components/${componentName}/${componentName}.html`,
});
