/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Input } from '@angular/core';
import { TooltipPosition } from '../../directives';
import './avi-tooltip-content.component.less';

const componentName = 'avi-tooltip-content';
const caretClassNameHash = {
    [TooltipPosition.TOP_POSITION]: `${componentName}--top`,
    [TooltipPosition.TOP_RIGHT_POSITION]: `${componentName}--top-right`,
    [TooltipPosition.TOP_LEFT_POSITION]: `${componentName}--top-left`,
    [TooltipPosition.BOTTOM_LEFT_POSITION]: `${componentName}--bottom-left`,
    [TooltipPosition.BOTTOM_RIGHT_POSITION]: `${componentName}--bottom-right`,
    [TooltipPosition.BOTTOM_POSITION]: `${componentName}--bottom`,
};

/**
 * @description Component for tooltip content, displayed when an element is hovered/clicked.
 * @author alextsg
 */
@Component({
    selector: 'avi-tooltip-content',
    templateUrl: './avi-tooltip-content.component.html',
})
export class AviTooltipContentComponent {
    /**
     *
     */
    @Input()
    public tooltipPosition: TooltipPosition = TooltipPosition.TOP_RIGHT_POSITION;

    /**
     * Returns the className to display the caret in the correct position.
     */
    public getCaretClassName(): string {
        return caretClassNameHash[this.tooltipPosition];
    }
}
