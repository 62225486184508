/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHTTPVersionMatch } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * @description  HTTPVersionMatch ConfigItem class.
 * @author Abhinesh Gour
 */
export class HTTPVersionMatchConfigItem extends MessageItem<IHTTPVersionMatch> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'HTTPVersionMatch',
            ...args,
        };

        super(extendedArgs);
    }
}
