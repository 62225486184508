/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './traffic-clone-profile-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  trafficCloneProfilePage
 * @description  Page for Geo Profiles containing a Collection Grid of Geo Profiles.
 */
class TrafficCloneProfilePageController {
    constructor(
        TrafficCloneProfileCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);

        const gridConfig = {
            collection: new TrafficCloneProfileCollection(),
            fields: [{
                name: 'data.config.name',
                sortBy: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
            }, {
                name: 'cloud',
                title: l10nService.getMessage(l10nKeys.columnTitleCloud),
                template: '{{ row.data.config.cloud_ref.name() }}',
            }],
        };

        const { objectName } = gridConfig.collection;

        gridConfig.id = `${objectName}-list-page`;

        this.gridConfig = CRUDGridConfig(gridConfig);
    }
}

TrafficCloneProfilePageController.$inject = [
        'TrafficCloneProfileCollection',
        'CRUDGridConfig',
        'l10nService',
];

angular.module('aviApp').component('trafficCloneProfilePage', {
    controller: TrafficCloneProfilePageController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});
