/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './email-smtp-page.component.l10n';
import './email-smtp-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Parent component for email-smtp-page.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'email-smtp-page',
    templateUrl: './email-smtp-page.component.html',
})
export class EmailSmtpPageComponent {
    /**
     * SystemConfigService resolved by UI router.
     */
    @Input('loadedSystemConfigService')
    public readonly systemConfigService: any;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Opens edit modal.
     */
    public editConfig(): void {
        this.systemConfigService.editEmailSmtp();
    }
}
