/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const hostHdrMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @alias module:config-item/HostHdrMatchConfigItem
     * @private
     */
    class HostHdrMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'HostHdrMatch',
                ...args,
            };

            super(extendedArgs);

            this.matchCaseLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchCase');
            this.operationLabelsHash_ = schemaService.getEnumValueLabelsHash('HdrMatchOperation');
        }

        /**
         * Returns a list of values that should be searchable.
         */
        get searchableValues() {
            const { value = [] } = this.config;

            return [
                ...value,
            ];
        }

        /**
         * Returns the label for the match_case value.
         * @returns {string}
         */
        get matchCaseLabel() {
            const { match_case: matchCase } = this.config;

            return this.matchCaseLabelsHash_[matchCase];
        }

        /**
         * Returns the label for the match_criteria value.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.operationLabelsHash_[matchCriteria];
        }

        /**
         * Returns the label for the 'value' config property.
         * @returns {string}
         */
        get valueLabels() {
            const { value = [] } = this.config;

            return value.join(', ');
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            return `${this.matchCaseLabel}, ${this.matchCriteriaLabel} ${this.valueLabels}`;
        }
    }

    return HostHdrMatchConfigItem;
};

hostHdrMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  HostHdrMatchConfigItem
 * @description  HostHdrMatch ConfigItem class.
 * @module config-item/HostHdrMatchConfigItem
 * @author alextsg
 */
angular
    .module('avi/policies')
    .factory('HostHdrMatchConfigItem', hostHdrMatchConfigItemFactory);
