/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-app-rule-logs-list';
const prefix = `${moduleName}.${componentName}`;

export const applicationRulesHeader = `${prefix}.applicationRulesHeader`;
export const multipleRulesLabel = `${prefix}.multipleRulesLabel`;
export const singleRuleLabel = `${prefix}.singleRuleLabel`;
export const collapseAllLabel = `${prefix}.collapseAllLabel`;
export const expandAllLabel = `${prefix}.expandAllLabel`;

export const ENGLISH = {
    [applicationRulesHeader]: 'Application Rules',
    [multipleRulesLabel]: 'Rules',
    [singleRuleLabel]: 'Rule',
    [collapseAllLabel]: 'Collapse All',
    [expandAllLabel]: 'Expand All',
};
