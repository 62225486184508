/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-mapping-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const nameLabel = `${prefix}.nameLabel`;
export const numberOfMatchesLabel = `${prefix}.numberOfMatchesLabel`;
export const actionLabel = `${prefix}.actionLabel`;
export const classifyAsLabel = `${prefix}.classifyAsLabel`;
export const enterNamePlaceholder = `${prefix}.enterNamePlaceholder`;
export const editLabel = `${prefix}.editLabel`;
export const deleteLabel = `${prefix}.deleteLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const botMappingRulesLabel = `${prefix}.botMappingRulesLabel`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create Bot Mapping: {0}',
    [modalTitleEdit]: 'Edit Bot Mapping: {0}',
    [generalSectionTitle]: 'General',
    [nameLabel]: 'Name',
    [numberOfMatchesLabel]: 'Number of Matches',
    [actionLabel]: 'Action',
    [classifyAsLabel]: 'Classify as: ',
    [enterNamePlaceholder]: 'Enter Name',
    [editLabel]: 'Edit',
    [deleteLabel]: 'Delete',
    [addButtonLabel]: 'Add',
    [botMappingRulesLabel]: 'Bot Mapping Rules ({0})',
};
