/**
 * @module TenancyModeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { TenantSettingsService } from 'ajs/modules/accounts';
import * as l10n from './tenancy-mode-page-card.l10n';
import './tenancy-mode-page-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Values used in template.
 */
interface ITenantDisplayValues {
    ipRouteDomainMessage: string,
    seManagedWithinContextMessage: string,
    tenantSEAccessMessage: string,
    seInProviderContext: boolean,
}

/**
 * @description Component displaying current Tenancy settings.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'tenancy-mode-page-card',
    templateUrl: './tenancy-mode-page-card.component.html',
})
export class TenancyModePageComponentCard implements OnInit, OnDestroy {
    public readonly l10nKeys = l10nKeys;

    public displayValues: ITenantDisplayValues = {
        ipRouteDomainMessage: this.l10nService.getMessage(l10nKeys.emptyDataLabel),
        seManagedWithinContextMessage: this.l10nService.getMessage(l10nKeys.emptyDataLabel),
        tenantSEAccessMessage: this.l10nService.getMessage(l10nKeys.emptyDataLabel),
        seInProviderContext: false,
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly tenantSettingsService: TenantSettingsService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** override */
    public ngOnInit(): void {
        this.tenantSettingsService.on(
            this.tenantSettingsService.parseMessagesEvent,
            this.updateDisplayValues,
        );
        this.tenantSettingsService.parseMessages();
    }

    /** override */
    public ngOnDestroy(): void {
        this.tenantSettingsService.unbind(
            this.tenantSettingsService.parseMessagesEvent,
            this.updateDisplayValues,
        );
    }

    /**
     * Copies TenantSettingsServices values locally.
     */
    public updateDisplayValues = (): void => {
        [
            'ipRouteDomainMessage',
            'seManagedWithinContextMessage',
            'seInProviderContext',
            'tenantSEAccessMessage',
        ].forEach(prop => this.displayValues[prop] = this.tenantSettingsService[prop]);
    };
}
