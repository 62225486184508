/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'verify-cloud-connector-user-host-modal';
const prefix = `${moduleName}.${componentName}`;

export const verifyHostModalTitle = `${prefix}.verifyHostModalTitle`;
export const cleanUpModalTitle = `${prefix}.cleanUpModalTitle`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const hostIpLabel = `${prefix}.hostIpLabel`;
export const hostIpPlaceholder = `${prefix}.hostIpPlaceholder`;
export const verifyButtonLabel = `${prefix}.verifyButtonLabel`;
export const submitBtnLabel = `${prefix}.submitBtnLabel`;

export const ENGLISH = {
    [verifyHostModalTitle]: 'Verify SSH Credentials with Host',
    [cleanUpModalTitle]: 'Clean up existing Service Engine configuration',
    [generalSectionTitle]: 'General',
    [hostIpLabel]: 'Host IP',
    [hostIpPlaceholder]: 'Enter Host IP',
    [verifyButtonLabel]: 'Verify',
    [submitBtnLabel]: 'Done',
};
