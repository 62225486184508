/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing core services and components.
 * @preferred
 * @module CoreModule
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    naturalSort,
} from 'ajs/modules/core/constants';

import {
    AsyncFactory,
    ASYNC_FACTORY_TOKEN,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
    Upload,
} from 'ajs/modules/core/factories';

import {
    ActiveUserProfileService,
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
    AppStateHandler,
    Auth,
    AUTH_SERVICE_TOKEN,
    AviConfirmService,
    AviModalService,
    booleanLabelFilter,
    DefaultValues,
    HorizonIframeService,
    InitialDataService,
    L10nKeysService,
    LicenseBasedFeaturesService,
    MyAccount,
    OpenstackKeystoneService,
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
    SchemaService,
    StateAccessMapService,
    StateManager,
    StringService,
    SystemInfoService,
    translateFilter,
    UserSessionEventListenersService,
    USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN,
} from 'ajs/modules/core/services';

import { appStateTree } from './constants/appStateTree';
import { LICENSE_BASED_FEATURES_SERVICE_TOKEN } from './core.tokens';
import { initCoreModule } from './core.init';
import { aviAjsConfirmOptions } from './components';
import { cdsAlertOnCloseChangeDirective } from './directives';

const coreModule = angular.module('avi/core');

const ajsDependencyFactories = [{
    factory: HttpWrapper,
    name: HTTP_WRAPPER_TOKEN,
}, {
    factory: Upload,
    name: 'Upload',
}, {
    factory: AsyncFactory,
    name: ASYNC_FACTORY_TOKEN,
}];

const ajsDependencyServices = [{
    name: AUTH_SERVICE_TOKEN,
    service: Auth,
}, {
    name: 'defaultValues',
    service: DefaultValues,
}, {
    name: 'initialDataService',
    service: InitialDataService,
}, {
    name: 'myAccount',
    service: MyAccount,
}, {
    name: 'stringService',
    service: StringService,
}, {
    name: 'horizonIframeService',
    service: HorizonIframeService,
}, {
    name: 'stateManagerService',
    service: StateManager,
}, {
    name: 'stateAccessMapService',
    service: StateAccessMapService,
}, {
    name: 'systemInfoService',
    service: SystemInfoService,
}];

const services = [{
    service: AviConfirmService,
    name: 'AviConfirmService',
}, {
    service: AviModalService,
    name: 'AviModal',
}, {
    service: SchemaService,
    name: 'schemaService',
}, {
    name: 'appStateHandler',
    service: AppStateHandler,
}, {
    service: L10nKeysService,
    name: 'l10nKeysService',
}, {
    service: LicenseBasedFeaturesService,
    name: LICENSE_BASED_FEATURES_SERVICE_TOKEN,
}, {
    service: UserSessionEventListenersService,
    name: USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN,
}, {
    service: OpenstackKeystoneService,
    name: OPENSTACK_KEYSTONE_SERVICE_TOKEN,
}, {
    service: ActiveUserProfileService,
    name: ACTIVE_USER_PROFILE_SERVICE_TOKEN,
}];

const constants = [{
    name: 'naturalSort',
    constant: naturalSort,
}, {
    name: 'appStateTree',
    constant: appStateTree,
}];

const directives = [
    {
        directiveFactory: cdsAlertOnCloseChangeDirective,
        name: 'cdsAlertOnCloseChange',
    },
];

const components = [
    {
        name: 'aviAjsConfirm',
        options: aviAjsConfirmOptions,
    },
];

const filters = [{
    name: 'booleanLabel',
    filter: booleanLabelFilter,
}, {
    name: 'vtranslate',
    filter: translateFilter,
}];

ajsDependencyFactories.forEach(({ name, factory }) => {
    initAjsDependency(
        coreModule,
        'factory',
        name,
        factory,
    );
});

ajsDependencyServices.forEach(({
    name,
    service: Service,
}) => {
    initAjsDependency(
        coreModule,
        'service',
        name,
        Service,
    );
});

services.forEach(({
    name,
    service: Service,
}) => {
    coreModule.service(name, Service);
});

constants.forEach(({ name, constant }) => coreModule.constant(name, constant));

directives.forEach(({ name, directiveFactory }) => coreModule.directive(name, directiveFactory));

components.forEach(({ name, options }) => coreModule.component(name, options));

filters.forEach(({ name, filter }) => coreModule.filter(name, filter));

coreModule.run(initCoreModule);
