/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IAuthorizationRule } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { AuthorizationRule } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { DefaultValues } from 'ajs/modules/core/services/default-values.service';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { AuthorizationRuleModalComponent }
    from 'ng/modules/sso-policy/components/authorization-rule-modal';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { AuthorizationMatchConfigItem } from './authorization-match.config-item.factory';
import { AuthorizationActionConfigItem } from './authorization-action.config-item.factory';

import * as l10n from '../sso-policy.l10n';

type TAuthorizationRulePartial = Omit<IAuthorizationRule, 'action' | 'match'>;

interface IAuthorizationRuleConfig extends TAuthorizationRulePartial {
    action?: AuthorizationActionConfigItem;
    match?: AuthorizationMatchConfigItem;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description AuthorizationRule ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class AuthorizationRuleConfigItem extends
    withFullModalMixin(MessageItem)<IAuthorizationRuleConfig> {
    public static ajsDependencies = [
        'defaultValues',
        'l10nService',
        'schemaService',
    ];

    private readonly l10nService: L10nService;

    private readonly schemaService: SchemaService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthorizationRule,
            windowElement: AuthorizationRuleModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
        this.schemaService = this.getAjsDependency_('schemaService');
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IAuthorizationRuleConfig> {
        const defaultValues: DefaultValues = this.getAjsDependency_('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            match: null,
        };
    }

    /**
     * Getter for AuthorizationMatchConfigItem.
     */
    public get match(): AuthorizationMatchConfigItem {
        return this.config.match;
    }

    /**
     * Getter for action configItem.
     */
    public get action(): AuthorizationActionConfigItem {
        return this.config.action;
    }

    /**
     * Returns authorizationAction#type in human readable format.
     */
    public get authorizationActionType(): string {
        if (!this.action.type) {
            return '';
        }

        return this.schemaService.getEnumValueDescription(
            'AuthorizationActionEnum',
            this.action.type,
        );
    }

    /**
     * Returns authorizationAction#status_code in human readable format.
     */
    public get authorizationActionStatusCode(): string {
        if (!this.action.config.status_code) {
            return '';
        }

        return this.schemaService.getEnumValueDescription(
            'AuthorizationActionHttpStatusCode',
            this.action.config.status_code,
        );
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return ['match'];
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.authorizationRuleModalBreadcrumbTitle);
    }
}
