/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function fileServiceFactory(Item) {
    /**
     * @alias module:services/FileService
     * @extends Item
     * @private
     */
    class FileService extends Item {
        /**
         * @override
         */
        getIdFromData_(data) {
            const { config } = data;

            if (!config || !config.url) {
                console.error('missing config or url');

                return '';
            }

            const
                { url } = config,
                apiIndex = url.indexOf('?');

            return url.slice(apiIndex);
        }
    }

    FileService.prototype.objectName = 'fileservice';

    return FileService;
}

fileServiceFactory.$inject = ['Item'];

/**
 * @ngdoc service
 * @name FileService
 * @module services/FileService
 * @author Akul Aggarwal
 * @description
 *
 *     Generic File Service item to generate reports etc.
 *
 */
angular.module('aviApp').factory('FileService', fileServiceFactory);
