/**
 * Module for all things Alert related.
 * @preferred
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    ActionGroupConfigModalComponent,
    AlertEmailModalComponent,
    AlertSyslogConfigModalComponent,
    AlertSyslogServerModalComponent,
} from '.';

import {
    alertEmailConfigCollectionProvider,
    alertSyslogConfigCollectionProvider,
    controlScriptCollectionProvider,
    snmpTrapProfileCollectionProvider,
} from './ajs-upgraded-provider';

const alertComponents = [
    ActionGroupConfigModalComponent,
    AlertEmailModalComponent,
    AlertSyslogConfigModalComponent,
    AlertSyslogServerModalComponent,
];

@NgModule({
    declarations: [
        ...alertComponents,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AviFormsModule,
    ],
    providers: [
        alertEmailConfigCollectionProvider,
        alertSyslogConfigCollectionProvider,
        controlScriptCollectionProvider,
        snmpTrapProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AlertModule {}
