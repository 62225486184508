/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './service-engine-group-list-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class ServiceEngineGroupListExpanderController {
    constructor(l10nService) {
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }
}

ServiceEngineGroupListExpanderController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('serviceEngineGroupListExpander', {
    bindings: {
        seGroup: '<',
    },
    controller: ServiceEngineGroupListExpanderController,
    templateUrl: 'src/components/pages/infrastructure/service-engine-group-list/' +
        'service-engine-group-list-expander/service-engine-group-list-expander.component.html',
});
