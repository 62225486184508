/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { isEmpty } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import {
    containsDropdownOptionByValue,
    getDisplayLabel,
    getValue,
} from '../avi-dropdown.utils';
import { IAviDropdownOption } from '../avi-dropdown.types';
import { OPTION_HEIGHT } from '../avi-dropdown.constants';
import './avi-dropdown-options.component.less';
import * as l10n from './avi-dropdown-options.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @description Component for the dropdown options.
 * @author alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-dropdown-options',
    templateUrl: './avi-dropdown-options.component.html',
})

export class AviDropdownOptionsComponent {
    /**
     * List of dropdown options to show in the menu.
     */
    @Input() public options: IAviDropdownOption[] = [];

    /**
     * List of dropdown options that have been selected.
     */
    @Input() public selectedOptions: IAviDropdownOption[] = [];

    /**
     * Width used for the viewport dimensions.
     */
    @Input() public width: number;

    /**
     * Height used for the viewport dimensions.
     */
    @Input() public height: number;

    /**
     * If true, shows a spinner under the options.
     */
    @Input() public busy = false;

    /**
     * Event emitter for selecting an option.
     */
    @Output() public onOptionSelect = new EventEmitter<IAviDropdownOption>();

    /**
     * Event emitter for scrolling to the end of the list.
     */
    @Output() public onScrollEnd = new EventEmitter<number>();

    @ViewChild(CdkVirtualScrollViewport) private viewport: CdkVirtualScrollViewport;

    /**
     * Height of each dropdown option. Passed to the virtual scrolling CDK.
     */
    public optionHeight = OPTION_HEIGHT;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { height } = changes;

        if (!isEmpty(height)) {
            const { firstChange, previousValue, currentValue } = height;

            if (firstChange) {
                return;
            }

            if (previousValue !== currentValue) {
                setTimeout(() => this.viewport.checkViewportSize());
            }
        }
    }

    /**
     * Handler for selecting a dropdown option.
     */
    public handleSelect(dropdownOption: IAviDropdownOption): void {
        this.onOptionSelect.emit(dropdownOption);
    }

    /**
     * Returns the text to be shown based on the DropdownOption.
     */
    public getOptionLabel(dropdownOption: IAviDropdownOption): string {
        return getDisplayLabel(dropdownOption);
    }

    /**
     * Returns true if the dropdownOption is selected.
     */
    public isSelected(dropdownOption: IAviDropdownOption): boolean {
        return containsDropdownOptionByValue(dropdownOption, this.selectedOptions);
    }

    /**
     * Returns the dropdown value from the dropdownOption object. Used as the trackBy function.
     */
    public trackByValue(
        index: number,
        dropdownOption: IAviDropdownOption,
    ): IAviDropdownOption['value'] {
        return getValue(dropdownOption);
    }

    /**
     * Called when the scrolling changes. The onScrollEnd event is emitted when the user scrolls to
     * the bottom of the options list.
     */
    public handleScrollIndexChange(): void {
        const { end } = this.viewport.getRenderedRange();
        const total = this.viewport.getDataLength();

        if (end >= total) {
            this.onScrollEnd.emit(total);
        }
    }
}
