/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'linux-server-configuration';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const sshUserLabel = `${prefix}.sshUserLabel`;
export const sshUserPlaceholder = `${prefix}.sshUserPlaceholder`;
export const inbandManagementCheckboxLabel = `${prefix}.inbandManagementCheckboxLabel`;
export const serversLabel = `${prefix}.serversResolverLabel`;

export const ENGLISH = {
    [sectionTitle]: 'Linux',
    [sshUserLabel]: 'SSH User',
    [sshUserPlaceholder]: 'Select SSH User',
    [inbandManagementCheckboxLabel]: 'Use Inband Management',
    [serversLabel]: 'Servers ({0})',
};
