/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name SensitiveFieldRuleLogActionConfig
 * @description SensitiveFieldRuleLogActionConfig, maps to LogAction(Enum type) in protobof.
 */
const sensitiveFieldRuleLogActionConfigFactory = (ActionConfigItem, schemaService) => {
    class SensitiveFieldRuleLogActionConfig extends ActionConfigItem {
        constructor(args = {}) {
            super(args);

            this.enumValues_ = ['LOG_FIELD_REMOVE', 'LOG_FIELD_MASKOFF'];
            this.data.config = { value: args.data && args.data.config || 'LOG_FIELD_REMOVE' };
        }

        /**
         * Getter of the value of the Enum.
         * @return {string}
         */
        get value() {
            return this.getConfig().value;
        }

        /**
         * Setter of the value of the Enum.
         * @param {string} actionValue
         * To wrap an Enum type, a setter is used here for Enum type validation concern.
         */
        set value(actionValue) {
            this.setAction_(actionValue);
        }

        /**
         * Validator for action value setting
         * @param {string} [actionValue='LOG_FIELD_REMOVE']
         */
        setAction_(actionValue = 'LOG_FIELD_REMOVE') {
            if (this.enumValues_.includes(actionValue)) {
                this.getConfig().value = actionValue;
            }
        }

        /** @override */
        dataToSave() {
            const config = super.dataToSave();

            if (!config) {
                return config;
            }

            return config.value;
        }

        /** @override */
        toString() {
            const { description } = schemaService.getEnumValue('LogAction', this.value);

            return description;
        }
    }

    angular.extend(SensitiveFieldRuleLogActionConfig.prototype, {
        type: 'LogAction',
        name: 'Log Action',
    });

    return SensitiveFieldRuleLogActionConfig;
};

sensitiveFieldRuleLogActionConfigFactory.$inject = [
        'ActionConfigItem',
        'schemaService',
];

angular.module('aviApp').factory(
    'SensitiveFieldRuleLogActionConfig', sensitiveFieldRuleLogActionConfigFactory,
);
