/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AuthorizationActionEnum,
    IAuthorizationAction,
} from 'generated-types';

import { AuthorizationAction } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * @description AuthorizationAction ConfigItem.
 *
 * @author Aravindh Nagarajan
 */
export class AuthorizationActionConfigItem extends MessageItem<IAuthorizationAction> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthorizationAction,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for action type.
     */
    public get type(): AuthorizationActionEnum {
        return this.config.type;
    }

    /**
     * Fires on type change.
     * status_code property is applicable only when action type is HTTP_LOCAL_RESPONSE,
     * so when user changes type, reset the above field.
     */
    public onTypeChange(): void {
        this.config.status_code = undefined;
    }
}
