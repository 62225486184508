/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    forwardRef,
    Input,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './backup-passphrase.l10n';
import './backup-passphrase.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *     Passphrase/Confirm Passphrase settings component.
 *
 *     This component contains two input fields -
 *          passphrase and confirmation passphrase.
 *
 * @author Aravindh Nagarajan, Ratan Kumar
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BackupPassphraseComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BackupPassphraseComponent),
            multi: true,
        },
    ],
    selector: 'backup-passphrase',
    templateUrl: './backup-passphrase.component.html',
})
export class BackupPassphraseComponent implements ControlValueAccessor, Validator {
    /**
     * Added this property becomes in some case we can both input in same row
     * and in some case we want every input in single row.
     * So from parent component we can set singleRow 'true' and base on that
     * we add 'full-modal-config__form-control-row' class.
     */
    @Input()
    public singleRow: boolean;

    /**
     * Model for passphrase confirmation field.
     * Can use as an input in case the parent component wants to initialize both fields
     * with the same input.
     */
    @Input()
    public confirmPassphrase ?= '';

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: string;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): string {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue.
     */
    public set value(val: string) {
        this.modelValue = val;
        this.onChange(val);
        this.onTouched();
    }

    /**
     * Change handler for confirm passphrase.
     * Triggers ngModel validation by calling onChange with same model value.
     */
    public onConfirmPassphraseChange(): void {
        this.onChange(this.value);
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.modelValue = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /***************************************************************************
     * IMPLEMENTING Validator INTERFACE
    */

    /**
     * @override
     * Checks passphrase and confirmpassphrase match.
     *
     * Returns null if it matches, else returns validationError.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        const { value } = control;

        return this.checkPassphraseMatch(value) ? null : {
            passphraseMismatch: value,
        };
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /**
     * Returns true if value matches with confirmPassphrase.
     */
    private checkPassphraseMatch(passphrase: string): boolean {
        return passphrase === this.confirmPassphrase;
    }
}
