/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import { AviPermissionResource, ISnmpTrapProfile } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { SnmpTrapProfile } from 'object-types';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    SnmpTrapProfileModalComponent,
} from 'ng/modules/snmp-trap-profile/components/snmp-trap-profile-modal';

import {
    SnmpTrapServerConfigItem,
} from './snmp-trap-server.config-item.factory';

import * as l10n from '../../alert.l10n';

const { ENGLISH: dictionary } = l10n;
const TRAP_SERVERS = 'trap_servers';

type SNMPTrapProfilePartaial = Omit<ISnmpTrapProfile, 'trap_servers'>;

interface ISnmpTrapProfileConfig extends SNMPTrapProfilePartaial {
    trap_servers?: RepeatedMessageItem<SnmpTrapServerConfigItem>
}

interface ISNMPTrapProfileData {
    config: ISnmpTrapProfileConfig;
}

/**
 * @description
 *
 * SnmpTrapProfile item.
 *
 * @author Guru Prasad H K
 *
 */
export class SnmpTrapProfileItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: ISNMPTrapProfileData;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'snmptrapprofile',
            objectType: SnmpTrapProfile,
            permissionName: AviPermissionResource.PERMISSION_SNMPTRAPPROFILE,
            windowElement: SnmpTrapProfileModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * To get snmpTraps from Snmp Trap profile config.
     */
    public get snmpTrapServers(): RepeatedMessageItem<SnmpTrapServerConfigItem> {
        const { [TRAP_SERVERS]: trapServers } = this.config;

        return trapServers;
    }

    /**
     * Method to add Snmp trap server item.
     */
    public addSnmpTrapServer(): void {
        this.addChildMessageItem({
            field: TRAP_SERVERS,
        });
    }

    /**
     * Method to edit Snmp trap server item.
     */
    public editSnmpTrapServer(snmpTrap: SnmpTrapServerConfigItem): void {
        this.addChildMessageItem({
            field: TRAP_SERVERS,
            messageItem: snmpTrap,
        });
    }
}
