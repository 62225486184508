/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { namespaces } from 'd3v6';

const d3 = { namespaces };

angular.module('charts').factory('chartUtils', function() {
    /**
     * Describes chart config properties.
     * @typedef {Object} ChartConfig
     * @property {string?} name
     * @property {string?} metric
     * @property {ChartSeries[]} series0
     * @property {ChartSeries[]} series1
     */

    /**
     * Chart Utilities class.
     */
    class ChartUtil {
        createElement(name = 'g') {
            return document.createElementNS(d3.namespaces.svg, name);
        }

        /**
         * Clamps to the nearest point based on xPos.
         * @param {number} xPos - X-value closest to p0 or p1.
         * @param {ChartPoint} p0
         * @param {ChartPoint} p1
         * @returns {ChartPoint}
         */
        clampPoint(xPos, p0, p1) {
            const vp0 = angular.isArray(p0);
            const vp1 = angular.isArray(p1);

            if (angular.isNumber(xPos) && vp0 && vp1) {
                const [x0] = p0;
                const [x1] = p1;

                return xPos - x0 > x1 - xPos ? p1 : p0;
            } else if (vp0) {
                return p0;
            } else if (vp1) {
                return p1;
            }

            return [0, 0];
        }

        /**
         * Returns Y-value from specified series based on specified timestamp.
         * @param {number} ts - Timestamp / X-value.
         * @param {ChartPoint[]} series
         * @returns {number}
         */
        getSeriesYFromTimestamp(ts, series) {
            let si;

            for (let i = 0; i < series.length; i++) {
                si = series[i];

                if (si[0] === ts) {
                    return si[1];
                }
            }

            return 0;
        }

        /**
         * Aggregates domains across multiple series.
         * @param {ChartSeries[]} series
         * @returns {string[]}
         */
        getDomainFromSeries(series) {
            let domains = [];

            if (Array.isArray(series)) {
                const domainHash = {};

                series.forEach(({ domain }) => {
                    if (Array.isArray(domain)) {
                        domain.forEach(d => domainHash[d] = 0);
                    }
                });
                domains = Object.keys(domainHash).map(d => {
                    const n = +d;

                    return _.isNaN(n) ? d : n;
                });
                domains.sort();

                return domains;
            }

            return domains;
        }

        /**
         * Validates xValue to be within data points.
         * @param {number} xValue
         * @param {ChartPoint[]} data
         * @returns {boolean}
         */
        validatePosition(xValue, data) {
            if (Array.isArray(data)) {
                const len = data.length;

                return len > 0 && xValue >= data[0][0] && xValue <= data[len - 1][0];
            }

            return false;
        }

        /**
         * Creates stack chart data from chart series.
         * @param {ChartSeries[]} series
         * @returns {Object[]}
         */
        seriesToStack(series) {
            const stack = [];

            series.forEach(s => {
                const { id, data, name } = s;

                data.forEach((d, i) => {
                    const [x, y] = d;

                    stack[i] = stack[i] || {};

                    const h = stack[i];

                    h.time = x;
                    h[name] = y;
                    h[id] = y;
                });
            });

            return stack;
        }

        /**
         * Returns series IDs from chart series.
         * @param {ChartSeries[]} series
         * @returns {string[]}
         */
        getSeriesId(series) {
            return series.map(s => s.id);
        }

        /**
         * Returns index in points based on X-value.
         * @param {number} xVal
         * @param {ChartPoint[]} points
         * @returns {number}
         */
        getIndexByXValue(xVal, points) {
            if (Array.isArray(points)) {
                for (let i = 0; i < points.length; i++) {
                    if (points[i][0] === xVal) {
                        return i;
                    }
                }
            }

            return -1;
        }
    }

    return new ChartUtil();
});
