/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './waf-policy-allowlist-rule-modal.less';
import * as l10n from './waf-policy-allowlist-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias waf/wafPolicyAllowlistRuleModal
 * @private
 */
class WafPolicyAllowlistRuleModalComponent {
    constructor(
        schemaService,
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;

        this.actionsTypes = schemaService.getEnumValues('WafPolicyAllowlistAction');
        this.learningSamplingPercentRange = schemaService.getFieldRangeAsTuple(
            'WafPolicyAllowlistRule',
            'sampling_percent',
        );

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns true if the location is being edited, contrary to creation.
     * @returns {boolean}
     */
    isEditing() {
        return !_.isUndefined(this.rule.getIndex());
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getEnableState() {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable() {
        this.rule.enable = !this.rule.enable;
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
        this.closeModal();
    }
}

WafPolicyAllowlistRuleModalComponent.$inject = [
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name wafPolicyAllowlistRuleModal
 * @module waf/wafPolicyAllowlistRuleModal
 * @desc Component for editing a WAF Policy allowlist rule.
 * @param {WafPolicyAllowlistRule} rule
 * @param {Function} onSubmit - Called when the user clicks the submit button.
 * @param {Function} onCancel - Called when the user closes the modal.
 * @param {Function} closeModal - Function to call to close the modal.
 * @author alextsg
 */
angular.module('avi/waf').component('wafPolicyAllowlistRuleModal', {
    bindings: {
        rule: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
    },
    controller: WafPolicyAllowlistRuleModalComponent,
    templateUrl: 'src/components/modals/waf-policy-allowlist-rule-modal/' +
        'waf-policy-allowlist-rule-modal.component.html',
});
