/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'enable-vip';
const prefix = `${moduleName}.${componentName}`;

export const enableVipCheckboxLabel = `${prefix}.enableVipCheckboxLabel`;

export const ENGLISH = {
    [enableVipCheckboxLabel]: 'Enable VIP',
};
