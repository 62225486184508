/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name ActionConfigItem
 * @description ActionConfigItem, abstraction of actions in policy/profile rules.
 */
const actionConfigItemFactory = (ConfigItem, stringService) => {
    class ActionConfigItem extends ConfigItem {
        /**
         * Name/label of this action to be shown in UI.
         * @return {string}
         */
        getName() {
            return this.name;
        }

        /**
         * Search Method, returns true if the action string contains term.
         * @param {string} searchTerm
         * @return {boolean}
         */
        hasTerm(searchTerm) {
            const content = this.toString();

            return stringService.contains(content, searchTerm);
        }

        /** @override */
        dataToSave() {
            const
                configCopy = angular.copy(this.getConfig()),
                config = ConfigItem._removeEmptyRepeated(configCopy);

            return config;
        }
    }

    angular.extend(ActionConfigItem.prototype, {
        type: 'AbstractAction',
        name: 'Abstract Action',
        defaultPath: '',
    });

    return ActionConfigItem;
};

actionConfigItemFactory.$inject = [
    'ConfigItem',
    'stringService',
];

angular.module('aviApp').factory('ActionConfigItem', actionConfigItemFactory);
