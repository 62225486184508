/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'segroup-license-selector';
const prefix = `${moduleName}.${componentName}`;

export const licenseSettings = `${prefix}.licenseSettings`;
export const enablePerappSeModeCheckboxLabel = `${prefix}.enablePerappSeModeCheckboxLabel`;
export const seBandwidthTypeInputLabel = `${prefix}.seBandwidthTypeInputLabel`;
export const virtualServicePerSeInputLabel = `${prefix}.virtualServicePerSeInputLabel`;
export const setNumberInputPlaceholder = `${prefix}.setNumberInputPlaceholder`;
export const maximumInnerText = `${prefix}.maximumInnerText`;
export const numberSeDataPathsInputLabel = `${prefix}.numberSeDataPathsInputLabel`;
export const useHyperThreadingCheckboxLabel = `${prefix}.useHyperThreadingCheckboxLabel`;
export const instanceFlavorInputLabel = `${prefix}.instanceFlavorInputLabel`;
export const azurePricingInfoInputLabel = `${prefix}.azurePricingInfoInputLabel`;
export const autoSelectInputPlaceholder = `${prefix}.autoSelectInputPlaceholder`;
export const showAllFlavorsCheckboxLabel = `${prefix}.showAllFlavorsCheckboxLabel`;

export const ENGLISH = {
    [licenseSettings]: 'License',
    [enablePerappSeModeCheckboxLabel]: 'Enable Per-app Service Engine Mode',
    [seBandwidthTypeInputLabel]: 'Service Engine Bandwidth Type',
    [virtualServicePerSeInputLabel]: 'Virtual Service per Service Engine',
    [setNumberInputPlaceholder]: 'Set number',
    [maximumInnerText]: 'Maximum',
    [numberSeDataPathsInputLabel]: 'Number of Service Engine Data Paths',
    [useHyperThreadingCheckboxLabel]: 'Use Hyperthreading',
    [instanceFlavorInputLabel]: 'Instance Flavor',
    [azurePricingInfoInputLabel]: 'Azure Pricing Info',
    [autoSelectInputPlaceholder]: 'Auto select (default)',
    [showAllFlavorsCheckboxLabel]: 'Show all flavors',
};
