/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} AsyncFileSubmit
 * @property {number} percentage - Progress percentage.
 */

/**
 * @ngdoc factory
 * @name asyncFileSubmitFactory
 * @param {number=} interval - Interval in milliseconds to update the percentage. Defaults to 1000.
 * @param {number=} maxPercentage - Maximum reachable percentage. Defaults to 95.
 * @description Used to simulate progress from any pending/continuous process, by updating a
 *     percentage using $interval.
 */
function asyncFileSubmitFactory($interval) {
    return class AsyncFileSubmitService {
        constructor(interval, maxPercentage) {
            this.intervalId_ = null;
            this.interval_ = interval || 1000;
            this.maxPercentage_ = maxPercentage || 99;

            this.percentage = 0;
        }

        /**
         * Initiates the update on this.percentage, starting from 0.
         */
        startProgress() {
            this.setPercentage(0);

            this.intervalId_ = $interval(() => {
                const percentage = Math.round((100 - this.percentage) / 2) + this.percentage;

                this.setPercentage(Math.min(percentage, this.maxPercentage_));
            }, this.interval_);
        }

        /**
         * Ends the polling for increasing the percentage.
         */
        stopProgress() {
            if (this.intervalId_) {
                $interval.cancel(this.intervalId_);
                this.intervalId_ = null;
            }
        }

        /**
         * Sets this.percentage.
         * @param {number} percentage - Number to set this.percentage to.
         */
        setPercentage(percentage) {
            this.percentage = percentage;
        }

        /**
         * Resets all values to the initial values.
         */
        reset() {
            this.setPercentage(0);
            this.stopProgress();
        }
    };
}

asyncFileSubmitFactory.$inject = [
    '$interval',
];

angular.module('aviApp').factory('AsyncFileSubmitService', asyncFileSubmitFactory);
