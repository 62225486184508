/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-policy-psm-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const wafModeOptionLabel = `${prefix}.wafModeOptionLabel`;
export const wafModeOptionDescription = `${prefix}.wafModeOptionDescription`;
export const editArgumentRuleHeader = `${prefix}.editArgumentRuleHeader`;
export const newArgumentRuleHeader = `${prefix}.newArgumentRuleHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const ruleEnableInputLabel = `${prefix}.ruleEnableInputLabel`;
export const ruleIdInputLabel = `${prefix}.ruleIdInputLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const paranoiaLevelInputLabel = `${prefix}.paranoiaLevelInputLabel`;
export const paranoiaLevelInputPlaceholder = `${prefix}.paranoiaLevelInputPlaceholder`;
export const matchElementsHeader = `${prefix}.matchElementsHeader`;
export const argumentsCaseSensitiveCheckboxLabel = `${prefix}.argumentsCaseSensitiveCheckboxLabel`;
export const matchValueMaxLengthInputLabel = `${prefix}.matchValueMaxLengthInputLabel`;
export const valueLabel = `${prefix}.valueLabel`;
export const patternRadioInputLabel = `${prefix}.patternRadioInputLabel`;
export const stringGroupKeyRadioInputLabel = `${prefix}.stringGroupKeyRadioInputLabel`;
export const patternInputLabel = `${prefix}.patternInputLabel`;
export const stringGroupInputLabel = `${prefix}.stringGroupInputLabel`;
export const stringGroupInputPlaceholder = `${prefix}.stringGroupInputPlaceholder`;
export const keyInputLabel = `${prefix}.keyInputLabel`;

export const ENGLISH = {
    [wafModeOptionLabel]: 'Use Policy Mode',
    [wafModeOptionDescription]: 'Use the policy mode.',
    [editArgumentRuleHeader]: 'Edit Argument Rule: {0}',
    [newArgumentRuleHeader]: 'New Argument Rule: {0}',
    [generalSectionHeader]: 'General',
    [ruleEnableInputLabel]: 'Rule {0}',
    [ruleIdInputLabel]: 'Rule ID',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [paranoiaLevelInputLabel]: 'Filter Rule Paranoia Level',
    [paranoiaLevelInputPlaceholder]: 'Select Paranoia Level',
    [matchElementsHeader]: 'Match Elements',
    [argumentsCaseSensitiveCheckboxLabel]: 'Arguments Case Sensitive',
    [matchValueMaxLengthInputLabel]: 'Value Max Length',
    [valueLabel]: 'Value',
    [patternRadioInputLabel]: 'Pattern',
    [stringGroupKeyRadioInputLabel]: 'String Group/Key',
    [patternInputLabel]: 'Pattern',
    [stringGroupInputLabel]: 'String Group',
    [stringGroupInputPlaceholder]: 'Select String Group',
    [keyInputLabel]: 'Key',
};
