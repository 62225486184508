/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './CloudStatusModalController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name CloudStatusModalController
 */
angular.module('aviApp').controller('CloudStatusModalController', CloudStatusModalController);

CloudStatusModalController.$inject = ['cloudService', '$scope', 'schemaService', 'l10nService'];

/**
 * Modal cloud status details controller.
 * @param {CloudService} cloudService
 * @param {angular.$scope} $scope
 * @constructor
 */
function CloudStatusModalController(cloudService, $scope, schemaService, l10nService) {
    /**
     * @type {CloudService}
     */
    this.cloudService = cloudService;
    /**
     * @type {Object}
     */
    this.hostDiagFields = schemaService.getMessageFields('VcenterHostDiag');
    /**
     * @type {Object}
     */
    this.networkDiagFields = schemaService.getMessageFields('VcenterMgmtNwDiag');

    $scope.$parent.modalScope = $scope;

    this.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);
}
