/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-psm-rule-expander-content';
const prefix = `${moduleName}.${componentName}`;

export const modeLabel = `${prefix}.modeLabel`;
export const useGroupModeLabel = `${prefix}.useGroupModeLabel`;
export const caseSensitiveLabel = `${prefix}.caseSensitiveLabel`;
export const trueLabel = `${prefix}.trueLabel`;
export const falseLabel = `${prefix}.falseLabel`;
export const matchElementsLabel = `${prefix}.matchElementsLabel`;
export const matchValuePatternLabel = `${prefix}.matchValuePatternLabel`;
export const valueMaxLengthLabel = `${prefix}.valueMaxLengthLabel`;
export const emptyMaxLengthValueLabel = `${prefix}.emptyMaxLengthValueLabel`;
export const valuePatternStringGroupLabel = `${prefix}.valuePatternStringGroupLabel`;
export const emptyStringGroupValue = `${prefix}.emptyStringGroupValue`;
export const valuePatternStringGroupKeyLabel = `${prefix}.valuePatternStringGroupKeyLabel`;

export const ENGLISH = {
    [modeLabel]: 'Mode',
    [useGroupModeLabel]: 'Use Group Mode',
    [caseSensitiveLabel]: 'Case Sensitive',
    [trueLabel]: 'True',
    [falseLabel]: 'False',
    [matchElementsLabel]: 'Match Elements',
    [matchValuePatternLabel]: 'Match Value Pattern',
    [valueMaxLengthLabel]: 'Value Max Length',
    [emptyMaxLengthValueLabel]: 'N/A',
    [valuePatternStringGroupLabel]: 'Value Pattern String Group',
    [emptyStringGroupValue]: 'N/A',
    [valuePatternStringGroupKeyLabel]: 'Value Pattern String Group Key',
};
