/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    BgpPeerGridComponent,
    BgpPeerModalComponent,
    BgpProfileModalComponent,
    BgpRoutingOptionsGridComponent,
    BgpRoutingOptionsModalComponent,
} from '.';

const vrfContextComponents = [
    BgpProfileModalComponent,
    BgpRoutingOptionsModalComponent,
    BgpRoutingOptionsGridComponent,
    BgpPeerGridComponent,
    BgpPeerModalComponent,
];

/**
 * @description
 *
 *      Angular Module for VRF-Context related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...vrfContextComponents,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        CommonModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrInputModule,
        ClrFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class VrfContextModule {}
