/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'ng/shared/shared.module';

import {
    NatRuleComponent,
    NatRuleExpanderContentComponent,
    NatRulesListComponent,
} from './components';

const natPolicyComponents = [
    NatRuleComponent,
    NatRuleExpanderContentComponent,
    NatRulesListComponent,
];

@NgModule({
    declarations: [
        ...natPolicyComponents,
    ],
    exports: [
        NatRulesListComponent,
    ],
    imports: [
        CommonModule,
        DragDropModule,
        SharedModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NatModule { }
