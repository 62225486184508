/**
 * @module ErrorPageModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    ErrorPageBody,
    ErrorPageBodyCollection,
    ErrorPageConfigItem,
    ErrorPageProfile,
    ErrorPageProfileCollection,
    ERROR_PAGE_BODY_COLLECTION_TOKEN,
    ERROR_PAGE_BODY_ITEM_TOKEN,
    ERROR_PAGE_CONFIG_ITEM_TOKEN,
    ERROR_PAGE_PROFILE_COLLECTION_TOKEN,
    ERROR_PAGE_PROFILE_ITEM_TOKEN,
} from '.';

const errorPageModule = angular.module('avi/error-page');

const factories = [
    {
        factory: ErrorPageBody,
        name: ERROR_PAGE_BODY_ITEM_TOKEN,
    },
    {
        factory: ErrorPageBodyCollection,
        name: ERROR_PAGE_BODY_COLLECTION_TOKEN,
    },
    {
        factory: ErrorPageProfile,
        name: ERROR_PAGE_PROFILE_ITEM_TOKEN,
    },
    {
        factory: ErrorPageProfileCollection,
        name: ERROR_PAGE_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: ErrorPageConfigItem,
        name: ERROR_PAGE_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(errorPageModule, 'factory', name, factory);
});
