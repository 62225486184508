/**
 *  @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Ajs dependency token for GeoProfile Item.
 */
export const GSLB_GEODB_PROFILE_ITEM_TOKEN = 'GslbGeoDbProfileItem';

/**
 * Ajs dependency token for GeoProfile Collection.
 */
export const GSLB_GEODB_PROFILE_COLLECTION_TOKEN = 'GslbGeoDbProfileCollection';
