/**
 * Module containing Webhook components.
 * @preferred
 * @module WebhookModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import { WebhookModalComponent } from '.';

const webhookComponents = [
    WebhookModalComponent,
];

@NgModule({
    declarations: [
        ...webhookComponents,
    ],
    imports: [
        AviFormsModule,
        SharedModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class WebhookModule {}
