/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description Component that is used to open editable item in a full modal.
 * @example
 *     <item-full-modal
 *         modalTitle="Modal Title"
 *         [item]="editable"
 *         [form]="form"
 *         class="some-class-name"
 *     >
 *         <form
 *             clrForm
 *             [clrLayout]="verticalLayout"
 *             #form="ngForm"
 *         >
 *           ...
 *         </form>
 *     </item-full-modal>
 * @author vgohil
 */

import {
    Component,
    ContentChildren,
    ElementRef,
    HostListener,
    Input,
    QueryList,
    Type,
} from '@angular/core';

import {
    AviDismissChangesConfirmationComponent,
    AviDismissChangesWarningType,
} from 'ng/modules/dialog';

import { NgForm } from '@angular/forms';
import { FullModalTabSectionComponent } from 'ng/shared/components';
import { DialogService } from 'ng/modules/core/services';
import { Item } from 'ajs/modules/data-model/factories/item.factory';

const ESCAPE_KEY_CODE = 27;

@Component({
    selector: 'item-full-modal',
    templateUrl: './item-full-modal.component.html',
})
export class ItemFullModalComponent {
    public static aviDismissChangesConfirmationId = 'avi-dismiss-changes-id';

    /**
     * Reference to form element.
     */
    @Input()
    public form: NgForm;

    /**
     * Title of the modal, typically `${objectType}: ${name}`.
     */
    @Input()
    public modalTitle: string;

    /**
     * Editable item of type Item.
     */
    @Input()
    public item: Item;

    /**
     * Optional flag to disable tabs in item-full-modal.
     */
    @Input()
    public disableTabs ?= false;

    /**
     * Optional class name for item full-modal.
     */
    @Input()
    public modalClassName ?= '';

    /**
     * Gets the list of projected FullModalTabSection components.
     */
    @ContentChildren(FullModalTabSectionComponent, { descendants: true })
    public tabSections: QueryList<FullModalTabSectionComponent>;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly dialogService: DialogService,
    ) {}

    /**
     * Listens for keydown events.
     * With FullModal, we might have a stack of modals where only the last modal is attached to the
     * DOM, so we check the isConnected property of the nativeElement to know if this modal is the
     * one attached.
     * If the Escape key is pressed, cancels the current modal.
     */
    @HostListener('document:keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        if (!this.elementRef.nativeElement.isConnected) {
            return;
        }

        if (event.which === ESCAPE_KEY_CODE) {
            this.handleCancelAttempt();
        }
    }

    /**
     * Called when the user tries to cancel or exit out of the modal. If the data has been modified,
     * we show a 'Confirm discard' confirmation in case the user wants to save changes. Otherwise,
     * the cancel handler is called.
     */
    public handleCancelAttempt(): void {
        const { aviDismissChangesConfirmationId } = ItemFullModalComponent;

        if (this.item.modified()) {
            this.dialogService.add({
                id: aviDismissChangesConfirmationId,
                component: AviDismissChangesConfirmationComponent as Type<Component>,
                componentProps: {
                    warningType: AviDismissChangesWarningType.UNSAVED_CHANGES,
                    onConfirm: () => {
                        this.dialogService.remove(aviDismissChangesConfirmationId);
                        this.handleCancel();
                    },
                    onClose: () => {
                        this.dialogService.remove(aviDismissChangesConfirmationId);
                    },
                },
            });
        } else {
            this.handleCancel();
        }
    }

    /**
     * Handler for cancelling or exiting out of the modal.
     */
    public handleCancel(): void {
        this.item.dismiss(true);
    }
}
