/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L4ConnectionPolicy } from 'object-types';
import { IL4ConnectionPolicy } from 'generated-types';

import { withEditChildMessageItemMixin } from 'ajs/modules/data-model/mixins';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import { L4RuleConfigItem } from '.';

interface IL4ConnectionPolicyConfig extends Omit<IL4ConnectionPolicy, 'rules'> {
    rules: RepeatedMessageItem<L4RuleConfigItem>,
}

/**
 * @desc L4ConnectionPolicy Message class.
 * @author Zhiqian Liu
 */
export class L4ConnectionPolicyConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<IL4ConnectionPolicyConfig> {
    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'l4connectionpolicy',
            objectType: L4ConnectionPolicy,
        };

        super(extendedArgs);
    }

    /**
     * Getter for the RepeatedMessageItem containing real rules of the policy.
     */
    public get rules(): RepeatedMessageItem<L4RuleConfigItem> {
        return this.getConfig().rules;
    }

    /**
     * Getter for the number of rules in policy.
     */
    public get rulesCount(): number {
        return this.rules.count;
    }

    /**
     * Add a new L4 Rule.
     */
    public addRule(): void {
        this.addChildMessageItem({
            field: 'rules',
            modalBindings: {
                isEditing: false,
            },
        });
    }

    /**
     * Edit an existing rule.
     */
    public editRule(rule: L4RuleConfigItem): void {
        this.editChildMessageItem({
            field: 'rules',
            messageItem: rule,
            modalBindings: {
                isEditing: true,
            },
        });
    }
}
