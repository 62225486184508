/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-confirm-change-crs-version.l10n';
import './waf-policy-confirm-change-crs-version.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface ICrsUpdatePreview {
    added_rules: number;
    changed_rules: number;
    removed_rules: number;
}

/**
 * Confirmation component for updating the WAF CRS version.
 */
@Component({
    selector: 'waf-policy-confirm-change-crs-version',
    templateUrl: './waf-policy-confirm-change-crs-version.component.html',
})
export class WafPolicyConfirmChangeCrsVersionComponent implements OnInit {
    /**
     * Promise returning the CRS Update Preview containing counts of affected rules.
     */
    @Input()
    public crsUpdatePreviewPromise: Promise<ICrsUpdatePreview>;

    /**
     * If true, shows the checkbox to save uncommitted changes on the WAF policy.
     */
    @Input()
    public modified = false;

    /**
     * Name of the WAF CRS item.
     */
    @Input()
    public wafCrsName = '';

    /**
     * Called on modal close.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Called on modal submit.
     */
    @Output()
    public onSubmit = new EventEmitter<boolean>();

    /**
     * Used as the ngModel for the checkbox to save uncommitted WAF Policy settings before updating
     * the CRS version.
     */
    public saveUncommitted = true;

    /**
     * Set to true when the CRS version change preview has finished loading.
     */
    public loadedChangeLog = false;

    /**
     * CRS Version change preview showing the number of rules that will be affected.
     */
    public crsUpdatePreview: ICrsUpdatePreview;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.crsUpdatePreviewPromise.then((crsUpdatePreview: ICrsUpdatePreview) => {
            this.crsUpdatePreview = crsUpdatePreview;
        });
    }

    /**
     * Called if the user confirms changing to this CRS version.
     */
    public handleSubmit(): void {
        this.onSubmit.emit(this.modified && this.saveUncommitted);
    }

    /**
     * Called if the user decides not to change to this CRS version.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }
}
