/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';
import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { IBotAllowList } from 'generated-types';
import { BotAllowList } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { BotAllowRuleConfigItem } from './bot-allow-rule.config-item.factory';

type TBotAllowListPartial = Omit<IBotAllowList, 'rules'>;

export interface IBotAllowListConfig extends TBotAllowListPartial {
    rules: RepeatedMessageItem<BotAllowRuleConfigItem>,
}

/**
 * config-item for BotAllowList
 * @author Akul Aggarwal
 */
export class BotAllowListConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<IBotAllowListConfig> {
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: BotAllowList,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public canFlatten(): boolean {
        return !this.config.rules.isEmpty();
    }

    /**
     * Returns the number of rules in the config.
     */
    public getRulesCount(): number {
        return this.config.rules.count;
    }

    /**
     * Triggers add BotAllowRule.
     */
    public addRule(): void {
        this.addChildMessageItem({
            field: 'rules',
            modalBindings: {
                isEditing: false,
            },
        });
    }

    /**
     * Triggers edit for BotAllowRule.
     */
    public editRule(rule: BotAllowRuleConfigItem): void {
        this.editChildMessageItem({
            field: 'rules',
            messageItem: rule,
            modalBindings: {
                isEditing: true,
            },
        });
    }
}
