/**
 * Module containing Pool related components and services.
 * @preferred
 * @module PoolModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';

import {
    ClrInputModule,
    ClrTextareaModule,
} from '@clr/angular';
import { AviFormsModule } from '../avi-forms/avi-forms.module';

import { PoolGroupDeploymentPolicyModalComponent } from '.';
import {
    cloudCollectionProvider,
    PoolGroupDeploymentPolicyCollectionProvider,
    PoolGroupDeploymentPolicyProvider,
    vrfContextCollectionProvider,
} from '../../ajs-upgraded-providers';

const poolComponents = [
    PoolGroupDeploymentPolicyModalComponent,
];

@NgModule({
    declarations: [
        ...poolComponents,
    ],
    imports: [
        ClrInputModule,
        ClrTextareaModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        AviFormsModule,
    ],
    providers: [
        cloudCollectionProvider,
        PoolGroupDeploymentPolicyProvider,
        PoolGroupDeploymentPolicyCollectionProvider,
        vrfContextCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PoolModule { }
