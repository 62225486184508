/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './service-engine-group-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name serviceEngineGroupList
 * @description Component for the Service Engine Group page.
 */
class ServiceEngineGroupListController {
    constructor(CRUDGridConfig, $templateCache, SEGroupCollection, infraCloudState, l10nService) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._$templateCache = $templateCache;
        this._SEGroupCollection = SEGroupCollection;
        this._infraCloudState = infraCloudState;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();

        this.gridConfig = new this._CRUDGridConfig();

        const segCollection = new this._SEGroupCollection({
            params: {
                'cloud_ref.uuid': slug,
            },
            defaults: {
                cloud_ref: cloudRef,
            },
            dataFields: [
                'config',
                'upgradestatus',
            ],
        });

        const { objectName } = segCollection;
        const { l10nService_: l10nService } = this;

        this.gridConfig.collection = segCollection;

        this.gridConfig.id = `${objectName}-list-page`;

        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'version',
            title: l10nService.getMessage(l10nKeys.columnTitleVersion),
            template: '{{ row.getVersion(true) }}',
            visibility: 'm',
        }, {
            name: 'data.inventory.serviceengines.length',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceEnginesNumber),
            visibility: 'm',
        }, {
            name: 'data.config.max_se',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceEnginesMaxNumber),
            visibility: 'm',
        }, {
            name: 'data.inventory.virtualservices.length',
            title: l10nService.getMessage(l10nKeys.columnTitleVirtualServicesNumber),
            visibility: 'm',
        }, {
            name: 'data.config.max_vs_per_se',
            title: l10nService.getMessage(l10nKeys.columnTitleVsPerSeMaxNumber),
        }, {
            name: 'ha_mode',
            title: l10nService.getMessage(l10nKeys.columnTitleHaMode),
            template: '<span>{{row.data.config.ha_mode | seGroupHaMode}}</span>',
            visibility: 'm',
        }];

        this.gridConfig.singleactions = [{
            title: l10nService.getMessage(l10nKeys.editBtnTitle),
            class: 'icon-pencil-4',
            do(row) {
                row.edit();
            },
            hidden(row) {
                return !row.isEditable();
            },
        }];

        this.gridConfig.expandedContainerTemplate =
            '<service-engine-group-list-expander se-group="row">' +
            '</service-engine-group-list-expander>';

        this.gridConfig.expanderDisabled = row => {
            const { inventory } = row.data;

            return _.isEmpty(inventory) ||
                    !inventory.serviceengines?.length && !inventory.virtualservices?.length;
        };

        this.handleCloudChange = this.handleCloudChange.bind(this);
        this._infraCloudState.on('cloudChange', this.handleCloudChange);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
        this._infraCloudState.unbind('cloudChange', this.handleCloudChange);
    }

    /**
     * Handler for the 'cloudChange' event. Sets the SE Group collection based on the cloud and
     * loads it.
     */
    handleCloudChange() {
        const cloudRef = this._infraCloudState.getCloudRef();
        const slug = cloudRef && cloudRef.slug();
        const { collection } = this.gridConfig;

        collection.setParams({
            'cloud_ref.uuid': slug,
        });

        collection.setDefaultItemConfigProps({
            cloud_ref: slug,
        });

        collection.load();
    }
}

ServiceEngineGroupListController.$inject = [
    'CRUDGridConfig',
    '$templateCache',
    'SEGroupCollection',
    'infraCloudState',
    'l10nService',
];

angular.module('aviApp').component('serviceEngineGroupList', {
    controller: ServiceEngineGroupListController,
    template:
        `<div class="infrastructure-detail">
            <collection-grid
                config="$ctrl.gridConfig"
                class="sel-segroup-list segroup">
            </collection-grid>
        </div>`,
});
