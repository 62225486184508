/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { AuthorizationPolicy } from 'object-types';
import { IAuthorizationPolicy, SSOPolicyType } from 'generated-types';
import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { AuthorizationRuleConfigItem } from './authorization-rule.config-item.factory';

type TAuthorizationPolicyPartial = Omit<IAuthorizationPolicy, 'authz_rules'>;

interface IAuthorizationPolicyConfig extends TAuthorizationPolicyPartial {
    authz_rules?: RepeatedMessageItem<AuthorizationRuleConfigItem>;
}

/**
 * @description AuthorizationPolicy config item.
 *
 * @author Aravindh Nagarajan
 */
export class AuthorizationPolicyConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<IAuthorizationPolicyConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthorizationPolicy,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for authz_rules.
     */
    public get authRules(): RepeatedMessageItem<AuthorizationRuleConfigItem> {
        return this.config.authz_rules;
    }

    /**
     * Opens Create AuthorizationRule Modal.
     */
    public addAuthorizationRule(type: SSOPolicyType): void {
        this.addChildMessageItem({
            field: 'authz_rules',
            modalBindings: {
                type,
            },
        });
    }

    /**
     * Opens Edit AuthorizationRule Modal.
     */
    public editAuthorizationRule(
        messageItem: AuthorizationRuleConfigItem,
        type: SSOPolicyType,
    ): void {
        this.addChildMessageItem({
            field: 'authz_rules',
            messageItem,
            modalBindings: {
                type,
            },
        });
    }
}
