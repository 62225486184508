/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-rule-select-pool-action';
const prefix = `${moduleName}.${componentName}`;

export const actionHeader = `${prefix}.actionHeader`;
export const actionTypeRadioLabelPool = `${prefix}.actionTypeRadioLabelPool`;
export const actionTypeRadioLabelPoolGroup = `${prefix}.actionTypeRadioLabelPoolGroup`;
export const poolLabel = `${prefix}.poolLabel`;
export const poolInputPlaceholder = `${prefix}.poolInputPlaceholder`;
export const poolGroupLabel = `${prefix}.poolGroupLabel`;
export const poolGroupInputPlaceholder = `${prefix}.poolGroupInputPlaceholder`;

export const ENGLISH = {
    [actionHeader]: 'Passthrough served by',
    [actionTypeRadioLabelPool]: 'Pool',
    [actionTypeRadioLabelPoolGroup]: 'Pool Group',
    [poolLabel]: 'Pool',
    [poolInputPlaceholder]: 'Select a Pool',
    [poolGroupLabel]: 'Pool Group',
    [poolGroupInputPlaceholder]: 'Select a Pool Group',
};
