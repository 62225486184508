/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gateway-monitor-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const vrfContextHeader = `${prefix}.vrfContextHeader`;

export const ENGLISH = {
    [columnTitleIpAddress]: 'IP Address',
    [vrfContextHeader]: 'VRF Context: {0}',
};
