/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-repeated-strings';
const prefix = `${moduleName}.${componentName}`;

export const placeholderLabel = `${prefix}.placeholderLabel`;

export const ENGLISH = {
    [placeholderLabel]: 'Enter Value(s)',
};

