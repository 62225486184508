/**
 * Module containing AutoScaleLaunchConfig components.
 * @preferred
 * @module LaunchConfigModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { LaunchConfigModalComponent } from '.';

const launchConfigComponents = [
    LaunchConfigModalComponent,
];

@NgModule({
    declarations: [
        ...launchConfigComponents,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrInputModule,
        ClrFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LaunchConfigModule {}
