/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L4RuleActionSelectPoolType } from 'generated-types';
import { PoolCollection, PoolGroupCollection } from 'ajs/modules/pool/factories';
import { L10nService } from '@vmw/ngx-vip';
import {
    L4RuleActionSelectPoolConfigItem,
} from 'ajs/modules/l4-policy/factories/actions/l4-rule-action-select-pool.config-item.factory';

import * as l10n from './l4-rule-select-pool-action.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// having local regular enum here since const enum type can't be used for variable assignment
enum L4RuleActionSelectPoolTypeEnum {
    L4_RULE_ACTION_SELECT_POOL = L4RuleActionSelectPoolType.L4_RULE_ACTION_SELECT_POOL,
    L4_RULE_ACTION_SELECT_POOLGROUP = L4RuleActionSelectPoolType.L4_RULE_ACTION_SELECT_POOLGROUP,
}

type TPoolCollection = typeof PoolCollection;
type TPoolGroupCollection = typeof PoolGroupCollection;

/**
 * @description Component for L4 rule select-pool action.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'l4-rule-select-pool-action',
    templateUrl: './l4-rule-select-pool-action.component.html',
})
export class L4RuleSelectPoolActionComponent implements OnInit, OnDestroy {
    /**
     * L4 rule action to be configured.
     */
    @Input()
    public action: L4RuleActionSelectPoolConfigItem;

    /**
     * Object type string of L4RuleActionSelectPoolConfigItem.
     */
    public objectType: string;

    /**
     * Collection of pools to be configured in the action.
     */
    public readonly poolCollection: PoolCollection;

    /**
     * Collection of pool groups to be confirgured in the action.
     */
    public readonly poolGroupCollection: PoolGroupCollection;

    /**
     * Description for the pool select radio button.
     */
    public readonly poolSelectDescription: string;

    /**
     * Description for the pool group select radio button.
     */
    public readonly poolGroupSelectDescription: string;

    /**
     * Enum of action type.
     */
    public readonly L4RuleActionSelectPoolTypeEnum = L4RuleActionSelectPoolTypeEnum;

    /**
     * l10n keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(PoolCollection)
        PoolCollection: TPoolCollection,
        @Inject(PoolGroupCollection)
        PoolGroupCollection: TPoolGroupCollection,
    ) {
        const collectionEntryReferredBy = 'virtualservice:none,dnspolicy:none,datascriptset:none';

        this.poolCollection = new PoolCollection({
            params: {
                referred_by: collectionEntryReferredBy,
            },
        });

        this.poolGroupCollection = new PoolGroupCollection({
            params: {
                referred_by: collectionEntryReferredBy,
            },
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Return true if the configured action is L4_RULE_ACTION_SELECT_POOL.
     */
    public isActionSelectPool(): boolean {
        return this.action.type === L4RuleActionSelectPoolType.L4_RULE_ACTION_SELECT_POOL;
    }

    /**
     * Return true if the configured action is L4_RULE_ACTION_SELECT_POOLGROUP.
     */
    public isActionSelectPoolGroup(): boolean {
        return this.action.type === L4RuleActionSelectPoolType.L4_RULE_ACTION_SELECT_POOLGROUP;
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.action.messageType;
    }

    /** @override */
    public ngOnDestroy(): void {
        this.poolCollection.destroy();
        this.poolGroupCollection.destroy();
    }
}
