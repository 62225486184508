/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'certificate-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const btnLabelRootIntermediateCaCert = `${prefix}.btnLabelRootIntermediateCaCert`;
export const btnLabelApplicationCert = `${prefix}.btnLabelApplicationCert`;
export const btnLabelControllerCert = `${prefix}.btnLabelControllerCert`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const columnTitleCommonName = `${prefix}.columnTitleCommonName`;
export const columnTitleIssuerName = `${prefix}.columnTitleIssuerName`;
export const columnTitleAlgorithm = `${prefix}.columnTitleAlgorithm`;
export const columnTitleSelfSigned = `${prefix}.columnTitleSelfSigned`;
export const columnTitleValidUntil = `${prefix}.columnTitleValidUntil`;
export const columnTitleSanDomainNames = `${prefix}.columnTitleSanDomainNames`;
export const actionBtnExport = `${prefix}.actionBtnExport`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const sslTlsCertificatesHeader = `${prefix}.sslTlsCertificatesHeader`;
export const rootIntermediateCaHeader = `${prefix}.rootIntermediateCaHeader`;
export const yesValue = `${prefix}.yesValue`;
export const noValue = `${prefix}.noValue`;
export const awaitingCertificateValue = `${prefix}.awaitingCertificateValue`;

export const ENGLISH = {
    [btnLabelRootIntermediateCaCert]: 'Root/Intermediate CA Certificate',
    [btnLabelApplicationCert]: 'Application Certificate',
    [btnLabelControllerCert]: 'Controller Certificate',
    [columnTitleName]: 'Name',
    [columnTitleStatus]: 'Status',
    [columnTitleCommonName]: 'Common Name',
    [columnTitleIssuerName]: 'Issuer Name',
    [columnTitleAlgorithm]: 'Algorithm',
    [columnTitleSelfSigned]: 'Self Signed',
    [columnTitleValidUntil]: 'Valid Until',
    [columnTitleSanDomainNames]: 'SAN Domain Names',
    [actionBtnExport]: 'Export',
    [actionBtnEdit]: 'Edit',
    [sslTlsCertificatesHeader]: 'SSL/TLS Certificates',
    [rootIntermediateCaHeader]: 'Root/Intermediate CA',
    [yesValue]: 'Yes',
    [noValue]: 'No',
    [awaitingCertificateValue]: 'Awaiting Certificate',
};
