/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'licensing';
const componentName = 'license-add-panel';
const prefix = `${moduleName}.${componentName}`;

export const title = `${prefix}.title`;
export const applyKeyLabel = `${prefix}.applyKeyLabel`;
export const licenseKeyFieldLabel = `${prefix}.licenseKeyFieldLabel`;
export const licenseKeyLabel = `${prefix}.licenseKeyLabel`;
export const licenseKeyPlaceholder = `${prefix}.licenseKeyPlaceholder`;
export const uploadLicenseLabel = `${prefix}.uploadLicenseLabel`;
export const uploadLicenseFileFieldLabel = `${prefix}.uploadLicenseFileFieldLabel`;
export const licenseAddSuccessMessage = `${prefix}.licenseAddSuccessMessage`;

export const ENGLISH = {
    [title]: 'Add License',
    [applyKeyLabel]: 'Apply Key',
    [licenseKeyFieldLabel]: 'Enter a License Key',
    [licenseKeyLabel]: 'License Key',
    [licenseKeyPlaceholder]: 'XXXXX-XXXXX-XXXXX-XXXXX-XXXXX',
    [uploadLicenseLabel]: 'Upload License',
    [uploadLicenseFileFieldLabel]: 'Upload a License File (.lic)',
    [licenseAddSuccessMessage]: 'License key successfully applied.',
};
