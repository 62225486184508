/**
 * @module groupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { StringGroupModalComponent } from 'ng/modules/groups';
import { STRING_GROUP_ITEM_TOKEN } from '../../groups.tokens';

/**
 * @description String Group Collection.
 * @author Suraj Kumar
 */
export class StringGroupCollection extends Collection {
    public static ajsDependencies = [
        STRING_GROUP_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'stringgroup',
            windowElement: StringGroupModalComponent,
            sortBy: 'name',
            permissionName: AviPermissionResource.PERMISSION_STRINGGROUP,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(STRING_GROUP_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            kv: [],
        };
    }
}
