/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IIcapNsxDefenderConfig } from 'generated-types';

/**
 * Ajs dependency token for IcapNsxDefenderConfigConfigItem.
 */
export const ICAP_NSX_DEFENDER_CONFIG_CONFIG_ITEM_TOKEN = 'IcapNsxDefenderConfigConfigItem';

/**
 * @description
 *
 *   IcapNsxDefenderConfig config item.
 *
 * @author Satish Pednekar
 */
export class IcapNsxDefenderConfigConfigItem extends MessageItem<IIcapNsxDefenderConfig> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IcapNsxDefenderConfig',
            ...args,
        };

        super(extendedArgs);
    }
}
