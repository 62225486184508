/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import {
    StringGroupItem,
} from 'ajs/modules/groups';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { createDropdownOption } from 'ng/shared/utils';
import { AviContinueConfirmationComponent } from 'ng/modules/dialog';
import { IAviDropdownOption } from 'ng/shared/components';
import { IKeyValue, StringGroupType } from 'generated-types';

import {
    AviAlertService,
    DialogService,
} from 'ng/modules/core';

import * as l10n from './string-group-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * ID for String Group change confirmation dialog.
 */
const STRING_GROUP_TYPE_CHANGE_DIALOG_ID = 'string-group-type-change-confirmation';

/**
 * @description Component for StringGroup create/edit modal.
 * @author Suraj Kumar
 */

@Component({
    selector: 'string-group-modal',
    templateUrl: './string-group-modal.component.html',
})
export class StringGroupModalComponent implements OnInit {
    /**
     * StringGroup ObjectTypeItem.
     */
    @Input()
    public editable: StringGroupItem;

    /**
     * Content of the file selected for import.
     */
    public stringGroupFileContent: string;

    /**
     * File selected for import.
     */
    public fileName: string;

    /**
     * StringGroup type.
     */
    public stringGroupType: StringGroupType;

    /**
     * Config data to be passed as param to key-value grid directive from template.
     */
    public keyValuesArray: IKeyValue[];

    /**
     * Config data to be passed as param to strings grid directive from template.
     */
    public stringsArray: string[];

    /**
     * StringGroup object type.
     */
    public objectType: string;

    /**
     * Hash of stringGroupTypes\ enum.
     */
    public readonly stringGroupTypesEnumHash = {
        SG_TYPE_STRING: StringGroupType.SG_TYPE_STRING,
        SG_TYPE_KEYVAL: StringGroupType.SG_TYPE_KEYVAL,
    };

    /**
     * Classifications dropdown options.
     */
    public readonly stringGroupTypeDropdownOptions: IAviDropdownOption[];

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly dialogService: DialogService,
        private readonly aviAlertService: AviAlertService,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.stringGroupTypeDropdownOptions = [
            createDropdownOption(
                this.stringGroupTypesEnumHash.SG_TYPE_KEYVAL,
                l10nService.getMessage(l10nKeys.typeKeyValue),
            ),
            createDropdownOption(
                this.stringGroupTypesEnumHash.SG_TYPE_STRING,
                l10nService.getMessage(l10nKeys.typeString),
            ),
        ];
    }

    /** @override */
    public ngOnInit(): void {
        const { kv, type } = this.editable.getConfig();

        this.objectType = this.editable.messageType;
        this.stringGroupType = type;

        if (type === this.stringGroupTypesEnumHash.SG_TYPE_STRING) {
            this.stringsArray = kv.getConfig().map(
                (element: MessageItem<IKeyValue>) => element.config.key,
            );
        }
    }

    /**
     * returns string value of currect active type selected..
     */
    public get importFieldLabel(): string {
        const currentActiveType = this.stringGroupTypesEnumHash.SG_TYPE_STRING;
        const importFieldLabel = this.editable.getConfig().type === currentActiveType ?
            this.l10nService.getMessage(this.l10nKeys.typeStringFileDataLabel) :
            this.l10nService.getMessage(this.l10nKeys.typeKeyValueFileDataLabel);

        return importFieldLabel;
    }

    /**
     * File upload event handler. Adds entries to the list.
     */
    public importFile(): void {
        let duplicateValuePresent = false;
        const { type } = this.editable.getConfig();
        const isStringType = type === this.stringGroupTypesEnumHash.SG_TYPE_STRING;

        if (!this.stringGroupFileContent) {
            return;
        }

        if (!this.stringsArray && isStringType) {
            this.stringsArray = [];
        }

        this.stringGroupFileContent.split('\n').forEach((str: string) => {
            if (!str) {
                return;
            }

            let key = str.trim();
            let element: string | IKeyValue = key;

            if (!isStringType) {
                const firstCommaPos: number = key.indexOf(',');
                let value = '';

                if (firstCommaPos !== -1) {
                    value = key.slice(firstCommaPos + 1).trim();
                    key = key.slice(0, firstCommaPos).trim();
                }

                element = {
                    key,
                    value,
                };
                duplicateValuePresent =
                    this.addKeyValueAndCheckDuplicate(element) || duplicateValuePresent;
            } else {
                duplicateValuePresent =
                    this.addStringAndCheckDuplicate(element) || duplicateValuePresent;
            }
        });

        if (isStringType) {
            this.refreshStringsConfigList();
        }

        if (duplicateValuePresent) {
            this.aviAlertService.throw(
                this.l10nService.getMessage(l10nKeys.duplicateValuesAlert),
            );
        }
    }

    /**
     * Change the StringGroup type or open modal for confirmation.
     */
    public onViewTypeChange(): void {
        const config = this.editable.getConfig();

        if (config.kv.count) {
            this.stringGroupTypeChangeConfirmation();
        } else {
            this.changeType();
        }
    }

    /**
     * Updates editable config list with local strings list.
     */
    public updateStringsConfigList(): void {
        const configList = this.stringsArray?.map(value => ({ key: value }));

        this.editable.updateConfigList(configList);
    }

    /**
     * Refreshes strings list for change detection.
     */
    private refreshStringsConfigList(): void {
        // For change detection.
        this.stringsArray = [...this.stringsArray];
    }

    /**
     * Updates value if present or add new entry in local key-values grid
     * list and returns true if duplicate present.
     */
    private addKeyValueAndCheckDuplicate(element: IKeyValue): boolean {
        const { kv: repeatedMessageItem } = this.editable.getConfig();
        const index = repeatedMessageItem.config.findIndex(
            (currentElement: MessageItem) => currentElement.config.key === element.key,
        );

        if (index < 0) {
            repeatedMessageItem.add(element);
        } else {
            repeatedMessageItem.at(index).updateConfig(element);
        }

        return index > 0;
    }

    /**
     * Updates value if present or add new entry in local strings grid
     * list and returns true if duplicate present.
     */
    private addStringAndCheckDuplicate(element: string): boolean {
        const index = this.stringsArray.findIndex(value => value === element);

        if (index < 0) {
            // Using push instead  concat for performance issues.
            this.stringsArray.push(element);
            this.updateStringsConfigList();
        }

        return index > 0;
    }

    /**
     * Displays stringGroupTypeChangeConfirmation dialog.
     */
    private stringGroupTypeChangeConfirmation(): void {
        return this.dialogService.add({
            id: STRING_GROUP_TYPE_CHANGE_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                warning: this.l10nService.getMessage(l10nKeys.discardedCurrentValuesConfirmation),
                customHeader: this.l10nService.getMessage(l10nKeys.resetToDefaultValuesHeader),
                onConfirm: () => {
                    this.dialogService.remove(STRING_GROUP_TYPE_CHANGE_DIALOG_ID);
                    this.changeType();
                },
                onClose: () => {
                    this.dialogService.remove(STRING_GROUP_TYPE_CHANGE_DIALOG_ID);
                    this.resetType();
                },
            },
        });
    }

    /**
     * Clears the lists.
     */
    private discardConfigList(): void {
        this.stringsArray = undefined;
        this.editable.discardConfigList();
    }

    /**
     * Clears import settings.
     */
    private clearImportFileSettings(): void {
        this.stringGroupFileContent = undefined;
        this.fileName = undefined;
    }

    /**
     * Change the StringGroup type.
     */
    private changeType(): void {
        this.editable.getConfig().type = this.stringGroupType;
        this.clearImportFileSettings();
        this.discardConfigList();
    }

    /**
     * Reset the StringGroup type.
     */
    private resetType(): void {
        this.stringGroupType = this.editable.getConfig().type;
    }
}
