/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auto-scale';
const componentName = 'auto-scale-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const instancesInputLabel = `${prefix}.instancesInputLabel`;
export const minimumInnerText = `${prefix}.minimumInnerText`;
export const maximumInnerText = `${prefix}.maximumInnerText`;
export const intelligentLabel = `${prefix}.intelligentLabel`;
export const predictedLoadLabel = `${prefix}.predictedLoadLabel`;
export const scaleOutHeader = `${prefix}.scaleOutHeader`;
export const alertsInputLabel = `${prefix}.alertsInputLabel`;
export const cooldownPeriodInputLabel = `${prefix}.cooldownPeriodInputLabel`;
export const adjustmentStepInputLabel = `${prefix}.adjustmentStepInputLabel`;
export const intelligentMarginInputLabel = `${prefix}.intelligentMarginInputLabel`;
export const scaleInHeader = `${prefix}.scaleInHeader`;
export const secondsInnerText = `${prefix}.secondsInnerText`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const alertDropdownPlaceholder = `${prefix}.inputPlaceHolderLabel`;
export const cooldownInputPlaceholder = `${prefix}.cooldownInputPlaceholder`;
export const adjustmentStepInputPlaceholder = `${prefix}.adjustmentStepInputPlaceholder`;
export const intelligentMarginInputPlaceholder = `${prefix}.intelligentMarginInputPlaceholder`;
export const minSizeInputPlaceholder = `${prefix}.minSizeInputPlaceholder`;
export const maxSizeInputPlaceholder = `${prefix}.maxSizeInputPlaceholder`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const serverGarbageCollectionLabel = `${prefix}.serverGarbageCollectionLabel`;
export const serverGarbageCollectionPlaceholder = `${prefix}.serverGarbageCollectionPlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Server AutoScale Policy: {0}',
    [modalTitleNew]: 'New Server AutoScale Policy: {0}',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [instancesInputLabel]: 'Instances',
    [minimumInnerText]: 'Minimum',
    [maximumInnerText]: 'Maximum',
    [intelligentLabel]: 'Intelligent (Machine Learning)',
    [predictedLoadLabel]: 'Use Predicted Load',
    [scaleOutHeader]: 'Scale Out',
    [alertsInputLabel]: 'Alerts',
    [cooldownPeriodInputLabel]: 'Cooldown Period',
    [adjustmentStepInputLabel]: 'Adjustment Step',
    [intelligentMarginInputLabel]: 'Intelligent Margin',
    [scaleInHeader]: 'Scale In',
    [secondsInnerText]: 'Seconds',
    [nameInputPlaceholder]: 'Enter Name',
    [alertDropdownPlaceholder]: 'Select Alerts',
    [cooldownInputPlaceholder]: 'Enter Cooldown Period',
    [adjustmentStepInputPlaceholder]: 'Enter Adjustment Step',
    [intelligentMarginInputPlaceholder]: 'Enter Intelligent Margin',
    [minSizeInputPlaceholder]: 'Enter Minimum Instances',
    [maxSizeInputPlaceholder]: 'Enter Maximum Instances',
    [minutesInnerText]: 'Minutes',
    [serverGarbageCollectionLabel]: 'Server Garbage Collection Delay',
    [serverGarbageCollectionPlaceholder]: 'Enter Server Garbage Collection Delay',
};
