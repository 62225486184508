/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IGenericMessageItemConfig, MessageBase } from 'ajs/modules/data-model/factories';
import { Constructor } from '../../../declarations/globals.d';

export interface IIndexItem {
    getIndex(): number;
    setIndex(index: number): void;
}

type IIndexed<K, X extends Constructor<MessageBase<K>>> =
    X & IIndexItem & (new (...args: any[]) => X & IIndexItem);

/**
 * @description
 *     Mixin to extend a MessageItem having index property.
 *     Not all MessageItems have Index property.
 *     Use only when you are know MessageItem supports index property.
 * @author Rachit Aggarwal
 */
export function withIndexMixin<
    K extends IGenericMessageItemConfig,
    BaseClass extends Constructor<MessageBase<K>>
>(BaseClass: BaseClass): IIndexed<K, BaseClass> {
    class WithIndexItem extends BaseClass implements IIndexItem {
        /**
         * Returns the index of the MessageItem, which typically exists for repeated MessageItems.
         */
        public getIndex(config = this.config): number | undefined {
            return config.index;
        }

        /**
         * Sets the index property on a config.
         */
        public setIndex(index = 0): void {
            const { config } = this;

            config.index = index;
        }
    }

    return WithIndexItem as unknown as IIndexed<K, BaseClass>;
}
