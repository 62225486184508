/**
 * @module NetworkServiceModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    NetworkService,
    NetworkServiceCollection,
    networkServicePageOptions,
    NETWORK_SERVICE_COLLECTION_TOKEN,
    NETWORK_SERVICE_PAGE_OPTIONS_TOKEN,
    NETWORK_SERVICE_TOKEN,
} from '.';

const networkServiceModule = angular.module('avi/network-service');

const factories = [
    {
        name: NETWORK_SERVICE_TOKEN,
        factory: NetworkService,
    },
    {
        name: NETWORK_SERVICE_COLLECTION_TOKEN,
        factory: NetworkServiceCollection,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        networkServiceModule,
        'factory',
        name,
        factory,
    );
});

const components = [
    {
        name: NETWORK_SERVICE_PAGE_OPTIONS_TOKEN,
        options: networkServicePageOptions,
    },
];

components.forEach(({ name, options }) => networkServiceModule.component(name, options));
