/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IALBServicesStatus } from 'generated-types';
import { portalStatus } from 'ajs/modules/cportal/constants/cportal.constants';

import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';

import {
    Case,
    CaseCollection,
} from 'ajs/modules/cportal';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './case-list.l10n';
import template from './case-list.html';
import './case-list.less';
import { CportalService } from '../../services';

type TCaseCollection = typeof CaseCollection;
type createCrudCollectionGridConfigType = typeof createCrudCollectionGridConfig;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'case-list';

export const CASE_LIST_COMPONENT_TOKEN = 'caseList';

/**
 * @description Case list page with options to create/edit a case.
 * @author Ashish Verma, Ram Pal, Rajawant Prajapati
 */
class CaseListController {
    /**
     * Default Avi cloud connected status.
     */
    public aviCloudConnected = false;

    /**
     * Default controller registration status.
     */
    public controllerRegistered = false;

    /**
     * grid configuration options.
     */
    public gridConfig: ICollectionGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Default controller registration status.
     */
    private readonly cportalService: CportalService;

    constructor(
        private readonly $element: JQuery,
        private readonly CaseCollection: TCaseCollection,
        private readonly CRUDGridConfig: createCrudCollectionGridConfigType,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public $onInit(): void {
        const { l10nService } = this;

        this.$element.addClass(componentName);

        const gridConfigOptions: any = {
            collection: new this.CaseCollection({ isStatic: false }),
            id: 'case-list-page',
            multipleactions: [],
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.attachmentBtnLabel),
                class: 'sl-icon-paper-clip',
                hidden: (row: Case) => !row.getConfig().case_attachments,
            }, {
                title: l10nService.getMessage(l10nKeys.editBtnLabel),
                class: 'icon-pencil-4',
                do: (row: Case) => row.edit(),
            }],
            defaultSorting: '',
            fields: [
                {
                    name: 'created_date',
                    title: l10nService.getMessage(l10nKeys.columnTitleDateOpened),
                    template: '{{ row.getConfig().created_date | aviDate }}',
                },
                {
                    name: 'case_number',
                    title: l10nService.getMessage(l10nKeys.columnTitleCaseNumber),
                    template: '{{ row.getConfig().case_number }}',
                },
                {
                    name: 'subject',
                    title: l10nService.getMessage(l10nKeys.columnTitleSubject),
                    template: '{{ row.getConfig().subject }}',
                },
                {
                    name: 'type',
                    title: l10nService.getMessage(l10nKeys.columnTitleType),
                    template: '{{ row.getConfig().type }}',
                },
                {
                    name: 'last_modified_date',
                    title: l10nService.getMessage(l10nKeys.columnTitleLastUpdated),
                    template: '{{ row.getConfig().last_modified_date | aviDate }}',
                },
                {
                    name: 'status',
                    title: l10nService.getMessage(l10nKeys.columnTitleStatus),
                    template: '<span class="case-list__case-status">{{ ::row.getStatus() }}</span>',
                },
            ],
            permission: 'PERMISSION_CONTROLLER',
            layout: {
                hideSearch: true,
                hideEditColumns: true,
            },
        };

        this.updateStatus(this.cportalService.getRegistrationStatusDetails());

        this.gridConfig = this.CRUDGridConfig(gridConfigOptions);
    }

    /**
     * @override
     */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }

    /**
     * Updates Controller registration and AlbServices connectivity status
     */
    private updateStatus(data: IALBServicesStatus): void {
        const { ALBSERVICES_REGISTERED, ALBSERVICES_CONNECTED } = portalStatus;

        this.controllerRegistered =
            data.registration_status === ALBSERVICES_REGISTERED;
        this.aviCloudConnected =
            data.connectivity_status === ALBSERVICES_CONNECTED;
    }
}

CaseListController.$inject = [
    '$element',
    'CaseCollection',
    'CRUDGridConfig',
    'l10nService',
];

export const caseListComponentOptions = {
    bindings: {
        cportalService: '<loadedPulseRegistrationStatus',
    },
    controller: CaseListController,
    template,
};
