/**
 * @module NetworkModule
 */

/***************************************************************************
* ========================================================================
* Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
* ========================================================================
*/

import {
    IDsrProfile,
    ITCPFastPathProfile,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { TCPFastPathProfile } from 'object-types';

/**
 * Interface for TCP Fast Path Profile Partial.
 */
type TTCPFastPathProfilePartial = Omit<ITCPFastPathProfile, 'dsr_profile'>;

/**
 * Interface for Extended Tcp Fast Path Profile.
 */
interface ITcpFastPathProfileConfig extends TTCPFastPathProfilePartial {
    dsr_profile?: MessageItem<IDsrProfile>;
}

/**
 * @description
 *      TCP Fast Path Message Item class for setting up DSR profile.
 * @author Ratan Kumar
 */
export class TCPFastPathProfileConfigItem extends MessageItem<ITcpFastPathProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: TCPFastPathProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Remove Dsr profile on type change.
     */
    public removeDsrProfile(): void {
        delete this.config.dsr_profile;
    }

    /**
     * create Dsr profile on type change.
     */
    public createDsrProfile(): void {
        this.safeSetNewChildByField('dsr_profile');
    }
}
