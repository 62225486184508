/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'credential-verification';
const prefix = `${moduleName}.${componentName}`;

export const credentialsHeader = `${prefix}.credentialsHeader`;
export const credentialsInvalidMessage = `${prefix}.credentialsInvalidMessage`;
export const credentialsUpdatedMessage = `${prefix}.credentialsUpdatedMessage`;
export const connectBtnLabel = `${prefix}.connectBtnLabel`;
export const changeCredentialsBtnLabel = `${prefix}.changeCredentialsBtnLabel`;

export const ENGLISH = {
    [credentialsHeader]: 'Credentials',
    [credentialsInvalidMessage]: 'Credentials invalid. {0}',
    [credentialsUpdatedMessage]: 'Credentials updated. {0}',
    [connectBtnLabel]: 'Connect',
    [changeCredentialsBtnLabel]: 'Change Credentials',
};
