/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN } from './ping-access-agent-profile.item.factory';

/**
 * Ajs dependency token for PingAccessAgentProfile collection.
 */
export const PING_ACCESS_AGENT_PROFILE_COLLECTION_TOKEN = 'PingAccessAgentProfileCollection';

/**
 * @description Ping access agent profile collection
 *
 * @author Rohit Gaikwad
 */
export class PingAccessAgentProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'pingaccessagent',
            objectType: PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
            permissionName: AviPermissionResource.PERMISSION_PINGACCESSAGENT,
            windowElement: 'ping-access-agent-modal',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN);
    }
}

PingAccessAgentProfileCollection.ajsDependencies = [
    PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
];
