/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    RevisedDataTransformer,
} from 'ajs/modules/data-model/factories/revised-data-transformer.factory';
import { isObject, pick } from 'underscore';
import {
    IGslbPool,
    IGslbPoolMemberDatapathStatus,
    IGslbPoolMemberRuntimeInfo,
    IGslbPoolRuntime,
    IIpAddr,
    VipType,
} from 'generated-types';

import { GSLBService } from '.';

interface IGSLBServicePoolMembersInventoryResponse {
    data: IGSLBServicePoolMembersInventoryData;
}

interface IGSLBServicePoolMembersInventoryData {
    results: IGslbPoolMemberResult[];
    count: number;
}

interface IHealthScore {
    health_score: number;
}

interface IGslbPoolMemberResult {
    config: any;
    runtime: IGslbPoolRuntime;
    health_score: IHealthScore;
}

interface IExtendedGslbPoolMemberRuntimeInfo extends IGslbPoolMemberRuntimeInfo {
    id: string;
    enabled: boolean;
    ip: IIpAddr;
    name: string;
    gslbPoolName: string;
    gslbPoolPriority: number;
}

/**
 * @description
 *
 *     Returns a flat list of GslbService GslbPool members.
 *
 * @author Alex Malitsky, Ram Pal, Zhiqian Liu
 */
export class GSLBServicePoolMembersInventoryDataTransformer extends RevisedDataTransformer {
    /**
     * Returns member name for UI.
     */
    private static getMemberName({
        vip_type: vipType,
        vs_name: vsName,
        ip,
        fqdn,
        site_name: siteName,
    }: IGslbPoolMemberRuntimeInfo): string {
        let name;

        if (vipType === VipType.AVI_VIP) {
            name = `${siteName}:${vsName}`;
        } else {
            name = fqdn || ip.addr;
        }

        return name;
    }

    /** @override */
    public processResponse(resp: any): IGSLBServicePoolMembersInventoryResponse {
        const results: IGslbPoolMemberResult[] = [];
        const { config: configData, runtime } = resp.data;

        if (isObject(runtime) && 'groups' in runtime) {
            const healthScore = { health_score: 100 };
            const gslbPoolPriorityHash = resp.data.config.groups.reduce(
                (acc: Record<string, number>, { name, priority }: IGslbPool) => {
                    acc[name] = priority;

                    return acc;
                }, {},
            );

            runtime.groups.forEach(({ name: gslbPoolName, members }: IGslbPoolRuntime) => {
                const gslbPoolPriority = gslbPoolPriorityHash[gslbPoolName];

                members.forEach((member: IExtendedGslbPoolMemberRuntimeInfo) => {
                    const siteStatusHash = {};
                    const config = pick<IExtendedGslbPoolMemberRuntimeInfo, string>(member, [
                        'ip', 'vip_type', 'vs_uuid', 'fqdn', 'public_ip',
                        'vs_name', 'cluster_uuid', 'site_name',
                    ]);
                    // since we wanna rebuild the list from scratch every time

                    config.name =
                        GSLBServicePoolMembersInventoryDataTransformer.getMemberName(config);
                    config.gslbPoolName = gslbPoolName;
                    config.gslbPoolPriority = gslbPoolPriority;
                    config.id = `${gslbPoolName}-${config.ip.addr}`;

                    const memberConfigData = GSLBService.getPoolMember(
                        configData,
                        gslbPoolName,
                        config.ip,
                    );

                    config.enabled = memberConfigData.enabled;

                    const runtime = {
                        oper_status: member.oper_status,
                        gslbSiteOperStatuses: siteStatusHash,
                    };

                    if ('datapath_status' in member) {
                        member.datapath_status
                            .forEach((siteStatus: IGslbPoolMemberDatapathStatus) => {
                                const {
                                    site_uuid: siteUuid,
                                    oper_status: operStatus,
                                    location,
                                } = siteStatus;

                                siteStatusHash[siteUuid] = {
                                    site_uuid: siteUuid,
                                    oper_status: operStatus,
                                    location,
                                };
                            });
                    }

                    results.push({
                        config,
                        runtime,
                        health_score: healthScore,
                    });
                });
            });
        }

        return {
            data: {
                results,
                count: results.length,
            },
        };
    }
}
