/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('avi/serviceengine').factory('SEGroupFlavorCollection', [
'Collection', 'SEGroupFlavor',
function(Collection, SEGroupFlavor) {
    const dataSources = {
        list: {
            source: 'SEGroupFlavorListCollDataSource',
            transformer: 'SEGroupFlavorListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    /**
     * @constructor
     * @extends module:avi/dataModel.Collection
     * @memberOf module:avi/serviceengine
     * @description Flavors {@link module:avi/dataModel.Collection} for
     *     {@link module:avi/serviceengine.SEGroup}.
     * @author Akul, Alex Malitsky
     */
    class SEGroupFlavorCollection extends Collection {
        constructor(args = {}) {
            if (angular.isUndefined(args.cloudId)) {
                throw new Error('Can\'t work with SEGroupFlavorCollection without cloudId');
            }

            super(args);

            /**
             * Cloud id of the Cloud this Server belongs to
             * @type {string}
             */
            this.cloudId = args.cloudId;
        }

        /** @override */
        isCreatable() {
            return false;
        }
    }

    angular.extend(SEGroupFlavorCollection.prototype, {
        objectName_: 'segroup-flavor',
        itemClass_: SEGroupFlavor,
        allDataSources_: dataSources,
        defaultDataSources_: 'list',
    });

    return SEGroupFlavorCollection;
}]);
