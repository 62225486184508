/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ICookieMatch } from 'generated-types';
import { CookieMatch } from 'object-types';
import { GenericHdrMatchBaseConfigItem } from './generic-hdr-match-base.config-item';

/**
 * @description  CookieMatch ConfigItem class.
 * @author Abhinesh Gour
 */
export class CookieMatchConfigItem extends GenericHdrMatchBaseConfigItem<ICookieMatch> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: CookieMatch,
            ...args,
        };

        super(extendedArgs);
    }
}
