/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { NgForm } from '@angular/forms';
import { StateParams } from '@uirouter/core';
import { IControllerProperties } from 'generated-types';

import {
    L10nService,
    LocaleService,
} from '@vmw/ngx-vip';

import {
    LANGUAGES,
    USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE,
} from 'ajs/js/constants/my-account.constant';

import {
    Auth,
    IUIProperty,
    IUserAccount,
    MyAccount,
} from 'ajs/modules/core/services';

import './my-account-modal.component.less';
import { MyAccountModalService } from '../..';

import * as l10n from './my-account-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for My Account modal.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'my-account-modal',
    templateUrl: './my-account-modal.component.html',
})
export class MyAccountModalComponent implements OnInit {
    /**
     * Template ref form element.
     */
    @ViewChild('form')
    public form: NgForm;

    /**
     * Layout for My Account clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Languages dropdown options.
     */
    public languageOptions = LANGUAGES;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Used to show error messages if any.
     */
    public errors: string;

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    constructor(
        l10nService: L10nService,
        private readonly localeService: LocaleService,
        private readonly myAccount: MyAccount,
        private readonly myAccountModalService: MyAccountModalService,
        private readonly authService: Auth,
        @Inject('$stateParams')
        private readonly $stateParams: StateParams,
        @Inject('systemConfigService')
        private readonly systemConfigService: any,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.myAccount.load();
        this.myAccount.getControllerProperties();

        /**
         *  Load systemConfiguration only when current
         *  user has 'PERMISSION_SYSTEMCONFIGURATION' access.
         */
        if (this.authService.isAllowed('systemconfiguration')) {
            this.systemConfigService.load();
        }
    }

    /**
     * Getter function for account data.
     */
    public get accountData(): IUserAccount {
        const { accountData } = this.myAccount;

        return accountData;
    }

    /**
     * Getter function for UI Property data.
     */
    public get uiProperty(): IUIProperty {
        const { uiProperty } = this.myAccount;

        return uiProperty;
    }

    /**
     * Getter function for Controller properties data.
     */
    public get controllerProperties(): IControllerProperties {
        const { controllerProperties } = this.myAccount;

        return controllerProperties;
    }

    /**
     * Getter function for password strength check field from systemConfiguration.
     */
    public get passwordStrengthCheckEnabled(): boolean {
        const { portal_configuration: portalConfig } = this.systemConfigService.getConfig() || {};

        return Boolean(portalConfig?.password_strength_check);
    }

    /**
     * Getter function for password minimum length field from systemConfiguration.
     */
    public get minimumPasswordLength(): number | undefined {
        const { portal_configuration: portalConfig } = this.systemConfigService.getConfig() || {};

        return portalConfig?.minimum_password_length;
    }

    /**
     * Compares password and confirmPassword values and
     * invalidate confirm password field if they do not match.
     */
    public comparePasswords(): void {
        if (this.accountData.password !== this.accountData.confirm_password) {
            this.form.controls.confirm_password.setErrors({ incorrect: true });
        } else {
            this.form.controls.confirm_password.setErrors(null);
        }
    }

    /**
     * Called on cancel button click.
     * Discard changes and close modal.
     */
    public dismiss(): void {
        this.myAccount.revertChanges();
        this.myAccountModalService.closeModal();
    }

    /**
     * Method to Save user preferences and account data.
     */
    public submit(): void {
        const { myAccount } = this;

        this.busy = true;
        this.errors = '';

        Promise.all([
            myAccount.saveAccount(),
            myAccount.saveUIProperty(),
            myAccount.saveControllerProperties(),
        ]).then(
            () => {
                const {
                    language,
                    uiProperty,
                    UseUTCTimeOriginal,
                } = myAccount;

                if (uiProperty.useUTCTime !== UseUTCTimeOriginal) {
                    myAccount.trigger(USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE);
                }

                // todo check current state for applicability
                this.$stateParams.timeframe = uiProperty.defaultTimeframe;

                if (this.localeService.getCurrentLanguage() !== language) {
                    myAccount.onLanguageChange();
                }

                this.myAccountModalService.closeModal();
            },
        ).catch(({ data }) => {
            this.errors = data;
        }).finally(() => this.busy = false);
    }
}
