/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name chartWithOverlays
 * @restrict AE
 * @description
 *     Chart with Overlays
 *     Includes both a table and a performance chart
 *
 * @param {boolean} e2eSeriesAvailable - When false, render license upgrade message on top of chart
 *     for end_to_end metric.
 */
angular.module('aviApp').directive('chartWithOverlays', ['myAccount',
function(myAccount) {
    return {
        restrict: 'AE',
        scope: {
            item: '=',
            config: '=',
            smallChart: '=',
            e2eSeriesAvailable: '<?',
        },
        templateUrl: 'src/views/components/chartWithOverlays.html',
        link(scope) {
            if (!myAccount.uiProperty.performanceControls) {
                myAccount.uiProperty.performanceControls = {};
            }

            scope.hide = myAccount.uiProperty.performanceControls;

            scope.toggle = function(type) {
                scope.hide[type] = !scope.hide[type];
                myAccount.uiProperty.performanceControls = scope.hide;
                scope.$broadcast('events-and-alerts-toggled');
                myAccount.saveUIProperty();
            };
        },
    };
}]);
