/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'policies';
const componentName = 'l4-policy-set-list';
const prefix = `${moduleName}.${componentName}`;

export const descriptionLabel = `${prefix}.descriptionLabel`;
export const notApplicableLabel = `${prefix}.notApplicableLabel`;
export const columnTitleNumberRules = `${prefix}.columnTitleNumberRules`;
export const columnTitleName = `${prefix}.columnTitleName`;

export const ENGLISH = {
    [descriptionLabel]: 'Description',
    [notApplicableLabel]: 'N/A',
    [columnTitleNumberRules]: '# Rules in L4 Connection Policy',
    [columnTitleName]: 'Name',
};
