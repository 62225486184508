/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { uniq } from 'underscore';

import { IAviDataGridConfig } from 'ng/shared/components';
import { IServer } from 'ajs/modules/groups';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './ip-addr-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for IP addresses in IP Address Group modal.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ip-addr-grid',
    templateUrl: './ip-addr-grid.component.html',
})
export class IpAddrGridComponent implements AfterViewInit {
    /**
     * List of IP Addresses in IP Group.
     */
    @Input()
    public ipAddrStrings: IServer[];

    /**
     * Fires on Add IP address.
     */
    @Output()
    public onAddIpAddr = new EventEmitter();

    /**
     * Fires on Remove IP addresses.
     */
    @Output()
    public onRemoveIps = new EventEmitter<IServer[]>();

    /**
     * TemplateRef for ip input element.
     */
    @ViewChild('ipAddrTemplateRef')
    public ipAddrTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Grid config for IP Address in IP Group.
     */
    public ipAddrGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * List of duplicate IP addresses from grid.
     */
    public duplicateIps: string[] = [];

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.ipAddrGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleIpAddress),
                    id: 'ip-addr',
                    templateRef: this.ipAddrTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (ipList: IServer[]) => {
                    this.onRemoveIps.emit(ipList);
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (ipAddr: IServer) => {
                    this.onRemoveIps.emit([ipAddr]);
                },
            }],
        };
    }

    /**
     * Handler for IP Address add operation.
     */
    public addIpAddress(): void {
        this.onAddIpAddr.emit();
    }

    /**
     * Handler for IP Address change operation.
     * Checks if any IP is already present in grid and store those duplicate IPs
     * to show in error message.
     */
    public handleIpAddressChange(): void {
        const flattenArr: string[] = [];

        this.ipAddrStrings.forEach((item: IServer) => {
            if (item.addr) {
                flattenArr.push(...item.addr);
            }
        });

        this.duplicateIps = uniq(flattenArr.filter(
            (item: string, index: number) => flattenArr.indexOf(item) !== index,
        ));
    }
}
