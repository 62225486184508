/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'bgp-peer-modal';
const prefix = `${moduleName}.${componentName}`;

export const peerHeader = `${prefix}.peerHeader`;
export const generalTabLabel = `${prefix}.generalTabLabel`;
export const generalHeader = `${prefix}.generalHeader`;
export const labelInputLabel = `${prefix}.labelInputLabel`;
export const labelInputPlaceholder = `${prefix}.labelInputPlaceholder`;
export const placementNetworkInputLabel = `${prefix}.placementNetworkInputLabel`;
export const placementNetworkInputPlaceholder = `${prefix}.placementNetworkInputPlaceholder`;
export const peerTabLabel = `${prefix}.peerTabLabel`;
export const peerSectionHeader = `${prefix}.peerSectionHeader`;
export const ipv4PrefixInputLabel = `${prefix}.ipv4PrefixInputLabel`;
export const prefixInputPlaceholder = `${prefix}.prefixInputPlaceholder`;
export const ipv4PeerInputLabel = `${prefix}.ipv4PeerInputLabel`;
export const peerInputPlaceholder = `${prefix}.peerInputPlaceholder`;
export const ipv6PrefixInputLabel = `${prefix}.ipv6PrefixInputLabel`;
export const ipv6PeerInputLabel = `${prefix}.ipv6PeerInputLabel`;
export const md5SecretInputLabel = `${prefix}.md5SecretInputLabel`;
export const md5SecretInputPlaceholder = `${prefix}.md5SecretInputPlaceholder`;
export const remoteAccessibleAutonomousSystemIdInputLabel = `${prefix}.remoteAccessibleAutonomousSystemIdInputLabel`;
export const remoteAccessibleAutonomousSystemIdInputPlaceholder = `${prefix}.remoteAccessibleAutonomousSystemIdInputPlaceholder`;
export const multihopIdInputLabel = `${prefix}.multihopIdInputLabel`;
export const multihopIdInputPlaceholder = `${prefix}.multihopIdInputPlaceholder`;
export const advertisementIntervalInputLabel = `${prefix}.advertisementIntervalInputLabel`;
export const advertisementIntervalInputPlaceholder = `${prefix}.advertisementIntervalInputPlaceholder`;
export const connectionTimerInputLabel = `${prefix}.connectionTimerInputLabel`;
export const connectionTimerInputPlaceholder = `${prefix}.connectionTimerInputPlaceholder`;
export const keepaliveIntervalInputLabel = `${prefix}.keepaliveIntervalInputLabel`;
export const keepaliveIntervalInputPlaceholder = `${prefix}.keepaliveIntervalInputPlaceholder`;
export const holdTimeInputLabel = `${prefix}.holdTimeInputLabel`;
export const holdTimeInputPlaceholder = `${prefix}.holdTimeInputPlaceholder`;
export const enableBfdCheckboxLabel = `${prefix}.enableBfdCheckboxLabel`;
export const advertiseVipToPeerCheckboxLabel = `${prefix}.advertiseVipToPeerCheckboxLabel`;
export const advertiseSnatToPeerCheckboxLabel = `${prefix}.advertiseSnatToPeerCheckboxLabel`;
export const ibgpLocalAsOverrideCheckBoxLabel = `${prefix}.ibgpLocalAsOverrideCheckBoxLabel`;
export const secondsInnerText = `${prefix}.secondsInnerText`;

export const ENGLISH = {
    [peerHeader]: 'Peer ({0})',
    [generalTabLabel]: 'General',
    [generalHeader]: 'General',
    [labelInputLabel]: 'Label',
    [labelInputPlaceholder]: 'Enter Label',
    [placementNetworkInputLabel]: 'Placement Network',
    [placementNetworkInputPlaceholder]: 'Select Placement Network',
    [peerTabLabel]: 'Peer',
    [peerSectionHeader]: 'Peer',
    [ipv4PrefixInputLabel]: 'IPV4 Prefix',
    [prefixInputPlaceholder]: 'Enter Prefix',
    [ipv4PeerInputLabel]: 'IPV4 Peer',
    [ipv6PrefixInputLabel]: 'IPV6 Prefix',
    [peerInputPlaceholder]: 'Enter Peer',
    [ipv6PeerInputLabel]: 'IPV6 Peer',
    [md5SecretInputLabel]: 'MD5 Secret',
    [md5SecretInputPlaceholder]: 'Enter Secret',
    [remoteAccessibleAutonomousSystemIdInputLabel]: 'Remote Accessible Autonomous System ID (AS)',
    [remoteAccessibleAutonomousSystemIdInputPlaceholder]: '1-429967295',
    [multihopIdInputLabel]: 'Multihop',
    [multihopIdInputPlaceholder]: 'Enter TTL (0-255)',
    [advertisementIntervalInputLabel]: 'Advertisement Interval',
    [advertisementIntervalInputPlaceholder]: 'Enter Advertisement Interval',
    [connectionTimerInputLabel]: 'Connection Timer',
    [connectionTimerInputPlaceholder]: 'Enter Connection Timer',
    [keepaliveIntervalInputLabel]: 'Keepalive Interval',
    [keepaliveIntervalInputPlaceholder]: 'Enter Keepalive Interval',
    [holdTimeInputLabel]: 'Hold Time',
    [holdTimeInputPlaceholder]: 'Enter Hold Time',
    [enableBfdCheckboxLabel]: 'Enable Bi-Directional Forward Detection (BFD)',
    [advertiseVipToPeerCheckboxLabel]: 'Advertise VIP to Peer',
    [advertiseSnatToPeerCheckboxLabel]: 'Advertise SNAT to Peer',
    [ibgpLocalAsOverrideCheckBoxLabel]: 'Use iBGP as Local Override',
    [secondsInnerText]: 'Seconds',
};
