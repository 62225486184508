/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { L10nService } from '@vmw/ngx-vip';
import { isEmpty } from 'underscore';

import {
    AVI_REPEATED_STRINGS_RANGE_VALIDATOR_KEY,
    AVI_REPEATED_STRINGS_UNIQUENESS_VALIDATOR_KEY,
    CONFIG_FIELD_INPUT_VALIDATOR_KEY,
    PORT_VALIDATOR_KEY,
    REGEX_PATTERN_VALIDATOR_KEY,
} from 'ng/modules/avi-forms/validators';

import * as l10n from './avi-input-container-error.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const errorsMap = new Map([
    [REGEX_PATTERN_VALIDATOR_KEY, l10nKeys.regexPatternError],
    [AVI_REPEATED_STRINGS_RANGE_VALIDATOR_KEY, l10nKeys.rangeError],
    [AVI_REPEATED_STRINGS_UNIQUENESS_VALIDATOR_KEY, l10nKeys.uniquenessError],
    ['required', l10nKeys.requiredError],
    [CONFIG_FIELD_INPUT_VALIDATOR_KEY, l10nKeys.configFieldInputValidationError],
    [PORT_VALIDATOR_KEY, l10nKeys.portValidateError],
]);

/**
 * @description Component to display validation errors in the AviInputContainer component.
 * @author alextsg
 */
@Component({
    selector: 'avi-input-container-error',
    template: '<div>{{ errorMessage }}</div>',
})
export class AviInputContainerErrorComponent {
    /**
     * Object of validationErrors
     */
    @Input()
    public validationErrors: ValidationErrors;

    /**
     * Optional Error text. If present, overrides all other errors.
     */
    @Input()
    public customError = '';

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns an error to be displayed. If customError exists, return that. If validationErrors
     * exists, return the error from the first error key.
     */
    public get errorMessage(): string | undefined {
        if (this.customError) {
            return this.customError;
        }

        if (!isEmpty(this.validationErrors)) {
            const key = Object.keys(this.validationErrors)[0];

            return this.l10nService.getMessage(errorsMap.get(key));
        }
    }
}
