/**
 * @module GeoDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'security';
const componentName = 'geo-db-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleFederated = `${prefix}.columnTitleFederated`;
export const columnTitleFiles = `${prefix}.columnTitleFiles`;
export const columnTitleMappings = `${prefix}.columnTitleMappings`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleFederated]: 'Federated',
    [columnTitleFiles]: 'GeoDB Files',
    [columnTitleMappings]: 'GeoDB Mapping',
    [actionBtnDelete]: 'Delete',
};
