/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './dns-policy-action.component.less';

/**
 * @ngdoc component
 * @name dnsPolicyAction
 * @description Component for configuring a DnsRule action.
 * @param {Function} onRemoveAction - Called when the close button is clicked.
 * @param {Object} action - Action configuration object.
 * @param {string} actionType - Action property used to set the templateUrl.
 */
class DnsPolicyActionController {
    constructor(PoolCollection, PoolGroupCollection, schemaService) {
        this.PoolCollection_ = PoolCollection;
        this.PoolGroupCollection_ = PoolGroupCollection;

        this.hiddenResponseCodes = [
                'DNS_RCODE_YXDOMAIN',
                'DNS_RCODE_YXRRSET',
                'DNS_RCODE_NXRRSET',
                'DNS_RCODE_NOTAUTH',
                'DNS_RCODE_NOTZONE',
        ];

        this.poolCollection = new this.PoolCollection_({
            params: {
                referred_by: 'virtualservice:none,dnspolicy:none,datascriptset:none',
            },
        });

        this.poolGroupCollection = new this.PoolGroupCollection_({
            params: {
                referred_by: 'virtualservice:none,dnspolicy:none,datascriptset:none',
            },
        });

        this.rateLimitActionTypes = schemaService.getEnumValues('DnsRuleRLActionType');
    }

    $onDestory() {
        this.poolCollection.destroy();
        this.poolGroupCollection.destroy();
    }
}

DnsPolicyActionController.$inject = [
        'PoolCollection',
        'PoolGroupCollection',
        'schemaService',
];

angular.module('aviApp').component('dnsPolicyAction', {
    controller: DnsPolicyActionController,
    bindings: {
        onRemoveAction: '&',
        action: '<',
        actionType: '@',
    },
    /**
     * Sets the templateUrl based on the actionType, which changes based on the action being
     * configured.
     */
    templateUrl: ['$attrs', ({ actionType }) => {
        const templateUrl = 'src/components/applications/virtualservice/' +
                'virtualservice-dns-policy/dns-policy-rule-modal/dns-policy-action/' +
                'dns-policy-action';

        return `${templateUrl}-${actionType}.html`;
    }],
});
