/** @module SecurityModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { Component, Type } from '@angular/core';
import { PkiCertificateAuthorityModalComponent } from 'ng/modules/security';

import {
    ISSLCertificate,
    ISSLCertificateDescription,
} from 'generated-types';

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './pki-profile.l10n';
import { SSLKeyParamsConfigItem } from '.';

type TSSLCertificatePartial = Omit<ISSLCertificate, 'subject' | 'issuer' | 'key_params'>;
interface ISSLCertificateConfig extends TSSLCertificatePartial {
    subject: MessageItem<ISSLCertificateDescription>;
    issuer: MessageItem<ISSLCertificateDescription>;
    key_params: SSLKeyParamsConfigItem;
}

/**
 * Ajs dependency token for CertificateAuthorityConfigItem.
 */
export const SSL_CERTIFICATE_CONFIG_ITEM_TOKEN = 'SSLCertificateConfigItem';

/**
 * API to resolve Certificate Authority(CA).
 */
const PRINT_SSL_API = '/api/printssl';

/**
 * Request ID to resolve Certificate Authority(CA).
 */
const RESOLVE_CA_REQUEST_ID = 'resolveCa';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   SSLCertificate Message Item.
 *
 * @author Satish Pednekar
 */
export class SSLCertificateConfigItem extends
    withFullModalMixin(MessageItem)<ISSLCertificateConfig> {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    /**
     * L10nService instance for translation.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'SSLCertificate',
            windowElement: PkiCertificateAuthorityModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_('HttpWrapper');

        this.httpWrapper = new HttpWrapper();
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for subject object.
     */
    public get subject(): MessageItem<ISSLCertificateDescription> | null {
        const { subject } = this.config;

        return subject || null;
    }

    /**
     * Getter for Certificate Authority(CA) subject name.
     */
    public get subjectName(): string {
        const { subject: { config: subjectConfig } } = this.config;

        const {
            common_name: commonName,
            distinguished_name: distinguishedName,
        } = subjectConfig;

        return commonName || distinguishedName;
    }

    /**
     * Getter for Certificate Authority(CA) issuer name.
     */
    public get issuerName(): string {
        const { issuer: { config: issuerConfig } } = this.config;

        const {
            common_name: commonName,
            distinguished_name: distinguishedName,
        } = issuerConfig;

        return commonName || distinguishedName;
    }

    /**
     * Update Certificate Authority(CA) data after resolving the file contents.
     */
    public async updateCertificateAuthorityData(content: string): Promise<void> {
        const certificateAuthority =
            await this.resolveCertificateAuthority(content);

        this.updateConfig(certificateAuthority as unknown as ISSLCertificateConfig);
    }

    /**
     * Clear errors and previous Certificate Authority(CA) data.
     */
    public clearCertificateAuthorityData(): void {
        this.errors = null;
        this.updateConfig({} as unknown as ISSLCertificateConfig);
    }

    /**
     * Resolves Certificate Authority(CA) file content.
     */
    public async resolveCertificateAuthority(content: string): Promise<ISSLCertificate> {
        this.busy = true;
        this.errors = null;

        this.httpWrapper.cancelRequest(RESOLVE_CA_REQUEST_ID);

        const payload = {
            body: btoa(content),
        };

        try {
            const requestConfig = {
                url: PRINT_SSL_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: RESOLVE_CA_REQUEST_ID,
            };

            const { data: sslCertificate = {} } = await this.httpWrapper.request(requestConfig);

            return sslCertificate;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Called to destroy and cancel all pending requests.
     * @override
     */
    public destroy(): void {
        this.httpWrapper.cancelAllRequests();

        super.destroy();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.addCaModalBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'subject',
        ];
    }
}

SSLCertificateConfigItem.ajsDependencies = [
    'HttpWrapper',
    'l10nService',
];
