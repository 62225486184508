/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './repeated-inputs.less';

import * as template from './repeated-inputs.partial.html';

import * as l10n from './repeatedInputs.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @name repeatedInputsDirective
 * @memberOf module:avi/component-kit
 * @restrict E
 * @desc
 *
 *     Used for input fields that are mapped to a repeated list
 *     in the protobuf, and '+/-' are desired.
 */
function repeatedInputsDirectiveFactory(l10nService) {
    /**
     * For undefined or array with zero length we gonna create an array with one empty
     * string value.
     * modelValue > viewValue
     * @param {string[]|undefined} modelVal
     * @returns {string[]}
     * @inner
     */
    function formatItemsList(modelVal) {
        const viewVal = [];

        if (!modelVal || !Array.isArray(modelVal) || !modelVal.length) {
            viewVal.push('');
        } else {
            viewVal.push(...modelVal);
        }

        return viewVal;
    }

    function repeatedInputsLink($scope, elm, attr, ngModelCtrl) {
        ngModelCtrl.$isEmpty = viewValue => !viewValue.length;

        l10nService.registerSourceBundles(dictionary);

        $scope.l10nKeys = l10nKeys;

        /**
         * Parser function that replaces undefined values with empty strings, since we don't want
         * the undefined values to be converted to null values in the save request.
         * @param {string[]|undefined} values
         * @inner
         * @returns {string[]|undefined}
         */
        function parseUndefinedValues_(values) {
            if (values) {
                return values.map(value => (_.isUndefined(value) ? '' : value));
            }
        }

        ngModelCtrl.$formatters.push(formatItemsList);
        ngModelCtrl.$parsers.push(parseUndefinedValues_);

        $scope.ngModelCtrl = ngModelCtrl;

        attr.$observe('required', required => {
            $scope.required = required;
        });

        $scope.placeholder = attr.placeholder || '';

        $scope.allowSingleInputDelete = $scope.allowSingleInputDelete || false;

        /**
         * Called when '+' button is clicked. Adds input field.
         * @public
         **/
        $scope.addInput = function() {
            ngModelCtrl.$setViewValue(
                ngModelCtrl.$viewValue.concat(''),
            );
        };

        /**
         * Called when '-' button is clicked. Removes input field.
         * @param {number} index - Index of element where '-' button clicked.
         * @public
         */
        $scope.removeInput = function(index) {
            const newValue = ngModelCtrl.$viewValue.concat();

            newValue.splice(index, 1);
            ngModelCtrl.$setViewValue(newValue);
        };

        /**
         * ngChange event listener for every repeated input.
         * @param {number} index - Index of the changed element.
         * @public
         */
        $scope.particularValueChange = function(index) {
            ngModelCtrl.$setViewValue(ngModelCtrl.$viewValue.concat());
        };
    }

    return {
        scope: {
            inputPattern: '<',
            maxInputs: '@',
            ngDisabled: '<',
            itemName: '@',
            // when there's only one input entry, it's not allowed to be deleted by default
            // setting this flag to true will enable this delete operation
            allowSingleInputDelete: '<?',
        },
        require: 'ngModel',
        link: repeatedInputsLink,
        template,
    };
}

angular.module('avi/component-kit')
    .directive('repeatedInputs', ['l10nService', repeatedInputsDirectiveFactory]);
