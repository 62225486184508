/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    isNaN,
    max,
} from 'underscore';

function staticRouteCollectionFactory(Collection, StaticRoute) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.StaticRoute StaticRoutes}.
     */
    class StaticRouteCollection extends Collection {
        constructor(args) {
            super(args);
            this.vrf = args && args.vrf || 'global';
            this.bind('collItemSave', function() {
                this.load();
            }.bind(this));
        }

        getNewIndex() {
            let res = 1;

            if (this.getNumberOfItems()) {
                // Get items only with route_id as number.
                const itemsWithIntegerId = StaticRouteCollection.filterRoutesWithIntegerId(
                    this.items,
                );

                // Check if any item exists with numerical id else keep route_id as 1.
                if (itemsWithIntegerId.length) {
                    // Get Route holding maximum route_id.
                    const row = max(itemsWithIntegerId, row => {
                        return +row.routeId;
                    });

                    if (row) {
                        res = parseInt(row.routeId, 10) + 1;
                    }
                }
            }

            return `${res}`;
        }

        /**
         * Filters items with Integer route IDs from the list of routes.
         * @param  {IGridRow} items - row items from grid.
         * @returns {items[]} Array with items having integer route_id.
         */
        static filterRoutesWithIntegerId(items) {
            return items.filter(
                item => !isNaN(+item.routeId),
            );
        }

        save(route) {
            const data = _.pluck(this.items, 'data');
            const entireObject = angular.copy(this.entireObject);
            const item = _.find(data, function(item) {
                return item.config.route_id == route.route_id;
            });

            if (item) { // update the entry
                angular.copy(route, item.config);
            } else { // push new entry
                data.push({ config: route });
            }

            entireObject.static_routes = _.pluck(data, 'config');

            return this.request('put', entireObject.url, entireObject).then(function() {
                this.entireObject.static_routes = data;

                return route;
            }.bind(this));
        }

        drop(routeId) {
            if (routeId) {
                this.entireObject.static_routes =
                _.reject(this.entireObject.static_routes, function(route) {
                    return route.route_id === routeId;
                });
            }

            return this.request('put', this.entireObject.url, this.entireObject)
                .then(function() {
                    return this.load();
                }.bind(this));
        }
    }

    const allDataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'StaticRouteListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(StaticRouteCollection.prototype, {
        objectName_: 'vrfcontext',
        itemClass_: StaticRoute,
        windowElement_: 'infra-staticroute-create',
        defaultDataSources_: 'list',
        allDataSources_: allDataSources,
        restrictCreateOnEssentialLicense_: false,
    });

    return StaticRouteCollection;
}

staticRouteCollectionFactory.$inject = [
    'Collection',
    'StaticRoute',
];

angular.module('avi/app')
    .factory('StaticRouteCollection', staticRouteCollectionFactory);
