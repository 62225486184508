/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories';
import { IAlertSyslogServer } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import {
    AlertSyslogServerModalComponent,
} from 'ng/modules/alert/components/alert-syslog-server-modal/alert-syslog-server-modal.component';
import { AlertSyslogServer } from 'object-types';

/**
 * @description Syslog servers config Item.
 *
 * @author Guru Prasad
 */
export class AlertSyslogServerConfigItem extends
    withFullModalMixin(MessageItem)<IAlertSyslogServer> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AlertSyslogServer,
            windowElement: AlertSyslogServerModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
    }
}
