/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'dns-resolver-nameservers-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const addressInputPlaceholder = `${prefix}.addressInputPlaceholder`;
export const columnTitleAddress = `${prefix}.columnTitleAddress`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [addressInputPlaceholder]: 'Enter Name Server IP address',
    [columnTitleAddress]: 'Name Server IP',
    [removeButtonLabel]: 'Remove',
};
