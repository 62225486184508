/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gslb-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const leaderLabel = `${prefix}.leaderLabel`;
export const activeLabel = `${prefix}.activeLabel`;
export const passiveLabel = `${prefix}.passiveLabel`;
export const disabledLabel = `${prefix}.disabledLabel`;
export const currentLabel = `${prefix}.currentLabel`;
export const columnTitleIpAdress = `${prefix}.columnTitleIpAdress`;
export const columnTitlePort = `${prefix}.columnTitlePort`;
export const columnTitleUsername = `${prefix}.columnTitleUsername`;
export const columnTitleSiteStatus = `${prefix}.columnTitleSiteStatus`;
export const columnTitleSwVersion = `${prefix}.columnTitleSwVersion`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;
export const actionBtnEnable = `${prefix}.actionBtnEnable`;
export const actionBtnDisable = `${prefix}.actionBtnDisable`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const columnTitleReplication = `${prefix}.columnTitleReplication`;
export const actionBtnKickoff = `${prefix}.actionBtnKickoff`;
export const columnTitleEnabled = `${prefix}.columnTitleEnabled`;
export const confirmMessage = `${prefix}.confirmMessage`;
export const gslbOffHeader = `${prefix}.gslbOffHeader`;
export const addNewSiteBtnLabel = `${prefix}.addNewSiteBtnLabel`;
export const add3rdPartySiteBtnLabel = `${prefix}.add3rdPartySiteBtnLabel`;
export const subdomainsDelegatedToGslbHeader = `${prefix}.subdomainsDelegatedToGslbHeader`;
export const emptySubdomainsSelectedLabel = `${prefix}.emptySubdomainsSelectedLabel`;
export const activeMembersHeader = `${prefix}.activeMembersHeader`;
export const replicationPolicyModeHeader = `${prefix}.replicationPolicyModeHeader`;
export const passiveMembersHeader = `${prefix}.passiveMembersHeader`;
export const thirdPartySitesHeader = `${prefix}.thirdPartySitesHeader`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [leaderLabel]: 'Leader',
    [activeLabel]: 'Active',
    [passiveLabel]: 'Passive',
    [disabledLabel]: '{0}, Disabled',
    [currentLabel]: '{0} (current)',
    [columnTitleIpAdress]: 'IP Address',
    [columnTitlePort]: 'Port',
    [columnTitleUsername]: 'Username',
    [columnTitleSiteStatus]: 'Site Status',
    [columnTitleSwVersion]: 'SW Version',
    [actionBtnDelete]: 'Delete',
    [actionBtnEnable]: 'Enable',
    [actionBtnDisable]: 'Disable',
    [actionBtnEdit]: 'Edit',
    [columnTitleReplication]: 'Replication',
    [actionBtnKickoff]: 'Kickoff Replication',
    [columnTitleEnabled]: 'Enabled',
    [confirmMessage]: 'GSLB Site configuration will be removed. Do you want to continue?',
    [gslbOffHeader]: 'GSLB: <strong>Off</strong>',
    [addNewSiteBtnLabel]: 'Add New Site',
    [add3rdPartySiteBtnLabel]: 'Add Third-party Site',
    [subdomainsDelegatedToGslbHeader]: 'Subdomains delegated to GSLB:',
    [emptySubdomainsSelectedLabel]: 'No subdomains selected',
    [activeMembersHeader]: 'Active Members',
    [replicationPolicyModeHeader]: '({0} Replication)',
    [passiveMembersHeader]: 'Passive Members',
    [thirdPartySitesHeader]: 'Third-party Sites',
};
