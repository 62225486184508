/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { isUndefined } from 'underscore';
import { Auth, AUTH_SERVICE_TOKEN } from 'ajs/modules/core/services/auth';
import {
    ObjectTypeItem,
    PatchAction,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { AviPermissionResource, IVrfContext } from 'generated-types';
import { VrfContext } from 'object-types';
import { BgpProfileConfigItem } from './bgp-profile.config-item.factory';

// Cannot extend IVrfContext as it is since
// bgp_profile and BgpProfileConfigItem are incompatible.
// so omitting it.
type TVrfContextPartial = Omit<IVrfContext, 'bgp_profile'>;

interface IVrfContextConfig extends TVrfContextPartial {
    bgp_profile?: BgpProfileConfigItem;
}

interface IVrfContextData {
    config: IVrfContextConfig;
}

/**
 * Ajs dependency token for VRFContext.
 */
export const VRF_CONTEXT_TOKEN = 'VRFContext';

/**
 * @description
 *
 *   VRF Context ObjectTypeItem.
 *
 * @author Aravindh Nagarajan
 */
export class VRFContext extends withEditChildMessageItemMixin(ObjectTypeItem) {
    public data: IVrfContextData;
    public getConfig: () => IVrfContextConfig;
    private readonly auth: Auth;

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'vrfcontext',
            objectType: VrfContext,
            permissionName: AviPermissionResource.PERMISSION_VRFCONTEXT,
            restrictEditOnEssentialLicense: false,
            whitelistedFields: [
                'bgp_profile',
            ],
        };

        super(extendedArgs);

        this.auth = this.getAjsDependency_(AUTH_SERVICE_TOKEN);
    }

    /** @override */
    public dataToSave(): IVrfContext {
        const config: IVrfContext = super.dataToSave();
        const { bgp_profile: bgpProfile } = config;

        if (bgpProfile && isUndefined(bgpProfile.local_as)) {
            delete config.bgp_profile;
        }

        return config;
    }

    /**
     * Returns true if VRF Context is editable, false otherwise (for
     * global and management VRFs).
     * @override
     */
    public isEditable(): boolean {
        return !this.isProtected() && this.auth.hasTenantVrf && super.isEditable();
    }

    /**
     * Getter for BGP Profile.
     */
    public get bgpProfile(): BgpProfileConfigItem {
        return this.getConfig().bgp_profile;
    }

    /**
     * Getter for BGP Profile.
     */
    public set bgpProfile(bgpProfile: BgpProfileConfigItem) {
        this.getConfig().bgp_profile = bgpProfile;
    }

    /**
     * Opens BGP edit modal.
     */
    public editBgpProfile(): void {
        this.editChildMessageItem({
            field: 'bgp_profile',
            messageItem: this.bgpProfile,
            modalBindings: {
                vrfContextName: this.getName(),
                vrfContextId: this.id,
                cloudId: this.cloudId,
            },
        }, true);
    }

    /**
     * Disables/removes bgp_profile.
     */
    public deactivateBgpProfile(): ng.IPromise<ng.IHttpResponse<void>> {
        return this.patch({
            [PatchAction.DELETE]: {
                bgp_profile: null,
            },
        });
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'bgp_profile',
        ];
    }

    /**
     * Getter for cloud uuid.
     */
    private get cloudId(): string {
        const { cloud_ref: cloudRef } = this.getConfig();

        return this.stringService.slug(cloudRef);
    }
}

VRFContext.ajsDependencies = [
    AUTH_SERVICE_TOKEN,
];
