/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    AzureServicePrincipalCredentialsConfigItem,
    AzureUserPassCredentialsConfigItem,
    CloudConnectorUser,
    GCPCredentialsConfigItem,
} from 'ajs/modules/cloud-connector-user';

import { ClrFormLayout } from '@clr/angular';

import { createDropdownOption } from 'ng/utils/dropdown.utils';

import { L10nService } from '@vmw/ngx-vip';

import {
    AZURE_AUTHENTICATION_TYPE,
    CREDENTIALS_TYPE,
    SSH_AUTHENTICATION_TYPE,
} from '../../cloud-connector-user.types';

import { getTypeLabel } from '../../cloud-connector-user.utils';

import * as l10n from './user-credentials-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum SSH_KEY_TYPE {
    GENERATE = 'GENERATE',
    IMPORT = 'IMPORT',
}

enum CONFIGURATION_STATE {
    GENERATE = 'GENERATE',
    IMPORT = 'IMPORT',
    DEFAULT = 'DEFAULT',
}

const submitLabelHash = {
    [CONFIGURATION_STATE.GENERATE]: 'Generate & Save',
    [CONFIGURATION_STATE.IMPORT]: 'Save',
    [CONFIGURATION_STATE.DEFAULT]: 'Save',
};

/**
 * List of ordered credentials types.
 */
const credentialsTypes = [
    CREDENTIALS_TYPE.SSH,
    CREDENTIALS_TYPE.AZURE,
    CREDENTIALS_TYPE.GCP,
    CREDENTIALS_TYPE.NSXT,
    CREDENTIALS_TYPE.VCENTER,
];

/**
 * @description Component for the CloudConnectorUser modal.
 * @author alextsg
 */
@Component({
    selector: 'user-credentials-modal',
    templateUrl: './user-credentials-modal.component.html',
})
export class UserCredentialsModalComponent implements OnInit {
    /**
     * CloudConnectorUser ObjectTypeItem.
     */
    @Input() public editable: CloudConnectorUser;

    /**
     * If overrideCredentialsType is passed in as a binding, this.credentialsType is set to that
     * type and the user is prevented from changing it.
     */
    @Input() public overrideCredentialsType: CREDENTIALS_TYPE;

    /**
     * Used to determine the type of credentials used.
     */
    public credentialsType: CREDENTIALS_TYPE = CREDENTIALS_TYPE.SSH;

    /**
     * Used to determine the type of authentication for SSH credentials.
     */
    public sshAuthenticationType: SSH_AUTHENTICATION_TYPE = SSH_AUTHENTICATION_TYPE.SSH_KEY;

    /**
     * Used to determine the type of keys for SSH key authentication.
     */
    public sshKeyType: SSH_KEY_TYPE = SSH_KEY_TYPE.GENERATE;

    /**
     * Used to determine the type of authentication for Azure credentials.
     */
    public azureAuthenticationType: AZURE_AUTHENTICATION_TYPE = AZURE_AUTHENTICATION_TYPE.USER_PASS;

    /**
     * Layout for clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * List of credentials used for the radio buttons for credentials type selection.
     */
    public credentialsTypeOptions = credentialsTypes.map(type => createDropdownOption(
        type,
        getTypeLabel(type),
    ));

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { AZURE, SSH } = CREDENTIALS_TYPE;
        const { SERVICE_PRINCIPAL } = AZURE_AUTHENTICATION_TYPE;

        if (this.overrideCredentialsType) {
            this.credentialsType = this.overrideCredentialsType;
            this.setType();
        } else if (this.isEditing()) {
            const type = this.editable.getType();

            this.credentialsType = type;

            switch (type) {
                case AZURE:
                    if (this.editable.hasCredentials(AZURE, SERVICE_PRINCIPAL)) {
                        this.azureAuthenticationType = SERVICE_PRINCIPAL;
                    }

                    break;
                case SSH:
                    if (this.editable.hasCredentials(SSH, SSH_AUTHENTICATION_TYPE.USER_PASS)) {
                        this.sshAuthenticationType = SSH_AUTHENTICATION_TYPE.USER_PASS;
                    }

                    break;
            }
        }
    }

    /**
     * Returns the current state of the configuration.
     */
    public get configurationState(): CONFIGURATION_STATE {
        switch (true) {
            case this.isSshKeyGeneration():
                return CONFIGURATION_STATE.GENERATE;

            case this.isSshKeyAuthentication():
            case this.credentialsType === CREDENTIALS_TYPE.GCP:
                return CONFIGURATION_STATE.IMPORT;

            default:
                return CONFIGURATION_STATE.DEFAULT;
        }
    }

    /**
     * Returns the AzureUserPassCredentialsConfigItem.
     */
    public get azureUserpass(): AzureUserPassCredentialsConfigItem {
        return this.editable.config.azure_userpass;
    }

    /**
     * Returns the AzureUserPassCredentialsConfigItem.
     */
    public get azureServicePrincipal(): AzureServicePrincipalCredentialsConfigItem {
        return this.editable.config.azure_serviceprincipal;
    }

    /**
     * Returns the GCPCredentialsConfigItem.
     */
    public get gcpCredentials(): GCPCredentialsConfigItem {
        return this.editable.config.gcp_credentials;
    }

    /**
     * Returns true if the user is editing the object rather than creating a new one.
     */
    public isEditing(): boolean {
        return Boolean(this.editable.id);
    }

    /**
     * Returns true if the type should not be changeable.
     */
    public disableTypeChange(): boolean {
        return this.isEditing() || Boolean(this.overrideCredentialsType);
    }

    /**
     * Returns the label for the submit button.
     */
    public getSubmitLabel(): string {
        return submitLabelHash[this.configurationState];
    }

    /**
     * Called when the credentials type or authentication type is changed.
     */
    public setType(): void {
        this.editable.setType(this.credentialsType);
    }

    /**
     * Called when the submit button is clicked.
     */
    public handleSubmit(): void {
        switch (this.configurationState) {
            case CONFIGURATION_STATE.GENERATE:
                this.editable.generateKey();
                break;

            case CONFIGURATION_STATE.IMPORT:
                this.editable.importKey();
                break;

            default:
                this.editable.submit();
        }
    }

    /**
     * Returns true if the current configuration is SSH Key.
     */
    private isSshKeyAuthentication(): boolean {
        return this.credentialsType === CREDENTIALS_TYPE.SSH &&
            this.sshAuthenticationType === SSH_AUTHENTICATION_TYPE.SSH_KEY;
    }

    /**
     * Returns true if the current configuration is SSH Key with Key Generation.
     */
    private isSshKeyGeneration(): boolean {
        return this.isSshKeyAuthentication() && this.sshKeyType === SSH_KEY_TYPE.GENERATE;
    }
}
