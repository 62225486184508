/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { CloudType } from 'generated-types';
import { VipConfigItem } from 'ajs/modules/vs';
import { StringService } from 'ajs/modules/core/services/string-service';
import { attachComponentBindings } from 'ng/shared/utils';
import { GenericVipConfigComponent } from './generic-vip-config';
import { AwsVipConfigComponent } from './aws-vip-config';
import { VcenterVipConfigComponent } from './vcenter-vip-config';
import { AzureVipConfigComponent } from './azure-vip-config';
import { GcpVipConfigComponent } from './gcp-vip-config';
import { OpenStackVipConfigComponent } from './openstack-vip-config';
import { NsxtVipConfigComponent } from './nsxt-vip-config';

import * as l10n from './vip-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Vip modal component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vip-modal',
    templateUrl: './vip-modal.component.html',
})
export class VipModalComponent implements OnInit {
    /**
     * VipConfigItem instance.
     */
    @Input()
    public editable: VipConfigItem;

    @Input()
    public vipCreateParams: Record<string, any> = {};

    /**
     * Url of cloud - used to load networks.
     */
    @Input()
    public cloudRef: string;

    /**
     * Url of vrfContextRef - used to load networks.
     */
    @Input()
    public vrfContextRef: string;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<VipConfigItem>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * TemplateRef for vipConfigComponent - To be rendered based on cloud type.
     */
    @ViewChild('vipConfigComponentRef', {
        read: ViewContainerRef,
        static: true,
    })
    public vipConfigComponentRef: ViewContainerRef;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of cloudTypes and its corresponding vipConfigComponent.
     */
    private readonly vipConfigComponentHash = {
        [CloudType.CLOUD_AWS]: AwsVipConfigComponent,
        [CloudType.CLOUD_NONE]: GenericVipConfigComponent,
        [CloudType.CLOUD_LINUXSERVER]: GenericVipConfigComponent,
        [CloudType.CLOUD_VCENTER]: VcenterVipConfigComponent,
        [CloudType.CLOUD_AZURE]: AzureVipConfigComponent,
        [CloudType.CLOUD_GCP]: GcpVipConfigComponent,
        [CloudType.CLOUD_OPENSTACK]: OpenStackVipConfigComponent,
        [CloudType.CLOUD_NSXT]: NsxtVipConfigComponent,
    };

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly stringService: StringService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.renderVipConfigComponent();
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable as unknown as VipConfigItem);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Renders vipConfig component based on cloudType.
     */
    private renderVipConfigComponent(): void {
        const {
            cloudType,
            isVrfOptional,
        } = this.vipCreateParams;

        const {
            editable,
            vipConfigComponentHash,
            vipConfigComponentRef,
        } = this;

        const vipConfigComponent = vipConfigComponentHash[cloudType] as Type<Component>;

        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(vipConfigComponent);

        const componentRef = componentFactory.create(vipConfigComponentRef.injector);

        const vrfContextId = isVrfOptional ? '' : this.stringService.slug(this.vrfContextRef);

        attachComponentBindings(componentRef, {
            cloudId: this.stringService.slug(this.cloudRef),
            editable,
            vrfContextId,
            ...this.vipCreateParams,
        });

        vipConfigComponentRef.insert(componentRef.hostView);
    }
}
