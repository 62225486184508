/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { isUndefined } from 'underscore';
import { IWafRuleGroupOverrides } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { WafRuleGroupModalComponent } from 'ng/modules/waf';
import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { withFullModalMixin } from 'ajs/utils/mixins';
import { WafExcludeListEntryConfigItem } from './waf-exclude-list-entry.config-item.factory';
import { WafRuleOverridesConfigItem } from './waf-rule-overrides.config-item.factory';
import { WafRuleConfigItem } from './waf-rule.config-item.factory';
import * as l10n from '../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafRuleGroupOverridesPartial = Omit<
IWafRuleGroupOverrides, 'exclude_list' | 'rule_overrides'
>;

interface IWafRuleGroupOverridesConfig extends TWafRuleGroupOverridesPartial {
    exclude_list?: RepeatedMessageItem<WafExcludeListEntryConfigItem>;
    rule_overrides?: RepeatedMessageItem<WafRuleOverridesConfigItem>;
}

export const WAF_RULE_GROUP_OVERRIDES_CONFIG_ITEM_TOKEN = 'WafRuleGroupOverridesConfigItem';

export class WafRuleGroupOverridesConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IWafRuleGroupOverridesConfig, typeof MessageItem>(MessageItem),
)<IWafRuleGroupOverridesConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    private ruleOverridesMap: Map<string, WafRuleOverridesConfigItem>;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafRuleGroupOverrides',
            windowElement: WafRuleGroupModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        this.ruleOverridesMap = new Map();
        this.ruleOverrides.config.forEach(ruleOverride => {
            this.ruleOverridesMap.set(ruleOverride.getId(), ruleOverride);
        });
    }

    /**
     * Returns the name of this group. Since this is an override, needs to be unique and match an
     * existing CRS rule.
     */
    public getName(): string {
        return this.config.name;
    }

    /**
     * Returns true if this group is enabled.
     */
    public isEnabled(): boolean {
        return Boolean(this.enable);
    }

    /**
     * Getter for the override enable flag. Can be undefined if not configured.
     */
    public get enable(): boolean | undefined {
        return this.config.enable;
    }

    /**
     * Setter for the override enable flag.
     */
    public set enable(enable) {
        this.config.enable = enable;
    }

    /**
     * Returns the WafRuleOverrides RepeatedMessageItem.
     */
    public get ruleOverrides(): RepeatedMessageItem<WafRuleOverridesConfigItem> {
        return this.config.rule_overrides;
    }

    /**
     * Returns the excludeList repeatedMessageItem.
     */
    public get excludeList(): RepeatedMessageItem<WafExcludeListEntryConfigItem> {
        return this.config.exclude_list;
    }

    /**
     * Creates a new location and opens the modal to edit it.
     */
    public addExcludeListEntryMessageItem(modalBindings?: Record<string, any>): void {
        this.addChildMessageItem({
            field: 'exclude_list',
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.addExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Edits a WafPSMLocation config item.
     */
    public editExcludeListEntryMessageItem(
        entry: WafExcludeListEntryConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        this.editChildMessageItem({
            field: 'exclude_list',
            messageItem: entry,
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.editExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Removes a WafExcludeListEntry message item from the exclude list.
     */
    public removeExcludeListEntry(entry: WafExcludeListEntryConfigItem): void {
        this.excludeList.removeByMessageItem(entry);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafGroupBreadcrumbTitle);
    }

    /**
     * Returns the WafRuleOverridesConfigItem instance given a rule ID.
     */
    public getRuleOverrideByID(id: string): WafRuleOverridesConfigItem {
        return this.ruleOverridesMap.get(id);
    }

    /**
     * Called to add a rule override.
     */
    public addRuleOverride(
        rule: WafRuleConfigItem,
        modalBindings?: Record<string, any>,
    ): Promise<WafRuleOverridesConfigItem> {
        const {
            enable,
            mode,
            rule_id: ruleID,
        } = rule.getConfig();

        const newConfig = {
            mode,
            enable,
            rule_id: ruleID,
        };

        return this.editRuleOverride(
            this.createRuleOverride(newConfig),
            rule,
            modalBindings,
        );
    }

    /**
     * Called to edit an existing rule override.
     */
    public editRuleOverride(
        ruleOverride: WafRuleOverridesConfigItem,
        originalRule: WafRuleConfigItem,
        modalBindings?: Record<string, any>,
    ): Promise<WafRuleOverridesConfigItem> {
        const { enable, name, rule } = originalRule.config;

        // If the override's enable flag is undefined, set it to the original rule's enable value.
        if (isUndefined(ruleOverride.enable)) {
            ruleOverride.enable = enable;
        }

        return this.editChildMessageItem({
            field: 'rule_overrides',
            messageItem: ruleOverride,
            modalBindings: {
                modalHeaderKey: l10nKeys.editCrsRuleModalHeader,
                crsRuleName: name,
                crsRuleString: rule,
                ...modalBindings,
            },
        }).then((editedRuleOverride: WafRuleOverridesConfigItem) => {
            this.ruleOverridesMap.set(ruleOverride.getId(), editedRuleOverride);

            return editedRuleOverride;
        });
    }

    /**
     * Called to remove an existing rule override.
     */
    public removeRuleOverride(ruleOverride: WafRuleOverridesConfigItem): void {
        this.ruleOverrides.removeByMessageItem(ruleOverride);
        this.ruleOverridesMap.delete(ruleOverride.getId());
    }

    /**
     * Returns a new WafRuleOverridesConfigItem instance.
     */
    public createRuleOverride(config: Record<string, any> = null): WafRuleOverridesConfigItem {
        return this.createChildByField(
            'rule_overrides',
            config,
            true,
        ) as WafRuleOverridesConfigItem;
    }

    /**
     * Checks if any override exists for WafRuleGroupOverridesConfigItem.
     */
    public isEmpty(): boolean {
        const {
            rule_overrides: ruleOverrides,
            enable,
            exclude_list: excludeList,
            mode,
        } = this.config;

        return (
            ruleOverrides.isEmpty() &&
            isUndefined(enable) &&
            excludeList.isEmpty() &&
            isUndefined(mode)
        );
    }
}
