/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'policy-grid-prompt-index';
const prefix = `${moduleName}.${componentName}`;

export const moveRuleHeader = `${prefix}.moveRuleHeader`;
export const createNewRuleHeader = `${prefix}.createNewRuleHeader`;
export const endListRadioLabel = `${prefix}.endListRadioLabel`;
export const specifiedIndexRadioLabel = `${prefix}.specifiedIndexRadioLabel`;
export const placementLabel = `${prefix}.placementLabel`;
export const aboveLabel = `${prefix}.aboveLabel`;
export const belowLabel = `${prefix}.belowLabel`;
export const actualIndexLabel = `${prefix}.actualIndexLabel`;
export const indexLabel = `${prefix}.indexLabel`;
export const createBtnLabel = `${prefix}.createBtnLabel`;
export const moveBtnLabel = `${prefix}.moveBtnLabel`;
export const indexInputPlaceholder = `${prefix}.indexInputPlaceholder`;

export const ENGLISH = {
    [moveRuleHeader]: 'Move rule "{0}"',
    [createNewRuleHeader]: 'Create new rule',
    [endListRadioLabel]: 'at the end of the list.',
    [specifiedIndexRadioLabel]: 'at a specified index.',
    [placementLabel]: 'Placement',
    [aboveLabel]: 'Above',
    [belowLabel]: 'Below',
    [actualIndexLabel]: 'Actual index of the rule, not the position within the list.',
    [indexLabel]: 'Index',
    [createBtnLabel]: 'Create',
    [moveBtnLabel]: 'Move',
    [indexInputPlaceholder]: 'Select Index',
};
