/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'host-servers-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleNew = `${prefix}.modalTitleNew`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const hostIpAddressLabel = `${prefix}.hostIpAddressLabel`;
export const hostIpAddressPlaceholder = `${prefix}.hostIpAddressPlaceholder`;
export const serviceEngineGroupLabel = `${prefix}.serviceEngineGroupHeader`;
export const serviceEngineGroupPlaceholder = `${prefix}.serviceEngineGroupPlaceholder`;
export const serviceEngineGroupHelperText = `${prefix}.serviceEngineGroupHelperText`;
export const enableDpdkLabel = `${prefix}.enableDpdkLabel`;
export const enableInbandManagementLabel = `${prefix}.enableInbandManagementLabel`;
export const coreServiceEngineNumberLabel = `${prefix}.coreServiceEngineNumberLabel`;
export const allLabel = `${prefix}.allLabel`;
export const customLabel = `${prefix}.customLabel`;
export const coresLabel = `${prefix}.coresLabel`;
export const coreNumberPlaceholder = `${prefix}.coreNumberPlaceholder`;
export const serviceEngineMemoryAmountLabel = `${prefix}.serviceEngineMemoryAmountLabel`;
export const memoryLabel = `${prefix}.memoryLabel`;
export const serviceEngineMemoryAmountPlaceholder = `${prefix}.serviceEngineMemoryAmountPlaceholder`;
export const serviceEngineMemoryAmountHelperText = `${prefix}.serviceEngineMemoryAmountHelperText`;
export const verifyHostMenuLabel = `${prefix}.verifyHostMenuLabel`;
export const cleanUpMenuLabel = `${prefix}.cleanUpMenuLabel`;

export const ENGLISH = {
    [modalTitleNew]: 'Add Server',
    [modalTitleEdit]: 'Edit Server',
    [generalSectionTitle]: 'General',
    [hostIpAddressLabel]: 'Host IP Address',
    [hostIpAddressPlaceholder]: 'Enter IP Address',
    [serviceEngineGroupLabel]: 'Service Engine Group',
    [serviceEngineGroupPlaceholder]: 'Select Service Engine Group',
    [serviceEngineGroupHelperText]: 'The above service engine group can be set once the cloud is created.',
    [enableDpdkLabel]: 'Enable DPDK',
    [enableInbandManagementLabel]: 'Enable Inband Management',
    [coreServiceEngineNumberLabel]: 'Number of Cores for Service Engines',
    [allLabel]: 'All',
    [customLabel]: 'Custom',
    [coresLabel]: 'Cores',
    [coreNumberPlaceholder]: 'Enter Number Cores',
    [serviceEngineMemoryAmountLabel]: 'Amount of memory for Service Engines',
    [memoryLabel]: 'Memory',
    [serviceEngineMemoryAmountPlaceholder]: 'Enter Memory Amount',
    [serviceEngineMemoryAmountHelperText]: 'Gigabytes',
    [verifyHostMenuLabel]: 'Verify Host',
    [cleanUpMenuLabel]: 'Clean Up',
};
