/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-allow-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const noNameLabel = `${prefix}.noNameLabel`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const matchSectionTitle = `${prefix}.matchSectionTitle`;
export const actionSectionTitle = `${prefix}.actionSectionTitle`;

export const nameLabel = `${prefix}.nameLabel`;
export const enterNamePlaceholder = `${prefix}.enterNamePlaceholder`;
export const clientIpAddressLabel = `${prefix}.clientIpAddressLabel`;
export const httpMethodLabel = `${prefix}.httpMethodLabel`;
export const pathLabel = `${prefix}.pathLabel`;
export const hostHeaderLabel = `${prefix}.hostHeaderLabel`;
export const botActionLabel = `${prefix}.botActionLabel`;
export const selectBotActionLabel = `${prefix}.selectBotActionLabel`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create Allow Rule: {0}',
    [modalTitleEdit]: 'Edit Allow Rule: {0}',
    [noNameLabel]: 'No Name',
    [generalSectionTitle]: 'General',
    [matchSectionTitle]: 'Match',
    [actionSectionTitle]: 'Action',

    [nameLabel]: 'Name',
    [enterNamePlaceholder]: 'Enter Name',
    [clientIpAddressLabel]: 'Client IP Address',
    [httpMethodLabel]: 'HTTP Method',
    [pathLabel]: 'Path',
    [hostHeaderLabel]: 'Host Header',
    [botActionLabel]: 'Bot Action',
    [selectBotActionLabel]: 'Select Bot Action',
};
