/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * This file to be used for exporting token services names to be provided in accounts module
 */

export const ROLE_SERVICE_TOKEN = 'roleService';
export const TENANT_SETTINGS_SERVICE_TOKEN = 'TenantSettingsService';
