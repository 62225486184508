/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Cloud } from 'object-types';
import { SEGroupCollection } from 'ajs/modules/service-engine-group';
import * as l10n from './service-engine-configuration.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSeGroupCollection = typeof SEGroupCollection;

/**
 * @description Component for Service Engine configuration.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'service-engine-configuration',
    templateUrl: './service-engine-configuration.component.html',
})
export class ServiceEngineConfigurationComponent implements OnInit, OnDestroy {
    /**
     * Model value passed as an input from Cloud Modal.
     */
    @Input()
    public serviceEngineGroupRef: string;

    /**
     * Id passed as an input from Cloud Modal.
     * To be used for invoking Service Engine Group Collection.
     */
    @Input()
    public cloudId: string;

    /**
     * Passed as an input from Cloud Modal.
     * This flag is used to disable Template Service Engine Group dropdown,
     * if cloud is in create mode.
     */
    @Input()
    public isEditing = false;

    /**
     * Event Emitter for when user selects Service Engine Group template Ref from dropdown.
     */
    @Output()
    public serviceEngineGroupRefChange = new EventEmitter<string | undefined>();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SEGroupCollection instance.
     */
    public readonly seGroupCollection: SEGroupCollection;

    /**
     * Holds the Object type to be used in the template.
     */
    public objectType = Cloud;

    constructor(
        @Inject(SEGroupCollection)
        private readonly SEGroupCollection: TSeGroupCollection,
        l10nService: L10nService,
    ) {
        this.seGroupCollection = new this.SEGroupCollection({
            isStatic: true,
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.seGroupCollection.setParams({
            'cloud_ref.uuid': this.cloudId,
        });
    }

    /**
     * Emits an event when user selects the Service Engine Group Template Ref from dropdown.
     */
    public handleUpdate(): void {
        this.serviceEngineGroupRefChange.emit(this.serviceEngineGroupRef);
    }

    /**
     * @override
     * Destroys Service Engine Group Collection
     */
    public ngOnDestroy(): void {
        this.seGroupCollection.destroy();
    }
}
