/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';
import './avi-checkbox-container-group.component.less';

/**
 * @description Component to style clr-checkbox-container elements grouped together.
 * @author alextsg
 */
@Component({
    selector: 'avi-checkbox-container-group',
    templateUrl: './avi-checkbox-container-group.component.html',
})
export class AviCheckboxContainerGroupComponent {}
