/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'server-auto-scale-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleMinSize = `${prefix}.columnTitleMinSize`;
export const columnTitleMaxSize = `${prefix}.columnTitleMaxSize`;
export const columnTitleIntelligentAutoScale = `${prefix}.columnTitleIntelligentAutoScale`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const enabledDataLabel = `${prefix}.enabledDataLabel`;
export const disabledDataLabel = `${prefix}.disabledDataLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleMinSize]: 'Min Size',
    [columnTitleMaxSize]: 'Max Size',
    [columnTitleIntelligentAutoScale]: 'Intelligent AutoScale',
    [emptyDataLabel]: 'None',
    [enabledDataLabel]: 'Enabled',
    [disabledDataLabel]: 'Disabled',
};
