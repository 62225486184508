/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { contains, isEmpty } from 'underscore';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IpAddrMatchConfigItem } from 'ajs/modules/match';
import {
    PolicyMatchConfigItem,
} from '../config-items/policy-match.config-item.factory';

/**
 * Supported match types for NatMatch message item.
 */
const supportedMatches = [
    'source_ip',
    'destination_ip',
    'source_port',
    'destination_port',
    'protocol',
];

/**
 * Match fields under serviceMatch.
 */
const serviceMatchFields = [
    'source_port',
    'destination_port',
    'protocol',
];

interface INatMatchConfig {
    services: MessageItem;
    destination_ip: IpAddrMatchConfigItem;
    source_ip: IpAddrMatchConfigItem;
    destination_port: MessageItem;
    source_port: MessageItem;
    protocol: MessageItem;
}

/**
 * @desc NatMatchTarget MessageItem class.
 * @author Aravindh Nagarajan
 */
export class NatMatchTargetConfigItem extends PolicyMatchConfigItem<INatMatchConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NatMatchTarget',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Reason to override this method:
     * We spread out ServiceMatch and
     * sets its child MessageItems(source_port, destination_port, l4RuleProtocol)
     * to `this.config`,
     * So whenever match-config tries to instantiate any of the above match,
     * we need to get/create an instance of the ServiceMatch
     * and create the required child MessageItem.
     * @todo Probably need to figure out a better way to do this because it seems like fieldName
     * can be a child property of ServiceMatch instead of being a property of this MessageItem.
     * createChildByField was intended to work with direct children.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public createChildByField_(fieldName: string, ...args: []): MessageItem {
        try {
            return super.createChildByField_(fieldName, ...args) as MessageItem;
        } catch (e) {
            if (contains(supportedMatches, fieldName)) {
                if (!this.config.services) {
                    this.setNewChildByField('services');
                }

                const { services: serviceMatch } = this.config;
                const childMessageItem = serviceMatch.createChildByField(fieldName);

                serviceMatch.config[fieldName] = childMessageItem;

                return childMessageItem as MessageItem;
            }
        }
    }

    /**
     * Getter for the supported matches.
     * @override
     */
    public get supportedMatches(): string[] {
        return supportedMatches;
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        const {
            services: serviceMessageItem,
        } = this.config;

        // If incoming NatMatchTargetConfigItem has serviceMessageItem
        // Take its child configItems and sets to `this.config`.
        if (serviceMessageItem) {
            Object.keys(serviceMessageItem.config).forEach(matchFieldName => {
                this.config[matchFieldName] =
                    serviceMessageItem.config[matchFieldName];
            });
        }
    }

    /**
     * @override
     */
    public get matchCount(): number {
        return this.supportedMatches.filter(this.hasMatchByField).length;
    }

    /**
     * @override
     * @param matchFieldName - Name of the field of the match to remove.
     */
    public removeMatch(matchFieldName: string): void {
        if (contains(serviceMatchFields, matchFieldName)) {
            const { services: serviceMatch } = this.config;

            delete serviceMatch.config[matchFieldName];
            delete this.config[matchFieldName];

            if (isEmpty(serviceMatch.config)) {
                delete this.config.services;
            }
        } else {
            delete this.config[matchFieldName];
        }
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        Object.keys(this.config).forEach(fieldName => {
            if (contains(serviceMatchFields, fieldName)) {
                if (!this.config.services) {
                    this.setNewChildByField('services');
                }

                this.config.services.config[fieldName] = this.config[fieldName];

                this.config[fieldName] = undefined;
            }
        });
    }
}
