/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    CRLConfigItem,
    PKIProfile,
    SSLCertificateConfigItem,
} from 'ajs/modules/security';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import * as l10n from './pki-profile-modal.l10n';
import './pki-profile-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for PKIProfile modal.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'pki-profile-modal',
    templateUrl: './pki-profile-modal.component.html',
})
export class PkiProfileModalComponent implements OnInit {
    /**
     * PKIProfile ObjectTypeItem instance.
     */
    @Input()
    public editable: PKIProfile;

    /**
     * PKIProfile object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for PKIProfile modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Opens modal to add new Certificate Authority(CA).
     */
    public onAddCertificateAuthority(): void {
        this.editable.addCertificateAuthority();
    }

    /**
     * Opens modal to add new Certificate Revocation List(CRL).
     */
    public onAddCertificateRevocationList(): void {
        this.editable.addCertificateRevocationList();
    }

    /**
     * Handles Certificate Authority (CA) remove operation.
     */
    public removeCertificateAuthority(certificateAuthority: SSLCertificateConfigItem): void {
        const { pkiCertificateAuthority } = this.editable;

        pkiCertificateAuthority.removeByMessageItem(certificateAuthority);
    }

    /**
     * Handles Certificate Revocation List(CRL) remove operation.
     */
    public onRemoveCertificateRevocationList(certificateRevocationListItem: CRLConfigItem): void {
        const { pkiCertificateRevocationList } = this.editable;

        pkiCertificateRevocationList.removeByMessageItem(certificateRevocationListItem);
    }
}
