/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/log-search.less';

angular.module('avi/logs').directive('logSearch', function() {
    //have to use simple eventSearch inside, can't move completely
    return {
        restrict: 'E',
        scope: false,
        templateUrl: 'src/views/application/partials/log-search.html',
    };
});
