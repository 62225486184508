/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';

import { ILDAPVSConfig } from 'generated-types';
import { SSOPolicyTypes } from 'ajs/modules/sso-policy/factories';
import { SSO_POLICY_COLLECTION_TOKEN } from 'ajs/modules/sso-policy/sso-policy.tokens';
import * as l10n from './ldap-vs-config.l10n';
import template from './ldap-vs-config.component.html';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Ldap Vs Config.
 *
 * @author Rohit Gaikwad
 */
class LdapVsConfigController {
    /**
     * Collection instance for SSOPolicy.
     */
    public ssoPolicyCollection;

    /**
     * Config object for Ldap access config.
     */
    public ldapVsConfig: ILDAPVSConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        SSOPolicyCollection: any,
        l10nService: L10nService,
    ) {
        /**
         * @type {SSOPolicyCollection}
         */
        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: SSOPolicyTypes.LDAP,
            },
            defaults: {
                type: SSOPolicyTypes.LDAP,
            },
        });

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onDestroy(): void {
        this.ssoPolicyCollection.destroy();
    }
}

LdapVsConfigController.$inject = [
    SSO_POLICY_COLLECTION_TOKEN,
    'l10nService',
];

export const LdapVsConfigOptions = {
    bindings: {
        ssoPolicyRef: '=',
        ldapVsConfig: '=',
    },
    controller: LdapVsConfigController,
    template,
};
