/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { ClrFormLayout } from '@clr/angular';
import {
    GeoDbMappingElementConfigItem,
} from 'ajs/modules/geo-db';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components';

import * as l10n from './geo-db-mapping-element-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Geo Db Mapping Element modal.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'geo-db-mapping-element-modal',
    templateUrl: './geo-db-mapping-element-modal.component.html',
})
export class GeoDbMappingElementModalComponent implements OnInit {
    /**
     * GeoDbMappingElement instance.
     */
    @Input()
    public editable: GeoDbMappingElementConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<GeoDbMappingElementConfigItem>();

    /**
     * GeoDbMappingElement object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Geo Db Mapping Element modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Vendor dropdown options.
     */
    public attributeOptions: IAviDropdownOption[] = [];

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const geoMappingAttribute = schemaService.getEnumValues('GeoMappingAttribute');

        this.attributeOptions = createOptionsFromEnumProps(geoMappingAttribute);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
