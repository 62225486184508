/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'auth-profile-verify-saml';
const prefix = `${moduleName}.${componentName}`;

export const serviceProviderSettingsHeader = `${prefix}.serviceProviderSettingsHeader`;
export const serviceProviderNodeHeader = `${prefix}.serviceProviderNodeHeader`;
export const entityIdInputLabel = `${prefix}.entityIdInputLabel`;
export const singleSignonUrlInputLabel = `${prefix}.singleSignonUrlInputLabel`;
export const signingCertificateInputLabel = `${prefix}.signingCertificateInputLabel`;

export const ENGLISH = {
    [serviceProviderSettingsHeader]: 'Service Provider Settings',
    [serviceProviderNodeHeader]: 'Service Provider Node',
    [entityIdInputLabel]: 'Entity ID',
    [singleSignonUrlInputLabel]: 'Single Sign on URL',
    [signingCertificateInputLabel]: 'Signing Certificate',
};
