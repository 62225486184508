/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'alert-config-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const duplicateMetricError = `${prefix}.duplicateMetricsError`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const basicsHeader = `${prefix}.basicsHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const enableAlertTriggerLabel = `${prefix}.enableAlertTriggerLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const conditionsHeader = `${prefix}.conditionsHeader`;
export const throttleAlertInputLabel = `${prefix}.throttleAlertInputLabel`;
export const sourceInputLabel = `${prefix}.sourceInputLabel`;
export const eventRadioOption = `${prefix}.eventRadioOption`;
export const metricsRadioOption = `${prefix}.metricsRadioOption`;
export const objectInputLabel = `${prefix}.objectInputLabel`;
export const noneRadioOption = `${prefix}.noneRadioOption`;
export const virtualServiceRadioOption = `${prefix}.virtualServiceRadioOption`;
export const serviceEngineRadioOption = `${prefix}.serviceEngineRadioOption`;
export const poolRadioOption = `${prefix}.poolRadioOption`;
export const autoScaleAlertInputLabel = `${prefix}.autoScaleAlertInputLabel`;
export const instanceInputLabel = `${prefix}.instanceInputLabel`;
export const dropdownNone = `${prefix}.dropdownNone`;
export const dropdownAllInstances = `${prefix}.dropdownAllInstances`;
export const numberOfOccurrencesInputLabel = `${prefix}.numberOfOccurrencesInputLabel`;
export const categoryInputLabel = `${prefix}.categoryInputLabel`;
export const realtimeRadioOption = `${prefix}.realtimeRadioOption`;
export const rollingWindowRadioOption = `${prefix}.rollingWindowRadioOption`;
export const timeWindowInputLabel = `${prefix}.timeWindowInputLabel`;
export const metricOccursInputLabel = `${prefix}.metricOccursInputLabel`;
export const comparatorInputLabel = `${prefix}.comparatorInputLabel`;
export const durationInputLabel = `${prefix}.durationInputLabel`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const removeMetricButtonTitle = `${prefix}.removeMetricButtonTitle`;
export const andRadioOption = `${prefix}.andRadioOption`;
export const andNotRadioOption = `${prefix}.andNotRadioOption`;
export const orRadioOption = `${prefix}.orRadioOption`;
export const addNewMetricLink = `${prefix}.addNewMetricLink`;
export const evenetOccursInputLabel = `${prefix}.evenetOccursInputLabel`;
export const selectEventPlaceholder = `${prefix}.selectEventPlaceholder`;
export const removeEventButtonTitle = `${prefix}.removeEventButtonTitle`;
export const addNewEventLink = `${prefix}.addNewEventLink`;
export const actionsHeader = `${prefix}.actionsHeader`;
export const alertActionInputLabel = `${prefix}.alertActionInputLabel`;
export const createAlertActionInputLabel = `${prefix}.createAlertActionInputLabel`;
export const eventFilterInputLabel = `${prefix}.eventFilterInputLabel`;
export const alertExpiryTimeInputLabel = `${prefix}.alertExpiryTimeInputLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [duplicateMetricError]: 'Metrics selected must be unique.',
    [modalHeaderEdit]: 'Edit Alert Configuration: {0}',
    [modalHeaderNew]: 'New Alert Configuration: {0}',
    [basicsHeader]: 'Basics',
    [nameInputLabel]: 'Name',
    [enableAlertTriggerLabel]: 'Enable Alert Trigger',
    [descriptionInputLabel]: 'Description',
    [conditionsHeader]: 'Conditions',
    [throttleAlertInputLabel]: 'Throttle Alert',
    [sourceInputLabel]: 'Source',
    [eventRadioOption]: 'Event',
    [metricsRadioOption]: 'Metrics',
    [objectInputLabel]: 'Object',
    [noneRadioOption]: 'None',
    [virtualServiceRadioOption]: 'Virtual Service',
    [serviceEngineRadioOption]: 'Service Engine',
    [poolRadioOption]: 'Pool',
    [autoScaleAlertInputLabel]: 'AutoScale Alert',
    [instanceInputLabel]: 'Instance',
    [dropdownNone]: 'None',
    [dropdownAllInstances]: 'All Instances',
    [numberOfOccurrencesInputLabel]: 'Number of Occurrences',
    [categoryInputLabel]: 'Category',
    [realtimeRadioOption]: 'Real-time',
    [rollingWindowRadioOption]: 'Rolling Window',
    [timeWindowInputLabel]: 'Time Window',
    [metricOccursInputLabel]: 'Metric Occurs',
    [comparatorInputLabel]: 'Comparator',
    [durationInputLabel]: 'Duration',
    [valueInputLabel]: 'Value',
    [removeMetricButtonTitle]: 'Remove Metric',
    [andRadioOption]: 'AND',
    [andNotRadioOption]: 'AND NOT',
    [orRadioOption]: 'OR',
    [addNewMetricLink]: '+ Add New Metric',
    [evenetOccursInputLabel]: 'Event Occurs',
    [selectEventPlaceholder]: 'Select an Event',
    [removeEventButtonTitle]: 'Remove Event',
    [addNewEventLink]: '+ Add New Event',
    [actionsHeader]: 'Actions',
    [alertActionInputLabel]: 'Alert Action',
    [createAlertActionInputLabel]: 'Create Alert Action',
    [eventFilterInputLabel]: 'Event Filter',
    [alertExpiryTimeInputLabel]: 'Alert Expiry Time',
    [saveButtonLabel]: 'Save',
};
