/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'notification';
const componentName = 'controller-faults';
const prefix = `${moduleName}.${componentName}`;

export const title = `${prefix}.title`;

export const ENGLISH = {
    [title]: 'Controller Faults',
};
