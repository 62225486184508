/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IQueryMatch } from 'generated-types';
import { QueryMatch } from 'object-types';
import { PathOrQueryMatchBaseConfigItem } from './path-or-query-match-base.config-item';

/**
 * @description  QueryMatch ConfigItem class.
 * @author Abhinesh Gour
 */
export class QueryMatchConfigItem extends PathOrQueryMatchBaseConfigItem<IQueryMatch> {
    public static ajsDependencies = ['schemaService'];

    constructor(args = {}) {
        const extendedArgs = {
            objectType: QueryMatch,
            ...args,
        };

        super(extendedArgs);

        const schemaService = this.getAjsDependency_('schemaService');

        this.operationLabels = schemaService.getEnumValueLabelsHash('QueryMatchOperation');
    }

    /**
     * default match_criteria for QueryMatch is Contains.
     * So, we are overriding it with the 'QUERY_MATCH_CONTAINS'.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IQueryMatch> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};

        return {
            ...defaultConfig,
            match_criteria: 'QUERY_MATCH_CONTAINS',
        };
    }
}
