/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { jwtServerProfileToken } from './jwt-server-profile.item.factory';

export const jwtServerProfileCollectionToken = 'JWTServerProfileCollection';

/**
 * @description
 *
 *   JWTServerProfile Collection.
 *
 * @author Rachit Aggarwal
 */
export class JWTServerProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'jwtserverprofile',
            objectType: jwtServerProfileToken,
            permissionName: AviPermissionResource.PERMISSION_JWTSERVERPROFILE,
            windowElement: 'jwt-server-profile-modal',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(jwtServerProfileToken);

        this.serverDefaultsOverride_ = {
            controller_internal_auth: undefined,
        };
    }
}

JWTServerProfileCollection.ajsDependencies = [
    jwtServerProfileToken,
];
