/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isUndefined } from 'underscore';
import { arrayBufferDecoder, normalizeValue } from 'ng/shared/utils';
import { L10nService } from '@vmw/ngx-vip';
import './file-upload.component.less';
import * as l10n from './file-upload.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export type TFileUploadModelValue = string | File;

/**
 * File size base unit: 1 MB.
 */
const FILE_SIZE_1MB = 1024 * 1024;

/**
 * @description
 *
 *     Component for input[type=file] with customizable layout and ngModel support.
 *
 *     Plain text and binary files are supported. Binary files are base64 encoded.
 *
 * @author alextsg, Alex Malitsky, Zhiqian Liu
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
        },
    ],
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
    /**
     * Used as the label for the upload button.
     */
    @Input() public buttonLabel = '';

    /**
     * Change event emitter for the contentType binding. Used as implementation for contentType
     * two-way binding.
     */
    @Output() public contentTypeChange = new EventEmitter<string>();

    /**
     * Getter for the contentType binding. Used as implementation for contentType two-way binding.
     */
    @Input() public get contentType(): string {
        return this.contentTypeValue;
    }

    /**
     * Setter for the contentType binding. Used as implementation for contentType two-way binding.
     */
    public set contentType(contentType: string) {
        this.contentTypeValue = contentType;
        this.contentTypeChange.emit(this.contentTypeValue);
    }

    /**
     * Change event emitter for the fileName binding. Used as implementation for contentType
     * two-way binding.
     */
    @Output() public fileNameChange = new EventEmitter<string>();

    /**
     * Getter for the fileName binding. Used as implementation for fileName two-way binding.
     */
    @Input() public get fileName(): string {
        return this.fileNameValue;
    }

    /**
     * Setter for the fileName binding. Used as implementation for fileName two-way binding.
     */
    public set fileName(fileName: string) {
        this.fileNameValue = fileName;
        this.fileNameChange.emit(this.fileNameValue);
    }

    /**
     * Pass truthy value for binary files. Applied only when decodeFile is true.
     */
    @Input() public base64 = false;

    /**
     * Setter for the max file size in MB. Binding is passed in as bits.
     */
    @Input('maxFileSize') private set setMaxFileSizeMB(maxFileSize: number) {
        this.maxFileSizeMB = maxFileSize ? maxFileSize * FILE_SIZE_1MB : Infinity;
    }

    /**
     * Setter for the decodeFile binding. Defaults to true so we need to check for undefined.
     */
    @Input('decodeFile') private set setDecodeFile(decodeFile: boolean) {
        this.decodeFile = isUndefined(decodeFile) || Boolean(decodeFile);
    }

    /**
     * Setter for the disabled attribute.
     */
    @Input('disabled') private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || Boolean(disabled);
    }

    /**
     * Optional button className to be used for the button.
     */
    @Input() public buttonClassName?: string;

    /**
     * True when processing uploaded file. Disables input and button.
     */
    public busy = false;

    /**
     * Set through 'disabled' binding. Disables input and button.
     */
    public disabled = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: TFileUploadModelValue;

    /**
     * Set on fileLoadStart and passed to ngModel on fileLoadComplete.
     */
    private uploadedFile: File = null;

    /**
     * Value being get/set as the contenType modelValue.
     */
    private contentTypeValue: string;

    /**
     * Value being get/set as the fileName modelValue.
     */
    private fileNameValue: string;

    /**
     * Instance of FileReader.
     */
    private fileReader: FileReader;

    /**
     * Maximum file size in MB.
     */
    private maxFileSizeMB = Infinity;

    /**
     * True to decode the file, false to set the file as the ngModel as is. Defaults to true.
     */
    private decodeFile = true;

    public constructor(
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.fileReader = new FileReader();
        this.fileReader.addEventListener('load', this.handleFileLoadComplete);
        this.fileReader.addEventListener('error', this.handleFileLoadFail);
        this.buttonLabel = this.l10nService.getMessage(l10nKeys.importFileInputLabel);
    }

    /**
     * Passes selected file to the fileReader instance.
     */
    public startFileLoad(event: Event): void {
        const { target } = event;
        const { files } = target as HTMLInputElement;
        const { l10nService } = this;

        if (!files.length) {
            return;
        }

        const file = files[0];

        if (file.size > this.maxFileSizeMB) {
            alert(l10nService
                .getMessage(l10nKeys.maxSizeExceededAlertMessage, [this.maxFileSizeMB]));

            return;
        }

        // Need to clear the file input value so that change event is triggered,
        // when the same file is selected next time.
        // eslint-disable-next-line no-extra-parens
        (target as HTMLInputElement).value = null;

        // Return only when keeping file ref. No further decoding actions are needed.
        if (!this.decodeFile) {
            this.updateValues(file, undefined);

            return;
        }

        this.busy = true;
        this.uploadedFile = file;

        const blob = file.slice();

        this.fileReader.readAsArrayBuffer(blob);
    }

    /**
     * Text to be displayed as the file label.
     */
    public get fileLabel(): string {
        const { l10nService } = this;

        if (this.fileName) {
            return this.fileName;
        }

        return l10nService.getMessage(l10nKeys.selectFileInputLabel);
    }

    /**
     * Returns the className to be used for the file select button.
     */
    public getButtonClassName(): string {
        return this.buttonClassName || 'btn btn-primary btn-sm';
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): TFileUploadModelValue {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue.
     */
    public set value(val: TFileUploadModelValue) {
        if (this.modelValue !== val) {
            const normalizedValue = normalizeValue(val);

            this.modelValue = normalizedValue;
            this.onChange(normalizedValue);
        }

        this.onTouched();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.modelValue = normalizeValue(value);
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Remove attached file.
     */
    public removeUploadedFile = (): void => {
        this.uploadedFile = null;
        this.updateValues();
    };

    /*************************************************************************/

    /**
     * Called on file read failure. Resets the ngModel modelValue.
     */
    private handleFileLoadFail = (): void => {
        this.busy = false;
        this.removeUploadedFile();
    };

    /**
     * Called on file read success event. Sets the ngModel modelValue.
     */
    private handleFileLoadComplete = (event: Event): void => {
        this.busy = false;

        let fileContent;

        if (this.decodeFile) {
            const { result: arrBuffer } = event.target as FileReader;

            fileContent = arrayBufferDecoder(arrBuffer as ArrayBuffer, this.base64);
        }

        this.updateValues(this.uploadedFile, fileContent);

        this.uploadedFile = null;
    };

    /**
     * Updates the ngModel value, along with the contentType and fileName two-way binding values.
     */
    private updateValues(file?: File, fileContent?: string): void {
        if (file) {
            const { name, type } = file;

            this.fileName = name;
            this.contentType = type;
            this.value = this.decodeFile ? fileContent : file;
        } else {
            this.fileName = undefined;
            this.contentType = undefined;
            this.value = undefined;
        }
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: TFileUploadModelValue): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
