/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './ipam-dns-infoblox-profile-config.component.less';
import * as l10n from './ipam-dns-inflobox-profile-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @typedef {('IPAMDNS_TYPE_INFOBLOX'|'IPAMDNS_TYPE_INFOBLOX_DNS')} IpamDnsInfobloxProfileType
 * @memberOf module:avi/ipam
 */

/**
 * @constructor
 * @memberOf module:avi/ipam
 * @mixes module:avi/ipam.ipamDnsInfobloxProfileConfigBindings
 * @see {@link module:avi/ipam.ipamDnsInfobloxProfileConfigComponent}
 */
class IpamDnsInfobloxProfileConfigController {
    constructor(
        $http,
        $q,
        Regex,
        getSubnetString,
        dropDownUtils,
        l10nService,
    ) {
        this.$http_ = $http;
        this.$q_ = $q;
        this.Regex = Regex;
        this.getSubnetString = getSubnetString;
        this.dropDownUtils_ = dropDownUtils;

        /**
         * Indicate whether credentials are being edited or not.
         * @type {boolean}
         */
        this.editingCredentials = false;

        /**
         * List of listeners to fire on Credential change event.
         * @type {Array.<Function>}
         */
        this.credentialChangeListeners_ = [];

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        if (!this.ipamProfileId) {
            this.editingCredentials = true;
        }
    }

    /**
     * Verify profile credentials based on types.
     * @return {ng.$q.promise}
     */
    verifyInfobloxProfileCredentials() {
        this.editable.busy = true;
        this.errors = null;

        const api = '/api/ipamdnsproviderprofilelogin';
        const url = this.editable.getUrl(api, this.type);
        const promise = this.$http_.get(url);

        return promise
            .then(({ data }) => data)
            .catch(({ data }) => this.$q_.reject(data))
            .finally(() => this.editable.busy = false);
    }

    /**
     * Fires on credential edit start.
     */
    onInfobloxProfileCredentialEditStart() {
        this.editable.clearProfilePassword();
    }

    /**
     * Fires after credentials are verified.
     */
    onInfobloxProfileCredentialEditComplete() {
        this.callCredentialChangeListeners();
    }

    /**
     * Calls all credentialChange listeners.
     */
    callCredentialChangeListeners() {
        this.credentialChangeListeners_.forEach(listener => listener());
    }

    /**
     * Adds a listener callback to `this.credentialChangeListeners_`.
     */
    addCredentialChangeListener(listener) {
        this.credentialChangeListeners_.push(listener);
    }

    /**
     * Removes all credential change event listeners.
     */
    resetCredentialChangeListeners() {
        this.credentialChangeListeners_ = [];
    }

    /**
     * Add one CustomParams entry to extensible_attributes list.
     */
    handleAddExtensibleAttributes() {
        this.editable.config.extensible_attributes.add();
    }

    /**
     * Delete one entry from extensible_attributes list by index.
     * @param {number} index
     */
    handleRemoveExtensibleAttributes(index) {
        this.editable.config.extensible_attributes.remove(index);
    }

    /** @override */
    $onDestroy() {
        this.resetCredentialChangeListeners();
    }
}

IpamDnsInfobloxProfileConfigController.$inject = [
    '$http',
    '$q',
    'Regex',
    'getSubnetString',
    'dropDownUtils',
    'l10nService',
];

/**
 * @name ipamDnsInfobloxProfileConfigComponent
 * @memberOf module:avi/ipam
 * @property {module:avi/ipam.ipamDnsInfobloxProfileConfigBindings} bindings
 * @property {module:avi/ipam.IpamDnsInfobloxProfileConfigController} controller
 * @description
 *      Component for Infoblox configuration in IPAM/DNS Modal.
 * @author Aravindh Nagarajan
 */
angular.module('avi/ipam').component('ipamDnsInfobloxProfileConfig', {
    /**
     * @mixin ipamDnsInfobloxProfileConfigBindings
     * @memberof module:avi/ipam
     * @property {module:avi/ipam.IpamDnsInfobloxProfileConfigItem} editable
     * @property {string} ipamProfileId - UUID of the parent IPAM profile.
     * @property {module:avi/ipam.IpamDnsInfobloxProfileType} type
     */
    bindings: {
        editable: '<profile',
        ipamProfileId: '@',
        type: '@',
    },
    controller: IpamDnsInfobloxProfileConfigController,
    templateUrl: 'src/components/templates/profiles/ipam-dns-profiles-modal/' +
        'ipam-dns-infoblox-profile-config/ipam-dns-infoblox-profile-config.component.html',
});
