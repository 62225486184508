/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('seSubheader', function() {
    return {
        restrict: 'E',
        templateUrl: 'src/views/infrastructure/serviceengine-subheader.html',
    };
});
