/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    IError,
    IRegisterControllerPayLoad,
    PortalInfo,
} from 'ajs/modules/cportal';

import {
    IALBServicesAccount,
    IALBServicesAccountUser,
    IALBServicesConfig,
    IALBServicesUser,
    LicenseTierType,
} from 'generated-types';

import { CportalService } from 'ajs/modules/cportal/services';
import { SystemInfoService } from 'ajs/modules/core/services/system-info/system-info.service';
import { IAviDropdownOption } from 'ng/shared/components';

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    findWhere,
    isUndefined,
} from 'underscore';

import {
    ALBServicesConfig,
    CaseConfig,
    PortalFeatureOptIn,
    WafCrsConfig,
} from 'object-types';

import { createDropdownOption } from 'ng/shared/utils';
import * as l10n from './cportal-info-modal.l10n';
import './cportal-info-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Component used to represent Cportal page.
 * @author Ram Pal, Ashish Verma, Navneet Sharma, Rajawant Prajapati, Ratan Kumar
 */
@Component({
    selector: 'cportal-info-modal',
    templateUrl: './cportal-info-modal.component.html',
})
export class CPortalInfoModalComponent implements OnInit {
    /**
     * PortalInfo item
     */
    @Input()
    public editable: PortalInfo;

    /**
     * Controller name to pass in modal.
     */
    @Input()
    public controllerName: string;

    /**
     * Method to be called after process is complete.
     */
    @Output()
    public onComplete: () => void;

    /**
     * Department Selector dropdown options.
     */
    public departmentOptions: IAviDropdownOption[] = [];

    /**
     * User Selector dropdown options.
     */
    public users: IAviDropdownOption[] = [];

    /**
     * Selected default department Id.
     */
    public defaultDepartmentId = '';

    /**
     * Selected default contact.
     */
    public defaultContact = '';

    /**
     * True if model is open in edit mode.
     */
    public editMode = false;

    /**
     * Set to true when fetching user info and saving portal info.
     */
    public busy = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Selected license tier.
     */
    public licenseTier: LicenseTierType;

    /**
     * Layout for modal.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Label for Account/Organization field.
     */
    public acctOrgLabel: string;

    /**
     * cportal info object type.
     */
    public objectType = {
        ALBServicesConfig,
        CaseConfig,
        PortalFeatureOptIn,
        WafCrsConfig,
    };

    /**
     * Default variable for error message.
     */
    public error: IError;

    /**
     * Departments belonging to user.
     */
    private departments: IALBServicesAccount[] = [];

    constructor(
        private readonly l10nService: L10nService,
        private readonly cportalService: CportalService,
        @Inject('systemInfoService')
        private readonly systemInfoService: SystemInfoService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
        this.licenseTier = this.systemInfoService.defaultLicenseTier;
    }

    /** @override */
    public ngOnInit(): void {
        if (!this.editMode) {
            this.setDepartmentAndContactDetails();
        }

        const { l10nKeys } = this;

        this.acctOrgLabel = this.isEnterpriseWithCloudServicesTier() ?
            this.acctOrgLabel = this.l10nService.getMessage(l10nKeys.organizationNameInputLabel) :
            this.l10nService.getMessage(l10nKeys.accountNameInputLabel);
    }

    /**
     * Fired when department is changed.
     */
    public onDepartmentChange(): void {
        this.defaultContact = '';
        this.setContactDetails();
    }

    /**
     * Saves portal info and registers controller thereafter.
     */
    public savePortalInfoAndRegisterController(): void {
        this.busy = true;
        this.error = undefined;

        let payload: IRegisterControllerPayLoad;

        if (!this.editMode) {
            payload = this.getRegisterPayload();
        }

        let promise: IPromise<IHttpResponse<IALBServicesConfig> | void> = this
            .editable.save()
            .catch((error: IError) => Promise.reject(error.data));

        // This code will run only for controller registration.
        if (!this.editMode) {
            promise = promise
                .then(() => this.cportalService.registerController(payload))
                .then(() => { this.onComplete(); });
        }

        promise
            .then(() => { this.editable.closeModal(); })
            .catch((error: IError) => this.error = error)
            .finally(() => this.busy = false);
    }

    /**
     * Returns true if default/selected tier is ENTERPRISE_WITH_CLOUD_SERVICES.
     */
    private isEnterpriseWithCloudServicesTier(): boolean {
        return this.licenseTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES;
    }

    /**
     * This populates department and contact details.
     */
    private setDepartmentAndContactDetails(): void {
        this.busy = true;

        this.cportalService.getUserInfo()
            .then(({ account_id: departmentId, email, managed_accounts: departments }:
            IALBServicesUser) => {
                this.defaultContact = email;
                this.defaultDepartmentId = departmentId;
                this.departments = departments;

                this.departmentOptions = this.departments
                    .map(({ id, name }: IALBServicesAccount) => {
                        return createDropdownOption(id, name);
                    });

                this.setContactDetails();
            })
            .finally(() => this.busy = false);
    }

    /**
     * Sets contact details based on department selected.
     */
    private setContactDetails(): void {
        const department = findWhere(this.departments, { id: this.defaultDepartmentId });

        this.users = department.users
            .filter(({ email }: IALBServicesAccountUser) => !isUndefined(email))
            .map(({ email, name }: IALBServicesAccountUser) => {
                return createDropdownOption(email, `${name} (${email})`);
            });
    }

    /**
     * Get registration request payload.
     */
    private getRegisterPayload(): IRegisterControllerPayLoad {
        return {
            name: this.controllerName,
            description: this.controllerName,
            email: this.defaultContact,
            account_id: this.defaultDepartmentId,
        };
    }
}
