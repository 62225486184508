/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import classnames from 'classnames';
import { isUndefined } from 'underscore';
import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { WafMode } from 'generated-types';
import {
    WafPolicy,
    WafRuleConfigItem,
    WafRuleGroupConfigItem,
    WafRuleGroupOverridesConfigItem,
    WafRuleOverridesConfigItem,
} from 'ajs/modules/waf';
import * as l10n from './waf-crs-rule-group.l10n';
import './waf-crs-rule-group.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Map of classnames to be set based on the enabled state.
 */
const wafGroupEnabledStateClassNamesMap = new Map([
    [0, 'waf-rule-group__enabled-setting--disabled'],
    [1, 'waf-rule-group__enabled-setting--enabled'],
    [2, 'waf-rule-group__enabled-setting--mixed'],
]);

/**
 * @description
 *     Component for the WAF CRS group. This differs from the waf-rule-group component in that this
 *     component does not allow for editing the group directly but instead works with overrides on
 *     top of the WAF group.
 * @author alextsg
 */
@Component({
    selector: 'waf-crs-rule-group',
    templateUrl: './waf-crs-rule-group.component.html',
})
export class WafCrsRuleGroupComponent {
    /**
     * WafRuleGroup messageItem instance.
     */
    @Input()
    public group: WafRuleGroupConfigItem;

    /**
     * Mode configuration for the parent WafPolicy.
     */
    @Input()
    public parentMode: WafMode;

    /**
     * Parent WAF Policy. Used for setting group overrides.
     */
    @Input()
    public wafPolicy: WafPolicy;

    /**
     * If true, allows for overriding the parent mode.
     */
    @Input()
    public modeDelegationAllowed: boolean;

    /**
     * Expanded state. When true, group details are expanded.
     */
    public expanded = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for clicking the expander button.
     */
    public handleExpand(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Returns the group override based on the group.
     */
    public get crsGroupOverride(): WafRuleGroupOverridesConfigItem {
        return this.wafPolicy.getCrsGroupOverrideByName(this.group.getName());
    }

    /**
     * Returns the enabled setting of this group or its override.
     */
    public get groupEnabled(): boolean {
        return this.hasOverrideGroup && !isUndefined(this.crsGroupOverride.enable) ?
            this.crsGroupOverride.isEnabled() :
            this.group.isEnabled();
    }

    /**
     * Returns the enabled state of the group as a number. 0 for disabled, 1 for enabled, and 2 for
     * mixed, as in some rules are enabled while some are disabled.
     */
    public get wafGroupEnabledState(): 0 | 1 | 2 {
        const { groupEnabled } = this;

        if (!this.group.hasRules()) {
            return groupEnabled ? 1 : 0;
        }

        const { enabledRulesCount } = this;

        if (groupEnabled && this.group.rules.count === enabledRulesCount) {
            return 1;
        }

        if (groupEnabled && enabledRulesCount > 0) {
            return 2;
        }

        return 0;
    }

    /**
     * Returns the number of rules that are enabled, taking the rule override into account.
     */
    private get enabledRulesCount(): number {
        return this.group.rules.config.reduce((acc, rule) => {
            let enabled = rule.isEnabled();

            if (this.hasOverrideGroup) {
                const ruleID = rule.getId();
                const overrideRule = this.crsGroupOverride.getRuleOverrideByID(ruleID);

                if (overrideRule && !isUndefined(overrideRule.enable)) {
                    enabled = overrideRule.isEnabled();
                }
            }

            return enabled ? acc + 1 : acc;
        }, 0);
    }

    /**
     * Returns the text label to be displayed based on the enabled status.
     */
    public get wafGroupEnabledSettingLabel(): string {
        const labelMap = new Map([
            [0, this.l10nKeys.deactivatedLabel],
            [1, this.l10nKeys.enabledLabel],
            [2, this.l10nKeys.mixedEnabledLabel],
        ]);

        const wafGroupEnabledStateKey = labelMap.get(this.wafGroupEnabledState);

        return this.l10nService.getMessage(wafGroupEnabledStateKey);
    }

    /**
     * Returns the class name to be set on the text label of the enabled status.
     */
    public get wafGroupEnabledStateClassName(): string {
        return classnames(
            'waf-rule-group__enabled-setting',
            wafGroupEnabledStateClassNamesMap.get(this.wafGroupEnabledState),
        );
    }

    /**
     * Returns true if the overrides badge should be shown.
     */
    public get hasOverrideGroup(): boolean {
        return Boolean(this.crsGroupOverride);
    }

    /**
     * Returns true if the group has a configured exclude list.
     */
    public hasExcludeList(): boolean {
        return this.hasOverrideGroup && !this.crsGroupOverride.excludeList.isEmpty();
    }

    /**
     * Called when a user wants to edit a CRS Group, which is actually adding or editing a group
     * override.
     */
    public handleEditCrsGroup(group: WafRuleGroupConfigItem): void {
        if (this.hasOverrideGroup) {
            this.wafPolicy.editCrsGroupOverride(this.crsGroupOverride, group);
        } else {
            this.wafPolicy.addCrsGroupOverride(group);
        }
    }

    /**
     * Called to remove an existing group override.
     */
    public handleRemoveCrsGroupOverride(): void {
        this.wafPolicy.removeCrsGroupOverride(this.crsGroupOverride);
    }

    /**
     * Returns a CRS Rule override from the rule.
     */
    public getRuleOverride(rule: WafRuleConfigItem): WafRuleOverridesConfigItem | undefined {
        if (this.hasOverrideGroup) {
            return this.crsGroupOverride.getRuleOverrideByID(rule.getId());
        }
    }

    /**
     * Called to edit a rule override.
     */
    public handleEditRuleOverride(
        ruleOverride: WafRuleOverridesConfigItem,
        originalRule: WafRuleConfigItem,
    ): void {
        this.crsGroupOverride.editRuleOverride(ruleOverride, originalRule, {
            parentMode: this.parentMode,
            modeDelegationAllowed: this.modeDelegationAllowed,
        });
    }

    /**
     * Called to add a new rule override.
     */
    public handleAddRuleOverride(rule: WafRuleConfigItem): void {
        this.wafPolicy.addCrsRuleOverride(
            this.group,
            rule,
            {
                parentMode: this.parentMode,
                modeDelegationAllowed: this.modeDelegationAllowed,
            },
        );
    }

    /**
     * Called to remove a rule override.
     * Calls handleRemoveCrsGroupOverride to remove override group if no rule override present.
     */
    public handleRemoveRuleOverride(rule: WafRuleOverridesConfigItem): void {
        this.crsGroupOverride.removeRuleOverride(rule);

        if (this.crsGroupOverride.isEmpty()) {
            this.handleRemoveCrsGroupOverride();
        }
    }

    /**
     * Trackby function. Tracks by rule ID.
     */
    public trackByRuleID(index: number, rule: WafRuleConfigItem): string {
        return rule.getId();
    }
}
