/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { IcapProfile } from 'ajs/modules/icap-profile';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { CloudCollection } from 'ajs/modules/cloud';
import { PoolGroupCollection } from 'ajs/modules/pool';
import { ICAPVendor } from 'generated-types';

import {
    IEnumValue,
    SchemaService,
    StringService,
} from 'ajs/modules/core/services';

import './icap-profile-modal.component.less';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './icap-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloudCollection = typeof CloudCollection;
type TPoolGroupCollection = typeof PoolGroupCollection;

/**
 * @description Component for ICAP Profile create/edit modal.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'icap-profile-modal',
    templateUrl: './icap-profile-modal.component.html',
})
export class ICAPProfileModalComponent implements OnInit, OnDestroy {
    /**
     * IcapProfile ObjectTypeItem.
     */
    @Input() public editable: IcapProfile;

    /**
     * Icap-profile object type.
     */
    public readonly objectType = 'IcapProfile';

    /**
     * CloudCollection instance - for cloud avi-collection-dropdown
     */
    public cloudCollection: CloudCollection;

    /**
     * PoolGroupCollection instance - for poolgroup avi-collection-dropdown
     */
    public poolGroupCollection: PoolGroupCollection;

    /**
     * ICAP Vendor dropdown options.
     */
    public icapVendorOptions: IAviDropdownOption[];

    /**
     * ICAP failAction Enum-values
     * Used to generate Action radio-buttons in template.
     */
    public failActionEnumValues: IEnumValue[];

    /**
     * Layout for ICAP modal clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of ICAP Vendor Enum values.
     */
    public readonly icapVendorHash = {
        ICAP_VENDOR_GENERIC: ICAPVendor.ICAP_VENDOR_GENERIC,
        ICAP_VENDOR_LASTLINE: ICAPVendor.ICAP_VENDOR_LASTLINE,
        ICAP_VENDOR_OPSWAT: ICAPVendor.ICAP_VENDOR_OPSWAT,
    };

    public constructor(
        @Inject('CloudCollection') private CloudCollection: TCloudCollection,
        @Inject(PoolGroupCollection) private PoolGroupCollection: TPoolGroupCollection,
        @Inject(StringService) private readonly stringService: StringService,
        @Inject(SchemaService) schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        const icapVendorEnumValues = schemaService.getEnumValues('ICAPVendor');

        this.icapVendorOptions = createOptionsFromEnumProps(icapVendorEnumValues);
        this.failActionEnumValues = schemaService.getEnumValues('ICAPFailAction');

        this.cloudCollection = new this.CloudCollection();
        this.poolGroupCollection = new this.PoolGroupCollection();

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.editable.configureIcapVendorSpecificFields();
        this.setPoolGroupCollectionParams();
    }

    /**
     * Fires on cloud dropdown change.
     * Clears poolGroupRef and resets poolGroupCollection param.
     */
    public onCloudChange(): void {
        this.editable.config.pool_group_ref = '';

        this.setPoolGroupCollectionParams();
    }

    /**
     * Returns true when editable (ICAPProfile objectTypeItem) is being edited, not created.
     */
    public get isEditing(): boolean {
        return Boolean(this.editable.id);
    }

    /**
     * trackBy Function for failActionEnumValues.
     */
    public trackByFailActionValue(i: number, failAction: IEnumValue): IEnumValue['value'] {
        return failAction.value;
    }

    /**
     * Destroys collection instances.
     * @override
     */
    public ngOnDestroy(): void {
        this.cloudCollection.destroy();
        this.poolGroupCollection.destroy();
    }

    /**
     * Sets cloud_ref param in PoolGroup collection instance.
     */
    private setPoolGroupCollectionParams(): void {
        const { cloud_ref: cloudRef } = this.editable.config;

        this.poolGroupCollection.setParams({
            'cloud_ref.uuid': this.stringService.slug(cloudRef),
        });

        this.poolGroupCollection.setDefaultItemConfigProps({ cloud_ref: cloudRef });
    }
}
