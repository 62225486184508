/**
 * @module VerticalNavModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    StateService,
    TransitionService,
} from '@uirouter/core';
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
} from '@angular/core';
import {
    IAppState,
} from 'ajs/js/services/appStates.types';
import './vertical-nav.component.less';
import { StateManager } from 'ajs/modules/core/services/state-manager';
import { any } from 'underscore';

interface IStateRefObject {
    state: string;
    paramExpr: string;
}

/**
 * Vertical Nav component.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'vertical-nav',
    templateUrl: './vertical-nav.component.html',
})
export class VerticalNavComponent implements OnInit {
    /**
     * Root state of current state.
     */
    public parent: IAppState;

    /**
     * List of states to be shown in navigation.
     */
    public states: IAppState[];

    public constructor(
        @Inject('$state')
        private readonly $state: StateService,
        @Inject('$transitions')
        private readonly $transitions: TransitionService,
        @Inject('systemInfoService')
        private readonly systemInfoService: any,
        private readonly stateManagerService: StateManager,
        @Inject('appStateTree')
        private readonly appStateTree: IAppState[],
        private elementRef: ElementRef,
    ) {
    }

    public ngOnInit(): void {
        this.setProperties();
        this.$transitions.onSuccess({ to: 'authenticated.**' }, () => {
            if (this.parent?.name !== this.getParent()?.name) {
                this.setProperties();
            }
        });
    }

    /**
     * Hides the non configured tabs.
     * Few states require configuration to be set before usable.
     */
    public isHidden(state: IAppState): boolean {
        const { fullName: stateName } = state;

        switch (stateName) {
            case 'authenticated.profile.profiles.gslb-healthmonitor-list':
            case 'authenticated.application.gslbservice-list':
                return !this.haveGSLBConfig();

            case 'authenticated.infrastructure.gslb.upload-geo-files':
            case 'authenticated.infrastructure.gslb.geo-profile':
            case 'authenticated.infrastructure.gslb.federation-checkpoints':
                return !this.systemInfoService.localSiteIsGSLBLeader;
            default:
                return false;
        }
    }

    /**
     * Returns true if state has to be group having child navigation links.
     */
    public isNavGroup(state: IAppState): boolean {
        return state.children && !state.url?.includes(':');
    }

    /**
     * Returns true if state has to be a dirent link and not a group of links.
     */
    public isNavLink(state: IAppState): boolean {
        return !state.children && !this.isHidden(state);
    }

    /**
     * Returns true if the GSLB Health Monitors tab should be shown.
     */
    public haveGSLBConfig(): boolean {
        return this.systemInfoService.haveGSLBConfig();
    }

    /**
     * Redirects to the state page.
     */
    public redirectTo(state: string): void {
        this.$state.go(this.stateManagerService.getFullAllowedState(state));
    }

    /**
     * Returns true if any of the activeStates or the current state is active.
     */
    public isActive(state: IAppState): boolean {
        if (Array.isArray(state.activeStates)) {
            return any(state.activeStates, eachState => this.$state.includes(eachState));
        } else if (Array.isArray(state.children)) {
            return any(state.children, childState => this.isActive(childState));
        } else {
            const stateRefObject = this.getStateRefObject(state.fullName);
            const stateObject = this.$state.get(stateRefObject.state, this.getStateContext());

            return this.$state.includes(stateObject.name);
        }
    }

    /**
     * Track by function for ngFor iterator on states.
     */
    public trackByState(index: number, state: IAppState): string {
        return state.fullName;
    }

    /**
     * Parses the state string and splits up the state name from the params. This logic is
     * duplicated from ui-router.
     */
    private getStateRefObject(stateRef: string): IStateRefObject {
        let ref = stateRef;
        const paramsOnly = stateRef.match(/^\s*({[^}]*})\s*$/);

        if (paramsOnly) {
            ref = `(${paramsOnly[1]})`;
        }

        const parsed = ref.replace(/\n/g, ' ').match(/^\s*([^(]*?)\s*(\((.*)\))?\s*$/);

        if (!parsed || parsed.length !== 4) {
            throw new Error(`Invalid state ref ${ref}`);
        }

        return {
            state: parsed[1] || null,
            paramExpr: parsed[3] || null,
        };
    }

    /**
     * Returns the context or parent level of the state. This logic is duplicated from ui-router.
     */
    private getStateContext(): string {
        const $uiView = $(this.elementRef.nativeElement)
            .parent().inheritedData('$uiView');

        const path = $uiView && $uiView.$cfg && $uiView.$cfg.path;

        return Array.isArray(path) && path[path.length - 1].state.name;
    }

    /**
     * Sets the states on initial load and change.
     */
    private setProperties(): void {
        this.parent = this.getParent();
        this.states = this.getStates();
    }

    /**
     * Returns the root state.
     */
    private getParent(): IAppState {
        return this.appStateTree.find(state => state.name === this.getParentName());
    }

    /**
     * Returns name of the root state.
     */
    private getParentName(): string {
        const { $current: currentState } = this.$state;

        return currentState.name.split('.').splice(0, 2).join('.');
    }

    /**
     * Returns list of states to be shown.
     */
    private getStates(): IAppState[] {
        return this.parent.children;
    }
}
