/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    GCPVIPAllocationMode,
    IGCPVIPAllocation,
} from 'generated-types';

import { VipConfigItem } from 'ajs/modules/vs';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './gcp-vip-config.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description VIP Config Component for GCP CloudType.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'gcp-vip-config',
    templateUrl: './gcp-vip-config.component.html',
})
export class GcpVipConfigComponent implements OnInit {
    /**
     * VipConfigItem Instance.
     */
    @Input()
    public editable: VipConfigItem;

    /**
     * UUID of the cloud on which this VIP is being configured.
     */
    @Input()
    public cloudId: string;

    /**
     * VIP Allocation Strategy object.
     */
    @Input()
    public vipAllocationStrategy: IGCPVIPAllocation;

    /**
     * UUID of the VRFContext on which this VIP is being configured.
     */
    @Input()
    public vrfContextId: string;

    /**
     * VsVip#ipam_selector label string.
     * Set by backend, UI configuration is not available yet.
     */
    @Input()
    public ipamSelectorLabels: string;

    /**
     * ObjectType of VIPConfigItem.
     */
    public objectType: string;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of GCPVIPAllocationMode enum.
     */
    public readonly gcpVIPAllocationModeEnumHash = {
        ILB: GCPVIPAllocationMode.ILB,
        ROUTES: GCPVIPAllocationMode.ROUTES,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        // If value of auto_allocate_floating_ip is 'false' and
        // VIP allocation strategy mode is 'ROUTES', then select `None` radio button as default.
        if (this.editable.autoAllocateFloatingIp === false &&
                this.vipAllocationStrategy.mode === GCPVIPAllocationMode.ROUTES) {
            this.editable.config.auto_allocate_floating_ip = undefined;
        }
    }
}
