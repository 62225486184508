/**
 * @module ClusterModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { ClusterNodeConfigItem }
    from 'ajs/modules/cluster/factories/cluster-node.config-item.factory';
import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { NgForm } from '@angular/forms';
import { InitialDataService } from 'ajs/modules/core/services/initial-data/initial-data.service';

import * as l10n from './cluster-node-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * ClusterNode modal component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'cluster-node-modal',
    templateUrl: './cluster-node-modal.component.html',
})
export class ClusterNodeModalComponent implements OnInit {
    /**
     * Template ref form element.
     */
    @ViewChild('form')
    public form: NgForm;

    /**
     * ClusterNodeConfigItem instance.
     */
    @Input()
    public editable: ClusterNodeConfigItem;

    /**
     * ClusterNodeConfigItem instance.
     */
    @Input()
    public clusterNodeList: RepeatedMessageItem<ClusterNodeConfigItem>;

    /**
     * Index of selected item.
     */
    @Input()
    public currentIndex: number;

    /**
     * Informs if current item is new or already exists.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ClusterNode object type.
     */
    public objectType: string;

    /**
     * Set password field required based on isAwsCloud.
     */
    public passwordRequired = false;

    /**
     * Layout for ClusterNode modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        l10nService: L10nService,
        private readonly initialDataService: InitialDataService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Set ObjectType and check if Password Required based on isAwsCloud status.
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        if (this.initialDataService.isAwsCloud) {
            this.passwordRequired = true;
        }
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.editable.setName();
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Method to set error based on field name and hasDuplicate status.
     */
    public setErrorField(hasDuplicate: boolean, fieldName: string): void {
        if (hasDuplicate) {
            this.form.controls[fieldName].setErrors({ incorrect: true });
        } else {
            this.form.controls[fieldName].setErrors(null);
        }
    }

    /**
     * Method to check duplicate IP on cluster node.
     */
    public checkDuplicateIp(): void {
        const currentIp = this.editable.ip.address;
        const hasDuplicateIp = this.clusterNodeList.config.some(
            (item, index) => item.config.ip.address === currentIp && this.currentIndex !== index,
        );

        this.setErrorField(hasDuplicateIp, 'ip');
    }

    /**
     * Method to check duplicate Name on cluster node.
     */
    public checkDuplicateName(): void {
        const currentName = this.editable.config.name;
        const hasDuplicateName = this.clusterNodeList.config.some(
            (item, index) => item.config.name === currentName && this.currentIndex !== index,
        );

        this.setErrorField(hasDuplicateName, 'name');
    }
}
