/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'cluster-edit';
const prefix = `${moduleName}.${componentName}`;

export const editControllerConfigurationHeader = `${prefix}.editControllerConfigurationHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const ipAddressInputPlaceholder = `${prefix}.ipAddressInputPlaceholder`;
export const generalHeader = `${prefix}.generalHeader`;
export const clusterNodeHeader = `${prefix}.clusterNodeHeader`;

export const ENGLISH = {
    [editControllerConfigurationHeader]: 'Edit Cluster',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [ipAddressInputLabel]: 'Controller Cluster IP',
    [ipAddressInputPlaceholder]: 'Enter IP Address',
    [generalHeader]: 'General',
    [clusterNodeHeader]: 'Cluster Node',
};
