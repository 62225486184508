/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ICloud } from 'generated-types';

export interface IInfraCloudState {
    cloud: ICloud | null;
    isLoading: boolean;
    error: any;
}

export const initialInfraCloudState: IInfraCloudState = {
    cloud: null,
    isLoading: false,
    error: null,
};
