/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { UpdatableItem } from 'ajs/modules/data-model/factories/updatable-item.factory';
import { ICollMetricsRequest } from 'ajs/modules/data-model/data-model.types';

/**
 * @description
 *
 *     Used to show GSLB Service DNS VS metrics info in a grid filtered by a list of FQDNs. That
 *     is why this item is a combination of GslbSite, VS and FQDN.
 *
 * @author Alex Malitsky, Ram Pal
 *
 */
export class GSLBServiceFQDN extends UpdatableItem {
    public getConfig: () => any;

    /** @override */
    public getName(): string {
        const { siteName, vsId, domainName } = this.getConfig();

        return `${siteName}:${vsId.name() || vsId.slice(-10)}:${domainName}`;
    }

    /** @override */
    public getRef(): string {
        return '';
    }

    /**
     * PoolId (second element) is not needed actually but good to have it to reuse a regular
     * mechanism.
     * @override
     */
    public getMetricsTuple(): Record<string, string> {
        const {
            domainName,
            gslbServiceName,
            vsId,
        } = this.getConfig();

        const Auth = this.getAjsDependency_('Auth');

        const tenantName = Auth.getTenantName();

        const objId = `fqdn-gs:${domainName}#${tenantName}:${gslbServiceName}`;

        return {
            entity_uuid: vsId.slug(),
            obj_id: objId,
        };
    }

    /**
     * Only difference is the fact we want to set a clusterId property on every subrequest
     * since we might make few API calls to different controllers in order to get this info.
     * @override
     */
    public getCollMetricsRequests(fields: string[]): ICollMetricsRequest[] {
        const { clusterId } = this.getConfig();
        const requests = super.getCollMetricsRequests(fields);

        requests.forEach(request => request.clusterId = clusterId);

        return requests;
    }

    /** @override */
    public isProtected(): boolean {
        return true;
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected getIdFromData_(data: any): string {
        const { clusterId, vsId, domainName } = data.config;

        return `${clusterId}:${vsId.slug()}:${domainName}`;
    }
}

Object.assign(GSLBServiceFQDN.prototype, {
    objectName: 'GSLBServiceFQDN',
});

GSLBServiceFQDN.ajsDependencies = [
    'Auth',
];
