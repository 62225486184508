/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { IAviDataGridConfig, TStringRow } from 'ng/shared/components';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ocsp-responder-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   OCSP Responder URL grid component.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ocsp-responder-grid',
    templateUrl: './ocsp-responder-grid.component.html',
})
export class OcspResponderGridComponent implements AfterViewInit {
    /**
     * List of responder Urls.
     */
    @Input()
    public responderUrls: TStringRow[];

    /**
     * Fires on new URL add.
     */
    @Output()
    public onAddResponderUrl = new EventEmitter<void>();

    /**
     * Fires on delete of Urls.
     */
    @Output()
    public onDeleteResponderUrls = new EventEmitter<TStringRow[]>();

    /**
     * Template ref for URL grid row.
     */
    @ViewChild('urlTemplateRef')
    public urlTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Responder Url grid config.
     */
    public responderUrlListGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        this.responderUrlListGridConfig = {
            fields: [{
                id: 'ocsp-responder-url',
                label: this.l10nService.getMessage(l10nKeys.columnTitleUrl),
                templateRef: this.urlTemplateRef,
            }],
            getRowId(index: number): number {
                return index;
            },
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.actionTitleRemove),
                onClick: (urls: TStringRow[]) => {
                    this.deleteResponderUrls(urls);
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.actionTitleRemove),
                shape: 'trash',
                onClick: (url: TStringRow): void => {
                    this.deleteResponderUrls([url]);
                },
            }],
        };
    }

    /**
     * Handler for responder url add operation.
     */
    public addResponderUrl(): void {
        this.onAddResponderUrl.emit();
    }

    /**
     * Handler for responder url delete operation.
     */
    private deleteResponderUrls(urls: TStringRow[]): void {
        this.onDeleteResponderUrls.emit(urls);
    }
}
