/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './persistence-profile-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name persistenceProfileListPage
 * @desription
 *
 *     Regular list view component for Persistent Profiles.
 */
class PersistenceProfileListPageController {
    constructor(
        CRUDGridConfig,
        PersistenceProfileCollection,
        l10nService,
    ) {
        this.CRUDGridConfig_ = CRUDGridConfig;
        this.PersistenceProfileCollection_ = PersistenceProfileCollection;

        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
        this.l10nService_ = l10nService;
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        const gridConfig = new this.CRUDGridConfig_();

        gridConfig.collection = new this.PersistenceProfileCollection_();

        const { objectName } = gridConfig.collection;

        gridConfig.id = `${objectName}-list-page`;

        gridConfig.fields = [
                {
                    name: 'data.config.name',
                    title: l10nService.getMessage(l10nKeys.columnTitleName),
                    sortBy: 'name',
                }, {
                    name: 'type',
                    title: l10nService.getMessage(l10nKeys.columnTitleType),
                    template: '<span class="sel-type">' +
                    '{{ row.getType() | enum: "PERSISTENCE_TYPE_" }}</span>',
                },
        ];

        gridConfig.singleactions = [{
            title: l10nService.getMessage(l10nKeys.actionBtnEdit),
            class: 'icon-pencil-4',
            hidden: row => !row.isEditable() && !row.isClonable(),
            do(row) {
                row.isEditable() ? row.edit() : this.config.collection.clone(row);
            },
        }];

        this.gridConfig = gridConfig;
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

PersistenceProfileListPageController.$inject = [
    'CRUDGridConfig',
    'PersistenceProfileCollection',
    'l10nService',
];

angular.module('aviApp').component('persistenceProfileListPage', {
    controller: PersistenceProfileListPageController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});
