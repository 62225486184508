/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-exclude-list-entry-modal';
const prefix = `${moduleName}.${componentName}`;

export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const subnetInputLabel = `${prefix}.subnetInputLabel`;
export const subnetInputPlaceholder = `${prefix}.subnetInputPlaceholder`;
export const pathInputLabel = `${prefix}.pathInputLabel`;
export const pathInputPlaceholder = `${prefix}.pathInputPlaceholder`;
export const matchElementInputLabel = `${prefix}.matchElementInputLabel`;
export const matchElementInputPlaceholder = `${prefix}.matchElementInputPlaceholder`;
export const caseSensitiveLabel = `${prefix}.caseSensitiveLabel`;
export const regexMatchLabel = `${prefix}.regexMatchLabel`;

export const ENGLISH = {
    [generalSectionHeader]: 'General',
    [subnetInputLabel]: 'Subnet',
    [subnetInputPlaceholder]: 'Enter Subnet',
    [pathInputLabel]: 'Path',
    [pathInputPlaceholder]: 'Enter Path',
    [matchElementInputLabel]: 'Match Element',
    [matchElementInputPlaceholder]: 'Enter Match Element',
    [caseSensitiveLabel]: 'Case Sensitive',
    [regexMatchLabel]: 'Regex Match',
};
