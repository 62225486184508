/**
 * @module IPReputationDBModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    IPReputationDB,
    IPReputationDBCollection,
} from '.';

const ipReputationDBModule = angular.module('avi/ip-reputation-db');
const factories = [
    {
        dependency: IPReputationDB,
        name: 'IPReputationDB',
    },
    {
        dependency: IPReputationDBCollection,
        name: 'IPReputationDBCollection',
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(ipReputationDBModule, 'factory', name, dependency);
});
