/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './vs-access-policy-settings.component.less';
import * as l10n from './vs-access-policy-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.vsAccessPolicySettingsBindings
 * @see {@link module:avi/vs.vsAccessPolicySettingsComponent vsAccessPolicySettingsComponent}
 */
class VsAccessPolicySettingsController {
    constructor(defaultValues, l10nService) {
        this.defaultValues_ = defaultValues;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        const {
            samlSpConfig,
            ssoPolicyRef,
            jwtConfig,
            ldapVsConfig,
        } = this;

        /**
         * Model value for Access type radio buttons (saml/pingaccess/none).
         * UI Specific.
         * @type {string}
         */
        this.accessPolicyType = this.getAccessPolicyType_(samlSpConfig, ssoPolicyRef, jwtConfig,
            ldapVsConfig);
    }

    /**
     * Returns access policy type (SAML/PingAccess/None) of a VS.
     * @param {Object} samlSpConfig
     * @param {string} ssoPolicyRef
     * @param {Object} jwtConfig
     * @param {Object} ldapVsConfig
     * @returns {string} access policy type
     * @protected
     */
    getAccessPolicyType_(samlSpConfig, ssoPolicyRef, jwtConfig, ldapVsConfig) {
        if (!_.isEmpty(samlSpConfig)) {
            return 'saml';
        } else if (!_.isEmpty(jwtConfig)) {
            return 'jwt';
        } else if (!_.isEmpty(ldapVsConfig)) {
            return 'ldap';
        } else if (ssoPolicyRef) {
            return 'pingaccess';
        }

        return 'none';
    }

    /**
     * Fires when access type is changed.
     * Clears ssoPolicyRef and samlSpConfig fields.
     */
    clearAccessPolicySettings() {
        delete this.samlSpConfig;
        delete this.ssoPolicyRef;
        delete this.jwtConfig;
        delete this.ldapVsConfig;

        switch (this.accessPolicyType) {
            case 'saml':
                this.setDefaultSamlSettings_();
                break;
            case 'jwt':
                this.setDefaultJwtSettings_();
                break;
            case 'ldap':
                this.setDefaultLdapSettings_();
                break;
        }
    }

    /**
     * Fires when user clicks at SAML radio button.
     * Sets samlSpConfig default values to show in input fields.
     * @protected
     */
    setDefaultSamlSettings_() {
        this.samlSpConfig = {};

        const defaultSamlConfig =
            this.defaultValues_.getDefaultItemConfigByType('SAMLSPConfig');

        Object.assign(this.samlSpConfig, defaultSamlConfig);
    }

    /**
     * Fires when user clicks at JWT radio button.
     * Sets jwtConfig default values to show in input fields.
     * @protected
     */
    setDefaultJwtSettings_() {
        this.jwtConfig = {};

        const defaultJWTConfig =
            this.defaultValues_.getDefaultItemConfigByType('JWTValidationVsConfig');

        Object.assign(this.jwtConfig, defaultJWTConfig);
    }

    /**
     * Fires when user clicks at LDAP radio button.
     * Sets ldapVsConfig default values to show in input fields.
     * @protected
     */
    setDefaultLdapSettings_() {
        this.ldapVsConfig = {};

        const defaultLDAPConfig =
            this.defaultValues_.getDefaultItemConfigByType('LDAPVsConfig');

        Object.assign(this.ldapVsConfig, defaultLDAPConfig);
    }
}

VsAccessPolicySettingsController.$inject = [
    'defaultValues',
    'l10nService',
];

/**
 * @name vsAccessPolicySettingsComponent
 * @memberOf module:avi/vs
 * @description
 *     Access policy settings (SAML & PingAccess) for virtual service.
 *     Only applicable for HTTP Virtual Services.
 * @param {module:avi/vs.vsAccessPolicySettingsBindings} bindings
 * @param {module:avi/vs.VsAccessPolicySettingsController} controller
 * @author Aravindh Nagarajan
 */
angular.module('avi/vs').component('vsAccessPolicySettings', {
    /**
     * @mixin vsAccessPolicySettingsBindings
     * @memberOf module:avi/vs
     * @property {Object} samlSpConfig VirtualService#data#config#saml_sp_config
     * @property {string} ssoPolicyRef VirtualService#data#config#sso_policy_ref
     * @property {Object} jwtConfig VirtualService#data#config#jwt_config
     * @property {Object} ldapVsConfig VirtualService#data#config#ldapVsConfig
     */
    bindings: {
        samlSpConfig: '=',
        ssoPolicyRef: '=',
        jwtConfig: '=',
        ldapVsConfig: '=',
    },
    controller: VsAccessPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'vs-access-policy-settings.component.html',
});
