/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './CollectionDropdownCustom.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name collectionDropdownCustom
 * @restrict E
 * @param {string|string[]} ngModel - String with full Item ref (URL) or array of such strings.
 * @param {Collection} options - Collection of Items we are working with.
 * @param {Function=} ngChange - Callback to be called on ngModel change.
 * @param {boolean=} ngDisabled - Dropdown is disabled when this value evaluates to true.
 * @param {boolean=} ngRequired - Dropdown value is considered to be required if set.
 * @param {Function=} onclear - Callback to be called while clearing the selected option
 * @param {string=} allowClear - Button to reset the value is available for user when evaluates
 * to true.
 * @param {string=} ngPattern - RegEx Pattern to be matched against the selected option.
 * @param {string} search - Search field for options list is available when evaluated to true.
 * @param {boolean=} allowEdit - Won't have edit button if attribute is set and evaluates to false.
**/
angular.module('aviApp').directive('collectionDropdownCustom',
    ['$timeout', '$window', 'Regex', 'l10nService',
function($timeout, $window, Regex, l10nService) {
    function link(scope, elm, attr, ctrl) {
        scope.attr = attr;
        scope.Regex = Regex;

        l10nService.registerSourceBundles(dictionary);

        scope.l10nKeys = l10nKeys;

        /**
         * Shortcuts to DOM elements
         * @type {jQuery}
        */
        const elContainer = elm.find('.dropdown-container');
        const elOptions = elm.find('.options');
        const searchContainer = elm.find('.dropdown-search');
        const elOverlay = elm.find('.drop-mask');
        const elScrollable = elm.find('.scrollable');

        scope.expanded = false;

        function calcViewpoOffsetAndLimit() {
            const
                height = Math.max(150, elScrollable.height()), //should match LESS minheight
                row = _.sample(elScrollable.find('> ul > li')),
                rowHeight = row ? $(row).outerHeight() : 30;

            return {
                viewportSize: Math.ceil(height / rowHeight),
                offset: Math.floor(elScrollable.scrollTop() / rowHeight),
            };
        }

        function updateCollectionOffsetAndLimit() {
            const sizeAndOffset = calcViewpoOffsetAndLimit();

            if (sizeAndOffset.viewportSize !== Infinity && !_.isNaN(sizeAndOffset.offset)) {
                scope.options.updateViewportSize(sizeAndOffset.viewportSize);
                scope.options.updateItemsVisibility(0, sizeAndOffset.offset);
            }
        }

        /**
         * Watching and validating ngModel
         */
        scope.$watch('ngModel', function(newValue, oldValue) {
            if (newValue === oldValue) {
                if (!angular.isUndefined(newValue)) {
                    if (scope.type === 'group') {
                        const id = scope.ngModel.slug();

                        if (id && scope.allowEdit !== false) {
                            const item = scope.options.createNewItem({ id }, true);

                            item.load();
                            scope.selectedItem = item;
                        }
                    }
                }
            }
        });

        /**
         * Infinite scroll logic
         * (Debounce and throttle didn't work well, did it on my own)
        */
        elScrollable.on('scroll', updateCollectionOffsetAndLimit);

        /**
         * Hides options panel when overlay clicked
         */
        elOverlay.on('mousedown', function() {
            scope.hideOptions();
        });

        /**
         * Asks collection to open create modal
         */
        scope.create = function() {
            scope.options.create().then(function(item) {
                // When new item created or saved need to refresh selection
                scope.select(item);
            });
            scope.hideOptions();
        };

        /**
         * Returns true if something selected
         * @returns {boolean}
         */
        scope.isNotEmpty = function() {
            return !!scope.ngModel;
        };

        /**
         * Tries to get the name of the option
         * @param value - option value
         * @returns {string}
         */
        scope.getLabel = function(option) {
            if (option) {
                if (typeof option.name === 'function' && option.name()) {
                    return option.name();
                } else if (typeof option.name === 'string') {
                    return option.name;
                } else if (option.slug()) {
                    return option.slug();
                } else {
                    return option;
                }
            }

            return '';
        };

        /**
         * Shows options panel
         */
        scope.showOptions = function() {
            if (scope.ngDisabled) {
                return;
            }

            elOptions.show();
            elOverlay.show();
            scope.expanded = true;
            scope.filter = '';
            scope.options.setSearch();
            searchContainer.trigger('focus'); //sets the focus on search field
            scope.options.load(undefined, true);
            elScrollable.scrollTop(0);

            const updatePanelSizeAndPosition = function() {
                elOptions.width(elContainer.width());
            };

            updatePanelSizeAndPosition();
            $($window).on('resize', updatePanelSizeAndPosition);
            $($window).on('scroll', updatePanelSizeAndPosition);
        };

        /**
         * Hides options panel
         */
        scope.hideOptions = function() {
            elOptions.hide();
            elOverlay.hide();
            scope.expanded = false;
            $($window).off('resize scroll');
        };

        /**
         * Selects the item
         * @param item {object}
         */
        scope.select = function(item) {
            scope.ngModel = item.data.config.url;
            scope.type = 'group';
            scope.selectedItem = item;
            // Sets the field to dirty state
            ctrl.$setViewValue(scope.ngModel);
            $timeout(function() {
                // Call ngChange
                scope.ngChange();
            });
        };

        scope.setCustom = function() {
            scope.ngModel = '';
            scope.type = 'custom';
            scope.selectedItem = null;
            // Sets the field to dirty state
            ctrl.$setViewValue(scope.ngModel);
            $timeout(function() {
                elm.find('input').trigger('focus');
            });
        };

        scope.onChange = function() {
            // Sets the field to dirty state
            ctrl.$setViewValue(scope.ngModel);
            scope.ngChange();
        };

        /**
         * Returns the next item after selected
         * @returns {object}
         */
        scope.getNextItem = function() {
            let index;

            for (let ii = 0; ii < scope.options.items.length; ii++) {
                if (scope.ngModel.slug() === scope.options.items[ii].id) {
                    index = ii;
                    break;
                }
            }

            if (scope.options.items[index + 1]) {
                return scope.options.items[index + 1];
            }
        };

        /**
         * Returns previous item after selected
         * @returns {object}
         */
        scope.getPrevItem = function() {
            let index;

            for (let ii = 0; ii < scope.options.items.length; ii++) {
                if (scope.ngModel.slug() === scope.options.items[ii].id) {
                    index = ii;
                    break;
                }
            }

            if (scope.options.items[index - 1]) {
                return scope.options.items[index - 1];
            }
        };

        scope.optionSelected = function(item) {
            if (!scope.ngModel || typeof scope.ngModel !== 'string') {
                return false;
            }

            return item.data.config.url.slug() == scope.ngModel.slug();
        };

        /**
         * Filters collection, debouncing multiple calls
         * Used to search in options
         * (Debounce and throttle didn't work well, did it on my own)
         */
        let searchTimer = null;

        scope.doSearch = function(query) {
            $timeout.cancel(searchTimer);
            searchTimer = $timeout(function() {
                scope.options.search(query);
            }, 500);
        };

        scope.isEditable = function() {
            return !scope.ngDisabled && scope.allowEdit !== false &&
                scope.selectedItem && scope.selectedItem.isEditable &&
                scope.selectedItem.isEditable();
        };

        /**
         * Triggers collection to open edit dialog
         */
        scope.edit = function() {
            if (scope.ngDisabled) {
                return;
            }

            let editable = scope.options.getItemById(scope.ngModel.slug());

            if (!editable) {
                editable = scope.options.createNewItem({ id: scope.ngModel.slug() }, true);
            }

            if (editable.isEditable()) {
                editable.load().then(function() {
                    editable.edit().then(function() {
                        scope.select(editable);
                    });
                });
            }
        };

        scope.options.bind('collectionLoadSuccess', updateCollectionOffsetAndLimit);

        scope.$on('$destroy', function() {
            scope.options.unbind('collectionLoadSuccess',
                updateCollectionOffsetAndLimit);
        });

        ctrl.$validators.required = function() {
            const ngReq = scope.ngRequired === true;
            const attrReq = attr['required'] === true;

            if (ngReq || attrReq) {
                return !_.isEmpty(scope.ngModel);
            } else {
                return true;
            }
        };

        ctrl.$validators.pattern = function() {
            if (_.isEmpty(scope.ngModel)) {
                return true;
            }

            if (!_.isUndefined(scope.ngPattern) && scope.type === 'custom') {
                const models = scope.ngModel.split(',');

                return _.every(models, model => scope.ngPattern.test(model));
            }

            return true;
        };
    }

    return {
        scope: {
            ngModel: '=',
            type: '=',
            options: '=',
            ngChange: '&',
            onclear: '&',
            ngDisabled: '=',
            ngRequired: '=',
            allowClear: '<',
            search: '@',
            ngPattern: '=',
            allowEdit: '<?',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/collection-dropdown-custom.html',
        require: 'ngModel',
        link,
    };
}]);
