/** @WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IWafExclusionType,
    MatchCase,
    StringOperation,
} from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

export const WAF_EXCLUSION_TYPE_CONFIG_ITEM_TOKEN = 'WafExclusionTypeConfigItem';

export class WafExclusionTypeConfigItem extends MessageItem<IWafExclusionType> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafExclusionType',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public canFlatten(): boolean {
        return this.isValid();
    }

    /** @override */
    public isValid(): boolean {
        const {
            match_op: matchOp,
            match_case: matchCase,
        } = this.config;

        return Boolean(matchOp || matchCase);
    }

    /**
     * Returns true if the match_case is set to case sensitive.
     */
    public get isCaseSensitive(): boolean {
        return this.config.match_case === MatchCase.SENSITIVE;
    }

    /**
     * Returns true if the match operation is set to regex match.
     */
    public get isRegexMatch(): boolean {
        return this.config.match_op === StringOperation.REGEX_MATCH;
    }

    /**
     * Sets the match_case to SENSITIVE or INSENSITIVE.
     */
    public setCaseSensitive(sensitive = false): void {
        this.config.match_case = sensitive ? MatchCase.SENSITIVE : MatchCase.INSENSITIVE;
    }

    /**
     * Sets theh match operation to REGEX_MATCH or EQUALS.
     */
    public setRegexMatch(regexMatch = false): void {
        this.config.match_op = regexMatch ? StringOperation.REGEX_MATCH : StringOperation.EQUALS;
    }
}
