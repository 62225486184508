/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    IPReputationDB,
    IPReputationDBStatus,
} from 'ajs/modules/ip-reputation-db';
import {
    IPReputationDBListPageContainerService,
    IPReputationDBRegistrationStatus,
} from './ip-reputation-db-list-page-container.service';

export enum IPReputationDBDisplayStatus {
    ACTIVE = 'ACTIVE',
    ERROR = 'ERROR',
    UNREGISTERED = 'UNREGISTERED',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
}

const combinedStatuses = {
    ...IPReputationDBStatus,
    ...IPReputationDBRegistrationStatus,
};

/**
 * Hash mapping enums from IPReputationDBRegistrationStatus and IPReputationDBStatus to
 * IPReputationDBDisplayStatus.
 */
const statusHash = Object.keys(combinedStatuses).reduce((hash, status) => {
    return {
        ...hash,
        [status]: IPReputationDBDisplayStatus[status],
    };
}, {});

/**
 * @description Container component for the IP Reputation DB List Page.
 * @author alextsg
 */
@Component({
    selector: 'ip-reputation-db-list-page-container',
    templateUrl: './ip-reputation-db-list-page-container.component.html',
})
export class IPReputationDBListPageContainerComponent implements OnInit, OnDestroy {
    /**
     * IP Reputation DB item, resolved by ui-router.
     */
    @Input('resolveIPReputationDB')
    public activeIPReputationDB: IPReputationDB;

    /**
     * Registration status. Can be UNREGISTERED, DISABLED, or ENABLED.
     */
    private registrationStatus: IPReputationDBRegistrationStatus =
    IPReputationDBRegistrationStatus.ENABLED;

    constructor(
        private readonly ipReputationDBService: IPReputationDBListPageContainerService,
        @Inject('$state')
        private readonly $state: StateService,
    ) {}

    /** @override */
    public ngOnInit(): void {
        this.setRegistrationStatus();
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.activeIPReputationDB) {
            this.activeIPReputationDB.destroy();
        }
    }

    /**
     * Returns the overall status, which is a combination of both the registration status and the
     * IPReputationDB item status. If the active IP Reputation DB item is not available yet, the
     * registration status is shown (REGISTERED, DISABLED, or ENABLED). When the active IP
     * Reputation DB item is available, the status of the item is shown.
     */
    public get status(): IPReputationDBDisplayStatus {
        if (!this.activeIPReputationDB) {
            return statusHash[this.registrationStatus];
        }

        return this.registrationStatus !== IPReputationDBRegistrationStatus.ENABLED ?
            statusHash[this.registrationStatus] :
            statusHash[this.activeIPReputationDB.status];
    }

    /**
     * Redirects to the Cloud services page.
     */
    public redirectToCloudServices(): void {
        this.$state.go('authenticated.administration.settings.cloud-services');
    }

    /**
     * Sets the registration status, retrieved asynchronously from the ipReputationDBService.
     */
    private async setRegistrationStatus(): Promise<void> {
        this.registrationStatus = await this.ipReputationDBService.getRegistrationStatus();
    }
}
