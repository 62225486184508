/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

export class CloudCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            allDataSources: {
                inventory: {
                    source: 'InventoryCollDataSource',
                    transformer: 'InventoryDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config', 'status'],
                },
            },
            defaultDataSources: 'inventory',
            isStatic: false,
            objectName: 'cloud-inventory',
            permissionName: 'PERMISSION_CLOUD',
            windowElement: 'infra-cloud-create',
            restrictCreateOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('Cloud');

        this.serverDefaultsOverride_ = {
            rancher_configuration: undefined,
            docker_configuration: undefined,
        };
    }
}

CloudCollection.ajsDependencies = [
    'Cloud',
];
