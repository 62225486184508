/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import {
    AviPermissionResource,
    IServerAutoScalePolicy,
} from 'generated-types';
import { AutoScalePolicyModalComponent } from 'ng/modules/auto-scale';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import * as l10n from '../auto-scale.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for AutoScalePolicy item.
 */
export const AUTO_SCALE_POLICY_ITEM_TOKEN = 'AutoScalePolicy';

/**
 * @description
 *
 *   AutoScale policy Item
 *
 * @author Ram Pal, Nisar Nadaf
 */
export class AutoScalePolicy extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'serverautoscalepolicy',
            objectType: 'ServerAutoScalePolicy',
            windowElement: AutoScalePolicyModalComponent,
            permissionName: AviPermissionResource.PERMISSION_AUTOSCALE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public dataToSave(): IServerAutoScalePolicy {
        const config: IServerAutoScalePolicy = super.dataToSave();

        if (!config.intelligent_autoscale) {
            delete config.intelligent_scaleout_margin;
            delete config.intelligent_scalein_margin;
        }

        return config;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.autoScalePolicyModalBreadcrumbTitle);
    }

    /**
     * Getter for intelligent_autoscale.
     */
    public get isIntelligentAutoscaleEnabled(): boolean {
        return this.config.intelligent_autoscale;
    }
}
