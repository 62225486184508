/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name cell
 * @restrict E
 * @param {Object} row
 * @param {Object} field - Gridconfig.fields element.
 * @param {Object} config - GridConfig.
 * @param {*} ngDisabled - Disabled flag passed by {@link grid} or {@link collectionGrid}.
 * @description
 *
 *     Helps to transform data for display
 *     Works with field.transform function to transform data, field.template
 *     property as a template and metricChart as boolean or object with settings for cell-sparkline.
 *
 */
angular.module('aviApp').directive('cell', [
'$compile', 'Auth', 'Regex', 'gridConfigTools',
function($compile, Auth, Regex, gridConfigTools) {
    function cellLink(scope, $elm) {
        const { field } = scope;

        scope.Auth = Auth;
        scope.Regex = Regex;

        if (!field) {
            return;
        }

        const fieldClassName = gridConfigTools.getFieldClassName(field);

        $elm.addClass(`grid-cell-${fieldClassName} sel-cell-${fieldClassName}`);

        let wrapper;

        const {
            name,
            transform,
            template,
            metricChart,
            require,
        } = field;

        //for angular-driven field#disabled support
        if (angular.isFunction(field.disabled)) {
            wrapper = $('<div ng-show="!field.disabled(row)"/>');

            $elm.append(
                $compile(wrapper)(scope),
                $compile('<div ng-if="field.disabled(row)">N/A</div>')(scope),
            );
        }

        let cellElm;

        if (transform && angular.isFunction(transform)) {
            cellElm = $('<span ng-bind-html="field.transform(row)"></span>');
        } else if (template && angular.isString(template)) {
            //wrap bare angularJs expression
            if (template.indexOf('{{') === 0 &&
                template.lastIndexOf('}}') === template.length - 2) {
                cellElm = $(`<span>${template}</span>`);
            } else {
                cellElm = $(template);
            }
        } else if (metricChart && require && angular.isString(require)) {
            cellElm = $('<cell-sparkline/>')
                .addClass(`sel-${require.replace(/\./g, '-')}`)
                .attr({
                    series: `row.getMetric("${require}").getMainSeries()`,
                    'mouse-updates-charts': true,
                    'is-loading': `row.getMetric("${require}").isLoading()`,
                });
        } else if (name && angular.isString(name)) {
            cellElm = $(`<span title="{{row.${name}}}">{{row.${name}}}</span>`);
        }

        cellElm.appendTo(wrapper || $elm);
        $compile(cellElm)(scope);
    }

    return {
        scope: {
            row: '<',
            field: '<',
            config: '<',
            ngDisabled: '<',
        },
        restrict: 'E',
        link: cellLink,
    };
}]);
