/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name SEGroupFlavorListCollDataSource
 * @desc Flavors DataSource for {@link SEGroupFlavorCollection}.
 */
angular.module('aviApp').factory('SEGroupFlavorListCollDataSource', [
'ListCollDataSource',
function(ListCollDataSource) {
    class SEGroupFlavorListCollDataSource extends ListCollDataSource {
        /** @override */
        getRequestParams_(params) {
            const
                { owner_: owner } = this,
                { cloudId } = owner,
                reqProps = {
                    objectName_: [
                        'cloud',
                        cloudId,
                        'flavors',
                    ],
                };

            return angular.extend(super.getRequestParams_(params), reqProps);
        }
    }

    angular.extend(SEGroupFlavorListCollDataSource.prototype, {
        defaultParams_: {
            includeName_: false,
        },
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
    });

    return SEGroupFlavorListCollDataSource;
}]);
