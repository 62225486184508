/**
 * @module TenancyModeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { AviModalService } from 'ajs/modules/core/services/avi-modal';
import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { TenantSettingsService } from 'ajs/modules/accounts';
import * as l10n from './tenancy-mode-page.l10n';
import './tenancy-mode-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Parent component holding Tenancy related component(s).
 * @author Akul Aggarwal
 */
@Component({
    selector: 'tenancy-mode-page',
    templateUrl: './tenancy-mode-page.component.html',
})
export class TenancyModePageComponent {
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly aviModalService: AviModalService,
        public readonly tenantSettingsService: TenantSettingsService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Opens settings modal window.
     */
    public edit(): void {
        this.aviModalService.open('tenant-settings-modal');
    }
}
