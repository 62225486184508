/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CportalModule */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    caseListComponentOptions,
    CASE_LIST_COMPONENT_TOKEN,
} from './components';

import {
    CportalService,
    CPORTAL_SERVICE_TOKEN,
} from './services';

import {
    Case,
    CaseCollection,
    CASE_COLLECTION_TOKEN,
    CASE_ITEM_TOKEN,
    PortalInfo,
    PORTAL_INFO_ITEM_TOKEN,
} from './factories';

const cportalModule = angular.module('avi/cportal');

const services = [
    {
        dependency: CportalService,
        name: CPORTAL_SERVICE_TOKEN,
    },
];

const factories = [
    {
        factory: Case,
        name: CASE_ITEM_TOKEN,
    },
    {
        factory: CaseCollection,
        name: CASE_COLLECTION_TOKEN,
    },
    {
        factory: PortalInfo,
        name: PORTAL_INFO_ITEM_TOKEN,
    },
];

const components = [
    {
        name: CASE_LIST_COMPONENT_TOKEN,
        options: caseListComponentOptions,
    },
];

services.forEach(({ name, dependency }) => {
    initAjsDependency(cportalModule, 'service', name, dependency);
});

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        cportalModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => cportalModule.component(name, options));
