/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './pingaccess-agent-profile-list.less';
import * as l10n from './pingaccess-agent-profile-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'pingaccess-agent-profile-list';

class PingAccessAgentProfileListController {
    constructor(
        $element,
        PingAccessAgentProfileCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.$element_ = $element;
        this.PingAccessAgentProfileCollection_ = PingAccessAgentProfileCollection;
        this.CRUDGridConfig_ = CRUDGridConfig;

        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.l10nService_ = l10nService;
    }

    /**
     * Creates the gridConfig and creates an instance of pingAccessAgentProfileCollection.
     */
    $onInit() {
        const { l10nService_: l10nService } = this;

        this.$element_.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.PingAccessAgentProfileCollection_(),
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.actionBtnDelete),
                class: 'icon-trash',
                do: row => {
                    this.gridConfig.collection.dropItems(row);

                    return true;
                },
            }],
            expandedContainerTemplate:
                `<pre class="pingaccess-agent-profile-list__expanded-row-details">
                    {{::row.getConfig().properties_file_data}}</pre>`,
            fields: [
                {
                    name: 'name',
                    title: l10nService.getMessage(l10nKeys.columnTitleName),
                    template: '{{::row.getName()}}',
                },
                {
                    name: 'pool',
                    title: l10nService.getMessage(l10nKeys.columnTitlePool),
                    template: '{{::row.getConfig().pingaccess_pool_ref | name}}',
                },
            ],
            permission: 'PERMISSION_PINGACCESSAGENT',
        };

        const { objectName } = gridConfigOptions.collection;

        gridConfigOptions.id = `${objectName}-list-page`;

        this.gridConfig = new this.CRUDGridConfig_(gridConfigOptions);
    }

    /**
     * Destroys the pingAccessAgentProfileCollection instance, cancelling any pending requests.
    */
    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

PingAccessAgentProfileListController.$inject = [
    '$element',
    'PingAccessAgentProfileCollection',
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @ngdoc component
 * @name pingAccessAgentProfileList
 * @author Chitra Flarid
 * @description pingAccess profile page for creating new PingAccess Agent.
 */
angular.module('aviApp').component('pingAccessAgentProfileList', {
    controller: PingAccessAgentProfileListController,
    template:
        `<div class="templatesList">
            <collection-grid config="$ctrl.gridConfig"></collection-grid>
        </div>`,
});
