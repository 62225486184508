/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './InfraGatewayMonitorController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name InfraGatewayMonitorController
 */
class InfraGatewayMonitorController {
    constructor($scope, l10nService) {
        $scope.$parent.modalScope = $scope;
        $scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

InfraGatewayMonitorController.$inject = [
    '$scope',
    'l10nService',
];

angular.module('avi/vrf')
    .controller('InfraGatewayMonitorController', InfraGatewayMonitorController);
