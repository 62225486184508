/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    extent as d3Extent,
} from 'd3';

const d3 = {
    extent: d3Extent,
};

angular.module('charts').factory('MetricChart', [
'Chart', 'chartUtils', 'colors', 'Metric',
function(Chart, chartUtils, colors, Metric) {
    /**
     * Chart component that uses Metric instances to self-update.
     */
    class MetricChart extends Chart {
        /**
         * Returns nested array of x and y values from specified array of objects.
         * @param {Array<{x: number, y: number}>} values - Array of objects with x and y values.
         * @returns {Array<ChartPoint>}
         */
        static convertToChartData(values = []) {
            return values.map(v => [v.timestamp, v.noData ? null : v.value]);
        }

        /**
         * Creates ChartConfig list from metrics.
         * @param {Metric} metric
         * @returns {ChartConfig}
         */
        static metricToChart(metric) {
            const chart = {
                series0: [],
                series1: [],
            };
            let curUnits = '';
            let curSeries = 'series1';
            const series = metric.getSeries() || [];

            series.forEach(series => {
                if (series.hasData()) {
                    const { header, values } = series.getData();
                    const { dominators } = series;
                    const id = series.getId();
                    const { units } = header;

                    // switch between 2 series when units change
                    if (curUnits !== units) {
                        curUnits = units;
                        curSeries = curSeries === 'series1' ? 'series0' : 'series1';
                    }

                    let unitsShort = '';
                    let unitsLong = '';
                    const unitMap = MetricChart.unitMap[units];

                    if (unitMap) {
                        unitsShort = unitMap.short;
                        unitsLong = unitMap.long;
                    }

                    const lineSeries = {
                        name: series.getTitle(),
                        data: MetricChart.convertToChartData(values),
                        unitsLong,
                        unitsShort,
                        dominators,
                        id,
                    };

                    chart[curSeries].push(lineSeries);

                    if (Array.isArray(header.stringValuesList)) {
                        lineSeries.domain = header.stringValuesList.concat();
                    } else {
                        lineSeries.domain = d3.extent(lineSeries.data, d => d[1]);

                        const { domain } = lineSeries;
                        let { metrics_min_scale: minScale } = header;

                        minScale = minScale || 0;

                        const last = domain[1];

                        if (last < minScale) {
                            domain[1] = minScale;
                        }

                        domain[0] = Math.min(domain[0], 0);
                    }
                }
            });

            return chart;
        }

        constructor(config, tooltip) {
            super(config, tooltip);

            /** @type {Metric[]} */
            this.stackMetrics = null;

            /** @type {Metric} */
            this.lineMetric = null;

            /** @type {ChartSeries[]} */
            this.stackSeries = null;

            /** @type {ChartSeries[]} */
            this.lineSeries0 = null;

            /** @type {ChartSeries[]} */
            this.lineSeries1 = null;

            /** @type {string[]} */
            this.colors = [];

            /** @type {string} */
            this.type = MetricChart.TYPE_LINE;

            this.renderStack = this.renderStack.bind(this);
            this.renderLines = this.renderLines.bind(this);
            this.renderType = this.renderType.bind(this);
        }

        /**
         * Renders chart based on pre-selected type.
         */
        renderType() {
            switch (this.type) {
                case MetricChart.TYPE_LINE:
                    this.renderLines();
                    break;
                case MetricChart.TYPE_STACK:
                    this.renderStack();
                    break;
            }
        }

        /**
         * Sets chart type.
         * @param {string} type
         */
        setType(type = MetricChart.TYPE_LINE) {
            this.type = type;
        }

        /**
         * Renders stack chart.
         */
        renderStack() {
            if (Array.isArray(this.stackMetrics)) {
                const cfg = this.stackMetrics.map(m => MetricChart.metricToChart(m));
                const series = _.compact(cfg.map(c => c.series0[0]));

                if (series.length > 0) {
                    this.stackSeries = series;

                    const crs = series.map(s => colors.getHslColor(s.name));

                    this.colors = crs;

                    this.updateStack(series, crs);
                    this.emit(MetricChart.METRIC_STACK_RENDER, this);
                }
            }
        }

        /**
         * Renders line chart.
         */
        renderLines() {
            if (this.lineMetric) {
                const cfg = MetricChart.metricToChart(this.lineMetric);
                const { series0, series1 } = cfg;

                this.lineSeries0 = series0;
                this.lineSeries1 = series1;

                const domain0 = chartUtils.getDomainFromSeries(series0);

                this.setY0Domain(domain0);

                const crs = [];

                crs[0] = series0.map(s => colors.getHslColor(s.name));

                if (series1.length) {
                    const domain1 = chartUtils.getDomainFromSeries(series1);

                    this.setY1Domain(domain1);
                    crs[1] = series1.map(s => colors.getHslColor(s.name));
                }

                this.colors = crs;

                this.updateLines(series0, series1, crs);
                this.emit(MetricChart.METRIC_LINE_RENDER, this);
            }
        }

        /**
         * Updates current line Metric instance.
         * @param {Metric} lineMetric
         */
        setLineMetric(lineMetric) {
            this.lineMetric = lineMetric;
            lineMetric.on(Metric.SERIES_UPDATE_EVENT, this.renderType);
            lineMetric.on(Metric.SERIES_LIST_UPDATE_EVENT, this.renderType);
            this.renderType();
        }

        /**
         * Updates current stack Metric instance.
         * @param {Metric[]} stackMetrics
         */
        setStackMetric(stackMetrics) {
            this.stackMetrics = stackMetrics;
            stackMetrics.forEach(metric => {
                metric.on(Metric.SERIES_UPDATE_EVENT, this.renderType);
                metric.on(Metric.SERIES_LIST_UPDATE_EVENT, this.renderType);
            });
            this.renderType();
        }

        /**
         * Destroys lineMetric.
         */
        destroyLines() {
            if (this.lineMetric) {
                this.lineMetric.unbind(Metric.SERIES_UPDATE_EVENT, this.renderType);
                this.lineMetric.unbind(Metric.SERIES_LIST_UPDATE_EVENT, this.renderType);
                this.lineMetric = null;
            }
        }

        /**
         * Destroys stackMetrics.
         */
        destroyStack() {
            if (Array.isArray(this.stackMetrics)) {
                this.stackMetrics.forEach(metric => {
                    metric.unbind(Metric.SERIES_UPDATE_EVENT, this.renderType);
                    metric.unbind(Metric.SERIES_LIST_UPDATE_EVENT, this.renderType);
                });
                this.stackMetrics = null;
            }
        }

        /**
         * Destroys instance.
         */
        destroy() {
            super.destroy();
            this.destroyLines();
            this.destroyStack();
            this.removeAllListeners();
        }
    }

    MetricChart.unitMap = {
        PER_SECOND: {
            long: 'Per Second',
            short: '/s',
        },
        SECONDS: {
            long: 'Seconds',
            short: 's',
        },
        MILLISECONDS: {
            long: 'Milliseconds',
            short: 'ms',
        },
        PERCENT: {
            long: 'Percent',
            short: '%',
        },
    };

    MetricChart.METRIC_STACK_RENDER = 'metricStackRender';
    MetricChart.METRIC_LINE_RENDER = 'metricLineRender';
    MetricChart.TYPE_STACK = 'stack';
    MetricChart.TYPE_LINE = 'line';

    return MetricChart;
}]);
