/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AviPermissionResource } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { PingAccessAgentModalComponent } from 'ng/modules/security';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ping-access-agent-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for PingAccessAgent item.
 */
export const PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN = 'PingAccessAgentProfile';

/**
 * @description
 *
 *   Ping access agent profile Item
 *
 * @author Rohit Gaikwad
 */
export class PingAccessAgentProfile extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'pingaccessagent',
            windowElement: PingAccessAgentModalComponent,
            objectType: 'PingAccessAgent',
            permissionName: AviPermissionResource.PERMISSION_PINGACCESSAGENT,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.pingAccessAgentModalBreadcrumbTitle);
    }
}
