/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    IpAddrConfigItem,
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { HostServersModalComponent } from 'ng/modules/cloud';
import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';

import {
    IHostAttributes,
    ILinuxServerHost,
} from 'generated-types';

import { LinuxServerHost } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../cloud.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TlinuxServerHostPartial = Omit<ILinuxServerHost, 'host_attr' | 'host_ip'>;

interface IHostAttributesConfig extends IHostAttributes {
    config?: any;
    getConfig(): IHostAttributesConfig['config'];
}
interface ILinuxServerHostConfig extends TlinuxServerHostPartial {
    host_attr: RepeatedMessageItem<MessageItem<IHostAttributesConfig>>;
    host_ip: IpAddrConfigItem;
}

/**
 * Holds the constant string key values used in Host Servers Modal.
 */
export enum HostAttributeKeys {
    CPU = 'CPU',
    MEMORY = 'MEMORY',
    DPDK = 'DPDK',
    SE_INBAND_MGMT = 'SE_INBAND_MGMT',
}

/**
 * Holds the constant string values used in Host Servers Modal.
 */
export enum HostAttributeValues {
    All = 'All',
    Yes = 'Yes',
    False = 'False',
    Custom = 'Custom',
    No = 'No',
    True = 'True',
}

/**
 * Default Key value pairs for Host Attributes.
 * To be used for comparing if any key is missing in config when cloud is created using AKO.
 */
const defaultHostAttributesValues = {
    CPU: {
        attr_key: HostAttributeKeys.CPU,
        attr_val: HostAttributeValues.All,
    },
    MEMORY: {
        attr_key: HostAttributeKeys.MEMORY,
        attr_val: HostAttributeValues.All,
    },
    DPDK: {
        attr_key: HostAttributeKeys.DPDK,
        attr_val: HostAttributeValues.Yes,
    },
    SE_INBAND_MGMT: {
        attr_key: HostAttributeKeys.SE_INBAND_MGMT,
        attr_val: HostAttributeValues.False,
    },
};

/**
 * @description
 *
 *   LinuxServerHost Message Item.
 *
 * @author Sarthak kapoor
 */
export class LinuxServerHostConfigItem extends
    withEditChildMessageItemMixin<ILinuxServerHostConfig, typeof MessageItem>(
        withFullModalMixin(MessageItem),
    )<ILinuxServerHostConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: LinuxServerHost,
            windowElement: HostServersModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * We want to set the model for host_attibutes child modal similar to one expected at backend.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<ILinuxServerHostConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};
        const { ...defaults } = defaultConfig;

        return {
            ...defaults,
            host_attr: [
                {
                    ...defaultHostAttributesValues.CPU,
                },
                {
                    ...defaultHostAttributesValues.MEMORY,
                },
                {
                    ...defaultHostAttributesValues.DPDK,
                },
                {
                    ...defaultHostAttributesValues.SE_INBAND_MGMT,
                },
            ],
        };
    }

    /**
     * Returns the value for number of cores for Service Engines.
     */
    public get cores(): string {
        const hostAttributesConfig = this.getConfig().host_attr.getConfig();

        const filteredHostAttribute = hostAttributesConfig.find(
            (hostAttribute: IHostAttributesConfig) => {
                return hostAttribute.getConfig().attr_key === HostAttributeKeys.CPU;
            },
        );

        return filteredHostAttribute.getConfig().attr_val;
    }

    /**
     * Returns the amount of memory needed for Service Engines.
     */
    public get memoryAmount(): string {
        const hostAttributesConfig = this.getConfig().host_attr.getConfig();

        const filteredHostAttribute = hostAttributesConfig.find(
            (hostAttribute: IHostAttributesConfig) => {
                return hostAttribute.getConfig().attr_key === HostAttributeKeys.MEMORY;
            },
        );

        return filteredHostAttribute.getConfig().attr_val;
    }

    /**
     * Sets the value of DPDK Host Attribute on select/unselect of checkbox.
     */
    public setDpdk(isDpdkEnabled: boolean): void {
        const { host_attr: hostAttr } = this.getConfig();
        const hostAttributeConfig = hostAttr.getConfig();

        const filteredHostAttribute = hostAttributeConfig.find(
            (hostAttribute: IHostAttributesConfig) => {
                return hostAttribute.config.attr_key === HostAttributeKeys.DPDK;
            },
        );

        if (isDpdkEnabled) {
            filteredHostAttribute.config.attr_val = HostAttributeValues.Yes;
        } else {
            filteredHostAttribute.config.attr_val = HostAttributeValues.No;
        }
    }

    /**
     * Sets the value of SE_INBAND_MANAGEMENT Host Attribute on select/unselect of checkbox.
     */
    public setSeInbandManagement(isSeInbandManagementEnabled: boolean): void {
        const { host_attr: hostAttr } = this.getConfig();
        const hostAttributeConfig = hostAttr.getConfig();

        const filteredHostAttribute = hostAttributeConfig.find(
            (hostAttribute: IHostAttributesConfig) => {
                return hostAttribute.config.attr_key === HostAttributeKeys.SE_INBAND_MGMT;
            },
        );

        if (isSeInbandManagementEnabled) {
            filteredHostAttribute.config.attr_val = HostAttributeValues.True;
        } else {
            filteredHostAttribute.config.attr_val = HostAttributeValues.False;
        }
    }

    /**
     * Sets the value for Cores for Service Engines.
     */
    public setCoresForServiceEngines(
        coresForServiceEngines: string,
        numberofCoresForServiceEngines: string,
    ): void {
        const { host_attr: hostAttr } = this.getConfig();
        const hostAttributeConfig = hostAttr.getConfig();

        const filteredHostAttribute = hostAttributeConfig.find(
            (hostAttribute: IHostAttributesConfig) => {
                return hostAttribute.config.attr_key === HostAttributeKeys.CPU;
            },
        );

        if (coresForServiceEngines === HostAttributeValues.Custom) {
            filteredHostAttribute.config.attr_val = numberofCoresForServiceEngines;
        } else {
            filteredHostAttribute.config.attr_val = HostAttributeValues.All;
        }
    }

    /**
     * Sets the value for Memory amount for Service Engines.
     */
    public setMemoryForServiceEngines(
        memoryForServiceEngines: string,
        memoryAmountForServiceEngines: string,
    ): void {
        const { host_attr: hostAttr } = this.getConfig();
        const hostAttributeConfig = hostAttr.getConfig();

        const filteredHostAttribute = hostAttributeConfig.find(
            (hostAttribute: IHostAttributesConfig) => {
                return hostAttribute.config.attr_key === HostAttributeKeys.MEMORY;
            },
        );

        if (memoryForServiceEngines === HostAttributeValues.Custom) {
            filteredHostAttribute.config.attr_val = memoryAmountForServiceEngines;
        } else {
            filteredHostAttribute.config.attr_val = HostAttributeValues.All;
        }
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'host_ip',
            'host_attr',
        ];
    }

    /**
     * @override
     * This is to ensure that all the 4 default host attribute keys are set,
     * irresepective of whichever way the cloud is created.
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { host_attr: hostAttr } = this.getConfig();
        const hostAttributeConfig = hostAttr.getConfig();

        if (Array.isArray(hostAttributeConfig)) {
            for (const key in defaultHostAttributesValues) {
                if (Object.prototype.hasOwnProperty.call(defaultHostAttributesValues, key)) {
                    const filteredHostAttribute = hostAttributeConfig.find(
                        (hostAttribute: IHostAttributesConfig) => {
                            return hostAttribute.config.attr_key === key;
                        },
                    );

                    if (!filteredHostAttribute) {
                        hostAttr.add(defaultHostAttributesValues[key]);
                    }
                }
            }
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.linuxHostServerBreadcrumbTitle);
    }
}
