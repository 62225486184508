/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'recommendation-dialog';
const prefix = `${moduleName}.${componentName}`;

export const recommendationHeader = `${prefix}.recommendationHeader`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const acceptRecommendationButtonLabel = `${prefix}.acceptRecommendationButtonLabel`;
export const alreadyAppliedAlertMessage = `${prefix}.alreadyAppliedAlertMessage`;
export const failedAlertMessage = `${prefix}.failedAlertMessage`;
export const logNotAvailableAlertMessage = `${prefix}.logNotAvailableAlertMessage`;
export const recommendationsMessage = `${prefix}.recommendationsMessage`;

export const ENGLISH = {
    [recommendationHeader]: 'Recommendation',
    [cancelButtonLabel]: 'Cancel',
    [acceptRecommendationButtonLabel]: 'Accept Recommendation',
    [alreadyAppliedAlertMessage]: 'All recommendations are already applied.',
    [failedAlertMessage]: 'Could not generate recommendations.',
    [logNotAvailableAlertMessage]: 'Could not find matching transaction, please refresh Logs page.',
    [recommendationsMessage]: 'The system has prepared these recommendations, in case the corresponding request is believed to be a false positive. Please review the proposed changes, the reasoning and the associated risk.',
};
