/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './credential-verification.component.less';
import * as l10n from './credential-verification.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'credential-verification';

const NOTIFICATION_INITIAL = 'INITIAL_STATE';
const NOTIFICATION_SUCCESS = 'SUCCESS_STATE';
const NOTIFICATION_ERROR = 'ERROR_STATE';

/**
 * @alias module:component/credentialVerification
 * @private
 */
class CredentialVerificationController {
    constructor(
        $filter,
        l10nService,
    ) {
        this.l10nKeys = l10nKeys;
        this.backendErrorMsgFilter = $filter('backendErrorMsg');
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        /**
         * Error message to be shown in notification box when verifying credentials.
         * Can be overridden by the errors returned when there is no custom error message.
         * @type {string}
         * @protected
         */
        this.notificationErrorMsg_ = this.errorMessage || '';

        /**
         * Success message to be shown in notification box when verifying credentials.
         * @type {string}
         * @protected
         */
        this.notificationSuccessMsg_ = this.successMessage || '';

        this.setNotificationState_(NOTIFICATION_INITIAL);

        /**
         * Hash for caching credentials.
         * @type {Object.<string, string>}
         * @protected
         */
        this.credentialCache_ = {};
    }

    /**
     * Cache credentials from form inputs.
     * @protected
     */
    cacheCredentials_() {
        const { credentialForm: form } = this;
        const controls = form.$getControls();
        const cache = {};

        controls.forEach(control => {
            const {
                $name: name,
                $viewValue: viewValue,
            } = control;

            if (!name) {
                throw new Error('Input element must have the name property.');
            }

            cache[name] = viewValue;
        });

        this.credentialCache_ = cache;
    }

    /**
     * Restore cached credentials to data model.
     * @protected
     */
    restoreCredentials_() {
        const { credentialForm: form } = this;

        _.forEach(this.credentialCache_, (val, key) => {
            const control = form[key];

            control.$setViewValue(val);
            control.$render();
        });
    }

    /**
     * Set notification by type.
     * Set message if no custom one is presented.
     * @param {string} type - Type of notification: 'success' or 'error'.
     * @param {string} message - Message to be set.
     * @protected
     */
    setNotification_(type, message) {
        switch (type) {
            case 'success':
                if (!this.hasCustomMsg_(type)) {
                    this.notificationSuccessMsg = message;
                }

                this.setNotificationState_(NOTIFICATION_SUCCESS);
                break;

            case 'error':
                if (!this.hasCustomMsg_(type)) {
                    this.notificationErrorMsg = message;
                }

                this.setNotificationState_(NOTIFICATION_ERROR);
                break;
        }
    }

    /**
     * Determine a specific type of custom message is present or not.
     * @param {string} type - Type of message: 'success' or 'error'.
     * @return {boolean}
     * @protected
     */
    hasCustomMsg_(type) {
        switch (type) {
            case 'success':
                return !!this.successMessage;

            case 'error':
                return !!this.errorMessage;

            default:
                return false;
        }
    }

    /**
     * Set verification notification state.
     * @param {string} state - State to be set.
     * @protected
     */
    setNotificationState_(state) {
        this.notificationState = state;
    }

    /**
     * Decide what current notificationState is.
     * @param {string} state - State to be checked.
     * @return {boolean}
     */
    isNotificationState(state) {
        return this.notificationState === state;
    }

    /**
     * When 'Change Credentials' button is clicked, enter edit mode.
     * When 'Connect' button is clicked, update list with verified credentials.
     */
    onButtonClick() {
        // 'Connect' button is clicked (under edit mode)
        if (this.editMode) {
            this.onConnect()
                .then(() => {
                    // quit edit mode
                    this.editMode = false;
                    this.onEditComplete();
                    this.setNotification_('success', '');
                })
                .catch(errors => {
                    const errMsg = this.backendErrorMsgFilter(errors);

                    this.setNotification_('error', errMsg);
                });
        } else { // 'Change Credentials' button is clicked, enter edit mode
            this.cacheCredentials_();
            // enter edit mode
            this.editMode = true;
            this.onEditStart();
            this.onNotificationClose();
        }
    }

    /**
     * Called when the close button of notification box has been clicked.
     */
    onNotificationClose() {
        this.setNotificationState_(NOTIFICATION_INITIAL);
    }

    /**
     * Called when the close button of credential edit section has been clicked.
     */
    onCloseCredentialEditing() {
        this.restoreCredentials_();
        // quit edit mode
        this.editMode = false;
        this.setNotificationState_(NOTIFICATION_INITIAL);
    }

    /** @override */
    $onDestroy() {
        if (this.editMode) {
            this.restoreCredentials_();
        }
    }
}

CredentialVerificationController.$inject = [
    '$filter',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  credentialVerification
 * @module component/credentialVerification
 * @param {boolean} editMode - Decide if the credentials are being edited.
 *      Initial value is passed by the parent and modified by this component upon editing states.
 * @param {Function} onEditStart - Called when edit starts.
 * @param {Function} onEditComplete - Called when edit completes.
 * @param {Function} onConnect - Function making the verification call. Returns a promise.
 * @param {string=} successMessage - Custom success message to be added into the notification.
 * @param {string=} errorMessage - Custom failure message to be added into the notification.
 * @description
 *
 *          Component for section of credential modification and verfication.
 *          Pure wrapper and event driven.
 *
 *          IMPORTANT: ng-if and ng-change are NOT allowed within the transcluded template.
 *          With ng-if errors will be caused by missing of DOM elements when canceling editing since
 *              the credential restoration mechanism is implemented by setting values through DOM.
 *          With ng-change side effects will be caused along with data model being modified by the
 *              parent without notifying this component. (eg. ng-change in radio buttons)
 *
 * @example
 *
 * <credential-verification
 *     edit-mode="$ctrl.editingCredentials"
 *     on-edit-start="$ctrl.onProfileCredentialEditStart($ctrl.getProfileType())"
 *     on-edit-complete="$ctrl.onProfileCredentialEditComplete()"
 *     on-edit-cancel="$ctrl.onProfileCredentialEditCancel($ctrl.getProfileType())"
 *     on-connect="$ctrl.verifyProfileCredentials($ctrl.getProfileType())"
 *     success-message="Subnetwork list successfully fetched."
 * >
 *
 *      // transcluded form content for credential fields
 *
 * </credential-verification>
 *
 * @author Zhiqian Liu
 */
angular.module('aviApp').component('credentialVerification', {
    bindings: {
        editMode: '=',
        onEditStart: '&',
        onEditComplete: '&',
        onEditCancel: '&',
        onConnect: '&',
        successMessage: '@?',
        errorMessage: '@?',
    },
    transclude: true,
    controller: CredentialVerificationController,
    templateUrl: `src/components/common/${componentName}/${componentName}.component.html`,
});
