/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'custom-ipam';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleScript = `${prefix}.columnTitleScript`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleScript]: 'Script',
};
