/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { BotTypeMatcherConfigItem } from 'ajs/modules/match';
import { BotTypeMatcher } from 'object-types';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import * as l10n from './bot-type-matcher.l10n';
import './bot-type-matcher.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description BotTypeMatcherComponent
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-type-matcher',
    templateUrl: './bot-type-matcher.component.html',
})
export class BotTypeMatcherComponent {
    @Input()
    public editable: BotTypeMatcherConfigItem;

    /**
     * Index of the match within the MatchAdder component
     */
    @Input()
    public matchIndex = 0;

    /**
     * Match label passed to the MatchWrapperComponent
     */
    @Input()
    public label: string;

    /**
     * fieldName passed to the MatchWrapperComponent
     */
    @Input()
    public fieldName: string;

    /**
     * objectType passed to the MatchWrapperComponent
     */
    @Input()
    public objectType: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Object Type to be used in template for tooltip.
     */
    public readonly botTypeMatcherObjectType = BotTypeMatcher;

    /**
     * client_types type dropdown options
     */
    public readonly botTypeMatcherOptions: IAviDropdownOption[] = [];

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const botTypeMatcherTypes = schemaService.getEnumValues('BotClientType');

        this.botTypeMatcherOptions = createOptionsFromEnumProps(botTypeMatcherTypes);
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
