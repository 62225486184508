/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { IAwsZoneConfig } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { VipConfigItem } from 'ajs/modules/vs';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './aws-vip-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description VIP Config Component for AWS CloudType.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'aws-vip-config',
    templateUrl: './aws-vip-config.component.html',
})
export class AwsVipConfigComponent implements OnInit {
    /**
     * VipConfigItem Instance.
     */
    @Input()
    public editable: VipConfigItem;

    /**
     * UUID of the cloud on which this VIP is being configured.
     */
    @Input()
    public cloudId: string;

    /**
     * UUID of the VRFContext on which this VIP is being configured.
     */
    @Input()
    public vrfContextId: string;

    /**
     * List of availability_zones.
     */
    @Input()
    public zones: IAwsZoneConfig[] = [];

    /**
     * ObjectType of VIPConfigItem.
     */
    public objectType: string;

    /**
     * Availability zones dropdown options.
     */
    public availabilityZoneDropdownOptions: IAviDropdownOption[];

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        this.availabilityZoneDropdownOptions =
            this.zones.map(({ availability_zone: zone }) => createDropdownOption(zone));
    }
}
