/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-allowlist-rule-modal';
const prefix = `${moduleName}.${componentName}`;

/**
 * TODO Remove protocolLabel, ipReputationLabel, servicePortLabel when converted to full modal as this rule is not supported.
 */
export const clientIpAddressLabel = `${prefix}.clientIpAddressLabel`;
export const cookieLabel = `${prefix}.cookieLabel`;
export const headerLabel = `${prefix}.headerLabel`;
export const hostHeaderLabel = `${prefix}.hostHeaderLabel`;
export const protocolLabel = `${prefix}.protocolLabel`;
export const ipReputationLabel = `${prefix}.ipReputationLabel`;
export const pathLabel = `${prefix}.pathLabel`;
export const httpMethodLabel = `${prefix}.httpMethodLabel`;
export const queryLabel = `${prefix}.queryLabel`;
export const querySubLabel = `${prefix}.querySubLabel`;
export const servicePortLabel = `${prefix}.servicePortLabel`;

export const ENGLISH = {
    [clientIpAddressLabel]: 'Client IP Address',
    [cookieLabel]: 'Cookie',
    [headerLabel]: 'Header',
    [hostHeaderLabel]: 'Host Header',
    [protocolLabel]: 'Protocol Type',
    [ipReputationLabel]: 'IP Reputation',
    [pathLabel]: 'Path',
    [httpMethodLabel]: 'HTTP Method',
    [queryLabel]: 'Query',
    [querySubLabel]: '(Contains)',
    [servicePortLabel]: 'Service Port',
};
