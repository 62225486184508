/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'chart-with-overlays-tables';
const prefix = `${moduleName}.${componentName}`;

export const showAllLinkLabel = `${prefix}.showAllLinkLabel`;
export const showNearSelectedLinkLabel = `${prefix}.showNearSelectedLinkLabel`;
export const hideTableLinkLabel = `${prefix}.hideTableLinkLabel`;
export const columnTitleEntity = `${prefix}.columnTitleEntity`;
export const columnTitleMetricValue = `${prefix}.columnTitleMetricValue`;
export const columnTitleAnomalousValue = `${prefix}.columnTitleAnomalousValue`;
export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleEntityType = `${prefix}.columnTitleEntityType`;
export const columnTitlePredictionInterval = `${prefix}.columnTitlePredictionInterval`;

export const ENGLISH = {
    [showAllLinkLabel]: 'Show All',
    [showNearSelectedLinkLabel]: 'Show Near Selected',
    [hideTableLinkLabel]: 'Hide Table',
    [columnTitleEntity]: 'Entity',
    [columnTitleMetricValue]: 'Metric Value',
    [columnTitleAnomalousValue]: 'Anomalous Value',
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleType]: 'Type',
    [columnTitleEntityType]: 'Entity Type',
    [columnTitlePredictionInterval]: 'Prediction Interval',
};
