/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isNaN } from 'underscore';
import { DIALOG_SERVICE_TOKEN } from 'ng/modules/core';

/**
 * @alias module:component/errorPage
 * @private
 */
class ErrorPageComponent {
    constructor(
        $state,
        AviModal,
        AviMessage,
        errorPageService,
        windowLocation,
        getLocationWoHash,
        notificationService,
        fullModalService,
        dialogService,
    ) {
        this.$state_ = $state;
        this.AviModal_ = AviModal;
        this.AviMessage_ = AviMessage;
        this.errorPageService_ = errorPageService;
        this.windowLocation_ = windowLocation;
        this.getLocationWoHash_ = getLocationWoHash;
        this.notificationService_ = notificationService;
        this.fullModalService_ = fullModalService;
        this.dialogService_ = dialogService;

        this.statusCode = NaN;
        this.clusterInitProgressPercentage = NaN;
    }

    /** @override */
    $onInit() {
        this.AviMessage_.destroyAll();
        this.AviModal_.destroyAll();
        this.notificationService_.removeAll();
        this.dialogService_.removeAll();
        this.fullModalService_.removeAllModals();

        const { statusCode } = this.$state_.current.data;

        this.statusCode = statusCode;

        this.errorPageService_.startPollingClusterState(this.clusterStateHandler_);
        this.errorPageService_.startPollingUpgradeState();
    }

    /**
     * Getter for the cluster polling active status.
     * @returns {boolean}
     */
    get pollingActive() {
        return this.errorPageService_.pollingActive;
    }

    /**
     * Getter to decide is upgrade in progress.
     * @returns {boolean}
     */
    get upgradeInProgress() {
        return this.errorPageService_.upgradeActive;
    }

    /**
     * Getter to decide is cluster initiation in progress.
     * @returns {boolean}
     */
    get clusterInitInProgress() {
        return !isNaN(this.clusterInitProgressPercentage);
    }

    /**
     * Getter for the upgrade progress percentage.
     * @return {number}
     */
    get controllerUpgradePercentage() {
        return this.errorPageService_.upgradeProgressPercentage;
    }

    /**
     * Handler called on every successful cluster poll request. Updates the progress percentage and
     * redirects the user to the login page if the controller is up.
     */
    clusterStateHandler_ = clusterState => {
        if (!clusterState) {
            return;
        }

        const { state, progress } = clusterState;

        this.clusterInitProgressPercentage = progress;

        if (state && _.isString(state) && !state.indexOf('CLUSTER_UP')) {
            //going to the default UI page with hard refresh
            this.windowLocation_.replace(this.getLocationWoHash_(true));
        }
    };

    /** @override */
    $onDestroy() {
        this.errorPageService_.cancelRequests();
    }
}

ErrorPageComponent.$inject = [
    '$state',
    'AviModal',
    'AviMessage',
    'errorPageService',
    'windowLocation',
    'getLocationWoHash',
    'notificationService',
    'fullModalService',
    DIALOG_SERVICE_TOKEN,
];

/**
 * @ngdoc component
 * @name ErrorPageComponent
 * @author alextsg
 * @module components/errorPage
 * @desc
 *
 *     Error page component. It is continuously polling controller's status updating the
 *     progress percentage and when done redirects user to the main UI page with a full page
 *     reload.
 */
angular.module('aviApp').component('errorPage', {
    controller: ErrorPageComponent,
    templateUrl: 'src/components/pages/error-page/error-page.html',
});
