/**
 * @module LaunchConfigModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for auto-scale-lanch-config related tokens.
 * @author Harmeet Kaur
 */

/**
 * Ajs Dependency token for AutoScaleLaunchConfigCollection.
 */
export const AUTO_SCALE_LAUNCH_CONFIG_COLLECTION_TOKEN = 'AutoScaleLaunchConfigCollection';

/**
 * Ajs Dependency token for AutoScaleLaunchConfigItem.
 */
export const AUTO_SCALE_LAUNCH_CONFIG_ITEM_TOKEN = 'AutoScaleLaunchConfigItem';
