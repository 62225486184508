/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function aggEventFactory(Item) {
    /**
     * @class
     * @constructor
     * @memberOf module:avi/events
     * @extends module:avi/dataModel.Item
     * @desc
     *
     *     When using groupby in our API calls we get a list of groups with number
     *     of encounters and corresponding percentage relative to the whole set of data.
     *     Supported for events and logs.
     *
     *     Config keeps only 'value', 'count' and 'percentage' props.
     *
     * @author Alex Malitsky
     */
    class AggEvent extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.config && data.config.value || '';
        }
    }

    AggEvent.prototype.objectName = 'analytics/logs';

    return AggEvent;
}

aggEventFactory.$inject = [
    'Item',
];

angular.module('avi/events')
    .factory('AggEvent', aggEventFactory);
