/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @file
 * Application level enumerations.
 * @author Ram Pal
 */

export const enumerations = {
    TCP: 'TCP',
    UDP: 'UDP',
    DNS: 'DNS',
    ICMP: 'ICMP',
    HTTP: 'HTTP',
    HTTPS: 'HTTPS',
    GZIP: 'GZIP',
    SDCH: 'SDCH',
    IP: 'IP',
    SSL2: 'SSL2',
    SSL3: 'SSL3',
    TLS1: 'TLS1',
    TLS1_1: 'TLS1-1', /* For classname only*/
    TLS1_2: 'TLS1-2',
    TLS1_3: 'TLS1-3',
    L4: 'L4',
    L7: 'L7',
    URI: 'URI',
    SYSLOG: 'SYSLOG',
    SSL: 'SSL',
    VS: 'VS',
    TLS: 'TLS',
    VMDK: 'VMDK',
    QCOW2: 'QCOW2',
    LDAP: 'LDAP',
    NW: 'NW',
    SE: 'SE',
    SSH: 'SSH',
    APIC: 'APIC',
    AWS: 'AWS',
    VIP: 'VIP',
    CC: 'CC',
    VNIC: 'VNIC',
    LB: 'LB',
    GSLB: 'GSLB',
    VCENTER: 'vCenter',
    OPENSTACK: 'OpenStack',
    REALTIME: 'Real Time',
    ROLLINGWINDOW: 'Rolling Window',
    DEDICATED_PAIR: 'Dedicated',
    HDR: 'Header',
    RSP: 'Response',
    RESP: 'Response',
    CONN: 'Connection',
    NETHSM: 'netHSM',
    DOCKER_UCP: 'Docker Universal Control Plane',
    PPP: 'PPP',
    ARAP: 'ARAP',
    PT: 'PT',
    RCMD: 'RCMD',
    X25: 'X25',
    NASI: 'NASI',
    FWPROXY: 'FWPROXY',
    TACACS_PLUS: 'TACACS+',
    SAFENET_LUNA: 'SafeNet Luna',
    UNAVAIL: 'Unavailable',
    MAXMIND: 'MaxMind',
    SAML: 'SAML',
    SSE: 'SSE',
    KMS: 'KMS',
    REQ: 'Request',
    REQ_DATA: 'Request Data',
    PROTOCOL_TYPE_TCP_PROXY: 'TCP',
    PROTOCOL_TYPE_TCP_FAST_PATH: 'Fast Path',
    PROTOCOL_TYPE_UDP_FAST_PATH: 'UDP',
    V4_V6: 'IPv4 and IPv6',
    V4_ONLY: 'Only IPv4',
    V6_ONLY: 'Only IPv6',
    DNS_RECORD_A: 'A/AAAA Record',
    DNS_RECORD_AAAA: 'AAAA Record',
    DNS_RECORD_SRV: 'SRV Record',
    DNS_RECORD_CNAME: 'CNAME Record',
    DNS_RECORD_NS: 'NS Record',
    DNS_RECORD_MX: 'MX Record',
    DNS_RECORD_TXT: 'Text Record',
    CO_GE: '>=',
    CO_LE: '<=',
    CO_LT: '<',
    CO_GT: '>',
    CO_EQ: '=',
    CO_NE: '!=',
    SNI: 'SNI',
};
