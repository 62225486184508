/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import {
    IIpAddrPrefix,
    IpAddressVersions,
    IpAddrType,
    ISubnet,
} from 'generated-types';

import {
    formatIpAddrPrefix,
    getSubnetString,
    parseIpPrefixString,
} from 'ng/shared/utils/ip-prefix-parser.utils';

import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'ajs/modules/core/services/string-service';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { SubnetListNetwork } from 'ajs/modules/network';
import * as l10n from './vip-placement-subnet.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSubnetListNetwork = typeof SubnetListNetwork;

/**
 * @description
 *
 *      Component for subnet & subnet6 selection based on networkRef (autoAllocationMode).
 *
 *      Loads network by networkRef and populates subnet & subnet6 dropdown options.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vip-placement-subnet',
    templateUrl: './vip-placement-subnet.component.html',
})
export class VipPlacementSubnetComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * Binding for vip#network_ref.
     */
    @Input()
    public networkRef: string;

    /**
     * Binding for vip#auto_allocate_ip_type.
     * Subnet dropdowns are marked as required depends on IP type.
     */
    @Input()
    public autoAllocateIpType = IpAddressVersions.V4_ONLY;

    /**
     * Two way binding for vip#subnet.
     */
    @Input()
    public subnet: IIpAddrPrefix;

    /**
     * Two way binding for vip#subnet6.
     */
    @Input()
    public subnet6: IIpAddrPrefix;

    /**
     * Cloud uuid - to load network.
     */
    @Input()
    public cloudId: string;

    /**
     * If true, IPV6 field will be shown.
     * for some cloudTypes, IPV6 is not supported (ex: AWS).
     */
    @Input()
    public supportIpv6 = true;

    /**
     * subnet two-way binding eventemitter.
     */
    @Output()
    public subnetChange = new EventEmitter<IIpAddrPrefix>();

    /**
     * subnet6 two-way binding eventemitter.
     */
    @Output()
    public subnet6Change = new EventEmitter<IIpAddrPrefix>();

    /**
     * ObjectType of VIP.
     */
    public readonly objectType = 'Vip';

    /**
     * ngModel for subnet dropdown.
     */
    // TODO: Make parseIpPrefix work with AviDropdown component, then rewrite this component.
    public subnetString: string;

    /**
     * ngModel for subnet6 dropdown.
     */
    public subnet6String: string;

    /**
     * subnet field dropdown options.
     */
    public subnetDropdownOptions: IAviDropdownOption[] = [];

    /**
     * subnet6 field dropdown options.
     */
    public subnet6DropdownOptions: IAviDropdownOption[] = [];

    /**
     * True when network is being loaded.
     */
    public loadingNetwork = false;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SubnetListNetwork instance.
     */
    private subnetListNetwork: SubnetListNetwork;

    constructor(
        private readonly stringService: StringService,
        @Inject(SubnetListNetwork)
        private readonly SubnetListNetwork: TSubnetListNetwork,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.networkRef) {
            if (this.subnet) {
                this.subnetString = formatIpAddrPrefix(this.subnet);
            }

            if (this.subnet6) {
                this.subnet6String = formatIpAddrPrefix(this.subnet6);
            }

            this.loadIpAllocationNetwork();
        }
    }

    /** @override */
    public ngOnChanges({ networkRef }: SimpleChanges): void {
        if (networkRef && !networkRef.firstChange) {
            // when networkRef is changed, subnet values & options that belonged to old network
            // should be cleared.
            this.subnetString = undefined;
            this.subnet6String = undefined;
            this.clearSubnetDropdowns();

            if (this.networkRef) {
                this.loadIpAllocationNetwork();
            }
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.subnetListNetwork) {
            this.subnetListNetwork.destroy();
        }
    }

    /**
     * Fires subnet dropdown change.
     */
    public emitSubnetChange(): void {
        // we need subnet value to be of IpAddrPrefix type
        this.subnet = this.subnetString ? parseIpPrefixString(this.subnetString) : undefined;

        this.subnetChange.emit(this.subnet);
    }

    /**
     * Fires subnet6 dropdown change.
     */
    public emitSubnet6Change(): void {
        // we need subnet6 value to be of IpAddrPrefix type
        this.subnet6 = this.subnet6String ? parseIpPrefixString(this.subnet6String) : undefined;

        this.subnet6Change.emit(this.subnet6);
    }

    /**
     * Returns true when IPV4 Subnet is required.
     */
    public get isIpV4SubnetRequired(): boolean {
        return this.autoAllocateIpType === IpAddressVersions.V4_ONLY ||
            this.autoAllocateIpType === IpAddressVersions.V4_V6;
    }

    /**
     * Returns true when IPV6 Subnet is required.
     */
    public get isIpV6SubnetRequired(): boolean {
        return this.autoAllocateIpType === IpAddressVersions.V6_ONLY ||
            this.autoAllocateIpType === IpAddressVersions.V4_V6;
    }

    /**
     * Loads SubnetListNetwork instance by networkRef & sets subnet dropdown options.
     */
    private async loadIpAllocationNetwork(): Promise<void> {
        this.loadingNetwork = true;

        try {
            this.subnetListNetwork = new this.SubnetListNetwork({
                id: this.stringService.slug(this.networkRef),
                cloudId: this.cloudId,
                params: {
                    auto_allocate_only: true,
                },
            });

            await this.subnetListNetwork.load();

            this.setSubnetDropdownOptions();
        } catch (e) {
            // empty catch block
        } finally {
            this.loadingNetwork = false;
        }
    }

    /**
     * Sets subnet & subnet6 dropdown options from subnetListNetwork.
     */
    private setSubnetDropdownOptions(): void {
        const subnets: ISubnet[] = this.subnetListNetwork.getSubnets();

        [
            this.subnetDropdownOptions,
            this.subnet6DropdownOptions,
        ] = subnets.reduce((
            subnetOptions: [IAviDropdownOption[], IAviDropdownOption[]],
            subnet: ISubnet,
        ) => {
            const { prefix } = subnet;
            const dropdownOption = createDropdownOption(getSubnetString(prefix));

            if (prefix.ip_addr.type === IpAddrType.V4) {
                subnetOptions[0].push(dropdownOption);
            } else {
                subnetOptions[1].push(dropdownOption);
            }

            return subnetOptions;
        }, [[], []]);
    }

    /**
     * Clears subnet & subnet6 dropdowns.
     */
    private clearSubnetDropdowns(): void {
        this.subnetDropdownOptions = [];
        this.subnet6DropdownOptions = [];
    }
}
