/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/tooltip2.less';

/**
 * @ngdoc directive
 * @name aviApp.directive:tooltip2
 * @element any
 * @description
 *     Creates floating tooltip/popover for selected element when user hovers over the element.
 * @example
 *      <div tooltip2="Some text for popover when user hovers over this div element">
 *          Hover Here!
 *      </div>
 * @example
 *      <div tooltip2="This content gets overridden if template is provided"
 *           tooltip2-attachment="bottom center"
 *           tooltip2-target-attachment="top center"
 *           tooltip2-template="myTemplateString">
 *              On hover over tooltip appears above element, top center.
 *      </div>
 *
 *      Inside controller:
 *
 *      $scope.myTemplateString = '<div class="tooltip2>
 *                                      <div class="tooltip2-title>
 *                                          Some title
 *                                      </div>
 *                                      <div> class=tooltip2-text>
 *                                          Tooltip body text.
 *                                      </div>
 *                                 </div>';
 */

import Tether from 'tether';

angular.module('aviApp').directive('tooltip2', function() {
    return {
        restrict: 'A',
        controller: Tooltip2Controller,
        controllerAs: 'tooltip2Ctrl',
    };
});

/**
 * Controller for tooltip2 directive.
 * @param {angular.$scope} $scope
 * @param {jQuery} $element
 * @param {angular.$attrs} $attrs
 * @param {angular.compile} $compile
 * @constructor
 */
function Tooltip2Controller($scope, $element, $attrs, $compile) {
    /**
     * @type {angular.$scope}
     * @private
     */
    this.scope_ = $scope;
    /**
     * @type {jQuery}
     * @private
     */
    this.element_ = $element;
    /**
     * @type {angular.$attrs}
     * @private
     */
    this.attributes_ = $attrs;
    /**
     * @type {angular.compile}
     * @private
     */
    this.compile_ = $compile;
    /**
     * @type {Function}
     * @private
     */
    this.Tether = Tether;
    /**
     * @type {Tether|undefined}
     * @private
     */
    this.tether_;
    /**
     * @type {jQuery}
     * @private
     */
    this.tooltip_ = this.createDom_();
}

Tooltip2Controller.$inject = [
    '$scope',
    '$element',
    '$attrs',
    '$compile',
];

/**
 * Creates jQuery wrapped DOM element for tooltip.
 * @returns {*|jQuery|HTMLElement}
 * @private
 */
Tooltip2Controller.prototype.createDom_ = function() {
    const self = this;
    const scope = this.scope_;
    const compile = this.compile_;
    const element = this.element_;
    const attrs = this.attributes_;

    let tooltip = $('<div></div>');

    tooltip.addClass('tooltip2');

    const unWatchTooltip = scope.$watch(function() {
        return attrs.tooltip2;
    }, function(newValue) {
        if (newValue) {
            self.destroyTooltip();
            tooltip.text(scope.$eval(newValue));
            self.tether(tooltip.get(0), element.get(0));
        }
    });

    const unwatchTooltipTemplate = scope.$watch(function() {
        return attrs.tooltip2Template;
    }, function(newValue) {
        if (newValue) {
            self.destroyTooltip();
            tooltip.html(scope.$eval(newValue));
            tooltip = compile(tooltip.contents())(scope);
            self.tether(tooltip.get(0), element.get(0));
        }
    });

    this.element_.on('mouseover', this.onMouseOver.bind(this));
    this.element_.on('mouseout', this.onMouseOut.bind(this));

    this.scope_.$on('$destroy', function() {
        this.destroyTooltip();
        unWatchTooltip();
        unwatchTooltipTemplate();
        this.element_.off();
    }.bind(this));

    return tooltip;
};

/**
 * Destroys existing tooltip DOM element and Tether instance.
 */
Tooltip2Controller.prototype.destroyTooltip = function() {
    if (this.tooltip_) {
        this.tooltip_.remove();
    }

    if (this.tether_) {
        this.tether_.destroy();
    }
};

/**
 * Tethers target to tooltip element.
 * @param {Element} element Tooltip pop-up element.
 * @param {Element} target Element to which tooltip will be assigned on hover over.
 * @returns {Tether}
 */
Tooltip2Controller.prototype.tether = function(element, target) {
    const $element = $(element);

    $element.css({ display: 'none' });
    $element.appendTo(document.body);
    this.tooltip_ = $element;

    this.tether_ = new this.Tether({
        element,
        target,
        attachment: this.attributes_['tooltip2Attachment'] || 'middle left',
        targetAttachment: this.attributes_['tooltip2TargetAttachment'] || 'middle right',
        constraints: this.attributes_['tooltip2Constraints'] ?
            JSON.parse(this.attributes_['tooltip2Constraints']) : [
                {
                    to: 'scrollParent',
                    attachment: 'together',
                    pin: true,
                },
            ],
        offset: this.attributes_['tooltip2Offset'] || '0px 0px',
    });

    return this.tether_;
};

/**
 * Target element mouse over event handler.
 * @param {MouseEvent} e
 */
Tooltip2Controller.prototype.onMouseOver = function(e) {
    this.tooltip_.show();
    this.tether_.position();
};

/**
 * Target element mouse out event handler.
 * @param {MouseEvent} e
 */
Tooltip2Controller.prototype.onMouseOut = function(e) {
    this.tooltip_.hide();
};
