/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vs-vip-modal-preview';
const prefix = `${moduleName}.${componentName}`;

export const tenantLabel = `${prefix}.tenantLabel`;
export const lastModifiedLabel = `${prefix}.lastModifiedLabel`;

export const ENGLISH = {
    [tenantLabel]: 'Tenant',
    [lastModifiedLabel]: 'Last Modified',
};
