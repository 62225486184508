/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    UserProfileCollection,
    USER_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/accounts';

const $injector = '$injector';

export const userProfileCollectionProvider = {
    deps: [$injector],
    provide: UserProfileCollection,
    useFactory(injector: Injector): typeof UserProfileCollection {
        return injector.get(USER_PROFILE_COLLECTION_TOKEN);
    },
};

export const systemConfigServiceProvider = {
    deps: [$injector],
    provide: 'systemConfigService',
    useFactory(injector: Injector): any {
        return injector.get('systemConfigService');
    },
};
