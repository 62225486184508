/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description BGP Profile Message Item.
 *
 * @author Aravindh Nagarajan, Zhiqian Liu
 */

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { Component, Type } from '@angular/core';
import { CloudType, IBgpProfile } from 'generated-types';
import { BgpProfileModalComponent } from 'ng/modules/vrf-context';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import { BgpProfile } from 'object-types';
import { BgpRoutingOptionsConfigItem } from './bgp-routing-options.config-item.factory';
import { BgpPeerConfigItem } from './bgp-peer.config-item.factory';
import * as l10n from '../vrf-context.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TBgpProfilePartial = Omit<IBgpProfile, 'peers' | 'routing_options'>;

interface IBgpProfileConfig extends TBgpProfilePartial {
    peers: RepeatedMessageItem<BgpPeerConfigItem>
    routing_options: RepeatedMessageItem<BgpRoutingOptionsConfigItem>;
    bgpType?: BgpTypes; // UI only property - to handle BGP type dropdown
}

export enum BgpTypes {
    ibgp = 'iBGP',
    ebgp = 'eBGP',
}

/**
 * Child fieldname constants.
 */
const PEERS = 'peers';
const ROUTING_OPTIONS = 'routing_options';

/**
 * Ajs dependency token for BgpProfileConfigItem.
 */
export const BGP_PROFILE_CONFIG_ITEM_TOKEN = 'BgpProfileConfigItem';

/**
 * @description BgpProfile messageItem class
 *
 * @author Aravindh Nagarajan.
 */
export class BgpProfileConfigItem extends
    withEditChildMessageItemMixin<IBgpProfileConfig, typeof MessageItem>(
        withFullModalMixin(MessageItem),
    )<IBgpProfileConfig> {
    public static ajsDependencies = ['l10nService'];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: BgpProfile,
            windowElement: BgpProfileModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const {
            ibgp,
        } = this.config;

        this.type = ibgp ? BgpTypes.ibgp : BgpTypes.ebgp;
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        this.config.ibgp = this.type === BgpTypes.ibgp;

        delete this.config.bgpType;
    }

    /**
     * Getter for BGP Type: iBGP/eBGP.
     */
    public get type(): BgpTypes {
        return this.config.bgpType;
    }

    /**
     * Setter for BGP Type: iBGP/eBGP.
     */
    public set type(bgpType: BgpTypes) {
        this.config.bgpType = bgpType;
    }

    /**
     * Getter for BGP Id.
     */
    public get bgpId(): number {
        return this.config.local_as;
    }

    /**
     * Returns true if bgpProfile has peers.
     */
    public hasPeers(): boolean {
        return !this.peers.isEmpty();
    }

    /**
     * Getter for Bgp peers.
     */
    public get peers(): RepeatedMessageItem<BgpPeerConfigItem> {
        const { peers } = this.config;

        return peers;
    }

    /**
     * Getter for Bgp RoutingOptions.
     */
    public get routingOptions(): RepeatedMessageItem<BgpRoutingOptionsConfigItem> {
        const { [ROUTING_OPTIONS]: routingOptions } = this.config;

        return routingOptions;
    }

    /**
     * Returns true if bgp is disabled.
     */
    public get isDisabled(): boolean {
        return !this.bgpId;
    }

    /**
     * Method to add a BGP Peer.
     */
    public addBgpPeer(
        vrfContextId: string,
        cloudId: string,
        cloudType: CloudType,
    ): void {
        this.addChildMessageItem({
            field: PEERS,
            modalBindings: {
                bgpProfileType: this.type,
                vrfContextId,
                cloudId,
                cloudType,
            },
        });
    }

    /**
     * Clear peers on BgpType Change.
     */
    public onBgpTypeChange(): void {
        this.peers.removeAll();
    }

    /**
     * Method to edit a BGP Peer.
     */
    public editBgpPeer(
        messageItem: BgpPeerConfigItem,
        vrfContextId: string,
        cloudId: string,
        cloudType: CloudType,
    ): void {
        const editArgs = {
            field: PEERS,
            messageItem,
            modalBindings: {
                bgpProfileType: this.type,
                vrfContextId,
                cloudId,
                cloudType,
            },
        };

        this.editChildMessageItem(editArgs);
    }

    /**
     * Method to create BGP Routing Options.
     */
    public addBgpRoutingOptions(): void {
        this.addChildMessageItem({
            field: ROUTING_OPTIONS,
        });
    }

    /**
     * Method to edit BGP Routing Options.
     */
    public editBgpRoutingOptions(messageItem: BgpRoutingOptionsConfigItem): void {
        this.editChildMessageItem({
            field: ROUTING_OPTIONS,
            messageItem,
        });
    }

    /**
     * Check if bgpType is iBGP.
     */
    public isIbgp(): boolean {
        const { type: bgpType } = this;

        return bgpType === BgpTypes.ibgp;
    }

    /**
     * Remove field from config as per the bgpType.
     */
    public removeFieldsByBgpType(): void {
        const { type: bgpType } = this;

        if (bgpType !== BgpTypes.ibgp) {
            delete this.getConfig().local_preference;
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.bgpProfileLabel);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            PEERS,
            ROUTING_OPTIONS,
        ];
    }
}
