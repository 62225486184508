/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-mode';
const prefix = `${moduleName}.${componentName}`;

export const useParentModeValueLabel = `${prefix}.useParentModeValueLabel`;

export const ENGLISH = {
    [useParentModeValueLabel]: 'Policy mode ({0})',
};
