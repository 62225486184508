/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    VipConfigItem,
    VipPlacementNetworkConfigItem,
} from 'ajs/modules/vs/factories';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './generic-vip-config.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Component for Generic VIP Configuration.
 *      used in No-Orchestrator/LinuxServer CloudTypes.
 *
 *      - when cloud has IpamProviderProfile, autoIpAllocation will be displayed
 *      - otherwise only static IPs can be configured.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'generic-vip-config',
    templateUrl: './generic-vip-config.component.html',
})
export class GenericVipConfigComponent implements OnInit {
    /**
     * VipConfigItem Instance.
     */
    @Input()
    public editable: VipConfigItem;

    /**
     * UUID of the cloud on which this VIP is being configured.
     */
    @Input()
    public cloudId: string;

    /**
     * UUID of the VRFContext on which this VIP is being configured.
     */
    @Input()
    public vrfContextId: string;

    /**
     * Indicates whether cloud has IPAM Provider profile.
     * If true, autoIpAllocation option will be available to user.
     */
    @Input()
    public hasIpamProviderProfile: boolean;

    /**
     * VsVip#ipam_selector label string.
     * Set by backend, UI configuration is not available yet.
     */
    @Input()
    public ipamSelectorLabels: string;

    /**
     * ObjectType of editable.
     */
    public objectType: string;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Opens Add placementNetwork modal.
     */
    public addPlacementNetwork(): void {
        const { placementNetworks: { config } } = this.editable;

        // To filter out already selected networks.
        const networkIdsToExclude = config
            .map(({ networkId }: VipPlacementNetworkConfigItem) => networkId)
            .join();

        this.editable.createPlacementNetwork(this.cloudId,
            this.vrfContextId, networkIdsToExclude);
    }

    /**
     * Opens Edit placementNetwork modal.
     */
    public editPlacementNetwork(network: VipPlacementNetworkConfigItem): void {
        const { placementNetworks: { config } } = this.editable;

        // To filter out already selected networks.
        const networkIdsToExclude = config
            .filter((placementNetwork: VipPlacementNetworkConfigItem) =>
                placementNetwork !== network) // ignore the current network
            .map(({ networkId }: VipPlacementNetworkConfigItem) => networkId)
            .join();

        this.editable.editPlacementNetwork(network,
            this.cloudId, this.vrfContextId, networkIdsToExclude);
    }

    /**
     * Deletes placementNetwork.
     */
    public deletePlacementNetwork(network: VipPlacementNetworkConfigItem): void {
        this.editable.placementNetworks.removeByMessageItem(network);
    }
}
