/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';

import {
    ICAPVendor,
    IIcapProfile,
} from 'generated-types';

import { ICAPProfileModalComponent } from 'ng/modules/icap-profile';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import * as l10n from '../icap-profile.l10n';
import { IcapNsxDefenderConfigConfigItem } from '.';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TIcapNsxDefenderConfigPartial = Omit<IIcapProfile, 'nsx_defender_config'>;

interface IIcapProfileConfig extends TIcapNsxDefenderConfigPartial {
    nsx_defender_config: IcapNsxDefenderConfigConfigItem;
}

interface IIcapProfileData {
    config: IIcapProfileConfig;
}

/**
 * @description
 *
 *   ICAP-Profile item.
 *
 * @author Aravindh Nagarajan
 */
export class IcapProfile extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IIcapProfileData;
    public getConfig: () => IIcapProfileConfig;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'icapprofile',
            objectType: 'IcapProfile',
            permissionName: 'PERMISSION_ICAP',
            windowElement: ICAPProfileModalComponent,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns nsx_defender_config message item data.
     */
    public get icapNsxDefenderConfig(): IcapNsxDefenderConfigConfigItem {
        const { nsx_defender_config: icapNsxDefenderConfig } = this.getConfig();

        return icapNsxDefenderConfig;
    }

    /**
     * Returns current vendor.
     */
    public getVendor(): ICAPVendor {
        const { vendor } = this.getConfig();

        return vendor;
    }

    /**
     * Method to configure vendor specific fields.
     * Sets IcapNsxDefenderConfig message item only when vendor is NSXDefender.
     */
    public configureIcapVendorSpecificFields(): void {
        const vendor = this.getVendor();

        switch (vendor) {
            case ICAPVendor.ICAP_VENDOR_LASTLINE:
                this.safeSetNewChildByField('nsx_defender_config');
                break;
            default:
                delete this.config.nsx_defender_config;
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.icapProfileModalBreadcrumbTitle);
    }
}
