/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './error-page-body.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name errorPageProfile
 * @description Error Page Body list page.
 */
class ErrorPageBodyController {
    constructor(
        ErrorPageBodyCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.ErrorPageBodyCollection = ErrorPageBodyCollection;
        this.CRUDGridConfig = CRUDGridConfig;
        /** @protected */
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        this.gridConfig = new this.CRUDGridConfig();
        this.gridConfig.collection = new this.ErrorPageBodyCollection();
        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }];

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

ErrorPageBodyController.$inject = [
        'ErrorPageBodyCollection',
        'CRUDGridConfig',
        'l10nService',
];

angular.module('aviApp').component('errorPageBodyList', {
    controller: ErrorPageBodyController,
    template:
            `<div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>`,
});
