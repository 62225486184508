/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IDragAndDropIndices } from 'ng/shared/shared.types';
import {
    WafPolicyPsmGroup,
    WafPolicyPsmGroupMetric,
    WafPSMLocationConfigItem,
} from 'ajs/modules/waf';
import './waf-policy-psm-locations-list.less';
import { WAF_PSMLOCATION_SUM_MATCHED_METRIC } from '../../../../waf.constants';

type TWafPolicyPsmGroupMetric = typeof WafPolicyPsmGroupMetric;

/**
 * @desc Component for displaying a list of WAF Policy PSM Locations in the WAF PSM Group modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-locations-list',
    templateUrl: './waf-policy-psm-locations-list.component.html',
})
export class WafPolicyPsmLocationsListComponent {
    @Input() public locations: WafPSMLocationConfigItem[];
    @Input() public preventEdit: boolean;
    @Input() public psmGroup: WafPolicyPsmGroup;

    /**
     * Called to edit a location.
     */
    @Output() public onEdit = new EventEmitter();

    /**
     * Called to delete a location.
     */
    @Output() public onDelete = new EventEmitter<number>();

    /**
     * Called to move a location.
     */
    @Output() public onMove = new EventEmitter<IDragAndDropIndices>();

    private locationMetric: WafPolicyPsmGroupMetric;

    constructor(
        @Inject('Timeframe') private Timeframe: any,
        @Inject(WafPolicyPsmGroupMetric) private WafPolicyPsmGroupMetric: TWafPolicyPsmGroupMetric,
    ) {}

    /**
     * @override
     */
    public ngOnInit(): void {
        const { step, limit } = this.Timeframe.selected();

        this.locationMetric = new this.WafPolicyPsmGroupMetric({
            id: this.psmGroup.id,
            metricIds: [WAF_PSMLOCATION_SUM_MATCHED_METRIC],
            params: {
                limit,
                step,
            },
        });

        this.locationMetric.load();
    }

    /**
     * Handler for editing a PSM location.
     */
    public handleEdit(location: WafPSMLocationConfigItem): void {
        this.onEdit.emit(location);
    }

    /**
     * Handler for deleting a PSM location.
     * @param index - Index of the PSM location to remove.
     */
    public handleDeleteLocation(index: number): void {
        this.onDelete.emit(index);
    }

    /**
     * Handler for the drag-and-drop event.
     * @param dragEvent - Object returned by angular/cdk/drag-drop when the user has dropped an item
     *     after dragging it.
     */
    public handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;

        this.onMove.emit({
            previousIndex,
            currentIndex,
        });
    }

    /**
     * Returns the number of location matches for a location.
     */
    public getLocationMatches(locationName: string): number {
        return this.locationMetric.getSumById(locationName, WAF_PSMLOCATION_SUM_MATCHED_METRIC);
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.locationMetric.destroy();
    }
}
