/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { AviPermissionResource, IErrorPageBody } from 'generated-types';
import { ErrorPageBodyModalComponent } from 'ng/modules/error-page';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../error-page.l10n';

interface IErrorPageBodyData {
    config: IErrorPageBody;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for ErrorPageBody item.
 */
export const ERROR_PAGE_BODY_ITEM_TOKEN = 'ErrorPageBody';

/**
 * @description ErrorPageBody Item class.
 * @author Satish Pednekar
 */
export class ErrorPageBody extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IErrorPageBodyData;
    public getConfig: () => IErrorPageBody;

    private l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'errorpagebody',
            objectType: 'ErrorPageBody',
            windowElement: ErrorPageBodyModalComponent,
            permissionName: AviPermissionResource.PERMISSION_ERRORPAGEBODY,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.errorPageBodyModalBreadcrumbTitle);
    }
}
