/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'geo-db-mapping-modal';
const prefix = `${moduleName}.${componentName}`;

export const geoDbMappingElementModalTitle = `${prefix}.geoDbMappingElementModalTitle`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const attributeLabel = `${prefix}.attributeLabel`;
export const attributePlaceholder = `${prefix}.attributePlaceholder`;
export const valuesLabel = `${prefix}.valuesLabel`;
export const valuesPlaceholder = `${prefix}.valuesPlaceholder`;

export const ENGLISH = {
    [geoDbMappingElementModalTitle]: 'Add Element',
    [sectionTitleGeneral]: 'General',
    [attributeLabel]: 'Attribute',
    [attributePlaceholder]: 'Select Attribute',
    [valuesLabel]: 'Values',
    [valuesPlaceholder]: 'Enter Values',
};
