/** @module PoolModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { StringService } from 'ajs/modules/core/services/string-service';
import {
    IPoolGroup,
    IPoolGroupMember,
} from 'generated-types';

import { PoolGroup, POOL_GROUP_ITEM_TOKEN } from './pool-group.item.factory';

/**
 * Ajs dependency token for PoolGroupCollection.
 */
export const POOL_GROUP_COLLECTION_TOKEN = 'PoolGroupCollection';

/**
 * @description Poolgroup collection
 * @author Nisar Nadaf
 */
export class PoolGroupCollection extends Collection {
    constructor(args = {}) {
        super(args);

        this.itemClass_ = this.getAjsDependency_(POOL_GROUP_ITEM_TOKEN);
    }

    /**
     * Returns a hash of Pool ids from the items array of a PoolGroup collection or from the
     * inventoryMap API.
     */
    public static getPoolIdsHash(items: Array<IPoolGroup | PoolGroup>) : Record<string, boolean> {
        const stringService: StringService = this.getAjsDependency_('stringService');

        return items.reduce((acc: Record<string, boolean>, item: IPoolGroup | PoolGroup) => {
            const members = 'members' in item ?
                item.members :
            // eslint-disable-next-line no-extra-parens
                (item as PoolGroup).getConfig().members;

            if (members && members.length) {
                members.forEach((member: IPoolGroupMember) => {
                    const { pool_ref: poolRef } = member;

                    acc[stringService.slug(poolRef)] = true;
                });
            }

            return acc;
        }, {});
    }
}

Object.assign(PoolGroupCollection.prototype, {
    objectName_: 'poolgroup-inventory',
    windowElement_: 'pool-group-create',
});

PoolGroupCollection.ajsDependencies = [
    POOL_GROUP_ITEM_TOKEN,
    'stringService',
];
