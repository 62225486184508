/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './segroup-license-selector.less';
import * as l10n from './segroup-license-selector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentTag = 'segroup-license-selector';

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing.segroupLicenseSelectorBindings
 * @see {@link module:avi/licensing.SegroupLicenseSelectorComponent}
 */
class SegroupLicenseSelectorController {
    /**
     * Label created for UI-only checkbox.
     * @type {string}
     */
    showAllFlavorsLabel = 'Show all flavor types in dropdown, including non-recommended ones.';

    /**
     * Enum name corresponding to this component's parent.
     * @type {string}
     */
    objectType = 'ServiceEngineGroup';

    /**
     * List of seBandwidth types.
     * @type {DropDownOption[]}
     */
    seBandwidthTypeOptions = [];

    /**
     * URL to take user to Azure cloud page if cloud type is Azure.
     * @type {string}
     */
    azureLicenseCostsUrl = 'https://azuremarketplace.microsoft.com/en-us/marketplace/' +
        'apps/avi-networks.avi-vantage-adc';

    /**
     * Prop to display all flavors, or recommended only.
     * @type {boolean}
     */
    showNonRecommendedFlavors = false;

    constructor(
        $http,
        $timeout,
        $element,
        schemaService,
        dropDownUtils,
        SEGroupFlavorCollection,
        l10nService,
    ) {
        this.$http = $http;
        this.$timeout = $timeout;
        this.$element = $element;
        this.schemaService = schemaService;
        this.dropDownUtils = dropDownUtils;
        this.SEGroupFlavorCollection = SEGroupFlavorCollection;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.$element.addClass(componentTag);
        this.setSeBandwidthTypeOptions_();

        if (this.supportsFlavors()) {
            this.setFlavorsCollection_();
        }
    }

    /**
     * Called on init to create seBandwidthTypeOptions and possibly set se_bandwidth_type.
     * @protected
     */
    setSeBandwidthTypeOptions_() {
        const seBandwidthTypes = this.schemaService.getEnumValues('SEBandwidthType');

        this.seBandwidthTypeOptions = seBandwidthTypes
            .map(({ value, label }) => this.dropDownUtils.createOption(value, label));

        const seBandwidthTypesHash_ = _.invert(seBandwidthTypes.map(({ value }) => value));

        if (!(this.segroup.getSeBandwidthType() in seBandwidthTypesHash_)) {
            this.setDefaultSeBandwidthType_();
        }
    }

    /**
     * Forces selection of se_bandwidth_type under certain conditions.
     * @protected
     */
    setSeBandwidthType_() {
        if (this.isPerAppSeModeEnabled_()) {
            this.segroup.getConfig().se_bandwidth_type = 'SE_BANDWIDTH_UNLIMITED';
        }
    }

    /**
     * Sets se_bandwidth_type to a predefined default.
     * @protected
     */
    setDefaultSeBandwidthType_() {
        this.segroup.getConfig().se_bandwidth_type = 'SE_BANDWIDTH_UNLIMITED';
    }

    /**
     * Disallows user selection of se_bandwidth_type under certain conditions.
     * @return {boolean}
     */
    disableSeBandwidthTypeSelection() {
        return this.isPerAppSeModeEnabled_();
    }

    /**
     * Acts upon user change of se_bandwidth_type.
     */
    handleSeBandwidthTypeChange() {
        this.setMaxNumSeDps_();
    }

    /**
     * Creates Flavors Collection.
     * @protected
     */
    setFlavorsCollection_() {
        this.flavorsCollection = new this.SEGroupFlavorCollection({
            cloudId: this.cloud.id,
        });

        this.updateFlavorsParams();
    }

    /**
     * Triggers timeout function to force ng-change fn to end of event loop.
     * Done because ng-change fires before ng-model is changed.
     * https://github.com/angular/angular.js/issues/4558
     */
    updateFlavorsParams() {
        this.$timeout(this.updateFlavorParams_, 0);
    }

    /**
     * Updates collection's params to be used in API call for flavors.
     * @protected
     */
    updateFlavorParams_ = () => {
        this.flavorsCollection.reset();

        const { segroup } = this;
        const params = {
            show_only_recommended: !this.showNonRecommendedFlavors,
            license_type: segroup.getLicenseType(),
        };

        this.flavorsCollection.setParams(params);
    };

    /**
     * Informs if current cloud for this SEGroup supports flavors.
     * @returns {boolean}
     */
    supportsFlavors() {
        switch (this.cloud.getVtype()) {
            case 'CLOUD_AZURE':
            case 'CLOUD_AWS':
            case 'CLOUD_OPENSTACK':
            case 'CLOUD_GCP':
                return true;
            default:
                return false;
        }
    }

    /**
     * Acts upon user toggle of per_app.
     */
    handlePerAppSeModeToggle() {
        this.setSeBandwidthType_();
        this.setMaxVsPerSe_();
    }

    /**
     * Disallows user selection of max_vs_per_se under certain conditions.
     * @return {boolean}
     */
    disableMaxVsPerSeSelection() {
        return this.isPerAppSeModeEnabled_();
    }

    /**
     * Forces selection of max_vs_per_se under certain conditions.
     * @protected
     */
    setMaxVsPerSe_() {
        if (this.isPerAppSeModeEnabled_()) {
            this.segroup.getConfig().max_vs_per_se = 2;
        }
    }

    /**
     * Informs if per_app is enabled.
     * @return {boolean}
     * @protected
     */
    isPerAppSeModeEnabled_() {
        return !!this.segroup.getConfig().per_app;
    }

    /**
     * Disallows user selection of max_num_se_dps under certain conditions.
     * @return {boolean}
     */
    disableMaxNumSeDpsSelection() {
        switch (this.segroup.getSeBandwidthType()) {
            case 'SE_BANDWIDTH_200M':
            case 'SE_BANDWIDTH_25M':
                return true;

            default:
                return false;
        }
    }

    /**
     * Forces selection of max_num_se_dps under certain conditions.
     * @protected
     */
    setMaxNumSeDps_() {
        const { segroup } = this;
        const config = segroup.getConfig();

        switch (segroup.getSeBandwidthType()) {
            case 'SE_BANDWIDTH_200M':
                config.max_num_se_dps = 2;
                break;

            case 'SE_BANDWIDTH_25M':
                config.max_num_se_dps = 1;
                break;
        }
    }

    /** @override */
    $onDestroy() {
        if (this.flavorsCollection) {
            this.flavorsCollection.destroy();
        }
    }
}

SegroupLicenseSelectorController.$inject = [
    '$http',
    '$timeout',
    '$element',
    'schemaService',
    'dropDownUtils',
    'SEGroupFlavorCollection',
    'l10nService',
];

const templateUrl = `src/components/infrastructure/segroup/${componentTag}/${componentTag}.html`;

/**
 * @mixin segroupLicenseSelectorBindings
 * @memberOf module:avi/licensing
 * @see {@link module:avi/licensing.SegroupLicenseSelectorComponent}
 * @property {Cloud} cloud - cloud instance
 * @property {SEGroup} segroup - segroup instance
 */
const segroupLicenseSelectorBindings = {
    cloud: '<',
    segroup: '<',
};

/**
 * @name SegroupLicenseSelectorComponent
 * @ngdoc component
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing.segroupLicenseSelectorBindings} bindings
 * @property {module:avi/licensing.SegroupLicenseSelectorController} controller
 * @description Component for selecting licensing info for SEGroup instance.
 * @author Akul Aggarwal
 */
angular.module('avi/licensing').component('segroupLicenseSelector', {
    bindings: segroupLicenseSelectorBindings,
    controller: SegroupLicenseSelectorController,
    templateUrl,
});
