/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf-profile';
const componentName = 'waf-data-file-grid';
const prefix = `${moduleName}.${componentName}`;

export const wafDataFileGridTitle = `${prefix}.wafDataFileGridTitle`;
export const wafDataFileName = `${prefix}.wafDataFileName`;
export const wafDataFileType = `${prefix}.wafDataFileType`;
export const addWafDataFileButtonLabel = `${prefix}.addWafDataFileButtonLabel`;
export const removeWafDataFileButtonLabel = `${prefix}.removeWafDataFileButtonLabel`;
export const editWafDataFileButtonLabel = `${prefix}.editWafDataFileButtonLabel`;
export const expandWafDataFileButtonLabel = `${prefix}.expandWafDataFileButtonLabel`;
export const wafDataLabel = `${prefix}.wafDataLabel`;

export const ENGLISH = {
    [wafDataFileGridTitle]: 'Files: ({0})',
    [wafDataFileName]: 'Name',
    [wafDataFileType]: 'Type',
    [addWafDataFileButtonLabel]: 'Add',
    [removeWafDataFileButtonLabel]: 'Remove',
    [editWafDataFileButtonLabel]: 'Edit',
    [expandWafDataFileButtonLabel]: 'Expand',
    [wafDataLabel]: 'Data',
};
