/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import classnames from 'classnames';
import * as l10n from './waf-rule.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafRuleController {
    constructor(
        Regex,
        l10nService,
    ) {
        this.Regex = Regex;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);

        this.expanded = false;
        this.showRule = false;
    }

    /**
     * Called when Show/Hide Rule is clicked.
     */
    handleExpand() {
        this.expanded = !this.expanded;

        if (this.expanded) {
            this.showRule = true;
        }
    }

    /**
     * Called when the Show/Hide Rule button is clicked.
     * @param {MouseEvent} event - MouseEvent. Used to stop event propagation.
     */
    handleShowRule(event) {
        event.stopPropagation();
        this.showRule = !this.showRule;
    }

    /**
     * Called when the trash icon is clicked to remove a rule.
     */
    handleRemoveRule() {
        this.onRemoveRule();
    }

    /**
     * Returns the tooltip for the icon next to the rule name.
     * @return {string}
     */
    getIconTooltipText() {
        const hasExclude = this.rule.hasExcludeListEntries();
        const hasCustom = this.rule.hasCustomMode();
        const { l10nService_: l10nService } = this;

        if (hasExclude && hasCustom) {
            return l10nService.getMessage(
                l10nKeys.ruleWithCustomModeAndExceptionsConfiguredMessage,
            );
        }

        if (hasExclude) {
            return l10nService.getMessage(l10nKeys.ruleWithExceptionsConfiguredMessage);
        }

        if (hasCustom) {
            return l10nService.getMessage(l10nKeys.ruleWithCustomModeConfiguredMessage);
        }
    }

    /**
     * Called to edit a rule.
     */
    handleEditRule() {
        this.onEditRule();
    }

    /**
     * Returns true if the User Overrides badge should be shown.
     * @returns {boolean}
     */
    showOverridesBadge() {
        return this.rule.hasExcludeListEntries() || this.rule.hasCustomMode();
    }

    /**
     * Returns the display value for a rule name.
     * @returns {string}
     */
    get wafRuleName() {
        const {
            rule,
            l10nService_: l10nService,
        } = this;
        const ruleId = rule.getId() || l10nService.getMessage(l10nKeys.emptyDataLabel);
        const ruleName = rule.getRuleName() || l10nService.getMessage(l10nKeys.emptyDataLabel);

        return `${ruleId} | ${ruleName}`;
    }

    /**
     * Returns the enabled display setting for a rule.
     * @returns {string}
     */
    get wafRuleEnabledSettingLabel() {
        return this.rule.isEnabled() ?
            this.l10nService_.getMessage(this.l10nKeys.enabledLabel) :
            this.l10nService_.getMessage(this.l10nKeys.deactivatedLabel);
    }

    /**
     * Returns the class name to be set on the enabled display setting. Enabled should show as green
     * and deactivated should show as gray.
     * @returns {string}
     */
    get wafRuleEnabledStateClassName() {
        const enabledClassName = this.rule.isEnabled() ?
            'waf-rule__enabled-setting--enabled' :
            'waf-rule__enabled-setting--deactivated';

        return classnames(
            'waf-rule__enabled-setting',
            enabledClassName,
        );
    }
}

WafRuleController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  wafRule
 * @description Component for configuring a WAF rule.
 * @param {WafRule} rule - WAF rule.
 * @param {Function} onRemoveRule - Called to remove a rule.
 * @param {string} mode - Parent mode.
 * @param {Function} onEditRule - Called to edit a rule.
 * @author alextsg
 */
angular.module('aviApp').component('wafRule', {
    controller: WafRuleController,
    bindings: {
        rule: '<',
        onRemoveRule: '&',
        mode: '@',
        onEditRule: '&',
    },
    templateUrl: 'src/components/modals/waf-policy-modal/waf-rule/waf-rule.html',
});
