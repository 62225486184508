/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'user-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitileUsername = `${prefix}.columnTitileUsername`;
export const columnTitileSuperUser = `${prefix}.columnTitileSuperUser`;
export const columnTitileStatus = `${prefix}.columnTitileStatus`;
export const columnTitileFullName = `${prefix}.columnTitileFullName`;
export const columnTitileEmail = `${prefix}.columnTitileEmail`;
export const columnTitileTenant = `${prefix}.columnTitileTenant`;
export const columnTitileLastSignedIn = `${prefix}.columnTitileLastSignedIn`;
export const gridMultiActionDeleteLabel = `${prefix}.gridMultiActionDeleteLabel`;
export const gridMultiActionActivateLabel = `${prefix}.gridMultiActionActivateLabel`;
export const gridMultiActionSuspendLabel = `${prefix}.gridMultiActionSuspendLabel`;
export const onlineSincePrefix = `${prefix}.onlineSincePrefix`;
export const lastLoginPrefix = `${prefix}.lastLoginPrefix`;

export const ENGLISH = {
    [columnTitileUsername]: 'Username',
    [columnTitileSuperUser]: 'Super User',
    [columnTitileStatus]: 'Status',
    [columnTitileFullName]: 'Full Name',
    [columnTitileEmail]: 'Email',
    [columnTitileTenant]: 'Tenant (Role)',
    [columnTitileLastSignedIn]: 'Last Signed In',
    [gridMultiActionDeleteLabel]: 'Delete',
    [gridMultiActionActivateLabel]: 'Activate',
    [gridMultiActionSuspendLabel]: 'Suspend',
    [onlineSincePrefix]: 'Online since ',
    [lastLoginPrefix]: 'Last login ',
};
