/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  AzureConfigurationConfig
 * @description  AzureConfigurationConfig ConfigItem class.
 */
const azureConfigurationConfigFactory = (ConfigItem, defaultValues, $q) => {
    return class AzureConfigurationConfig extends ConfigItem {
        constructor(args) {
            const extendedArgs = angular.extend(
                {
                    defaultConfig:
                        defaultValues.getDefaultItemConfig('cloud.azure_configuration'),
                    type: 'AzureConfiguration',
                },
                args,
            );

            super(extendedArgs);
        }

        /**
         * Clears the Service Engine network.
         * @param {number=} index - Index of the object in the network_info list. We expect
         *     it to only have 1 element.
         */
        clearNetwork(index = 0) {
            const { network_info: networkInfo } = this.getConfig();

            delete networkInfo[index].se_network_id;
        }

        /**
         * Returns lists of Azure Locations And Resource Groups.
         * @return {ng.$q.promise}
         */
        getAzureLocationsAndResourceGroups() {
            const {
                cloud_credentials_ref: cloudCredentialsRef,
                subscription_id: subscriptionId,
            } = this.getConfig();

            const payload = {
                subscription_id: subscriptionId,
            };

            if (cloudCredentialsRef) {
                payload.cloud_credentials_uuid = cloudCredentialsRef.slug();
            }

            this.busy = true;
            this.errors = null;

            const promises = [
                this._getAzureResourceGroups(payload).then(({ data }) => data.resource_groups),
                this._getAzureLocations(payload).then(({ data }) => data.locations || []),
            ];

            return $q.all(promises)
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject(data);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Clears the Location, Resource Group and Network fields,
         * called when credentials have changed.
         */
        clearLocationResourceGroupAndNetworkFields() {
            const config = this.getConfig();

            delete config.location;
            delete config.resource_group;
            this.clearLocationBasedFields();
        }

        /**
         * Clears the location based fields, called when the Location has changed.
         */
        clearLocationBasedFields() {
            const config = this.getConfig();
            const { network_info: [networkInfoConfig] } = config;

            delete config.des_id;
            delete networkInfoConfig.virtual_network_id;
            delete networkInfoConfig.se_network_id;
        }

        /**
         * Returns lists based on the location, ie. the list of VNets and
         * optionally the list of networks. The list of networks is based on the VNet, but if
         * the cloud is being edited, the VNet is already present so the networks list can be
         * requested.
         * @param {boolean=} includeNetworks
         * @return {ng.$q.promise}
         */
        getLocationBasedLists(includeNetworks = false) {
            const config = this.getConfig();
            const {
                cloud_credentials_ref: cloudCredentialsRef,
                subscription_id: subscriptionId,
                location,
            } = config;

            const payload = {
                location,
                subscription_id: subscriptionId,
            };

            if (cloudCredentialsRef) {
                payload.cloud_credentials_uuid = cloudCredentialsRef.slug();
            }

            this.busy = true;
            this.errors = null;

            const promises = [
                this._getAzureVirtualNetworks(payload).then(({ data }) => data.vnets),
                this._getAzureDesResourceIds(payload)
                    .then(({ data }) => data['disk-encryption-sets']),
            ];

            if (includeNetworks) {
                const networkInfo = config.network_info[0];
                const networkPayload = angular.extend(
                    { vnet_id: networkInfo.virtual_network_id },
                    payload,
                );

                promises.push(this._getAzureNetworks(networkPayload)
                    .then(({ data }) => data.subnets));
            }

            return $q.all(promises)
                .catch(({ data }) => {
                    this.errors = data;

                    return $q.reject(data);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for Azure subnets while setting this.busy and this.errors.
         * @return {ng.$q.promise}
         */
        getAzureNetworks() {
            const {
                cloud_credentials_ref: cloudCredentialsRef,
                subscription_id: subscriptionId,
                location,
                network_info: networkInfo,
            } = this.getConfig();

            const payload = {
                location,
                subscription_id: subscriptionId,
                vnet_id: networkInfo[0].virtual_network_id,
            };

            if (cloudCredentialsRef) {
                payload.cloud_credentials_uuid = cloudCredentialsRef.slug();
            }

            this.busy = true;
            this.errors = null;

            return this._getAzureNetworks(payload)
                .then(({ data }) => data.subnets)
                .catch(rsp => {
                    this.errors = rsp.data;

                    return $q.reject(rsp);
                })
                .finally(() => this.busy = false);
        }

        /**
         * Makes request for Azure locations.
         * @param {Object} payload - Payload for the request.
         * @return {ng.$q.promise}
         */
        _getAzureLocations(payload) {
            return this.request('POST', '/api/azure-get-locations', payload);
        }

        /**
         * Makes request for Resource Groups.
         * @param {Object} payload - Payload for the request.
         * @return {ng.$q.promise}
         */
        _getAzureResourceGroups(payload) {
            return this.request('POST', '/api/azure-get-resource-groups', payload);
        }

        /**
         * Makes request for VNets.
         * @param {Object} payload - Payload for the request.
         * @return {ng.$q.promise}
         */
        _getAzureVirtualNetworks(payload) {
            return this.request('POST', '/api/azure-get-virtual-networks', payload);
        }

        /**
         * Makes request for Azure subnets to be used as networks.
         * @private
         * @return {ng.$q.promise}
         */
        _getAzureNetworks(payload) {
            return this.request('POST', '/api/azure-get-subnets', payload);
        }

        /**
         * Makes requests to fetch diskEncryptionSets.
         * @param {Object} payload
         * @returns {Promise} To be resolved with Object (data['disk-encryption-sets'])
         * @private
         */
        _getAzureDesResourceIds(payload) {
            const requestConfig = {
                method: 'GET',
                url: '/api/azure-list-des',
                params: payload,
            };

            return this.request(requestConfig);
        }

        /**
         * @override
         */
        beforeEdit() {
            const config = this.getConfig();

            config.network_info = config.network_info || [];

            if (!config.network_info.length) {
                config.network_info.push({});
            } else if (config.network_info[0]['management_network_id']) {
                config.useManagementNetwork_ = true;
            }
        }

        /**
         * @override
         */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            delete config.useManagementNetwork_;

            return config;
        }
    };
};

azureConfigurationConfigFactory.$inject = [
    'ConfigItem',
    'defaultValues',
    '$q',
];

angular.module('aviApp').factory('AzureConfigurationConfig', azureConfigurationConfigFactory);
