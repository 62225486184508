/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'dns-resolver-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleName]: 'Name',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
};
