/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { ISSLRating, SSLScore } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Ajs dependency token for SSLRating configItem.
 */
export const SSL_RATING_CONFIG_ITEM_TOKEN = 'SSLRatingConfigItem';

/**
 * @description SSLRating ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class SSLRatingConfigItem extends MessageItem<ISSLRating> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'SSLRating',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for Compatibility rating of a sslProfile
     */
    public get compatibilityRating(): SSLScore | undefined {
        const { config } = this;

        return config.compatibility_rating;
    }

    /**
     * Getter for Performance rating of a sslProfile
     */
    public get performanceRating(): SSLScore | undefined {
        const { config } = this;

        return config.performance_rating;
    }

    /**
     * Getter for security score.
     */
    public get securityScore(): number | undefined {
        const { config } = this;

        return Number(config.security_score);
    }
}
