/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'groupsModule';
const componentName = 'ip-addr-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const ipAddressPlaceholder = `${prefix}.ipAddressPlaceholder`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;
export const inputHelperText = `${prefix}.editButtonLabel`;
export const duplicateValuesErrorMsg = `${prefix}.duplicateValuesErrorMsg`;
export const keyDuplicationErrorMsg = `${prefix}.keyDuplicationErrorMsg`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleIpAddress]: 'IP Address',
    [ipAddressPlaceholder]: 'Enter IP Address',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
    [inputHelperText]: 'Accepts Single, Comma Separated and Ranges',
    [duplicateValuesErrorMsg]: 'Duplicate values: {0}',
    [keyDuplicationErrorMsg]: 'System does not support duplicate values. Please update or delete.',
};
