/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'infra-dashboard-controller';
const prefix = `${moduleName}.${componentName}`;

export const healthScoreOptionLabel = `${prefix}.healthScoreOptionLabel`;
export const nameOptionLabel = `${prefix}.nameOptionLabel`;
export const sortByDropdownLabel = `${prefix}.sortByDropdownLabel`;
export const searchInputPlaceolder = `${prefix}.searchInputPlaceolder`;
export const serviceEngineHeader = `${prefix}.serviceEngineHeader`;
export const emptyServiceEnginesSearchMatchMessage = `${prefix}.emptyServiceEnginesSearchMatchMessage`;
export const showHideLegendTitle = `${prefix}.showHideLegendTitle`;
export const alertsLabel = `${prefix}.alertsLabel`;
export const emptyServiceEnglinesCreatedMessage = `${prefix}.emptyServiceEnglinesCreatedMessage`;

export const ENGLISH = {
    [sortByDropdownLabel]: 'Sort By',
    [healthScoreOptionLabel]: 'Health Score',
    [nameOptionLabel]: 'Name',
    [searchInputPlaceolder]: 'Search',
    [serviceEngineHeader]: 'Service Engine ({0})',
    [emptyServiceEnginesSearchMatchMessage]: 'No Service Engines match your search criteria.',
    [showHideLegendTitle]: 'Show/Hide Legend',
    [alertsLabel]: 'Alerts',
    [emptyServiceEnglinesCreatedMessage]: 'No Service Engines have been created.',
};
