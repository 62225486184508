/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { LinuxServerConfiguration } from 'object-types';
import { LinuxServerConfigurationConfigItem } from 'ajs/modules/cloud';

import {
    DefaultSeUsageValues,
} from 'ajs/modules/cloud/factories/linux-server-configuration.config-item.factory';

import * as l10n from './se-usage-directory-path.l10n';
import './se-usage-directory-path.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Direct Path for Service Engine Usage section in Linux Cloud.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'se-usage-directory-path',
    templateUrl: 'se-usage-directory-path.component.html',
})
export class SeUsageDirectoryPathComponent {
    /**
     * LinuxServerConfiguration Config Item.
     */
    @Input()
    public editable: LinuxServerConfigurationConfigItem;

    /**
     * Input for Service Engine Usage Directory path passed from Cloud Modal Component.
     */
    @Input()
    public seUsagePath: string;

    /**
     * Object Type to be used in template.
     */
    public readonly objectType = LinuxServerConfiguration;

    /**
     * Holds values of DefaultSeUsageValues enum to use in template.
     */
    public readonly defaultSeUsageValues = DefaultSeUsageValues;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
