/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { isNaN, isUndefined } from 'underscore';

// Importing declarations
import { MessageItem, RepeatedMessageItem } from 'ajs/modules/data-model/factories';
import { IPolicyRuleConfig, PolicyRuleExtendableConfigItem } from '.';

export const POLICY_EXTENDABLE_CONFIG_ITEM_TOKEN = 'PolicyExtendableConfigItem';

/**
 * @desc
 *     Abstract Policy ConfigItem class. Used for protobuf messages that map to policies.
 *     ie. L4Policy, HTTPPolicy (Note: NatPolicy excluded, since it's an ObjectTypeItem)
 *     This class works as an abstraction which contains routine operations (adding/deleting a
 *     rule, etc.) of a policy.
 * @author Zhiqian Liu
 */
export abstract class PolicyExtendableConfigItem
<T extends PolicyRuleExtendableConfigItem<IPolicyRuleConfig>> extends MessageItem {
    // override getConfig()'s return type
    public getConfig: () => { rules: RepeatedMessageItem<T> };

    /**
     * Getter for the RepeatedMessageItem containing real rules of the policy.
     */
    public get rules(): RepeatedMessageItem<T> {
        return this.getConfig().rules;
    }

    /**
     * Getter for the number of rules in policy.
     */
    public get rulesCount(): number {
        return this.rules.count;
    }

    /**
     * Returns a new policy rule ConfigItem instance.
     */
    public createNewRule(config: {} = null): T {
        return this.createChildByField_('rules', config, true) as T;
    }

    /**
     * Adds a rule to the list of rules. If the rule has an index, it means that an existing
     * rule was modified so the existing rule should be replaced. If not, add the new rule to
     * the end of the list.
     */
    public addRule(rule: T): void {
        const { rules } = this;
        const index = rule.getIndex();

        // New rule doesn't have an index.
        if (isUndefined(index)) {
            const maxIndex = rules.getMaxIndex();
            const newIndex = isNaN(maxIndex) ? 0 : maxIndex + 1;

            rule.setIndex(newIndex);
            rules.add(rule);
        } else {
            const arrayIndex = rules.getArrayIndexWithIndexField(index);

            rules.config[arrayIndex] = rule;
        }
    }
}
