/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function eventsListPageStatusService(
    Base,
    eventSearchParser,
) {
    /**
     * @class
     * @constructor
     * @memberOf module:avi/events
     * @extends module:avi/dataModel.Base
     * @desc
     *
     *     Layer between {@link module:avi/events.EventsListController EventsListController}
     *     and {@link module:avi/events.EventsListDefaultSummaryComponentController
     *     Summary Popover Controller} which opens from the sidebar. Basically keeps a reference
     *     to EventsListController.params object and few useful methods to keep both parties in
     *     touch.
     *
     * @author Alex Malitsky
     */
    class EventsListPageStatus extends Base {
        /**
         * Events list page status $scope.params of {@link module:avi/events.EventsListController
         * EventsListController}.
         * @type {Object|null}
         * @protected
         */
        params_ = null;

        /**
         * EventsListController sets internal reference to $scope.params (only once during
         * initialization).
         * @param {Object|null} params
         * @public
         */
        setStatus(params) {
            this.params_ = params;
        }

        /**
         * Updates the search value of EventsListController#params. EventsListController should
         * listen to the event triggered by method and re-fetch appropriate data from the backend
         * once search filter got updated.
         * @param {string} string
         * @public
         */
        addSearchFilter(string) {
            this.params_.search = string;
            this.trigger('searchFilterUpdate');
        }

        /**
         * Returns the state of EventsListController#params which is nothing but a set of filters
         * for API call. Will be used to set Collection parameters of a Summary popover.
         * @returns {{string: *}}
         * @public
         */
        getPageStateFilters() {
            const { params_ } = this;
            const filters = [];

            const params = {
                end: params_.end.toISOString(),
                duration: params_.duration,
            };

            if (params_.slug) {
                filters.push(`co(all,"${params_.slug}")`);
            }

            if (params_.search) {
                filters.push(...eventSearchParser.parse(params_.search));
            }

            if (!params_.inclInternal) {
                filters.push('ne(internal,EVENT_INTERNAL)');
            }

            if (params_.portalSection) {
                filters.push(`co(event_pages,${params_.portalSection})`);
            }

            params.filter = filters;

            return params;
        }
    }

    //exporting singleton only
    return new EventsListPageStatus();
}

eventsListPageStatusService.$inject = [
    'Base',
    'eventSearchParser',
];

angular.module('avi/events')
    .factory('eventsListPageStatus', eventsListPageStatusService);
