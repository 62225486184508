/**
 * Module containing Logs(VirtualService#Logs) related components and services.
 * @preferred
 * @module LogsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { ClrSpinnerModule } from '@clr/angular';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AviFormsModule } from '../avi-forms/avi-forms.module';
import { DialogModule } from '../dialog/dialog.module';

import {
    RecommendationDialogComponent,
    RecommendationDialogService,
    RecommendationGridComponent,
} from '.';

const logsComponents = [
    RecommendationDialogComponent,
    RecommendationGridComponent,
];

/**
 * @description Module containing Logs related components and services.
 * @author vgohil
 */
@NgModule({
    declarations: [
        ...logsComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        ClrSpinnerModule,
        DialogModule,
        SharedModule,
    ],
    providers: [
        RecommendationDialogService,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class LogsModule {}
