/** @module L4PolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { Cancelable, debounce } from 'underscore';

import { L10nService } from '@vmw/ngx-vip';

import { L4RulePortMatch } from 'object-types';

import {
    L4RulePortMatchConfigItem,
} from 'ajs/modules/l4-policy/factories/matches/l4-rule-port-match.config-item.factory';

import {
    parseStartEndToNumbers,
} from 'ng/modules/avi-forms/components/avi-repeated-numbers/avi-repeated-numbers.utils';

import * as l10n from './l4-rule-port-match.l10n';
import './l4-rule-port-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Match component for L4RulePortMatch message.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'l4-rule-port-match',
    templateUrl: './l4-rule-port-match.component.html',
})
export class L4RulePortMatchComponent implements OnInit, OnDestroy {
    /**
     * L4RulePortMatch message item instance.
     */
    @Input()
    public editable: L4RulePortMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Debounced version of updateDataWithPortList() method of the editable.
     * Used to flush and reconstruct the data on portList update.
     */
    public debouncedUpdateDataWithPortList: (() => void) & Cancelable;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Max limit of a port number.
     */
    public readonly portNumberMax = 65535;

    /**
     * Min limit of a port number.
     */
    public readonly portNumberMin = 1;

    /**
     * Type string of L4RulePortMatch.
     */
    public l4RulePortMatchObjectType: string;

    /**
     * List of ports passed as the ngModel to avi-repeated-numbers.
     * Contains normalized ports and port ranges as pure array of numbers.
     */
    public portList: number[] = [];

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.l4RulePortMatchObjectType = L4RulePortMatch;
        this.debouncedUpdateDataWithPortList = debounce(
            () => this.editable.updateDataWithPortList(this.portList),
            200,
        );

        this.parsePorts();
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.debouncedUpdateDataWithPortList.cancel();
    }

    /**
     * Parse ports and port ranges to an array of individual port numbers.
     */
    private parsePorts(): void {
        const { ports, port_ranges: portRanges } = this.editable.config;

        if (ports) {
            this.portList.push(...ports);
        }

        portRanges.config.forEach(portRangeConfigItem => {
            const { start, end } = portRangeConfigItem.config;
            const resultPorts = parseStartEndToNumbers(start, end);

            this.portList.push(...resultPorts);
        });
    }
}
