/**
 * @module ClusterModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    Cluster,
    ClusterNodeConfigItem,
} from 'ajs/modules/cluster/factories';

import * as l10n from './cluster-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description cluster edit full-modal component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'cluster-modal',
    templateUrl: './cluster-modal.component.html',
})
export class ClusterModalComponent implements OnInit {
    /**
     * Cluster ObjectTypeItem instance.
     */
    @Input()
    public editable: Cluster;

    /**
     * Cluster object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Error Page Body modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Opens cluster node modal to add new ClusterNode.
     */
    public createClusterNode(): void {
        this.editable.addClusterNode();
    }

    /**
     * Opens cluster node modal to edit new editClusterNode.
     */
    public editClusterNode(clusterNode: ClusterNodeConfigItem): void {
        this.editable.updateClusterNode(clusterNode);
    }

    /**
     * Deletes cluster node from ClusterNode List.
     */
    public deleteClusterNode(clusterNode: ClusterNodeConfigItem): void {
        const { clusterNodeList } = this.editable;

        clusterNodeList.removeByMessageItem(clusterNode);
    }
}
