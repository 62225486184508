/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    WafPSMLocationConfigItem,
    WafPSMLocationMatchConfigItem,
} from 'ajs/modules/waf';
import './waf-policy-psm-location-expander-content.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-psm-location-expander-content.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name wafPolicyPsmLocationExpanderContent
 * @module waf/wafPolicyPsmLocationExpanderContent
 * @desc Displays match information about a WAF PSM Location.
 * @param location - WAF PSM Location.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-location-expander-content',
    templateUrl: './waf-policy-psm-location-expander-content.component.html',
})
export class WafPolicyPsmLocationExpanderContentComponent implements OnInit {
    @Input() public location: WafPSMLocationConfigItem;

    public match: WafPSMLocationMatchConfigItem;
    public field: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    public ngOnInit(): void {
        this.match = this.location.config.match;
    }

    /**
     * Callback to ngFor-track-by
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
