/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { getResolvablePromise, IResolvablePromise } from 'ng/shared/utils';
import { DevLoggerService } from 'ng/modules/core';

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export interface IHttpWrapperRequestConfig {
    method: HttpMethod;
    url: string;
    data?: any;
    headers?: ng.IHttpRequestConfigHeaders;
    requestId?: string; // unique Id for a request, used to cancel it.
    params?: Record<string, any>;
    uploadEventHandlers?: Record<string, any>;
}

interface IHttpWrapperRequestPromise extends Promise<any> {
    cancel?: (reason: string) => void;
}

/**
 * Ajs Dependency token for HttpWrapper.
 */
export const HTTP_WRAPPER_TOKEN = 'HttpWrapper';

/**
 * HttpWrapper class type.
 */
export type THttpWrapper = typeof HttpWrapper;

/**
 * @desc Wrapper class to create and cancel HTTP requests.
 * @author Aravindh Nagarajan
 */
export class HttpWrapper extends AjsDependency {
    private $httpService: ng.IHttpService;
    private readonly devLoggerService: DevLoggerService;

    /**
     * Hash of request-id and promise to cancel it.
     */
    private requestsHash: Record<string, IResolvablePromise<string>> = {};

    constructor() {
        super();

        this.$httpService = this.getAjsDependency_('$http');
        this.devLoggerService = this.getAjsDependency_('devLoggerService');
    }

    /**
     * Makes a HTTP Request to the url provided.
     * If request-id is provided, we will be able to cancel the request.
     */
    public request(requestConfig: IHttpWrapperRequestConfig): IHttpWrapperRequestPromise {
        const { $httpService } = this;
        const { requestId } = requestConfig;
        const cancelRequestPromise: IResolvablePromise<string> = getResolvablePromise<string>();

        const httpDefaults = {
            group: '',
            params: {},
            paramSerializer: 'httpParamFullSerializer',
            timeout: cancelRequestPromise,
        };

        const httpRequestConfig: ng.IRequestConfig = {
            ...httpDefaults,
            ...requestConfig,
        };

        if (requestId) {
            // To make sure only one request can be made at a time for a request ID.
            if (requestId in this.requestsHash) {
                this.cancelRequest(requestId);
            }

            // Collecting requests to be able to cancel them later
            this.addToRequestsList(requestId, cancelRequestPromise);
        }

        const httpRequestPromise: IHttpWrapperRequestPromise = new Promise((resolve, reject) => {
            $httpService(httpRequestConfig)
                .then(resolve)
                .catch(reject)
                .finally(() => {
                    if (requestId) {
                        this.removeFromRequestsList(requestId, cancelRequestPromise);
                    }
                });
        });

        // Returned promise should have an option to cancel the call
        httpRequestPromise.cancel = (reason: string): void => {
            cancelRequestPromise.resolve(reason);
        };

        return httpRequestPromise;
    }

    /**
     * Cancels pending requests for all request-ids.
     */
    public cancelAllRequests(): void {
        const { requestsHash } = this;

        const cancelRequestPromises = Object.values(requestsHash);

        cancelRequestPromises
            .forEach(promise => promise.resolve('All outstanding requests are being cancelled'));

        this.requestsHash = {};
    }

    /**
     * Cancels ongoing requests using request-id
     * @param requestId - specific requestId to cancel
     */
    public cancelRequest(requestId = ''): void {
        const { requestsHash } = this;

        if (requestId) {
            const cancelRequestPromise = requestsHash[requestId];

            if (!cancelRequestPromise) {
                // No-op when group doesn't exist
                this.devLoggerService.warn(`Request: ${requestId} does not exist`);

                return;
            }

            // cancels the request
            cancelRequestPromise.resolve(`Request: ${requestId} is cancelled`);
        } else {
            // Cancel them all
            this.cancelAllRequests();
        }
    }

    /**
     * Adds a request to requestsHash.
     */
    private addToRequestsList(
        requestId: string,
        cancelRequestPromise: IResolvablePromise<string>,
    ): void {
        const { requestsHash } = this;

        requestsHash[requestId] = cancelRequestPromise;
    }

    /**
     * Removes a request from requestsHash.
     */
    private removeFromRequestsList(
        requestId: string,
        cancelRequestPromise: IResolvablePromise<string>,
    ): void {
        const { requestsHash } = this;

        // This check is crucially important in order to avoid race condition
        if (cancelRequestPromise === requestsHash[requestId]) {
            delete requestsHash[requestId];
        }
    }
}

HttpWrapper.ajsDependencies = [
    '$http',
    'devLoggerService',
];
