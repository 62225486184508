/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'azure-configuration';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const credentialsSectionHeader = `${prefix}.credentialsSectionHeader`;
export const subscriptionIdLabel = `${prefix}.subscriptionIdLabel`;
export const credentialsNotSetPlaceholder = `${prefix}.credentialsNotSetPlaceholder`;
export const azureCredentialsLabel = `${prefix}.azureCredentialsLabel`;
export const useAzureMsiAuthenticationLabel = `${prefix}.useAzureMsiAuthenticationLabel`;
export const useAzureMsiAuthenticationEnabledValue = `${prefix}.useAzureMsiAuthenticationEnabledValue`;
export const useAzureMsiAuthenticationNotEnabledValue = `${prefix}.useAzureMsiAuthenticationNotEnabledValue`;
export const setCredentialsButtonLabel = `${prefix}.setCredentialsButtonLabel`;
export const changeCredentialsButtonLabel = `${prefix}.changeCredentialsButtonLabel`;

export const ENGLISH = {
    [sectionTitle]: 'Azure',
    [credentialsSectionHeader]: 'Credentials',
    [subscriptionIdLabel]: 'Subscription ID',
    [credentialsNotSetPlaceholder]: 'Not Set',
    [azureCredentialsLabel]: 'Azure Credentials',
    [useAzureMsiAuthenticationLabel]: 'Use Azure Managed Service Identity (MSI) Authentication',
    [useAzureMsiAuthenticationEnabledValue]: 'Enabled',
    [useAzureMsiAuthenticationNotEnabledValue]: 'Not Enabled',
    [setCredentialsButtonLabel]: 'Set Credentials',
    [changeCredentialsButtonLabel]: 'Change Credentials',
};
