/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

// TODO destroyAll should reject modalPromises

import {
    each,
    isEmpty,
    isUndefined,
} from 'underscore';
import { Auth } from 'ajs/modules/core/services/auth';
import * as Regex from 'ng/utils/regex.utils';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-modal.l10n';
import { AviModalType } from '.';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TRegex = typeof Regex;

export type TComponentBindings = Record<string, any>;

type TActiveModal = { [modalId: string]: IActiveModalProps };

interface IActiveModalProps {
    elem?: IJQLiteAviModal;
    id: string;
    isReady: boolean;
    type: AviModalType;
    scope?: IModalParentScope;
}

interface IModalParentScope extends ng.IScope {
    container: JQLite;
    forms: { [formName: string]: ng.IFormController };
    modalForm?: ng.IFormController;
    modalScope: IModalParentScope;
    closeModal(): void;
    destroy?(): void;
    init?(): void;
}

interface IJQLiteAviModal extends JQLite {
    aviModal(method?: string, modalType?: AviModalType): void;
}

export class AviModalService {
    /**
     * Keep track of all open modal windows.
     */
    private activeModals: TActiveModal = {};
    private readonly l10nKeys = l10nKeys;

    constructor(
        private authService: Auth,
        private $timeout: ng.ITimeoutService,
        private $compile: ng.ICompileService,
        private $rootScope: ng.IRootScopeService,
        private $templateRequest: ng.ITemplateRequestService,
        private Regex: TRegex,
        private $injector: ng.auto.IInjectorService,
        private templateStringBuilder: any,
        private componentBindingsSetter: any,
        l10nService: L10nService,
    ) {
        $rootScope.$on('userLoggedOut', this.destroyAll);
        $rootScope.$on('setContext', this.destroyAll);
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Disable page scrollbar.
     */
    private static disableBodyScroll(bool = true): void {
        if (bool) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = null;
        }
    }

    /**
     * Opens the modal window after getting its content through ng.$templateCache http call.
     * Calls this.getComponent or this.getTemplate internally.
     */
    public open(
        modalId: string,
        data?: TComponentBindings,
        className?: string,
        modalType: AviModalType = AviModalType.AVI_MODAL,
    ): Promise<void> {
        const camelCase = $.camelCase(modalId);

        if (!this.authService.isLoggedIn()) {
            return Promise.reject();
        } else if (this.isOpen(modalId)) {
            const error = `Modal window "${modalId}" is already opened or being opened`;

            console.warn(error);

            return Promise.reject(new Error(error));
        }

        let contentPromise;

        if (this.$injector.has(`${camelCase}Directive`)) {
            contentPromise = this.getComponent(modalId, data, className);
            data = this.componentBindingsSetter(modalId, data);
        } else {
            contentPromise = this.getTemplate(modalId);
        }

        this.activeModals[modalId] = {
            id: modalId,
            isReady: false,
            type: modalType,
        };

        return contentPromise
            .then((template: string) => {
                // modal got closed/destroyed
                // while template was being loaded (or promise getting resolved)
                if (!(modalId in this.activeModals)) {
                    return;
                }

                return this.openModal(modalId, modalType, data, template);
            });
    }

    /**
     * Closes the Modal, calling $scope.destroy on the parent scope.
     * For compatibility reasons rewrites provided by `data` set of properties on the scope object
     * and calls its destroy function if present. Both of these are deprecated now.
     */
    public destroy(modalId: string): Promise<void> {
        const modal = this.activeModals[modalId];

        if (!this.isOpen(modalId)) {
            console.warn(`Could not find modal window "${modalId}" in opened modals.`);
        } else if (modal.isReady) {
            const { type } = this.activeModals[modalId];

            modal.elem.aviModal('hide', type);

            return new Promise<void>(resolve => {
                // DEPRECATED TODO remove
                const { modalScope } = modal.scope;

                if (modalScope && typeof modalScope.destroy === 'function') {
                    this.$timeout(() => {
                        modalScope.destroy();
                        resolve();
                    });
                } else {
                    resolve();
                }
            }).then(() => {
                modal.scope.$destroy();
                modal.elem.remove();

                delete this.activeModals[modalId];

                if (!this.hasOpen()) {
                    AviModalService.disableBodyScroll(false);
                }
            });
        } else { // when we are loading a template right now
            delete this.activeModals[modalId];

            return Promise.resolve();
        }
    }

    /**
     * Destroys all active modals.
     */
    public destroyAll = (): void => {
        each(this.activeModals, modal => {
            if (modal) {
                this.destroy(modal.id);
            }
        });
    };

    /**
     * Checks if specified window is open.
     */
    public isOpen(modalId: string): boolean {
        return Boolean(modalId) && !isUndefined(this.activeModals[modalId]);
    }

    /**
     * Returns true if any modal is open.
     */
    public hasOpen(): boolean {
        return !isEmpty(this.activeModals);
    }

    /**
     * Puts some useful methods and properties on the modal window parent scope.
     */
    private initModalsParentScope(scope: ng.IScope): void {
        const common = {
            Auth: this.authService,
            Regex: this.Regex,
            forms: {},
            l10nKeys: this.l10nKeys,
        };

        Object.assign(scope, common);
    }

    /**
     * Returns template string for component.
     */
    private getComponent(
        componentId: string,
        bindings: TComponentBindings,
        className: string,
    ): Promise<string> {
        return Promise.resolve(this.templateStringBuilder(componentId, bindings, className));
    }

    /**
     * Returns HTML modal template.
     */
    private getTemplate(modalId: string): Promise<string> {
        const fullTplName = `src/views/modals/${modalId}.html`;
        const contentPromise = this.$templateRequest(fullTplName);

        return Promise.resolve(contentPromise)
            .catch(err => {
                delete this.activeModals[modalId];

                console.error(
                    'Can\'t find or load template file for modal window "%s". Response: %O',
                    modalId,
                    err,
                );

                return Promise.reject(err);
            });
    }

    /**
     * Does actual job of showing modal after getting its content.
     */
    private openModal(
        modalId: string,
        modalType: AviModalType,
        data: TComponentBindings,
        content: string,
    ): void {
        const modalParentScope = this.$rootScope.$new() as IModalParentScope;
        const isComponent = this.$injector.has(`${$.camelCase(modalId)}Directive`);

        /**
         * Function attached to the modalParentScope that closes the modal.
         * Must be declared as a binding in order to be used by the component.
         */
        modalParentScope.closeModal = () => {
            this.destroy(modalId);
        };

        if (isComponent) {
            Object.assign(modalParentScope, data);
        } else {
            this.initModalsParentScope(modalParentScope);
        }

        const elem = this.$compile(content)(modalParentScope) as IJQLiteAviModal;

        if (!isComponent && typeof modalParentScope.modalScope !== 'object') {
            if (elem.is('[ng-controller]')) {
                delete this.activeModals[modalId];
                throw new Error(`Modal window's (${modalId}) controller should set` +
                    ' a "modalScope" property on the $parent\'s scope referencing the' +
                    ' modal\'s scope.');
            } else { // parent scope is the only scope, no controller
                modalParentScope.modalScope = modalParentScope;
            }
        }

        $('div.modals').append(elem);

        AviModalService.disableBodyScroll();

        if (!isComponent) {
            if (modalParentScope.modalScope.forms) {
                each(modalParentScope.modalScope.forms, form => form.$setPristine());
            }

            if (modalParentScope.modalScope.modalForm) {
                modalParentScope.modalScope.modalForm.$setPristine();
            }

            // Put extra properties into the Modal's scope
            if (typeof data === 'object') {
                Object.assign(modalParentScope.modalScope, data);
            }
        }

        // Open up modal
        elem.aviModal(undefined, modalType);

        // Keep the link to the container in the scope
        modalParentScope.container = elem;

        // Focus the first input in the window
        this.$timeout(() => elem.find('input:first').trigger('focus'));

        if (!isComponent && typeof modalParentScope.modalScope.init === 'function') {
            modalParentScope.modalScope.init();
        }

        this.activeModals[modalId].scope = modalParentScope;
        this.activeModals[modalId].elem = elem;
        this.activeModals[modalId].isReady = true;
    }
}

AviModalService.$inject = [
    'Auth',
    '$timeout',
    '$compile',
    '$rootScope',
    '$templateRequest',
    'Regex',
    '$injector',
    'ComponentTemplateStringBuilder',
    'ComponentBindingsSetter',
    'l10nService',
];
