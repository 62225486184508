/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * VRFContext related services and components.
 * @module VrfContextModule
 * @preferred
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    BgpPeerConfigItem,
    BgpProfileConfigItem,
    bgpProfileDetailsComponentOptions,
    bgpProfileListComponentOptions,
    BgpRoutingOptionsConfigItem,
    BGP_PEER_CONFIG_ITEM_TOKEN,
    BGP_PROFILE_CONFIG_ITEM_TOKEN,
    BGP_PROFILE_DETAILS_COMPONENT_TOKEN,
    BGP_PROFILE_LIST_COMPONENT_TOKEN,
    BGP_ROUTING_OPTIONS_CONFIG_ITEM_TOKEN,
    deactivateBgpPeeringConfirmationComponentOptions,
    DEACTIVATE_BGP_PEERING_CONFIRMATION_COMPONENT_TOKEN,
    InfraCloudState,
    INFRA_CLOUD_STATE_SERVICE_TOKEN,
    VRFContext,
    VRFContextCollDataSource,
    VRFContextCollection,
    VRF_CONTEXT_COLLECTION_TOKEN,
    VRF_CONTEXT_COLL_DATASOURCE_TOKEN,
    VRF_CONTEXT_TOKEN,
} from '.';

const VrfContextModule = angular.module('avi/vrf');

const factories = [
    {
        name: VRF_CONTEXT_TOKEN,
        factory: VRFContext,
    }, {
        name: VRF_CONTEXT_COLLECTION_TOKEN,
        factory: VRFContextCollection,
    }, {
        name: VRF_CONTEXT_COLL_DATASOURCE_TOKEN,
        factory: VRFContextCollDataSource,
    }, {
        name: BGP_PROFILE_CONFIG_ITEM_TOKEN,
        factory: BgpProfileConfigItem,
    }, {
        name: BGP_PEER_CONFIG_ITEM_TOKEN,
        factory: BgpPeerConfigItem,
    }, {
        name: BGP_ROUTING_OPTIONS_CONFIG_ITEM_TOKEN,
        factory: BgpRoutingOptionsConfigItem,
    },
];

const services = [
    {
        name: INFRA_CLOUD_STATE_SERVICE_TOKEN,
        service: InfraCloudState,
    },
];

const components = [
    {
        name: BGP_PROFILE_LIST_COMPONENT_TOKEN,
        options: bgpProfileListComponentOptions,
    }, {
        name: BGP_PROFILE_DETAILS_COMPONENT_TOKEN,
        options: bgpProfileDetailsComponentOptions,
    }, {
        name: DEACTIVATE_BGP_PEERING_CONFIRMATION_COMPONENT_TOKEN,
        options: deactivateBgpPeeringConfirmationComponentOptions,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(VrfContextModule, 'factory', name, factory);
});

services.forEach(({ name, service }) => {
    initAjsDependency(VrfContextModule, 'service', name, service);
});

components.forEach(({ name, options }) => VrfContextModule.component(name, options));
