/**
 * @module ClusterModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    Cluster,
    ClusterNodeConfigItem,
} from './factories';

import {
    CLUSTER_ITEM_TOKEN,
    CLUSTER_NODE_CONFIG_ITEM_TOKEN,
} from './cluster.tokens';

const clusterModule = angular.module('avi/cluster');

const factories = [
    {
        factory: Cluster,
        name: CLUSTER_ITEM_TOKEN,
    },
    {
        factory: ClusterNodeConfigItem,
        name: CLUSTER_NODE_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(clusterModule, 'factory', name, factory);
});
