/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'match-and-action-wrapper';
const prefix = `${moduleName}.${componentName}`;

export const matchSectionHeader = `${prefix}.matchSectionHeader`;
export const actionSectionHeader = `${prefix}.actionSectionHeader`;

export const ENGLISH = {
    [matchSectionHeader]: 'Match',
    [actionSectionHeader]: 'Action',
};
