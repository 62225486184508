/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { Component, Type } from '@angular/core';
import { WafDataFile } from 'object-types';
import {
    WafDataFileModalComponent,
} from
    'ng/modules/waf/components/waf-profile-modal/waf-data-file-modal/waf-data-file-modal.component';
import { IWafDataFile } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from '../../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description WafDataFileConfig Item Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class WafDataFileConfigItem extends withFullModalMixin(MessageItem)<IWafDataFile> {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: WafDataFile,
            windowElement: WafDataFileModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Gets BreadCrumb Title for Create and Edit Modal.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafDataFileModalBreadcrumbTitle);
    }
}
