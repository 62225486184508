/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'compression-filters';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleClientIp = `${prefix}.columnTitleClientIp`;
export const columnTitleUserAgent = `${prefix}.columnTitleUserAgent`;
export const anyDataLabel = `${prefix}.anyDataLabel`;
export const columnTitleCompression = `${prefix}.columnTitleCompression`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const filterNameInUseErrorMessage = `${prefix}.filterNameInUseErrorMessage`;
export const compressionFilterBtnLabel = `${prefix}.compressionFilterBtnLabel`;
export const filterNameInputPlaceholder = `${prefix}.filterNameInputPlaceholder`;
export const addCompressionFilterHeader = `${prefix}.addCompressionFilterHeader`;
export const matchingRulesHeader = `${prefix}.matchingRulesHeader`;
export const clientIpAddressInputLabel = `${prefix}.clientIpAddressInputLabel`;
export const isInRadioOption = `${prefix}.isInRadioOption`;
export const isNotInRadioOption = `${prefix}.isNotInRadioOption`;
export const clientIpAddressInputPlaceholder = `${prefix}.clientIpAddressInputPlaceholder`;
export const createIpGroupBtnLabel = `${prefix}.createIpGroupBtnLabel`;
export const userAgentContainsInputLabel = `${prefix}.userAgentContainsInputLabel`;
export const userAgentContainsInputPlaceholder = `${prefix}.userAgentContainsInputPlaceholder`;
export const createStringGroupBtnLabel = `${prefix}.createStringGroupBtnLabel`;
export const actionHeader = `${prefix}.actionHeader`;
export const compressionInputLabel = `${prefix}.compressionInputLabel`;
export const compressionFiltersHeader = `${prefix}.compressionFiltersHeader`;
export const filtersCountLabel = `${prefix}.filtersCountLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleClientIp]: 'Client IP',
    [columnTitleUserAgent]: 'User Agent',
    [anyDataLabel]: 'Any',
    [columnTitleCompression]: 'Compression',
    [actionBtnDelete]: 'Delete',
    [actionBtnEdit]: 'Edit',
    [filterNameInUseErrorMessage]: 'Filter name already in use',
    [compressionFilterBtnLabel]: 'Compression Filter',
    [filterNameInputPlaceholder]: 'Filter Name',
    [addCompressionFilterHeader]: 'Add Compression Filter',
    [matchingRulesHeader]: 'Matching Rules',
    [clientIpAddressInputLabel]: 'Client IP Address',
    [isInRadioOption]: 'Is in',
    [isNotInRadioOption]: 'Is not in',
    [clientIpAddressInputPlaceholder]: 'Select IP Group',
    [createIpGroupBtnLabel]: 'Create IP Group',
    [userAgentContainsInputLabel]: 'User Agent <span style="font-weight:normal">contains</span>',
    [userAgentContainsInputPlaceholder]: 'Select String Group',
    [createStringGroupBtnLabel]: 'Create String Group',
    [actionHeader]: 'Action',
    [compressionInputLabel]: 'Compression',
    [compressionFiltersHeader]: 'Compression Filters',
    [filtersCountLabel]: 'Filter {0}',
};
