/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'nat';
const componentName = 'nat-rule-expander-content';
const prefix = `${moduleName}.${componentName}`;

export const matchLabel = `${prefix}.matchLabel`;
export const actionLabel = `${prefix}.actionLabel`;
export const actionTypeLabel = `${prefix}.actionTypeLabel`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;

export const ENGLISH = {
    [matchLabel]: 'Match ({0})',
    [actionLabel]: 'Action',
    [actionTypeLabel]: 'Type',
    [ipAddressLabel]: 'IP Address',
};
