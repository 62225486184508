/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-signing-request';
const prefix = `${moduleName}.${componentName}`;

export const certificateSigningRequestLabel = `${prefix}.certificateSigningRequestLabel`;
export const signingRequestPlaceholder = `${prefix}.signingRequestPlaceholder`;
export const copyButtonLabel = `${prefix}.copyButtonLabel`;
export const certificateInputLabel = `${prefix}.certificateInputLabel`;
export const certificateFileInputPlaceholder = `${prefix}.certificateFileInputPlaceholder`;

export const ENGLISH = {
    [certificateSigningRequestLabel]: 'Certificate Signing Request',
    [signingRequestPlaceholder]: 'Enter CSR',
    [copyButtonLabel]: 'Copy to Clipboard',
    [certificateFileInputPlaceholder]: 'Paste Certificate',
    [certificateInputLabel]: 'Upload or Paste Certificate File',
};
