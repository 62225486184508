/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const componentName = 'c-grid-table-row-actions';

const template =
    `<div
        ng-repeat="action in $ctrl.actions track by action.title"
        ng-disabled="$ctrl.actionIsDisabled(action, $ctrl.row)"
        ng-hide="$ctrl.actionIsHidden(action, $ctrl.row)"
        ng-click="$ctrl.execAction(action, $event)"
        class="${componentName}__action"
        title="{{::action.title}}"
    >
            <i class="{{::action.class}}"></i>
    </div>
    <div class="${componentName}__transclude-wrapper" ng-transclude></div>`;

class GridTableRowActionsController {
    constructor($elem, gridConfigTools) {
        this.gridConfigTools_ = gridConfigTools;
        this.$elem_ = $elem;
    }

    $onInit() {
        this.$elem_.addClass(componentName);
    }

    /**
     * Returns true for disabled actions.
     * @param {Object} action
     * @param {*} row
     * @return {boolean}
     */
    actionIsDisabled(action, row) {
        return this.isDisabled || this.gridConfigTools_.isActionDisabled(action, row);
    }

    /**
     * Used in template.
     * helps to hide single action
     * @param {Object} action
     * @param {*} row
     * @return {boolean}
     */
    actionIsHidden(action, row) {
        return this.isDisabled || this.gridConfigTools_.isActionHidden(action, row);
    }

    /**
     * Calls single action (row button clicked)
     * @param action {object} - The action object (defined in config)
     * @param {Object} $event
     */
    execAction(action, $event) {
        $event.stopPropagation();
        this.gridConfigTools_.execAction(action, this.row);
    }
}

GridTableRowActionsController.$inject = [
    '$element',
    'gridConfigTools',
];

/**
 * @ngdoc component
 * @name gridTableRowActions
 * @param {Object[]} actions - List of "single" actions.
 * @param {boolean} isDisabled
 * @param {*} row - Table row object/value.
 * @desc
 *
 *     Renders list of "single" actions per table row.
 *     Special actions (such as expander and sorting) can be passed as transcluded elements.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid').component('gridTableRowActions', {
    bindings: {
        actions: '<',
        isDisabled: '<',
        row: '<',
    },
    transclude: true,
    controller: GridTableRowActionsController,
    template,
});
