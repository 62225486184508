/** @module VsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { VsVip } from 'ajs/modules/vs';
import { IIpAddr } from 'generated-types';
import { IExtendedVipRuntimeDetail, OperStateType } from '../../vs.types';
import vsVipAddressGridCellTemplate from './vs-vip-address-grid-cell.component.html';
import './vs-vip-address-grid-cell.component.less';

const stateIconClassPrefix = 'vs-vip-address__state-icon--';

/**
 * @description
 *
 *   VsVipAddressGridCell component displays vsvip adresses and their count group by their
 *   states as up/down. Component also has a popover to display those states in detail.
 *
 * @author Ram Pal
 */
class VsVipAddressGridCellController {
    /**
     * currentOperStateType property holds current state of vip address in terms of up/down/pending
     * and currently it is used by vipListPopover component.
     */
    public currentOperStateType: OperStateType;

    /**
     * Property holding OperStateType.
     */
    public readonly operStateType = OperStateType;

    /**
     * List of vips with their addresses and states. Vips which are not associated with any VS
     * does not have runtime detail(states) and for those cases we return list of vips with their
     * addresses only hence we have other return type as Array<Record<string, string | IIpAddr>>.
     */
    public vips: IExtendedVipRuntimeDetail[] | Array<Record<string, string | IIpAddr>>;

    /**
     * Row representing current vip address.
     */
    public vsVip: VsVip;

    /**
     * Property holding popover object.
     */
    private vipListPopover: any = null;

    constructor(
        private readonly $scope: ng.IScope,
        private readonly $compile: ng.ICompileService,
        private readonly PopoverFactory: any,
    ) {}

    /** @override */
    public $onInit(): void {
        this.setPopoverInstance();
    }

    /**
     * Getter function which returns list of vipRuntime.
     */
    public get vipRuntimeList(): IExtendedVipRuntimeDetail[] {
        return this.vsVip.getRuntimeData() as IExtendedVipRuntimeDetail[] ?? [];
    }

    /**
     * function to close existig vip summary popover.
     */
    public closeVipListPopover(): void {
        this.vipListPopover.hide();
        this.vips = [];
        this.currentOperStateType = null;
    }

    /**
     * Returns runtime object having operStateType as 'Up'.
     */
    public get vipsWithUpState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_UP);
    }

    /**
     * Returns runtime object having operStateType as 'Down'.
     */
    public get vipsWithDownState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_DOWN);
    }

    /**
     * Returns runtime object having operStateType as 'Pending'.
     */
    public get vipsWithPendingState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_PENDING);
    }

    /**
     * Returns class for circle arrow icon based on vip state.
     */
    public getVipStateIconClass(operStateType: OperStateType): string {
        return stateIconClassPrefix + operStateType;
    }

    /**
     * Returns icon shape based on operStateType.
     */
    public getIconShape(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
            case OperStateType.OPER_DOWN:
                return 'circle-arrow';

            case OperStateType.OPER_PENDING:
                return 'minus-circle';

            default:
                return '';
        }
    }

    /**
     * Returns icon direction based on operStateType.
     */
    public getIconDirection(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
                return 'up';

            case OperStateType.OPER_DOWN:
                return 'down';

            default:
                return '';
        }
    }

    /** @override */
    public $onDestroy(): void {
        this.vipListPopover.remove();
    }

    /**
     * Opens popover with details like vips and OperStateType('Up', 'Down', 'Pending').
     */
    public openVipListPopover(operStateType: OperStateType, $event: MouseEvent): void {
        $event.stopPropagation();
        this.currentOperStateType = operStateType;
        this.vips = this.getVipsByState(operStateType);
        this.vipListPopover.show($event.target);
    }

    /**
     * For vips having no runtime it shows popover with viplist and OperStateType as 'Pending'.
     */
    public openVipListPopoverWithNoRuntime($event: MouseEvent): void {
        $event.stopPropagation();
        this.currentOperStateType = OperStateType.OPER_PENDING;
        this.vips = this.vsVip.getVipAddressHashes();
        this.vipListPopover.show($event.target);
    }

    /**
     * Returns a string of comma-separated VIP addresses.
     */
    public getVipListAsString(): string {
        const vipAddresses = this.vsVip.getVipAddresses();

        return vipAddresses && vipAddresses.length ? vipAddresses.join(', ') : '';
    }

    /**
     * Returns runtime object based on OperStateType ('UP', 'DOWN', 'PENDING');
     */
    private getVipsByState(operStateType: OperStateType): IExtendedVipRuntimeDetail[] {
        return this.vipRuntimeList
            .filter((runtimeItem: IExtendedVipRuntimeDetail) => {
                return runtimeItem.operStateType === operStateType;
            });
    }

    /**
     * Compile popover template and set an instance of PopoverFactory.
     */
    private setPopoverInstance(): void {
        const popoverTemplate =
         `<vip-list-popover
            vips="$ctrl.vips"
            oper-state-type="$ctrl.currentOperStateType"
            on-close="$ctrl.closeVipListPopover()"
         >
        </vip-list-popover>`;

        const $popoverContent = $(popoverTemplate);

        this.vipListPopover = new this.PopoverFactory({
            position: 'right',
            repositioning: true,
            removeAfterHide: true,
            html: this.$compile($popoverContent)(this.$scope),
            carat: true,
            hide: {
                outClick: true,
                onEscape: true,
            },
        });
    }
}

VsVipAddressGridCellController.$inject = [
    '$scope',
    '$compile',
    'popoverFactory',
];

export const vsVipAddressGridCellOptions = {
    bindings: {
        vsVip: '<',
    },
    controller: VsVipAddressGridCellController,
    template: vsVipAddressGridCellTemplate,
};
