/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';

import { Observable } from 'rxjs';
import { AzureConfiguration } from 'object-types';

import {
    TAzureLoginCredentials,
} from 'ajs/modules/cloud/factories/azure-configuration.config-item.factory';

import {
    CloudConnectorUserCollection,
} from 'ajs/modules/cloud-connector-user/factories/cloud-connector-user.collection.factory';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './azure-edit-credentials-dialog.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloudConnectorUserCollection = typeof CloudConnectorUserCollection;

/**
 * @description Component for editing Azure credentials.
 * @author Sarthak Kapoor
 */

@Component({
    selector: 'azure-edit-credentials-dialog',
    templateUrl: './azure-edit-credentials-dialog.component.html',
})
export class AzureEditCredentialsDialogComponent implements OnDestroy {
    /**
     * True if the credentials are being edited.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Azure login credentials config.
     */
    @Input()
    public config: TAzureLoginCredentials;

    /**
     * Model value for Use Azure MSI Authentication checkbox.
     * Also used as a flag for displaying Azure Credentials dropdown.
     * Property cloud_credentials_ref will be deleted when this checkbox is checked.
     */
    @Input()
    public useAzureMsiAuthentication = false;

    /**
     * Observable called on submit.
     */
    @Input()
    public submit$: Observable<void>;

    /**
     * EventEmitter passed in by the parent. Called when cancelling this dialog.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * EventEmitter automatically passed in by the CredentialsVerification component. Used to close
     * this dialog.
     */
    @Output()
    public closeDialog = new EventEmitter();

    /**
     * Object Type to be used in template.
     */
    public readonly objectType = AzureConfiguration;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * CloudConnectorUserCollection instance.
     */
    public readonly cloudConnectorUserCollection: CloudConnectorUserCollection;

    /**
     * Errors to be displayed if an error occurs after a connect attempt.
     */
    public errors: string | null;

    /**
     * Busy flag for rendering a spinner in the Connect button.
     */
    public busy = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject('CloudConnectorUserCollection')
        CloudConnectorUserCollection: TCloudConnectorUserCollection,
    ) {
        this.cloudConnectorUserCollection = new CloudConnectorUserCollection({
            params: {
                search: 'azure_',
            },
            createParams: {
                credentialsType: 'AZURE',
            },
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.cloudConnectorUserCollection.destroy();
    }

    /**
     * Handles change in selection of use MSI Authentication checkbox.
     */
    public handleUseAzureMsiAuthenticationChange(): void {
        if (this.useAzureMsiAuthentication) {
            delete this.config.cloud_credentials_ref;
        }
    }

    /**
     * Handles the cancel operation. Emits the onCancel EventEmitter that's passed in by the parent,
     * and the closeDialog EventEmitter that's passed in automatically by the
     * CredentialsVerification component to close this dialog.
     */
    public handleCancel(): void {
        this.onCancel.emit();
        this.closeDialog.emit();
    }

    /**
     * Called when the Connect button is clicked. Subscribes to the submit$ observable, and closes
     * this dialog if successful. If not, shows the error message.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.errors = null;

        const subscription = this.submit$
            .subscribe(
                () => this.closeDialog.emit(),
                errors => this.errors = errors,
            ).add(
                () => {
                    this.busy = false;
                    subscription.unsubscribe();
                },
            );
    }
}
