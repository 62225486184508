/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview File containing list of permissions used to render Main menu options.
 * imported in main-menu-list.component.js
 */

export const APPLICATION_PERMISSION = 'UI_APPLICATION';
export const OPERATIONS_PERMISSION = 'UI_OPERATIONS';
export const TEMPLATE_PERMISSION = 'UI_TEMPLATE';
export const INFRASTRUCTURE_PERMISSION = 'UI_INFRASTRUCTURE';
export const ADMINISTRATION_PERMISSION = 'UI_ADMINISTRATION';
