/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    forwardRef,
    HostListener,
    Input,
    OnInit,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import {
    isNull,
    isUndefined,
} from 'underscore';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services';

import {
    aviRepeatedStringsRangeValidator,
    aviRepeatedStringsUniquenessValidator,
    regexPatternValidator,
} from 'ng/modules/avi-forms/validators';

import * as l10n from './avi-repeated-strings.l10n';

import './avi-repeated-strings.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// Default maxLimit value.
const DEFAULT_MAX_REPEATED_STRINGS_LIMIT = Infinity;

// Default minLimit value.
const DEFAULT_MIN_REPEATED_STRINGS_LIMIT = 0;

/**
 * @description
 *      Component to add repeated strings from an input field.
 *      On Enter key, string will be added to the list.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'avi-repeated-strings',
    templateUrl: './avi-repeated-strings.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRepeatedStringsComponent),
        },
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AviRepeatedStringsComponent),
        },
    ],
})
export class AviRepeatedStringsComponent implements OnInit, ControlValueAccessor, Validator {
    /**
     * Configurable placeholder.
     */
    @Input()
    public placeholder = l10nKeys.placeholderLabel;

    /**
     * Max number of values that can be added (Optional).
     * It is set by objectType & fieldName.
     *
     * If maxLimit is passed, it takes precedence over
     * schema bindings.
     *
     * Default is Infinity.
     */
    @Input()
    public maxLimit ?= DEFAULT_MAX_REPEATED_STRINGS_LIMIT;

    /**
     * Min number of values that should be added (Optional).
     * It is set by objectType & fieldName.
     *
     * If minLimit is passed, it takes precedence over
     * schema bindings.
     *
     * Default is 0.
     */
    @Input()
    public minLimit ?= DEFAULT_MIN_REPEATED_STRINGS_LIMIT;

    /**
     * ObjectType of the field (Optional).
     */
    @Input()
    public objectType?: string;

    /**
     * Name of the field (Optional).
     */
    @Input()
    public fieldName?: string;

    /**
     * When true, wont invalidate if duplicate values are added.
     */
    @Input()
    public allowDuplicates = false;

    /**
     * Name of regex from regex.utils to apply to input field.
     * When value present, validates string using regexPatternValidator.
     */
    @Input()
    public regex?: string;

    /**
     * Model value for input tag.
     * On Enter it will be added to the ngModel array.
     */
    public currentEditable = '';

    /**
     * The ngModel value.
     */
    private modelValue: string[];

    constructor(
        private readonly schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Attaching Enter key-down event.
     * currentEditable will be added to the modelValue list.
     * We return false to prevent default behaviour of event.
     */
    @HostListener('keydown.enter', ['$event'])
    private enterKeyDownEventHandler(event: Event): false {
        // So full-modal form wont submit.
        event.stopPropagation();

        // do nothing
        if (!this.currentEditable) {
            return false;
        }

        this.addItem();

        return false;
    }

    /**
     * Handler for blur/focusout event.
     * currentEditable will be added to the modelValue list.
     */
    @HostListener('focusout')
    private focusOutEventHandler(): void {
        // user typed something in the input
        // and moved on without pressing enter-key,
        // add it to the modelValue list.
        if (this.currentEditable) {
            this.addItem();
        } else {
            // just make the component dirty
            // for `required` validation.
            this.onTouched();
        }
    }

    /**
     * Handler for backspace event.
     * if currentEditable is '', last added tag will be removed.
     */
    @HostListener('keydown.backspace')
    private backSpaceKeyPressEventHandler(): true {
        // if there is nothing on the input &
        // user presses backspace key,
        // delete the last added value.
        if (!this.currentEditable && this.hasItems) {
            this.removeItem(this.items.length - 1);
        }

        return true;
    }

    /**
     * If objectType & fieldName is passed by user,
     * try to set maxLimit.
     * @override
     */
    public ngOnInit(): void {
        const {
            objectType,
            fieldName,
            maxLimit,
            minLimit,
            schemaService,
        } = this;

        // If maxLimit/minLimit is not set by user
        // try to get it from schema bindings.
        if (objectType && fieldName) {
            if (maxLimit === DEFAULT_MAX_REPEATED_STRINGS_LIMIT) {
                try {
                    this.maxLimit = schemaService.getFieldMaxElements(objectType, fieldName);
                } catch (e) { /** empty catch block */ }
            }

            if (minLimit === DEFAULT_MIN_REPEATED_STRINGS_LIMIT) {
                try {
                    this.minLimit = schemaService.getFieldMinElements(objectType, fieldName);
                } catch (e) { /** empty catch block */ }
            }
        }
    }

    /**
     * Getter for added items.
     */
    public get items(): string[] {
        return this.modelValue;
    }

    /**
     * True, if modelValue is not empty.
     */
    public get hasItems(): boolean {
        return Boolean(this.items?.length);
    }

    /**
     * Removes an item from modelValue list.
     */
    public removeItem(index: number): void {
        this.modelValue.splice(index, 1);

        if (!this.hasItems) {
            this.modelValue = undefined;
        }

        this.emitModelChange();
    }

    /**
     * Removes all items from modelValue list.
     */
    public removeAllItems(): void {
        this.modelValue = undefined;

        this.emitModelChange();
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string[]): void {
        this.modelValue = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /***************************************************************************
     * IMPLEMENTING Validator INTERFACE
    */

    /**
     * To invalidate the field incase of duplicate or out-of-range values or
     * not matching with provided regex.
     * @override
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        if (isUndefined(control.value) || isNull(control.value)) {
            return null;
        }

        const range: [number, number] = [this.minLimit, this.maxLimit];

        const duplicateCheck = !this.allowDuplicates &&
            aviRepeatedStringsUniquenessValidator()(control);

        const regexCheck = Boolean(this.regex) && regexPatternValidator(this.regex)(control);

        return aviRepeatedStringsRangeValidator(range)(control) ||
            duplicateCheck || regexCheck || null;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string[]): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /*************************************************************************/

    /**
     * Adds an item to ngModel list.
     */
    private addItem(): void {
        if (!Array.isArray(this.modelValue)) {
            this.modelValue = [];
        }

        this.modelValue.push(this.currentEditable);
        this.currentEditable = '';
        this.emitModelChange();
    }

    /**
     * Emits model change event.
     */
    private emitModelChange(): void {
        this.onChange(this.modelValue);
        this.onTouched();
    }
}
