/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'upload-status';
const prefix = `${moduleName}.${componentName}`;

export const errorLabel = `${prefix}.errorLabel`;
export const inProgressLabel = `${prefix}.inProgressLabel`;
export const processingLabel = `${prefix}.processingLabel`;
export const progressBytesPercentage = `${prefix}.progressBytesPercentage`;
export const uploadAbortedLabel = `${prefix}.uploadAbortedLabel`;
export const uploadCompleteLabel = `${prefix}.uploadCompleteLabel`;

export const ENGLISH = {
    [errorLabel]: 'Error: {0}',
    [inProgressLabel]: 'Uploading {0}',
    [processingLabel]: 'Processing...',
    [progressBytesPercentage]: '{0} of {1}',
    [uploadAbortedLabel]: 'Upload Aborted',
    [uploadCompleteLabel]: 'Upload Complete',
};
