/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const methodMatchConfigItemFactory = (MessageItem, schemaService) => {
    /**
     * @alias module:config-item/MethodMatchConfigItem
     * @private
     */
    class MethodMatchConfigItem extends MessageItem {
        constructor(args) {
            const extendedArgs = {
                objectType: 'MethodMatch',
                ...args,
            };

            super(extendedArgs);

            this.matchOperationLabelsHash_ = schemaService.getEnumValueLabelsHash('MatchOperation');
            this.methodLabelsHash_ = schemaService.getEnumValueLabelsHash('HTTPMethod');
        }

        /**
         * Returns a list of values that should be searchable.
         */
        get searchableValues() {
            return [
                ...this.methodLabels,
            ];
        }

        /**
         * @override
         */
        get defaultConfigOverride_() { //eslint-disable-line class-methods-use-this
            return {
                match_criteria: 'IS_IN',
            };
        }

        /**
         * Returns the label to display for this.config.methods.
         * @returns {string[]}
         */
        get methodLabels() {
            const { methods = [] } = this.config;

            return methods.map(method => this.methodLabelsHash_[method]);
        }

        /**
         * Returns the label to display for this.config.match_criteria.
         * @returns {string}
         */
        get matchCriteriaLabel() {
            const { match_criteria: matchCriteria } = this.config;

            return this.matchOperationLabelsHash_[matchCriteria];
        }

        /**
         * Returns a string representation of the config data.
         * @returns {string}
         */
        toString() {
            return `${this.matchCriteriaLabel} ${this.methodLabels.join(', ')}`;
        }
    }

    return MethodMatchConfigItem;
};

methodMatchConfigItemFactory.$inject = [
    'MessageItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name  MethodMatchConfigItem
 * @description  MethodMatch ConfigItem class.
 * @module config-item/MethodMatchConfigItem
 * @author alextsg
 */
angular
    .module('avi/policies')
    .factory('MethodMatchConfigItem', methodMatchConfigItemFactory);
