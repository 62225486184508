/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './client-ssl-certificate-validation.l10n';

const componentPath = 'src/components/templates/profiles/application/application-profile-modal';
const componentName = 'client-ssl-certificate-validation';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/profiles/application
 * @mixes module:avi/profiles/application.clientSslCertificateValidationComponentBindings
 * @see {@link module:avi/profiles/application.clientSslCertificateValidationComponent}
 */
class ClientSslCertificateValidationController {
    constructor(
        schemaService,
        dropDownUtils,
        PKIProfileCollection,
        l10nService,
    ) {
        this.pkiProfileCollection = new PKIProfileCollection({
            params: {
                fields: 'name,tenant_ref',
            },
        });
        this.schemaService_ = schemaService;
        this.dropDownUtils_ = dropDownUtils;

        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        const { appProfile } = this;
        const { data: { config } } = appProfile;
        const profileType = appProfile.getType();

        if (profileType === 'APPLICATION_PROFILE_TYPE_HTTP') {
            this.profileConfig = config.http_profile;
        } else {
            this.profileConfig = config.tcp_app_profile;
        }

        this.sslClientCertificateMode = this.profileConfig.ssl_client_certificate_mode;

        const httpPolicyVarEnums = this.schemaService_.getEnumValues('HTTPPolicyVar');

        /**
         * HTTP headers list present in HTTPPolicyVar
         * @type {DropDownOption[]}
         */
        this.httpHeaderOptions =
            httpPolicyVarEnums.reduce((acc, { label, value, description }) => {
                if (value.indexOf('SSL') > -1) {
                    acc.push(
                        this.dropDownUtils_.createOption(value, label, description),
                    );
                }

                return acc;
            }, []);
    }

    /**
     * Handles SSL client certificate validation mode buttons click.
     * @param {string} mode - current mode clicked
     */
    handleModeChange(mode) {
        this.sslClientCertificateMode = mode;
        this.appProfile.setSslClientCertMode(mode);
    }

    /**
     * Removes entry from ssl_client_certificate_action headers.
     * @param {Object} header - HTTP Header.
     */
    removeSslClientCertificateActionHeader(header) {
        this.appProfile.removeSslClientCertificateActionHeader(header);
    }

    /**
     * Adds ssl_client_certificate_action header for HTTP Type Application Profile.
     */
    addSslClientCertificateActionHeader() {
        this.appProfile.addSslClientCertificateActionHeader();
    }

    /**
     * @override
     */
    $onDestroy() {
        this.pkiProfileCollection.destroy();
    }
}

ClientSslCertificateValidationController.$inject = [
    'schemaService',
    'dropDownUtils',
    'PKIProfileCollection',
    'l10nService',
];

/**
 * @name clientSslCertificateValidationComponent
 * @property {module:avi/profiles/application.ClientSslCertificateValidationController} controller
 * @property {module:avi/profiles/application.clientSslCertificateValidationComponentBindings}
 *     bindings
 * @memberOf module:avi/profiles/application
 * @description Component for configuring Client SSL Certificate Validation
 *     for HTTP/SSL Application Profiles.
 * @author Alex Malitsky, chitra
 */
angular.module('avi/profiles/application').component('clientSslCertificateValidation', {
    /**
     * @mixin clientSslCertificateValidationComponentBindings
     * @memberOf module:avi/profiles/application
     * @property {module:avi/profiles/application.ApplicationProfile} appProfile -
     *     ApplicationProfile item.
     */
    bindings: {
        appProfile: '<',
    },
    controller: ClientSslCertificateValidationController,
    templateUrl: `${componentPath}/${componentName}/${componentName}.html`,
});
