/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'bgp-routing-options-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleLabel = `${prefix}.columnTitleLabel`;
export const columnTitleLearnRoutes = `${prefix}.columnTitleLearnRoutes`;
export const columnTitleAdvertise = `${prefix}.columnTitleAdvertise`;
export const columnTitleMaxRoutes = `${prefix}.columnTitleMaxRoutes`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleLabel]: 'Label',
    [columnTitleLearnRoutes]: 'Learn Routes',
    [columnTitleAdvertise]: 'Advertise',
    [columnTitleMaxRoutes]: 'Max Routes',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
};
