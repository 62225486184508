/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'custom-params-list';
const prefix = `${moduleName}.${componentName}`;

export const nameInputLabel = `${prefix}.nameInputLabel`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const isSensitiveInputLabel = `${prefix}.isSensitiveInputLabel`;
export const isDynamicInputLabel = `${prefix}.isDynamicInputLabel`;
export const removeScriptParamButtonLabel = `${prefix}.removeScriptParamButtonLabel`;
export const addCustomParamsButtonLabel = `${prefix}.addCustomParamsButtonLabel`;

export const ENGLISH = {
    [nameInputLabel]: 'Name',
    [valueInputLabel]: 'Value',
    [isSensitiveInputLabel]: 'Sensitive',
    [isDynamicInputLabel]: 'Dynamic',
    [removeScriptParamButtonLabel]: 'Remove Script Param',
    [addCustomParamsButtonLabel]: 'Add Custom Params',
};
