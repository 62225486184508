/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-psm-match-element-config';
const prefix = `${moduleName}.${componentName}`;

export const addMatchElementButtonLabel = `${prefix}.addMatchElementButtonLabel`;

export const ENGLISH = {
    [addMatchElementButtonLabel]: 'Add Match Element',
};
