/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @name CountryDict
 * @description
 *
 *     Used by string filters.
 */
angular.module('aviApp').constant('countryDict', {
    iso2to3: {
        AF: 'AFG',
        AX: 'ALA',
        AL: 'ALB',
        DZ: 'DZA',
        AS: 'ASM',
        AD: 'AND',
        AO: 'AGO',
        AI: 'AIA',
        AQ: 'ATA',
        AG: 'ATG',
        AR: 'ARG',
        AM: 'ARM',
        AW: 'ABW',
        AU: 'AUS',
        AT: 'AUT',
        AZ: 'AZE',
        BS: 'BHS',
        BH: 'BHR',
        BD: 'BGD',
        BB: 'BRB',
        BY: 'BLR',
        BE: 'BEL',
        BZ: 'BLZ',
        BJ: 'BEN',
        BM: 'BMU',
        BT: 'BTN',
        BO: 'BOL',
        BQ: 'BES',
        BA: 'BIH',
        BW: 'BWA',
        BV: 'BVT',
        BR: 'BRA',
        IO: 'IOT',
        BN: 'BRN',
        BG: 'BGR',
        BF: 'BFA',
        BI: 'BDI',
        KH: 'KHM',
        CM: 'CMR',
        CA: 'CAN',
        CV: 'CPV',
        KY: 'CYM',
        CF: 'CAF',
        TD: 'TCD',
        CL: 'CHL',
        CN: 'CHN',
        CX: 'CXR',
        CC: 'CCK',
        CO: 'COL',
        KM: 'COM',
        CG: 'COG',
        CD: 'COD',
        CK: 'COK',
        CR: 'CRI',
        CI: 'CIV',
        HR: 'HRV',
        CU: 'CUB',
        CW: 'CUW',
        CY: 'CYP',
        CZ: 'CZE',
        DK: 'DNK',
        DJ: 'DJI',
        DM: 'DMA',
        DO: 'DOM',
        EC: 'ECU',
        EG: 'EGY',
        SV: 'SLV',
        GQ: 'GNQ',
        ER: 'ERI',
        EE: 'EST',
        ET: 'ETH',
        FK: 'FLK',
        FO: 'FRO',
        FJ: 'FJI',
        FI: 'FIN',
        FR: 'FRA',
        GF: 'GUF',
        PF: 'PYF',
        TF: 'ATF',
        GA: 'GAB',
        GM: 'GMB',
        GE: 'GEO',
        DE: 'DEU',
        GH: 'GHA',
        GI: 'GIB',
        GR: 'GRC',
        GL: 'GRL',
        GD: 'GRD',
        GP: 'GLP',
        GU: 'GUM',
        GT: 'GTM',
        GG: 'GGY',
        GN: 'GIN',
        GW: 'GNB',
        GY: 'GUY',
        HT: 'HTI',
        HM: 'HMD',
        VA: 'VAT',
        HN: 'HND',
        HK: 'HKG',
        HU: 'HUN',
        IS: 'ISL',
        IN: 'IND',
        ID: 'IDN',
        IR: 'IRN',
        IQ: 'IRQ',
        IE: 'IRL',
        IM: 'IMN',
        IL: 'ISR',
        IT: 'ITA',
        JM: 'JAM',
        JP: 'JPN',
        JE: 'JEY',
        JO: 'JOR',
        KZ: 'KAZ',
        KE: 'KEN',
        KI: 'KIR',
        KP: 'PRK',
        KR: 'KOR',
        KW: 'KWT',
        KG: 'KGZ',
        LA: 'LAO',
        LV: 'LVA',
        LB: 'LBN',
        LS: 'LSO',
        LR: 'LBR',
        LY: 'LBY',
        LI: 'LIE',
        LT: 'LTU',
        LU: 'LUX',
        MO: 'MAC',
        MK: 'MKD',
        MG: 'MDG',
        MW: 'MWI',
        MY: 'MYS',
        MV: 'MDV',
        ML: 'MLI',
        MT: 'MLT',
        MH: 'MHL',
        MQ: 'MTQ',
        MR: 'MRT',
        MU: 'MUS',
        YT: 'MYT',
        MX: 'MEX',
        FM: 'FSM',
        MD: 'MDA',
        MC: 'MCO',
        MN: 'MNG',
        ME: 'MNE',
        MS: 'MSR',
        MA: 'MAR',
        MZ: 'MOZ',
        MM: 'MMR',
        NA: 'NAM',
        NR: 'NRU',
        NP: 'NPL',
        NL: 'NLD',
        NC: 'NCL',
        NZ: 'NZL',
        NI: 'NIC',
        NE: 'NER',
        NG: 'NGA',
        NU: 'NIU',
        NF: 'NFK',
        MP: 'MNP',
        NO: 'NOR',
        OM: 'OMN',
        PK: 'PAK',
        PW: 'PLW',
        PS: 'PSE',
        PA: 'PAN',
        PG: 'PNG',
        PY: 'PRY',
        PE: 'PER',
        PH: 'PHL',
        PN: 'PCN',
        PL: 'POL',
        PT: 'PRT',
        PR: 'PRI',
        QA: 'QAT',
        RE: 'REU',
        RO: 'ROU',
        RU: 'RUS',
        RW: 'RWA',
        BL: 'BLM',
        SH: 'SHN',
        KN: 'KNA',
        LC: 'LCA',
        MF: 'MAF',
        PM: 'SPM',
        VC: 'VCT',
        WS: 'WSM',
        SM: 'SMR',
        ST: 'STP',
        SA: 'SAU',
        SN: 'SEN',
        RS: 'SRB',
        SC: 'SYC',
        SL: 'SLE',
        SG: 'SGP',
        SX: 'SXM',
        SK: 'SVK',
        SI: 'SVN',
        SB: 'SLB',
        SO: 'SOM',
        ZA: 'ZAF',
        GS: 'SGS',
        SS: 'SSD',
        ES: 'ESP',
        LK: 'LKA',
        SD: 'SDN',
        SR: 'SUR',
        SJ: 'SJM',
        SZ: 'SWZ',
        SE: 'SWE',
        CH: 'CHE',
        SY: 'SYR',
        TW: 'TWN',
        TJ: 'TJK',
        TZ: 'TZA',
        TH: 'THA',
        TL: 'TLS',
        TG: 'TGO',
        TK: 'TKL',
        TO: 'TON',
        TT: 'TTO',
        TN: 'TUN',
        TR: 'TUR',
        TM: 'TKM',
        TC: 'TCA',
        TV: 'TUV',
        UG: 'UGA',
        UA: 'UKR',
        AE: 'ARE',
        GB: 'GBR',
        US: 'USA',
        UM: 'UMI',
        UY: 'URY',
        UZ: 'UZB',
        VU: 'VUT',
        VE: 'VEN',
        VN: 'VNM',
        VG: 'VGB',
        VI: 'VIR',
        WF: 'WLF',
        EH: 'ESH',
        YE: 'YEM',
        ZM: 'ZMB',
        ZW: 'ZWE',
    },
    iso3to2: {
        AFG: 'AF',
        ALA: 'AX',
        ALB: 'AL',
        DZA: 'DZ',
        ASM: 'AS',
        AND: 'AD',
        AGO: 'AO',
        AIA: 'AI',
        ATA: 'AQ',
        ATG: 'AG',
        ARG: 'AR',
        ARM: 'AM',
        ABW: 'AW',
        AUS: 'AU',
        AUT: 'AT',
        AZE: 'AZ',
        BHS: 'BS',
        BHR: 'BH',
        BGD: 'BD',
        BRB: 'BB',
        BLR: 'BY',
        BEL: 'BE',
        BLZ: 'BZ',
        BEN: 'BJ',
        BMU: 'BM',
        BTN: 'BT',
        BOL: 'BO',
        BES: 'BQ',
        BIH: 'BA',
        BWA: 'BW',
        BVT: 'BV',
        BRA: 'BR',
        IOT: 'IO',
        BRN: 'BN',
        BGR: 'BG',
        BFA: 'BF',
        BDI: 'BI',
        KHM: 'KH',
        CMR: 'CM',
        CAN: 'CA',
        CPV: 'CV',
        CYM: 'KY',
        CAF: 'CF',
        TCD: 'TD',
        CHL: 'CL',
        CHN: 'CN',
        CXR: 'CX',
        CCK: 'CC',
        COL: 'CO',
        COM: 'KM',
        COG: 'CG',
        COD: 'CD',
        COK: 'CK',
        CRI: 'CR',
        CIV: 'CI',
        HRV: 'HR',
        CUB: 'CU',
        CUW: 'CW',
        CYP: 'CY',
        CZE: 'CZ',
        DNK: 'DK',
        DJI: 'DJ',
        DMA: 'DM',
        DOM: 'DO',
        ECU: 'EC',
        EGY: 'EG',
        SLV: 'SV',
        GNQ: 'GQ',
        ERI: 'ER',
        EST: 'EE',
        ETH: 'ET',
        FLK: 'FK',
        FRO: 'FO',
        FJI: 'FJ',
        FIN: 'FI',
        FRA: 'FR',
        GUF: 'GF',
        PYF: 'PF',
        ATF: 'TF',
        GAB: 'GA',
        GMB: 'GM',
        GEO: 'GE',
        DEU: 'DE',
        GHA: 'GH',
        GIB: 'GI',
        GRC: 'GR',
        GRL: 'GL',
        GRD: 'GD',
        GLP: 'GP',
        GUM: 'GU',
        GTM: 'GT',
        GGY: 'GG',
        GIN: 'GN',
        GNB: 'GW',
        GUY: 'GY',
        HTI: 'HT',
        HMD: 'HM',
        VAT: 'VA',
        HND: 'HN',
        HKG: 'HK',
        HUN: 'HU',
        ISL: 'IS',
        IND: 'IN',
        IDN: 'ID',
        IRN: 'IR',
        IRQ: 'IQ',
        IRL: 'IE',
        IMN: 'IM',
        ISR: 'IL',
        ITA: 'IT',
        JAM: 'JM',
        JPN: 'JP',
        JEY: 'JE',
        JOR: 'JO',
        KAZ: 'KZ',
        KEN: 'KE',
        KIR: 'KI',
        PRK: 'KP',
        KOR: 'KR',
        KWT: 'KW',
        KGZ: 'KG',
        LAO: 'LA',
        LVA: 'LV',
        LBN: 'LB',
        LSO: 'LS',
        LBR: 'LR',
        LBY: 'LY',
        LIE: 'LI',
        LTU: 'LT',
        LUX: 'LU',
        MAC: 'MO',
        MKD: 'MK',
        MDG: 'MG',
        MWI: 'MW',
        MYS: 'MY',
        MDV: 'MV',
        MLI: 'ML',
        MLT: 'MT',
        MHL: 'MH',
        MTQ: 'MQ',
        MRT: 'MR',
        MUS: 'MU',
        MYT: 'YT',
        MEX: 'MX',
        FSM: 'FM',
        MDA: 'MD',
        MCO: 'MC',
        MNG: 'MN',
        MNE: 'ME',
        MSR: 'MS',
        MAR: 'MA',
        MOZ: 'MZ',
        MMR: 'MM',
        NAM: 'NA',
        NRU: 'NR',
        NPL: 'NP',
        NLD: 'NL',
        NCL: 'NC',
        NZL: 'NZ',
        NIC: 'NI',
        NER: 'NE',
        NGA: 'NG',
        NIU: 'NU',
        NFK: 'NF',
        MNP: 'MP',
        NOR: 'NO',
        OMN: 'OM',
        PAK: 'PK',
        PLW: 'PW',
        PSE: 'PS',
        PAN: 'PA',
        PNG: 'PG',
        PRY: 'PY',
        PER: 'PE',
        PHL: 'PH',
        PCN: 'PN',
        POL: 'PL',
        PRT: 'PT',
        PRI: 'PR',
        QAT: 'QA',
        REU: 'RE',
        ROU: 'RO',
        RUS: 'RU',
        RWA: 'RW',
        BLM: 'BL',
        SHN: 'SH',
        KNA: 'KN',
        LCA: 'LC',
        MAF: 'MF',
        SPM: 'PM',
        VCT: 'VC',
        WSM: 'WS',
        SMR: 'SM',
        STP: 'ST',
        SAU: 'SA',
        SEN: 'SN',
        SRB: 'RS',
        SYC: 'SC',
        SLE: 'SL',
        SGP: 'SG',
        SXM: 'SX',
        SVK: 'SK',
        SVN: 'SI',
        SLB: 'SB',
        SOM: 'SO',
        ZAF: 'ZA',
        SGS: 'GS',
        SSD: 'SS',
        ESP: 'ES',
        LKA: 'LK',
        SDN: 'SD',
        SUR: 'SR',
        SJM: 'SJ',
        SWZ: 'SZ',
        SWE: 'SE',
        CHE: 'CH',
        SYR: 'SY',
        TWN: 'TW',
        TJK: 'TJ',
        TZA: 'TZ',
        THA: 'TH',
        TLS: 'TL',
        TGO: 'TG',
        TKL: 'TK',
        TON: 'TO',
        TTO: 'TT',
        TUN: 'TN',
        TUR: 'TR',
        TKM: 'TM',
        TCA: 'TC',
        TUV: 'TV',
        UGA: 'UG',
        UKR: 'UA',
        ARE: 'AE',
        GBR: 'GB',
        USA: 'US',
        UMI: 'UM',
        URY: 'UY',
        UZB: 'UZ',
        VUT: 'VU',
        VEN: 'VE',
        VNM: 'VN',
        VGB: 'VG',
        VIR: 'VI',
        WLF: 'WF',
        ESH: 'EH',
        YEM: 'YE',
        ZMB: 'ZM',
        ZWE: 'ZW',
    },
    iso3toName: {
        AFG: 'Afghanistan',
        ALA: 'Åland Islands',
        ALB: 'Albania',
        DZA: 'Algeria',
        ASM: 'American Samoa',
        AND: 'Andorra',
        AGO: 'Angola',
        AIA: 'Anguilla',
        ATA: 'Antarctica',
        ATG: 'Antigua and Barbuda',
        ARG: 'Argentina',
        ARM: 'Armenia',
        ABW: 'Aruba',
        AUS: 'Australia',
        AUT: 'Austria',
        AZE: 'Azerbaijan',
        BHS: 'Bahamas',
        BHR: 'Bahrain',
        BGD: 'Bangladesh',
        BRB: 'Barbados',
        BLR: 'Belarus',
        BEL: 'Belgium',
        BLZ: 'Belize',
        BEN: 'Benin',
        BMU: 'Bermuda',
        BTN: 'Bhutan',
        BOL: 'Bolivia, Plurinational State of',
        BES: 'Bonaire, Sint Eustatius and Saba',
        BIH: 'Bosnia and Herzegovina',
        BWA: 'Botswana',
        BVT: 'Bouvet Island',
        BRA: 'Brazil',
        IOT: 'British Indian Ocean Territory',
        BRN: 'Brunei Darussalam',
        BGR: 'Bulgaria',
        BFA: 'Burkina Faso',
        BDI: 'Burundi',
        KHM: 'Cambodia',
        CMR: 'Cameroon',
        CAN: 'Canada',
        CPV: 'Cape Verde',
        CYM: 'Cayman Islands',
        CAF: 'Central African Republic',
        TCD: 'Chad',
        CHL: 'Chile',
        CHN: 'China',
        CXR: 'Christmas Island',
        CCK: 'Cocos (Keeling) Islands',
        COL: 'Colombia',
        COM: 'Comoros',
        COG: 'Congo',
        COD: 'Congo, the Democratic Republic of the',
        COK: 'Cook Islands',
        CRI: 'Costa Rica',
        CIV: 'Côte d\'Ivoire',
        HRV: 'Croatia',
        CUB: 'Cuba',
        CUW: 'Curaçao',
        CYP: 'Cyprus',
        CZE: 'Czech Republic',
        DNK: 'Denmark',
        DJI: 'Djibouti',
        DMA: 'Dominica',
        DOM: 'Dominican Republic',
        ECU: 'Ecuador',
        EGY: 'Egypt',
        SLV: 'El Salvador',
        GNQ: 'Equatorial Guinea',
        ERI: 'Eritrea',
        EST: 'Estonia',
        ETH: 'Ethiopia',
        FLK: 'Falkland Islands (Malvinas)',
        FRO: 'Faroe Islands',
        FJI: 'Fiji',
        FIN: 'Finland',
        FRA: 'France',
        GUF: 'French Guiana',
        PYF: 'French Polynesia',
        ATF: 'French Southern Territories',
        GAB: 'Gabon',
        GMB: 'Gambia',
        GEO: 'Georgia',
        DEU: 'Germany',
        GHA: 'Ghana',
        GIB: 'Gibraltar',
        GRC: 'Greece',
        GRL: 'Greenland',
        GRD: 'Grenada',
        GLP: 'Guadeloupe',
        GUM: 'Guam',
        GTM: 'Guatemala',
        GGY: 'Guernsey',
        GIN: 'Guinea',
        GNB: 'Guinea-Bissau',
        GUY: 'Guyana',
        HTI: 'Haiti',
        HMD: 'Heard Island and McDonald Islands',
        VAT: 'Holy See (Vatican City State)',
        HND: 'Honduras',
        HKG: 'Hong Kong',
        HUN: 'Hungary',
        ISL: 'Iceland',
        IND: 'India',
        IDN: 'Indonesia',
        IRN: 'Iran, Islamic Republic of',
        IRQ: 'Iraq',
        IRL: 'Ireland',
        IMN: 'Isle of Man',
        ISR: 'Israel',
        ITA: 'Italy',
        JAM: 'Jamaica',
        JPN: 'Japan',
        JEY: 'Jersey',
        JOR: 'Jordan',
        KAZ: 'Kazakhstan',
        KEN: 'Kenya',
        KIR: 'Kiribati',
        PRK: 'Korea, Democratic People\'s Republic of',
        KOR: 'Korea, Republic of',
        KWT: 'Kuwait',
        KGZ: 'Kyrgyzstan',
        LAO: 'Lao People\'s Democratic Republic',
        LVA: 'Latvia',
        LBN: 'Lebanon',
        LSO: 'Lesotho',
        LBR: 'Liberia',
        LBY: 'Libya',
        LIE: 'Liechtenstein',
        LTU: 'Lithuania',
        LUX: 'Luxembourg',
        MAC: 'Macao',
        MKD: 'Macedonia, the former Yugoslav Republic of',
        MDG: 'Madagascar',
        MWI: 'Malawi',
        MYS: 'Malaysia',
        MDV: 'Maldives',
        MLI: 'Mali',
        MLT: 'Malta',
        MHL: 'Marshall Islands',
        MTQ: 'Martinique',
        MRT: 'Mauritania',
        MUS: 'Mauritius',
        MYT: 'Mayotte',
        MEX: 'Mexico',
        FSM: 'Micronesia, Federated States of',
        MDA: 'Moldova, Republic of',
        MCO: 'Monaco',
        MNG: 'Mongolia',
        MNE: 'Montenegro',
        MSR: 'Montserrat',
        MAR: 'Morocco',
        MOZ: 'Mozambique',
        MMR: 'Myanmar',
        NAM: 'Namibia',
        NRU: 'Nauru',
        NPL: 'Nepal',
        NLD: 'Netherlands',
        NCL: 'New Caledonia',
        NZL: 'New Zealand',
        NIC: 'Nicaragua',
        NER: 'Niger',
        NGA: 'Nigeria',
        NIU: 'Niue',
        NFK: 'Norfolk Island',
        MNP: 'Northern Mariana Islands',
        NOR: 'Norway',
        OMN: 'Oman',
        PAK: 'Pakistan',
        PLW: 'Palau',
        PSE: 'Palestinian Territory, Occupied',
        PAN: 'Panama',
        PNG: 'Papua New Guinea',
        PRY: 'Paraguay',
        PER: 'Peru',
        PHL: 'Philippines',
        PCN: 'Pitcairn',
        POL: 'Poland',
        PRT: 'Portugal',
        PRI: 'Puerto Rico',
        QAT: 'Qatar',
        REU: 'Réunion',
        ROU: 'Romania',
        RUS: 'Russian Federation',
        RWA: 'Rwanda',
        BLM: 'Saint Barthélemy',
        SHN: 'Saint Helena, Ascension and Tristan da Cunha',
        KNA: 'Saint Kitts and Nevis',
        LCA: 'Saint Lucia',
        MAF: 'Saint Martin (French part)',
        SPM: 'Saint Pierre and Miquelon',
        VCT: 'Saint Vincent and the Grenadines',
        WSM: 'Samoa',
        SMR: 'San Marino',
        STP: 'Sao Tome and Principe',
        SAU: 'Saudi Arabia',
        SEN: 'Senegal',
        SRB: 'Serbia',
        SYC: 'Seychelles',
        SLE: 'Sierra Leone',
        SGP: 'Singapore',
        SXM: 'Sint Maarten (Dutch part)',
        SVK: 'Slovakia',
        SVN: 'Slovenia',
        SLB: 'Solomon Islands',
        SOM: 'Somalia',
        ZAF: 'South Africa',
        SGS: 'South Georgia and the South Sandwich Islands',
        SSD: 'South Sudan',
        ESP: 'Spain',
        LKA: 'Sri Lanka',
        SDN: 'Sudan',
        SUR: 'Suriname',
        SJM: 'Svalbard and Jan Mayen',
        SWZ: 'Swaziland',
        SWE: 'Sweden',
        CHE: 'Switzerland',
        SYR: 'Syrian Arab Republic',
        TWN: 'Taiwan, Province of China',
        TJK: 'Tajikistan',
        TZA: 'Tanzania, United Republic of',
        THA: 'Thailand',
        TLS: 'Timor-Leste',
        TGO: 'Togo',
        TKL: 'Tokelau',
        TON: 'Tonga',
        TTO: 'Trinidad and Tobago',
        TUN: 'Tunisia',
        TUR: 'Turkey',
        TKM: 'Turkmenistan',
        TCA: 'Turks and Caicos Islands',
        TUV: 'Tuvalu',
        UGA: 'Uganda',
        UKR: 'Ukraine',
        ARE: 'United Arab Emirates',
        GBR: 'United Kingdom',
        USA: 'United States',
        UMI: 'United States Minor Outlying Islands',
        URY: 'Uruguay',
        UZB: 'Uzbekistan',
        VUT: 'Vanuatu',
        VEN: 'Venezuela, Bolivarian Republic of',
        VNM: 'Viet Nam',
        VGB: 'Virgin Islands, British',
        VIR: 'Virgin Islands, U.S.',
        WLF: 'Wallis and Futuna',
        ESH: 'Western Sahara',
        YEM: 'Yemen',
        ZMB: 'Zambia',
        ZWE: 'Zimbabwe',
    },
});
