/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'top-list';
const prefix = `${moduleName}.${componentName}`;

export const emptyDataMessage = `${prefix}.emptyDataMessage`;
export const resourceTimingBtnLabel = `${prefix}.resourceTimingBtnLabel`;
export const totalTimeLabel = `${prefix}.totalTimeLabel`;
export const pageDownloadTimeTotalLabel = `${prefix}.pageDownloadTimeTotalLabel`;
export const pageDownloadTimeTotalSecondLabel = `${prefix}.pageDownloadTimeTotalSecondLabel`;
export const deviceLabel = `${prefix}.deviceLabel`;
export const browserLabel = `${prefix}.browserLabel`;
export const operatingSystemLabel = `${prefix}.operatingSystemLabel`;
export const topUrlsLabel = `${prefix}.topUrlsLabel`;

export const ENGLISH = {
    [emptyDataMessage]: 'No Data Available',
    [resourceTimingBtnLabel]: 'Resource Timing',
    [totalTimeLabel]: 'Total time: {0} <span class = "units-color">ms</span>',
    [pageDownloadTimeTotalLabel]: '{0} <span class = "units-color">ms</span>',
    [pageDownloadTimeTotalSecondLabel]: '{0} <span class = "units-color">s</span>',
    [deviceLabel]: 'Device',
    [browserLabel]: 'Browser',
    [operatingSystemLabel]: 'Operating System',
    [topUrlsLabel]: 'Top URLs',
};
