/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    BgpPeerConfigItem,
    BgpProfileConfigItem,
    BgpRoutingOptionsConfigItem,
    BgpTypes,
    InfraCloudState,
} from 'ajs/modules/vrf-context';

import {
    AviConfirmService,
    IAviAjsConfirmBindings,
} from 'ajs/modules/core/services/avi-confirm.service';

import { CloudType } from 'generated-types';
import { ClrFormLayout } from '@clr/angular';
import { createDropdownOption } from 'ng/shared/utils';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './bgp-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Modal component to configure BGP Profile.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'bgp-profile-modal',
    templateUrl: './bgp-profile-modal.component.html',
})
export class BgpProfileModalComponent implements OnInit {
    /**
     * BgpProfileConfigItem messageItem instance.
     */
    @Input()
    public editable: BgpProfileConfigItem;

    /**
     * Parent VRF Context name.
     */
    @Input()
    public vrfContextName: string;

    /**
     * Parent VRF Context Id.
     */
    @Input()
    public vrfContextId: string;

    /**
     * Parent Cloud Id.
     */
    @Input()
    public cloudId: string;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel: EventEmitter<void> = new EventEmitter<void>();

    /**
     * BgpProfile object type.
     */
    public objectType: string;

    /**
     * Bgp Type dropdown options.
     */
    public readonly bgpTypeOptions = [
        BgpTypes.ibgp,
        BgpTypes.ebgp,
    ].map(type => createDropdownOption(type));

    /**
     * Layout for BGP Profile modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Currently selected cloud's type.
     */
    public readonly cloudType: CloudType;

    /**
     * Number of Community Strings.
     */
    public get communityStringsCount(): number {
        const { community } = this.editable.config;

        if (!community) {
            return 0;
        }

        return community.length;
    }

    constructor(
        private aviConfirmService: AviConfirmService,
        private readonly l10nService: L10nService,
        infraCloudState: InfraCloudState,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.cloudType = infraCloudState.getCloudType();
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * When user changes bgpProfile type (iBgp -> eBgp or vice-versa),
     * should check whether we have peers configured.
     *
     * If there are peers, get confirmation from user and
     * clear the peers before changing the type.
     */
    public onBgpTypeChange(): void {
        if (!this.editable.hasPeers()) {
            this.editable.removeFieldsByBgpType();

            return;
        }

        const { l10nService, l10nKeys } = this;

        const aviConfirmComponentBindings: IAviAjsConfirmBindings = {
            headerText: l10nService.getMessage(l10nKeys.warningHeaderText),
            message: l10nService.getMessage(l10nKeys.switchingTypeMessage),
            isWarning: true,
            confirmationText: l10nService.getMessage(l10nKeys.confirmationText),
            confirmButtonText: l10nService.getMessage(l10nKeys.confirmButtonText),
        };

        this.aviConfirmService.confirm(aviConfirmComponentBindings)
            .then(() => {
                this.editable.removeFieldsByBgpType();
                this.editable.onBgpTypeChange();
            })
            .catch(() => {
                const { type } = this.editable;

                // If user says No, revert type change.
                this.editable.type =
                    type === BgpTypes.ibgp ? BgpTypes.ebgp : BgpTypes.ibgp;
            });
    }

    /**
     * Opens BgpPeer modal to add new BgpPeer.
     */
    public createBgpPeer(): void {
        this.editable.addBgpPeer(this.vrfContextId, this.cloudId, this.cloudType);
    }

    /**
     * Opens BgpPeer modal to edit bgpPeer.
     */
    public editBgpPeer(bgpPeer: BgpPeerConfigItem): void {
        this.editable.editBgpPeer(
            bgpPeer,
            this.vrfContextId,
            this.cloudId,
            this.cloudType,
        );
    }

    /**
     * Deletes bgpPeer from BgpPeers grid.
     */
    public deleteBgpPeer(bgpPeer: BgpPeerConfigItem): void {
        const { peers } = this.editable;

        peers.removeByMessageItem(bgpPeer);
    }

    /**
     * Opens BgpRoutingOptions modal to add new BgpRoutingOptions.
     */
    public createBgpRoutingOptions(): void {
        this.editable.addBgpRoutingOptions();
    }

    /**
     * Opens BgpRoutingOptions modal to edit new BgpRoutingOptions.
     */
    public editBgpRoutingOptions(bgpRoutingOptions: BgpRoutingOptionsConfigItem): void {
        this.editable.editBgpRoutingOptions(bgpRoutingOptions);
    }

    /**
     * Handles BgpRoutingOptions delete.
     */
    public deleteBgpRoutingOptions(bgpRoutingOptions: BgpRoutingOptionsConfigItem): void {
        const { routingOptions } = this.editable;

        routingOptions.removeByMessageItem(bgpRoutingOptions);
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
