/**
 * @module RbacModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IRoleFilterMatchLabel } from 'generated-types';

/**
 * @description The counterpart of RbacLabelGrid component used in Ajs.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'rbac-label-grid-ajs-wrapper',
    templateUrl: './rbac-label-grid-ajs-wrapper.component.html',
})
export class RbacLabelGridAjsWrapperComponent {
    /**
     * List of RBAC label key-value objects.
     */
    @Input()
    public rbacLabelList: IRoleFilterMatchLabel[] | undefined;

    /**
     * Event emitter to propagate RBAC list changes to the parent.
     * This event emitter enables the two-way binding [(rbacLabelList)] under Ajs by auto matching
     * the input name with the emitter prefix.
     */
    @Output()
    public rbacLabelListChange = new EventEmitter<IRoleFilterMatchLabel[]>();

    /**
     * Getter for RBAC label list.
     */
    public get list(): IRoleFilterMatchLabel[] | undefined {
        return this.rbacLabelList;
    }

    /**
     * Setter for RBAC label list. In the template whenever the bound Angular [(ngModel)] list gets
     * updated from UI, this setter gets called and it in turn calls the event emitter propagating
     * the update to the RBAC label list passing from an AngularJS parent.
     */
    public set list(newList: IRoleFilterMatchLabel[] | undefined) {
        this.rbacLabelListChange.emit(newList);
    }
}
