/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf-profile';
const componentName = 'waf-data-file-modal';
const prefix = `${moduleName}.${componentName}`;

export const wafDataFileModalTitle = `${prefix}.wafDataFileModalTitle`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeDropdownLabel = `${prefix}.typeDropdownLabel`;
export const typeDropdownPlaceholder = `${prefix}.typeDropdownPlaceholder`;
export const dataTextareaLabel = `${prefix}.dataTextareaLabel`;
export const dataTextareaPlaceholder = `${prefix}.dataTextareaPlaceholder`;

export const ENGLISH = {
    [wafDataFileModalTitle]: 'Add File: {0}',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [typeDropdownLabel]: 'Type',
    [typeDropdownPlaceholder]: 'Select Type',
    [dataTextareaLabel]: 'Data',
    [dataTextareaPlaceholder]: 'Enter Data',
};
