/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential.
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'deactivate-bgp-peering-confirmation';
const prefix = `${moduleName}.${componentName}`;

export const deactivateBgpPeeringConfirmationHeader = `${prefix}.deactivateBgpPeeringConfirmationHeader`;
export const deactivateBgpPeeringConfirmationMesssage = `${prefix}.deactivateBgpPeeringConfirmationMesssage`;
export const deactivateBgpPeeringConfirmationText = `${prefix}.deactivateBgpPeeringConfirmationText`;
export const continueLabel = `${prefix}.continueLabel`;
export const cancelLabel = `${prefix}.cancelLabel`;

export const ENGLISH = {
    [deactivateBgpPeeringConfirmationHeader]: 'Deactivate BGP Peering',
    [deactivateBgpPeeringConfirmationMesssage]: 'BGP Peering will be deactivated for <strong>{0}</strong> and all settings will be removed.',
    [deactivateBgpPeeringConfirmationText]: 'Are you sure you want to continue?',
    [continueLabel]: 'Yes, Continue',
    [cancelLabel]: 'Cancel',
};
