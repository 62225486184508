/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'recent-updates-list';
const prefix = `${moduleName}.${componentName}`;

export const title = `${prefix}.title`;
export const emptyListPlaceholderController = `${prefix}.emptyListPlaceholderController`;
export const emptyListPlaceholderSeg = `${prefix}.emptyListPlaceholderSeg`;

export const ENGLISH = {
    [title]: 'Recent',
    [emptyListPlaceholderController]: 'Controller has never been upgraded',
    [emptyListPlaceholderSeg]: 'Service Engine Group has never been upgraded',
};
