/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'notification';
const componentName = 'utc-mismatch-notification';
const prefix = `${moduleName}.${componentName}`;

export const timeDiffSecondsTitle = `${prefix}.timeDiffSecondsTitle`;
export const systemClockBehindMessage = `${prefix}.systemClockBehindMessage`;
export const systemClockAheadMessage = `${prefix}.systemClockAheadMessage`;
export const controllerClock = `${prefix}.controllerClock`;
export const timeDiffImpactMessage = `${prefix}.timeDiffImpactMessage`;
export const checkNtpSettingsMessage = `${prefix}.checkNtpSettingsMessage`;
export const ntpSettings = `${prefix}.ntpSettings`;

export const ENGLISH = {
    [timeDiffSecondsTitle]: '{0} seconds',
    [systemClockBehindMessage]: 'Your System Clock is behind by <span title="{0}"> {1}.</span>',
    [systemClockAheadMessage]: 'Your System Clock is ahead by <span title="{0}"> {1}.</span>',
    [controllerClock]: 'Controller clock ({0} UTC)',
    [timeDiffImpactMessage]: 'The difference may degrade analytics.',
    [checkNtpSettingsMessage]: 'Please check ##0NTP Settings##1.',
    [ntpSettings]: 'NTP Settings.',
};
