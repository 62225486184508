/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'user-modal-all-tenants-settings';
const prefix = `${moduleName}.${componentName}`;

export const rolesForAllTenantsHeader = `${prefix}.rolesForAllTenantsHeader`;
export const allTenantRolesSelectPlaceholder = `${prefix}.allTenantRolesSelectPlaceholder`;
export const addRoleButtonLabel = `${prefix}.addRoleButtonLabel`;

export const ENGLISH = {
    [rolesForAllTenantsHeader]: 'Roles for All Tenants',
    [allTenantRolesSelectPlaceholder]: 'Select Role',
    [addRoleButtonLabel]: 'Add Role',
};
