/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import {
    IFloatingIpSubnet,
    IpAddrType,
    ISubnetList,
} from 'generated-types';

import { Vip } from 'object-types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { SubnetListNetwork } from 'ajs/modules/network/factories/subnet-list-network.item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './fip-auto-allocate-subnet-selection.l10n';
import './fip-auto-allocate-subnet-selection.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSubnetListNetwork = typeof SubnetListNetwork;

/**
 * @description COmponent to select subnets for FIP Auto-allocate [Used in Openstack VsVip].
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'fip-auto-allocate-subnet-selection',
    templateUrl: './fip-auto-allocate-subnet-selection.component.html',
})
export class FipAutoAllocateSubnetSelection implements OnInit, OnChanges, OnDestroy {
    /**
     * UUID of the cloud on which this VIP is being configured.
     */
    @Input()
    public cloudId: string;

    /**
     * Subnet UUID for the floating ip address allocation.
     */
    @Input()
    public floatingIpSubnetId: string | undefined;

    /**
     * Subnet UUID for the floating ipv6 address allocation.
     */
    @Input()
    public floatingIp6SubnetId: string | undefined;

    /**
     * NetworkRef of VIP.
     */
    @Input()
    public networkRef?: string;

    /**
     * Change emitter for vip#floating_subnet_uuid field (for two way binding).
     */
    @Output()
    public floatingIpSubnetIdChange = new EventEmitter<string | undefined>();

    /**
     * Change emitter for vip#floating_subnet6_uuid field (for two way binding).
     */
    @Output()
    public floatingIp6SubnetIdChange = new EventEmitter<string | undefined>();

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectType of VIPConfigItem.
     */
    public readonly objectType = Vip;

    /**
     * True when network is being loaded.
     */
    public loadingNetwork = false;

    /**
     * subnet field dropdown options.
     */
    public subnetDropdownOptions: IAviDropdownOption[] = [];

    /**
     * subnet6 field dropdown options.
     */
    public subnet6DropdownOptions: IAviDropdownOption[] = [];

    /**
     * SubnetListNetwork instance.
     */
    private subnetListNetwork: SubnetListNetwork;

    constructor(
        private readonly stringService: StringService,
        @Inject(SubnetListNetwork)
        private readonly SubnetListNetwork: TSubnetListNetwork,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        if (this.networkRef) {
            this.loadIpAllocationNetwork();
        }
    }

    /** @override */
    public ngOnChanges({ networkRef }: SimpleChanges): void {
        // Sometimes networkRef gets changed when its loaded again with name (include_name),
        // we dont have to update subnets in that case.
        if (networkRef && !networkRef.firstChange) {
            const { currentValue, previousValue } = networkRef;

            const shouldReset = !currentValue || !previousValue ||
                this.stringService.slug(currentValue) !== this.stringService.slug(previousValue);

            if (shouldReset) {
                // when networkRef is changed,
                // subnet values & options that belonged to old network should be cleared.
                this.floatingIpSubnetId = undefined;
                this.floatingIp6SubnetId = undefined;
                this.clearSubnetDropdowns();

                if (this.networkRef) {
                    this.loadIpAllocationNetwork();
                }
            }
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.subnetListNetwork) {
            this.subnetListNetwork.destroy();
        }
    }

    /**
     * Fires subnet dropdown change.
     */
    public emitSubnetChange(): void {
        this.floatingIpSubnetIdChange.emit(this.floatingIpSubnetId);
    }

    /**
     * Fires subnet6 dropdown change.
     */
    public emitSubnet6Change(): void {
        this.floatingIp6SubnetIdChange.emit(this.floatingIp6SubnetId);
    }

    /**
     * Loads SubnetListNetwork instance by networkRef & sets subnet dropdown options.
     */
    private async loadIpAllocationNetwork(): Promise<void> {
        this.loadingNetwork = true;

        try {
            this.subnetListNetwork = new this.SubnetListNetwork({
                id: this.stringService.slug(this.networkRef),
                cloudId: this.cloudId,
                params: {
                    fip_capable: true, // To load fip subnets
                },
            });

            await this.subnetListNetwork.load();

            this.setSubnetDropdownOptions();
        } catch (e) {
            // empty catch block
        } finally {
            this.loadingNetwork = false;
        }
    }

    /**
     * Sets subnet & subnet6 dropdown options from subnetListNetwork.
     */
    private setSubnetDropdownOptions(): void {
        const subnets = this.subnetListNetwork.getSubnets();

        [
            this.subnetDropdownOptions,
            this.subnet6DropdownOptions,
        ] = subnets.reduce((
            subnetOptions: [IAviDropdownOption[], IAviDropdownOption[]],
            subnet: ISubnetList,
        ) => {
            const { floatingip_subnets: fipSubnets = [] } = subnet;

            const [
                ipFipSubnetOptions,
                ipv6FipSubnetOptions,
            ] = fipSubnets.reduce((
                acc: [IAviDropdownOption[], IAviDropdownOption[]],
                { uuid, name, prefix }: IFloatingIpSubnet,
            ) => {
                const dropdownOption = createDropdownOption(uuid, name);
                const { ip_addr: { type } } = prefix;

                if (type === IpAddrType.V4) {
                    acc[0].push(dropdownOption);
                } else {
                    acc[1].push(dropdownOption);
                }

                return acc;
            }, [[], []]);

            subnetOptions[0].push(...ipFipSubnetOptions);
            subnetOptions[1].push(...ipv6FipSubnetOptions);

            return subnetOptions;
        }, [[], []]);
    }

    /**
     * Clears subnet & subnet6 dropdowns.
     */
    private clearSubnetDropdowns(): void {
        this.subnetDropdownOptions = [];
        this.subnet6DropdownOptions = [];
    }
}
