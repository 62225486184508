/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit VIP: {0}',
    [modalTitleNew]: 'Create VIP: {0}',
    [sectionTitleGeneral]: 'General',
};
