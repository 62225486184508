/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'persistence-profile';

export const persistenceProfileBreadcrumbTitle = `${prefix}.persistenceProfileBreadcrumbTitle`;

export const ENGLISH = {
    [persistenceProfileBreadcrumbTitle]: 'Persistence Profile',
};
