/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './DatascriptPolicy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').directive('datascriptPolicy', [
'DataScriptSetCollection',
'DataScriptSet',
'l10nService',
function(
    DataScriptSetCollection,
    DataScriptSet,
    l10nService,
) {
    l10nService.registerSourceBundles(dictionary);

    function dataScriptPolicyLink(scope) {
        /**
         * Get keys from source bundles for template usage
         */
        scope.l10nKeys = l10nKeys;

        /**
         * Updates some properties (names) of the dataScripts we have in editable.
         * @param {Item} item - Updated Item.
         * @private
         */
        const updateEditableDataScripts = function(item) {
            _.find(scope.data, function(row) {
                const found = row.vs_datascript_set_ref.slug() === item.id;

                if (found) {
                    row.vs_datascript_set_ref = item.data.config.url;
                }

                return found;
            });
        };

        //need to update name of DataScripts on itemConfigUpdate event
        scope.DataScriptSetCollection = new DataScriptSetCollection({
            bind: {
                collItemConfigUpdate: updateEditableDataScripts,
            },
        });

        scope.datascriptEditMode = function(script) {
            script.save = function() {
                scope.saveDatascript();
            };

            script.dismiss = function() {
                scope.error = null;
            };
        };

        scope.addDatascript = function() {
            scope.current = {};
            scope.datascriptEditMode(scope.current);
        };

        scope.saveDatascript = function() {
            if (!scope.current) {
                return;
            }

            // check to make sure the Datascript is not already in use in this VS
            const ent = _.find(scope.data, function(item) {
                return item.vs_datascript_set_ref == scope.current.vs_datascript_set_ref;
            });

            if (ent && ent.index != scope.current.index) {
                // fail the save and provide error to user
                scope.error = 'Datascript already in use by the VS';

                return;
            }

            if (!_.isUndefined(scope.current.index)) {
                angular.copy(scope.current, _.find(scope.data, function(item) {
                    return item.index == scope.current.index;
                }));
            } else {
                scope.current.index =
                    _.max(scope.data, function(i) { return i.index; }).index + 1 || 1;
                scope.data.push(scope.current);
            }

            scope.current = null;
        };

        // clear the error in case it's set
        scope.selectScript = function() {
            scope.error = null;
        };

        /**
         * Removes the rule from the policy.
         * @param {Object} r - Rule.
         */
        scope.removeRule = function(r) {
            if (scope.current && scope.current == r) {
                scope.current = null;
            }

            scope.data = _.filter(scope.data, function(v, i) {
                return v != r;
            });
        };

        /**
         * Swaps rules updating their indexes.
         * @param {number} from
         * @param {number} to
         */
        scope.onRowMove = function(from, to) {
            const
                { data: rules } = scope,
                targetRule = rules[from],
                swappedRule = rules[to],
                { index: targetIndex } = targetRule,
                { index: swapIndex } = swappedRule;

            targetRule.index = swapIndex;
            swappedRule.index = targetIndex;

            rules[from] = swappedRule;
            rules[to] = targetRule;
        };

        const
            singleActions = [],
            multipleActions = [];

        if (!scope.readonly) {
            singleActions.push({
                title: l10nService.getMessage(l10nKeys.editActionLabel),
                class: 'sel-edit icon-pencil-4',
                do(row) {
                    const item = new DataScriptSet({
                        id: row.vs_datascript_set_ref.slug(),
                        loadOnEdit: false,
                    });

                    //need to update the dataScript name we are showing for the grid
                    item.load()
                        .then(() => {
                            if (item.isEditable()) {
                                return item.edit()
                                    .then(() => row['vs_datascript_set_ref'] = item.getRef());
                            }
                        })
                        .finally(() => item.destroy());
                },
            });

            multipleActions.push({
                title: l10nService.getMessage(l10nKeys.deleteActionLabel),
                do(rules) {
                    _.each(rules, function(rule) {
                        scope.removeRule(rule);
                    });

                    return true;
                },
            });
        }

        scope.datascriptGrid = {
            id: 'datascript-policy-list',
            fields: [{
                name: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                template: '<span class="sel-name">{{row.vs_datascript_set_ref | name}}</span>',
            }, {
                name: 'index',
                title: l10nService.getMessage(l10nKeys.columnTitleIndex),
            }],
            rowId(row) {
                return row.vs_datascript_set_ref.name();
            },
            searchFields: ['name', 'vs_datascript_set_ref.name()'],
            multipleactions: multipleActions,
            singleactions: singleActions,
            withReordering: true,
        };

        scope.$on('$destroy', () => {
            scope.DataScriptSetCollection.destroy();
        });
    }

    return {
        scope: {
            data: '=',
            current: '=',
            vsId: '<?',
            readonly: '@',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/datascript-policy.html',
        link: dataScriptPolicyLink,
    };
}]);
