/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ACTIVE_USER_PROFILE_SERVICE_TOKEN } from 'ajs/modules/core/services/active-user-profile';

import * as l10n from './user-modal-default-tenant-settings.l10n';

const componentName = 'user-modal-default-tenant-settings';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/accounts
 * @mixes module:avi/accounts.userModalDefaultTenantSettingsBindings
 * @see {@link module:avi/accounts.userModalDefaultTenantSettingsComponent}
 */
class UserModalDefaultTenantSettingsController {
    constructor(
        Auth,
        dropDownUtils,
        TenantCollection,
        l10nService,
        activeUserProfileService,
    ) {
        this.dropDownUtils_ = dropDownUtils;

        this.defaultTenantCollection = new TenantCollection({
            isStatic: true,
            defaultDataSources: ['inventory'],
        });

        /**
         * True if the current user is admin.
         * Used to restrict tenant creation for admin user.
         * @type {boolean}
         */
        this.isAdminUser = activeUserProfileService.isAdminUser();

        /**
         * Default tenant Dropdown options.
         * @type {DropDownOption[]}
         */
        this.defaultTenantDropdownOptions = [];

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onChanges(changes) {
        const {
            hasAllTenants: hasAllTenantsChanges,
            individualTenants: individualTenantsChanges,
        } = changes;

        // will be set to true
        // If hasAllTenants is changed to false or
        // If individualTenants is modified & hasAllTenants is false
        let shouldUpdateComponentData =
            !!(hasAllTenantsChanges && !hasAllTenantsChanges.currentValue);

        shouldUpdateComponentData = shouldUpdateComponentData ||
            !!(individualTenantsChanges && !this.hasAllTenants);

        // if shouldUpdateComponentData is true,
        // Updates dropdown options.
        // default_tenant_ref may not be present in the individual tenant list,
        // In that case, reset it.
        if (shouldUpdateComponentData) {
            this.setDefaultTenantDropdownOptions_();
            this.resetDefaultTenantRef_();
        }
    }

    /**
     * Generates options for default tenants dropdown.
     * Resets defaultTenantRef if its not present in dropdown options.
     * @protected
     */
    setDefaultTenantDropdownOptions_() {
        this.defaultTenantDropdownOptions = this.individualTenants
            .map(tenantRef => this.dropDownUtils_.createOption(tenantRef, tenantRef.name()));
    }

    /**
     * Resets defaultTenantRef if defaultTenantRef is not present in individualTenants list.
     * If individulaTenants list has only one tenant, make it default.
     * @protected
     */
    resetDefaultTenantRef_() {
        if (!this.hasDefaultTenant_()) {
            this.defaultTenantRef = undefined;
        }

        if (this.individualTenants.length === 1) {
            this.defaultTenantRef = this.individualTenants[0];
        }
    }

    /**
     * Return true if defaultTenantRef is present in individualTenants list.
     * @return {boolean}
     * @protected
     */
    hasDefaultTenant_() {
        const {
            individualTenants,
            defaultTenantRef,
        } = this;

        return _.contains(individualTenants, defaultTenantRef);
    }

    /** @override */
    $onDestroy() {
        this.defaultTenantCollection.destroy();
    }
}

UserModalDefaultTenantSettingsController.$inject = [
    'Auth',
    'dropDownUtils',
    'TenantCollection',
    'l10nService',
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
];
/**
 * @name userModalDefaultTenantSettingsComponent
 * @memberOf module:avi/accounts
 * @property {module:avi/accounts.userModalDefaultTenantSettingsBindings} bindings
 * @property {module:avi/accounts.UserModalDefaultTenantSettingsController} controller
 * @description
 *      component for Default tenant configuration.
 *
 *      We have two different scenarios to handle for default_tenant_ref.
 *
 *          1) When a user is configured with an `All Tenant` role,
 *      He gets access to all the tenants and he can choose any one of the
 *      available tenants as default tenant - In that case, we need to display
 *      a `collection dropdown` with TenantCollection.
 *
 *          2) If user is configured with individual tenants (with no all_tenants role),
 *      he gets access only to those selected list of tenants and
 *      default tenant can be one of those.
 *      So we use `dropdown` for this case, with the selected individual tenants
 *      as options.
 *
 * @author Aravindh Nagarajan
 */
angular.module('avi/accounts').component('userModalDefaultTenantSettings', {
    /**
     * @mixin userModalDefaultTenantSettingsBindings
     * @memberof module:avi/accounts
     * @property {boolean} hasAllTenants - True, if user has allTenant roles
     * @property {string} defaultTenantRef - user.getConfig().default_tenant_ref
     * @property {string[]} individualTenants - List of individual tenant refs
     */
    bindings: {
        hasAllTenants: '<',
        defaultTenantRef: '=',
        individualTenants: '<',
    },
    controller: UserModalDefaultTenantSettingsController,
    templateUrl:
        'src/components/modals/administration/accounts/user/' +
        `${componentName}/${componentName}.component.html`,
});
