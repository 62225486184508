/**
 * Accounts module
 * @module AccountsModule
 * @preferred
 */
/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    authenticationTenantRoleMappingController,
    authenticationTypeController,
    roleController,
    systemConfigurationEditAccessController,
    TenantCollection,
    TenantItem,
    TenantSettingsModalController,
    TenantSettingsService,
    TENANT_COLLECTION_TOKEN,
    TENANT_ITEM_TOKEN,
    UserProfileCollection,
    UserProfileItem,
    USER_PROFILE_COLLECTION_TOKEN,
    USER_PROFILE_ITEM_TOKEN,
} from '.';

import { RoleService } from './services';
import {
    ROLE_SERVICE_TOKEN,
    TENANT_SETTINGS_SERVICE_TOKEN,
} from './services/services.constants';

const accountsModule = angular.module('avi/accounts');

const controllers = [
    {
        controller: roleController,
        name: 'RoleController',
    },
    {
        controller: authenticationTypeController,
        name: 'AuthenticationTypeController',
    },
    {
        controller: authenticationTenantRoleMappingController,
        name: 'AuthenticationTenantRoleMappingController',
    },
    {
        controller: systemConfigurationEditAccessController,
        name: 'SystemConfigurationEditAccessController',
    },
    {
        controller: TenantSettingsModalController,
        name: 'TenantSettingsModalController',
    },
];

const services = [
    {
        service: RoleService,
        name: ROLE_SERVICE_TOKEN,
    },
    {
        service: TenantSettingsService,
        name: TENANT_SETTINGS_SERVICE_TOKEN,
    },
];

const factories = [
    {
        factory: UserProfileItem,
        name: USER_PROFILE_ITEM_TOKEN,
    },
    {
        factory: UserProfileCollection,
        name: USER_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: TenantItem,
        name: TENANT_ITEM_TOKEN,
    },
    {
        factory: TenantCollection,
        name: TENANT_COLLECTION_TOKEN,
    },
];

controllers.forEach(({ name, controller }) => accountsModule.controller(name, controller));

services.forEach(({ name, service }) => {
    initAjsDependency(accountsModule, 'service', name, service);
});

factories.forEach(({ name, factory }) => {
    initAjsDependency(accountsModule, 'factory', name, factory);
});
