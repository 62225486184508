/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRuleClientIpMatchConfig
 * @description DnsPolicyRuleClientIpMatchConfig MatchConfigItem class.
 */
const dnsPolicyRuleClientIpMatchConfigFactory = (MatchConfigItem, IpAddrMatchConfig) => {
    class DnsPolicyRuleClientIpMatchConfig extends MatchConfigItem {
        constructor(args) {
            super(args);

            const config = this.getConfig();

            config['client_ip'] = new IpAddrMatchConfig({
                data: { config: config['client_ip'] || null },
            });
        }

        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config['use_edns_client_subnet_ip'] = !!config['use_edns_client_subnet_ip'];
            config['client_ip'].beforeEdit();
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            if (config['client_ip'] instanceof MatchConfigItem) {
                config['client_ip'] = config['client_ip'].dataToSave();
            }

            return config;
        }

        /** @override */
        toString() {
            const { client_ip: clientIpMatch } = this.getConfig();

            return clientIpMatch ? clientIpMatch.toString() : '';
        }
    }

    angular.extend(DnsPolicyRuleClientIpMatchConfig.prototype, {
        type: 'DnsClientIpMatch',
        name: 'DNS Client IP',
        defaultPath: 'dnsrule.match.client_ip_address',
    });

    return DnsPolicyRuleClientIpMatchConfig;
};

dnsPolicyRuleClientIpMatchConfigFactory.$inject = [
        'MatchConfigItem',
        'IpAddrMatchConfig',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleClientIpMatchConfig', dnsPolicyRuleClientIpMatchConfigFactory,
);
