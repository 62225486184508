/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-management-page';
const prefix = `${moduleName}.${componentName}`;

export const botManagementPagePolicyTitle = `${prefix}.botManagementPagePolicyTitle`;

export const columnTitleNameLabel = `${prefix}.columnTitleNameLabel`;

export const ENGLISH = {
    [botManagementPagePolicyTitle]: 'Bot Management Policy',

    [columnTitleNameLabel]: 'Name',
};
