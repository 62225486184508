/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name hostWithTokensValidator
 * @restrict A
 * @author Igor Kucheinik
 * @description
 *
 *     Validates tokenized string like HOST[1].PATH[2:3].
 *
 */
angular.module('aviApp').directive('hostWithTokensValidator', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, elm, attr, ctrl) {
            const regExp = '^(HOST|PATH|H|P|SG_MAP|StringGroup_Map|SG_RE|StringGroup_Regex)' +
                    '((\\[(\\d+):(\\d*)])|(\\[(\\d+)])|(\\[])?)$';

            function validToken(item) {
                return new RegExp(regExp, 'i').test(item);
            }

            function foundSpecialChars(item) {
                return /[!|@|#|$|%|^|&|*|(|)|[|\]]/g.test(item);
            }

            $(elm).on('keyup', function() {
                const val = $(elm).val().split('.');

                ctrl.$setValidity('tokens', !_.any(val, function(item) {
                    return !validToken(item) && foundSpecialChars(item);
                }));
            });
        },
    };
});
