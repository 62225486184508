/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name DNSQueryCollection
 * @description
 *
 *     Collection of Metrics with a unique DataTransformer for parsing data.
 */
angular.module('aviApp').factory('DNSQueryCollection', [
'Collection', 'MetricData',
(Collection, MetricData) => {
    class DNSQueryCollection extends Collection {}

    angular.extend(DNSQueryCollection.prototype, {
        itemClass_: MetricData,
        objectName_: 'dnsquery',
        allDataSources_: {
            list: {
                source: 'MetricDataListCollDataSource',
                transformer: 'DNSQueryListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        isStatic_: false,
    });

    return DNSQueryCollection;
}]);
