/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './ServiceEngineNavbars.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').directive('seNavbar', function() {
    class SeNavbarDirective {
        constructor($stateParams, l10nService) {
            this.$stateParams = $stateParams;
            this.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);
        }
    }

    SeNavbarDirective.$inject = [
        '$stateParams',
        'l10nService',
    ];

    return {
        scope: true,
        restrict: 'E',
        templateUrl: 'src/views/components/application/service-engine-navbar-bottom.html',
        link: {
            pre(scope, element) {
                element.addClass('navbar');
            },
        },
        controller: SeNavbarDirective,
        controllerAs: '$ctrl',
    };
});
