/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'wizard';
const prefix = `${moduleName}.${componentName}`;

export const stepLinkLabel = `${prefix}.stepLinkLabel`;

export const ENGLISH = {
    [stepLinkLabel]: 'Step {0}: {1}',
};
