/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IExpanderAction } from 'ng/shared/components/expander-with-actions';
import { WafPolicyPsmGroup } from 'ajs/modules/waf';
import './waf-policy-psm-group.less';

/**
 * @desc Component for displaying a WAF Policy PSM Group.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-group',
    templateUrl: './waf-policy-psm-group.component.html',
})
export class WafPolicyPsmGroupComponent {
    @Input() public group: WafPolicyPsmGroup;

    @Output() public onEdit = new EventEmitter();
    @Output() public onDelete = new EventEmitter();

    public expanderActions: IExpanderAction[];

    /**
     * @override
     */
    public ngOnInit(): void {
        this.expanderActions = [
            {
                confirmationButtonProps: {
                    actions: [
                        {
                            label: 'Remove',
                            onClick: (): void => this.handleRemove(),
                        },
                    ],
                    confirmationButtonClassName: 'avi-btn avi-btn-danger',
                },
                confirmationMessage: `Are you sure you want to remove ${this.group.getName()}?`,
                iconClassName: 'sl-icon-close',
                title: 'Remove',
            },
            {
                iconClassName: 'sl-icon-note',
                onClick: (): void => this.handleEdit(),
                title: 'Edit',
            },
        ];
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    public getEnableState(): number {
        return this.group.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    public handleClickEnable(): void {
        this.group.enable = !this.group.enable;
    }

    /**
     * Click handler for deleting a PSM group.
     */
    public handleRemove(): void {
        this.onDelete.emit();
    }

    /**
     * Click handler for editing a PSM Group.
     */
    public handleEdit(): void {
        this.onEdit.emit();
    }
}
