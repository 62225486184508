/**
 * Module containing match components.
 * @preferred
 * @module MatchModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { VIPModule } from '@vmw/ngx-vip';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    AuthAttributeMatchComponent,
    AuthAttributeMatchReadonlyComponent,
    BotClassMatcherComponent,
    BotClassMatcherReadonlyComponent,
    BotTypeMatcherComponent,
    BotTypeMatcherReadonlyComponent,
    GenericHdrMatchComponent,
    HdrMatchComponent,
    HdrMatchReadonlyComponent,
    HostHdrMatchComponent,
    HostHdrMatchReadonlyComponent,
    HttpMethodMatchComponent,
    HttpMethodMatchReadonlyComponent,
    HttpVersionMatchComponent,
    IpAddrMatchComponent,
    IpAddrMatchEntryComponent,
    IpAddrMatchReadonlyComponent,
    IpReputationTypeMatchComponent,
    JwtClaimMatchConfigComponent,
    JwtMatchComponent,
    JwtMatchReadonlyComponent,
    MatchAdderComponent,
    MatchAndActionDisplayWrapperComponent,
    MatchDisplayWrapperComponent,
    MatchWrapperComponent,
    PathOrQueryMatchComponent,
    PathOrQueryMatchReadonlyComponent,
    PortMatchComponent,
    ProtocolTypeMatchComponent,
    StringMatchConfigComponent,
} from './components';

import {
    ipAddrGroupCollectionProvider,
    rangeParserProvider,
} from './ajs-upgraded-providers';

const matchComponents = [
    AuthAttributeMatchComponent,
    AuthAttributeMatchReadonlyComponent,
    BotClassMatcherComponent,
    BotClassMatcherReadonlyComponent,
    BotTypeMatcherComponent,
    BotTypeMatcherReadonlyComponent,
    GenericHdrMatchComponent,
    HdrMatchComponent,
    HdrMatchReadonlyComponent,
    HostHdrMatchComponent,
    HostHdrMatchReadonlyComponent,
    HttpMethodMatchComponent,
    HttpMethodMatchReadonlyComponent,
    HttpVersionMatchComponent,
    IpAddrMatchComponent,
    IpAddrMatchEntryComponent,
    IpAddrMatchReadonlyComponent,
    IpReputationTypeMatchComponent,
    JwtClaimMatchConfigComponent,
    JwtMatchComponent,
    JwtMatchReadonlyComponent,
    MatchAdderComponent,
    MatchAndActionDisplayWrapperComponent,
    MatchDisplayWrapperComponent,
    MatchWrapperComponent,
    MatchWrapperComponent,
    PathOrQueryMatchComponent,
    PathOrQueryMatchReadonlyComponent,
    PortMatchComponent,
    ProtocolTypeMatchComponent,
    ProtocolTypeMatchComponent,
    StringMatchConfigComponent,
];

@NgModule({
    declarations: [
        ...matchComponents,
    ],
    exports: [
        AuthAttributeMatchReadonlyComponent,
        BotClassMatcherComponent,
        BotClassMatcherReadonlyComponent,
        BotTypeMatcherComponent,
        BotTypeMatcherReadonlyComponent,
        HdrMatchComponent,
        HdrMatchReadonlyComponent,
        HostHdrMatchComponent,
        HostHdrMatchReadonlyComponent,
        HttpMethodMatchComponent,
        HttpMethodMatchReadonlyComponent,
        IpAddrMatchComponent,
        IpAddrMatchReadonlyComponent,
        JwtMatchReadonlyComponent,
        MatchAdderComponent,
        MatchAndActionDisplayWrapperComponent,
        MatchDisplayWrapperComponent,
        MatchWrapperComponent,
        IpAddrMatchComponent,
        PathOrQueryMatchReadonlyComponent,
    ],
    imports: [
        CommonModule,
        VIPModule,
        SharedModule,
        AviFormsModule,
    ],
    providers: [
        ipAddrGroupCollectionProvider,
        rangeParserProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class MatchModule {}
