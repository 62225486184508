/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-group-list-expander';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const columnTitleRatio = `${prefix}.columnTitleRatio`;
export const columnTitleDeploymentState = `${prefix}.columnTitleDeploymentState`;
export const columnTitlePriority = `${prefix}.columnTitlePriority`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleStatus]: 'Status',
    [columnTitleRatio]: 'Ratio',
    [columnTitleDeploymentState]: 'Deployment State',
    [columnTitlePriority]: 'Priority',
};
