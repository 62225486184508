/**
 * @module AlertEmailModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { AlertEmailModalComponent } from 'ng/modules/alert';

/**
 * @description
 *
 *   AlertEmailConfig collection
 *
 * @author Guru Prasad H K
 */
export class AlertEmailConfigCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'alertemailconfig',
            permissionName: AviPermissionResource.PERMISSION_ALERTEMAILCONFIG,
            windowElement: AlertEmailModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('AlertEmailConfigItem');
    }
}

AlertEmailConfigCollection.ajsDependencies = [
    'AlertEmailConfigItem',
];
