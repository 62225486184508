/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ip-addr-group-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleIpAddressesOrRanges = `${prefix}.columnTitleIpAddressesOrRanges`;
export const columnTitleCountryCodesOrEpg = `${prefix}.columnTitleCountryCodesOrEpg`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const createIpGroupButtonLabel = `${prefix}.createIpGroupButtonLabel`;
export const countryCodeDataLabel = `${prefix}.countryCodeDataLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleIpAddressesOrRanges]: 'IP Addresses or Ranges',
    [columnTitleCountryCodesOrEpg]: 'Country Codes or EPG',
    [actionBtnEdit]: 'Edit',
    [createIpGroupButtonLabel]: 'Create IP Group',
    [countryCodeDataLabel]: 'Country Code',
};
