/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './WebhookController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name  WebhookController
 * @description
 *     Controller for Webhook List view.
 */
class WebhookController {
    constructor(
        CRUDGridConfig,
        WebhookCollection,
        l10nService,
    ) {
        this.collection = new WebhookCollection();

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);

        const { objectName } = this.collection;

        const config = {
            id: `${objectName}-list-page`,
            collection: this.collection,
            fields: [{
                name: 'data.config.name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
            }, {
                name: 'data.config.callback_url',
                title: l10nService.getMessage(l10nKeys.columnTitleCallbackUrl),
            }, {
                name: 'data.config.verification_token',
                title: l10nService.getMessage(l10nKeys.columnTitleVerificationToken),
            }],
        };

        this.gridConfig = CRUDGridConfig(config);
    }

    $onDestroy() {
        this.collection.destroy();
    }
}

WebhookController.$inject = [
        'CRUDGridConfig',
        'WebhookCollection',
        'l10nService',
];

angular.module('aviApp').controller('WebhookController', WebhookController);
