/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';

import {
    BgpPeerConfigItem,
    BgpTypes,
} from 'ajs/modules/vrf-context';

import { CloudType } from 'generated-types';
import { SubnetListNetworkCollection } from 'ajs/modules/network';
import * as l10n from './bgp-peer-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSubnetListNetworkCollection = typeof SubnetListNetworkCollection;

// Set of cloud types that supports placement_network field.
const CLOUDS_WITH_PLACEMENT_NETWORK_SUPPORT = new Set([
    CloudType.CLOUD_VCENTER,
    CloudType.CLOUD_NSXT,
    CloudType.CLOUD_OPENSTACK,
]);

/**
 * @description
 *
 *  Modal component to configure BGP Peer.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'bgp-peer-modal',
    templateUrl: './bgp-peer-modal.component.html',
})
export class BgpPeerModalComponent implements OnInit, OnDestroy {
    /**
     * BgpPeerConfigItem instance.
     */
    @Input()
    public editable: BgpPeerConfigItem;

    /**
     * Type of BGP Profile (iBGP/eBGP).
     */
    @Input()
    public bgpProfileType: BgpTypes;

    /**
     * Parent VRFContext Id.
     * Used to fetch placement_networks.
     */
    @Input()
    public vrfContextId: string;

    /**
     * Parent Cloud Id.
     * Used to fetch placement_networks.
     */
    @Input()
    public cloudId: string;

    /**
     * Parent Cloud Type.
     * Used to show/hide placement_networks.
     */
    @Input()
    public cloudType: CloudType;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit: EventEmitter<BgpPeerConfigItem> = new EventEmitter();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel: EventEmitter<void> = new EventEmitter<void>();

    /**
     * BgpPeer object type.
     */
    public objectType: string;

    /**
     * Layout for BGP Peer modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * SubnetListNetworkCollection instance.
     * Used in placement network collection-dropdown.
     */
    public subnetListNetworkCollection: SubnetListNetworkCollection;

    public readonly bgpTypesEnum = BgpTypes;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Model for IPv4 subnet (bgpPeer#subnet).
     * Interim solution until IpAddrPrefix message item configuration works
     * properly.
     */
    public ipv4Subnet: string;

    /**
     * Model for IPv6 subnet (bgpPeer#subnet6).
     * Interim solution until IpAddrPrefix message item configuration works
     * properly.
     */
    public ipv6Subnet: string;

    constructor(
        @Inject(SubnetListNetworkCollection)
        private readonly SubnetListNetworkCollection: TSubnetListNetworkCollection,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Initializes label dropdown options and subnetListNetworkCollection/
     * @override
     */
    public ngOnInit(): void {
        this.subnetListNetworkCollection = new this.SubnetListNetworkCollection({
            params: {
                cloud_uuid: this.cloudId,
                vrf_context_uuid: this.vrfContextId,
            },
        });

        this.objectType = this.editable.messageType;

        this.ipv4Subnet = this.editable.config.subnet.subnet || undefined;
        this.ipv6Subnet = this.editable.config.subnet6.subnet || undefined;
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.subnetListNetworkCollection.destroy();
    }

    /**
     * Returns true if Placement Network is available for the Peer.
     */
    public get showPlacementNetworkDropdown(): boolean {
        return CLOUDS_WITH_PLACEMENT_NETWORK_SUPPORT.has(this.cloudType);
    }

    /**
     * Called on IPv4 subnet ngModelChange.
     * Updates bgpPeer messageItem with the subnet value.
     */
    public onIpv4SubnetChange(): void {
        this.editable.config.subnet.subnet = this.ipv4Subnet;
    }

    /**
     * Called on Ipv6 subnet ngModelChange.
     * Updates bgpPeer messageItem with the subnet6 value.
     */
    public onIpv6SubnetChange(): void {
        this.editable.config.subnet6.subnet = this.ipv6Subnet;
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable as unknown as BgpPeerConfigItem);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
