/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Cloud } from 'object-types';
import { IPAMProfileCollection } from 'ajs/modules/ipam';

import {
    IPAMDNS_TYPE_AWS_DNS,
    IPAMDNS_TYPE_AZURE_DNS,
    IPAMDNS_TYPE_CUSTOM_DNS,
    IPAMDNS_TYPE_INFOBLOX_DNS,
    IPAMDNS_TYPE_INTERNAL_DNS,
} from 'ajs/js/constants/ipam-dns.constants';

import { L10nService } from '@vmw/ngx-vip';
import { CloudType } from 'generated-types';
import { ICloud } from '../../../cloud.types';

import * as l10n from './ipam-dns-configuration.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TIPAMProfileCollection = typeof IPAMProfileCollection;

const DNS_PROFILE_TYPES = [
    IPAMDNS_TYPE_AWS_DNS,
    IPAMDNS_TYPE_AZURE_DNS,
    IPAMDNS_TYPE_CUSTOM_DNS,
    IPAMDNS_TYPE_INFOBLOX_DNS,
    IPAMDNS_TYPE_INTERNAL_DNS,
].join();

const cloudsWithDnsResolver = new Set([
    CloudType.CLOUD_NSXT,
    CloudType.CLOUD_NONE,
]);

const cloudsWithStateBasedDnsRegistration = new Set([
    CloudType.CLOUD_NONE,
    CloudType.CLOUD_LINUXSERVER,
]);

/**
 * @description Component for configuring IPAM/DNS settings in the Cloud modal.
 * @author alextsg
 */
@Component({
    selector: 'ipam-dns-configuration',
    templateUrl: './ipam-dns-configuration.component.html',
})
export class IpamDnsConfigurationComponent implements OnInit, OnDestroy {
    /**
     * Cloud Item.
     */
    @Input() public editable: ICloud;

    /**
     * IPAMProfileCollection instance, for the IPAM profile AviCollectionDropdown options.
     */
    public ipamProfileCollection: IPAMProfileCollection;

    /**
     * IPAMProfileCollection instance, for the DNS profile AviCollectionDropdown options.
     */
    public dnsIpamProfileCollection: IPAMProfileCollection;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Flag to display State Based DNS Registration field.
     */
    public showStateBasedDnsRegistration = false;

    /**
     * Flag to display DNS Reolver Grid for applicable clouds.
     */
    public showDnsResolverGrid = false;

    /**
     * objectType property to be used in template for tooltip.
     */
    public readonly objectType = Cloud;

    public constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(IPAMProfileCollection) IPAMProfileCollection: TIPAMProfileCollection,
        l10nService: L10nService,
    ) {
        // IPAM/DNS Profile Creation and Editing is disabled in the template for now, because there
        // is an issue with overlaying avi-modals on top of full-modals.
        this.ipamProfileCollection = new IPAMProfileCollection({
            params: {
                type: DNS_PROFILE_TYPES,
                exclude: 'type',
            },
        });

        this.dnsIpamProfileCollection = new IPAMProfileCollection({
            params: {
                type: DNS_PROFILE_TYPES,
            },
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngOnInit(): void {
        this.showStateBasedDnsRegistration = cloudsWithStateBasedDnsRegistration.has(
            this.editable.getVtype(),
        );

        this.showDnsResolverGrid = cloudsWithDnsResolver.has(this.editable.getVtype());
    }

    /** @override */
    public ngOnDestroy(): void {
        this.ipamProfileCollection.destroy();
        this.dnsIpamProfileCollection.destroy();
    }
}
