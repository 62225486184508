/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleVipId = `${prefix}.columnTitleVipId`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const columnTitleIpv6Address = `${prefix}.columnTitleIpv6Address`;
export const columnTitlePrivateIpType = `${prefix}.columnTitlePrivateIpType`;
export const columnTitleEnabled = `${prefix}.columnTitleEnabled`;
export const columnTitlePublicIp = `${prefix}.columnTitlePublicIp`;
export const removeActionLabel = `${prefix}.removeActionLabel`;
export const editActionLabel = `${prefix}.editActionLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const vipsHeader = `${prefix}.vipsHeader`;
export const autoAllocateLabel = `${prefix}.autoAllocateLabel`;
export const staticLabel = `${prefix}.staticLabel`;

export const ENGLISH = {
    [columnTitleVipId]: 'VIP ID',
    [columnTitleIpAddress]: 'IP Address',
    [columnTitlePrivateIpType]: 'Private IP Type',
    [columnTitleIpv6Address]: 'IPv6 Address',
    [columnTitleEnabled]: 'Enabled',
    [columnTitlePublicIp]: 'Public IP',
    [removeActionLabel]: 'Remove',
    [editActionLabel]: 'Edit',
    [addButtonLabel]: 'Add',
    [vipsHeader]: 'VIPs ({0})',
    [autoAllocateLabel]: 'Auto-Allocate',
    [staticLabel]: 'Static',
};
