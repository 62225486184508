/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { L10nService } from '@vmw/ngx-vip';
import { TFileUploadModelValue } from '../file-upload';
import { normalizeValue } from '../../utils';
import './async-file-upload.component.less';
import * as l10n from './async-file-upload.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const enum LoadingState {
    DEFAULT = 'default',
    LOADING = 'loading',
}

/**
 * @description
 *     Component that renders a file selection and upload button, along with a progress bar and
 *     error message. An EventEmitter is tied to the upload button, to allow the dev to decide what
 *     service to use for uploading the file to the backend.
 *     AsyncFileUploadWithUploadServiceComponent can be used to upload a file with the upload
 *     service.
 * @author alextsg
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AsyncFileUploadComponent),
        },
    ],
    selector: 'async-file-upload',
    templateUrl: './async-file-upload.component.html',
})
export class AsyncFileUploadComponent implements ControlValueAccessor {
    /**
     * Max file size passed to the FileUploadComponent.
     */
    @Input()
    public maxFileSize = Infinity;

    /**
     * Error to be displayed.
     */
    @Input()
    public error?: string;

    /**
     * If true, displays the progress bar.
     */
    @Input()
    public showProgress = false;

    /**
     * Percentage of the file uploaded. Controls the width of the progress bar.
     */
    @Input()
    public uploadPercentage = 0;

    /**
     * Message to be displayed next to the progress bar. Ex. 'Uploading File' or '50%'.
     */
    @Input()
    public progressDisplayValue: string;

    /**
     * File name passed to the file-upload component. Displayed to the user to show that a file has
     * been selected/uploaded.
     */
    @Input()
    public fileName: string;

    /**
     * True to decode the selected file to a string. False by default.
     */
    @Input()
    public decodeFile = false;

    /**
     * Loading state of the upload button. If set to 'loading', shows a spinner.
     */
    @Input()
    public loadingState: string = LoadingState.DEFAULT;

    /**
     * Called when the Upload button is clicked.
     */
    @Output()
    public onFileUpload = new EventEmitter<TFileUploadModelValue>();

    /**
     * Change event emitter for the fileName binding.
     */
    @Output() public fileNameChange = new EventEmitter<string>();

    /**
     * If true, disables both the file select and upload buttons.
     */
    public disabled = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: TFileUploadModelValue;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): TFileUploadModelValue | undefined {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue.
     */
    public set value(val: TFileUploadModelValue) {
        if (this.modelValue !== val) {
            const normalizedValue = normalizeValue(val);

            this.modelValue = normalizedValue;
            this.onChange(normalizedValue);
        }

        this.onTouched();
    }

    /**
     * If no file has been selected, disable the upload button.
     */
    public get uploadDisabled(): boolean {
        return !this.value;
    }

    /**
     * Used to show upload button with loading state.
     */
    public get busy(): boolean {
        return this.loadingState === LoadingState.LOADING;
    }

    /**
     * Called after clicking the Upload button.
     */
    public handleFileUpload(): void {
        this.onFileUpload.emit(this.value);
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.modelValue = normalizeValue(value);
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     *  Emit selected file name to be used in parent.
     */
    public handleFileNameChange(fileName: string): void {
        this.fileNameChange.emit(fileName);
    }

    /**
     * Function that is called by the forms API when the control status changes to or from
     * 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: TFileUploadModelValue): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
