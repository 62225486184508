/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-group-create';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleRatio = `${prefix}.columnTitleRatio`;
export const columnTitlePriority = `${prefix}.columnTitlePriority`;
export const columnTitleDeploymentState = `${prefix}.columnTitleDeploymentState`;
export const removeActionTitle = `${prefix}.removeActionTitle`;
export const editActionTitle = `${prefix}.editActionTitle`;
export const editPoolGroupHeader = `${prefix}.editPoolGroupHeader`;
export const newPoolGroupHeader = `${prefix}.newPoolGroupHeader`;
export const selectVrfContextHeader = `${prefix}.selectVrfContextHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const poolGroupMembersHeader = `${prefix}.poolGroupMembersHeader`;
export const poolInputLabel = `${prefix}.poolInputLabel`;
export const poolInputPlaceholder = `${prefix}.poolInputPlaceholder`;
export const createPoolButtonLabel = `${prefix}.createPoolButtonLabel`;
export const ratioInputLabel = `${prefix}.ratioInputLabel`;
export const priorityInputLabel = `${prefix}.priorityInputLabel`;
export const deploymentStateInputLabel = `${prefix}.deploymentStateInputLabel`;
export const deploymentStateInputPlaceholder = `${prefix}.deploymentStateInputPlaceholder`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const confirmButtonLabel = `${prefix}.confirmButtonLabel`;
export const addMemberButtonLabel = `${prefix}.addMemberButtonLabel`;
export const addPoolGroupMemberButtonLabel = `${prefix}.addPoolGroupMemberButtonLabel`;
export const poolServersHeader = `${prefix}.poolServersHeader`;
export const enableHttp2CheckboxLabel = `${prefix}.enableHttp2CheckboxLabel`;
export const minimumNumberOfServersInputLabel = `${prefix}.minimumNumberOfServersInputLabel`;
export const poolGroupFailureSettingsHeader = `${prefix}.poolGroupFailureSettingsHeader`;
export const deploymentPolicyInputLabel = `${prefix}.deploymentPolicyInputLabel`;
export const deploymentPolicyInputPlaceholder = `${prefix}.deploymentPolicyInputPlaceholder`;
export const createDeploymentPolicyButtonLabel = `${prefix}.createDeploymentPolicyButtonLabel`;
export const nextButtonLabel = `${prefix}.nextButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const rbacHeader = `${prefix}.rbacHeader`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleRatio]: 'Ratio',
    [columnTitlePriority]: 'Priority',
    [columnTitleDeploymentState]: 'Deployment State',
    [removeActionTitle]: 'Remove',
    [editActionTitle]: 'Edit',
    [editPoolGroupHeader]: 'Edit Pool Group: {0}',
    [newPoolGroupHeader]: 'New Pool Group: {0}',
    [selectVrfContextHeader]: 'Select VRF Context',
    [nameInputLabel]: 'Name',
    [poolGroupMembersHeader]: 'Pool Group Members',
    [poolInputLabel]: 'Pool',
    [poolInputPlaceholder]: 'Select Pool',
    [createPoolButtonLabel]: 'Create Pool',
    [ratioInputLabel]: 'Ratio',
    [priorityInputLabel]: 'Priority',
    [deploymentStateInputLabel]: 'Deployment State',
    [deploymentStateInputPlaceholder]: 'Select Deployment State',
    [cancelButtonLabel]: 'Cancel',
    [confirmButtonLabel]: 'Confirm',
    [addMemberButtonLabel]: 'Add Member',
    [addPoolGroupMemberButtonLabel]: '+ Add Pool Group Member',
    [poolServersHeader]: 'Pool Servers',
    [enableHttp2CheckboxLabel]: 'Enable HTTP2',
    [minimumNumberOfServersInputLabel]: 'Minimum number of servers',
    [poolGroupFailureSettingsHeader]: 'Pool Group Failure Settings',
    [deploymentPolicyInputLabel]: 'Pool Group Deployment Policy',
    [deploymentPolicyInputPlaceholder]: 'Select a Policy',
    [createDeploymentPolicyButtonLabel]: 'Create Policy',
    [nextButtonLabel]: 'Next',
    [saveButtonLabel]: 'Save',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
};
