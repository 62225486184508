/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'aws-vip-config';
const prefix = `${moduleName}.${componentName}`;

export const availabilityZoneLabel = `${prefix}.availabilityZoneLabel`;
export const availabilityZonePlaceholder = `${prefix}.availabilityZonePlaceholder`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;

export const ENGLISH = {
    [availabilityZoneLabel]: 'Availability Zone',
    [availabilityZonePlaceholder]: 'Select Availability Zone',
    [sectionTitleGeneral]: 'General',
};
