/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gslb-pool-member-form';
const prefix = `${moduleName}.${componentName}`;

export const poolMemberPlural = `${prefix}.poolMemberPlural`;
export const ipAddressRadioLabel = `${prefix}.ipAddressRadioLabel`;
export const virtualServiceLabel = `${prefix}.virtualServiceLabel`;
export const siteClusterControllerLabel = `${prefix}.siteClusterControllerLabel`;
export const selectSiteInputPlaceholder = `${prefix}.selectSiteInputPlaceholder`;
export const virtualServiceInputPlaceholder = `${prefix}.virtualServiceInputPlaceholder`;
export const resolvedAddressLabel = `${prefix}.resolvedAddressLabel`;
export const publicAddressLabel = `${prefix}.publicAddressLabel`;
export const thirdPartySiteClusterControllerLabel = `${prefix}.thirdPartySiteClusterControllerLabel`;
export const radioLabel = `${prefix}.radioLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const removeGslbPoolMemberTitle = `${prefix}.removeGslbPoolMemberTitle`;
export const addGslbPoolMemberLinkLabel = `${prefix}.addGslbPoolMemberLinkLabel`;

export const ENGLISH = {
    [poolMemberPlural]: '{0, plural, one{Pool Member} other{Pool Members}}',
    [ipAddressRadioLabel]: 'IP Address',
    [virtualServiceLabel]: 'Virtual Service',
    [siteClusterControllerLabel]: 'Site Cluster Controller',
    [selectSiteInputPlaceholder]: 'Select Site',
    [virtualServiceInputPlaceholder]: 'Select Virtual Service',
    [resolvedAddressLabel]: 'Resolved IP(v4/v6) Address',
    [publicAddressLabel]: 'Public IP(v4/v6) Address',
    [thirdPartySiteClusterControllerLabel]: 'Third-party Site Cluster Controller',
    [radioLabel]: 'Ratio',
    [enabledLabel]: 'Enabled',
    [descriptionLabel]: 'Description',
    [removeGslbPoolMemberTitle]: 'Remove GSLB Pool Member',
    [addGslbPoolMemberLinkLabel]: 'Add GSLB Pool Member',
};
