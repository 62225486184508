/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    DefaultDnsTypeValues,
} from 'ajs/modules/cloud/factories/azure-configuration.config-item.factory';

import { IPAMProfileCollection } from 'ajs/modules/ipam/factories/ipam-profile.collection.factory';
import { IpamDnsType } from 'generated-types';
import { Cloud } from 'object-types';
import { ICloud } from '../../../../cloud.types';

import * as l10n from './azure-dns.l10n';
import './azure-dns.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TIpamProfileCollection = typeof IPAMProfileCollection;

/**
 * DNS Profile Types to be passed as a param to IpamProfileCollection.
 */
const dnsProfileTypes = [
    IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS,
    IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS,
    IpamDnsType.IPAMDNS_TYPE_AWS_DNS,
    IpamDnsType.IPAMDNS_TYPE_AZURE_DNS,
    IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS,
];

/**
 * @description Component for DNS section for Azure cloud.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'azure-dns',
    templateUrl: './azure-dns.component.html',
})
export class AzureDnsComponent implements OnInit {
    /**
     * Cloud Item passed as an input reference from Cloud Modal component.
     */
    @Input()
    public editable: ICloud;

    /**
     * Input for Use Azure DNS passed from Cloud modal component.
     */
    @Input()
    public useAzureDns = false;

    /**
     * Event Emitter when user selects/deselects the Use Azure option.
     */
    @Output()
    public useAzureDnsChange = new EventEmitter<boolean>();

    /**
     * IPAMProfileCollection instance.
     */
    public readonly ipamProfileCollection: IPAMProfileCollection;

    /**
     * Object Type to be used in template.
     */
    public readonly objectType = Cloud;

    /**
     * Model value for DNS Type radio buttons.
     */
    public dnsType: string;

    /**
     * Holds values of DefaultDnsTypeValues enum to use in template.
     */
    public readonly defaultDnsTypeValues = DefaultDnsTypeValues;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        @Inject(IPAMProfileCollection)
        private readonly IPAMProfileCollection: TIpamProfileCollection,
        l10nService: L10nService,
    ) {
        this.ipamProfileCollection = new IPAMProfileCollection();

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Sets the value for dnsType depending upon already set properties in config.
     */
    public ngOnInit(): void {
        this.ipamProfileCollection.setParams({
            type: dnsProfileTypes.join(),
        });

        if (this.useAzureDns) {
            this.dnsType = this.defaultDnsTypeValues.AZURE;
        } else if (this.editable.getConfig().dns_provider_ref) {
            this.dnsType = this.defaultDnsTypeValues.OTHER;
        } else {
            this.dnsType = this.defaultDnsTypeValues.NONE;
        }
    }

    /**
     * Triggered on change of DNS Type radio buttons.
     */
    public handleUpdate(): void {
        switch (this.dnsType) {
            case this.defaultDnsTypeValues.AZURE: {
                this.useAzureDns = true;
                this.editable.removeDnsProviderRef();
                break;
            }

            case this.defaultDnsTypeValues.NONE: {
                this.useAzureDns = false;
                this.editable.removeDnsProviderRef();
                break;
            }

            case this.defaultDnsTypeValues.OTHER: {
                this.useAzureDns = false;
                break;
            }
        }

        this.useAzureDnsChange.emit(this.useAzureDns);
    }
}
