/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'email-smtp-page';
const prefix = `${moduleName}.${componentName}`;

export const emailSmtpPageTitle = `${prefix}.emailSmtpPageTitle`;
export const editButtonLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [emailSmtpPageTitle]: 'Email/SMTP',
    [editButtonLabel]: 'Edit',
};
