/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component, ElementRef, Input,
} from '@angular/core';
import './avi-state-page.component.less';

const componentSelector = 'avi-state-page';

/**
 * Generic component to be used in pages. Meant for leaf-page node only, parent handles header.
 */
@Component({
    selector: componentSelector,
    templateUrl: './avi-state-page.component.html',
})
export class AviStatePageComponent {
    @Input() public hasSidebar?: boolean;

    /**
     * Toggle for deciding if sidebar is to show.
     */
    public expanded = true;

    /**
     * Sets class for component template element.
     * @param elementRef - wrapper around a native element inside of a View
     */
    public constructor(public elementRef: ElementRef) {
        elementRef.nativeElement.classList.add(componentSelector);
    }

    /**
     * Click handler for toggling the expander.
     */
    public handleClick(): void {
        this.expanded = !this.expanded;
    }
}
