/**
 * @module AviCredentialsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { VCenterServerModalComponent } from 'ng/modules/cloud';
import { IVcenterContentLibrary } from 'generated-types';
import { ContentLibConfigConfigItem } from './content-lib-config.config-item.factory';

interface IVCenterServerConfig {
    name?: string;
    vcenter_url?: string;
    vcenter_credentials_ref?: string;
    content_lib?: ContentLibConfigConfigItem;
    cloud_ref?: string;
}

interface IVCenterServerData {
    config: IVCenterServerConfig;
}

export type TVcenterLoginCredentials =
    Pick<IVCenterServerConfig, 'vcenter_url' | 'vcenter_credentials_ref'>;

const CONTENT_LIBRARIES_API = '/api/vcenter/contentlibraries';
const VERIFY_LOGIN_API = '/api/vcenterserver/verify/login';

export class VCenterServer extends withFullModalMixin(ObjectTypeItem) {
    public data: IVCenterServerData;
    public getConfig: () => IVCenterServerConfig;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'vcenterserver',
            objectType: 'VCenterServer',
            permissionName: 'PERMISSION_CLOUD',
            windowElement: VCenterServerModalComponent,
        };

        super(extendedArgs);
    }

    /**
     * Returns true if both the VCenter URL and Credentials are set.
     */
    public hasUrlAndCredentials(): boolean {
        const {
            vcenter_credentials_ref: credentialsRef,
            vcenter_url: vcenterUrl,
        } = this.config;

        return !isUndefined(credentialsRef) && !isUndefined(vcenterUrl);
    }

    /**
     * Fetches a list of content libraries.
     */
    public async fetchContentLibraries(): Promise<IVcenterContentLibrary[]> {
        const {
            vcenter_credentials_ref: credentialsRef,
            vcenter_url: vcenterUrl,
        } = this.getConfig();

        const stringService = this.getAjsDependency_('stringService');

        if (!this.hasUrlAndCredentials()) {
            return [];
        }

        const payload = {
            host: vcenterUrl,
            // eslint-disable-next-line no-extra-parens
            credentials_uuid: stringService.slug(credentialsRef),
        };

        this.cancelRequests('content-lib');
        this.busy = true;
        this.errors = null;

        try {
            const response = await this.request<any>(
                'POST',
                CONTENT_LIBRARIES_API,
                payload,
                null,
                'content-lib',
            );

            const { resource = {} } = response.data;
            const { vcenter_clibs: contentLibraries = [] } = resource;

            this.busy = false;

            return contentLibraries;
        } catch (errors) {
            this.busy = false;
            this.errors = errors.data;

            return Promise.reject(errors);
        }
    }

    /**
     * Called to verify vcenter login credentials.
     */
    public async verifyVcenterLogin(loginCredentials: TVcenterLoginCredentials): Promise<void> {
        const stringService = this.getAjsDependency_('stringService');
        const {
            vcenter_url: vcenterUrl,
            vcenter_credentials_ref: credentialsRef,
        } = loginCredentials;

        const payload = {
            host: vcenterUrl,
            credentials_uuid: stringService.slug(credentialsRef),
        };

        try {
            await this.request<unknown>('POST', VERIFY_LOGIN_API, payload, null, 'verify-login');
        } catch (errors) {
            return Promise.reject(errors.data.error);
        }
    }

    /**
     * Cancels any outstanding verify-login requests.
     */
    public cancelVerifyVcenterLogin(): void {
        this.cancelRequests('verify-login');
    }

    /**
     * Sets the vcenter_url and vcenter_credentials_ref properties on the config.
     */
    public setVcenterLoginCredentials(loginCredentials: TVcenterLoginCredentials): void {
        const config = this.getConfig();
        const {
            vcenter_url: vcenterUrl,
            vcenter_credentials_ref: credentialsRef,
        } = loginCredentials;

        config.vcenter_url = vcenterUrl;
        config.vcenter_credentials_ref = credentialsRef;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'content_lib',
        ];
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string { // eslint-disable-line class-methods-use-this
        return 'vCenter Server';
    }
}

VCenterServer.ajsDependencies = [
    'stringService',
];
