/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    forwardRef,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
    IAviDataGridConfig,
    TStringRow,
} from 'ng/shared/components';
import { debounce } from 'underscore';

import { L10nService } from '@vmw/ngx-vip';

import './avi-repeated-strings-grid.component.less';

import * as l10n from './avi-repeated-strings-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *    Component translates array of strings into an array of objects
 *    and pass the data to AviDataGrid.
 *    Emits ngModel value as array of strings.
 *
 *  @example
 *     <avi-repeated-strings-grid
 *         name="control-name"
 *         placeholder="Enter Value"
 *         columnLabel="Column Label"
 *         [(ngModel)]="stringsArray"
 *         [allowDuplicates]="false"
 *     ></avi-repeated-strings-grid>
 *
 * @author Rajawant Prajapati, Suraj Kumar
 */
@Component({
    selector: 'avi-repeated-strings-grid',
    templateUrl: './avi-repeated-strings-grid.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRepeatedStringsGridComponent),
        },
    ],
})

export class AviRepeatedStringsGridComponent implements
    AfterViewInit,
    ControlValueAccessor {
    /**
     * Placeholder for input control.
     */
    @Input()
    public placeholder = l10nKeys.stringInputPlaceholder;

    /**
     * AviDataGrid column label.
     */
    @Input()
    public columnLabel = l10nKeys.columnTitleString;

    /**
     * Check for allowing duplicate values.
     */
    @Input()
    public allowDuplicates = false;

    /**
     * Optional Helper-text.
     * To be displayed below input fields.
     */
    @Input()
    public inputHelperText ?= '';

    /**
     */
    @Input()
    public autoCompleteOptions?: string[];

    /**
     * Template ref for string input row.
     */
    @ViewChild('inputTemplateRef')
    public inputTemplateRef: TemplateRef<HTMLElement>;

    /**
     * String rows, it is passed to the AviDataGrid rows.
     */
    public stringRows: TStringRow[] = [];

    /**
     * Contains duplicate value of the AviDataGrid.
     */
    public duplicateValues: string[] = [];

    /**
     * Repeated Strings Grid Config.
     */
    public repeatedStringsGridConfig: IAviDataGridConfig;

    /**
     * This will be added as prefix for each input row.
     */
    public readonly repeatedStringRowPrefix = 'repeated_string_';

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * The ngModel value.
     */
    private modelValue: string[];

    constructor(
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.findDuplicates = debounce(this.findDuplicates, 250);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            columnLabel,
        } = this;

        this.repeatedStringsGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(columnLabel),
                id: 'repeated_string',
                templateRef: this.inputTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (stringRows: TStringRow[]) => {
                    stringRows.forEach((stringRow: TStringRow) => {
                        this.deleteString(stringRow);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (stringRow: TStringRow) => this.deleteString(stringRow),
            }],
        };
    }

    /**
     * Handler for string add operation.
     */
    public addString(): void {
        this.stringRows.push({ value: undefined });

        this.updateModelValueAndFindDuplicates(this.stringRows);
    }

    /**
     * Handler for row model change.
     * Updates modelValue and emits model change event.
     */
    public handleRowModelChange(): void {
        this.updateModelValueAndFindDuplicates(this.stringRows);
    }

    /**
     * Checks for duplicate error.
     */
    public get hasDuplicateError(): boolean {
        return this.duplicateValues.length > 0;
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
     */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(values: string[]): void {
        this.modelValue = values;

        this.setRepeatedStrings(values);
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string[]): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /*************************************************************************/

    /**
     * Set stringRows to be passed to AviDataGrid rows.
     */
    private setRepeatedStrings(values: string[]): void {
        values = values || [];

        // Convert received string array into array of objects to pass to the AviDataGrid rows.
        this.stringRows = values.map(item => {
            return { value: item };
        });
    }

    /**
     * Updates modelValue and emits model change event.
     */
    private updateModelValue(stringRows: TStringRow[]): void {
        this.modelValue = stringRows.length ? stringRows.map(item => item.value) : undefined;

        this.onChange(this.modelValue);
        this.onTouched();
    }

    /**
     * Triggers Updates modelValue and findDuplicates.
     */
    private updateModelValueAndFindDuplicates(stringRows: TStringRow[]): void {
        this.updateModelValue(stringRows);
        this.findDuplicates();
    }

    /**
     * Handler for string delete operation.
     */
    private deleteString(stringRow: TStringRow): void {
        const index = this.stringRows.indexOf(stringRow);

        this.stringRows.splice(index, 1);

        this.updateModelValueAndFindDuplicates(this.stringRows);
    }

    /**
     * To find duplicates values in grid.
     */
    private findDuplicates(): void {
        const uniqueElements = new Set();

        this.duplicateValues = [];

        if (!this.allowDuplicates) {
            this.stringRows.forEach(item => {
                if (uniqueElements.has(item.value)) {
                    this.duplicateValues.push(item.value);
                } else {
                    uniqueElements.add(item.value);
                }
            });
        }
    }
}
