/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'verify-ldap-controller';
const prefix = `${moduleName}.${componentName}`;

export const nameInputLabel = `${prefix}.nameInputLabel`;
export const testUserEntryRadioLabel = `${prefix}.testUserEntryRadioLabel`;
export const testUserGroupMembershipRadioLabel = `${prefix}.testUserGroupMembershipRadioLabel`;
export const verifyAuthProfileHeader = `${prefix}.verifyAuthProfileHeader`;
export const testBaseDnRadioLabel = `${prefix}.testBaseDnRadioLabel`;
export const userNameInputLabel = `${prefix}.userNameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const resultsInputLabel = `${prefix}.resultsInputLabel`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const verifyBtnLabel = `${prefix}.verifyBtnLabel`;

export const ENGLISH = {
    [nameInputLabel]: 'Name',
    [testUserEntryRadioLabel]: 'Test user entry',
    [testUserGroupMembershipRadioLabel]: 'Test user group membership',
    [verifyAuthProfileHeader]: 'Verify Auth Profile: {0}',
    [testBaseDnRadioLabel]: 'Test base DN',
    [userNameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [resultsInputLabel]: 'Results',
    [cancelBtnLabel]: 'Cancel',
    [verifyBtnLabel]: 'Verify',
};
