/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    LicenseSummaryService,
} from 'ajs/components/pages/administration/settings/licensing-page/license-summary';
import { reduce } from 'underscore';

/**
 * License tier type, enum.LicenseTierType in protobuf.
 * @typedef {string} module:avi/licensing.LicenseTierType
 * i.e. 'ENTERPRISE' or 'BASIC'
 */

/**
 * License type, enum.LicenseType in protobuf
 * @typedef {string} module:avi/licensing.LicenseType
 * i.e. 'service_cores'
 */

/**
 * Holds information needed for each prop in {@link module:avi/licensing.LicenseHash}.
 * @typedef {Object} module:avi/licensing.LicenseHashVal
 * @property {string} description - description found in schema/protobuf for license type
 * @property {number} used - num licenses used
 * @property {number} total - total num licenses available
 * @property {string} label - text to show in UI
 */

/**
 * Holds information for each {@link module:avi/licensing.licenseType} and
 * {@link module:avi/licensing.licenseTier} paired together,
 * to make a 2-D array for easy lookup and organisation by type or tier.
 * @memberOf module:avi/licensing
 * @name LicenseHash
 * @typedef {Object<module:avi/licensing.LicenseHashKey, module:avi/licensing.LicenseHashVal>}
 */

/**
 * Map for each type of {@link module:avi/licensing.LicenseType} needed in UI.
 * Each object maps to used, total, and label keys associated with
 * each key found in pb.CumulativeLicense from protobuf.
 * Ultimately is input needed to create {@link module:avi/licensing.LicenseHash}.
 * @memberOf module:avi/licensing
 * @name LicenseApiKeysByCumulativeLicenseType
 * @typedef {Object<module:avi/licensing.LicenseTierType, module:avi/licensing.LicenseApiKeys>}
 */

/**
 * Properties necessary for lookup for each type of license.
 * @typedef {Object} module:avi/licensing.LicenseApiKeys
 * @property {string} usedKey - number currently being used
 * @property {string} totalKey - total number available (used + free)
 * @property {string} label - human readable label for user
 */

/**
 * @type module:avi/licensing.LicenseApiKeysByCumulativeLicenseType
 * @inner
 */
const licenseApiKeysByCumulativeLicenseType = {
    service_cores: {
        usedKey: 'used_service_cores',
        totalKey: 'licensed_service_cores',
        label: 'Service Cores',
    },
};

function licenseUsageDataTransformerFactory(
    schemaService,
    RevisedDataTransformer,
) {
    /**
     * @constructor
     * @memberOf module:avi/licensing
     * @extends module:avi/dataModel.RevisedDataTransformer
     * @author Akul Aggarwal
     * @description Process response for license usage summary table.
     * Makes {@link module:avi/licensing.LicenseHash} for 2-dimensional license lookup,
     * along with {@link module:avi/licensing.LicenseTierType}
     * and {@link module:avi/licensing.LicenseType} lists.
     */
    class LicenseUsageDataTransformer extends RevisedDataTransformer {
        /**
         * Allows user flexibility to manipulate license summary data by providing lists and hash.
         * @param {Object} tierHash - license usage data returned from API
         * @return {Object.<string, Object>} - three objects holding:
         *     {@link module:avi/licensing.LicenseHash},
         *     {@link module:avi/licensing.LicenseType}, and
         *     {@link module:avi/licensing.LicenseTierType}
         * @protected
         */
        static getUsageListsAndHash_(tierHash) {
            return {
                licenseHash: LicenseUsageDataTransformer.getLicensingHashMap_(tierHash),
                licenseTypes: Object.keys(licenseApiKeysByCumulativeLicenseType),
                licenseTierTypes: Object.keys(tierHash),
            };
        }

        /**
         * Creates licenseHash.
         * @param {Object} tierHash - license usage data returned from API
         * @return {module:avi/licensing.LicenseHash}
         * @protected
         */
        static getLicensingHashMap_(tierHash) {
            return reduce(
                tierHash,
                (
                    accumulator,
                    tierObj,
                    licenseTierType,
                ) => {
                    /**
                     * @type {module:avi/licensing.LicenseHash}
                     */
                    const hashForSingleLicenseType = LicenseUsageDataTransformer
                        .getHashForSingleLicenseType_(tierHash, licenseTierType);

                    return Object.assign(accumulator, hashForSingleLicenseType);
                },
                {},
            );
        }

        /**
         * Creates hashes used in {@link module:avi/licensing.LicenseHash}, for one license type.
         * @param {Object} tierHash - license usage data returned from API
         * @param {module:avi/licensing.LicenseTierType} licenseTierType
         * @return {module:avi/licensing.LicenseHash} accumulator
         * @protected
         */
        static getHashForSingleLicenseType_(tierHash, licenseTierType) {
            return reduce(
                licenseApiKeysByCumulativeLicenseType,
                (
                    accumulator,
                    {
                        label,
                        totalKey,
                        usedKey,
                    },
                    licenseType,
                ) => {
                    /**
                     * @type {module:avi/licensing.LicenseHashKey}
                     */
                    const licenseHashKey =
                        LicenseSummaryService.getLicenseHashKey(licenseTierType, licenseType);

                    /**
                     * @type {module:avi/licensing.LicenseHashVal}
                     */
                    const licenseHashVal = LicenseUsageDataTransformer.getLicenseHashVal_(
                        licenseType,
                        label,
                        tierHash[licenseTierType][totalKey],
                        tierHash[licenseTierType][usedKey],
                    );

                    accumulator[licenseHashKey] = licenseHashVal;

                    return accumulator;
                },
                {},
            );
        }

        /**
         * Creates {@link module:avi/licensing.LicenseHashVal}.
         * @param {module:avi/licensing.LicenseType} licenseType - fieldName for object
         *     in schema/protobuf
         * @param {string} label
         * @param {number} used - number of used instances
         * @param {number} total - number of total instances
         * @returns {module:avi/licensing.LicenseHashVal}
         * @protected
         */
        static getLicenseHashVal_(
            licenseType,
            label,
            total,
            used,
        ) {
            return {
                description: schemaService.getFieldDescription('CumulativeLicense', licenseType),
                label,
                total,
                used,
            };
        }

        /**
         * @param {Object} rsp
         * @return {Object.<string, Object>} result - Object holding:
         *     {@link module:avi/licensing.LicenseHash},
         *     {@link module:avi/licensing.LicenseType}, and
         *     {@link module:avi/licensing.LicenseTierType}
         * @override
         */
        processResponse(rsp) {
            return { data: LicenseUsageDataTransformer.getUsageListsAndHash_(rsp.data.pertier) };
        }
    }

    return LicenseUsageDataTransformer;
}

licenseUsageDataTransformerFactory.$inject = [
    'schemaService',
    'RevisedDataTransformer',
];

angular.module('avi/licensing')
    .factory('LicenseUsageDataTransformer', licenseUsageDataTransformerFactory);
