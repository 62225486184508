/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *
 *   SSLKeyParams config item
 *
 * @author Nisar Nadaf
 */
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    ISSLKeyECParams,
    ISSLKeyParams,
    ISSLKeyRSAParams,
    SSLKeyAlgorithm,
    SSLKeyECCurve,
    SSLRSAKeySize,
} from 'generated-types';

type TSSLKeyParamsPartial = Omit<ISSLKeyParams, 'ec_params' | 'rsa_params'>;

interface ISSLKeyParamsConfig extends TSSLKeyParamsPartial {
    ec_params: MessageItem<ISSLKeyECParams>;
    rsa_params: MessageItem<ISSLKeyRSAParams>;
}

/**
 * Ajs dependency token for SSLKeyParamsConfigItem.
 */
export const SSL_KEY_PARAMS_CONFIG_ITEM_TOKEN = 'SSLKeyParamsConfigItem';

export class SSLKeyParamsConfigItem extends MessageItem<ISSLKeyParamsConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'SSLKeyParams',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for algorithm.
     */
    public get algorithm(): SSLKeyAlgorithm {
        const { algorithm } = this.config;

        return algorithm;
    }

    /**
     * Getter for key_size of rsa_params.
     */
    public get keySize(): SSLRSAKeySize {
        const { rsa_params: { config: rsaParamsConfig } } = this.config;
        const { key_size: keySize } = rsaParamsConfig;

        return keySize;
    }

    /**
     * Getter for curve of ec_params.
     */
    public get curve(): SSLKeyECCurve {
        const { ec_params: { config: ecParamsConfig } } = this.config;
        const { curve } = ecParamsConfig;

        return curve;
    }
}
