/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'client-ssl-certificate-validation';
const prefix = `${moduleName}.${componentName}`;

export const clientSSLCertificateValidationHeader = `${prefix}.clientSSLCertificateValidationHeader`;
export const clientCertificateLabel = `${prefix}.clientCertificateLabel`;
export const noneButtonLabel = `${prefix}.noneButtonLabel`;
export const requestButtonLabel = `${prefix}.requestButtonLabel`;
export const requireButtonLabel = `${prefix}.requireButtonLabel`;
export const pkiProfileInputLabel = `${prefix}.pkiProfileInputLabel`;
export const createPKIProfileLabel = `${prefix}.createPKIProfileLabel`;
export const selectPKIProfilePlaceholder = `${prefix}.selectPKIProfilePlaceholder`;
export const addHTTPHeaderFormLabel = `${prefix}.addHTTPHeaderFormLabel`;
export const addHTTPRequestHeaderInputLabel = `${prefix}.addHTTPRequestHeaderInputLabel`;
export const httpHeaderNameInputLabel = `${prefix}.httpHeaderNameInputLabel`;
export const httpHeaderNamePlaceholder = `${prefix}.httpHeaderNamePlaceholder`;
export const httpHeaderValueInputLabel = `${prefix}.httpHeaderValueInputLabel`;
export const httpHeaderValuePlaceholder = `${prefix}.httpHeaderValuePlaceholder`;
export const closeConnectionClientRequestHasHeaderName = `${prefix}.closeConnectionClientRequestHasHeaderName`;

export const ENGLISH = {
    [clientSSLCertificateValidationHeader]: 'Client SSL Certificate Validation',
    [clientCertificateLabel]: 'Client Certificate',
    [noneButtonLabel]: 'None',
    [requestButtonLabel]: 'Request',
    [requireButtonLabel]: 'Require',
    [pkiProfileInputLabel]: 'PKI Profile',
    [createPKIProfileLabel]: 'Create PKI Profile',
    [selectPKIProfilePlaceholder]: 'Select PKI Profile',
    [addHTTPHeaderFormLabel]: `Add an HTTP header to the client's request prior to sending the request
                                    to the server. For instance, terminate SSL and send the server information about the
                                    client's SSL certificate.`,
    [addHTTPRequestHeaderInputLabel]: 'Add HTTP Request Headers',
    [httpHeaderNameInputLabel]: 'HTTP Header Name',
    [httpHeaderNamePlaceholder]: 'Header Name',
    [httpHeaderValueInputLabel]: 'HTTP Header Value',
    [httpHeaderValuePlaceholder]: 'Header Value',
    [closeConnectionClientRequestHasHeaderName]: 'Close connection if HTTP client request has header name',
};
