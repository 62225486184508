/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    JWTServerProfileCollection,
    jwtServerProfileCollectionToken,
} from 'ajs/modules/security/factories';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './auth-profile-jwt-settings.l10n';
import template from './auth-profile-jwt-settings.component.html';

type JWTServerProfileCollectionType = typeof JWTServerProfileCollection;

export const AUTH_PROFILE_JWT_SETTINGS_TOKEN = 'authProfileJwtSettings';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class AuthProfileJwtSettingsController {
    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly jwtServerProfileCollection: JWTServerProfileCollection;

    constructor(
        JWTServerProfileCollection: JWTServerProfileCollectionType,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
        this.jwtServerProfileCollection = new JWTServerProfileCollection();
    }
}

AuthProfileJwtSettingsController.$inject = [
    jwtServerProfileCollectionToken,
    'l10nService',
];

export const authProfileJwtSettingsOptions = {
    bindings: {
        authProfile: '<',
    },
    controller: AuthProfileJwtSettingsController,
    template,
};
