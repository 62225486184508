/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function clusterNodeCollectionFactory(Collection, ClusterNode) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc
     *      Collection of the cluster's nodes. Have one cluster in a system which has up to three
     *      nodes. Need to call a cluster `config` and `runtime` APIs for the node's name, ip,
     *      state and role.
     */
    class ClusterNodeCollection extends Collection {}

    const allDataSources = {
        list: {
            source: 'ClusterNodeListCollDataSource',
            transformer: 'ClusterNodeDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
        'runtime-list': {
            source: 'ClusterNodeRuntimeCollDataSource',
            transformer: 'ClusterNodeDataTransformer',
            transport: 'ListDataTransport',
            fields: ['runtime'],
            dependsOn: 'config',
        },
    };

    Object.assign(ClusterNodeCollection.prototype, {
        objectName_: 'cluster',
        itemClass_: ClusterNode,
        isStatic_: false,
        defaultDataSources_: 'list',
        allDataSources_: allDataSources,
    });

    return ClusterNodeCollection;
}

clusterNodeCollectionFactory.$inject = [
    'Collection',
    'ClusterNode',
];

angular.module('avi/app')
    .factory('ClusterNodeCollection', clusterNodeCollectionFactory);
