/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Returns a parsed copy of the bindings object, stripping away functions defined as arrays.
 * @param {string} componentId - messageId.
 * @param {Object} bindings - Object of component bindings.
 * @return {Object}
 */
angular.module('aviApp').factory('ComponentBindingsSetter', ['$injector', function($injector) {
    return function(componentId, bindings) {
        const camelId = $.camelCase(componentId);
        const [{ bindToController }] = $injector.get(`${camelId}Directive`);

        _.each(bindings, (value, key, hash) => {
            if (angular.isUndefined(bindToController[$.camelCase(key)])) {
                console.warn(`"${key}" binding not defined in component.`);

                return;
            }

            const bindingSymbol = bindToController[$.camelCase(key)][0];

            if (bindingSymbol === '&' && Array.isArray(value) && value.length) {
                if ($injector.annotate(value).length &&
                        angular.isFunction(value[value.length - 1])) {
                    hash[key] = value[value.length - 1];
                }
            }
        });

        return bindings;
    };
}]);
