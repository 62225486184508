/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'network-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const editTcpUdpProfileHeader = `${prefix}.editTcpUdpProfileHeader`;
export const newTcpUdpProfileHeader = `${prefix}.newTcpUdpProfileHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionInputPlaceholder = `${prefix}.descriptionInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const rbacHeader = `${prefix}.rbacHeader`;
export const sectionTitleRbac = `${prefix}.sectionTitleRbac`;
export const tcpFastPathSettingsHeader = `${prefix}.tcpFastPathSettingsHeader`;
export const tcpProxySettingsHeader = `${prefix}.tcpProxySettingsHeader`;
export const sectionTitleDsr = `${prefix}.sectionTitleDsr`;
export const dsrProfileHeader = `${prefix}.dsrProfileHeader`;
export const protocolTypeInputPlaceholder = `${prefix}.protocolTypeInputPlaceholder`;
export const enableDsrInputLabel = `${prefix}.enableDsrInputLabel`;
export const udpProxySettingsHeader = `${prefix}.protocolTypeInputPlaceholder`;
export const udpFastPathSettingsHeader = `${prefix}.udpFastPathSettingsHeader`;
export const discardedCurrentValuesConfirmation = `${prefix}.discardedCurrentValuesConfirmation`;
export const resetToDefaultValuesHeader = `${prefix}.resetToDefaultValuesHeader`;

export const ENGLISH = {
    [editTcpUdpProfileHeader]: 'Edit TCP/UDP Profile: {0}',
    [newTcpUdpProfileHeader]: 'New TCP/UDP Profile: {0}',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [descriptionInputLabel]: 'Description',
    [descriptionInputPlaceholder]: 'Enter Description',
    [typeInputLabel]: 'Type',
    [protocolTypeInputPlaceholder]: 'Select Protocol Type',
    [sectionTitleGeneral]: 'General',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
    [sectionTitleRbac]: 'RBAC',
    [tcpFastPathSettingsHeader]: 'TCP Fast Path',
    [sectionTitleDsr]: 'DSR',
    [dsrProfileHeader]: 'Direct Server Return (DSR)',
    [tcpProxySettingsHeader]: 'TCP Proxy',
    [enableDsrInputLabel]: 'Enable DSR',
    [udpProxySettingsHeader]: 'UDP Proxy',
    [udpFastPathSettingsHeader]: 'UDP Fast Path',
    [discardedCurrentValuesConfirmation]: 'Switching type will reset any stored values to default.',
    [resetToDefaultValuesHeader]: 'Reset to Defaults',
};
