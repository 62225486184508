/**
 * @module GeoDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';
import {
    GeoDb,
    GeoDbCollection,
    GEO_DB_COLLECTION_TOKEN,
} from 'ajs/modules/geo-db/factories';
import template from './geo-db-list-page.component.html';
import * as l10n from './geo-db-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type GeoDbCollectionType = typeof GeoDbCollection;

export const GEO_DB_LIST_PAGE_TOKEN = 'geoDbListPage';

/**
 * @description
 *
 *   Geo Db list page component.
 *
 * @author Rachit Aggarwal.
 */
class GeoDbListPageController {
    /**
     * Geo Db collection-grid config.
     */
    public gridConfig: ICollectionGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly GeoDbCollection: GeoDbCollectionType,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.initGeoDbGrid();
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }

    /**
     * Initializes Geo Db collection-grid.
     */
    private initGeoDbGrid(): void {
        this.gridConfig = createCrudCollectionGridConfig();

        const { gridConfig, l10nService } = this;

        gridConfig.id = 'geo-db-list-page';

        gridConfig.collection = new this.GeoDbCollection();

        gridConfig.fields = [{
            name: 'getConfig().name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }, {
            name: 'getConfig().is_federated',
            title: l10nService.getMessage(l10nKeys.columnTitleFederated),
            template: '<geo-db-federated-grid-cell is-federated="row.getConfig().is_federated"/>',
        }, {
            name: 'files.getConfig().length',
            title: l10nService.getMessage(l10nKeys.columnTitleFiles),
        }, {
            name: 'mappings.getConfig().length',
            title: l10nService.getMessage(l10nKeys.columnTitleMappings),
        }];

        gridConfig.singleactions.push({
            title: l10nService.getMessage(l10nKeys.actionBtnDelete),
            class: 'icon-trash',
            do: (item: GeoDb) => item.drop(),
        });
    }
}

GeoDbListPageController.$inject = [
    GEO_DB_COLLECTION_TOKEN,
    'l10nService',
];

export const geoDbListPageOptions = {
    controller: GeoDbListPageController,
    template,
};
