/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function networkInventoryDataTransformerFactory(InventoryDataTransformer) {
    /**
     * @constructor
     * @memberOf module:avi/network
     * @extends module:avi/dataModel.InventoryDataTransformer
     * @author Ashish Verma
     * @desc
     *     InventoryDataTransformer of
     *     {@link module:avi/network.ConfiguredNetworkCollection ConfiguredNetworkCollection}.
     */
    class NetworkInventoryDataTransformer extends InventoryDataTransformer {
        /** @override */
        processResponse(resp, request) {
            function subnet2str(s) {
                return `${s.prefix.ip_addr.addr}/${s.prefix.mask}`;
            }

            resp = this.responseListOffsetControl_(resp, request);

            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                resp.data.results.forEach(function(net) {
                    const runtimeSubnets = {};

                    if (net.discovery && net.discovery.ip_subnet &&
                        Array.isArray(net.discovery.ip_subnet.length)) {
                        net.discovery.ip_subnet.forEach(function(s) {
                            s.asString = subnet2str(s);
                        });
                    }

                    if (net.runtime && net.runtime.subnet_runtime &&
                        Array.isArray(net.runtime.subnet_runtime)) {
                        net.runtime.subnet_runtime.forEach(function(s) {
                            runtimeSubnets[subnet2str(s)] = s;
                        });
                    }

                    if (net.config && net.config.configured_subnets &&
                        Array.isArray(net.config.configured_subnets)) {
                        net.config.configured_subnets.forEach(function(s) {
                            s.asString = subnet2str(s);

                            if (s.asString in runtimeSubnets) {
                                _.extend(s, runtimeSubnets[s.asString]);
                            }
                        });
                    }
                });
            }

            return resp;
        }
    }

    return NetworkInventoryDataTransformer;
}

networkInventoryDataTransformerFactory.$inject = [
    'InventoryDataTransformer',
];

angular.module('avi/network').factory(
    'NetworkInventoryDataTransformer',
    networkInventoryDataTransformerFactory,
);
