/**
 * @module UserProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'user-profile';
const componentName = 'user-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const maxPasswordHistoryCountInputLabel = `${prefix}.maxPasswordHistoryCountInputLabel`;
export const maxLoginFailureCountInputLabel = `${prefix}.maxLoginFailureCountInputLabel`;
export const accountLockTimeoutInputLabel = `${prefix}.accountLockTimeoutInputLabel`;
export const maxConcurrentSessionsInputLabel = `${prefix}.maxConcurrentSessionsInputLabel`;
export const credentialsTimeoutThresholdInputLabel = `${prefix}.credentialsTimeoutThresholdInputLabel`;
export const accountLockTimeoutInnerText = `${prefix}.accountLockTimeoutInnerText`;
export const credentialsTimeoutThresholdInnerText = `${prefix}.credentialsTimeoutThresholdInnerText`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const maxPasswordHistoryCountInputPlaceholder = `${prefix}.maxPasswordHistoryCountPlaceholder`;
export const maxLoginFailureCountInputPlaceholder = `${prefix}.maxLoginFailureCountPlaceholder`;
export const accountLockTimeoutInputPlaceholder = `${prefix}.accountLockTimeoutPlaceholder`;
export const maxConcurrentSessionsInputPlaceholder = `${prefix}.maxConcurrentSessionsPlaceholder`;
export const credentialsTimeoutThresholdInputPlaceholder = `${prefix}.credentialsTimeoutThresholdPlaceholder`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit User Profile: {0}',
    [modalHeaderNew]: 'New User Profile: {0}',
    [nameInputLabel]: 'Name',
    [maxPasswordHistoryCountInputLabel]: 'Max Password History Count',
    [maxLoginFailureCountInputLabel]: 'Max Login Failure Count',
    [accountLockTimeoutInputLabel]: 'Account Lock Timeout',
    [maxConcurrentSessionsInputLabel]: 'Max Concurrent Sessions',
    [credentialsTimeoutThresholdInputLabel]: 'Credentials Timeout Threshold',
    [accountLockTimeoutInnerText]: 'minutes',
    [credentialsTimeoutThresholdInnerText]: 'days',
    [nameInputPlaceholder]: 'Enter Name',
    [maxPasswordHistoryCountInputPlaceholder]: 'Enter Max Password History Count',
    [maxLoginFailureCountInputPlaceholder]: 'Enter Max Login Failure Count',
    [accountLockTimeoutInputPlaceholder]: 'Enter Account Lock Timeout',
    [maxConcurrentSessionsInputPlaceholder]: 'Enter Max Concurrent Sessions',
    [credentialsTimeoutThresholdInputPlaceholder]: 'Enter Credentials Timeout Threshold',
    [sectionTitleGeneral]: 'General',
};
