/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './dns-infoblox-usable-domains.component.less';
import * as l10n from './dns-infoblox-usable-domains.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'dns-infoblox-usable-domains';

/**
 * @constructor
 * @memberOf module:avi/ipam
 * @mixes module:avi/ipam.dnsInfobloxUsableDomainsBindings
 * @see {@link module:avi/ipam.dnsInfobloxUsableDomainsComponent}
 */
class DnsInfobloxUsableDomainsController {
    constructor(
        dropDownUtils,
        l10nService,
    ) {
        this.dropDownUtils_ = dropDownUtils;

        /**
         * List of domains Used for usable domain dropdown.
         * @type {DropDownOption[]}
         */
        this.infobloxDnsProfileDomainList = [];

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        if (this.ipamProfileId) {
            this.loadInfobloxDomainList_();
        }

        this.ipamDnsInfobloxProfileConfigController
            .addCredentialChangeListener(this.updateInfobloxDomainList_);
    }

    /**
     * Retrieves domains for selected infoblox-dns profile.
     * @param {boolean} withId If true, Id will be used to fetch domains.
     * @protected
     */
    loadInfobloxDomainList_(withId = true) {
        let id;

        if (withId) {
            id = this.ipamProfileId;
        }

        this.editable.getInfobloxDnsProfileDomainList(id)
            .then(domains => {
                this.infobloxDnsProfileDomainList = this.getDomainOptions_(domains);
            });
    }

    /**
     * Listener to Infoblox credential change event.
     * Update infoblox dns domain list from raw data using credentials.
     * Update related states.
     * @protected
     */
    updateInfobloxDomainList_ = () => {
        this.editable.clearInfobloxDnsProfileUsableDomaintList();
        this.infobloxDnsProfileDomainList = [];
        this.loadInfobloxDomainList_(false);
    }

    /**
     * Returns dropdown options for usable domain in infoblox dns profile configuration section.
     * @param {string[]} domains - Domain list.
     * @return {DropDownOption[]} - Dropdown option objects.
     * @protected
     */
    getDomainOptions_(domains) {
        let options = [];

        if (Array.isArray(domains)) {
            options = domains.map(domain => this.dropDownUtils_.createOption(domain));
        }

        return options;
    }

    /**
     * Returns true if there is domain optionn list and it's not empty.
     * @return {boolean}
     * @protected
     */
    hasInfobloxDnsProfileDomainList() {
        return this.infobloxDnsProfileDomainList.length > 0;
    }

    /**
     * Disable usable domain dropdown if there's no dropdown options or usable domain model data.
     * @return {boolean}
     */
    disableUsableDomainDropdown() {
        const domainList = this.editable.getInfobloxDnsProfileUsableDomainList();

        return !this.hasInfobloxDnsProfileDomainList() && _.isEmpty(domainList);
    }
}

DnsInfobloxUsableDomainsController.$inject = [
    'dropDownUtils',
    'l10nService',
];

/**
 * @name dnsInfobloxUsableDomainsComponent
 * @memberOf module:avi/ipam
 * @property {module:avi/ipam.dnsInfobloxUsableDomainsBindings} bindings
 * @property {module:avi/ipam.DnsInfobloxUsableDomainsController} controller
 * @description
 *      Component for Usable domains selection in IPAM/DNS Modal.
 *      Will be displayed when type is DNS and profile is Infoblox.
 * @author Aravindh Nagarajan
 */
angular.module('avi/ipam').component('dnsInfobloxUsableDomains', {
    /**
     * @mixin dnsInfobloxUsableDomainsBindings
     * @memberof module:avi/ipam
     * @property {module:avi/ipam.IpamDnsInfobloxProfileConfigItem} editable
     * @property {string} ipamProfileId - UUID of the parent IPAM profile.
     */
    bindings: {
        editable: '<',
        ipamProfileId: '@',
    },
    require: {
        ipamDnsInfobloxProfileConfigController: '^^ipamDnsInfobloxProfileConfig',
    },
    controller: DnsInfobloxUsableDomainsController,
    templateUrl: 'src/components/templates/profiles/ipam-dns-profiles-modal/' +
        `ipam-dns-infoblox-profile-config/${componentName}/${componentName}.component.html`,
});
