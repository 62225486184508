/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IGridConfig } from 'ajs/components/common/grid/grid-data-model';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './l4-datascript-log.l10n';
import template from './l4-datascript-log.component.html';
import './l4-datascript-log.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @author alextsg
 * @desc Component for L4 Datascript Logs.
 */
class L4DatascriptLogComponentController {
    /**
     * Input binding for dsLog string.
     */
    public dsLog: string;

    public gridConfig: IGridConfig;
    public gridRows: string[];

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    public $onInit(): void {
        this.gridRows = [this.dsLog];
        this.gridConfig = {
            fields: [
                {
                    name: 'datascript-log',
                    title: this.l10nService.getMessage(l10nKeys.datascriptLogGridTitle),
                    transform: (dsLog: string) => dsLog,
                },
            ],
            id: 'datascript-log',
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
        };
    }
}

L4DatascriptLogComponentController.$inject = [
    'l10nService',
];

export const l4DatascriptLogComponentOptions = {
    bindings: {
        dsLog: '@',
    },
    controller: L4DatascriptLogComponentController,
    template,
};
