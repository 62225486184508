/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHttpResponse, IPromise } from 'angular';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { IGslb, IGslbSite } from 'generated-types';
import { GSLB } from './gslb.item.factory';

interface IGslbSiteArgs {
    gslb: GSLB;
    nonAvi?: boolean;
}

const allDataSources = {
    list: {
        source: 'GSLBSiteInventoryCollDataSource',
        transformer: 'GSLBSiteInventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config', 'runtime'],
    },
};

/**
 * @description
 *
 *     Collection of GSLB sites coming from GSLB inventory. Since GslbSites are
 *     contained in GSLB configuration all edit operations are executed by GSLBSiteCollection.gslb.
 *
 *     There are two types of Gslb Sites - regular ones and "non avi" (third-party). Different
 *     collections and Gslb methods should be used for each of these types.
 *
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBSiteCollection extends Collection {
    public gslb: GSLB;
    public readonly nonAvi: boolean;
    private readonly gslbReloadEvents: string[] = ['itemLoadSuccess', 'itemSaveSuccess'];

    constructor(args: IGslbSiteArgs) {
        super(args);

        this.itemClass_ = this.getAjsDependency_('GSLBSite');

        if (args.gslb) {
            this.gslb = args.gslb;

            const { gslb } = args;

            this.gslbReloadEvents.forEach(eventType => gslb.bind(eventType, this.reload));
            gslb.one('beforeDestroy', this.destroy);
        } else {
            console.error(
                'GSLB instance is needed for GSLBSiteCollection to operate properly.',
            );
        }

        if (args.nonAvi) {
            /**
             * Set to true for collection of "non avi" Gslb sites.
             */
            this.nonAvi = true;
        }
    }

    /**
     * Returns gslb id
     */
    public getGSLBid(): string {
        return this.gslb?.id;
    }

    /** @override */
    public isCreatable(): boolean {
        return this.gslb.isEditable();
    }

    /**
     * Drops passed GslbSites through GSLB Item.
     */
    public dropSites(siteIds: string | IGslbSite | IGslbSite[]): IPromise<IHttpResponse<IGslb>> {
        const { gslb, nonAvi } = this;
        const methodName = nonAvi ? 'dropNonAviSites' : 'dropAviSites';

        return gslb[methodName](siteIds);
    }

    /**
     * Enables passed GslbSites through GSLB Item.
     */
    public enableSites(siteIds: IGslbSite | IGslbSite[]): IPromise<IHttpResponse<IGslb>> {
        const { gslb, nonAvi } = this;
        const methodName = nonAvi ? 'toggleNonAviSiteEnabledFlag' : 'toggleAviSiteEnabledFlag';

        return gslb[methodName](siteIds, true);
    }

    /**
     * Disables passed GslbSites through GSLB Item.
     */
    public disableSites(siteIds: IGslbSite | IGslbSite[]): IPromise<IHttpResponse<IGslb>> {
        const { gslb, nonAvi } = this;
        const methodName = nonAvi ? 'toggleNonAviSiteEnabledFlag' : 'toggleAviSiteEnabledFlag';

        return gslb[methodName](siteIds, false);
    }

    /** @override */
    public destroy = (): boolean => {
        const gotDestroyed = super.destroy();

        if (gotDestroyed) {
            const { gslb } = this;

            this.gslbReloadEvents.forEach(eventType => {
                gslb.unbind(eventType, this.reload);
            });

            gslb.unbind('beforeDestroy', this.destroy);
            this.gslb = undefined;
        }

        return gotDestroyed;
    };

    /**
     * Wrapper for Collection.load to be called on certain GSLBSiteCollection.GSLB events.
     */
    private reload = (): Promise<void> => {
        return this.load();
    };
}

Object.assign(GSLBSiteCollection.prototype, {
    objectName_: 'gslb-inventory',
    allDataSources_: allDataSources,
    defaultDataFields_: ['config', 'runtime'],
    isStatic_: false,
});

GSLBSiteCollection.ajsDependencies = [
    'GSLBSite',
];
