/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { IJWTMatch } from 'generated-types';
import { JWTMatch } from 'object-types';
import { JwtClaimMatchConfigItem } from './jwt-claim-match.config-item.factory';

type TJwtMatchPartial = Omit<IJWTMatch, 'matches'>;

interface IJwtMatchConfig extends TJwtMatchPartial {
    matches?: RepeatedMessageItem<JwtClaimMatchConfigItem>;
}

/**
 * @description JWTMatch ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class JwtMatchConfigItem extends MessageItem<IJwtMatchConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: JWTMatch,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for matches.
     */
    public get matches(): RepeatedMessageItem<JwtClaimMatchConfigItem> {
        return this.config.matches;
    }
}
