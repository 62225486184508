/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-rule-groups';
const prefix = `${moduleName}.${componentName}`;

export const addGroupButtonLabel = `${prefix}.addGroupButtonLabel`;
export const createGroupButtonLabel = `${prefix}.createGroupButtonLabel`;

export const ENGLISH = {
    [addGroupButtonLabel]: 'Add Group',
    [createGroupButtonLabel]: 'Create Group',
};
