/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-export-modal-service';
const prefix = `${moduleName}.${componentName}`;

export const exportModalName = `${prefix}.exportModalName`;

export const ENGLISH = {
    [exportModalName]: 'Export Certificate',
};
