/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name NetworkSecurityPolicyDos
 * @description
 *     An item of a Network Security Policy DoS rule containing blocked addresses.
 */
angular.module('aviApp').factory('NetworkSecurityPolicyDos', ['Item', Item => {
    const NetworkSecurityPolicyDos = class extends Item {
        /** @override */
        getIdFromData_(data) {
            return data && data.name || '';
        }
    };

    Object.assign(NetworkSecurityPolicyDos.prototype, {
        objectName: 'NetworkSecurityPolicyDos',
    });

    return NetworkSecurityPolicyDos;
}]);
