/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'application-profile-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [actionBtnEdit]: 'Edit',
};
