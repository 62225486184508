/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { isUndefined } from 'underscore';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Upload } from 'ajs/modules/core/factories/upload';
import { L10nService } from '@vmw/ngx-vip';
import { normalizeValue } from '../../utils';
import * as l10n from './async-file-upload-with-upload-service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TUpload = typeof Upload;

/**
 * @description
 *     Component that allows the user to select and upload a file to the controller. The ngModel is
 *     populated with the filePath and the file name.
 * @author alextsg
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AsyncFileUploadWithUploadServiceComponent),
        },
    ],
    selector: 'async-file-upload-with-upload-service',
    templateUrl: './async-file-upload-with-upload-service.component.html',
})
export class AsyncFileUploadWithUploadServiceComponent implements ControlValueAccessor, OnDestroy {
    /**
     * Max file size passed to the FileUploadComponent.
     */
    @Input()
    public maxFileSize = Infinity;

    /**
     * File path, used for uploading the file to the backend.
     */
    @Input()
    public filePath = '';

    /**
     * File API, used for uploading the file to the backend.
     */
    @Input()
    public fileApi = '';

    /**
     * Emits event with error when new upload error comes from backend api.
     */
    @Output()
    public onError = new EventEmitter<string>();

    /**
     * File name passed to the file-upload component. Displayed to the user to show that a file has
     * been selected/uploaded.
     */
    public fileName: string;

    /**
     * Selected file, passed as the ngModel to the file-upload component.
     */
    public file: File;

    /**
     * Errors from uploading the file.
     */
    public errors: string;

    /**
     * If true, disables both the file select and upload buttons.
     */
    public disabled = false;

    /**
     * If true, disable both the file select and upload buttons in the AsyncFileUploadComponent.
     */
    public pendingUpload = false;

    /**
     * If true, displays the progress bar.
     */
    public showProgress = false;

    /**
     * If true, shows the Upload Complete message.
     */
    public completedUpload = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value. Set to the <file-path>/<file-name>.
     */
    private modelValue: string;

    /**
     * Injected upload service
     */
    private readonly upload: Upload;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(Upload) UploadFactory: TUpload,
    ) {
        this.upload = new UploadFactory();
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.upload.isInProgress()) {
            this.upload.cancelUpload();
        }
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): string | undefined {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue.
     */
    public set value(val: string) {
        if (this.modelValue !== val) {
            const normalizedValue = normalizeValue(val);

            this.modelValue = normalizedValue;
            this.onChange(normalizedValue);
            this.fileName = isUndefined(normalizedValue) ? normalizedValue : this.getFileName();
        }

        this.onTouched();
    }

    /**
     * Called after clicking the Upload button.
     */
    public async handleFileUpload(): Promise<void> {
        if (this.errors) {
            this.onError.emit('');
        }

        const {
            file,
            filePath,
            fileApi,
        } = this;
        const { name: fileName } = file;

        this.showProgress = true;
        this.pendingUpload = true;
        this.completedUpload = false;
        this.errors = '';

        try {
            await this.upload.send(file as File, fileName, fileApi, filePath);
            this.completedUpload = true;
            this.value = `${filePath}/${fileName}`;
        } catch (errors) {
            this.errors = this.upload.error;
            this.onError.emit(this.errors);
        } finally {
            this.pendingUpload = false;
        }
    }

    /**
     * Returns the percentage of the file uploaded.
     */
    public get uploadPercentage(): number {
        return this.upload.getUploadPercentage() || 0;
    }

    /**
     * Returns the display text of the upload status. The upload can be complete, processing, or in
     * progress.
     */
    public get progressDisplayValue(): string {
        const { l10nService } = this;

        if (this.completedUpload) {
            return l10nService.getMessage(l10nKeys.uploadCompleteStatusLabel);
        }

        if (this.uploadPercentage === 100 && this.upload.isInProgress()) {
            return l10nService.getMessage(l10nKeys.processingStatusLabel);
        }

        return `${this.uploadPercentage}%`;
    }

    /**
     * Handler for file select change.
     */
    public handleFileSelectChange(file: File): void {
        this.value = undefined;

        if (file) {
            this.fileName = file.name;
        }
    }

    /**
     * ngModel value in the async-file-upload component is the selected file.
     * While editing we have just the file name, not the entire file
     * so when we remove the file while editing, just fileName gets changed and
     * ngModel value in the async-file-upload component doesn't change.
     * that's why ngModelChange is not getting called and
     * we are not using ngModelChange handler here.
     *
     * Method to reset model value in parent component if selected file is removed.
     */
    public handleFileNameChange(fileName: string): void {
        if (!fileName) {
            this.value = undefined;
        }
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        const normalizedValue = normalizeValue(value);

        this.modelValue = normalizedValue;
        this.fileName = isUndefined(normalizedValue) ? normalizedValue : this.getFileName();
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to or from
     * 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /**
     * Returns the file name, based on the ngModel value minus the given file path.
     */
    private getFileName(): string {
        return this.modelValue ? this.modelValue.replace(`${this.filePath}/`, '') : '';
    }
}
