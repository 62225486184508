/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-crs-expander-opener.l10n';
import './waf-crs-expander-opener.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for the WAF CRS expander opener. Displays the User Overrides badge and action
 *     buttons (edit and delete). Name and status should be transcluded.
 * @author alextsg
 */
@Component({
    selector: 'waf-crs-expander-opener',
    templateUrl: './waf-crs-expander-opener.component.html',
})
export class WafCrsExpanderOpenerComponent {
    /**
     * True to expand the opener.
     */
    @Input()
    public expanded = false;

    /**
     * True to display the edit button.
     */
    @Input()
    public allowEdit = false;

    /**
     * True to display the remove button.
     */
    @Input()
    public allowRemove = false;

    /**
     * Emitted when the user clicks the opener to expand/collapse it.
     */
    @Output()
    public onExpand = new EventEmitter<void>();

    /**
     * Emitted when the edit button is clicked.
     */
    @Output()
    public onEdit = new EventEmitter<void>();

    /**
     * Emitted when the remove button is clicked.
     */
    @Output()
    public onRemove = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when the opener is expanded/collapsed.
     */
    public handleExpand(): void {
        this.onExpand.emit();
    }

    /**
     * Called when the user clicks something in the region that should not collapse the opener.
     * Stops propagating the click event.
     */
    public handleInnerExpand($event: MouseEvent): void {
        $event.stopPropagation();
    }

    /**
     * Called when the user clicks the edit button.
     */
    public handleEdit($event: MouseEvent): void {
        $event.stopPropagation();
        this.onEdit.emit();
    }

    /**
     * Called when the user clicks the remove button.
     */
    public handleRemove($event: MouseEvent): void {
        $event.stopPropagation();
        this.onRemove.emit();
    }
}
