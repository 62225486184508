/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'geo-db-mapping-modal';
const prefix = `${moduleName}.${componentName}`;

export const geoDbMappingModalTitle = `${prefix}.geoDbMappingModalTitle`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const geoDbElementsLabel = `${prefix}.geoDbElementsLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;

export const ENGLISH = {
    [geoDbMappingModalTitle]: 'Add GeoDB Mapping: {0}',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [descriptionInputLabel]: 'Description',
    [sectionTitleGeneral]: 'General',
    [geoDbElementsLabel]: 'Elements ({0})',
};
