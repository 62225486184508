/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function clusterNodeFactory(UpdatableItem) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.UpdatableItem
     * @author Ram Pal
     * @desc ClusterNode item.
     */
    class ClusterNode extends UpdatableItem {
        /** @override */
        getIdFromData_(data) {
            return data && data.config && data.config.name || '';
        }
    }

    return ClusterNode;
}

clusterNodeFactory.$inject = [
    'UpdatableItem',
];

angular.module('avi/app')
    .factory('ClusterNode', clusterNodeFactory);
