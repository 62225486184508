/**
 * @module BotDetectionPolicyModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    BOT_ALLOW_LIST_CONFIG_ITEM_TOKEN,
    BOT_ALLOW_RULE_CONFIG_ITEM_TOKEN,
    BOT_DETECTION_POLICY_COLLECTION_TOKEN,
    BOT_DETECTION_POLICY_ITEM_TOKEN,
    BOT_IP_REPUTATION_TYPE_MAPPING_COLLECTION_TOKEN,
    BOT_IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN,
    BOT_MANAGEMENT_POLICY_LIST_PAGE_OPTIONS_TOKEN,
    BOT_MAPPING_COLLECTION_TOKEN,
    BOT_MAPPING_ITEM_TOKEN,
    BOT_MAPPING_RULE_ITEM_TOKEN,
    IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN,
} from './bot-detection-policy.tokens';

import {
    BotAllowListConfigItem,
    BotAllowRuleConfigItem,
    BotDetectionPolicyCollection,
    BotDetectionPolicyItem,
    BotIpReputationTypeMappingCollection,
    BotIpReputationTypeMappingItem,
    botManagementPolicyListPageOptions,
    BotMappingCollection,
    BotMappingItem,
    BotMappingRuleConfigItem,
    IpReputationTypeMappingConfigItem,
} from '.';

import {
    botDetectionResultMatchComponentOptions,
    BOT_DETECTION_RESULT_MATCH_CONTROLLER_TOKEN,
} from './components';

const botDetectionPolicyModule = angular.module('avi/bot-detection-policy');

const factories = [
    {
        factory: BotAllowListConfigItem,
        name: BOT_ALLOW_LIST_CONFIG_ITEM_TOKEN,
    },
    {
        factory: BotAllowRuleConfigItem,
        name: BOT_ALLOW_RULE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: BotDetectionPolicyCollection,
        name: BOT_DETECTION_POLICY_COLLECTION_TOKEN,
    },
    {
        factory: BotDetectionPolicyItem,
        name: BOT_DETECTION_POLICY_ITEM_TOKEN,
    },
    {
        factory: BotIpReputationTypeMappingItem,
        name: BOT_IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN,
    },
    {
        factory: BotIpReputationTypeMappingCollection,
        name: BOT_IP_REPUTATION_TYPE_MAPPING_COLLECTION_TOKEN,
    },
    {
        factory: BotMappingItem,
        name: BOT_MAPPING_ITEM_TOKEN,
    },
    {
        factory: BotMappingCollection,
        name: BOT_MAPPING_COLLECTION_TOKEN,
    },
    {
        factory: BotMappingRuleConfigItem,
        name: BOT_MAPPING_RULE_ITEM_TOKEN,
    },
    {
        factory: IpReputationTypeMappingConfigItem,
        name: IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN,
    },

];

const components = [
    {
        name: BOT_DETECTION_RESULT_MATCH_CONTROLLER_TOKEN,
        options: botDetectionResultMatchComponentOptions,
    },
    {
        name: BOT_MANAGEMENT_POLICY_LIST_PAGE_OPTIONS_TOKEN,
        options: botManagementPolicyListPageOptions,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        botDetectionPolicyModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => botDetectionPolicyModule.component(name, options));
