/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'system-configuration-controller';
const prefix = `${moduleName}.${componentName}`;

export const anyLabel = `${prefix}.anyLabel`;
export const emptyObjectLabel = `${prefix}.emptyObjectLabel`;
export const systemAccessSettingsHeader = `${prefix}.systemAccessSettingsHeader`;
export const accessMechanismsLabel = `${prefix}.accessMechanismsLabel`;
export const httpTextLabel = `${prefix}.httpTextLabel`;
export const httpsTextLabel = `${prefix}.httpsTextLabel`;
export const redirectHttpToHttpsTextLabel = `${prefix}.redirectHttpToHttpsTextLabel`;
export const basicAuthenticationTextLabel = `${prefix}.basicAuthenticationTextLabel`;
export const sslProfileLabel = `${prefix}.sslProfileLabel`;
export const sslCertificateLabel = `${prefix}.sslCertificateLabel`;
export const secureChannelSslCertificateLabel = `${prefix}.secureChannelSslCertificateLabel`;
export const allowedCiphersLabel = `${prefix}.allowedCiphersLabel`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const allowedHmacsLabel = `${prefix}.allowedHmacsLabel`;
export const snmpCommunityLabel = `${prefix}.snmpCommunityLabel`;
export const managementIpAccessControlLabel = `${prefix}.managementIpAccessControlLabel`;
export const sshAccessTextLabel = `${prefix}.sshAccessTextLabel`;
export const cliShellServerAccessTextLabel = `${prefix}.cliShellServerAccessTextLabel`;
export const apiAccessTextLabel = `${prefix}.apiAccessTextLabel`;
export const snmpAccessTextLabel = `${prefix}.snmpAccessTextLabel`;
export const loginBannerLabel = `${prefix}.loginBannerLabel`;
export const messageOfTheDayLabel = `${prefix}.messageOfTheDayLabel`;

export const ENGLISH = {
    [anyLabel]: 'Any',
    [emptyObjectLabel]: 'N/A',
    [systemAccessSettingsHeader]: 'System Access Settings',
    [accessMechanismsLabel]: 'Access Mechanisms',
    [httpTextLabel]: 'HTTP - {0},',
    [httpsTextLabel]: 'HTTPS - {0},',
    [redirectHttpToHttpsTextLabel]: 'Redirect HTTP to HTTPS - {0},',
    [basicAuthenticationTextLabel]: 'Basic Authentication - {0},',
    [sslProfileLabel]: 'SSL Profile',
    [sslCertificateLabel]: 'SSL Certificate',
    [secureChannelSslCertificateLabel]: 'Secure Channel SSL Certificate',
    [allowedCiphersLabel]: 'Allowed Ciphers',
    [emptyDataLabel]: 'None',
    [allowedHmacsLabel]: 'Allowed HMACs',
    [snmpCommunityLabel]: 'SNMP Community',
    [managementIpAccessControlLabel]: 'Management IP Access Control',
    [sshAccessTextLabel]: 'SSH Access: {0}',
    [cliShellServerAccessTextLabel]: 'CLI Shell Server Access: {0}',
    [apiAccessTextLabel]: 'API Access: {0}',
    [snmpAccessTextLabel]: 'SNMP Access: {0}',
    [messageOfTheDayLabel]: 'Message of the Day',
    [loginBannerLabel]: 'Login Banner',
};
