/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi/accounts';
const componentName = 'user-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const userInformationTitle = `${prefix}.userInformationTitle`;
export const userStatusLabel = `${prefix}.userStatusLabel`;
export const nameLabel = `${prefix}.nameLabel`;
export const usernameLabel = `${prefix}.usernameLabel`;
export const emailLabel = `${prefix}.emailLabel`;
export const superUserLabel = `${prefix}.superUserLabel`;
export const userProfileSelectLabel = `${prefix}.userProfileSelectLabel`;
export const userProfilePlaceholder = `${prefix}.userProfilePlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit User: {0}',
    [modalTitleNew]: 'New User: {0}',
    [userInformationTitle]: 'User Information',
    [userStatusLabel]: 'User Status',
    [nameLabel]: 'Name',
    [usernameLabel]: 'Username',
    [emailLabel]: 'Email',
    [superUserLabel]: 'Super User',
    [userProfileSelectLabel]: 'User Profile',
    [userProfilePlaceholder]: 'Use default User Profile',
};
