/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'vs-access-policy-settings';
const prefix = `${moduleName}.${componentName}`;

export const accessPolicyHeader = `${prefix}.accessPolicyHeader`;
export const noAccessPolicyRadioInputLabel = `${prefix}.noAccessPolicyRadioInputLabel`;
export const pingAccessRadioInputLabel = `${prefix}.pingAccessRadioInputLabel`;
export const samlAccessRadioInputLabel = `${prefix}.samlAccessRadioInputLabel`;
export const jwtAccessRadioInputLabel = `${prefix}.jwtAccessRadioInputLabel`;
export const ldapAccessRadioInputLabel = `${prefix}.ldapAccessRadioInputLabel`;

export const ENGLISH = {
    [accessPolicyHeader]: 'Access Policy',
    [noAccessPolicyRadioInputLabel]: 'None',
    [pingAccessRadioInputLabel]: 'PingAccess',
    [samlAccessRadioInputLabel]: 'SAML',
    [jwtAccessRadioInputLabel]: 'JWT',
    [ldapAccessRadioInputLabel]: 'LDAP',
};
