/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ConfigItem } from 'ajs/js/services/ConfigItem';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

const NETWORK_MODE_INBAND = 'INBAND_MANAGEMENT';

/**
 * @description  GcpConfigurationConfig ConfigItem class.
 * @author Chitra, Ram Pal
 */
export class GcpConfigurationConfig extends ConfigItem {
    constructor(args) {
        const extendedArgs = {
            defaultConfig: {},
            type: 'GcpConfiguration',
            ...args,
        };

        super(extendedArgs);

        this.defaultValues_ = this.getAjsDependency_('defaultValues');

        this.defaultConfig = this.defaultValues_.getDefaultItemConfigByType('gcpconfiguration');
        this.UpdatableItem_ = this.getAjsDependency_('UpdatableItem');
        this.$q_ = this.getAjsDependency_('$q');
    }

    /**
     * Retrieves GCP Regions and Networks list.
     * @returns {Promise<ng.$q.promise>}
     */
    getRegionsAndNetworks() {
        if (!this.busy) {
            this.busy = true;
            this.errors = null;

            const promises = [
                this.getRegions_()
                    .then(resp => resp.data),
                this.getNetworks_()
                    .then(resp => resp.data),
            ];

            return this.$q_.all(promises)
                .catch(resp => {
                    this.errors = resp.data;

                    return this.$q_.reject(resp);
                })
                .finally(() => this.busy = false);
        }
    }

    /**
     * Retrieves GCP Networks list.
     * To be called on VPC Project-ID change.
     * @returns {Promise|undefined} to be resolved with networks list.
     */
    getVPCNetworks() {
        if (!this.busy) {
            this.busy = true;
        }

        this.errors = null;

        return this.getNetworks_()
            .then(resp => resp.data)
            .catch(resp => {
                this.errors = resp.data;

                return this.$q_.reject(resp);
            })
            .finally(() => this.busy = false);
    }

    /**
     * Makes request for retrieving Gcp Regions.
     * @return {ng.$q.promise}
     * @protected
     */
    getRegions_() {
        const payload = this.getPayload_();
        const url = this.UpdatableItem_
            .getUrl('/api/gcp-get-regions', payload);

        return this.request('get', url);
    }

    /**
     * Makes request for Gcp subnets while setting this.busy and this.errors.
     * @return {ng.$q.promise}
     * @protected
     */
    getNetworks_() {
        const payload = this.getPayload_('networks');

        payload.nic_id = 0;

        const url = this.UpdatableItem_
            .getUrl('/api/gcp-get-networks', payload);

        return this.request('get', url);
    }

    /**
     * Prepares the payload with the required params.
     * It picks required config properties to be sent as payload.
     * It's being used by the APIs to get regions, zones, networks and subnetworks.
     * @param {string|undefined} type - networks for networks/subnets payload.
     * @return {Object} object.
     * @protected
     */
    getPayload_(type) {
        const payload = {};
        const {
            cloud_credentials_ref: cloudCredentialsRef,
            se_project_id: seProjectId,
            network_config: networkConfig,
        } = this.getConfig();

        if (cloudCredentialsRef) {
            payload.cloud_credentials_uuid = cloudCredentialsRef.slug();
        }

        if (type === 'networks' && networkConfig) {
            const { inband } = networkConfig;

            payload.project = inband && inband.vpc_project_id ?
                inband.vpc_project_id : seProjectId;
        } else {
            payload.project = seProjectId;
        }

        return payload;
    }

    /**
     * Retrieves zones list for the given SE project id and region.
     * @returns {Promise<ng.$q.promise>}
     */
    getAvailableZones() {
        const payload = this.getPayload_();

        const config = this.getConfig();

        payload.region = config.region_name;

        if (!this.busy) {
            this.errors = null;
            this.busy = true;

            const url = this.UpdatableItem_.getUrl('/api/gcp-get-zones', payload);

            return this.request('get', url)
                .then(resp => resp.data)
                .catch(resp => {
                    this.errors = resp.data;

                    return this.$q_.reject(resp);
                })
                .finally(() => this.busy = false);
        }
    }

    /**
     * Retrives the subnetworks list for the given SE project id
     * region and network.
     * @returns {Promise<ng.$q.promise>}
     */
    getSubNetworks() {
        const payload = this.getPayload_('networks');
        const config = this.getConfig();
        const { network_config: networkConfig } = config;

        payload.region = config.region_name;

        if (networkConfig && networkConfig.inband) {
            payload.network = networkConfig.inband.vpc_network_name;
        }

        if (!this.busy) {
            this.errors = null;
            this.busy = true;

            const url = this.UpdatableItem_.getUrl('/api/gcp-get-subnets', payload);

            return this.request('get', url)
                .then(resp => resp.data)
                .catch(resp => {
                    this.errors = resp.data;

                    return this.$q_.reject(resp);
                })
                .finally(() => this.busy = false);
        }
    }

    /**
     * Clears Zones and Sub network fields,
     * called when credentials have changed.
     */
    clearZonesAndSubNetwork() {
        const config = this.getConfig();

        delete config.zones;

        this.clearSubnetwork();
    }

    /**
     * Clears subnetwork field.
     */
    clearSubnetwork() {
        const config = this.getConfig();
        const { network_config: networkConfig } = config;

        if (networkConfig && networkConfig.inband) {
            delete networkConfig.inband.vpc_subnet_name;
        }
    }

    /**
     * Clears network field.
     */
    clearNetwork() {
        const config = this.getConfig();
        const { network_config: networkConfig } = config;

        if (networkConfig && networkConfig.inband) {
            delete networkConfig.inband.vpc_network_name;
        }
    }

    /**
     * Clears region, zones and Network fields.
     */
    clearLocationResourceAndNetworkFields() {
        const config = this.getConfig();

        delete config.region_name;
        this.clearZonesAndSubNetwork();
        delete config.network_config;
    }

    /**
     * Handles default service account auth checkbox toggling.
     * If its checked, cloud_credentials_ref will be deleted.
     */
    handleDefaultServiceAccountAuthChange() {
        const config = this.getConfig();

        delete config.cloud_credentials_ref;
    }

    /**
     * Handler for DNS type change.
     */
    handleDnsTypeChange() {
        const config = this.getConfig();

        if (config.dnsType === 'none') {
            delete config.dns_provider_ref;
        }
    }

    /**
     * @override
     */
    beforeEdit() {
        const config = this.getConfig();

        config.network_config = config.network_config || {};

        config.vip_allocation_strategy = config.vip_allocation_strategy || {
            mode: 'ROUTES',
        };
    }

    /**
     * se_project_id is passed as default value to vpc_project_id and cloud_storage_id.
     * @override
     */
    dataToSave() {
        const config = { ...this.getConfig() };
        const { firewall_target_tags: firewallTargetTags } = config;
        const { network_config: networkConfig } = config;

        if (networkConfig) {
            const { inband: inbandConfig } = networkConfig;

            networkConfig.config = NETWORK_MODE_INBAND;

            if (inbandConfig && !inbandConfig.vpc_project_id) {
                inbandConfig.vpc_project_id = config.se_project_id;
            }

            if (inbandConfig && !inbandConfig.gcs_project_id) {
                inbandConfig.gcs_project_id = config.se_project_id;
            }
        }

        if (firewallTargetTags && firewallTargetTags.length &&
            firewallTargetTags.every(firewall => _.isEmpty(firewall))) {
            delete config.firewall_target_tags;
        }

        return config;
    }

    /**
     * Returns encryption Keys for Google Cloud Services.
     * @return {GCPEncryptionKeys|null}
     */
    get encryptionKeys() {
        return this.getConfig().encryption_keys || null;
    }

    /**
     * Sets encryption Keys for Google Cloud Services.
     * @param {GCPEncryptionKeys} keys
     */
    set encryptionKeys(keys) {
        this.getConfig().encryption_keys = keys;
    }

    /**
     * Returns VIP Allocation strategy for Google Cloud Services.
     * @return {GCPVIPAllocation|null}
     */
    get vipAllocationStrategy() {
        return this.getConfig().vip_allocation_strategy || null;
    }
}

GcpConfigurationConfig.ajsDependencies = [
    'defaultValues',
    'UpdatableItem',
    '$q',
];

initAjsDependency(
    angular.module('aviApp'),
    'factory',
    'GcpConfigurationConfig',
    GcpConfigurationConfig,
);
