/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    forwardRef,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './enable-vip.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Vip Enabled checkbox.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnableVipComponent),
        },
    ],
    selector: 'enable-vip',
    templateUrl: './enable-vip.component.html',
})
export class EnableVipComponent implements ControlValueAccessor {
    /**
     * ObjectType of Vip.
     */
    public readonly objectType = 'Vip';

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: string;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): string {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue.
     */
    public set value(val: string) {
        this.modelValue = val;
        this.onChange(val);
        this.onTouched();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.modelValue = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
