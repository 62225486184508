/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @alias seriesDataPointService */
function seriesDataPointService() {
    /**
     * Returns string representation of {@link Metric} {@link Series} data point.
     * @param {SeriesDataPoint|null} dataPoint
     * @return {string}
     * @public
     */
    function getDataPointHashString(dataPoint) {
        if (!dataPoint) {
            return '';
        }

        const { timestamp, value } = dataPoint;

        return `${timestamp}-${value}`;
    }

    return {
        getDataPointHashString,
    };
}

/**
 * @ngdoc service
 * @name seriesDataPointService
 * @author Alex Malitsky
 * @desc
 *
 *     Service for handling {@link Series} data point.
 *     To be replaced with SeriesDataPoint configItem class.
 */
angular.module('avi/metrics')
    .factory('seriesDataPointService', seriesDataPointService);
