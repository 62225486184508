/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './string-match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name stringMatch
 * @description
 *     Component for displaying the string match
 * @param {StringMatchConfig} match - StringMatch ConfigItem object.
 * @param {Function=} onDelete - Method to delete the match.
 * @param {boolean=} allowDelete - Showing remove button or not.
 * @param {Array<Object>=} matchPatterns - Enum object list of custom string operations.
 */
class StringMatchController {
    constructor(
        schemaService,
        l10nService,
    ) {
        this.matchPatterns = this.matchPatterns ||
                schemaService.getEnumValues('StringOperation');

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }
}

StringMatchController.$inject = [
        'schemaService',
        'l10nService',
];

angular.module('aviApp').component('stringMatch', {
    bindings: {
        match: '<',
        allowDelete: '<?',
        onDelete: '&?',
        matchPatterns: '<?',
    },
    controller: StringMatchController,
    templateUrl: 'src/components/templates/profiles/analytics/string-match/string-match.html',
});
