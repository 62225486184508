/**
 * Module for L4 Policy related components and services.
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/utils/create-crud-collection-grid-config.factory';
import { L10nService } from '@vmw/ngx-vip';

import { L4PolicySetCollection } from '../factories/l4-policy-set-collection.factory';

import template from './l4-policy-set-list.component.html';
import expandedContainerTemplate from './l4-policy-set-list-expander.partial.html';

import * as l10n from './l4-policy-set-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'l4-policy-set-list';

type TL4PolicySetCollection = typeof L4PolicySetCollection;
type TCRUDGridConfig = typeof createCrudCollectionGridConfig;

/**
 * @constructor
 * @see {@link module:avi/policies/l4.l4PolicySetListComponent}
 */
class L4PolicySetListController {
    /**
     * L4 policy set collection-grid config.
     */
    public gridConfig: ICollectionGridConfig;

    /**
     * l10n keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
       private $element: ng.IRootElementService,
       private readonly L4PolicySetCollection: TL4PolicySetCollection,
       private CRUDGridConfig: TCRUDGridConfig,
       private l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.$element.addClass(componentName);

        const gridConfigOptions = {
            collection: new this.L4PolicySetCollection({
                isStatic: false,
            }),
            fields: [{
                name: 'name',
                title: this.l10nService.getMessage(l10nKeys.columnTitleName),
                template: '{{ row.getName() }}',
                sortBy: 'name',
            },
            {
                name: 'rules',
                title: this.l10nService.getMessage(l10nKeys.columnTitleNumberRules),
                template: '{{ row.l4ConnectionPolicy.rulesCount }}',
            }],
            expandedContainerTemplate,
            permission: 'PERMISSION_L4POLICYSET',
        };

        const { objectName } = gridConfigOptions.collection;

        this.gridConfig = this.CRUDGridConfig(gridConfigOptions);
        this.gridConfig.id = `${objectName}-list-page`;
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }
}

L4PolicySetListController.$inject = [
    '$element',
    'L4PolicySetCollection',
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @name l4PolicySetListComponent
 * @desc L4 Policy Set list page component
 * @author Zhiqian Liu
 */
export const l4PolicySetListComponentOptions = {
    controller: L4PolicySetListController,
    template,
};
