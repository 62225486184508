/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'groupsModule';
const componentName = 'ip-addr-group-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const ipGroupCreateTypeIpLabel = `${prefix}.ipGroupCreateTypeIpLabel`;
export const ipGroupCreateTypeCountryCodeLabel = `${prefix}.ipGroupCreateTypeCountryCodeLabel`;
export const typeLabel = `${prefix}.typeLabel`;
export const ipListFileUploadLabel = `${prefix}.ipListFileUploadLabel`;
export const countryCodesInputLabel = `${prefix}.countryCodesInputLabel`;
export const countryCodesInputPlaceholder = `${prefix}.countryCodesInputPlaceholder`;
export const ipAddressesLabel = `${prefix}.ipAddressesLabel`;
export const fileHasNoValidIpAddressesErrorMessage = `${prefix}.fileHasNoValidIpAddressesErrorMessage`;
export const fileUploadSuccessMessage = `${prefix}.fileUploadSuccessMessage`;
export const discardedCurrentValuesConfirmation = `${prefix}.discardedCurrentValuesConfirmation`;
export const resetToDefaultValuesHeader = `${prefix}.resetToDefaultValuesHeader`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit IP Group: {0}',
    [modalTitleNew]: 'New IP Group: {0}',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [ipGroupCreateTypeIpLabel]: 'IP Address',
    [ipGroupCreateTypeCountryCodeLabel]: 'Country Code',
    [typeLabel]: 'Type',
    [ipListFileUploadLabel]: 'Import IP Address From File',
    [countryCodesInputLabel]: 'Country Code',
    [countryCodesInputPlaceholder]: 'Select Country Code',
    [ipAddressesLabel]: 'IP Addresses ({0})',
    [fileHasNoValidIpAddressesErrorMessage]: 'File does not contain any valid IP addresses.',
    [fileUploadSuccessMessage]: 'Successfully imported {0} records. {1} duplicates removed.',
    [discardedCurrentValuesConfirmation]: 'Switching type will reset any stored values to default.',
    [resetToDefaultValuesHeader]: 'Reset to Defaults',
};
