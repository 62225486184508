/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './StaticRouteCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name StaticRouteCreateController
 */
class StaticRouteCreateController {
    constructor($scope, l10nService) {
        $scope.$parent.modalScope = $scope;
        $scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

StaticRouteCreateController.$inject = [
    '$scope',
    'l10nService',
];

angular.module('aviApp')
    .controller('StaticRouteCreateController', StaticRouteCreateController);
