/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs';
const componentName = 'vip-list-popover';
const prefix = `${moduleName}.${componentName}`;

export const headerPrefix = `${prefix}.headerPrefix`;

export const ENGLISH = {
    [headerPrefix]: 'Addresses {0} ({1})',
};
