/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SSLProfile, SSLRatingConfigItem } from 'ajs/modules/security';
import { ISSLRating } from 'generated-types';
import { DevLoggerService } from 'ng/modules/core';

export interface ISSLRatingFetchData {
    sslRatingConfigItem?: SSLRatingConfigItem;
    loading?: boolean;
}

type TSSLRatingConfigItem = typeof SSLRatingConfigItem;

/**
 * This will be emitted as SSLRating if loading or SSLProfile is invalid.
 */
const EMPTY_SSLRATING_CONFIG = {};

/**
 * @description
 *
 *      Service which is used for main modal & side-rail component communication.
 *
 *      On SSLProfileUpdate, It fetches latest rating from Backend and emits to
 *      its subscribers.
 *
 * @author Aravindh Nagarajan
 */
@Injectable()
export class SslProfileModalService {
    /**
     * ReplaySubject to control SSL profile update event.
     * Using ReplaySubject here to make sure early emitted values will not be lost.
     */
    private readonly sslProfileUpdateSubject = new ReplaySubject<ISSLRatingFetchData>();

    /**
     * SSLRatingConfigItem instance.
     */
    private sslRatingConfigItem: SSLRatingConfigItem = null;

    constructor(
        private readonly devLoggerService: DevLoggerService,
        @Inject(SSLRatingConfigItem)
        private readonly SSLRatingConfigItem: TSSLRatingConfigItem,
    ) {
        this.sslRatingConfigItem = new this.SSLRatingConfigItem();
    }

    /**
     * Fires on SSL profile update event.
     * @param sslProfile - sslProfile configItem which is being edited.
     * @param getRatingFromProfile - If true, use rating from profile, dont fetch.
     */
    public async onSslProfileUpdate(
        sslProfile: SSLProfile,
        getRatingFromProfile = false,
    ): Promise<void> {
        this.emitSslRatingConfigItem(EMPTY_SSLRATING_CONFIG, true);

        if (getRatingFromProfile) {
            this.emitSslRatingConfigItem(sslProfile.sslRating.config);
        } else if (sslProfile.hasAcceptedVersionAndType()) {
            try {
                const sslRating = await sslProfile.fetchSSLRating();

                this.emitSslRatingConfigItem(sslRating);
            } catch (e) {
                this.devLoggerService.error(e);
            }
        } else {
            // pass empty object for invalid state.
            this.emitSslRatingConfigItem(EMPTY_SSLRATING_CONFIG);
        }
    }

    /**
     * Returns an observable to make subscriptions.
     */
    public get sslProfileUpdate$(): Observable<ISSLRatingFetchData> {
        return this.sslProfileUpdateSubject.asObservable();
    }

    /**
     * Cleaning up.
     * @override
     */
    public ngOnDestroy(): void {
        this.sslRatingConfigItem.destroy();
        this.sslProfileUpdateSubject.complete();

        this.sslRatingConfigItem = null;
    }

    /**
     * Emits SSLRatingConfigItem with latest rating and loading states.
     */
    private emitSslRatingConfigItem(sslRatingConfig: ISSLRating, loading = false): void {
        this.sslRatingConfigItem.updateConfig(sslRatingConfig);

        this.sslProfileUpdateSubject.next({
            loading,
            sslRatingConfigItem: this.sslRatingConfigItem,
        });
    }
}
