/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const componentName = 'c-grid-table-header-cell-sort-icon';

class GridTableHeaderCellSortIconController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.$element.addClass(componentName);
    }

    /**
     * Returns class name for the appropriate icon (up or down).
     * @returns {string}
     */
    getClassName() {
        const { state } = this;

        return state ? `icon-sort-${state === 1 ? 'desc' : 'asc'}` : '';
    }
}

GridTableHeaderCellSortIconController.$inject = [
    '$element',
];

/**
 * @ngdoc component
 * @name gridTableHeaderCellSortIcon
 * @param {number} state - 0 for no sorting, 1 for descending and 2 for ascending.
 * @desc
 *
 *     Renders appropriate icon representing column sorting.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid').component('gridTableHeaderCellSortIcon', {
    bindings: {
        state: '<',
    },
    controller: GridTableHeaderCellSortIconController,
    template: '<i class="icon icon-sort" ng-class="$ctrl.getClassName()"/>',
});
