/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { HSMSafenetClientInfoConfigItem } from 'ajs/modules/security';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { IAviDataGridConfig } from 'ng/shared/components';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './hsm-group-safenet-client-info-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Safenet Client Info grid component.
 * @author vgohil
 */
@Component({
    selector: 'hsm-group-safenet-client-info-grid',
    templateUrl: './hsm-group-safenet-client-info-grid.component.html',
})
export class HsmGroupSafenetClientInfoGridComponent implements AfterViewInit {
    /**
     * Safenet Client Info RepeatedMessageItem instance.
     */
    @Input()
    public clientInfoList: RepeatedMessageItem<HSMSafenetClientInfoConfigItem>;

    /**
     * Fires on Add Safenet Client Info item.
     */
    @Output()
    public onAddClientInfo = new EventEmitter();

    /**
     * Fires on Remove Safenet Client Info item.
     */
    @Output()
    public onRemoveClientInfo = new EventEmitter<HSMSafenetClientInfoConfigItem>();

    /**
     * TemplateRef for ClientInfo#ip input element.
     */
    @ViewChild('clientInfoIpTemplateRef')
    public clientInfoIpTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Safenet Client Info grid config.
     */
    public clientInfoGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.clientInfoGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleClientIp),
                    id: 'client_ip',
                    templateRef: this.clientInfoIpTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (clientInfoList: HSMSafenetClientInfoConfigItem[]) => {
                    clientInfoList
                        .forEach((clientInfo: HSMSafenetClientInfoConfigItem) => {
                            this.removeClientInfo(clientInfo);
                        });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (clientInfo: HSMSafenetClientInfoConfigItem) => {
                    this.removeClientInfo(clientInfo);
                },
            }],
        };
    }

    /**
     * Handler for Safenet Client Info item add operation.
     */
    public addClientInfo(): void {
        this.onAddClientInfo.emit();
    }

    /**
     * Handler for Safenet Client Info item remove operation.
     */
    private removeClientInfo(clientInfo: HSMSafenetClientInfoConfigItem): void {
        this.onRemoveClientInfo.emit(clientInfo);
    }
}
