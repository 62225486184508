/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network-services';
const componentName = 'network-services-page';
const prefix = `${moduleName}.${componentName}`;

export const networkServicesPageTitle = `${prefix}.networkServicesPageTitle`;
export const columnTitleName = `${prefix}.columnTitleName`;

export const ENGLISH = {
    [networkServicesPageTitle]: 'Network Services',
    [columnTitleName]: 'Name',
};
