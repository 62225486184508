/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    isEmpty,
    pluck,
} from 'underscore';

import { HSMAwsCloudHsm } from 'object-types';
import { IHSMAwsCloudHsm } from 'generated-types';
import { TStringRow } from 'ng/shared/components';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

interface IHSMAwsCloudHsmConfig extends IHSMAwsCloudHsm {
    hsm_ip_wrappers: TStringRow[];
}

/**
 * @description HSM AWS CloudHSM Message Item.
 * @author vgohil
 */
export class HSMAwsCloudHsmConfigItem extends MessageItem<IHSMAwsCloudHsmConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HSMAwsCloudHsm,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { hsm_ip: hsmIps } = this.config;

        if (!hsmIps) {
            this.config.hsm_ip_wrappers = [];
        } else {
            this.config.hsm_ip_wrappers = hsmIps.map(item => {
                return {
                    value: item,
                };
            });
        }
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { hsm_ip_wrappers: hsmIpWrappers } = this.config;

        if (!isEmpty(hsmIpWrappers)) {
            this.config.hsm_ip = pluck(hsmIpWrappers, 'value');
        } else {
            delete this.config.hsm_ip;
        }

        delete this.config.hsm_ip_wrappers;
    }

    /**
     * Getter for HSM IP string objects.
     */
    public get hsmIpStrings(): TStringRow[] {
        return this.config.hsm_ip_wrappers;
    }

    /**
     * Handles add new empty row to HSM IP strings grid.
     */
    public addHsmIpString(): void {
        this.hsmIpStrings.push({
            value: '',
        });
    }

    /**
     * Handles remove operation on HSM IP string objects.
     */
    public removeHsmIpStrings(hsmIpStrings: TStringRow[]): void {
        hsmIpStrings.forEach(item => {
            const index = this.hsmIpStrings.indexOf(item);

            this.hsmIpStrings.splice(index, 1);
        });
    }
}
