/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name PoolServerInventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     New API to load servers across multiple pools. Filters as well as pagination params are
 *     applied for the pools, but their servers are returned.
 *     all_se, include, step and limit params affect server output.
 */
angular.module('aviApp').factory('PoolServerInventoryCollDataSource', [
'OnePoolServerInventoryCollDataSource',
function(OnePoolServerInventoryCollDataSource) {
    return class PoolServerInventoryCollDataSource extends OnePoolServerInventoryCollDataSource {
        constructor(args) {
            super(args);
            this.hasPagination = true;//pagination applied over pools, not servers =)
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_(this.params_);

            params['objectName_'] = ['pool-inventory', 'server'];

            return params;
        }
    };
}]);
