/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'hs-insights';
const prefix = `${moduleName}.${componentName}`;

export const topFactorsAffectingHealthScoreMessage = `${prefix}.topFactorsAffectingHealthScoreMessage`;

export const ENGLISH = {
    [topFactorsAffectingHealthScoreMessage]: 'Top Factors Affecting Health Score',
};
