/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { HSM_GROUP_DOWNLOAD_CERTIFICATE_MODAL_SERVICE_TOKEN }
    from '../../../downgrade-services.tokens';
import * as l10n from './HSMGroupListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('HSMGroupListController', [
'$scope',
'HSMGroupCollection',
'CRUDGridConfig',
'l10nService',
'schemaService',
HSM_GROUP_DOWNLOAD_CERTIFICATE_MODAL_SERVICE_TOKEN,
function(
    $scope,
    HSMGroupCollection,
    CRUDGridConfig,
    l10nService,
    schemaService,
    hsmGroupDownloadCertificateModalService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    const hsmGroupCollection = new HSMGroupCollection();

    this.gridConfig = new CRUDGridConfig();
    this.gridConfig.collection = hsmGroupCollection;

    const { objectName } = this.gridConfig.collection;

    this.gridConfig.id = `${objectName}-list-page`;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }, {
        name: 'type',
        title: l10nService.getMessage(l10nKeys.columnTitleType),
        template: '{{row.data.config.hsm.config.type | enum: "HSM_TYPE_" }}',
    }];

    this.gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnEdit),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }, {
        title: l10nService.getMessage(l10nKeys.actionBtnOpenWindowForClientCertDownload),
        class: 'icon-lostlog',
        do: ({ data }) => {
            const nodeInfo = data.config.hsm.safenetLuna.clientInfoList;

            hsmGroupDownloadCertificateModalService
                .openModal(data.config.name, nodeInfo);
        },
        hidden(row) {
            return row.data.config.hsm.config.type !== 'HSM_TYPE_SAFENET_LUNA';
        },
    }];

    const hsmTypeEnumValues = schemaService.getEnumValues('HSMType');

    this.gridConfig.createActions = hsmTypeEnumValues.map(({ label, value }) => ({
        label,
        onClick: () => {
            hsmGroupCollection.setDefaultItemConfigProps({
                hsm: {
                    type: value,
                },
            });

            hsmGroupCollection.create();
        },
    }));
}]);
