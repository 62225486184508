/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './vrf-context-setter.less';

const componentName = 'vrf-context-setter';

class VrfContextSetterController {
    constructor($elem, VRFContextCollection) {
        this.$elem = $elem;
        this.VRFContextCollection = VRFContextCollection;

        /**
         * Shows spinner while initial collection load is happening.
         * @type {boolean}
         */
        this.isReady = false;

        /**
         * Inner ngModel value.
         * @type {string|undefined}
         */
        this.vrfContextId = undefined;
    }

    $onInit() {
        this.$elem.addClass(componentName);

        this.vrfContextCollection = new this.VRFContextCollection({
            sortBy: 'name',
            params: {
                'cloud_ref.uuid': this.cloudId,
            },
        });

        this.ngModelCtrl.$render = this.updateViewValue_.bind(this);

        this.vrfContextCollection.load()
            .then(() => this.onInitialCollectionLoad_())
            .finally(() => this.isReady = true);
    }

    $onDestroy() {
        this.vrfContextCollection.destroy();
    }

    /**
     * Updates ngModel viewValue.
     * @protected
     */
    updateViewValue_() {
        this.vrfContextId = this.ngModelCtrl.$modelValue;
    }

    /**
     * Updates ngModel modelValue.
     * @param {VRFContext|undefined} vrfContext
     */
    updateModelValue(vrfContext) {
        this.ngModelCtrl.$setViewValue(vrfContext ? vrfContext.getRef() : undefined);
    }

    /**
     * Automatically selects first VRFContext in the list.
     * Calls autoSubmit if only one is present in the system.
     * @protected
     */
    onInitialCollectionLoad_() {
        const { vrfContextCollection } = this;

        //selecting first in the list
        const [vrfContext] = vrfContextCollection.items;

        this.updateModelValue(vrfContext);
        this.updateViewValue_();

        // fast forward
        if (vrfContextCollection.getTotalNumberOfItems() === 1) {
            this.autoSubmit();
        }
    }
}

VrfContextSetterController.$inject = [
    '$element',
    'VRFContextCollection',
];

/**
 * @ngdoc component
 * @name vrfContextSetter
 * @desc
 *
 *     Dropdown to select vrfContext of the cloud id passed. Extends ngModel controller.
 *
 * @param {string} cloudId
 * @param {Function=} autoSubmit - If set will call it when only one vrf context was
 *     found in the system.
 * @author Alex Malitsky
 */
angular.module('aviApp').component('vrfContextSetter', {
    bindings: {
        cloudId: '<',
        autoSubmit: '&?',
    },
    require: {
        ngModelCtrl: 'ngModel',
    },
    controller: VrfContextSetterController,
    templateUrl: 'src/components/common/vrf-context-setter/vrf-context-setter.html',
});
