/**
 * @module ServiceEngineModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Sarthak Kapoor
 */

/**
 * Token for SEGroup Collection.
 */
export const SE_GROUP_COLLECTION_TOKEN = 'SEGroupCollection';
