/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AviPermissionResource, ICustomIpamDnsProfile } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import { CustomIpamDnsProfileModalComponent } from 'ng/modules/ipam';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../custom-ipam-dns-profile.l10n';

interface ICustomDnsProfileData {
    config: ICustomIpamDnsProfile;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const customIpamDnsProfileToken = 'CustomIpamDnsProfile';

/**
 * @description CustomIpamDnsProfile Item class.
 *
 * @author Aravindh Nagarajan
 */
export class CustomIpamDnsProfile extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: ICustomDnsProfileData;
    public getConfig: () => ICustomIpamDnsProfile;
    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectType: 'CustomIpamDnsProfile',
            windowElement: CustomIpamDnsProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CUSTOMIPAMDNSPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for script_params.
     */
    public get scriptParams(): RepeatedMessageItem<MessageItem> {
        const { script_params: scriptParams } = this.config;

        return scriptParams as RepeatedMessageItem<MessageItem>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.customIpamDnsProfileModalBreadcrumbTitle);
    }
}

Object.assign(CustomIpamDnsProfile.prototype, {
    objectName: 'customipamdnsprofile',
});
