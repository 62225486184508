/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-group-deployment-policy-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleTargetTrafficRatio = `${prefix}.columnTitleTargetTrafficRatio`;
export const columnTitleDuration = `${prefix}.columnTitleDuration`;
export const columnTitleRamupRatio = `${prefix}.columnTitleRamupRatio`;
export const columnTitleRules = `${prefix}.columnTitleRules`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleTargetTrafficRatio]: 'Target Traffic Ratio',
    [columnTitleDuration]: 'Duration',
    [columnTitleRamupRatio]: 'Ramup Ratio',
    [columnTitleRules]: 'Rules',
};
