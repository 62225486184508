/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './CreateVlanInterfaceController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc Controller
 * @name  CreateVlanInterfaceController
 * @description
 * Controller to create/edit VLAN interfaces by manually adding/modifying VLAN object to the list
 * of network interfaces.
 */
angular.module('aviApp').controller('CreateVlanInterfaceController', [
'$scope', 'Regex', 'l10nService', function($scope, Regex, l10nService) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    const vm = this;

    // Array of parent interface names as strings used for dropdown.
    vm.parentInterfaces = null;
    vm.errors = '';
    vm.Regex = Regex;

    vm.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.init = function() {
        vm.config = angular.copy($scope.vlan.config);
        vm.parentInterfaces = $scope.vlan.getParentInterfaceListNames();
        vm.parentInterface = $scope.vlan.parentInterface;
        vm.VIPVnicNetworks = $scope.vlan.VIPVnicNetworks;
    };

    /**
     * VLAN name is composed of <parent-interface>.<vlan-id>. Triggers on ng-change.
     */
    vm.setInterfaceName = function() {
        vm.config.if_name = `${_.isUndefined(vm.parentInterface) ? '' : vm.parentInterface}.${
            _.isUndefined(vm.config.vlan_id) ? '' : vm.config.vlan_id}`;
    };

    /**
     * Saves VLAN object.
     */
    vm.save = function() {
        vm.errors = '';

        if ($scope.vlan.vlanAlreadyExists(vm.parentInterface, vm.config.if_name)) {
            vm.errors = l10nService.getMessage(l10nKeys.duplicateVlanErrorMessage);

            return;
        }

        if ($scope.vlan.mode === 'create') {
            $scope.vlan.addNewVlan(vm.config, vm.parentInterface);
        } else {
            $scope.vlan.editVlan(vm.config, vm.parentInterface);
        }

        $scope.closeVlan();
    };

    /**
     * Deletes static network interface by index.
     * @param {number} index
     */
    vm.removeIpPrefix = function(index) {
        const { config } = vm;
        const { vnic_networks: vnicNetworks } = config;

        if (Array.isArray(vnicNetworks)) {
            vnicNetworks.splice(index, 1);
        }
    };

    /**
     * Adds empty static network interface.
     */
    vm.addIpPrefix = function() {
        const { config } = vm;
        const { vnic_networks: vnicNetworks } = config;

        if (Array.isArray(vnicNetworks)) {
            vnicNetworks.push({
                mode: 'STATIC',
                ip: {
                    ip_addr: {
                        addr: '0.0.0.0',
                        type: 'V4',
                    },
                    mask: 0,
                },
            });
        }
    };
}]);
