/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'error-page';
const componentName = 'error-page-body-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const htmlContentInputLabel = `${prefix}.htmlContentInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const htmlContentInputPlaceholder = `${prefix}.htmlContentInputPlaceholder`;
export const generalHeader = `${prefix}.generalHeader`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Error Page Body: {0}',
    [modalTitleNew]: 'New Error Page Body: {0}',
    [nameInputLabel]: 'Name',
    [htmlContentInputLabel]: 'HTML Content',
    [nameInputPlaceholder]: 'Enter Name',
    [htmlContentInputPlaceholder]: 'Enter HTML Content',
    [generalHeader]: 'General',
};
