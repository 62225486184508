/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'bot-class-matcher';
const prefix = `${moduleName}.${componentName}`;

export const classTypesLabel = `${prefix}.classTypesLabel`;
export const selectClientTypePlaceholder = `${prefix}.selectClassTypeLabel`;

export const ENGLISH = {
    [classTypesLabel]: 'Class Types',
    [selectClientTypePlaceholder]: 'Select Class Types',
};
