/**
 * @module DataModelModule
 */

/* eslint-disable no-underscore-dangle */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    copy,
    IDeferred,
    IHttpRequestConfigHeaders,
    IHttpResponse,
    IPromise,
    IWindowService,
} from 'angular';

import {
    compact,
    each,
    extend,
    isObject,
    isUndefined,
    map,
    omit,
    throttle,
} from 'underscore';

import {
    Auth,
    AviModalService,
    DefaultValues,
    StringService,
    SystemInfoService,
    TComponentBindings,
} from 'ajs/modules/core/services';

import { Component, Type } from '@angular/core';
import { diff as deepDiff } from 'deep-diff';
import {
    AviAlertService,
    DevLoggerService,
} from 'ng/modules/core';
import { IAppState } from 'ajs/js/services/appStates.types';
import { StateService, TransitionPromise } from '@uirouter/core';
import { ConfigItem } from 'ajs/js/services/ConfigItem';
import { IItemMetricTuple } from '../data-model.types';
import { Collection } from './collection.factory';
import { Constructor } from '../../../declarations/globals.d';
import {
    Base,
    IBaseArgs,
    IBaseEvent,
} from './base.factory';

/**
 * Interface for Item arguments.
 */
export interface IItemArgs<T> extends IBaseArgs {
    collection?: Collection;
    data?: IItemData<T>;
    defaultConfig?: Partial<T>;
    events?: Record<string, IBaseEvent>;
    id?: string;
    loadOnEdit?: boolean;
    objectName?: string;
    opener?: Item<T>;
    params?: Record<string, any>;
    permissionName?: string;
    restrictEditOnEssentialLicense?: boolean;
    windowElement?: string | Type<Component>;
}

export interface IListResponse<T> {
    count?: number;
    results?: T[];
}

/**
 * Interface for Item params.
 */
interface IItemLoadParams {
    [param: string]: any;
    include_name?: boolean;
    headers_?: IHttpRequestConfigHeaders;
}

/**
 * Interface for Item#data.
 * T is the Item config.
 */
export interface IItemData<T> {
    [key: string]: any;
    config: T;
}

interface IItemResponseWithRuntime<T> {
    [key: string]: any;
    config: T;
    runtime?: any;
    alert?: any;
}

/**
 * Generic interface for Item#data#config.
 */
export interface IItemGenericConfig {
    name?: string;
    url?: string;
    tenant_ref?: string;
    _last_modified?: string;
}

export enum PatchAction {
    ADD = 'add',
    REPLACE = 'replace',
    DELETE = 'delete',
}

type TPatchRequestPayload<T> = {
    [key in PatchAction]?: Partial<T>
};

// FIXME on Item create transformAfterLoad should be called
// FIXME transformAfterSave should use transformAfterLoad
/**
 * @description
 *      Abstract item service, represents single object with properties, provides a way to load
 *      and save the data.
 *      The object can be instantiated as follows:
 *
 *      ````javascript
 *              new Item({
 *              id: 'vs-0578abgh',
 *              objectName: 'virtualservice',
 *               data: {},
 *              bind: {},
 *           })
 *      ````
 *      Events list:
 *      onBeforeLoad: Is called before preloading the object
 *      itemLoadSuccess: Is called when the object preload was successful (response is passed as
 *      a parameter).
 *      itemLoadFail: Is called when object preload failed (response is passed as a parameter)
 *      itemBeforeCreate: Is called before going to create state
 *      itemBeforeEdit: Is called before going to edit state
 *      itemBeforeSave: Is called before sending save request
 *      itemSaveSuccess: Is called when save was successful (response is passed as a parameter)
 *      itemSave: Is called after save was completed or failed (response is passed as a
 *          parameter)
 *      itemCreate: Called when item was created
 *      itemUpdate: Called when item was updated
 *      itemSaveFail: Is called when save failed (response is passed as a parameter)
 *      itemChange: Is called when item.set() was executed to update the data (updates are
 *          passed as a parameter).
 *      itemBeforeDrop: Is called before delete request sent
 *      itemDrop: Is called after delete was completed or failed (response is passed as a
 *          parameter)
 *      itemDropSuccess: Is called when delete was successful (response is passed as a
 *          parameter)
 *      itemDropFail: Is called after delete fail (response is passed as a parameter)
 *      itemConfigUpdate: Triggered after saving (a config of) an Item.
 */
export class Item<T extends IItemGenericConfig = any> extends Base {
    /**
     * Keeps id (slug) of the current object.
     */
    public id: string | null;
    /**
     * Keeps lower-case object name like 'applicationprofile' used to build the url for
     * making calls and by permission checks.
     */
    public objectName: string;
    /**
     * DOM element of the window that creates/edits the current object
     */
    public windowElement: string | Type<Component>;
    /**
     * Loading indicator and block for some ongoing API calls.
     */
    public busy = false;
    /**
     * Url parameters (key - value pairs), will be append to API URL as 'key=value&'.
     */
    public params: IItemLoadParams;
    /**
     * Actual data of the object. Contains config, runtime, metrics data.
     */
    public data: IItemData<T>;
    /**
     * In case if backend returned an error, it's going to be here.
     */
    public errors: any = null;
    /**
     * The flag that determines either the Item should be reloaded before opening
     * an edit Modal window.
     */
    public loadOnEdit: boolean;
    /**
     * Name of the permission associated with the Item, ex. PERMISSION_VIRTUALSERVICE or
     * PERMISSION_POOL. Used to determine, for example, if the user has permissions
     * to edit or delete the Item.
     */
    public permissionName_: string;
    /**
     * Default Item's configuration coming from protobuf.
     */
    public defaultConfig_: Partial<T>;
    /**
     * Name of Item's details abstract UI state.
     */
    public detailsStateName_: string;
    /**
     * Flag to restrict edit/create of Item on Essential License tier.
     */
    public restrictEditOnEssentialLicense_: boolean;

    /**
     * Copy of Item#data.config.
     */
    protected backup: T;
    /**
     * Collection instance of the Item.
     * undefined, If its a standalone Item.
     */
    protected collection: Collection;
    /**
     * Opener of this Item (used in Item create flow).
     */
    protected opener: Item<T>;
    /**
     * StringService instance.
     */
    protected readonly stringService: StringService;
    /**
     * DevLoggerService instance.
     */
    protected readonly devLoggerService: DevLoggerService;
    /**
     * AviAlertService instance.
     */
    private readonly aviAlertService: AviAlertService;
    /**
     * DefaultValues instance.
     */
    private readonly defaultValues: DefaultValues;
    /**
     * SystemInfoService instance.
     */
    private readonly systemInfoService: SystemInfoService;
    /**
     * The promise that is used to catch save or edit operation through the
     * Modal window.
     */
    private modalPromise: IDeferred<any> | null = null;
    /**
     * Flag to track modal loading/opening state.
     * True if Item's create/edit modal is being opened.
     */
    private loadingModal: boolean;

    constructor(oArgs = {} as any as IItemArgs<T>) {
        super(oArgs);

        // Dependencies
        this.aviAlertService = this.getAjsDependency_('aviAlertService');
        this.defaultValues = this.getAjsDependency_('defaultValues');
        this.stringService = this.getAjsDependency_('stringService');
        this.systemInfoService = this.getAjsDependency_('systemInfoService');
        this.devLoggerService = this.getAjsDependency_('devLoggerService');

        // Initialize
        this.objectName = oArgs.objectName || this.objectName;
        this.params = oArgs.params || copy(this.params);
        this.id = oArgs.id || this.id;

        this.windowElement = oArgs.windowElement || this.windowElement;
        this.collection = oArgs.collection || this.collection;
        this.opener = oArgs.opener || this.opener;
        this.data = oArgs.data || this.data || {} as any as IItemData<T>;

        this.loadOnEdit = !isUndefined(oArgs.loadOnEdit) ?
            oArgs.loadOnEdit : this.loadOnEdit;

        this.permissionName_ = oArgs.permissionName ||
            this.collection && this.collection.permissionName_ || '';

        if (this.data && !this.id && oArgs) {
            this.id = this.getIdFromData(oArgs.data);
        }

        // If object name is still missing then try to get it somewhere
        if (!this.objectName) {
            if (this.opener) {
                this.objectName = this.opener.objectName;
            } else if (this.collection) {
                this.objectName = this.collection.objectName_;
            }
        }

        if (!this.objectName) {
            const errMsg = "Can't create item wo objectName set";

            this.devLoggerService.warn(errMsg, this.constructor, oArgs);
        }

        if (oArgs.defaultConfig) {
            this.defaultConfig_ = copy(oArgs.defaultConfig);
        }

        if (!this.defaultConfig_) {
            this.setDefaultConfig_();
        }

        this.restrictEditOnEssentialLicense_ = !isUndefined(oArgs.restrictEditOnEssentialLicense) ?
            oArgs.restrictEditOnEssentialLicense : this.restrictEditOnEssentialLicense_;

        // throttle to avoid double click on edit button
        // TODO be aware of ongoing load request not to initialize it till we have finished
        // TODO the first one
        this.edit = throttle(this.edit.bind(this), 999, { trailing: false });
    }

    /**
     * Parses the list of instances and calls dataToSave on each instance, then filters out
     * undefined results. If the resulting list is empty, return undefined as the final
     * output. Used in optional Repeated lists of objects.
     */
    public static filterRepeatedInstances<T>(
        instances: Array<Item<T> | ConfigItem>,
    ): Array<T | any> {
        let dataToSave =
            compact(instances.map((instance: Item<T>) => instance.dataToSave()));

        if (!dataToSave.length) {
            dataToSave = undefined;
        }

        return dataToSave;
    }

    /**
     * Returns config object of data object.
     */
    public getConfig(): T | null {
        return this.data && this.data.config || null;
    }

    /**
     * Returns Item's name. Empty string when not ready.
     */
    public getName(): string {
        const config = this.getConfig();

        return config && (config.name || config.url && this.stringService.name(config.url)) || '';
    }

    /**
     * Returns Item's ref/url. Empty if not ready.
     */
    public getRef(): string {
        const config = this.getConfig();

        return config && config.url || '';
    }

    /**
     * Return item's type name.
     */
    public getItemType(): string {
        return this.objectName;
    }

    /**
     * Returns busy status of an Item.
     */
    public isBusy(): boolean {
        return this.busy;
    }

    /**
     * Returns true when item has config (presumably was loaded).
     */
    public hasConfig(): boolean {
        return Boolean(this.getConfig());
    }

    /**
     * Returns copied object with default Item configuration.
     */
    public getDefaultConfig(): Partial<T> | null {
        return this.getDefaultConfig_();
    }

    /**
     * Returns tenant ref this object belongs to. Returns empty string if not set/present.
     */
    public getTenantRef(): string {
        return this.getConfig().tenant_ref || '';
    }

    /**
     * Returns tenant id this object belongs to. Returns empty string if not set/present.
     */
    public getTenantId(): string {
        const tenantRef = this.getTenantRef();

        return this.stringService.slug(tenantRef);
    }

    /**
     * Returns a copy of the data.
     */
    public getDataCopy(): IItemData<T> {
        return copy(this.data);
    }

    /**
     * Loads the Item's data from the back-end.
     * @param fields - Array of fields (types of data) to be loaded.
     * @param ignoreErrors - Don't show error messages when API fails.
     */
    public load(
        fields?: string[],
        ignoreErrors = false,
    ): IPromise<IHttpResponse<T>> {
        const deferred = this.$q.defer<IHttpResponse<T>>();

        if (this.id) {
            this.trigger('onBeforeLoad');
            this.busy = true;
            this.loadRequest(fields).then((rsps: IHttpResponse<T>) => {
                this.transformAfterLoad(rsps);
                this.busy = false;
                this.trigger('itemLoad itemLoadSuccess', rsps);
                deferred.resolve(rsps);
            }, (errors: any) => {
                this.errors = errors.data;
                this.busy = false;

                if (!this.isDestroyed()) {
                    this.trigger('itemLoad itemLoadFail', errors.data);
                }

                if (!ignoreErrors) {
                    this.aviAlertService.throw(errors.data);
                }

                deferred.reject(errors);
            });
        } else {
            const errMsg = `Can't load since Item.id for the "${this.objectName}"
                Item is missing`;

            console.error(errMsg);
            deferred.reject({ error: errMsg });
        }

        return deferred.promise;
    }

    /**
     * This function should return the object that will be sent to server on save.
     * If the object requires cleaning before save then this is a place to do that.
     * @returns Should return configuration data that is ready for saving.
     */
    public dataToSave(): T {
        return copy(this.getConfig());
    }

    /**
     * Saves the object. Send reqests, makes modifications on a config object, notifies
     * Collection and triggers events.
     * @param appendToCollection - True by default.
     */
    public save(appendToCollection = true): IPromise<IHttpResponse<T>> {
        const action = this.id ? 'update' : 'create';
        const deferred = this.$q.defer<IHttpResponse<T>>();

        this.errors = null;

        if (this.busy) {
            deferred.reject(this.isBusyError());
        } else {
            this.trigger('itemBeforeSave');
            this.busy = true;

            this.saveRequest().then((rsp: IHttpResponse<T>) => {
                const transformedResponse = this.transformDataAfterSave(rsp);

                this.set(transformedResponse);
                this.busy = false;

                // data === null after Item was destroyed
                if (this.opener && this.opener.data) {
                    this.opener.set(transformedResponse);
                    this.opener.id = this.getIdFromData();
                }

                if (!this.id) {
                    this.id = this.getIdFromData();
                }

                if (this.collection) {
                    if (action === 'create' && appendToCollection) {
                        const item = this.opener || this;

                        this.collection.append(item);
                    }
                }

                this.emitOnSaveEvents_('save-success');
                this.emitOnSaveEvents_(action);

                deferred.resolve(rsp);
            }, (errors: any) => {
                this.errors = errors.data;
                this.busy = false;

                this.emitOnSaveEvents_('save-fail', errors);
                deferred.reject(errors);
            });
        }

        return deferred.promise;
    }

    /**
     * Method to apply config changes wo opening modal window. Might be convenient for table
     * view operations (actions).
     */
    public patch(payload: TPatchRequestPayload<T>): IPromise<IHttpResponse<T>> {
        if (this.busy) {
            return this.$q.reject(this.isBusyError());
        } else if (this.id) {
            this.busy = true;
            this.errors = null;
            this.trigger('itemBeforeSave');

            return this.patchRequest_(payload)
                .then(
                    this.patchResponseHandler_.bind(this),
                    this.patchErrorHandler_.bind(this),
                )
                .finally(() => this.busy = false);
        } else {
            return this.$q.reject('Can\'t patch wo Item id');
        }
    }

    /**
     * Deletes the item by appropriate API call to the back-end.
     * @param force - Special back-end flag to remove some related objects. As
     *     for now used by {@link VirtualService} only.
     * @param params - Optional parameters for deletion request.
     * @param showAlert - Show Alert dialog if delete fails.
     * TODO no forced collection reload option
     * TODO call destroy on/after deletion
     */
    public drop(
        force = false,
        params = {},
        showAlert = true,
    ): IPromise<IHttpResponse<T>> {
        let promise;

        if (this.isDroppable()) {
            this.trigger('itemBeforeDrop');

            const url = this.getUrlToDelete_(force);

            const method = this.getHttpMethodToDelete_(force);

            promise = this.request(method, url)
                .then(rsp => {
                    if (this.collection) {
                        this.collection.onItemDrop(this.getIdFromData());
                    }

                    this.trigger('itemDrop itemDropSuccess', rsp);
                    this.destroy();

                    return rsp;
                })
                .catch(errors => {
                    if (showAlert) {
                        this.aviAlertService.throw(errors.data);
                    }

                    if (this.collection) {
                        this.collection
                            .trigger('collectionItemDrop collectionItemDropFail');
                    }

                    this.trigger('itemDrop itemDropFail', errors.data);

                    return this.$q.reject(errors);
                });
        } else {
            promise = this.$q.reject({ error: 'Can not delete system default object' });
        }

        return promise;
    }

    /**
     * Loads an Item when needed, opens Modal window which resolves a promise when done.
     * @param windowElement - DOM selector, jQuery element
     *     of the window element or Component class.
     * @param params - All properties of this object will be passed into
     *     {@link AviModal.open Modal window} angular.scope.
     */
    // TODO Set a flag or keep promise unresolved to prevent following method calls
    // when we have modal opened
    public edit(
        windowElement?: string | Type<Component>,
        params = {} as any as Record<string, any>,
    ): IPromise<void> {
        windowElement = windowElement || this.windowElement;

        if (this.loadingModal) {
            return this.$q.reject(this.isBusyError());
        }

        const deferred = this.$q.defer<any>();

        // Cloning entire editable object
        // eslint-disable-next-line no-extra-parens
        const editable = new (this.constructor as any as Constructor<Item<T>>)({
            id: this.id,
            // FIXME: Should probably make copy of config, not whole data
            data: this.getDataCopy(),
            windowElement,
            opener: this,
            collection: this.collection,
            loadOnEdit: this.loadOnEdit,
        });

        // FIXME should be cloned through config: this.dataToSave() rather then config
        // reference

        params.editable = editable;
        editable.modalPromise = deferred;

        // Destroy editable once modal is closed (after save/cancel)
        editable.modalPromise.promise
            .finally(() => {
                editable.destroy();
            });

        this.loadingModal = true;

        // beforeEdit and modal components are using defaultValues
        const promises: Array<IPromise<any>> = [
            this.defaultValues.load(),
        ];

        // loads full config when we can
        if (this.id && this.loadOnEdit) {
            promises.push(editable.load());
        }

        this.$q.all(promises)
            .then(() => {
                editable.beforeEdit();
                editable.trigger('itemBeforeEdit');
                editable.setPristine();
                this.openModal(editable.windowElement, this.getModalParams_(params));
            })
            .catch(error => {
                // log the error in the console when opening modal, otherwise it would be swallowed
                // without the modal being rendered, which makes it difficult to debug
                this.devLoggerService.warn(error);

                return deferred.reject(error);
            })
            .finally(() => this.loadingModal = false);

        return deferred.promise;
    }

    /**
     * Wrapper for AviModal to allow for the modification of params/bindings.
     * @param windowElement - Modal ID.
     * @param params - Properties to be passed to the modal.
     */
    public openModal(
        windowElement: string | Type<Component>,
        params: TComponentBindings,
    ): Promise<void> {
        const aviModal: AviModalService = this.getAjsDependency_('AviModal');

        return aviModal.open(windowElement as string, params);
    }

    /**
     * Wrapper for AviModal.destroy to be overwritable.
     * @param windowElement - Window element to close. Defaults to this.windowElement.
     */
    public closeModal(windowElement = this.windowElement): Promise<void> {
        const aviModal: AviModalService = this.getAjsDependency_('AviModal');

        if (windowElement) {
            return aviModal.destroy(windowElement as string);
        }

        return Promise.resolve();
    }

    /**
     * Called by save button on the Modal window to save an Item.
     */
    public submit(): IPromise<void> {
        // windowElement must be defined here to avoid a potential race condition with removal of
        // Item inside collection. If defined after this.save(), the windowElement may not be the
        // correct one.
        const { windowElement } = this;

        return this.save().then(() => {
            Promise.resolve(this.closeModal(windowElement)).finally(() => {
                if (this.modalPromise) {
                    this.modalPromise.resolve(this.opener);

                    this.modalPromise = null;
                }

                this.backup = null;
            });
        });
    }

    /**
     * Closes create/edit Modal window (called by cancel button), raises confirm dialog if
     * object has changed.
     */
    public dismiss(silent: boolean): void {
        const $window: IWindowService = this.getAjsDependency_('$window');

        if (!silent && this.modified() && !$window.confirm('Dismiss changes?')) {
            return;
        }

        if (this.modalPromise) {
            this.modalPromise.reject('Cancelled by user.');
            this.modalPromise = null;
        }

        this.backup = null;
        this.closeModal();
    }

    /**
     * Checks if the current state of editable Item differs from the `backed-up` state.
     * @return true when different.
     */
    public modified(): boolean {
        if (this.data) {
            return Boolean(deepDiff(
                this.backup,
                this.dataToSave(),
                this.modifiedDiffFilter,
            ));
        }

        return false;
    }

    /**
     * Saves a copy of Item#data.config for future comparison.
     * //TODO replace by object-hash
     */
    public setPristine(): void {
        this.backup = this.dataToSave();
    }

    /**
     * Returns true if edit function is available for this object for the current user.
     */
    public isEditable(): boolean {
        const auth = this.getAjsDependency_('Auth');
        const { isEssentialsLicense } = this.systemInfoService;

        if (!this.windowElement || this.restrictEditOnEssentialLicense_ && isEssentialsLicense) {
            return false;
        }

        return this.isInTenant(auth.getTenantName()) && this.isAllowed();
    }

    /**
     * Returns true if an object is clonable.
     * This for system templates (analyticsProfile, applicationProfile, ...)
     * for non-admin tenants.
     *
     * Templates system_objects can not be edited from non-admin tenants,
     * in that case, we need to allow them to clone.
     *
     * @returns {boolean}
     */
    public isClonable(): boolean {
        const auth = this.getAjsDependency_('Auth');
        const { isEssentialsLicense } = this.systemInfoService;

        if (!this.windowElement || this.restrictEditOnEssentialLicense_ && isEssentialsLicense) {
            return false;
        }

        return auth.getTenantName() !== 'admin' && this.isProtected() && this.isAllowed();
    }

    /**
     * Checks if Item has write permission.
     */
    public isAllowed(): boolean {
        const Auth: Auth = this.getAjsDependency_('Auth');

        return Auth.isAllowed(
            this.permissionName_ || this.objectName.replace('-inventory', ''),
            'w',
        );
    }

    /**
     * Returns true if delete action is allowed for this object and current user.
     */
    public isDroppable(): boolean {
        const Auth: Auth = this.getAjsDependency_('Auth');

        return !this.isProtected() &&
            this.isInTenant(Auth.getTenantName()) && this.isAllowed();
    }

    /**
     * Returns true if this object is protected (cannot be deleted). Used for some
     * predefined object like profiles.
     */
    public isProtected(): boolean {
        return this.defaultValues.isSystemObject(
            this.getItemType().replace('-inventory', ''),
            this.id,
        );
    }

    /**
     * Checks if object is in specified tenant.
     */
    public isInTenant(tenantName: string): boolean {
        const config = this.getConfig();

        if (tenantName && config) {
            const isAllTenants = tenantName === '*';
            const isAdmin = tenantName === 'admin';
            const dataTenantRef = this.getTenantRef();
            const dataTenantName = this.stringService.name(dataTenantRef);

            return isAllTenants || isAdmin || tenantName === dataTenantName;
        }

        return false;
    }

    /**
     * Item may have more than one runtime data object. This is the way to get them.
     * @param params - Depends on implementation. Regular Item keeps only one runtime
     *     object and no params needs to be passed.
     * @returns Runtime object or undefined when not ready.
     */
    public getRuntimeData(
        params?: any,
    ): Record<string, any> | undefined {
        return this.data?.runtime;
    }

    /**
     * Returns alerts data object (received through inventory API).
     */
    public getAlertsData(): Record<string, any> | null {
        return this.data?.alert || null;
    }

    /**
     * Collection metric API needs a list of ids to be applied as filters.
     * ex: server requires pool and server ids and * can be used for vs.
     */
    public getMetricsTuple(): IItemMetricTuple {
        return {
            entity_uuid: this.id,
        };
    }

    /**
     * Some items want to change the usual timeframe settings based on certain config
     * properties.
     */
    public hasCustomTimeFrameSettings(): boolean {
        return false;
    }

    /**
     * Returns customized time frame object for the passed time frame id.
     * @returns Null is returned to use default settings.
     * @see {@link Timeframe}
     */
    public getCustomTimeFrameSettings(tfLabel: string): any {
        return null;
    }

    /**
     * Goes to details page of the current item.
     * @param innerState - Child state within {@link Item.detailsStateName_}.
     */
    public goToItemPage(innerState: string): TransitionPromise | IPromise<string> {
        const $state: StateService = this.getAjsDependency_('$state');

        if (this.detailsStateName_) {
            if (!innerState) {
                const { defaultChild } =
                    $state.get(this.detailsStateName_) as IAppState;

                innerState = defaultChild;
            }

            return $state.go(
                `${this.detailsStateName_}.${innerState}`,
                this.getDetailsPageStateParams_(innerState),
            );
        } else {
            const errMsg =
                `Details state address is not set for Item type "${this.objectName}"`;

            console.error(errMsg);

            return this.$q.reject(errMsg);
        }
    }

    /**
     * Updates the properties of Item.data.config. Legacy, use updateItemData instead.
     * @param configDataToUpdate - Object with properties to update.
     * @deprecated
     */
    public set(configDataToUpdate: T): this {
        if (configDataToUpdate && isObject(configDataToUpdate)) {
            this.updateItemData({ config: configDataToUpdate });
        }

        return this;
    }

    /**
     * Updates Item#data by received data. Manual angular.extend.
     * @param newData
     * @returns Always true for now.
     */
    public updateItemData(newData: IItemData<T>): boolean {
        if (isObject(newData)) {
            const { data } = this;

            each(newData, (val, key) => {
                data[key] = val;

                if (key === 'config') {
                    this.trigger('itemChange', val);
                }
            });
        }

        return true;
    }

    /**
     * Used to fulfill a rejected promise when trying to make a 'config' API call
     * while we already have an ongoing 'config' API call. Fakes a backend
     * provided error response.
     * @param errorMsg - Text to be added to the default message report.
     */
    public isBusyError(errorMsg = ''): { data: { error: string }} {
        console.warn('isBusyError of %s, id: %s. %s', this.objectName, this.id,
            errorMsg || '');

        return {
            data: {
                error: `${this.objectName} with id:${this.id} is "busy" now. ${
                    errorMsg || ''}`,
            },
        };
    }

    /**
     * Item's destructor. Cancels all ongoing API calls.
     * @override
     */
    public destroy(): boolean {
        const gotDestroyed = super.destroy();

        if (gotDestroyed) {
            this.data = null;
            this.backup = null;
            this.windowElement = '';
            this.params = null;
            this.collection = null;
            this.opener = null;
        }

        return gotDestroyed;
    }

    /**
     * Returns headers present in params, if they exist.
     */
    protected getLoadHeaders_(): IHttpRequestConfigHeaders | null {
        return this.params.headers_ || null;
    }

    /**
     * Returns copy of this.params with indicated params removed.
     * @param paramsToRemove - list of keys, to identify key/val pairs to remove
     * @returns filtered copy of this.params
     */
    protected filterOutParams_(paramsToRemove: string[]): Record<string, string> {
        return omit(this.params, paramsToRemove);
    }

    /**
     * Adds each param provided as argument to current params of Item instance.
     * @param newParams - new params to add
     */
    protected addParams(newParams: Record<string, string>): void {
        extend(this.params, newParams);
    }

    /**
     * Generating and returning the parameters needed to be added to the API URL in order to
     * load the Item.
     * @returns Array of parameters as 'key=value'.
     */
    protected getLoadParams(): string[] {
        const filteredParams = this.filterOutParams_(['headers_']);

        return map(filteredParams, (val, key) => `${key}=${val}`);
    }

    /**
     * Method for modifying params passed to the modal.
     * @param params - Properties to be passed to the modal.
     * @abstract
     */
    protected getModalParams_(params = {} as any as TComponentBindings): TComponentBindings {
        return params;
    }

    /**
     * Returns Item.id from Item's data object.
     * @returns {Item.id|null} - null for historical reasons.
     */
    protected getIdFromData_(data: IItemData<T>): string | null {
        // TODO: Need to use stringService
        // Right now collection.factory is calling this method from
        // item class's prototype with no context. method: collection.getItemIdFromData
        // ` this.itemClass_.prototype.getIdFromData_.call(undefined, ...data);`
        return data.config?.url ? data.config.url.slug() : null;
    }

    /**
     * Returns an Item.id. If it is set and no `data` is passed id will be returned right away.
     * If it is not yet set or data object is passed, Item.getIdFromData_ will be used to
     * figure out id from Item.data.
     * @param data - Item.data.
     */
    protected getIdFromData(data?: IItemData<T>): string | null {
        let res = null;

        if (!data && this.id) {
            res = this.id;
        } else {
            if (!data) {
                data = this.data;
            }

            res = this.getIdFromData_(data);
        }

        return res;
    }

    /**
     * Sets internal property based on defaults provided by Auth service.
     */
    protected setDefaultConfig_(): void {
        const objectName = this.getItemType().replace('-inventory', '');

        if (!objectName) {
            this.defaultConfig_ = null;

            return;
        }

        // TODO apply changes to defaults as collection does
        this.defaultConfig_ = this.defaultValues.getDefaultItemConfigByType(objectName);
    }

    /**
     * Returns a copied object with default Item configuration.
     */
    protected getDefaultConfig_(): Partial<T> | null {
        // if item was instantiated before defaultValues are loaded
        if (!this.defaultConfig_) {
            this.setDefaultConfig_();
        }

        return copy(this.defaultConfig_);
    }

    /**
     * Returns a API URI to save an object.
     */
    protected urlToSave(): string {
        return `/api/${this.objectName}${this.id ? `/${this.id}` : ''}?include_name`;
    }

    /**
     * Returns URL to delete an object.
     */
    protected getUrlToDelete_(force: boolean): string {
        return `/api/${this.objectName}/${this.id}${force ? '?force_delete' : ''}`;
    }

    /**
     * Returns HTTP Method to delete an Item.
     */
    protected getHttpMethodToDelete_(force: boolean): string {
        return 'DELETE';
    }

    /**
     * Sends a load request. Can be overridden to make a few calls on this phase.
     * @param fields - Array of fields (types of data) to be loaded.
     */
    protected loadRequest(
        fields: string[],
    ): IPromise<IHttpResponse<T | IItemResponseWithRuntime<T>>> {
        return this.loadConfig(fields);
    }

    /**
     * Used to prep data to be used as the payload, like changing the structure to use
     * the macro API for example.
     * @param dataToSave - Config data to save.
     * @returns Object to be used as the payload for the save request.
     */
    protected createSaveRequestPayload_(dataToSave: T): T {
        return dataToSave;
    }

    /**
     * Makes an actual API call to save an Item.
     */
    protected saveRequest(): IPromise<IHttpResponse<T>> {
        return this.request(this.id ? 'put' : 'post',
            this.urlToSave(),
            this.createSaveRequestPayload_(this.dataToSave()),
            null,
            'save');
    }

    /**
     * To update configuration we can use patch HTTP request type which will return whole
     * configuration of updated object.
     */
    protected patchRequest_(payload: TPatchRequestPayload<T>): IPromise<IHttpResponse<T>> {
        return this.request('patch', this.urlToSave(), payload, null, 'patch');
    }

    /**
     * Loads configuration object and puts the response into Item#data.config.
     */
    protected loadConfig(
        fields?: string[],
    ): IPromise<IHttpResponse<T | IItemResponseWithRuntime<T>>> {
        const headers = this.getLoadHeaders_();
        const params = this.getLoadParams();
        const paramStr = params.join('&');

        let url = `/api/${this.objectName}/${this.id}`;

        if (paramStr) {
            url += `?${paramStr}`;
        }

        this.cancelRequests('config');

        return this.request('get', url, undefined, headers, 'config')
            .then(this.onConfigLoad_.bind(this));
    }

    /**
     * For historical reasons we are saving loadConfig response to Item.data right away
     * before calling transformAfterLoad.
     */
    protected onConfigLoad_(
        rsp: IHttpResponse<T | IItemResponseWithRuntime<T>> | ng.IHttpResponse<IListResponse<T>>,
    ): IHttpResponse<T | IItemResponseWithRuntime<T>> | ng.IHttpResponse<IListResponse<T>> {
        const { data } = rsp;

        if ('config' in data && 'url' in data.config) {
            // inventory response
            this.updateItemData(data);
        } else {
            // pure config response
            this.updateItemData({ config: data } as any as IItemData<T>);
        }

        return rsp;
    }

    /**
     * HTTP API call response handler.
     */
    protected patchResponseHandler_(rsp: IHttpResponse<T>): IHttpResponse<T> {
        this.set(this.transformDataAfterSave(rsp));
        this.emitOnSaveEvents_('save-success');

        return rsp;
    }

    /**
     * Event handler for patch API call failure.
     */
    protected patchErrorHandler_(errRsp: any): IPromise<void> {
        this.errors = errRsp.data;
        this.emitOnSaveEvents_('save-fail', this.errors);
        this.aviAlertService.throw(errRsp.data);

        return this.$q.reject(errRsp.data);
    }

    /**
     * Gives an option to prepare Item#data.config before going to edit mode.
     * @abstract
     */
    protected beforeEdit(): void {}

    /**
     * Gives an option to do anything with this.data.whatever after load has finished.
     * @abstract
     */
    protected transformAfterLoad(
        rsp?: IHttpResponse<T | IItemResponseWithRuntime<T>>,
    ): void {}

    /**
     * Modifies the data after saving the Item and before putting it into the data.
     * @param rsp - Back-end response after the saving API call.
     * @returns Properties of this object will be put into this.data.config.
     */
    protected transformDataAfterSave(rsp: IHttpResponse<T>): T {
        // If response contains array of objects, the last object is the one that we need
        return rsp.data instanceof Array ? rsp.data[rsp.data.length - 1] : rsp.data;
    }

    /**
     * Returns an object with router params for Item's inner details state.
     * @abstract
     */
    protected getDetailsPageStateParams_(innerState: string): { id?: string | null } {
        return { id: this.id };
    }

    /**
     * Emit Item save events.
     */
    protected emitOnSaveEvents_(type: string, payload?: IHttpResponse<void>): void {
        function emitEvent(
            text: string,
            payload: Item<T> | IHttpResponse<void>,
        ): (entity: Item<T> | IHttpResponse<void>) => void {
            return function(entity: Item<T>) {
                entity.trigger(text, payload);
            };
        }

        const emitters = [this as Item<T>];

        // currently editable is working with Item.opener events object which can cause them to
        // fire twice
        if (this.opener && this.opener.events !== this.events) {
            emitters.push(this.opener);
        }

        switch (type) {
            case 'save-success':
                emitters.forEach(emitEvent('itemSave itemSaveSuccess', this));

                if (this.collection) {
                    this.collection.trigger('collItemSave collItemSaveSuccess', this);
                }

                break;

            case 'save-fail':
                emitters.forEach(emitEvent('itemSave itemSaveFail', payload));

                if (this.collection) {
                    this.collection.trigger('collItemSave collItemSaveFail', payload);
                }

                break;

            case 'create':
                emitters.forEach(emitEvent('itemCreate', this));

                if (this.collection) {
                    this.collection.trigger('collItemCreate', this);
                }

                break;

            case 'update':
                emitters.forEach(emitEvent('itemUpdate itemConfigUpdate', this));

                if (this.collection) {
                    this.collection.trigger('collItemConfigUpdate', this);
                }

                break;
        }
    }

    /**
     * Returns a parsed version of any payload taking into account the secretStubStr. If the
     * uuid exists and the payload password is equal to the secretStubStr, add the "uuid" to the
     * payload while removing "username" and "password".
     * This also covers the case where the user accidentally clears the password by clicking on
     * it. If the password is empty then the uuid is supplied in the payload.
     * @param payload - Object used for any POST request containing the "username" and
     *     "password" properties
     * @param key - Key to be used in the payload for the uuid. Defaults to 'uuid'.
     */
    protected getSecretStubPayload_(
        payload: Record<string, any>, key = 'uuid',
    ): Record<string, any> {
        const secretStubStr: string = this.getAjsDependency_('secretStubStr');

        if (this.id && (!payload.password || payload.password === secretStubStr)) {
            payload[key] = this.id;
            delete payload.username;
            delete payload.password;
        }

        return payload;
    }

    /**
     * Is used by JS DeepDiff.diff function as a prefilter for editable objects comparison at
     * the modal windows.
     * By default filters out AngularJS $$hashKey property only.
     * @param path - Path (array of properties names) to the current properties
     *    being compared. Is partially broken in deepDiff v1.7.
     * @param key - Property name of a compared value.
     * @return DeepDiff.diff won't go deeper or mark it as a difference when true
     *    is returned.
     */

    protected modifiedDiffFilter(path: string[], key: string): boolean {
        return key === '$$hashKey';
    }
}

Item.prototype.defaultConfig_ = null;
Item.prototype.objectName = '';
Item.prototype.detailsStateName_ = '';
Item.prototype.id = null;
Item.prototype.data = null;
Item.prototype.windowElement = null;
Item.prototype.loadOnEdit = true;
Item.prototype.restrictEditOnEssentialLicense_ = true;
Item.prototype.params = {
    include_name: true,
};

Item.ajsDependencies = [
    '$q',
    '$state',
    '$window',
    'Auth',
    'aviAlertService',
    'AviModal',
    'defaultValues',
    'secretStubStr',
    'stringService',
    'systemInfoService',
    'devLoggerService',
];
