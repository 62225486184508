/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'case-list';
const prefix = `${moduleName}.${componentName}`;

export const attachmentBtnLabel = `${prefix}.attachmentBtnLabel`;
export const editBtnLabel = `${prefix}.editBtnLabel`;
export const columnTitleDateOpened = `${prefix}.columnTitleDateOpened`;
export const columnTitleCaseNumber = `${prefix}.columnTitleCaseNumber`;
export const columnTitleSubject = `${prefix}.columnTitleSubject`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleLastUpdated = `${prefix}.columnTitleLastUpdated`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const registerNowBtnLabel = `${prefix}.registerNowBtnLabel`;
export const registerControllerPromptLabel = `${prefix}.registerControllerPromptLabel`;
export const pulseMustBeConnectedLabel = `${prefix}.pulseMustBeConnectedLabel`;
export const connectNowBtnLabel = `${prefix}.connectNowBtnLabel`;

export const ENGLISH = {
    [attachmentBtnLabel]: 'Attachment',
    [editBtnLabel]: 'Edit',
    [columnTitleDateOpened]: 'Date Opened',
    [columnTitleCaseNumber]: 'Case Number',
    [columnTitleSubject]: 'Subject',
    [columnTitleType]: 'Type',
    [columnTitleLastUpdated]: 'Last Updated',
    [columnTitleStatus]: 'Status',
    [registerControllerPromptLabel]: 'Register your controller and easily access your support tickets from inside Avi Vantage.',
    [registerNowBtnLabel]: 'Register Now.',
    [pulseMustBeConnectedLabel]: 'Pulse must be connected to access your support tickets from inside Avi Vantage.',
    [connectNowBtnLabel]: 'Connect Now.',
};
