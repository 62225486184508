/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-imported-information';
const prefix = `${moduleName}.${componentName}`;

export const commonNameInputLabel = `${prefix}.commonNameInputLabel`;
export const emailInputLabel = `${prefix}.emailInputLabel`;
export const organizationInputLabel = `${prefix}.organizationInputLabel`;
export const organizationUnitInputLabel = `${prefix}.organizationUnitInputLabel`;
export const algorithmInputLabel = `${prefix}.algorithmInputLabel`;
export const keySizeInputLabel = `${prefix}.keySizeInputLabel`;
export const validUntilInputLabel = `${prefix}.validUntilInputLabel`;
export const importedInformationHeader = `${prefix}.importedInformationHeader`;

export const ENGLISH = {
    [commonNameInputLabel]: 'Common Name',
    [emailInputLabel]: 'Email',
    [organizationInputLabel]: 'Organization',
    [organizationUnitInputLabel]: 'Organization Unit',
    [algorithmInputLabel]: 'Algorithm',
    [keySizeInputLabel]: 'Key Size',
    [validUntilInputLabel]: 'Valid Until',
    [importedInformationHeader]: 'Imported Information',
};
