/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'user-modal-password-settings';
const prefix = `${moduleName}.${componentName}`;

export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const useMyAccountToChangePasswordMessage = `${prefix}.useMyAccountToChangePasswordMessage`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const confirmPasswordInputLabel = `${prefix}.confirmPasswordInputLabel`;
export const generateBtnLabel = `${prefix}.generateBtnLabel`;

export const ENGLISH = {
    [passwordInputLabel]: 'Password',
    [useMyAccountToChangePasswordMessage]: 'Please use My Account to change Password',
    [passwordInputPlaceholder]: 'Password',
    [confirmPasswordInputLabel]: 'Confirm Password',
    [generateBtnLabel]: 'Generate',
};
