/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'saml-settings';
const prefix = `${moduleName}.${componentName}`;

export const samlIdentityProviderSettingsHeader = `${prefix}.samlIdentityProviderSettingsHeader`;
export const idpMetadataInputLabel = `${prefix}.idpMetadataInputLabel`;
export const samlServiceProviderSettingsHeader = `${prefix}.samlServiceProviderSettingsHeader`;
export const entityTypeInputLabel = `${prefix}.entityTypeInputLabel`;
export const organizationNameInputLabel = `${prefix}.organizationNameInputLabel`;
export const organizationDisplayNameInputLabel = `${prefix}.organizationDisplayNameInputLabel`;
export const organizationUrlInputLabel = `${prefix}.organizationUrlInputLabel`;
export const technicalContactNameInputLabel = `${prefix}.technicalContactNameInputLabel`;
export const technicalContactEmailInputLabel = `${prefix}.technicalContactEmailInputLabel`;

export const ENGLISH = {
    [samlIdentityProviderSettingsHeader]: 'SAML Identity Provider Settings',
    [idpMetadataInputLabel]: 'IDP Metadata',
    [samlServiceProviderSettingsHeader]: 'SAML Service Provider Settings',
    [entityTypeInputLabel]: 'Entity Type',
    [organizationNameInputLabel]: 'Organization Name',
    [organizationDisplayNameInputLabel]: 'Organization Display Name',
    [organizationUrlInputLabel]: 'Organization URL',
    [technicalContactNameInputLabel]: 'Technical Contact Name',
    [technicalContactEmailInputLabel]: 'Technical Contact Email',
};
