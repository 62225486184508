/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/components/dos-rate-limiter.less';
import * as l10n from './dosRateLimiterVS.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name dosRateLimiterVs
 * @restrict E
 * @description Rate Limiter inputs for the VS Modal. Can have only two there which are always
 * present on the screen. Since the layout is different and we have update different from Profile's
 * Modal properties need to have this shallow copy of {@link dosRateLimiter}.
 */
angular.module('aviApp').directive('dosRateLimiterVs', [
'dosRateLimiterService',
'dropDownUtils',
'schemaService',
'l10nService',
function(
    dosRateLimiterService,
    dropDownUtils,
    schemaService,
    l10nService,
) {
    l10nService.registerSourceBundles(dictionary);

    const link = function(scope) {
        scope.l10nKeys = l10nKeys;

        /**
        * @type {dosRateLimiterService}
        */
        scope.limiters = dosRateLimiterService;

        const { l4Actions } = dosRateLimiterService;

        /**
        * l4 Rateprofile action types.
        * @type {DropDownOption[]}
        */
        scope.l4RateLimitActionTypeOptions = l4Actions.map(({ id, label }) =>
            dropDownUtils.createOption(id, label));

        const { actions } = dosRateLimiterService;

        const l7RateProfileActionTypes = actions
            .filter(({ type }) => type === 10 || type === 11);

        scope.httpProtocolEnumKeys = schemaService.getEnumKeys('HTTPProtocol');

        /**
         * Dropdown options for the second (l7) rate limiter.
         * @type {DropDownOption[]}
         */
        scope.l7RateLimitActionTypeOptions = l7RateProfileActionTypes.map(({ id, label }) =>
            dropDownUtils.createOption(id, label));
    };

    return {
        restrict: 'E',
        scope: true,
        link,
        templateUrl: 'src/views/profile/dos-rate-limiter-vs.html',
    };
}]);
