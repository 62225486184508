/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/shared/components';
import { ICustomTag } from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { ICloud } from '../../../cloud.types';

import * as l10n from './custom-tags.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Custom Tags section for Azure and GCP Cloud.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'custom-tags',
    templateUrl: './custom-tags.component.html',
})
export class CustomTagsComponent implements AfterViewInit {
    /**
     * TemplateRef for classification dropdown field.
     */
    @ViewChild('keyTemplateRef')
    public keyTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for value field in case User Defined Bot is selected.
     */
    @ViewChild('valueTemplateRef')
    public valueTemplateRef: TemplateRef<HTMLElement>;

    /**
     * CustomTags input passed from Cloud modal.
     */
    @Input()
    public editable: ICloud;

    /**
     * DataGrid config for Tags.
     */
    public tagsGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Sets the Grid config.
     */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            keyTemplateRef,
            valueTemplateRef,
        } = this;

        this.tagsGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleKey),
                    id: 'key',
                    templateRef: keyTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleValue),
                    id: 'value',
                    templateRef: valueTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeActionLabel),
                onClick: (customTags: ICustomTag[]) => {
                    customTags.forEach((customTag: ICustomTag) => {
                        this.removeCustomTag(customTag);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'trash',
                onClick: (customTag: ICustomTag) => {
                    this.removeCustomTag(customTag);
                },
            }],
        };
    }

    /**
     * Returns the custom tags object.
     */
    public get customTags(): RepeatedMessageItem<MessageItem<ICustomTag>> {
        return this.editable.config.custom_tags;
    }

    /**
     * Triggered on click of Add button.
     */
    public addCustomTags(): void {
        this.editable.addCustomTagRow();
    }

    /**
     * Triggered when delete action is selected.
     */
    public removeCustomTag(customTag: ICustomTag): void {
        this.editable.deleteCustomTagRow(customTag);
    }
}
