/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    axisBottom,
    axisTop,
    axisLeft,
    axisRight,
    timeFormat as d3TimeFormat,
    select as d3Select,
} from 'd3v6';

const d3 = {
    axisBottom,
    axisTop,
    axisLeft,
    axisRight,
    timeFormat: d3TimeFormat,
    select: d3Select,
};

angular.module('charts').factory('Axis', ['chartUtils', function(chartUtils) {
    /**
     * Axis class to easily create and update axis elements.
     */
    class Axis {
        /**
         * Creates axis element.
         * @returns {d3.Selection}
         */
        static createAxis() {
            const axisSel = d3.select(chartUtils.createElement());

            axisSel.attr('class', 'chart-axis');

            return axisSel;
        }

        /**
         * Creates timestamp text label element.
         * @returns {d3.Selection}
         */
        static createTimestampText() {
            const tsSel = d3.select(chartUtils.createElement());

            tsSel
                .attr('class', 'axis-timestamp')
                .append('text')
                .attr('class', 'axis-timestamp-text');

            return tsSel;
        }

        /**
         * Creates timestamp circle element.
         * @returns {d3.Selection}
         */
        static createTimestampCircle() {
            const circle = d3.select(chartUtils.createElement());

            circle
                .attr('class', 'axis-timestamp-circle')
                .append('circle');

            return circle;
        }

        /**
         * @param {Function} scale
         * @param {string} orient
         */
        constructor(scale, orient) {
            /**
             * D3 scale function.
             * @type {Function}
             */
            this.scale = scale;

            /** @type {string} */
            this.orient = orient;

            /** @type {Function} */
            this.timeFormat = d3.timeFormat('%H:%M');

            /**
             * D3 axis function.
             * @type {Function}
             */
            this.axis = this.getAxis()(this.scale);

            /**
             * Container element.
             * @type {d3.Selection}
             */
            this.element = d3.select(chartUtils.createElement());

            /**
             * D3 selection for axis element.
             * @type {d3.Selection}
             */
            this.axisSel = Axis.createAxis();

            /** @type {d3.Selection} */
            this.tsCont = d3.select(chartUtils.createElement());

            /** @type {d3.Selection} */
            this.textSel = Axis.createTimestampText();

            /** @type {d3.Selection} */
            this.circleSel = Axis.createTimestampCircle();

            const elNode = this.element.node();

            elNode.appendChild(this.axisSel.node());
            elNode.appendChild(this.tsCont.node());

            const tsNode = this.tsCont.node();

            tsNode.appendChild(this.textSel.node());
            tsNode.appendChild(this.circleSel.node());

            this.hideSync();
        }

        /**
         * Returns D3 Axis function based on orientation.
         * @return {Function}
         */
        getAxis() {
            switch (this.orient) {
                case Axis.TOP:
                    return d3.axisTop;
                case Axis.BOTTOM:
                    return d3.axisBottom;
                case Axis.LEFT:
                    return d3.axisLeft;
                case Axis.RIGHT:
                    return d3.axisRight;
            }
        }

        /**
         * Changes axis position relative to its parent element.
         * @param {number} x
         * @param {number} y
         */
        position(x, y) {
            this.element.attr('transform', `translate(${x}, ${y})`);
        }

        /**
         * Updates D3 Scale function.
         * @param {Function} scale
         */
        updateScale(scale) {
            this.scale = scale;
            this.axis.scale(scale);
            this.axisSel.call(this.axis);
        }

        /**
         * Attaches axis to parent element.
         * @param {Element} parent
         * @returns {Element} - Axis element.
         */
        render(parent) {
            const el = this.element.node();

            parent.appendChild(el);
            this.axisSel.call(this.axis);

            return el;
        }

        /**
         * Sets max number of ticks on axis.
         * @param {number} count
         */
        setTicks(count) {
            this.axis.ticks(count);
        }

        /**
         * Positions axis timestamp label based on value provided.
         * @param {number} value
         */
        syncValue(value) {
            const offset = this.scale(value);
            let translate = 'translate(0, 0)';

            switch (this.orient) {
                case Axis.TOP:
                case Axis.BOTTOM:
                    translate = `translate(${offset}, 0)`;
                    break;
                case Axis.LEFT:
                case Axis.RIGHT:
                    translate = `translate(0, ${offset})`;
                    break;
            }

            this.tsCont
                .attr('transform', translate);

            this.textSel
                .select('text')
                .text(this.timeFormat(value));

            this.tsCont.classed('hidden', false);
        }

        /**
         * Displays timestamp on axis.
         */
        resumeSync() {
            this.tsCont.classed('hidden', false);
        }

        /**
         * Displays timestamp on axis.
         */
        pauseSync() {
            this.tsCont.classed('hidden', false);
        }

        /**
         * Hides axis timestamp.
         */
        hideSync() {
            this.tsCont.classed('hidden', true);
        }
    }

    Axis.TOP = 'top';
    Axis.BOTTOM = 'bottom';
    Axis.LEFT = 'left';
    Axis.RIGHT = 'right';

    return Axis;
}]);
