/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/** Top List Collection
 * Contains slug, dimension information, and a list of TopListItems (or UrlTopListItems)
 * Important Methods:
 *     setData -- takes a response, and updates items appropriately (based on whether it's base data
 *     or filtered data)
 *     getEndToEndData -- gets end to end timing and drilldown for a dimension
 *
 * Note: for both TopListCollection and TopListItems, display.oneActive and display.active are set
 * in the ClientInsightsController
 */
angular.module('aviApp').factory('TopListCollection', ['TopListItem', 'UrlTopListItem',
'$stateParams', 'Base', 'Timeframe',
    function(TopListItem, UrlTopListItem, $stateParams, Base, Timeframe) {
        const sorter = function(a, b) {
            b.currentData.value = b.currentData.value || 0;
            a.currentData.value = a.currentData.value || 0;

            return b.currentData.value - a.currentData.value;
        };

        class TopListCollection extends Base {
            constructor(series, dim, slug) {
                super();

                this.dim = dim;
                this.slug = slug;
                this.display = {};

                this.base = {};
                this.filtered = {};
                this.current = this.base;

                this.items = _.map(series, series => this.generateListItem_(series));

                this.items.sort(sorter);

                this.getEndToEndData();
            }

            generateListItem_(series) {
                let item;

                if (this.dim !== 'url') {
                    item = new TopListItem(series, this);
                } else {
                    item = new UrlTopListItem(series, this);
                }

                return item;
            }
        }

        TopListCollection.prototype.updateListItems = function(series) {
            this.items = _.map(series, series => this.generateListItem_(series));

            this.items.sort(sorter);
        };

        TopListCollection.prototype.setData = function(baseOrFiltered, series) {
            const self = this;

            if (this[baseOrFiltered]) { this[baseOrFiltered].isData = !!series; } else { return; }

            if (baseOrFiltered === 'filtered') { self.clearFilteredData(); }

            _.each(series, function(s) {
                const item = self.get(s.header &&
                    s.header.dimension_data &&
                    s.header.dimension_data.length &&
                    s.header.dimension_data[0] &&
                    s.header.dimension_data[0].dimension_id);

                if (!item) { return; }

                if (baseOrFiltered === 'base') {
                    item.setBaseData(s);
                } else if (baseOrFiltered === 'filtered') {
                    item.setFilteredData(s);
                }
            });

            this.sort();
        };

        TopListCollection.prototype.setBaseData = _.partial(
            TopListCollection.prototype.setData, 'base',
        );

        TopListCollection.prototype.setFilteredData = _.partial(
            TopListCollection.prototype.setData, 'filtered',
        );

        TopListCollection.prototype.get = function(id) {
            return _.find(this.items, function(item) {
                // ipgroup mapping to real ip-group will be url else will be just a name
                return item.info.id === id || item.info.id === id.slug();
            });
        };

        TopListCollection.prototype.clearAll = function() {
            this.each(function(item) {
                item.display.active = false;
            });
        };

        TopListCollection.prototype.clearFilteredData = function() {
            this.each(function(item) {
                item.clearFilteredData();
            });
        };

        TopListCollection.prototype.showBaseData = function() {
            const self = this;

            self.current = self.base;
            self.each(function(item) {
                item.showBaseData();
            });
            self.sort();
            self.trigger('toggling');
        };

        TopListCollection.prototype.showFilteredData = function() {
            const self = this;

            self.current = self.base;
            self.each(function(item) {
                item.showFilteredData();
            });
            self.sort();
            self.trigger('toggling');
        };

        TopListCollection.prototype.each = function(fn) {
            _.each(this.items, function(item) {
                fn(item);
            });
        };

        TopListCollection.prototype.getTiming = function(filters) {
            this.each(function(item) {
                if (typeof item.getTiming === 'function') { item.getTiming(filters); }
            });
        };

        TopListCollection.prototype.sort = function() {
            this.items.sort(function(a, b) {
                return b.currentData.value - a.currentData.value;
            });
        };

        TopListCollection.prototype.getEndToEndData = function(filters) {
            filters = filters && filters.slice() || [];

            const self = this;
            const curTimeframe = Timeframe.selected();
            const stepAndLimit = `&step=${curTimeframe.step}&limit=${curTimeframe.limit}`;
            const vsUrl = `/api/analytics/metrics/virtualservice/${$stateParams.vsId}`;
            let endToEndUrl = `${vsUrl}?metric_id=collection.rum_navigation_timing` +
                '&dimension_aggregation=avg&pad_missing_data=false';

            _.each(filters, function({ info, parent }) {
                const { id: filter } = info;
                const { dim } = parent;

                if (self.dim !== dim) { endToEndUrl += `&${dim}=${filter}`; }
            });

            const url = `${endToEndUrl}&dimensions=${this.dim}${stepAndLimit}`;

            this.request('get', url).then(function(rsp) {
                if (!(rsp.data && rsp.data.series && rsp.data.series.length)) { return; }

                _.each(rsp.data.series, function(series) {
                    const item = self.get(series.header.dimension_data[0].dimension_id);

                    if (item) {
                        if (filters && filters.length) {
                            item.setFilteredEndToEnd(series);
                        } else {
                            item.setBaseEndToEnd(series);
                        }
                    }
                });

                self.trigger('end-to-end-set');
            });
        };

        TopListCollection.prototype.getAll = function() {
            return _.map(this.items, function(d) { return d; });
        };

        TopListCollection.prototype.getMaxNavigationTiming = function() {
            return _.reduce(this.items, function(memo, item) {
                const val = item.timing.currentData.endToEndTotal;

                return val > memo ? val : memo;
            }, 0);
        };

        return TopListCollection;
    }]);

/** Top List Item -- inherits from Base
 * Takes a series, and a reference to its parent
 * timing -- instance of TimingItem -- which has methods to set both base and filtered end to end
 *     timings and (for UrlTopListItems) can also set resource timings
 * Important Methods:
 *     setFilteredData, setBaseData -- Responsible for stats (i.e. value + percents)
 *     setBaseEndToEnd, setFilteredEndToEnd -- Responsible for PLT and DLT charts
 *     showFilteredData, showBaseData -- display functions ($scope pays attention to
 *     $scope.currentData)
 */
angular.module('aviApp').factory('TopListItem', ['Base', 'TimingItem', function(Base, TimingItem) {
    class TopListItem extends Base {
        constructor(s, parent) {
            super();

            if (!s) { return this; }

            this.parent = parent;
            this.info = {};
            this.display = {};
            this.baseData = {};
            this.filteredData = {};
            // Current data should start out being baseData
            this.showBaseData();

            this.info.type = s.header &&
                s.header.dimension_data &&
                s.header.dimension_data.length &&
                s.header.dimension_data[0];
            this.display.title = this.info.type.dimension_id;

            if (this.parent.dim === 'ipgroup') {
                // special case as it can be a url or country name
                if (this.info.type.dimension_id) {
                    this.display.title = this.info.type.dimension_id.name();

                    if (!this.display.title || !this.display.title.length) {
                        // this is case of country name
                        this.display.title = this.info.type.dimension_id;
                        this.info.id = this.info.type.dimension_id;
                        this.display.probablyACountry = true;
                    } else {
                        this.info.id = this.info.type.dimension_id.slug();
                    }
                } else {
                    this.info.id = 'unknown';
                }
            } else {
                this.info.id = this.info.type.dimension_id;
            }

            //console.log('Dimension-id %s dim %s', this.info.id, parent.dim)
            this.extraStuff = s;

            this.timing = new TimingItem(this.display.title);
        }
    }

    // ------- Communicating with timing item ------------//
    TopListItem.prototype.setBaseEndToEnd = function(series) {
        this.timing.setBaseEndToEnd(series);
    };

    TopListItem.prototype.setFilteredEndToEnd = function(series) {
        this.timing.setFilteredEndToEnd(series);
    };

    TopListItem.prototype.clearFilteredData = function() {
        this.filteredData.value = 0;
        this.filteredData.percentChange = this.baseData.value ? -this.baseData.value : 0;
    };

    // -----------Choosing what is displayed -------------//
    TopListItem.prototype.showBaseData = function() {
        this.currentData = this.baseData;

        if (this.timing) { this.timing.showBaseData(); }
    };

    TopListItem.prototype.showFilteredData = function() {
        this.currentData = this.filteredData;

        if (this.timing) { this.timing.showFilteredData(); }
    };

    // ------------Setters --------------------//
    TopListItem.prototype.setBaseData = function(s) {
        this.baseData.value = s.data && s.data.length && s.data[0].value;
        this.setBaseEndToEnd(s);
    };

    TopListItem.prototype.setFilteredData = function(s) {
        this.filteredData.value = s.data && s.data.length && s.data[0].value;
        this.filteredData.percentChange = null;

        if (this.baseData.value !== undefined) {
            this.filteredData.percentChange = this.filteredData.value - this.baseData.value;
        }

        this.setFilteredEndToEnd(s);
    };

    return TopListItem;
}]);

angular.module('aviApp').factory('UrlTopListItem', ['TopListItem', function(TopListItem) {
    /** API Note:
     * This response ends up having data for different browsers.
     * We'll pick out the browser with the best data out of the browsers we have
     * And then we'll use that one to do the resource timing + navigation timing
     */
    function findBestDataByBrowser(series) {
        const browsers = ['Chromium', 'Chrome', 'IE', 'Safari', 'Firefox'];

        for (let i = 0; i < browsers.length; i++) {
            const browser = browsers[i].toLowerCase();
            const result = _.find(series, function(d) {
                return d.browser.toLowerCase().match(new RegExp(browser));
            });

            if (result) { return result; }
        }

        return series[0];
    }

    class UrlTopListItem extends TopListItem {
        constructor(args) {
            super(args);

            this.getTiming();
        }
    }

    // --------------Getting Resource Timing ----------------//
    UrlTopListItem.prototype.getTiming = function(filters) {
        filters = filters || [];

        const filtersLength = filters.length;

        // We don't want to filter by url
        filters = filters.slice();
        filters = _.filter(filters, function(f) {
            return f.parent.dim !== 'url';
        });

        let url = `/api/analytics/rum/virtualservice/${this.parent.slug
        }?dimension=url&sample_type=METRICS_RUM_LAST_SAMPLE&url=${this.info.id}`;
        const self = this;

        _.each(filters, function({ info, parent }) {
            const { id: filter } = info;
            const { dim } = parent;

            if (self.dim !== dim) { url += `&${dim}=${filter}`; }
        });

        // Getting Resource Timing Data
        this.request('get', url).then(function(rsp) {
            let bestSeries;

            // Finding the series with the best quality data
            if (rsp.data.series && rsp.data.series.length) {
                bestSeries = findBestDataByBrowser(rsp.data.series[0].data);
            } else {
                console.warn('No timing series for ', self.info.id);

                return;
            }

            // Formatting navigation data for Timing object
            const timing = {};

            timing.data = [bestSeries.navigation_timing];

            if (filtersLength) {
                // Setting Filtered Data
                self.timing.setFilteredEndToEnd(timing);

                if (bestSeries.resource_timings) {
                    self.timing.setFilteredResourceTiming(bestSeries.resource_timings);
                } else {
                    self.timing.setFilteredResourceTiming(null);
                }
            } else {
                // Settings base data
                self.timing.setBaseEndToEnd(timing);

                if (bestSeries.resource_timings) {
                    self.timing.setBaseResourceTiming(bestSeries.resource_timings);
                } else {
                    self.timing.setBaseResourceTiming(null);
                }
            }
        });
    };

    return UrlTopListItem;
}]);
