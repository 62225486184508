/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name  vsSecurityDdos
 * @param {VirtualService} virtualservice - Virtual Service item.
 * @description
 *
 *     Component for the VS security DDoS insights on the security page.
 *
 *     Collections are used to render top lists (per every dimension) and Metrics - for the chart.
 *     Chart is shown for selected top list only - with stacked layers of the top list (and more).
 *
 *     All lists data as well as chart data for every list is loaded all the time (every 60
 *     sec), data to render them is the same but we don't have a way to reuse it, so we make two
 *     calls to pull same thing through GET analytics requests and POST (collection).
 *
 *     List (tab) selection switches the chart to be shown - it starts to show representation of
 *     traffic by each dimensionId of the selected dimension name/type.
 *
 *     Click on the entry of a non active(!) list adds a filter for 'attack' dimension calls,
 *     corresponding top list and chart reload. Also there will be no updates for those until user
 *     clicks 'refresh', 'clear filter' or switches the active tab (dimension type).
 *
 */
const ddosMetrics = [{
    name: 'avg_attack_count_attack',
    alias: 'attack',
    dimensions: [
            'METRICS_DIMENSION_METRIC_TIMESTAMP',
            'METRICS_DIMENSION_ATTACK',
    ],
}, {
    name: 'avg_attack_count_ipgroup',
    alias: 'ipgroup',
    dimensions: [
            'METRICS_DIMENSION_METRIC_TIMESTAMP',
            'METRICS_DIMENSION_IPGROUP',
    ],
}, {
    name: 'avg_attack_count_url',
    alias: 'url',
    dimensions: [
            'METRICS_DIMENSION_METRIC_TIMESTAMP',
            'METRICS_DIMENSION_URL',
    ],
}, {
    name: 'avg_attack_count_asn',
    alias: 'asn',
    dimensions: [
            'METRICS_DIMENSION_METRIC_TIMESTAMP',
            'METRICS_DIMENSION_ASN',
    ],
}, {
    name: 'avg_attack_count_blocked',
    alias: 'blocked',
    dimensions: [
            'METRICS_DIMENSION_METRIC_TIMESTAMP',
            'METRICS_DIMENSION_IPGROUP',
    ],
    filters: {
        attack: 'POLICY_DROPS',
    },
}];

const ddosMetricsList = _.pluck(ddosMetrics, 'name');

ddosMetrics.forEach(metric =>
    angular.extend(metric, {
        seriesId: 'dos.avg_attack_count',
        dimension_aggregation: 'METRICS_DIMENSION_AGG_AVG',
        isAggregated: false, //will be timeseries regardless of aggregation param presence
        params: {
            frequency: 60,
        },
        subscriber: 'ddosSubscriber',
        metricClassName: 'SingleSeriesPerEveryDimensionIdMetric',
    }));

class VsSecurityDdosController {
    constructor(
            ChartConfig,
            MetricsDimensionCollection,
    ) {
        this.ChartConfig_ = ChartConfig;
        this.MetricsDimensionCollection_ = MetricsDimensionCollection;

        this.updateChartConfig_ = this.updateChartConfig_.bind(this);
    }

    $onInit() {
        const vsRef = this.virtualservice.getRef();

        this.attacksCollection = this.createMetricsDimensionCollection_(vsRef, 'attack');

        this.ipgroupsCollection = this.createMetricsDimensionCollection_(vsRef, 'ipgroup');

        this.urlsCollection = this.createMetricsDimensionCollection_(vsRef, 'url');

        this.asnsCollection = this.createMetricsDimensionCollection_(vsRef, 'asn');

        this.blockedCollection = this.createMetricsDimensionCollection_(
            vsRef,
            'ipgroup',
            { attack: 'POLICY_DROPS' },
            true,
        );

        /**
         * Hash of references to the MetricsDimensionCollections.
         * @type {Object}
         */
        this.ddosCollections = {
            attack: this.attacksCollection,
            ipgroup: this.ipgroupsCollection,
            url: this.urlsCollection,
            asn: this.asnsCollection,
            blocked: this.blockedCollection,
        };

        this.chartConfig = new this.ChartConfig_([{
            id: '',
            series: [],
        }]);

        /**
         * Has dimension Id when filter is active.
         * @type {string}
         */
        this.filter = '';

        /**
         * When filter is active, has dimension name/type.
         * @type {string}
         */
        this.filteredDimension = '';

        this.loadCollections_();
        this.loadMetrics_(ddosMetrics);
    }

    /**
     * Returns an instance of the MetricsDimensionCollection with a filter.
     * @param  {string} vsRef - VS ref.
     * @param  {string} dimension - Dimension name/type, ex. 'ipgroup'.
     * @param  {Object} filter - Extra filter, ex. {attack: 'SYN_FLOOD'}.
     * @param  {boolean} isStatic - True if the MetricsDimensionCollection should not update.
     * @return {MetricsDimensionCollection}
     * @protected
     */
    createMetricsDimensionCollection_(vsRef, dimension, filter, isStatic = false) {
        const dataFields = [];

        if (dimension === 'ipgroup' || dimension === 'asn') {
            dataFields.push('list', 'duration-ds', `asname-${dimension}`);
        }

        const params = {};

        if (filter) {
            angular.extend(params, filter);
        }

        return new this.MetricsDimensionCollection_({
            vsRef,
            dimension,
            isStatic,
            dataFields,
            params,
        });
    }

    /**
     * Returns collection and metric by dimension name/type (tab id).
     * @param {string} dimension
     * @returns {Array.<MetricsDimensionCollection, Metric>}
     * @protected
     */
    getCollectionAndMetricByDimension_(dimension) {
        const
            { [dimension]: collection } = this.ddosCollections,
            metricId = `avg_attack_count_${dimension}`,
            metric = this.virtualservice.getMetric(metricId);

        return [collection, metric];
    }

    /**
     * Loads each DDoS collection.
     * @protected
     */
    loadCollections_() {
        _.each(this.ddosCollections, collection => collection.load());
    }

    /**
     * Subscribes to metrics and updates chartConfig after the first load.
     * @param {Object[]} metrics - Metrics configuration object.
     * @protected
     */
    loadMetrics_(metrics) {
        const { virtualservice: vs } = this;

        vs.collMetricsUnSubscribe('ddosSubscriber');

        vs.collMetricsSubscribe(metrics)
            .then(() => {
                vs.startCollMetricsAsync()
                    .then(this.updateChartConfig_);
            });
    }

    /**
     * Updates chartConfig based on metrics being loaded for this component.
     * @protected
     */
    updateChartConfig_() {
        const
            { virtualservice: vs } = this,
            config = vs.createChartsConfig(ddosMetricsList);

        this.chartConfig = new this.ChartConfig_(config);
    }

    /**
     * Handles the top list type selection/activation.
     * @param {string} tab - Selected tab.
     * @public
     */
    handleTabChange(tab) {
        this.clearFilter();
        this.chartConfig.setActiveCard(`avg_attack_count_${tab}`);
    }

    /**
     * Handles filter (top list entry) selection/activation.
     * @param {string} filterParam - Name of the field to be used for the filtering
     *     (different from dimension name).
     * @param {string} filterValue - Value of the filterParam field to be used as a filter.
     * @param {string} dimension - Active/selected dimension name/type. I'd guess that only
     *     'attack' can be such a dimension.
     */
    handleSeriesSelect([filterParam, filterValue], dimension) {
        this.clearFilter();

        this.filter = filterValue;
        this.filteredDimension = dimension;

        const
            [collection, metric] = this.getCollectionAndMetricByDimension_(dimension),
            params = {};

        params[filterParam] = filterValue;

        collection.setParams(params);
        //won't be auto-updated
        collection.setStaticFlag(true);
        collection.load();

        metric.setFilters(params);
        //no updates here either
        metric.setParams({ frequency: Infinity });

        this.virtualservice.startCollMetricsAsync()
            .then(this.updateChartConfig_);
    }

    /**
     * Sets default filters for collections and metrics, reloads them and updates ChartConfig.
     * @public
     */
    clearFilter() {
        if (!this.filter) {
            return;
        }

        const
            {
                filteredDimension: dimension,
                virtualservice: vs,
            } = this,
            [collection, metric] = this.getCollectionAndMetricByDimension_(dimension),
            metricConfig = _.findWhere(ddosMetrics, { name: `avg_attack_count_${dimension}` });

        //restoring default filters
        collection.setParams(metricConfig.filters);

        //switching updates back on all lists but `blocked`
        if (dimension !== 'blocked') {
            collection.setStaticFlag(false);
        }

        collection.load();

        metric.setFilters(metricConfig.filters);

        //auto updates back on
        metric.setParams({ frequency: 60 });

        this.filter = '';
        this.filteredDimension = '';

        vs.startCollMetricsAsync()
            .then(this.updateChartConfig_);
    }

    /**
     * Reloads collections and metrics when active filter is set.
     * @public
     */
    refresh() {
        const
            {
                filteredDimension: dimension,
                virtualservice: vs,
            } = this,
            [collection, metric] = this.getCollectionAndMetricByDimension_(dimension);

        collection.load();

        //updates on
        metric.setParams({ frequency: 60 });

        vs.startCollMetricsAsync().then(() => {
            this.updateChartConfig_();

            //auto updates off
            metric.setParams({ frequency: Infinity });
        });
    }

    $onDestroy() {
        _.each(this.ddosCollections, collection => collection.destroy());
        this.virtualservice.collMetricsUnSubscribe('ddosSubscriber');
    }
}

VsSecurityDdosController.$inject = [
    'ChartConfig',
    'MetricsDimensionCollection',
];

angular.module('avi/vs/security').component('vsSecurityDdos', {
    bindings: {
        virtualservice: '<',
    },
    controller: VsSecurityDdosController,
    templateUrl: 'src/components/applications/virtualservice/' +
            'vs-security-tab/vs-security-ddos/vs-security-ddos.html',
});
