/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

interface INatAddrConfig {
    nat_ip: MessageItem;
    nat_ip_range: MessageItem;
    flatProps: Array<IFlatIp | IFlatIpRange>;
}

interface IFlatIp {
    type: string;
    addr: string;
    begin?: undefined;
}

interface IFlatIpRange {
    begin: IFlatIp;
    end: IFlatIp;
}

/**
 * @desc NatAddrInfo MessageItem class.
 * @author Aravindh Nagarajan
 */
export class NatAddrInfoConfigItem extends MessageItem<INatAddrConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NatAddrInfo',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns true if passed value is a IP Range.
     */
    private static isIPRange(value: IFlatIpRange | IFlatIp): boolean {
        return !!value.begin;
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        // Set flatten child messageItems in flatProps.
        // To use them in repeatedInputs directive.
        const {
            nat_ip: natIp,
            nat_ip_range: natIpRange,
        } = this.config;

        this.config.flatProps = [];

        if (natIp) {
            // eslint-disable-next-line no-underscore-dangle
            const flattenIp = natIp.flattenConfig();

            this.config.flatProps.push(flattenIp as any as IFlatIp);
        }

        if (natIpRange) {
            // eslint-disable-next-line no-underscore-dangle
            const flattenIpRange = natIpRange.flattenConfig();

            this.config.flatProps.push(flattenIpRange as any as IFlatIpRange);
        }
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        const { flatProps } = this.config;

        flatProps.forEach(value => {
            if (!value) {
                return;
            }

            const field = NatAddrInfoConfigItem.isIPRange(value) ? 'nat_ip_range' : 'nat_ip';

            if (!this.config[field]) {
                this.setNewChildByField(field);
            }

            this.config[field].updateConfig(value);
        });

        delete this.config.flatProps;
    }

    /** @override */
    public toString(): string {
        const { flatProps = [] } = this.config;

        const natAddrInfoValue = flatProps.reduce((acc, propValue) => {
            if (!propValue) {
                return acc;
            }

            if (NatAddrInfoConfigItem.isIPRange(propValue)) {
                const { begin, end } = propValue as any as IFlatIpRange;

                acc.push(`${begin.addr} - ${end.addr}`);
            } else {
                const { addr } = propValue as any as IFlatIp;

                acc.push(addr);
            }

            return acc;
        }, []);

        return natAddrInfoValue.join(', ');
    }
}
