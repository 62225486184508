/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    SchemaService,
    StringService,
} from 'ajs/modules/core/services';
import { IAviDataGridConfig } from 'ng/shared/components';
import { GeoDbFileConfigItem } from 'ajs/modules/geo-db';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import './geo-db-file-grid.component.less';
import * as l10n from './geo-db-file-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Geo DB file Grid component.
 *
 * @author Rachit Aggarwal
 */

@Component({
    selector: 'geo-db-file-grid',
    templateUrl: './geo-db-file-grid.component.html',
})
export class GeoDbFileGridComponent implements AfterViewInit {
    /**
     * GeoDbFIle RepeatedMessageItem instance.
     */
    @Input()
    public geoDbFiles: RepeatedMessageItem<GeoDbFileConfigItem>;

    /**
     * Fires on add Geo Db File.
     */
    @Output()
    public onAddGeoDbFile = new EventEmitter();

    /**
     * Fires on remove Geo Db File.
     */
    @Output()
    public onRemoveGeoDbFile = new EventEmitter<GeoDbFileConfigItem>();

    /**
     * Fires on edit Geo Db File.
     */
    @Output()
    public onEditGeoDbFile = new EventEmitter<GeoDbFileConfigItem>();

    /**
     * Template ref for GeoDB file enabled grid cell.
     */
    @ViewChild('geoDbFileEnabledTemplateRef')
    public geoDbFileEnabledTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Geo Db file grid config.
     */
    public geoDbFileGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys, stringService, schemaService } = this;

        this.geoDbFileGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.geoDbFileColTitleEnabled),
                    id: 'enabled',
                    templateRef: this.geoDbFileEnabledTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.geoDbFileColTitleName),
                    id: 'name',
                    transform(file: GeoDbFileConfigItem) {
                        return file.getConfig().name;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.geoDbFileColTitleVendor),
                    id: 'vendor',
                    transform(file: GeoDbFileConfigItem) {
                        return schemaService.getEnumValue('GeoDBFileVendor',
                            file.getConfig().vendor).label;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.geoDbFileColTitleGeoDbFile),
                    id: 'file',
                    transform(file: GeoDbFileConfigItem) {
                        return stringService.name(file.getConfig().file_ref);
                    },
                },
            ],
            multipleactions: [{
                label: this.l10nService.getMessage(this.l10nKeys.geoDbFileRemoveButtonLabel),
                onClick: (geoDbFileList: GeoDbFileConfigItem[]) => {
                    geoDbFileList
                        .forEach((geoDbFileItem: GeoDbFileConfigItem) => {
                            this.removeGeoDbFileList(geoDbFileItem);
                        });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.geoDbFileEditButtonLabel),
                shape: 'pencil',
                onClick: (geoDbFileItem: GeoDbFileConfigItem) => {
                    this.editGeoDbFileList(geoDbFileItem);
                },
            }, {
                label: this.l10nService.getMessage(this.l10nKeys.geoDbFileRemoveButtonLabel),
                shape: 'trash',
                onClick: (geoDbFileItem: GeoDbFileConfigItem) => {
                    this.removeGeoDbFileList(geoDbFileItem);
                },
            }],
        };
    }

    /**
     * Handler for Geo Db file add operation.
     */
    public addGeoDbFileList(): void {
        this.onAddGeoDbFile.emit();
    }

    /**
     * Handler for Geo Db file remove operation.
     */
    private removeGeoDbFileList(geoDbFileItem: GeoDbFileConfigItem): void {
        this.onRemoveGeoDbFile.emit(geoDbFileItem);
    }

    /**
     * Handler for Geo Db file edit operation.
     */
    private editGeoDbFileList(geoDbFileItem: GeoDbFileConfigItem): void {
        this.onEditGeoDbFile.emit(geoDbFileItem);
    }
}
