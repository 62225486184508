/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';
import { isNumber } from 'underscore';
import { IAviDropdownButtonAction } from 'ng/shared/components';
import { StringService } from 'ajs/modules/core/services/string-service';
import {
    WafPolicyPsmGroup,
    WafPolicyPsmGroupCollection,
    WafPositiveSecurityModelConfigItem,
} from 'ajs/modules/waf';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-psm-groups-config.l10n';
import './waf-policy-psm-groups-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IEditEvent {
    group: WafPolicyPsmGroup;
    index?: number;
}

type TWafPolicyPsmGroupCollection = typeof WafPolicyPsmGroupCollection;

/**
 * @desc Component for displaying a a list of WafPolicy PSM Groups.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-groups-config',
    templateUrl: './waf-policy-psm-groups-config.component.html',
})
export class WafPolicyPsmGroupsConfigComponent {
    /**
     * Name of the WAF Policy, used as a breadcrumb.
     */
    @Input()
    public wafPolicyName: string;

    /**
     * WafPositiveSecurityModelConfigItem used for adding and removing groups.
     */
    @Input()
    public psm: WafPositiveSecurityModelConfigItem;

    /**
     * Called to create a new PSM group.
     */
    @Output()
    public onCreateGroup = new EventEmitter<void>();

    /**
     * WafPolicyPsmGroupCollection instance with non-learning PSM groups.
     */
    public nonLearningPsmGroupCollection: WafPolicyPsmGroupCollection;

    /**
     * Selected non-learning PSM Group ref.
     */
    public selectedNonLearningPsmGroupRef: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Actions for the collection dropdown menu.
     */
    public dropdownActions: IAviDropdownButtonAction[];

    public constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(WafPolicyPsmGroupCollection)
        WafPolicyPsmGroupCollection: TWafPolicyPsmGroupCollection,
        private readonly l10nService: L10nService,
        @Inject(StringService)
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.dropdownActions = [
            {
                label: l10nService.getMessage(l10nKeys.createLabel),
                onClick: (): void => this.onCreateGroup.emit(),
                disabled: (): boolean => !this.nonLearningPsmGroupCollection.isCreatable(),
            },
        ];

        this.nonLearningPsmGroupCollection = new WafPolicyPsmGroupCollection({
            params: {
                is_learning_group: false,
                exclude: 'uuid.in',
            },
            bind: {
                collectionBeforeLoad: this.onPsmGroupCollectionBeforeLoad,
            },
        });
    }

    /**
     * Edits a WafPolicyPsmGroup item.
     */
    public editPsmGroup($event: IEditEvent): void {
        const { group, index } = $event;
        const { l10nService } = this;

        group.edit(group.windowElement, {
            breadcrumbs: [l10nService.getMessage(l10nKeys.wafPolicyLabel, [this.wafPolicyName])],
            onSubmit: ['group', this.getHandleSubmit(index)],
        });
    }

    /**
     * Called to delete a PSM Group.
     */
    public deletePsmGroup(group: WafPolicyPsmGroup): void {
        this.psm.deleteGroup(group);
    }

    /**
     * Returns a handleSubmit function based on whether or not a group is being edited or created.
     * @param index - Index of the group.
     */
    public getHandleSubmit = (index: number) => {
        return (group: WafPolicyPsmGroup): void => {
            if (isNumber(index)) {
                this.psm.editGroup(group, index);
            } else {
                this.psm.addGroup(group);
            }

            group.closeModal(group.windowElement as string);
        };
    };

    /**
     * Called when selecting a PSM group from the CollectionDropdown. Adds the PSM group to the list
     * of PSM groups and resets the ngModel value.
     */
    public handleSelectNonLearningPsmGroup(selectedRef: string): void {
        const psmGroup = this.nonLearningPsmGroupCollection.getItemById(
            this.stringService.slug(selectedRef),
        );

        // Cloning the PSM Group item here so that it doesn't get destroyed on collection load.
        const psmGroupConfig = psmGroup.flattenConfig();
        const psmGroupClone = this.nonLearningPsmGroupCollection.createNewItem({
            data: {
                config: psmGroupConfig,
            },
        });

        this.psm.addGroup(psmGroupClone);

        setTimeout(() => {
            this.selectedNonLearningPsmGroupRef = undefined;
        });
    }

    /**
     * Called before PSM Group Collection is loaded.
     */
    private onPsmGroupCollectionBeforeLoad = (): void => {
        this.nonLearningPsmGroupCollection.setParams({ 'uuid.in': this.psm.groupIds.join() });
    };
}
