/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { WafPolicyPsmGroup } from './waf-policy-psm-group.item.factory';

const allDataSources = {
    'collection-metrics': {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'waf_psmgroup.sum_flagged',
            'waf_psmgroup.sum_evaluated',
        ],
        dependsOn: 'config',
    },
    list: {
        source: 'ListCollDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

export class WafPolicyPsmGroupCollection extends Collection {
    public createNewItem: (args?: any, isLone?: boolean) => WafPolicyPsmGroup;

    constructor(args = {}) {
        const extendedArgs = {
            allDataSources,
            objectName: 'wafpolicypsmgroup',
            permissionName: 'PERMISSION_WAFPOLICYPSMGROUP',
            windowElement: 'waf-policy-psm-group-modal',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('WafPolicyPsmGroup');
    }
}

WafPolicyPsmGroupCollection.ajsDependencies = [
    'WafPolicyPsmGroup',
];
