/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'service-engine-navbars';
const prefix = `${moduleName}.${componentName}`;

export const analyticsLinkLabel = `${prefix}.analyticsLinkLabel`;
export const healthLinkLabel = `${prefix}.healthLinkLabel`;
export const eventsLinkLabel = `${prefix}.eventsLinkLabel`;
export const alertsLinkLabel = `${prefix}.alertsLinkLabel`;

export const ENGLISH = {
    [analyticsLinkLabel]: 'Analytics',
    [healthLinkLabel]: 'Health',
    [eventsLinkLabel]: 'Events',
    [alertsLinkLabel]: 'Alerts',
};
