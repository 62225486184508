/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module for Alert/alertConfig related controllers, components and services.
 * @module avi/alerts
 */

import {
    ACTION_GROUP_CONFIG_COLLECTION_TOKEN,
} from 'ajs/modules/alert/alert.tokens';
import * as l10n from './AlertConfigCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

function alertConfigCreateController(
    $scope,
    AlertActionCollection,
    AlertMetricsCollection,
    defaultValues,
    dropDownUtils,
    PoolCollection,
    Regex,
    schemaService,
    ServiceEngineCollection,
    VirtualServiceCollection,
    stringService,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.regex = Regex;
    $scope.$parent.modalScope = $scope;//AviModal thing

    const vm = this;

    vm.alertActionCollection = new AlertActionCollection();
    vm.alertMetricsCollection = new AlertMetricsCollection();
    vm.staticAlertMetricsCollection = new AlertMetricsCollection();
    vm.poolCollection = new PoolCollection({ isStatic: true });
    vm.vsCollection = new VirtualServiceCollection({ isStatic: true });
    vm.seCollection = new ServiceEngineCollection({ isStatic: true });

    vm.staticAlertMetricsCollection.load();

    $scope.init = function() {
        const { config } = $scope.editable.data;

        vm.eventOptions = getEventDropdownOptions();

        vm.alertMetricsCollection.setParams({
            entity_type: angular.isUndefined(config.object_type) ?
                undefined : config.object_type.toLowerCase(),
        });

        vm.ui = {
            errors: null,
        };
    };

    /**
     * Returns dropdown options for `Event occurs` field.
     * @return {DropDownOption[]}
     */
    function getEventDropdownOptions() {
        return schemaService.getEnumValues('EventId')
            .map(({ value, description }) => {
                const label = stringService.enumeration(value);
                let tooltip;

                try {
                    tooltip = description;
                } catch (e) {
                    tooltip = label;
                }

                return dropDownUtils.createOption(value, label, tooltip);
            });
    }

    /**
     * Handles Metric Occurs dropdown change.
     * @param  {Object} metricRule
     */
    vm.metricRuleChange = function(metricRule) {
        if (metricRule && typeof metricRule.metric_id === 'string') {
            metricRule.metric_id = metricRule.metric_id.slug();
        }

        checkDuplicateMetrics();
    };

    /**
     * Checks for duplicate metric_ids.
     */
    function checkDuplicateMetrics() {
        const metrics = $scope.editable.data.config.alert_rule.metrics_rule;
        const metricHash = {};

        const hasDuplicates = _.any(metrics, function(metric) {
            const check = metric.metric_id in metricHash;

            metricHash[metric.metric_id] = true;

            return check;
        });

        vm.ui.errors = hasDuplicates ? l10nService.getMessage(l10nKeys.duplicateMetricError) : null;
    }

    /**
     * Called when user clicks AutoScale Alert checkbox. Sets obj_ref to undefined.
     */
    vm.onAutoscaleAlertChange = function() {
        if ($scope.editable.data.config.autoscale_alert) {
            $scope.editable.data.config.obj_ref = undefined;
        }
    };

    /**
     * Called when user changes between object types. Sets params on alertMetricsCollection.
     */
    vm.onObjectTypeChange = function() {
        const { config } = $scope.editable.data;

        config.obj_ref = undefined;
        config.alert_rule.metrics_rule = [
            defaultValues.getDefaultItemConfigByType('alertrulemetric'),
        ];

        vm.alertMetricsCollection.setParams({
            entity_type: angular.isUndefined(config.object_type) ?
                undefined : config.object_type.toLowerCase(),
        });
    };

    /**
     * Adds default alertrulemetric object to metrics_rule list.
     */
    vm.addEmptyAlertMetric = function() {
        const rule = $scope.editable.data.config.alert_rule;

        rule.metrics_rule = rule.metrics_rule || [];
        rule.metrics_rule.push(defaultValues.getDefaultItemConfigByType('alertrulemetric'));
    };

    /**
     * Adds default alertruleevent object to sys_event_rule list.
     */
    vm.addEmptyEventRule = function() {
        const rule = $scope.editable.data.config.alert_rule;

        rule.sys_event_rule = rule.sys_event_rule || [];
        rule.sys_event_rule.push(defaultValues.getDefaultItemConfigByType('alertruleevent'));

        checkEventRules();
    };

    /**
     * Removes event rule and sets the operator to 'OPERATOR_AND' if set to 'OPERATOR_NOT'.
     * @param  {number} index - Index of event rule.
     */
    vm.removeEventRule = function(index) {
        const rule = $scope.editable.data.config.alert_rule;

        rule.sys_event_rule.splice(index, 1);
        rule.operator = rule.operator === 'OPERATOR_NOT' ? 'OPERATOR_AND' : rule.operator;
    };

    /**
     * Called when source changes from Event to Metrics. Metrics does not allow 'none' to be
     * selected so we need to make sure it gets changed to 'virtualservice'.
     */
    vm.changeSource = function() {
        const { config } = $scope.editable.data;

        if (config.source === 'METRICS' && angular.isUndefined(config.object_type)) {
            config.object_type = 'VIRTUALSERVICE';
        }
    };

    /**
     * Returns metric unit based on its id.
     * @param {string} metricId - Metric id to find metric unit.
     * @returns {string} Metric unit.
     */
    vm.getMetricUnitById = function(metricId) {
        const item = vm.staticAlertMetricsCollection.itemById[metricId];

        if (item) {
            return item.data.config.metric_units.toLowerCase();
        }

        return 'units';
    };

    /**
     * Check to make sure operator can be 'NOT'. If more than 2 events, 'NOT' is not allowed.
     */
    function checkEventRules() {
        const rule = $scope.editable.data.config.alert_rule;

        if (rule.sys_event_rule.length > 2 && rule.operator === 'OPERATOR_NOT') {
            rule.operator = 'OPERATOR_AND';
        }
    }

    $scope.$on('$destroy', function() {
        vm.alertActionCollection.destroy();
        vm.alertMetricsCollection.destroy();
        vm.staticAlertMetricsCollection.destroy();
        vm.poolCollection.destroy();
        vm.vsCollection.destroy();
        vm.seCollection.destroy();
    });
}

alertConfigCreateController.$inject = [
    '$scope',
    ACTION_GROUP_CONFIG_COLLECTION_TOKEN,
    'AlertMetricsCollection',
    'defaultValues',
    'dropDownUtils',
    'PoolCollection',
    'Regex',
    'schemaService',
    'ServiceEngineCollection',
    'VirtualServiceCollection',
    'stringService',
    'l10nService',
];

/**
 * @ngdoc controller
 * @name AlertConfigCreateController
 * @memberof module:avi/alerts
 * @description
 *
 *    Alert config modal controller.
 */
angular.module('avi/alerts')
    .controller('AlertConfigCreateController', alertConfigCreateController);
