/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'network-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const editNetworkCreateHeader = `${prefix}.editNetworkCreateHeader`;
export const newNetworkCreateHeader = `${prefix}.newNetworkCreateHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const ipAddressManagementHeader = `${prefix}.ipAddressManagementHeader`;
export const dhcpEnableInputLabel = `${prefix}.dhcpEnableInputLabel`;
export const ipV6AutoConfigurationInputLabel = `${prefix}.ipV6AutoConfigurationInputLabel`;
export const routingContextInputLabel = `${prefix}.routingContextInputLabel`;
export const routingContextInputPlaceholder = `${prefix}.routingContextInputPlaceholder`;
export const excludeDiscoveredSubnetsInputLabel = `${prefix}.excludeDiscoveredSubnetsInputLabel`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;
export const cloudRequiredToCreateNetworkErrorMessage = `${prefix}.cloudRequiredToCreateNetworkErrorMessage`;

export const ENGLISH = {
    [editNetworkCreateHeader]: 'Edit Network Settings: {0}',
    [newNetworkCreateHeader]: 'New Network Settings: {0}',
    [nameInputLabel]: 'Name',
    [ipAddressManagementHeader]: 'IP Address Management',
    [dhcpEnableInputLabel]: 'DHCP Enabled',
    [ipV6AutoConfigurationInputLabel]: 'IPv6 Auto Configuration',
    [routingContextInputLabel]: 'Routing Context',
    [routingContextInputPlaceholder]: 'Select VRF',
    [excludeDiscoveredSubnetsInputLabel]: 'Exclude Discovered Subnets for Virtual Service Placement',
    [cancelBtnLabel]: 'Cancel',
    [saveBtnLabel]: 'Save',
    [cloudRequiredToCreateNetworkErrorMessage]: 'Cloud is required to create Network',
};
