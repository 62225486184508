/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name convertBytes
 * @description
 *      Formats ngModel by dividing it by provided number. ngModel can be any number.
 *      The specified convertBytes value will be used to divide ngModel.
 * @example
 *      Input displays 1 when ngModel=1024 or displays 2 when ngModel=2048.
 *      <input convert-bytes="1024">
 *
 *      Input displays 5 when ngModel is 10 or displays 10 when ngModel=20.
 *      <input convert-bytes="2">
 *
 */
angular.module('aviApp').directive('convertBytes', () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attrs, ngModel) {
            const multiplier = +attrs['convertBytes'] || 1;

            ngModel.$formatters.push(value => +value / multiplier);
            ngModel.$parsers.push(value => +value * multiplier);
        },
    };
});
