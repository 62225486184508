/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-rule-protocol-match';
const prefix = `${moduleName}.${componentName}`;

export const radioLabelMatchCriteriaIs = `${prefix}.radioLabelMatchCriteriaIs`;
export const radioLabelMatchCriteriaIsNot = `${prefix}.radioLabelMatchCriteriaIsNot`;
export const matchProtocolFieldLabel = `${prefix}.matchProtocolFieldLabel`;

export const ENGLISH = {
    [radioLabelMatchCriteriaIs]: 'Is',
    [radioLabelMatchCriteriaIsNot]: 'Is not',
    [matchProtocolFieldLabel]: 'L4 Rule Protocol',
};
