/**
 * Module containing Tenancy Mode components.
 * @preferred
 * @module TenancyModeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    ClrIconModule,
    ClrInputModule,
} from '@clr/angular';
import { CommonModule } from '@angular/common';
import { tenantSettingsServiceProvider } from './ajs-upgraded-providers';
import {
    TenancyModePageComponent,
    TenancyModePageComponentCard,
} from '.';

const tenancyModeComponents = [
    TenancyModePageComponent,
    TenancyModePageComponentCard,
];

@NgModule({
    declarations: [
        ...tenancyModeComponents,
    ],
    imports: [
        AviFormsModule,
        ClrInputModule,
        SharedModule,
        FormsModule,
        ClrIconModule,
        CommonModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
    providers: [tenantSettingsServiceProvider],
})
export class TenancyModeModule {}
