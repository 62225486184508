/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'administration';
const componentName = 'snmp-v3-user';
const prefix = `${moduleName}.${componentName}`;

export const usernameLabel = `${prefix}.usernameLabel`;
export const usernamePlaceholder = `${prefix}.usernamePlaceholder`;
export const authPassphraseLabel = `${prefix}.authPassphraseLabel`;
export const authPassphrasePlaceholder = `${prefix}.authPassphrasePlaceholder`;
export const privacySettingsLabel = `${prefix}.privacySettingsLabel`;
export const privacySettingsPlaceholder = `${prefix}.privacySettingsPlaceholder`;
export const privacyPassphraseLabel = `${prefix}.privacyPassphraseLabel`;
export const privacyPassphrasePlaceholder = `${prefix}.privacyPassphrasePlaceholder`;
export const authTypeLabel = `${prefix}.authTypeLabel`;
export const authTypePlaceholder = `${prefix}.authTypePlaceholder`;

export const ENGLISH = {
    [usernameLabel]: 'Username',
    [usernamePlaceholder]: 'Enter Username',
    [authPassphraseLabel]: 'Auth Passphrase',
    [authPassphrasePlaceholder]: 'Enter Auth Passphrase',
    [privacySettingsLabel]: 'Privacy Settings',
    [privacySettingsPlaceholder]: 'Select Privacy Settings',
    [privacyPassphraseLabel]: 'Privacy Passphrase',
    [privacyPassphrasePlaceholder]: 'Enter Privacy Passphrase',
    [authTypeLabel]: 'Auth Type',
    [authTypePlaceholder]: 'Select Auth Type',
};
