/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/compression-filters.less';
import * as l10n from './CompressionFilters.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').directive('compressionFilters', [
'Regex',
'IpAddrGroupCollection',
'StringGroupCollection',
'schemaService',
'l10nService',
function(
    Regex,
    IpAddrGroupCollection,
    StringGroupCollection,
    schemaService,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage.
    */
    l10nService.registerSourceBundles(dictionary);

    return {
        scope: {
            data: '=',
            layer: '=',
            current: '=',
            ngDisabled: '=',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/compression-filters.html',
        link(scope) {
            scope.Regex = Regex;
            scope.IpAddrGroupCollection = new IpAddrGroupCollection();
            scope.StringGroupCollection = new StringGroupCollection();
            scope.l10nKeys = l10nKeys;

            scope.gridConfig = {
                id: 'compression-filters-list',
                fields: [{
                    name: 'name',
                    title: l10nService.getMessage(l10nKeys.columnTitleName),
                }, {
                    name: 'ip_addrs_ref',
                    title: l10nService.getMessage(l10nKeys.columnTitleClientIp),
                    transform(row) {
                        return row.ip_addrs_ref ?
                            `${
                                schemaService.getEnumValue('MatchOperation', row.match).label
                            } ${row.ip_addrs_ref.name()}` : 'Any';
                    },
                }, {
                    name: 'devices_ref',
                    title: l10nService.getMessage(l10nKeys.columnTitleUserAgent),
                    transform(row) {
                        return row.devices_ref ?
                            row.devices_ref.name() : l10nService.getMessage(l10nKeys.anyDataLabel);
                    },
                }, {
                    name: 'level',
                    title: l10nService
                        .getMessage(l10nKeys.columnTitleCompression),
                    transform(row) {
                        return schemaService.getEnumValue(
                            'CompressionFilterLevel',
                            row.level,
                        ).label;
                    },
                }],
                rowId: 'name',
                searchFields: ['name', 'ip_addrs_ref.name()'],
                multipleactions: [{
                    title: l10nService.getMessage(l10nKeys.actionBtnDelete),
                    do(selected) {
                        scope.data = _.filter(scope.data, function(filter) {
                            return !_.find(selected, function(item) {
                                return filter === item;
                            });
                        });

                        return true;
                    },
                }],
                singleactions: [{
                    title: l10nService.getMessage(l10nKeys.actionBtnEdit),
                    class: 'sel-edit-btn icon-pencil-4',
                    do(filter) {
                        scope.editFilter(filter);
                    },
                }],
                checkboxDisable() {
                    return scope.ngDisabled;
                },
                withReordering: true,
            };

            scope.reindex = function() {
                // Reindex it
                _.each(scope.data, function(item, index) {
                    item.index = index;
                });
            };

            scope.object2Array = function(obj) {
                const arr = [];

                _.each(obj, function(item, key) {
                    arr.push({
                        id: key,
                        data: item,
                    });
                });

                return arr;
            };

            scope.toggleAll = function() {
                const expand = !scope.allExpanded();

                if (!scope.data) {
                    return;
                }

                _.each(scope.data, function(rule) {
                    rule.showDetails = expand;
                });
            };

            scope.remove = function(index) {
                scope.data.splice(index, 1);
            };

            scope.changeState = function(state) {
                _.each(scope.data, function(r) {
                    if (scope.table.selected[scope.table.keyFn(r)]) {
                        r.enabled = state;
                    }
                });
            };

            scope.removeMultiple = function() {
                scope.data = _.filter(scope.data, function(r) {
                    return !scope.table.selected[scope.table.keyFn(r)];
                });
                scope.table.selected = {};
            };

            /**
             * Swaps rules updating their indexes.
             * @param {number} from
             * @param {number} to
             */
            scope.onRowMove = function(from, to) {
                scope.current = null;

                const
                    { data: rules } = scope,
                    targetRule = rules[from],
                    swappedRule = rules[to],
                    { index: targetIndex } = targetRule,
                    { index: swapIndex } = swappedRule;

                targetRule.index = swapIndex;
                swappedRule.index = targetIndex;

                rules[from] = swappedRule;
                rules[to] = targetRule;
            };

            scope.addFilter = function() {
                if (!scope.data) {
                    scope.data = [];
                }

                const filter = {
                    name: l10nService
                        .getMessage(l10nKeys.filtersCountLabel, [scope.data.length + 1]),
                    match: 'IS_IN',
                    level: 'NORMAL_COMPRESSION',
                };

                scope.editFilter(filter);
            };

            scope.editFilter = function(filter) {
                // Work with copy of the object
                filter = angular.copy(filter);

                filter.save = function() {
                    scope.saveFilter();
                };

                scope.current = filter;
            };

            scope.saveFilter = function() {
                if (!scope.current) {
                    return;
                }

                // Make sure there is no item with the same name
                scope.error = null;

                if (_.any(scope.data, function(item) {
                    return item.name == scope.current.name &&
                            item.index != scope.current.index;
                })) {
                    scope.error = l10nService.getMessage(l10nKeys.filterNameInUseErrorMessage);

                    return;
                }

                delete scope.current.save;

                if (!scope.data) {
                    scope.data = [];
                }

                if (scope.current.index !== undefined) {
                    angular.copy(scope.current, _.find(scope.data, function(item) {
                        return item.index == scope.current.index;
                    }));
                } else {
                    scope.current.index = _.max(
                        scope.data, function(i) {
                            return i.index;
                        },
                    ).index + 1 || 1;
                    scope.data.push(scope.current);
                }

                scope.current = null;
            };

            scope.$on('$destroy', () => {
                scope.StringGroupCollection.destroy();
                scope.IpAddrGroupCollection.destroy();
            });
        },
    };
}]);
