/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'case-custom-tags-grid';
const prefix = `${moduleName}.${componentName}`;

export const customTagLabelWithCount = `${prefix}.customTagLabelWithCount`;
export const addTagButtonLabel = `${prefix}.addTagButtonLabel`;
export const systemTagTypePlaceholder = `${prefix}.systemTagTypePlaceholder`;
export const systemTagValuePlaceholder = `${prefix}.systemTagValuePlaceholder`;
export const customTagTypePlaceholder = `${prefix}.customTagTypePlaceholder`;
export const customTagValuePlaceholder = `${prefix}.customTagValuePlaceholder`;
export const valueLabel = `${prefix}.valueLabel`;
export const typeLabel = `${prefix}.typeLabel`;
export const removeActionLabel = `${prefix}.removeActionLabel`;

export const ENGLISH = {
    [customTagLabelWithCount]: 'Custom Tag ({0})',
    [addTagButtonLabel]: 'Add',
    [systemTagTypePlaceholder]: 'Select System Type',
    [systemTagValuePlaceholder]: 'Select Value',
    [customTagTypePlaceholder]: 'Enter Custom Type',
    [customTagValuePlaceholder]: 'Enter Value',
    [valueLabel]: 'Value',
    [typeLabel]: 'Type',
    [removeActionLabel]: 'Remove',
};
