/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IDeferred,
    IHttpRequestConfigHeaders,
    IHttpResponse,
    IHttpService,
    IPromise,
    IQService,
    IRequestConfig,
} from 'angular';

import { isFunction } from 'underscore';
import { AjsDependency } from 'ajs/utils/ajsDependency';

interface IBaseRequestConfig extends IRequestConfig {
    group?: string; // unique Id for a request, used to cancel it.
}

export interface IBaseRequestPromise<T> extends IPromise<IHttpResponse<T>> {
    cancel?: (reason: string) => void;
}

interface IBaseRequestDeferred<T> extends IDeferred<IHttpResponse<T>> {
    promise: IBaseRequestPromise<T>;
}

export interface IBaseArgs {
    bind?: Record<string, TEventListenerFunc | TEventListenerFunc[]>;
    events?: Record<string, IBaseEvent>;
}

type TEventListenerFunc = () => void;

interface IEventListener {
    listener: TEventListenerFunc;
    onetime?: boolean;
}

export interface IBaseEvent {
    listeners: Array<IEventListener | TEventListenerFunc>;
}

const HTTP_PARAM_SERIALIZER = 'httpParamFullSerializer';

/**
 * @constructor
 * @memberOf module:avi/dataModel
 * @alias Base
 * @desc Events API and http requests wrapper.
 * @author Alex Malitsky, Alex Tseung, Aravindh Nagarajan
 */
export class Base extends AjsDependency {
    protected readonly $http: IHttpService;
    protected readonly $q: IQService;
    protected events: Record<string, IBaseEvent> = {};
    private destroyed = false;
    private outstandingRequestCancel: Record<string, Array<IDeferred<any>>> = {};

    constructor(args: IBaseArgs = {}) {
        super();

        this.events = args.events || this.events;

        if (args.bind && typeof args.bind === 'object') {
            this.bindMultiple(args.bind);
        }

        this.$http = this.getAjsDependency_('$http');
        this.$q = this.getAjsDependency_('$q');
    }

    /**
     * Makes a call to the url provided with ability to cancel an ongoing call through
     */
    public request<T = any>(
        method: IBaseRequestConfig | string,
        url = '',
        data: any = null,
        headers?: IHttpRequestConfigHeaders,
        group = '',
    ): IBaseRequestPromise<T> {
        const { $q, $http } = this;
        const deferred: IBaseRequestDeferred<T> = $q.defer();
        const cancelRequestDefered = $q.defer();

        // Query params for Request.
        let params = {};

        if (typeof method === 'object') {
            if (arguments.length > 1) {
                throw new Error('No additional parameters are required if RequestConfig is passed');
            }

            ({
                method,
                url,
                data,
                headers,
                group = '',
                params = {},
            } = method);
        }

        // Remove hash (for IE)
        url = url.replace(/#.+$/, '');

        $http({
            method,
            url,
            data,
            timeout: cancelRequestDefered.promise,
            headers,
            params,
            paramSerializer: HTTP_PARAM_SERIALIZER,
        })
            .then((rsp: IHttpResponse<T>) => {
                deferred.resolve(rsp);
            })
            .catch((err: any) => {
                deferred.reject(err);
            })
            .finally(() => {
                const outstandingRequestsForGroup = this.outstandingRequestCancel[group];

                const currentRequestIndex =
                    outstandingRequestsForGroup.indexOf(cancelRequestDefered);

                outstandingRequestsForGroup.splice(currentRequestIndex, 1);
            });

        // Returned promise should have an option to cancel the call
        deferred.promise.cancel = (reason: string) => {
            cancelRequestDefered.resolve(reason);
        };

        // Collecting requests to be able to cancel them later
        if (!this.outstandingRequestCancel[group]) {
            this.outstandingRequestCancel[group] = [];
        }

        this.outstandingRequestCancel[group].push(cancelRequestDefered);

        return deferred.promise;
    }

    /**
     * Cancels all pending requests using promise
     * @param group - specific group to cancel
     */
    public cancelRequests(group = ''): void {
        if (group) {
            const outsandingRequestsForGroup = this.outstandingRequestCancel[group];

            if (!outsandingRequestsForGroup) {
                // No-op when group doesn't exist
                return;
            }

            // Just cancel the group
            outsandingRequestsForGroup.forEach((deferred: IDeferred<any>) => {
                deferred.resolve();
            });
        } else {
            // Cancel them all
            const { outstandingRequestCancel } = this;

            Object.keys(this.outstandingRequestCancel).forEach((key: string) => {
                outstandingRequestCancel[key].forEach((deferred: IDeferred<any>) => {
                    deferred.resolve();
                });
            });
        }
    }

    /**
     * Creates event listener
     * @param eventName - The name of event
     * @param listener - Callback function
     * @param bindOneTime - If true then the event will be removed after event fire
     * @param unshift - If true, listener is added to the start of the events queue.
     */
    public bind(
        eventName: string,
        listener: TEventListenerFunc,
        bindOneTime = false,
        unshift = false,
    ): boolean {
        if (!isFunction(listener)) {
            return false;
        }

        // If multiple events
        const eventNames = eventName.split(' ');

        if (eventNames.length > 1) {
            eventNames
                .forEach(eventName => this.bind(eventName, listener, bindOneTime, unshift));

            return;
        }

        const { events: baseEvents } = this;
        let event = baseEvents[eventName];

        if (!event) {
            baseEvents[eventName] = {
                listeners: [],
            };

            event = baseEvents[eventName];
        } else {
            this.unbind(eventName, listener);
        }

        const insertOp = unshift ? 'unshift' : 'push';

        if (bindOneTime) {
            event.listeners[insertOp]({
                listener,
                onetime: bindOneTime,
            });
        } else {
            event.listeners[insertOp](listener);
        }
    }

    /**
     * Shortcut to bind
     * @param eventName - The name of event
     * @param listener - Callback function
     * @param bindOneTime - If true then the event will be removed after event fire
     */
    public on(eventName: string, listener: TEventListenerFunc, bindOneTime = false): void {
        this.bind(eventName, listener, bindOneTime);
    }

    /**
     * Shortcut for one time bind.
     * @param eventName - event type.
     * @param listener - Event handler function.
     */
    public one(eventName: string, listener: TEventListenerFunc): void {
        this.bind(eventName, listener, true);
    }

    /**
     * Removes event listener
     * @param eventName - Event name
     * @param listener - Callback function
     * @returns Number of removed listeners
     */
    public unbind(eventName: string, listener: TEventListenerFunc): number {
        let removedListenerCount = 0;

        if (!this.isDestroyed()) {
            // If multiple events
            const eventNames = eventName.split(' ');

            if (eventNames.length > 1) {
                eventNames
                    .forEach(event => this.unbind(event, listener));

                return;
            }

            const baseEvents = this.events;

            if (!baseEvents[eventName]) {
                return 0;
            }

            const { listeners: eventListeners } = baseEvents[eventName];

            for (let i = eventListeners.length - 1; i >= 0; i--) {
                const eventListener = eventListeners[i];

                if (eventListener === listener ||
                        !isFunction(eventListener) && eventListener.listener === listener) {
                    eventListeners.splice(i, 1);
                    removedListenerCount++;
                }
            }
        }

        return removedListenerCount;
    }

    /**
     * Fires event. Multiple arguments allowed, will be passed to listener functions
     * @param eventName - Event name
     */
    public trigger(eventName: string, ...args: any[]): void {
        if (!this.isDestroyed()) {
            // If multiple events
            const eventNames = eventName.split(' ');

            if (eventNames.length > 1) {
                eventNames
                    .forEach(eventName => this.trigger(eventName, args));

                return;
            }

            const { events: baseEvents } = this;

            if (!baseEvents[eventName]) {
                return;
            }

            const event = baseEvents[eventName];
            // Duplicate array because it may be modified from within the listeners
            const eventListeners = event.listeners.slice();

            // Remove one-time event listeners
            this.removeOnetimeEventListeners(eventName);

            eventListeners.forEach(eventListener => {
                const listenerArgs = args.slice();

                try {
                    if (isFunction(eventListener)) {
                        eventListener.apply(this, listenerArgs);
                    } else {
                        const { listener } = eventListener;

                        listener.apply(this, listenerArgs);
                    }
                } catch (e) {
                    console.error(
                        `Event listener for "${eventName}" event raised an exception`,
                        this,
                        e,
                    );
                }
            });
        } else {
            console.error(`Trying to trigger "${eventName}" event on destroyed object`, this);
        }
    }

    /**
     * Service to unbind all events and cancel all pending requests.
     */
    public destroy(): boolean {
        if (!this.isDestroyed()) {
            this.trigger('beforeDestroy');
            this.destroyed = true;
            this.cancelRequests();
            this.events = null;

            return true;
        }

        return false;
    }

    /**
     * Getter to figure out whether element got destroyed.
     */
    public isDestroyed(): boolean {
        return this.destroyed;
    }

    /**
     * Adds multiple event listeners.
     */
    private bindMultiple(
        events: Record<string, TEventListenerFunc | TEventListenerFunc[]>,
    ): void {
        const eventNames = Object.keys(events);

        eventNames.forEach(eventName => {
            const listeners = events[eventName];

            if (Array.isArray(listeners)) {
                listeners.forEach(listener => this.bind(eventName, listener));
            } else {
                this.bind(eventName, listeners);
            }
        });
    }

    /**
     * Removes ontime event listeners
     * @param eventName - Event name
     * @returns Number of removed listeners
     */
    private removeOnetimeEventListeners(eventName: string): number {
        // If multiple events
        const eventNames = eventName.split(' ');

        if (eventNames.length > 1) {
            eventNames
                .forEach(event => this.removeOnetimeEventListeners(event));

            return;
        }

        const baseEvents = this.events;

        if (!baseEvents[eventName]) {
            return 0;
        }

        const event = baseEvents[eventName];
        const { listeners } = event;
        const listenersCount = listeners.length;

        let removedListenerCount = 0;

        for (let i = 0; i < listenersCount; i++) {
            const listener = listeners[i];

            if (typeof listener === 'object') {
                const { onetime } = listener;

                if (onetime) {
                    listeners.splice(i, 1);

                    removedListenerCount++;
                }
            }
        }

        return removedListenerCount;
    }
}

Base.ajsDependencies = [
    '$http',
    '$q',
];
