/** @module PoolModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/
import { PoolGroupDeploymentPolicyModalComponent } from 'ng/modules/pool';
import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import {
    AviPermissionResource,
    ComparisonOperator,
    IPoolGroupDeploymentPolicy,
} from 'generated-types';
import * as l10n from './pool-group-deployment-policy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const defaultRuleConfig = {
    metric_id: '',
    operator: ComparisonOperator.CO_LE,
};

interface IPoolGroupDeploymentPolicyData {
    config: IPoolGroupDeploymentPolicy;
}

type IPoolGroupDeploymentPolicyPartial = Omit<IPoolGroupDeploymentPolicy, 'rules'>;
interface IPoolGroupDeploymentPolicyConfig extends IPoolGroupDeploymentPolicyPartial {
    rules?: RepeatedMessageItem<MessageItem>
}

/**
 * Ajs dependency token for PoolGroupDeploymentPolicy.
 */
export const POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN = 'PoolGroupDeploymentPolicy';

/**
 * @description Pool Group Deployment Policy collection item.
 * @author Nisar Nadaf
 */
export class PoolGroupDeploymentPolicy extends withFullModalMixin(ObjectTypeItem) {
    public data: IPoolGroupDeploymentPolicyData;
    public getConfig: () => IPoolGroupDeploymentPolicyConfig;

    public l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectType: 'PoolGroupDeploymentPolicy',
            windowElement: PoolGroupDeploymentPolicyModalComponent,
            objectName: 'poolgroupdeploymentpolicy',
            permissionName: AviPermissionResource.PERMISSION_POOLGROUPDEPLOYMENTPOLICY,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the list of rules in pool group deployment policy.
     */
    public getRules(): RepeatedMessageItem<MessageItem> {
        const { rules } = this.getConfig();

        return rules;
    }

    /**
     * Adds a rule to pool group deployment policy with default configs.
     */
    public addRule(): void {
        const rules = this.getRules();

        rules.add(defaultRuleConfig);
    }

    /**
     * Removes a rule from pool group policy deployment by index.
     */
    public removeRule(rule: MessageItem): void {
        const rules = this.getRules();

        rules.removeByMessageItem(rule);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.poolGroupDeploymentPolicyModalBreadcrumbTitle);
    }
}

PoolGroupDeploymentPolicy.ajsDependencies = [
    'l10nService',
    'stringService',
];
