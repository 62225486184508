/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/pages/profile/pki-profile-list-page.less';
import * as l10n from './PKIProfileListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('PKIProfileListController', [
'$scope',
'CRUDGridConfig',
'PKIProfileCollection',
'PKIProfile',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    PKIProfileCollection,
    PKIProfile,
    l10nService,
) {
    const gridConfig = new CRUDGridConfig({
        collection: new PKIProfileCollection({
            params: {
                fields: 'crls.fingerprint,ca_certs.version',
            },
        }),
        options: {},
    });

    const { objectName } = gridConfig.collection;

    gridConfig.id = `${objectName}-list-page`;

    /**
     * Hash of PKI Profile Item.
     * @type {Object}
     */
    this.pkiProfileHash = {};

    /**
    * Get keys from source bundles for template usage.
    */
    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    gridConfig.fields = [{
        name: 'name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        template: '{{row.getName()}}',
        sortBy: 'name',
    }, {
        name: 'data.config.ca_certs.count',
        title: l10nService.getMessage(l10nKeys.columnTitleCaCertification),
    }, {
        name: 'data.config.crls.count',
        title: l10nService.getMessage(l10nKeys.columnTitleCertificationRevocationList),
    }];

    const getChildGridConfig = type => {
        const
            config = {
                fields: [],
                layout: {
                    hideSearch: true,
                    hideDisplaying: true,
                },
                expandedContainerTemplate:
                    '<pre class="cert-text__expanded">{{::row.config.text}}</pre>',
            },
            { fields } = config;

        switch (type) {
            case 'ca':
                fields.push({
                    name: 'config.subject.config.common_name',
                    title: l10nService.getMessage(l10nKeys.columnTitleName),
                }, {
                    name: 'config.issuer.config.common_name',
                    title: l10nService.getMessage(l10nKeys.columnTitleIssuedBy),
                }, {
                    name: 'config.not_after',
                    title: l10nService.getMessage(l10nKeys.columnTitleExpirationDate),
                });

                config.rowId = row => [
                    row.config.subject.config.common_name,
                    row.config.issuer.config.common_name,
                    row.config.not_after,
                ].join();

                break;

            case 'crl':
                fields.push({
                    name: 'config.common_name',
                    title: l10nService.getMessage(l10nKeys.columnTitleName),
                }, {
                    name: 'config.next_update',
                    title: l10nService.getMessage(l10nKeys.columnTitleExpirationDate),
                });

                config.rowId = row => [
                    row.config.common_name,
                    row.config.next_update,
                ].join();

                break;
        }

        return config;
    };

    gridConfig.executeBeforeContainerExpand = async row => {
        const { options } = gridConfig;
        const { id, config } = row;

        // Load full PKI Profile item data for the row expander.
        if (!this.pkiProfileHash[id]) {
            row.busy = true;

            const pkiProfile = new PKIProfile({ id: config.uuid });

            await pkiProfile.load();

            this.pkiProfileHash[id] = pkiProfile;
            row.busy = false;
        }

        // Update partial PKI Profile item data with full PKI Profile item data
        // for the row expander.
        row.updateItemData({ config: this.pkiProfileHash[id].dataToSave() });

        options.caCertsGridConfig = getChildGridConfig('ca');
        options.caCertsGridConfig.id = 'pkiprofile__ca-certs';
        options.crlsGridConfig = getChildGridConfig('crl');
        options.crlsGridConfig.id = 'pkiprofile__ca-certs-revocation';
    };

    gridConfig.expandedContainerTemplate =
        `<div ng-if="!row.busy">
            <h3>${l10nService.getMessage(l10nKeys.caCertificationsHeader)}</h3>
            <grid
                config="config.options.caCertsGridConfig"
                rows="row.pkiCertificateAuthority.config"
            ></grid>

            <h3 class="mt5">
                ${l10nService.getMessage(l10nKeys.certificationRevocationListHeader)}
            </h3>
            <grid
                config="config.options.crlsGridConfig"
                rows="row.pkiCertificateRevocationList.config"
            ></grid>
        </div>
        <span avi-loader ng-if="row.busy"></span>`;

    $scope.gridConfig = gridConfig;

    /**
     * Destroy PKI Profile items stored in pkiProfileHash.
     * @override
     */
    this.$onDestroy = function() {
        Object.values(this.pkiProfileHash).forEach(pkiProfileItem => {
            pkiProfileItem.destroy();
        });

        this.pkiProfileHash = null;
    };
}]);
