/** @module SecurityModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import { Component, Type } from '@angular/core';
import { PkiCertificateRevocationListModalComponent } from 'ng/modules/security';
import { ICRL } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './pki-profile.l10n';

/**
 * Ajs dependency token for CRLConfigItem.
 */
export const CRL_CONFIG_ITEM_TOKEN = 'CRLConfigItem';

/**
 * API to resolve Certificate Revocation List(CRL).
 */
const PRINT_SSL_API = '/api/printssl';

/**
 * Request ID to resolve Certificate Revocation List(CRL).
 */
const RESOLVE_CRL_REQUEST_ID = 'resolveCrl';

/**
 * Request ID to resolve Certificate Revocation List(CRL) by URL.
 */
const RESOLVE_CRL_URL_REQUEST_ID = 'resolveCrlByUrl';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   CRL Message Item.
 *
 * @author Satish Pednekar
 */
export class CRLConfigItem extends withFullModalMixin(MessageItem)<ICRL> {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'CRL',
            windowElement: PkiCertificateRevocationListModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_('HttpWrapper');

        this.httpWrapper = new HttpWrapper();
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for Certificate Revocation List(CRL) name.
     */
    public get name(): string {
        const {
            common_name: commonName,
            distinguished_name: distinguishedName,
        } = this.config;

        return commonName || distinguishedName;
    }

    /**
     * Update Certificate Revocation List(CRL) data after resolving the file contents.
     */
    public async updateCrlData(content: string): Promise<void> {
        const certificateRevocationList =
            await this.resolveCertificateRevocationList(content);

        this.updateCrlConfig(certificateRevocationList);
    }

    /**
     * Fetch and update Certificate Revocation List(CRL) from server.
     */
    public async fetchCrlData(url: string): Promise<void> {
        const certificateRevocationList = await this.resolveUrl(url);

        this.updateCrlConfig(certificateRevocationList);
    }

    /**
     * Resolves Certificate Revocation List(CRL) Server URL.
     */
    public async resolveUrl(url: string): Promise<ICRL> {
        this.busy = true;
        this.errors = null;

        this.httpWrapper.cancelRequest(RESOLVE_CRL_URL_REQUEST_ID);

        const payload = {
            url,
            type: 'crl',
        };

        try {
            const requestConfig = {
                url: PRINT_SSL_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: RESOLVE_CRL_URL_REQUEST_ID,
            };

            const { data: crlData = {} } = await this.httpWrapper.request(requestConfig);

            return crlData;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Resolves Certificate Revocation List(CRL) file content.
     */
    public async resolveCertificateRevocationList(content: string): Promise<ICRL> {
        this.busy = true;
        this.errors = null;

        this.httpWrapper.cancelRequest(RESOLVE_CRL_REQUEST_ID);

        const payload = {
            body: btoa(content),
            type: 'crl',
        };

        try {
            const requestConfig = {
                url: PRINT_SSL_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: RESOLVE_CRL_REQUEST_ID,
            };

            const { data: crlData = {} } = await this.httpWrapper.request(requestConfig);

            return crlData;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Clear errors and previous Certificate Revocation List(CRL) data.
     */
    public clearCrlData(): void {
        this.errors = null;
        this.updateConfig({});
    }

    /**
     * Called to destroy and cancel all pending requests.
     * @override
     */
    public destroy(): void {
        this.httpWrapper.cancelAllRequests();

        super.destroy();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.addCrlModalBreadcrumbTitle);
    }

    /**
     * Function to update CRL config.
     * Set update_interval property on resolved CRL data object before updating the config.
     */
    private updateCrlConfig(certificateRevocationList: ICRL): void {
        const { update_interval: updateInterval } = this.config;

        if (updateInterval) {
            certificateRevocationList.update_interval = updateInterval;
        }

        this.updateConfig(certificateRevocationList);
    }
}

CRLConfigItem.ajsDependencies = [
    'HttpWrapper',
    'l10nService',
];
