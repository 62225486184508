/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs';
const componentName = 'vs-vip-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleAddress = `${prefix}.columnTitleAddress`;
export const columnTitleVirtualServicesCount = `${prefix}.columnTitleVirtualServicesCount`;
export const virtualServicesHeader = `${prefix}.virtualServicesHeader`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const allVipsOptionLabel = `${prefix}.allVipsOptionLabel`;
export const unusedVipsOptionLabel = `${prefix}.unusedVipsOptionLabel`;
export const inUseVipsOptionLabel = `${prefix}.inUseVipsOptionLabel`;
export const showPrependLabel = `${prefix}.showPrependLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleAddress]: 'Address',
    [columnTitleVirtualServicesCount]: '# Virtual Services',
    [virtualServicesHeader]: 'Virtual Services',
    [emptyDataLabel]: 'None',
    [allVipsOptionLabel]: 'All VIPs',
    [unusedVipsOptionLabel]: 'Unused VIPs',
    [inUseVipsOptionLabel]: 'In-Use VIPs',
    [showPrependLabel]: 'Show',
};
