/**
 * Module for SSO Policy
 * @module SsoPolicyModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/utils/ajsDependency';

import {
    AuthenticationMatchConfigItem,
    AuthenticationPolicyConfigItem,
    AuthenticationRuleConfigItem,
    AuthorizationActionConfigItem,
    AuthorizationMatchConfigItem,
    AuthorizationPolicyConfigItem,
    AuthorizationRuleConfigItem,
    SsoPolicyCollection,
    SsoPolicyItem,
} from '.';

import {
    AUTHENTICATION_MATCH_CONFIG_ITEM_TOKEN,
    AUTHENTICATION_POLICY_CONFIG_ITEM_TOKEN,
    AUTHENTICATION_RULE_CONFIG_ITEM_TOKEN,
    AUTHORIZATION_ACTION_CONFIG_ITEM_TOKEN,
    AUTHORIZATION_MATCH_CONFIG_ITEM_TOKEN,
    AUTHORIZATION_POLICY_CONFIG_ITEM_TOKEN,
    AUTHORIZATION_RULE_CONFIG_ITEM_TOKEN,
    SSO_POLICY_COLLECTION_TOKEN,
    SSO_POLICY_ITEM_TOKEN,
} from './sso-policy.tokens';

const ssoPolicyModule = angular.module('avi/policies/sso');

const factories = [
    {
        dependency: SsoPolicyItem,
        name: SSO_POLICY_ITEM_TOKEN,
    },
    {
        dependency: SsoPolicyCollection,
        name: SSO_POLICY_COLLECTION_TOKEN,
    },
    {
        dependency: AuthenticationPolicyConfigItem,
        name: AUTHENTICATION_POLICY_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AuthenticationRuleConfigItem,
        name: AUTHENTICATION_RULE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AuthenticationMatchConfigItem,
        name: AUTHENTICATION_MATCH_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AuthorizationRuleConfigItem,
        name: AUTHORIZATION_RULE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AuthorizationPolicyConfigItem,
        name: AUTHORIZATION_POLICY_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AuthorizationMatchConfigItem,
        name: AUTHORIZATION_MATCH_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AuthorizationActionConfigItem,
        name: AUTHORIZATION_ACTION_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(ssoPolicyModule, 'factory', name, dependency);
});
