/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module Angular-Core
 */

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { FormsModule } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UIRouterModule } from '@uirouter/angular';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    I18nContext,
    LocaleService,
    VIPModule,
    VIPService,
} from '@vmw/ngx-vip';

import { initVIPConfig } from 'ajs/modules/core/services/i18n';

import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './shared/shared.module';
import { NotificationModule } from './modules/notification/notification.module';
import { WafModule } from './modules/waf/waf.module';
import { PoolModule } from './modules/pool/pool.module';
import { UpdateModule } from './modules/update/update.module';
import { NatModule } from './modules/nat-policy';
import { PolicyModule } from './modules/policy/policy.module';
import { WebhookModule } from './modules/webhook/webhook.module';
import { L4PolicyModule } from './modules/l4-policy/l4-policy.module';
import { LaunchConfigModule } from './modules/launch-config/launch-config.module';
import { LicensingModule } from './modules/licensing/licensing.module';
import {
    CloudConnectorUserModule,
} from './modules/cloud-connector-user/cloud-connector-user.module';
import { CloudModule } from './modules/cloud/cloud.module';
import { GslbModule } from './modules/gslb/gslb.module';
import { HeaderModule } from './modules/header/header.module';
import { LoginModule } from './modules/login/login.module';
import { VerticalNavModule } from './modules/vertical-nav/vertical-nav.module';
import { IcapProfileModule } from './modules/icap-profile/icap-profile.module';
import { WelcomeModule } from './modules/welcome/welcome.module';
import { IPReputationDBModule } from './modules/ip-reputation-db/ip-reputation-db.module';
import { TenancyModeModule } from './modules/tenancy-mode/tenancy-mode.module';
import { NetworkModule } from './modules/network/network.module';
import { IpamModule } from './modules/ipam/ipam.module';
import { DialogModule } from './modules/dialog/dialog.module';
import { SystemModule } from './modules/system/system.module';
import { SecurityModule } from './modules/security/security.module';
import { AviFormsModule } from './modules/avi-forms/avi-forms.module';
import { ErrorPageModule } from './modules/error-page/error-page.module';
import { VrfContextModule } from './modules/vrf-context/vrf-context.module';
import { ScriptsModule } from './modules/scripts/scripts.module';
import { AutoScaleModule } from './modules/auto-scale/auto-scale.module';
import { CportalModule } from './modules/cportal/cportal.module';
import { PersistenceProfileModule } from './modules/persistence-profile/persistence-profile.module';
import { VsVipModule } from './modules/vs-vip/vs-vip.module';
import { MatchModule } from './modules/match/match.module';
import { AccountsModule } from './modules/accounts/accounts.module';
import { DataGridModule } from './modules/data-grid/data-grid.module';
import { GroupsModule } from './modules/groups/groups.module';
import { AlertModule } from './modules/alert/alert.module';
import { RbacModule } from './modules/rbac/rbac.module';
import { SnmpTrapProfileModule } from './modules/snmp-trap-profile/snmp-trap-profile.module';
import { ClusterModule } from './modules/cluster/cluster.module';
import { SsoPolicyModule } from './modules/sso-policy/sso-policy.module';
import { LogsModule } from './modules/logs/logs.module';

import {
    BotDetectionPolicyModule,
} from './modules/bot-detection-policy/bot-detection-policy.module';

import { RootStoreModule } from './root-store';

import {
    $stateParamsProvider,
    $stateProvider,
    $transitionsProvider,
    alertMetricsCollectionProvider,
    appStateTreeProvider,
    authServiceProvider,
    aviConfirmServiceProvider,
    aviModalProvider,
    backupConfigCollectionProvider,
    backupConfigItemProvider,
    clipboardServiceProvider,
    cloudCollectionProvider,
    cloudConnectorUserCollectionProvider,
    cloudItemProvider,
    cportalServiceProvider,
    defaultValuesProvider,
    faultServiceProvider,
    geoDbCollectionProvider,
    HttpWrapperProvider,
    infraCloudStateServiceProvider,
    initialDataServiceProvider,
    ipamProfileCollectionProvider,
    licensingServiceProvider,
    macroStackProvider,
    matchTargetConfigItemProvider,
    myAccountProvider,
    poolGroupCollectionProvider,
    schemaServiceProvider,
    stateManagerServiceProvider,
    stringServiceProvider,
    subnetlistNetworkCollectionProvider,
    systemConfigProvider,
    systemInfoServiceProvider,
    timeframeProvider,
    UpgradeNodeCollectionProvider,
    UpgradeServiceProvider,
    uploadProvider,
    vCenterServerCollectionProvider,
    vrfContextCollectionProvider,
    wafPolicyPsmGroupCollectionProvider,
    wafPolicyPsmGroupMetricProvider,
} from './ajs-upgraded-providers';

import './shared/common/clarity-icons';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        UpgradeModule,
        FormsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFToken',
        }),
        UIRouterUpgradeModule.forRoot({}),
        VIPModule.forRoot(),
        UIRouterModule,
        CoreModule,
        ClusterModule,
        SharedModule,
        NotificationModule,
        WafModule,
        PoolModule,
        UpdateModule,
        NatModule,
        PolicyModule,
        WebhookModule,
        LaunchConfigModule,
        L4PolicyModule,
        LicensingModule,
        CloudConnectorUserModule,
        CloudModule,
        GslbModule,
        HeaderModule,
        VerticalNavModule,
        LoginModule,
        LogsModule,
        IcapProfileModule,
        NetworkModule,
        WelcomeModule,
        IPReputationDBModule,
        TenancyModeModule,
        IpamModule,
        DialogModule,
        SystemModule,
        SecurityModule,
        AviFormsModule,
        ErrorPageModule,
        VrfContextModule,
        ScriptsModule,
        AutoScaleModule,
        CportalModule,
        PersistenceProfileModule,
        BotDetectionPolicyModule,
        VsVipModule,
        MatchModule,
        AccountsModule,
        DataGridModule,
        AlertModule,
        SnmpTrapProfileModule,
        RootStoreModule,
        StoreDevtoolsModule.instrument({
            // dev tool retains the last 20 states
            maxAge: 20,
        }),
        GroupsModule,
        RbacModule,
        SsoPolicyModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initVIPConfig,
            deps: [
                VIPService,
                LocaleService,
                I18nContext,
            ],
            multi: true,
        },
        schemaServiceProvider,
        aviConfirmServiceProvider,
        aviModalProvider,
        cloudItemProvider,
        defaultValuesProvider,
        geoDbCollectionProvider,
        UpgradeNodeCollectionProvider,
        UpgradeServiceProvider,
        licensingServiceProvider,
        initialDataServiceProvider,
        matchTargetConfigItemProvider,
        myAccountProvider,
        wafPolicyPsmGroupCollectionProvider,
        wafPolicyPsmGroupMetricProvider,
        timeframeProvider,
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        appStateTreeProvider,
        authServiceProvider,
        $stateProvider,
        $stateParamsProvider,
        $transitionsProvider,
        cloudConnectorUserCollectionProvider,
        cloudCollectionProvider,
        ipamProfileCollectionProvider,
        vCenterServerCollectionProvider,
        vrfContextCollectionProvider,
        poolGroupCollectionProvider,
        stringServiceProvider,
        stateManagerServiceProvider,
        uploadProvider,
        macroStackProvider,
        systemConfigProvider,
        backupConfigItemProvider,
        backupConfigCollectionProvider,
        faultServiceProvider,
        systemInfoServiceProvider,
        alertMetricsCollectionProvider,
        cportalServiceProvider,
        infraCloudStateServiceProvider,
        subnetlistNetworkCollectionProvider,
        HttpWrapperProvider,
        clipboardServiceProvider,
    ],
})
export class AppModule {
    public constructor(private upgrade: UpgradeModule) {}

    public ngDoBootstrap(): void {
        this.upgrade.bootstrap(document.body, ['aviApp'], { strictDi: true });
    }
}
