/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'bgp-peer-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleIpv4Peer = `${prefix}.columnTitleIpv4Peer`;
export const columnTitleIpv6Peer = `${prefix}.columnTitleIpv6Peer`;
export const columnTitlePlacementNetwork = `${prefix}.columnTitlePlacementNetwork`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleIpv4Peer]: 'IPV4 Peer',
    [columnTitleIpv6Peer]: 'IPV6 Peer',
    [columnTitlePlacementNetwork]: 'Placement Network',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
};
