/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-user-overrides-badge';
const prefix = `${moduleName}.${componentName}`;

export const userOverridesLabel = `${prefix}.userOverridesLabel`;

export const ENGLISH = {
    [userOverridesLabel]: 'User Overrides',
};
