/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IExtendedALBServicesConfig } from 'ajs/modules/cportal';

import './cportal-info-preview.component.less';
import * as l10n from './cportal-info-preview.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * Component to show the custom Fields on cPortal info full modal preview.
 * @author Ratan Kumar
 */
@Component({
    selector: 'cportal-info-preview',
    templateUrl: './cportal-info-preview.component.html',
})
export class CPortalInfoPreviewComponent {
    /**
     * ALB Services Config.
     */
    @Input()
    public config: IExtendedALBServicesConfig;

    /**
     * Flag that tells if editable is an item or a message item.
     */
    @Input()
    public readonly isItem: boolean;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(public l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }
}
