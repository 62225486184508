/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for SsoPolicy factories & services.
 *
 * @author Aravindh Nagarajan
 */

/**
 * Ajs Dependency token for SsoPolicy item.
 */
export const SSO_POLICY_ITEM_TOKEN = 'SsoPolicyItem';

/**
 * Ajs Dependency token for SsoPolicy collection.
 */
export const SSO_POLICY_COLLECTION_TOKEN = 'SsoPolicyCollection';

/**
 * Ajs Dependency token for AuthenticationPolicy ConfigItem.
 */
export const AUTHENTICATION_POLICY_CONFIG_ITEM_TOKEN = 'AuthenticationPolicyConfigItem';

/**
 * Ajs Dependency token for AuthenticationRule ConfigItem.
 */
export const AUTHENTICATION_RULE_CONFIG_ITEM_TOKEN = 'AuthenticationRuleConfigItem';

/**
 * Ajs Dependency token for AuthenticationMatch ConfigItem.
 */
export const AUTHENTICATION_MATCH_CONFIG_ITEM_TOKEN = 'AuthenticationMatchConfigItem';

/**
 * Ajs Dependency token for AuthorizationRule ConfigItem.
 */
export const AUTHORIZATION_RULE_CONFIG_ITEM_TOKEN = 'AuthorizationRuleConfigItem';

/**
 * Ajs Dependency token for AuthorizationMatch ConfigItem.
 */
export const AUTHORIZATION_MATCH_CONFIG_ITEM_TOKEN = 'AuthorizationMatchConfigItem';

/**
 * Ajs Dependency token for AuthorizationPolicy ConfigItem.
 */
export const AUTHORIZATION_POLICY_CONFIG_ITEM_TOKEN = 'AuthorizationPolicyConfigItem';

/**
 * Ajs Dependency token for AuthorizationAction ConfigItem.
 */
export const AUTHORIZATION_ACTION_CONFIG_ITEM_TOKEN = 'AuthorizationActionConfigItem';
