/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'nsxt-configuration';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const credentialSectionHeader = `${prefix}.credentialSectionHeader`;
export const managerAddressInputLabel = `${prefix}.managerAddressInputLabel`;
export const managerCredentialSelectLabel = `${prefix}.managerCredentialSelectLabel`;
export const managerNotSetPlaceholder = `${prefix}.managerNotSetPlaceholder`;

export const ENGLISH = {
    [sectionTitle]: 'NSX-T',
    [credentialSectionHeader]: 'Credentials',
    [managerAddressInputLabel]: 'NSX-T Manager Address',
    [managerCredentialSelectLabel]: 'NSX-T Manager Credentials',
    [managerNotSetPlaceholder]: 'Not Set',
};
