/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { IInfraCloudStateModule } from './infra-cloud-store/infra-cloud-store.module';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot({}),
        IInfraCloudStateModule,
    ],
    declarations: [],
})
export class RootStoreModule {}
