/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { isUndefined } from 'underscore';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

export const POLICY_MATCH_OR_ACTION_WRAPPER_EXTENDABLE_CONFIG_ITEM_TOKEN =
    'PolicyMatchOrActionWrapperConfigItem';

/**
 * @alias PolicyMatchOrActionWrapperConfigItem
 * @description
 *
 *    Abstract class that wraps configurable entries. To be extended by policy match or action
 *    wrappers, ie. PolicyMatchConfigItem and PolicyActionConfigItem.
 *
 * @author Zhiqian Liu
 */
export abstract class PolicyMatchOrActionWrapperConfigItem<T> extends MessageItem<T> {
    /**
     * List of entry field names.
     */
    public readonly fields: string[];

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<T> {
        return {} as unknown as Partial<T>;
    }

    /**
     * Return the number of configurable entries.
     */
    public get entryCount(): number {
        return this.fields.filter(this.hasEntryByField).length;
    }

    constructor(args: {} = {}) {
        super(args);

        const schemaService = this.getAjsDependency_('schemaService');

        this.fields = schemaService.getObjectFieldNames(this.objectType) || [];
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public canFlatten_(): boolean {
        return this.hasAnyEntry();
    }

    /**
     * Return true if any configurable entry exists in this.config.
     */
    public hasAnyEntry(): boolean {
        return this.entryCount > 0;
    }

    /**
     * Add an empty ConfigItem for the entry.
     * @param entryFieldName - Name of the field of the entry to add.
     */
    protected addEntry(entryFieldName: string): void {
        this.setNewChildByField(entryFieldName);
    }

    /**
     * Remove the entry from the configuration.
     * @param entryFieldName - Name of the field of the entry to remove.
     */
    protected removeEntry(entryFieldName: string): void {
        const RepeatedMessageItem = this.getAjsDependency_('RepeatedMessageItem');
        const entry = this.config[entryFieldName];

        if (entry instanceof RepeatedMessageItem) {
            entry.removeAll();
        } else {
            delete this.config[entryFieldName];
        }
    }

    /**
     * Return true if an entry exists in this.config. We check for a RepeatedMessageItem
     * because of some entries are RepeatedMessageItem such as the HdrMatch.
     * Defined as an arrow function because member function "this" is not preserved when being used
     * as a callback in Underscore or Array methods.
     * @param entryFieldName - Name of the entry field name.
     */
    protected hasEntryByField = (entryFieldName: string): boolean => {
        const entry = this.config[entryFieldName];
        const RepeatedMessageItem = this.getAjsDependency_('RepeatedMessageItem');

        return entry instanceof RepeatedMessageItem ? !entry.isEmpty() : !isUndefined(entry);
    };
}

PolicyMatchOrActionWrapperConfigItem.ajsDependencies = [
    'RepeatedMessageItem',
    'schemaService',
];
