/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    WafApplicationSignatureProvider,
} from 'ajs/modules/waf';
import './waf-application-rules-list-header.component.less';
import { CportalService } from 'ajs/modules/cportal/services';
import { ALBServicesRegistrationStatus } from 'generated-types';
import * as l10n from './waf-application-rules-list-header.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export enum ApplicationRulesDBStatus {
    ACTIVE = 'ACTIVE',
    ERROR = 'ERROR',
}

export enum ApplicationRulesDBRegistrationStatus {
    UNREGISTERED = 'UNREGISTERED',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
}

export enum ApplicationRulesDBDisplayStatus {
    ACTIVE = 'ACTIVE',
    ERROR = 'ERROR',
    UNREGISTERED = 'UNREGISTERED',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
}

const combinedStatuses = {
    ...ApplicationRulesDBStatus,
    ...ApplicationRulesDBRegistrationStatus,
};

/**
 * Hash mapping enums from ApplicationRulesDBRegistrationStatus and ApplicationRulesDBStatus to
 * ApplicationRulesDBDisplayStatus.
 */
const statusHash = Object.keys(combinedStatuses).reduce((hash, status) => {
    return {
        ...hash,
        [status]: ApplicationRulesDBDisplayStatus[status],
    };
}, {});

/**
 * @description Component for the Application rules list header.
 * Display Title and Pulse related information.
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'waf-application-rules-list-header',
    templateUrl: './waf-application-rules-list-header.component.html',
})
export class WafApplicationRulesListHeaderComponent {
    /**
     * Status of the Application Rules DB. If UNREGISTERED, displays the banner to register the
     * controller. If DISABLED, displays the banner to edit pulse settings. If ENABLED or ERROR,
     * shows the green or red status icon.
     */
    @Input()
    public applicationSignatureProvider: WafApplicationSignatureProvider;

    /**
     * Called when the user clicks the Register/Edit Pulse settings link in the banner.
     */
    @Output()
    public onEditPulseSettings = new EventEmitter();

    /**
     * Error message to be displayed in the tooltip.
     */
    public error: string | undefined;

    /**
     * ApplicationRulesDBDisplayStatus enum, set as a property to be compared in the template.
     */
    public readonly ApplicationRulesDBDisplayStatus = ApplicationRulesDBDisplayStatus;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Registration status. Can be UNREGISTERED, DISABLED, or ENABLED.
     */
    private registrationStatus: ApplicationRulesDBRegistrationStatus =
    ApplicationRulesDBRegistrationStatus.ENABLED;

    constructor(
        private readonly cportalService: CportalService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setRegistrationStatus();
        this.error = this.applicationSignatureProvider.serviceStatusError;
    }

    /**
     * Called when the user clicks the Register/Edit Pulse settings link in the banner.
     */
    public onEditPulseSettingsHandler(): void {
        this.onEditPulseSettings.emit();
    }

    /**
     * Returns the error status of the Application Rules DB item.
     * If the error is 'SUCCESS' then there is no error.
     */
    public get applicationRulesDBStatus(): ApplicationRulesDBStatus {
        return this.applicationSignatureProvider.serviceStatusError === 'SUCCESS' ?
            ApplicationRulesDBStatus.ACTIVE :
            ApplicationRulesDBStatus.ERROR;
    }

    /**
     * Returns the Application Rules status from pulse settings. If the controller is not registered
     * with pulse, returns UNREGISTERED. If registered, but the feature has not been opted-in, then
     * the status is DISABLED. If registered and opted-in, returns ENABLED.
     */
    public async getRegistrationStatus(): Promise<ApplicationRulesDBRegistrationStatus> {
        await this.cportalService.loadRegistrationStatus();

        const {
            registration_status: registrationStatus,
        } = this.cportalService.getRegistrationStatusDetails();

        if (registrationStatus !== ALBServicesRegistrationStatus.ALBSERVICES_REGISTERED) {
            return ApplicationRulesDBRegistrationStatus.UNREGISTERED;
        }

        const info = await this.cportalService.getPortalInfo();
        const { feature_opt_in_status: optInStatus } = info;
        const { enable_appsignature_sync: enableAppSignatureSync } = optInStatus;

        if (!enableAppSignatureSync) {
            return ApplicationRulesDBRegistrationStatus.DISABLED;
        }

        return ApplicationRulesDBRegistrationStatus.ENABLED;
    }

    /**
     * Returns the overall status, which is a combination of both the registration status and the
     * ApplicationRulesDBStatus item status.
     * If the active Application Rules DB Status item is not available yet,
     * the registration status is shown (REGISTERED, DISABLED, or ENABLED). When the Application
     * Rules DB Status item is available, the status of the item is shown.
     */
    public get status(): ApplicationRulesDBDisplayStatus {
        if (!this.applicationSignatureProvider) {
            return statusHash[this.registrationStatus];
        }

        return this.registrationStatus !== ApplicationRulesDBRegistrationStatus.ENABLED ?
            statusHash[this.registrationStatus] :
            statusHash[this.applicationRulesDBStatus];
    }

    /**
     * Sets the registration status.
     */
    private async setRegistrationStatus(): Promise<void> {
        this.registrationStatus = await this.getRegistrationStatus();
    }
}
