/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IL4PolicySet } from 'generated-types';
import { L4PolicySet as L4PolicySetObjectType } from 'object-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/utils/mixins/with-full-modal.mixin';

import {
    L4PolicySetModalComponent,
} from 'ng/modules/l4-policy/components/l4-policy-set-modal/l4-policy-set-modal.component';

import { L4ConnectionPolicyConfigItem } from '.';

interface IL4PolicySetConfig extends Omit<IL4PolicySet, 'l4_connection_policy'> {
    l4_connection_policy?: L4ConnectionPolicyConfigItem;
}

interface IL4PolicySetData {
    config: IL4PolicySetConfig,
}

/**
 * @desc L4PolicySet ObjectTypeItem class.
 * @author Zhiqian Liu
 */
export class L4PolicySet extends withFullModalMixin(ObjectTypeItem) {
    public data: IL4PolicySetData;

    /**
     * Getter for L4 Connection Policy.
     */
    public get l4ConnectionPolicy(): L4ConnectionPolicyConfigItem {
        return this.config.l4_connection_policy;
    }

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'l4policyset',
            objectType: L4PolicySetObjectType,
            permissionName: 'PERMISSION_L4POLICYSET',
            windowElement: L4PolicySetModalComponent,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected requiredFields(): string[] {
        return ['l4_connection_policy'];
    }
}
