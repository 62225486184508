/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'azure-dns';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const noneLabel = `${prefix}.noneLabel`;
export const azureDnsLabel = `${prefix}.azureDnsLabel`;
export const otherDnsLabel = `${prefix}.otherDnsLabel`;
export const dnsProviderLabel = `${prefix}.dnsProviderLabel`;
export const dnsProviderPlaceholder = `${prefix}.dnsProviderPlaceholder`;
export const enableStateBasedDnsRegistrationLabel = `${prefix}.enableStateBasedDnsRegistrationLabel`;

export const ENGLISH = {
    [sectionTitle]: 'DNS',
    [noneLabel]: 'None',
    [azureDnsLabel]: 'Azure DNS',
    [otherDnsLabel]: 'Other DNS',
    [dnsProviderLabel]: 'DNS Provider',
    [dnsProviderPlaceholder]: 'Select Provider',
    [enableStateBasedDnsRegistrationLabel]: 'Enable State Based DNS Registration',
};
