/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { TCPProxyProfile } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ITCPProxyProfile } from 'generated-types';
import * as l10n from './tcp-proxy.l10n';
import './tcp-proxy.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Special values of passthrough.
 */
const IP_DSCP_PASS_VALUE = 2147483647;

/**
 * @description TCP Proxy setting component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'tcp-proxy',
    templateUrl: './tcp-proxy.component.html',
})
export class TcpProxyComponent {
    /**
     * TCP Proxy profile data.
     */
    @Input()
    public editable: MessageItem<ITCPProxyProfile>;

    /**
     * Object type for tcp Proxy.
     */
    public readonly objectType = TCPProxyProfile;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * NgModel field will determine if ipDscp Pass '2147483647' then true else false.
     */
    public ipDscpPass: boolean;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.ipDscpPass = this.editable.config.ip_dscp === IP_DSCP_PASS_VALUE;
    }

    /**
     * Method will update ip_dscp based on ipDscpPass value change.
     */
    public onIpDscpPassChange(): void {
        this.editable.config.ip_dscp = this.ipDscpPass ? IP_DSCP_PASS_VALUE : 0;
    }
}
