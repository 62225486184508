/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'geo-profile-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleEntries = `${prefix}.columnTitleEntries`;
export const disabledCreateButtonLabel = `${prefix}.disabledCreateButtonLabel`;

export const ENGLISH = {
    [columnTitleEntries]: 'Entries (priority)',
    [disabledCreateButtonLabel]: 'Maximum 1 Geo Profile Allowed',
};
