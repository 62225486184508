/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { MethodMatchConfigItem } from 'ajs/modules/match';
import { HTTPMethod } from 'generated-types';
import * as l10n from './http-method-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for http-method-match.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'http-method-match-readonly',
    templateUrl: './http-method-match-readonly.component.html',
})
export class HttpMethodMatchReadonlyComponent {
    @Input()
    public editable: MethodMatchConfigItem;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get text label for selected match criteria.
     */
    public get matchCriteria(): string {
        return this.schemaService.getEnumValueLabel(
            'MatchOperation',
            this.editable.config.match_criteria,
        );
    }

    /**
     * Returns list of methods, if any, corresponding to criteria.
     */
    public get methods(): HTTPMethod[] | undefined {
        return this.editable.config.methods;
    }

    /**
     * Returns 'description' for passed in HTTPMethod.
     */
    public transformedMatchMethod(val: HTTPMethod): string {
        return this.schemaService.getEnumValueDescription('HTTPMethod', val);
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
