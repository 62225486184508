/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'icap-log-headers';
const prefix = `${moduleName}.${componentName}`;

export const icapHeadersSentToServerLabel = `${prefix}.icapHeadersSentToServerLabel`;
export const icapHeadersReceivedFromServerLabel = `${prefix}.icapHeadersRecievedFromServerLabel`;

export const ENGLISH = {
    [icapHeadersSentToServerLabel]: 'ICAP Headers Sent to Server',
    [icapHeadersReceivedFromServerLabel]: 'ICAP Headers Received From Server',
};
