/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './waf-psm-match-element-config-form.less';
import * as l10n from './waf-psm-match-element-config-form.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias waf/wafPsmMatchElementConfigForm
 * @private
 */
class WafPsmMatchElementConfigFormComponent {
    constructor(
        dropDownUtils,
        l10nService,
    ) {
        this.wafVariableOptions = dropDownUtils.getEnumDropdownOptions('WafVariable');
        this.criteriaOptions = dropDownUtils.getEnumDropdownOptions('StringOperation');

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

WafPsmMatchElementConfigFormComponent.$inject = [
    'dropDownUtils',
    'l10nService',
];

/**
 * @ngdoc component
 * @name wafPsmMatchElementConfigForm
 * @module waf/wafPsmMatchElementConfigForm
 * @desc Component for editing a PSM rule match element.
 * @param {WafPSMMatchElement} matchElement
 * @param {Function} onClose - Called when the user clicks the close button.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/waf').component('wafPsmMatchElementConfigForm', {
    bindings: {
        matchElement: '<',
        onClose: '&',
        preventEdit: '<',
    },
    controller: WafPsmMatchElementConfigFormComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-rule-modal/' +
        'waf-psm-match-element-config/waf-psm-match-element-config-form/' +
        'waf-psm-match-element-config-form.component.html',
});
