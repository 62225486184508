/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'cportal-info-modal';
const prefix = `${moduleName}.${componentName}`;

export const caseManagementLabel = `${prefix}.caseManagementLabel`;
export const ipReputationLabel = `${prefix}.ipReputationLabel`;
export const appSignaturesSyncLabel = `${prefix}.appSignaturesSyncLabel`;
export const generalHeader = `${prefix}.generalHeader`;
export const accountNameInputLabel = `${prefix}.accountNameInputLabel`;
export const organizationNameInputLabel = `${prefix}.organizationNameInputLabel`;
export const departmentInputPlaceholder = `${prefix}.departmentInputPlaceholder`;
export const contactInputLabel = `${prefix}.contactInputLabel`;
export const contactInputPlaceholder = `${prefix}.contactInputPlaceholder`;
export const optInHeader = `${prefix}.optInHeader`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleAdd = `${prefix}.modalTitleAdd`;
export const signaturesLabel = `${prefix}.signaturesLabel`;
export const systemProxyLabel = `${prefix}.systemProxyLabel`;
export const enableCaseManagementLabel = `${prefix}.enableCaseManagementLabel`;
export const enableProactiveSupportLabel = `${prefix}.enableProactiveSupportLabel`;
export const enableAutomaticCasesSystemFailureLabel = `${prefix}.enableAutomaticCasesSystemFailureLabel`;
export const enableAutomaticCasesServiceFailureLabel = `${prefix}.enableAutomaticCasesServiceFailureLabel`;
export const threatIntelligenceLabel = `${prefix}.threatIntelligenceLabel`;
export const enableCrsDataLabel = `${prefix}.enableCrsDataLabel`;
export const enableAutoDownloadWafLabel = `${prefix}.enableAutoDownloadWafLabel`;
export const enableApplicationRulesDbLabel = `${prefix}.enableApplicationRulesDbLabel`;
export const enableAutoSyncIpLabel = `${prefix}.enableAutoSyncIpLabel`;
export const proxyHeader = `${prefix}.proxyHeader`;
export const enableWafManagementLabel = `${prefix}.enableWafManagementLabel`;
export const enableUserAgentDbManagementLabel = `${prefix}.enableUserAgentDbManagementLabel`;
export const userAgentDbLabel = `${prefix}.userAgentDbLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Cloud Services Settings',
    [modalTitleAdd]: 'Add Cloud Services Settings',
    [caseManagementLabel]: 'Case Management',
    [signaturesLabel]: 'Signatures',
    [ipReputationLabel]: 'IP Reputation',
    [appSignaturesSyncLabel]: 'Application Rules',
    [generalHeader]: 'General',
    [accountNameInputLabel]: 'Account',
    [organizationNameInputLabel]: 'Organization',
    [departmentInputPlaceholder]: 'Select Account Name',
    [contactInputLabel]: 'Default Contact',
    [contactInputPlaceholder]: 'Contacts',
    [optInHeader]: 'Opt In',
    [systemProxyLabel]: 'Use Cloud Services Split Proxy',
    [enableCaseManagementLabel]: 'Enable Case Management',
    [enableWafManagementLabel]: 'Enable Cloud Services WAF Management',
    [enableProactiveSupportLabel]: 'Proactive Support',
    [enableAutomaticCasesSystemFailureLabel]: 'Enable automatic cases on system failure',
    [enableAutomaticCasesServiceFailureLabel]: 'Enable automatic cases on service engine failure',
    [threatIntelligenceLabel]: 'Threat Intelligence',
    [enableCrsDataLabel]: 'Receive notifications when new CRS data is available',
    [enableAutoDownloadWafLabel]: 'Enable auto download WAF Signatures',
    [enableApplicationRulesDbLabel]: 'Enable auto-sync Application Rules DB updates',
    [enableAutoSyncIpLabel]: 'Enable auto-sync IP Reputation DB updates',
    [proxyHeader]: 'Proxy',
    [enableUserAgentDbManagementLabel]: 'Enable User Agent DB Sync',
    [userAgentDbLabel]: 'User Agent DB',
};
