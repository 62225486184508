/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ip-reputation-db';
const componentName = 'ip-reputation-db-list-page-card';
const prefix = `${moduleName}.${componentName}`;

export const ipReputationDB = `${prefix}.ipReputationDB`;
export const nameFieldLabel = `${prefix}.nameFieldLabel`;
export const descriptionFieldLabel = `${prefix}.descriptionFieldLabel`;
export const vendorFieldLabel = `${prefix}.vendorFieldLabel`;
export const filenameLabel = `${prefix}.filenameLabel`;
export const fileTypeLabel = `${prefix}.fileTypeLabel`;
export const lastUpdate = `${prefix}.lastUpdate`;

export const ENGLISH = {
    [ipReputationDB]: 'IP Reputation DB',
    [nameFieldLabel]: 'Name',
    [descriptionFieldLabel]: 'Description',
    [vendorFieldLabel]: 'Vendor',
    [filenameLabel]: 'Filename',
    [fileTypeLabel]: 'Type',
    [lastUpdate]: 'Last Update {0}',
};
