/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @file
 *
 * List of UI only permissions used in Navbar, its related types and utils.
 */

export const uiOnlyPermissions = [
    'UI_HELP',
    'UI_MAIN_MENU',
    'UI_NAVBAR',
    'UI_USER_MENU',
];

export interface IUIPermissionHash {
    UI_HELP: string;
    UI_MAIN_MENU: string;
    UI_NAVBAR: string;
    UI_USER_MENU: string;
}

/**
 * Returns UI only permission mapping with passed access level.
 * @param access READ_ACCESS/WRITE_ACCESS/NO_ACCESS
 */
export const getUiOnlyPermissionMapping = (access: string): IUIPermissionHash => {
    return uiOnlyPermissions
        .reduce((permissionHash: IUIPermissionHash, permission: string) => {
            permissionHash[permission] = access;

            return permissionHash;
        }, {} as any as IUIPermissionHash);
};
