/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IPromise } from 'angular';
import { Base } from 'ajs/modules/data-model/factories';

import {
    IALBServicesStatus,
    ICluster,
    IGslb,
    ISystemConfiguration,
    LicenseTierType,
} from 'generated-types';

interface ISystemInfo {
    albservices: IALBServicesStatus;
    cluster: ICluster;
    gslb: IGslb;
    systemconfiguration: ISystemConfiguration;
}

interface ISystemInfoApiResponse {
    data: ISystemInfo;
}

/**
 * @description
 *     Sharable instance of system info data with a few convenience methods. Not an Item.
 * @author Alex Malitsky, Rajawant Prajapati
 */
export class SystemInfoService extends Base {
    /**
     * Duration in seconds for which loaded data is considered "fresh" (cached).
     */
    private static readonly dataCacheMaxAge = 15;

    /**
     * When set to true load will pull data from API, would use cache (this.data) otherwise.
     */
    private dataCacheExpired = true;

    /**
     * Timeout id returned by setTimeout. NaN by default.
     */
    private dataCacheInvTimeout = NaN;

    /**
     * Actual data of a System Info service.
     */
    private data: ISystemInfo = null;

    /**
     * Internal property to figure out whether loading is in process.
     */
    private loadPromise: IPromise<ISystemInfo> = null;

    /**
     * Checks whether it is loaded.
     */
    public isReady(): boolean {
        return Boolean(this.getData());
    }

    /**
     * Returns systemInfo data object.
     */
    public getData(): ISystemInfo | null {
        return this.data;
    }

    /**
     * Returns the default_license_tier.
     */
    public get defaultLicenseTier(): LicenseTierType | undefined {
        return this.systemConfiguration?.default_license_tier;
    }

    /**
     * Loads system info data. Only one pending API call may be present in any point of time.
     * Made for ui-router dependencies so that many states could request info data and it won't
     * cause multiple API calls. Also, if loaded, data is considered "fresh", no reloading will
     * be done, and cached version will be used instead.
     */
    public load(force = false): IPromise<ISystemInfo> {
        let promise = this.loadPromise;

        if (!promise) {
            if (force || this.dataCacheExpired) {
                this.loadPromise =
                    this.request<ISystemInfo>('get', '/api/system-info', null, undefined, 'default')
                        .then(this.onLoad)
                        .finally((): null => this.loadPromise = null);

                promise = this.loadPromise;
            } else {
                promise = Promise.resolve(this.getData());
            }
        }

        return promise;
    }

    /**
     * Checks whether back-end has a GSLB configuration to show or hide some navigational tabs.
     */
    public haveGSLBConfig(): boolean {
        return this.isReady() && 'leader_cluster_uuid' in this.getData().gslb;
    }

    /**
     * Returns cluster object.
     */
    public get clusterInfo(): ICluster | null {
        return this.getData().cluster ?? null;
    }

    /**
     * Returns the systemconfiguration.
     */
    private get systemConfiguration(): ISystemConfiguration | null {
        return this.getData()?.systemconfiguration ?? null;
    }

    /**
     * Processes and saves data provided by the backend. Also sets cache invalidation timeout.
     */
    private onLoad = ({ data }: ISystemInfoApiResponse): ISystemInfo => {
        this.data = data;

        this.dataCacheExpired = false;

        clearTimeout(this.dataCacheInvTimeout);

        this.dataCacheInvTimeout =
            +setTimeout(this.invalidateDataCache, SystemInfoService.dataCacheMaxAge * 1000);

        return data;
    };

    /**
     * Sets data cache expired flag to true and drops pending timeout if present.
     */
    private invalidateDataCache = (): void => {
        this.dataCacheExpired = true;
        clearTimeout(this.dataCacheInvTimeout);
        this.dataCacheInvTimeout = NaN;
    };

    /**
     * Compares GSLB config leader cluster_id of with cluster_id of a current controller.
     * Used by GSLB related Items and Collections to figure out whether they are changeable.
     */
    public get localSiteIsGSLBLeader(): boolean {
        return this.haveGSLBConfig() &&
            this.getData().gslb.leader_cluster_uuid === this.localClusterId;
    }

    /**
     * Returns a current\local cluster uuid.
     */
    public get localClusterId(): string | undefined {
        return this.clusterInfo.uuid;
    }

    /**
     * Returns a current\local cluster name.
     */
    public get localClusterName(): string | undefined {
        return this.clusterInfo.name;
    }

    /**
     * Returns GSLB id.
     */
    public get gslbId(): string | undefined {
        return this.getData().gslb.uuid;
    }

    /**
     * Checks whether the SE is in provider context or not
     * with the tenant settings changed to per-tenant SE mode
     */
    public get seInProviderContext(): boolean | undefined {
        const { systemconfiguration: systemConfig } = this.getData();
        const { global_tenant_config: globalTenantConfig } = systemConfig;

        return globalTenantConfig.se_in_provider_context;
    }

    /**
     * Returns true if defaultLicenseTier is
     * ENTERPRISE OR ENTERPRISE_WITH_CLOUD_SERVICES License Tier.
     */
    public get isEnterpriseOrEnterpriseWithCloudServicesTier(): boolean {
        return this.defaultLicenseTier === LicenseTierType.ENTERPRISE ||
            this.defaultLicenseTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES;
    }

    /**
     * Returns true if defaultLicenseTier is Essentials.
     */
    public get isEssentialsLicense(): boolean {
        return this.defaultLicenseTier === LicenseTierType.ESSENTIALS;
    }
}
