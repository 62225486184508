/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'notification';
const componentName = 'vs-faults';
const prefix = `${moduleName}.${componentName}`;

export const virtualServiceFaultsTitle = `${prefix}.virtualServiceFaultsTitle`;
export const serverFaultsTitle = `${prefix}.serverFaultsTitle`;

export const ENGLISH = {
    [virtualServiceFaultsTitle]: 'Virtual Service Faults',
    [serverFaultsTitle]: 'Server Faults',
};
