/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    ControlScriptCollection,
    TControlScriptCollection,
} from 'ajs/modules/scripts';

import {
    CertificateManagement,
    CustomParamsConfigItem,
} from 'ajs/modules/security/factories/certificate-management';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import * as l10n from './certificate-management-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Certificate Management Modal.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'certificate-management-modal',
    templateUrl: './certificate-management-modal.component.html',
})
export class CertificateManagementModalComponent implements OnInit, OnDestroy {
    /**
     * CertificateManagement ObjectTypeItem.
     */
    @Input()
    public editable: CertificateManagement;

    /**
     * CertificateManagement objectType.
     */
    public objectType: string;

    /**
     * Layout for CertificateManagement modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Control script collection instance.
     */
    public readonly controlScriptCollection: ControlScriptCollection;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(ControlScriptCollection)
        ControlScriptCollection: TControlScriptCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.controlScriptCollection = new ControlScriptCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Destroy controlScriptCollection instance.
     * @override
     */
    public ngOnDestroy(): void {
        this.controlScriptCollection.destroy();
    }

    /**
     * Returns custom script params count.
     */
    public get customScriptParamsCount(): number {
        return this.editable.scriptParams.count;
    }

    /**
     * Handles Script Param add operation.
     */
    public onAddScriptParam(): void {
        this.editable.addScriptParam();
    }

    /**
     * Handles Script Param edit operation.
     */
    public onEditScriptParam(scriptParam: CustomParamsConfigItem): void {
        this.editable.editScriptParam(scriptParam);
    }

    /**
     * Handles Script Param remove operation.
     */
    public onRemoveScriptParam(scriptParam: CustomParamsConfigItem): void {
        this.editable.removeScriptParam(scriptParam);
    }
}
