/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { IAviDataGridConfig } from 'ng/shared/components';
import { BgpRoutingOptionsConfigItem } from 'ajs/modules/vrf-context';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './bgp-routing-options-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Grid component for Bgp Advanced Routing options.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'bgp-routing-options-grid',
    templateUrl: './bgp-routing-options-grid.component.html',
})
export class BgpRoutingOptionsGridComponent implements AfterViewInit {
    /**
     * BGP Routing options RepeatedMessageItem instance.
     */
    @Input()
    public routingOptions: RepeatedMessageItem<BgpRoutingOptionsConfigItem>;

    /**
     * Fires on add Routing options.
     */
    @Output()
    public onAddRoutingOptions: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on edit Routing options.
     */
    @Output()
    public onEditRoutingOptions: EventEmitter<BgpRoutingOptionsConfigItem> = new EventEmitter();

    /**
     * Fires on delete/deleteAll Routing options.
     */
    @Output()
    public onDeleteRoutingOptions: EventEmitter<BgpRoutingOptionsConfigItem> = new EventEmitter();

    /**
     * TemplateRef for Learn Routes column.
     */
    @ViewChild('learnRoutesTemplateRef')
    public learnRoutesTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for Advertise column.
     */
    @ViewChild('advertiseRoutesTemplateRef')
    public advertiseRoutesTemplateRef: TemplateRef<HTMLElement>;

    /**
     * BGP Routing options grid config.
     */
    public bgpRoutingOptionsGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.bgpRoutingOptionsGridConfig = {
            getRowId(index, row: BgpRoutingOptionsConfigItem): string {
                return row.label;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleLabel),
                id: 'label',
                transform(bgpRoutingOptions: BgpRoutingOptionsConfigItem) {
                    return bgpRoutingOptions.label;
                },
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleLearnRoutes),
                id: 'learn_routes',
                templateRef: this.learnRoutesTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleAdvertise),
                id: 'advertise',
                templateRef: this.advertiseRoutesTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleMaxRoutes),
                id: 'max_learn_limit',
                transform(bgpRoutingOptions: BgpRoutingOptionsConfigItem) {
                    return bgpRoutingOptions.config.max_learn_limit.toString();
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (routingOptionsList: BgpRoutingOptionsConfigItem[]) => {
                    routingOptionsList.forEach((routingOptions: BgpRoutingOptionsConfigItem) => {
                        this.deleteBgpRoutingOptions(routingOptions);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (bgpRoutingOptions: BgpRoutingOptionsConfigItem) => {
                    this.editBgpRoutingOptions(bgpRoutingOptions);
                },
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (bgpRoutingOptions: BgpRoutingOptionsConfigItem) => {
                    this.deleteBgpRoutingOptions(bgpRoutingOptions);
                },
            }],
        };
    }

    /**
     * Handler for Add routing options button.
     */
    public addBgpRoutingOptions(): void {
        this.onAddRoutingOptions.emit();
    }

    /**
     * Handler for BGP Routing options edit.
     */
    private editBgpRoutingOptions(bgpRoutingOptions: BgpRoutingOptionsConfigItem): void {
        this.onEditRoutingOptions.emit(bgpRoutingOptions);
    }

    /**
     * Handler for BGP Routing options delete operation.
     */
    private deleteBgpRoutingOptions(bgpRoutingOptions: BgpRoutingOptionsConfigItem): void {
        this.onDeleteRoutingOptions.emit(bgpRoutingOptions);
    }
}
