/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'update-node-status-card';
const prefix = `${moduleName}.${componentName}`;

export const updateOperationLabel = `${prefix}.updateOperationLabel`;
export const updateFromVersionLabel = `${prefix}.updateFromVersionLabel`;
export const updateOperationStartTimeLabel = `${prefix}.updateOperationStartTimeLabel`;
export const successfulLabel = `${prefix}.successfulLabel`;
export const failedLabel = `${prefix}.failedLabel`;
export const unknownLabel = `${prefix}.unknownLabel`;

export const ENGLISH = {
    [updateOperationLabel]: 'Operation',
    [updateFromVersionLabel]: 'From',
    [updateOperationStartTimeLabel]: 'Started',
    [successfulLabel]: 'Successful',
    [failedLabel]: 'Failed',
    [unknownLabel]: 'Unknown',
};
