/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pingaccess-agent-profile-list';
const prefix = `${moduleName}.${componentName}`;

export const actionBtnDelete = `${prefix}.actionBtnDelete`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitlePool = `${prefix}.columnTitlePool`;

export const ENGLISH = {
    [actionBtnDelete]: 'Delete',
    [columnTitleName]: 'Name',
    [columnTitlePool]: 'Pool',
};
