/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { each } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IpAddrGroup } from 'object-types';
import { IpAddrGroupModalComponent } from 'ng/modules/groups';

import {
    AviPermissionResource,
    IIpAddr,
    IIpAddrPrefix,
} from 'generated-types';

import * as l10n from '../../groups.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// Interface for UI only field `servers`.
export interface IServer {
    addr: string[];
}

/**
 * @description IP address group item.
 *
 * @author Nisar Nadaf
 */
export class IpAddrGroupItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'RangeParser',
        'l10nService',
    ];

    // todo -  define type for rangeParser.
    private readonly rangeParser: any;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'ipaddrgroup',
            windowElement: IpAddrGroupModalComponent,
            objectType: IpAddrGroup,
            permissionName: AviPermissionResource.PERMISSION_IPADDRGROUP,
            ...args,
        };

        super(extendedArgs);

        this.rangeParser = this.getAjsDependency_('RangeParser');

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Gets number of IP addresses owned by this IpAddrGroup.
     */
    public getNumberOfIPs(): number {
        const { addrs, prefixes, ranges } = this.getConfig();

        let res = 0;

        // Checks number of single IP addresses and calculates number generated by prefix mask.
        if (addrs) {
            res += addrs.count;
        }

        if (prefixes) {
            res += prefixes.config.reduce(
                (acc: number, { config: { mask } } : MessageItem<IIpAddrPrefix>) => {
                    return acc + this.rangeParser.getNumberOfIpsFromPrefixLength(mask);
                }, 0,
            );
        }

        if (ranges) {
            each(ranges.config, ({ config: { begin, end } }) => {
                if (begin) {
                    const rangeStr = `${begin.config.addr}-${end.config.addr}`;

                    res += this.rangeParser.getNumberOfIpsFromRange(rangeStr);
                }
            });
        }

        return res;
    }

    /**
     * Returns all IpAddr objects owned by this IpAddrGroup.
     */
    public getIPs(): IIpAddr[] {
        const { addrs, ranges } = this.getConfig();
        const ips: IIpAddr[] = [];

        each(addrs.config, (addr: MessageItem<IIpAddr>) => {
            if (addr) {
                ips.push(addr.config);
            }
        });

        if (ranges) {
            each(ranges.config, ({ config: { begin, end } }) => {
                if (begin) {
                    const rangeStr = `${begin.config.addr}-${end.config.addr}`;

                    ips.push(...this.rangeParser.getIpsFromRange(rangeStr));
                }
            });
        }

        return ips;
    }

    /**
     * Returns an array of all addresses in this.config.
     */
    public get addresses(): IServer[] {
        const { addrs, ranges, prefixes } = this.config;

        const servers: IServer[] = [];

        each(addrs.config, ({ config: { addr } }) => {
            if (addr) {
                servers.push({ addr: [addr] });
            }
        });

        each(ranges.config, ({ config: { begin, end } }) => {
            if (begin) {
                servers.push({ addr: [`${begin.config.addr}-${end.config.addr}`] });
            }
        });

        each(prefixes.config, ({ config: { ip_addr, mask } }) => {
            if (ip_addr.config) {
                servers.push({ addr: [`${ip_addr.config.addr}/${mask}`] });
            }
        });

        return servers;
    }

    /**
     * Remove all IP Addresses from config.
     */
    public removeIpAddresses(): void {
        const {
            addrs,
            prefixes,
            ranges,
        } = this.config;

        const repeatedMessageItems = [addrs, prefixes, ranges];

        repeatedMessageItems.forEach(repeatedMessageItem => repeatedMessageItem.removeAll());
    }

    /**
     * Remove country codes from config.
     */
    public removeCountryCodes(): void {
        delete this.config.country_codes;
    }

    /**
     * Returns true if config has country codes.
     */
    public get hasCountryCodes(): boolean {
        const { country_codes: countryCodes } = this.config;

        return Boolean(countryCodes);
    }

    /**
     * getter for count of IP Addresses.
     */
    public get ipAddrCount(): number {
        const {
            addrs,
            prefixes,
            ranges,
        } = this.config;

        return addrs.count + prefixes.count + ranges.count;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.ipAddrgroupModalBreadcrumbTitle);
    }
}
