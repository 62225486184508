/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @name appStateConstants
 * @type {Object<string, string>}
 * @desc Enums for ui-router states commonly used in redirection by appStateHandler.
 */
export const appStates = {
    DEFAULT_STATE: 'authenticated.application.dashboard',
    LOGIN_STATE: 'login',
    LOGGED_OUT_STATE: 'logged-out',
    WELCOME_STATE: 'welcome',
    PLEASE_RELOAD_STATE: 'please-reload',
    CONTROLLER_DOWN_STATE: '503',
    UPGRADE_STATE: 'authenticated.administration.controller-detail.system-update',
    PRE_WELCOME_AWS_STATE: 'pre-welcome-aws',
    ADMIN_USER_SETUP_STATE: 'admin-user-setup',
};
