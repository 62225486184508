/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'my-account-modal-service';
const prefix = `${moduleName}.${componentName}`;

export const myAccountLabel = `${prefix}.myAccountLabel`;

export const ENGLISH = {
    [myAccountLabel]: 'My Account',
};
