/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential.
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'bgp-profile-list';
const prefix = `${moduleName}.${componentName}`;

export const editEnableBgpButtonLabel = `${prefix}.editEnableBgpButtonLabel`;
export const bgpPeeringLabel = `${prefix}.bgpPeeringLabel`;
export const bgpPeeringWithVrfLabel = `${prefix}.bgpPeeringWithVrfLabel`;
export const bgpPeeringDisabledLabel = `${prefix}.bgpPeeringDisabledLabel`;
export const bgpPeeringDisabledWithVrfLabel = `${prefix}.bgpPeeringDisabledWithVrfLabel`;
export const addBgpButtonLabel = `${prefix}.addBgpButtonLabel`;

export const ENGLISH = {
    [editEnableBgpButtonLabel]: 'Edit/Enable BGP',
    [addBgpButtonLabel]: 'Add',
    [bgpPeeringLabel]: 'BGP Peering',
    [bgpPeeringWithVrfLabel]: 'BGP Peering for <strong>{0}</strong> VRF Context',
    [bgpPeeringDisabledLabel]: 'BGP Peering is disabled',
    [bgpPeeringDisabledWithVrfLabel]: 'BGP Peering for <strong>{0}</strong> VRF Context is ' +
        'disabled',
};
