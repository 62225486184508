/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-placement-network-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const placementNetworkLabel = `${prefix}.placementNetworkLabel`;
export const placementNetworkPlaceholder = `${prefix}.placementNetworkPlaceholder`;
export const ipv4SubnetDropdownLabel = `${prefix}.ipv4SubnetDropdownLabel`;
export const ipv4SubnetDropdownPlaceholder = `${prefix}.ipv4SubnetDropdownPlaceholder`;
export const ipv6SubnetDropdownLabel = `${prefix}.ipv6SubnetDropdownLabel`;
export const ipv6SubnetDropdownPlaceholder = `${prefix}.ipv6SubnetDropdownPlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Placement Network',
    [modalTitleNew]: 'Create Placement Network',
    [generalSectionHeader]: 'General',
    [placementNetworkLabel]: 'Placement Network',
    [placementNetworkPlaceholder]: 'Enter Placement Network',
    [ipv4SubnetDropdownLabel]: 'IPv4 Subnet',
    [ipv4SubnetDropdownPlaceholder]: 'Select IPv4 Subnet',
    [ipv6SubnetDropdownLabel]: 'IPv6 Subnet',
    [ipv6SubnetDropdownPlaceholder]: 'Select IPv6 Subnet',
};
