/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-management-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const allowListSectionTitle = `${prefix}.allowListSectionTitle`;
export const userAgentSectionTitle = `${prefix}.userAgentSectionTitle`;
export const ipReputationSectionTitle = `${prefix}.ipReputationSectionTitle`;
export const ipLocationSectionTitle = `${prefix}.ipLocationSectionTitle`;
export const botMappingSectionTitle = `${prefix}.botMappingSectionTitle`;

export const editLabel = `${prefix}.editLabel`;
export const deleteLabel = `${prefix}.deleteLabel`;
export const nameLabel = `${prefix}.nameLabel`;
export const numberOfMatchesLabel = `${prefix}.numberOfMatchesLabel`;
export const actionLabel = `${prefix}.actionLabel`;
export const enterNamePlaceholder = `${prefix}.enterNamePlaceholder`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const enterDescriptionLabel = `${prefix}.enterDescriptionLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const allowRulesLabel = `${prefix}.allowRulesLabel`;
export const useUserAgentLabel = `${prefix}.useUserAgentLabel`;
export const useIpReputationLabel = `${prefix}.useIpReputationLabel`;
export const ipReputationDbLabel = `${prefix}.ipReputationDbLabel`;
export const selectIpReputationDbLabel = `${prefix}.selectIpReputationDbLabel`;
export const ipReputationMappingLabel = `${prefix}.ipReputationMappingLabel`;
export const selectIpReputationMappingLabel = `${prefix}.selectIpReputationMappingLabel`;
export const useIpLocationDbLabel = `${prefix}.useIpLocationDbLabel`;
export const geoIpDbLabel = `${prefix}.geoIpDbLabel`;
export const selectGeoIpDbLabel = `${prefix}.selectGeoIpDbLabel`;
export const cloudProviderLabel = `${prefix}.cloudProviderLabel`;
export const selectCloudProviderLabel = `${prefix}.selectCloudProviderLabel`;
export const searchEnginesLabel = `${prefix}.searchEnginesLabel`;
export const selectSearchEnginesLabel = `${prefix}.selectSearchEnginesLabel`;
export const userBotMappingLabel = `${prefix}.userBotMappingLabel`;
export const selectUserBotMappingLabel = `${prefix}.selectUserBotMappingLabel`;
export const systemBotMappingLabel = `${prefix}.systemBotMappingLabel`;
export const selectSystemBotMappingLabel = `${prefix}.selectSystemBotMappingLabel`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create Bot Management Policy: {0}',
    [modalTitleEdit]: 'Edit Bot Management Policy: {0}',
    [generalSectionTitle]: 'General',
    [allowListSectionTitle]: 'Allow List',
    [userAgentSectionTitle]: 'User-Agent',
    [ipReputationSectionTitle]: 'IP Reputation',
    [ipLocationSectionTitle]: 'IP Location',
    [botMappingSectionTitle]: 'Bot Mapping',

    [editLabel]: 'Edit',
    [deleteLabel]: 'Delete',
    [nameLabel]: 'Name',
    [numberOfMatchesLabel]: 'Number of Matches',
    [actionLabel]: 'Action',
    [enterNamePlaceholder]: 'Enter Name',
    [descriptionLabel]: 'Description',
    [enterDescriptionLabel]: 'Enter Description',
    [addButtonLabel]: 'Add',
    [allowRulesLabel]: 'Allow Rules ({0})',
    [useUserAgentLabel]: 'Use User Agent based Bot detection',
    [useIpReputationLabel]: 'Use IP Reputation based Bot detection',
    [ipReputationDbLabel]: 'IP Reputation DB',
    [selectIpReputationDbLabel]: 'Select IP Reputation DB',
    [ipReputationMappingLabel]: 'IP Reputation Mapping',
    [selectIpReputationMappingLabel]: 'Select IP Reputation Mapping',
    [useIpLocationDbLabel]: 'Use IP Location DB',
    [geoIpDbLabel]: 'Geo-IP DB',
    [selectGeoIpDbLabel]: 'Select Geo-IP DB',
    [cloudProviderLabel]: 'Cloud Provider',
    [selectCloudProviderLabel]: 'Select Cloud Provider',
    [searchEnginesLabel]: 'Search Engine',
    [selectSearchEnginesLabel]: 'Select Search Engine',
    [userBotMappingLabel]: 'User Bot Mapping',
    [selectUserBotMappingLabel]: 'Select User Bot Mapping',
    [systemBotMappingLabel]: 'System Bot Mapping',
    [selectSystemBotMappingLabel]: 'Select System Bot Mapping',
};
