/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    ContentChild,
    forwardRef,
    Input,
    OnDestroy,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NgControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import {
    isNull,
    isUndefined,
} from 'underscore';

import { Subscription } from 'rxjs';
import { regexPatternValidator } from 'ng/modules/avi-forms/validators';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-auto-complete.l10n';
import './avi-auto-complete.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// CdsDataList accepts status string to display component's validation status.
enum CdsDataListStatus {
    error = 'error',
    neutral = 'neutral'
}

/**
 * @description Autocomplete component
 *
 *      Allows user to enter custom text input & choose from a set of suggested options.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'avi-auto-complete',
    templateUrl: './avi-auto-complete.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviAutoCompleteComponent),
        },
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AviAutoCompleteComponent),
        },
    ],
})
export class AviAutoCompleteComponent
implements AfterViewInit, ControlValueAccessor, Validator, OnDestroy {
    /**
     * Configurable placeholder.
     */
    @Input()
    public placeholder: string;

    /**
     * Configurable Name.
     */
    @Input()
    public name = 'avi-auto-complete';

    /**
     * Type of input field.
     */
    @Input()
    public type = 'text';

    /**
     * Set of options/suggestions.
     */
    @Input()
    public options: string[] = [];

    /**
     * Name of regex from regex.utils to apply to the field.
     * When value present, validates string using regexPatternValidator.
     */
    @Input()
    public regex?: string;

    /**
     * Optional Helper-text.
     * To be displayed below input field.
     */
    @Input()
    public helperText ?= '';

    /**
     * Optional Error text.
     * To be displayed when ngModel becomes invalid.
     */
    @Input()
    public errorText ?= '';

    /**
     * Transcluded ngControl instance.
     */
    @ContentChild(NgControl, { static: true })
    public ngControl: NgControl;

    /**
     * Hash of validation errors, coming from the ngControl's errors object. Updated on validation
     * status changes.
     */
    public validationErrors: ValidationErrors;

    /**
     * Set to true to make this component required.
     */
    public required: boolean;

    /**
     * ngControl statusChanges subscription. Reference stored to unsubscribe when the component is
     * destroyed.
     */
    private statusChangesSubscription: Subscription;

    /**
     * Setter for preselectOnlyOption.
     */
    @Input('preselectOnlyOption')
    private set setPreselectOnlyOption(preselectOnlyOption: boolean | '') {
        this.preselectOnlyOption = preselectOnlyOption === '' || preselectOnlyOption;
    }

    /**
     * Setter for required attribute.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || required;
    }

    /**
     * When set to true and we will take the only option from options when ngModel is not set.
     */
    private preselectOnlyOption: boolean;

    /**
     * The ngModel value.
     */
    private modelValue: string;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.placeholder = l10nService.getMessage(l10nKeys.placeholderLabel);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.preselectOnlyOption && !this.modelValue && this.options.length === 1) {
                this.value = this.options[0];
            }
        }, 0);

        if (this.ngControl) {
            this.statusChangesSubscription = this.ngControl.statusChanges.subscribe(() => {
                this.validationErrors = this.ngControl.control.errors || {};
            });
        }
    }

    /**
     * Not sure its necessary, but deleting this.classList since its a live collection.
     * @override
     */
    public ngOnDestroy(): void {
        if (this.statusChangesSubscription) {
            this.statusChangesSubscription.unsubscribe();
        }
    }

    /**
     * Getter for model value.
     */
    public get value(): string {
        return this.modelValue;
    }

    /**
     * Setter for model value.
     */
    public set value(value: string) {
        this.modelValue = value;

        this.onChange(this.modelValue);
        this.onTouched();
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackByValue(index: number, dropdownOption: string): string {
        return dropdownOption;
    }

    /**
     * Getter for component status.
     * To show red highlight on error.
     */
    public get status(): CdsDataListStatus {
        return this.invalid ? CdsDataListStatus.error : CdsDataListStatus.neutral;
    }

    /**
     * Returns true if the ngControl is invalid.
     */
    public get invalid(): boolean {
        if (!this.ngControl) {
            return false;
        }

        const { control } = this.ngControl;
        const { dirty, invalid, touched } = control;

        return (touched || dirty) && invalid;
    }

    /***************************************************************************
     * IMPLEMENTING Validator INTERFACE
    */

    /**
     * @override
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.dirty && (isUndefined(control.value) || isNull(control.value))) {
            return null;
        }

        if (this.regex) {
            return regexPatternValidator(this.regex)(control);
        }

        return null;
    }

    /*************************************************************************/

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.modelValue = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /*************************************************************************/
}
