/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Used as a default value for the height of each dropdown option.
 */
export const OPTION_HEIGHT = 30;

/**
 * Used as a default value for the height of the list of dropdown options.
 */
export const MAX_OPTIONS_LIST_HEIGHT = 195;
