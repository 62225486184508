/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    AviModalService,
    StringService,
} from 'ajs/modules/core/services';
import { SMTPType } from 'generated-types';
import * as l10n from './email-smtp-page-card.l10n';
import './email-smtp-page-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Card component for email-smtp-page.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'email-smtp-page-card',
    templateUrl: './email-smtp-page-card.component.html',
})
export class EmailSmtpPageCardComponent {
    /**
     * SystemConfigService resolved by UI router.
     */
    @Input()
    public readonly systemConfigService: any;

    public readonly l10nKeys = l10nKeys;

    /**
     * Map holding smtpSourceVal to it's l10nKey val.
     */
    private readonly smtpSourceValueHashMap = {
        [SMTPType.SMTP_ANONYMOUS_SERVER]: l10nKeys.anonymousSmtpServerLabel,
        [SMTPType.SMTP_LOCAL_HOST]: l10nKeys.localHostLabel,
        [SMTPType.SMTP_SERVER]: l10nKeys.smtpServerLabel,
    };

    constructor(
        public readonly l10nService: L10nService,
        public readonly stringService: StringService,
        private readonly aviModal: AviModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get empty data label.
     */
    public get emptyDataLabel(): string {
        return this.l10nService.getMessage(l10nKeys.emptyDataLabel);
    }

    /**
     * Converts emailSmtpType to display value.
     */
    public get smtpSourceDisplayValue(): string {
        const emailSmtpTypeL10nKey =
            this.smtpSourceValueHashMap[this.systemConfigService.emailSmtpType];

        return emailSmtpTypeL10nKey ?
            this.l10nService.getMessage(emailSmtpTypeL10nKey) : this.emptyDataLabel;
    }

    /**
     * Informs whether to show fromAddress.
     */
    public showFromAddress(): boolean {
        return this.systemConfigService.getConfig().email_configuration.hasFromEmail();
    }

    /**
     * Gets fromAddress val, if exists.
     */
    public get fromAddressValue(): string {
        return this.systemConfigService.getEmailConfig().from_email;
    }

    /**
     * Informs whether to show server, and port.
     */
    public showSmtpServerAndPort(): boolean {
        return this.systemConfigService.getConfig()
            .email_configuration.emailSmtpTypeIsServerOrAnonymous();
    }

    /**
     * Gets server val, if exists.
     */
    public get smtpServerValue(): string {
        const emailConfig = this.systemConfigService.getEmailConfig();

        return emailConfig?.mail_server_name || this.emptyDataLabel;
    }

    /**
     * Gets port val, if exists.
     */
    public get smtpPortValue(): string {
        const emailConfig = this.systemConfigService.getEmailConfig();

        return emailConfig?.mail_server_port || this.emptyDataLabel;
    }

    /**
     * Informs whether to show userName.
     */
    public showUserName(): boolean {
        return this.systemConfigService.emailSmtpType === SMTPType.SMTP_SERVER;
    }

    /**
     * Gets username, if exists.
     */
    public get username(): string {
        return this.systemConfigService.getEmailConfig().auth_username || this.emptyDataLabel;
    }

    /**
     * Informs whether to show Send Test Email action.
     */
    public showSendTestEmail(): boolean {
        const { emailSmtpType } = this.systemConfigService;

        return emailSmtpType !== SMTPType.SMTP_NONE;
    }

    /**
     * Opens Send Test Email modal.
     */
    public openSendTestEmail(): void {
        this.aviModal.open(
            'adm-email-validate',
            { type: 'smtp' },
        );
    }
}
