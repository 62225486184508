/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

class WafRulesCollapsibleListController {
    constructor($element) {
        /** @type {angular.element} */
        this.$element = $element;

        /** @type {boolean} */
        this.expanded = false;
    }

    /**
     * Returns all collapsible list elements.
     * @returns {angular.element}
     */
    getListItems() {
        return this.$element.find('.collapsible-list__waf-item');
    }

    /**
     * Collapses all collapsible list elements.
     */
    collapse() {
        this.expanded = false;
        this.getListItems().removeClass('visible');
    }

    /**
     * Expands all collapsible list elements.
     */
    expand() {
        this.expanded = true;
        this.getListItems().addClass('visible');
    }
}

WafRulesCollapsibleListController.$inject = [
    '$element',
];

/**
 * @ngdoc directive
 * @name collapsibleList
 * @restrict Attribute
 * @description
 *      Looks-up child elements by specific class to show or hide them.
 */
angular.module('aviApp').directive('wafRulesCollapsibleList', () => {
    return {
        restrict: 'A',
        controller: WafRulesCollapsibleListController,
    };
});
