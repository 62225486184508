/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import { AviPermissionResource } from 'generated-types';
import {
    ActionGroupConfigModalComponent,
} from 'ng/modules/alert/components/action-group-config/action-group-config-modal.component';

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { ActionGroupConfig } from 'object-types';
import * as l10n from '../../alert.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   ActionGroupConfig item.
 *
 * @author Guru Prasad H K
 */
export class ActionGroupConfigItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'actiongroupconfig',
            objectType: ActionGroupConfig,
            permissionName: AviPermissionResource.PERMISSION_ACTIONGROUPCONFIG,
            windowElement: ActionGroupConfigModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.alertActionBreadcrumbTitle);
    }
}
