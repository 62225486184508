/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Cloud } from 'object-types';
import * as l10n from './no-orchestrator-configuration.l10n';
import { ICloud } from '../../../cloud.types';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for No Orchestrator configuration.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'no-orchestrator-configuration',
    templateUrl: './no-orchestrator-configuration.component.html',
})
export class NoOrchestratorConfigurationComponent {
    /**
     * Editable object passed as an input from Cloud Modal.
     */
    @Input()
    public editable: ICloud;

    /**
     * Holds the object type to be used in the template.
     */
    public objectType = Cloud;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
