/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import {
    SubnetListNetwork,
    SubnetListNetworkCollection,
} from 'ajs/modules/network';

import {
    IpAddrType,
    ISubnet,
} from 'generated-types';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'ajs/modules/core/services';
import { VipPlacementNetworkConfigItem } from 'ajs/modules/vs';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown';
import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './vip-placement-network-modal.l10n';
import './vip-placement-network-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSubnetListNetworkCollection = typeof SubnetListNetworkCollection;
type TSubnetListNetwork = typeof SubnetListNetwork;

/**
 * @description VIP Placement Network Modal.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vip-placement-network-modal',
    templateUrl: './vip-placement-network-modal.component.html',
})
export class VipPlacementNetworkModalComponent implements OnInit, OnDestroy {
    /**
     * VipPlacementNetworkConfigItem instance.
     */
    @Input()
    public editable: VipPlacementNetworkConfigItem;

    /**
     * UUID of cloud.
     */
    @Input()
    public cloudId: string;

    /**
     * UUID of VRFContext.
     */
    @Input()
    public vrfContextId: string;

    /**
     * Boolean flag for editing - VipPlacementNetworkConfigItem doesnt have id,
     * so parent item factory passes this flag for indication of edit op.
     */
    @Input()
    public isEditing = false;

    /**
     * Uuids of selected networks to be excluded from backend requests.
     */
    @Input()
    public networkIdsToExclude = '';

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<VipPlacementNetworkConfigItem>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * SubnetListNetworkCollection instance.
     * Used in placement network collection-dropdown.
     */
    public subnetListNetworkCollection: SubnetListNetworkCollection;

    /**
     * ObjectType.
     */
    public objectType: string;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * subnet field dropdown options.
     */
    public subnetDropdownOptions: IAviDropdownOption[] = [];

    /**
     * subnet6 field dropdown options.
     */
    public subnet6DropdownOptions: IAviDropdownOption[] = [];

    /**
     * True when network is being loaded.
     */
    public loadingNetwork = false;

    /**
     * SubnetListNetwork instance.
     */
    private subnetListNetwork: SubnetListNetwork;

    constructor(
        @Inject(SubnetListNetworkCollection)
        private readonly SubnetListNetworkCollection: TSubnetListNetworkCollection,
        @Inject(SubnetListNetwork)
        private readonly SubnetListNetwork: TSubnetListNetwork,
        private readonly stringService: StringService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        const subnetListNetworkParams: Record<string, string> = {
            cloud_uuid: this.cloudId,
            'uuid.in': this.networkIdsToExclude,
            exclude: 'uuid.in',
        };

        if (this.vrfContextId) {
            subnetListNetworkParams.vrf_context_uuid = this.vrfContextId;
        }

        this.subnetListNetworkCollection = new this.SubnetListNetworkCollection({
            params: subnetListNetworkParams,
        });

        if (this.editable.config.network_ref) {
            this.loadSubnetListNetwork();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.subnetListNetwork) {
            this.subnetListNetwork.destroy();
        }

        this.subnetListNetworkCollection.destroy();
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Fires on placement network change.
     */
    public onNetworkChange(): void {
        const network: SubnetListNetwork =
            this.subnetListNetworkCollection.getItemById(
                this.stringService.slug(this.editable.config.network_ref),
            );

        this.editable.resetSubnets();
        this.setSubnetDropdownOptions(network);
    }

    /**
     * On Edit, Loads SubnetListNetwork instance by networkRef & sets subnet dropdown options.
     */
    private async loadSubnetListNetwork(): Promise<void> {
        this.loadingNetwork = true;

        try {
            this.subnetListNetwork = new this.SubnetListNetwork({
                id: this.stringService.slug(this.editable.config.network_ref),
                cloudId: this.cloudId,
            });

            await this.subnetListNetwork.load();

            this.setSubnetDropdownOptions(this.subnetListNetwork);
        } catch (e) {
            // empty catch block
        } finally {
            this.loadingNetwork = false;
        }
    }

    /**
     * Sets subnet & subnet6 dropdown options from subnetListNetwork.
     */
    private setSubnetDropdownOptions(network: SubnetListNetwork): void {
        const subnets: ISubnet[] = network.getSubnets();

        [
            this.subnetDropdownOptions,
            this.subnet6DropdownOptions,
        ] = subnets.reduce((
            subnetOptions: [IAviDropdownOption[], IAviDropdownOption[]],
            subnet: ISubnet,
        ) => {
            const { prefix } = subnet;
            const dropdownOption = createDropdownOption(getSubnetString(prefix));

            if (prefix.ip_addr.type === IpAddrType.V4) {
                subnetOptions[0].push(dropdownOption);
            } else {
                subnetOptions[1].push(dropdownOption);
            }

            return subnetOptions;
        }, [[], []]);
    }
}
