/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';
import { BotDetectionPolicyCollection } from 'ajs/modules/bot-detection-policy';
import template from './bot-management-policy-list-page.component.html';
import * as l10n from './bot-management-policy-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TBotDetectionPolicyCollectionType = typeof BotDetectionPolicyCollection;

/**
 * @description Bot Management page showing botDetectionPolicy collection grid.
 * @author Akul Aggarwal
 */
class BotManagementPolicyListPageController {
    public readonly l10nKeys = l10nKeys;
    public gridConfig: ICollectionGridConfig;

    constructor(
        private readonly BotDetectionPolicyCollection: TBotDetectionPolicyCollectionType,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** override */
    public $onInit(): void {
        this.initBotDetectionPolicyCollection();
    }

    /** override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }

    /** Creates bot detection grid */
    private initBotDetectionPolicyCollection(): void {
        this.gridConfig = createCrudCollectionGridConfig();

        const {
            gridConfig,
            l10nService,
        } = this;

        gridConfig.id = 'bot-management-page';
        gridConfig.collection = new this.BotDetectionPolicyCollection();
        gridConfig.fields = [
            {
                name: 'getConfig().name',
                title: l10nService.getMessage(l10nKeys.columnTitleNameLabel),
            },
        ];
    }
}

BotManagementPolicyListPageController.$inject = [
    'BotDetectionPolicyCollection',
    'l10nService',
];

export const botManagementPolicyListPageOptions = {
    controller: BotManagementPolicyListPageController,
    template,
};
