/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ipam-infoblox-usable-subnets';
const prefix = `${moduleName}.${componentName}`;

export const ipV4ColumnTitle = `${prefix}.ipV4ColumnTitle`;
export const ipv4SubnetInputPlaceholder = `${prefix}.ipv4SubnetInputPlaceholder`;
export const ipV6ColumnTitle = `${prefix}.ipV6ColumnTitle`;
export const ipv6SubnetInputPlaceholder = `${prefix}.ipv6SubnetInputPlaceholder`;
export const deleteButtonLabel = `${prefix}.deleteButtonLabel`;
export const noUsableSubnetsAlert = `${prefix}.noUsableSubnetsAlert`;
export const addButtonLabel = `${prefix}.addButtonLabel`;

export const ENGLISH = {
    [ipV4ColumnTitle]: 'IPv4',
    [ipv4SubnetInputPlaceholder]: 'Select IPv4 Subnet',
    [ipV6ColumnTitle]: 'IPv6',
    [ipv6SubnetInputPlaceholder]: 'Select IPv6 Subnet',
    [deleteButtonLabel]: 'Delete',
    [noUsableSubnetsAlert]: 'The retrieved credentials came back with no usable subnets',
    [addButtonLabel]: 'Add',
};
