/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'alert-config-grid-expander';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleEvents = `${prefix}.columnTitleEvents`;
export const columnTitleMetrics = `${prefix}.columnTitleMetrics`;

export const ENGLISH = {
    [columnTitleType]: 'Type',
    [columnTitleEvents]: 'Event(s)',
    [columnTitleMetrics]: 'Metric(s)',
};
