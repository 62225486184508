/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-confirm-change-crs-version';
const prefix = `${moduleName}.${componentName}`;

export const crsVersionUpdateHeader = `${prefix}.crsVersionUpdateHeader`;
export const changeLogHeader = `${prefix}.changeLogHeader`;
export const newRulesLabel = `${prefix}.newRulesLabel`;
export const updatedRulesLabel = `${prefix}.updatedRulesLabel`;
export const removedRulesLabel = `${prefix}.removedRulesLabel`;
export const updateVersionWarning = `${prefix}.updateVersionWarning`;
export const updateVersionConfirmation = `${prefix}.updateVersionConfirmation`;
export const saveUncommittedLabel = `${prefix}.saveUncommittedLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const okButtonLabel = `${prefix}.okButtonLabel`;

export const ENGLISH = {
    [crsVersionUpdateHeader]: 'CRS Version Update: {0}',
    [changeLogHeader]: 'Change Log',
    [newRulesLabel]: 'New Rules',
    [updatedRulesLabel]: 'Updated Rules',
    [removedRulesLabel]: 'Removed Rules',
    [updateVersionWarning]: 'As a precautionary all new rules mode will be set as detection. ' +
        'Updated rules will maintain currently set mode. Exceptions and Policy Overrides on any ' +
        'removed rules will be lost.',
    [updateVersionConfirmation]: 'Would you like to continue?',
    [saveUncommittedLabel]: 'Save uncommitted WAF Policy settings before updating',
    [cancelButtonLabel]: 'Cancel',
    [okButtonLabel]: 'OK',
};
