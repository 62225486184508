/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './ControlScriptsController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('ControlScriptsController', [
'$scope',
'CRUDGridConfig',
'ControlScriptCollection',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    ControlScriptCollection,
    l10nService,
) {
    $scope.gridConfig = new CRUDGridConfig();

    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    // Instantiate collection
    $scope.collection = new ControlScriptCollection();

    const { objectName } = $scope.collection;

    // Mofify default grid configuration
    $scope.gridConfig.id = `${objectName}-list-page`;
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        },
    ];

    $scope.$on('$destroy', () => {
        $scope.collection.destroy();
    });
}]);
