/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtual-service-security-dashboard';
const prefix = `${moduleName}.${componentName}`;

export const certificateTitle = `${prefix}.certificateTitle`;
export const nonPfsTitle = `${prefix}.nonPfsTitle`;
export const tlsVersionTitle = `${prefix}.tlsVersionTitle`;
export const emptyValueLabel = `${prefix}.emptyValueLabel`;
export const networkHeader = `${prefix}.networkHeader`;
export const applicationHeader = `${prefix}.applicationHeader`;
export const viewOpenSslPatchesLinkLabel = `${prefix}.viewOpenSslPatchesLinkLabel`;
export const failedTransactionsLabel = `${prefix}.failedTransactionsLabel`;
export const sslTimeoutsLabel = `${prefix}.sslTimeoutsLabel`;
export const sslNetworkErrorsLabel = `${prefix}.sslNetworkErrorsLabel`;
export const sslProtocolErrorsLabel = `${prefix}.sslProtocolErrorsLabel`;

export const ENGLISH = {
    [certificateTitle]: 'Certificate',
    [nonPfsTitle]: 'Non-PFS',
    [tlsVersionTitle]: 'TLS Version',
    [emptyValueLabel]: 'N/A',
    [networkHeader]: 'Network',
    [applicationHeader]: 'Application',
    [viewOpenSslPatchesLinkLabel]: 'View OpenSSL Patches',
    [failedTransactionsLabel]: 'Failed Transactions',
    [sslTimeoutsLabel]: '<strong>SSL Timeouts:</strong> {0}',
    [sslNetworkErrorsLabel]: '<strong>SSL Network Errors:</strong> {0}',
    [sslProtocolErrorsLabel]: '<strong>SSL Protocol Errors:</strong> {0}',
};
