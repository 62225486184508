/** @module AccountsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    Auth,
    StringService,
} from 'ajs/modules/core/services';

import * as l10n from './my-account-preview.l10n';
import './my-account-preview.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Preview component for My Account Modal. Displays Tenant name.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'my-account-preview',
    templateUrl: './my-account-preview.component.html',
})
export class MyAccountPreviewComponent implements OnInit {
    /**
     * Tenant name.
     */
    public tenantName: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly authService: Auth,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.tenantName = this.authService.getTenantName();
    }
}
