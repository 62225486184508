/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-policy-allowlist-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const editAllowlistRuleHeader = `${prefix}.editAllowlistRuleHeader`;
export const newAllowlistRuleHeader = `${prefix}.newAllowlistRuleHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const ruleEnableInputLabel = `${prefix}.ruleEnableInputLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const samplingPercentInputLabel = `${prefix}.samplingPercentInputLabel`;
export const matchRequiredInputLabel = `${prefix}.matchRequiredInputLabel`;
export const matchInputLabel = `${prefix}.matchInputLabel`;
export const actionHeader = `${prefix}.actionHeader`;
export const actionInputLabel = `${prefix}.actionInputLabel`;
export const actionInputPlaceholder = `${prefix}.actionInputPlaceholder`;

export const ENGLISH = {
    [editAllowlistRuleHeader]: 'Edit Allowlist Rule: {0}',
    [newAllowlistRuleHeader]: 'New Allowlist Rule: {0}',
    [generalSectionHeader]: 'General',
    [ruleEnableInputLabel]: 'Rule {0}',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [samplingPercentInputLabel]: 'Sampling',
    [matchRequiredInputLabel]: 'At least one match is required.',
    [matchInputLabel]: 'Match ({0})',
    [actionHeader]: 'Action',
    [actionInputLabel]: 'Action',
    [actionInputPlaceholder]: 'Select Action',
};
