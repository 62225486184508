/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-rule-group';
const prefix = `${moduleName}.${componentName}`;

export const groupLabel = `${prefix}.groupLabel`;
export const addRuleButtonLabel = `${prefix}.addRuleButtonLabel`;
export const createRuleButtonLabel = `${prefix}.createRuleButtonLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const deactivatedLabel = `${prefix}.deactivatedLabel`;
export const mixedEnabledLabel = `${prefix}.mixedEnabledLabel`;
export const statusLabel = `${prefix}.statusLabel`;

export const ENGLISH = {
    [groupLabel]: 'Group',
    [addRuleButtonLabel]: 'Add Rule',
    [createRuleButtonLabel]: 'Create Rule',
    [enabledLabel]: 'Enabled',
    [deactivatedLabel]: 'Deactivated',
    [mixedEnabledLabel]: 'Mixed (Enabled & Deactivated Rules)',
    [statusLabel]: 'Status',
};
