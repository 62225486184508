/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';
import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper';
import { StringService } from 'ajs/modules/core/services/string-service';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    INsxtConfiguration,
    INsxtSegment,
    INsxtTier1,
    INsxtTransportzone,
    INsxtVcenterIp,
    NsxtTransportZoneType,
} from 'generated-types';
import {
    DataNetworkConfigConfigItem,
    ManagementNetworkConfigConfigItem,
} from '.';

const NSXT_BASE_API = '/api/nsxt';
const TRANSPORT_ZONES_API = `${NSXT_BASE_API}/transportzones`;
const SEGMENTS_API = `${NSXT_BASE_API}/segments`;
const TIER1S_API = `${NSXT_BASE_API}/tier1s`;
const VCENTERS_API = `${NSXT_BASE_API}/vcenters`;
const VERIFY_LOGIN_API = `${NSXT_BASE_API}/verify/login`;

const VERIFY_LOGIN_REQUEST_ID = 'verify-login';

type TNsxtConfigurationPartial = Omit<INsxtConfiguration,
'management_segment' | 'tier1_segment_config' | 'data_network_config' | 'management_network_config'
>;

interface INsxtConfigurationConfig extends TNsxtConfigurationPartial {
    management_network_config?: ManagementNetworkConfigConfigItem;
    data_network_config?: DataNetworkConfigConfigItem;
}

interface ISegmentsRequestPayload {
    credentials_uuid: string;
    host: string;
    transport_zone_id: string;
    tier1_id?: string;
}

export type TNsxtLoginCredentials =
    Pick<INsxtConfigurationConfig, 'nsxt_url' | 'nsxt_credentials_ref'>;

export class NsxtConfigurationConfigItem extends MessageItem<INsxtConfigurationConfig> {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * StringService instance to work with string.
     */
    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NsxtConfiguration',
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.stringService = this.getAjsDependency_('stringService');
    }

    /**
     * Fetches a list of transport zones.
     */
    public async fetchTransportZones(): Promise<INsxtTransportzone[]> {
        const { nsxt_url: nsxtUrl, nsxt_credentials_ref: credentialsRef } = this.config;

        if (!this.hasUrlAndCredentials()) {
            return [];
        }

        const payload = {
            credentials_uuid: this.stringService.slug(credentialsRef),
            host: nsxtUrl,
        };

        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = {
                url: TRANSPORT_ZONES_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: 'transport-zones',
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { nsxt_transportzones: transportZones = [] } = resource;

            return transportZones;
        } catch (errors) {
            this.errors = errors.data;

            return Promise.reject(errors);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches NSX-T Tier1s.
     */
    public async fetchTier1s(): Promise<INsxtTier1[]> {
        if (!this.hasUrlAndCredentials()) {
            return [];
        }

        const {
            nsxt_url: nsxtUrl,
            nsxt_credentials_ref: credentialsRef,
        } = this.config;

        const payload = {
            credentials_uuid: this.stringService.slug(credentialsRef),
            host: nsxtUrl,
        };

        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = {
                url: TIER1S_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: 'tier1s',
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { nsxt_tier1routers: tier1Routers = [] } = resource;

            return tier1Routers;
        } catch (errors) {
            this.errors = errors.data;

            return [];
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetch NSX-T segments for a VLAN transport zone.
     */
    public async fetchVlanSegments(
        transportZoneID: string,
    ): Promise<INsxtSegment[]> {
        const segments = await this.fetchSegments(transportZoneID);

        return segments;
    }

    /**
     * Fetch NSX-T segments under a specific Tier 1 Logical Router ID.
     * Typically used for OVERLAY transport zones.
     */
    public async fetchSegmentsByTier1LRID(
        transportZoneID: string,
        tier1LRID: string,
    ): Promise<INsxtSegment[]> {
        const segments = await this.fetchSegments(transportZoneID, tier1LRID);

        return segments;
    }

    /**
     * Fetches VCenter addresses.
     */
    public async fetchVCenters(): Promise<string[]> {
        if (!this.hasUrlAndCredentials()) {
            return [];
        }

        const {
            nsxt_url: nsxtUrl,
            nsxt_credentials_ref: credentialsRef,
            // transport zone used to fetch VCenters is given by data_network_config
            data_network_config: { transportZone },
        } = this.config;

        const payload = {
            credentials_uuid: this.stringService.slug(credentialsRef),
            host: nsxtUrl,
            transport_zone_id: transportZone,
        };

        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = {
                url: VCENTERS_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: 'vcenters',
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { vcenter_ips: vcenterIps = [] } = resource;

            return vcenterIps.map((vcenterIp: INsxtVcenterIp) => vcenterIp.vcenter_ip?.addr);
        } catch (errors) {
            this.errors = errors.data;

            return [];
        } finally {
            this.busy = false;
        }
    }

    /**
     * Returns true if both the NSX-T URL and Credentials are set.
     */
    public hasUrlAndCredentials(): boolean {
        const { nsxt_url: nsxtUrl, nsxt_credentials_ref: credentialsRef } = this.config;

        return !isUndefined(credentialsRef) && !isUndefined(nsxtUrl);
    }

    /**
     * Clear transport zone related data configured for child fields.
     */
    public clearTransportZoneData(): void {
        this.config.management_network_config.clearTransportZoneData();
        this.config.data_network_config.clearTransportZoneData();
    }

    /**
     * Called to verify NSX-T login credentials.
     */
    public async verifyNsxtLogin(loginCredentials: TNsxtLoginCredentials): Promise<void> {
        const stringService: StringService = this.getAjsDependency_('stringService');
        const {
            nsxt_url: nsxtUrl,
            nsxt_credentials_ref: credentialsRef,
        } = loginCredentials;

        const payload = {
            host: nsxtUrl,
            credentials_uuid: stringService.slug(credentialsRef),
        };

        try {
            const requestConfig = {
                url: VERIFY_LOGIN_API,
                method: HttpMethod.POST,
                data: payload,
                requestId: VERIFY_LOGIN_REQUEST_ID,
            };

            await this.httpWrapper.request(requestConfig);
        } catch (errors) {
            return Promise.reject(errors.data.error);
        }
    }

    /**
     * Cancels any outstanding verify-login requests.
     */
    public cancelVerifyNsxtLogin(): void {
        this.httpWrapper.cancelRequest(VERIFY_LOGIN_REQUEST_ID);
    }

    /**
     * Sets the nsxt_url and nsxt_credentials_ref properties on the config.
     */
    public setNsxtLoginCredentials(loginCredentials: TNsxtLoginCredentials): void {
        const config = this.getConfig();

        const {
            nsxt_url: nsxtUrl,
            nsxt_credentials_ref: credentialsRef,
        } = loginCredentials;

        config.nsxt_url = nsxtUrl;
        config.nsxt_credentials_ref = credentialsRef;
    }

    /**
     * Returns true if the data_network_config is configured with an Overlay tz_type.
     */
    public isOverlayTransportZone(): boolean {
        return this.isTransportZoneType(NsxtTransportZoneType.OVERLAY);
    }

    /**
     * Returns true if the data_network_config is configured with a VLAN tz_type.
     */
    public isVlanTransportZone(): boolean {
        return this.isTransportZoneType(NsxtTransportZoneType.VLAN);
    }

    /**
     * Called to destroy and cancel all pending requests.
     * @override
     */
    public destroy(): void {
        this.httpWrapper.cancelAllRequests();

        super.destroy();
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'management_network_config',
            'data_network_config',
        ];
    }

    /**
     * Returns true if the data_network_config is configured with a specified tz_type.
     */
    private isTransportZoneType(transportZoneType: NsxtTransportZoneType): boolean {
        return this.config.data_network_config.isTransportZoneType(transportZoneType);
    }

    /**
     * Fetches NSX-T segments.
     * All segments fetchings are dependent on a transport zone ID. Segments can also be fetched for
     * a particular Tier 1 Logical Router path by adding its ID.
     */
    private async fetchSegments(
        transportZoneID: string,
        tier1LRID?: string,
    ): Promise<INsxtSegment[]> {
        if (!this.hasUrlAndCredentials()) {
            return [];
        }

        const {
            nsxt_url: nsxtUrl,
            nsxt_credentials_ref: credentialsRef,
        } = this.config;

        const payload: ISegmentsRequestPayload = {
            credentials_uuid: this.stringService.slug(credentialsRef),
            host: nsxtUrl,
            transport_zone_id: transportZoneID,
        };

        if (tier1LRID) {
            payload.tier1_id = tier1LRID;
        }

        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = {
                url: SEGMENTS_API,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { nsxt_segments: segments = [] } = resource;

            return segments;
        } catch (errors) {
            this.errors = errors.data;

            return [];
        } finally {
            this.busy = false;
        }
    }
}

NsxtConfigurationConfigItem.ajsDependencies = [
    HTTP_WRAPPER_TOKEN,
    'stringService',
];
