/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'management-network-config';
const prefix = `${moduleName}.${componentName}`;

export const managementNetworkSectionTitle = `${prefix}.managementNetworkSectionTitle`;
export const transportZoneSelectLabel = `${prefix}.transportZoneSelectLabel`;
export const transportZoneSelectPlaceholder = `${prefix}.transportZoneSelectPlaceholder`;
export const transportZoneSelectHelperText = `${prefix}.transportZoneSelectHelperText`;
export const tier1LogicalRouterSelectLabel = `${prefix}.tier1LogicalRouterSelectLabel`;
export const tier1LogicalRouterSelectPlaceholder = `${prefix}.tier1LogicalRouterSelectPlaceholder`;
export const vlanSegmentSelectLabel = `${prefix}.vlanSegmentSelectLabel`;
export const overlaySegmentSelectLabel = `${prefix}.overlaySegmentSelectLabel`;
export const segmentSelectPlaceholder = `${prefix}.segmentSelectPlaceholder`;
export const segmentDropdownHelperText = `${prefix}.segmentDropdownHelperText`;

export const ENGLISH = {
    [managementNetworkSectionTitle]: 'Management Network',
    [transportZoneSelectLabel]: 'Transport Zone',
    [transportZoneSelectPlaceholder]: 'Select Transport Zone',
    [transportZoneSelectHelperText]: 'Connect credentials to populate Transport Zones',
    [vlanSegmentSelectLabel]: 'VLAN Segment',
    [tier1LogicalRouterSelectLabel]: 'Tier1 Logical Router',
    [tier1LogicalRouterSelectPlaceholder]: 'Select Tier1 Logical Router',
    [overlaySegmentSelectLabel]: 'Overlay Segment',
    [segmentSelectPlaceholder]: 'Select Segment',
    [segmentDropdownHelperText]: 'Configure Tier1 Logical Router ID to populate Segment IDs',
};
