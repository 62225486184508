/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IExpanderAction } from 'ng/shared/components/expander-with-actions';
import { WafPolicyAllowlistRuleConfigItem } from 'ajs/modules/waf';
import './waf-policy-allowlist-rule.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-allowlist-rule.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum EnableState {
    Disabled = 0,
    Enabled = 1,
}

/**
 * @desc Component for displaying a WAF Policy Allowlist rule in the WAF Policy modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-allowlist-rule',
    templateUrl: './waf-policy-allowlist-rule.component.html',
})
export class WafPolicyAllowlistRuleComponent {
    @Input() public rule: WafPolicyAllowlistRuleConfigItem;

    @Output() public onDelete: EventEmitter<void> = new EventEmitter();
    @Output() public onEdit: EventEmitter<void> = new EventEmitter();

    public expanderActions: IExpanderAction[];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.expanderActions = [
            {
                confirmationButtonProps: {
                    actions: [
                        {
                            label: 'Delete',
                            onClick: (): void => this.handleDelete(),
                        },
                    ],
                    confirmationButtonClassName: 'avi-btn avi-btn-danger',
                },
                confirmationMessage: `Are you sure you want to delete ${this.rule.getName()}?`,
                iconClassName: 'sl-icon-trash',
                title: 'Delete',
            },
            {
                iconClassName: 'sl-icon-note',
                onClick: (): void => this.handleEdit(),
                title: 'Edit',
            },
        ];
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    public getEnableState(): EnableState {
        return this.rule.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    public handleClickEnable(): void {
        this.rule.enable = !this.rule.enable;
    }

    /**
     * Click handler for deleting a allowlist rule.
     */
    public handleDelete(): void {
        this.onDelete.emit();
    }

    /**
     * Click handler for editing a allowlist rule.
     */
    public handleEdit(): void {
        this.onEdit.emit();
    }
}
