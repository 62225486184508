/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './PerformanceChartControls.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name  performanceChartControls
 * @restrict AE
 */
angular.module('aviApp').directive('performanceChartControls', ['l10nService',
    function(l10nService) {
        return {
            scope: true,
            restrict: 'AE',
            templateUrl: 'src/views/components/performance-chart-controls.html',
            link: function performanceChartControlsLink($scope, element) {
                element.addClass('performance-chart-controls-container');
                $scope.l10nKeys = l10nKeys;

                l10nService.registerSourceBundles(dictionary);

                /**
                 * Returns a sum of series and errorSeries anomalies.
                 * @return {number} - Total number of anomalies.
                 */
                $scope.countAnomalies = function() {
                    const
                        { metric } = $scope.config.getCard(),
                        series = metric && metric.getMainSeries(),
                        errorSeries = metric && metric.getSeriesByType('errorTotal');

                    let totalAnomalies = 0;

                    if (series) {
                        totalAnomalies += series.getAnomaliesQ();
                    }

                    if (errorSeries) {
                        totalAnomalies += errorSeries.getAnomaliesQ();
                    }

                    return totalAnomalies;
                };
            },
        };
    }]);
