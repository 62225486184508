/**
 * @module ClusterModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { Cluster } from 'ajs/modules/cluster/factories/cluster.item.factory';
import { ClusterNodeConfigItem }
    from 'ajs/modules/cluster/factories/cluster-node.config-item.factory';

import { IAviDataGridConfig } from 'ng/shared/components/avi-data-grid/avi-data-grid.types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './cluster-node-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Grid component for Cluster node.
 * @author Ratan Kumar
 */
@Component({
    selector: 'cluster-node-grid',
    templateUrl: './cluster-node-grid.component.html',
})
export class ClusterNodeGridComponent implements OnInit {
    /**
     * Cluster Item.
     */
    @Input()
    public editable: Cluster;

    /**
     * Fires on add Cluster node.
     */
    @Output()
    public onAddClusterNode = new EventEmitter<void>();

    /**
     * Fires on edit Cluster node.
     */
    @Output()
    public onEditClusterNode = new EventEmitter<ClusterNodeConfigItem>();

    /**
     * Fires on delete/deleteAll Cluster node.
     */
    @Output()
    public onDeleteClusterNode = new EventEmitter<ClusterNodeConfigItem>();

    /**
     * Cluster Node grid config.
     */
    public clusterNodeGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Max Nodes allowed to add in cluster Node List.
     */
    public readonly maxNodesAllowed = 3;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        const { l10nService } = this;

        this.clusterNodeGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.ipLabel),
                    id: 'ip',
                    transform(row: ClusterNodeConfigItem): string {
                        return row.ip.address;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.nameLabel),
                    id: 'name',
                    transform(row: ClusterNodeConfigItem): string {
                        return row.config.name;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.publicIpOrNameLabel),
                    id: 'public-ip',
                    transform(row: ClusterNodeConfigItem): string {
                        return row.publicIp.address;
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.removeLabel),
                    onClick: (clusterNodes: ClusterNodeConfigItem[]) => {
                        clusterNodes.forEach((clusterNode: ClusterNodeConfigItem) => {
                            this.deleteClusterNode(clusterNode);
                        });
                    },
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (clusterNode: ClusterNodeConfigItem) => {
                        this.editClusterNode(clusterNode);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.removeLabel),
                    shape: 'trash',
                    onClick: (clusterNode: ClusterNodeConfigItem) => {
                        this.deleteClusterNode(clusterNode);
                    },
                },
            ],
        };
    }

    /**
     * Handler for Add cluster Node button.
     */
    public addClusterNode(): void {
        this.onAddClusterNode.emit();
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Handler for Cluster Node edit.
     */
    private editClusterNode(clusterNode: ClusterNodeConfigItem): void {
        this.onEditClusterNode.emit(clusterNode);
    }

    /**
     * Deletes Cluster Node from Cluster node list.
     */
    private deleteClusterNode(clusterNode: ClusterNodeConfigItem): void {
        this.onDeleteClusterNode.emit(clusterNode);
    }
}
