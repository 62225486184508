/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'direct-server-return-settings';
const prefix = `${moduleName}.${componentName}`;

export const dsrTypeInputLabel = `${prefix}.dsrTypeInputLabel`;
export const dsrEncapsulationTypeInputLabel = `${prefix}.dsrEncapsulationTypeInputLabel`;
export const dsrTypeSelectPlaceholder = `${prefix}.dsrTypeSelectPlaceholder`;
export const dsrEncapsulationTypeSelectPlaceholder = `${prefix}.dsrEncapsulationTypeSelectPlaceholder`;

export const ENGLISH = {
    [dsrTypeInputLabel]: 'DSR Type',
    [dsrEncapsulationTypeInputLabel]: 'DSR Encapsulation Type',
    [dsrTypeSelectPlaceholder]: 'Select DSR Type',
    [dsrEncapsulationTypeSelectPlaceholder]: 'Select DSR Encapsulation Type',
};
