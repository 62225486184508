/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { InjectionToken } from '@angular/core';

export const ITEM_ID_TOKEN = new InjectionToken<string>('itemId');
