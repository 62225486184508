/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name VsWafTopHitsDataSource
 * @author Alex Malitsky
 * @description
 *
 *     Fields are top hit list types.
 *     Filter API param (multiple values) is made by DS out of this.filters_ property.
 *     Actual lists are stored in this.owner_.groups.
 *
 */
angular.module('aviApp').factory('VsWafTopHitsDataSource', [
'DataSource', 'Timeframe',
function(DataSource, Timeframe) {
    class VsWafTopHitsDataSource extends DataSource {
        constructor(args) {
            angular.extend(args, {
                defaultFields:
                    Object.keys(VsWafTopHitsDataSource.fieldNameToPropertyName)
                        .map(id => ({
                            id,
                            preserved: true,
                            subscribers: ['__default_field'],
                        })),
                defaultParams: {
                    //not affected by user selection, don't reset either
                    filter: [
                        //waf hits only
                        'eq(waf_log.status, [REJECTED, FLAGGED])',
                        //only matches which can be used as exceptions are shown
                        'ne(waf_log.rule_logs.matches.is_internal, true)',
                    ],
                    timeout: 2,
                    type: 1,
                    page_size: 10,
                    orderby: '-count',
                },
            });

            super(args);

            this.onTimeframeChange_ = this.onTimeframeChange_.bind(this);
            Timeframe.on('change', this.onTimeframeChange_);

            this.setDurationParam_();

            /**
             * Multiple filters (one value per every property name) can be applied to API calls.
             * @type {{string: *}}
             * @private
             */
            this.filters_ = {};
        }

        /**
         * Generates `filter` param value based on active filters. Also populates a special
         * groupBy_ param which will be used to create multiple API call - one per every
         * grouping field.
         * @override
         **/
        getRequestParams_() {
            const params = super.getRequestParams_();

            params['objectName_'] = [
                'analytics',
                'logs',
            ];

            params['virtualservice'] = this.owner_.vsId;

            params['groupBy_'] = _.map(
                this.fields_,
                ({ id }) => VsWafTopHitsDataSource.fieldNameToPropertyName[id],
            );

            const filters = [];

            _.each(this.filters_, (value, filterType) => {
                filters.push(`eq(${filterType},"${value}")`);
            });

            if (filters.length) {
                if ('filter' in params) {
                    params['filter'].push(...filters);
                } else {
                    params['filter'] = filters;
                }
            }

            return params;
        }

        /**
         * Saves top lists data into owner and drops groups which haven't been returned by API.
         * Reloads the list wo corresponding filter if API returned an empty list.
         * @override
         */
        processResponse_(listHash, requestParams) {
            const
                { groups } = this.owner_,
                receivedListTypes = [];

            requestParams.groupBy_.forEach(propName => {
                const { [propName]: groupId } = VsWafTopHitsDataSource.propNameToFieldName;

                groups[groupId] = listHash[propName];

                receivedListTypes.push(groupId);
            });

            _.difference(
                Object.keys(groups),
                receivedListTypes,
            ).forEach(
                key => delete groups[key],
            );

            if (this.activeFiltersCheck_()) {
                this.owner_.load();

                return;
            }

            this.enforceFilteredGroupsToHaveOneRecordOnly_();
        }

        /**
         * Resets the filter if corresponding list returned by API is empty. This might happen
         * on manual refresh when previously picked as a filter value is not present in results
         * list anymore.
         * @returns {boolean}
         * @private
         */
        activeFiltersCheck_() {
            const
                { groups } = this.owner_,
                { filters_: filtersHash } = this,
                emptyListTypes = Object.keys(this.fields_)
                    .filter(
                        fieldName => !(fieldName in groups) || !groups[fieldName].count,
                    );

            let changed = false;

            emptyListTypes.forEach(listType => {
                const { [listType]: propName } = VsWafTopHitsDataSource.fieldNameToPropertyName;

                if (propName in filtersHash) {
                    changed = true;
                    delete filtersHash[propName];
                }
            });

            return changed;
        }

        /**
         * Since one WAF log might have multiple matches filtering by log field is rather
         * "includes" then "equal to" operation. Meaning that after applying group by operation
         * (on same property as was used for filtering) whole list of values might include more
         * values than the one used for filtering. I.e. filter=eq(rule_id, 3)&groupby=rule_id may
         * return rule_id=1 as well if there were entries matching rule 1 and rule 3
         * simultaneously. To avoid user confusion we are removing all other entries of the
         * group but the one used for such filtering.
         * @private
         */
        enforceFilteredGroupsToHaveOneRecordOnly_() {
            const
                { groups } = this.owner_,
                { filters_: filtersHash } = this;

            _.each(groups, (group, type) => {
                const valueToRemain = filtersHash[
                    VsWafTopHitsDataSource.fieldNameToPropertyName[type]
                ];

                if (!angular.isUndefined(valueToRemain)) {
                    group.list = group.list.filter(
                        ({ name }) => name === valueToRemain,
                    );
                    group.count = group.list.length;// should always be 1
                }
            });
        }

        /**
         * Sets duration param on Timeframe change event.
         * @protected
         */
        setDurationParam_() {
            const { range } = Timeframe.selected();

            this.params_['duration'] = range;
        }

        /**
         * Updates this.filters_ property with received hash. This is normally a union operation
         * but if ALL passed filters are active on the time of the call it will implement
         * deselect operation on em.
         * @param {{string: *}} filtersHash
         * @public
         */
        setFilters(filtersHash) {
            if (!filtersHash || _.isEmpty(filtersHash)) {
                return;
            }

            const
                { filters_ } = this,
                deselection = this.isFilterActive(filtersHash);

            _.each(filtersHash, (value, type) => {
                if (!deselection && !angular.isUndefined(value)) {
                    filters_[type] = value;
                } else {
                    delete filters_[type];
                }
            });
        }

        /**
         * Checks whether all passed filters are present (active) on an instance.
         * @param {{string: *}} filtersHash
         * @returns {boolean}
         * @public
         */
        isFilterActive(filtersHash) {
            if (!filtersHash || _.isEmpty(filtersHash)) {
                return _.isEmpty(this.filters_);
            }

            const { filters_ } = this;

            return _.every(filtersHash,
                (value, type) => filters_[type] === value);
        }

        /**
         * Returns a copy of filters hash.
         * @returns {{string: *}}
         */
        getActiveFiltersHash() {
            return angular.copy(this.filters_);
        }

        /**
         * Timeframe change event listener.
         * @protected
         */
        onTimeframeChange_() {
            this.reset();
            this.setDurationParam_();
            this.owner_.load(undefined, true);
        }

        /**
         * @public
         */
        removeFilters() {
            this.filters_ = {};
        }

        /** @override */
        reset() {
            this.removeFilters();
            super.reset();
        }

        /** @override */
        destroy(force) {
            const gotDestroyed = super.destroy(force);

            if (gotDestroyed) {
                Timeframe.unbind('change', this.onTimeframeChange_);
            }

            return gotDestroyed;
        }
    }

    const DS = VsWafTopHitsDataSource;

    /**
     * Hash of field names (top hits list types) to property names.
     * @type {{string: string}}
     */
    DS.fieldNameToPropertyName = {
        group: 'waf_log.rule_logs.rule_group',
        rule: 'waf_log.rule_logs.rule_id',
        tag: 'waf_log.rule_logs.tags',
        clientIp: 'client_ip',
        path: 'uri_path',
        matchElement: 'waf_log.rule_logs.matches.match_element',
        combination: [
            'uri_path',
            'client_ip',
            'waf_log.rule_logs.matches.match_element',
        ].join(),
    };

    //and vice versa
    DS.propNameToFieldName = _.invert(DS.fieldNameToPropertyName);

    DS.propertiesOrder = [
        'group', 'rule', 'tag', 'clientIp', 'path', 'matchElement',
    ].map(fieldName => DS.fieldNameToPropertyName[fieldName]);

    //text labels by group type
    DS.propertyLabelsByType = {
        group: 'Group',
        rule: 'Rule',
        tag: 'Tag',
        clientIp: 'Client IP',
        path: 'Path',
        matchElement: 'Match Element',
    };

    //text labels by field name
    DS.propertyLabelsByName = {};

    _.each(DS.propertyLabelsByType, (label, groupId) => {
        const { [groupId]: propName } = DS.fieldNameToPropertyName;

        DS.propertyLabelsByName[propName] = label;
    });

    return VsWafTopHitsDataSource;
}]);
