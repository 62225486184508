/**
 * Module for Alert related services and components
 * @module AlertModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    ACTION_GROUP_CONFIG_COLLECTION_TOKEN,
    ACTION_GROUP_CONFIG_ITEM_TOKEN,
    ALERT_EMAIL_CONFIG_COLLECTION_TOKEN,
    ALERT_EMAIL_CONFIG_ITEM_TOKEN,
    ALERT_SYSLOG_CONFIG_COLLECTION_TOKEN,
    ALERT_SYSLOG_CONFIG_ITEM_TOKEN,
    ALERT_SYSLOG_SERVER_CONFIG_ITEM_TOKEN,
    SNMP_TRAP_PROFILE_COLLECTION_TOKEN,
    SNMP_TRAP_PROFILE_CONFIG_ITEM_TOKEN,
    SNMP_TRAP_SERVER_CONFIG_ITEM_TOKEN,
    SNMP_V3_USER_PARAMS_CONFIG_ITEM,
} from './alert.tokens';

import {
    ActionGroupConfigCollection,
    ActionGroupConfigItem,
    AlertEmailConfigCollection,
    AlertEmailConfigItem,
    AlertSyslogConfigCollection,
    AlertSyslogConfigItem,
    AlertSyslogServerConfigItem,
    SnmpTrapProfileCollection,
    SnmpTrapProfileItem,
    SnmpTrapServerConfigItem,
    SnmpV3UserParamsConfigItem,
} from './factories';

const alertModule = angular.module('avi/alert');

const factories = [
    {
        dependency: AlertEmailConfigItem,
        name: ALERT_EMAIL_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AlertEmailConfigCollection,
        name: ALERT_EMAIL_CONFIG_COLLECTION_TOKEN,
    },
    {
        dependency: AlertSyslogConfigCollection,
        name: ALERT_SYSLOG_CONFIG_COLLECTION_TOKEN,
    },
    {
        dependency: AlertSyslogConfigItem,
        name: ALERT_SYSLOG_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AlertSyslogServerConfigItem,
        name: ALERT_SYSLOG_SERVER_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: SnmpTrapProfileItem,
        name: SNMP_TRAP_PROFILE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: SnmpTrapProfileCollection,
        name: SNMP_TRAP_PROFILE_COLLECTION_TOKEN,
    },
    {
        dependency: SnmpTrapServerConfigItem,
        name: SNMP_TRAP_SERVER_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: SnmpV3UserParamsConfigItem,
        name: SNMP_V3_USER_PARAMS_CONFIG_ITEM,
    },
    {
        dependency: ActionGroupConfigCollection,
        name: ACTION_GROUP_CONFIG_COLLECTION_TOKEN,
    },
    {
        dependency: ActionGroupConfigItem,
        name: ACTION_GROUP_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(
        alertModule,
        'factory',
        name,
        dependency,
    );
});
