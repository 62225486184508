/**
 * Module for ICAP Profile related services and components
 * @module IcapProfileModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    IcapNsxDefenderConfigConfigItem,
    IcapProfile,
    IcapProfileCollection,
    icapProfileListPageOptions,
    ICAP_NSX_DEFENDER_CONFIG_CONFIG_ITEM_TOKEN,
} from '.';

const icapProfileModule = angular.module('avi/icap-profile');

const factories = [{
    dependency: IcapProfile,
    name: 'IcapProfile',
}, {
    dependency: IcapProfileCollection,
    name: 'IcapProfileCollection',
}, {
    dependency: IcapNsxDefenderConfigConfigItem,
    name: ICAP_NSX_DEFENDER_CONFIG_CONFIG_ITEM_TOKEN,
}];

const components = [{
    name: 'icapProfileListPage',
    options: icapProfileListPageOptions,
}];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(
        icapProfileModule,
        'factory',
        name,
        dependency,
    );
});

components.forEach(({ name, options }) => icapProfileModule.component(name, options));
