/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IGridConfig,
    IGridRow,
} from 'ajs/components/common/grid/grid-data-model';
import {
    ICAPVendor,
    IIcapLog,
    IIcapRequestLog,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { IIcapNSXDefenderLogsData, IIcapOPSWATLogsData } from 'ajs/modules/logs';
import * as l10n from './icap-logs.l10n';
import template from './icap-logs.component.html';
import './icap-logs.component.less';

/** Formatted object containing values needed for Application>VS>Logs (expand twice). */
export interface IIcapLogDisplayRow extends IGridRow {
    action: {
        display: string;
        val: IIcapRequestLog['action'];
    };
    pool_name: {
        display: string;
        val: IIcapRequestLog['pool_name'];
    };
    icap_server_ip: {
        display: string;
        val: IIcapRequestLog['icap_server_ip'];
    };
    icap_headers_sent_to_server: {
        display: string;
        val: IIcapRequestLog['icap_headers_sent_to_server'];
    };
    icap_headers_received_from_server: {
        display: string;
        val: IIcapRequestLog['icap_headers_received_from_server'];
    };
    icap_server_port: {
        display: string;
        val: IIcapRequestLog['icap_server_port'];
    };
    latency: {
        display: string;
        val: IIcapRequestLog['latency'];
    };
    http_method: {
        display: string;
        val: IIcapRequestLog['http_method'];
    };
    complete_body_sent: {
        display: string;
        val: IIcapRequestLog['complete_body_sent'];
    };
    icap_absolute_uri: {
        display: string;
        val: IIcapRequestLog['icap_absolute_uri'];
    };
    icap_method: {
        display: string;
        val: IIcapRequestLog['icap_method'];
    };
    http_response_code: {
        display: string;
        val: IIcapRequestLog['http_response_code'];
    };
    modified_content_length: {
        display: string;
        val: IIcapRequestLog['modified_content_length'];
    };
    icap_response_code: {
        display: string;
        val: IIcapRequestLog['icap_response_code'];
    };
    vendor: {
        display: string;
        val: IIcapRequestLog['vendor'];
    }
    opswat_log: IIcapOPSWATLogsData;
    nsx_defender_log: IIcapNSXDefenderLogsData;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @author akulaggarwal
 * @desc Component when ICAP logs present in VS for L7.
 */
class IcapLogsComponentController {
    public gridConfig: IGridConfig;
    public gridRows: IIcapLogDisplayRow[];
    public readonly l10nKeys = l10nKeys;

    /**
     * Data to be shown in ICAP logs table.
     * Note: here top level action represents most severe action out of entire list,
     *       not to be confused with action for each individual icap log entry
     */
    public readonly icapData: {
        action: IIcapLog['action'],
        logs: IIcapLogDisplayRow[],
    };

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (args: { [str: string]: string }) => void;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    public $onInit(): void {
        this.gridRows = this.icapData.logs;
        this.gridConfig = this.createIcapLogsGrid();
    }

    /** Creates and returns grid config. */
    private createIcapLogsGrid(): IGridConfig {
        return {
            expandedContainerTemplate:
                `<icap-log-list-expander
                     icap-log="::row"
                     update-search="config.props.updateSearch(str)"
                 ></icap-log-list-expander>`,
            props: {
                updateSearch: (str: string) => this.updateSearch({ str }),
            },
            fields: this.getGridConfigFields(),
            id: 'icap-logs',
            layout: {
                hideDisplaying: true, // TODO: Consider removing when multiple rows possible
                hideSearch: true,
            },
        };
    }

    /**
     * Returns vendor specific grid config fields.
     */
    private getGridConfigFields(): IGridConfig['fields'] {
        const { icapData: { logs } } = this;

        const isOpswatLogType = logs.some(log => log.vendor.val === ICAPVendor.ICAP_VENDOR_OPSWAT);
        const isNsxDefenderLogType =
            logs.some(log => log.vendor.val === ICAPVendor.ICAP_VENDOR_LASTLINE);

        const gridConfigFields = [{
            name: 'action',
            title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderActionLabel),
            template: `<div
                           class="icap-logs__clickable-filter"
                           log-filter-click
                           key="icap_log.request_logs.action"
                           display-value="{{ ::row.action.display }}"
                           value="::row.action.val"
                           on-update="config.props.updateSearch(str)"
                       ></div>`,
        }, {
            name: 'type',
            title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderTypeLabel),
            template: `<div
                           class="icap-logs__clickable-filter"
                           log-filter-click
                           key="icap_log.request_logs.vendor"
                           display-value="{{ ::row.vendor.display }}"
                           value="::row.vendor.val"
                           on-update="config.props.updateSearch(str)"
                       ></div>`,
        }];

        if (isOpswatLogType) {
            gridConfigFields.push({
                name: 'reason',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderReasonLabel),
                template: `<div
                               class="icap-logs__clickable-filter"
                               log-filter-click
                               key="icap_log.request_logs.opswat_log.reason"
                               display-value="{{ ::row.opswat_log.reason.display }}"
                               value="::row.opswat_log.reason.val"
                               on-update="config.props.updateSearch(str)"
                           ></div>`,
            }, {
                name: 'violations',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderViolationsLabel),
                template: `<cds-icon
                               shape="exclamation-triangle"
                               size="22"
                               status="warning"
                               class="icap-logs__warning-icon"
                               ng-if="::row.opswat_log.violations.length"
                           ></cds-icon>
                           <span ng-if="::row.opswat_log.violations.length">
                                {{ row.opswat_log.violations.length }}
                           </span>
                           <div ng-if="::!row.opswat_log.violations.length">-</div>`,
            });
        } else if (isNsxDefenderLogType) {
            gridConfigFields.push({
                name: 'score',
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderScoreLabel),
                template: `<div
                               class="icap-logs__clickable-filter"
                               log-filter-click
                               key="icap_log.request_logs.nsx_defender_log.score"
                               display-value="{{ ::row.nsx_defender_log.score.display }}"
                               value="::row.nsx_defender_log.score.val"
                               on-update="config.props.updateSearch(str)"
                           ></div>`,
            });
        }

        return gridConfigFields;
    }
}

IcapLogsComponentController.$inject = [
    'l10nService',
];

export const icapLogsComponentOptions = {
    bindings: {
        icapData: '<',
        updateSearch: '&',
    },
    controller: IcapLogsComponentController,
    template,
};
