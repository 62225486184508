/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './auth-profile-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name authProfilePage
 * @description Component for the Auth Profile page.
 */
class AuthProfilePageController {
    constructor(
        CRUDGridConfig,
        AuthProfileCollection,
        AviModal,
        l10nService,
    ) {
        this._CRUDGridConfig = CRUDGridConfig;
        this._AuthProfileCollection = AuthProfileCollection;
        this._AviModal = AviModal;

        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.l10nService_ = l10nService;
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        this.gridConfig = new this._CRUDGridConfig();
        this.gridConfig.collection = new this._AuthProfileCollection();

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;

        this.gridConfig.fields = [
            {
                name: 'data.config.name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                sortBy: 'name',
            }, {
                name: 'data.config.type',
                title: l10nService.getMessage(l10nKeys.columnTitleType),
                template: '<span>{{ row.data.config.type | enum:"AUTH_PROFILE_" }}</span>',
                sortBy: 'type',
            },
        ];
        this.gridConfig.singleactions.push({
            title: l10nService.getMessage(l10nKeys.actionBtnVerify),
            class: 'icon-lostlog',
            do: row => {
                this._AviModal.open('prof-auth-profile-verify-ldap', {
                    ldap: row.data.config,
                });
            },
            hidden: row => row.getConfig().type !== 'AUTH_PROFILE_LDAP',
        }, {
            title: l10nService.getMessage(l10nKeys.actionBtnVerify),
            class: 'icon-lostlog',
            do: row => {
                this._AviModal.open('auth-profile-verify-saml', {
                    config: row.data.config,
                });
            },
            hidden: row => {
                const config = row.getConfig();

                /**
                 * Hide the verification icon for saml profile
                 * with eintity type as User-provided-entity
                 */
                if (config.type === 'AUTH_PROFILE_SAML') {
                    const { sp } = config.saml;

                    return sp.saml_entity_type === 'AUTH_SAML_APP_VS';
                }

                return true;
            },
        });
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

AuthProfilePageController.$inject = [
    'CRUDGridConfig',
    'AuthProfileCollection',
    'AviModal',
    'l10nService',
];

angular.module('aviApp').component('authProfilePage', {
    controller: AuthProfilePageController,
    template:
        `<div class="avi-main">
            <div class="panel templatesList">
                <collection-grid config="$ctrl.gridConfig"></collection-grid>
            </div>
        </div>`,
});
