/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'azure-edit-credentials-dialog';
const prefix = `${moduleName}.${componentName}`;

export const azureCredentialsHeader = `${prefix}.azureCredentialsHeader`;
export const subscriptionIdLabel = `${prefix}.subscriptionIdLabel`;
export const subscriptionIdPlaceholder = `${prefix}.subscriptionIdPlaceholder`;
export const useAzureMsiAuthenticationLabel = `${prefix}.useAzureMsiAuthenticationLabel`;
export const azureCredentialsPlaceholder = `${prefix}.azureCredentialsPlaceholder`;

export const ENGLISH = {
    [azureCredentialsHeader]: 'Azure Credentials',
    [subscriptionIdLabel]: 'Subscription ID',
    [subscriptionIdPlaceholder]: 'Enter ID',
    [useAzureMsiAuthenticationLabel]: 'Use Azure Managed Service Identity (MSI) Authentication',
    [azureCredentialsPlaceholder]: 'Select Credentials',
};
