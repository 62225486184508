/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

//TODO rebuild @am
//TODO use set params in typeahead query
angular.module('avi/logs').directive('logSuggestions', [
'Base', 'logSearchMediator',
function(Base, logSearchMediator) {
    function logSuggestionsLink(scope, elem) {
        const mediator = logSearchMediator.ta;

        let
            selected,
            expr,
            expected,
            listNode,
            provided,
            child,
            activeScroll = false,
            scrollTimeout = false;

        const setSelected = function(val) {
            selected = val;

            if (listNode) {
                listNode.find('tr').each(function(key) {
                    if (val !== null && key === +val) {
                        $(this).addClass('selected');
                    } else {
                        $(this).removeClass('selected');
                    }
                });
            }

            return selected;
        };

        expr = [];//parsed parts of query
        provided = '';//part of param name, provided by user
        expected = [];//values that will go to dropdown after filtering
        setSelected(null);

        // setup pointers to dom elements
        const parent = $('.log-search');
        const input = elem;

        const childTempl = $('<div/>')
            .addClass('log-search-typeahead')
            .on('mouseleave', function() {
                setSelected(null);
            });

        const listTempl = $('<div/>')
            .addClass('log-search-typeahead-list');

        const listen = function() {
            input
                .on('focusin', function() {
                    if (!child) {
                        child = childTempl.appendTo('body');
                        listNode = listTempl.appendTo(child);
                    }

                    resize();
                    change();
                })
                .on('focusout', function(e) {
                    hide();

                    if (!mediator.suggClicked) {
                        child.remove();
                        child = false;
                        listNode = false;
                    }
                })
                .on('keyup', function(event) {
                    if (event.which === 38 || event.which === 40 ||
                        event.which === 9 || event.which === 13 ||
                        event.which === 27) {
                        return;
                    }

                    change();
                })
                .on('keypress keydown', function(event) {
                    if (event.which === 38 && event.type === 'keydown') {
                        if (mediator.opened) {
                            up();
                            event.preventDefault();
                        } else {
                            change();
                        }
                    //check type to avoid event on opening bracket (
                    } else if (event.which === 40 && event.type === 'keydown') {
                        if (mediator.opened) {
                            down();
                            event.preventDefault();
                        } else {
                            change();
                        }
                    } else if (event.which === 9) {
                        if (mediator.opened) {
                            setEventTimeout('tabClicked');
                            tab(event);
                            event.preventDefault();
                        }
                    } else if (event.which === 13) {
                        if (event.type === 'keydown' && mediator.opened) {
                            if (selected === null) {
                                hide();
                            } else {
                                setEventTimeout('suggClicked');
                                select();
                            }
                        }
                    // esc
                    } else if (event.which === 27) {
                        if (mediator.opened) {
                            setEventTimeout('escClicked');
                            hide();
                        }
                    }
                });
        };

        const hide = function() {
            mediator.opened = false;
            setSelected(null);

            if (child) {
                child.hide();
            }
        };

        const resize = function() {
            let offset,
                height,
                width;

            if (child) {
                offset = parent.offset();
                height = parent.height();
                width = parent.innerWidth();
                child.css({
                    top: offset.top + height + 3,
                    left: offset.left + 1,
                    width: width - 4,
                });
            }
        };

        //to make GET requests only
        const base = new Base();

        const change = function() {
            const prefix = `l${scope.isHTTPVS ? '7' : '4'}`;
            const splitter = `${prefix}_all: ${input.val()}`;

            // reset typeahead
            expr = [];
            provided = '';
            setSelected(null);

            // use the accomodative version to split the input
            try {
                expr = avi.QueryParser.parse(splitter);
            } catch (e) {
                expr = [];
            }

            // show typeahead only for last value if it parsed as a wildcard.
            // otherwise, expression is valid
            if (expr.length && !expr[expr.length - 1].wildcard) {
                expected = [];
                render();

                return;
            }

            const currExpr = expr[expr.length - 1] || {};
            const query = `${prefix}: ${currExpr.query || input.val() || ''}`;

            try {
                // attempt to parse expression typeahead
                avi.QueryParser.parse(query);
                // successfully parsed - reset suggestions
                expected = [];
                render();
            } catch (e) { // get suggestions from AJAX
                if (avi.ParsedQuery.lastOp) {
                    // get partially typed query
                    provided = query.slice(avi.ParsedQuery.lastQueryStart, query.length);

                    // make group by request for top 10 and then render
                    if (provided === '') {
                        top10();
                    } else {
                        render();
                    }
                // get suggestions from grammar
                } else {
                    // get partially typed variable or operator

                    provided = e.found ? query.slice(e.column - 1, query.length) : '';

                    // remove character class suggestions
                    expected = _.filter(e.expected, function(i) {
                        return i.type !== 'class';
                    });

                    // sort parser suggestions
                    expected = _.sortBy(expected, function(i) {
                        return i.value;
                    });

                    // render right away
                    render();
                }
            }
        };

        /* generates this.expected when param and logic sign were defined and parsed */
        const top10 = function() {
            // format url for top 10
            const exprKeys = expr.map(e => e.query.slice(0, -1));
            const exprs = expr && expr.length ? expr.slice(0, expr.length - 1) : [];
            const filters = _.map(exprs, q => {
                let quote = '';

                if (q.quoted === 'double') {
                    quote = '"';
                }

                if (q.quoted === 'single') {
                    quote = '\'';
                }

                return `filter=${q.op}(${q.field},${quote}${q.query}${quote})`;
            }).join('&');

            const uri = scope.requestUri({ type: 'typeahead' });
            const filter = filters.length ? `&${filters}` : '';
            const url = uri + filter;

            // reset suggestions
            expected = [];
            setSelected(null);

            // fetch group by query
            base.request('get', url, null, undefined, 'typeahead')
                .then(({ data: rsp }) => {
                    if (rsp.results && rsp.results.length) {
                        /* removal of special results like 'next' and 'prev' */
                        if (rsp.results.length > 10) {
                            rsp.results.length = 10;
                        }

                        rsp.results.forEach(res => {
                            exprKeys.forEach(k => {
                                if (k in res) {
                                    const isNumber = avi.ParsedQuery.lastOpType === 'number';
                                    const value = isNumber ? `${res[k]}` : `"${res[k]}"`;

                                    expected.push({
                                        value,
                                        count: res.count,
                                        percentage: res.percentage,
                                    });
                                }
                            });
                        });
                    } else {
                        expected = [];
                    }

                    render();
                })
                .catch(() => {
                    expected = [];
                    render();
                });
        };

        /* this.expected to this.filtered and to list */
        const render = function() {
            // prettify the grammar's suggestions

            const operators = {
                '=': 'equal to',
                '!=': 'not equal to',
                '>=': 'greater than or equal to',
                '>': 'greater than',
                '<=': 'less than or equal to',
                '<': 'less than',
                '~=': 'contains',
                '!~=': 'does not contain',
                '^=': 'starts with',
            };

            const quotesTrim = /^['"](.*?)['"]?$/g;

            let
                type, //sugestions of what type are we going to show, name of style
                template,
                filtered;

            if (provided) {
                filtered = _.filter(expected, function(e) {
                    if (!e.value) { return; }

                    const displayValue = e.value.replace(quotesTrim, '$1');
                    const prov = provided.replace(quotesTrim, '$1');
                    const pos = displayValue.toLowerCase().indexOf(prov.toLowerCase());

                    return pos !== -1 || !prov.length;
                });
            } else {
                filtered = expected;
            }

            listNode.children().remove();

            // nothing to show
            if (!filtered.length) {
                child.hide();
                mediator.opened = false;
            } else {
                if (avi.ParsedQuery.lastVariable) {
                    if (avi.ParsedQuery.lastOp) {
                        type = 'query';
                    } else {
                        type = 'operator';
                    }
                } else {
                    type = 'variable';
                }

                template = `<table class="${type}">`;

                // add a node for each filtered element
                _.each(filtered, function(e, i) {
                    const displayValue = e.value.replace(quotesTrim, '$1');
                    const prov = provided.replace(quotesTrim, '$1');
                    const pos = displayValue.toLowerCase().indexOf(prov.toLowerCase());

                    let descr = false;

                    if (e.value.length < 4 && operators[e.value]) {
                        descr = operators[e.value];
                    }

                    template += `<tr${i === selected ? ' class="selected"' : ''
                    }><td class="value">`;

                    if (pos !== -1) {
                        template +=
                            `<span>${displayValue.slice(0, pos)}</span>` +
                            `<span class="provided">${
                                displayValue.slice(pos, pos + provided.length)}</span>` +
                            `<span>${displayValue.slice(pos + provided.length)}</span>`;
                    } else {
                        template += displayValue;
                    }

                    template += '</td>';

                    if (type === 'operator' || type === 'variable') {
                        template += `<td class="descr">${descr || ''}</td>`;
                    }

                    if (type === 'query') {
                        template += `<td class="percent">${e.count ?
                            `${e.count} (${e.percentage.toFixed(1)}%)` : ''}</td>`;
                    }

                    template += '<td class="padding"></td></tr>';
                });

                template += '</table>';

                $(template).appendTo(listNode);

                child.show();
                mediator.opened = true;
                setSelected(0);

                listNode.find('tr')
                    .on('mousedown', function(e) {
                        setEventTimeout('suggClicked');
                        select();
                    })
                    .on('mouseover', function(e) {
                        if (!activeScroll) {
                            setSelected($(this).data('key'));
                        }
                    })
                    .each(function(key) {
                        $(this).data({
                            key,
                            suggestion: filtered[key].value,
                        });
                    });
            }
        };

        const up = function() {
            const { length } = listNode.find('tr');

            if (selected === null) {
                setSelected(0);
            } else if (selected === 0) {
                setSelected(length - 1);
            } else {
                setSelected(selected - 1);
            }

            checkIfVisible();
        };

        const down = function() {
            const { length } = listNode.find('tr');

            if (selected === null || selected + 1 === length) {
                setSelected(0);
            } else {
                setSelected(selected + 1);
            }

            checkIfVisible();
        };

        const tab = function(event) {
            if (!expected.length) { return; }

            if (selected === null) {
                setSelected(0);
                event.preventDefault();/* prevent outOfFocus */
            }

            select();
            event.stopPropagation();
        };

        const select = function() {
            const
                node = $(listNode.find('tr')[selected]),
                text = node.data('suggestion');

            if (text && text.length) {
                suggestion(text);
            }
        };

        const suggestion = function(value) {
            // compute new value
            const
                v = input.val(),
                newv = v.slice(0, v.length - provided.length) + value;

            // set new value
            input.val(newv).trigger('change');

            // focus element
            setTimeout(function() {
                input.trigger('focus');
            });
        };

        const autoScrollFinished = function() {
            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
            }

            scrollTimeout = setTimeout(function() {
                activeScroll = false;
            }, 150);
        };

        const checkIfVisible = function() {
            const { length } = listNode.find('tr');

            let
                parent,
                pos,
                shift = false,
                elem;

            if (selected === null) {
                child.scrollTop(0);
                console.warn('TA.checkIfVisible: Asked to scroll for null value.');
            } else if (length && selected < length) {
                elem = $(listNode.find('tr')[selected]);
                pos = {
                    top: elem.position().top,
                    height: elem.outerHeight(),
                };

                parent = {
                    scroll: child.scrollTop(),
                    height: child.innerHeight(),
                };

                if (pos.top < 0) {
                    shift = pos.top;
                } else if (pos.top + pos.height > parent.height) {
                    shift = pos.top + pos.height - parent.height;
                }

                if (shift) {
                    if (scrollTimeout) {
                        clearTimeout(scrollTimeout);
                        scrollTimeout = false;
                    }

                    activeScroll = true;
                    child.animate({ scrollTop: `+=${shift}` },
                        {
                            duration: 49,
                            complete: autoScrollFinished,
                        });
                }
            }
        };

        /* used for filtering focusout and enter events in different wrappers */
        const setEventTimeout = function(name) {
            if (mediator[name]) {
                clearTimeout(mediator[name]);
            }

            mediator[name] = setTimeout(function() {
                mediator[name] = false;
            });
        };

        listen();

        scope.$on('logFetch', function() {
            hide();
        });

        scope.$on('$repaintViewport', function() {
            resize();
        });

        scope.$on('userLoggedOut', function() {
            if (child) { child.remove(); }
        });

        scope.$on('$destroy', () => {
            if (child) {
                child.remove();
            }

            base.destroy();
        });
    }

    return {
        link: logSuggestionsLink,
        restrict: 'A',
        scope: false,
    };
}]);
