/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for policy related things.
 * @author Akul Aggarwal
 */

export const BOT_MAPPING_RULE_MATCH_TARGET_CONFIG_ITEM_TOKEN =
    'BotMappingRuleMatchTargetConfigItem';
