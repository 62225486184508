/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';

/**
 * Using the downgraded l10nService to translate UI strings.
 * Support string interpolation, pass strings to replace parameters.
 * Such as 'This example was created on {0}'
 * @param $sce let the inline tag display normally, such as 'hello <a>world</a>'.
 * @param l10nService provide translation for the key.
 * @returns {Function} the function is used to translate the UI strings in template.
 */
export function translateFilter(
    $sce: ng.ISCEService,
    l10nService: L10nService,
) {
    /**
     * @param key
     * @param trustAsHtml
     * @param args - Optional params to be passed to l10nService.getMessage
     */
    return function translateFilter(
        key: string,
        trustAsHtml: boolean,
        ...args: []
    ): string {
        const phrase = l10nService.getMessage(key, args);

        return trustAsHtml ? $sce.trustAsHtml(phrase) : phrase;
    };
}

translateFilter.$inject = [
    '$sce',
    'l10nService',
];
