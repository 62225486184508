/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview
 *      It contains the following:
 *          - Hash of match field_names to their labels - used to lookup match field
 *              labels by field_names.
 *          - Hash of match field object_types to labels - used to lookup label by object_type.
 * @author Aravindh Nagarajan
 */

export const fieldNamesToLabelsHash = {
    source_ip: 'Source IP Address',
    destination_ip: 'Destination IP Address',
    source_port: 'Source Port',
    destination_port: 'Destination Port',
    protocol: 'Protocol',
    port: 'Service Ports',
};

export const objectTypesToLabelsHash = {
    IpAddrMatch: 'Client IP',
    PortMatch: 'Service Port',
    ProtocolMatch: 'Protocol Type',
    MethodMatch: 'HTTP Method',
    HTTPVersionMatch: 'HTTP Version',
    PathMatch: 'Path',
    QueryMatch: 'Query',
    HdrMatch: 'Headers',
    CookieMatch: 'Cookie',
    HostHdrMatch: 'Host Header',
    L4RulePortMatch: 'Listening Ports',
    L4RuleProtocolMatch: 'L4 Protocol',
};
