/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name LogRecordListDataTransformer
 * @description
 *
 *     Calculates extra log record properties and timeline properties (for the whole list).
 *
 *     Might be worth to move timeline calculations to DS.
 */
angular.module('aviApp').factory('LogRecordListDataTransformer', [
'ListDataTransformer', 'moment',
function(ListDataTransformer, moment) {
    return class LogRecordListDataTransformer extends ListDataTransformer {
        processResponse(resp, reqParams) {
            const
                result = super.processResponse(resp, reqParams),
                { results: list } = result.data;

            let
                maxTimestamp = moment.utc(0),
                minTimestamp = moment.utc(),
                maxDuration = 0;

            list.forEach(({ config: record }) => {
                const
                    { total_time: duration } = record,
                    finish = moment.utc(record['report_timestamp']),
                    start = finish.clone().subtract(duration, 'ms');

                record['dt_start'] = start.toISOString();

                if ('waf_log' in record) {
                    const wafLogTime = [
                        'latency_request_header_phase',
                        'latency_request_body_phase',
                        'latency_response_header_phase',
                        'latency_response_body_phase',
                    ].reduce(
                        (acc, fieldName) => acc + record['waf_log'][fieldName],
                        0,
                    );

                    record['waf_log_time'] = Math.round(wafLogTime / 1000);
                }

                if (start.isBefore(minTimestamp)) {
                    minTimestamp = start;
                }

                if (finish.isAfter(maxTimestamp)) {
                    maxTimestamp = finish;
                }

                if (duration > maxDuration) {
                    maxDuration = duration;
                }
            });

            angular.extend(result.data, {
                maxDuration,
                maxTimestamp: maxTimestamp.toISOString(),
                minTimestamp: minTimestamp.toISOString(),
            });

            return result;
        }
    };
}]);
