/**
 * Module containing dataGrid related components
 * @preferred
 * @module DataGridModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClrRadioModule } from '@clr/angular';
import { VIPModule } from '@vmw/ngx-vip';
import { DialogModule } from '../dialog/dialog.module';
import { SharedModule } from '../../shared/shared.module';

import {
    AviCollectionDataGridComponent,
    MoveDataGridRowComponent,
    MoveDataGridRowService,
} from '.';

const dataGridComponents = [
    AviCollectionDataGridComponent,
    MoveDataGridRowComponent,
];

@NgModule({
    declarations: [
        ...dataGridComponents,
    ],
    exports: [
        AviCollectionDataGridComponent,
    ],
    imports: [
        ClrRadioModule,
        CommonModule,
        FormsModule,
        SharedModule,
        DialogModule,
        VIPModule,
    ],
    providers: [
        MoveDataGridRowService,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class DataGridModule {}
