/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'data-grid';
const componentName = 'avi-collection-data-grid';
const prefix = `${moduleName}.${componentName}`;

export const createButtonLabel = `${prefix}.createButtonLabel`;
export const editActionLabel = `${prefix}.editActionLabel`;
export const deleteActionLabel = `${prefix}.deleteActionLabel`;
export const tenantColumnLabel = `${prefix}.tenantColumnLabel`;

export const ENGLISH = {
    [createButtonLabel]: 'Create',
    [editActionLabel]: 'Edit',
    [deleteActionLabel]: 'Delete',
    [tenantColumnLabel]: 'Tenant',
};
