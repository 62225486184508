/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    AzureServicePrincipalCredentialsConfigItem,
    AzureUserPassCredentialsConfigItem,
    CloudConnectorUser,
    CloudConnectorUserCollection,
    GCPCredentialsConfigItem,
    NsxtCredentialsConfigItem,
    VCenterCredentialsConfigItem,
} from '.';
import {
    userCredentialsPageOptions,
} from './components';

const cloudConnectorUserModule = angular.module('avi/cloud-connector-user');
const factories = [
    {
        dependency: AzureUserPassCredentialsConfigItem,
        name: 'AzureUserPassCredentialsConfigItem',
    },
    {
        dependency: AzureServicePrincipalCredentialsConfigItem,
        name: 'AzureServicePrincipalCredentialsConfigItem',
    },
    {
        dependency: GCPCredentialsConfigItem,
        name: 'GCPCredentialsConfigItem',
    },
    {
        dependency: NsxtCredentialsConfigItem,
        name: 'NsxtCredentialsConfigItem',
    },
    {
        dependency: VCenterCredentialsConfigItem,
        name: 'VCenterCredentialsConfigItem',
    },
    {
        dependency: CloudConnectorUser,
        name: 'CloudConnectorUser',
    },
    {
        dependency: CloudConnectorUserCollection,
        name: 'CloudConnectorUserCollection',
    },
];

const components = [
    {
        name: 'userCredentialsPage',
        options: userCredentialsPageOptions,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(cloudConnectorUserModule, 'factory', name, dependency);
});

components.forEach(({ name, options }) => cloudConnectorUserModule.component(name, options));
