/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'tech-support-generation-modal';
const prefix = `${moduleName}.${componentName}`;

export const controllerRegistrationError = `${prefix}.controllerRegistrationError`;
export const pulseConnectionError = `${prefix}.pulseConnectionError`;
export const generateTechSupportModalHeader = `${prefix}.generateTechSupportModalHeader`;
export const generateBtnLabel = `${prefix}.generateBtnLabel`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const cloudSelectionInputLabel = `${prefix}.cloudSelectionInputLabel`;
export const gslbInstanceInputLabel = `${prefix}.gslbInstanceInputLabel`;
export const poolInstanceInputLabel = `${prefix}.poolInstanceInputLabel`;
export const serviceEngineInstanceInputLabel = `${prefix}.serviceEngineInstanceInputLabel`;
export const virtualServiceInstanceInputLabel = `${prefix}.virtualServiceInstanceInputLabel`;
export const attachToSupportCaseTooltipLabel = `${prefix}.attachToSupportCaseTooltipLabel`;
export const attachToSupportCaseInputLabel = `${prefix}.attachToSupportCaseInputLabel`;
export const caseInputLabel = `${prefix}.caseInputLabel`;

export const ENGLISH = {
    [controllerRegistrationError]: 'Controller must be registered with Pulse to use this feature.',
    [pulseConnectionError]: 'Pulse must be connected to use this feature.',
    [generateTechSupportModalHeader]: 'Generate Tech Support',
    [generateBtnLabel]: 'Generate',
    [typeInputLabel]: 'Type',
    [cloudSelectionInputLabel]: 'Cloud Selection',
    [gslbInstanceInputLabel]: 'GSLB Instance',
    [poolInstanceInputLabel]: 'Pool Instance',
    [serviceEngineInstanceInputLabel]: 'Service Engine Instance',
    [virtualServiceInstanceInputLabel]: 'Virtual Service Instance',
    [attachToSupportCaseTooltipLabel]: 'Generated Techsupport logs will be attached to Support Case upon completion.',
    [attachToSupportCaseInputLabel]: 'Attach to Support Case on Completion',
    [caseInputLabel]: 'Case',
};
