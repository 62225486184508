/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    AviPermissionResource,
    IBotConfigIPLocation,
    IBotConfigIPReputation,
} from 'generated-types';
import { withFullModalMixin } from 'ajs/utils/mixins/with-full-modal.mixin';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import {
    BotConfigIPLocation,
    BotConfigIPReputation,
    BotDetectionPolicy,
} from 'object-types';
import { BotManagementPolicyModalComponent } from
    // eslint-disable-next-line max-len
    'ng/modules/bot-detection-policy/components/bot-management-policy-modal/bot-management-policy-modal.component';
import * as l10n from './bot-detection-policy.item.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * Item for Bot Detection Policy
 *
 * @author Sarthak Kapoor
 */
export class BotDetectionPolicyItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'botdetectionpolicy',
            objectType: BotDetectionPolicy,
            permissionName: AviPermissionResource.PERMISSION_BOTDETECTIONPOLICY,
            windowElement: BotManagementPolicyModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method for ip_reputation_detector.config.
     */
    public get ipReputationDetectorConfig(): IBotConfigIPReputation {
        return this.config.ip_reputation_detector.config;
    }

    /**
     * Convenience method for ip_location_detector.config.
     */
    public get ipLocationDetectorConfig(): IBotConfigIPLocation {
        return this.config.ip_location_detector.config;
    }

    /**
     * Resets botConfigIPLocation values to defaults.
     */
    public resetIpLocationDetectorConfigToDefaultValues(): void {
        this.ipLocationDetectorConfig.ip_location_db_ref =
            this.getDefaultValues(BotConfigIPLocation, 'ip_location_db_ref');
        this.ipLocationDetectorConfig.system_cloud_providers_ref =
            this.getDefaultValues(BotConfigIPLocation, 'system_cloud_providers_ref');
        this.ipLocationDetectorConfig.system_search_engines_ref =
            this.getDefaultValues(BotConfigIPLocation, 'system_search_engines_ref');
    }

    /**
     * Resets BotConfigIPReputation values to defaults.
     */
    public resetIpReputationDetectorConfigToDefaultValues(): void {
        this.ipReputationDetectorConfig.ip_reputation_db_ref =
            this.getDefaultValues(BotConfigIPReputation, 'ip_reputation_db_ref');
        this.ipReputationDetectorConfig.system_ip_reputation_mapping_ref =
            this.getDefaultValues(BotConfigIPReputation, 'system_ip_reputation_mapping_ref');
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.botManagementPolicyBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['allow_list'];
    }

    /**
     * Returns default value provided type and ref.
     */
    private getDefaultValues(type: string, ref: string): string {
        const defaultValues = this.getAjsDependency_('defaultValues');

        return defaultValues.getDefaultItemConfigByType(type)[ref];
    }
}
