/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function parseNumberAsStringDirective() {
    const parseNumberAsStringLink = function(scope, elm, attr, ngModelCtrl) {
        function parseToString(viewValue) {
            if (viewValue === null) {
                return viewValue;
            }

            return String(viewValue);
        }

        function formatToNumber(modelValue) {
            if (modelValue === undefined) {
                return null;
            }

            return +modelValue;
        }

        ngModelCtrl.$parsers.push(parseToString);
        ngModelCtrl.$formatters.push(formatToNumber);
    };

    return {
        require: 'ngModel',
        restrict: 'A',
        link: parseNumberAsStringLink,
    };
}

/**
 * @ngdoc directive
 * @name  parseNumberAsString
 * @author Aravindh Nagarajan
 * @restrict A
 * @description
 *     For input type="number" that requires the model to be a string, for the protobuf defining the
 *     field as a string instead of an integer.
 * @example
 *     <input
 *         type="number"
 *         parse-number-as-string
 *      />
 */
angular.module('avi/component-kit').directive('parseNumberAsString', parseNumberAsStringDirective);
