/**
 * @module Angular-Core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Angular components need to be specified here to be downgraded and used in
 *     AngularJS.
 */
import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import {
    AviNotificationComponent,
    AviNotificationsPortalComponent,
    UtcMismatchNotificationComponent,
} from './angular/modules/notification';

import {
    AviConfirmationComponent,
    AviDialogsPortalComponent,
} from './angular/modules/dialog';

import {
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviLabelWithTooltipComponent,
    AviStatePageComponent,
    CopyrightComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FullModalComponent,
    GradientLoaderComponent,
    HelperTextTooltipIconComponent,
    TransitionProgressBarComponent,
    TriSwitchComponent,
} from './angular/shared/components';

import {
    WafCrsExpanderOpenerComponent,
    WafCrsRuleGroupComponent,
    WafExcludeListComponent,
    WafLearningLinkComponent,
    WafModeComponent,
    WafPolicyAllowlistRulesListComponent,
    WafPolicyApplicationRulesComponent,
    WafPolicyPsmGroupsConfigComponent,
    WafPolicyPsmLocationsConfigComponent,
    WafPolicyPsmRulesConfigComponent,
    WafUserOverridesBadgeComponent,
} from './angular/modules/waf/components';

import { PolicyRuleListComponent } from './angular/modules/policy';
import { NatRulesListComponent } from './angular/modules/nat-policy/components';

import {
    LicenseAddPanelComponent,
    LicenseSummaryCardComponent,
    LicensingPageHeaderComponent,
} from './angular/modules/licensing';

import {
    RecentUpdatesListComponent,
    SystemUpdateAboutCardComponent,
    UpdateCardAboutComponent,
    UpgradeTranscriptModalComponent,
} from './angular/modules/update';

import {
    BrandingComponent,
    StateButtonComponent,
    UserMenuComponent,
} from './angular/modules/header';

import {
    VerticalNavComponent,
} from './angular/modules/vertical-nav';

import {
    ConfiguredNetworkSubnetExpanderComponent,
    NetworkListExpanderComponent,
} from './angular/modules/network';

import { DnsResolverGridComponent } from './angular/modules/cloud';
import { BotDetectionResultMatchGridComponent } from './angular/modules/bot-detection-policy';
import { RbacLabelGridAjsWrapperComponent } from './angular/modules/rbac';

import {
    AviAppLevelAlertsPortalComponent,
} from './angular/modules/core/components/app-level-alerts-portal';

/**
 * List out Angular components to downgrade here. Names must be listed explicitly and not generated
 * automatically from the component class since that information gets stripped in the dist build.
 */
const downgradeComponents = [
    {
        ajsName: 'aviConfirmation',
        component: AviConfirmationComponent,
    },
    {
        ajsName: 'wafPolicyAllowlistRulesList',
        component: WafPolicyAllowlistRulesListComponent,
    },
    {
        ajsName: 'wafPolicyApplicationRules',
        component: WafPolicyApplicationRulesComponent,
    },
    {
        ajsName: 'wafPolicyPsmLocationsConfig',
        component: WafPolicyPsmLocationsConfigComponent,
    },
    {
        ajsName: 'wafPolicyPsmRulesConfig',
        component: WafPolicyPsmRulesConfigComponent,
    },
    {
        ajsName: 'wafPolicyPsmGroupsConfig',
        component: WafPolicyPsmGroupsConfigComponent,
    },
    {
        ajsName: 'expander',
        component: ExpanderComponent,
    },
    {
        ajsName: 'expanderWithActions',
        component: ExpanderWithActionsComponent,
    },
    {
        ajsName: 'gradientLoader',
        component: GradientLoaderComponent,
    },
    {
        ajsName: 'recentUpdatesList',
        component: RecentUpdatesListComponent,
    },
    {
        ajsName: 'updateCardAbout',
        component: UpdateCardAboutComponent,
    },
    {
        ajsName: 'utcMismatchNotification',
        component: UtcMismatchNotificationComponent,
    },
    {
        ajsName: 'aviNotification',
        component: AviNotificationComponent,
    },
    {
        ajsName: 'helperTextTooltipIcon',
        component: HelperTextTooltipIconComponent,
    },
    {
        ajsName: 'aviStatePage',
        component: AviStatePageComponent,
    },
    {
        ajsName: 'aviLabelWithTooltip',
        component: AviLabelWithTooltipComponent,
    },
    {
        ajsName: 'natRulesList',
        component: NatRulesListComponent,
    },
    {
        ajsName: 'policyRuleList',
        component: PolicyRuleListComponent,
    },
    {
        ajsName: 'triSwitch',
        component: TriSwitchComponent,
    },
    {
        ajsName: 'aviNotificationsPortal',
        component: AviNotificationsPortalComponent,
    },
    {
        ajsName: 'aviDropdown',
        component: AviDropdownComponent,
    },
    {
        ajsName: 'fullModal',
        component: FullModalComponent,
    },
    {
        ajsName: 'aviDropdownButton',
        component: AviDropdownButtonComponent,
    },
    {
        ajsName: 'copyright',
        component: CopyrightComponent,
    },
    {
        ajsName: 'branding',
        component: BrandingComponent,
    },
    {
        ajsName: 'stateButton',
        component: StateButtonComponent,
    },
    {
        ajsName: 'verticalNav',
        component: VerticalNavComponent,
    },
    {
        ajsName: 'wafLearningLink',
        component: WafLearningLinkComponent,
    },
    {
        ajsName: 'licenseSummaryCard',
        component: LicenseSummaryCardComponent,
    },
    {
        ajsName: 'licenseAddPanel',
        component: LicenseAddPanelComponent,
    },
    {
        ajsName: 'licensingPageHeader',
        component: LicensingPageHeaderComponent,
    },
    {
        ajsName: 'networkListExpander',
        component: NetworkListExpanderComponent,
    },
    {
        ajsName: 'configuredNetworkSubnetExpander',
        component: ConfiguredNetworkSubnetExpanderComponent,
    },
    {
        ajsName: 'rbacLabelGridAjsWrapper',
        component: RbacLabelGridAjsWrapperComponent,
    },
    {
        ajsName: 'upgradeTranscriptModal',
        component: UpgradeTranscriptModalComponent,
    },
    {
        ajsName: 'aviDialogsPortal',
        component: AviDialogsPortalComponent,
    },
    {
        ajsName: 'systemUpdateAboutCard',
        component: SystemUpdateAboutCardComponent,
    },
    {
        ajsName: 'wafCrsExpanderOpener',
        component: WafCrsExpanderOpenerComponent,
    },
    {
        ajsName: 'wafExcludeList',
        component: WafExcludeListComponent,
    },
    {
        ajsName: 'wafMode',
        component: WafModeComponent,
    },
    {
        ajsName: 'wafCrsRuleGroup',
        component: WafCrsRuleGroupComponent,
    },
    {
        ajsName: 'dnsResolverGrid',
        component: DnsResolverGridComponent,
    },
    {
        ajsName: 'wafUserOverridesBadge',
        component: WafUserOverridesBadgeComponent,
    },
    {
        ajsName: 'botDetectionResultMatchGrid',
        component: BotDetectionResultMatchGridComponent,
    },
    {
        ajsName: 'transitionProgressBar',
        component: TransitionProgressBarComponent,
    },
    {
        ajsName: 'userMenu',
        component: UserMenuComponent,
    },
    {
        ajsName: 'aviAppLevelAlertsPortal',
        component: AviAppLevelAlertsPortalComponent,
    },
];

downgradeComponents.forEach(componentData => {
    const { ajsName, component } = componentData;

    if (!ajsName) {
        throw new Error(`Angular JS name needed for ${component}`);
    }

    angular.module('aviApp').directive(ajsName, downgradeComponent({ component }));
});
