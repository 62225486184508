/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './CertificateManagementListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('CertificateManagementListController', [
'$scope',
'CertificateManagementCollection',
'CRUDGridConfig',
'l10nService',
function(
    $scope,
    CertificateManagementCollection,
    CRUDGridConfig,
    l10nService,
) {
    const vm = this;

    vm.collection = new CertificateManagementCollection();

    vm.gridConfig = new CRUDGridConfig();
    vm.gridConfig.collection = this.collection;

    const { objectName } = vm.gridConfig.collection;

    vm.gridConfig.id = `${objectName}-list-page`;

    /**
    * Get keys from source bundles for template usage.
    */
    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    vm.gridConfig.fields = [{
        name: 'getConfig().name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }, {
        name: 'control_script_name',
        title: l10nService.getMessage(l10nKeys.columnTitleControlScript),
        template: '{{ row.controlScriptName || "-" }}',
    }, {
        name: 'script_params',
        title: l10nService.getMessage(l10nKeys.columnTitleCustomParams),
        template: '{{ row.scriptParams.count || "-" }}',
    }];

    vm.gridConfig.expandedContainerTemplate = `
        <certificate-management-expander certificate-management="row">
        </certificate-management-expander>`;

    vm.gridConfig.expanderDisabled = row => !row.hasScriptParams;

    vm.gridConfig.singleactions = [{
        title: l10nService.getMessage(l10nKeys.actionBtnEdit),
        class: 'icon-pencil-4',
        hidden: row => !row.isEditable() && !row.isClonable(),
        do(row) {
            row.isEditable() ? row.edit() : this.config.collection.clone(row);
        },
    }];
}]);
