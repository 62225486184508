/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { TenantItem } from 'ajs/modules/accounts';

import * as l10n from './tenant-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IObjectType {
    tenant?: string;
    tenantConfiguration?: string;
}

/**
 * @description Component for Tenant create/edit modal.
 * @author Suraj Kumar
 */
@Component({
    selector: 'tenant-modal',
    templateUrl: './tenant-modal.component.html',
})
export class TenantModalComponent implements OnInit {
    /**
     * Tenant ObjectTypeItem.
     */
    @Input()
    public editable: TenantItem;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Tenant object type.
     */
    public objectType: IObjectType;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = {
            tenant: this.editable.messageType,
            tenantConfiguration: this.editable.configSettings?.messageType,
        };
    }
}
