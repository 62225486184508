/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './gslb-service-detail.component.less';

class GslbServiceDetailController {
    $onInit() {
        this.gslbService.addLoad('health');
    }

    $onDestroy() {
        this.gslbService.destroy();

        if (this.gslb_) {
            this.gslb_.destroy();
        }
    }
}

const componentTag = 'gslb-service-detail';
const templateUrl =
    `src/components/pages/application/gslb/${componentTag}/${componentTag}.component.html`;

/**
 * @ngdoc component
 * @name  gslbServiceDetail
 * @param {GSLBService} gslbService - gslbService object
 * @param {GSLB} gslb_ - GSLB instance, only included here to destroy as needed.
 * @author Alex Malitsky, Akul Aggarwal
 * @description
 *
 *     Wrapper component for all GslbService details components/pages.
 */
angular.module('avi/gslb').component('gslbServiceDetail', {
    bindings: {
        gslbService: '<resolveGslbService',
        gslb_: '<resolveGslb',
    },
    controller: GslbServiceDetailController,
    templateUrl,
});
