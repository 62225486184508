/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-psm-match-element-config-form';
const prefix = `${moduleName}.${componentName}`;

export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const criteriaInputLabel = `${prefix}.criteriaInputLabel`;
export const criteriaInputPlaceholder = `${prefix}.criteriaInputPlaceholder`;
export const subElementInputLabel = `${prefix}.subElementInputLabel`;
export const excludedCheckboxLabel = `${prefix}.excludedCheckboxLabel`;
export const caseSensitiveCheckboxLabel = `${prefix}.caseSensitiveCheckboxLabel`;

export const ENGLISH = {
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Select Name',
    [criteriaInputLabel]: 'Criteria',
    [criteriaInputPlaceholder]: 'Select Criteria',
    [subElementInputLabel]: 'Sub Element',
    [excludedCheckboxLabel]: 'Excluded',
    [caseSensitiveCheckboxLabel]: 'Case Sensitive',
};
