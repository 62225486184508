/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    Inject,
    Input,
} from '@angular/core';
import { WafPSMRuleConfigItem } from 'ajs/modules/waf';
import { isUndefined } from 'underscore';
import { SchemaService, StringService } from 'ajs/modules/core/services';
import './waf-policy-psm-rule-expander-content.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-policy-psm-rule-expander-content.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IWafModeLabelHash {
    [key: string]: string;
}

/**
 * @desc Displays match information about a WAF PSM Rule.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-psm-rule-expander-content',
    templateUrl: './waf-policy-psm-rule-expander-content.component.html',
})
export class WafPolicyPsmRuleExpanderContentComponent {
    @Input() public rule: WafPSMRuleConfigItem;

    public wafModeLabelHash: IWafModeLabelHash;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        @Inject(SchemaService) private schemaService: SchemaService,
        private l10nService: L10nService,
        private stringService: StringService,
    ) {
        this.wafModeLabelHash = this.schemaService.getEnumValueLabelsHash('WafMode');
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the label to use for displaying the PSM Rule's mode.
     */
    public getWafModeLabel(): string {
        return this.wafModeLabelHash[this.rule.mode] ||
            this.l10nService.getMessage(l10nKeys.useGroupModeLabel);
    }

    /**
     * Returns the label to use for displaying the PSM Rule's match_case.
     */
    public getMatchCaseLabel(): string {
        const { trueLabel, falseLabel } = this.l10nKeys;

        const labelKey = this.rule.config.match_case === 'SENSITIVE' ? trueLabel : falseLabel;

        return this.l10nService.getMessage(labelKey);
    }

    /**
     * Returns the label to use for displaying the PSM Rule's match_value_max_length.
     */
    public getValueMaxLengthLabel(): string {
        const { match_value_max_length: matchValueMaxLength } = this.rule.config;

        return isUndefined(matchValueMaxLength) ?
            this.l10nService.getMessage(l10nKeys.emptyMaxLengthValueLabel) :
            matchValueMaxLength.toString();
    }

    /**
     * Returns the name of the String Group.
     */
    public get stringGroupName(): string {
        const { match_value_string_group_ref: stringGroupRef } = this.rule.config;

        return isUndefined(stringGroupRef) ?
            this.l10nService.getMessage(l10nKeys.emptyStringGroupValue) :
            this.stringService.name(stringGroupRef);
    }
}
