/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './virtualservice-scale-out.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceScaleOut
 * @description
 *     Modal for scaling out a VIP. Allows the user to select a VIP and a Service Engine.
 * @param {Function} onCancel - Called when cancelling the scale out process.
 * @param {Function} onComplete - Called after the scale out process has been initiated.
 * @param {VirtualService} virtualservice
 */
class VirtualserviceScaleOutController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.l10nService_ = l10nService;
    }

    $onInit() {
        this.serviceEngines = [];
        this.canSpawnNewSE = false;
        this.hosts = [];

        this.params = {
            to_se_ref: undefined,
            to_new_se: false,
            to_host_ref: undefined,
            vip_id: undefined,
        };

        this.vips = this.virtualservice.getIPAddresses('hash');

        const [vip] = this.vips;

        if (vip) {
            this.params.vip_id = vip.vip_id;
        }

        this.setServiceEnginesAndHosts();
    }

    /**
     * Sets the list of Service Engines and hosts for selection in the dropdown.
     */
    setServiceEnginesAndHosts() {
        this.params.to_se_ref = undefined;

        if (angular.isUndefined(this.params.vip_id)) {
            this.serviceEngines = [];
            this.canSpawnNewSE = false;
            this.hosts = [];
        } else {
            this.busy = true;
            this.errors = null;

            this.virtualservice.getCandidateServiceEnginesAndHosts(this.params.vip_id)
                .then(candidates => {
                    this.serviceEngines = candidates['se_refs'] || [];
                    this.canSpawnNewSE = candidates['can_spawn_new_se'] || false;
                    this.hosts = candidates['host'] || [];
                })
                .catch(({ data }) => this.errors = data)
                .finally(() => this.busy = false);
        }
    }

    /**
     * Handles when the checkbox for "Create Service Engine" is changed.
     */
    handleCreateServiceEngineChange() {
        if (this.params.to_new_se) {
            this.params.to_se_ref = undefined;
        }
    }

    /**
     * Makes a request to scale out the selected VIP and SE.
     */
    submit() {
        const params = angular.copy(this.params);

        this.busy = true;
        this.errors = null;

        this.virtualservice.scaleOut(params)
            .then(() => this.onComplete({ vipId: params.vip_id }))
            .catch(rsp => this.errors = rsp.data)
            .finally(() => this.busy = false);
    }
}

VirtualserviceScaleOutController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('virtualserviceScaleOut', {
    bindings: {
        onCancel: '&',
        onComplete: '&',
        virtualservice: '<',
    },
    controller: VirtualserviceScaleOutController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-scale/' +
            'virtualservice-scale-out/virtualservice-scale-out.html',
});
