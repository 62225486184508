/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import {
    AviDropdownButtonPosition,
    IAviDataGridConfig,
    IAviDropdownButtonAction,
} from 'ng/shared/components';

import { DialogService } from 'ng/modules/core';
import { StringService } from 'ajs/modules/core/services/string-service';
import { Case } from 'ajs/modules/cportal';
import { L10nService } from '@vmw/ngx-vip';
import { AttachmentsSelectionDialogComponent } from '../attachments-selection-dialog';

import {
    FileType,
    IAttachmentSelection,
    IFileServiceAttachment,
} from './add-attachments-grid.types';

import * as l10n from './add-attachments-grid.l10n';
import './add-attachments-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * ID for attachments selection dialog.
 */
const ATTACHMENT_SELECTION_DIALOG_ID = 'attachments-selection';

/**
 * @description
 *      Component for a grid to shows the list of the files which are selected
 *      from the attachment selection dialog and to be attached to the case.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'add-attachments-grid',
    templateUrl: './add-attachments-grid.component.html',
})
export class AddAttachmentsGridComponent implements OnInit {
    /**
     * Case Item currently opened in case modal.
     */
    @Input()
    public editable: Case;

    /**
     * Position of the add attachments actions menu tooltip.
     */
    public actionPosition = AviDropdownButtonPosition.BOTTOM_LEFT;

    /**
     * DataGrid config to show the list of files to be attached to the case.
     */
    public filesToAttachGridConfig: IAviDataGridConfig;

    /**
     * List of attachment selection hash holding file type and attachment action label for
     * different file types.
     */
    public attachmentSelections: IAttachmentSelection[];

    /**
     * List of actions in the actions menu.
     */
    public addAttachmentsActions: IAviDropdownButtonAction[] = [];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly dialogService: DialogService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.attachmentSelections = [
            {
                fileType: FileType.TECH_SUPPORT,
                addAttachmentActionLabel: l10nService
                    .getMessage(l10nKeys.addTechSupportActionLabel),
            },
            {
                fileType: FileType.VS_PCAP,
                addAttachmentActionLabel: l10nService
                    .getMessage(l10nKeys.addTrafficCaptureActionLabel),
            },
            {
                fileType: FileType.ARCHIVE,
                addAttachmentActionLabel: l10nService.getMessage(l10nKeys.addCoreFilesActionLabel),
            },
            {
                fileType: FileType.UPLOADS,
                addAttachmentActionLabel: l10nService.getMessage(l10nKeys.addUploadsActionLabel),
            },
        ];
    }

    /** @override */
    public ngOnInit(): void {
        const { l10nService } = this;

        // Grid config for the grid which is used to show
        // the list of files to be attached to the case.
        this.filesToAttachGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.nameColumnLabel),
                    id: 'name',
                    transform: ({ url }: IFileServiceAttachment) => {
                        return this.stringService.slug(url) || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.statusColumnLabel),
                    id: 'status',
                    // Since there is no file upload status while creating the new case
                    // that's why returning '-'.
                    transform: () => {
                        return '-';
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.removeActionLabel),
                    onClick: (attachments: IFileServiceAttachment[]) => {
                        attachments.forEach((attachment: IFileServiceAttachment) => {
                            this.removeAttachment(attachment);
                        });
                    },
                },
            ],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'times-circle',
                onClick: (attachment: IFileServiceAttachment) => {
                    this.removeAttachment(attachment);
                },
            }],
            getRowId: (index: number): number => index,
            layout: {
                placeholderMessage: l10nService.getMessage(l10nKeys.emptyGridPlaceholder),
            },
        };

        // Add attachments actions.
        this.attachmentSelections.forEach((attachmentSelection: IAttachmentSelection) => {
            this.addAttachmentsActions.push({
                label: attachmentSelection.addAttachmentActionLabel,
                onClick: () => this.openAttachmentSelectionDialog(attachmentSelection),
            });
        });
    }

    /**
     * Returns the count of files to be attached to the case.
     */
    public get filesToAttachCount(): number {
        return this.filesToAttach?.length || 0;
    }

    /**
     * Returns list of files to be attached to the case.
     * Used to show the data in the grid.
     */
    public get filesToAttach(): IFileServiceAttachment[] {
        const { filesToAttach } = this.editable;

        return filesToAttach;
    }

    /**
     * Open attachments selection dialog.
     */
    public openAttachmentSelectionDialog(attachment: IAttachmentSelection): void {
        const { fileType, addAttachmentActionLabel } = attachment;

        this.dialogService.add({
            id: ATTACHMENT_SELECTION_DIALOG_ID,
            component: AttachmentsSelectionDialogComponent as Type<Component>,
            componentProps: {
                editable: this.editable,
                fileType,
                attachmentsSelectionDialogHeader: addAttachmentActionLabel,
            },
        });
    }

    /**
     * Function to remove the attachment from the grid.
     */
    private removeAttachment(attachment: IFileServiceAttachment): void {
        this.editable.removeAttachment(attachment);
    }
}
