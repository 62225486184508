/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'security-policy';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameDuplicatedMessage = `${prefix}.ruleNameDuplicatedMessage`;
export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const clientIpMatchLabel = `${prefix}.clientIpMatchLabel`;
export const servicePortMatchLabel = `${prefix}.servicePortMatchLabel`;
export const protocolTypeMatchLabel = `${prefix}.protocolTypeMatchLabel`;
export const httpMethodMatchLabel = `${prefix}.httpMethodMatchLabel`;
export const httpVersionMatchLabel = `${prefix}.httpVersionMatchLabel`;
export const pathMatchLabel = `${prefix}.pathMatchLabel`;
export const queryMatchLabel = `${prefix}.queryMatchLabel`;
export const headersMatchLabel = `${prefix}.headersMatchLabel`;
export const cookieMatchLabel = `${prefix}.cookieMatchLabel`;
export const hostHeaderMatchLabel = `${prefix}.hostHeaderMatchLabel`;
export const ipReputationMatchLabel = `${prefix}.ipReputationMatchLabel`;
export const createRuleActionLabel = `${prefix}.createRuleActionLabel`;
export const deleteRuleActionLabel = `${prefix}.deleteRuleActionLabel`;
export const menuActionLabel = `${prefix}.menuActionLabel`;
export const ipReputationDbHeader = `${prefix}.ipReputationDbHeader`;
export const ipReputationDbInputPlaceholder = `${prefix}.ipReputationDbInputPlaceholder`;
export const geoDbHeader = `${prefix}.geoDbHeader`;
export const geoDbInputPlaceholder = `${prefix}.geoDbInputPlaceholder`;
export const addHttpSecurityRuleHeader = `${prefix}.addHttpSecurityRuleHeader`;
export const matchingRulesHeader = `${prefix}.matchingRulesHeader`;
export const actionHeader = `${prefix}.actionHeader`;
export const actionInputPlaceholder = `${prefix}.actionInputPlaceholder`;
export const sendResponseSettingsHeader = `${prefix}.sendResponseSettingsHeader`;
export const statusCodeInputLabel = `${prefix}.statusCodeInputLabel`;
export const redirectToHttpsSettingsHeader = `${prefix}.redirectToHttpsSettingsHeader`;
export const portInputLabel = `${prefix}.portInputLabel`;
export const rateLimitSettingsHeader = `${prefix}.rateLimitSettingsHeader`;
export const countInputLabel = `${prefix}.countInputLabel`;
export const periodInputLabel = `${prefix}.periodInputLabel`;
export const secondsInnerText = `${prefix}.secondsInnerText`;
export const rateLimiterActionInputLabel = `${prefix}.rateLimiterActionInputLabel`;
export const rateLimiterActionInputPlaceholder = `${prefix}.rateLimiterActionInputPlaceholder`;
export const emptyLabel = `${prefix}.emptyLabel`;
export const reportOnlyLabel = `${prefix}.reportOnlyLabel`;
export const localResponseSettingsHeader = `${prefix}.localResponseSettingsHeader`;
export const rateLimiterActionStatusCodeInputLabel = `${prefix}.rateLimiterActionStatusCodeInputLabel`;
export const rateLimiterActionStatusCodeInputPlaceholder = `${prefix}.rateLimiterActionStatusCodeInputPlaceholder`;
export const fileUploadInputLabel = `${prefix}.fileUploadInputLabel`;
export const rbacHeader = `${prefix}.rbacHeader`;
export const botManagementLabel = `${prefix}.botManagementLabel`;
export const invalidBotManagemntValues = `${prefix}.invalidBotManagemntValues`;
export const sourceIpMatchLabel = `${prefix}.sourceIpMatchLabel`;

export const ENGLISH = {
    [ruleNameDuplicatedMessage]: '{0} - duplicated',
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [clientIpMatchLabel]: 'Client IP',
    [servicePortMatchLabel]: 'Service Port',
    [protocolTypeMatchLabel]: 'Protocol Type',
    [httpMethodMatchLabel]: 'HTTP Method',
    [httpVersionMatchLabel]: 'HTTP Version',
    [pathMatchLabel]: 'Path',
    [queryMatchLabel]: 'Query',
    [headersMatchLabel]: 'Headers',
    [cookieMatchLabel]: 'Cookie',
    [hostHeaderMatchLabel]: 'Host Header',
    [ipReputationMatchLabel]: 'IP Reputation',
    [createRuleActionLabel]: 'Create rule',
    [deleteRuleActionLabel]: 'Delete',
    [menuActionLabel]: 'Menu',
    [ipReputationDbHeader]: 'IP Reputation DB',
    [ipReputationDbInputPlaceholder]: 'Select IP Reputation DB',
    [geoDbHeader]: 'Geo DB',
    [geoDbInputPlaceholder]: 'Select Geo DB',
    [addHttpSecurityRuleHeader]: 'Add HTTP Security Rule',
    [matchingRulesHeader]: 'Matching Rules',
    [actionHeader]: 'Action',
    [actionInputPlaceholder]: 'Select Action',
    [sendResponseSettingsHeader]: 'Send Response Settings',
    [statusCodeInputLabel]: 'Status Code:',
    [redirectToHttpsSettingsHeader]: 'Redirect to HTTPS Settings',
    [portInputLabel]: 'Port',
    [rateLimitSettingsHeader]: 'Rate Limit Settings',
    [countInputLabel]: 'Count',
    [periodInputLabel]: 'Period',
    [secondsInnerText]: 'seconds',
    [rateLimiterActionInputLabel]: 'Rate Limiter Action',
    [rateLimiterActionInputPlaceholder]: 'Select Rate Limiter Action',
    [emptyLabel]: 'None',
    [reportOnlyLabel]: 'Report Only',
    [localResponseSettingsHeader]: 'Local Response Settings',
    [rateLimiterActionStatusCodeInputLabel]: 'Status Code',
    [rateLimiterActionStatusCodeInputPlaceholder]: 'Select Rate Limiter Action',
    [fileUploadInputLabel]: 'File',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
    [botManagementLabel]: 'Bot Management',
    [invalidBotManagemntValues]: 'Invalid Values For Bot Management Rule',
    [sourceIpMatchLabel]: 'Source IP',
};
