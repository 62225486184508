/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name wafEnable
 * @param {string} type - Either 'group' or 'rule'.
 * @param {string} name - Either the group name or the rule ID.
 * @param {WafPolicy} wafPolicy
 * @param {Function} onEnableChange - Called when the switch is clicked.
 */
class WafEnableController {
    $onInit() {
        this.configItem = this.getConfigItem();
    }

    /**
     * Returns the ConfigItem instance based on the type, either the WafRuleGroupConfig or the
     * WafRuleConfig.
     * @return {WafRuleGroupConfig|WafRuleConfig}
     */
    getConfigItem() {
        const { name } = this.topHitsListRecord;

        switch (this.type) {
            case 'group':
                return this.wafPolicy.getGroupByGroupName(name);
            case 'rule':
                return this.wafPolicy.getRuleByRuleId(name);
        }
    }

    /**
     * Returns true if the tri-switch should be hidden.
     * @return {boolean}
     */
    isDisabled() {
        return angular.isUndefined(this.configItem) || this.wafPolicy.isBusy();
    }

    /**
     * Returns the name to be displayed.
     * @return {string}
     */
    getName() {
        const { name } = this.topHitsListRecord;

        return this.type === 'rule' ?
            this.wafPolicy.getRuleNameByRuleId(name, true) || name :
            name;
    }

    /**
     * Returns the state of the tri-switch, either enabled or disabled.
     * @return {number} 1 or 0.
     */
    getEnableSwitchState() {
        return this.configItem && this.configItem.isEnabled() ? 1 : 0;
    }

    /**
     * Handler for clicking the switch. Sets the enable flag on the configItem and calls the
     * onEnableChange function. onEnableChange should trigger a WafPolicy save.
     */
    handleEnableSwitchClick() {
        const enabled = this.configItem.isEnabled();

        this.configItem.setEnabledState(!enabled);
        this.onEnableChange();
    }
}

angular.module('aviApp').component('wafEnable', {
    controller: WafEnableController,
    bindings: {
        topHitsListRecord: '<',
        type: '<',
        wafPolicy: '<',
        onEnableChange: '&',
    },
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/waf-enable/waf-enable.html',
});
