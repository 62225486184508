/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/shared/components';
import { DnsInfoConfigItem } from 'ajs/modules/dns/factories';
import { StringService } from 'ajs/modules/core/services';
import { RepeatedMessageItem } from 'ajs/modules/data-model/factories';
import * as l10n from './dns-info-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * DNSRecordType enum prefix.
 */
const DNS_RECORD_TYPE_PREFIX = 'DNS_RECORD_';

/**
 * @description DnsInfo Grid component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'dns-info-grid',
    templateUrl: './dns-info-grid.component.html',
})
export class DnsInfoGridComponent implements OnInit {
    /**
     * DnsInfoConfigItem RepeatedMessageItem instance.
     */
    @Input()
    public dnsInfoList: RepeatedMessageItem<DnsInfoConfigItem>;

    /**
     * Fires on add dnsInfo click.
     */
    @Output()
    public onAddDnsInfo = new EventEmitter<void>();

    /**
     * Fires on edit dnsInfo.
     */
    @Output()
    public onEditDnsInfo = new EventEmitter<DnsInfoConfigItem>();

    /**
     * Fires on delete dnsInfo.
     */
    @Output()
    public onDeleteDnsInfo = new EventEmitter<DnsInfoConfigItem>();

    /**
     * DnsInfo grid config.
     */
    public dnsInfoGridConfig: IAviDataGridConfig;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.dnsInfoGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: this.l10nService.getMessage(l10nKeys.columnTitleApplicationDomain),
                id: 'fqdn',
                transform(dnsInfoConfigItem: DnsInfoConfigItem) {
                    return dnsInfoConfigItem.fqdn;
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.columnTitleType),
                id: 'type',
                transform: (dnsInfoConfigItem: DnsInfoConfigItem) => {
                    const { type } = dnsInfoConfigItem.config;

                    return this.stringService.enumeration(type, DNS_RECORD_TYPE_PREFIX);
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.editActionLabel),
                shape: 'pencil',
                onClick: (dnsInfo: DnsInfoConfigItem) => this.editDnsInfo(dnsInfo),
            }, {
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'trash',
                onClick: (dnsInfo: DnsInfoConfigItem) => this.deleteDnsInfo(dnsInfo),
            }],
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                onClick: (dnsInfoList: DnsInfoConfigItem[]) => {
                    dnsInfoList.forEach((dns: DnsInfoConfigItem) => this.deleteDnsInfo(dns));
                },
            }],
        };
    }

    /**
     * Emits Add dnsInfo event.
     */
    public addDnsInfo(): void {
        this.onAddDnsInfo.emit();
    }

    /**
     * Emits Edit dnsInfo event.
     */
    public editDnsInfo(dnsInfoConfigItem: DnsInfoConfigItem): void {
        this.onEditDnsInfo.emit(dnsInfoConfigItem);
    }

    /**
     * Emits delete dnsInfo event.
     */
    public deleteDnsInfo(dnsInfoConfigItem: DnsInfoConfigItem): void {
        this.onDeleteDnsInfo.emit(dnsInfoConfigItem);
    }
}
