/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    isEmpty,
    isObject,
    isUndefined,
    pick,
} from 'underscore';

import {
    IIpAddr,
    IIpAddrPrefix,
    IIPNetworkSubnet,
    IpAddrType,
    IVip,
} from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { VipModalComponent } from 'ng/modules/vs-vip/components/vip-modal';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { VipPlacementNetworkConfigItem } from './vip-placement-network.config-item.factory';

type TVipPartial = Omit<IVip, 'placement_networks'>;

export interface IVipConfig extends TVipPartial {
    placement_networks: RepeatedMessageItem<VipPlacementNetworkConfigItem>
}

const PLACEMENT_NETWORKS = 'placement_networks';

/**
 * Ajs dependency token for VipConfigItem.
 */
export const VIP_CONFIG_ITEM_TOKEN = 'VipConfigItem';

/**
 * List of property names with IP addresses of the VIP.
 */
const IP_PROPS = [
    'ip_address',
    'floating_ip',
    'ip6_address',
    'floating_ip6',
];

/**
 * @description Vip ConfigItem class.
 * @author alextsg, Aravindh Nagarajan, Ram Pal
 */
export class VipConfigItem extends
    withEditChildMessageItemMixin<IVipConfig, typeof MessageItem>(
        withFullModalMixin(MessageItem),
    )<IVipConfig> {
    public static ajsDependencies = [
        'Regex',
        'RangeParser',
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'Vip',
            whitelistedFields: [
                'placement_networks',
            ],
            windowElement: VipModalComponent,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Sets the auto_allocate_ip field.
     */
    public setAutoAllocateIp(enabled = false): void {
        this.config.auto_allocate_ip = enabled;
    }

    /**
     * Sets the auto_allocate_floating_ip field.
     */
    public setAutoAllocateFloatingIp(enabled = false): void {
        this.config.auto_allocate_floating_ip = enabled;
    }

    /**
     * Returns the auto_allocate_floating_ip field.
     */
    public get autoAllocateFloatingIp(): boolean {
        return this.config.auto_allocate_floating_ip;
    }

    /**
     * Clears the network_ref property. If ipam_network_subnet exists, clears
     * ipam_network_subnet.network_ref as well.
     */
    public clearVipNetwork(): void {
        const config = this.getConfig();

        delete config.network_ref;

        if (config.ipam_network_subnet) {
            config.ipam_network_subnet.network_ref = undefined;
        }
    }

    /**
     * Sets the subnet and subnet_uuid fields.
     * @param subnet - Prefix for IP subnet.
     * @param uuid - Subnet uuid.
     * @param isIpamSubnet - If true, sets the subnet and uuid on the ipam_network_subnet object.
     *     Otherwise, set those properties on this.config.
     */
    public setVipSubnet(subnet: IIpAddrPrefix, uuid: string, isIpamSubnet = false): void {
        let config: Record<string, any> = this.getConfig();

        if (isIpamSubnet) {
            if (!isObject(config.ipam_network_subnet)) {
                config.ipam_network_subnet = {};
            }

            config = config.ipam_network_subnet;
        }

        switch (subnet.ip_addr.type) {
            case 'V4':
                config.subnet = subnet;
                config.subnet_uuid = uuid;
                break;
            case 'V6':
                config.subnet6 = subnet;
                config.subnet6_uuid = uuid;
                break;
        }
    }

    /**
     * Clears a set of subnet-related fields. If ipam_network_subnet exists, clears the subnet
     * properties in that object as well.
     * @param isOpenStack - True to clear Openstack-related fields as well.
     */
    public clearVipSubnet(isOpenStack = false): void {
        const { config } = this;

        // config fields to clear
        const networkFields = [
            'subnet',
            'subnet_uuid',
            'subnet6',
            'subnet6_uuid',
            'floating_subnet_uuid',
        ];

        if (isOpenStack) {
            // Fields to clear for openstack vs
            const openStackFields = [
                'port_uuid',
                'network_ref',
            ];

            networkFields.push(...openStackFields);
        }

        let ipamSubnet: IIPNetworkSubnet;

        if (isObject(config.ipam_network_subnet)) {
            ipamSubnet = config.ipam_network_subnet;
        }

        networkFields.forEach(field => {
            if (field in config) {
                delete config[field];
            }

            if (ipamSubnet && field in ipamSubnet) {
                delete ipamSubnet[field];
            }
        });
    }

    /**
     * Clears the selected placement_network's subnet
     * @param index - Index of the changed item in placement_networks' list.
     */
    public clearPlacementNetworkSubnet(index: number): void {
        const selectedPlacementNetwork =
            this.getPlacementNetwork(index) as VipPlacementNetworkConfigItem;

        selectedPlacementNetwork.resetSubnets();
    }

    /**
     * Adds a new entry to placement_networks List.
     */
    public addPlacementNetwork(): void {
        this.placementNetworks.add();
    }

    /**
     * Removes an entry from placement_network Object.
     */
    public removePlacementNetwork(index: number): void {
        this.placementNetworks.remove(index);
    }

    /**
     * Sets the ip_address property.
     * @param address - IP address string, object, or undefined.
     * @param prop - ip_address or ip6_address.
     */
    public setIpAddr(address: IIpAddr | string | undefined, prop = 'ip_address'): void {
        this.setAddress(prop, address);
    }

    /**
     * Sets the floating_ip property.
     * @param address - IP address string, object, or undefined.
     */
    public setFloatingIpAddr(address?: IIpAddr | string | undefined): void {
        this.setAddress('floating_ip', address);
    }

    /**
     * Clears the Vip#data#config#floating_ip and Vip#data#config#floating_subnet_uuid
     * fields.
     */
    public clearFloatingIp(): void {
        this.setFloatingIpAddr();
    }

    /**
     * Clears VIP Ipv4 & Ipv6 addresses.
     */
    public clearVipAddress(): void {
        this.clearVipIpv4Address();
        this.clearVipIpv6Address();
    }

    /**
     * Clears VIP Ipv4 address.
     */
    public clearVipIpv4Address(): void {
        this.config.ip_address.addr = undefined;
    }

    /**
     * Clears VIP Ipv6 address.
     */
    public clearVipIpv6Address(): void {
        this.config.ip6_address.addr = undefined;
    }

    /**
     * Returns true if floating IP is configured.
     */
    public floatingIpIsEnabled(): boolean {
        const {
            floating_ip: floatingIP,
            auto_allocate_floating_ip: autoAllocateFloatingIP,
        } = this.config;

        return Boolean(floatingIP?.addr || autoAllocateFloatingIP);
    }

    /**
     * Returns a hash of IP addresses used by this VIP.
     */
    public getVipAddressHash(): Record<string, any> {
        return pick(this.config, IP_PROPS);
    }

    /**
     * Returns either IPv4 or IPv6 address.
     */
    public getAnyVipAddress(): string {
        return this.getVipAddress() || this.getIP6VipAddress();
    }

    /**
     * Returns the IP address string.
     */
    public getVipAddress(): string | undefined {
        return this.config.ip_address?.addr;
    }

    /**
     * Returns the IPv6 address string.
     */
    public getIP6VipAddress(): string | undefined {
        return this.config.ip6_address?.addr;
    }

    /**
     * Returns true if Vip has been configured with an IP address or a network.
     * @override
     */
    public isValid(): boolean {
        const {
            network_ref: networkRef,
            ipam_network_subnet: ipamNetworkSubnet,
        } = this.config;

        return Boolean(this.getAnyVipAddress() || networkRef || ipamNetworkSubnet?.network_ref);
    }

    /**
     * Returns placement_networks list.
     */
    public get placementNetworks(): RepeatedMessageItem<VipPlacementNetworkConfigItem> {
        return this.config.placement_networks;
    }

    /**
     * Returns ipam_network_subnet.
     */
    public get ipamNetworkSubnet(): IIPNetworkSubnet {
        return this.config.ipam_network_subnet;
    }

    /**
     * Returns network_ref.
     */
    public get networkRef(): string {
        return this.config.network_ref;
    }

    /**
     * Returns the vip_id field, which is a number as a string, unique within one VSVip.
     */
    public get id(): string {
        return this.config.vip_id;
    }

    /**
     * Sets the vip_id field.
     */
    public set id(id: string) {
        this.config.vip_id = id;
    }

    /**
     * Returns true if vip is enabled, false otherwise.
     */
    public get enabled(): boolean {
        return this.config.enabled;
    }

    /**
     * Returns true if auto_allocate_ip is enabled, false otherwise.
     */
    public get isAutoAllocateIpEnabled(): boolean {
        return this.config.auto_allocate_ip;
    }

    /**
     * Getter for ipam_network_subnet config.
     */
    public get ipamNetworkConfig(): IIPNetworkSubnet {
        return this.config.ipam_network_subnet;
    }

    /**
     * Returns true if prefix_length field has the default value set.
     */
    public prefixLengthMatchesDefault(): boolean {
        const { prefix_length: defaultPrefixLength } = this.getDefaultConfig();

        return this.config.prefix_length === defaultPrefixLength;
    }

    /**
     * Sets prefix_length field to the default value.
     */
    public restoreDefaultPrefixLength(): void {
        const { prefix_length: defaultPrefixLength } = this.getDefaultConfig();

        this.config.prefix_length = defaultPrefixLength;
    }

    /**
     * Opens PlacementNetwork create modal.
     */
    public createPlacementNetwork(
        cloudId: string,
        vrfContextId: string,
        networkIdsToExclude = '',
    ): void {
        this.addChildMessageItem({
            field: PLACEMENT_NETWORKS,
            modalBindings: {
                cloudId,
                vrfContextId,
                networkIdsToExclude,
            },
        });
    }

    /**
     * Opens PlacementNetwork edit modal.
     */
    public editPlacementNetwork(
        network: VipPlacementNetworkConfigItem,
        cloudId: string,
        vrfContextId: string,
        networkIdsToExclude = '',
    ): void {
        this.addChildMessageItem({
            field: PLACEMENT_NETWORKS,
            messageItem: network,
            modalBindings: {
                cloudId,
                vrfContextId,
                isEditing: true,
                networkIdsToExclude,
            },
        });
    }

    /** @override */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        if (isEmpty(this.ipamNetworkSubnet)) {
            delete this.config.ipam_network_subnet;
        }

        if (isUndefined(this.getVipAddress())) {
            delete this.config.ip_address;
        }

        if (isUndefined(this.getIP6VipAddress())) {
            delete this.config.ip6_address;
        }
    }

    /** @override */
    protected modifyConfigDataAfterLoad(): void {
        if (isUndefined(this.config.ip_address)) {
            this.config.ip_address = {
                type: IpAddrType.V4,
            };
        }

        if (isUndefined(this.config.ip6_address)) {
            this.config.ip6_address = {
                type: IpAddrType.V6,
            };
        }

        if (isUndefined(this.config.ipam_network_subnet)) {
            this.config.ipam_network_subnet = {};
        }
    }

    /**
     * @override
     */
    protected getModalBreadcrumbDescription(): string {
        return this.config.vip_id || '';
    }

    /**
     * Sets the IP address in the VipConfig object for a specified property.
     * @param field - Property in the VipConfig object to update. Either 'floating_ip' or
     *     'ip_address'.
     * @param address - IP address string, IpAddr object, or undefined.
     */
    private setAddress(field: string, address?: IIpAddr | string): void {
        const Regex = this.getAjsDependency_('Regex');
        const RangeParser = this.getAjsDependency_('RangeParser');

        let value;

        if (isUndefined(address) || isObject(address)) {
            value = address;
        } else if (Regex.anyIP.test(address)) {
            value = RangeParser.ipRange2Json(address);
        }

        this.config[field] = value;
    }

    /**
     * Returns the placement_network for index.
     */
    private getPlacementNetwork(index: number): MessageItem {
        return this.placementNetworks.at(index);
    }
}
