/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-app-rule-log';
const prefix = `${moduleName}.${componentName}`;

export const applicationGroupLabel = `${prefix}.applicationGroupLabel`;
export const ruleLabel = `${prefix}.ruleLabel`;
export const messageLabel = `${prefix}.messageLabel`;
export const matchLabel = `${prefix}.matchLabel`;
export const phaseLabel = `${prefix}.phaseLabel`;
export const tagsLabel = `${prefix}.tagsLabel`;
export const naLabel = `${prefix}.naLabel`;

export const ENGLISH = {
    [applicationGroupLabel]: 'Application Group',
    [ruleLabel]: 'Rule',
    [messageLabel]: 'Message',
    [matchLabel]: 'Match',
    [phaseLabel]: 'Phase',
    [tagsLabel]: 'Tags',
    [naLabel]: 'N/A',
};
