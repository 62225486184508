/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HeaderModule
 */

import { Injector } from '@angular/core';

import {
    ActiveUserProfileService,
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
} from 'ajs/modules/core/services/active-user-profile';

const $injector = '$injector';

export const activeUserProfileServiceProvider = {
    deps: [$injector],
    provide: ActiveUserProfileService,
    useFactory(injector: Injector): ActiveUserProfileService {
        return injector.get(ACTIVE_USER_PROFILE_SERVICE_TOKEN);
    },
};

export const userProvider = {
    deps: [$injector],
    provide: 'User',
    useFactory(injector: Injector): any {
        return injector.get('User');
    },
};
