/**
 * @module BotDetectionPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    BOT_IP_REPUTATION_TYPE_MAPPING_COLLECTION_TOKEN,
    BOT_MAPPING_COLLECTION_TOKEN,
}
    from 'ajs/modules/bot-detection-policy/bot-detection-policy.tokens';

import {
    BotMappingCollection,
    TBotMappingCollection,
} from 'ajs/modules/bot-detection-policy/factories/bot-mapping.collection.factory';

import { BotIpReputationTypeMappingCollection } from
    'ajs/modules/bot-detection-policy/factories/bot-ip-reputation-type-mapping.collection.factory';

const $injector = '$injector';

export const botIpReputationTypeMappingCollectionProvider = {
    deps: [$injector],
    provide: BotIpReputationTypeMappingCollection,
    useFactory(injector: Injector): any {
        return injector.get(BOT_IP_REPUTATION_TYPE_MAPPING_COLLECTION_TOKEN);
    },
};

export const botMappingCollectionProvider = {
    deps: [$injector],
    provide: BotMappingCollection,
    useFactory(injector: Injector): TBotMappingCollection {
        return injector.get(BOT_MAPPING_COLLECTION_TOKEN);
    },
};

export const stringGroupCollectionProvider = {
    deps: [$injector],
    provide: 'StringGroupCollection',
    useFactory(injector: Injector): any {
        return injector.get('StringGroupCollection');
    },
};
