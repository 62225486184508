/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    TPbEnumName,
    TPbEnumValueKey,
    TPbMessageName,
} from '../schema-service/schema.types';

export class L10nKeysService {
    /**
     * Generates unique localizable string key for schema pb description.
     * Used by schemaService to retrieve translations and VIP library source collection script.
     * Could be customized for other methods of the service.
     * @param messageName Protobuf object type is used to compose key.
     * @param fieldName Field name is used to compose key.
     */
    public getPbDescriptionKey(
        messageName: TPbMessageName,
        fieldName: TPbEnumValueKey,
    ): string {
        return ['schema', 'pb', messageName, fieldName, 'desc'].join('.').toLowerCase();
    }

    /**
     * Generates unique localizable string key for schema enum text.
     * Used by schemaService to retrieve translations and VIP library source collection script.
     * Could be customized for other methods of the service.
     * @param enumName Enum name is used to compose key.
     * @param enumVal Enum value is used to compose key.
     */
    public getEnumLabelKey(enumName: TPbEnumName, enumVal: TPbEnumValueKey): string {
        return ['schema', 'enum', enumName, enumVal, 'label'].join('.').toLowerCase();
    }

    /**
     * Generates unique localizable string key for schema enum description.
     * Used by schemaService to retrieve translations and VIP library source collection script.
     * Could be customized for other methods of the service.
     * @param enumName Enum name is used to compose key.
     * @param enumVal Enum value is used to compose key.
     */
    public getEnumDescriptionKey(enumName: TPbEnumName, enumVal: TPbEnumValueKey): string {
        return ['schema', 'enum', enumName, enumVal, 'desc'].join('.').toLowerCase();
    }
}
