/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'event-details';
const prefix = `${moduleName}.${componentName}`;

export const descriptionLabel = `${prefix}.descriptionLabel`;
export const downloadCrsDataButtonLabel = `${prefix}.downloadCrsDataButtonLabel`;
export const previousConfigurationLabel = `${prefix}.previousConfigurationLabel`;
export const newConfigurationLabel = `${prefix}.newConfigurationLabel`;

export const ENGLISH = {
    [descriptionLabel]: 'Description',
    [downloadCrsDataButtonLabel]: 'Download CRS data',
    [previousConfigurationLabel]: 'Previous Configuration',
    [newConfigurationLabel]: 'New Configuration',
};
