/**
 * @module ClusterModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    ClusterModalComponent,
    ClusterNodeGridComponent,
    ClusterNodeModalComponent,
} from '.';

const clusterComponents = [
    ClusterModalComponent,
    ClusterNodeGridComponent,
    ClusterNodeModalComponent,
];

/**
 * @description
 *      Angular Module for Cluster Module Components.
 * @author Ratan Kumar
 */
@NgModule({
    declarations: [
        ...clusterComponents,
    ],
    imports: [
        SharedModule,
        AviFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ClusterModule {}
