/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { MessageItem, RepeatedMessageItem } from 'ajs/modules/data-model/factories';
import { IAviDataGridConfig } from 'ng/shared/components/avi-data-grid/avi-data-grid.types';
import { L10nService } from '@vmw/ngx-vip';
import { IWafContentTypeMapping } from 'generated-types';

import * as l10n from './waf-profile-content-type-mapping-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid Component for Waf Profile Content Type Mapping.
 *
 * @author Kondiparthi Shanmukha Sarath.
 */
@Component({
    selector: 'waf-profile-content-type-mapping-grid',
    templateUrl: './waf-profile-content-type-mapping-grid.component.html',
})
export class WafProfileContentTypeMappingGrid implements AfterViewInit {
    /**
     * Input Params List for Content Type Mapping.
     */
    @Input()
    public contentTypeMapping: RepeatedMessageItem<MessageItem<IWafContentTypeMapping>>;

    /**
     * Template Ref for Content Type String.
     */
    @ViewChild('contentTypeTemplateRef')
    public contentTypeTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template Ref for Request Parser Dropdown.
     */
    @ViewChild('requestParserTemplateRef')
    public requestParserTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Fires on Add button click for content-type-mapping.
     */
    @Output()
    public onAddContentTypeMapping = new EventEmitter();

    /**
     * Fires on Content Type Mapping removal.
     */
    @Output()
    public onRemoveContentTypeMapping = new EventEmitter<MessageItem>();

    /**
     * Config for Content Type Mapping.
     */
    public contentTypeMappingGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Content Type Mapping Grid Config Initialization.
     */
    public ngAfterViewInit(): void {
        this.contentTypeMappingGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.contentTypeInputLabel),
                    id: 'content-type',
                    templateRef: this.contentTypeTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.l10nKeys.requestParserDropdownLabel),
                    id: 'request-parser',
                    templateRef: this.requestParserTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(
                        this.l10nKeys.removeContentTypeMappingButtonLabel,
                    ),
                    onClick: (contentTypeMappingList: MessageItem[]) => {
                        contentTypeMappingList.forEach(mapping => this.removeMapping(mapping));
                    },
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(
                        this.l10nKeys.removeContentTypeMappingButtonLabel,
                    ),
                    shape: 'trash',
                    onClick: (contentTypeMapping: MessageItem) => {
                        this.removeMapping(contentTypeMapping);
                    },
                },
            ],
            getRowId(index: number) {
                return index;
            },
            layout: {
                hidePagination: true,
            },
        };
    }

    /**
     * Handler for Adding Content Type Mapping.
     */
    public addContentTypeMapping(): void {
        this.onAddContentTypeMapping.emit();
    }

    /**
     * Handler for removing Content Type Mapping.
     */
    private removeMapping(mapping: MessageItem): void {
        this.onRemoveContentTypeMapping.emit(mapping);
    }
}
