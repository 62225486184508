/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  SensitiveLogProfileConfig
 * @description
 *     SensitiveLogProfileConfig, inherits LegacyPolicyRuleConfigItem class,
 *     mapped to the protobuf message SensitiiveLogProfile.
 */
function sensitiveLogProfileConfigFactory(
        LegacyPolicyConfigItem, SensitiveFieldRuleConfig,
) {
    class SensitiveLogProfileConfig extends LegacyPolicyConfigItem {}

    angular.extend(SensitiveLogProfileConfig.prototype, {
        type: 'SensitiveLogProfile',
        windowElement_: 'sensitive-field-rule-modal',
        ruleClass_: SensitiveFieldRuleConfig,
        rulesPropertyName_: 'header_field_rules',
    });

    return SensitiveLogProfileConfig;
}

sensitiveLogProfileConfigFactory.$inject = [
        'LegacyPolicyConfigItem',
        'SensitiveFieldRuleConfig',
];

angular.module('aviApp').factory(
    'SensitiveLogProfileConfig', sensitiveLogProfileConfigFactory,
);
