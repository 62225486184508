/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gslb-edit-controller';
const prefix = `${moduleName}.${componentName}`;

export const editGslbConfigurationTitle = `${prefix}.editGslbConfigurationTitle`;
export const newGslbConfigurationTitle = `${prefix}.newGslbConfigurationTitle`;
export const newGslbSiteTitle = `${prefix}.newGslbSiteTitle`;
export const eidtGslbSiteTitle = `${prefix}.eidtGslbSiteTitle`;
export const dnsVirtualServicesTitle = `${prefix}.dnsVirtualServicesTitle`;
export const gslbSubdomainInputLabel = `${prefix}.gslbSubdomainInputLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const userNameInputLabel = `${prefix}.userNameInputLabel`;
export const activeMemberCheckboxLabel = `${prefix}.activeMemberCheckboxLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const ipAdressInputLabel = `${prefix}.ipAdressInputLabel`;
export const removeIpAdressButtonTitle = `${prefix}.removeIpAdressButtonTitle`;
export const addIpAdressLink = `${prefix}.addIpAdressLink`;
export const portInputLabel = `${prefix}.portInputLabel`;
export const removeDnsRuleButtonTitle = `${prefix}.removeDnsRuleButtonTitle`;
export const addGslbSubdomainLink = `${prefix}.addGslbSubdomainLink`;
export const advancedSettingsSectionTitle = `${prefix}.advancedSettingsSectionTitle`;
export const clientGroupIpAdressTypeDropdownList = `${prefix}.clientGroupIpAdressTypeDropdownList`;
export const clientGroupIpAdressTypePlaceholder = `${prefix}.clientGroupIpAdressTypePlaceholder`;
export const ipAdressPrefixRangeInputPlaceholder = `${prefix}.ipAdressPrefixRangeInputPlaceholder`;
export const addGroupIpAdressLink = `${prefix}.addGroupIpAdressLink`;
export const saveLabel = `${prefix}.saveLabel`;
export const saveAndSetDnsVirtualServicesButton = `${prefix}.saveAndSetDnsVirtualServicesButton`;
export const continuousReplicationModeLabel = `${prefix}.continuousReplicationModeLabel`;
export const adaptiveReplicationModeLabel = `${prefix}.adaptiveReplicationModeLabel`;
export const replicationModeLabel = `${prefix}.replicationModeLabel`;

export const ENGLISH = {
    [editGslbConfigurationTitle]: 'Edit GSLB Configuration',
    [newGslbConfigurationTitle]: 'New GSLB Configuration',
    [newGslbSiteTitle]: 'New GSLB Site',
    [eidtGslbSiteTitle]: 'Edit GSLB Site',
    [dnsVirtualServicesTitle]: '{0} DNS Virtual Services',
    [gslbSubdomainInputLabel]: 'GSLB Subdomain',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Name',
    [userNameInputLabel]: 'Username',
    [activeMemberCheckboxLabel]: 'Active Member',
    [passwordInputLabel]: 'Password',
    [ipAdressInputLabel]: 'IP Address',
    [removeIpAdressButtonTitle]: 'Remove Ip Address',
    [addIpAdressLink]: 'Add IP Address',
    [portInputLabel]: 'Port',
    [removeDnsRuleButtonTitle]: 'Remove DNS Rule',
    [addGslbSubdomainLink]: 'Add GSLB Subdomain',
    [advancedSettingsSectionTitle]: 'Advanced Settings',
    [clientGroupIpAdressTypeDropdownList]: 'Client Group IP Address Type',
    [clientGroupIpAdressTypePlaceholder]: 'Client Group IP Address Type',
    [ipAdressPrefixRangeInputPlaceholder]: 'IP Address or Prefix or Range',
    [addGroupIpAdressLink]: '+ Add Group IP Address',
    [saveLabel]: 'Save',
    [saveAndSetDnsVirtualServicesButton]: 'Save and Set DNS Virtual Services',
    [continuousReplicationModeLabel]: 'Continuous',
    [adaptiveReplicationModeLabel]: 'Adaptive',
    [replicationModeLabel]: 'Replication Mode',
};
