/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name HSGraphMetrics
 * @author Alex Malitsky
 * @description
 *
 *     Health Score graph metrics class. It is getting updates on onDataUpdate_ and
 *     onActiveNodeChange_ HSGraph events, puts metrics into two groups: 'child'
 *     and 'graph', updates metrics which existed before and creates/destroys new or
 *     removed ones.
 *
 *     HS Graph gets a start timestamp param for metrics incremental updates through getSeriesStart.
 *
 *     From API we are getting series and out of those we are creating Metrics with one-to-one
 *     relation to series (to be changed soon).
 *
 *     Two events are triggered by this class:
 *     - GROUP_UPDATE_EVENT: when list of metrics is updated within the group, group name and
 *         list of metrics are passed as arguments.
 *     - BEFORE_LOAD_EVENT: when another active node git clicked by user wo arguments.
 */
angular.module('aviApp').factory('HSGraphMetrics', [
    'Base', 'HSGraphMetric',
    function(Base, HSGraphMetric) {
        class HSGraphMetrics extends Base {
            /**
             * @constructor
             * @param {Object=} args
             */
            constructor(args = {}) {
                super(args);

                const { graph } = args;

                if (!graph) {
                    console.error('Can\'t create HSGraphMetric instance wo reference to HSGraph');
                }

                /** @type {HSGraph} */
                this.graph_ = graph;

                /**
                 * Hash of all metrics we have by full metric id.
                 * @type {{string: HSGraphMetric}}
                 * @public
                 */
                this.metricsHash = {};

                /**
                 * When set to true (through event listener) switches off incremental updates.
                 * Is set to false after every update.
                 * @type {boolean}
                 * */
                this.activeNodeHasChanged_ = true;

                /**
                 * Hash of group to series ids lists.
                 * @type {{string: Series.id[]}}
                 */
                this.seriesByGroup = {};

                /**
                 * Hash of group to metric ids lists.
                 * @type {{string: Series.id[]}}
                 */
                this.metricsByGroup = {};

                _.each(HSGraphMetrics.groupToPropNames, (list, key) => {
                    this.seriesByGroup[key] = [];
                    this.metricsByGroup[key] = [];
                });

                this.onDataUpdate_(graph.getMetricsData());

                [
                    'destroy',
                    'onDataUpdate_',
                    'onActiveNodeChange_',
                ].forEach(methodName => this[methodName] = this[methodName].bind(this));

                graph.one('beforeDestroy', this.destroy);
                graph.on('metricsDataUpdate', this.onDataUpdate_);
                graph.on('activeNodeChanged', this.onActiveNodeChange_);
            }

            /**
             * Creates and updates metrics putting them in appropriate groups and triggers
             * update event.
             * @param {Object} data - API response object filtered by a list of metrics fields.
             * @private
             */
            //TODO add support of Metrics containing two or more series
            onDataUpdate_(data) {
                const
                    currentMetricsIds = _.keys(this.metricsHash),
                    newMetricsIds = [],
                    groupMetricsHash = {};//will compare with a new one after update happens

                this.activeNodeHasChanged_ = false;

                let dom;

                if (angular.isObject(data)) {
                    dom = data.dominant_contributors;
                    delete data.dominant_contributors;
                }

                //compute hashes and flush group to metric id relations
                _.each(this.metricsByGroup, (list, groupName) => {
                    groupMetricsHash[groupName] = HSGraphMetrics.getMetricsIdListHash(list);
                    list.length = 0;
                });

                _.each(this.seriesByGroup, list => list.length = 0);

                //for every metrics property in API response
                _.each(data, (data, propName) => {
                    const groups = HSGraphMetrics.namesToPropGroup[propName];

                    if (!angular.isArray(data)) {
                        data = [data];
                    }

                    //every property in a series
                    data.forEach(series => {
                        const { name: seriesId, title } = series.header;

                        if (seriesId in dom) {
                            series.dominators = dom[seriesId];
                        }

                        //group to series id mapping and newMetricId lists are made here
                        groups.forEach(groupName => {
                            this.seriesByGroup[groupName].push(seriesId);

                            // one to one relation for children
                            this.metricsByGroup[groupName].push(seriesId);
                        });

                        newMetricsIds.push(seriesId);

                        if (!(seriesId in this.metricsHash)) {
                            this.metricsHash[seriesId] = new HSGraphMetric({
                                name: seriesId,
                                title,
                                series: seriesId,
                            });

                            this.metricsHash[seriesId].beforeCall();
                        }

                        this.metricsHash[seriesId].afterCall(series);
                    });
                });

                _.difference(currentMetricsIds, newMetricsIds)
                    .forEach(
                        this.destroyMetricById_.bind(this),
                    );

                const updatedGroups = [];

                _.each(this.metricsByGroup, (list, groupName) => {
                    if (HSGraphMetrics.getMetricsIdListHash(list) !== groupMetricsHash[groupName]) {
                        updatedGroups.push(groupName);
                    }
                });

                if (updatedGroups.length) {
                    updatedGroups.forEach(groupName => this.trigger(
                        HSGraphMetrics.GROUP_UPDATE_EVENT,
                        groupName,
                        this.getMetricsByGroupName(groupName),
                    ));
                }
            }

            /**
             * After an active node change we should not use start param in API call since the
             * series list might have changed. Triggers an appropriate event.
             * @private
             */
            onActiveNodeChange_() {
                this.activeNodeHasChanged_ = true;
                this.trigger(HSGraphMetrics.BEFORE_LOAD_EVENT);
            }

            /**
             * Returns a list of metrics of a passed groupName.
             * @param {string} groupName
             * @returns {HSGraphMetric[]}
             * @public
             */
            getMetricsByGroupName(groupName) {
                return _.values(
                    _.pick(this.metricsHash, this.metricsByGroup[groupName]),
                );
            }

            /**
             * For incremental updates we want to pick the most recent timestamp all time series
             * have. Unless active node had changed before this update.
             * @returns {number|undefined}
             */
            getSeriesStart() {
                let start = Infinity;

                _.each(this.metricsHash, metric => {
                    //only child metrics define the start param for incremental updates
                    if (_.contains(this.metricsByGroup['child'], metric.name)) {
                        start = Math.min(metric.getLastPointTime(), start);
                    }

                    metric.beforeCall();
                });

                //incremental updates only for the fixed active node
                return !this.activeNodeHasChanged_ && _.isFinite(start) ? start : undefined;
            }

            /**
             * Destroys metrics and removes it from hash.
             * @param {string} metricId
             * @private
             */
            destroyMetricById_(metricId) {
                const { metricsHash } = this;

                if (metricId in metricsHash) {
                    metricsHash[metricId].destroy();
                    delete metricsHash[metricId];
                }
            }

            /** @override */
            destroy() {
                const gotDestroyed = super.destroy();

                if (gotDestroyed) {
                    const { graph_: graph } = this;

                    graph.unbind('beforeDestroy', this.destroy);
                    graph.unbind('metricsDataUpdate', this.onDataUpdate_);
                    graph.unbind('activeNodeChanged', this.onActiveNodeChange_);

                    Object.keys(this.metricsHash).forEach(
                        this.destroyMetricById_.bind(this),
                    );
                }

                return gotDestroyed;
            }
        }

        /**
         * Mapping of group name to series property list.
         * @type {{string: string[]}}
         */
        HSGraphMetrics.groupToPropNames = {
            child: ['child_metrics'],
            //do not participate in last timestamp calculation
            graph: ['hs_series', 'root_child_series'],
        };

        /**
         * Mapping of series name to metric groups it belongs to. We use an alpha function to
         * generate it from HSGraphMetrics.groupToPropNames.
         * @type {{string: string[]}}
         */
        HSGraphMetrics.namesToPropGroup = {};

        (function() {
            const hash = this.namesToPropGroup;

            _.each(this.groupToPropNames, (list, key) => {
                list.forEach(propName => {
                    if (!(propName in hash)) {
                        hash[propName] = [];
                    }

                    hash[propName].push(key);
                });
            });
        }).call(HSGraphMetrics);

        /**
         * List of series properties returned by API.
         * @type {string[]}
         */
        HSGraphMetrics.seriesPropList = Object.keys(HSGraphMetrics.namesToPropGroup);

        /**
         * To compare series lists of each group we sort and concatenate their ids.
         * @param {string[]} list
         * @returns {string}
         * @static
         */
        HSGraphMetrics.getMetricsIdListHash = function(list) {
            return list.sort().join('|');
        };

        /**
         * Metric group update event name.
         * @type {string}
         */
        HSGraphMetrics.GROUP_UPDATE_EVENT = 'groupListUpdate';

        /**
         * Active node change event name.
         * @type {string}
         */
        HSGraphMetrics.BEFORE_LOAD_EVENT = 'beforeLoad';

        return HSGraphMetrics;
    }]);
