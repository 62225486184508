/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  VirtualServiceFaultDataTransformer
 * @description
 *     DataTransformer responsible for adding faults to Virtual Service from the
 *     "virtualservice-inventory" request.
 */
angular.module('aviApp').factory('VirtualServiceFaultDataTransformer', [
'DataTransformer', 'FaultService',
(DataTransformer, FaultService) => {
    return class VirtualServiceFaultDataTransformer extends DataTransformer {
        constructor(args) {
            super(args);
            this.name = 'faults';
        }

        /**
         * @override
         */
        beforeCall(data, groupCall) {
            if (groupCall.inventory) {
                groupCall.inventory.include.faults = true;
            }
        }

        /**
         * @override
         */
        afterCall(data, groupCall) {
            if (groupCall.inventory && groupCall.inventory.response) {
                if (!_.isEmpty(groupCall.inventory.response.faults)) {
                    data.faults = FaultService.parseFaults(groupCall.inventory.response.faults);
                    FaultService.handleVirtualserviceUpdate(data.faults);
                }
            }
        }
    };
}]);
