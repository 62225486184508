/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'welcome-email-localhost-settings';
const prefix = `${moduleName}.${componentName}`;

export const fromAddressLabel = `${prefix}.fromAddressLabel`;

export const ENGLISH = {
    [fromAddressLabel]: 'From Address',
};
