/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { UDPFastPathProfileConfigItem } from 'ajs/modules/network';

import * as l10n from './udp-fast-path.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Udp fast path setting component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'udp-fast-path',
    templateUrl: './udp-fast-path.component.html',
})
export class UdpFastPathComponent {
    /**
     * Udp fast path profile data.
     */
    @Input()
    public editable: UDPFastPathProfileConfigItem;

    /**
     * Object type for Udp fast path.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
