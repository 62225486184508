/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { BotMappingRuleMatchTarget } from 'object-types';
import { IBotMappingRuleMatchTarget } from 'generated-types';
import {
    BotClassMatcherConfigItem,
    BotTypeMatcherConfigItem,
    HdrMatchConfigItem,
    HostHdrMatchConfigItem,
    IpAddrMatchConfigItem,
    MethodMatchConfigItem,
    PathMatchConfigItem,
    StringMatchConfigItem,
} from 'ajs/modules/match';
import { PolicyMatchConfigItem } from './policy-match.config-item.factory';

/* tslint:disable:whitespace */
/* eslint-disable @typescript-eslint/indent */
type TBotMappingRuleMatchTargetPartial = Omit<
    IBotMappingRuleMatchTarget,
    'class_matcher' |
    'client_ip' |
    'hdrs' |
    'host_hdr' |
    'identifier_matcher' |
    'method' |
    'path' |
    'type_matcher'
>;
/* tslint:enable:whitespace */

interface IBotMappingRuleMatchTargetConfig extends TBotMappingRuleMatchTargetPartial {
    class_matcher?: BotClassMatcherConfigItem;
    client_ip?: IpAddrMatchConfigItem,
    hdrs?: HdrMatchConfigItem,
    host_hdr?: HostHdrMatchConfigItem,
    identifier_matcher?: StringMatchConfigItem,
    method?: MethodMatchConfigItem,
    path?: PathMatchConfigItem,
    type_matcher?: BotTypeMatcherConfigItem,

}

export const botMappingRulesSupportedMatches = [
    'class_matcher',
    'client_ip',
    'hdrs',
    'host_hdr',
    'identifier_matcher',
    'method',
    'path',
    'type_matcher',
];

export class BotMappingRuleMatchTargetConfigItem extends
    PolicyMatchConfigItem<IBotMappingRuleMatchTargetConfig> {
    /** @override */
    public readonly fields = botMappingRulesSupportedMatches;

    constructor(args = {}) {
        super({
            objectType: BotMappingRuleMatchTarget,
            ...args,
        });
    }

    /**
     * Informs if minimum required fields are set.
     */
    public meetsMatchMinimumConfiguration(): boolean {
        return Boolean(this.configuredMatchFields.length);
    }

    /** @override */
    public canFlatten(): boolean {
        return this.meetsMatchMinimumConfiguration();
    }
}
