/**
 * @module NotificationsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { pluck } from 'underscore';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { ControllerFault } from 'ajs/modules/notifications';

/**
 * @description Collection of Controller Faults.
 * @author Alex Tseung, Alex Malitsky, Aravindh Nagarajan
 */
export class ControllerFaultCollection extends Collection {
    constructor(args: Record<string, any> = {}) {
        super(args);

        this.itemClass_ = this.getAjsDependency_('ControllerFault');
    }

    /**
     * Returns a list of all fault descriptions.
     * @return Array of fault descriptions.
     */
    public get faultDescriptions(): string[] {
        const controllerFaults = this.itemList as ControllerFault[];

        return controllerFaults.reduce((faultsList: string[], item: ControllerFault) => {
            const { faults: itemFaults } = item.getConfig();
            const descriptions = pluck(itemFaults, 'description');

            faultsList.push(...descriptions);

            return faultsList;
        }, []);
    }
}

Object.assign(ControllerFaultCollection.prototype, {
    objectName_: 'controller-inventory',
    allDataSources_: {
        list: {
            source: 'ControllerFaultCollDataSource',
            transformer: 'ControllerFaultDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config', 'faults'],
        },
    },
    defaultDataFields_: ['config', 'faults'],
    isStatic_: false,
});

ControllerFaultCollection.ajsDependencies = [
    'ControllerFault',
];
