/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IMethodMatch,
    MatchOperation,
} from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { MethodMatch } from 'object-types';

/**
 * @description  MethodMatch ConfigItem class.
 * @author Abhinesh Gour
 */
export class MethodMatchConfigItem extends MessageItem<IMethodMatch> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: MethodMatch,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IMethodMatch> {
        return {
            match_criteria: MatchOperation.IS_IN,
        };
    }
}
