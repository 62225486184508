/**
 * @module LoginModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';

import './login-container.component.less';

/**
 * @description
 *      Login-container component.
 *          - Background
 *          - App title
 *          - will project whatever component passed.
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'login-container',
    templateUrl: './login-container.component.html',
})
export class LoginContainerComponent {}
