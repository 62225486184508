/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    RevisedDataTransformer,
} from 'ajs/modules/data-model/factories/revised-data-transformer.factory';

interface IGSLBServiceFQDNListResponse {
    data: IGSLBServiceFQDNListData;
}

interface IGSLBServiceFQDNListData {
    results: any[];
    count: number;
}

/**
 * @description
 *
 *     Used with IdentityDataTransport meaning we have to transform a request a bit to get an
 *     expected format of response.
 *
 * @author Ram Pal
 */
export class GSLBServiceFQDNListDataTransformer extends RevisedDataTransformer {
    /** override */
    public processResponse({ results }: IGSLBServiceFQDNListData): IGSLBServiceFQDNListResponse {
        return {
            data: {
                results: results.map((config: any) => ({ config })),
                count: results.length,
            },
        };
    }
}
