/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    VCenterServer,
    VCenterServerCollection,
} from 'ajs/modules/cloud';
import { StringService } from 'ajs/modules/core/services/string-service';
import { IAviDataGridConfig } from 'ng/shared/components';
import './vcenter-servers-list.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './vcenter-servers-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TVCenterServerCollection = typeof VCenterServerCollection;

/**
 * @description Component to configure VCenterServers in the NSX-T Cloud.
 * @author alextsg
 */
@Component({
    selector: 'vcenter-servers-list',
    templateUrl: './vcenter-servers-list.component.html',
})
export class VCenterServersListComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * True if the "Add" button should be disabled.
     */
    @Input()
    public disableAdd ? = false;

    /**
     * Cloud url, when editing a cloud.
     */
    @Input()
    public cloudRef?: string;

    /**
     * List of vCenter addresses.
     */
    @Input()
    public vcenterAddresses?: string[];

    /**
     * Configured transport zone. If the transport zone is changed, remove all configured
     * VCenterServers.
     */
    @Input()
    public transportZone?: string;

    /**
     * Called to add a VCenterServer.
     */
    @Output()
    public onAddVCenterServer: EventEmitter<VCenterServer> = new EventEmitter();

    /**
     * Called to delete a VCenterServer.
     */
    @Output()
    public onDeleteVCenterServer: EventEmitter<VCenterServer> = new EventEmitter();

    /**
     * AviDataGrid GridConfig for vCenterServers.
     */
    public vCenterServersGridConfig: IAviDataGridConfig;

    /**
     * List of VCenterServers passed to the datagrid, which will contain both edited VCenterServers
     * (from the VCenterServerCollection) and newly created VCenterServers. We're not using
     * VCenterServerCollection for creating/editing because we're using the macrostack API.
     */
    public vCenterServers: VCenterServer[] = [];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * VCenterServerCollection instance. Used for loading existing VCenterServers.
     */
    private vCenterServerCollection: VCenterServerCollection;

    constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(VCenterServerCollection)
        VCenterServerCollection: TVCenterServerCollection,
        l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        this.vCenterServerCollection = new VCenterServerCollection();

        l10nService.registerSourceBundles(dictionary);

        this.vCenterServersGridConfig = {
            getRowId(index): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleName),
                    id: 'name',
                    transform(vCenterServer: VCenterServer) {
                        return vCenterServer.config.name;
                    },
                },
                {
                    label: 'URL',
                    id: 'url',
                    transform(vCenterServer: VCenterServer) {
                        return vCenterServer.config.vcenter_url;
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.actionTitleRemove),
                    onClick: (vCenterServers: VCenterServer[]) => {
                        this.deleteVCenterServers(vCenterServers);
                    },
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.actionTitleEdit),
                    shape: 'pencil',
                    onClick: (vCenterServer: VCenterServer) =>
                        this.editVCenterServer(vCenterServer),
                },
                {
                    label: l10nService.getMessage(l10nKeys.actionTitleRemove),
                    shape: 'trash',
                    onClick: (vCenterServer: VCenterServer) =>
                        this.deleteVCenterServer(vCenterServer),
                },
            ],
        };
    }

    /** @override */
    public async ngOnInit(): Promise<void> {
        if (this.isEditingCloud) {
            this.vCenterServerCollection.setParams({
                'cloud_ref.uuid': this.stringService.slug(this.cloudRef),
            });

            await this.vCenterServerCollection.load();

            const vcenterServers = this.vCenterServerCollection.itemList as any as VCenterServer[];

            this.vCenterServers = this.vCenterServers.concat(vcenterServers);
        }
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { transportZone } = changes;

        if (transportZone) {
            const { currentValue, previousValue, firstChange } = transportZone;

            if (currentValue !== previousValue && !firstChange) {
                this.deleteVCenterServers(this.vCenterServers);
            }
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.vCenterServerCollection.destroy();
    }

    /**
     * Returns true if this.cloudRef exists, meaning that the cloud is being edited and not created.
     */
    public get isEditingCloud(): boolean {
        return Boolean(this.cloudRef);
    }

    /**
     * Called to create a new vCenterServer.
     */
    public createVCenterServer(): void {
        this.vCenterServerCollection.create(undefined, {
            cloudRef: this.cloudRef,
            hideCloud: !this.isEditingCloud,
            vcenterAddresses: this.vcenterAddresses,
            onSubmit: (vCenterServer: VCenterServer) => {
                this.addVCenterServer(vCenterServer);
                vCenterServer.closeModal();
            },
        });
    }

    /**
     * Adds a vCenterServer.
     */
    private addVCenterServer(vCenterServer: VCenterServer): void {
        this.vCenterServers = this.vCenterServers.concat(vCenterServer);
        this.onAddVCenterServer.emit(vCenterServer);
    }

    /**
     * Called to edit a vCenterServer. On success, the returned VCenterServer instance is different
     * from the one edit is called on, so we remove the old instance and add the new instance.
     */
    private editVCenterServer(vCenterServerToEdit: VCenterServer): void {
        vCenterServerToEdit.edit(undefined, {
            cloudRef: this.cloudRef,
            hideCloud: !this.isEditingCloud,
            vcenterAddresses: this.vcenterAddresses,
            onSubmit: (editedVCenterServer: VCenterServer) => {
                vCenterServerToEdit.updateItemData({ config: editedVCenterServer.dataToSave() });
                this.onAddVCenterServer.emit(vCenterServerToEdit);
                vCenterServerToEdit.closeModal();
            },
        });
    }

    /**
     * Deletes a list of VCenterServers.
     */
    private deleteVCenterServers(vCenterServers: VCenterServer[]): void {
        vCenterServers.forEach(vCenterServer => this.deleteVCenterServer(vCenterServer));
    }

    /**
     * Deletes a single VCenterServer. Removes the VCenterServer from the datagrid list, then emits
     * the onDeleteVCenterServer event.
     */
    private deleteVCenterServer(vCenterServer: VCenterServer): void {
        this.removeVCenterServerFromComponent(vCenterServer);
        this.onDeleteVCenterServer.emit(vCenterServer);
    }

    /**
     * Removes a VCenterServer from the datagrid list.
     */
    private removeVCenterServerFromComponent(vCenterServer: VCenterServer): void {
        const index = this.vCenterServers.indexOf(vCenterServer);

        this.vCenterServers = [
            ...this.vCenterServers.slice(0, index),
            ...this.vCenterServers.slice(index + 1),
        ];
    }
}
