/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import template from './gslb-replication-status.component.html';
import './gslb-replication-status.component.less';

/**
 * StatusWithIcon Array holds the list of replicatiopn status for which
 * the exclamation-triangle icon should be displayed.
 */
const statusWithIcon: string[] = [
    'GSLB_SITE_CFG_SYNC_STALLED',
    'GSLB_SITE_CFG_OUT_OF_SYNC',
];

/**
 * @description
 *
 *      GslbReplicationStatus component display the replication status in the collections grid.
 *
 * @author tmaridi
 */
class GslbReplicationStatusController {
    /**
     * Representing current data of the Gslb site configuration.
     */
    public row: any;

    /**
     * Property holding popover object.
     */
    private gslbReplicationStatusControllerPopover: any = null;

    constructor(
        private readonly $scope: ng.IScope,
        private readonly $compile: ng.ICompileService,
        private readonly PopoverFactory: any,
    ) {}

    /** @override */
    public $onInit(): void {
        this.setPopoverInstance();
    }

    /** @override */
    public $onDestroy(): void {
        this.gslbReplicationStatusControllerPopover.remove();
    }

    /**
     * Function to open the gslb replication status popover on mouse hover.
     */
    public onHover($event: MouseEvent): void {
        $event.stopPropagation();
        this.gslbReplicationStatusControllerPopover.show($event.target);
    }

    /**
     * Function to close the gslb replication status popover on mouse leave.
     */
    public onLeave(): void {
        this.gslbReplicationStatusControllerPopover.hide();
    }

    /**
     * Function to display the icon for some sync states.
     */
    public displayIcon(): boolean {
        const { syncState } = this.row.data.runtime.replicationStatus;

        return statusWithIcon.includes(syncState);
    }

    /**
     * Compile popover template and set an instance of PopoverFactory.
     */
    private setPopoverInstance(): void {
        const popoverTemplate = `
            <gslb-replication-status-popover
                row="$ctrl.row"
            ></gslb-replication-status-popover>`;

        const $popoverContent = $(popoverTemplate);

        this.gslbReplicationStatusControllerPopover = new this.PopoverFactory({
            position: 'left',
            repositioning: true,
            removeAfterHide: true,
            html: this.$compile($popoverContent)(this.$scope),
            carat: true,
            hide: {
                outClick: true,
                onEscape: true,
            },
        });
    }
}

GslbReplicationStatusController.$inject = [
    '$scope',
    '$compile',
    'popoverFactory',
];

export const gslbReplicationStatusOptions = {
    bindings: {
        row: '<',
    },
    controller: GslbReplicationStatusController,
    template,
};
