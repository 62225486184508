/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    AutoScalePolicy,
    AutoScalePolicyCollection,
    AUTO_SCALE_POLICY_COLLECTION_TOKEN,
    AUTO_SCALE_POLICY_ITEM_TOKEN,
} from '.';

const autoScaleModule = angular.module('avi/auto-scale');
const factories = [
    {
        factory: AutoScalePolicyCollection,
        name: AUTO_SCALE_POLICY_COLLECTION_TOKEN,
    },
    {
        factory: AutoScalePolicy,
        name: AUTO_SCALE_POLICY_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(autoScaleModule, 'factory', name, factory);
});
