/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function aviTooltipDirective(PopoverFactory) {
    return {
        restrict: 'A',
        link(scope, elm, attrs) {
            /**
             * @constructor
             * @memberOf module:avi/app
             * @extends module:avi/app.PopoverFactory
             * @author Ashish Verma
             */
            class PopoverClass extends PopoverFactory { }

            const popover = new PopoverClass({
                className: 'aviTooltip',
                html: attrs['aviTooltip'],
                position: 'top',
                carat: true,
                repositioning: true,
                removeAfterHide: true,
                hide: {
                    mouseOut: true,
                    parentMouseOut: true,
                },
            });

            elm.on('mouseover', function() {
                popover.show(elm);
            });

            scope.$on('$destroy', function() {
                popover.remove();
            });

            attrs.$observe('aviTooltip', newValue => {
                popover.config.html = newValue;
            });
        },
    };
}

aviTooltipDirective.$inject = [
    'popoverFactory',
];

angular.module('avi/app').directive('aviTooltip', aviTooltipDirective);
