/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Injectable,
    Type,
} from '@angular/core';

import { IHttpPromise } from 'angular';
import { L10nService } from '@vmw/ngx-vip';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories';

import {
    FullModalService,
    IFullModalLayout,
} from 'ng/modules/core';

import { VerifyCloudConnectorUserHostModalComponent } from '../components';
import * as l10n from './verify-cloud-connector-user-host-modal.service.I10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const CLOUD_CONNECTOR_USER_REQUEST_ID = 'cloudconnectoruserhostverify';
const CLOUD_CONNECTOR_USER_BASE_API = '/api/cloudconnectoruser';

type THttpWrapper = typeof HttpWrapper;

/**
 * Enum to hold task types.
 */
export const enum TaskType {
    VERIFY = 'test',
    CLEANUP = 'cleanup',
}

/**
 * @description Service to fetch data and open/close the modal.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class VerifyCloudConnectorUserHostModalService {
    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * User uuid. Used in api request for verification.
     */
    private userUuid: string;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    /**
     * Breadcrumb title hash. Used to get Breadcrumb title.
     */
    private breadcrumbTitleHash = {
        test: l10nKeys.verifyHostBreadcrumbTitle,
        cleanup: l10nKeys.cleanUpBreadcrumbTitle,
    };

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Makes API call to verify host or Clean up existing SE configuration.
     */
    public runTask(
        task: TaskType,
        hostIp: string,
    ): IHttpPromise<string> {
        const cloudConnectorUserApi = `${CLOUD_CONNECTOR_USER_BASE_API}/${this.userUuid}/${task}`;
        const payload = {
            host: hostIp,
        };

        const requestConfig = {
            url: cloudConnectorUserApi,
            method: HttpMethod.POST,
            data: payload,
            requestId: CLOUD_CONNECTOR_USER_REQUEST_ID,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Method to open verify cloud connector host modal.
     */
    public openModal(
        task: TaskType,
        userUuid: string,
        hostIp?: IpAddrConfigItem,
    ): void {
        this.userUuid = userUuid;

        this.fullModalService.addModal(this.getExportModalProps(task, hostIp));
    }

    /**
     * Method to close verify cloud connector host modal.
     */
    public closeModal(): void {
        this.fullModalService
            .removeModalByComponent(VerifyCloudConnectorUserHostModalComponent as Type<Component>);
    }

    /**
     * Called to destroy and cancel the pending request.
     * @override
     */
    public ngOnDestroy(): void {
        this.httpWrapper.cancelRequest(CLOUD_CONNECTOR_USER_REQUEST_ID);
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getExportModalProps(
        task: TaskType,
        hostIp: IpAddrConfigItem,
    ): IFullModalLayout {
        const { l10nService } = this;

        return {
            component: VerifyCloudConnectorUserHostModalComponent as Type<Component>,
            componentProps: {
                task,
                hostIp: hostIp?.address,
            },
            getName: () => l10nService.getMessage(this.breadcrumbTitleHash[task]),
            getDescription: () => '',
        };
    }
}
