/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/shared/components/avi-data-grid/avi-data-grid.types';
import {
    WafDataFileConfigItem,
} from 'ajs/modules/waf/factories/waf-profile/waf-data-file.config-item.factory';

import { SchemaService } from 'ajs/modules/core/services/schema-service';

import * as l10n from './waf-data-file-grid.l10n';
import './waf-data-file-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Grid Component for Waf Profile Modal, for holding all WafDataFiles.
 *
 * @author Kondiparthi Shanmukha Sarath.
 */
@Component({
    selector: 'waf-data-file-grid',
    templateUrl: './waf-data-file-grid.component.html',
})
export class WafDataFileGrid implements AfterViewInit {
    /**
     * List of all the WafDataFile objects.
     */
    @Input()
    public wafDataFiles: RepeatedMessageItem<WafDataFileConfigItem>;

    /**
     * Template Ref for WafProfile Filedata.
     */
    @ViewChild('wafFileDataTemplateRef')
    public wafFileDataTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Fires on add Waf Data File.
     */
    @Output()
    public onAddWafDataFile = new EventEmitter();

    /**
     * Fires on edit Waf Data File.
     */
    @Output()
    public onEditWafDataFile = new EventEmitter<WafDataFileConfigItem>();

    /**
     * Fires on Removing Waf Data File.
     */
    @Output()
    public onRemoveWafDataFile = new EventEmitter<WafDataFileConfigItem>();

    /**
     * WafDataFile Grid Config.
     */
    public wafDataFileGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Grid Config is initialized with fields, singleactions and multipleactions.
     */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys, schemaService } = this;

        this.wafDataFileGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.wafDataFileName),
                    id: 'content-type',
                    transform(file: WafDataFileConfigItem) {
                        return file.config.name;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.wafDataFileType),
                    id: 'request-parser',
                    transform(file: WafDataFileConfigItem) {
                        return schemaService.getEnumValueLabel(
                            'WafDataFileType',
                            file.config.type,
                        );
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.removeWafDataFileButtonLabel),
                    onClick: (wafDataFileList: WafDataFileConfigItem[]) => {
                        wafDataFileList.forEach(datafile => this.removeWafDataFile(datafile));
                    },
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.editWafDataFileButtonLabel),
                    shape: 'pencil',
                    onClick: (wafDataFile: WafDataFileConfigItem) => {
                        this.editWafDataFile(wafDataFile);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.removeWafDataFileButtonLabel),
                    shape: 'trash',
                    onClick: (wafDataFile: WafDataFileConfigItem) => {
                        this.removeWafDataFile(wafDataFile);
                    },
                },
            ],
            expandedContentTemplateRef: this.wafFileDataTemplateRef,
            getRowId(index: number) {
                return index;
            },
        };
    }

    /**
     * Handler for adding new WafDataFile into grid.
     */
    public addWafDataFile(): void {
        this.onAddWafDataFile.emit();
    }

    /**
     * Handler for editing WafDataFile in grid.
     */
    private editWafDataFile(wafDataFile: WafDataFileConfigItem): void {
        this.onEditWafDataFile.emit(wafDataFile);
    }

    /**
     * Remove Waf Data File from the wafDataFiles and conversely in grid.
     */
    private removeWafDataFile(wafDataFile: WafDataFileConfigItem): void {
        this.onRemoveWafDataFile.emit(wafDataFile);
    }
}
