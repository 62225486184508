/** @module SystemModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';

/**
 * @description FileObject Collection.
 *
 * @author Rachit Aggarwal
 */

export class FileObjectCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'fileobject',
            objectType: 'FileObject',
            permissionName: AviPermissionResource.PERMISSION_FILE_OBJECT,
            ...args,
        };

        super(extendedArgs);
    }
}
