/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { NsxtTransportZoneType } from 'generated-types';
import { isUndefined } from 'underscore';

import {
    IAviDataGridConfig,
    IAviDataGridRow,
    IAviDropdownOption,
} from 'ng/shared/components';

import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';

import {
    DataNetworkConfigConfigItem,
    NsxtConfigurationConfigItem,
    NsxtTier1SegmentConfigConfigItem,
    NsxtTier1SegmentConfigMode,
    Tier1LogicalRouterInfoConfigItem,
} from 'ajs/modules/cloud';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { L10nService } from '@vmw/ngx-vip';

import './data-network-config.component.less';
import * as l10n from './data-network-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for NSXT Data Network configuration.
 * @author alextsg, Zhiqian Liu
 */
@Component({
    selector: 'data-network-config',
    templateUrl: './data-network-config.component.html',
})
export class DataNetworkConfigComponent implements AfterViewInit, OnInit {
    /**
     * DataNetworkConfig Config Item.
     */
    @Input()
    public editable: DataNetworkConfigConfigItem;

    /**
     * NsxtConfigurationConfigItem used for making requests for Segment ID lists.
     */
    @Input()
    public nsxtConfiguration: NsxtConfigurationConfigItem;

    /**
     * True if NSX-T Manager address and credentials have been set and succussfully connected.
     */
    @Input()
    public credentialsConnected: boolean;

    /**
     * NsxtConfigurationConfigItem used for making requests for Segment ID lists.
     */
    @Input()
    public transportZoneOptions: IAviDropdownOption[];

    /**
     * Tier1 Logical Router dropdown options.
     */
    @Input()
    public tier1DropdownOptions: IAviDropdownOption[];

    /**
     * Map from transport zone IDs to the respective transport zone type.
     */
    @Input()
    public tzIDToTypeMap = new Map<string, NsxtTransportZoneType>();

    /**
     * Callback for the parent to react to transport zone changes.
     */
    @Output()
    public onTransportZoneChange = new EventEmitter<void>();

    /**
     * Datagrid template containing a dropdown to select a Tier 1 Logical Router ID.
     */
    @ViewChild('tier1LogicalRouterIdTemplateRef')
    public tier1LogicalRouterIdTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template containing a dropdown to select a Segment ID with respect to a Tier1 LR.
     */
    @ViewChild('tier1SegmentIdTemplateRef')
    public tier1SegmentIdTemplateRef: TemplateRef<HTMLElement>;

    /**
     * DataGrid config for Tier1LogicalRouterInfo under NsxtTier1SegmentConfig manual mode.
     * In use for OVERLAY transport zone.
     */
    public tier1LRDataGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Dropdown options for VLAN segments.
     */
    public vlanSegmentDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Hash of OVERLAY Tier1 Logical Router IDs to Segment ID dropdown options.
     */
    private overlaySegmentIDOptionsHash: Record<string, IAviDataGridRow[]> = {};

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isOverlay) {
            this.setOverlaySegmentOptions();
        } else if (this.isVlan) {
            this.setVlanSegmentOptions();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.tier1LRDataGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.tier1LogicalRouterFieldLabel),
                    id: 'tier1-logical-router',
                    templateRef: this.tier1LogicalRouterIdTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.l10nKeys.overlaySegmentFieldLabel),
                    id: 'tier1-logical-router-segment',
                    templateRef: this.tier1SegmentIdTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                    onClick: (tier1LRs: Tier1LogicalRouterInfoConfigItem[]) => tier1LRs.forEach(
                        tier1LR => this.tier1LogicalRouters.removeByMessageItem(tier1LR),
                    ),
                },
            ],
            getRowId(index: number) {
                return index;
            },
        };
    }

    /**
     * Check if the transport zone type is VLAN.
     */
    public get isVlan(): boolean {
        return this.editable.isTransportZoneType(NsxtTransportZoneType.VLAN);
    }

    /**
     * Check if the transport zone type is OVERLAY.
     */
    public get isOverlay(): boolean {
        return this.editable.isTransportZoneType(NsxtTransportZoneType.OVERLAY);
    }

    /**
     * Check if the config mode is Manual for OVERLAY configurations.
     */
    public get isOverlayManualMode(): boolean {
        return this.overlayTier1SegmentConfig.isConfigMode(
            NsxtTier1SegmentConfigMode.TIER1_SEGMENT_MANUAL,
        );
    }

    /**
     * Getter for the Tier1 Segment config under OVERLAY transport zone.
     */
    public get overlayTier1SegmentConfig(): NsxtTier1SegmentConfigConfigItem {
        return this.editable.tier1SegmentConfig;
    }

    /**
     * Return the Tier1LogicalRouterInfo RepeatedMessageItem under tier1_segment_config manual mode.
     * In use only for OVERLAY transport zone.
     */
    public get tier1LogicalRouters():
    RepeatedMessageItem<Tier1LogicalRouterInfoConfigItem> | undefined {
        return this.editable.manualTier1LRs;
    }

    /**
     * Check if the transport zone is configured.
     */
    public isTransportZoneConfigured(): boolean {
        return !isUndefined(this.editable.transportZone);
    }

    /**
     * Handler for changing transport zone.
     */
    public handleTransportZoneChange(): void {
        const { transportZone } = this.editable;
        const tzType = this.tzIDToTypeMap.get(transportZone);

        this.editable.transportZoneType = tzType;
        this.editable.onTransportZoneChange();
        this.onTransportZoneChange.emit();

        if (this.isVlan) {
            this.setVlanSegmentOptions();
        } else if (this.isOverlay) {
            this.overlaySegmentIDOptionsHash = {};
        }
    }

    /**
     * Handler for changing a Tier1 Logical Router ID in a Tier1LogicalRouterInfo entry. Resets the
     * Segment ID and sets Segment ID dropdown options.
     */
    public handleChangeTier1(row: Tier1LogicalRouterInfoConfigItem): void {
        const { tier1LRID } = row;

        // Clear previously selected segment on change of logical router.
        row.clearSegmentID();

        this.setOverlaySegmentOptionsByTier1LRID(tier1LRID);
    }

    /**
     * Returns a list of OVERLAY Segment ID dropdown options.
     */
    public getOverlaySegmentDropdownOptions({ tier1LRID }: Tier1LogicalRouterInfoConfigItem)
        : IAviDropdownOption[] {
        return this.overlaySegmentIDOptionsHash[tier1LRID] || [];
    }

    /**
     * Disable transport zone selection if credentials are not connected or the nsxtConfiguration
     * ConfigItem is busy.
     */
    public disableTransportZone(): boolean {
        return !this.credentialsConnected || this.nsxtConfiguration.busy;
    }

    /**
     * Disable segment ID selection for either OVERLAY or VLAN transport zone if the
     * nsxtConfiguration ConfigItem is busy or its respective Tier1LR ID has not been selected.
     */
    public disableTier1SegmentID({ tier1LRID }: Tier1LogicalRouterInfoConfigItem): boolean {
        return this.nsxtConfiguration.busy || !tier1LRID;
    }

    /**
     * Returns the helper text on the Segment ID dropdown.
     */
    public getSegmentIDHelperText({ tier1LRID }: Tier1LogicalRouterInfoConfigItem): string {
        return tier1LRID ?
            '' :
            this.l10nService.getMessage(this.l10nKeys.segmentDropdownHelperText);
    }

    /**
     * Fetch a list of Segment IDs and sets dropdown options for VLAN transport zone.
     */
    private async setVlanSegmentOptions(): Promise<void> {
        const { transportZone } = this.editable;
        const segments =
            await this.nsxtConfiguration.fetchVlanSegments(transportZone) || [];

        this.vlanSegmentDropdownOptions = segments.map(({ id, name }) => {
            return createDropdownOption(id, name);
        });
    }

    /**
     * Sets Segment ID options from the segmentIDOptionsHash for every Tier1LR configured. If the
     * options don't already exist, fetch them asynchronously.
     */
    private setOverlaySegmentOptions(): void {
        this.tier1LogicalRouters.config.forEach(({ tier1LRID }) => {
            this.setOverlaySegmentOptionsByTier1LRID(tier1LRID);
        });
    }

    /**
     * Sets Segment ID options from the segmentIDOptionsHash for a given Tier1LR. If the options
     * don't already exist, fetch them asynchronously.
     */
    private async setOverlaySegmentOptionsByTier1LRID(tier1LRID: string): Promise<void> {
        const { transportZone } = this.editable;

        if (tier1LRID in this.overlaySegmentIDOptionsHash) {
            return;
        }

        const segments = await this.nsxtConfiguration
            .fetchSegmentsByTier1LRID(transportZone, tier1LRID) || [];
        const segmentOptions = segments.map(({ id, name }) => createDropdownOption(id, name));

        this.overlaySegmentIDOptionsHash[tier1LRID] = segmentOptions;
    }
}
