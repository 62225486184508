/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './ipam-infoblox-usable-subnets.component.less';
import * as l10n from './ipam-infoblox-usable-subnets.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'ipam-infoblox-usable-subnets';

/**
 * @constructor
 * @memberOf module:avi/ipam
 * @mixes module:avi/ipam.ipamInfobloxUsableSubnetsBindings
 * @see {@link module:avi/ipam.ipamInfobloxUsableSubnetsComponent}
 */
class IpamInfobloxUsableSubnetsController {
    constructor(
        dropDownUtils,
        getSubnetString,
        l10nService,
    ) {
        this.dropDownUtils_ = dropDownUtils;
        this.getSubnetString_ = getSubnetString;

        /**
         * Used for usable subnets in Infoblox IPAM Profile.
         * @type {AvailableInfobloxSubnets}
         */
        this.availableSubnetOptions = {
            v4: [],
            v6: [],
        };

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        const { l10nService_: l10nService } = this;

        this.gridConfig = {
            id: componentName,
            rowId: row => this.configuredUsableSubnets.indexOf(row),
            fields: [
                {
                    name: 'ipv4',
                    title: l10nService.getMessage(l10nKeys.ipV4ColumnTitle),
                    template: `
                        <dropdown
                            class="full-width"
                            ng-model="row.config.subnet.config.flatProps"
                            parse-any-ip
                            options="config.props.subnets.v4"
                            placeholder="${l10nService
        .getMessage(l10nKeys.ipv4SubnetInputPlaceholder)}"
                            search="true"
                        ></dropdown>
                    `,
                },
                {
                    name: 'ipv6',
                    title: l10nService.getMessage(l10nKeys.ipV6ColumnTitle),
                    template: `
                        <dropdown
                            class="full-width"
                            ng-model="row.config.subnet6.config.flatProps"
                            parse-any-ip
                            options="config.props.subnets.v6"
                            placeholder="${l10nService
        .getMessage(l10nKeys.ipv6SubnetInputPlaceholder)}"
                            search="true"
                        ></dropdown>
                    `,
                },
            ],
            singleactions: [
                {
                    title: l10nService.getMessage(l10nKeys.deleteButtonLabel),
                    class: 'icon icon-trash',
                    do: this.removeUsableSubnetByRow,
                },
            ],
            multipleactions: [
                {
                    title: l10nService.getMessage(l10nKeys.deleteButtonLabel),
                    do: rows => {
                        rows.forEach(this.removeUsableSubnetByRow);

                        return true;
                    },
                },
            ],
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
            props: {
                subnets: this.availableSubnetOptions,
            },
        };

        if (this.ipamProfileId) {
            this.loadAvailableSubnets_(true);
        }

        this.ipamDnsInfobloxProfileConfigController
            .addCredentialChangeListener(this.updateUsableSubnets_);
    }

    /**
     * Returns the list of configured subnets.
     * @returns {InfobloxSubnet[]}
     */
    get configuredUsableSubnets() {
        return this.editable.configuredUsableSubnets;
    }

    /**
     * Retrives network list for selected IPAM Profile.
     * @param {boolean} withId If true, Id will be used to fetch networks.
     * @protected
     */
    loadAvailableSubnets_(withId = false) {
        let id;

        if (withId) {
            id = this.ipamProfileId;
        }

        this.editable.getAvailableSubnets(id).then(this.setAvailableSubnetOptions_);
    }

    /**
     * Listener to Infoblox credential change event.
     * Update infoblox subnet list with credentials.
     * Update related states.
     * @protected
     */
    updateUsableSubnets_ = () => {
        this.editable.clearUsableSubnetList();
        this.setAvailableSubnetOptions_({});
        this.loadAvailableSubnets_();
    }

    /**
     * Returns true if usable subnets exist.
     * @returns {boolean}
     */
    hasUsableSubnetsOptions() {
        const { v4 = [], v6 = [] } = this.availableSubnetOptions;

        return Boolean(v4.length || v6.length);
    }

    /**
     * Returns true if credetials form is in edit mode.
     * @returns {boolean}
     */
    get isEditingCredentials() {
        return this.ipamDnsInfobloxProfileConfigController.editingCredentials;
    }

    /**
     * Returns dropdown options for usable subnet in infoblox configuration section.
     * @param {Object[]?} subnets - Subnet list JSON data.
     * @protected
     * @return {DropDownOption[]} - Dropdown option objects.
     */
    createSubnetOptions_(subnets = []) {
        return subnets.map(subnet => {
            const label = this.getSubnetString_(subnet);

            return this.dropDownUtils_.createOption(label);
        });
    }

    /**
     * Sets the lists within the availableSubnetOptions hash, preserving the reference to
     * this.availableSubnetOptions for the gridConfig.
     * @param {InfobloxSubnet} subnetsHash - Hash containing v4 and v6 subnets.
     * @protected
     */
    setAvailableSubnetOptions_ = (subnetsHash = {}) => {
        const { v4 = [], v6 = [] } = subnetsHash;

        this.availableSubnetOptions.v4 = this.createSubnetOptions_(v4);
        this.availableSubnetOptions.v6 = this.createSubnetOptions_(v6);
    }

    /**
     * Called to add a usable subnet.
     */
    addUsableSubnet() {
        this.editable.addUsableSubnet();
    }

    /**
     * Removes a subnet object from the list of configured subnets.
     * @param {InfobloxSubnet} row - Subnet object containing a v4 and/or a v6 subnet.
     */
    removeUsableSubnetByRow = row => {
        const indexToRemove = this.configuredUsableSubnets.indexOf(row);

        this.editable.removeUsableSubnet(indexToRemove);
    }
}

IpamInfobloxUsableSubnetsController.$inject = [
    'dropDownUtils',
    'getSubnetString',
    'l10nService',
];

/**
 * @name ipamInfobloxUsableSubnetsComponent
 * @memberOf module:avi/ipam
 * @property {module:avi/ipam.ipamInfobloxUsableSubnetsBindings} bindings
 * @property {module:avi/ipam.IpamInfobloxUsableSubnetsController} controller
 * @description
 *      Component for Usable subnets selection in IPAM/DNS Modal.
 *      Will be displayed when type is IPAM and profile is Infoblox.
 * @author Aravindh Nagarajan
 */
angular.module('avi/ipam').component('ipamInfobloxUsableSubnets', {
    /**
     * @mixin ipamInfobloxUsableSubnetsBindings
     * @memberof module:avi/ipam
     * @property {module:avi/ipam.IpamDnsInfobloxProfileConfigItem} editable
     * @property {string} ipamProfileId - UUID of the parent IPAM profile.
     */
    bindings: {
        editable: '<',
        ipamProfileId: '@',
    },
    require: {
        ipamDnsInfobloxProfileConfigController: '^^ipamDnsInfobloxProfileConfig',
    },
    controller: IpamInfobloxUsableSubnetsController,
    templateUrl: 'src/components/templates/profiles/ipam-dns-profiles-modal/' +
        `ipam-dns-infoblox-profile-config/${componentName}/${componentName}.component.html`,
});
