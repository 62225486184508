/**
 * @module ClusterModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/
import {
    IBaseRequestPromise,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { withFullModalMixin } from 'ajs/js/utilities/mixins';

import {
    AviPermissionResource,
    ICluster,
} from 'generated-types';

import { Cluster as ClusterObj } from 'object-types';
import { ClusterModalComponent } from 'ng/modules/cluster/components/cluster-modal';
import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';
import { ClusterNodeConfigItem } from './cluster-node.config-item.factory';

type TClusterConfigPartial = Omit<ICluster, 'nodes' | 'virtual_ip'>;

export interface IExtendedClusterConfig extends TClusterConfigPartial {
    nodes?: RepeatedMessageItem<ClusterNodeConfigItem>;
    virtual_ip?: IpAddrConfigItem,
}

interface IClusterData {
    config: IExtendedClusterConfig;
}

/**
 * @description Cluster Item class.
 * @author Ratan Kumar
 */
export class Cluster extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public data: IClusterData;
    public getConfig: () => IExtendedClusterConfig;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'cluster',
            objectType: ClusterObj,
            windowElement: ClusterModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CONTROLLER,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns a API URI to save an object.
     * @override
     */
    public urlToSave(): string {
        return `/api/${this.objectName}?include_name`;
    }

    /**
     * Getter to get nodes from cluster config.
     */
    public get clusterNodeList(): RepeatedMessageItem<ClusterNodeConfigItem> {
        const { nodes } = this.config;

        return nodes;
    }

    /**
     * getter to get virtual ip.
     */
    public get virtualIp() : IpAddrConfigItem {
        return this.config.virtual_ip;
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public getIdFromData_(): string {
        return 'default'; // fakeId for single instance
    }

    /**
     * Method to add new cluster node item.
     */
    public addClusterNode(): void {
        this.addChildMessageItem({
            field: 'nodes',
            modalBindings: {
                clusterNodeList: this.clusterNodeList,
                isEditing: false,
            },
        });
    }

    /**
     * Method to edit cluster node.
     */
    public updateClusterNode(clusterNode : ClusterNodeConfigItem): void {
        const index = this.clusterNodeList.config.indexOf(clusterNode);

        this.editChildMessageItem({
            field: 'nodes',
            messageItem: clusterNode,
            modalBindings: {
                clusterNodeList: this.clusterNodeList,
                isEditing: true,
                currentIndex: index,
            },
        });
    }

    /**
     * Load Cluster config
     * @override
     */
    protected loadConfig(): IBaseRequestPromise<ICluster> {
        const params = this.getLoadParams();
        const headers = this.getLoadHeaders_();
        const url = `/api/${this.objectName + (params.length ? `?${params.join('&')}` : '')}`;
        const requestConfig = {
            group: 'config',
            method: 'GET',
            headers,
            url,
        };

        this.cancelRequests('config');

        return this.request<ICluster>(requestConfig)
            .then(this.onConfigLoad_.bind(this));
    }

    /**
     * Method will load required Field on add or edit action.
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'virtual_ip',
        ];
    }
}
