/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'scripts';
const componentName = 'ds-rate-limiters-grid';
const prefix = `${moduleName}.${componentName}`;

export const nameLabel = `${prefix}.nameLabel`;
export const namePlaceholder = `${prefix}.namePlaceholder`;
export const countLabel = `${prefix}.countLabel`;
export const countPlaceholder = `${prefix}.countPlaceholder`;
export const burstSizeLabel = `${prefix}.burstSizeLabel`;
export const burstSizePlaceholder = `${prefix}.burstSizePlaceholder`;
export const periodLabel = `${prefix}.periodLabel`;
export const periodPlaceholder = `${prefix}.periodPlaceholder`;
export const actionTitleRemove = `${prefix}.actionTitleRemove`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const maximumInnerText = `${prefix}.maximumInnerText`;
export const secondsInnerText = `${prefix}.secondsInnerText`;

export const ENGLISH = {
    [nameLabel]: 'Name',
    [namePlaceholder]: 'Enter Name',
    [countLabel]: 'Count',
    [countPlaceholder]: 'Enter Count',
    [burstSizeLabel]: 'Burst Size',
    [burstSizePlaceholder]: 'Enter Size',
    [periodLabel]: 'Period',
    [periodPlaceholder]: 'Enter Duration',
    [actionTitleRemove]: 'Remove',
    [addButtonLabel]: 'Add',
    [maximumInnerText]: 'Maximum',
    [secondsInnerText]: 'Seconds',
};
