/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import * as patterns from 'ng/utils/regex.utils';

export const REGEX_PATTERN_VALIDATOR_KEY = 'regexPattern';

/**
 * Validates a value against a regex pattern.
 * If value contains array of strings then validates each string in array.
 */
export function regexPatternValidator(regexPatternName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!(regexPatternName in patterns)) {
            throw new Error(`regexPatternName ${regexPatternName} not found.`);
        }

        const { value } = control;
        let valid = true;

        if (Array.isArray(value)) {
            value.forEach(val => {
                if (!patterns[regexPatternName].test(value)) {
                    valid = false;
                }
            });
        } else {
            valid = patterns[regexPatternName].test(value);
        }

        return valid ? null : { [REGEX_PATTERN_VALIDATOR_KEY]: { value } };
    };
}
