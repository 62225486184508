/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import {
    AzureConfigurationConfigItem,
} from 'ajs/modules/cloud/factories/azure-configuration.config-item.factory';

import { L10nService } from '@vmw/ngx-vip';
import { Observable } from 'rxjs';

import {
    AzureConfiguration,
    Cloud,
} from 'object-types';

import {
    CloudConnectorUserCollection,
} from 'ajs/modules/cloud-connector-user/factories/cloud-connector-user.collection.factory';

import { IEditCredentialsConfig } from 'ng/modules/avi-forms/components/credentials-verification';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';

import {
    AzureEditCredentialsDialogComponent,
} from './azure-edit-credentials-dialog/azure-edit-credentials-dialog.component';

import * as l10n from './azure-configuration.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Type of CloudConnect User Collection.
 */
type TCloudConnectorUserCollection = typeof CloudConnectorUserCollection;

/**
 * @description Component for Azure configuration.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'azure-configuration',
    templateUrl: './azure-configuration.component.html',
})
export class AzureConfigurationComponent implements OnInit, OnDestroy {
    /**
     * AzureConfiguration Config Item.
     */
    @Input()
    public editable: AzureConfigurationConfigItem;

    /**
     * Emits an event when cloud credentials are connected.
     */
    @Output()
    public onAzureCloudConnect = new EventEmitter<void>();

    /**
     * CloudConnectorUserCollection instance.
     */
    public cloudConnectorUserCollection: CloudConnectorUserCollection;

    /**
     * Object Type to be used in template.
     */
    public readonly objectType = AzureConfiguration;

    /**
     * Cloud Object Type used in template.
     */
    public readonly cloudObjectType = Cloud;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Credentials config passed to the CredentialsVerification component.
     */
    public editAzureCredentialsConfig: IEditCredentialsConfig;

    /**
     * True if the Cloud Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * True if Subscription id and credentials have been set by the user. Used to display
     * helper messages for the user to first connect credentials.
     */
    public connected = false;

    /**
     * Model value for Use Azure MSI Authentication label.
     * Also used as a flag for displaying Azure Credentials dropdown.
     * Property cloud_credentials_ref will be deleted when this checkbox is checked.
     */
    public useAzureMsiAuthenticationModelValue: string;

    /**
     * Flag to check if user has selected use Azure MSI Authentication option.
     */
    public useAzureMsiAuthentication = false;

    /**
     * Dropdown options for Location.
     */
    public locationDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Dropdown options for Resource Group.
     */
    public resourceGroupDropdownOptions: IAviDropdownOption[] = [];

    constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject('CloudConnectorUserCollection')
        CloudConnectorUserCollection: TCloudConnectorUserCollection,
        private readonly l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        this.cloudConnectorUserCollection = new CloudConnectorUserCollection();

        this.isEditing = Boolean(itemId);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isEditing) {
            this.connected = true;
            this.setUseAzureMsiAuthenticationModelValue(this.editable.config.cloud_credentials_ref);
            this.setLocationDropdownOptions();
            this.setResourceGroupDropdownOptions();
        }

        this.setEditAzureCredentialsConfig();
    }

    /**
     * Returns the Label for Credentials Button.
     */
    public getCredentialsButtonLabel(): string {
        const {
            l10nService,
            l10nKeys,
        } = this;

        return this.editable.getConfig().subscription_id ?
            l10nService.getMessage(l10nKeys.changeCredentialsButtonLabel) : l10nService.getMessage(
                l10nKeys.setCredentialsButtonLabel,
            );
    }

    /** @override */
    public ngOnDestroy(): void {
        this.cloudConnectorUserCollection.destroy();
    }

    /**
     * Sets the editAzureCredentialsConfig to be passed to CredentialsVerification Component.
     */
    private setEditAzureCredentialsConfig(): void {
        const config = this.editable.getConfig();

        const editCredentialsConfig = {
            cloud_credentials_ref: config.cloud_credentials_ref,
            subscription_id: config.subscription_id,
        };

        this.editAzureCredentialsConfig = {
            editCredentialsDialog: AzureEditCredentialsDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                isEditing: this.isEditing,
                useAzureMsiAuthentication: this.useAzureMsiAuthentication,
                submit$: new Observable<void>(
                    subscriber => {
                        subscriber.next();
                        this.editable.setAzureCredentials(editCredentialsConfig);
                        this.connected = true;
                        this.setUseAzureMsiAuthenticationModelValue(config.cloud_credentials_ref);
                        this.onAzureCloudConnect.emit();
                        this.resetResourceGroupDropdownOptions();
                        this.resetLocationDropdownOptions();
                        this.removeLocationAndResourceGroupFields();
                        this.setLocationDropdownOptions();
                        this.setResourceGroupDropdownOptions();
                        this.setEditAzureCredentialsConfig();
                    },
                ),
                onCancel: () => {
                    this.setEditAzureCredentialsConfig();
                },
            },
        };
    }

    /**
     * Resets the Resource Group Dropdown everytime the credentails are changed.
     */
    private resetResourceGroupDropdownOptions(): void {
        this.resourceGroupDropdownOptions = [];
    }

    /**
     * Resets the Location Dropdown everytime the credentials are changed.
     */
    private resetLocationDropdownOptions(): void {
        this.locationDropdownOptions = [];
    }

    /**
     * Removes location and resource group properties on change of credentials.
     */
    private removeLocationAndResourceGroupFields(): void {
        this.editable.removeLocationAndResourceGroupFields();
    }

    /**
     * Fetches list of resource groups and sets resource group dropdown options.
     */
    private async setResourceGroupDropdownOptions(): Promise<void> {
        const resourceGroup = await this.editable.fetchResourceGroups() || [];

        this.resourceGroupDropdownOptions = resourceGroup.map(
            ({ name }) => createDropdownOption(name),
        );
    }

    /**
     * Fetches list of locations and sets location dropdown options.
     */
    private async setLocationDropdownOptions(): Promise<void> {
        const locations = await this.editable.fetchLocations() || [];

        this.locationDropdownOptions = locations.map(
            ({ name, display_name }) => createDropdownOption(name, display_name),
        );
    }

    /**
     * Sets the useAzureMsiAuthentication model value according to credentials set.
     */
    private setUseAzureMsiAuthenticationModelValue(cloudCredentialsRef: string): void {
        const {
            connected,
            l10nKeys,
            l10nService,
        } = this;

        if (!cloudCredentialsRef) {
            this.useAzureMsiAuthenticationModelValue = l10nService.getMessage(
                l10nKeys.useAzureMsiAuthenticationEnabledValue,
            );

            if (connected) {
                this.useAzureMsiAuthentication = true;
            } else {
                this.useAzureMsiAuthentication = false;
            }
        } else {
            this.useAzureMsiAuthenticationModelValue = l10nService.getMessage(
                l10nKeys.useAzureMsiAuthenticationNotEnabledValue,
            );

            this.useAzureMsiAuthentication = false;
        }
    }
}
