/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import classnames from 'classnames';
import { USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE } from '../../constants/my-account.constant';
import { RECOMMENDATION_DIALOG_SERVICE_TOKEN } from '../../../downgrade-services.tokens';

import * as l10n from './LogListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constant
 * @type {number}
 * Log loading keeps polling on exponential intervals with base of 2 seconds until the remaining
 * percentage is zero.
 */
export const LOAD_POLLING_INTERVAL_BASE = 2;

/**
 * @constant
 * @type {number}
 * Limit of max log bar polling interval timeout of 32s (reached after 5 times of polling.)
 */
export const LOAD_POLLING_INTERVAL_MAX = 32;

/**
 * @constant
 * @type {number}
 * Max number of pollings.
 */
export const LOAD_POLLING_COUNT_MAX = 20;

/**
 * Constants used for WAF status code.
 */
const FLAGGED = 'FLAGGED';
const REJECTED = 'REJECTED';

/**
 * Hash of WafStatusCode.
 * @type {Object.<string, string>}
 * @private
 */
const wafStatusCodeHash = {
    [FLAGGED]: FLAGGED,
    [REJECTED]: REJECTED,
};

/**
 * Module for Logs.
 * @module avi/logs
 */

//TODO suggest to look at all logs if there are no significant
angular.module('avi/logs').controller('LogListController', [
    '$scope', '$location', '$stateParams', '$state',
    '$timeout', '$q', '$http', 'myAccount', 'logTimeframes', 'Timeframe', 'detailsExpander',
    'logDataTransform', 'popoverFactory', 'AviModal', 'logApiMaxEntriesLimit',
    'httpHeadersTextToHash', 'noValueLogLabel', 'encodeURIQueryPartService', 'dropDownUtils',
    'stringService', 'l10nService', RECOMMENDATION_DIALOG_SERVICE_TOKEN,
    function(scope, $location, $stateParams, $state, $timeout, $q, $http, myAccount,
             logTimeframes, Timeframe, DetailsExpander, logDataTransform,
             PopoverFactory, AviModal, logApiMaxEntriesLimit, httpHeadersTextToHash,
             noValueLogLabel, encodeURIQueryPartService, dropDownUtils, stringService,
             l10nService, recommendationDialogService) {
        scope.myAccount = myAccount;
        scope.noValueLogLabel = noValueLogLabel;
        scope.$state = $state;
        scope.$stateParams = $stateParams;
        scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        scope.reloadMoreLogsMessageList =
            l10nService.getSplitedMessage(l10nKeys.reloadMoreLogsMessage);

        /**
         * Hash of log types and its values.
         * In Log fetch Query,
         *      If type is set to 0, L4 logs will be fetched.
         *      If type is set to 1, L7 logs will be fetched.
         */
        scope.vsLogs = {
            L4_LOGS_TYPE: 0,
            L7_LOGS_TYPE: 1,
        };

        /**
         * Log switcher dropdown options.
         */
        scope.logSwitcherDropdownOptions = [{
            name: l10nService.getMessage(l10nKeys.l4LogsLabel),
            value: scope.vsLogs.L4_LOGS_TYPE,
        }, {
            name: l10nService.getMessage(l10nKeys.l7LogsLabel),
            value: scope.vsLogs.L7_LOGS_TYPE,
        }].map(({ name, value }) => dropDownUtils.createOption(value, name));

        /**
         * NgModel for log switcher dropdown.
         */
        scope.selectedLogType = scope.vsLogs.L4_LOGS_TYPE;

        /**
         * Will be set to true when any of VS's service has override_application_profile_ref.
         * If true, Log switcher dropdown will be displayed.
         */
        scope.hasOverrideL4AppProfile = false;

        const defaults = {
            timeframe: '15m', //for use instead of custom
            allTimeStart: '2014-01-01 00:00 +0000',
            barsPerPage: 120,
            apiLimit: logApiMaxEntriesLimit, //can't show more logs in table
            page: 1,
            page_size: 20,
            filter: 2, //1: nf&udf, 2: adf, 3:nf&udf&adf
            orderby: 'report_timestamp',
        };

        const ordersBy = { //availiable 'orderby's
            report_timestamp: true,
            client_ip: true,
            client_src_port: true,
            server_ip: true,
            server_dest_port: true,
            total_bytes: true,
            total_time: true,
            uri_path: true,
            method: true,
            response_code: true,
            response_length: true,
            microservice_name: true,
        };

        //only string fields support OR logic for now
        const
            orLogicFields = ['significance', 'virtualservice', 'service_engine',
                'client_location', 'ssl_session_id', 'ssl_cipher', 'ssl_version',
                'http_version', 'method', 'uri_path', 'uri_query', 'referer',
                'user_agent', 'client_device', 'client_browser', 'client_os',
                'xff', 'persistent_cookie', 'host', 'etag',
                'http_security_policy_rule_name',
                'network_security_policy_rule_name',
                'http_request_policy_rule_name', 'http_response_policy_rule_name',
                'pool_name', 'server_name', 'connection_error_info', 'client_ip', 'client_ip6',
                'server_ip', 'server_ip6', 'server_conn_src_ip', 'response_content_type',
                'request_content_type', 'rewritten_uri_path', 'rewritten_uri_query',
                'redirected_uri', 'server_side_redirect_uri', 'microservice',
                'microservice_name', 'dns_qtype', 'gslbservice', 'server_conn_src_ip6',
                'gslbservice_name', 'gslbpool_name', 'protocol', 'dns_etype', 'vs_ip', 'vs_ip6',
                'paa_log.request_logs.uri_path', 'paa_log.request_logs.server_ip',
            ].reduce((acc, fieldName) => {
                acc[fieldName] = true;

                return acc;
            }, {});

        /**
         * Escapes search value for parser.
         * @param {string} value
         */
        const escapeSearchValue = function(value) {
            return value.replace(/\\"/g, '%22');
        };

        /**
         * Decodes search value for display.
         * @param {string} value
         */
        const decodeSerachValue = function(value) {
            return value.replace(/%22/g, '\\"');
        };

        /* save and modify parameters in one place (well, mostly) */
        const Params = function() {
            const moment2str = function(moment) {
                return encodeURI(moment.toISOString());
            };

            //duration - timeframe string, start&end - dates in 'moment' type
            this.updateTimeframe = function(duration, start, end) {
                if (typeof duration === 'undefined') { //update on forceRefresh
                    if (this.start && this.end && endIsNow &&
                        this.duration && this.uri.duration !== 'custom') {
                        this.end = moment();
                        this.start = this.end.clone().subtract(this.duration, 's');
                        checkBrushBoundaries();
                    }
                } else if (duration !== 'custom' && typeof duration === 'string' &&
                    logTimeframes[duration]) {
                    this.end = moment();
                    endIsNow = true;

                    if (duration === 'all') {
                        this.start = moment(defaults.allTimeStart, 'YYYY-MM-DD HH:mm Z');
                        this.duration = this.end.diff(this.start, 's');
                    } else {
                        this.duration = logTimeframes[duration].range;//in seconds
                        this.start = this.end.clone().subtract(this.duration, 's');
                    }

                    this.uri.timeframe = duration;
                    this.step = Math.round(this.duration / defaults.barsPerPage) || 1;
                    this.brush.end = false;
                    this.brush.start = false;
                    logTimeframes.custom.hiddenInLayout = true;
                } else if (duration === 'custom') {
                    if (typeof start === 'object') {
                        this.start = start;

                        if (typeof end === 'object') {
                            this.end = end;
                            endIsNow = false;
                        } else if (endIsNow || !this.end) {
                            if (!this.end) {
                                console.warn('params.updateTimeframe: unexpected behaviour:' +
                                    'custom timeframe when end is false');
                            }

                            this.end = moment();
                            endIsNow = true;
                        }

                        this.duration = this.end.diff(this.start, 's');

                        if (this.duration === 0) { //not less then one second
                            this.duration = 1;
                            this.end = this.start.clone().add(1, 's');
                        }

                        this.uri.timeframe = 'custom';
                        this.step = Math.round(this.duration / defaults.barsPerPage) || 1;
                        this.brush.end = false;
                        this.brush.start = false;
                        logTimeframes.custom.hiddenInLayout = false;
                        this.updateStParamsAndURI();
                    } else {
                        console.warn('params.updateTimeframe: custom timeframe given without' +
                            'start object');
                        this.updateTimeframe(defaults.timeframe);

                        return false;
                    }
                }
            };

            this.setOrderBy = function(name) {
                if (angular.isUndefined(name)) {
                    this.asc = false;
                    this.orderby = false;
                } else if (this.orderby && this.orderby === name) {
                    this.asc = !this.asc;
                } else {
                    this.orderby = name;
                    this.asc = false;
                }

                this.page = 1;
            };

            // type: undefined for list, 'barChart', 'callout', 'typeahead', 'listUpdate'
            this.queryString = function(type) {
                if (typeof type === 'undefined') {
                    checkPageSize();
                    checkPageNum();
                    this.updateStParamsAndURI();
                    parseSearchString();
                }

                const { params } = scope;

                let query = '/api/analytics/logs?';

                // 0 for connection logs (l4), 1 for application logs (l7)
                query += `type=${scope.selectedLogType}&`;

                query += `virtualservice=${params.slug}&`;

                if (this.filter == 1 || this.filter == 3) {
                    query += 'udf=true&nf=true&';
                }

                if (this.filter == 1) {
                    query += 'adf=false&';
                }

                if (this.filters && type !== 'typeahead') {
                    query += this.filters.reduce(function(str, val) {
                        return `${str}filter=${val}&`;
                    }, '');
                }

                if ($stateParams.poolId) {
                    query += `filter=eq(pool,"${$stateParams.poolId}")&`;
                }

                if (type === 'barChart' || type === 'wafLogs') {
                    query += `end=${moment2str(this.end)}&`;
                    query += `duration=${params.duration}&`;
                    query += `step=${this.step}&`;
                    query += `page_size=${defaults.apiLimit}&`;

                    if (type === 'wafLogs' && scope.vs.hasWAFPolicy()) {
                        query += '&filter=eq(waf_log.status,[REJECTED,FLAGGED])&';
                    }
                } else { //for entities lists, callouts, typeaheads, download, downloadAll
                    // (last one doesn't care about pages)
                    query += 'timeout=2&';

                    if (typeof type === 'undefined' || type === 'download' ||
                        type === 'downloadAll') { //for list, not for callouts
                        if (this.orderby) {
                            query += `orderby=${this.asc ? '-' : ''}${this.orderby}&`;
                        }

                        if (type !== 'downloadAll') {
                            query += `page_size=${this.page_size}&`;
                            query += `page=${this.page}&`;
                        }

                        if (type === 'download' || type === 'downloadAll') {
                            query += 'download=true&';
                        }
                    } else if (type === 'listUpdate') {
                        query += 'page_size=1&page=1&';
                    } else if (type === 'typeahead') {
                        query += 'orderby=-count&';
                        query += `groupby=${avi.ParsedQuery.lastVariable}&`;
                    }

                    let end,
                        duration;

                    if (this.brush.end && this.brush.start) {
                        duration = this.brush.end.diff(this.brush.start, 's');
                        end = moment2str(this.brush.end);
                    } else {
                        duration = this.duration;
                        end = moment2str(this.end);
                    }

                    query += `end=${end}&`;
                    query += `duration=${duration}&`;
                }

                return `${query}js_compat=true&`;
            };

            this.slug = 0;
            this.poolId = $stateParams.poolId || false;
            this.start = false;
            this.end = false;
            this.brush = {
                start: 0,
                end: 0,
            };
            this.asc = true;//name is wrong - desc actually TODO rename @am
            this.isHTTPVS = false;
            this.isFastPathOnly = false;
            this.filter = defaults.filter;
            this.page = defaults.page;
            this.page_size = defaults.page_size;
            this.orderby = defaults.orderby;
            this.search = [];//array of strings from search input
            this.filters = [];//array of filters from search field, for API queries
            this.duration = 0;//in seconds, for API queries
            this.step = Math.round(moment.duration(15, 'm').asSeconds() / defaults.barsPerPage);
            this.lastSearch = '';//stringified value of previous search input
            this.uri = { timeframe: defaults.timeframe };
            this.hasBotDetectionPolicy = false;

            const self = this;
            let endIsNow = true;

            this.updateStParamsAndURI = function() { //update stateParams and URI (if forced)
                _.each($state.current.params, (prevValue, key) => {
                    const value = this[key];
                    let stateParamValue = null;

                    if (key === 'timeframe') {
                        stateParamValue = this.uri.timeframe;
                    } else if (value &&
                        (angular.isUndefined(defaults[key]) || value !== defaults[key])) {
                        if (key === 'start' || key === 'end') {
                            if (this.uri.timeframe === 'custom') {
                                stateParamValue = moment2str(value);
                            }
                        } else if (key === 'search') {
                            if (value.length) {
                                stateParamValue = JSON.stringify(value);
                            }
                        } else {
                            stateParamValue = `${value}`;
                        }
                    }

                    $location.search(key, stateParamValue);
                });
            };

            /**
             * Sets appType, isHTTPVS, isFastPathOnly and hasBotDetectionPolicy based on VS config.
             * @param {VirtualService} vs
             * @public
             */
            this.updateVSParams = function(vs) {
                this.appType = vs.appType();
                this.isHTTPVS = vs.isHTTP();
                this.isFastPathOnly = vs.hasTCPFastPathNetProfile(true);
                this.hasBotDetectionPolicy = vs.hasBotDetectionPolicy();
            };

            const checkPageSize = function() {
                const pageSize = parseInt(self.page_size, 10) || defaults.page_size;

                self.page_size = Math.clipValue(pageSize, 1, 100);
            };

            const checkPageNum = function() {
                const
                    page = parseInt(self.page, 10) || 1,
                    itemsQ = Math.min(defaults.apiLimit, scope.selItemsQ || scope.itemsQ);

                let lastPageNum = Math.ceil(itemsQ / self.page_size) || 1;

                if (lastPageNum * self.page_size > defaults.apiLimit) {
                    lastPageNum--;
                }

                self.page = Math.min(lastPageNum, page);
            };

            const checkBrushBoundaries = function() {
                if (self.brush.start && +self.brush.start < +self.start ||
                    self.brush.end && +self.brush.end > +self.end) {
                    self.brush.end = false;
                    self.brush.start = false;
                }
            };

            const parseSearchString = function() {
                let parsed;

                const squashFilters = function() {
                    const
                        res = [],
                        dict = {}, //two dimensions array: [field][operator]
                        parsed = {}; //search string parsed to object

                    const combine = function(item1, item2) {
                        const
                            removeQuotes = function(val) {
                                return val.replace(quotesTrim, '$1');
                            },
                            parsed = item1,
                            quotesTrim = /^['"](.*?)['"]$/g,
                            quote = function(type) {
                                return type === 'double' ? '"' : '\'';
                            },
                            ops = {
                                eq: '=',
                                ne: '!=',
                                ge: '>=',
                                gt: '>',
                                le: '<=',
                                lt: '<',
                                co: '~=',
                                nc: '!~=',
                                sw: '^=',
                            };

                        if (typeof item1.array === 'undefined') { //if only one value
                            item1.query = [quote(item1.quoted) +
                            item1.query + quote(item1.quoted)];
                        }

                        if (typeof item2.array === 'undefined') {
                            item2.query = [quote(item2.quoted) +
                            item2.query + quote(item2.quoted)];
                        }

                        //remove duplicates inside arrays
                        parsed.query = _.unique(item1.query.concat(item2.query),
                            false, removeQuotes);
                        parsed.array = true;

                        const string =
                            `${parsed.field + ops[parsed.op]}[${parsed.query.join(',')}]`;

                        return {
                            parsed,
                            string,
                        };
                    };

                    self.search.forEach((str, key) => {
                        let item,
                            gotSquashed = false;

                        try {
                            const phrase =
                                `l${scope.selectedLogType ? 7 : 4}: ${escapeSearchValue(str)}`;

                            [item] = avi.QueryParser.parse(phrase);
                        } catch (e) {
                            // Ignore
                        }

                        if (item) {
                            const {
                                wildcard: isWildcard,
                                field: fieldName,
                                op: operator,
                            } = item;

                            if (!isWildcard && fieldName in orLogicFields && operator !== 'ne') {
                                parsed[key] = item;

                                if (!(fieldName in dict)) {
                                    dict[fieldName] = {};
                                    dict[fieldName][operator] = key;
                                } else if (!(operator in dict[fieldName])) {
                                    dict[fieldName][operator] = key;
                                } else {
                                    const keyToCombineWith = dict[fieldName][operator];

                                    gotSquashed = true;

                                    const { string: str, parsed: parsedElem } = combine(
                                        parsed[keyToCombineWith], item,
                                    );

                                    res[keyToCombineWith] = str;
                                    parsed[keyToCombineWith] = parsedElem;
                                }
                            }
                        }

                        if (!gotSquashed) {
                            res[key] = str;
                        }
                    });

                    self.search = _.compact(res);
                };

                if (!self.search.length || self.search.join(' ') === self.lastSearch) {
                    self.lastSearch = self.search.length ? self.search.join(' ') : '';

                    if (!self.search.length) {
                        self.filters.length = 0;
                    }

                    return;
                }

                self.search = _.unique(self.search);
                squashFilters();
                self.lastSearch = self.search.join(' ');

                try {
                    if (!avi || typeof avi.QueryParser === 'undefined') {
                        throw new Error('PegJs parser is not defined.');
                    }

                    const prefix = scope.selectedLogType ? 'l7_all: ' : 'l4_all: ';
                    let { lastSearch = '' } = self;

                    lastSearch = escapeSearchValue(lastSearch);

                    try {
                        parsed = avi.QueryParser.parse(prefix + lastSearch);
                    } catch (e) {
                        lastSearch = JSON.stringify(lastSearch).slice(1, -1);
                        parsed = avi.QueryParser.parse(prefix + lastSearch);
                    }

                    self.filters = _.map(parsed, q => {
                        const {
                            quoted,
                            wildcard,
                            array,
                            op,
                            field,
                        } = q;

                        let
                            quote = '',
                            { query: val } = q;

                        if (quoted === 'double' || quoted === 'single' || wildcard) {
                            quote = '"';
                        }

                        if (array) { //array of values for OR logic
                            val = val.join();
                            val = `[${val}]`;
                        }

                        if (angular.isString(val)) {
                            val = decodeSerachValue(val);
                        }

                        return `${op}(${field},${quote}${val}${quote})`;
                    });
                } catch (e) {
                    self.filters = [];
                    console.warn('logsController params.parseSearchString: queryParser,' +
                        'error: %O', e);
                }
            };
        };

        scope.params = new Params();

        const { params } = scope;

        scope.setInitialState = function() {
            let
                { key: duration } = Timeframe.selected(),
                start,
                end;

            if (duration === 'custom') {
                if ((start = moment($stateParams.start)) && (end = moment($stateParams.end)) &&
                    start.isValid() && end.isValid() && start.isBefore(end)) {
                    params.start = start;
                    params.end = end;
                } else {
                    duration = defaults.timeframe;
                    Timeframe.set(duration);
                }
            }

            params.updateTimeframe(duration, start, end);

            if ($stateParams.filter == 1 || $stateParams.filter == 2 ||
                $stateParams.filter == 3) {
                params.filter = +$stateParams.filter;
            }

            if ($stateParams.orderby && ordersBy[$stateParams.orderby]) {
                params.orderby = $stateParams.orderby;
            }

            if ($stateParams.asc === 'false' || $stateParams.asc === 'true') {
                params.asc = $stateParams.asc === 'true';
            }

            //filter regular search which might come from events list page
            if ($stateParams.search) {
                try {
                    params.search = JSON.parse($stateParams.search);
                } catch (e) {
                    console.error('Search query param parsing failed', $stateParams.search, e);
                }
            }

            params.slug = $stateParams.vsId;

            if (scope.vs.getConfig()) {
                params.updateVSParams(scope.vs);
            } else {
                scope.vs.load().then(() => params.updateVSParams(scope.vs));
            }

            const item = scope.pool || scope.vs || scope.se || null;

            if (item) {
                item.addLoad(['health', 'alert', 'faults']);
            } else {
                console.warn('No Item on LogsController init');
            }

            params.page_size = +$stateParams.page_size;

            scope.expander = new DetailsExpander();
            scope.itemsQ = 0; //number of entities gotten by our request
            scope.selItemsQ = 0;
            scope.data = {
                results: [],
                chart: {},
            };
            scope.timeline = { active: false };// timelines object in table rows for durations
            scope.loading = true;
            scope.timeouts = {
                chart: null,
                list: false,
                listUpdate: false,
            };
            //is there is no need in queries - smth went wrong (no VS for pool)
            scope.suspended = $stateParams.poolId && !$stateParams.vsId;
            scope.vs.bind('itemSaveSuccess', () => {
                params.updateVSParams(scope.vs);

                // Show only Significant log if Non-Significant log is not enabled in VS setting
                // 1: nf&udf, 2: adf, 3:nf&udf&adf
                if (!scope.ui.isNonSignificantLogEnabled()) {
                    scope.params.filter = 2;
                }

                scope.ui.forceRefresh();
            });

            // If VS is Http(s)-VS -> load L7 logs by default.
            scope.selectedLogType = scope.vs.isHTTP() ?
                scope.vsLogs.L7_LOGS_TYPE : scope.vsLogs.L4_LOGS_TYPE;

            Timeframe.on('change', scope.ui.onTimeframeChange);
        };

        /**
         * Returns class names to be applied on Non-Significant logs legend
         * @returns {string}
         */
        scope.getNonSignificantLogsLegendClassNames = () => {
            return classnames('legend-pair', {
                disabled: params.filter == 2,
                'not-clickable': params.filter == 1,
                'non-significant-log-disabled': !scope.ui.isNonSignificantLogEnabled(),
            });
        };

        //UI communication
        //limeline view with connection to query
        const UI = function() {
            const self = this;

            const fetch = function() {
                scope.params.page = 1;
                scope.fetchLogs();
                scope.fetchForBar();
                self.searchIsSaved =
                    typeof scope.savedSearch.ifCurrentSearchIsSaved() !== 'undefined';
            };

            const fetchLogs = function() {
                scope.fetchLogs();
            };

            const fetchForBar = function() {
                scope.fetchForBar();
            };

            this.fetch = function() { //don't like this!
                fetch();
            };

            this.fetchLogs = function() {
                scope.fetchLogs();
            };

            this.clearSearchFilter = function() {
                scope.params.search = [];

                if (scope.params.lastSearch) {
                    fetch();
                }
            };

            /**
             * Updates Log search filter.
             * @param {string} str - search string.
             */
            this.updateSearch = function(str) {
                if (str) {
                    if (typeof str === 'object' && str.length) {
                        str.forEach(function(val) {
                            scope.params.search.push(val);
                        });
                    } else {
                        scope.params.search.push(str);
                    }

                    fetch();
                }
            };

            /**
             * Handles NTLM log filtering. Custom query is formed to handle filtering of
             * NTLM detected and not detected logs.
             * @param {boolean} ntlmDetected - indicates whether NTLM is detected or not.
             */
            this.updateNtlmSearch = function(ntlmDetected = false) {
                const searchValue = ntlmDetected ?
                    'ntlm_log.ntlm_detected=true' : 'ntlm_log.ntlm_detected!=true';

                this.updateSearch(searchValue);
            };

            this.updateTimeframe = function(val) {
                if (typeof val === 'undefined') {
                    val = $stateParams.timeframe;
                }

                scope.params.updateTimeframe(val);
                fetch();
            };

            this.orderBy = function(fieldName) {
                if (ordersBy[fieldName]) {
                    scope.params.setOrderBy(fieldName);
                    fetchLogs();
                }
            };

            this.forceRefresh = function() {
                scope.params.page = 1;
                scope.params.updateTimeframe();
                fetch();
            };

            /* two types now: 'nf' (includes udf) and 'adf'
             * 1: nf, 2:adf, 3: nf&adf */
            this.switchFilter = function(type) {
                const routes = {
                    adf: {
                        1: 3,
                        3: 1,
                    },
                    nf: {
                        2: 3,
                        3: 2,
                    },
                };

                const route = routes[type] && routes[type][scope.params.filter];

                if (route) {
                    scope.params.filter = route;
                    this.forceRefresh();
                }
            };

            /**
             * Toggle Non-Significant logs legend.
             */
            this.toggleNonSignificantLogs = () => {
                this.isNonSignificantLogEnabled() && this.switchFilter('nf');
            };

            this.toggleTimelineView = function() { //TODO overview
                scope.timeline.active = !scope.timeline.active;
            };

            /* To get right padding length for all td.log-timeline in log list
             @param duration (maximum on the page) is in ms
             zero for timeline.active.
             Need padding to show duration Label right after the timeline bars
             TODO update to support hours
             */
            this.durLabelLength = 0;

            this.updateDurationLabelLength = function(duration) {
                let len = 0;

                if (duration < 10) {
                    len = '2em';
                } else if (duration < 100) {
                    len = '3em';
                } else if (duration < 1000) {
                    len = '3.25em';
                } else if (duration < 10000) {
                    len = '4.5em';
                } else if (duration < moment.duration(1, 'm').asMilliseconds()) {
                    len = '5.5em';
                } else if (duration < moment.duration(10, 'm').asMilliseconds()) {
                    len = '4.5em';
                } else {
                    len = '5.1em';
                }

                this.durLabelLength = len;
            };

            /**
             * Switcher of the view and sorting in a single table header cell with two options
             * (timeline and duration). First click on inactive option activates it and sets the
             * layout, second (and all the following) cause sorting by corresponding db field.
             * @param {string=} type - Which option was clicked or when not set - assume click
             *     on an active field (for sorting).
             **/
            this.toggleTimelineButton = function(type) {
                if (_.isUndefined(type)) {
                    type = scope.timeline.active && 'timeline' || 'duration';
                }

                if (type === 'duration') {
                    if (scope.timeline.active) {
                        this.toggleTimelineView();
                    } else {
                        this.orderBy('total_time');
                    }
                } else if (type === 'timeline') {
                    if (scope.timeline.active) {
                        this.orderBy('report_timestamp');
                    } else {
                        this.toggleTimelineView();
                    }
                }
            };

            /* BarChart */
            this.updateBrushBoundaries = function() { //load table of results onBrushEnd event
                scope.params.page = 1;
                fetchLogs();
                $timeout(this.updatePagesQ.bind(this));
            };

            /* we show 120 bar on chart and can't set step less then 1 sec */
            this.zoomInAvailable = function() {
                return scope.params.brush.start && scope.params.brush.end &&
                    scope.params.brush.end.diff(scope.params.brush.start, 's') > 119;
            };

            this.loadBrushTimeframe = function() { //zoomIn
                scope.params.updateTimeframe('custom',
                    scope.params.brush.start, scope.params.brush.end);

                Timeframe.set('custom');
                fetchForBar();
            };

            this.highlightRegion = function(time) { //shows line on BarChart
                $('log-bar-chart').trigger('$highlightByRow', [time]);
            };

            /* paginator */
            this.prevPage = function() {
                if (scope.params.page > 1) {
                    scope.params.page--;
                    fetchLogs();
                }
            };

            this.nextPage = function() {
                const { params } = scope;
                const itemsQ = Math.min(defaults.apiLimit, scope.selItemsQ || scope.itemsQ);
                let lastPageNum = Math.ceil(itemsQ / params.page_size) || 1;

                if (lastPageNum * params.page_size > defaults.apiLimit) {
                    lastPageNum--;
                }

                if (params.page < lastPageNum) {
                    params.page++;
                    fetchLogs();
                }
            };

            this.updatePagesQ = function() {
                //for pagination only
                this.itemsQ = Math.min(defaults.apiLimit, scope.selItemsQ || scope.itemsQ);
            };

            /* ng-click on user search icon in log search input */
            this.saveSearch = function(e) {
                const list = myAccount.uiProperty.logs.savedSearch;
                let id,
                    text = '';//edition of existing search

                if (this.searchIsSaved) {
                    id = scope.savedSearch.ifCurrentSearchIsSaved();
                    text = list[id].name;
                }

                popoverItem.init($(e.target), id, text);
                popoverItem.show();
            };

            /* ng-click on export logs button */
            this.exportLogs = function(e) {
                const logsQ = scope.selItemsQ || scope.itemsQ;

                if (logsQ > scope.params.page_size) { //show dropdown
                    expLogsPopoverItem.config.html =
                        `<ul>
                        <li>
                            <a href="${encodeURIQueryPartService(scope.ui.exportURI[0])}">
                            Current page of logs</a>
                        </li>
                        <li>
                            <a href="${encodeURIQueryPartService(scope.ui.exportURI[1])}">
                            All ${Math.min(defaults.apiLimit, logsQ)} logs</a>
                        </li>
                    </ul>`;

                    expLogsPopoverItem.init($(e.target));
                    expLogsPopoverItem.show();
                } else {
                    //opens window 'save file' without any (hopefully) side effects
                    window.location.assign(scope.ui.exportURI[1]);
                }
            };

            this.onTimeframeChange = function() {
                const val = Timeframe.selected().key;

                if (val !== scope.params.uri.timeframe) {
                    scope.params.page = 1;
                    scope.ui.updateTimeframe(val);
                }
            };

            /**
             * Used to enable/disable Non-Significant log legend
             * Returns true if Non-Significant log is enabled in VS settings
             * Returns false if Non-Significant log is not enabled in VS settings
             * @returns {boolean}
             */
            this.isNonSignificantLogEnabled = () => {
                const config = scope.vs.getConfig();

                if (config) {
                    const { analytics_policy: { full_client_logs: fullClientLogs } } = config;

                    return fullClientLogs?.enabled ?? false;
                }

                return false;
            };

            /* Two links in array to export result list <a>, updated by fetchLogs.
             First - to export logs from current page, second - to export all logs for current
             set of filters.  */
            this.exportURI = [];
            //show message that list could be reloaded to get fresh results
            this.listUpdated = false;
            this.searchIsSaved = false;//flag if we have current search as saved by user
            this.newSearch = '';//if search is being updated y user right now (have input with text)
            this.hitApiLimit = false;

            /* pseudo init function for UI */
            const popoverItem = new PopoverFactory({
                position: 'top',
                className: 'saveLogSearch',
                width: 270,
                height: 54,
                carat: true,
            });

            popoverItem.actions = {
                save(text, id) {
                    scope.savedSearch.save(text, id);
                },
                checkBeforeSave(name, id) {
                    return scope.savedSearch.checkBeforeSave(name, id);
                },
            };

            popoverItem.superinit = popoverItem.init;

            popoverItem.init = function(parent, id, value) {
                const self = this;

                this.superinit.call(this, parent);

                $(`<input type="text" value="${value}" size="18" maxlenghth="18"/>`)
                    .on('keydown', function(e) {
                        const button = self.popover.find('button');

                        if (e.which === 13 && e.type === 'keydown') { //enter
                            if (button.is(':enabled')) {
                                button.trigger('click');
                            }
                        } else {
                            $timeout(function() {
                                if (`${self.popover.find('input').val()}`.length > 2) {
                                    button.prop('disabled', false);
                                } else {
                                    button.attr('disabled', 'disabled');
                                }
                            });
                        }
                    })
                    .appendTo(this.popover);

                const button = $('<button class="avi-btn avi-btn-primary">Save Search</button>')
                    .on('click', function() {
                        const text = self.popover.find('input').val();

                        if (text && text.length > 2 && self.actions.checkBeforeSave(text, id)) {
                            self.actions.save(text, id);
                            self.hide();
                        } else {
                            console.warn('LogController.popover: Can\'t save user search.');
                        }
                    })
                    .appendTo(this.popover);

                if (typeof id === 'undefined' || !value || value.length < 2) {
                    button.attr('disabled', 'disabled');
                }

                this.popover.appendTo('body');

                $timeout(function() {
                    self.popover.find('input').trigger('focus');
                });
            };

            let expLogsPopoverItem;

            expLogsPopoverItem = new PopoverFactory({
                position: 'bottom',
                className: 'exportLogs',
                width: 160,
                height: 35,
                margin: 10,
                hide: {
                    outClick: true,
                    onEscape: true,
                    onWinResize: true,
                    innerClick: true,
                },
            });

            /**
             * Opens a modal window with some extra log properties.
             * @param log {Object} - Log's "record" object from the log details template scope.
             */
            this.showFullLogModal = function(log) {
                AviModal.open('full-log-details', { log }, 'avi-modal');
            };

            /**
             * Checks if VS log throttling is enabled.
             * @returns {boolean}
             */
            this.logThrottleEnabled = function() {
                const { VirtualService } = scope;

                if (VirtualService) {
                    const config = VirtualService.getConfig();

                    if (config) {
                        const { analytics_policy: ap } = config;

                        if (ap) {
                            const { full_client_logs: fcl } = ap;

                            if (fcl) {
                                return fcl.enabled && fcl.throttle > 0 ||
                                    ap.significant_log_throttle > 0 || ap.udf_log_throttle > 0;
                            }
                        }
                    }
                }

                return false;
            };
        };

        scope.ui = new UI();

        /* user saved searches - sidebar and star icon in log search input field with popover */
        const SavedSearch = function() {
            /* Id is for updating name of saved search, optional. */
            this.save = function(name, id) {
                const list = myAccount.uiProperty.logs.savedSearch;

                if (this.checkBeforeSave(name, id)) {
                    const item = {
                        name,
                        search: angular.copy(scope.params.search),
                    };

                    if (typeof id === 'undefined') { //new
                        list.push(item);
                    } else { //edition of name
                        list[id].name = name;
                    }

                    myAccount.saveUIProperty();
                    scope.ui.searchIsSaved = true;
                } else {
                    console.warn('logController.saveSearch.save: Can\'t save user search because' +
                        'of failed pre-save check.');
                }
            };

            this.remove = function(key) {
                const list = myAccount.uiProperty.logs.savedSearch;

                if (key === this.ifCurrentSearchIsSaved()) {
                    scope.ui.searchIsSaved = false;
                }

                if (list[key]) {
                    list.splice(key, 1);
                }

                myAccount.saveUIProperty();
            };

            this.load = function(key) {
                const list = myAccount.uiProperty.logs.savedSearch;

                if (list[key]) {
                    scope.params.search = angular.copy(list[key].search);
                    scope.ui.fetch();
                }
            };

            /* Check for duplicates by name and search string itself.
             * Id is used on renaming for existing searches.
             * */
            this.checkBeforeSave = function(name, id) {
                const list = myAccount.uiProperty.logs.savedSearch;
                let byName,
                    bySearch;

                const search = JSON.stringify(scope.params.search);

                list.forEach(function(val, key) {
                    if (!byName && key !== id && val.name === name) {
                        byName = true;
                    }

                    if (!bySearch && key !== id && JSON.stringify(val.search) === search) {
                        bySearch = true;
                    }
                });

                return !byName && !bySearch;
            };

            /* Checks if current search is saved in a list of users searches.
             * Returns undefined or id of saved search in array. */
            this.ifCurrentSearchIsSaved = function() {
                const list = myAccount.uiProperty.logs.savedSearch;
                let res;

                const search = JSON.stringify(scope.params.search);

                list.forEach(function(val, key) {
                    if (typeof res === 'undefined' && JSON.stringify(val.search) === search) {
                        res = key;
                    }
                });

                return res;
            };
        };

        scope.savedSearch = new SavedSearch();

        scope.toggleRepaint = function() { //called on hide/show of sidebar
            setTimeout(function() { //ng-click is too fast to get DOM in rendered state
                scope.$broadcast('$repaintViewport');
            });
        };

        /* content load */
        scope.fetchLogs = function() {
            function updateItemsQ(num) {
                //used only to update number of items inside brush (selItemsQ) selection as it
                //can be inaccurate when counting from bars otherwise number comes from barChartLoad
                if (scope.params.brush.start) {
                    scope.selItemsQ = num || 0;
                    scope.ui.updatePagesQ();
                }

                scope.ui.hitApiLimit = (scope.selItemsQ || scope.itemsQ) > defaults.apiLimit &&
                    (scope.params.page + 1) * scope.params.page_size > defaults.apiLimit;
            }

            if (scope.suspended) {
                return;
            }

            const
                query = scope.params.queryString(),
                def = $q.defer(),
                load = function() {
                    $http({
                        method: 'get',
                        url: query,
                        timeout: def.promise,
                    })
                        .then(({ data }) => {
                            let timeoutId;

                            //when previous request timeout resolve was late
                            if (scope.timeouts.list !== def) {
                                return;
                            }

                            scope.timeouts.list = null;

                            const timeline = {
                                maxTimestamp: scope.params.start,
                                minTimestamp: scope.params.end,
                                maxDuration: 0,
                                active: scope.timeline.active,
                            };

                            _.each(data.results, function(r) {
                                const row = logDataTransform.rowOnLoad(r);
                                const stop = moment(row.report_timestamp);
                                const start = moment(row.dt_start);

                                if (start.isBefore(this.minTimestamp)) {
                                    this.minTimestamp = start;
                                }

                                if (stop.isAfter(this.maxTimestamp)) {
                                    this.maxTimestamp.stop = stop;
                                }

                                this.maxDuration = Math.max(this.maxDuration, row.total_time);

                                scope.data.results.push(row);
                            }, timeline);

                            scope.timeline = timeline;

                            scope.ui.updateDurationLabelLength(timeline.maxDuration);
                            scope.expander.setLength(scope.data.results.length);
                            updateItemsQ(data.count);

                            /* check for list results */
                            if (+data.percent_remaining !== 0 &&
                                +data.count < scope.params.page_size) {
                                timeoutId = $timeout(function() {
                                    scope.checkListUpdates(timeoutId);
                                }, 1999);
                                scope.timeouts.listUpdate = timeoutId;
                            }
                        })
                        .catch(() => {
                            updateItemsQ();

                            if (scope.timeouts.list !== def) {
                                return;
                            }

                            scope.timeouts.list = null;
                            scope.timeline = { active: false };
                            scope.expander.setLength(0);
                        }).finally(() => scope.loading = false);
                };

            if (scope.timeouts.list) {
                scope.timeouts.list.resolve();
                scope.timeouts.list = null;
            }

            if (scope.timeouts.listUpdate) {
                $timeout.cancel(scope.timeouts.listUpdate);
                scope.timeouts.listUpdate = false;
            }

            scope.timeouts.list = def;

            scope.$broadcast('logFetch');//for search autocomplete
            scope.loading = true;
            scope.ui.listUpdated = false;
            scope.ui.exportURI = [scope.params.queryString('download'),
                scope.params.queryString('downloadAll')];
            scope.data.results = [];

            load();
        };

        scope.checkListUpdates = function(timeoutId) {
            if (timeoutId !== scope.timeouts.listUpdate) {
                return;
            }

            const query = scope.params.queryString('listUpdate');

            $http.get(query)
                .then(({ data }) => {
                    if (timeoutId !== scope.timeouts.listUpdate) {
                        return;
                    }

                    scope.timeouts.listUpdate = false;

                    if (+data.percent_remaining === 0 ||
                        scope.data.results.length < data.count) {
                        if (data.count) {
                            scope.ui.listUpdated = true;
                        }
                    } else {
                        timeoutId = $timeout(function() {
                            scope.checkListUpdates(timeoutId);
                        }, 1999);
                        scope.timeouts.listUpdate = timeoutId;
                    }
                });
        };

        scope.fetchForBar = function() {
            let nthPolling = 1;
            const query = scope.params.queryString('barChart');
            const wafQuery = scope.params.queryString('wafLogs');
            const deff = $q.defer();

            function load() {
                const pollingInterval = Math.min(
                    LOAD_POLLING_INTERVAL_MAX,
                    LOAD_POLLING_INTERVAL_BASE ** nthPolling,
                );

                nthPolling++;

                const fullQuery = `${query}timeout=${pollingInterval}&`;
                const wafFullQuery = `${wafQuery}timeout=${pollingInterval}&`;
                const requests = [$http({
                    method: 'get',
                    url: fullQuery,
                    timeout: deff.promise,
                })];

                if (scope.vs.hasWAFPolicy()) {
                    requests.push($http(
                        {
                            method: 'get',
                            url: wafFullQuery,
                            timeout: deff.promise,
                        },
                    ));
                }

                return $q.all(requests).then(responses => {
                    if (deff !== scope.timeouts.chart) {
                        return;
                    }

                    const [resp0, resp1] = responses;
                    const data0 = resp0.data;
                    const itemsQ = data0.results.reduce((base, { value }) => base + value, 0);

                    updateItemsQ(itemsQ);

                    const { chart } = scope.data;

                    chart.results = data0.results;
                    chart.start = data0.start;
                    chart.end = data0.end;

                    // stop polling once data are all fetched
                    // or it exceeds 20 times of polling (with interval limit max of 32s)
                    if (+data0.percent_remaining === 0 || nthPolling > LOAD_POLLING_COUNT_MAX) {
                        scope.timeouts.chart = null;
                    } else {
                        load();
                    }

                    if (scope.vs.hasWAFPolicy()) {
                        const data1 = resp1.data;

                        chart.waf = data1.results;
                    }
                }).catch(() => {
                    if (deff !== scope.timeouts.chart) {
                        return;
                    }

                    scope.data.chart = { results: [] };
                    updateItemsQ();
                    scope.timeouts.chart = null;
                });
            }

            function updateItemsQ(num) {
                scope.itemsQ = num || 0;

                if (!num && scope.params.brush.start) {
                    scope.selItemsQ = 0;
                }

                scope.ui.updatePagesQ();
            }

            if (scope.suspended) {
                return;
            }

            updateItemsQ();

            if (scope.timeouts.chart) {
                scope.timeouts.chart.resolve();
                scope.timeouts.chart = null;
            }

            scope.data.chart = { results: [] };
            scope.timeouts.chart = deff;
            load();//recursive load when great amount of logs
        };

        /**
         * Setting up virtual service dropdown options for log search.
         * @private
         */
        const setVsOptionsForSearch_ = () => {
            const { pool } = scope;

            if (!pool) {
                return;
            }

            scope.vsRefs = pool.getVSRefs().map(vsRef => {
                const vsName = stringService.name(vsRef);
                const uuid = stringService.slug(vsRef);

                return dropDownUtils.createOption(
                    uuid,
                    vsName,
                );
            });
        };

        /**
         * Sets hasOverrideL4AppProfile if any of the VS’s Services has
         * override_application_profile_uuid populated.
         * @private
         */
        const setOverrideL4AppProfile_ = () => {
            const { services = [] } = scope.vs.getConfig();

            scope.hasOverrideL4AppProfile = services.some(service => {
                return !!service.override_application_profile_ref;
            });
        };

        /**
         * When selected VS/log-type (L4/L7) is changed,
         * Update the search param with new value.
         */
        scope.selectedVsOrLogTypeChangeHandler = () => {
            scope.ui.fetch();
        };

        /**
         * Show/hide WAF Log recommnedations icon based on it's status.
         * @param {WafStatusCode} wafLogStatus - Status of WAF Log.
         */
        scope.handleRecommendationsIcon = wafLogStatus => {
            if (wafLogStatus === wafStatusCodeHash.FLAGGED ||
                wafLogStatus === wafStatusCodeHash.REJECTED) {
                return true;
            }

            return false;
        };

        /**
         * Handler for click of WAF log recommendation icon.
         * @param {jQuery.Event} event - jQuery event object.
         * @param {IApplicationLog} record - Log record object.
         */
        scope.onRecommendationsIconClick = (event, record) => {
            event.stopPropagation();

            const { uuid: vsUuid } = scope.vs.getConfig();
            const { report_timestamp: reportTimestamp, request_id: requestId } = record;

            recommendationDialogService.openDialog(vsUuid, reportTimestamp, requestId);
        };

        /**
         * Set initial state and fetch data.
         */
        function onInit() {
            if (!scope.vs) {
                scope.suspended = true;
                console.error('No VS object in rootScope, can\'t start logs.');
            } else {
                scope.setInitialState();

                if (!scope.vs.getConfig()) {
                    scope.vs.bind('itemLoadSuccess', () => {
                        params.updateVSParams(scope.vs);

                        setOverrideL4AppProfile_();

                        scope.ui.fetch();
                    }, true);//one time event
                } else {
                    setOverrideL4AppProfile_();

                    scope.ui.fetch();
                }
            }

            setVsOptionsForSearch_();
        }

        /** init */
        onInit();

        myAccount.on(USER_SETTINGS_DISPLAY_TIMEZONE_UPDATE, onInit);

        scope.$on('$destroy', () => {
            Object.keys(scope.timeouts).forEach(function(key) {
                if (scope.timeouts[key]) {
                    if (key !== 'listUpdate') {
                        scope.timeouts[key].resolve();
                    } else {
                        $timeout.cancel(scope.timeouts.listUpdate);
                    }

                    scope.timeouts[key] = null;
                }
            });

            logTimeframes.custom.hiddenInLayout = true;

            const item = scope.Pool || scope.vs || scope.ServiceEngine;

            if (item) {
                item.async.stop(true);
            }

            Timeframe.unbind('change', scope.ui.onTimeframeChange);

            if (recommendationDialogService.isOpen()) {
                recommendationDialogService.closeDialog();
            }
        });

        /**
         * Returns true if the log contains bot management data.
         * @param {object} record - Log object.
         * @returns {boolean}
         */
        scope.hasBotManagementLog = record => {
            return Boolean(record.bot_management_log);
        };
    }]);

/**
 * @ngdoc constant
 * @name logApiMaxEntriesLimit
 * @description
 *     API can't return more entries then this limit. We aren't allowed to overflow this limit by
 *     page_size * page.
 */
angular.module('avi/logs').constant('logApiMaxEntriesLimit', 10000);
