/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'persistence-profile';
const componentName = 'persistence-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const serverHmDownRecoveryInputLabel = `${prefix}.serverHmDownRecoveryInputLabel`;
export const immediateOption = `${prefix}.immediateOption`;
export const neverOption = `${prefix}.neverOption`;
export const persistenceTypeInputLabel = `${prefix}.persistenceTypeInputLabel`;
export const ipPersistenceTimeoutInputLabel = `${prefix}.ipPersistenceTimeoutInputLabel`;
export const ipPersistenceTimeoutInputPlaceholder = `${prefix}.ipPersistenceTimeoutInputPlaceholder`;
export const ipMaskInputLabel = `${prefix}.ipMaskInputLabel`;
export const ipMaskInputPlaceholder = `${prefix}.ipMaskInputPlaceholder`;
export const persistenceHeaderNameInputLabel = `${prefix}.persistenceHeaderNameInputLabel`;
export const persistenceHeaderNameInputPlaceholder = `${prefix}.persistenceHeaderNameInputPlaceholder`;
export const cookieNameInputLabel = `${prefix}.cookieNameInputLabel`;
export const cookieNameInputPlaceholder = `${prefix}.cookieNameInputPlaceholder`;
export const timeoutInputLabel = `${prefix}.timeoutInputLabel`;
export const timeoutInputPlaceholder = `${prefix}.timeoutInputPlaceholder`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const alwaysSendCookieInputLabel = `${prefix}.alwaysSendCookieInputLabel`;
export const appCookieNameInputLabel = `${prefix}.appCookieNameInputLabel`;
export const appCookieNameInputPlaceholder = `${prefix}.appCookieNameInputPlaceholder`;
export const isFederatedInputLabel = `${prefix}.isFederatedInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const isPersistentCookieCheckboxLabel = `${prefix}.isPersistentCookieCheckboxLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Persistence Profile: {0}',
    [modalHeaderNew]: 'New Persistence Profile: {0}',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [serverHmDownRecoveryInputLabel]: 'Select New Server When Persistent Server Down',
    [immediateOption]: 'Immediate',
    [neverOption]: 'Never',
    [persistenceTypeInputLabel]: 'Type',
    [ipPersistenceTimeoutInputLabel]: 'Persistence Timeout',
    [ipPersistenceTimeoutInputPlaceholder]: 'Use Session Persistence',
    [ipMaskInputLabel]: 'IP Mask',
    [ipMaskInputPlaceholder]: 'Enter IP Mask',
    [persistenceHeaderNameInputLabel]: 'Header Name',
    [persistenceHeaderNameInputPlaceholder]: 'Enter Header Name',
    [cookieNameInputLabel]: 'HTTP Cookie Name',
    [cookieNameInputPlaceholder]: 'Enter HTTP Cookie Name',
    [timeoutInputLabel]: 'Persistence Timeout',
    [timeoutInputPlaceholder]: 'Use Session Persistence',
    [minutesInnerText]: 'Minutes',
    [alwaysSendCookieInputLabel]: 'Always Send',
    [appCookieNameInputLabel]: 'Application Cookie Name',
    [appCookieNameInputPlaceholder]: 'Enter Application Cookie Name',
    [isFederatedInputLabel]: 'Is Federated',
    [descriptionInputLabel]: 'Description',
    [isPersistentCookieCheckboxLabel]: 'Is Persistent Cookie',
};
