/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-input-container-error';
const prefix = `${moduleName}.${componentName}`;

export const requiredError = `${prefix}.requiredError`;
export const regexPatternError = `${prefix}.regexPatternError`;
export const rangeError = `${prefix}.rangeError`;
export const uniquenessError = `${prefix}.uniquenessError`;
export const configFieldInputValidationError = `${prefix}.configFieldInputValidationError`;
export const portValidateError = `${prefix}.portValid`;

export const ENGLISH = {
    [requiredError]: 'This field is required.',
    [regexPatternError]: 'The value entered is invalid.',
    [rangeError]: 'The value entered is outside of the valid range.',
    [uniquenessError]: 'This field does not allow duplicate values.',
    [configFieldInputValidationError]: 'The value entered is outside of the valid range.',
    [portValidateError]: 'The Port value entered is invalid.',
};
