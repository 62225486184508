/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './method-match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias module:match/methodMatch
 * @private
 */
class MethodMatchComponent {
    constructor(
        dropDownUtils,
        l10nService,
    ) {
        this.methods = dropDownUtils.getEnumDropdownOptions('HTTPMethod');

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

MethodMatchComponent.$inject = [
    'dropDownUtils',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  methodMatch
 * @module match/methodMatch
 * @description Component for configuring a MethodMatch Config Item.
 * @param {MethodMatchConfigItem} methodMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('methodMatch', {
    bindings: {
        methodMatch: '<',
        preventEdit: '<',
    },
    controller: MethodMatchComponent,
    templateUrl: 'src/components/forms/match-config/matches/method-match/' +
        'method-match.component.html',
});
