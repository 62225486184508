/**
 * @module NotificationsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    StateService,
    Transition,
} from '@uirouter/core';

import {
    IVSGroupedFaults,
    VSFaultsComponent,
} from 'ng/modules/notification/components/vs-faults/vs-faults.component';

import {
    ControllerFaultsComponent,
} from 'ng/modules/notification/components/controller-faults/controller-faults.component';

import { NotificationService } from 'ng/modules/core';

import { map } from 'underscore';
import { IRootScopeService } from 'angular';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    getFlattenedVirtualServiceFaultDescriptions,
    getGroupedVirtualServiceFaultDescriptions,
    IFault,
    IGenericFault,
} from './fault-service.utils';
import {
    ControllerFaultCollection,
} from '../factories/controller-fault/controller-fault.collection.factory';

const CONTROLLER_FAULTS_NOTIFICATION_ID = 'controller-faults';
const VS_FAULTS_NOTIFICATION_ID = 'virtual-service-faults';
const controllerFaultsLocalStorageKey = 'controllerFaults';

type TControllerFaultCollection = typeof ControllerFaultCollection;

/**
 * @description
 *     Service that manages both Controller and Virtual Service faults.
 *
 *     Virtual Service faults come from the "virtualservice-inventory" request, and
 *     handleVirtualserviceUpdate is called if faults are detected.
 *
 *     Controller faults come from the "controller-inventory" request,
 *
 *     This service is responsible for creating the ControllerFaultCollection instance
 *     and loading/destroying it.
 *     The collection is not static and continues to load.
 *
 *     If a Controller fault is detected, a message is compiled to be displayed to the user. The
 *     user can close that message, and it will stay closed until a different set of faults is
 *     returned in the response.
 *
 * @author Alex Tseung, Alex Malitsky, Aravindh Nagarajan
 */
export class FaultService extends AjsDependency {
    private readonly $rootScope: IRootScopeService;
    private readonly $state: StateService;
    private readonly notificationService: NotificationService;
    private readonly ControllerFaultCollection: TControllerFaultCollection;
    private controllerFaultCollection: ControllerFaultCollection | null = null;

    /**
     * This hash is used to keep track of virtualservice ids to faults. The virtualservice
     * faults message should only be compiled if the incoming faults differ from previous
     * faults.
     *
     * @example {'VS_UUID': 'Fault description'}
     */
    private readonly virtualserviceFaultsHash: Record<string, string> = {};

    /**
     * UUID of the VS whose fault notification is currently being displayed.
     */
    private currentVirtualserviceId = '';

    /**
     * Flag to show fault message on login only.
     */
    private ignoreControllerFaults = false;

    constructor() {
        super();

        this.$rootScope = this.getAjsDependency_('$rootScope');
        this.$state = this.getAjsDependency_('$state');
        this.ControllerFaultCollection = this.getAjsDependency_('ControllerFaultCollection');
        this.notificationService = this.getAjsDependency_('notificationService');

        const $transitions: Transition = this.getAjsDependency_('$transitions');

        $transitions.onSuccess({}, this.stateChangeSuccessHandler);
    }

    /**
     * Called when user is logged in to the application. Should be called both when the user
     * logs in and when user refreshes the page. Sets localStorage.controllerFaults and calls
     * getControllerFault();
     */
    public start = (): void => {
        this.stop();

        if (!localStorage.getItem(controllerFaultsLocalStorageKey)) {
            localStorage.setItem(controllerFaultsLocalStorageKey, '');
        }

        this.getControllerFaults();
    };

    /**
     * Called when user is logged out from the application. Destroys controllerFaultCollection
     * to stop polling for data.
     */
    public stop(): void {
        localStorage.removeItem(controllerFaultsLocalStorageKey);

        if (this.controllerFaultCollection) {
            this.controllerFaultCollection.destroy();

            this.controllerFaultCollection = null;
        }
    }

    /**
     * Transforms the faults object from the response into an array of faults with "type" as the
     * "id".
     * @param faults - Virtual Service faults object from "virtualservice-inventory" request.
     */
    public parseFaults(faults: Record<string, IGenericFault[]>): IFault[] {
        return map(faults, (faultList: IGenericFault[], type: string) => {
            return {
                id: type,
                type,
                faults: faultList,
            };
        });
    }

    /**
     * Called by VirtualServiceFaultDataTransformer if a fault is detected from the response to
     * the "virtualservice-inventory" request. Only do something when state is within Virtual
     * Service detail.
     */
    public handleVirtualserviceUpdate(faults: IFault[]): void {
        const { vsId } = this.$state.params;

        if (!vsId) {
            return;
        }

        const faultDescriptionList = getFlattenedVirtualServiceFaultDescriptions(faults);
        const faultDescriptions = faultDescriptionList.sort().join(',');
        const faultDescriptionsFromCache = this.getVirtualserviceCachedDescriptions(vsId);

        this.currentVirtualserviceId = vsId;

        if (faultDescriptionsFromCache !== faultDescriptions) {
            if (faultDescriptions) {
                const groupedFaults = getGroupedVirtualServiceFaultDescriptions(faults);

                this.removeVirtualServiceFaultsNotification();
                this.showVirtualServiceFaultsNotification(groupedFaults);
            }

            this.setVirtualserviceCachedDescriptions(vsId, faultDescriptions);
        }
    }

    /**
     * Set listeners to listen for $rootScope $broadcast events. Loads/destroys collection
     * and opens/closes message.
     */
    public setListeners(): void {
        this.$rootScope.$on('setContext', this.start);

        this.$rootScope.$on('userLoggedOut', () => {
            this.ignoreControllerFaults = false;
            this.stop();
        });
    }

    /**
     * Function to set ignoreControllerFaults property. This is only called
     * from welcome controller to ignore controller faults for welcome flow.
     */
    public setIgnoreControllerFaults(value: boolean): void {
        this.ignoreControllerFaults = value;
    }

    /**
     * Function to refresh controller fault notifications. This is called from welcome
     * controller after welcome submit button click so that notification get refreshed.
     */
    public refreshControllerFaultsNotification(): void {
        const faultDescriptionsFromCache =
            localStorage.getItem(controllerFaultsLocalStorageKey);

        // If no controller fault present then no need to fetch data again.
        if (!faultDescriptionsFromCache) {
            return;
        }

        this.hideControllerFaultsNotification();

        this.getControllerFaults();
    }

    /**
     * Compiles controllerFaultsMessage component to display Controller faults to div.messages.
     */
    private showControllerFaultsNotification(): void {
        this.notificationService.add({
            id: CONTROLLER_FAULTS_NOTIFICATION_ID,
            component: ControllerFaultsComponent as Type<Component>,
            componentProps: {
                faults: this.controllerFaultCollection.faultDescriptions,
            },
        });
    }

    /**
     * Removes compiled controllerFaultsMessage component.
     */
    private hideControllerFaultsNotification(): void {
        if (this.notificationService.has(CONTROLLER_FAULTS_NOTIFICATION_ID)) {
            this.notificationService.remove(CONTROLLER_FAULTS_NOTIFICATION_ID);
        }
    }

    /**
     * Gets the cached string of fault descriptions for a Virtual Service.
     * @param id - Virtual Service id.
     */
    private getVirtualserviceCachedDescriptions(id: string): string {
        return this.virtualserviceFaultsHash[id];
    }

    /**
     * Sets the cached string of fault descriptions for a Virtual Service.
     * @param id - Virtual Service id.
     * @param description - Virtual Service fault descriptions.
     */
    private setVirtualserviceCachedDescriptions(id: string, description: string): void {
        this.virtualserviceFaultsHash[id] = description;
    }

    /**
     * Shows VSFaultsComponent to display Virtual Service faults.
     */
    private showVirtualServiceFaultsNotification(groupedFaults: IVSGroupedFaults): void {
        this.notificationService.add({
            id: VS_FAULTS_NOTIFICATION_ID,
            component: VSFaultsComponent as Type<Component>,
            componentProps: {
                faults: groupedFaults,
            },
        });
    }

    /**
     * Called after ControllerFaultCollection load.
     */
    private onCollectionLoadSuccess(): void {
        const faultDescriptionList = this.controllerFaultCollection.faultDescriptions;
        const faultDescriptions = faultDescriptionList.sort().join(',');
        const faultDescriptionsFromCache =
            localStorage.getItem(controllerFaultsLocalStorageKey);

        if (faultDescriptionsFromCache !== faultDescriptions) {
            if (faultDescriptions) {
                this.hideControllerFaultsNotification();
                this.showControllerFaultsNotification();
            }

            localStorage.setItem(controllerFaultsLocalStorageKey, faultDescriptions);
        }
    }

    /**
     * Binds collectionLoadSuccess event to compile Controller faults message if new incoming
     * faults are detected, then loads ControllerFaultCollection.
     */
    private getControllerFaults(): void {
        if (this.ignoreControllerFaults) {
            return;
        }

        if (!this.controllerFaultCollection) {
            this.controllerFaultCollection = new this.ControllerFaultCollection({
                bind: {
                    collectionLoadSuccess: this.onCollectionLoadSuccess.bind(this),
                },
            });
        }

        this.controllerFaultCollection.load();
    }

    /**
     * Called when app state has changed. If the state is not within Virtual Service Detail,
     * remove the message.
     */
    private stateChangeSuccessHandler = (): void => {
        const { vsId } = this.$state.params;

        if (!vsId || vsId !== this.currentVirtualserviceId) {
            this.removeVirtualServiceFaultsNotification();
        }
    };

    /**
     * Removes VSFaultsComponent.
     */
    private removeVirtualServiceFaultsNotification(): void {
        if (this.notificationService.has(VS_FAULTS_NOTIFICATION_ID)) {
            this.notificationService.remove(VS_FAULTS_NOTIFICATION_ID);
        }
    }
}

FaultService.ajsDependencies = [
    '$rootScope',
    '$state',
    '$transitions',
    'notificationService',
    'ControllerFaultCollection',
];
