/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

/**
 * @description
 *      Grid component for dynamicParams instances with editable value cell.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'dynamic-params-grid',
    templateUrl: './dynamic-params-grid.component.html',
})
export class DynamicParamsGridComponent {
    /**
     * List of dynamicParams ConfigItems.
     */
    @Input()
    public paramsList: RepeatedMessageItem<MessageItem>;
}
