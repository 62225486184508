/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { IAviDataGridConfig } from 'ng/shared/components';
import { RepeatedMessageItem } from 'ajs/modules/data-model/factories';
import { LinuxServerHostConfigItem } from 'ajs/modules/cloud';
import { StringService } from 'ajs/modules/core/services';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './host-servers-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   Host Servers Grid Component used in Linux Server Cloud.
 *
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'host-servers-grid',
    templateUrl: './host-servers-grid.component.html',
})
export class HostServersGridComponent implements OnInit {
    /**
     * Hosts RepeatedMessageItem.
     */
    @Input()
    public hosts: RepeatedMessageItem<LinuxServerHostConfigItem>;

    /**
     * Fires on add Host Servers.
     */
    @Output()
    public onAddHostServer = new EventEmitter<void>();

    /**
     * Fires on edit Host Servers.
     */
    @Output()
    public onEditHostServer = new EventEmitter<LinuxServerHostConfigItem>();

    /**
     * Host Servers grid config.
     */
    public hostServersGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const {
            l10nService,
            l10nKeys,
        } = this;

        this.hostServersGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleHostIpAddress),
                    id: 'hostIpAddress',
                    transform: (linuxServerHost: LinuxServerHostConfigItem) => {
                        const { host_ip: hostIpConfigItem } = linuxServerHost.config;

                        return hostIpConfigItem.config.addr;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleServiceEngineGroup),
                    id: 'serviceEngineGroup',
                    transform: (linuxServerHost: LinuxServerHostConfigItem) => {
                        const { se_group_ref: seGroupRef } = linuxServerHost.getConfig();

                        return seGroupRef && this.stringService.name(seGroupRef) || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleCores),
                    id: 'cores',
                    transform: (linuxServerHost: LinuxServerHostConfigItem) => {
                        return linuxServerHost.cores;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleMemory),
                    id: 'memory',
                    transform: (linuxServerHost: LinuxServerHostConfigItem) => {
                        return linuxServerHost.memoryAmount;
                    },
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (linuxServerHostList: LinuxServerHostConfigItem[]) => {
                    linuxServerHostList.forEach((hostServer: LinuxServerHostConfigItem) => {
                        this.deleteHostServer(hostServer);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (linuxServerHost: LinuxServerHostConfigItem) => {
                    this.editHostServer(linuxServerHost);
                },
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (linuxServerHost: LinuxServerHostConfigItem) => {
                    return this.deleteHostServer(linuxServerHost);
                },
            }],
        };
    }

    /**
     * Emits an event on click of add button from tempalte.
     */
    public addHostServer(): void {
        this.onAddHostServer.emit();
    }

    /**
     * Emits an event on click of edit icon from grid.
     */
    private editHostServer(hostServer: LinuxServerHostConfigItem): void {
        this.onEditHostServer.emit(hostServer);
    }

    /**
     * Removes the selected host server from the config.
     */
    private deleteHostServer(hostServer: LinuxServerHostConfigItem): void {
        this.hosts.removeByMessageItem(hostServer);
    }
}
