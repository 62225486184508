/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ClrFormLayout } from '@clr/angular';
import { AutoScalePolicy } from 'ajs/modules/auto-scale';

import './autoscale-policy-modal.component.less';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './autoscale-policy-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Server Auto Scale policy create/edit modal.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'autoscale-policy-modal',
    templateUrl: './autoscale-policy-modal.component.html',
})
export class AutoScalePolicyModalComponent implements OnInit, OnDestroy {
    /**
     * AutoScale ObjectTypeItem.
     */
    @Input()
    public editable: AutoScalePolicy;

    /**
     * AutoScale object type.
     */
    public objectType: string;

    /**
     * Layout for AutoScale modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * AlertConfigCollection instance - for Alert avi-collection-dropdown
     */
    public readonly alertConfigCollection: any;

    constructor(
        l10nService: L10nService,
        @Inject('AlertConfigCollection')
        AlertConfigCollection: any,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.alertConfigCollection = new AlertConfigCollection({ sortBy: 'name' });
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Destroys collection instances.
     * @override
     */
    public ngOnDestroy(): void {
        this.alertConfigCollection.destroy();
    }
}
