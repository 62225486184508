/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IWafPolicyAllowlistRule } from 'generated-types';
import { WafPolicyAllowlistRuleModalComponent } from 'ng/modules/waf';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { MatchTargetConfigItem, TMatchField } from 'ajs/modules/policies';
import { withFullModalMixin } from 'ajs/utils/mixins';

const supportedMatches = ['client_ip', 'method', 'path', 'host_hdr'];

type TWafPolicyAllowlistRule = Omit<IWafPolicyAllowlistRule, 'match'>;

interface IWafPolicyAllowlistRuleConfig extends TWafPolicyAllowlistRule {
    match?: MatchTargetConfigItem;
}

export const WAF_POLICY_ALLOWLIST_RULE_CONFIG_ITEM_TOKEN = 'WafPolicyAllowlistRuleConfigItem';

export class WafPolicyAllowlistRuleConfigItem extends
    withFullModalMixin(MessageItem)<IWafPolicyAllowlistRuleConfig> {
    public static ajsDependencies = [
        'defaultValues',
    ];

    constructor(args = {}) {
        super({
            objectType: 'WafPolicyAllowlistRule',
            windowElement: WafPolicyAllowlistRuleModalComponent,
            ...args,
        });
    }

    /**
     * We want to remove 'match' from the default configuration so that the user can add matches
     * manually.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IWafPolicyAllowlistRuleConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            match: null,
        };
    }

    /**
     * Returns the list of supported matches. TODO: refactor to use Schema when
     * "match_target_options" is available.
     */
    public get supportedMatches(): string[] {
        return supportedMatches;
    }

    /**
     * Returns the number of matches configured.
     */
    public get matchCount(): number {
        return this.supportedMatches.filter(this.hasMatchByField).length;
    }

    /**
     * Returns the enable state.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable state.
     */
    public set enable(enabled) {
        this.config.enable = enabled;
    }

    /**
     * Returns true if the match exists in this.config.match.
     */
    public hasMatchByField = (matchFieldName: TMatchField): boolean => {
        return this.config.match ? this.config.match.hasMatchByField(matchFieldName) : false;
    };

    // @TODO Uncomment when converted to FullModal and the MatchAdder component is being used.
    // JIRA ID: AV-119451
    // /**
    //  * @override
    //  * We clear out flatProps so that the repeatedMessageItems don't get overwritten.
    //  */
    // public modifyConfigDataBeforeSave(): void {
    //     super.modifyConfigDataBeforeSave();
    //
    //     const { match } = this.config;
    //
    //     if (match) {
    //         const { client_ip: clientIp } = match.config;
    //
    //         if (clientIp) {
    //             clientIp.clearFlatProps();
    //         }
    //     }
    // }
}
