/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} TechSupportConfig
 * @description properties used by tech support collection's items
 * extends {@link TechSupportSharedStatus}
 * @property {string} created - when tech support tar ball was created
 * @property {string} duration
 * @property {string[]} errors
 * @property {string} resultSnippet - 'Completed successfully' or 'Completed with errors'
 * @property {string} resultStatus - 'success' or 'warning'
 * @property {FormattedSize} size
 */

const PERMISSION_TECHSUPPORT = 'PERMISSION_TECHSUPPORT';

function techSupportFactory(FileService, Auth) {
    /**
     * @alias module:services/TechSupport
     * @extends FileService
     * @private
     */
    class TechSupport extends FileService {
        /**
         * @override
         */
        isAllowed() {
            return Auth.isAllowed(PERMISSION_TECHSUPPORT, 'w');
        }
    }

    return TechSupport;
}

techSupportFactory.$inject = ['FileService', 'Auth'];

/**
 * @ngdoc service
 * @name TechSupport
 * @module services/TechSupport
 * @author Akul Aggarwal
 * @description
 *
 *     Extension of generic @FileService @Item for @TechSupportCollection.
 *
 */
angular.module('aviApp').factory('TechSupport', techSupportFactory);
