/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'policies';
const componentName = 'dns-policy-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const clientIpAddressOptLabel = `${prefix}.clientIpAddressOptLabel`;
export const protocolOptLabel = `${prefix}.protocolOptLabel`;
export const queryNameOptLabel = `${prefix}.queryNameOptLabel`;
export const queryTypeOptLabel = `${prefix}.queryTypeOptLabel`;
export const geographicalLocationOptLabel = `${prefix}.geographicalLocationOptLabel`;
export const gslbSiteAction = `${prefix}.gslbSiteAction`;
export const allowDropQueryAction = `${prefix}.allowDropQueryAction`;
export const queryResponseAction = `${prefix}.queryResponseAction`;
export const poolSwitchingAction = `${prefix}.poolSwitchingAction`;
export const rateLimitAction = `${prefix}.rateLimitAction`;
export const editDnsPolicyRuleHeader = `${prefix}.editDnsPolicyRuleHeader`;
export const newDnsPolicyRuleHeader = `${prefix}.newDnsPolicyRuleHeader`;
export const nameLabel = `${prefix}.nameLabel`;
export const clickRuleTitle = `${prefix}.clickRuleTitle`;
export const matchesHeader = `${prefix}.matchesHeader`;
export const addNewMatchInputPlaceholder = `${prefix}.addNewMatchInputPlaceholder`;
export const actionsHeader = `${prefix}.actionsHeader`;
export const addNewActionInputPlaceholder = `${prefix}.addNewActionInputPlaceholder`;
export const submitBtnLabel = `${prefix}.submitBtnLabel`;

export const ENGLISH = {
    [clientIpAddressOptLabel]: 'Client IP Address',
    [protocolOptLabel]: 'Protocol',
    [queryNameOptLabel]: 'Query Name',
    [queryTypeOptLabel]: 'Query Type',
    [geographicalLocationOptLabel]: 'Geographical Location',
    [gslbSiteAction]: 'GSLB Site',
    [allowDropQueryAction]: 'Allow/Drop Query',
    [queryResponseAction]: 'Query Response',
    [poolSwitchingAction]: 'Pool Switching',
    [rateLimitAction]: 'Rate Limit',
    [editDnsPolicyRuleHeader]: 'Edit DNS Policy Rule: {0}',
    [newDnsPolicyRuleHeader]: 'New DNS Policy Rule: {0}',
    [nameLabel]: 'Name',
    [clickRuleTitle]: 'Click to {0} this rule',
    [matchesHeader]: 'Matches',
    [addNewMatchInputPlaceholder]: 'Add New Match',
    [actionsHeader]: 'Actions',
    [addNewActionInputPlaceholder]: 'Add New Action',
    [submitBtnLabel]: 'Submit',
};
