/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import { Injector } from '@angular/core';

import {
    CertificateManagementCollection,
    CERTIFICATE_MANAGEMENT_COLLECTION_TOKEN,
    JWTServerProfile,
    JWTServerProfileCollection,
    jwtServerProfileCollectionToken,
    jwtServerProfileToken,
    SSLRatingConfigItem,
    SSL_RATING_CONFIG_ITEM_TOKEN,
    TCertificateManagementCollection,
} from 'ajs/modules/security/factories';

import {
    GeoDb,
    GeoDbFileConfigItem,
    GeoDbMappingConfigItem,
    GeoDbMappingElementConfigItem,
    GEO_DB_FILE_CONFIG_ITEM_TOKEN,
    GEO_DB_ITEM_TOKEN,
    GEO_DB_MAPPING_CONFIG_ITEM_TOKEN,
    GEO_DB_MAPPING_ELEMENT_CONFIG_ITEM_TOKEN,
} from 'ajs/modules/geo-db/factories';

import {
    FileObjectCollection,
    FILE_OBJECT_COLLECTION_TOKEN,
} from 'ajs/modules/system';

import {
    ControlScriptCollection,
    CONTROL_SCRIPT_COLLECTION_TOKEN,
    TControlScriptCollection,
} from 'ajs/modules/scripts';

const $injector = '$injector';

export const jwtServerProfileCollectionProvider = {
    deps: [$injector],
    provide: JWTServerProfileCollection,
    useFactory(injector: Injector): typeof JWTServerProfileCollection {
        return injector.get(jwtServerProfileCollectionToken);
    },
};

export const jwtServerProfileProvider = {
    deps: [$injector],
    provide: JWTServerProfile,
    useFactory(injector: Injector): typeof JWTServerProfile {
        return injector.get(jwtServerProfileToken);
    },
};

export const geoDbProvider = {
    deps: [$injector],
    provide: GeoDb,
    useFactory(injector: Injector): typeof GeoDb {
        return injector.get(GEO_DB_ITEM_TOKEN);
    },
};

export const geoDbFileConfigItemProvider = {
    deps: [$injector],
    provide: GeoDbFileConfigItem,
    useFactory(injector: Injector): typeof GeoDbFileConfigItem {
        return injector.get(GEO_DB_FILE_CONFIG_ITEM_TOKEN);
    },
};

export const geoDbMappingConfigItemProvider = {
    deps: [$injector],
    provide: GeoDbMappingConfigItem,
    useFactory(injector: Injector): typeof GeoDbMappingConfigItem {
        return injector.get(GEO_DB_MAPPING_CONFIG_ITEM_TOKEN);
    },
};

export const geoDbMappingElementConfigItemProvider = {
    deps: [$injector],
    provide: GeoDbMappingElementConfigItem,
    useFactory(injector: Injector): typeof GeoDbMappingElementConfigItem {
        return injector.get(GEO_DB_MAPPING_ELEMENT_CONFIG_ITEM_TOKEN);
    },
};

export const fileObjectCollectionProvider = {
    deps: [$injector],
    provide: FileObjectCollection,
    useFactory(injector: Injector): typeof FileObjectCollection {
        return injector.get(FILE_OBJECT_COLLECTION_TOKEN);
    },
};

export const sslRatingConfigItemProvider = {
    deps: [$injector],
    provide: SSLRatingConfigItem,
    useFactory(injector: Injector): typeof SSLRatingConfigItem {
        return injector.get(SSL_RATING_CONFIG_ITEM_TOKEN);
    },
};

export const controlScriptCollectionProvider = {
    deps: [$injector],
    provide: ControlScriptCollection,
    useFactory(injector: Injector): TControlScriptCollection {
        return injector.get(CONTROL_SCRIPT_COLLECTION_TOKEN);
    },
};

export const certificateManagementCollectionProvider = {
    deps: [$injector],
    provide: CertificateManagementCollection,
    useFactory(injector: Injector): TCertificateManagementCollection {
        return injector.get(CERTIFICATE_MANAGEMENT_COLLECTION_TOKEN);
    },
};

export const hsmGroupCollectionProvider = {
    deps: [$injector],
    provide: 'HSMGroupCollection',
    useFactory(injector: Injector): any {
        return injector.get('HSMGroupCollection');
    },
};
