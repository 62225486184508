/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf-profile';
const componentName = 'waf-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const sectionTitleDefaultActions = `${prefix}.sectionTitleDefaultActions`;
export const sectionTitleFiles = `${prefix}.sectionTitleFiles`;
export const sectionTitleContentTypeMapping = `${prefix}.sectionTitleContentTypeMapping`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionPlaceholder = `${prefix}.descriptionPlaceholder`;
export const allowTitleLabel = `${prefix}.allowTitleLabel`;
export const restrictedTitleLabel = `${prefix}.restrictedTitleLabel`;
export const staticTitleLabel = `${prefix}.staticTitleLabel`;
export const staticExtensionsInputLabel = `${prefix}.staticExtensionsInputLabel`;
export const staticExtensionsInputPlaceholder = `${prefix}.staticExtensionsInputPlaceholder`;
export const maxClientRequestSizeInputLabel = `${prefix}.maxClientRequestSizeInputLabel`;
export const maxClientRequestSizeInputPlaceholder = `${prefix}.maxClientRequestSizeInputPlaceholder`;
export const maxBackendResponseInputLabel = `${prefix}.maxBackendResponseInputLabel`;
export const maxBackendResponseInputPlaceholder = `${prefix}.maxBackendResponseInputPlaceholder`;
export const regexMatchLimitInputLabel = `${prefix}.regexMatchLimitInputLabel`;
export const regexMatchLimitInputPlaceholder = `${prefix}.regexMatchLimitInputPlaceholder`;
export const regexRecursionLimitInputLabel = `${prefix}.regexRecursionLimitInputLabel`;
export const regexRecursionLimitInputPlaceholder = `${prefix}.regexRecursionLimitInputPlaceholder`;
export const maxExecutionTimeInputLabel = `${prefix}.maxExecutionTimeInputLabel`;
export const maxExecutionTimeInputPlaceholder = `${prefix}.maxExecutionTimeInputPlaceholder`;
export const maxExecutionTimeInputInnerText = `${prefix}.maxExecutionTimeInputInnerText`;
export const maxClientRequestSizeInputInnerText = `${prefix}.maxClientRequestSizeInputInnerText`;
export const maxBackendResponseInputInnerText = `${prefix}.maxBackendResponseInputInnerText`;
export const argumentSeparatorInputLabel = `${prefix}.argumentSeparatorInputLabel`;
export const argumentSeparatorInputPlaceholder = `${prefix}.argumentSeparatorInputPlaceholder`;
export const cookieFormatVersionRadioInputGroupLabel = `${prefix}.cookieFormatVersionRadioInputGroupLabel`;
export const netscapeCookiesRadioInputLabel = `${prefix}.netscapeCookiesRadioInputLabel`;
export const versionOneCookiesRadioInputLabel = `${prefix}.versionOneCookiesRadioInputLabel`;
export const ignoreIncompleteRequestBodyErrorMessageCheckboxLabel = `${prefix}.ignoreIncompleteRequestBodyErrorMessageCheckboxLabel`;
export const enableXXEProtectionCheckboxLabel = `${prefix}.enableXXEProtectionCheckboxLabel`;
export const allowedVersionsDropdownLabel = `${prefix}.allowedVersionsDropdownLabel`;
export const allowedVersionsDropdownPlaceholder = `${prefix}.allowedVersionsDropdownPlaceholder`;
export const allowedMethodsDropdownLabel = `${prefix}.allowedMethodsDropdownLabel`;
export const allowedMethodsDropdownPlaceholder = `${prefix}.allowedMethodsDropdownPlaceholder`;
export const contentTypeMappingGridTitle = `${prefix}.contentTypeMappingGridTitle`;
export const restrictedExtensionsInputLabel = `${prefix}.restrictedExtensionsInputLabel`;
export const restrictedExtensionsInputPlaceholder = `${prefix}.restrictedExtensionsInputPlaceholder`;
export const restrictedHeadersInputLabel = `${prefix}.restrictedHeadersInputLabel`;
export const restrictedHeadersInputPlaceholder = `${prefix}.restrictedHeadersInputPlaceholder`;
export const requestHeaderPhaseInputLabel = `${prefix}.requestHeaderPhaseInputLabel`;
export const requestHeaderPhaseInputPlaceholder = `${prefix}.requestHeaderPhaseInputPlaceholder`;
export const requestBodyPhaseInputLabel = `${prefix}.requestBodyPhaseInputLabel`;
export const requestBodyPhaseInputPlaceholder = `${prefix}.requestBodyPhaseInputPlaceholder`;
export const responseHeaderPhaseInputLabel = `${prefix}.responseHeaderPhaseInputLabel`;
export const responseHeaderPhaseInputPlaceholder = `${prefix}.responseHeaderPhaseInputPlaceholder`;
export const responseBodyPhaseInputLabel = `${prefix}.responseBodyPhaseInputLabel`;
export const responseBodyPhaseInputPlaceholder = `${prefix}.responseBodyPhaseInputPlaceholder`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit WAF Profile: {0}',
    [modalHeaderNew]: 'Create WAF Profile: {0}',
    [sectionTitleGeneral]: 'General',
    [sectionTitleDefaultActions]: 'Default Actions',
    [sectionTitleFiles]: 'Files',
    [sectionTitleContentTypeMapping]: 'Content Type Mapping',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [descriptionInputLabel]: 'Description',
    [descriptionPlaceholder]: 'Enter Description',
    [allowTitleLabel]: 'Allow',
    [restrictedTitleLabel]: 'Restricted',
    [staticTitleLabel]: 'Static',
    [staticExtensionsInputLabel]: 'Static Extensions',
    [staticExtensionsInputPlaceholder]: 'Enter Static Extensions',
    [maxClientRequestSizeInputLabel]: 'Maximum Client Request Size',
    [maxClientRequestSizeInputPlaceholder]: 'Enter Maximum Client Request Size',
    [maxBackendResponseInputLabel]: 'Maximum Backend Response',
    [maxBackendResponseInputPlaceholder]: 'Enter Maximum Backend Response',
    [regexMatchLimitInputLabel]: 'Regex Match Limit',
    [regexMatchLimitInputPlaceholder]: 'Enter Regex Match Limit',
    [regexRecursionLimitInputLabel]: 'Regex Recursion Limit',
    [regexRecursionLimitInputPlaceholder]: 'Enter Regex Recursion Limit',
    [maxExecutionTimeInputLabel]: 'Maximum Execution Time',
    [maxExecutionTimeInputPlaceholder]: 'Enter Maximum Execution Time',
    [maxExecutionTimeInputInnerText]: 'Milliseconds',
    [maxClientRequestSizeInputInnerText]: 'KB',
    [maxBackendResponseInputInnerText]: 'KB',
    [argumentSeparatorInputLabel]: 'Argument Separator',
    [argumentSeparatorInputPlaceholder]: 'Enter Argument Separator',
    [cookieFormatVersionRadioInputGroupLabel]: 'Cookie Format Version',
    [netscapeCookiesRadioInputLabel]: 'Netscape Cookies',
    [versionOneCookiesRadioInputLabel]: 'Version 1 Cookies',
    [ignoreIncompleteRequestBodyErrorMessageCheckboxLabel]: 'Ignore Request Body Parsing Errors due to partial Scanning',
    [enableXXEProtectionCheckboxLabel]: 'Enable XXE Protection',
    [allowedVersionsDropdownLabel]: 'Allowed Versions',
    [allowedVersionsDropdownPlaceholder]: 'Select Allowed Versions',
    [allowedMethodsDropdownLabel]: 'Allowed Methods',
    [allowedMethodsDropdownPlaceholder]: 'Select Allowed Methods',
    [contentTypeMappingGridTitle]: 'Content Type Mapping ({0})',
    [restrictedExtensionsInputLabel]: 'Restricted Extensions',
    [restrictedExtensionsInputPlaceholder]: 'Enter Restricted Extensions',
    [restrictedHeadersInputLabel]: 'Restricted Headers',
    [restrictedHeadersInputPlaceholder]: 'Enter Restricted Headers',
    [requestHeaderPhaseInputLabel]: 'Request Header Phase',
    [requestHeaderPhaseInputPlaceholder]: 'Enter Request Header Phase',
    [requestBodyPhaseInputLabel]: 'Request Body Phase',
    [requestBodyPhaseInputPlaceholder]: 'Enter Request Body Phase',
    [responseHeaderPhaseInputLabel]: 'Respose Header Phase',
    [responseHeaderPhaseInputPlaceholder]: 'Enter Response Header Phase',
    [responseBodyPhaseInputLabel]: 'Response Body Phase',
    [responseBodyPhaseInputPlaceholder]: 'Enter Response Body Phase',
};
