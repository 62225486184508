/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  DnsPolicyConfig
 * @description  DnsPolicyConfig ConfigItem.
 */
const dnsPolicyConfigFactory = (LegacyPolicyConfigItem, DnsPolicyRuleConfig) => {
    class DnsPolicyConfig extends LegacyPolicyConfigItem {
        /** @override */
        transformAfterLoad_() {
            super.transformAfterLoad_();
            this.sortRulesBy_('index');
        }
    }

    angular.extend(DnsPolicyConfig.prototype, {
        type: 'DnsPolicy',
        windowElement_: 'dns-policy-rule-modal',
        ruleClass_: DnsPolicyRuleConfig,
        rulesPropertyName_: 'rule',
    });

    return DnsPolicyConfig;
};

dnsPolicyConfigFactory.$inject = [
    'LegacyPolicyConfigItem',
    'DnsPolicyRuleConfig',
];

angular.module('aviApp').factory('DnsPolicyConfig', dnsPolicyConfigFactory);
