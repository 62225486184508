/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import { AutoScalePolicyModalComponent } from '.';
import { alertConfigCollectionProvider } from './ajs-upgraded-providers';

const autoScaleComponents = [
    AutoScalePolicyModalComponent,
];

/**
 * @description
 *
 *      Angular Module for Auto-Scale related components.
 *
 * @author Nisar Nadaf
 */
@NgModule({
    declarations: [
        ...autoScaleComponents,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrInputModule,
        ClrFormsModule,
    ],
    providers: [
        alertConfigCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AutoScaleModule {}
