/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ocsp-responder-grid';
const prefix = `${moduleName}.${componentName}`;

export const addOcspResponderUrlButtonLabel = `${prefix}.addOcspResponderUrlButtonLabel`;
export const actionTitleRemove = `${prefix}.actionTitleRemove`;
export const columnTitleUrl = `${prefix}.columnTitleUrl`;
export const urlInputLabel = `${prefix}.urlInputLabel`;

export const ENGLISH = {
    [addOcspResponderUrlButtonLabel]: 'Add',
    [actionTitleRemove]: 'Remove',
    [columnTitleUrl]: 'URL',
    [urlInputLabel]: 'Enter URL',
};
