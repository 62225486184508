/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'auth-profile-ping-access-settings';
const prefix = `${moduleName}.${componentName}`;

export const pingAccessAgentSettingsHeader = `${prefix}.pingAccessAgentSettingsHeader`;
export const pingAccessAgentInputLabel = `${prefix}.pingAccessAgentInputLabel`;
export const pingAccessAgentInputPlaceholder = `${prefix}.pingAccessAgentInputPlaceholder`;

export const ENGLISH = {
    [pingAccessAgentSettingsHeader]: 'PingAccess Agent Settings',
    [pingAccessAgentInputLabel]: 'PingAccess Agent',
    [pingAccessAgentInputPlaceholder]: 'Select',
};
