/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'ip-addr-match-entry';
const prefix = `${moduleName}.${componentName}`;

export const selectFromAvailableLabel = `${prefix}.selectFromAvailableLabel`;
export const enterCustomValueLabel = `${prefix}.enterCustomValueLabel`;
export const selectIpGroupPlaceholder = `${prefix}.selectIpGroupPlaceholder`;

export const ENGLISH = {
    [selectFromAvailableLabel]: 'Select From Available',
    [enterCustomValueLabel]: 'Enter Custom Value',
    [selectIpGroupPlaceholder]: 'Select IP Group',
};
