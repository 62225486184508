/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'network-and-subnet';
const prefix = `${moduleName}.${componentName}`;

export const networkInputLabel = `${prefix}.networkInputLabel`;
export const networkInputPlaceholder = `${prefix}.networkInputPlaceholder`;
export const subnetInputLabel = `${prefix}.subnetInputLabel`;

export const ENGLISH = {
    [networkInputLabel]: 'Network',
    [networkInputPlaceholder]: 'Select Network',
    [subnetInputLabel]: 'Subnet',
};
