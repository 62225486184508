/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

/**
 * @description Pipe to transform file size in B, KB, MB, or GB with 1 decimal place rounding.
 *
 * @author Rajawant Prajapati
 */
@Pipe({
    name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
    /**
     * Transforms file size in B, KB, MB, or GB with 1 decimal place rounding.
     */
    public transform(size: number): string {
        return this.formatBytes(size);
    }

    /**
     * Returns file size in B, KB, MB, or GB with 1 decimal place rounding.
     */
    private formatBytes(size: number): string {
        if (size === 0) {
            return '0';
        }

        if (!Math.floor(size / 1024)) {
            return `${size} B`;
        }

        size /= 1024;

        if (!Math.floor(size / 1024)) {
            return `${size.toFixed(1)} KB`;
        }

        size /= 1024;

        if (!Math.floor(size / 1024)) {
            return `${size.toFixed(1)} MB`;
        }

        size /= 1024;

        return `${size.toFixed(1)} GB`;
    }
}
