/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { BotClassificationTypes } from 'generated-types';
import {
    IBotDetectionLogClassification,
    IBotDetectionLogRow,
} from '../bot-detection-policy-logs/bot-detection-policy-logs.types';
import template from './bot-e2e-classification.component.html';

export const BOT_E2E_CLASSIFICATION_TOKEN = 'botE2eClassification';

const botSet = new Set([
    BotClassificationTypes.BAD_BOT,
    BotClassificationTypes.DANGEROUS_BOT,
    BotClassificationTypes.GOOD_BOT,
    BotClassificationTypes.USER_DEFINED_BOT,
]);

/**
 * @description Component for displaying the bot classification icon in the e2e timing diagram.
 * @author alextsg
 */
class BotE2eClassificationComponent {
    /**
     * botLog component binding.
     */
    public botLog: {
        classification: IBotDetectionLogClassification,
        results: IBotDetectionLogRow[],
    };

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (args: Record<string, string>) => void;

    /**
     * Calls parent function to handle user click to filter by clicked prop.
     */
    public handleUpdate(str: string): void {
        this.updateSearch({ str });
    }

    /**
     * Returns the classification type of the log.
     */
    public get classificationType(): IBotDetectionLogClassification['type'] {
        return this.botLog.classification.type;
    }

    /**
     * Returns true if the client is classified as a bot.
     */
    public get isBot(): boolean {
        return botSet.has(this.classificationType.val);
    }
}

BotE2eClassificationComponent.$inject = [
    'l10nService',
];

export const botE2eClassificationComponentOptions = {
    bindings: {
        botLog: '<',
        updateSearch: '&',
    },
    controller: BotE2eClassificationComponent,
    template,
};
