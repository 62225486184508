/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './EventSearch.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @restrict E
 * @name eventSearch
 * @param {string} value - Value we update though input field.
 * @param {Function} fire - ng-change/ng-enter/on-clear event handler.
 * @param {boolean} onChange - If defined ngChange event handler will be set.
 * @author Alex Malitsky
 * @description
 *
 *     On-change event is optional, debounced and switched off by default. Ng-enter and on-clear
 *     events are not debounced but do cancel ng-change timeout when it is set.
 *
 */
angular.module('aviApp').directive('eventSearch', ['$timeout', 'l10nService',
function($timeout, l10nService) {
    function link(scope, elem, attr) {
        let timer;

        scope.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);

        scope.callFire = function(immediate) {
            $timeout.cancel(timer);

            if (immediate) {
                scope.fire();
            } else {
                timer = $timeout(scope.fire, 500);
            }
        };

        scope.clearSearch = function() {
            scope.value = '';

            $timeout.cancel(timer);

            //not sure why do we need timeout here but it doesn't work without
            $timeout(function() {
                scope.callFire(true);
            });
        };

        scope.onChange = !_.isUndefined(attr['onChange']) ? scope.callFire : angular.noop;
    }

    return {
        restrict: 'E',
        scope: {
            value: '=',
            fire: '&',
        },
        templateUrl: 'src/views/components/event-search.html',
        link,
    };
}]);
