/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pki-profile-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleCaCertification = `${prefix}.columnTitleCaCertification`;
export const columnTitleCertificationRevocationList = `${prefix}.columnTitleCertificationRevocationList`;
export const columnTitleIssuedBy = `${prefix}.columnTitleIssuedBy`;
export const columnTitleExpirationDate = `${prefix}.columnTitleExpirationDate`;
export const caCertificationsHeader = `${prefix}.caCertificationsHeader`;
export const certificationRevocationListHeader = `${prefix}.certificationRevocationListHeader`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleCaCertification]: 'CA Certification',
    [columnTitleCertificationRevocationList]: 'Certificate Revocation List (CRL)',
    [columnTitleIssuedBy]: 'Issued By',
    [columnTitleExpirationDate]: 'Expiration Date',
    [caCertificationsHeader]: 'CA Certifications',
    [certificationRevocationListHeader]: 'Certificate Revocation List (CRL)',
};
