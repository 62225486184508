/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IBotTypeMatcher } from 'generated-types';
import { BotTypeMatcher } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * @description  BotTypeMatcher ConfigItem class.
 * @author Akul Aggarwal
 */
export class BotTypeMatcherConfigItem extends MessageItem<IBotTypeMatcher> {
    constructor(args = {}) {
        super({
            objectType: BotTypeMatcher,
            ...args,
        });
    }
}
