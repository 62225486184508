/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'tenant-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionInputPlaceholder = `${prefix}.descriptionInputPlaceholder`;
export const tenantAccessLabel = `${prefix}.tenantAccessLabel`;
export const tenantVrfLabel = `${prefix}.tenantVrfLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Tenant: {0}',
    [modalHeaderNew]: 'New Tenant: {0}',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [descriptionInputLabel]: 'Description',
    [descriptionInputPlaceholder]: 'Enter a description',
    [tenantAccessLabel]: 'Tenant Access to Provider Service Engine',
    [tenantVrfLabel]: 'Tenant VRF',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
};
