/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'analytics';
const componentName = 'timing-tiles';
const prefix = `${moduleName}.${componentName}`;

export const licenseUpgradeMessage = `${prefix}.licenseUpgradeMessage`;

export const ENGLISH = {
    [licenseUpgradeMessage]: 'Upgrade your license to use End-to-End timing.',
};
