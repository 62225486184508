/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';
import { ValueType } from 'ajs/modules/waf';
import './waf-policy-psm-rule-modal.less';
import * as l10n from './waf-policy-psm-rule-modal.l10n';

const WAF_MODE_NONE = 'WAF_MODE_NONE';
const ValueTypeOptions = {
    PATTERN: 'PATTERN',
    STRING_GROUP: 'STRING_GROUP',
};
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias waf/wafPolicyPsmRuleModal
 * @private
 */
class WafPolicyPsmRuleModalComponent {
    constructor(
        schemaService,
        dropDownUtils,
        Regex,
        StringGroupCollection,
        l10nService,
    ) {
        this.schemaService_ = schemaService;
        this.wafParanoiaLevelOptions = dropDownUtils.getEnumDropdownOptions('WafParanoiaLevel');
        this.Regex = Regex;
        this.stringGroupCollection = new StringGroupCollection();

        /**
         * Enum property reference.
         */
        this.ValueTypeOptions = ValueTypeOptions;

        /**
         * Used as the ngModel value for the Match Value radio buttons.
         */
        this.valueType = ValueTypeOptions.PATTERN;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        const wafModeNoneOption = {
            value: WAF_MODE_NONE,
            label: l10nService.getMessage(l10nKeys.wafModeOptionLabel),
            description: l10nService.getMessage(l10nKeys.wafModeOptionDescription),
        };

        this.modeOptions = [wafModeNoneOption].concat(this.schemaService_.getEnumValues('WafMode'));
        this.mode = this.editable.mode || WAF_MODE_NONE;

        if (this.editable.config.match_value_string_group_ref) {
            this.valueType = ValueTypeOptions.STRING_GROUP;
        }
    }

    /** @override */
    $onDestroy() {
        this.stringGroupCollection.destroy();
    }

    /**
     * Returns true if the rule is being edited, contrary to creation.
     * @returns {boolean}
     */
    isEditing() {
        return !isUndefined(this.editable.getIndex());
    }

    /**
     * Handler for changing the mode radio buttons.
     */
    handleModeChange() {
        if (this.mode === WAF_MODE_NONE) {
            this.editable.mode = undefined;
        } else {
            this.editable.mode = this.mode;
        }
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getEnableState() {
        return this.editable.enable ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable() {
        this.editable.enable = !this.editable.enable;
    }

    /**
     * Handler for the user clicking the close button.
     */
    handleCancel() {
        this.onCancel();
    }

    /**
     * Handler for the user clicking the submit button.
     */
    handleSubmit() {
        this.onSubmit();
    }

    /**
     * Called when the radio buttons for Value type has been toggled between Pattern or String
     * Group/Key.
     */
    handleValueTypeChange() {
        switch (this.valueType) {
            case ValueTypeOptions.PATTERN:
                this.editable.onMatchValueTypeChange(ValueType.PATTERN);
                break;

            case ValueTypeOptions.STRING_GROUP:
                this.editable.onMatchValueTypeChange(ValueType.STRING_GROUP);
                break;
        }
    }
}

WafPolicyPsmRuleModalComponent.$inject = [
    'schemaService',
    'dropDownUtils',
    'Regex',
    'StringGroupCollection',
    'l10nService',
];

/**
 * @ngdoc component
 * @name wafPolicyPsmRuleModal
 * @module waf/wafPolicyPsmRuleModal
 * @desc Component for editing a WAF PSM location argument rule.
 * @param {WafPolicyPSMRule} editable
 * @param {Function} onSubmit - Called when the user clicks the submit button.
 * @param {Function} onCancel - Called when the user closes the modal.
 * @param {Function} closeModal - Function to call to close the modal.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @param {string[]} breadcrumbs - Array of strings to be displayed as breadcrumbs.
 * @author alextsg
 */
angular.module('avi/waf').component('wafPolicyPsmRuleModal', {
    bindings: {
        editable: '<',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
        preventEdit: '<',
        breadcrumbs: '<',
    },
    controller: WafPolicyPsmRuleModalComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-rule-modal/' +
        'waf-policy-psm-rule-modal.component.html',
});
