/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    OnDestroy,
    OnInit,
    Type,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { DialogService } from 'ng/modules/core';
import { CportalService } from 'ajs/modules/cportal';
import { L10nService } from '@vmw/ngx-vip';
import { GenerateSsoTokenDialogComponent } from '../../generate-sso-token-dialog';
import * as l10n from './user-menu-additional-menu.l10n';
import './user-menu-additional-menu.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const GENERATE_SSO_TOKEN_DIALOG_ID = 'GENERATE_SSO_TOKEN_DIALOG_ID';

/**
 * @desc Component for the user menu additional menu.
 * @author alextsg
 */
@Component({
    selector: 'user-menu-additional-menu',
    templateUrl: './user-menu-additional-menu.component.html',
})
export class UserMenuAdditionalMenuComponent implements OnInit, OnDestroy {
    /**
     * Portal URL for the user to navigate to.
     */
    public aviPortalUrl = '';

    /**
     * Additional menu dropdown/tooltip position strategy.
     */
    public menuPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
        },
    ];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Subject to control additional menu.
     */
    private readonly additionalMenuControlSubject = new Subject<boolean>();

    constructor(
        private readonly cportalService: CportalService,
        private readonly dialogService: DialogService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setPortalUrl();
    }

    /**
     * Returns an observable to allow subscriptions to show and hide menu.
     */
    public get additionalMenuControl$(): Observable<boolean> {
        return this.additionalMenuControlSubject.asObservable();
    }

    /**
     * Opens the Generate SSO Token dialog.
     */
    public openGenerateTokenDialog(): void {
        this.closeMenu();

        this.dialogService.add({
            id: GENERATE_SSO_TOKEN_DIALOG_ID,
            component: GenerateSsoTokenDialogComponent as Type<Component>,
            componentProps: {
                onClose: () => {
                    this.dialogService.remove(GENERATE_SSO_TOKEN_DIALOG_ID);
                },
            },
        });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.closeMenu();

        if (this.dialogService.has(GENERATE_SSO_TOKEN_DIALOG_ID)) {
            this.dialogService.remove(GENERATE_SSO_TOKEN_DIALOG_ID);
        }
    }

    /**
     * Fetches the portal URL used as a hyperlink.
     */
    private async setPortalUrl(): Promise<void> {
        try {
            const portalInfo = await this.cportalService.getPortalInfo();

            this.aviPortalUrl = portalInfo.portal_url;
        } catch (e) {
            // empty catch block
        }
    }

    /**
     * Called to close the dropdown menu.
     */
    private closeMenu(): void {
        this.additionalMenuControlSubject.next(false);
    }
}
