/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ssl-bar-chart';
const prefix = `${moduleName}.${componentName}`;

export const symmetricEncryptionCipherStrengthNameLabel = `${prefix}.symmetricEncryptionCipherStrengthNameLabel`;
export const emptyValueLabel = `${prefix}.emptyValueLabel`;
export const pfsSupportNameLabel = `${prefix}.pfsSupportNameLabel`;
export const sslProtocolStrengthNameLabel = `${prefix}.sslProtocolStrengthNameLabel`;
export const weakestEncryptionAlgorithmNameLabel = `${prefix}.weakestEncryptionAlgorithmNameLabel`;
export const certificateExpirationTimeNameLabel = `${prefix}.certificateExpirationTimeNameLabel`;
export const signatureAlgorithmNameLabel = `${prefix}.signatureAlgorithmNameLabel`;
export const deactivateClientRenegotiationNameLabel = `${prefix}.deactivateClientRenegotiationNameLabel`;
export const trustedCaCertificateNameLabel = `${prefix}.trustedCaCertificateNameLabel`;
export const hstsEnabledNameLabel = `${prefix}.hstsEnabledNameLabel`;
export const sslScoreHeader = `${prefix}.sslScoreHeader`;
export const notApplicableLabel = `${prefix}.notApplicableLabel`;

export const ENGLISH = {
    [symmetricEncryptionCipherStrengthNameLabel]: 'Symmetric Encryption Cipher Strength',
    [emptyValueLabel]: 'N/A',
    [pfsSupportNameLabel]: 'PFS Support',
    [sslProtocolStrengthNameLabel]: 'SSL Protocol Strength',
    [weakestEncryptionAlgorithmNameLabel]: 'Weakest Encryption Algorithm',
    [certificateExpirationTimeNameLabel]: 'Certificate Expiration Time',
    [signatureAlgorithmNameLabel]: 'Signature Algorithm',
    [deactivateClientRenegotiationNameLabel]: 'Deactivate Client Renegotiation',
    [trustedCaCertificateNameLabel]: 'Trusted CA Certificate',
    [hstsEnabledNameLabel]: 'HSTS Enabled',
    [sslScoreHeader]: 'SSL Score',
    [notApplicableLabel]: 'Not Applicable',
};
