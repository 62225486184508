/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { LICENSE_SUBSCRIPTION_FAILED_ALERT_ID }
    from 'ng/modules/core/services/central-license-alerts/central-license-alerts.service';
import './licensing-page.component.less';
import { CENTRAL_LICENSE_ALERT_SERVICE_TOKEN } from '../../../../../downgrade-services.tokens';
import * as l10n from './licensing-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'licensing-page';
const SUCCESS = 'success';
const ENTERPRISE_WITH_CLOUD_SERVICES = 'ENTERPRISE_WITH_CLOUD_SERVICES';

/**
 * Module for licensing.
 * @module avi/licensing
 */

/**
 * Fires upon successful license upload.
 * @event module:avi/licensing#UPDATE_LICENSE_SUMMARY
 * @see module:avi/licensing#UPDATE_LICENSE_SUMMARY
 */

/**
 * Fires upon license tier change request.
 * @event module:avi/licensing#OPEN_LICENSE_TIER_CHANGE_MODAL
 * @see module:avi/licensing#OPEN_LICENSE_TIER_CHANGE_MODAL
 */

/**
 * @memberOf module:avi/licensing
 * @typedef {Object} ILicenseAddAlert
 * @property {string} type
 * @property {string} message
 */

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing.licensingPageComponentBindings
 * @see {@link module:avi/licensing.licensingPageComponent licensingPageComponent}
 */
class LicensingPageController {
    /**
     * Event name for license upload success.
     * @type {string}
     */
    static UPDATE_LICENSE_SUMMARY = 'UPDATE_LICENSE_SUMMARY';

    /**
     * Event name for license tier change modal open.
     * @type {string}
     */
    static OPEN_LICENSE_TIER_CHANGE_MODAL = 'OPEN_LICENSE_TIER_CHANGE_MODAL';

    /**
     * UI properties.
     * @type {Object}
     */
    ui = {
        busy: true,
        errors: null,
    };

    constructor(
        $scope,
        $element,
        $q,
        schemaService,
        dropDownUtils,
        licensingService,
        initialDataService,
        l10nService,
        centralLicenseAlertsService,
    ) {
        this.$scope = $scope;
        this.$q = $q;
        this.schemaService = schemaService;
        this.dropDownUtils = dropDownUtils;
        this.licensingService_ = licensingService;
        this.centralLicenseAlertsService_ = centralLicenseAlertsService;

        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);

        /**
         * Property to hold license add alert type and message.
         * @type {ILicenseAddAlert}
         */
        this.licenseAddAlert = null;

        /**
         * Add class to the component element.
         */
        $element.addClass(componentName);

        /**
         * Time difference between controller and client clock in seconds
         * @type {number}
         * @protected
         */
        this.controllerTimeDifference_ = initialDataService.controllerTimeDifference;

        this.setGridConfig_();
        this.loadGridData_();
        this.createLicenseTierDropdownOptions_();
    }

    /**
     * @override
     */
    $onInit() {
        this.handleTierChange();

        // Subscribe to license subscription status from reconcile api to
        // update license subscription status.
        this.licenseStatusSubscription = this.centralLicenseAlertsService_
            .licenseSubscriptionStatus$.subscribe(() => {
                this.loadLicenseStatus_();
            });
    }

    setGridConfig_() {
        const { l10nService_: l10nService } = this;
        const fields = [{
            name: 'license_name',
            title: l10nService.getMessage(l10nKeys.columnTitleDescription),
        }, {
            name: 'license_id',
            title: l10nService.getMessage(l10nKeys.columnTitleLicenseId),
        }, {
            name: 'tier',
            title: l10nService.getMessage(l10nKeys.columnTitleTier),
            template: '{{ row.tier_type || enum }}',
        }, {
            name: 'serviceCores',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceCores),
            template: '{{ row.service_cores || 0 }}',
        }, {
            name: 'cores',
            title: 'SE vCPUs',
            template: '{{ row.cores || 0 }}',
        }, {
            name: 'sockets',
            title: l10nService.getMessage(l10nKeys.columnTitleSeSockets),
            template: '{{ row.sockets || 0 }}',
        }, {
            name: 'valid_until',
            title: l10nService.getMessage(l10nKeys.columnTitleExpiry),
            transform: license => {
                const validUntil = this.validUntil_(license);

                if (validUntil.isBefore()) {
                    return l10nService.getMessage(l10nKeys.expiredLabel);
                }

                return validUntil.format('ll');
            },
        }];

        const multipleActions = [{
            title: l10nService.getMessage(l10nKeys.actionTitleDelete),
            do: rows => {
                this.ui.busy = true;
                this.resetErrors_();

                this.deleteLicenses(rows)
                    .then(() => {
                        this.reload_();
                    })
                    .catch(this.handleLoadError_.bind(this))
                    .finally(this.handleLoadFinally_.bind(this));

                return true;
            },
            disabled: rows => {
                let disabled = this.ui.busy;

                if (!disabled) {
                    disabled = rows.length === 1 && rows[0].license_id === 'Trial';
                }

                return disabled;
            },
        }];

        /**
         * Configures grid directive
         * @type {object}
         */
        this.gridConfig = {
            id: componentName,
            fields,
            rowId: 'license_id',
            layout: {
                hideSearch: true,
            },
            searchFields: ['license_name', 'license_id', 'license_type'],
            rowClass: license => {
                return this.validUntil_(license).isBefore() ? 'disabled' : '';
            },
            checkboxDisable: row => row.license_id === 'Trial',
            multipleactions: multipleActions,
        };
    }

    createLicenseTierDropdownOptions_() {
        const enumVals = this.schemaService.getEnumValues('LicenseTierType');

        this.licenseTierDropdownOptions = enumVals.map(({ value, label, description }) => {
            return this.dropDownUtils.createOption(value, label, description);
        });
    }

    /**
     * Updates page's tables.
     * @fires module:avi/licensing#UPDATE_LICENSE_SUMMARY
     * @protected
     */
    reload_ = () => {
        this.resetErrors_();
        this.loadGridData_();
        this.$scope.$broadcast(LicensingPageController.UPDATE_LICENSE_SUMMARY);
    };

    /**
     * Fires after a license is uploaded.
     */
    reloadLicenseGrid() {
        this.reload_();
    }

    /**
     * Updates data for bottom License table.
     * @return {Promise<any>}
     * @protected
     */
    loadGridData_() {
        this.ui.busy = true;

        return this.licensingService_.loadLicenseList()
            .then(licenseList => this.data = licenseList)
            .catch(this.handleLoadError_.bind(this))
            .finally(this.handleLoadFinally_.bind(this));
    }

    /**
     * Updates SAAS license status icon and tooltip,
     * license subscription status in centralLicenseAlertService and
     * call method to show/hide license subscription failed alert.
     * @return {Promise<any>}
     * @protected
     */
    loadLicenseStatus_() {
        this.ui.busy = true;

        return this.licensingService_.loadLicenseStatus()
            .then(licenseStatus => {
                this.licenseStatus = licenseStatus;

                const { saas_status: saasStatus } = this.licenseStatus;

                this.centralLicenseAlertsService_.saasStatus = saasStatus;
                this.centralLicenseAlertsService_.refreshLicenseSubscriptionFailAlert();
            })
            .catch(this.handleLoadError_.bind(this))
            .finally(this.handleLoadFinally_.bind(this));
    }

    /**
     * Called on license tier change.
     * @public
     */
    handleTierChange() {
        if (this.isEnterpriseWithCloudServicesTier()) {
            this.loadLicenseStatus_();
        } else {
            this.centralLicenseAlertsService_.removeAlert(LICENSE_SUBSCRIPTION_FAILED_ALERT_ID);
        }
    }

    /**
     * @param {Object} rsp
     * @protected
     */
    handleLoadError_(rsp) {
        this.ui.errors = rsp.data;
    }

    /**
     * @protected
     */
    handleLoadFinally_() {
        this.ui.busy = false;
    }

    /**
     * @protected
     */
    resetErrors_() {
        this.ui.errors = null;
    }

    /**
     * Returns date of license expiration.
     * @param  {License} license - license item.
     * @return {Moment} Moment object.
     */
    validUntil_(license) {
        return moment(license.valid_until).add(this.controllerTimeDifference_, 'seconds');
    }

    /**
     * Returns start date.
     * @param  {License} license - license item.
     * @return {Moment} Moment object.
     */
    startOn_(license) {
        return moment(license.start_on).add(this.controllerTimeDifference_, 'seconds');
    }

    /**
     * Returns true if ENTERPRISE_WITH_CLOUD_SERVICES tier is selected.
     * @return boolean.
     */
    isEnterpriseWithCloudServicesTier() {
        return this.systemConfig.defaultLicenseTier === ENTERPRISE_WITH_CLOUD_SERVICES;
    }

    /**
     * Deletes passed license(s).
     * @param {Object[]} rows
     * @returns {ng.$q.promise} To be resolved with list of Response objects.
     */
    deleteLicenses(rows) {
        const deletePromises =
            rows.map(({ license_id: licenseId }) =>
                this.licensingService_.deleteLicense(licenseId));

        return this.$q.all(deletePromises);
    }

    /**
     * Fires event to open license tier change modal.
     * @fires module:avi/licensing#OPEN_LICENSE_TIER_CHANGE_MODAL
     */
    openTierChangeModal() {
        this.$scope.$broadcast(LicensingPageController.OPEN_LICENSE_TIER_CHANGE_MODAL);
    }

    /**
     * Called after request for adding a new license is completed.
     * Used to reload license grid if license add request is completed successfully.
     * @param {Object} licenseAddAlert - License add request's success/error message and alert type.
     */
    handleLicenseAdd(licenseAddAlert) {
        this.licenseAddAlert = licenseAddAlert;

        if (licenseAddAlert.type === SUCCESS) {
            this.reloadLicenseGrid();
        }
    }

    /**
     * Function to clear success/error message on closing alert.
     */
    resetAlert() {
        this.licenseAddAlert.message = undefined;
    }

    /** @override */
    $onDestroy() {
        this.licenseStatusSubscription.unsubscribe();
    }

    /**
     * Returns Org Service Units.
     * Org Service Units holds available_service_units and used_service_units.
     * @return {IOrgServiceUnits | undefined}
     */
    get orgServiceUnits() {
        return this.licenseStatus?.service_update?.service_units;
    }
}

LicensingPageController.$inject = [
    '$scope',
    '$element',
    '$q',
    'schemaService',
    'dropDownUtils',
    'licensingService',
    'initialDataService',
    'l10nService',
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
];

const templateUrl = 'src/components/pages/administration/settings/' +
    `${componentName}/${componentName}.component.html`;

/**
 * @mixin licensingPageComponentBindings
 * @memberOf module:avi/licensing
 * @property {SystemConfig} systemConfig
 */
const bindings = {
    systemConfig: '<loadedSystemConfigService',
};

/**
 * @name licensingPageComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing.licensingPageComponentBindings} bindings
 * @property {module:avi/licensing.LicensingPageController} controller
 * @description System>Settings>Licensing page component.
 * @author Akul Aggarwal, Aravindh Nagarajan
 */
angular.module('avi/licensing').component('licensingPage', {
    bindings,
    controller: LicensingPageController,
    templateUrl,
});

export { LicensingPageController };
