/**
 * Module containing Common UI components and Core services.
 * @preferred
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ClrCheckboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrIconModule,
    ClrInputModule,
    ClrProgressBarModule,
    ClrRadioModule,
    ClrSpinnerModule,
} from '@clr/angular';
import { VIPModule } from '@vmw/ngx-vip';

import {
    AsyncFileUploadComponent,
    AsyncFileUploadWithUploadServiceComponent,
    AviAppLevelAlertComponent,
    AviAutoCompleteComponent,
    AviCardComponent,
    AviCheckboxContainerGroupComponent,
    AviCollectionDropdownComponent,
    AviCollectionDropdownSelectedValuesContainerComponent,
    AviCustomCollectionDropdownComponent,
    AviDataGridBaseComponent,
    AviDataGridColumnSelectionComponent,
    AviDataGridComponent,
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviDropdownMenuComponent,
    AviDropdownOptionComponent,
    AviDropdownOptionsComponent,
    AviDropdownSelectedValuesContainerComponent,
    AviDropdownSingleSelectedValueComponent,
    AviIconDirective,
    AviInputContainerComponent,
    AviInputContainerErrorComponent,
    AviLabelWithTooltipComponent,
    AviPageHeaderComponent,
    AviRepeatedStringsComponent,
    AviStatePageComponent,
    AviTextareaContainerComponent,
    AviTooltipContentComponent,
    AviTooltipDirective,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    BackendErrorMsgPipe,
    BooleanLabelPipe,
    ButtonLoaderComponent,
    CopyrightComponent,
    CustomParamsGridComponent,
    DynamicParamsGridComponent,
    ExpanderActionConfirmationComponent,
    ExpanderActionRowComponent,
    ExpanderButtonComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FileUploadComponent,
    FileUploadTextareaComponent,
    FormatBytesPipe,
    FullModalBreadcrumbComponent,
    FullModalBreadcrumbsComponent,
    FullModalComponent,
    FullModalConfigComponent,
    FullModalConfigErrorsComponent,
    FullModalConfigFooterComponent,
    FullModalConfigHeaderComponent,
    FullModalConfigHeaderTabsComponent,
    FullModalPreviewComponent,
    FullModalTabSectionComponent,
    GradientLoaderComponent,
    HealthScoreClassNamePipe,
    HelperTextTooltipIconComponent,
    InputFieldDirective,
    ItemPreviewDefaultsComponent,
    ItemPreviewWrapperComponent,
    PreviewFieldComponent,
    ProgressBarDirective,
    TransitionProgressBarComponent,
    TriSwitchComponent,
} from '.';

const sharedComponents = [
    AsyncFileUploadComponent,
    AsyncFileUploadWithUploadServiceComponent,
    AviAppLevelAlertComponent,
    AviCardComponent,
    AviCheckboxContainerGroupComponent,
    AviCollectionDropdownComponent,
    AviCollectionDropdownSelectedValuesContainerComponent,
    AviCustomCollectionDropdownComponent,
    AviPageHeaderComponent,
    AviDataGridBaseComponent,
    AviDataGridColumnSelectionComponent,
    AviDataGridComponent,
    AviDropdownButtonComponent,
    AviDropdownComponent,
    AviDropdownMenuComponent,
    AviDropdownOptionComponent,
    AviDropdownOptionsComponent,
    AviDropdownSelectedValuesContainerComponent,
    AviDropdownSingleSelectedValueComponent,
    AviInputContainerComponent,
    AviInputContainerErrorComponent,
    AviLabelWithTooltipComponent,
    AviRepeatedStringsComponent,
    AviStatePageComponent,
    AviTextareaContainerComponent,
    AviTooltipContentComponent,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    AviAutoCompleteComponent,
    BackendErrorMsgPipe,
    BooleanLabelPipe,
    ButtonLoaderComponent,
    CopyrightComponent,
    CustomParamsGridComponent,
    DynamicParamsGridComponent,
    ExpanderActionConfirmationComponent,
    ExpanderActionRowComponent,
    ExpanderButtonComponent,
    ExpanderComponent,
    ExpanderWithActionsComponent,
    FileUploadComponent,
    FileUploadTextareaComponent,
    FormatBytesPipe,
    FullModalBreadcrumbComponent,
    FullModalBreadcrumbsComponent,
    FullModalComponent,
    FullModalConfigComponent,
    FullModalConfigErrorsComponent,
    FullModalConfigFooterComponent,
    FullModalConfigHeaderComponent,
    FullModalConfigHeaderTabsComponent,
    FullModalPreviewComponent,
    PreviewFieldComponent,
    ItemPreviewDefaultsComponent,
    ItemPreviewWrapperComponent,
    FullModalTabSectionComponent,
    GradientLoaderComponent,
    HealthScoreClassNamePipe,
    HelperTextTooltipIconComponent,
    TriSwitchComponent,
];

const sharedDirectives = [
    AviIconDirective,
    AviTooltipDirective,
    InputFieldDirective,
    ProgressBarDirective,
    TransitionProgressBarComponent,
];

@NgModule({
    declarations: [
        ...sharedComponents,
        ...sharedDirectives,
    ],
    exports: [
        AsyncFileUploadComponent,
        AsyncFileUploadWithUploadServiceComponent,
        AviCardComponent,
        AviCheckboxContainerGroupComponent,
        AviCollectionDropdownComponent,
        AviCustomCollectionDropdownComponent,
        AviDataGridBaseComponent,
        AviPageHeaderComponent,
        AviDataGridComponent,
        AviDropdownButtonComponent,
        AviDropdownComponent,
        AviIconDirective,
        AviInputContainerComponent,
        AviLabelWithTooltipComponent,
        AviRepeatedStringsComponent,
        AviStatePageComponent,
        AviTextareaContainerComponent,
        AviTooltipContentComponent,
        AviTooltipDirective,
        AviTooltipIconComponent,
        AviTooltipIconWithContentWrapperComponent,
        BackendErrorMsgPipe,
        BooleanLabelPipe,
        FormatBytesPipe,
        ButtonLoaderComponent,
        CopyrightComponent,
        CustomParamsGridComponent,
        DynamicParamsGridComponent,
        ExpanderComponent,
        ExpanderWithActionsComponent,
        FileUploadComponent,
        FileUploadTextareaComponent,
        FullModalComponent,
        FullModalConfigComponent,
        FullModalPreviewComponent,
        FullModalTabSectionComponent,
        GradientLoaderComponent,
        HealthScoreClassNamePipe,
        HelperTextTooltipIconComponent,
        InputFieldDirective,
        ProgressBarDirective,
        TransitionProgressBarComponent,
        TriSwitchComponent,
        VIPModule,
        PreviewFieldComponent,
        ItemPreviewDefaultsComponent,
        ItemPreviewWrapperComponent,
        AviAutoCompleteComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrDatagridModule,
        ClrDropdownModule,
        ClrIconModule,
        ClrInputModule,
        ClrProgressBarModule,
        ClrRadioModule,
        ClrSpinnerModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        OverlayModule,
        ScrollingModule,
        VIPModule,
    ],
    providers: [
        BooleanLabelPipe,
        FormatBytesPipe,
        BackendErrorMsgPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
