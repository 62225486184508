/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Used for downgrading collections used in Cloud Module
 * @module CloudModule
 */

import { Injector } from '@angular/core';

import {
    SEGroupCollection,
    SE_GROUP_COLLECTION_TOKEN,
} from 'ajs/modules/service-engine-group';

import { LicenseBasedFeaturesService } from 'ajs/modules/core/services';
import { LICENSE_BASED_FEATURES_SERVICE_TOKEN } from 'ajs/modules/core/core.tokens';

const $injector = '$injector';

export const seGroupCollectionProvider = {
    deps: [$injector],
    provide: SEGroupCollection,
    useFactory(injector: Injector): typeof SEGroupCollection {
        return injector.get(SE_GROUP_COLLECTION_TOKEN);
    },
};

export const licenseBasedFeaturesServiceProvider = {
    deps: [$injector],
    provide: LicenseBasedFeaturesService,
    useFactory(injector: Injector): LicenseBasedFeaturesService {
        return injector.get(LICENSE_BASED_FEATURES_SERVICE_TOKEN);
    },
};
