/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-custom-collection-dropdown';
const prefix = `${moduleName}.${componentName}`;

export const selectFromAvailableLabel = `${prefix}.selectFromAvailableLabel`;
export const enterCustomValueLabel = `${prefix}.enterCustomValueLabel`;
export const collectionDropdownDefaultPlaceholder = `${prefix}.collectionDropdownDefaultPlaceholder`;
export const customInputPlaceholder = `${prefix}.customInputPlaceholder`;

export const ENGLISH = {
    [selectFromAvailableLabel]: 'Select From Available',
    [enterCustomValueLabel]: 'Enter Custom Value',
    [collectionDropdownDefaultPlaceholder]: 'Select',
    [customInputPlaceholder]: 'Enter Value',
};
