/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'safenet-client-info-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleClientIp = `${prefix}.columnTitleClientIp`;
export const clientIpPlaceholder = `${prefix}.clientIpPlaceholder`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleClientIp]: 'Client IP',
    [clientIpPlaceholder]: 'Enter Client IP',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
};
