/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import './avi-card.component.less';

@Component({
    selector: 'avi-card',
    templateUrl: './avi-card.component.html',
})
export class AviCardComponent {
    /**
     * Reference to the div containing an optional transcluded avi-card_footer element.
     */
    @ViewChild('cardFooterContainerContent') private cardFooterContainer: ElementRef;

    /**
     * Returns true if the avi-card_footer transcluded element exists.
     */
    public get hasTranscludedcardFooterContainer(): boolean {
        return this.cardFooterContainer?.nativeElement.children.length > 0;
    }
}
