/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'sensitive-log-profile';
const prefix = `${moduleName}.${componentName}`;

export const indexColumnTitle = `${prefix}.indexColumnTitle`;
export const enableColumnTitle = `${prefix}.enableColumnTitle`;
export const nameColumnTitle = `${prefix}.nameColumnTitle`;
export const matchingRuleColumnTitle = `${prefix}.matchingRuleColumnTitle`;
export const actionColumnTitle = `${prefix}.actionColumnTitle`;
export const createRuleControlTitle = `${prefix}.createRuleControlTitle`;
export const deleteActionTitle = `${prefix}.deleteActionTitle`;
export const menuActionTitle = `${prefix}.menuActionTitle`;

export const ENGLISH = {
    [indexColumnTitle]: 'Index',
    [enableColumnTitle]: 'Enable',
    [nameColumnTitle]: 'Name',
    [matchingRuleColumnTitle]: 'Matching Rule',
    [actionColumnTitle]: 'Action',
    [createRuleControlTitle]: 'Create rule',
    [deleteActionTitle]: 'Delete',
    [menuActionTitle]: 'Menu',
};
