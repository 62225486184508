/**
 * @module MatchModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    AuthAttributeMatchConfigItem,
    BotClassMatcherConfigItem,
    BotTypeMatcherConfigItem,
    CookieMatchConfigItem,
    GenericHdrMatchBaseConfigItem,
    HdrMatchConfigItem,
    HostHdrMatchConfigItem,
    HTTPVersionMatchConfigItem,
    IpAddrMatchConfigItem,
    IPReputationTypeMatchConfigItem,
    IP_ADDR_MATCH_CONFIG_ITEM_TOKEN,
    IP_REPUTATION_TYPE_MATCH_CONFIG_ITEM_TOKEN,
    JwtClaimMatchConfigItem,
    JwtMatchConfigItem,
    MethodMatchConfigItem,
    PathMatchConfigItem,
    PathOrQueryMatchBaseConfigItem,
    PortMatchConfigItem,
    ProtocolMatchConfigItem,
    PROTOCOL_MATCH_CONFIG_ITEM_TOKEN,
    QueryMatchConfigItem,
    StringMatchConfigItem,
} from '.';

import {
    AUTH_ATTRIBUTE_MATCH_CONFIG_ITEM_TOKEN,
    BOT_CLASS_MATCHER_CONFIG_ITEM_TOKEN,
    BOT_TYPE_MATCHER_CONFIG_ITEM_TOKEN,
    COOKIE_MATCH_CONFIG_ITEM_TOKEN,
    GENERIC_HDR_MATCH_BASE_CONFIG_ITEM_TOKEN,
    HDR_MATCH_CONFIG_ITEM_TOKEN,
    HOST_HDR_MATCH_CONFIG_ITEM_TOKEN,
    HTTP_VERSION_MATCH_CONFIG_ITEM_TOKEN,
    JWT_CLAIM_MATCH_CONFIG_ITEM_TOKEN,
    JWT_MATCH_CONFIG_ITEM_TOKEN,
    METHOD_MATCH_CONFIG_ITEM_TOKEN,
    PATH_MATCH_CONFIG_ITEM_TOKEN,
    PATH_OR_QUERY_MATCH_BASE_CONFIG_ITEM_TOKEN,
    PORT_MATCH_CONFIG_ITEM_TOKEN,
    QUERY_MATCH_CONFIG_ITEM_TOKEN,
    STRING_MATCH_CONFIG_ITEM_TOKEN,
} from './match.tokens';

const matchModule = angular.module('avi/match');

const factories = [
    {
        name: BOT_CLASS_MATCHER_CONFIG_ITEM_TOKEN,
        factory: BotClassMatcherConfigItem,
    },
    {
        name: BOT_TYPE_MATCHER_CONFIG_ITEM_TOKEN,
        factory: BotTypeMatcherConfigItem,
    },
    {
        name: IP_ADDR_MATCH_CONFIG_ITEM_TOKEN,
        factory: IpAddrMatchConfigItem,
    },
    {
        name: HOST_HDR_MATCH_CONFIG_ITEM_TOKEN,
        factory: HostHdrMatchConfigItem,
    },
    {
        name: HDR_MATCH_CONFIG_ITEM_TOKEN,
        factory: HdrMatchConfigItem,
    },
    {
        name: PROTOCOL_MATCH_CONFIG_ITEM_TOKEN,
        factory: ProtocolMatchConfigItem,
    },
    {
        name: IP_REPUTATION_TYPE_MATCH_CONFIG_ITEM_TOKEN,
        factory: IPReputationTypeMatchConfigItem,
    },
    {
        name: PATH_OR_QUERY_MATCH_BASE_CONFIG_ITEM_TOKEN,
        factory: PathOrQueryMatchBaseConfigItem,
    },
    {
        name: PATH_MATCH_CONFIG_ITEM_TOKEN,
        factory: PathMatchConfigItem,
    },
    {
        name: QUERY_MATCH_CONFIG_ITEM_TOKEN,
        factory: QueryMatchConfigItem,
    },
    {
        name: METHOD_MATCH_CONFIG_ITEM_TOKEN,
        factory: MethodMatchConfigItem,
    },
    {
        name: HTTP_VERSION_MATCH_CONFIG_ITEM_TOKEN,
        factory: HTTPVersionMatchConfigItem,
    },
    {
        name: PORT_MATCH_CONFIG_ITEM_TOKEN,
        factory: PortMatchConfigItem,
    },
    {
        name: GENERIC_HDR_MATCH_BASE_CONFIG_ITEM_TOKEN,
        factory: GenericHdrMatchBaseConfigItem,
    },
    {
        name: COOKIE_MATCH_CONFIG_ITEM_TOKEN,
        factory: CookieMatchConfigItem,
    },
    {
        name: STRING_MATCH_CONFIG_ITEM_TOKEN,
        factory: StringMatchConfigItem,
    },
    {
        name: AUTH_ATTRIBUTE_MATCH_CONFIG_ITEM_TOKEN,
        factory: AuthAttributeMatchConfigItem,
    },
    {
        name: JWT_MATCH_CONFIG_ITEM_TOKEN,
        factory: JwtMatchConfigItem,
    },
    {
        name: STRING_MATCH_CONFIG_ITEM_TOKEN,
        factory: StringMatchConfigItem,
    },
    {
        name: JWT_CLAIM_MATCH_CONFIG_ITEM_TOKEN,
        factory: JwtClaimMatchConfigItem,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        matchModule,
        'factory',
        name,
        factory,
    );
});
