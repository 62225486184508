/** @module PoolModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    copy,
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    compact,
    findWhere,
    isArray,
    isEmpty,
    isUndefined,
    some,
    uniq,
} from 'underscore';

import { UpdatableItem } from 'ajs/modules/data-model/factories';
import { StringService } from 'ajs/modules/core/services/string-service';
import {
    FailActionEnum,
    IMetricsQuery,
    IPool,
    IServer,
} from 'generated-types';

interface IExtendedServer extends IServer {
    uuid?: string;
    default_server_port?: number;
}

interface IPoolConfig extends IPool {
    ab_pool?: any;
    servers: IExtendedServer[];
}

interface IPoolData {
    config: IPoolConfig;
    virtualservices: string[];
    pool_group_refs: string[];
}

interface IDetailsPageStateParams {
    id?: string | null;
    poolId?: string,
    vsId?: string,
}

interface IAutoscaleGroupsApiResponse {
    group_name: string,
    name: string,
}

export interface ICloudVrfContext {
    vrf_ref: string,
    cloud_ref: string,
}

/**
 * Ajs dependency token for Pool.
 */
export const POOL_ITEM_TOKEN = 'Pool';

/**
 * @description Pool item.
 * @author Nisar Nadaf
 */
export class Pool extends UpdatableItem {
    public apiResponseCache: any;
    public getConfig: () => IPoolConfig;
    public data: IPoolData;
    protected readonly stringService: StringService;
    private vsId: string;

    /**
     * VirtualService item. TODO: change "any" to the VirtualService class once
     * it's been converted to ts
     */
    private vs: any;

    /**
     * Server item. TODO: change "any" to the Server class once it's been converted to ts
     */
    private Server: any;

    /**
     * TODO: change any to PoolFailActionService class once it's been converted to ts
     */
    private poolFailActionService: any;

    constructor(args: any = {}) {
        super(args);

        this.apiResponseCache = {
            autoscaleGroupServers: {},
        };

        const {
            vs,
            vsId,
        } = args;

        if (vs) {
            this.vs = vs;
            this.vsId = vs.id;
        } else if (vsId) {
            this.vsId = vsId;
        }

        this.Server = this.getAjsDependency_('Server');
        this.poolFailActionService = this.getAjsDependency_('poolFailActionService');
        this.stringService = this.getAjsDependency_('stringService');
    }

    /** @override */
    public destroy(): boolean {
        const gotDestroyed = super.destroy();

        if (gotDestroyed) {
            this.vs = null;
            this.apiResponseCache = null;
        }

        return gotDestroyed;
    }

    /**
     * Returns the cloud_ref configured on the PoolGroup.
     */
    public getCloudRef(): string {
        const { cloud_ref: cloudRef } = this.getConfig() || {};

        return cloudRef;
    }

    /**
     * Returns the VRF context ref configured for this pool.
     */
    public getVRFContextRef(): string {
        const { vrf_ref: vrfRef } = this.getConfig();

        return vrfRef || '';
    }

    /** @override */
    public getMetricsTuple(): IMetricsQuery {
        return {
            entity_uuid: '*',
            aggregate_entity: true,
            pool_uuid: this.id,
        };
    }

    /**
     * Returns Pool's config default_server_port property.
     */
    public getDefaultServerPort(): number | undefined {
        const { default_server_port: defaultServerPort } = this.getConfig() || {};

        return defaultServerPort || undefined;
    }

    public removeServerDuplicates(data: IPool): void {
        data.servers = uniq(data.servers, false, this.Server.getServerUuid);
    }

    public dataToSave(): IPoolConfig {
        const config = copy(this.getConfig());

        this.removeServerDuplicates(config);

        const networksHash = {};
        const networks: string[] = [];

        config.servers.forEach((server: IExtendedServer) => {
            if (server.port === null) {
                delete server.port;
            }

            const netId = server.nw_ref;

            if (netId && !(netId in networksHash)) {
                networks.push(netId);
                networksHash[netId] = true;
            }

            // for servers provided by ServerCollection
            delete server.uuid;
            delete server.default_server_port;
        });

        // vCenter only when servers added by network
        if (networks.length) {
            config.networks = networks.map((netId: string) => ({ network_ref: netId }));
        } else {
            delete config.networks;
        }

        if (!config.servers.length) {
            delete config.servers;
        }

        // Remove falsy values (null/'') from health_monitor_refs
        const { health_monitor_refs: monitorList } = config;

        config.health_monitor_refs = compact(monitorList);

        config.fail_action = this.poolFailActionService.dataToSave(config.fail_action);

        if (config.max_concurrent_connections_per_server === null) {
            delete config.max_concurrent_connections_per_server;
        }

        if (config.ab_pool && !config.ab_pool.pool_ref) {
            delete config.ab_pool;
        }

        const { markers } = config;

        if (markers) {
            // filter out RBAC entries with an empty key
            config.markers = markers.filter(({ key }) => key);

            // delete empty RABC label list
            if (markers.length === 0) {
                delete config.markers;
            }
        }

        return config;
    }

    /**
     * Is used by JS DeepDiff.diff function as a prefilter for editable objects comparison at
     * the modal windows. By default filters out AngularJS $$hashKey property only.
     */
    public modifiedDiffFilter(path: string[], key: string): boolean {
        const fullPath = path.join('/');

        let res;

        // default check for $$hashKey
        res = super.modifiedDiffFilter(path, key);

        // specific for the Pool modals: servers:[{index:}]
        if (!res) {
            res = key === 'index' && !fullPath;
        }

        return res;
    }

    /**
     * Returns true if pool is enabled.
     */
    public isEnabled(): boolean {
        return this.getConfig().enabled;
    }

    /**
     * If the Pool state doesn't match the passed in state, toggle it and save the Pool.
     */
    public setEnabledState(enabled = true): IPromise<IHttpResponse<void>> {
        const promise = new Promise<IHttpResponse<void>>(resolve => {
            if (this.isEnabled() !== enabled) {
                resolve(this.patch({ replace: { enabled } }));
            }
        });

        return promise;
    }

    /**
     * Makes a request to get addresses for a security group.
     */
    public getSecurityGroupAddresses(): IPromise<any> {
        const { nsx_securitygroup: nsxSecurityGroup } = this.getConfig();

        if (!isArray(nsxSecurityGroup) || !nsxSecurityGroup.length) {
            return Promise.resolve([]);
        }

        const cloudId = this.stringService.slug(this.getCloudRef());
        const sg = nsxSecurityGroup[0];
        const api = `/api/nsx/securitygroup/ips/?cloud_uuid=${cloudId}&securitygroup=${sg}`;

        this.errors = null;

        return this.request('GET', api)
            .then(({ data: ipList }) => {
                return Array.isArray(ipList) ? ipList.map(({ sg_ip: ip }) => ({ ip })) : [];
            })
            .catch(({ data }) => {
                this.errors = data;

                return [];
            });
    }

    /**
     * Returns a list of server AutoScale groups.
     */
    public getAutoscaleGroups(): IPromise<IAutoscaleGroupsApiResponse> {
        const { cloud_ref: cloudRef } = this.getConfig();
        const cloudId = this.stringService.slug(cloudRef);

        this.busy = true;
        this.errors = null;

        return this.request('GET', `/api/cloud/${cloudId}/autoscalegroup`)
            .then(({ data }) => data.results)
            .catch(() => [])
            .finally(() => this.busy = false);
    }

    /**
     * Returns a list of servers belonging to an AutoScale group.
     */
    public getAutoscaleGroupServers(): IPromise<IServer[]> {
        const {
            cloud_ref: cloudRef,
            external_autoscale_groups: externalAutoscaleGroups,
        } = this.getConfig();
        const cloudId = this.stringService.slug(cloudRef);
        const names = externalAutoscaleGroups || [];

        const promises = names.map((name: string) => {
            if (name in this.apiResponseCache.autoscaleGroupServers) {
                return Promise.resolve(this.apiResponseCache.autoscaleGroupServers[name]);
            } else {
                return this.request(
                    'GET',
                    `/api/cloud/${cloudId}/autoscalegroup/${name}/servers`,
                ).then(({ data }) => {
                    const servers = data?.results || [];

                    this.apiResponseCache.autoscaleGroupServers[name] = servers;

                    return servers;
                }).catch(({ data }) => {
                    this.errors = data;

                    return [];
                });
            }
        });

        this.busy = true;
        this.errors = null;

        return Promise.all(promises)
            .then((responses: IServer[][]) => {
                return responses.reduce((acc: IServer[], servers: IServer[]) => {
                    acc.push(...servers);

                    return acc;
                }, []);
            })
            .finally(() => this.busy = false);
    }

    /**
     * Back-end can populate servers list by referenced object. Here we check whether this is a
     * case.
     */
    public autoPopulatedServers(): boolean {
        const {
            ipaddrgroup_ref: ipAddrGroupRef,
            external_autoscale_groups: externalAutoscaleGroups,
        } = this.getConfig();

        return Boolean(ipAddrGroupRef || !isEmpty(externalAutoscaleGroups));
    }

    /**
     * Checks whether Pool is using NSX security group for servers placement.
     */
    public hasNSXSecurityGroup(): boolean {
        const { nsx_securitygroup: nsxSecurityGroup } = this.getConfig();

        return Boolean(nsxSecurityGroup && nsxSecurityGroup[0]);
    }

    /**
     * Returns true if Pool has external AutoScale groups configured.
     */
    public hasAutoscaleGroups(): boolean {
        const { external_autoscale_groups: externalAutoscaleGroups } = this.getConfig();

        return !isEmpty(externalAutoscaleGroups);
    }

    /**
     * @override
     */
    public submit(): IPromise<void> {
        return super.submit()
            .then(() => {
                this.apiResponseCache = { autoscaleGroupServers: {} };
            });
    }

    /**
     * @override
     */
    public dismiss(args: any): void {
        super.dismiss(args);
        this.apiResponseCache = { autoscaleGroupServers: {} };
    }

    /**
     * Returns vs id this pool is used by. For pool details pages it is vsId from $stateParams,
     * otherwise first from the list provided by inventory API.
     */
    public getVSId(): string {
        const { vsId, vs } = this;

        if (vsId) {
            return vsId;
        }

        if (vs) {
            return vs.id;
        }

        const vsRefs = this.getVSRefs();

        return vsRefs.length ? this.stringService.slug(vsRefs[0]) : '';
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public getDetailsPageStateParams_(): IDetailsPageStateParams {
        return {
            poolId: this.id,
            vsId: this.getVSId(),
        };
    }

    /**
     * Checks whether there is at least one Server having VM id within this Pool.
     */
    public hasServerWithVMId(): boolean {
        const { servers } = this.getConfig();

        return some(servers, this.Server.getServerVMId);
    }

    /**
     * Returns the list of VS refs this pool is used by. Provided by inventory API, hence
     * present only on Pools belonging to collection.
     */
    public getVSRefs(): string[] {
        const { virtualservices: list } = this.data || {};

        return list ? list.concat() : [];
    }

    /** @override */
    public isEditable(): boolean {
        const { gslb_sp_enabled: gslbSpEnabled } = this.getConfig();

        return !gslbSpEnabled && super.isEditable();
    }

    /** @override */
    public isProtected(): boolean {
        const { gslb_sp_enabled: gslbSpEnabled } = this.getConfig() || {};

        return gslbSpEnabled || super.isProtected();
    }

    /** @override */
    public hasCustomTimeFrameSettings(): boolean {
        const { vs } = this;

        return !this.collection && vs && vs.hasCustomTimeFrameSettings() || false;
    }

    /** @override */
    public getCustomTimeFrameSettings(tfLabel: string): { step: number, limit: number} | null {
        if (this.hasCustomTimeFrameSettings()) {
            return this.vs.getCustomTimeFrameSettings(tfLabel);
        }

        return null;
    }

    /**
     * Returns nested server config by its id.
     */
    public getServerConfigById(serverId: string): IExtendedServer {
        const { servers } = this.getConfig();

        const serverConfig = findWhere(servers, { uuid: serverId });

        if (serverConfig) {
            return copy(serverConfig);
        }

        return null;
    }

    /**
     * Returns a list of pool group names.
     */
    public getPoolGroupNames(): string[] {
        // pool_group_refs comes from inventory API
        const { pool_group_refs: refs } = this.data;

        return refs ? refs.map(ref => ref.name()) : [];
    }

    /**
     * Updates pool fail action config based on type provided.
     */
    public onFailActionTypeChange(type: FailActionEnum): void {
        const config = this.getConfig();

        this.poolFailActionService.onTypeChange(config.fail_action, type);
    }

    /** @override */
    protected transformDataAfterSave({ data: config }: IHttpResponse<IPoolConfig>): IPoolConfig {
        return this.transformAfterLoad_(config);
    }

    /**
     * This function emulates a call to the server and giving the promise
     * It is making multiple calls periodically to continuously update the object
     * that was delivered into resolve
     *
     */
    protected loadRequest(fields : string[]): IPromise<any> {
        const requests = [
            this.loadConfig(fields),
            this.loadEventsAndAlerts(fields),
            this.loadMetrics(fields, undefined, undefined),
        ];

        return Promise.all(requests);
    }

    protected beforeEdit(): void {
        const promises: Array<IPromise<IHttpResponse<void>>> = [];
        const config = this.getConfig();

        config.fail_action = this.poolFailActionService.beforeEdit(config.fail_action);

        const ConfiguredNetwork = this.getAjsDependency_('ConfiguredNetwork');

        if (config.servers && (this.autoPopulatedServers() || this.hasNSXSecurityGroup())) {
            config.servers.forEach((server: IServer) => {
                server.hostname = server.ip.addr;
                server.ratio = 1;
            });
        }
        // If server network_ref is present but name is not (ex. OpenStack or AWS network),
        //  we need to make a request to retrieve the name.

        if (Array.isArray(config.servers)) {
            config.servers.forEach((server: IExtendedServer) => {
                if (!isUndefined(server.nw_ref) && !server.nw_ref.name()) {
                    const network = new ConfiguredNetwork({
                        id: this.stringService.slug(server.nw_ref),
                        params: {
                            cloud_uuid: this.stringService.slug(this.getCloudRef()),
                        },
                    });

                    const loadNetwork = network.load()
                        .then(() => server.nw_ref = network.getRef())
                        .finally(() => {
                            network.destroy();
                        });

                    promises.push(loadNetwork);
                }
            });
        } else {
            config.servers = [];
        }

        if (!config.health_monitor_refs) {
            config.health_monitor_refs = [];
        }

        config.nsx_securitygroup = config.nsx_securitygroup || [];

        Promise.all(promises).finally(this.setPristine.bind(this));
    }

    /** @override */
    protected transformAfterLoad(): void {
        this.transformAfterLoad_(this.getConfig());
    }

    /**
     * Does actual config transformation.
     */
    // eslint-disable-next-line no-underscore-dangle
    protected transformAfterLoad_(config: IPoolConfig): IPoolConfig {
        if ('servers' in config) {
            const {
                servers,
                default_server_port: defaultServerPort,
            } = config;

            servers.forEach(server => {
                server.default_server_port = defaultServerPort;
                server.uuid = this.Server.getServerUuid(server);
            });
        }

        return config;
    }
}

Object.assign(Pool.prototype, {
    objectName: 'pool',
    windowElement: 'app-pool-create',
    detailsStateName_: 'authenticated.application.pool-detail',
    vs: null,
    vsId: '',
    params: {
        include_name: true,
        join: ['application_persistence_profile_ref'].join(','),
    },
});

Pool.ajsDependencies = [
    'Server',
    'ConfiguredNetwork',
    'poolFailActionService',
    'stringService',
];
