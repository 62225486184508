/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { IPReputationTypeMatchConfigItem } from 'ajs/modules/match';
import * as l10n from './ip-reputation-type-match.l10n';
import './ip-reputation-type-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Match component for IPReputationTypeMatch message.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'ip-reputation-type-match',
    templateUrl: './ip-reputation-type-match.component.html',
})
export class IpReputationTypeMatchComponent {
    /**
     * IP Reputation Type message item instance.
     */
    @Input()
    public editable: IPReputationTypeMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object Type to be used in template for tooltip.
     */
    public readonly ipReputationTypeMatchObjectType = 'IPReputationTypeMatch';

    /**
     * IpReputation type dropdown options.
     */
    public readonly ipReputationTypeOptions: IAviDropdownOption[] = [];

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        const ipReputationTypes = schemaService.getEnumValues('IPReputationType');

        this.ipReputationTypeOptions = createOptionsFromEnumProps(ipReputationTypes);

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
