/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-psm-rules-config';
const prefix = `${moduleName}.${componentName}`;

export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;
export const nameLabel = `${prefix}.nameLabel`;
export const matchesLabel = `${prefix}.matchesLabel`;
export const evaluatedLabel = `${prefix}.evaluatedLabel`;
export const addRuleButtonLabel = `${prefix}.addRuleButtonLabel`;
export const locationBreadcrumb = `${prefix}.locationBreadcrumb`;

export const ENGLISH = {
    [searchInputPlaceholder]: 'Search',
    [nameLabel]: 'Name',
    [matchesLabel]: 'Matches',
    [evaluatedLabel]: 'Evaluated',
    [addRuleButtonLabel]: 'Add Rule',
    [locationBreadcrumb]: 'Location: {0}',
};
