/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'avi-repeated-strings-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const stringInputPlaceholder = `${prefix}.stringInputPlaceholder`;
export const keyDuplicationErrorMsg = `${prefix}.keyDuplicationErrorMsg`;
export const duplicatValuesErrorMsg = `${prefix}.duplicatValuesErrorMsg`;
export const columnTitleString = `${prefix}.columnTitleString`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [stringInputPlaceholder]: 'Enter Value',
    [keyDuplicationErrorMsg]: 'System does not support duplicate values. Please update or delete.',
    [duplicatValuesErrorMsg]: 'Duplicate values: {0}.',
    [columnTitleString]: 'Value',
    [removeButtonLabel]: 'Remove',
};
