/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

/**
 * @desc NAT policy Collection.
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
export class NatPolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'natpolicy',
            windowElement: 'nat-policy-modal',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('NatPolicy');
    }
}

NatPolicyCollection.ajsDependencies = [
    'NatPolicy',
];
