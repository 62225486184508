/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { DnsInfoConfigItem } from 'ajs/modules/dns';
import { SchemaService } from 'ajs/modules/core/services';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './dns-info-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description DnsInfo modal component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'dns-info-modal',
    templateUrl: './dns-info-modal.component.html',
})
export class DnsInfoModalComponent implements OnInit {
    /**
     * DnsInfoConfigItem instance.
     */
    @Input()
    public editable: DnsInfoConfigItem;

    /**
     * VirtualService name.
     * On create, it will be the default value for hostname.
     */
    @Input()
    public vsName?: string;

    /**
     * Ref for associated cloud.
     */
    @Input()
    public cloudRef: string;

    /**
     * Boolean flag for editing - DnsInfo doesnt have id, so parent item factory
     * is passing this flag for indication.
     */
    @Input()
    public isEditing = false;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<DnsInfoConfigItem>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * ObjectType DnsInfoConfigItem.
     */
    public objectType: string;

    /**
     * Type dropdown options.
     */
    public dnsInfoTypeDropdownOptions: IAviDropdownOption[];

    /**
     * Record response algorithm dropdown options.
     */
    public recordResponseAlgorithmDropdownOptions: IAviDropdownOption[];

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        const dnsRecordTypes = schemaService.getEnumValues('DnsRecordType');
        const dnsRecordResponseAlgorithms =
            schemaService.getEnumValues('DnsRecordResponseAlgorithm');

        this.dnsInfoTypeDropdownOptions = createOptionsFromEnumProps(dnsRecordTypes);
        this.recordResponseAlgorithmDropdownOptions =
            createOptionsFromEnumProps(dnsRecordResponseAlgorithms);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
