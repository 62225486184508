/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'path-or-query-match';
const prefix = `${moduleName}.${componentName}`;

export const criteriaDropdownLabel = `${prefix}.criteriaDropdownLabel`;
export const selectMethodPlaceholder = `${prefix}.selectMethodPlaceholder`;
export const stringLabel = `${prefix}.stringLabel`;
export const addItemButtonLabel = `${prefix}.addItemButtonLabel`;
export const enableMatchCaseLabel = `${prefix}.enableMatchCaseLabel`;
export const enterCustomValueLabel = `${prefix}.enterCustomValueLabel`;

export const ENGLISH = {
    [criteriaDropdownLabel]: 'Criteria',
    [selectMethodPlaceholder]: 'Select Method',
    [stringLabel]: 'String',
    [addItemButtonLabel]: 'Add Item',
    [enableMatchCaseLabel]: 'Enable Match Case',
    [enterCustomValueLabel]: 'Enter Custom Value',
};
