/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { some } from 'underscore';
import { AjsDependency } from 'ajs/utils/ajsDependency';
import { Privilege } from 'generated-types';
import {
    IAppState,
    IAppStateData,
    IStateAccessMap,
} from 'ajs/js/services/appStates.types';

import { Auth } from '../auth';

/**
 * @description
 *      Service to Generate a map of state names to its accessibility
 *      by the current user.
 *      stateAccessMap is regenerated on each tenant switch.
 *      Used by StateManagerService to validate a state.
 * @author Aravindh Nagarajan
 */
export class StateAccessMapService extends AjsDependency {
    /**
     * Keeps the generated access map, which is a Hash of state names
     * and its accessiblity by the current user. will get regenerated on
     * every tenant switch. Used by stateManagerService.
     */
    private stateAccessMap: IStateAccessMap = {};

    private $injector: angular.auto.IInjectorService;

    private auth: Auth = null;

    constructor() {
        super();

        this.$injector = this.getAjsDependency_('$injector');
    }

    /**
     * Checks whether user has access to the state path.
     * @param fullName full name of the state.
     * @returns true if user has access to the state.
     * @throws if we pass non-existent state.
     */
    public getStateAccess(fullName: string): boolean {
        const { stateAccessMap } = this;

        if (fullName in stateAccessMap) {
            return stateAccessMap[fullName];
        }

        throw new Error(`State with name: ${fullName} is not found.`);
    }

    /**
     * Generates stateAccessMap by Parsing AppStateTree.
     */
    public generateMap(): void {
        const appStateTree: IAppState[] = this.getAjsDependency_('appStateTree');

        try {
            appStateTree.forEach(state => this.parseState(state));
        } catch (error) {
            this.stateAccessMap = {};

            throw error;
        }
    }

    /**
     * Checks whether user has access to the state and all its children and sets it in accessMap.
     * If enforceNoAccess is true, skips Permission check and mark the state inaccessible.
     * @param state - State to be parsed
     * @param enforceNoAccess - If true, state will be marked inaccessible.
     */
    private parseState(state: IAppState, enforceNoAccess = false): void {
        const {
            fullName: currentStateName,
            children: childStates = [],
        } = state;

        const hasPermission = enforceNoAccess ? false : this.checkUserPermissions(state);

        this.setStateAccess(currentStateName, hasPermission);

        if (childStates.length) {
            // If we dont have access to currentState, Skip permission check and
            // mark its children inaccessible.
            const enforceNoAccess = !hasPermission;

            childStates.forEach(childState => this.parseState(childState, enforceNoAccess));

            // If currentState has access but none of the children are accessible,
            // Should mark current state inaccessible.
            // No recursion here.
            if (hasPermission && !this.isAnyStateAccessible(childStates)) {
                this.setStateAccess(currentStateName, false);
            }
        }
    }

    /**
     * Returns true if current user has access to state.
     */
    private checkUserPermissions(state: IAppState): boolean {
        if (!this.auth) {
            this.auth = this.$injector.get('Auth');
        }

        const { data: stateData = {} as any as IAppStateData } = state;

        const {
            permission,
            permissionType,
        } = stateData;

        return !permission || this.auth.isPrivilegeAllowed(permission, permissionType as Privilege);
    }

    /**
     * Sets the (statename, accessibility) pair in access map.
     */
    private setStateAccess(fullName: string, hasAccess: boolean): void {
        this.stateAccessMap[fullName] = hasAccess;
    }

    /**
     * Returns true when at least one of the states is accessible to user.
     */
    private isAnyStateAccessible(states: IAppState[]): boolean {
        return some(states, ({ fullName: stateFullName }) => this.getStateAccess(stateFullName));
    }
}

StateAccessMapService.ajsDependencies = [
    'appStateTree',
    '$injector',
];
