/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IIpAddrRange } from 'generated-types';

import {
    MessageItem,
} from '.';

/**
 * @description IpAddrRange MessageItem.
 *
 * @author Alex Tseung, Aravindh Nagarajan
 */
export class IpAddrRangeConfigItem extends MessageItem<IIpAddrRange> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IpAddrRange',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        const { begin, end } = this.config;

        return `${begin.toString()} - ${end.toString()}`;
    }
}
