/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('StaticRouteListDataTransformer', [
'ListDataTransformer',
function(ListDataTransformer) {
    return class StaticRouteListDataTransformer extends ListDataTransformer {
        /**
         * Adds "name" param with VRFContext name.
         * @override
         */
        getRequestConfig(requestParams) {
            const params = super.getRequestConfig(requestParams);

            params.name = this.owner_.vrf;

            return params;
        }

        /**
         * Taking list of static routes out of the first entry returned by API.
         * @override
         */
        processResponse(resp, request) {
            const results = [];

            resp = this.responseListOffsetControl_(resp, request);

            const { data } = resp;

            if ('results' in data && Array.isArray(data.results)) {
                const [vrfContext] = data.results;

                this.owner_.entireObject = vrfContext;

                const {
                    tenant_ref: tenantRef,
                    static_routes: staticRoutes,
                } = vrfContext;

                if (Array.isArray(staticRoutes)) {
                    results.push(
                        ...staticRoutes.map(row => {
                            const rowCopy = angular.copy(row);

                            rowCopy.tenant_ref = tenantRef;

                            return { config: rowCopy };
                        }),
                    );
                }
            }

            data.results = results;
            data.count = results.length;

            return resp;
        }
    };
}]);
