/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
} from '@angular/core';

import { LinuxServerConfigurationConfigItem } from 'ajs/modules/cloud';
import { L10nService } from '@vmw/ngx-vip';

import {
    Cloud,
    LinuxServerConfiguration,
} from 'object-types';

import { CloudConnectorUserCollection } from 'ajs/modules/cloud-connector-user';
import * as l10n from './linux-server-configuration.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Type of CloudConnect User Collection.
 */
type TCloudConnectorUserCollection = typeof CloudConnectorUserCollection;

/**
 * @description Component for LinuxServer configuration.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'linux-server-configuration',
    templateUrl: './linux-server-configuration.component.html',
})
export class LinuxServerConfigurationComponent implements OnDestroy {
    /**
     * LinuxServerConfiguration Config Item.
     */
    @Input()
    public editable: LinuxServerConfigurationConfigItem;

    /**
     * Input for Service Engine Usage Directory path passed from Cloud Modal Component.
     */
    @Input()
    public seUsagePath: string;

    /**
     * Cloud Id passed as an input from cloud Modal.
     */
    @Input()
    public cloudId: string;

    /**
     * Holds the flag for edit mode.
     */
    @Input()
    public isEditing: boolean;

    /**
     * CloudConnectorUserCollection instance.
     */
    public cloudConnectorUserCollection: CloudConnectorUserCollection;

    /**
     * Object Type to be used in template.
     */
    public readonly objectType = LinuxServerConfiguration;

    /**
     * Cloud Object Type used in template.
     */
    public readonly cloudObjectType = Cloud;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject('CloudConnectorUserCollection')
        CloudConnectorUserCollection: TCloudConnectorUserCollection,
        l10nService: L10nService,
    ) {
        this.cloudConnectorUserCollection = new CloudConnectorUserCollection();

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.cloudConnectorUserCollection.destroy();
    }
}
