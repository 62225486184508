/**
 * @module PoolModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    IAviDataGridConfig,
} from 'ng/shared/components';
import {
    SchemaService,
    StringService,
} from 'ajs/modules/core/services';
import {
    IPGDeploymentRule,
} from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { PoolGroupDeploymentPolicy } from 'ajs/modules/pool';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import * as l10n from './pool-group-deployment-policy-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Pool group deployment policy create/edit modal.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'pool-group-deployment-policy-modal',
    templateUrl: './pool-group-deployment-policy-modal.component.html',
})
export class PoolGroupDeploymentPolicyModalComponent implements AfterViewInit, OnDestroy {
    /**
     * PoolGroupDeploymentPolicy instance.
     */
    @Input()
    public editable: PoolGroupDeploymentPolicy;

    /**
     * TemplateRef for name input field.
     */
    @ViewChild('metricFieldTemplateRef')
    public metricFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for value input field.
     */
    @ViewChild('operatorFieldTemplateRef')
    public operatorFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for sensitive checkbox field.
     */
    @ViewChild('thresholdFieldTemplateRef')
    public thresholdFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * PoolGroupDeploymentPolicy object type.
     */
    public readonly objectType = 'PoolGroupDeploymentPolicy';

    /**
     * Instance object of AlertMetricsCollection.
     */
    public metricsCollection: any;

    /**
     * Stores Enum values of `ComparisonOperator`.
     * Used in ComparisonOperator dropdown.
     */
    public comparisonOperatorEnumValues;

    /**
     * Layout for ICAP modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * DataGrid config for Tier1LogicalRouterInfo.
     */
    public poolGroupDeploymentRuleGridConfig: IAviDataGridConfig;

    public constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        @Inject(SchemaService)
        schemaService: SchemaService,
        @Inject('alertMetricsCollection')
        AlertMetricsCollection: any,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.metricsCollection = new AlertMetricsCollection({
            params: {
                include_name: true,
            },
        });
        this.comparisonOperatorEnumValues = schemaService.getEnumValues('ComparisonOperator');
    }

    /**
     * Adds default config to Rules MessageItem.
     */
    public addRuleHandler(): void {
        this.editable.addRule();
    }

    /**
     * Removes rule item from Rules MessageItem.
     */
    public removeRuleHandler(rule: MessageItem): void {
        this.editable.removeRule(rule);
    }

    /**
     * Handles Metric ID dropdown change.
     */
    public metricChangeHandler(rule: IPGDeploymentRule): void {
        if (typeof rule?.metric_id === 'string') {
            rule.metric_id = this.stringService.slug(rule.metric_id);
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.poolGroupDeploymentRuleGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleMetric),
                id: 'metric',
                templateRef: this.metricFieldTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleOperator),
                id: 'operator',
                templateRef: this.operatorFieldTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleThreshold),
                id: 'threshold',
                templateRef: this.thresholdFieldTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeActionLabel),
                onClick: rules => rules.forEach((rule: MessageItem) =>
                    this.removeRuleHandler(rule)),
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeActionLabel),
                shape: 'trash',
                onClick: (rule: MessageItem) => {
                    this.removeRuleHandler(rule);
                },
            }],
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.metricsCollection.destroy();
    }
}
