/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IAviDataGridConfig } from 'ng/shared/components';
import { SSLCertificateConfigItem } from 'ajs/modules/security';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './pki-profile-certificate-authority.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Certificate Authority (CA) Grid component for PKI Profile.
 *
 * @author Satish Pednekar
 */

@Component({
    selector: 'pki-profile-certificate-authority-grid',
    templateUrl: './pki-profile-certificate-authority-grid.component.html',
})
export class PkiProfileCertificateAuthorityGridComponent implements AfterViewInit {
    /**
     * SSLCertificate RepeatedMessageItem instance.
     */
    @Input()
    public pkiCertificateAuthority: RepeatedMessageItem<SSLCertificateConfigItem>;

    /**
     * Fires on add Certificate Authority(CA).
     */
    @Output()
    public onAddCertificateAuthority = new EventEmitter<void>();

    /**
     * Fires on remove Certificate Authority.
     */
    @Output()
    public onRemoveCertificateAuthority = new EventEmitter<SSLCertificateConfigItem>();

    /**
     * PKI Certificate Authority grid config.
     */
    public pkiCertificateAuthorityGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        this.pkiCertificateAuthorityGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.certAuthColTitleName),
                    id: 'name',
                    transform(sslCertificate: SSLCertificateConfigItem) {
                        return sslCertificate.subjectName || '-';
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.certAuthColTitleIssuedBy),
                    id: 'issued_by',
                    transform(sslCertificate: SSLCertificateConfigItem) {
                        return sslCertificate.issuerName || '-';
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.certAuthColTitleExpirationDate),
                    id: 'expiration_date',
                    transform(sslCertificate: SSLCertificateConfigItem) {
                        return sslCertificate.config.not_after || '-';
                    },
                },

            ],
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.certAuthorityRemoveButtonLabel),
                onClick: (certificateAuthorityList: SSLCertificateConfigItem[]) => {
                    certificateAuthorityList
                        .forEach((certificateAuthority: SSLCertificateConfigItem) => {
                            this.removeCertificateAuthority(certificateAuthority);
                        });
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.certAuthorityRemoveButtonLabel),
                shape: 'trash',
                onClick: (certificateAuthority: SSLCertificateConfigItem) => {
                    this.removeCertificateAuthority(certificateAuthority);
                },
            }],
        };
    }

    /**
     * Handler for 'Add CA' (Certificate Authority) button.
     */
    public addCertificateAuthority(): void {
        this.onAddCertificateAuthority.emit();
    }

    /**
     * Handler for Certificate Authority remove operation.
     */
    private removeCertificateAuthority(certificateAuthority: SSLCertificateConfigItem): void {
        this.onRemoveCertificateAuthority.emit(certificateAuthority);
    }
}
