/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { fqdnSplit } from 'ng/shared/utils/fqdn-split';
import * as l10n from './vs-vip-fqdn.l10n';
import './vs-vip-fqdn.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to configure FQDN for VS VIP.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vs-vip-fqdn',
    templateUrl: './vs-vip-fqdn.component.html',
})
export class VsVipFqdnComponent implements OnInit {
    /**
     * Two way binding for DnsInfo#fqdn.
     */
    @Input()
    public fqdn: string;

    /**
     * VirtualService name.
     * On create, it will be the default value for hostname.
     */
    @Input()
    public vsName?: string;

    /**
     * Ref for associated cloud.
     */
    @Input()
    public cloudRef: string;

    /**
     * Fires on FQDN change - for two-way binding.
     */
    @Output()
    public fqdnChange = new EventEmitter<string>();

    /**
     * DnsInfo objectType.
     */
    public readonly objectType = 'DnsInfo';

    /**
     * NgModel for hostname input field.
     */
    public hostname: string;

    /**
     * NgModel for domainName dropdown.
     */
    public domainName: string;

    /**
     * List of domains.
     */
    public domainList: string[];

    /**
     * Domain name dropdown options.
     */
    public domainDropdownOptions: IAviDropdownOption[] = [];

    /**
     * True when domains are being loaded.
     */
    public loadingDomains = false;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
       @Inject('IPAMProfile')
       public readonly IPAMProfile: any, // Its not converted to TS yet.
       l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.loadDomains()
            .then(() => {
                if (this.fqdn) { // edit case
                    [this.hostname, this.domainName] = fqdnSplit(this.fqdn, this.domainList);
                } else {
                    if (this.vsName) {
                        this.hostname = this.vsName;
                    }

                    this.domainName = this.domainList[0];

                    this.emitDomainNameChange();
                }
            });
    }

    /**
     * Emits FQDN (hostname/domainName) value change.
     */
    public emitFqdnChange(): void {
        this.fqdn = this.hostname + this.domainName;

        this.fqdnChange.emit(this.fqdn);
    }

    /**
     * Emits FQDN change event on when domain name is changed.
     */
    public emitDomainNameChange(): void {
        // if hostname is undefined, we dont have to emit change event.
        if (this.hostname) {
            this.emitFqdnChange();
        }
    }

    /**
     * Loads list of domains using cloudRef.
     */
    private async loadDomains(): Promise<void> {
        this.loadingDomains = true;

        try {
            this.domainList = await this.IPAMProfile.getIpamDomains(this.cloudRef);

            this.domainDropdownOptions =
                 this.domainList.map(domain => createDropdownOption(domain));
        } finally {
            this.loadingDomains = false;
        }
    }
}
