/**
 * @module l4PolicyModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    L4ConnectionPolicyConfigItem,
    L4PolicySet,
    L4PolicySetCollection,
    l4PolicySetListComponentOptions,
    L4RuleActionConfigItem,
    L4RuleActionSelectPoolConfigItem,
    L4RuleConfigItem,
    L4RuleMatchTargetConfigItem,
    L4RulePortMatchConfigItem,
    l4RuleProtocolMatchComponentOptions,
    L4RuleProtocolMatchConfigItem,
} from '.';

import {
    L4_CONNECTION_POLICY_CONFIG_ITEM_TOKEN,
    L4_POLICY_SET_COLLECTION_TOKEN,
    L4_POLICY_SET_TOKEN,
    L4_RULE_ACTION_CONFIG_ITEM_TOKEN,
    L4_RULE_ACTION_SELECT_POOL_CONFIG_ITEM_TOKEN,
    L4_RULE_CONFIG_ITEM_TOKEN,
    L4_RULE_MATCH_TARGET_CONFIG_ITEM_TOKEN,
    L4_RULE_PORT_MATCH_CONFIG_ITEM_TOKEN,
    L4_RULE_PROTOCOL_MATCH_CONFIG_ITEM_TOKEN,
} from './l4-policy.tokens';

const l4PolicyModule = angular.module('avi/l4-policy');

const factories = [
    {
        factory: L4PolicySet,
        name: L4_POLICY_SET_TOKEN,
    },
    {
        factory: L4PolicySetCollection,
        name: L4_POLICY_SET_COLLECTION_TOKEN,
    },
    {
        factory: L4ConnectionPolicyConfigItem,
        name: L4_CONNECTION_POLICY_CONFIG_ITEM_TOKEN,
    },
    {
        factory: L4RuleConfigItem,
        name: L4_RULE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: L4RuleActionConfigItem,
        name: L4_RULE_ACTION_CONFIG_ITEM_TOKEN,
    },
    {
        factory: L4RuleMatchTargetConfigItem,
        name: L4_RULE_MATCH_TARGET_CONFIG_ITEM_TOKEN,
    },
    {
        factory: L4RuleActionSelectPoolConfigItem,
        name: L4_RULE_ACTION_SELECT_POOL_CONFIG_ITEM_TOKEN,
    },
    {
        factory: L4RulePortMatchConfigItem,
        name: L4_RULE_PORT_MATCH_CONFIG_ITEM_TOKEN,
    },
    {
        factory: L4RuleProtocolMatchConfigItem,
        name: L4_RULE_PROTOCOL_MATCH_CONFIG_ITEM_TOKEN,
    },
];

const components = [
    {
        name: 'l4PolicySetList',
        options: l4PolicySetListComponentOptions,
    },
    {
        name: 'l4RuleProtocolMatch',
        options: l4RuleProtocolMatchComponentOptions,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        l4PolicyModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => l4PolicyModule.component(name, options));
