/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'dns-info-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeader = `${prefix}.modalHeader`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const ttlInputLabel = `${prefix}.ttlInputLabel`;
export const ttlInputPlaceholder = `${prefix}.ttlInputPlaceholder`;
export const secondsHelperText = `${prefix}.secondsHelperText`;
export const typeDropdownLabel = `${prefix}.typeDropdownLabel`;
export const typeDropdownPlaceholder = `${prefix}.typeDropdownPlaceholder`;
export const recordResponseNumberInputLabel = `${prefix}.recordResponseNumberInputLabel`;
export const recordResponseNumberInputPlaceholder = `${prefix}.recordResponseNumberInputPlaceholder`;
export const recordResponseNumberInputHelperText = `${prefix}.recordResponseNumberInputHelperText`;
export const recordResponseAlgorithmDropdownLabel = `${prefix}.recordResponseAlgorithmDropdownLabel`;
export const recordResponseAlgorithmDropdownPlaceholder = `${prefix}.recordResponseAlgorithmDropdownPlaceholder`;
export const canonicalNameInputLabel = `${prefix}.canonicalNameInputLabel`;
export const canonicalNameInputPlaceholder = `${prefix}.canonicalNameInputPlaceholder`;
export const metaDataTextAreaLabel = `${prefix}.metaDataTextAreaLabel`;
export const metaDataTextAreaPlaceholder = `${prefix}.metaDataTextAreaPlaceholder`;

export const ENGLISH = {
    [modalHeader]: 'DNS: {0}',
    [modalTitleEdit]: 'Edit DNS: {0}',
    [modalTitleNew]: 'Create DNS: {0}',
    [generalSectionHeader]: 'General',
    [ttlInputLabel]: 'TTL',
    [ttlInputPlaceholder]: 'Enter Time To Live',
    [secondsHelperText]: 'Seconds',
    [typeDropdownLabel]: 'Type',
    [typeDropdownPlaceholder]: 'Select Type',
    [recordResponseNumberInputLabel]: 'Number of Records in Response',
    [recordResponseNumberInputPlaceholder]: 'Enter Number of Records in Response',
    [recordResponseNumberInputHelperText]: '0: All Records',
    [recordResponseAlgorithmDropdownLabel]: 'Record Response Algorithm',
    [recordResponseAlgorithmDropdownPlaceholder]: 'Select Record Response Algorithm',
    [canonicalNameInputLabel]: 'Canonical Name',
    [canonicalNameInputPlaceholder]: 'Enter Canonical Name',
    [metaDataTextAreaLabel]: 'Metadata',
    [metaDataTextAreaPlaceholder]: 'Enter Metadata',
};
