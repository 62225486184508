/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';

import {
    TaskType,
    VerifyCloudConnectorUserHostModalService,
} from 'ng/modules/cloud/services/verify-cloud-connector-user-host-modal.service';

import {
    CREDENTIALS_TYPE,
    getTypeLabel,
} from 'ng/modules/cloud-connector-user';

import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';

import { VERIFY_CLOUD_CONNECTOR_USER_HOST_MODAL_SERVICE_TOKEN }
    from '../../../../downgrade-services.tokens';

import {
    CloudConnectorUser,
    CloudConnectorUserCollection,
} from '../../factories';

import * as l10n from './user-credentials-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type CloudConnectorUserCollectionType = typeof CloudConnectorUserCollection;

class UserCredentialsPageController {
    public gridConfig: ICollectionGridConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private CloudConnectorUserCollection: CloudConnectorUserCollectionType,
        private readonly l10nService: L10nService,
        private readonly verifyCloudConnectorUserHostModalService
        : VerifyCloudConnectorUserHostModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        const { l10nService } = this;

        this.gridConfig = createCrudCollectionGridConfig();
        this.gridConfig.collection = new this.CloudConnectorUserCollection();

        this.gridConfig.fields = [{
            name: 'data.config.name',
            sortBy: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
        }, {
            name: 'type',
            title: l10nService.getMessage(l10nKeys.columnTitleCredentialType),
            transform(row: CloudConnectorUser) {
                return getTypeLabel(row.getType());
            },
        }];

        this.gridConfig.singleactions.push({
            class: 'icon-lostlog',
            do: (row: CloudConnectorUser) => {
                this.verifyCloudConnectorUserHostModalService.openModal(TaskType.VERIFY, row.id);
            },
            hidden: (row: CloudConnectorUser) => row.hasCredentials(CREDENTIALS_TYPE.GCP),
            title: l10nService.getMessage(l10nKeys.verifyHostBtnLabel),
        }, {
            class: 'icon-download-1',
            do(row: CloudConnectorUser) {
                const anchorElement = document.createElement('a');
                const url = `data:application/x-pem-file,
                    ${encodeURIComponent(row.getConfig().public_key)}`;

                anchorElement.href = url;
                anchorElement.download = `${row.getName()}_pub_key.pub`;
                anchorElement.click();
            },
            hidden: (row: CloudConnectorUser) => !row.getConfig().public_key,
            title: l10nService.getMessage(l10nKeys.downloadPublicKeyBtnLabel),
        });
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }
}

UserCredentialsPageController.$inject = [
    'CloudConnectorUserCollection',
    'l10nService',
    VERIFY_CLOUD_CONNECTOR_USER_HOST_MODAL_SERVICE_TOKEN,
];

export const userCredentialsPageOptions = {
    controller: UserCredentialsPageController,
    template:
        `<div class="avi-main">
            <div class="panel templatesList">
                <collection-grid config="::$ctrl.gridConfig"></collection-grid>
            </div>
        </div>`,
};
