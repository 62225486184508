/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PoliciesModule
 */

import {
    IpAddrMatchConfigItem,
    PortMatchConfigItem,
} from 'ajs/modules/match';

import { MatchTarget } from 'object-types';
import { PolicyMatchConfigItem } from './policy-match.config-item.factory';

interface IMatchTargetConfigItem {
    client_ip?: IpAddrMatchConfigItem;
    vs_port?: PortMatchConfigItem,
}

export const MATCH_TARGET_CONFIG_ITEM_TOKEN = 'MatchTargetConfigItem';

export class MatchTargetConfigItem extends PolicyMatchConfigItem<IMatchTargetConfigItem> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: MatchTarget,
            ...args,
        };

        super(extendedArgs);
    }
}
