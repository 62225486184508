/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'bot-type-matcher-readonly';
const prefix = `${moduleName}.${componentName}`;

export const clientTypeLabel = `${prefix}.clientTypeLabel`;

export const ENGLISH = {
    [clientTypeLabel]: 'Client Type',
};
