/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsVipModule
 */

import { Injector } from '@angular/core';
import { SubnetListNetwork } from 'ajs/modules/network';

import {
    VRFContext,
    VRF_CONTEXT_TOKEN,
} from 'ajs/modules/vrf-context';

const $injector = '$injector';

export const subnetListNetworkItemProvider = {
    deps: [$injector],
    provide: SubnetListNetwork,
    useFactory(injector: Injector): SubnetListNetwork {
        return injector.get('SubnetListNetwork');
    },
};

export const ipamProfileItemProvider = {
    deps: [$injector],
    provide: 'IPAMProfile',
    useFactory(injector: Injector): any {
        return injector.get('IPAMProfile');
    },
};

export const vrfContextItemProvider = {
    deps: [$injector],
    provide: VRFContext,
    useFactory(injector: Injector): typeof VRFContext {
        return injector.get(VRF_CONTEXT_TOKEN);
    },
};
