/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-private-ip-config';
const prefix = `${moduleName}.${componentName}`;

export const privateIpLabel = `${prefix}.privateIpLabel`;
export const autoAllocateRadioButtonLabel = `${prefix}.autoAllocateRadioButtonLabel`;
export const staticRadioButtonLabel = `${prefix}.staticRadioButtonLabel`;
export const networkDropdownLabel = `${prefix}.networkDropdownLabel`;
export const networkDropdownPlaceholder = `${prefix}.networkDropdownPlaceholder`;
export const ipv4AddressInputLabel = `${prefix}.ipv4AddressInputLabel`;
export const ipv4AddressInputPlaceholder = `${prefix}.ipv4AddressInputPlaceholder`;
export const ipv6AddressInputLabel = `${prefix}.ipv6AddressInputLabel`;
export const ipv6AddressInputPlaceholder = `${prefix}.ipv6AddressInputPlaceholder`;
export const ipProtocolLabel = `${prefix}.ipProtocolLabel`;
export const v4RadioButtonLabel = `${prefix}.v4RadioButtonLabel`;
export const v6RadioButtonLabel = `${prefix}.v6RadioButtonLabel`;
export const bothRadioButtonLabel = `${prefix}.bothRadioButtonLabel`;
export const prefixLengthInputLabel = `${prefix}.prefixLengthInputLabel`;
export const prefixLengthInputPlaceholder = `${prefix}.prefixLengthInputPlaceholder`;
export const enablePrefixLengthCheckboxLabel = `${prefix}.enablePrefixLengthCheckboxLabel`;

export const ENGLISH = {
    [privateIpLabel]: 'Private IP',
    [autoAllocateRadioButtonLabel]: 'Auto-Allocate',
    [staticRadioButtonLabel]: 'Static',
    [networkDropdownLabel]: 'VIP Address Allocation Network',
    [networkDropdownPlaceholder]: 'Select Network for VIP Address Allocation',
    [ipv4AddressInputLabel]: 'IPv4 Address',
    [ipv4AddressInputPlaceholder]: 'Enter IPv4 Address',
    [ipv6AddressInputLabel]: 'IPv6 Address',
    [ipv6AddressInputPlaceholder]: 'Enter IPv6 Address',
    [ipProtocolLabel]: 'IP Protocol',
    [v4RadioButtonLabel]: 'V4 Only',
    [v6RadioButtonLabel]: 'V6 Only',
    [bothRadioButtonLabel]: 'Both',
    [prefixLengthInputLabel]: 'Prefix Length',
    [prefixLengthInputPlaceholder]: 'Enter Prefix Length',
    [enablePrefixLengthCheckboxLabel]: 'Enable Prefix Length',
};

