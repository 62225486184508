/** @module PoolModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    copy,
    IPromise,
} from 'angular';

import {
    isArray,
    isNaN,
    isUndefined,
    reduce,
    sortBy,
} from 'underscore';

import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { StringService } from 'ajs/modules/core/services/string-service';
import {
    FailActionEnum,
    IPoolGroup,
    IPoolGroupMember,
} from 'generated-types';

import { Pool, POOL_ITEM_TOKEN } from './pool.item.factory';

interface IExtendedPoolGroupMember extends IPoolGroupMember {
    pool: Pool;
}

interface IPriorityHashMembers {
    priority: string,
    members: IPoolGroupMember[],
}

/**
 * Ajs dependency token for PoolGroup.
 */
export const POOL_GROUP_ITEM_TOKEN = 'PoolGroup';

/**
 * @description Pool Group collection item.
 * @author Nisar Nadaf
 */
export class PoolGroup extends Item<IPoolGroup> {
    // TODO: change any to PoolFailActionService class once it's been converted to ts
    private poolFailActionService: any;

    constructor(args = {}) {
        super(args);

        this.poolFailActionService = this.getAjsDependency_('poolFailActionService');
    }

    /**
     * Sort function used in grid config to sort by priority_label.
     */
    public static gridSortByPriority(a: IPoolGroupMember, b: IPoolGroupMember): number {
        const priorityA = isNaN(+a.priority_label) ? 0 : +a.priority_label;
        const priorityB = isNaN(+b.priority_label) ? 0 : +b.priority_label;

        return priorityB - priorityA;
    }

    /**
     * Matches pools to the pool_refs within members to be used in unit cards.
     */
    public static matchPoolsToMembers(
        members: IExtendedPoolGroupMember[],
        pools: Pool[],
    ): IExtendedPoolGroupMember[] {
        const stringService: StringService = this.getAjsDependency_('stringService');
        const poolHash = pools.reduce((acc: Record<string, Pool>, pool: Pool) => {
            acc[pool.id] = pool;

            return acc;
        }, {});

        if (isArray(members) && members.length) {
            members.forEach((member: IExtendedPoolGroupMember) =>
                member.pool = poolHash[stringService.slug(member.pool_ref)]);
        }

        return members;
    }

    /**
     * Sort pool members based on priority_label, then by ratio. Used by poolGroupListExpander.
     */
    public static sortMembersByPriorityLabel(members: IPoolGroupMember[]): IPoolGroupMember[] {
        if (isUndefined(members) || !members.length) {
            return [];
        }

        const membersCopy = copy(members);

        return membersCopy.sort((a: IPoolGroupMember, b: IPoolGroupMember) => {
            const priorityA = +a.priority_label || 0;
            const priorityB = +b.priority_label || 0;

            return priorityA === priorityB ? b.ratio - a.ratio : priorityB - priorityA;
        });
    }

    /**
     * Converts a list of Pool Group members into a list of Pools sorted by priority then ratio.
     */
    public static membersToSortedPools(members: IPoolGroupMember[]): IPriorityHashMembers[] {
        const priorityHash = PoolGroup.getPriorityToMembersHash(members);

        return PoolGroup.priorityHashToSortedPools(priorityHash);
    }

    /**
     * Sort function that sorts two objects with priority.
     */
    private static sortByPriority(a: IPriorityHashMembers, b: IPriorityHashMembers): number {
        const priorityA = isNaN(+a.priority) ? 0 : +a.priority;
        const priorityB = isNaN(+b.priority) ? 0 : +b.priority;

        return priorityB - priorityA;
    }

    /**
     * Returns a hash of priority values to Pool Group members.
     */
    private static getPriorityToMembersHash(
        members: IPoolGroupMember[] = [],
    ): Record<string, IPoolGroupMember[]> {
        return members.reduce((
            acc: Record<string, IPoolGroupMember[]>,
            member: IPoolGroupMember,
        ) => {
            const priority = member.priority_label || '--';

            if (isUndefined(acc[priority])) {
                acc[priority] = [];
            }

            acc[priority].push(member);

            return acc;
        }, {});
    }

    /**
     * Returns an array of objects containing a priority property and list of Pools with that
     * priority. The list of pools are sorted by ratio.
     */
    private static priorityHashToSortedPools(
        priorityHash: Record<string,
        IPoolGroupMember[]>,
    ): IPriorityHashMembers[] {
        return reduce(priorityHash,
            (acc: IPriorityHashMembers[], members: IPoolGroupMember[], priority: string) => {
                acc.push({
                    priority,
                    members: sortBy(members, 'ratio'),
                });

                return acc;
            }, []).sort(PoolGroup.sortByPriority);
    }

    /**
     * Returns the cloud_ref configured on the PoolGroup.
     */
    public getCloudRef(): string {
        const { cloud_ref: cloudRef } = this.getConfig();

        return cloudRef;
    }

    /**
     * Making an API call to figure what VRF context is used by the first pool in the list.
     * All pools of the poolGroup are using the same VRF context and poolGroup's config
     * doesn't have vrf_context_uuid property.
     */
    public getVRFContextRef(): IPromise<string> {
        const Collection = this.getAjsDependency_('Collection');
        const poolItemClass: Pool = this.getAjsDependency_(POOL_ITEM_TOKEN);
        const stringService: StringService = this.getAjsDependency_('stringService');
        const { members } = this.getConfig();

        if (!members || !members.length) {
            return Promise.reject(
                new Error('PoolGroup has no member to figure out VRF Context ref'),
            );
        }

        const [poolConfig] = members;
        const { pool_ref: poolRef } = poolConfig;

        // can't use PoolCollection cause that one is inventory (no "fields" param support)
        // we could load single pool as well but such approach has the exact same problem
        const poolCollection = new Collection({
            objectName: 'pool',
            itemClass: poolItemClass,
            limit: 1,
            params: {
                fields: 'vrf_ref,tenant_ref',
                uuid: stringService.slug(poolRef),
            },
        });

        const promise = poolCollection.load()
            .then(() => {
                const [pool] = poolCollection.items;

                return pool.getVRFContextRef();
            });

        promise.finally(() => poolCollection.destroy());

        return promise;
    }

    /**
     * Updates fail action configuration based on the type passed.
     */
    public onFailActionTypeChange(type: FailActionEnum): void {
        const { fail_action: failAction } = this.getConfig();

        this.poolFailActionService.onTypeChange(failAction, type);
    }

    /** @override */
    public dataToSave() : IPoolGroup {
        const config = copy(this.getConfig());

        config.fail_action = this.poolFailActionService.dataToSave(config.fail_action);

        const { markers } = config;

        if (markers) {
            // filter out RBAC entries with an empty key
            config.markers = markers.filter(({ key }) => key);

            // delete empty RABC label list
            if (markers.length === 0) {
                delete config.markers;
            }
        }

        return config;
    }

    /** @override */
    protected beforeEdit(): void {
        const config = this.getConfig();

        config.fail_action = this.poolFailActionService.beforeEdit(config.fail_action);
    }
}

Object.assign(PoolGroup.prototype, {
    objectName: 'poolgroup',
    windowElement: 'pool-group-create',
});

PoolGroup.ajsDependencies = [
    'Collection',
    POOL_ITEM_TOKEN,
    'poolFailActionService',
    'stringService',
];
