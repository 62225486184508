/**
 * Module for all things gslb related.
 * @preferred
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ClrFormsModule,
    ClrInputModule,
    ClrTextareaModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import {
    GslbCheckpointsModalComponent,
    GslbGeoDbProfileEntriesGridComponent,
    GslbGeoDbProfileModalComponent,
} from '.';

import { gslbGeoDbProfileCollectionProvider } from './ajs-upgraded-providers';

const gslbComponents = [
    GslbCheckpointsModalComponent,
    GslbGeoDbProfileModalComponent,
    GslbGeoDbProfileEntriesGridComponent,
];

@NgModule({
    declarations: [
        ...gslbComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        ClrFormsModule,
        ClrInputModule,
        ClrTextareaModule,
        FormsModule,
        SharedModule,
    ],
    providers: [
        gslbGeoDbProfileCollectionProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class GslbModule {}
