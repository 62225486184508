/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'policies';
const componentName = 'policy-rule-summary';
const prefix = `${moduleName}.${componentName}`;

export const matchHeaderLabel = `${prefix}.matchHeaderLabel`;
export const actionHeaderLabel = `${prefix}.actionHeaderLabel`;

export const ENGLISH = {
    [matchHeaderLabel]: 'Match ({0})',
    [actionHeaderLabel]: 'Action ({0})',
};
