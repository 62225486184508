/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ClrFormLayout } from '@clr/angular';
import { IpReputationTypeMappingConfigItem } from 'ajs/modules/bot-detection-policy';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import { IBotIdentification } from 'generated-types';
import * as l10n from './ip-reputation-type-mapping-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Bot IP Reputation Type Mapping modal.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'ip-reputation-type-mapping-modal',
    templateUrl: './ip-reputation-type-mapping-modal.component.html',
})
export class IpReputationTypeMappingModalComponent implements OnInit {
    @Input()
    public editable: IpReputationTypeMappingConfigItem;

    /**
     * Informs if current type mapping is being edited, or new one created.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Used when user attempts to save.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Variable modal header, depends on creating new, or editing.
     */
    public modalHeader: string;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Is 'IpReputationTypeMapping' MessageItem.
     */
    public objectType: string;

    /**
     * Options list for enum IPReputationType.
     */
    public ipReputationTypeOptions: IAviDropdownOption[];

    /**
     * Options list for enum BotClientClass.
     */
    public botClientClassOptions: IAviDropdownOption[];

    /**
     * Options list for enum BotClientType.
     */
    public botClientTypeOptions: IAviDropdownOption[];

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.setDropdownOptions();
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        this.modalHeader = this.isEditing ?
            this.l10nService.getMessage(this.l10nKeys.modalTitleEdit) :
            this.l10nService.getMessage(this.l10nKeys.modalTitleCreate);
    }

    /**
     * Convenience method to get bot_identification config.
     */
    public get botIdentificationConfig(): IBotIdentification {
        return this.editable.config.bot_identification.config;
    }

    /**
     * Handles user clicking save.
     */
    public onSave(): void {
        this.onSubmit.emit();
    }

    /**
     * Sets dropdown options.
     */
    private setDropdownOptions(): void {
        const ipReputationTypes = this.schemaService.getEnumValues('IPReputationType');

        this.ipReputationTypeOptions = createOptionsFromEnumProps(ipReputationTypes);

        const botClientClasses = this.schemaService.getEnumValues('BotClientClass');

        this.botClientClassOptions = createOptionsFromEnumProps(botClientClasses);

        const botClientTypes = this.schemaService.getEnumValues('BotClientType');

        this.botClientTypeOptions = createOptionsFromEnumProps(botClientTypes);
    }
}
