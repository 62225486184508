/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Input } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { WafDataFileConfigItem, WafProfileItem } from 'ajs/modules/waf/factories/waf-profile';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { MessageItem } from 'ajs/modules/data-model/factories';
import { WafConfig } from 'object-types';

import * as l10n from './waf-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for Waf Profile Modal.
 *
 * @author Kondiparthi Shanmukha Sarath.
 */
@Component({
    selector: 'waf-profile-modal',
    templateUrl: './waf-profile-modal.component.html',
})
export class WafProfileModalComponent {
    /**
     * WafProfileItem ObjectTypeItem Instance
     */
    @Input()
    public editable: WafProfileItem;

    /**
     * WafProfileItem Object Type.
     */
    public objectType: string;

    /**
     * WafConfig Object Type used in Component.
     */
    public readonly objectTypeWafConfig = WafConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Input value for cookie format versions,
     * Accepted Values [0, 1].
     */
    public readonly cookieFormatVersion = {
        ONE: 1,
        ZERO: 0,
    };

    /**
     * Dropdown Options for Allowed HTTP Versions.
     */
    public readonly allowedVersionsDropdownOptions: IAviDropdownOption[];

    /**
     * Dropdown Options for Allowed HTTP Methods.
     */
    public readonly allowedMethodsDropdownOptions: IAviDropdownOption[];

    /**
     * Layout for WafProfileItem modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const allowedVersions = schemaService.getEnumValues('HTTPVersion');
        const allowedMethods = schemaService.getEnumValues('HTTPMethod');

        this.allowedVersionsDropdownOptions = createOptionsFromEnumProps(allowedVersions);
        this.allowedMethodsDropdownOptions = createOptionsFromEnumProps(allowedMethods);
    }

    /**
     * @override
     * Setting Object Type and config for WafProfile.
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Opens modal to add new Waf Data File.
     */
    public onAddWafDataFile(): void {
        this.editable.addWafDataFile();
    }

    /**
     * Opens modal to edit Waf Data File.
     */
    public onEditWafDataFile(wafDataFileConfigItem: WafDataFileConfigItem): void {
        this.editable.editWafDataFile(wafDataFileConfigItem);
    }

    /**
     * Removes Waf Data File from Config.
     */
    public onRemoveWafDataFile(wafDataFileConfigItem: WafDataFileConfigItem): void {
        this.editable.removeWafDataFile(wafDataFileConfigItem);
    }

    /**
     * Adds new content type mapping into the grid.
     */
    public onAddContentTypeMapping(): void {
        this.editable.wafContentTypeMapping.add();
    }

    /**
     * Removes Content Type Mapping from grid.
     */
    public onRemoveContentTypeMapping(mapping: MessageItem): void {
        this.editable.wafContentTypeMapping.removeByMessageItem(mapping);
    }
}
