/**
 * @module Cloud
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';

import { CloudType } from 'generated-types';
import { LicenseBasedFeaturesService } from 'ajs/modules/core/services';
import { Cloud } from 'ajs/js/services/items/Cloud';
import { createDropdownOption } from 'ng/shared/utils';
import { IAviDropdownOption } from 'ng/shared/components';
import { L10nService } from '@vmw/ngx-vip';
import './convert-cloud-type-dialog.component.less';
import * as l10n from './convert-cloud-type-dialog.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloud = typeof Cloud;

/**
 * @description Component used for Converting cloud type for No Orchestrator cloud.
 * @author vgohil
 */
@Component({
    selector: 'convert-cloud-type-dialog',
    templateUrl: './convert-cloud-type-dialog.component.html',
})
export class ConvertCloudTypeDialogComponent {
    /**
     * Current cloud name value.
     */
    @Input()
    public cloudName: string;

    /**
     * Method to close the dialog.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Method to submit the dialog.
     */
    @Output()
    public onSubmit = new EventEmitter<string>();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Cloud type dropdown options.
     */
    public readonly cloudTypeOptions: IAviDropdownOption[] = [];

    /**
     * Value of selected cloud type from dropdown.
     */
    public selectedCloudType: CloudType;

    /**
     * List of Cloud types used for creating the cloud type dropdown options.
     */
    private readonly cloudTypes = [
        CloudType.CLOUD_NSXT,
        CloudType.CLOUD_VCENTER,
        CloudType.CLOUD_OPENSTACK,
        CloudType.CLOUD_AWS,
        CloudType.CLOUD_LINUXSERVER,
        CloudType.CLOUD_AZURE,
        CloudType.CLOUD_GCP,
    ];

    constructor(
        l10nService: L10nService,
        licenseBasedFeaturesService: LicenseBasedFeaturesService,
        @Inject(Cloud)
        Cloud: TCloud,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const supportedCloudTypesMap = licenseBasedFeaturesService.getSupportedCloudTypesMap();

        this.cloudTypeOptions = this.cloudTypes
            .filter((cloudType: CloudType) => supportedCloudTypesMap.get(cloudType))
            .map((cloudType: CloudType) => {
                return createDropdownOption(cloudType, Cloud.getCloudTypeName(cloudType));
            });
    }

    /**
     * Called when user confirms changing the cloud type.
     */
    public handleSubmit(): void {
        this.onSubmit.emit(this.selectedCloudType);
    }

    /**
     * Called when user decides not to change the cloud type.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }
}
