/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-dropdown-options';
const prefix = `${moduleName}.${componentName}`;

export const noValidEntriesLabel = `${prefix}.noValidEntriesLabel`;

export const ENGLISH = {
    [noValidEntriesLabel]: '-- no valid entries --',
};
