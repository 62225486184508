/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'convert-cloud-type-dialog';
const prefix = `${moduleName}.${componentName}`;

export const convertCloudTypeHeader = `${prefix}.convertCloudTypeHeader`;
export const convertCloudTypeMessage = `${prefix}.convertCloudTypeMessage`;
export const cloudTypeInputLabel = `${prefix}.cloudTypeInputLabel`;
export const cloudTypeInputPlaceholder = `${prefix}.cloudTypeInputPlaceholder`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const continueButtonLabel = `${prefix}.continueButtonLabel`;

export const ENGLISH = {
    [convertCloudTypeHeader]: 'Convert Cloud Type',
    [convertCloudTypeMessage]: 'Select the new cloud type for cloud: {0}',
    [cloudTypeInputLabel]: 'Cloud Type',
    [cloudTypeInputPlaceholder]: 'Select Cloud Type',
    [cancelButtonLabel]: 'Cancel',
    [continueButtonLabel]: 'Yes, continue',
};
