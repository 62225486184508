/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { findWhere } from 'underscore';
import { DialogService } from 'ng/modules/core';

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import { AviAlertComponent } from '../components/avi-alert';
import { BackendErrorMsgPipe } from '../../../shared/pipes/backend-error-msg.pipe';

export interface IErrorMessageObject {
    count: number;
    text: string;
}

/**
 * ID for attachments selection dialog.
 */
const AVI_ALERT_DIALOG_ID = 'avi-alert';

/**
 * @description
 * Service to keep all current error messages and show an alert modal window.
 * @author Rohit Gaikwad, Zhiqian Liu, Alex Malitsky, Suraj Kumar
 */

@Injectable()
export class AviAlertService {
    /**
     * Array of error messages objects having text and counter.
     */
    private readonly messages: IErrorMessageObject[] = [];

    /**
     * List of messages in string format to be rendered by avi-alert component.
     * Has to be immutable so that aviAlert could re-render the list on the binding update.
     */
    private readonly aviAlertMessages: string[] = [];

    constructor(
        private readonly dialogService: DialogService,
        private readonly backendErrorMsgPipe: BackendErrorMsgPipe,
    ) { }

    /**
     * Appends an error's text to the list and shows an alert modal if it was hidden.
     * @todo currently aviAlert won't appear when user is logged out but error would get
     *     added to the stack
     */
    public throw = (message: any): void => {
        // can be null on cancelled requests, ignore those
        if (!message || typeof message === 'object' && message.silent) {
            return;
        }

        const msgText = this.backendErrorMsgPipe.transform(message);
        const sameMessage = findWhere(this.messages, { text: msgText });

        if (sameMessage) {
            sameMessage.count++;
        } else {
            this.messages.unshift({
                count: 1,
                text: msgText,
            });
        }

        this.updateAviAlertMessages();

        if (!this.dialogService.has(AVI_ALERT_DIALOG_ID)) {
            this.dialogService.add({
                id: AVI_ALERT_DIALOG_ID,
                component: AviAlertComponent as Type<Component>,
                componentProps: {
                    messages: this.aviAlertMessages, // has to be passed by reference
                    onClose: () => this.removeAll(),
                    closeModal: () => this.dialogService.remove(AVI_ALERT_DIALOG_ID),
                },
            });
        }
    };

    /**
     * Empties the list of errors.
     */
    public removeAll = (): void => {
        this.messages.length = 0;
        this.aviAlertMessages.length = 0;
    };

    /**
     * Flatten out errors into the list of strings to be rendered via avi-alert component.
     */
    private updateAviAlertMessages(): void {
        const { aviAlertMessages } = this;

        aviAlertMessages.length = 0;

        this.messages
            .forEach(({ text: aviAlertMessage, count }) => {
                if (count > 1) {
                    aviAlertMessage += ` (x${count})`;
                }

                aviAlertMessages.push(aviAlertMessage);
            });
    }
}
