/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'ip-reputation-type-mapping';
const prefix = `${moduleName}.${componentName}`;

export const typeMappingTitle = `${prefix}.typeMappingTitle`;
export const allowRuleTitle = `${prefix}.allowRuleTitle`;

export const ENGLISH = {
    [typeMappingTitle]: 'Type Mapping',
    [allowRuleTitle]: 'Allow Rule',
};
