/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import { Observable } from 'rxjs';
import { CloudConnectorUserCollection } from 'ajs/modules/cloud-connector-user';
import { CloudCollection, VCenterServer } from 'ajs/modules/cloud';
import { createDropdownOption } from 'ng/shared/utils';
import { IAviDropdownOption } from 'ng/shared/components';
import { IVcenterContentLibrary } from 'generated-types';
import { ClrFormLayout } from '@clr/angular';
import './vcenter-server-modal.component.less';
import { L10nService } from '@vmw/ngx-vip';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { IEditCredentialsConfig } from 'ng/modules/avi-forms';
import {
    VcenterServerEditCredentialsDialogComponent,
} from './vcenter-server-edit-credentials-dialog';

import * as l10n from './vcenter-server-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloudConnectorUserCollection = typeof CloudConnectorUserCollection;

type TCloudCollection = typeof CloudCollection;

/**
 * @description Component for the VCenterServer modal.
 * @author alextsg
 */
@Component({
    selector: 'vcenter-server-modal',
    templateUrl: './vcenter-server-modal.component.html',
})
export class VCenterServerModalComponent implements OnInit, OnDestroy {
    /**
     * VCenterServer ObjectTypeItem.
     */
    @Input()
    public editable: VCenterServer;

    /**
     * True to hide the cloud_ref form field, set when the VCenterServer is being configured using
     * the macrostack API which will auto-populate the cloud_ref.
     */
    @Input()
    public hideCloud ? = false;

    /**
     * Cloud url, used when the vCenterServer is set for a specific cloud.
     */
    @Input()
    public cloudRef?: string;

    /**
     * List of vCenter addresses.
     */
    @Input()
    public vcenterAddresses?: string[];

    /**
     * Called when the user hits Submit.
     */
    @Output()
    public onSubmit: EventEmitter<VCenterServer> = new EventEmitter();

    /**
     * Layout for clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * CloudConnectorUserCollection instance.
     */
    public cloudConnectorUserCollection: CloudConnectorUserCollection;

    /**
     * CloudCollection instance.
     */
    public cloudCollection: CloudCollection;

    /**
     * Content Library dropdown options.
     */
    public contentLibrariesDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * True if the Cloud Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * List of vcenter address dropdown options, passed to the VcenterServerEditCredentialsDialog
     * component.
     */
    public vcenterAddressDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Credentials config passed to the CredentialsVerification component.
     */
    public editVcenterCredentialsConfig: IEditCredentialsConfig;

    constructor(
        @Inject('CloudConnectorUserCollection')
        private CloudConnectorUserCollection: TCloudConnectorUserCollection,
        @Inject('CloudCollection')
        private CloudCollection: TCloudCollection,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
        l10nService: L10nService,
    ) {
        this.cloudConnectorUserCollection = new this.CloudConnectorUserCollection({
            params: {
                fields: 'name,tenant_ref',
                search: 'vcenter_credentials',
            },
            createParams: {
                credentialsType: 'VCENTER',
            },
        });

        this.cloudCollection = new this.CloudCollection({
            isStatic: true,
            params: {
                fields: 'name,tenant_ref',
            },
        });

        this.isEditing = Boolean(itemId);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isEditing) {
            this.fetchContentLibraries();
        } else if (this.cloudRef) {
            this.editable.config.cloud_ref = this.cloudRef;
        }

        if (this.vcenterAddresses) {
            this.vcenterAddressDropdownOptions = this.vcenterAddresses.map(address => {
                return createDropdownOption(address);
            });
        }

        this.setEditVcenterCredentialsConfig();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.cloudConnectorUserCollection.destroy();
        this.cloudCollection.destroy();
    }

    /**
     * Returns true if a custom submit handler has been passed in, so we display a "Done" label
     * instead of "Save".
     */
    public hasCustomSubmit(): boolean {
        return this.onSubmit.observers.length > 0;
    }

    /**
     * Called when the submit button is clicked.
     */
    public handleSubmit(): void {
        if (this.hasCustomSubmit()) {
            this.onSubmit.emit(this.editable);
        } else {
            this.editable.submit();
        }
    }

    /**
     * True if the credentials fields have been set.
     */
    public get hasCredentials(): boolean {
        return this.editable.hasUrlAndCredentials();
    }

    /**
     * Creates the config object passed to the CredentialsVerification component for editing
     * credentials.
     */
    private setEditVcenterCredentialsConfig(): void {
        const config = this.editable.getConfig();
        const editCredentialsConfig = {
            vcenter_credentials_ref: config.vcenter_credentials_ref,
            vcenter_url: config.vcenter_url,
        };

        this.editVcenterCredentialsConfig = {
            editCredentialsDialog: VcenterServerEditCredentialsDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                vcenterAddressDropdownOptions: this.vcenterAddressDropdownOptions,
                submit$: new Observable<void>(subscriber => {
                    this.editable.verifyVcenterLogin(editCredentialsConfig)
                        .then(() => {
                            subscriber.next();
                            subscriber.complete();

                            this.contentLibrariesDropdownOptions = [];
                            this.editable.config.content_lib.config.id = undefined;
                            this.editable.setVcenterLoginCredentials(editCredentialsConfig);
                            this.setEditVcenterCredentialsConfig();

                            return this.fetchContentLibraries();
                        })
                        .catch(error => subscriber.error(error));
                }),
                onCancel: () => {
                    this.editable.cancelVerifyVcenterLogin();
                    this.setEditVcenterCredentialsConfig();
                },
            },
        };
    }

    /**
     * Called to fetch and populate content library dropdown options.
     */
    private fetchContentLibraries = async(): Promise<void> => {
        const contentLibraries = await this.editable.fetchContentLibraries();

        this.contentLibrariesDropdownOptions = contentLibraries.map(
            (contentLibrary: IVcenterContentLibrary) => {
                const { name, id } = contentLibrary;

                return createDropdownOption(id, name);
            },
        );
    };
}
