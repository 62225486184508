/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc Service
 * @name  Vlan
 * @description
 * Service for creating, editing, and removing VLAN interfaces from a parent VNIC network.
 */
angular.module('aviApp').factory('Vlan', [
'defaultValues', 'VRFContextCollection',
(defaultValues, VRFContextCollection) => {
    /**
     * Constructor function.
     * @param {Object[]} parentInterfaceList -
     *      Parent vnics where Vlan needs to be placed/removed from.
     * @param {Object} vlan  - Vlan object being created, modified, or deleted.
     * @param {Object=} cloud - Optional Cloud object.
     */
    const VlanInterface = function(parentInterfaceList, vlan, cloud) {
        this.parentInterfaceList = parentInterfaceList || [];
        this.parentInterfaceMap = getParentInterfaceMap(parentInterfaceList);

        this.cloud = cloud;
        this.vrfCollection = new VRFContextCollection();
        this.vrfCollection.isCreatable = () => false;

        if (cloud) {
            this.vrfCollection.setParams({ 'cloud_ref.uuid': this.cloud.id });
        }

        if (vlan) {
            this.mode = 'edit';
            this.config = vlan;
            this.parentInterface = this.getParentInterfaceName();
        } else {
            this.mode = 'create';
            this.config = _.extend(
                defaultValues.getDefaultItemConfigByType('vlaninterface') || {},
                { dhcp_enabled: false },
            );
        }

        this.config.vnic_networks = this.config.vnic_networks || [{ mode: 'STATIC' }];
        this.VIPVnicNetworks = this.getVIPVnicNetworksString();
    };

    /**
     * Generates a map for parent interfaces for quick access, e.g. {'eth0': [eth0 Object]}
     * @param  {Object[]} networks - Array of parent interfaces/vnics.
     * @return {Object}
     */
    function getParentInterfaceMap(networks = []) {
        const map = {};

        networks.forEach(network => {
            if (network.if_name) {
                map[network.if_name] = network;
            }
        });

        return map;
    }

    /**
     * Gets the parent interface string name from the if_name, since the if_name is composed of
     * <parent-interface>.<vlan ID>
     * @return {string} Name of parent interface.
     */
    VlanInterface.prototype.getParentInterfaceName = function() {
        const
            vlanString = `.${this.config.vlan_id}`,
            index = this.config.if_name.lastIndexOf(vlanString);

        return this.config.if_name.slice(0, index);
    };

    /**
     * Returns comma-separated string of vnic_networks with mode === 'VIP'.
     * @return {string} string of VIP vnic_networks.
     */
    VlanInterface.prototype.getVIPVnicNetworksString = function() {
        return this.config.vnic_networks.reduce(function(acc, network) {
            return network.mode === 'VIP' ?
                acc += `${network.ip.ip_addr.addr}/${network.ip.mask}, ` : acc;
        }, '').slice(0, -2);
    };

    /**
     * Returns an array of strings of all parent interfaces.
     * @return {string[]} Array of parent interface string.
     */
    VlanInterface.prototype.getParentInterfaceListNames = function() {
        return _.pluck(this.parentInterfaceList, 'if_name');
    };

    /**
     * Checks to see if VLAN name already exists, unless the VLAN is being edited, where
     * (this.config.if_name === name). If VLAN is being created, this.config.if_name should be
     * undefined.
     * @param  {string} parentInterface - Name of parent interface.
     * @param  {string} name - Name of VLAN interface.
     * @return {Boolean} True if VLAN already exists, false otherwise.
     */
    VlanInterface.prototype.vlanAlreadyExists = function(parentInterface, name) {
        const vnic = this.parentInterfaceMap[parentInterface];

        return this.config.if_name !== name && Array.isArray(vnic.vlan_interfaces) &&
                _.any(vnic.vlan_interfaces, function(vlan) {
                    return vlan.if_name === name;
                });
    };

    /**
     * Removes VLAN from parent interface.
     */
    VlanInterface.prototype.removeVlan = function() {
        const
            vnic = this.parentInterfaceMap[this.parentInterface],
            index = vnic.vlan_interfaces.indexOf(this.config);

        if (index > -1) {
            vnic.vlan_interfaces.splice(index, 1);
        }

        if (vnic.vlan_interfaces.length === 0) {
            vnic.vlan_interfaces = undefined;
        }
    };

    /**
     * Adds new VLAN object into parent interface.
     * @param {Object} vlan - VLAN object.
     * @param {String} parentInterface - Name of parent interface.
     */
    VlanInterface.prototype.addNewVlan = function(vlan, parentInterface) {
        const vnic = this.parentInterfaceMap[parentInterface];

        vnic.vlan_interfaces = vnic.vlan_interfaces || [];
        vnic.vlan_interfaces.push(vlan);
    };

    /**
     * Modifies existing VLAN object. If changing parent interfaces, need to remove original from
     * parent interface and place in new parent interface.
     * @param  {Object} vlan - VLAN object.
     * @param  {string} parentInterface - Name of parent interface.
     */
    VlanInterface.prototype.editVlan = function(vlan, parentInterface) {
        if (parentInterface !== this.parentInterface) {
            this.removeVlan();
            this.addNewVlan(vlan, parentInterface);
        } else {
            _.extend(this.config, vlan);
        }
    };

    return VlanInterface;
}]);
