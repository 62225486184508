/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name Alert
 */
angular.module('aviApp').factory('Alert',
    ['Item', function(Item) {
        class Alert extends Item {
        /**
         * Returns the Item name this alert was generated about.
         * @returns {string}
         * @public
         */
            getObjName() {
                const { obj_name: objName, obj_ref: objRef } = this.getConfig();

                return objName || objRef.name() || '';
            }

            /**
             * Human readable alert level enum value, such as "high", "medium" and "low".
             * @type {string}
             */
            get level() {
                const { level } = this.getConfig();

                return level.slice(6).toLowerCase();// 'ALERT_'.length
            }
        }

        Alert.prototype.objectName = 'alert';

        return Alert;
    }]);
