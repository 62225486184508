/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'dsr-profile-setting';
const prefix = `${moduleName}.${componentName}`;

export const directServerReturnHeader = `${prefix}.directServerReturnHeader`;
export const enableDsrInputLabel = `${prefix}.enableDsrInputLabel`;
export const dsrTypeInputLabel = `${prefix}.dsrTypeInputLabel`;
export const dsrEncapsulationTypeInputLabel = `${prefix}.dsrEncapsulationTypeInputLabel`;

export const ENGLISH = {
    [directServerReturnHeader]: 'Direct Server Return',
    [enableDsrInputLabel]: 'Enable DSR',
    [dsrTypeInputLabel]: 'DSR Type',
    [dsrEncapsulationTypeInputLabel]: 'DSR Encapsulation Type',
};
