/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    ApplicationProfileType,
    CloudType,
    LicenseTierType,
} from 'generated-types';

import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { SystemInfoService } from '../system-info/system-info.service';

/**
 * @description Tracks what configurations are supported based on the user's license.
 * @author alextsg, Zhiqian Liu
 */
export class LicenseBasedFeaturesService {
    /**
     * Set of metrics which are not available on basic license.
     */
    private readonly basicLicenseMetricBlockList = new Set(['end_to_end']);

    constructor(
        private readonly systemInfoService: SystemInfoService,
        private readonly schemaService: SchemaService,
    ) {}

    /**
     * Returns a map of supported cloud types.
     */
    public getSupportedCloudTypesMap(): Map<CloudType, boolean> {
        const supportedCloudTypesMap = new Map([
            [CloudType.CLOUD_NSXT, false],
            [CloudType.CLOUD_VCENTER, false],
            [CloudType.CLOUD_OPENSTACK, false],
            [CloudType.CLOUD_AWS, false],
            [CloudType.CLOUD_LINUXSERVER, false],
            [CloudType.CLOUD_AZURE, false],
            [CloudType.CLOUD_GCP, false],
            [CloudType.CLOUD_NONE, false],
        ]);

        const limitedSupportedTypes = this.schemaService.getFieldAllowValuesBySystemLicenseTier(
            'Cloud', 'vtype',
        );

        // no support cloud type restrictions, set all cloud type in the map to true
        if (!limitedSupportedTypes) {
            for (const cloudType of supportedCloudTypesMap.keys()) {
                supportedCloudTypesMap.set(cloudType, true);
            }
        } else if (limitedSupportedTypes.length !== 0) {
            limitedSupportedTypes.forEach(
                cloudTypeString => supportedCloudTypesMap.set(
                    cloudTypeString as CloudType, true,
                ),
            );
        }

        return supportedCloudTypesMap;
    }

    /**
     * Returns a map of ApplicationProfile types and whether they are
     * allowed for the defaultLicenseTier.
     */
    public getSupportedAppProfileTypesMap(): Map<ApplicationProfileType, boolean> {
        const supportedAppProfileTypesMap = new Map([
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_HTTP, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_SIP, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_SSL, false],
            [ApplicationProfileType.APPLICATION_PROFILE_TYPE_SYSLOG, false],
        ]);

        const limitedSupportedTypes = this.schemaService.getFieldAllowValuesBySystemLicenseTier(
            'ApplicationProfile', 'type',
        );

        // no support application profile type restrictions,
        // set all types in the map to true
        if (!limitedSupportedTypes) {
            for (const appProfileType of supportedAppProfileTypesMap.keys()) {
                supportedAppProfileTypesMap.set(appProfileType, true);
            }
        } else if (limitedSupportedTypes.length) {
            limitedSupportedTypes.forEach(
                type => supportedAppProfileTypesMap.set(type as ApplicationProfileType, true),
            );
        }

        return supportedAppProfileTypesMap;
    }

    /**
     * Returns true for all metrics on Enterprise license type
     * and for the available metrics on Basic license type
     */
    public isMetricAvailable(metricId: string): boolean {
        const { defaultLicenseTier } = this.systemInfoService;

        return defaultLicenseTier !== LicenseTierType.BASIC ||
            !this.basicLicenseMetricBlockList.has(metricId);
    }
}

LicenseBasedFeaturesService.$inject = [
    'systemInfoService',
    'schemaService',
];
