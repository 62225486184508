/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('logClickableHeader', function() {
    return {
        restrict: 'E',
        scope: {
            name: '@',
            param: '@',
            click: '&',
            orderby: '<',
            asc: '<',
        },
        template: '<div ng-if="orderby !== undefined" class="clickable" ' +
            'ng-click="click({name:param})">{{ name }}<i ng-if="orderby !== undefined" ' +
            'class="icon icon-sort" ng-class="{\'icon-sort-asc\': orderby === param && !asc,' +
            ' \'icon-sort-desc\': orderby === param && asc}"></i></div>' +
            '<span ng-if="orderby === undefined">{{ name }}</span>',
    };
});
