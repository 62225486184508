/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ip-reputation-change-request-card.l10n';

import './ip-reputation-change-request-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const changeRequestUrl = 'https://www.brightcloud.com/tools/change-request.php';

/**
 * @description Component for a card containing ip reputation change request for
 *     incorrectly identified URLs or IPs.
 * @author Ashish Verma
 */
@Component({
    selector: 'ip-reputation-change-request-card',
    templateUrl: './ip-reputation-change-request-card.component.html',
})
export class IpReputationChangeRequestCardComponent {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Change request url.
     */
    public readonly changeRequestUrl = changeRequestUrl;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handle on change request button click.
     */
    public onChangeRequestClick(): void {
        window.open(changeRequestUrl, '_blank');
    }
}
