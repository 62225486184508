/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    AviPermissionResource,
    IWafConfig,
    IWafContentTypeMapping,
    IWafProfile,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';

import {
    WafProfileModalComponent,
} from 'ng/modules/waf/components/waf-profile-modal/waf-profile-modal.component';

import { WafProfile } from 'object-types';
import * as l10n from 'ajs/modules/waf/waf.l10n';

import { WafDataFileConfigItem } from './waf-data-file.config-item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Interface for WafProfileConfig as data.
 */
interface IWafProfileData {
    config: IWafProfileConfig,
}

/**
 * Custom Waf Profile Type.
 */
type TWafProfilePartial = Omit<IWafProfile, 'files'>;

/**
 * Interface for IWafProfileConfig
 */
interface IWafProfileConfig extends TWafProfilePartial {
    files: RepeatedMessageItem<WafDataFileConfigItem>;
}

/**
 * @description WafProfile Item Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class WafProfileItem extends withEditChildMessageItemMixin(
    withFullModalMixin(ObjectTypeItem),
) {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * Data of WafProfileItem.
     */
    public data: IWafProfileData;

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafprofile',
            windowElement: WafProfileModalComponent,
            objectType: WafProfile,
            permissionName: AviPermissionResource.PERMISSION_WAFPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for files object in WafProfileItem.
     */
    public get files(): RepeatedMessageItem<WafDataFileConfigItem> {
        return this.getConfig().files;
    }

    /**
     * Getter for WafConfig from WafProfileItem.
     */
    public get wafConfig(): IWafConfig {
        const wafConfig = this.getConfig().config.getConfig();

        return wafConfig as IWafConfig;
    }

    /**
     * Getter for WafContentType Mappings from WafProfileItem.
     */
    public get wafContentTypeMapping(): RepeatedMessageItem<MessageItem<IWafContentTypeMapping>> {
        const wafContentTypeMapping = this.getConfig().config.getConfig().content_type_mappings;

        return wafContentTypeMapping as RepeatedMessageItem<MessageItem<IWafContentTypeMapping>>;
    }

    /**
     * Helper function to add WafData Files.
     */
    public addWafDataFile(): void {
        this.addChildMessageItem({
            field: 'files',
        });
    }

    /**
     * Helper function to edit WafData Files.
     */
    public editWafDataFile(wafDataFileConfigItem: WafDataFileConfigItem): void {
        this.editChildMessageItem({
            field: 'files',
            messageItem: wafDataFileConfigItem,
        });
    }

    /**
     * Helper function to remove datafile from config.
     */
    public removeWafDataFile(wafDataFileConfigItem: WafDataFileConfigItem): void {
        this.config.files.removeByMessageItem(wafDataFileConfigItem);
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WafProfile.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafProfileModalBreadcrumbTitle);
    }
}
