/**
 * @module PersistenceProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { PersistenceProfileType } from 'generated-types';

/**
 * Different types of Persistence profiles.
 * PERSISTENCE_TYPE_GSLB_SITE is a UI only type
 * https://avinetworks.atlassian.net/browse/AV-55890
 */
export const PersistenceProfileTypeMap = new Map(Object.entries({
    [PersistenceProfileType.PERSISTENCE_TYPE_CLIENT_IP_ADDRESS]: 'ip_persistence_profile',
    [PersistenceProfileType.PERSISTENCE_TYPE_CUSTOM_HTTP_HEADER]: 'hdr_persistence_profile',
    [PersistenceProfileType.PERSISTENCE_TYPE_APP_COOKIE]: 'app_cookie_persistence_profile',
    [PersistenceProfileType.PERSISTENCE_TYPE_HTTP_COOKIE]: 'http_cookie_persistence_profile',
    [PersistenceProfileType.PERSISTENCE_TYPE_GSLB_SITE]: 'http_cookie_persistence_profile',
}));
