/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IAviDataGridConfig } from 'ng/shared/components';
import { CRLConfigItem } from 'ajs/modules/security';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './pki-profile-certificate-revocation-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Certificate Revocation List (CRL) Grid component for PKI Profile.
 *
 * @author Satish Pednekar
 */

@Component({
    selector: 'pki-profile-certificate-revocation-list-grid',
    templateUrl: './pki-profile-certificate-revocation-list-grid.component.html',
})
export class PkiProfileCertificateRevocationListGridComponent implements AfterViewInit {
    /**
     * CRL RepeatedMessageItem instance.
     */
    @Input()
    public pkiCertificateRevocationList: RepeatedMessageItem<CRLConfigItem>;

    /**
     * Fires on add Certificate Revocation List(CRL).
     */
    @Output()
    public onAddCertificateRevocationList = new EventEmitter();

    /**
     * Fires on remove Certificate Revocation List.
     */
    @Output()
    public onRemoveCertificateRevocationList = new EventEmitter<CRLConfigItem>();

    /**
     * PKI Certificate Revocation List grid config.
     */
    public pkiCertificateRevocationListGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.pkiCertificateRevocationListGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.certRevocationListColTitleName),
                    id: 'name',
                    transform(crl: CRLConfigItem) {
                        return crl.name || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.certRevocationListColTitleExpDate),
                    id: 'expiration_date',
                    transform(crl: CRLConfigItem) {
                        return crl.config.next_update || '-';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.certRevocationListColTitleRefresh),
                    id: 'refresh',
                    transform(crl: CRLConfigItem) {
                        const { update_interval: updateInterval } = crl.config;

                        return updateInterval ?
                            l10nService.getMessage(l10nKeys.crlMinutesLabel, [updateInterval]) :
                            l10nService.getMessage(l10nKeys.crlRefreshEmptyLabel);
                    },
                },

            ],
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.certRevocationListRemoveButtonLabel),
                onClick: (certificateRevocationList: CRLConfigItem[]) => {
                    certificateRevocationList
                        .forEach((certificateRevocationListItem: CRLConfigItem) => {
                            this.removeCertificateRevocationList(certificateRevocationListItem);
                        });
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.certRevocationListRemoveButtonLabel),
                shape: 'trash',
                onClick: (certificateRevocationListItem: CRLConfigItem) => {
                    this.removeCertificateRevocationList(certificateRevocationListItem);
                },
            }],
        };
    }

    /**
     * Handler for 'Add CRL' button.
     */
    public addCertificateRevocationList(): void {
        this.onAddCertificateRevocationList.emit();
    }

    /**
     * Handler for Certificate Revocation List remove operation.
     */
    private removeCertificateRevocationList(certificateRevocationListItem: CRLConfigItem): void {
        this.onRemoveCertificateRevocationList.emit(certificateRevocationListItem);
    }
}
