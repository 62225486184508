/** @module GeoDBModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { GeoDbModalComponent } from 'ng/modules/security';
import {
    AviPermissionResource,
    IGeoDB,
} from 'generated-types';
import {
    GeoDbFileConfigItem,
    GeoDbMappingConfigItem,
} from '.';

type TGeoDbPartial = Omit<IGeoDB, 'files' | 'mappings'>;

interface IGeoDbConfig extends TGeoDbPartial {
    files: RepeatedMessageItem<GeoDbFileConfigItem>;
    mappings: RepeatedMessageItem<GeoDbMappingConfigItem>;
}
interface IGeoDbData {
    config: IGeoDbConfig;
}

/**
 * Ajs dependency token for GeoDB Item.
 */
export const GEO_DB_ITEM_TOKEN = 'GeoDB';

/**
 * @description Item for GEO DB
 *
 * @author Sarthak Kapoor
 */

export class GeoDb extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public data: IGeoDbData;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'geodb',
            objectType: 'GeoDB',
            permissionName: AviPermissionResource.PERMISSION_GEODB,
            windowElement: GeoDbModalComponent,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns files list.
     */
    public get files(): RepeatedMessageItem<GeoDbFileConfigItem> {
        return this.getConfig().files;
    }

    /**
     * Method to add Geo Db file.
     */
    public addGeoDbFile(): void {
        this.addChildMessageItem({
            field: 'files',
        });
    }

    /**
     * Method to edit Geo Db file.
     */
    public editGeoDbFile(geoDbFileConfigItem: GeoDbFileConfigItem): void {
        this.editChildMessageItem({
            field: 'files',
            messageItem: geoDbFileConfigItem,
        });
    }

    /**
     * Returns mappings list.
     */
    public get mappings(): RepeatedMessageItem<GeoDbMappingConfigItem> {
        return this.getConfig().mappings;
    }

    /**
     * Method to add Geo Db mapping.
     */
    public addGeoDbMapping(): void {
        this.addChildMessageItem({
            field: 'mappings',
        });
    }

    /**
     * Method to remove Geo Db mapping.
     */
    public removeGeoDbMapping(
        geoDbMappingConfigItem: GeoDbMappingConfigItem,
    ): void {
        this.mappings.removeByMessageItem(geoDbMappingConfigItem);
    }

    /**
     * Method to edit Geo Db mapping.
     */
    public editGeoDbMapping(geoDbMappingConfigItem: GeoDbMappingConfigItem): void {
        this.editChildMessageItem({
            field: 'mappings',
            messageItem: geoDbMappingConfigItem,
        });
    }
}
