/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'pool';
const componentName = 'pool-group-deployment-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const editDeploymentPolicyHeader = `${prefix}.editDeploymentPolicyHeader`;
export const newDeploymentPolicyHeader = `${prefix}.newDeploymentPolicyHeader`;
export const generalSectionHeader = `${prefix}.generalSectionHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceHolder = `${prefix}.nameInputPlaceHolder`;
export const trafficRatioInputLabel = `${prefix}.trafficRatioInputLabel`;
export const trafficRatioInputPlaceholder = `${prefix}.trafficRatioInputPlaceholder`;
export const evaluationDurationInputLabel = `${prefix}.evaluationDurationInputLabel`;
export const evaluationDurationInputPlaceholder = `${prefix}.evaluationDurationInputPlaceholder`;
export const evaluationDurationUnitsLabel = `${prefix}.evaluationDurationUnitsLabel`;
export const trafficRatioRampupInputLabel = `${prefix}.trafficRatioRampupInputLabel`;
export const trafficRatioRampupInputPlaceholder = `${prefix}.trafficRatioRampupInputPlaceholder`;
export const rulesSectionHeader = `${prefix}.rulesSectionHeader`;
export const rulesDataGridTitle = `${prefix}.rulesDataGridTitle`;
export const emptyRuleMetricPlaceholder = `${prefix}.emptyRuleMetricPlaceholder`;
export const removeRuleButtonLabel = `${prefix}.removeRuleButtonLabel`;
export const addRuleButtonLabel = `${prefix}.addRuleButtonLabel`;
export const columnTitleMetric = `${prefix}.columnTitleMetric`;
export const columnTitleOperator = `${prefix}.columnTitleOperator`;
export const columnTitleThreshold = `${prefix}.columnTitleThreshold`;
export const removeActionLabel = `${prefix}.removeActionLabel`;

export const ENGLISH = {
    [editDeploymentPolicyHeader]: 'Edit Deployment Policy: {0}',
    [newDeploymentPolicyHeader]: 'New Deployment Policy: {0}',
    [generalSectionHeader]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceHolder]: 'Enter Name',
    [trafficRatioInputLabel]: 'Traffic Ratio',
    [trafficRatioInputPlaceholder]: 'Enter Ratio',
    [evaluationDurationInputLabel]: 'Evaluation Duration',
    [evaluationDurationInputPlaceholder]: 'Enter Duration',
    [evaluationDurationUnitsLabel]: 'Seconds',
    [trafficRatioRampupInputLabel]: 'Test Traffic Ratio Rampup',
    [trafficRatioRampupInputPlaceholder]: 'Enter Ratio',
    [rulesSectionHeader]: 'Rules',
    [rulesDataGridTitle]: 'Deployment Validation Rules ({0})',
    [emptyRuleMetricPlaceholder]: 'None',
    [removeRuleButtonLabel]: 'Remove Rule',
    [addRuleButtonLabel]: 'Add',
    [columnTitleMetric]: 'Metric ID',
    [columnTitleOperator]: 'Operator',
    [columnTitleThreshold]: 'Threshold',
    [removeActionLabel]: 'Remove',
};
