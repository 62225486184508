/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'sso-policy';

export const ssoPolicyModalBreadcrumbTitle = `${prefix}.ssoPolicyModalBreadcrumbTitle`;
export const authenticationRuleModalBreadcrumbTitle = `${prefix}.authenticationRuleModalBreadcrumbTitle`;
export const authorizationRuleModalBreadcrumbTitle = `${prefix}.authorizationRuleModalBreadcrumbTitle`;

export const ENGLISH = {
    [ssoPolicyModalBreadcrumbTitle]: 'SSO Policy',
    [authenticationRuleModalBreadcrumbTitle]: 'Authentication Rule',
    [authorizationRuleModalBreadcrumbTitle]: 'Authorization Rule',
};
