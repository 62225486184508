/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential.
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'bgp-profile-details';
const prefix = `${moduleName}.${componentName}`;

export const typeTableHeader = `${prefix}.typeTableHeader`;
export const bgpAsTableHeader = `${prefix}.bgpAsTableHeader`;
export const ipv4PrefixTableHeader = `${prefix}.ipv4PrefixTableHeader`;
export const ipv4PeerTableHeader = `${prefix}.ipv4PeerTableHeader`;
export const ipv6PrefixTableHeader = `${prefix}.ipv6PrefixTableHeader`;
export const ipv6PeerTableHeader = `${prefix}.ipv6PeerTableHeader`;
export const remoteAsTableHeader = `${prefix}.remoteAsTableHeader`;
export const advertiseVipTableHeader = `${prefix}.advertiseVipTableHeader`;
export const advertiseSnatTableHeader = `${prefix}.advertiseSnatTableHeader`;

export const ENGLISH = {
    [typeTableHeader]: 'Type',
    [bgpAsTableHeader]: 'BGP AS',
    [ipv4PrefixTableHeader]: 'IPv4 Prefix',
    [ipv4PeerTableHeader]: 'IPv4 Peer',
    [ipv6PrefixTableHeader]: 'IPv6 Prefix',
    [ipv6PeerTableHeader]: 'IPv6 Peer',
    [remoteAsTableHeader]: 'Remote AS',
    [advertiseVipTableHeader]: 'Advertise VIP',
    [advertiseSnatTableHeader]: 'Advertise SNAT',
};
