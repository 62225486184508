/**
 * Module containing system configuration components.
 * @preferred
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrIconModule,
    ClrInputModule,
    ClrTextareaModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import {
    EmailSmtpModalComponent,
    EmailSmtpPageCardComponent,
    EmailSmtpPageComponent,
    SchedulerModalComponent,
} from '.';

const systemComponents = [
    EmailSmtpModalComponent,
    EmailSmtpPageCardComponent,
    EmailSmtpPageComponent,
    SchedulerModalComponent,
];

@NgModule({
    declarations: [
        ...systemComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        FormsModule,
        SharedModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        ClrIconModule,
        ClrTextareaModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class SystemModule {}
