/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { ErrorPageConfigItem, ErrorPageProfile } from 'ajs/modules/error-page';

import * as l10n from './error-page-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Error Page Profile full-modal component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'error-page-profile-modal',
    templateUrl: './error-page-profile-modal.component.html',
})
export class ErrorPageProfileModalComponent implements OnInit {
    /**
     * ErrorPageProfile ObjectTypeItem instance.
     */
    @Input()
    public editable: ErrorPageProfile;

    /**
     * ErrorPageProfile object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Error Page Body modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Opens ErrorPageProfileList modal to add new ErrorPage.
     */
    public createErrorPage(): void {
        this.editable.addErrorPage();
    }

    /**
     * Opens ErrorPageProfileList modal to edit new editErrorPage.
     */
    public editErrorPage(errorPage: ErrorPageConfigItem): void {
        this.editable.updateErrorPage(errorPage);
    }

    /**
     * Deletes Error Page from ErrorPageProfile.
     */
    public deleteErrorPage(errorPage: ErrorPageConfigItem): void {
        const { errorPageList } = this.editable;

        errorPageList.removeByMessageItem(errorPage);
    }
}
