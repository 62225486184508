/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('resourceTimingTable', function() {
    return {
        scope: {
            data: '=',
        },
        restrict: 'AE',
        templateUrl: 'src/views/components/resource-timing-table.html',
    };
});
