/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'security';

export const sslProfileModalBreadcrumbTitle = `${prefix}.sslProfileModalBreadcrumbTitle`;
export const sslTlsCertificateModalBreadcrumbTitle = `${prefix}.sslTlsCertificateModalBreadcrumbTitle`;

export const ENGLISH = {
    [sslProfileModalBreadcrumbTitle]: 'SSL/TLS Profile',
    [sslTlsCertificateModalBreadcrumbTitle]: 'Certificate (SSL/TLS)',
};
