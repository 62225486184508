/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name  MetricsDimension
 * @description
 *
 *     Each Item has one or more Series. Item id is the dimension id of the Series.
 */
angular.module('avi/vs/security').factory('MetricsDimension', [
'$q', 'Item',
function($q, Item) {
    class MetricsDimension extends Item {
        constructor(args = {}) {
            super(args);

            /** @type {{Series.id: Series}} */
            this.seriesHash = {};

            const { data } = args;

            //that's how it should be handled by Item in the first place
            if (angular.isObject(data)) {
                this.data = {};
                this.updateItemData(data);
            }
        }

        /** @override */
        getIdFromData_({ series, config }) {
            return config && config.id || series && series.getDimensionId() || '';
        }

        /**
         * Blocks this address if the type of MetricsDimension is 'ipgroup'.
         * @param  {VirtualService.data.config} vsConfig - Virtual Service config object.
         * @return {ng.$q.promise}
         */
        blockAddress(vsConfig) {
            if (this.data.type === 'ipgroup') {
                const
                    method = vsConfig.network_security_policy_ref ? 'PUT' : 'POST',
                    payload = {
                        rules: [{
                            action: 'NETWORK_SECURITY_POLICY_ACTION_TYPE_DENY',
                            enable: true,
                            log: false,
                            age: 30,
                            match: {
                                client_ip: {
                                    addrs: [{
                                        addr: this.data.id,
                                        type: 'V4',
                                    }],
                                    match_criteria: 'IS_IN',
                                },
                            },
                        }],
                        vs_name: vsConfig.name,
                        vs_uuid: vsConfig.uuid,
                    };

                return this.request(method, '/api/networksecuritypolicydos/?action=block', payload);
            } else {
                return $q.reject();
            }
        }

        /**
         * Unblocks this MetricDimension's address.
         * @param  {Object} vsConfig - Virtual Service config object.
         * @return {ng.$q.promise}
         */
        unblockAddress(vsConfig) {
            const payload = {
                ips: {
                    addrs: [{
                        addr: this.data.id,
                        type: 'V4',
                    }],
                    match_criteria: 'IS_IN',
                },
                vs_name: vsConfig.name,
                vs_uuid: vsConfig.uuid,
            };

            return this.request('PUT', '/api/networksecuritypolicydos/?action=unblock', payload);
        }

        /** @override */
        getName() {
            return this.getConfig().dimensionId;
        }

        /** @override */
        updateItemData(newData) {
            if (angular.isObject(newData)) {
                const { data } = this;

                _.each(newData, (val, key) => {
                    if (key === 'series') {
                        this.addSeries_(val);

                        return;
                    }

                    data[key] = val;

                    if (key === 'config') {
                        this.trigger('itemChange', val);
                    }
                });
            }

            return true;
        }

        /**
         * Puts passed Series instance into Item.seriesHash.
         * @param {Series} series
         * @protected
         */
        addSeries_(series) {
            const
                { seriesHash } = this,
                config = this.getConfig(),
                id = series.getId();

            seriesHash[id] = series;

            if (!config.seriesId) {
                config.dimensionId = series.getDimensionId();
                config.seriesId = series.getSeriesId();
                config.itemId = series.getItemId();
            }
        }

        /**
         * Returns a Series instance by Series id.
         * @param {string} seriesId
         * @returns {Series|null}
         * @public
         */
        getSeriesBySeriesId(seriesId) {
            return _.find(this.seriesHash,
                series => series.getSeriesId() === seriesId) || null;
        }
    }

    MetricsDimension.prototype.objectName = 'analytics/metrics/virtualservice/';

    return MetricsDimension;
}]);
