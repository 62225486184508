/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */
import { Component } from '@angular/core';
import './full-modal-preview.component.less';

/**
 * @description Component for a preview on the right side of the full modal.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-preview',
    templateUrl: './full-modal-preview.component.html',
})
export class FullModalPreviewComponent {}
