/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
    Type,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    GenericHdrMatchComponent,
    HttpMethodMatchComponent,
    IMatchOption,
    IpAddrMatchComponent,
    IpReputationTypeMatchComponent,
    PathOrQueryMatchComponent,
    PortMatchComponent,
    ProtocolTypeMatchComponent,
} from 'ng/modules/match';

import { WafPolicyAllowlistRuleConfigItem } from 'ajs/modules/waf';
import * as l10n from './waf-policy-allowlist-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the WafPolicyAllowlistRule message item modal.
 * @author alextsg
 */
@Component({
    selector: 'waf-policy-allowlist-rule-modal',
    templateUrl: './waf-policy-allowlist-rule-modal.component.html',
})
export class WafPolicyAllowlistRuleModalComponent {
    /**
     * WafPolicyAllowlistRuleConfigItem instance.
     */
    @Input()
    public editable: WafPolicyAllowlistRuleConfigItem;

    /**
     * Called when the user wants to save the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Called when the user wants to cancel editing the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * List of match options the user is able to add.
     * TODO: Allow only 'client_ip', 'method', 'path', and 'host_hdr' matches. Remove 'hdrs' - this
     * is currently added only for testing purposes.
     */
    public matchOptions: IMatchOption[];

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.matchOptions = [
            {
                component: GenericHdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.hostHeaderLabel),
                objectType: 'MatchTarget',
                fieldName: 'host_hdr',
            },
            {
                component: IpAddrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.clientIpAddressLabel),
                objectType: 'MatchTarget',
                fieldName: 'client_ip',
            },
            {
                component: GenericHdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.headerLabel),
                objectType: 'MatchTarget',
                fieldName: 'hdrs',
            },
            {
                component: GenericHdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.cookieLabel),
                objectType: 'MatchTarget',
                fieldName: 'cookie',
            },
            {
                component: ProtocolTypeMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.protocolLabel),
                objectType: 'MatchTarget',
                fieldName: 'protocol',
            },
            {
                component: IpReputationTypeMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.ipReputationLabel),
                objectType: 'MatchTarget',
                fieldName: 'ip_reputation_type',
            },
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                componentBindings: {
                    disallowStringGroups: true,
                },
                label: this.l10nService.getMessage(l10nKeys.pathLabel),
                objectType: 'MatchTarget',
                fieldName: 'path',
            },
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                componentBindings: {
                    disallowStringGroups: true,
                },
                label: this.l10nService.getMessage(l10nKeys.queryLabel),
                subLabel: this.l10nService.getMessage(l10nKeys.querySubLabel),
                objectType: 'MatchTarget',
                fieldName: 'query',
            },
            {
                component: HttpMethodMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.httpMethodLabel),
                objectType: 'MatchTarget',
                fieldName: 'method',
            },
            {
                component: PortMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.servicePortLabel),
                objectType: 'MatchTarget',
                fieldName: 'vs_port',
            },
        ];
    }

    /**
     * Handler for saving the modal.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Handler to cancel editing the modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }
}
