/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'security';

export const pingAccessAgentModalBreadcrumbTitle = `${prefix}.pingAccessAgentModalBreadcrumbTitle`;

export const ENGLISH = {
    [pingAccessAgentModalBreadcrumbTitle]: 'PingAccess Agent',
};
