/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { ClrDatagridStateInterface } from '@clr/angular';

import {
    CdkDragDrop,
    moveItemInArray,
} from '@angular/cdk/drag-drop';

import {
    isEmpty,
    isUndefined,
} from 'underscore';

import {
    IAviDataGridConfig,
    IAviDataGridRow,
    IRowReorder,
} from './avi-data-grid.types';

import './avi-data-grid.component.less';

/**
 * @description
 *     Data grid component which makes use of avi-data-grid-base component to support basic list
 *     data displaying.
 *
 *     When using custom templates for cells, we need to use templateRefs as creating a template
 *     from a string is not supported in Angular. This means that the parent component needs to
 *     create a templateRef variable, then pass it into config.fields in ngAfterViewInit. The outlet
 *     context contains the row and the index.
 *
 *     Please refer to avi-data-grid.stories.ts for an example.
 *
 *     Note: Clarity's datagrid has an issue with displaying columns when rows are empty. If you try
 *     to update fields while rows is an empty array, those fields will not get displayed. As a
 *     workaround, we use the afterViewInit flag to render the clarity datagrid, to allow the parent
 *     component to update its fields in its own ngAfterViewInit hook.
 *
 * @author alextsg, Zhiqian Liu
 */
@Component({
    selector: 'avi-data-grid',
    templateUrl: './avi-data-grid.component.html',
})
export class AviDataGridComponent {
    /**
     * Grid configuration object containing getRowId, multipleactions, etc.
     */
    @Input()
    public config: IAviDataGridConfig;

    /**
     * Total data sent to the grid.
     */
    @Input()
    public rows: IAviDataGridRow[] = [];

    /**
     * Grid Title sent to the grid.
     */
    @Input()
    public gridTitle: string;

    /**
     * Fires on row selection change.
     */
    @Output()
    public onSelectionChange = new EventEmitter<IAviDataGridRow[]>();

    /**
     * Fires on row reordering.
     */
    @Output()
    public onRowOrderChange = new EventEmitter<IRowReorder>();

    /**
     * Partial of the total data to be displayed in the grid for one page.
     */
    public get displayedRows(): IAviDataGridRow[] {
        if (!this.rows || isUndefined(this.dataGridState)) {
            return [];
        }

        if (isEmpty(this.dataGridState)) {
            return this.rows;
        }

        const { page: { size, current } } = this.dataGridState;

        return this.rows.slice(size * (current - 1), size * current);
    }

    /**
     * Latest data grid state object containing info about pagination, sorting and filtering.
     */
    private dataGridState: ClrDatagridStateInterface;

    /**
     * Called on row selection change.
     * Help as a relay to bubble up the onSelectionChange output from avi-data-grid-base to the
     * parent using this avi-data-grid component.
     */
    public onRowSelectionChange(rows: IAviDataGridRow[]): void {
        this.onSelectionChange.emit(rows);
    }

    /**
     * Update the local data grid state with the latest one bubbled up from avi-data-grid-base.
     */
    public updateLocalDataGridState(state: ClrDatagridStateInterface): void {
        this.dataGridState = state;
    }

    /**
     * Changes indexes of Items and emits row change event to parent.
     */
    public handleRowOrderChange(event: CdkDragDrop<IAviDataGridRow[]>): void {
        const { previousIndex, currentIndex } = event;

        // If onRowOrderChange has observers,
        // Index change should be performed by onRowOrderChange callback.
        // else reorder the grid here itself.
        if (!this.onRowOrderChange.observers.length) {
            moveItemInArray(this.rows, previousIndex, currentIndex);
        } else {
            this.onRowOrderChange.emit({
                row: this.rows[previousIndex],
                currentIndex,
                previousIndex,
            });
        }
    }
}
