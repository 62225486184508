/**
 * @module avi/dataModel
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { isString } from 'underscore';
import { AviModalService } from 'ajs/modules/core/services/avi-modal';
import {
    IGenericMessageItemConfig,
    IMessageBaseArgs,
    MessageBase,
} from 'ajs/modules/data-model/factories';
import { Constructor } from '../../../declarations/globals.d';

// TODO: Turn these into tokens exported from the folder the dependencies are registered. On first
// attempt, importing from the module folder caused unit tests to fail.
const AVI_MODAL = 'AviModal';

export type TWindowElement = string | Type<Component>;
export type TModalBindings = Record<string, any>;

interface IEditableMessageBaseArgs<K> extends IMessageBaseArgs<K> {
    /**
     * Modal element to open.
     */
    windowElement?: TWindowElement;
}

interface IEditableMessageItem {
    dismiss(): void;
    edit(
        windowElement: TWindowElement,
        modalBindings: TModalBindings,
    ): Observable<this>;
    openModal(windowElement: TWindowElement, modalBindings: TModalBindings): void;
    closeModal(windowElement: TWindowElement): void;
}

type IEditable<K, X extends Constructor<MessageBase<K>>> = X & IEditableMessageItem &
(new (...args: any[]) => X & IEditableMessageItem);

/**
 * Mixin to add edit functionality. Applied to MessageBase in the MessageItem definition so that all
 * message items have these methods. This mixin uses the AviModal service to open modal1.0 modals,
 * but applying withFullModalMixin on top of the MessageItem will override the openModal and
 * closeModal methods so that modal 2.0 is used instead.
 *
 * @example
 * Used in the definition of MessageItem:
 * ```
 * class MessageItem extends withEditMixin(MessageBase) {}
 * ```
 *
 * @author alextsg
 */
export function withEditMixin<
    S extends IGenericMessageItemConfig,
    BaseClass extends Constructor<MessageBase<S>>
>(BaseClass: BaseClass): IEditable<S, BaseClass> {
    class EditableMessageItem extends BaseClass implements IEditableMessageItem {
        public static ajsDependencies = [
            AVI_MODAL,
        ];

        /**
         * Modal element to open.
         */
        protected readonly windowElement: TWindowElement;

        constructor(...args: any[]) {
            super(...args);

            const [{ windowElement }] = args;

            this.windowElement = windowElement || this.windowElement;
        }

        /**
         * Edits the message item and returns an Observable for subscription. The reason an
         * observable is returned is that we need to keep listening for a submit until success or
         * the user closes the modal. If the save request fails, the user needs to be able to modify
         * fields and retry the submission. A promise would only resolve/reject just once.
         */
        public edit(
            windowElement: TWindowElement = this.windowElement,
            modalBindings: TModalBindings = {},
        ): Observable<this> {
            if (!windowElement) {
                throw new Error('windowElement is missing.');
            }

            return new Observable(subscriber => {
                const clonedEditable = this.clone();
                const bindings = {
                    editable: clonedEditable,
                    onCancel: () => subscriber.error(),
                    onSubmit: () => subscriber.next(clonedEditable),
                    ...modalBindings,
                };

                this.openModal(windowElement, bindings);
            });
        }

        /**
         * Calls this.closeModal to close the modal. Exists to match the dismiss method on Item.
         */
        public dismiss(windowElement: TWindowElement = this.windowElement): void {
            this.closeModal(windowElement);
        }

        /**
         * Opens the modal through the AviModal service. Can be overridden with FullModal service
         * methods.
         */
        public openModal(windowElement: TWindowElement, modalBindings: TModalBindings): void {
            if (!isString(windowElement)) {
                throw new Error('Trying to use aviModal.open without a string type windowElement');
            }

            const aviModal: AviModalService = this.getAjsDependency_(AVI_MODAL);

            aviModal.open(windowElement, modalBindings);
        }

        /**
         * Closes the modal through the AviModal service. Can be overridden with FullModal service
         * methods.
         */
        public closeModal(windowElement: TWindowElement): void {
            if (!isString(windowElement)) {
                throw new Error('Trying to use aviModal.open without a string type windowElement');
            }

            const aviModal: AviModalService = this.getAjsDependency_(AVI_MODAL);

            aviModal.destroy(windowElement);
        }

        /** @override */
        protected getCloneArgs(): IEditableMessageBaseArgs<S> {
            const args = super.getCloneArgs();

            return {
                ...args,
                windowElement: this.windowElement,
            };
        }
    }

    return EditableMessageItem as unknown as IEditable<S, BaseClass>;
}
