/**
 * @module ServiceEngineGroup
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/utils/ajsDependency';

import {
    SEGroup,
    segroupClusterHostConfigOptions,
    SEGroupCollection,
    segroupNsxtPlacementScopeConfigContainerOptions,
    segroupNsxtPlacementScopeConfigOptions,
} from '.';

import { SE_GROUP_COLLECTION_TOKEN } from './se-group.tokens';

const serviceEngineModule = angular.module('avi/service-engine-group');
const factories = [
    {
        dependency: SEGroup,
        name: 'SEGroup',
    },
    {
        dependency: SEGroupCollection,
        name: SE_GROUP_COLLECTION_TOKEN,
    },
];

const components = [
    {
        name: 'segroupNsxtPlacementScopeConfig',
        options: segroupNsxtPlacementScopeConfigOptions,
    },
    {
        name: 'segroupNsxtPlacementScopeConfigContainer',
        options: segroupNsxtPlacementScopeConfigContainerOptions,
    },
    {
        name: 'segroupClusterHostConfig',
        options: segroupClusterHostConfigOptions,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(serviceEngineModule, 'factory', name, dependency);
});

components.forEach(({ name, options }) => serviceEngineModule.component(name, options));
