/**
 * Module for Templates>Security.
 * @preferred
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    authProfileCreateController,
    authProfileJwtSettingsOptions,
    AUTH_PROFILE_JWT_SETTINGS_TOKEN,
    Certificate,
    CertificateCollection,
    certificateExpanderOptions,
    CertificateManagement,
    CertificateManagementCollection,
    certificateManagementExpanderOptions,
    CERTIFICATE_COLLECTION_TOKEN,
    CERTIFICATE_ITEM_TOKEN,
    CERTIFICATE_MANAGEMENT_COLLECTION_TOKEN,
    CERTIFICATE_MANAGEMENT_ITEM_TOKEN,
    CRLConfigItem,
    CRL_CONFIG_ITEM_TOKEN,
    CustomParamsConfigItem,
    HardwareSecurityModuleConfigItem,
    HSMAwsCloudHsmConfigItem,
    HSMGroup,
    HSMGroupCollection,
    HSMSafenetClientInfoConfigItem,
    HSMSafenetLunaConfigItem,
    HSMSafenetLunaServerConfigItem,
    JWTServerProfile,
    JWTServerProfileCollection,
    jwtServerProfileCollectionToken,
    jwtServerProfileListPageOptions,
    jwtServerProfileListPageToken,
    jwtServerProfileToken,
    PingAccessAgentProfile,
    PingAccessAgentProfileCollection,
    PING_ACCESS_AGENT_PROFILE_COLLECTION_TOKEN,
    PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
    PKIProfile,
    PKIProfileCollection,
    PKI_PROFILE_COLLECTION_TOKEN,
    PKI_PROFILE_ITEM_TOKEN,
    SSLCertificateConfigItem,
    SSLKeyParamsConfigItem,
    SSLProfile,
    SSLProfileCollection,
    SSLRatingConfigItem,
    SSLVersionConfigItem,
    SSL_CERTIFICATE_CONFIG_ITEM_TOKEN,
    SSL_KEY_PARAMS_CONFIG_ITEM_TOKEN,
    SSL_PROFILE_COLLECTION_TOKEN,
    SSL_PROFILE_ITEM_TOKEN,
    SSL_RATING_CONFIG_ITEM_TOKEN,
    SSL_VERSION_CONFIG_ITEM_TOKEN,
} from '.';

import {
    CUSTOM_PARAMS_CONFIG_ITEM_TOKEN,
    HARDWARE_SECURITY_MODULE_CONFIG_ITEM_TOKEN,
    HSM_AWS_CLOUD_HSM_CONFIG_ITEM_TOKEN,
    HSM_GROUP_COLLECTION_TOKEN,
    HSM_GROUP_ITEM_TOKEN,
    HSM_SAFENET_CLIENT_INFO_CONFIG_ITEM_TOKEN,
    HSM_SAFENET_LUNA_CONFIG_ITEM_TOKEN,
    HSM_SAFENET_LUNA_SERVER_CONFIG_ITEM_TOKEN,
} from './security.tokens';

const securityModule = angular.module('avi/security');

const components = [{
    name: 'certificateExpander',
    options: certificateExpanderOptions,
}, {
    name: 'certificateManagementExpander',
    options: certificateManagementExpanderOptions,
}, {
    name: jwtServerProfileListPageToken,
    options: jwtServerProfileListPageOptions,
}, {
    name: AUTH_PROFILE_JWT_SETTINGS_TOKEN,
    options: authProfileJwtSettingsOptions,
}];

components.forEach(({ name, options }) => securityModule.component(name, options));

const controllers = [
    {
        name: 'AuthProfileCreateController',
        controller: authProfileCreateController,
    },
];

const factories = [
    {
        factory: JWTServerProfileCollection,
        name: jwtServerProfileCollectionToken,
    },
    {
        factory: JWTServerProfile,
        name: jwtServerProfileToken,
    },
    {
        factory: PingAccessAgentProfileCollection,
        name: PING_ACCESS_AGENT_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: PingAccessAgentProfile,
        name: PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
    },
    {
        factory: SSLProfile,
        name: SSL_PROFILE_ITEM_TOKEN,
    },
    {
        factory: SSLProfileCollection,
        name: SSL_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: PKIProfile,
        name: PKI_PROFILE_ITEM_TOKEN,
    },
    {
        factory: SSLCertificateConfigItem,
        name: SSL_CERTIFICATE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: CRLConfigItem,
        name: CRL_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PKIProfileCollection,
        name: PKI_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: CertificateManagementCollection,
        name: CERTIFICATE_MANAGEMENT_COLLECTION_TOKEN,
    },
    {
        factory: CertificateManagement,
        name: CERTIFICATE_MANAGEMENT_ITEM_TOKEN,
    },
    {
        factory: CertificateCollection,
        name: CERTIFICATE_COLLECTION_TOKEN,
    },
    {
        factory: Certificate,
        name: CERTIFICATE_ITEM_TOKEN,
    },
    {
        factory: SSLVersionConfigItem,
        name: SSL_VERSION_CONFIG_ITEM_TOKEN,
    },
    {
        factory: SSLRatingConfigItem,
        name: SSL_RATING_CONFIG_ITEM_TOKEN,
    },
    {
        factory: SSLKeyParamsConfigItem,
        name: SSL_KEY_PARAMS_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMGroupCollection,
        name: HSM_GROUP_COLLECTION_TOKEN,
    },
    {
        factory: HSMGroup,
        name: HSM_GROUP_ITEM_TOKEN,
    },
    {
        factory: HardwareSecurityModuleConfigItem,
        name: HARDWARE_SECURITY_MODULE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMSafenetLunaConfigItem,
        name: HSM_SAFENET_LUNA_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMSafenetLunaServerConfigItem,
        name: HSM_SAFENET_LUNA_SERVER_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMSafenetClientInfoConfigItem,
        name: HSM_SAFENET_CLIENT_INFO_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HSMAwsCloudHsmConfigItem,
        name: HSM_AWS_CLOUD_HSM_CONFIG_ITEM_TOKEN,
    },
    {
        factory: CustomParamsConfigItem,
        name: CUSTOM_PARAMS_CONFIG_ITEM_TOKEN,
    },
];

controllers.forEach(({ name, controller }) => securityModule.controller(name, controller));

factories.forEach(({ name, factory }) => {
    initAjsDependency(securityModule, 'factory', name, factory);
});
