/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'service-engine-configuration';
const prefix = `${moduleName}.${componentName}`;

export const templateServiceEngineGroupInputLabel = `${prefix}.templateServiceEngineGroupInputLabel`;
export const templateSegGroupDropdownPlaceholder = `${prefix}.templateSegGroupDropdownPlaceholder`;
export const templateSegGroupHelperText = `${prefix}.templateSegGroupHelperText`;

export const ENGLISH = {
    [templateServiceEngineGroupInputLabel]: 'Template Service Engine Group',
    [templateSegGroupDropdownPlaceholder]: 'Select Template Service Engine Group',
    [templateSegGroupHelperText]: 'The above template can be set once the cloud is created.',
};
