/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'security';

export const hsmGroupModalBreadcrumbTitle = `${prefix}.hsmGroupModalBreadcrumbTitle`;
export const thalesNetHsmModalBreadcrumbTitle = `${prefix}.thalesNetHsmModalBreadcrumbTitle`;
export const safenetLunaServerModalBreadcrumbTitle = `${prefix}.safenetLunaServerModalBreadcrumbTitle`;

export const ENGLISH = {
    [hsmGroupModalBreadcrumbTitle]: 'HSM Group',
    [thalesNetHsmModalBreadcrumbTitle]: 'Thales netHSM',
    [safenetLunaServerModalBreadcrumbTitle]: 'Safenet Luna Server',
};
