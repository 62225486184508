/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    StringGroupItem,
    STRING_GROUP_ITEM_TOKEN,
} from 'ajs/modules/groups';

const $injector = '$injector';

export const StringGroupItemProvider = {
    deps: [$injector],
    provide: StringGroupItem,
    useFactory(injector: Injector): typeof StringGroupItem {
        return injector.get(STRING_GROUP_ITEM_TOKEN);
    },
};
