/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf-profile';
const componentName = 'waf-profile-content-type-mapping-grid';
const prefix = `${moduleName}.${componentName}`;

export const contentTypeMappingGridTitle = `${prefix}.contentTypeMappingGridTitle`;
export const contentTypeInputLabel = `${prefix}.contentTypeInputLabel`;
export const contentTypeInputPlaceholder = `${prefix}.contentTypeInputPlaceholder`;
export const requestParserDropdownLabel = `${prefix}.requestParserDropdownLabel`;
export const requestParserDropdownPlaceholder = `${prefix}.requestParserDropdownPlaceholder`;
export const addContentTypeMappingButtonLabel = `${prefix}.addContentTypeMappingButtonLabel`;
export const removeContentTypeMappingButtonLabel = `${prefix}.removeContentTypeMappingButtonLabel`;

export const ENGLISH = {
    [contentTypeMappingGridTitle]: 'Content Type Mapping: ({0})',
    [contentTypeInputLabel]: 'Content Type',
    [contentTypeInputPlaceholder]: 'Enter Content String',
    [requestParserDropdownLabel]: 'Request Parser',
    [requestParserDropdownPlaceholder]: 'Select Request Parser',
    [addContentTypeMappingButtonLabel]: 'Add',
    [removeContentTypeMappingButtonLabel]: 'Remove',
};
