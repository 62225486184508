/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { WafDataFileConfigItem } from 'ajs/modules/waf';

import * as l10n from './waf-data-file-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal Component for Waf Data File Modal.
 *
 * @author Kondiparthi Shanmukha Sarath.
 */
@Component({
    selector: 'waf-data-file-modal',
    templateUrl: './waf-data-file-modal.component.html',
})
export class WafDataFileModalComponent implements OnInit {
    /**
     * WafDataFileConfig ObjectTypeItem Instance.
     */
    @Input()
    public editable: WafDataFileConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<WafDataFileConfigItem>();

    /**
     * WafDataFile object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Waf Data file modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setting the objectType string.
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on Submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
