/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ITier1LogicalRouterInfo } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

export class Tier1LogicalRouterInfoConfigItem extends MessageItem<ITier1LogicalRouterInfo> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'Tier1LogicalRouterInfo',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.tier1_lr_id);
    }

    /**
     * Clears the configured Segment ID.
     */
    public clearSegmentID(): void {
        this.config.segment_id = undefined;
    }

    /**
     * Clears both configured Tier1LR ID and Segment ID.
     */
    public clearFields(): void {
        this.clearTier1LRID();
        this.clearSegmentID();
    }

    /**
     * Getter for tier1 logical router id.
     */
    public get tier1LRID(): string {
        const { tier1_lr_id: tier1LRID } = this.config;

        return tier1LRID;
    }

    /**
     * Clears the configured Tier1LR ID.
     */
    private clearTier1LRID(): void {
        this.config.tier1_lr_id = undefined;
    }
}
