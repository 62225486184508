/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'custom-params-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleValue = `${prefix}.columnTitleValue`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const columntitleSensitive = `${prefix}.columntitleSensitive`;
export const columntitleDynamic = `${prefix}.columntitleDynamic`;
export const removeActionLabel = `${prefix}.removeActionLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleValue]: 'Value',
    [nameInputPlaceholder]: 'Enter Name',
    [valueInputLabel]: 'Enter Value',
    [columntitleSensitive]: 'Sensitive',
    [columntitleDynamic]: 'Dynamic',
    [removeActionLabel]: 'Remove',
};
