/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'no-orchestrator-configuration.component';
const prefix = `${moduleName}.${componentName}`;

export const noOrchestratorSectionTitle = `${prefix}.noOrchestratorSectionTitle`;
export const dhcpSectionHeader = `${prefix}.dhcpSettingSectionHeader`;
export const routesSectionHeader = `${prefix}.routesSectionHeader`;
export const useIp4DhcpCheckboxLabel = `${prefix}.useIp4DhcpCheckboxLabel`;
export const useIp6DhcpCheckboxLabel = `${prefix}.useIp6DhcpCheckboxLabel`;
export const enableVipStaticRoutesCheckboxLabel = `${prefix}.enableVipStaticRoutesCheckboxLabel`;
export const preferStaticRoutesCheckboxLabel = `${prefix}.preferStaticRoutesCheckboxLabel`;

export const ENGLISH = {
    [noOrchestratorSectionTitle]: 'No Orchestrator',
    [dhcpSectionHeader]: 'DHCP',
    [routesSectionHeader]: 'Routes',
    [useIp4DhcpCheckboxLabel]: 'Use IPv4 DHCP',
    [useIp6DhcpCheckboxLabel]: 'Use IPv6 DHCP',
    [enableVipStaticRoutesCheckboxLabel]: 'Enable VIP Static Routes',
    [preferStaticRoutesCheckboxLabel]: 'Prefer Static Routes',
};
