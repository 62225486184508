/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vs-vip-fqdn';
const prefix = `${moduleName}.${componentName}`;

export const applicationDomainInputLabel = `${prefix}.applicationDomainInputLabel`;
export const applicationDomainInputPlaceholder = `${prefix}.applicationDomainInputPlaceholder`;
export const domainNameSelectPlaceholder = `${prefix}.domainNameSelectPlaceholder`;

export const ENGLISH = {
    [applicationDomainInputLabel]: 'Application Domain',
    [applicationDomainInputPlaceholder]: 'Enter Hostname',
    [domainNameSelectPlaceholder]: 'Select Domain Name',
};
