/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'auto-scale';

export const autoScalePolicyModalBreadcrumbTitle = `${prefix}.autoScalePolicyModalBreadcrumbTitle`;

export const ENGLISH = {
    [autoScalePolicyModalBreadcrumbTitle]: 'Server AutoScale Policy',
};
