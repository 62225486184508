/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './waf-policy-psm-group-modal.l10n';
import './waf-policy-psm-group-modal.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias module:waf/wafPolicyPsmGroupModal
 * @private
 */
class WafPolicyPsmGroupModalComponent {
    constructor(
        dropDownUtils,
        Regex,
        l10nService,
    ) {
        this.wafActions = dropDownUtils.getEnumDropdownOptions('WafAction');

        // These values are unsupported by the backend, not yet annotated in the protobuf. Update
        // this using Schema after this has been updated.
        this.hitHiddenActions = ['WAF_ACTION_BLOCK'];
        this.missHiddenActions = ['WAF_ACTION_ALLOW_PARAMETER'];
        this.Regex = Regex;
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        if (this.isLearningGroup && !this.editable.id) {
            this.editable.isLearningGroup = true;
        }
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     * @returns {number}
     */
    getEnableState() {
        return this.editable.getConfig().enable ? 1 : 0;
    }

    /**
     * Return true to disable the Learning group checkbox.
     * @returns {boolean}
     */
    disableLearning() {
        return this.editable.id || this.disableLearningToggle;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    handleClickEnable() {
        this.editable.getConfig().enable = !this.editable.getConfig().enable;
    }

    /**
     * Handler for clicking the Submit button. If this.onSubmit exists, the modal was opened from
     * the WAF Policy modal, so we should not make a save request, but pass this.editable to
     * onSubmit to be saved as group_refs_data.
     */
    handleSubmit() {
        if (this.onSubmit) {
            this.onSubmit({ group: this.editable });
        } else {
            this.editable.submit();
        }
    }

    /**
     * Handler for clicking the close button. If this.onCancel exists, the modal was opened from
     * the WAF Policy modal.
     */
    handleCancel() {
        if (this.onCancel) {
            this.onCancel();
        } else {
            this.editable.dismiss(true);
        }
    }
}

WafPolicyPsmGroupModalComponent.$inject = [
    'dropDownUtils',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  wafPolicyPsmGroupModal
 * @description Component for a WAF Policy PSM Group modal.
 * @param {WafPolicyPsmGroup} editable
 * @param {Function} [onSubmit=] - Called over editable.submit() if passed.
 * @param {Function} [onCancel=] - Called over editable.dismiss(true) if passed.
 * @param {Function} closeModal - Called to close this modal.
 * @param {string[]} [breadcrumbs=] - Array of strings to be displayed as breadcrumbs.
 * @param {boolean} [isLearningGroup=] - True to set learning to true on creation only.
 * @param {boolean} [disableLearningToggle=] - True to disable the learning checkbox.
 * @module waf/wafPolicyPsmGroupModal
 * @author alextsg
 */
angular.module('aviApp').component('wafPolicyPsmGroupModal', {
    bindings: {
        editable: '<',
        onSubmit: '&?',
        onCancel: '&?',
        closeModal: '&',
        breadcrumbs: '<?',
        isLearningGroup: '<?',
        disableLearningToggle: '<?',
    },
    controller: WafPolicyPsmGroupModalComponent,
    templateUrl: 'src/components/modals/waf-policy-psm-group-modal/' +
        'waf-policy-psm-group-modal.component.html',
});
