/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { HSMSafenetLuna } from 'object-types';
import { IHSMSafenetLuna } from 'generated-types';
import { HSMSafenetLunaServerConfigItem } from './hsm-safenet-luna-server.config-item.factory';
import { HSMSafenetClientInfoConfigItem } from './hsm-safenet-client-info.config-item.factory';

type THSMSafenetLunaPartial = Omit<IHSMSafenetLuna, 'server' | 'node_info'>;

interface IHSMSafenetLunaConfig extends THSMSafenetLunaPartial {
    server?: RepeatedMessageItem<HSMSafenetLunaServerConfigItem>;
    node_info?: RepeatedMessageItem<HSMSafenetClientInfoConfigItem>;
}

/**
 * @description HSM Safenet Luna Message Item.
 * @author vgohil
 */
export class HSMSafenetLunaConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<IHSMSafenetLunaConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HSMSafenetLuna,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns Safenet Luna Server Repeated Message Item.
     */
    public get serverList(): RepeatedMessageItem<HSMSafenetLunaServerConfigItem> {
        return this.config.server;
    }

    /**
     * Method to add Safenet Luna Server Message Item.
     */
    public addServer(): void {
        this.addChildMessageItem({
            field: 'server',
        });
    }

    /**
     * Method to remove Safenet Luna Server Message Item.
     */
    public removeServer(safenetLunaServer: HSMSafenetLunaServerConfigItem): void {
        this.serverList.removeByMessageItem(safenetLunaServer);
    }

    /**
     * Method to edit Safenet Luna Server Message Item.
     */
    public editServer(
        safenetLunaServer: HSMSafenetLunaServerConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        this.editChildMessageItem({
            field: 'server',
            messageItem: safenetLunaServer,
            modalBindings: {
                editMode: true,
                ...modalBindings,
            },
        });
    }

    /**
     * Returns Safenet ClientInfo Repeated Message Item.
     */
    public get clientInfoList(): RepeatedMessageItem<HSMSafenetClientInfoConfigItem> {
        return this.config.node_info;
    }

    /**
     * Method to add Safenet ClientInfo Message Item.
     */
    public addClientInfo(): void {
        this.clientInfoList.add();
    }

    /**
     * Method to remove Safenet ClientInfo Message Item.
     */
    public removeClientInfo(clientInfo: HSMSafenetClientInfoConfigItem): void {
        this.clientInfoList.removeByMessageItem(clientInfo);
    }

    /**
     * Returns Safenet use_dedicated_network.
     */
    public get useDedicatedNetwork(): boolean {
        return this.config.use_dedicated_network;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'server',
            'node_info',
        ];
    }
}
