/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name aviApp.service:messageService
 * @element any
 * @description
 *     This service is used for hiding and displaying Html elements registered using
 *     {@link aviApp.directive:messenger} directive.
 */
angular.module('aviApp').service('messageService', MessageService);

MessageService.$inject = [
    '$document',
];

/**
 * Message Service constructor.
 * @constructor
 */
function MessageService($document) {
    this.$document_ = $document;
    /**
     * Hashtable keeps track of message id associated with its data object.
     * @type {Object<string, {visible: boolean, element: jQuery}>}
     */
    this.messages = {};
}

/**
 * Appends a compiled message to the "messages" div, then shows that message.
 * @param  {string} messageId - Message id used when creating
 *     {@link aviApp.directive:messenger}.
 * @param  {jQuery} element - Element associated with message id.
 */
MessageService.prototype.append = function(messageId, $element) {
    this.$document_.find('.messages').append($element);
    this.show(messageId);
};

/**
 * Informs if message with provided ID is visible.
 * @param {string} messageId
 * @returns {boolean}
 */
MessageService.prototype.messageIsVisible = function(messageId) {
    const messageData = this.messages[messageId];

    return !!(messageData && messageData.visible);
};

/**
 * Assigns message HTML jQuery element with specified message id.
 * @param {string} messageId - Message id used when creating
 *     {@link aviApp.directive:messenger}.
 * @param {jQuery} element - Element associated with message id.
 */
MessageService.prototype.register = function(messageId, element) {
    if (angular.isUndefined(messageId) || angular.isUndefined(element)) {
        throw new Error('Message ID and its element is required to register new message.');
    }

    const message = this.messages[messageId];

    if (message) {
        message.element = element;

        if (message.visible) {
            this.show(messageId);
        }
    } else {
        this.messages[messageId] = {
            element,
            visible: false,
        };
    }
};

/**
 * Removes specified message from messages table.
 * @param {string} messageId
 */
MessageService.prototype.remove = function(messageId) {
    if (!messageId) {
        console.warn('No message ID is specified to remove.');
    }

    const message = this.messages[messageId];

    if (message) {
        if (message.element) {
            message.element.detach();
        }

        delete this.messages[messageId];
    }
};

/**
 * Displays specified message element associated with specified message id.
 * @param {string} messageId
 */
MessageService.prototype.show = function(messageId) {
    const message = this.messages[messageId];

    if (message) {
        message.visible = true;

        if (message.element) {
            message.element.addClass('visible');
        }
    } else if (messageId) {
        // To prevent race-condition register message even if element doesn't exist yet
        this.messages[messageId] = {
            visible: true,
        };
    } else {
        console.warn(`Cannot display "${messageId}" message.`);
    }
};

/**
 * Hides specified message element by specified message id.
 * @param messageId
 */
MessageService.prototype.hide = function(messageId) {
    if (!messageId) {
        console.warn('No message ID is specified to hide.');
    }

    const message = this.messages[messageId];

    if (message) {
        message.visible = false;

        if (message.element) {
            message.element.removeClass('visible');
        }
    }
};
