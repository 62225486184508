/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'cloud-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameLabel = `${prefix}.nameLabel`;
export const nameLabelPlaceholder = `${prefix}.nameLabelPlaceholder`;
export const typeLabel = `${prefix}.typeLabel`;
export const typeHelperText = `${prefix}.typeHelperText`;
export const dhcpCheckboxLabel = `${prefix}.dhcpCheckboxLabel`;
export const objNamePrefixLabel = `${prefix}.objNamePrefixLabel`;
export const nsxtTabTitle = `${prefix}.nsxtTabTitle`;
export const ipamDnsTabTitle = `${prefix}.ipamDnsTabTitle`;
export const serviceEngineTabTitle = `${prefix}.serviceEngineTabTitle`;
export const linuxTabTitle = `${prefix}.linuxTabTitle`;
export const autoScalePollingIntervalLabel = `${prefix}.autoScalePolingIntervalLabel`;
export const autoScalePollingIntervalPlaceholder = `${prefix}.autoScalePollingIntervalPlaceholder`;
export const secondsHelperText = `${prefix}.secondsHelperText`;
export const staticRouteCheckboxLabel = `${prefix}.staticRouteLabel`;
export const azureTabTitle = `${prefix}.azureTabTitle`;
export const dnsTabTitle = `${prefix}.dnsTabTitle`;
export const tagsTabTitle = `${prefix}.tagsTabTitle`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Cloud: {0}',
    [modalTitleNew]: 'New Cloud: {0}',
    [sectionTitleGeneral]: 'General',
    [nameLabel]: 'Name',
    [nameLabelPlaceholder]: 'Enter Name',
    [typeLabel]: 'Type',
    [typeHelperText]: "Changing the Cloud type is only supported from the Clouds page by clicking 'Convert Cloud Type'.",
    [dhcpCheckboxLabel]: 'DHCP',
    [objNamePrefixLabel]: 'Object Name Prefix',
    [nsxtTabTitle]: 'NSX-T',
    [ipamDnsTabTitle]: 'IPAM/DNS',
    [serviceEngineTabTitle]: 'Service Engine',
    [linuxTabTitle]: 'Linux',
    [autoScalePollingIntervalLabel]: 'Autoscale Polling Interval',
    [autoScalePollingIntervalPlaceholder]: 'Autoscale Polling Interval',
    [secondsHelperText]: 'Seconds',
    [staticRouteCheckboxLabel]: 'Use Static Routes for VIP Placement',
    [azureTabTitle]: 'Azure',
    [dnsTabTitle]: 'DNS',
    [tagsTabTitle]: 'Tags',
};
