/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

function seGroupFlavorListDataTransformerFactory(ListDataTransformer, naturalSort) {
    /**
     * Compares two SEGroup flavors using naturalSort by passing down their name properties.
     * @param {string} nameA
     * @param {string} nameB
     * @return {number}
     * @protected
     */
    function seGroupFlavorComparator(
        { config: { name: nameA } },
        { config: { name: nameB } },
    ) {
        return naturalSort(nameA, nameB);
    }

    return class SEGroupFlavorListDataTransformer extends ListDataTransformer {
        /**
         * Sorts the list alphabetically. Esp useful since no search is provided by API.
         * @override
         **/
        processResponse(resp, requestParams) {
            resp = super.processResponse(resp, requestParams);

            const { data: { results } } = resp;

            results.sort(seGroupFlavorComparator);

            return resp;
        }
    };
}

/**
 * @ngdoc service
 * @name SEGroupFlavorListDataTransformer
 * @desc Data transformer for SEGroupFlavor collection.
 */
angular.module('aviApp').factory(
    'SEGroupFlavorListDataTransformer', [
        'ListDataTransformer',
        'naturalSort',
        seGroupFlavorListDataTransformerFactory,
    ],
);
