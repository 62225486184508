/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { SchedulerModalComponent } from 'ng/modules/system';
import { SCHEDULER_ITEM_TOKEN } from '../system.tokens';

/**
 * @description scheduler collection class.
 *
 * @author Ratan Kumar
 */
export class SchedulerCollection extends Collection {
    public static ajsDependencies = [
        SCHEDULER_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'scheduler',
            windowElement: SchedulerModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(SCHEDULER_ITEM_TOKEN);
    }
}

/* eslint no-underscore-dangle: 0 */
SchedulerCollection.prototype.defaultViewportSize_ = 1;
