/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './deactivate-bgp-peering-confirmation.l10n';
import template from './deactivate-bgp-peering-confirmation.component.html';
import './deactivate-bgp-peering-confirmation.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Confirmation dialog to be displayed when user tries to
 *      deactivate BGP-Peering in a VRFContext.
 *
 * @author Aravindh Nagarajan
 */
class DeactivateBgpPeeringConfirmationController {
    /**
     * Name of VRFContext object.
     */
    public vrfContextName: string;

    /**
     * Fires on confirm.
     */
    public onSubmit: () => void;

    /**
     * Fires on cancel.
     */
    public onCancel: () => void;

    /**
     * To close modal.
     */
    public closeModal: () => void;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for confirm button click.
     */
    public confirm(): void {
        this.onSubmit();
        this.closeModal();
    }

    /**
     * Handler for cancel button click.
     */
    public cancel(): void {
        this.onCancel();
        this.closeModal();
    }
}

DeactivateBgpPeeringConfirmationController.$inject = [
    'l10nService',
];

/**
 * Ajs name of the component.
 */
export const DEACTIVATE_BGP_PEERING_CONFIRMATION_COMPONENT_TOKEN =
    'deactivateBgpPeeringConfirmation';

export const deactivateBgpPeeringConfirmationComponentOptions = {
    bindings: {
        vrfContextName: '@',
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
    },
    controller: DeactivateBgpPeeringConfirmationController,
    template,
};
