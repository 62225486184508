/**
 * @module DataGridModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { copy } from 'angular';

import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { AviDropdownButtonPosition } from 'ng/shared/components';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { Auth, StringService } from 'ajs/modules/core/services';
import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from './avi-collection-data-grid.types';

import * as l10n from './avi-collection-data-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *     Collection Grid component, built using AviDataGridBase.
 *
 *     This component deals with the collection passed-in to show the Items in the collection as
 *     rows with item editing and creating functionalities.
 *
 * @example
 *
 *     this.webhookCollection = new WebhookCollection();
 *
 *     const { objectName } = this.webhookCollection;
 *
 *     this.webhookCollectionGridConfig = {
 *         id: `${objectName}-list-page`
 *         collection: this.webhookCollection,
 *         fields: [{
 *             id: 'name',
 *             label: l10nService.getMessage(l10nKeys.columnTitleName),
 *             sortBy: 'name',
 *             visibility: AviDataGridFieldVisibility.MANDATORY
 *             transform: row => row.getName(),
 *         }, {
 *             id: 'callback-url',
 *             label: l10nService.getMessage(l10nKeys.columnTitleCallbackUrl),
 *             sortBy: 'callback_url',
 *             transform: row => row.getConfig().callback_url,
 *         }, {
 *             id: 'verfication-token',
 *             label: l10nService.getMessage(l10nKeys.columnTitleVerificationToken),
 *             visibility: AviDataGridFieldVisibility.OPTIONAL,
 *             transform: row => row.getConfig().verification_token,
 *         }],
 *         layout: {
 *             placeholderMessage: 'No VRF context found!',
 *         },
 *         createActions: [{
 *             label: 'Basic Create',
 *             onClick: () => null,
 *         }, {
 *             label: 'Advanced Create',
 *             onClick: () => null,
 *         }],
 *         getRowId: (index, row: VRFContext) => row.id,
 *     };
 *
 *     <avi-collection-data-grid
 *         [config]="webhookCollectionGridConfig"
 *     >
 *         <avi-collection-data-grid_header>
 *             <h6>
 *                 Webhook Collection
 *             </h6>
 *         </avi-collection-data-grid_header>
 *     </avi-collection-data-grid>
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-collection-data-grid',
    templateUrl: './avi-collection-data-grid.component.html',
})
export class AviCollectionDataGridComponent implements OnInit, OnChanges, DoCheck {
    /**
     * Grid configuration object containing getRowId, multipleactions, etc.
     */
    @Input()
    public config: IAviCollectionDataGridConfig;

    /**
     * Fires on row selection change.
     */
    @Output()
    public onSelectionChange = new EventEmitter<IAviCollectionDataGridRow[]>();

    /**
     * Used to decide the true data grid config under the hood by adding essential functionalities
     * on top of the passed-in config.
     */
    public internalConfig: IAviCollectionDataGridConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Primary position for the dropdown of the create button.
     */
    public createDropdownPrimaryPosition = AviDropdownButtonPosition.BOTTOM_LEFT;

    /**
     * Getter of the collection bound to the data grid.
     */
    public get collection(): Collection {
        return this.internalConfig.collection;
    }

    /**
     * Show create button / dropdown button only when the collection is creatable and the config
     * doesn't appoint it hidden.
     */
    public get showCreate(): boolean {
        return this.collection.isCreatable() && !this.internalConfig.layout.hideCreate;
    }

    /**
     * Grey out the create button / dropdown button.
     */
    public get disableCreate(): boolean {
        return this.internalConfig.layout.disableCreate;
    }

    /**
     * Label for the create button. Use a default value if not configured.
     */
    public get createButtonLabel(): string {
        return this.internalConfig.layout.createButtonLabel ||
            this.l10nService.getMessage(l10nKeys.createButtonLabel);
    }

    /**
     * Whether to turn the create button to a menu dropdown.
     */
    public get useDropdownCreate(): boolean {
        return Boolean(this.internalConfig.createActions?.length);
    }

    /**
     * Latest data grid state object containing info about pagination, sorting and filtering.
     */
    private dataGridState: ClrDatagridStateInterface;

    public constructor(
        private readonly authService: Auth,
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setInternalConfigs();
        this.setInitialSorting();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { config } = changes;

        if (config && !config.isFirstChange()) {
            this.setInternalConfigs();
        }
    }

    /**
     * @override
     * Used to add a custom change detection for this.config.fields and update this.internalConfig.
     * This detection is needed because Angular is not able to detect any changes of fields since
     * it's not directly bound to the template, and this.internalConfig needs to be updated as the
     * original config changes.
     */
    public ngDoCheck(): void {
        if (this.config?.fields !== this.internalConfig.fields) {
            this.internalConfig.fields = this.config?.fields || [];

            this.setInternalConfigs();
        }
    }

    /**
     * Called on row selection change.
     * Help as a relay to bubble up the onSelectionChange output from avi-data-grid-base to the
     * parent using this avi-collection-data-grid component.
     */
    public onRowSelectionChange(rows: IAviCollectionDataGridRow[]): void {
        this.onSelectionChange.emit(rows);
    }

    /**
     * Update the local data grid state with the latest one bubbled up from avi-data-grid-base.
     * This method is called page init and everytime afterwards when pagination, sorting, filtering
     * info gets updated. Currently (08/20/21) used to load data based on pagination.
     * TODO: add load by filtering and sorting.
     */
    public updateLocalDataGridState(state: ClrDatagridStateInterface): void {
        this.dataGridState = state;

        this.loadCurrentPage();
    }

    /**
     * Search by field values.
     */
    public search(value: string): void {
        this.collection.search(value);
    }

    /**
     * Load collection on current page.
     */
    private loadCurrentPage(): void {
        const { sort, page: { size, current } } = this.dataGridState;

        if (sort) {
            const { by, reverse } = sort;

            const sortBy = reverse ? `-${by}` : by;

            // the type assertion is safe here as for now only sort by field name is supported
            this.collection.setSorting(sortBy as string, true);
        }

        this.collection.loadPage(size * (current - 1), size);
    }

    /**
     * Set the internal configs based on the pass-in one to ensure basic functionalities of a
     * collection grid.
     */
    private setInternalConfigs(): void {
        // get a deep copy of the pass-in config for further settings
        // but we'll save the overhead doing that for the collection
        const {
            collection,
            fields,
            ...props
        } = this.config;

        this.internalConfig = {
            collection,
            fields,
            ...copy(props),
        };

        // set basic collection grid operations
        this.setEditSingleAction();
        this.setRowSelectionDisable();
        this.setDeleteSingleAction();
        this.setDeleteMultipleAction();
        this.addTenantColumn();
    }

    /**
     * Add 'Edit' single action to the config if there isn't one and hideEdit is false.
     */
    private setEditSingleAction(): void {
        if (this.internalConfig.layout.hideEdit) {
            return;
        }

        if (!this.internalConfig.singleactions) {
            this.internalConfig.singleactions = [];
        }

        const { singleactions } = this.internalConfig;
        const hasEditSingleAction = singleactions.some(({ id }) => {
            const normalizedId = id.toLowerCase();

            return normalizedId === 'edit';
        });

        if (!hasEditSingleAction) {
            singleactions.push({
                id: 'edit',
                label: this.l10nService.getMessage(l10nKeys.editActionLabel),
                shape: 'pencil',
                onClick: (row: IAviCollectionDataGridRow) => row.edit(),
                disabled: (row: IAviCollectionDataGridRow) => !row.isEditable(),
            });
        }
    }

    /**
     * Add row selection (checkbox) disabling predicate if there isn't one.
     */
    private setRowSelectionDisable(): void {
        const { rowSelectionDisabled } = this.internalConfig;

        if (!rowSelectionDisabled) {
            this.internalConfig.rowSelectionDisabled =
                (row: IAviCollectionDataGridRow) => !row.isDroppable();
        }
    }

    /**
     * Add 'Delete' single action to the config if there isn't one and hideDelete is false.
     */
    private setDeleteSingleAction(): void {
        if (this.internalConfig.layout.hideDelete) {
            return;
        }

        if (!this.internalConfig.singleactions) {
            this.internalConfig.singleactions = [];
        }

        const { singleactions } = this.internalConfig;
        const hasDeleteSingleAction = singleactions.some(({ id }) => {
            const normalizedId = id.toLowerCase();

            return normalizedId === 'delete' || normalizedId === 'remove';
        });

        if (!hasDeleteSingleAction) {
            singleactions.push({
                id: 'delete',
                label: this.l10nService.getMessage(l10nKeys.deleteActionLabel),
                shape: 'trash',
                onClick: (row: IAviCollectionDataGridRow) => this.collection.dropItems(row),
                disabled: (row: IAviCollectionDataGridRow) => !row.isDroppable(),
            });
        }
    }

    /**
     * Add 'Delete' multiple action to the config if there isn't one.
     */
    private setDeleteMultipleAction(): void {
        if (!this.internalConfig.multipleactions) {
            this.internalConfig.multipleactions = [];
        }

        const { multipleactions } = this.internalConfig;
        const hasDeleteMultipleAction = multipleactions.some(({ id }) => {
            const normalizedId = id.toLowerCase();

            return normalizedId === 'delete' || normalizedId === 'remove';
        });

        if (!hasDeleteMultipleAction) {
            multipleactions.push({
                id: 'delete',
                label: this.l10nService.getMessage(l10nKeys.deleteActionLabel),
                onClick: (rows: IAviCollectionDataGridRow[]) => {
                    const rowsToDelete = rows.filter(row => row.isDroppable());

                    this.collection.dropItems(rowsToDelete);
                },
                // `selected` from `avi-data-grid-base` (`rows` here) is undefined when
                // `hideCheckboxes` is set to true
                disabled: (rows: IAviCollectionDataGridRow[]) => rows?.every(
                    row => !row.isDroppable(),
                ),
            });
        }
    }

    /**
     * Add a "Tenant" column to the grid under "All Tenants" mode.
     */
    private addTenantColumn(): void {
        if (this.authService.allTenantsMode() && !this.internalConfig.layout.hideTenantColumn) {
            this.internalConfig.fields.push({
                label: this.l10nService.getMessage(l10nKeys.tenantColumnLabel),
                id: 'tenant',
                transform: row => this.stringService.name(row.getTenantRef()) ||
                    row.getConfig().tenant,
            });
        }
    }

    /**
     * Set collection data grid's initial sorting order with the default one; either provided by the
     * config or from data source default which usually sorts by 'name'.
     */
    private setInitialSorting(): void {
        const { collection, defaultSorting } = this.internalConfig;

        if (defaultSorting && defaultSorting !== collection.getSorting()) {
            collection.setSorting(defaultSorting);
        }
    }
}
