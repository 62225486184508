/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import * as l10n from './avi-custom-collection-dropdown.l10n';

import './avi-custom-collection-dropdown.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component that renders collection-dropdown or input field based on user selection.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'avi-custom-collection-dropdown',
    templateUrl: './avi-custom-collection-dropdown.component.html',
})
export class AviCustomCollectionDropdownComponent implements OnInit {
    /**
     * Collection instance.
     */
    @Input()
    public collection: Collection;

    /**
     * Model value for collection-dropdown (two-way binding).
     */
    @Input()
    public collectionRef: string;

    /**
     * Model value for custom value input (two-way binding).
     */
    @Input()
    public customValue: string;

    /**
     * Required - True if either dropdown-ref or custom-value is a required.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Set through 'disabled' binding. Disables dropdown and custom input field.
     */
    @Input('disabled')
    private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || Boolean(disabled);
    }

    /**
     * Params to be passed to collection.create (Optional).
     */
    @Input()
    public createParams = {};

    /**
     * Collection-dropdown placeholder (Optional).
     */
    @Input()
    public dropdownPlaceholder: string;

    /**
     * Custom value input placeholder (Optional).
     */
    @Input()
    public inputPlaceholder: string;

    /**
     * True to disallow creating a new Item from collectionDropdown (Optional).
     */
    @Input()
    public disableCreate = false;

    /**
     * True to disallow editing a selected Item from collectionDropdown (Optional).
     */
    @Input()
    public disableEdit = false;

    /**
     * Emitter for collectionRef change.
     * Required for two-way binding.
     */
    @Output()
    public collectionRefChange = new EventEmitter<string>();

    /**
     * Emitter for customValue change.
     * Required for two-way binding.
     */
    @Output()
    public customValueChange = new EventEmitter<string>();

    /**
     * Controls Radio buttons.
     * If True, Collection-dropdown will be show & input will be hidden,
     * else viceversa.
     */
    public showCollectionDropdown = true;

    /**
     * Set through 'required' binding. Makes form field required.
     */
    public required = false;

    /**
     * Set through 'disabled' binding. Disables input and button.
     */
    public disabled = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.dropdownPlaceholder =
            l10nService.getMessage(l10nKeys.collectionDropdownDefaultPlaceholder);

        this.inputPlaceholder =
            l10nService.getMessage(l10nKeys.customInputPlaceholder);
    }

    /** @override */
    public ngOnInit(): void {
        this.showCollectionDropdown = !this.customValue;
    }

    /**
     * Handler for type (dropdown/input) radio button change.
     */
    public onTypeChange(): void {
        if (this.showCollectionDropdown) {
            this.customValue = undefined;

            this.onCustomValueChange();
        } else {
            this.collectionRef = undefined;

            this.onCollectionRefChange();
        }
    }

    /**
     * Emits collectionRef change event.
     */
    public onCollectionRefChange(): void {
        this.collectionRefChange.emit(this.collectionRef);
    }

    /**
     * Emits customValue change event.
     */
    public onCustomValueChange(): void {
        this.customValueChange.emit(this.customValue);
    }
}
