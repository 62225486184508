/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'alert';

export const alertEmailConfigBreadcrumbTitle = `${prefix}.alertEmailConfigBreadcrumbTitle`;
export const alertActionBreadcrumbTitle = `${prefix}.alertActionBreadcrumbTitle`;

export const ENGLISH = {
    [alertEmailConfigBreadcrumbTitle]: 'Email',
    [alertActionBreadcrumbTitle]: 'Alert Action',
};
