/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './analytics-profile-modal.less';
import { analyticsProfileAnalyticsTypeLabelHash }
    from '../../../../../js/constants/analytics-enabled-keys.constant';
import * as l10n from './analytics-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class AnalyticsProfileModalController {
    constructor(
        dropDownUtils,
        $element,
        schemaService,
        Regex,
        l10nService,
    ) {
        this.$element_ = $element;
        this.dropDownUtils_ = dropDownUtils;
        this.schemaService_ = schemaService;

        /**
         * @type {Regex}
         */
        this.regex = Regex;

        this.analyticsTypeOptions = [];

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const analyticsPropertyList = Object.keys(analyticsProfileAnalyticsTypeLabelHash);
        const analyticsProfileObjectType = 'AnalyticsProfile';
        const { schemaService_: schemaService } = this;

        this.analyticsTypeOptions =
            analyticsPropertyList.map(key => {
                const tooltip = schemaService.getFieldDescription(analyticsProfileObjectType, key);

                const label =
                    analyticsProfileAnalyticsTypeLabelHash[key];

                return this.dropDownUtils_.createOption(key, label, tooltip);
            });

        this.hsPerformanceBoostRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'hs_performance_boost');

        this.hsMaxAnomalyPenaltyRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'hs_max_anomaly_penalty');

        this.hsMaxResourcesPenaltyRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'hs_max_resources_penalty');

        this.hsMaxSecurityPenaltyRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'hs_max_security_penalty');

        this.hsSecurityChainInvalidityPenaltyRange = schemaService
            .getFieldRangeAsTuple(
                analyticsProfileObjectType,
                'hs_security_chain_invalidity_penalty',
            );

        this.hsSecurityHstsPenaltyRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'hs_security_hsts_penalty');

        this.connLossyTotalRexmtThresholdRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'conn_lossy_total_rexmt_threshold');

        this.connLossyTimeoRexmtThresholdRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'conn_lossy_timeo_rexmt_threshold');

        this.connLossyOooThresholdRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'conn_lossy_ooo_threshold');

        this.connLossyZeroWinSizeEventThresholdRange = schemaService
            .getFieldRangeAsTuple(
                analyticsProfileObjectType,
                'conn_lossy_zero_win_size_event_threshold',
            );

        this.connServerLossyTotalRexmtThresholdRange = schemaService
            .getFieldRangeAsTuple(
                analyticsProfileObjectType,
                'conn_server_lossy_total_rexmt_threshold',
            );

        this.connServerLossyOooThresholdRange = schemaService
            .getFieldRangeAsTuple(analyticsProfileObjectType, 'conn_server_lossy_ooo_threshold');

        this.connServerLossyZeroWinSizeEventThresholdRange = schemaService
            .getFieldRangeAsTuple(
                analyticsProfileObjectType,
                'conn_server_lossy_zero_win_size_event_threshold',
            );

        this.connServerLossyTimeoRexmtThresholdRange = schemaService
            .getFieldRangeAsTuple(
                analyticsProfileObjectType,
                'conn_server_lossy_timeo_rexmt_threshold',
            );
    }
}

AnalyticsProfileModalController.modalPortalClassName = 'avi-modal';

AnalyticsProfileModalController.$inject = [
    'dropDownUtils',
    '$element',
    'schemaService',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name analyticsProfileModal
 * @author Chitra
 * @param {AnalyticsProfile} editable - AnalyticsProfile item.
 * @param {Function} closeModal - Closes modal.
 * @description Creates/edits an AnalyticsProfile item
 */
angular.module('aviApp').component('analyticsProfileModal', {
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    controller: AnalyticsProfileModalController,
    templateUrl: 'src/components/templates/profiles/analytics/' +
        'analytics-profile-modal/analytics-profile-modal.html',
});
