/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createReducer, on } from '@ngrx/store';

import {
    IInfraCloudState,
    initialInfraCloudState,
} from './infra-cloud.state';

import { InfraCloudActions } from './infra-cloud-store.actions';

export const infraCloudStateReducer = createReducer(
    initialInfraCloudState,
    on(
        InfraCloudActions.setCloud,
        (state, action): IInfraCloudState => (
            {
                ...state,
                cloud: action.cloud,
            }
        ),
    ),
);
