/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './LaunchConfigListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name LaunchConfigListController
 * @description Controller for AutoScale Launch Config page.
 **/
angular.module('aviApp').controller('LaunchConfigListController', [
'$scope',
'CRUDGridConfig',
'AutoScaleLaunchConfigCollection',
'Regex',
'l10nService',
function(
    $scope,
    CRUDGridConfig,
    AutoScaleLaunchConfigCollection,
    Regex,
    l10nService,
) {
    this.Regex = Regex;

    this.collection = new AutoScaleLaunchConfigCollection();

    this.gridConfig = new CRUDGridConfig();

    this.gridConfig.collection = this.collection;

    /**
    * Get keys from source bundles for template usage.
    */
    $scope.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const { objectName } = this.gridConfig.collection;

    this.gridConfig.id = `${objectName}-list-page`;

    this.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }];

    this.gridConfig.layout = {
        hideEditColumns: true,
    };
}]);
