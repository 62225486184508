/**
 * Module containing persistence profile related components.
 * @preferred
 * @module PersistenceProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { VIPModule } from '@vmw/ngx-vip';
import { PersistenceProfileModalComponent } from '.';

@NgModule({
    declarations: [
        PersistenceProfileModalComponent,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        CommonModule,
        SharedModule,
        VIPModule,
    ],
    exports: [
        PersistenceProfileModalComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PersistenceProfileModule { }
