/**
 * @module avi/component-kit/grid
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import {
    AjsDependency,
    initAjsDependency,
} from 'ajs/js/utilities/ajsDependency';

import { IGridInfo, MyAccount } from 'ajs/modules/core/services/my-account';
import { ColumnWidthHash } from './grid-types';

/**
 * We store column-width hash of a grid with the following
 * key in myAccount.uiProperty.
 */
const columnWidthHashKey = 'columnWidths';

/**
 * We store sorting order of a grid with the following
 * key in myAccount.uiProperty.
 */
const sortOrderHashkey = 'columnSortOrder';

/**
 * @description
 *      Service to store grid related data (columnSize, sortingOrder) in myAccount.
 *
 * @author Aravindh Nagarajan
 */
export class GridDataStorageService extends AjsDependency {
    /**
     * MyAccount instance.
     */
    private myAccount: MyAccount;

    public constructor() {
        super();

        this.myAccount = this.getAjsDependency_('myAccount');
    }

    /**
     * Returns grid data for a grid id.
     */
    public getGridData(gridId: string): IGridInfo {
        const { grid: gridHash } = this.myAccount;

        if (!(gridId in gridHash)) {
            return null;
        }

        return gridHash[gridId];
    }

    /**
     * Returns Sorting order for a grid id. null if no information is stored.
     */
    public getGridSortOrder(gridId: string): string | null {
        const gridData = this.getGridData(gridId);

        if (!gridData || !(sortOrderHashkey in gridData)) {
            return null;
        }

        return gridData[sortOrderHashkey];
    }

    /**
     * Sets sortingOrder for a grid Id.
     */
    public setGridSortOrder(gridId: string, sortOrder: string): void {
        const { grid: gridHash } = this.myAccount;

        if (!(gridId in gridHash)) {
            gridHash[gridId] = {};
        }

        gridHash[gridId][sortOrderHashkey] = sortOrder;

        this.myAccount.saveUIProperty();
    }

    /**
     * Returns columnWidthHash for a grid from uiProperty using gridId.
     */
    public getGridColumnWidthHash(gridId: string): ColumnWidthHash | null {
        const gridData = this.getGridData(gridId);

        if (!gridData || !(columnWidthHashKey in gridData)) {
            return null;
        }

        return angular.copy(gridData[columnWidthHashKey]);
    }

    /**
     * Sets columnWidthHash for a gridId in uiProperty.
     */
    public setGridColumnWidthHash(gridId: string, columnWidthHash: ColumnWidthHash | null): void {
        const { grid: gridHash } = this.myAccount;

        if (!(gridId in gridHash)) {
            gridHash[gridId] = {};
        }

        if (columnWidthHash === null) {
            delete gridHash[gridId][columnWidthHashKey];
        } else {
            gridHash[gridId][columnWidthHashKey] = columnWidthHash;
        }

        this.myAccount.saveUIProperty();
    }
}

GridDataStorageService.ajsDependencies = [
    'myAccount',
];

initAjsDependency(
    angular.module('avi/component-kit/grid'),
    'service',
    'gridDataStorageService',
    GridDataStorageService,
);
