/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';
import { IPromise } from 'angular';
import { ClrFormLayout } from '@clr/angular';

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import {
    ALBServiceMode,
    IALBServicesConfig,
    IALBServicesUser,
} from 'generated-types';

import {
    Case,
    CportalService,
    IALBServicesCaseComment,
    IALBServicesCaseCommentsResponse,
    IError,
    IUserListApiResponse,
} from 'ajs/modules/cportal';

import {
    environmentHash,
    severityHash,
    typeHash,
} from 'ajs/modules/core/constants/case.constants';

import { IAviDropdownOption } from 'ng/shared/components';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { createDropdownOption } from 'ng/shared/utils';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './case-modal.l10n';
import './case-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const {
    SEVERITY_1,
    SEVERITY_2,
    SEVERITY_3,
    SEVERITY_4,
    SEVERITY_5,
} = severityHash;

const {
    CONFIGURATION_HELP,
    QUESTION,
    FEATURE_REQUEST,
} = typeHash;

/**
 * @description Modal component for creating/editing a Case.
 *
 * @author Ashish Verma, Rajawant Prajapati
 */

@Component({
    selector: 'case-modal',
    templateUrl: './case-modal.component.html',
})

export class CaseModalComponent implements OnInit {
    /**
     * Case Item currently opened in case modal.
     */
    @Input()
    public editable: Case;

    /**
     * Contact dropdown options.
     */
    public contactOptions: IAviDropdownOption[] = [];

    /**
     * Case environment dropdown options.
     */
    public readonly environmentsOptions: IAviDropdownOption[] = [];

    /**
     * Severity dropdown options.
     */
    public readonly severitiesOptions: IAviDropdownOption[] = [];

    /**
     * Case types dropdown options.
     */
    public readonly caseTypesOptions: IAviDropdownOption[] = [];

    /**
     * Flag to show and hide severity dropdown.
     * When selected case type is Configuration Help, Question or Feature Request
     * we will hide severity dropdown.
     */
    public displaySeverity = true;

    /**
     * Flag to show comment once they are loaded.
     */
    public loadingComments = false;

    /**
     * Comments List for the case.
     */
    public comments: IALBServicesCaseComment[] = [];

    /**
     * Flag to show Disable contact dropdown until option value is created.
     */
    public userDataLoading = false;

    /**
     * Portal mode to define the type of portal to which controller is connected.
     */
    public portalMode: ALBServiceMode;

    /**
     * Error information.
     */
    public error: IError;

    /**
     * Layout for modal.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * True if the Case Item is being edited, not created.
     */
    public readonly isEditMode: boolean;

    constructor(
        private readonly l10nService: L10nService,
        private readonly cportalService: CportalService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const severitiesList = Object.values(severityHash).map(this.severityMap);

        this.severitiesOptions = severitiesList
            .map(({ value, label }: IAviDropdownOption) => createDropdownOption(
                value,
                label,
            ));

        this.environmentsOptions = Object.values(environmentHash)
            .map((value: string) => createDropdownOption(value));

        this.caseTypesOptions = Object.values(typeHash)
            .map((value: string) => createDropdownOption(value));

        this.isEditMode = Boolean(itemId);
    }

    /** @override */
    public ngOnInit(): void {
        this.updateSeverity();

        const config = this.editable.getConfig();

        if (this.isEditMode) {
            const { contact_info: contactInfo } = config;
            const { email, name } = contactInfo;

            this.contactOptions.push(createDropdownOption(email, `${name} (${email})`));

            this.setCaseComments();
        } else {
            this.setContactOptions();
        }

        this.loadPortalInfo();
    }

    /**
     * Returns true if case is closed.
     */
    public get isCaseClosed(): boolean {
        return this.editable.isClosed();
    }

    /**
     * Handle case type change.
     */
    public handleCaseTypeChange(): void {
        const config = this.editable.getConfig();

        delete config.fr_use_cases;
        delete config.fr_business_justification;
        delete config.fr_current_solution;

        this.updateSeverity();
    }

    /**
     * Check if the connected portal mode is SYSTEST.
     */
    public get isPortalModeSysTest(): boolean {
        return this.portalMode === ALBServiceMode.SYSTEST;
    }

    /**
     * Check if the connected portal mode is MYVMWARE.
     */
    public get isPortalModeMyvmware(): boolean {
        return this.portalMode === ALBServiceMode.MYVMWARE;
    }

    /**
     * Check if the case type is Feature.
     */
    public isFeatureRequest(): boolean {
        return this.editable.getType() === FEATURE_REQUEST;
    }

    /**
     * Check if case has some attachments.
     */
    public get hasCaseAttachments(): boolean {
        const { caseAttachments } = this.editable;

        return Boolean(caseAttachments.length);
    }

    /**
     * Used to disable the Save button.
     */
    public get disableSubmit(): boolean {
        return this.userDataLoading || this.isCaseClosed;
    }

    /**
     * Used to disable the contact field when returns true.
     */
    public get disableContactField(): boolean {
        return this.isEditMode || this.userDataLoading;
    }

    /**
     * Used to disable form fields if case modal is open in edit mode
     * for MYVMWARE/SYSTEST portal mode or Case is Closed.
     */
    public get disableFormField(): boolean {
        return this.isEditMode &&
            (this.isPortalModeMyvmware || this.isPortalModeSysTest) ||
            this.isCaseClosed;
    }

    /**
     * Returns severity object with value and label properties for severity dropdown.
     */
    private severityMap = (severity: string): IAviDropdownOption => {
        const value = severity;
        let label = '';

        switch (value) {
            case SEVERITY_1:
                label = `${value} (${this.l10nService.getMessage(l10nKeys.criticalSeverityLabel)})`;
                break;

            case SEVERITY_2:
                label = `${value} (${this.l10nService.getMessage(l10nKeys.highSeverityLabel)})`;
                break;

            case SEVERITY_3:
                label = `${value} (${this.l10nService.getMessage(l10nKeys.mediumSeverityLabel)})`;
                break;

            case SEVERITY_4:
                label = `${value} (${this.l10nService.getMessage(l10nKeys.lowSeverityLabel)})`;
                break;

            case SEVERITY_5:
                label = `${value} (${this.l10nService.getMessage(l10nKeys.defaultSeverityLabel)})`;
                break;
        }

        return {
            value,
            label,
        };
    };

    /**
     * Set Contact dropdown options.
     */
    private setContactOptions(): void {
        this.userDataLoading = true;

        this.cportalService.getUsersList()
            .then(({ results }: IUserListApiResponse) => {
                // Creating contact dropdown options.
                this.contactOptions = results
                    .filter(({ email }: IALBServicesUser) => !isUndefined(email))
                    .map(({ email, name }: IALBServicesUser) => {
                        return createDropdownOption(email, `${name} (${email})`);
                    });
            })
            .catch((error: IError) => {
                this.error = error;
            })
            .finally(() => {
                this.userDataLoading = false;
            });
    }

    /**
     * Get the portal info.
     */
    private loadPortalInfo(): IPromise<void> {
        return this.cportalService.getPortalInfo()
            .then((portalInfo: IALBServicesConfig) => {
                const {
                    asset_contact: contactDetail,
                    mode,
                } = portalInfo;

                this.setPortalMode(mode);

                // Set default email for the contact dropdown field,
                // when case modal is opened in create mode.
                if (!this.isEditMode && contactDetail) {
                    const { email } = contactDetail;

                    this.editable.email = email;
                }
            })
            .catch((error: IError) => {
                this.error = error;
            });
    }

    /**
     * Set portal mode.
     */
    private setPortalMode(portalMode: ALBServiceMode): void {
        this.portalMode = portalMode;
    }

    /**
     * Set Case comments.
     */
    private setCaseComments(): void {
        this.loadingComments = true;

        this.editable.loadCaseComments()
            .then(({ results }: IALBServicesCaseCommentsResponse) => {
                this.comments = results;
            })
            .catch((error: IError) => {
                this.error = error;
            })
            .finally(() => {
                this.loadingComments = false;
            });
    }

    /**
     * Update the severity value bases on selected case type.
     */
    private updateSeverity(): void {
        const config = this.editable.getConfig();
        const { type } = config;

        if (
            type === CONFIGURATION_HELP ||
            type === QUESTION ||
            type === FEATURE_REQUEST
        ) {
            this.displaySeverity = false;
            this.editable.severity = SEVERITY_4;
        } else {
            this.displaySeverity = true;
        }
    }
}
