/**
 * @module LogsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { IRecommendation } from 'generated-types';
import { IAviDataGridConfig } from 'ng/shared/components/avi-data-grid/avi-data-grid.types';
import { L10nService } from '@vmw/ngx-vip';
import './recommendation-grid.component.less';
import * as l10n from './recommendation-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for Recommendations list.
 * @author vgohil
 */
@Component({
    selector: 'recommendation-grid',
    templateUrl: './recommendation-grid.component.html',
})
export class RecommendationGridComponent implements OnInit, AfterViewInit {
    /**
     * List of Recommendations, used to show data in grid.
     */
    @Input()
    public recommendationList: IRecommendation[] = [];

    /**
     * TemplateRef for Expand content of the recommendation.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Recommendation grid config.
     */
    public recommendationGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.recommendationGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.recommendationGridConfig = {
            ...this.recommendationGridConfig,
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleRecommendations),
                    id: 'action-summary',
                    transform(recommendation: IRecommendation) {
                        return recommendation.action_summary;
                    },
                },
            ],
            layout: {
                ...this.recommendationGridConfig.layout,
                placeholderMessage: l10nService.getMessage(l10nKeys.noRecommendationsPlaceholder),
            },
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }
}
