/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'azure-location-network';
const prefix = `${moduleName}.${componentName}`;

export const locationAndNetworkSectionHeader = `${prefix}.locationAndNetworkSectionHeader`;
export const locationLabel = `${prefix}.locationLabel`;
export const locationPlaceholder = `${prefix}.locationPlaceholder`;
export const resourceGroupLabel = `${prefix}.resourceGroupLabel`;
export const resourceGroupPlaceholder = `${prefix}.resourceGroupPlaceholder`;
export const vNetLabel = `${prefix}.vNetLabel`;
export const vNetPlaceholder = `${prefix}.vNetPlaceholder`;
export const vNetHelperText = `${prefix}.vNetHelperText`;
export const serviceEngineNetworkLabel = `${prefix}.serviceEngineNetworkLabel`;
export const serviceEngineNetworkPlaceholder = `${prefix}.serviceEngineNetworkPlaceholder`;
export const serviceEngineHelperText = `${prefix}.serviceEngineHelperText`;
export const useAzureEnhancedHaFeaturesLabel = `${prefix}.useAzureEnhancedHaFeaturesLabel`;
export const availabilityZonesLabel = `${prefix}.availabilityZonesLabel`;
export const availabilityZonesPlaceholder = `${prefix}.availabilityZonesPlaceholder`;
export const availabilityZonesHelperText = `${prefix}.availabilityZonesHelperText`;
export const useDedicatedManagementInterfaceLabel = `${prefix}.useDedicatedManagementInterfaceLabel`;
export const subnetForManagementInterfaceLabel = `${prefix}.subnetForManagementInterfaceLabel`;
export const subnetForManagementInterfacePlaceholder = `${prefix}.subnetForManagementInterfacePlaceholder`;
export const subnetHelperText = `${prefix}.subnetHelperText`;
export const storageSectionHeader = `${prefix}.storageSectionHeader`;
export const serviceEngineDiskEncryptionLabel = `${prefix}.serviceEngineDiskEncryptionLabel`;
export const serviceEngineDiskEncryptionPlaceholder = `${prefix}.serviceEngineDiskEncryptionPlaceholder`;
export const seDiskEncryptionHelperText = `${prefix}.seDiskEncryptionHelperText`;

export const ENGLISH = {
    [locationAndNetworkSectionHeader]: 'Location & Network',
    [locationLabel]: 'Location',
    [locationPlaceholder]: 'Select Location',
    [resourceGroupLabel]: 'Resource Group',
    [resourceGroupPlaceholder]: 'Select Resource Group',
    [vNetLabel]: 'VNet',
    [vNetPlaceholder]: 'Select VNet',
    [vNetHelperText]: 'Select Location to populate Virtual Networks',
    [serviceEngineNetworkLabel]: 'Service Engine Network',
    [serviceEngineNetworkPlaceholder]: 'Select Service Engine Network',
    [serviceEngineHelperText]: 'Select VNet to populate Service Engines',
    [useAzureEnhancedHaFeaturesLabel]: "Use Azure's Enhanced HA Features",
    [availabilityZonesLabel]: 'Availability Zones',
    [availabilityZonesPlaceholder]: 'Enter Availability Zones',
    [availabilityZonesHelperText]: 'Valid values are 1, 2 and 3. Maximum 3 values are allowed.',
    [useDedicatedManagementInterfaceLabel]: 'Use Dedicated Management Interface',
    [subnetForManagementInterfaceLabel]: 'Subnet for Management Interface',
    [subnetForManagementInterfacePlaceholder]: 'Select Subnet for Management Interface',
    [subnetHelperText]: 'Select VNet to populate Subnets',
    [storageSectionHeader]: 'Storage',
    [serviceEngineDiskEncryptionLabel]: 'Service Engine Disk Encryption',
    [serviceEngineDiskEncryptionPlaceholder]: 'Select Service Engine Disk Encryption',
    [seDiskEncryptionHelperText]: 'Select Location to populate Disk Encryption Sets',
};
