/**
 * @module VsVipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    VipConfigItem,
    VsVip,
} from 'ajs/modules/vs/factories';

import {
    CloudType,
    IGCPConfiguration,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { StringService } from 'ajs/modules/core/services/string-service';
import { Cloud } from 'ajs/js/services/items/Cloud';
import { DnsInfoConfigItem } from 'ajs/modules/dns/factories/dns-info.config-item.factory';
import { NsxtConfigurationConfigItem }
    from 'ajs/modules/cloud/factories/nsxt-configuration.config-item.factory';

import {
    BgpPeerConfigItem,
    VRFContext,
} from 'ajs/modules/vrf-context/factories';

import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import * as l10n from './vs-vip-modal.l10n';
import './vs-vip-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Set of public clouds for which BGP Peer Labels are not supported.
 */
const PUBLIC_CLOUDS = new Set([
    CloudType.CLOUD_AZURE,
    CloudType.CLOUD_AWS,
    CloudType.CLOUD_GCP,
    CloudType.CLOUD_OPENSTACK,
]);

/**
 * Set of cloudTypes with have multi-vip support.
 */
const CLOUDS_WITH_MULTIVIP_SUPPORT = new Set([
    CloudType.CLOUD_AZURE,
    CloudType.CLOUD_AWS,
]);

type TVRFContext = typeof VRFContext;
type TCloud = typeof Cloud;

/**
 * Tier1 Logical Router interface.
 */
interface ITier1LR {
    id?: string;
    name?: string;
}

/**
 * @description Modal Component for VsVip Configuration.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'vs-vip-modal',
    templateUrl: './vs-vip-modal.component.html',
})
export class VsVipModalComponent implements OnInit, OnDestroy {
    /**
     * VsVip ObjectTypeItem instance.
     */
    @Input()
    public editable: VsVip;

    /**
     * Name of the VS.
     */
    @Input()
    public vsName?: string;

    /**
     * For some cloud types (eg nsxt), VRFselection is optional.
     * So on create mode, they will be undefined.
     *
     * For the above case, isVrfOptional should be set to true.
     */
    @Input()
    public isVrfOptional = false;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Cloud (on which vsvip is being configured) instance.
     */
    public cloud: Cloud;

    /**
     * Name of the cloud
     */
    public cloudName: string;

    /**
     * Name of the VRF Context.
     */
    public vrfContextName: string;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * VsVip ObjectType.
     */
    public objectType: string;

    /**
     * Indicates whether we can configure multi-vip for this vsvip.
     * Depends on cloud-type.
     */
    public hasMultiVip = false;

    /**
     * True if cloud instance is loaded.
     */
    public cloudLoaded = false;

    /**
     * Indicates whether cloud has IPAM Provider profile.
     * If true, autoIpAllocation option will be available to user.
     */
    public hasIpamProviderProfile = false;

    /**
     * Type of cloud.
     */
    public cloudType: CloudType;

    /**
     * Sets to true if the cloud is type CLOUD_NSXT with overlay transport type.
     * Used to show/hide Tier1 Logical Router ID dropdown.
     */
    public isOverlayTransportZone = false;

    /**
     * Tier1 Logical Router ID dropdown options for NSX-T Virtual Services.
     */
    public tier1LROptions: IAviDropdownOption[] = [];

    /**
     * True if the VsVip Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * Bgp Peer Label dropdown options.
     */
    public bgpPeerLabelOptions: IAviDropdownOption[] = [];

    constructor(
        private readonly stringService: StringService,
        @Inject(Cloud)
        private readonly Cloud: TCloud,
        @Inject(VRFContext)
        private readonly VrfContext: TVRFContext,
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        this.isEditing = Boolean(itemId);

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * We load cloud instance here since vsvip depends
     * on many cloud fields (type, ipam/dns info, ...).
     *
     * @override
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        this.loadCloud();
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.cloud) {
            this.cloud.destroy();
        }
    }

    /**
     * Open VIP create modal.
     */
    public createVip(): void {
        this.editable.createVip(this.vipCreateParams);
    }

    /**
     * Open VIP edit modal.
     */
    public editVip(vip: VipConfigItem): void {
        this.editable.editVip(vip, this.vipCreateParams);
    }

    /**
     * Deletes a Vip from VsVip.
     */
    public deleteVip(vip: VipConfigItem): void {
        this.editable.config.vip.removeByMessageItem(vip);
    }

    /**
     * Opens DnsInfo create modal.
     */
    public createDnsInfo(): void {
        let defaultDnsName = '';

        // If user is going to create the first DNS in VsVip and we have vs name,
        // keep it as default name for DNS.
        if (this.editable.dnsInfoList.isEmpty() && this.vsName) {
            defaultDnsName = this.vsName;
        }

        this.editable.createDnsInfo(defaultDnsName);
    }

    /**
     * Opens DNS edit modal.
     */
    public editDnsInfo(dnsInfo: DnsInfoConfigItem): void {
        this.editable.editDnsInfo(dnsInfo);
    }

    /**
     * Deletes DNS from RepeatedMessageItem.
     */
    public deleteDnsInfo(dnsInfo: DnsInfoConfigItem): void {
        this.editable.removeDnsInfoByMessageItem(dnsInfo);
    }

    /*
     * Returns true if we need to show DnsInfo config.
     */
    public get showDnsConfiguration(): boolean {
        if (!this.cloudLoaded) {
            return false;
        }

        return this.cloud.hasDnsConfigurationSupport();
    }

    /*
     * Returns true if selected cloud is Azure cloud.
     * Used to show use_standard_alb checkbox for Azure cloud.
     */
    public get isAzureCloud(): boolean {
        return this.cloudType === CloudType.CLOUD_AZURE;
    }

    /*
     * Returns true when Cloud and Vrf Context Refs are set.
     */
    public get isCloudAndVrfSelected(): boolean {
        const { config } = this.editable;

        const {
            cloud_ref: cloudRef,
            vrf_context_ref: vrfContextRef,
        } = config;

        const hasVrfContext = vrfContextRef || this.isVrfOptional;

        return Boolean(cloudRef && hasVrfContext);
    }

    /*
     * Handler for Cloud Ref change.
     */
    public onCloudRefChange(): void {
        this.loadCloud();
    }

    /**
     * If true, BGP-Peer-Labels Dropdown will be displayed.
     *
     * To fetch bgpPeerLabels, VRFContext has to be loaded.
     * So show this field only when we have vrfContextRef.
     */
    public get showBgpLabelsDropdown(): boolean {
        return (!this.isVrfOptional || Boolean(this.editable.vrfContextRef)) &&
            this.isBgpPeerLabelsSupported;
    }

    /**
     * Method to check if BGP Peer Labels are supported.
     * BGP Peer Labels are not supported for public cloud.
     */
    public get isBgpPeerLabelsSupported(): boolean {
        return !PUBLIC_CLOUDS.has(this.cloudType);
    }

    /**
     * Loads cloud instance using cloudRef.
     */
    private async loadCloud(): Promise<void> {
        this.cloudLoaded = false;

        this.cloud = new this.Cloud({
            id: this.stringService.slug(this.editable.cloudRef),
        });

        try {
            await this.cloud.load();

            this.setCloudDetails();

            this.cloudLoaded = true;
        } catch (e) {
            // empty catch block
        }
    }

    /**
     * Once this.cloud is loaded, initialize some of the cloud config fields.
     */
    private setCloudDetails(): void {
        this.cloudType = this.cloud.getVtype() as unknown as CloudType;
        this.hasIpamProviderProfile = this.cloud.hasIpamProviderProfile();

        const multiVipSupport = CLOUDS_WITH_MULTIVIP_SUPPORT.has(this.cloudType);

        // If cloudType AWS, DnsInfo should be added for multivip.
        this.hasMultiVip = multiVipSupport &&
            (this.cloudType !== CloudType.CLOUD_AWS || this.cloud.hasDnsConfigurationSupport());

        // Set NSX-T Cloud related fields.
        const cloudConfig: Record<string, any> = this.cloud.getCloudConfig();

        this.isOverlayTransportZone = this.cloudType === CloudType.CLOUD_NSXT &&
            cloudConfig.isOverlayTransportZone();

        if (this.isOverlayTransportZone) {
            this.setNsxtTier1LROptions();
        }

        if (this.showBgpLabelsDropdown) {
            // Set dropdown options of BGP Peer Labels.
            this.setBgpPeerLabelOptions();
        }
    }

    /**
     * Create dropdown options of BGP Peer Labels.
     */
    private async setBgpPeerLabelOptions(): Promise<void> {
        if (!this.isCloudAndVrfSelected) {
            return;
        }

        this.bgpPeerLabelOptions = [];

        const bgpPeerLabelOptionsHash: Record<string, boolean> = {};

        const vrfContext = new this.VrfContext(
            { id: this.stringService.slug(this.editable.vrfContextRef) },
        );

        await vrfContext.load();

        // Populate bgpPeerLabelOptions with the list of labels available across bgp peers.
        const { config: { bgp_profile: bgpProfile } } = vrfContext;

        this.bgpPeerLabelOptions = bgpProfile.config.peers.config
            .reduce((bgpPeerLabelOptions: IAviDropdownOption[], { config }: BgpPeerConfigItem) => {
                const { label } = config;

                // Need to check for label since there can be Bgp Peers without label.
                if (label) {
                    // We don't allow duplicate options for BGP Peer Labels dropdown.
                    if (!bgpPeerLabelOptionsHash[label]) {
                        const bgpPeerLabelOption = createDropdownOption(label);

                        bgpPeerLabelOptions.push(bgpPeerLabelOption);
                        bgpPeerLabelOptionsHash[label] = true;
                    }
                }

                return bgpPeerLabelOptions;
            }, []);

        vrfContext.destroy();
    }

    /**
     * Getter for params required for VIP configuration.
     */
    private get vipCreateParams(): Record<string, any> {
        const {
            cloudType,
            hasIpamProviderProfile,
            hasMultiVip,
        } = this;

        const params: Record<string, any> = {
            cloudType,
            hasIpamProviderProfile,
            hasMultiVip,
            ipamSelectorLabels: this.editable.ipamSelectorLabels,
            isVrfOptional: this.isVrfOptional,
        };

        const cloudConfig: Record<string, any> = this.cloud.getCloudConfig();

        // Add cloudType specific params here.
        switch (cloudType) {
            case CloudType.CLOUD_AWS: {
                params.zones = cloudConfig.zones;

                break;
            }

            case CloudType.CLOUD_GCP: {
                const GcpConfiguration: IGCPConfiguration =
                    this.cloud.getGcpConfigurationConfig();

                params.vipAllocationStrategy = GcpConfiguration.vip_allocation_strategy;

                break;
            }

            case CloudType.CLOUD_NSXT: {
                const nsxtConfigItem =
                    this.cloud.getNsxtConfigurationConfig() as NsxtConfigurationConfigItem;

                params.isVlanTransportZone = nsxtConfigItem.isVlanTransportZone();

                break;
            }
        }

        return params;
    }

    /**
     * Sets Tier1 Logical Router ID dropdown options.
     */
    private async setNsxtTier1LROptions(): Promise<void> {
        try {
            const tier1LRs: ITier1LR[] = await this.cloud.getNsxtTier1LogicalRoutersByID();

            this.tier1LROptions = tier1LRs.map(({ id, name }) => createDropdownOption(id, name));
        } catch (error) {
            this.tier1LROptions = [];
        }
    }
}
