/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'login';
const componentName = 'login-page';
const prefix = `${moduleName}.${componentName}`;

export const recoveryInstructionSent = `${prefix}.recoveryInstructionSent`;

export const ENGLISH = {
    [recoveryInstructionSent]: 'Recovery instruction sent to email',
};
