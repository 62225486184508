/**
 * @module VsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IIpAddr, IVipRuntimeDetail } from 'generated-types';

/**
 * Enumerations constant holding types of operational states. We have 18 OperationalStates
 * like 'OPER_RESOURCES', 'OPER_UNUSED', 'OPER_DISABLED' etc. These operational states are mainly
 * categorised into 3 types like 'OPER_UP', 'OPER_DOWN' and 'OPER_PENDING'.
 */
export enum OperStateType {
    OPER_UP = 'up',
    OPER_DOWN = 'down',
    OPER_PENDING = 'pending',
}

export interface IExtendedVipRuntimeDetail extends IVipRuntimeDetail {
    ip_address?: IIpAddr;
    ip6_address?: IIpAddr;
    operStateType: OperStateType;
}
