/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtual-service-policy-header-controller';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameInputLabel = `${prefix}.ruleNameInputLabel`;
export const noneBtnLabel = `${prefix}.noneBtnLabel`;
export const logBtnLabel = `${prefix}.logBtnLabel`;
export const logWithHeadersBtnLabel = `${prefix}.logWithHeadersBtnLabel`;

export const ENGLISH = {
    [ruleNameInputLabel]: 'Rule Name',
    [noneBtnLabel]: 'None',
    [logBtnLabel]: 'Log',
    [logWithHeadersBtnLabel]: 'Log with headers',
};
