/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import './sensitive-field-rule-modal.less';
import * as l10n from './sensitive-field-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name sensitiveFieldRuleModal
 * @description Modal for configuring a Sensitive Field rule.
 * @param {SensitiveFieldRuleConfig} rule
 *      Sensitive Field rule. SensitiveFieldRuleConfig ConfigItem.
 * @param {Function} onSubmit - Called when the user saves the rule.
 * @param {Function} closeModal - Called to close the modal.
 */
class SensitiveFieldRuleModalController {
    constructor(
        schemaService,
        Regex,
        l10nService,
    ) {
        this.errors = null;

        const stringOperationEnumObjects = schemaService.getEnumValues('StringOperation');

        this.logActionEnumKeys = schemaService.getEnumKeys('LogAction');

        // regex operations are not supported
        this.matchPatterns = stringOperationEnumObjects.filter(
            ({ value }) => !value.startsWith('REGEX'),
        );

        this.Regex = Regex;

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when the Enable Action checkbox is changed.
     */
    handleEnableAction() {
        if (this.rule.actionEnabled) {
            this.rule.addLogAction();
        } else {
            this.rule.removeLogAction();
        }
    }

    /**
     * Called when the Submit button is clicked. Saves the rule.
     */
    handleSubmit() {
        this.errors = null;
        this.onSubmit({ rule: this.rule })
            .then(() => this.closeModal())
            .catch(({ data }) => this.errors = data);
    }
}

SensitiveFieldRuleModalController.$inject = [
    'schemaService',
    'Regex',
    'l10nService',
];

angular.module('aviApp').component('sensitiveFieldRuleModal', {
    controller: SensitiveFieldRuleModalController,
    bindings: {
        rule: '<',
        onSubmit: '&',
        closeModal: '&',
    },
    templateUrl: 'src/components/templates/profiles/analytics/sensitive-field-rule-modal/' +
                    'sensitive-field-rule-modal.html',
});
