/** @module VsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { VsVip, VsVipCollection } from 'ajs/modules/vs';
import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown';
import vsVipListTemplate from './vs-vip-list.component.html';
import vsVipListExpanderTemplate from './vs-vip-list-expander.html';
import * as l10n from './vs-vip-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type VsVipCollectionType = typeof VsVipCollection;
type createCrudCollectionGridConfigType = typeof createCrudCollectionGridConfig;

const enum vsvipFilterValues {
    all,
    unused,
    used,
}

/**
 * @description
 *
 *   VsVip list-page component.
 *
 * @author Ram Pal
 */
class VsVipListController {
    public gridConfig: ICollectionGridConfig;

    /**
     * Filter model value.
     */
    public vsvipFilter: vsvipFilterValues = vsvipFilterValues.all;

    /**
     * Filter dropdown options.
     */
    public vsvipFilterOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from source bundles for template usage.
     */
    private readonly l10nKeys = l10nKeys;

    /** @constructor */
    constructor(
        private VsVipCollection: VsVipCollectionType,
        private CRUDGridConfig: createCrudCollectionGridConfigType,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.vsvipFilterOptions = this.getVsVipFilterOptions();
    }

    /** @override */
    public $onInit(): void {
        const { l10nService } = this;

        this.gridConfig = this.CRUDGridConfig();
        this.gridConfig.collection = new this.VsVipCollection();

        this.gridConfig.fields = [{
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        }, {
            name: 'data.config.vip',
            title: l10nService.getMessage(l10nKeys.columnTitleAddress),
            template: '<vs-vip-address-grid-cell vs-vip="row" />',
        }, {
            name: 'data.config.vs_refs',
            title: l10nService.getMessage(l10nKeys.columnTitleVirtualServicesCount),
            template: '{{ row.getConfig().vs_refs.length }}',
        }];

        this.gridConfig.expandedContainerTemplate = vsVipListExpanderTemplate;

        this.gridConfig.expanderDisabled =
            (row: VsVip) => !row.getConfig() || !('vs_refs' in row.getConfig());

        const { objectName } = this.gridConfig.collection;

        this.gridConfig.id = `${objectName}-list-page`;
    }

    /**
     * Sets vsvip grid collection params on filter change.
     */
    public onFilterChange(): void {
        const { vsvipFilter } = this;

        // Default params to load all vsvips.
        const params: Record<string, string | undefined> = {
            referred_by: undefined,
        };

        switch (vsvipFilter) {
            case vsvipFilterValues.used: {
                params.referred_by = 'virtualservice:any';
                break;
            }

            case vsvipFilterValues.unused: {
                params.referred_by = 'virtualservice:none';
                break;
            }
        }

        this.gridConfig.collection.setParams(params);
        this.gridConfig.collection.load();
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }

    /**
     * Returns vsVip filter options.
     */
    public getVsVipFilterOptions(): IAviDropdownOption[] {
        const { l10nService } = this;

        return [{
            label: l10nService.getMessage(l10nKeys.allVipsOptionLabel),
            value: vsvipFilterValues.all,
        }, {
            label: l10nService.getMessage(l10nKeys.unusedVipsOptionLabel),
            value: vsvipFilterValues.unused,
        }, {
            label: l10nService.getMessage(l10nKeys.inUseVipsOptionLabel),
            value: vsvipFilterValues.used,
        }];
    }
}

VsVipListController.$inject = [
    'VsVipCollection',
    'CRUDGridConfig',
    'l10nService',
];

export const vsVipListOptions = {
    controller: VsVipListController,
    template: vsVipListTemplate,
};
