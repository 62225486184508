/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ldap-vs-validation-config';
const prefix = `${moduleName}.${componentName}`;

export const ssoPolicyLabel = `${prefix}.ssoPolicyLabel`;
export const ssoPolicyInputPlaceholder = `${prefix}.ssoPolicyInputPlaceholder`;
export const createSsoPolicyButtonLabel = `${prefix}.createSsoPolicyButtonLabel`;
export const realmInputLabel = `${prefix}.realmInputLabel`;
export const realmInputPlaceholder = `${prefix}.realmInputPlaceholder`;
export const bindTimeoutInputLabel = `${prefix}.bindTimeoutInputLabel`;
export const bindTimeoutInputPlaceholder = `${prefix}.bindTimeoutInputPlaceholder`;
export const cacheSizeInputLabel = `${prefix}.cacheSizeInputLabel`;
export const cacheSizeInputPlaceholder = `${prefix}.cacheSizeInputPlaceholder`;
export const connectTimeoutInputLabel = `${prefix}.connectTimeoutInputLabel`;
export const connectTimeoutInputPlaceholder = `${prefix}.connectTimeoutInputPlaceholder`;
export const connsPerServerInputLabel = `${prefix}.connsPerServerInputLabel`;
export const connsPerServerInputPlaceholder = `${prefix}.connsPerServerInputPlaceholder`;
export const reconnectTimeoutInputLabel = `${prefix}.reconnectTimeoutInputLabel`;
export const reconnectTimeoutInputPlaceholder = `${prefix}.reconnectTimeoutInputPlaceholder`;
export const requestTimeoutInputLabel = `${prefix}.requestTimeoutInputLabel`;
export const requestTimeoutInputPlaceholder = `${prefix}.requestTimeoutInputPlaceholder`;
export const serversFailoverOnlyLabel = `${prefix}.serversFailoverOnlyLabel`;
export const millisecondsDataLabel = `${prefix}.millisecondsDataLabel`;
export const bytesDataLabel = `${prefix}.bytesDataLabel`;

export const ENGLISH = {
    [ssoPolicyLabel]: 'SSO Policy',
    [ssoPolicyInputPlaceholder]: 'Select SSO Policy',
    [createSsoPolicyButtonLabel]: 'Create SSO Policy',
    [realmInputLabel]: 'Basic Realm',
    [realmInputPlaceholder]: 'Enter Basic Realm',
    [bindTimeoutInputLabel]: 'Bind Timeout',
    [bindTimeoutInputPlaceholder]: 'Enter Bind Timeout',
    [cacheSizeInputLabel]: 'Cache Size',
    [cacheSizeInputPlaceholder]: 'Enter Cache Size',
    [connectTimeoutInputLabel]: 'Connect Timeout',
    [connectTimeoutInputPlaceholder]: 'Enter Connect Timeout',
    [connsPerServerInputLabel]: 'Connections Per Server',
    [connsPerServerInputPlaceholder]: 'Enter Connections Per Server',
    [reconnectTimeoutInputLabel]: 'Reconnect Timeout',
    [reconnectTimeoutInputPlaceholder]: 'Enter Reconnect Timeout',
    [requestTimeoutInputLabel]: 'Request Timeout',
    [requestTimeoutInputPlaceholder]: 'Enter Request Timeout',
    [serversFailoverOnlyLabel]: 'Servers Failover Only',
    [millisecondsDataLabel]: 'milliseconds',
    [bytesDataLabel]: 'bytes',
};
