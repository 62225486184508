/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'rate-profile';
const prefix = `${moduleName}.${componentName}`;

export const thresholdInputLabel = `${prefix}.thresholdInputLabel`;
export const timePeriodInputLabel = `${prefix}.timePeriodInputLabel`;
export const secondsInnerText = `${prefix}.secondsInnerText`;
export const actionInputLabel = `${prefix}.actionInputLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const cookieRadioOptionLabel = `${prefix}.cookieRadioOptionLabel`;
export const headerRadioOptionLabel = `${prefix}.headerRadioOptionLabel`;
export const statusCodeInputLabel = `${prefix}.statusCodeInputLabel`;
export const statusCodeInputPlaceholder = `${prefix}.statusCodeInputPlaceholder`;
export const fileInputLabel = `${prefix}.fileInputLabel`;
export const protocolInputLabel = `${prefix}.protocolInputLabel`;
export const portInputLabel = `${prefix}.portInputLabel`;
export const portInputPlaceholder = `${prefix}.portInputPlaceholder`;
export const keepQueryInputLabel = `${prefix}.keepQueryInputLabel`;
export const hostInputLabel = `${prefix}.hostInputLabel`;
export const pathInputLabel = `${prefix}.pathInputLabel`;
export const pathInputPlaceholder = `${prefix}.pathInputPlaceholder`;

export const ENGLISH = {
    [thresholdInputLabel]: 'Threshold',
    [timePeriodInputLabel]: 'Time Period',
    [secondsInnerText]: 'Seconds',
    [actionInputLabel]: 'Action',
    [nameInputLabel]: 'Name',
    [cookieRadioOptionLabel]: 'Cookie',
    [headerRadioOptionLabel]: 'Header',
    [statusCodeInputLabel]: 'Status Code:',
    [statusCodeInputPlaceholder]: 'Status Code',
    [fileInputLabel]: 'File:',
    [protocolInputLabel]: 'Protocol:',
    [portInputLabel]: 'Port:',
    [portInputPlaceholder]: 'Port',
    [keepQueryInputLabel]: 'Keep query',
    [hostInputLabel]: 'Host:',
    [pathInputLabel]: 'Path:',
    [pathInputPlaceholder]: 'Keep Existing Path',
};
