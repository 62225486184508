/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'collection-dropdown';
const prefix = `${moduleName}.${componentName}`;

export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;
export const noValidEntriesLabel = `${prefix}.noValidEntriesLabel`;
export const createButtonLabel = `${prefix}.createButtonLabel`;

export const ENGLISH = {
    [searchInputPlaceholder]: 'Search',
    [noValidEntriesLabel]: '-- no valid entries --',
    [createButtonLabel]: 'Create',
};
