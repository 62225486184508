/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'hsm-group-download-certificate-modal-service';
const prefix = `${moduleName}.${componentName}`;

export const hsmGroupModalName = `${prefix}.hsmGroupModalName`;

export const ENGLISH = {
    [hsmGroupModalName]: 'HSM Group',
};
