/**
 * @module LaunchConfigModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from '../../js/utilities/ajsDependency';
import {
    AutoScaleLaunchConfigCollection,
    AutoScaleLaunchConfigItem,
} from '.';
import {
    AUTO_SCALE_LAUNCH_CONFIG_COLLECTION_TOKEN,
    AUTO_SCALE_LAUNCH_CONFIG_ITEM_TOKEN,
} from './launch-config.tokens';

const launchConfigModule = angular.module('avi/launch-config');
const factories = [
    {
        factory: AutoScaleLaunchConfigCollection,
        name: AUTO_SCALE_LAUNCH_CONFIG_COLLECTION_TOKEN,
    },
    {
        factory: AutoScaleLaunchConfigItem,
        name: AUTO_SCALE_LAUNCH_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(launchConfigModule, 'factory', name, factory);
});
