/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './waf-policy-psm-group-list-page.l10n';

const SUM_FLAGGED_METRIC = 'waf_psmgroup.sum_flagged';
const SUM_EVALUATED_METRIC = 'waf_psmgroup.sum_evaluated';
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class WafPolicyPsmGroupListPageComponent {
    constructor(
        WafPolicyPsmGroupCollection,
        CRUDGridConfig,
        l10nService,
    ) {
        this.WafPolicyPsmGroupCollection_ = WafPolicyPsmGroupCollection;
        this.CrudGridConfig_ = CRUDGridConfig;

        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        const { l10nService_: l10nService } = this;

        const getGridMetricFieldTemplate = metric => {
            return `{{ row.getMetric('${metric}').getMainSeries().getValue() || 'N/A' }}`;
        };

        const fields = [
            {
                require: 'config',
                name: 'data.config.name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                sortBy: 'name',
            },
            {
                require: SUM_FLAGGED_METRIC,
                name: SUM_FLAGGED_METRIC,
                title: l10nService.getMessage(l10nKeys.columnTitleFlagged),
                template: getGridMetricFieldTemplate(SUM_FLAGGED_METRIC),
            },
            {
                require: SUM_EVALUATED_METRIC,
                name: SUM_EVALUATED_METRIC,
                title: l10nService.getMessage(l10nKeys.columnTitleEvaluated),
                template: getGridMetricFieldTemplate(SUM_EVALUATED_METRIC),
            },
        ];

        const config = {
            collection: new this.WafPolicyPsmGroupCollection_({
                params: {
                    fields: 'name,tenant_ref',
                },
            }),
            fields,
            permission: 'PERMISSION_WAFPOLICYPSMGROUP',
            singleactions: [
                {
                    title: l10nService.getMessage(l10nKeys.actionTitleEdit),
                    class: 'icon-pencil-4',
                    hidden: row => !row.isAllowed() || row.isProtected(),
                    do: row => row.edit(),
                },
            ],
            layout: {
                includeTimeframeSelector: true,
                hideEditColumns: true,
            },
        };

        const { objectName } = config.collection;

        config.id = `${objectName}-list-page`;

        this.gridConfig = new this.CrudGridConfig_(config);
    }

    $onDestroy() {
        this.gridConfig.collection.destroy();
    }
}

WafPolicyPsmGroupListPageComponent.$inject = [
    'WafPolicyPsmGroupCollection',
    'CRUDGridConfig',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  wafPolicyPsmGroupListPage
 * @description Component for a WAF Policy PSM Group list page.
 * @module waf/wafPolicyPsmGroupListPage
 * @author alextsg
 */
angular.module('avi/waf').component('wafPolicyPsmGroupListPage', {
    controller: WafPolicyPsmGroupListPageComponent,
    template:
        `<div class="panel templatesList">
            <collection-grid config="$ctrl.gridConfig"></collection-grid>
        </div>`,
});
