/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name  ControllerFaultCollDataSource
 * @description
 *     DataSource for ControllerFaultCollection. Extends LimitedListCollDataSource as it does not
 *     support pagination or search, and sets the update interval to 3 minutes.
 */
angular.module('aviApp').factory('ControllerFaultCollDataSource', [
'LimitedListCollDataSource', LimitedListCollDataSource => {
    return class ControllerFaultCollDataSource extends LimitedListCollDataSource {
        constructor(args) {
            super(args);
            this.updateInterval_ = 600;
            this.protectedUpdateInterval_ = true;
        }

        /** @override */
        getRequestParams_(reqParams) {
            const params = super.getRequestParams_(this.params_);

            // eslint-disable-next-line no-underscore-dangle
            params.fields_ = _.map(this.fields_, field => field.id);

            return params;
        }
    };
}]);
