/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import {
    GeoDbMappingConfigItem,
    GeoDbMappingElementConfigItem,
} from 'ajs/modules/geo-db';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './geo-db-mapping-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Geo Db Mapping modal.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'geo-db-mapping-modal',
    templateUrl: './geo-db-mapping-modal.component.html',
})
export class GeoDbMappingModalComponent implements OnInit {
    /**
     * GeoDbMapping instance.
     */
    @Input()
    public editable: GeoDbMappingConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<GeoDbMappingConfigItem>();

    /**
     * GeoDbMapping object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Geo Db Mapping modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Opens modal to add new Geo Db Element.
     */
    public onAddGeoDbMappingElement(): void {
        this.editable.addGeoDbMappingElement();
    }

    /**
     * Handles Geo Db Mapping Element remove operation.
     */
    public onRemoveGeoDbMappingElement(
        geoDbMappingElementItem: GeoDbMappingElementConfigItem,
    ): void {
        this.editable.removeGeoDbMappingElement(geoDbMappingElementItem);
    }

    /**
     * Handles Geo Db Mapping Element edit operation.
     */
    public onEditGeoDbMappingElement(
        geoDbMappingElementItem: GeoDbMappingElementConfigItem,
    ): void {
        this.editable.editGeoDbMappingElement(geoDbMappingElementItem);
    }
}
