/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'nsxt-edit-credentials-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setNsxtCredentialsHeader = `${prefix}.setNsxtCredentialsHeader`;
export const managerAddressInputLabel = `${prefix}.managerAddressInputLabel`;
export const managerCredentialSelectLabel = `${prefix}.managerCredentialSelectLabel`;

export const ENGLISH = {
    [setNsxtCredentialsHeader]: 'Set NSX-T Credentials',
    [managerAddressInputLabel]: 'NSX-T Manager Address',
    [managerCredentialSelectLabel]: 'NSX-T Manager Credentials',
};
