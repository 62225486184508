/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './dns-record-config-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name dnsRecordConfigModal
 * @description
 *     Modal component for creating or editing a DNS Record in a Virtual Service.
 * @param {DnsRecordConfig=} dnsRecord - DnsRecordConfig config item. If empty it means a new DNS
 *     Record is being created.
 * @param {Function} closeModal - Closes the modal.
 * @param {Function} onSubmit - Called when Submit is clicked.
 */
class DnsRecordConfigModalController {
    constructor(
        Regex,
        DnsRecordConfig,
        l10nService,
    ) {
        this.Regex = Regex;
        this._DnsRecordConfig = DnsRecordConfig;
        this.editing = false;
        this.recordTypes = angular.copy(DnsRecordConfig.recordTypes);

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        if (!_.isEmpty(this.dnsRecord)) {
            this.editing = true;
            this.record = this.dnsRecord.getCopy();
        } else {
            this.record = new this._DnsRecordConfig();
            this.record.beforeEdit();
        }
    }

    disableSubmit() {
        const record = this.record.getConfig();

        switch (record.type) {
            case 'DNS_RECORD_TXT':

                return record.txt_records.length === 0;

            case 'DNS_RECORD_MX':

                return record.mx_records.length === 0;

            default:

                return false;
        }
    }

    /**
     * Called when the Submit button is clicked. Calls the bound onSubmit function and closes
     * the modal.
     */
    handleSubmit() {
        this.onSubmit({ record: this.record });
        this.closeModal();
    }
}

DnsRecordConfigModalController.$inject = [
        'Regex',
        'DnsRecordConfig',
        'l10nService',
];

angular.module('aviApp').component('dnsRecordConfigModal', {
    controller: DnsRecordConfigModalController,
    bindings: {
        dnsRecord: '<',
        closeModal: '&',
        onSubmit: '&',
    },
    templateUrl: 'src/components/modals/dns-record-config-modal/dns-record-config-modal.html',
});
